import { StyleProp, ViewStyle } from 'react-native';

import { ContentViewStyle } from '@levelapp/softfabric';

export default class ZonesViewStyle extends ContentViewStyle {
  container: StyleProp<ViewStyle> = {
    display: 'flex',
    flex: 1,
    backgroundColor: 'white',
    margin: 20,
    marginTop: 35,
  }

  treeView: StyleProp<ViewStyle> = {
    padding: 20,
  }
  
  noData: StyleProp<ViewStyle> = {
    paddingBottom: 20,
  }
}