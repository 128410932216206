import { ContentViewModel, ContentView } from '@levelapp/softfabric';
import RolePermissionPopupViewState from './RolePermissionPopupViewState';
import RolePermissionPopupViewProps from './RolePermissionPopupViewProps';
import RoleOperations from '../../../../../../../Transfer/Identity/RoleOperations';
import Permission from '../../../../../../../Common/DTO/Permission';
import PermissionOperation from '../../../../../../../Transfer/Identity/PermissionOperations';
import RolePermission from '../../../../../../../Common/DTO/M&N/RolePermission';

export default class RolePermissionPopupViewModel extends ContentViewModel<RolePermissionPopupViewState, RolePermissionPopupViewProps>
{
    _roleOperation : RoleOperations;
    _permissionOperation : PermissionOperation;

    constructor(props: ContentView) {
        super(props);

        /* Initial State */
        this.initialState({
            role: this.props().role,
            allPermissions : [],
            showErrors: false,
            index: 0,
            tab: "main",
            isLoading: false,
            isLoadingPermissions : false,
            windowTab : 'main'
        });

        /* BINDINGS */
        this.changeTab = this.changeTab.bind(this);

        this.handleChangeName = this.handleChangeName.bind(this);
        this.handleBackPressed = this.handleBackPressed.bind(this);
        this.handleSavePressed = this.handleSavePressed.bind(this);
        this.handleFieldChanged = this.handleFieldChanged.bind(this);
        this.handleGetAllPermissions = this.handleGetAllPermissions.bind(this);
        this.handleNavigationTo = this.handleNavigationTo.bind(this);

        this._roleOperation = new RoleOperations();
        this._permissionOperation = new PermissionOperation();
    }

    /* COMPONENT EVENTS */
    componentDidMount() 
    {
        this.refreshPermissions();
    }

    handleNavigationTo(tab : string)
    {
        this.setField({windowTab : tab});
        if(tab == 'main')
        {
            this.refreshPermissions();
        }
    }

    refreshPermissions() {
        this.setField({isLoadingPermissions : true});
        this._permissionOperation.getAll(this.handleGetAllPermissions)

    }

    handleGetAllPermissions(permissions : Permission[])
    {
        this.setField({allPermissions : permissions , isLoadingPermissions : false});
    }

    handleBackPressed() {
        this.closePopUp();
    }

    handleFieldChanged(type : 'name' , text : string)
    {
        switch(type)
        {
            case 'name':
                this.handleChangeName(text);
                break;
        }
    }


    private handleChangeName(text : string)
    {
       this.setField((previousState) => previousState.role.name = text);
    }

    
    handleSavePressed() {
        if (this.state().role.name == null || this.state().role.name == "")
            {
            this.setField((previousState) => previousState.showErrors = true);
        }
        else {
            this.setField((previousState) => previousState.showErrors = false);
            this.setField((previousState) => previousState.isLoading = true);
            this.props().saveRolePermission(this.state().role);
        }
    }

    handleChangePermission(value : boolean , permission : Permission)
    {

        if (value) {
            // Add permission to list Permissions of the Role
            var state = this.state();
            state.role.rolePermissions.push(
                {
                    permissionId : permission.id,
                    permission : permission
                });
            this.setField({state});
        } else {
            // Remove permission from the list permissions of the Role
            var obj = this.state().role.rolePermissions.find((element: RolePermission) => element.permissionId == permission.id);
            if(obj != undefined)
            {
                this.setField(previousState => previousState.role.rolePermissions.splice(this.state().role.rolePermissions.indexOf(obj as RolePermission), 1));
            }
        }
    }


    changeTab(tab: any) {
        this.setField((previousState) => previousState.tab = tab);
    }
} 