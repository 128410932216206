import { ContentShellViewModel, ContentShellView } from '@levelapp/softfabric';
import ShellKitchenAppViewState from './ShellKitchenAppViewState';
import ShellKitchenAppViewProps from './ShellKitchenAppViewProps';
 
import KitchenAppView from './pages/kitchenapp/KitchenAppView';
import EnumModules from '../../../../../Common/Constants/EnumModules';

export default class ShellKitchenAppViewModel extends ContentShellViewModel<ShellKitchenAppViewState, ShellKitchenAppViewProps>
{

    constructor(view: ContentShellView)
    {
        super(view);

        this.registerEntryPoint('main');
        this.register(<KitchenAppView />, 'main');

        this.initialisation();
    }

    getModuleId()
    {
        return EnumModules.KITCHENAPP;
    }

}