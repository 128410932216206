 
import { ContentView } from '@levelapp/softfabric';
import CouponsViewState from './CouponsViewState';
import CouponsViewProps from './CouponsViewProps';
import CouponsViewStyle from './CouponsViewStyle';
import CouponsViewModel from './CouponsViewModel';
import { View } from 'react-native';
import TableView from '../../../../../../../Common/Components/TabelView/TableView';
import ResearchInputView from '../../../../../../../Common/Components/researchinput/ResearchInputView';
import HeaderView from '../../../../components/header/HeaderView';

export default class CouponsView extends ContentView<CouponsViewProps, CouponsViewState, CouponsViewModel, CouponsViewStyle>
{
  /* CONSTRUCTOR */
  constructor(props: CouponsViewProps) {
    super(props);

    // Binding
    this.bind(new CouponsViewModel(this), new CouponsViewStyle(this));
  }

  /* RENDERING */
  render() {
    return (
      <View style={this.style.container}>
        <View style={this.style.box}>
          
          <HeaderView
            title={`${this.props.type}.title`}
            description={`${this.props.type}.description`}
            shouldShowButton={true}
            onButtonPress={this.binding.addField}
            buttonText={`${this.props.type}.button.add`}

          />

          <View style={this.style.boxContainer}>
            <ResearchInputView
              placeholder={this.binding.translate("generic.search")}
              handlerResearching={(searchText: string) => { this.binding.handleResearching(searchText) }}
              handlingClose={() => { this.binding.handleCloseResearching() }} />
            <TableView
              headerTextStyle={this.style.tableHeader}
              backgroundStepColor={this.style.stepColor}
              rowStyle={this.style.rowStyle}
              nothingToDisplayText={this.binding.translate("component.table.noresults")}
              isLastColumnActions={true}
              data={this.state.tableData}
              isLoading={this.state.isLoadingCoupon || this.state.isLoadingContantTypeKeys || this.state.isLoadingCountry || this.state.isLoadingLanguage || this.state.isLoadingMembership } 
              headers={this.state.tableHeaders}
              onPageChange={(page: number) => this.binding.onPageChange(page)}
              handleSortChange={(indexHeaderSort : number, sortOrder : string) => {this.binding.onSortChange(indexHeaderSort, sortOrder)}}
              nbPage={this.state.nbPage}
              currentPage={this.state.currentPage - 1}
              isSortable={true}/>
          </View>
        </View>
      </View>
    );
  }
}