import { ContentViewModel, ContentView } from '@levelapp/softfabric';
import DropdownItemViewState from './DropdownItemViewState';
import DropdownItemViewProps from './DropdownItemViewProps';

export default class DropdownItemViewModel extends ContentViewModel<DropdownItemViewState, DropdownItemViewProps>
{
  /* CONSTRUCTOR */
  constructor(view: ContentView) {
    super(view);

    this.initialState({}); // Initialize your state here

    // Bindings

  }
}