 
import {ContentView} from '@levelapp/softfabric';
import VouchersPopUpViewState from './VouchersPopUpViewState';
import VouchersPopUpViewProps from './VouchersPopUpViewProps';
import VouchersPopUpViewStyle from './VouchersPopUpViewStyle';
import VouchersPopUpViewModel from './VouchersPopUpViewModel';
import {TranslationView} from '@levelapp/softfabric-ui';
import { View } from 'react-native';
import Constants from '../../../../../../../Common/Constants/Constants';
import PopupHeaderView from '../../../../../../../Presentation/modules/dashboard/components/popupheader/PopupHeaderView';
import Helpers from '../../../../../../../Common/Helpers/Helpers';
import PopupMenuItemView from '../../../../../../../Presentation/modules/dashboard/components/popupmenuitem/PopupMenuItemView';
import MainInformationView from './components/maininformation/MainInformationView';
import CountriesView from './components/countries/CountriesView';
import DescriptionView from '../../../../../../../Presentation/modules/dashboard/components/description/DescriptionView';
import PicturesView from '../../../../../../../Presentation/modules/dashboard/components/pictures/PicturesView';
import GenericSettingsSectionView from '../../../../../../../Presentation/modules/dashboard/components/genericsettingssection/GenericSettingsSectionView';

export default class VouchersPopUpView extends ContentView<VouchersPopUpViewProps, VouchersPopUpViewState, VouchersPopUpViewModel, VouchersPopUpViewStyle>
{
  /* CONSTRUCTOR */
  constructor(props: VouchersPopUpViewProps) {
    super(props);

    // Binding
    this.bind(new VouchersPopUpViewModel(this), new VouchersPopUpViewStyle(this));
  }

  /* RENDERING */
  render() {
    return (
      <View style={{ minWidth: 600, minHeight: 850, width: Constants.PopUpWidth, maxWidth: "100%" }}>
        <PopupHeaderView
          handleBackPressed={() => {
            this.props.refreshParent()
            Helpers.closePopup()
          }}
          title="add.popup.title"
          shouldShowButton
          isLoading={this.state.isLoading}
          handleOnPressButton={this.binding.handleSavePressed}>
        </PopupHeaderView>

        <View style={{ display: "flex", flexDirection: "row", flexWrap: "wrap" }}>
          <PopupMenuItemView
            activeItem={this.state.tab}
            title="form.maininformation"
            item="main"
            handleChangeTab={() => this.binding.changeTab("main")}
          />
          <PopupMenuItemView
            activeItem={this.state.tab}
            title="form.description"
            item="desc"
            handleChangeTab={() => this.binding.changeTab("desc")}
          />
          <PopupMenuItemView
            activeItem={this.state.tab}
            title="form.images"
            item="pictures"
            handleChangeTab={() => this.binding.changeTab("pictures")}
          />
          {!Constants.IsCountryUnique && this.state.countries.length > 1 &&
            (
              <PopupMenuItemView
                activeItem={this.state.tab}
                title="form.countries"
                item="countries"
                handleChangeTab={() => this.binding.changeTab("countries")}
              />
            )
          }
          {this.props.contentTypeKeys.length > 0 && (
            <PopupMenuItemView
              activeItem={this.state.tab}
              title="form.settings"
              item="settings"
              handleChangeTab={() => this.binding.changeTab("settings")}
            />
          )}
        </View>

        {this.state.tab === "main" &&
          <MainInformationView
          languages={this.state.languages}
          voucher={this.state.voucher}
          shouldShowError={this.state.showErrors}
          handleDateChange={this.binding._dispatcherHelper.handleDateChange}
          handleValueChange={this.binding._dispatcherHelper.handleValueChange}
          handleTranslationsChange={this.binding._dispatcherHelper.handleTranslationsChange}
          handleCheckboxChange={this.binding._dispatcherHelper.handleCheckboxChange}
          handleTypeChange={this.binding.handleTypeChange}
          handlePrizeTypeChange={this.binding.handlePrizeTypeChange}
          handleTriggerChange={this.binding.handleTriggerChange}
          />
        }

        {this.state.tab === "desc" &&
          <DescriptionView
            listDesc={this.state.voucher.descriptionTranslations}
            languages={this.state.languages}
            shouldShowError={this.state.showErrors}
            handleTranslationsChange={this.binding._dispatcherHelper.handleTranslationsChange}
          />
        }
        {this.state.tab === "pictures" &&
          <PicturesView
            languages={this.state.languages}
            listImage={this.state.voucher.imageTranslation}
            listThumbnail={this.state.voucher.thumbnailTranslation}
            handleOnComplete={this.binding._dispatcherHelper.handleAnyChange}
            handleOnPressImage={this.binding._dispatcherHelper.handleNumberChange}
            handleCheckboxChange={this.binding._dispatcherHelper.handleCheckboxChange}
            shouldUseDefaultImage={this.state.useDefaultImageChecked}
            shouldUseDefaultThumnail={this.state.useDefaultThumbnailChecked}
          />
        }
        {this.state.tab === "countries" &&
          <CountriesView
            voucher={this.state.voucher}
            countries={this.state.countries}
            handleValueChange={this.binding._dispatcherHelper.handleValueChange}
          />
        }
        
        {this.state.tab === "settings" &&
          <GenericSettingsSectionView
            onKeyValueChange={this.binding._dispatcherHelper.handleAnyChange}
            contentTypeKeys={this.binding.getContentTypeKeys()}
            contentTypeKeyValues={this.props.voucher.contentTypeKeyValue} />
        }

        {this.state.showErrors && (
          <View style={this.style.footer}>
            <TranslationView style={{ color: "red" }}>
              error.please.fields
            </TranslationView>
          </View>
        )}
      </View >
    );
  }
}