 
import {ContentShellView} from '@levelapp/softfabric';
import ShellLanguageViewState from './ShellLanguageViewState';
import ShellLanguageViewProps from './ShellLanguageViewProps';
import ShellLanguageViewStyle from './ShellLanguageViewStyle';
import ShellLanguageViewModel from './ShellLanguageViewModel';
import {View} from 'react-native';

export default class ShellLanguageView extends ContentShellView<ShellLanguageViewProps, ShellLanguageViewState, ShellLanguageViewModel, ShellLanguageViewStyle>
{
  /* CONSTRUCTOR */
  constructor(props:ShellLanguageViewProps)
  {
    super(props);

    // Binding
    this.bind(new ShellLanguageViewModel(this), new ShellLanguageViewStyle(this));
  }

  /* RENDERING */
  render()
  {
    return (
      <View style={{flex : 1}}>
        {this.getSwitch()}
      </View>
    );
  }
}