import {ContentViewStyle} from '@levelapp/softfabric';
import {StyleProp,ViewStyle, TextStyle} from 'react-native';

export default class MessageBlockViewStyle extends ContentViewStyle
{
    page: StyleProp<ViewStyle> = {
        flex: 1
    }

    inputTitle: StyleProp<TextStyle> = {
        fontSize: 14,
        fontWeight: '600',
        color: '#3F3F3F',
        fontFamily: 'Montserrat SemiBold',
        marginBottom: 13,
    };


    errorInput: StyleProp<ViewStyle> = {
        borderColor: 'red',
        borderWidth: 1,
        width: '50%',
        marginBottom: 13,
        paddingHorizontal: 15,
        borderRadius: 8,
    }

    input: StyleProp<any> = {
        marginBottom: 13,
        paddingHorizontal: 15,
        borderRadius: 8,
        width: '50%',
        boxShadow: '0 5px 14px 0 rgba(0,0,0,0.1)',
    };

    errorTextArea: StyleProp<ViewStyle> = {
        borderColor: 'red',
        borderWidth: 1,
        width: '100%',
        minWidth: 475,
        height: 92,
        paddingHorizontal: 5,
        borderRadius: 8,
        
    };

    textArea: StyleProp<any> = {
        width: '100%',
        minWidth: 475,
        height: 92,
        paddingHorizontal: 5,
        borderRadius: 8,
        boxShadow: '0 5px 14px 0 rgba(0,0,0,0.1)',
    };

    container : StyleProp<ViewStyle> =
    {
        paddingVertical : 10,
    }
}