import {ContentViewModel,ContentView} from '@levelapp/softfabric';
import SelectionViewState from './SelectionViewState';
import SelectionViewProps from './SelectionViewProps';

export default class SelectionViewModel extends ContentViewModel<SelectionViewState, SelectionViewProps>
{
    interval: any;

      /* CONSTRUCTOR */
    constructor(view: ContentView)
    {
        super(view);

        this.initialState(
            {
                activeSelection : this.props().defaultObjects,
                searchText : '',
                page : 1,
            }); // Initialize your state here

        this.interval = null;
        // Bindings
        this.changePage = this.changePage.bind(this);
        this.onPressSave = this.onPressSave.bind(this);
        this.handleCloseResearching = this.handleCloseResearching.bind(this);
        this.handleOnChangeValue = this.handleOnChangeValue.bind(this);

    }

    handleOnChangeValue(value : any)
    {
        var state = this.state();
        var found = state.activeSelection.findIndex(x => x.id == value.id);
        if(found != -1)
        {
            state.activeSelection.splice(found,1);
        }else
        {
            state.activeSelection.push(value);
        }
        this.setField({state});
    }

    changePage(nextPage: number) {
        this.setField({ page: nextPage + 1 });
    }


    onPressSave()
    {
        this.props().handleSave(this.state().activeSelection);
        this.props().handleClose();
    }

    handleResearching(searchText: string) {
        this.setField({ isLoading: true, isResearching: true , searchText : searchText});

        if (this.interval) {
            clearInterval(this.interval);
        }
        // this.interval = setTimeout(() => {
        //     this.refresh();
        //     // this.onPageChange(0)
        // }, 1000)
    }

    handleCloseResearching() {
        this.setField({searchText : '' , page : 1});
    }
}