 
import { TouchableOpacity, View } from 'react-native';
import { ContentView } from '@levelapp/softfabric';
import { TranslationView } from '@levelapp/softfabric-ui';

import FiltersViewState from './FiltersViewState';
import FiltersViewProps from './FiltersViewProps';
import FiltersViewStyle from './FiltersViewStyle';
import FiltersViewModel from './FiltersViewModel';

export default class FiltersView extends ContentView<FiltersViewProps, FiltersViewState, FiltersViewModel, FiltersViewStyle>
{
  /* CONSTRUCTOR */
  constructor(props: FiltersViewProps) {
    super(props);

    // Binding
    this.bind(new FiltersViewModel(this), new FiltersViewStyle(this));
  }

  /* RENDERING */
  render() {
    const { style, props } = this;
    const { selectedFilter, setSelectedFilter } = props;

    return (
      <View style={style.container}>
        <TranslationView
          upperCase
          style={[style.title, { color: '#737373' }]}
        >
          tools.products.filter.title
        </TranslationView>
        <View style={style.filters}>
          <TouchableOpacity
            onPress={() => setSelectedFilter('availability')}
            style={[
              style.buttonLeft,
              style.button,
              selectedFilter === 'availability' && style.selected]}
          >
            <TranslationView style={style.text}>
              tools.products.filter.availability
            </TranslationView>
          </TouchableOpacity>
          <TouchableOpacity
            onPress={() => setSelectedFilter('price')}
            style={[
              style.buttonRight,
              style.button,
              selectedFilter === 'price' && style.selected]}
          >
            <TranslationView style={style.text}>
              tools.products.filter.price
            </TranslationView>
          </TouchableOpacity>
        </View>
      </View>
    );
  }
}