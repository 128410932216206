import {ContentViewModel,ContentView, LanguageManager, Application, Managers} from '@levelapp/softfabric';
import CheckBoxHeaderSelectionViewState from './CheckBoxHeaderSelectionViewState';
import CheckBoxHeaderSelectionViewProps from './CheckBoxHeaderSelectionViewProps';
import TranslationValue from '../../../../../Common/DTO/TranslationValue';

export default class CheckBoxHeaderSelectionViewModel extends ContentViewModel<CheckBoxHeaderSelectionViewState, CheckBoxHeaderSelectionViewProps>
{
    languageManager : LanguageManager;

      /* CONSTRUCTOR */
    constructor(view: ContentView)
    {
        super(view);

        this.initialState({}); // Initialize your state here

        // Bindings

        this.languageManager = Application.current.resolve<LanguageManager>(Managers.LANGUAGE_MANAGER);
    }


    isChecked(item : any)
    {
        if(this.props().items == null)
        {
            return false;
        }
        return this.props().items.find((element: any) => element.allergenId == item.id) == null ? false : true 
    }

    handleCheckboxAllergenChange(value : boolean , item : any)
    {
        this.props().handleCheckBoxChanged(value,item);
    }

    handleTranslations(item : any)
    {
        if(item.titleTranslations.length === 0) return "No Translation";

        const translation = item.titleTranslations.find(
            (element: TranslationValue) => 
            {
                  return ( element.language.key == this.languageManager.language);
            }
          );
        
        if(translation !== undefined) return translation.value;
          
        return item.titleTranslations[0].value;
    }
}