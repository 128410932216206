import {ContentViewModel,ContentView} from '@levelapp/softfabric';
import GenericLogsViewState from './GenericLogsViewState';
import GenericLogsViewProps from './GenericLogsViewProps';
import {updatedDiff}  from 'deep-object-diff';
import EntityLog from '../../../../../Common/DTO/EntityLog';


export default class GenericLogsViewModel extends ContentViewModel<GenericLogsViewState, GenericLogsViewProps>
{
    _propertiesModified : string[] = []
      /* CONSTRUCTOR */
    constructor(view: ContentView)
    {
        super(view);

        

        this.initialState({
            currentValue : "",
            originalValue : "",
            currentVersionLogs : "",
            showLogs : false,
            position : 0,
            propertiesModifiedName : [],
            propertySelected : "",
            timeLineLogs : [],
        }); // Initialize your state here

        // Bindings
        this.handleMoreDetails = this.handleMoreDetails.bind(this);
        this.getPropertyNameModified = this.getPropertyNameModified.bind(this);
        this.handlePropertySelect = this.handlePropertySelect.bind(this);
        this.getColorLabel = this.getColorLabel.bind(this)

    }

    componentDidMount(){  
        this.refresh()
    }

    refresh(){
        let propertiesModifiedName : string[] = [];
        this.props().timeLineLogs.map((log : any)=>{
            let updateDiff : any = updatedDiff( log.originalValue,log.currentValue);
            this._propertiesModified.push(Object.keys(updateDiff)[0])
            propertiesModifiedName.indexOf(Object.keys(updateDiff)[0]) === -1 && propertiesModifiedName.push(Object.keys(updateDiff)[0]) ;
        })
        propertiesModifiedName.sort();
        this.handlePropertySelect(propertiesModifiedName[0])
        this.setField({
            originalValue : "", 
            currentValue : "",
            propertiesModifiedName : propertiesModifiedName, 
            propertySelected : propertiesModifiedName[0],
        })
    }

    
    
    getPropertyNameModified(log : any) : any{
        let updateDiff : any = updatedDiff( log.originalValue,log.currentValue);
        return updateDiff
    }

    getColorLabel(label : string) : string {
        label = label.toString().toLocaleLowerCase();
        let color = ""
        switch(label){
            case "true" : 
                color = "green";
                break;
            case "false" : 
                color = "red";
                break;
            default :
                color = "blue"
        }
        return color;
    }

    handlePropertySelect(propertyChangedName : string){
        let timeLineLogs : EntityLog[] = []
        this._propertiesModified.map((property : string, index : number)=>{
            if(property === propertyChangedName){
                timeLineLogs.push(this.props().timeLineLogs[index])
            }
        })  
        this.setField({timeLineLogs : timeLineLogs,propertySelected :propertyChangedName })
    }

    handleMoreDetails(version : string){
        if(this.state().currentVersionLogs === version){
            this.setField({showLogs : false,currentVersionLogs: ""})
        }else{
            let storeLog  = this.props().detailsLogs ? this.props().detailsLogs?.find(x=>x.version ===version) : this.props().timeLineLogs.find(x=>x.version ===version)
            this.setField({originalValue : storeLog?.originalValue, currentValue : storeLog?.currentValue, currentVersionLogs : version, showLogs : true, currentLog : storeLog})
        }   
    }
}