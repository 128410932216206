
import { View } from 'react-native';
import { ContentView } from '@levelapp/softfabric';
import MainInformationViewState from './MainInformationViewState';
import MainInformationViewProps from './MainInformationViewProps';
import MainInformationViewStyle from './MainInformationViewStyle';
import MainInformationViewModel from './MainInformationViewModel';
import LineView from '../../../../../../components/line/LineView';
import ExtendedTextInputView from '../../../../../../components/extendedtextinput/ExtendedTextInputView';
import ExtendedCheckBoxView from '../../../../../../components/extendedcheckbox/ExtendedCheckBoxView';
import ExtendedDatePickerView from '../../../../../../components/extendeddatepicker/ExtendedDatePickerView';
import ExtendedBlockTextInputView from '../../../../../../components/extendedblocktextinput/ExtendedBlockTextInputView';
import ExtendedTranslationView from '../../../../../../components/extendedtranslation/ExtendedTranslationView';
import Helpers from '../../../../../../../../../Common/Helpers/Helpers';
import Language from '../../../../../../../../../Common/DTO/Language';
import Constants from '../../../../../../../../../Common/Constants/Constants';
import ExtendedDropDownView from '../../../../../../components/extendeddropdown/ExtendedDropDownView';
import DurationUnitHelper from '../../../../../../../../../Common/Helpers/DurationUnitHelper';
import SectionTitleView from '../../../../../../components/sectiontitle/SectionTitleView';
import DropDown from 'src/Common/Components/Dropdown/DropDownView';
import Select from "react-dropdown-select";
import MembershipType from 'src/Common/DTO/MembershipType';
import CouponMembership from 'src/Common/DTO/CouponMembership';
import TranslationView from '@levelapp/softfabric-ui/components/Both/Translation/TranslationView';
import SliderView from 'src/Common/Components/slider/SliderView';

export default class MainInformationView extends ContentView<MainInformationViewProps, MainInformationViewState, MainInformationViewModel, MainInformationViewStyle>
{
  /* CONSTRUCTOR */
  constructor(props: MainInformationViewProps) {
    super(props);

    // Binding
    this.bind(new MainInformationViewModel(this), new MainInformationViewStyle(this));
  }

  /* RENDERING */
  render() {

    const { container, leftblock, centerblock, rightblock } = this.style;


    return (
      <View style={container}>

        <SectionTitleView title={'form.informations'} />
        {/* UpperSide */}
        <View style={{ flexDirection: "row", zIndex: 3 }}>
          {/* Id side */}
          <View style={{ zIndex: 1 }}>
            <View style={leftblock}>
              <ExtendedTextInputView
                title={"form.id"}
                disable
                showError={false}
                value={`${this.props.coupon.id == undefined ? ' ' : this.props.coupon.id}`}
                onChangeText={() => { }}
              />
              <ExtendedTextInputView
                title={"form.externalid"}
                showError={false}
                infoText={this.binding.translate('generic.externalid.info')}
                style={{ zIndex: 2 }}
                value={this.props.coupon.thridPartId}
                onChangeText={(text: string) => this.props.handleValueChange('tpid', text)}
              />
              <ExtendedTextInputView
                title={"form.posid"}
                style={{ zIndex: 1 }}
                infoText={this.binding.translate('generic.posid.info').replace('{1}', this.binding.translate('coupons.title.singular').toLowerCase())}
                showError={this.props.shouldShowError && (this.props.coupon.posId == null) ? true : false}
                value={`${this.props.coupon.posId}`}
                onChangeText={(text: string) => this.props.handleValueChange('posid', text)}
              />
              {Constants.UseSettingsContent && <ExtendedDropDownView
                title={'CouponType'}
                values={this.props.couponTypes}
                selectedValue={this.props.coupon.couponType}
                onValueChange={(value: any) => this.props.handleDropDownChange(value)}
              />}

            </View>
            {/* DatePicker center container */}
            <View style={centerblock}>

              <ExtendedTextInputView
                title={"form.index"}
                infoText={this.binding.translate('generic.orderindex.info').replace('{1}', this.binding.translate('coupons.title.singular').toLowerCase())}
                showError={this.props.shouldShowError && (this.props.coupon.orderIndex == null) ? true : false}
                value={`${this.props.coupon.orderIndex}`}
                onChangeText={(text: string) => this.props.handleValueChange('orderindex', text)}
              />

              <View style={{ flexDirection: 'row', zIndex: 1 }}>
                <ExtendedDatePickerView
                  zIndex={2}
                  title={'form.startdate'}
                  selected={this.props.coupon.StartDateInput}
                  placeholder={this.binding.translate('form.startdate.placeholder')}
                  handleOnChange={(date: Date) => this.binding.handleOnChangeDate('startdate', date)}
                />

                <ExtendedDatePickerView
                  zIndex={1}
                  title={'form.enddate'}
                  selected={this.props.coupon.EndDateInput}
                  ref={(r: any) => this.binding._refEndDate = r}
                  placeholder={this.binding.translate('form.enddate.placeholder')}
                  handleOnChange={(date: Date) => this.props.handleDateChange('enddate', date)}
                />
              </View>
            </View>
          </View>

          <View style={rightblock}>
            {/* Checkbox side */}
            <View style={{ flex: 1, flexDirection: 'column', width: "75%" }}>
              <ExtendedTranslationView uppercase>
                form.options
              </ExtendedTranslationView>
              <ExtendedCheckBoxView isTranslation title="form.preview" value={this.props.coupon.isPreview} onValueChange={(value: boolean) => this.props.handleCheckboxChange('isPreviewVisible', value)} />
              <ExtendedCheckBoxView isTranslation title="form.anonymous" value={this.props.coupon.isAnonymousVisible} onValueChange={(value: boolean) => this.props.handleCheckboxChange('isAnonymousVisible', value)} />
              <ExtendedCheckBoxView isTranslation title="form.marketingAutomation" value={this.props.coupon.isMarketingAutomation} onValueChange={(value: boolean) => this.props.handleCheckboxChange('isMarketAutomation', value)} />
              <ExtendedCheckBoxView isTranslation title="form.renewable" value={this.props.coupon.isRenewable} onValueChange={(value: boolean) => this.props.handleCheckboxChange('isRenewable', value)} />
              {this.props.coupon.isRenewable &&
                <View style={{ marginLeft: 50 }}>
                  <ExtendedCheckBoxView isTranslation title="form.renewableCooldown" value={this.props.isRenewableWithCooldown} onValueChange={(value: boolean) => this.props.handleCheckboxChange('isRenewableWithCooldown', value)} />
                  {this.props.isRenewableWithCooldown &&
                    <View>
                      <View style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginBottom: 10 }}>
                        <TranslationView style={[this.style.inputTitle, { marginBottom: 0 }]} upperCase>form.durationIn</TranslationView>
                        <DropDown
                          values={this.props.units.map(u => { return { ...u, title: u.label } })}
                          selectedValue={{ ...this.props.units.find(u => u.id === this.props.coupon.loyaltyRulesUnit?.id), title: this.props.coupon.loyaltyRulesUnit?.label }}
                          onValueChange={(value: any) => {
                            const unitFound = this.props.units.find(u => u.id === value.id)
                            if (unitFound) this.props.handleRenewableUnitChange(unitFound)
                          }} />
                        <TranslationView style={[this.style.inputTitle, { marginBottom: 0 }]} upperCase>form.ofRenewability</TranslationView>
                      </View>
                      <SliderView
                        minValue={this.props.coupon.loyaltyRulesUnit?.minValue || 1}
                        maxValue={this.props.coupon.loyaltyRulesUnit?.maxValue || 1}
                        currentValue={(this.props.coupon.loyaltyRulesUnitValue) ? this.props.coupon.loyaltyRulesUnitValue : this.props.coupon.loyaltyRulesUnit?.defaultValue || 1}
                        handleChange={(value: number) => { this.props.handleRenewableValueChange(value) }}
                        backgroundColor={Constants.GradiantStartColor} />
                    </View>
                  }
                </View>
              }
              <ExtendedCheckBoxView isTranslation title="form.isMultipleScan" value={this.props.coupon.isMultipleScan} onValueChange={(value: boolean) => this.props.handleCheckboxChange('isMultipleScan', value)} />
              <ExtendedCheckBoxView isTranslation title="form.isExclusive" value={this.props.coupon.isExclusive} onValueChange={(value: boolean) => this.props.handleCheckboxChange('isExclusive', value)} />

              {this.props.type == 'everyday' && <ExtendedCheckBoxView isTranslation disable title="form.everydayvalue" value={true} onValueChange={() => { }} />}
              {Constants.hasCouponDuration &&
                <ExtendedCheckBoxView isTranslation title={"form.hasduration"} value={this.props.coupon.hasDuration} onValueChange={(value: boolean) => this.props.handleCheckboxChange('hasDuration', value)} />

              }

              <ExtendedTranslationView uppercase>
                form.membership.options
              </ExtendedTranslationView>
              {this.props.membershipTypes.map((membershipType: MembershipType) => {
                return (
                  <ExtendedCheckBoxView isTranslation={false} title={membershipType.key} value={this.props.coupon.couponMemberships.filter((couponMembership: CouponMembership) => couponMembership.membershipTypeId === membershipType.id).length > 0} onValueChange={(value: boolean) => this.props.handleMembershipChange(membershipType.id, value)} />
                )
              })}
            </View>
          </View>

        </View>
        {this.props.coupon.hasDuration &&
          <View style={{ flexDirection: 'row', paddingTop: 30 }}>
            <ExtendedTextInputView
              title={"form.validityduration"}
              showError={this.props.shouldShowError && (this.props.coupon.orderIndex == null) ? true : false}
              value={`${this.props.coupon.validityDuration}`}
              onChangeText={(text: string) => this.props.handleValueChange('validityduration', text)}
            />
            <ExtendedDropDownView
              title={"form.durationunit"}
              values={DurationUnitHelper.getDurationUnit()}
              selectedValue={DurationUnitHelper.getSelectedUnit(this.props.coupon.validityDurationUnit)}
              onValueChange={(value: any) => this.props.handleValueChange('durationunit', value.value)}
            />
          </View>
        }
        {/* Middle line */}
        {Constants.HasClickAndCollect && Constants.ShowClickAndCollectForCoupons && <View style={{ zIndex: 2 }}>
          <LineView />
          <SectionTitleView title={'form.clickandcollect'} />
          <View style={{ zIndex: 2, flexDirection: 'column', justifyContent: 'flex-start' }}>
            <Select
              placeholder={this.binding.translate('form.freeProducts')}
              color={this.state.color}
              values={this.state.selectedFreeProducts}
              options={this.props.products}
              onChange={(value: any) => this.props.handleValueChange('freeProductIds', value)}
              multi={true}
              create={false}
              keepSelectedInList={false}
              clearable={true}
              valueField={"id"}
              labelField={"title"}
              searchBy={"title"}

            />
            <Select
              placeholder={this.binding.translate('form.requiredProducts')}
              color={this.state.color}
              values={this.state.selectedRequiredProducts}
              options={this.props.products}
              onChange={(value: any) => this.props.handleValueChange('requiredProductIds', value)}
              multi={true}
              create={false}
              keepSelectedInList={false}
              clearable={true}
              labelField={"title"}
              valueField={"id"}
              searchBy={"title"}
            />
            <ExtendedTextInputView
              title={"form.discount"}
              showError={false}
              value={this.props.coupon.discountValue}
              onChangeText={(text: string) => this.props.handleValueChange('value', text)}
            />
            <ExtendedTextInputView
              title={"form.minimum"}
              showError={false}
              value={this.props.coupon.miniumValue}
              onChangeText={(text: string) => this.props.handleValueChange('valueMinimum', text)}
            />
          </View>
        </View>
        }

        {/* Bottom line */}
        <LineView />

        <SectionTitleView title={'form.titlefield'} />
        <View style={{ zIndex: -1, display: 'flex', flexDirection: 'row', flexWrap: 'wrap', justifyContent: 'space-between' }}>
          {/* Title */}
          {this.props.languages.map((content: Language) => {
            var _text = Helpers.getTranslationValueByLanguageKey(this.props.coupon.titleTranslations, content.key);
            return (
              <ExtendedBlockTextInputView
                title={content.key.toUpperCase()}
                showError={this.props.shouldShowError && (_text == null || _text == "") ? true : false}
                value={_text}
                onChangeText={(text: string) => this.props.handleTranslationsChange('title', text, content.id as number)}
              />

            );
          })}
        </View>
      </View>
    );
  }
}