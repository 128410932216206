import {ContentViewModel,ContentView} from '@levelapp/softfabric';
import ExtendedDatePickerViewState from './ExtendedDatePickerViewState';
import ExtendedDatePickerViewProps from './ExtendedDatePickerViewProps';

export default class ExtendedDatePickerViewModel extends ContentViewModel<ExtendedDatePickerViewState, ExtendedDatePickerViewProps>
{
      /* CONSTRUCTOR */
    constructor(view: ContentView)
    {
        super(view);

        this.initialState({}); // Initialize your state here

        // Bindings

    }
}