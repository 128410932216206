import {ContentViewStyle} from '@levelapp/softfabric';
import {StyleProp,ViewStyle} from 'react-native';

export default class OverviewViewStyle extends ContentViewStyle
{
    page: StyleProp<ViewStyle> = {
        flex: 1
    }

    sticky : StyleProp<any> =
    {
        position: 'sticky' , 
        right : 0 , top : 40,
        height : '10%',
        width : '20%' ,  
        justifyContent : 'flex-start' , 
        alignItems : 'center'
    }
}