 
import { TouchableOpacity, View } from 'react-native';

import { ContentView } from '@levelapp/softfabric';
import { LottieView, TranslationView } from '@levelapp/softfabric-ui';
import Assets from 'src/Common/Assets';

import HeaderViewState from './HeaderViewState';
import HeaderViewProps from './HeaderViewProps';
import HeaderViewStyle from './HeaderViewStyle';
import HeaderViewModel from './HeaderViewModel';

export default class HeaderView extends ContentView<HeaderViewProps, HeaderViewState, HeaderViewModel, HeaderViewStyle>
{
  /* CONSTRUCTOR */
  constructor(props: HeaderViewProps) {
    super(props);

    // Binding
    this.bind(new HeaderViewModel(this), new HeaderViewStyle(this));
  }

  /* RENDERING */
  render() {
    const { disabled, onSaveClick, title, buttonIsLoading } = this.props;

    return (
      <View style={this.style.header}>
        <TranslationView style={this.style.title}>
          {title}
        </TranslationView>
        <TouchableOpacity
          onPress={onSaveClick}
          style={[this.style.saveButton, (!disabled || buttonIsLoading) && this.style.disabledButton]}
          disabled={!disabled || buttonIsLoading}
        >
          {!buttonIsLoading && (
            <TranslationView style={{ color: 'white' }}>
              tools.products.tabs.priceSaveButton
            </TranslationView>
          )}
          {buttonIsLoading && (
            <LottieView
              height={25}
              width={40}
              source={Assets.buttonLoader}
            />
          )}
        </TouchableOpacity>
      </View>
    );
  }
}