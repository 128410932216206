 
import {ContentView} from '@levelapp/softfabric';
import DetailCompositionViewState from './DetailCompositionViewState';
import DetailCompositionViewProps from './DetailCompositionViewProps';
import DetailCompositionViewStyle from './DetailCompositionViewStyle';
import DetailCompositionViewModel from './DetailCompositionViewModel';
import { View,  Image, Text } from 'react-native';
import { TranslationView } from '@levelapp/softfabric-ui';

import Helpers from '../../../../../../../../../Common/Helpers/Helpers';
import ExtendedTranslationView from '../../../../../../components/extendedtranslation/ExtendedTranslationView';
import ExtendedCheckBoxView from '../../../../../../components/extendedcheckbox/ExtendedCheckBoxView';
import PopupHeaderView from '../../../../../../components/popupheader/PopupHeaderView';
import ExtendedTextInputView from '../../../../../../components/extendedtextinput/ExtendedTextInputView';


export default class DetailCompositionView extends ContentView<DetailCompositionViewProps, DetailCompositionViewState, DetailCompositionViewModel, DetailCompositionViewStyle>
{
  /* CONSTRUCTOR */
  constructor(props:DetailCompositionViewProps)
  {
    super(props);

    // Binding
    this.bind(new DetailCompositionViewModel(this), new DetailCompositionViewStyle(this));
  }

  /* RENDERING */
  render()
  {
    return(
          <View style={this.style.popup}>

           
            <PopupHeaderView
              handleOnPressButton={this.binding.handleSavePressed}
              handleBackPressed={Helpers.closePopup}
              title={'products.popup.title'}
              shouldShowButton
            />
            <Text style={{padding:20,fontSize: 36,fontWeight: "bold"}}> {this.state.node.node.composition.childProduct.titleTranslations[0].value}</Text>
            <View style={{ flexDirection: "row"}}>
              {this.state.node.node.composition.childProduct.imageTranslations != null && this.state.node.node.composition.childProduct.imageTranslations.length > 0 &&  <Image style={{width:350,minHeight:350,margin:20}} source={this.state.node.node.composition.childProduct.imageTranslations[0].url}></Image>}

              <View style={{flexDirection:'column',justifyContent: 'center' }}>
              <TranslationView style={{padding:20,fontSize: 25,fontWeight: "bold"}} >form.description</TranslationView>

              {this.state.node.node.composition.childProduct.descriptionTranslations != null && this.state.node.node.composition.childProduct.descriptionTranslations.length > 0 &&<Text style={{marginBottom:25,padding:10,fontSize: 18,fontStyle: 'italic'}}> {this.state.node.node.composition.childProduct.descriptionTranslations[0].value}</Text> }


                  <View style={{ flexDirection:'row',justifyContent: 'space-around' }}>
                    <View style={{justifyContent: 'space-around',margin:20 }}>
                      <ExtendedTextInputView
                        onChangeText={(text: string) => this.binding._dispatcherManager.handleValueChange('quantityMin',text)}
                        title={'menuconfigurator.popup.detailcomposition.form.quantitymin'}
                        value={this.state.composition.quantityMin}
                        showError={this.state.showErrors && (this.state.composition.quantityMin == null || this.state.composition.quantityMin == "")}
                      />
                    </View>
                    <View style={{ justifyContent: 'space-around',margin:20 }}>
                      <ExtendedTextInputView
                        onChangeText={(text: string) => this.binding._dispatcherManager.handleValueChange('quantityMax',text)}
                        title={'menuconfigurator.popup.detailcomposition.form.quantitymax'}
                        value={this.state.composition.quantityMax}
                        showError={this.state.showErrors && (this.state.composition.quantityMax == null || this.state.composition.quantityMax == "")}
                      />

                    </View>
                  </View>
                  <View style={{ flexDirection:'row',justifyContent: 'space-around' }}>
                      <View style={{ flex: 1,  flexDirection: 'column',margin:20 }}>
                      <ExtendedTranslationView >
                        form.options
                      </ExtendedTranslationView>
                      <ExtendedCheckBoxView isTranslation title={"menuconfigurator.popup.detailcomposition.form.ismandatory"} value={this.state.composition.isMandatory} onValueChange={(value : boolean) => {this.binding._dispatcherManager.handleCheckboxChange('isMandatory',value)}}/>
                    </View>
                  </View>
                </View>
            </View>
      </View>
    );
  }
}