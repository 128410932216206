import {ContentViewStyle} from '@levelapp/softfabric';
import {StyleProp,ViewStyle, TextStyle} from 'react-native';

export default class PopupConfirmationViewStyle extends ContentViewStyle
{
    container: StyleProp<ViewStyle> = {
        position: 'absolute', 
        width: '100%', 
        height: '100%', 
        zIndex: 500, 
        justifyContent: 'center', 
        alignItems: 'center'
    }

    background : StyleProp<ViewStyle> =
    {
        width: '100%', 
        height: '100%', 
        opacity: 0.5, 
        backgroundColor: 'black'
    }

    box : StyleProp<ViewStyle> =
    {
        position: 'absolute', 
        width: 600, 
        height: 350, 
        backgroundColor: 'white', 
        zIndex: 600, 
        borderRadius : 8

    }

    titlePopup: StyleProp<TextStyle> = {
        textAlign: 'center',
        color: '#4A495C',
        fontSize: 21,
        fontWeight: '600',
        marginTop: 100
    };

    contentPopup: StyleProp<TextStyle> = {
        textAlign: 'center',
        color: '#4A495C',
        fontSize: 21,
        fontWeight: '600',
    };

    noButtonText: StyleProp<TextStyle> = {
        color: '#9C9C9C',
    };

    footer: StyleProp<ViewStyle> = {
        display: 'flex',
        flexDirection: 'row',
        margin: 'auto',
        marginTop:75,
        marginBottom:100
    };

    noButton: StyleProp<ViewStyle> =
    {       
        height: 42,
        width: 119,
        marginRight : '5px',
        justifyContent : 'center',
        alignItems : 'center'
    }  

   yesButton: StyleProp<ViewStyle> =
    {       
        height: 42,
        width: 119,
        marginLeft : '5px',
        justifyContent : 'center',
        alignItems : 'center'
    } 
}