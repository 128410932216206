import {ContentViewModel,ContentView} from '@levelapp/softfabric';
import LineViewState from './LineViewState';
import LineViewProps from './LineViewProps';

export default class LineViewModel extends ContentViewModel<LineViewState, LineViewProps>
{
      /* CONSTRUCTOR */
    constructor(view: ContentView)
    {
        super(view);

        this.initialState({}); // Initialize your state here

        // Bindings

    }
}