import { Service, Application, HttpManager, Managers } from '@levelapp/softfabric';

import PriceHistory from 'src/Common/DTO/PriceHistory';

export default class PriceHistoryService extends Service {
  httpClient = Application.current.resolve<HttpManager>(Managers.HTTP_MANAGER);

  async get(productId: number): Promise<PriceHistory[]> {
    const response = await this.httpClient.get(`priceHistory/products/${productId}`);

    if (response && response.data) {
      return response.data.data;
    }

    return [];
  }
}