import { Service, HttpManager, Managers, Application } from "@levelapp/softfabric"

export default class UserMetadataUnitsService extends Service{
    
    httpClient: HttpManager;

    constructor(){
        super();
        this.httpClient = Application.current.resolve(Managers.HTTP_MANAGER);
        this.httpClient.handleSomethingWentWrong = (response:any) => {
            console.warn("Erreur : "+response);
        }
    }

    get(callBack:Function){
        this.httpClient
            .get('UserMetadataUnits')
            .then((response: any) => 
            {
                if (response)
                {
                    if (response.data)
                    {
                        callBack(response.data.data);
                    }
                }
            });
    }

    getStatus(callBack:Function){
        let statusTemp: any[] = [
            { key: "1", value: "Étudiant", },
            { key: "2", value: "Professionnel", },
            { key: "3", value: "Parent avec enfant(s) + 12", },
            { key: "4", value: "Parent avec enfant(s) - 12", }
        ]
        callBack(statusTemp);
    }
    getCountries(callBack:Function){

        let countriesTemp: any[] = [
            { key: "BE", value: "Belgique" },
            { key: "LU", value: "Luxembourg" },
            { key: "Autre", value: "Autre"}
        ]
        callBack(countriesTemp);
    }

    getGenders(callBack:Function){
        let gendersTemp: any[] = [
            { key: "M", value: "Masculin" },
            { key: "F", value: "Feminin" },
            { key: "N", value: "Neutre" }
        ]
        callBack(gendersTemp);
    }

}