import {ContentViewStyle, Application} from '@levelapp/softfabric';
import { StyleManager, UiManagers } from '@levelapp/softfabric-ui';
import { StyleProp, ViewStyle } from 'react-native';

export default class ActivationCodeViewStyle extends ContentViewStyle{
    MyStyleManager : StyleManager = Application.current.resolve<StyleManager>(UiManagers.STYLE_MANAGER);

    container: StyleProp<ViewStyle> = {
        display: 'flex',
        flex: 1,
        alignItems: 'center'
    }

    box: StyleProp<ViewStyle> = {
        width: '95%',
        backgroundColor: 'white',
        borderRadius: 6,
        marginTop: 40,
        shadowColor: "#0000006b",
        shadowOffset: {
            width: 1,
            height: 0,
        },
        shadowOpacity: 0.70,
        shadowRadius: 20.00,
        elevation: 60,
    }
    boxContainer: StyleProp<ViewStyle> = {
        display: 'flex',
        minHeight: 250,
        
        paddingTop: 30,
        paddingBottom: 30,
        paddingLeft: 50,
        paddingRight: 50,
    }
    generator: StyleProp<ViewStyle> = {
        display: 'flex',
        justifyContent: 'center'
    }
    button: StyleProp<ViewStyle> = {
        
        padding: 14,
        borderRadius: 15,
        width: 205
    }
    activationCode: StyleProp<ViewStyle> = {
        marginTop: 15,
        padding: 14,
        borderStyle: 'solid',
        borderWidth: 1,
        borderColor: this.MyStyleManager.primaryColor,
        borderRadius: 15,
        width: 150
    }


    



 
}