 
import {ContentView} from '@levelapp/softfabric';
import LineViewState from './LineViewState';
import LineViewProps from './LineViewProps';
import LineViewStyle from './LineViewStyle';
import LineViewModel from './LineViewModel';
import { View } from 'react-native';

export default class LineView extends ContentView<LineViewProps, LineViewState, LineViewModel, LineViewStyle>
{
  /* CONSTRUCTOR */
  constructor(props:LineViewProps)
  {
    super(props);

    // Binding
    this.bind(new LineViewModel(this), new LineViewStyle(this));
  }

  /* RENDERING */
  render()
  {
    const { container } = this.style;

    return (
      <View
          style={container}
      />
    );
  }
}