import {ContentViewStyle} from '@levelapp/softfabric';
import { StyleProp, ViewStyle } from 'react-native';

export default class SeparationLineViewStyle extends ContentViewStyle{
  line: StyleProp<ViewStyle> = {
    backgroundColor: '#D8D8D8', 
    width: '100%', 
    height: 1, 
    marginTop: 30, 
    marginBottom: 30
  }
}