 
import {ContentView} from '@levelapp/softfabric';
import MainInformationViewState from './MainInformationViewState';
import MainInformationViewProps from './MainInformationViewProps';
import MainInformationViewStyle from './MainInformationViewStyle';
import MainInformationViewModel from './MainInformationViewModel';
import { View } from 'react-native';
import SectionTitleView from '../../../../../../../../../Presentation/modules/dashboard/components/sectiontitle/SectionTitleView';
import ExtendedTextInputView from '../../../../../../../../../Presentation/modules/dashboard/components/extendedtextinput/ExtendedTextInputView';
import ExtendedDatePickerView from '../../../../../../../../../Presentation/modules/dashboard/components/extendeddatepicker/ExtendedDatePickerView';
import ExtendedTranslationView from '../../../../../../../../../Presentation/modules/dashboard/components/extendedtranslation/ExtendedTranslationView';
import ExtendedCheckBoxView from '../../../../../../../../../Presentation/modules/dashboard/components/extendedcheckbox/ExtendedCheckBoxView';
import LineView from '../../../../../../../../../Presentation/modules/dashboard/components/line/LineView';
import Language from '../../../../../../../../../Common/DTO/Language';
import ExtendedBlockTextInputView from '../../../../../../../../../Presentation/modules/dashboard/components/extendedblocktextinput/ExtendedBlockTextInputView';
import ExtendedDropDownView from '../../../../../../../../../Presentation/modules/dashboard/components/extendeddropdown/ExtendedDropDownView';

export default class MainInformationView extends ContentView<MainInformationViewProps, MainInformationViewState, MainInformationViewModel, MainInformationViewStyle>
{
  /* CONSTRUCTOR */
  constructor(props: MainInformationViewProps) {
    super(props);

    // Binding
    this.bind(new MainInformationViewModel(this), new MainInformationViewStyle(this));
  }

  /* RENDERING */
  render() {
    const { container } = this.style;
    return (
      <View style={container}>

        <SectionTitleView title={'form.informations'} />
        {/* UpperSide */}
        <View>
          {/* Id side */}
          <View style={{zIndex: 1}}>
            <View style= {{flexDirection: 'row', zIndex:1}}>
              <ExtendedTextInputView
                title={"form.id"}
                disable
                showError={false}
                value={`${this.props.voucher.id == undefined ? ' ' : this.props.voucher.id}`}
                onChangeText={() => { }}
              />

              <View style={{maxWidth: 200, flexDirection: 'row', zIndex:1}}>
                <ExtendedDatePickerView
                  zIndex={2}
                  title={'form.startdate'}
                  selected={this.props.voucher.StartDateInput}
                  placeholder={this.binding.translate('form.startdate.placeholder')}
                  handleOnChange={(date: Date) => this.binding.handleOnChangeDate('startdate', date)}
                />

                <ExtendedDatePickerView
                  zIndex={1}
                  title={'form.enddate'}
                  selected={this.props.voucher.EndDateInput}
                  ref={(r: any) => this.binding._refEndDate = r}
                  placeholder={this.binding.translate('form.enddate.placeholder')}
                  handleOnChange={(date: Date) => this.props.handleDateChange('enddate', date)}
                />
                <ExtendedDropDownView
                title={'type.select'}
                values={this.state.voucherTypes}
                selectedValue={this.state.voucherTypes.find(x => x.id === this.props.voucher.type.id)}
                onValueChange={(type: any) => this.props.handleTypeChange(type)}
                />
                <ExtendedDropDownView
                title={'prizeType.select'}
                values={this.state.prizeTypes}
                selectedValue={this.state.prizeTypes.find(x => x.id === this.props.voucher.prizeType.id)}
                onValueChange={(type: any) => this.props.handlePrizeTypeChange(type)}
                />
                <ExtendedDropDownView
                title={'triggers.select'}
                values={this.state.triggers}
                selectedValue={this.state.triggers.find(x => x.id === this.props.voucher.trigger.id)}
                onValueChange={(trigger: any) => this.props.handleTriggerChange(trigger)}
                />
              </View>

            </View>
          </View>

          <View>
            {/* Checkbox side */}
            <View>
              <ExtendedTranslationView uppercase>
                form.options
              </ExtendedTranslationView>
              <ExtendedCheckBoxView isTranslation title="form.preview" value={this.props.voucher.isPreview} onValueChange={(value: boolean) => this.props.handleCheckboxChange('isPreviewVisible', value)} />
              
              <ExtendedTextInputView
              style={{paddingTop: 10}}
              title={"form.duration"}
              showError={this.props.shouldShowError ? true : false}
              value={`${this.props.voucher.duration}`}
              onChangeText={(text: string) => this.props.handleValueChange('validityduration', text)}
            />
            </View>
          </View>

        {/* Bottom line */}
        <LineView />

        <SectionTitleView title={'form.titlefield'} />
        <View style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', justifyContent: 'space-between' }}>
          {this.props.languages.map((content: Language) => {
            var result = this.props.voucher.titleTranslations.find((x: any) => x.language.id == content.id)
            var _text = result ? result.value : "";
            return (
              <ExtendedBlockTextInputView
                value={_text}
                title={content.key.toUpperCase()}
                showError={this.props.shouldShowError && (_text == null || _text == "")}
                onChangeText={(text: string) => this.props.handleTranslationsChange('title', text, content.id as number)}
              />
            );
          })}
        </View>
      </View>
      </View>
    );
  }
}