 
import {ContentShellView} from '@levelapp/softfabric';
import ShellContentTypeKeyConfiguratorViewState from './ShellContentTypeKeyConfiguratorViewState';
import ShellContentTypeKeyConfiguratorViewProps from './ShellContentTypeKeyConfiguratorViewProps';
import ShellContentTypeKeyConfiguratorViewStyle from './ShellContentTypeKeyConfiguratorViewStyle';
import ShellContentTypeKeyConfiguratorViewModel from './ShellContentTypeKeyConfiguratorViewModel';
import {View} from 'react-native';

export default class ShellContentTypeKeyConfiguratorView extends ContentShellView<ShellContentTypeKeyConfiguratorViewProps, ShellContentTypeKeyConfiguratorViewState, ShellContentTypeKeyConfiguratorViewModel, ShellContentTypeKeyConfiguratorViewStyle>
{
  /* CONSTRUCTOR */
  constructor(props:ShellContentTypeKeyConfiguratorViewProps)
  {
    super(props);

    // Binding
    this.bind(new ShellContentTypeKeyConfiguratorViewModel(this), new ShellContentTypeKeyConfiguratorViewStyle(this));
  }

  /* RENDERING */
  render()
  {
    return (
      <View style={{flex : 1}}>
        {this.getSwitch()}
      </View>
    );
  }
}