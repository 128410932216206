import MenuViewState from './MenuViewState';
import MenuViewProps from './MenuViewProps';
import { ContentViewModel, ContentView, Application, NavigationManager, Managers } from '@levelapp/softfabric';
import { Animated } from 'react-native';
import Constants from '../../../../../Common/Constants/Constants';
import PermissionHelper from '../../../../../Common/Helpers/PermissionHelper';
import MenuItemView from './components/menuitem/MenuItemView';
import EnumModules from '../../../../../Common/Constants/EnumModules';
 
import DashboardsOperations from '../../../../../Transfer/DashboardsOperations';
import Dashboard from '../../../../../Common/DTO/Dashboard';
import DashboardType from '../../../../../Common/DTO/DashboardType';

export default class MenuViewModel extends ContentViewModel<MenuViewState, MenuViewProps>{

  NavigationManager: NavigationManager = Application.current.resolve<NavigationManager>(Managers.NAVIGATION_MANAGER);

  animated: Animated.Value;

  _operation: DashboardsOperations;


  constructor(props: ContentView) {
    super(props);

    this.initialState(
      {
        height: 50,
        dashboard: [],
        isLoadingDashboard: true,
      });

    this._operation = new DashboardsOperations();

    this._operation.get([], (dashboards: any) => {
      this.setField({ dashboard: dashboards.data, isLoadingDashboard: false });
    });
    this.onLayout = this.onLayout.bind(this);
    this.animated = new Animated.Value(Constants.ShouldDisplayMenu ? 1 : 0);
  }

  toolMenus = () => [
    {
      underMenu: true,
      isDirect: true,
      title: "tools.zones",
      display: true,
      selected: this.NavigationManager.route.includes('/tools/zones'),
      onPress: () => this.navigateToModule(EnumModules.ZONES_TOOLS),
    },
    {
      underMenu: true,
      isDirect: true,
      title: "tools.products",
      display: true,
      selected: this.NavigationManager.route.includes('/tools/tproducts'),
      onPress: () => this.navigateToModule(EnumModules.PRODUCTS_TOOLS),
    },
  ];

  manageAnimation(inout: number) {
    Animated.timing(this.animated,
      {
        useNativeDriver: true,
        toValue: inout,
      }).start();
  }


  onLayout(type: boolean) {
    var h = this.state().height;
    if (type) {
      h = h;
    }
    // }else
    // {
    //     h = h - height;
    // }
    this.setField({ height: h });
  }

  getImage() {
    if (this.props().animation) {
      return require('../../../../../Common/Assets/collapse-nav.svg').default;
    } else {
      return require('../../../../../Common/Assets/open-nav.svg').default;
    }
  }

  renderStats() {
    var stats: any[] = [];
    if (PermissionHelper.shouldShowStats() && this.state().dashboard.length != 0) {
      var alreadySet: number[] = [];
      this.state().dashboard.map((value: Dashboard) => {
        if (alreadySet.find(x => x == value.dashboardTypeId)) {
          return;
        }
        var listdashboard = this.state().dashboard.filter(x => x.dashboardTypeId == value.dashboardTypeId);
        alreadySet.push(value.dashboardTypeId as number);
        if ((value.dashboardType as DashboardType) != undefined) {
          stats.push(<MenuItemView
            underMenu={true}
            isDirect={true}
            title={`stats.${(value.dashboardType as DashboardType).key.toLowerCase()}.title`}
            display={PermissionHelper.shouldShowStats()}
            selected={this.NavigationManager.route.indexOf('/stats/main') !== -1 && (this.NavigationManager.parameters() != undefined ? (this.NavigationManager.parameters() as any).dashboard[0].dashboardTypeId == value.dashboardTypeId : false)}
            onPress={() => this.navigateToModulePage(EnumModules.STATS, `main`, true, { dashboard: listdashboard })} />)
        }
      })
    }

    stats.push(<MenuItemView
      underMenu={true}
      isDirect={true}
      title={`stats.settings.title`}
      display={PermissionHelper.shouldShowStats()}
      selected={this.NavigationManager.route.indexOf('/stats/settings') !== -1}
      onPress={() => this.navigateToModulePage(EnumModules.STATS, `settings`)} />)

    return stats;
  }

  renderMenuClickCollect() {
    var clickcollect = [];
    if (PermissionHelper.shouldShowStoresSummary()) {
      clickcollect.push(<MenuItemView
        title={"storessummary.title"}
        display={PermissionHelper.shouldShowStoresSummary()}
        underMenu={true}
        isDirect={true}
        selected={this.NavigationManager.route.indexOf('/summarystores') !== -1}
        onPress={() => this.navigateToModule(EnumModules.STORES_SUMMARY)} />)
    }
    if (PermissionHelper.shouldShowProducts()) {
      clickcollect.push(<MenuItemView
        underMenu={true}
        isDirect={true}
        title={"products.title"}
        display={PermissionHelper.shouldShowProducts()}
        selected={this.NavigationManager.route.indexOf('/products') !== -1}
        onPress={() => this.navigateToModule(EnumModules.PRODUCTS)} />);
    }

    if (PermissionHelper.shouldShowProductCategories()) {
      clickcollect.push(<MenuItemView
        underMenu={true}
        isDirect={true}
        title={"products.categories.title"}
        display={PermissionHelper.shouldShowProductCategories()}
        selected={this.NavigationManager.route.indexOf('/productcategories') !== -1}
        onPress={() => this.navigateToModule(EnumModules.PRODUCT_CATEGORIES)} />);
    }

    if (PermissionHelper.shouldShowProductAdditionalInfo()) {
      clickcollect.push(<MenuItemView
        underMenu={true}
        isDirect={true}
        title={"products.additionalInfos.title"}
        display={PermissionHelper.shouldShowProductCategories()}
        selected={this.NavigationManager.route.indexOf('/productAdditionalInfos') !== -1}
        onPress={() => this.navigateToModule(EnumModules.PRODUCT_ADDITIONAL_INFOS)} />);
    }

    if (PermissionHelper.shouldShowOrders()) {
      clickcollect.push(<MenuItemView
        underMenu={true}
        isDirect={true}
        title={"orders.title"}
        display={PermissionHelper.shouldShowOrders()}
        selected={this.NavigationManager.route.indexOf('/orders') !== -1}
        onPress={() => this.navigateToModule(EnumModules.ORDERS)} />)
    }

    if (PermissionHelper.shouldShowKitchenApp()) {
      clickcollect.push(<MenuItemView
        underMenu={true}
        isDirect={true}
        title={"kitchenapp.title"}
        display={PermissionHelper.shouldShowKitchenApp()}
        selected={this.NavigationManager.route.indexOf('/kitchenapp') !== -1}
        onPress={() => this.navigateToModule(EnumModules.KITCHENAPP)} />)
    }

    if (PermissionHelper.shouldShowMenuConfiguration()) {
      clickcollect.push(<MenuItemView
        title={"menuconfigurator.title"}
        display={PermissionHelper.shouldShowMenuConfiguration()}
        underMenu={true}
        isDirect={true}
        selected={this.NavigationManager.route.indexOf('/menuconfigurator') !== -1}
        onPress={() => this.navigateToModule(EnumModules.MENUCONFIGURATOR)} />)
    }

    if (PermissionHelper.shouldShowCrossSellconfigurator()) {
      clickcollect.push(<MenuItemView
        title={"crosssellconfig.title"}
        display={PermissionHelper.shouldShowCrossSellconfigurator()}
        underMenu={true}
        isDirect={true}
        selected={this.NavigationManager.route.indexOf('/crosssellconfigurator') !== -1}
        onPress={() => this.navigateToModule(EnumModules.CROSS_SELL_CONFIGURATOR)} />)
    }

    if (PermissionHelper.shouldShowPromoCodes()) {
      clickcollect.push(<MenuItemView
        title={"promocodes.title"}
        display={PermissionHelper.shouldShowPromoCodes()}
        underMenu={true}
        isDirect={true}
        selected={this.NavigationManager.route.indexOf('/promocodes') !== -1}
        onPress={() => this.navigateToModule(EnumModules.PROMOCODES)} />)
    }
    if (PermissionHelper.shouldShowOutOfStock()) {
      clickcollect.push(<MenuItemView
        title={"outofstock.title"}
        display={PermissionHelper.shouldShowOutOfStock()}
        underMenu={true}
        isDirect={true}
        selected={this.NavigationManager.route.indexOf('/outofstock') !== -1}
        onPress={() => this.navigateToModule(EnumModules.OUT_OF_STOCK)} />)
    }


    return clickcollect;
  }


  renderMenuLoyalty() {
    var loyalty = [];
    if (PermissionHelper.shouldShowRewards()) {
      loyalty.push(<MenuItemView
        title={"rewards.title"}
        display={PermissionHelper.shouldShowRewards()}
        underMenu={true}
        isDirect={true}
        selected={this.NavigationManager.route.indexOf('/rewards') !== -1}
        onPress={() => this.navigateToModule(EnumModules.REWARDS)} />);
    }

    if (PermissionHelper.shouldShowCoupons()) {
      loyalty.push(<MenuItemView
        title={"coupons.title"}
        display={PermissionHelper.shouldShowCoupons()}
        underMenu={true}
        isDirect={true}
        selected={this.NavigationManager.route.indexOf('/coupons') !== -1}
        onPress={() => this.navigateToModule(EnumModules.COUPONS)} />);
    }

    if (PermissionHelper.shouldShowEverydayValues()) {
      loyalty.push(<MenuItemView
        title={"everyday.title"}
        display={PermissionHelper.shouldShowEverydayValues()}
        underMenu={true}
        isDirect={true}
        selected={this.NavigationManager.route.indexOf('/everyday') !== -1}
        onPress={() => this.navigateToModule(EnumModules.EVERYDAYVALUES)} />);
    }

    if (PermissionHelper.shouldShowBoosters()) {
      loyalty.push(<MenuItemView
        underMenu={true}
        isDirect={true}
        title={"boosters.title"}
        display={PermissionHelper.shouldShowBoosters()}
        selected={this.NavigationManager.route.indexOf('/boosters') !== -1}
        onPress={() => this.navigateToModule(EnumModules.BOOSTERS)} />);
    }

    if (PermissionHelper.shouldShowSavingCards()) {
      loyalty.push(<MenuItemView
        underMenu={true}
        isDirect={true}
        title={(Constants.IsSavingCardsUsedAsBadge) ? "badges.title" : "savingcards.title"}
        display={PermissionHelper.shouldShowSavingCards()}
        selected={this.NavigationManager.route.indexOf((Constants.IsSavingCardsUsedAsBadge) ? '/badges' : '/savingCards') !== -1}
        onPress={() => this.navigateToModule(EnumModules.SAVINGCARDS)} />)
    }

    if (PermissionHelper.shouldShowMessages()) {
      loyalty.push(<MenuItemView
        underMenu={true}
        isDirect={true}
        title={"messageCampaigns.title"}
        display={PermissionHelper.shouldShowMessages()}
        selected={this.NavigationManager.route.indexOf('/messages') !== -1}
        onPress={() => this.navigateToModule(EnumModules.MESSAGES)} />)
    }

    if (PermissionHelper.shouldShowAutomatedMessages()) {
      loyalty.push(<MenuItemView
        underMenu={true}
        isDirect={true}
        title={"automatedmessages.title"}
        display={PermissionHelper.shouldShowAutomatedMessages()}
        selected={this.NavigationManager.route.indexOf('/automatedmessages') !== -1}
        onPress={() => this.navigateToModule(EnumModules.AUTOMATED_MESSAGES)} />);
    }

    if (PermissionHelper.shouldShowLoyaltyRules()) {
      loyalty.push(<MenuItemView
        underMenu={true}
        isDirect={true}
        title={"loyaltyrules.title"}
        display={PermissionHelper.shouldShowLoyaltyRules()}
        selected={this.NavigationManager.route.indexOf('/loyaltyrules') !== -1}
        onPress={() => this.navigateToModule(EnumModules.LOYALTY_RULES)} />);
    }
    if (PermissionHelper.shouldShowEarningPoints()) {
      loyalty.push(<MenuItemView
        underMenu={true}
        isDirect={true}
        title={"ActivationCode.title"}
        display={PermissionHelper.shouldShowEarningPoints()}
        selected={this.NavigationManager.route.indexOf('/earningpoints') !== -1}
        onPress={() => this.navigateToModule(EnumModules.EARNINGPOINT)} />);
    }
    if (PermissionHelper.shouldShowVouchers()) {
      loyalty.push(<MenuItemView
        title={"vouchers.title"}
        display={PermissionHelper.shouldShowVouchers()}
        underMenu={true}
        isDirect={true}
        selected={this.NavigationManager.route.indexOf('/vouchers') !== -1}
        onPress={() => this.navigateToModule(EnumModules.VOUCHERS)} />);
    }

    return loyalty;
  }

  renderMenuAdmin() {
    var admin = [];

    if (PermissionHelper.shouldShowRolePermissions()) {
      admin.push(<MenuItemView
        underMenu={true}
        isDirect={true}
        title={"rolepermissions.title"}
        display={PermissionHelper.shouldShowRolePermissions()}
        selected={this.NavigationManager.route.indexOf('/rolepermissions') !== -1}
        onPress={() => this.navigateToModule(EnumModules.ROLEPERMISSIONS)} />)
    }

    if (PermissionHelper.shouldShowContentTypeKeyConfig()) {
      admin.push(<MenuItemView
        underMenu={true}
        isDirect={true}
        title={"contenttypekeyconfig.title"}
        display={PermissionHelper.shouldShowContentTypeKeyConfig()}
        selected={this.NavigationManager.route.indexOf('/contenttypekeyconfig') !== -1}
        onPress={() => this.navigateToModule(EnumModules.CONTENT_TYPE_KEY_CONFIGURATOR)} />);
    }

    return admin;
  }

  renderToolsMenu = () => this.toolMenus().map((menu) => {
    if (PermissionHelper.shouldShowZoneTool() && PermissionHelper.shouldShowProductTool()) {
      return (
        <MenuItemView
          underMenu={menu.underMenu}
          isDirect={menu.isDirect}
          title={menu.title}
          display={menu.display}
          selected={menu.selected}
          onPress={menu.onPress} />
      )
    }

    return <></>
  });

  renderMenuAppContent() {
    var content = [];

    if (PermissionHelper.shouldShowNews()) {
      content.push(<MenuItemView
        underMenu={true}
        isDirect={true}
        title={"news.title"}
        display={PermissionHelper.shouldShowNews()}
        selected={this.NavigationManager.route.indexOf('/news') !== -1}
        onPress={() => this.navigateToModule(EnumModules.NEWS)} />);
    }

    if (PermissionHelper.shouldShowTranslations()) {
      content.push(<MenuItemView
        underMenu={true}
        isDirect={true}
        title={"translation.title"}
        display={PermissionHelper.shouldShowTranslations()}
        selected={this.NavigationManager.route.indexOf('/translation') !== -1}
        onPress={() => this.navigateToModule(EnumModules.TRANSLATION)} />);
    }


    if (PermissionHelper.shouldShowLegalTexts()) {
      content.push(<MenuItemView
        underMenu={true}
        isDirect={true}
        title={"legaltexts.title"}
        display={PermissionHelper.shouldShowLegalTexts()}
        selected={this.NavigationManager.route.indexOf('/legaltexts') !== -1}
        onPress={() => this.navigateToModule(EnumModules.LEGAL_TEXTS)} />);
    }

    if (PermissionHelper.shouldShowFaq()) {
      content.push(<MenuItemView
        underMenu={true}
        isDirect={true}
        title={"faq.title"}
        display={PermissionHelper.shouldShowFaq()}
        selected={this.NavigationManager.route.indexOf('/faq') !== -1}
        onPress={() => this.navigateToModule(EnumModules.FAQ)} />
      );
    }

    if (PermissionHelper.shouldShowBanners()) {
      content.push(<MenuItemView
        underMenu={true}
        isDirect={true}
        title={"banners.title"}
        display={PermissionHelper.shouldShowBanners()}
        selected={this.NavigationManager.route.indexOf('/banners') !== -1}
        onPress={() => this.navigateToModule(EnumModules.BANNERS)} />
      );
    }



    return content;
  }
}