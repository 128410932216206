import { Operation } from "@levelapp/softfabric";
import AddSsoKey from "src/Common/DTO/AddSsoKey";
import SsoService from "src/Proxy/SsoService";

export default class SsoOperation extends Operation {
  service: SsoService;

  constructor() {
    super();
    this.service = new SsoService();
  }

  post(sso: AddSsoKey) {
    return this.service.post(sso);
  }
}
