import { Operation } from "@levelapp/softfabric";
import TicketService from "../Proxy/TicketService";

export default class TicketOperations extends Operation
{
    service : TicketService;

    constructor()
    {
        super();
        this.service = new TicketService();
    }

    get(id : number, callback : Function)
    {
        this.service.get(id, callback);
    }  
    
    getList(parameters : any[], callback : Function)
    {
        this.service.getList(parameters, callback);
    }  
}
