import { Operation } from "@levelapp/softfabric";
import PurchaseService from "../Proxy/PurchaseService";

export default class PurchaseOperations extends Operation
{
    service: PurchaseService;

    constructor()
    {
        super();
        this.service = new PurchaseService();
    }

    cancel(ticketId: number, callback: Function)
    {
        this.service.cancel(ticketId, callback);
    }
}