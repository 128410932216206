import { Service, Application, HttpManager, Managers } from "@levelapp/softfabric";
import UpdateIntegration from "src/Common/DTO/UpdateIntegration";
import ExtendedManagers from "src/Common/Managers/ExtendedManagers";
import Store from '../Common/DTO/Store';

export default class AdminProductService extends Service {

  httpClient = Application.current.resolve<HttpManager>(Managers.HTTP_MANAGER);
  httpDeliveryClient = Application.current.resolve<HttpManager>(ExtendedManagers.DELIVERY_MANAGER);

  refreshCache(store: Store, onSuccess: Function) {
    this.httpClient.get('AdminProduct/RefreshCaches/' + store.id)
      .then((response: any) => {
        if (response) {
          onSuccess("succes");
        }
      })
  }

  updateMenuConfig(onSuccess: Function) {
    this.httpClient.get('AdminProduct/UpdateMenuConfig')
      .then((response: any) => {
        if (response) {
          onSuccess("success");
        }
      })
  }

  async updateDelivery(storeId: number) {
    const response = await this.httpDeliveryClient.get(`api/product/UpdateMenuDelivery/${storeId}`);

    if (response && response.data) {
      return response.data.data;
    }

    return Promise.reject();
  }

  async fetchUberEatIntegrationStatus(storeId: number) {
    const response = await this.httpDeliveryClient.get(`api/store/IsEnableUberEatsIntegration/${storeId}`);

    if (response && response.data) {
      return response.data.data;
    }

    return Promise.reject();
  }  

  async updateUberEatIntegration(integration: UpdateIntegration) {
    const response = await this.httpDeliveryClient.put('api/store/UpdateUberEatsIntegration', [], integration);

    if (response && response.data) {
      return response.data.data;
    }

    return Promise.reject();
  }
}