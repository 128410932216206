import { ContentViewStyle, Application } from '@levelapp/softfabric';
import { StyleProp, ViewStyle, TextStyle } from 'react-native';
import { StyleManager, UiManagers } from '@levelapp/softfabric-ui';

export default class MainInformationViewStyle extends ContentViewStyle
{
    MyStyleManager : StyleManager = Application.current.resolve<StyleManager>(UiManagers.STYLE_MANAGER);
    
    page: StyleProp<ViewStyle> = {
        flex: 1
    }

    container : StyleProp<ViewStyle> =
    { 
        display: "flex", 
        flexDirection: "column", 
        paddingHorizontal: 170,
        paddingVertical: 50 
    }

    leftblock : StyleProp<ViewStyle> =
    { 
        flex: 2,
        zIndex: 1, 
        justifyContent: "flex-start", 
        flexDirection: 'column' 
    }

    centerblock : StyleProp<ViewStyle> =
    {
        flex: 2,
        zIndex: 1, 
        flexDirection: 'row', 
        alignItems: 'center'
    }

    rightblock : StyleProp<ViewStyle> =
    {
        flex : 2,
        justifyContent: "flex-end", 
        flexDirection: 'row'
    }

    stepColor : StyleProp<ViewStyle> =
    {
        backgroundColor : this.MyStyleManager.primaryColor,
    }

    rowStyle: StyleProp<ViewStyle> =
    {
        minHeight: 170,
    }

    tableHeader : StyleProp<TextStyle> =
    {
        color: '#575757',
        fontSize : 15,
        fontFamily : 'Montserrat',
        fontWeight : 'bold',
    }

    checkbox: StyleProp<any> = {
        width : 20,
        height : 20,
        marginHorizontal: 5,
    }

    titleActive: StyleProp<TextStyle> = {
        fontSize: 14,
        color: '#3F3F3F',
        fontFamily : 'Montserrat SemiBold',
    }
}