import { Service, Application, HttpManager, Managers } from '@levelapp/softfabric';


export default class HistoryService extends Service
{
    httpClient = Application.current.resolve<HttpManager>(Managers.HTTP_MANAGER);

    get(loyaltyCardId : any[], onSuccess: Function)
    {       
        this.httpClient
        .get('history', loyaltyCardId)
        .then((response : any) => {
            if (response)
            {
                if (response.data)
                {                  
                    onSuccess(response.data);
                }
            }
        });
    }
}