import * as React from 'react';
import {ContentView } from '@levelapp/softfabric';
import ButtonImportFileWebViewState from './ButtonImportFileWebViewState';
import ButtonImportFileWebViewProps from './ButtonImportFileWebViewProps';
import ButtonImportFileWebViewStyle from './ButtonImportFileWebViewStyle';
import ButtonImportFileWebViewModel from './ButtonImportFileWebViewModel';
import { View } from 'react-native';
import { ButtonView } from '@levelapp/softfabric-ui';


export default class ButtonImportFileWebView extends ContentView<ButtonImportFileWebViewProps, ButtonImportFileWebViewState, ButtonImportFileWebViewModel, ButtonImportFileWebViewStyle>
{

  /* CONSTRUCTOR */
  constructor(props:ButtonImportFileWebViewProps)
  {
    super(props);
    // Binding
    this.bind(new ButtonImportFileWebViewModel(this), new ButtonImportFileWebViewStyle(this));
  }

  /* RENDERING */
  render()
  {
    return (
        <View style={this.style.container}>
            <View style={this.style.hide}>
              <input type='file' id='FileUploader' onChange={(file: React.ChangeEvent<HTMLInputElement>) => { if ((file.target as any).files != null) { this.binding.handleFileChanged((file.target as any).files[0]) } }}></input>
            </View>
            <ButtonView animation={'None'} borders={'Rectangle'} backgroundColor ={this.props.buttonColor } textColor={this.props.textColor} handleClick ={()=>{ this.binding.handleClick()}} isLoading={false}> import </ButtonView>
        </View>
        

    );
  }
}