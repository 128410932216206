 
import { ContentView } from '@levelapp/softfabric';
import PropertiesManagementPopupViewState from './PropertiesManagementPopupViewState';
import PropertiesManagementPopupViewProps from './PropertiesManagementPopupViewProps';
import PropertiesManagementPopupViewStyle from './PropertiesManagementPopupViewStyle';
import PropertiesManagementPopupViewModel from './PropertiesManagementPopupViewModel';
import { View, TouchableWithoutFeedback, Image, Text, TextInput } from 'react-native';
import Constants from '../../../../../../../../../Common/Constants/Constants';
import LinearGradient from "react-native-web-linear-gradient";
import { TranslationView } from '@levelapp/softfabric-ui';
import ButtonView from '../../../../../../../../../Common/Components/Button/ButtonView';
import PopupConfirmationView from '../../../../../../../../../Common/Components/popupconfirmation/PopupConfirmationView';
import ItemManagementView from '../../../../../../components/itemmanagement/ItemManagementView';
import Helpers from '../../../../../../../../../Common/Helpers/Helpers';
import Language from '../../../../../../../../../Common/DTO/Language';

export default class PropertiesManagementPopupView extends ContentView<PropertiesManagementPopupViewProps, PropertiesManagementPopupViewState, PropertiesManagementPopupViewModel, PropertiesManagementPopupViewStyle>
{
  /* CONSTRUCTOR */
  constructor(props: PropertiesManagementPopupViewProps) {
    super(props);

    // Binding
    this.bind(new PropertiesManagementPopupViewModel(this), new PropertiesManagementPopupViewStyle(this));
  }

  /* RENDERING */
  render() {
    return (

      <View style={{ minWidth: 600, minHeight: 850, width: Constants.PopUpWidth, maxWidth: "100%" }}>

      
        {/* FIXME: generic.delete */}
        {this.state.showPopup &&
          <PopupConfirmationView 
          title={this.state.titlePopup} 
          content={this.state.contentPopup}
          confirmationPopup={this.state.confirmationPopup} 
          handleOnPressBack={ () => this.binding.handleBackPopupPressed()} 
          handleOnPressAccepted={() =>{ this.binding.deleteProperty() }} />
        }

        {/* Color Box */}
        <View>
          <LinearGradient start={{ x: 0, y: 0 }} end={{ x: 1, y: 0 }} colors={[Constants.GradiantStartColor, Constants.GradiantEndColor]} >
            <View style={{ display: "flex", flexDirection: "row", justifyContent: 'space-between' }}>
              <TranslationView style={this.style.title}>
                form.editglobalproperties
              </TranslationView>
              <TouchableWithoutFeedback onPress={this.binding.handleBackPressed} >
                <Image style={{ height: 23, width: 23, resizeMode: 'contain', marginTop: 25, marginRight: 30 }}
                  source={{ uri: 'https://appstoragflyx.blob.core.windows.net/app/Console/exit.png' }}></Image>
              </TouchableWithoutFeedback>
            </View>
          </LinearGradient>
        </View>

        <View style={{ display: "flex", flexDirection: "column", paddingHorizontal: 170, paddingVertical: 50 }}>
          <View style={{ flex: 1, maxHeight: 225, flexWrap: 'wrap' }}>
            {this.state.list.map((content: any) => {
              return (
                <ItemManagementView onEraseClick={() => this.binding.handleDeleteProperty(content)}>
                 {content.titleTranslations
                    .find(
                  (element : any) => 
                  { 
                    return element.language.key == this.binding._languageManager.language
                  }
                  )?.value}
                </ItemManagementView>
              );
            })}
          </View>

          {/* Line */}
          <View style={{ zIndex: -1, backgroundColor: '#D8D8D8', width: '100%', height: 1, marginTop: 30, marginBottom: 50 }}></View>

          <Text style={{
            fontSize: 25,
            fontWeight: '600',
            color: '#3F3F3F',
            fontFamily: 'Montserrat SemiBold',
            marginBottom: 13,
          }}><TranslationView upperCase>form.propertycreation</TranslationView></Text>

          <View style={{ display: "flex", flexDirection: "row", flexWrap: "wrap" }}>
            {this.state.languages.map((content: Language) => {
              var _text = Helpers.getTranslationValueByLanguageKey(this.state.property.titleTranslations,content.key);
              return (
                <View style={this.style.section}>
                  <Text style={[this.style.inputTitle, { marginBottom: 10 }]}>({content.key.toUpperCase()})</Text>
                  <TextInput
                    placeholder='Property name'
                    style={this.state.showErrors && _text == "" ? [this.style.errorInput, { marginRight: 10 }] : [this.style.input, { marginRight: 10 }]}
                    onChangeText={(text: string) => { this.binding.handlePropertyNameChanged(text, content.key) }}
                    value={_text} />
                </View>
              );
            })}
          </View>

          <View style={{ marginBottom: 20, width : 140 }}>
            <Text style={[this.style.inputTitle, { marginBottom: 10 }]}>Logo</Text>
            {/* <ImagePicker onComplete={this.binding.handleImage}>
              <img style={{ cursor: "pointer" }} width="140" height="100" src={this.state.property.imageTranslations[0].url} />
            </ImagePicker> */}

            <label >
              <img style={{ cursor: "default" }} width="140" height="100" src={this.state.property.imageTranslations[0].url} />
              <input type="file" style={{ display : "none"}}  onChange={async (e) => 
                {
                  if(e.target.files != undefined && e.target.files.length > 0)
                  {
                    var base = Helpers.getBase64((e.target.files as any)[0]);
                    base.then((result) => this.binding.handleImage(result));
                  }              
                  }} />
            </label>
          </View>
          {this.state.showErrors && !this.binding.isSelectedImage() &&
            <View>
              <TranslationView style={{ color: "red" }}>error.please.images</TranslationView>
            </View>
          }

          <View style={this.style.createButton}>
            <ButtonView  handleClick={this.binding.handleCreateProperty} isLoading={this.state.isSendingProperty} backgroundColor='#2196f3'>
              <TranslationView>form.createproperty</TranslationView>
            </ButtonView>
          </View>
        </View>
      </View>
    );
  }
}