
import { ContentView } from '@levelapp/softfabric';
import NewsPopUpViewState from './NewsPopUpViewState';
import NewsPopUpViewProps from './NewsPopUpViewProps';
import NewsPopUpViewStyle from './NewsPopUpViewStyle';
import NewsPopUpViewModel from './NewsPopUpViewModel';
import { View, TextInput, Text, TouchableWithoutFeedback, CheckBox, Image } from 'react-native';
import { TranslationView } from '@levelapp/softfabric-ui';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import 'react-datepicker/dist/react-datepicker-cssmodules.css';
import Constants from '../../../../../../../Common/Constants/Constants';
import LinearGradient from "react-native-web-linear-gradient";
import MyWysiwygView from '../../../../../../../Common/Components/mywysiwyg/MyWysiwygView';
import ButtonView from "../../../../../../../Common/Components/Button/ButtonView";
import PopupMenuItemView from '../../../../components/popupmenuitem/PopupMenuItemView';
import LineView from '../../../../components/line/LineView';
import ExtendedTextInputView from '../../../../components/extendedtextinput/ExtendedTextInputView';
import PicturesView from '../../../../components/pictures/PicturesView';
import Helpers from '../../../../../../../Common/Helpers/Helpers';
import Language from '../../../../../../../Common/DTO/Language';
import Select from "react-dropdown-select";
import StoresView from './components/stores/StoresView';

export default class NewsPopUpView extends ContentView<NewsPopUpViewProps, NewsPopUpViewState, NewsPopUpViewModel, NewsPopUpViewStyle>
{
  /* CONSTRUCTOR */
  constructor(props: NewsPopUpViewProps) {
    super(props);

    // Binding
    this.bind(new NewsPopUpViewModel(this), new NewsPopUpViewStyle(this));
  }

  /* RENDERING */
  render() {
    return (
      <View style={{ minWidth: 600, minHeight: 850, width: Constants.PopUpWidth, maxWidth: "100%" }}>
        <View>
          <LinearGradient start={{ x: 0, y: 0 }} end={{ x: 1, y: 0 }} colors={[Constants.GradiantStartColor, Constants.GradiantEndColor]} >
            <View style={{ display: "flex", flexDirection: "row" }}>
              <TranslationView style={this.style.title}>
                news.popup.title
              </TranslationView>
              <ButtonView isLoading={this.state.isLoading} handleClick={this.binding.handleSavePressed} backgroundColor="white" style={{ marginTop: 20 }}>
                <TranslationView upperCase style={this.style.saveButton}>
                  generic.button.saveclose
                </TranslationView>
              </ButtonView>
              <TouchableWithoutFeedback onPress={Helpers.closePopup} >
                <Image style={{ height: 23, width: 23, resizeMode: 'contain', marginTop: 25, marginLeft: 20 }}
                  source={{ uri: 'https://appstoragflyx.blob.core.windows.net/app/Console/exit.png' }}></Image>
              </TouchableWithoutFeedback>
            </View>
          </LinearGradient>
        </View>

        <View style={{ display: "flex", flexDirection: "row", flexWrap: "wrap" }}>
          <PopupMenuItemView activeItem={this.state.tab} title={"form.maininformation"} item={"main"} handleChangeTab={() => this.binding.changeTab("main")} />
          <PopupMenuItemView activeItem={this.state.tab} title={"form.description"} item={"desc"} handleChangeTab={() => this.binding.changeTab("desc")} />
          <PopupMenuItemView activeItem={this.state.tab} title={"form.images"} item={"pictures"} handleChangeTab={() => this.binding.changeTab("pictures")} />
          {!Constants.IsCountryUnique && this.props.countries.length > 1 && <PopupMenuItemView activeItem={this.state.tab} title={"form.countries"} item={"countries"} handleChangeTab={() => this.binding.changeTab("countries")} />}
          <PopupMenuItemView activeItem={this.state.tab} title={"form.stores"} item={"stores"} handleChangeTab={() => this.binding.changeTab("stores")} />
        </View>

        {this.state.tab == "main" &&
          <View style={{ display: 'flex', flexDirection: 'column', paddingHorizontal: 170, paddingVertical: 50 }}>

            {/* UpperSide */}
            <View style={{ flexDirection: "row", marginVertical: 15}}>

              {/* DateContainer */}
              <View style={{ flex: 1, flexDirection: 'row', justifyContent: 'flex-start' }}>
                <View style={this.style.sectionDatePicker}>
                  <TranslationView style={this.style.inputTitle} upperCase={true}>form.startdate</TranslationView>
                  <DatePicker
                    placeholderText="Click to select the start date"
                    selected={this.state.news.StartDateInput}
                    dateFormat="d/MM/yyyy"
                    onChange={(date : Date) => this.binding._dispatcherManager.handleDateChange('startdate',date)} />
                </View>

                <View style={this.style.sectionDatePicker}>
                  <TranslationView style={this.style.inputTitle} upperCase={true}>form.enddate</TranslationView>
                  <DatePicker
                    placeholderText="Click to select the end date"
                    selected={this.state.news.EndDateInput}
                    dateFormat="d/MM/yyyy"
                    onChange={(date : Date) => this.binding._dispatcherManager.handleDateChange('enddate',date)} />
                </View>
              </View>

              {/* CheckBoxContainer */}
              <View style={{ flex: 1, flexDirection: 'row', justifyContent: 'space-between' }}>

                {/* CheckBox */}
                <View style={{ flexDirection: 'column', justifyContent: 'space-between' }}>
                  <View style={{ flexDirection: 'row', justifyContent: 'flex-start'}}>
                    <CheckBox style={this.style.checkbox} value={this.state.news.priority > 0  } onValueChange={(value : boolean) => this.binding._dispatcherManager.handleCheckboxChange('isPriority',value)} />
                    <TranslationView style={this.style.titleActive}>form.priority</TranslationView>
                  </View>
                  {this.state.news.priority > 0 && <ExtendedTextInputView
                    style={{paddingVertical : 10}}
                    title={"form.index.priority"}
                    infoText={this.binding.translate('generic.orderindexpriority.info').replace('{1}', this.binding.translate('news.title.singular').toLowerCase())}
                    showError={false}
                    value={`${this.state.news.priority}`}
                    onChangeText={(text: string) => this.binding._dispatcherManager.handleValueChange('orderindexpriority', text)}
                  />}
                  <View style={{ flexDirection: 'row', justifyContent: 'flex-start'}}>
                    <CheckBox style={this.style.checkbox} value={this.state.news.isPreview} onValueChange={(value : boolean) => this.binding._dispatcherManager.handleCheckboxChange('isPreviewVisible',value)} />
                    <TranslationView style={this.style.titleActive}>form.preview</TranslationView>
                  </View>
                  <View style={{ flexDirection: 'row', justifyContent: 'flex-start' }}>
                    <CheckBox style={this.style.checkbox} value={this.state.news.isAnonymousVisible} onValueChange={(value : boolean) => this.binding._dispatcherManager.handleCheckboxChange('isAnonymousVisible',value)} />
                    <TranslationView style={this.style.titleActive}>form.anonymous</TranslationView>
                  </View>
                </View>
              </View>
            </View>

{/* 
            <ExtendedTextInputView
                title={"form.url"}
                showError={false}
                value={this.state.news.url}
                onChangeText={(text: string) => this.binding.handleUrlChanged(text)}
              /> */}

            {/* Middle line */}
            <LineView/>
            {/* DownSide */}
            <View style={{ zIndex : -1, width: '100%', flexDirection: 'row', justifyContent: 'space-between', flexWrap: 'wrap' }}>
              {/* Title */}
              {this.state.languages.map((content: Language) => {
                var _text = Helpers.getTranslationValueByLanguageKey(this.state.news.titleTranslations,content.key);
                return (
                  <View style={this.style.section}>
                    <Text style={this.style.inputTitle}><TranslationView upperCase>form.titlefield</TranslationView> ({content.key.toUpperCase()})</Text>
                    <TextInput
                      style={this.state.showErrors && (_text == null || _text == "") ? this.style.errorTextArea : this.style.textArea}
                      value={_text}
                      onChangeText={(text: string) => this.binding._dispatcherManager.handleTranslationsChange('title',text,content.id)}
                      multiline={true} />
                  </View>
                );
              })}
            </View>
            
            {/* Middle line */}
            <LineView/>

            <View style={{ zIndex : -1, width: '100%', flexDirection: 'row', justifyContent: 'space-between', flexWrap: 'wrap' }}>
              {/* Title */}
              {this.state.languages.map((content: Language) => {
                var _text = Helpers.getTranslationValueByLanguageKey(this.state.news.urlTranslation,content.key);
                return (
                  <View style={this.style.section}>
                    <ExtendedTextInputView
                      title={"form.url"}
                      style={{width : 475}}
                      value={_text}
                      extraTitle={` (${content.key.toUpperCase()})`}
                      showError={this.state.showErrors && (_text == null || _text == "") ? true : false}
                      onChangeText ={(text: string) => this.binding._dispatcherManager.handleTranslationsChange('url',text,content.id)}
                    />
                  </View>
                );
              })}
            </View>
          </View>
        }
        {this.state.tab == "desc" &&
          <View style={{ display: "flex", flexDirection: "row", flexWrap: "wrap", justifyContent: 'space-between', paddingHorizontal: 170, paddingVertical: 50 }}>
            {/* Description */}
            {this.state.languages.map((content: Language) => {
              var _text = Helpers.getTranslationValueByLanguageKey(this.state.news.descriptionTranslations,content.key);
              return (
                <View style={this.style.section}>
                  <Text style={this.style.inputTitle}><TranslationView upperCase>form.description</TranslationView> ({content.key.toUpperCase()})</Text>
                  {Constants.IsNewsHtml ?
                    <MyWysiwygView text={_text} onChangeText={(text: string) => this.binding._dispatcherManager.handleTranslationsChange('description',text,content.id)}></MyWysiwygView>
                    :
                    <TextInput
                      multiline={true}
                      numberOfLines={10}
                      style={this.state.showErrors && (_text == null || _text == "") ? this.style.errorTextArea : this.style.textArea}
                      value={_text}
                      onChangeText={(text: string) => this.binding._dispatcherManager.handleTranslationsChange('description',text,content.id)} />
                  }
                </View>
              );
            })}
          </View>
        }

        {this.state.tab == "pictures" &&
          <PicturesView
            languages={this.props.languages}
            listImage={this.state.news.imageTranslation}
            listThumbnail={this.state.news.thumbnailTranslation}
            handleOnComplete={this.binding._dispatcherManager.handleAnyChange}
            handleOnPressImage={this.binding._dispatcherManager.handleNumberChange}
            handleCheckboxChange={this.binding._dispatcherManager.handleCheckboxChange}
            shouldUseDefaultImage={this.state.useDefaultImageChecked}
            shouldUseDefaultThumnail={this.state.useDefaultThumbnailChecked}
          />
        }

        {this.state.tab === "stores" &&
          <StoresView
            isLoading={this.state.isStoresLoading}
            stores={this.state.stores}
            selectedIds={this.state.news?.storeIds ?? []}
            handleValueChange={(value: any) => this.binding._dispatcherManager.handleValueChange('store', value)}
          />
        }

        {this.state.tab == "countries" &&
          <View style={{ display: "flex", flexDirection: "row", flexWrap: "wrap", justifyContent: 'space-between', paddingHorizontal : 170, paddingVertical:50}}>
          {/* List of Countries*/}
            <View style={this.style.section}>
              <TranslationView style={this.style.inputTitle} upperCase>form.countries</TranslationView>
              <Select 
              placeholder="Select countries"
              color={this.state.color}
              values={this.state.selectedCountries}
              options={this.props.countries} 
              onChange={(value: any) => this.binding._dispatcherManager.handleValueChange('country',value)}
              multi={true}
              create={false}
              keepSelectedInList={false}
              clearable={true}
              labelField={"countryCode"}
              valueField={"id"}
              searchBy={"countryCode"}
              />
            </View>
          </View>
          }

        {this.state.showErrors &&
          < View style={this.style.footer} >
            <TranslationView style={{ color: "red" }}>error.please.fields</TranslationView>
          </View>
        }
      </View>
    );
  }
}