import { Operation } from "@levelapp/softfabric";
import BasketStatusHistoriesService from "../Proxy/BasketStatusHistoriesService";

export default class BasketStatusHistoriesOperation extends Operation
{
    service: BasketStatusHistoriesService;

    constructor()
    {
        super();
        this.service = new BasketStatusHistoriesService();
    }

    async get(basketId: number)
    {
        return await this.service.get(basketId);
    }
}