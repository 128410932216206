 
import { ContentViewModel, ContentView } from '@levelapp/softfabric';
import OrderViewState from './OrderViewState';
import OrderViewProps from './OrderViewProps';
import BasketLine from '../../../../../../../Common/DTO/BasketLine';
import Constants from '../../../../../../../Common/Constants/Constants';
import { OrderSources } from '../../../../../../../Common/Enums/OrderSources';
import { Image, StyleProp } from 'react-native';
import { DeliveryProviderKeys, DeliveryProviders } from '../../../../../../../Common/Enums/DeliveryProviders';

export default class OrderViewModel extends ContentViewModel<OrderViewState, OrderViewProps>
{
    private _ShowAllSubLines = Constants.kitchenUseBrand

    /* CONSTRUCTOR */
    constructor(view: ContentView) {
        super(view);

        this.initialState({
            isMenuVisible: false,
            isTimeOut: false
        });

        // Bindings
        this.handleSettingsPressed = this.handleSettingsPressed.bind(this);
        this.getOrderSource = this.getOrderSource.bind(this);
        this.getFontSizeTxt = this.getFontSizeTxt.bind(this);
        this.getFontSizeTxtSeeMore = this.getFontSizeTxtSeeMore.bind(this);
        this.getOrderContainer = this.getOrderContainer.bind(this);
        this.getIndexStarter = this.getIndexStarter.bind(this);
    }

    handleSettingsPressed() {
        const { setShouldHideMenu, isSettingsMenuOpen } = this.props();

        setShouldHideMenu(isSettingsMenuOpen);
    }

    getChildLineColor(line: BasketLine) {
        if (line.totalVatInclude > 0) {
            return 'green';
        }
        if (Number.parseInt(line.posId) >= Constants.modifierRangeMin && Number.parseInt(line.posId) <= Constants.modifierRangeMax) {
            return 'red';
        }
        return '#777777';
    }
    getDeliveryIcon() {
        switch (this.props().deliveryProvider?.key) {

            case DeliveryProviderKeys.DELIVEROO: return <Image source={require('../../assets/deliveroo.svg').default} style={{ width: 24, height: 24 }} resizeMode="contain" />;

            case DeliveryProviderKeys.UBEREATS: return <Image source={require('../../assets/ubereats.svg').default} style={{ width: 39, height: 30 }} resizeMode="contain" />;

            case DeliveryProviderKeys.TAKEAWAY: return <Image source={require('../../assets/takeaway.svg').default} style={{ width: 24, height: 24 }} resizeMode="contain" />;

            case DeliveryProviderKeys.JUSTEEAT: return <Image source={require('../../assets/takeaway.svg').default} style={{ width: 24, height: 24 }} resizeMode="contain" />;

            default:
                return <Image source={require('../../assets/ubereats.svg').default} style={{ width: 39, height: 30 }} resizeMode="contain" />
        }
    }

    getDeliveryIconOld() {
        switch (this.props().deliveryProviderId) {
            case DeliveryProviders.DELIVEROO: return <Image source={require('../../assets/deliveroo.svg').default} style={{ width: 24, height: 24 }} resizeMode="contain" />;
            case DeliveryProviders.UBEREATS: return <Image source={require('../../assets/ubereats.svg').default} style={{ width: 39, height: 30 }} resizeMode="contain" />;
            case DeliveryProviders.TAKEAWAY: return <Image source={require('../../assets/takeaway.svg').default} style={{ width: 24, height: 24 }} resizeMode="contain" />;
            default:
                return <Image source={require('../../assets/ubereats.svg').default} style={{ width: 39, height: 30 }} resizeMode="contain" />
        }
    }

    getOrderSource() {
      if (this.props().deliveryProvider?.key) {
        return this.getDeliveryIcon();
      }

      if (this.props().orderSource === OrderSources.DELIVERY) {
          return this.getDeliveryIconOld();
      }

      switch (this.props().orderSource) {
          case OrderSources.APP:
              return <Image source={require('../../assets/app.svg').default} style={{ width: 25, height: 25 }} resizeMode="contain" />

          case OrderSources.KIOSK:
              return <Image source={require('../../assets/kiosk.svg').default} style={{ width: 25, height: 25 }} resizeMode="contain" />
          case OrderSources.DRIVE:
              return <Image source={require('../../assets/CarDelivery.svg').default} style={{ width: 39, height: 21 }} resizeMode="contain" />
          case OrderSources.WEB:
              return <Image source={require('../../assets/web-icon.svg').default} style={{ width: 39, height: 21 }} resizeMode="contain" />
      }
      return <Image source={require('../../assets/cashRegister.svg').default} style={{ width: 23, height: 21 }} resizeMode="contain" />;
    }

    getAllSubLines(basketLineId: number, basketLines: BasketLine[], counter: number) {
        const subLines: BasketLine[] = [];
        const temp = basketLines.filter(x => x.parentBasketLineId == basketLineId);

        const _ShowAllSubLines = this._ShowAllSubLines || (this.props().orderSource === OrderSources.DELIVERY && Constants.ProjectName == "Nordsee");

        for (let i = 0; i < temp.length; i++) {

            if (counter % 2 === 1 || _ShowAllSubLines) subLines.push(temp[i]);
            const subSubLines = this.getAllSubLines(temp[i].basketLineId, basketLines, counter + 1);

            for (let j = 0; j < subSubLines.length; j++) {
                subLines.push(subSubLines[j]);
            }
        }

        return subLines;
    }

    getIndexStarter(useNewProductTreeStructure: boolean, line: BasketLine) {
        if (useNewProductTreeStructure && line.couponUUID === null) {
            return 0;
        }
        return 1;
    }

    getFontSizeTxt() {
        return {
            fontSize: this.props().fontsizeTxt
        }
    }

    getFontSizeTxtSeeMore() {
        return {
            fontSize: this.props().fontsizeTxt - 4
        }
    }

    getOrderContainer(): StyleProp<any> {
        return {
            width: this.props().orderWidth,
            maxHeight: this.props().maxHeight,
            marginRight: 12,
            borderRadius: 5,
            marginBottom: 10,
            borderWidth: 2,
            boxShadow: '4px 4px 13px -6px rgba(140,140,140,0.5)',

        }
    }
}