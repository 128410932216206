 
import { ContentView } from '@levelapp/softfabric';
import ExtendedImageViewState from './ExtendedImageViewState';
import ExtendedImageViewProps from './ExtendedImageViewProps';
import ExtendedImageViewStyle from './ExtendedImageViewStyle';
import ExtendedImageViewModel from './ExtendedImageViewModel';
import { TranslationView } from '@levelapp/softfabric-ui';
import { TouchableWithoutFeedback, View, Text, CheckBox } from 'react-native';
import ExtendedTranslationView from '../extendedtranslation/ExtendedTranslationView';
import Helpers from '../../../../../../src/Common/Helpers/Helpers';

export default class ExtendedImageView extends ContentView<ExtendedImageViewProps, ExtendedImageViewState, ExtendedImageViewModel, ExtendedImageViewStyle>
{
  /* CONSTRUCTOR */
  constructor(props: ExtendedImageViewProps)
  {
    super(props);

    // Binding
    this.bind(new ExtendedImageViewModel(this), new ExtendedImageViewStyle(this));
  }

  /* RENDERING */
  render()
  {
    const { section, defaultStyle } = this.style;
    const infoText = this.props.title == "form.picture" ? "popup.image.picture.info" : "popup.image.thumbnail.info"
    return (
      <TouchableWithoutFeedback onPress={() => this.props.handleOnPressImage(this.props.id)}>
        <View style={section}>
          {(this.props.index == 0 || !this.props.shouldUseDefaultImage ?
            <View >
              <ExtendedTranslationView afterTitle={`(${this.props.value.toUpperCase()})`} infoText={this.binding.translate(infoText)}>
                {this.props.title}
              </ExtendedTranslationView>
            <label >
              <img style={{ cursor: "default" }} width="140" height="100" src={this.props.source} />
              <input type="file" style={{ display : "none"}}  onChange={async (e) => 
                {
                  if(e.target.files != undefined && e.target.files.length > 0)
                  {
                    var base = Helpers.getBase64((e.target.files as any)[0]);
                    base.then((result)=> {this.props.handleOnComplete(result)})
                    
                  }              
                  }} />
            </label>
                

            </View>
            : <View></View>)}
          {(this.props.index == 0 &&
            <View style={defaultStyle}>
              <View style={{ flexDirection: 'row' }}>
                <CheckBox
                  value={this.props.shouldUseDefaultImage}
                  onValueChange={this.props.handleCheckboxChange}
                />
                <Text style={{ marginLeft: '5px' }}><TranslationView>form.useasdefault</TranslationView></Text>
              </View>
            </View>)}
        </View>
      </TouchableWithoutFeedback >
    );
  }

}

