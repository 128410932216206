import { ContentViewStyle, Application } from '@levelapp/softfabric';
import { StyleProp, ViewStyle } from 'react-native';
import { StyleManager, UiManagers } from '@levelapp/softfabric-ui';

export default class TitleDescritpionViewStyle extends ContentViewStyle
{
    MyStyleManager : StyleManager = Application.current.resolve<StyleManager>(UiManagers.STYLE_MANAGER);
    
    page: StyleProp<ViewStyle> = {
        flex: 1
    }

    container : StyleProp<ViewStyle> =
    { 
        display: "flex", 
        flexDirection: "column", 
        paddingHorizontal: 170,
        paddingVertical: 50 
    }
}