import {ContentViewStyle} from '@levelapp/softfabric';
import {StyleProp,TextStyle,ViewStyle} from 'react-native';

export default class PointsSectionViewStyle extends ContentViewStyle
{
    page: StyleProp<ViewStyle> = {
        flex: 1
    }

    containerTop : StyleProp<ViewStyle> =
    {
        flexDirection: "row", 
        marginBottom: 20, 
        alignItems: 'center'
    }

    containerCenter : StyleProp<ViewStyle> =
    {
        flexDirection: "row", 
    }

    containerBottom : StyleProp<ViewStyle> =
    {
        flexDirection: "row", 
        alignItems:'center',
        marginTop: 20
    }

    input: StyleProp<any> = {

        paddingHorizontal: 15,
        marginTop: 15,
        borderRadius: 8,
        boxShadow: '0 5px 14px 0 rgba(0,0,0,0.1)',
    };

    code: StyleProp<TextStyle> = {
        fontWeight: 'bold',
        paddingLeft: 10
    }

    addedPoints: StyleProp<ViewStyle> = {
        alignSelf: 'flex-end',
    };

    addPoints: StyleProp<ViewStyle> = {
        alignSelf: 'flex-start',
        marginRight: 40,
    };

    textArea: StyleProp<any> = {
        width: '100%',
        minWidth: 400,
        minHeight : 38,
        height : '100%',
        paddingHorizontal: 5,
        marginTop: 15,
        borderRadius : 8,
        boxShadow: '0 5px 14px 0 rgba(0,0,0,0.1)',
        alignSelf: 'flex-end'
    };
}