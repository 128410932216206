import {ContentViewStyle} from '@levelapp/softfabric';
import {StyleProp,ViewStyle, TextStyle} from 'react-native';
import Constants from '../../../../../../../../../../../../Common/Constants/Constants';

export default class HistoricItemTabNavigationViewStyle extends ContentViewStyle
{
    page: StyleProp<ViewStyle> = {
        flex: 1
    }

    onglet: StyleProp<ViewStyle> = {
        display: "flex",
        flexDirection: "column",
        flex: 1,
        alignItems: "center"
    }

    tabActive: StyleProp<ViewStyle> = {
        backgroundColor: Constants.GradiantStartColor,
        width: "100%",
        alignItems: "center",
        height: "50px",
        display: "flex",
        justifyContent: "center",
    };

    tab: StyleProp<ViewStyle> = {
        backgroundColor: "#F2F2F3",
        width: "100%",
        alignItems: "center",
        height: "50px",
        display: "flex",
        justifyContent: "center",
    };

    titleActive: StyleProp<TextStyle> = {
        fontSize: 14,
        color: 'white',
        fontFamily: 'Montserrat SemiBold',
        textAlign: 'center'
    };

    titleInactive: StyleProp<TextStyle> = {
        color: '#B8BBC1',
        fontSize: 14,
        fontFamily: 'Montserrat SemiBold',
        textAlign: 'center'
    };
}