import { Operation } from "@levelapp/softfabric";
import LegalTextsService from "../Proxy/LegalTextsService";

export default class LegalTextsOperations extends Operation
{
    service : LegalTextsService;

    constructor()
    {
        super();
        this.service = new LegalTextsService();
    }

    get(parameters : any[], callback : Function)
    {
        this.service.get(parameters, callback);
    }

    put(text:any, callback : Function)
    {
        this.service.put(text, callback);
    }

    post(text:any, callback : Function, error: Function, errorNotFound : Function)
    {
        this.service.post(text, callback, error, errorNotFound);
    }
}