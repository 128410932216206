 
import { View, Text } from 'react-native';
import {ContentView} from '@levelapp/softfabric';
import AllergensManagementViewState from './AllergensManagementViewState';
import AllergensManagementViewProps from './AllergensManagementViewProps';
import AllergensManagementViewStyle from './AllergensManagementViewStyle';
import AllergensManagementViewModel from './AllergensManagementViewModel';
import Constants from '../../../../../../../../../Common/Constants/Constants';
import PopupHeaderView from '../../../../../../components/popupheader/PopupHeaderView';
import PopupConfirmationView from '../../../../../../../../../Common/Components/popupconfirmation/PopupConfirmationView';
import Allergen from '../../../../../../../../../Common/DTO/Allergen';
import ItemManagementView from '../../../../../../components/itemmanagement/ItemManagementView';
import LineView from '../../../../../../components/line/LineView';
import { TranslationView, ButtonView } from '@levelapp/softfabric-ui';
import ItemLanguageView from '../../../../../../components/itemlanguage/ItemLanguageView';
import Language from '../../../../../../../../../Common/DTO/Language';
import Helpers from '../../../../../../../../../Common/Helpers/Helpers';


export default class AllergensManagementView extends ContentView<AllergensManagementViewProps, AllergensManagementViewState, AllergensManagementViewModel, AllergensManagementViewStyle>
{
  /* CONSTRUCTOR */
  constructor(props:AllergensManagementViewProps)
  {
    super(props);

    // Binding
    this.bind(new AllergensManagementViewModel(this), new AllergensManagementViewStyle(this));
  }

  /* RENDERING */
  render()
  {
    const { body , text , inputTitle , createButton}  = this.style;

    return (
      <View style={{ minWidth: 600, minHeight: 850, width: Constants.PopUpWidth, maxWidth: "100%" }}>

        {this.state.showPopup &&
          <PopupConfirmationView 
          title={this.state.titlePopup} 
          content={this.state.contentPopup}
          confirmationPopup={this.state.confirmationPopup} 
          handleOnPressBack={ () => this.binding.handleBackPopupPressed()} 
          handleOnPressAccepted={() =>{ this.binding.deleteAllergen() }} />
        }
       
       <PopupHeaderView shouldShowButton={false} title={"allergens.management.header.title"} handleBackPressed={this.binding.handleCloseManagement}/>
       <View style={body}>
          <View style={{ flex: 1, maxHeight: 225, flexWrap: 'wrap' }}>
            {this.state.allergens.map((content: Allergen) => {
              return (
                <ItemManagementView onEraseClick={() => this.binding.handleDeleteAllergen(content)}>
                  {this.binding.handleTranslations(content)}
                  </ItemManagementView>
              );
            })}
          </View>

          <LineView/>

          <TranslationView upperCase style={[text]}>allergens.management.title</TranslationView>
          <View style={{ display: "flex", flexDirection: "row", flexWrap: "wrap" }}>
            {this.state.languages.map((content: Language) => {
              return (
                  <ItemLanguageView value={this.state.allergen != undefined && Helpers.getTranslationValueByLanguageKey(this.state.allergen.titleTranslations , content.key)} 
                  language={content.key} 
                  placeholder={this.binding.translate("component.item.language.allergen.placeholder")} 
                  isError={false} 
                  handleOnChangeText={this.binding.handleAllergenNameChanged} />
              );
            })}
          </View>

          <View style={{ marginBottom: 20, width : 140 }}>
            <Text style={[inputTitle, { marginBottom: 10 }]}>Logo</Text>


            <label >
              <img style={{ cursor: "default" }} width="140" height="100" src={this.state.allergen != undefined ? this.state.allergen.imageTranslations[0].url : ""} />
              <input type="file" style={{ display : "none"}}  onChange={async (e) => 
                {
                  if(e.target.files != undefined && e.target.files.length > 0)
                  {
                    var base = Helpers.getBase64((e.target.files as any)[0]);
                    base.then((result)=> {this.binding.handleImage(result)})

                  }              
                  }} />
            </label>
          </View>
         

          <View style={createButton}>
            <ButtonView animation={"Bounce"} borders={"Little"} handleClick={this.binding.handleCreateAllergen} isLoading={this.state.isCreating} backgroundColor='#2196f3'>
              <TranslationView>allergens.management.button.add</TranslationView>
            </ButtonView>
          </View>
         
        </View>

      </View>
    );
  }
}