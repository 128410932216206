import { Operation } from "@levelapp/softfabric";
import PromoCodesService from "../Proxy/PromoCodesService";
import PromoCodeBatch from "../Common/DTO/PromoCodeBatch";
import PromoCode from "../Common/DTO/PromoCode";

export default class PromoCodesOperations extends Operation
{
    service : PromoCodesService;

    constructor()
    {
        super();
        this.service = new PromoCodesService();
    }

    get(parameters:any[], callback : Function , onFailure : Function)
    {
        this.service.get(parameters, callback, onFailure);
    }

    post(pcgo:PromoCodeBatch, callback : Function , onFailure : Function)
    {
        this.service.post(pcgo, callback, onFailure);
    }

    delete(promoCode:PromoCode, callback : Function , onFailure : Function)
    {
        this.service.delete(promoCode, callback, onFailure);
    }

    deleteBatch(batch:PromoCodeBatch, callback : Function , onFailure : Function)
    {
        this.service.deleteBatch(batch, callback, onFailure);
    }

    put(batch:PromoCodeBatch, callback : Function , onFailure : Function)
    {
        this.service.put(batch, callback, onFailure);
    }
}