 
import { View, TextInput, Text } from 'react-native';

import { ContentView } from '@levelapp/softfabric';
import { LottieView } from '@levelapp/softfabric-ui';

import PriceTabViewState from './PriceTabViewState';
import PriceTabViewProps from './PriceTabViewProps';
import PriceTabViewStyle from './PriceTabViewStyle';
import PriceTabViewModel from './PriceTabViewModel';
import LabelViewView from '../labelview/LabelViewView';
import HeaderView from '../header/HeaderView';
import Constants from 'src/Common/Constants/Constants';
import NoDataPlaceholderView from '../nodataplaceholder/NoDataPlaceholderView';

export default class PriceTabView extends ContentView<PriceTabViewProps, PriceTabViewState, PriceTabViewModel, PriceTabViewStyle>
{
  /* CONSTRUCTOR */
  constructor(props: PriceTabViewProps) {
    super(props);

    // Binding
    this.bind(new PriceTabViewModel(this), new PriceTabViewStyle(this));
  }

  /* RENDERING */
  render() {
    const {
      mappedPrices,
      orderSources,
      isDirty,
      isLoading,
      currentSelectedProduct,
      storeGroups,
    } = this.state;

    return (
      <View style={this.style.container}>
        <HeaderView
          buttonIsLoading={this.state.buttonIsLoading}
          disabled={isDirty || !!currentSelectedProduct?.isParent}
          onSaveClick={this.binding.updatePrices}
          title="tools.products.tabs.price"
        />
        {!isLoading ? (
          storeGroups.length > 0 ? (
            <View style={this.style.storeGroupContainer}>
              <View style={this.style.topLabels}>
                {orderSources.map((os) => (
                  <LabelViewView key={os.id} label={os.label} position='top' />
                ))}
              </View>
              {mappedPrices.map((m) => (
                <View key={m.id} style={this.style.row}>
                  <LabelViewView label={m.name} position='left' />
                  <View style={this.style.inputs}>
                    {m.sources.map((source) => (
                      <View style={this.style.inputView} key={source.id}>
                        <TextInput
                          onBlur={(e) => this.binding.onBlur(e, source, m)}
                          style={this.style.input}
                          value={`${this.binding.getPrice(source)}`}
                          onChangeText={(e) => this.binding.onChange(e, source, m)}
                          keyboardType="numeric"
                        />
                        <Text style={this.style.euroLabel}> €</Text>
                      </View>
                    ))}
                  </View>
                </View>
              ))}
            </View>
          ) : <NoDataPlaceholderView noDataLabel="tools.product.storeGroup.empty" />
        ) : (
          <LottieView height={150} width={150} source={require('src/Common' + Constants.LottieAnimationPath)} />
        )}
      </View>
    );
  }
}