import { Service, Application, HttpManager, Managers } from '@levelapp/softfabric';

import AddProductGroup from 'src/Common/DTO/AddProductGroup';
import ProductGroup from 'src/Common/DTO/ProductGroup';

export default class ProductGroupService extends Service {
  httpClient = Application.current.resolve<HttpManager>(Managers.HTTP_MANAGER);

  async get(): Promise<ProductGroup[]> {
    const response = await this.httpClient.get('productGroup');

    if (response && response.data) {
      return response.data.data;
    }

    return [];
  }

  async post(productGroup: AddProductGroup): Promise<ProductGroup> {
    const response = await this.httpClient.post('productGroup', [], { ...productGroup });

    if (response && response.data) {
      return response.data.data;
    }

    return Promise.reject();
  }

  async update(productGroup: AddProductGroup): Promise<ProductGroup> {
    const response = await this.httpClient.put('productGroup', [], productGroup);

    if (response && response.data) {
      return response.data.data;
    }

    return Promise.reject();
  }

  async addSingleProduct(products: any[]): Promise<ProductGroup> {
    const response = await this.httpClient.put('productGroup/single-product', [], products);

    if (response && response.data) {
      return response.data.data;
    }

    return Promise.reject();
  }

  async delete(productGroupId: number): Promise<boolean> {
    const response = await this.httpClient.delete(`productGroup/${productGroupId}`);

    if (response && response.data) {
      return response.data.data;
    }

    return Promise.reject();
  }

  async removeProductFromGroup(productId: number): Promise<boolean> {
    const response = await this.httpClient.delete(`productGroup/products/${productId}`);

    if (response && response.data) {
      return response.data.data;
    }

    return Promise.reject();
  }
}