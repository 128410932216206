import { Service, Application, HttpManager, Managers } from '@levelapp/softfabric';
import Dashboard from '../Common/DTO/Dashboard';


export default class DashboardsService extends Service
{
    httpClient = Application.current.resolve<HttpManager>(Managers.HTTP_MANAGER);

    async get(parameters: any[], onSuccess: Function)
    {
        this.httpClient
            .get('dashboards', parameters)
            .then((response: any) =>
            {
                if (response)
                {
                    if (response.data)
                    {
                        onSuccess(response.data);
                    }
                }
            });
    }

    async getDashboardTypes(parameters: any[], onSuccess: Function)
    {
        this.httpClient
            .get('dashboards/types', parameters)
            .then((response: any) =>
            {
                if (response)
                {
                    if (response.data)
                    {
                        onSuccess(response.data.data);
                    }
                }
            });
    }


    async getwithparamaters(parameters: any[], onSuccess: Function)
    {
        this.httpClient
            .get('dashboards/parameters', parameters)
            .then((response: any) =>
            {
                if (response)
                {
                    if (response.data)
                    {
                        onSuccess(response.data);
                    }
                }
            });
    }

    post(dashboard:Dashboard, callback : Function = () => {})
    {
        this.httpClient
            .post('dashboards', [], dashboard)
            .then((response : any) => 
            {
                if (response)
                {
                    
                    callback("");
                }
            });
    }

    put(dashboard:Dashboard, callback : Function = () => {})
    {
        this.httpClient
            .put('dashboards', [], dashboard)
            .then((response : any) => 
            {
                if (response)
                {
                  
                    callback("");
                }
            });
    }

    delete(dashboards:Dashboard, callback : Function = () => {})
    {
        this.httpClient
            .delete('dashboards/delete/'+dashboards.id)
            .then((response : any) => 
            {
                if (response)
                {
                   
                    callback("");
                }
            });
    }
}