import { Service, Application, HttpManager, Managers } from '@levelapp/softfabric';
import Brand from '../Common/DTO/Brand';

export default class BrandService extends Service {
  httpClient = Application.current.resolve<HttpManager>(Managers.HTTP_MANAGER);

  async get(): Promise<Brand[]> {
    const response = await this.httpClient.get('brand');

    if (response && response.data) {
      return response.data.data;
    }

    return Promise.reject();
  }
}