import { Application, ContentViewStyle } from '@levelapp/softfabric';
import { StyleManager, UiManagers } from '@levelapp/softfabric-ui';
import { StyleProp, TextStyle } from 'react-native';

export default class RefreshProductViewStyle extends ContentViewStyle
{
    styleManager: StyleManager = Application.current.resolve<StyleManager>(UiManagers.STYLE_MANAGER);

    refreshBtn: StyleProp<any> = {
        paddingVertical: 10,
        paddingHorizontal: 13,
        borderColor: '#358DFF',
        backgroundColor: 'white',
        borderRadius: 5,
        marginHorizontal: 4,
        height: 45,
        justifyContent: 'center',
        alignItems: 'center',
        boxShadow: '0 2px 4px 2px rgba(164,164,164,0.5)'
    };

    refreshBtnTxt: StyleProp<TextStyle> = {
        fontSize: 14,
        color : '#358DFF',
        fontFamily : 'Montserrat Regular',
        
    };
}