 
import {ContentView} from '@levelapp/softfabric';
import InformationsSectionViewState from './InformationsSectionViewState';
import InformationsSectionViewProps from './InformationsSectionViewProps';
import InformationsSectionViewStyle from './InformationsSectionViewStyle';
import InformationsSectionViewModel from './InformationsSectionViewModel';
import { View } from 'react-native';
import ExtendedTextInputView from '../../../../../../../../components/extendedtextinput/ExtendedTextInputView';
import ExtendedCheckBoxView from '../../../../../../../../components/extendedcheckbox/ExtendedCheckBoxView';
import ExtendedDropDownView from '../../../../../../../../components/extendeddropdown/ExtendedDropDownView';
import BoosterType from '../../../../../../../../../../../Common/DTO/BoosterType';
import SectionTitleView from '../../../../../../../../components/sectiontitle/SectionTitleView';

export default class InformationsSectionView extends ContentView<InformationsSectionViewProps, InformationsSectionViewState, InformationsSectionViewModel, InformationsSectionViewStyle>
{
  /* CONSTRUCTOR */
  constructor(props:InformationsSectionViewProps)
  {
    super(props);

    // Binding
    this.bind(new InformationsSectionViewModel(this), new InformationsSectionViewStyle(this));
  }

  /* RENDERING */
  render()
  {



    return (
      <View>
          <SectionTitleView title={'form.informations'}/>
          <ExtendedTextInputView
              title={"form.id"}
              disable
              showError={false}
              value={`${this.props.booster.id == undefined ? ' ' :this.props.booster.id}`}
              onChangeText={() =>{}}
            />
          <ExtendedDropDownView
              title={'BoosterType'}
              values={this.props.boosterTypes}
              selectedValue={this.props.booster.boosterType}
              onValueChange={(value : BoosterType) => this.props.handleDropDownChange(value)}
            />
            <ExtendedTextInputView
              title={"form.pointstoearn"}
              showError={this.props.showErrors && (this.props.booster.pointsToEarn == null || this.props.booster.pointsToEarn < 0) }
              value={`${this.props.booster.pointsToEarn}`}
              onChangeText={(text: string) => this.props.handleValueChange('pointstoearn',text)}
            />     

            <ExtendedCheckBoxView 
              isTranslation
              title={'form.preview'} 
              value={this.props.booster.isPreview} 
              onValueChange={(value : boolean) => this.props.handleCheckboxChange('isPreviewVisible',value)}/>

        </View>

      
    );
  }
}