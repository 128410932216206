import { ContentViewModel, ContentView, Application, LanguageManager, Managers } from '@levelapp/softfabric';
import BoostersViewState from './BoostersViewState';
import BoostersViewProps from './BoostersViewProps';
import { TableRow } from '@levelapp/softfabric-ui';
import BoostersOperations from '../../../../../../../Transfer/BoostersOperations';
import BoosterPopUpView from '../boosterpopup/BoosterPopUpView';
 
import LanguagesOperations from '../../../../../../../Transfer/LanguagesOperations';
import Helpers from '../../../../../../../Common/Helpers/Helpers';
import ConfirmPopUpView from '../../../../../../../Common/Components/confirmpopup/ConfirmPopUpView';
import Booster from '../../../../../../../Common/DTO/Booster';
import BoosterTypesOperations from '../../../../../../../Transfer/BoosterTypesOperations';
import BoosterType from '../../../../../../../Common/DTO/BoosterType';
import ProductsOperation from "../../../../../../../Transfer/ProductsOperations";
import ToastHelper from '../../../../../../../Common/Helpers/ToastHelper';
import Language from '../../../../../../../Common/DTO/Language';
import PaginationHelper from '../../../../../../../Common/Helpers/PaginationHelper';
import ErrorHelper from '../../../../../../../Common/Helpers/ErrorHelper';
import ContentTypeKeyOperations from '../../../../../../../Transfer/ContentTypeKeyOperations';
import ContentTypeKey from '../../../../../../../Common/DTO/ContentTypeKey';
import { ContentTypeEnum } from '../../../../../../../Common/Enums/ContentTypeEnum';
import Constants from '../../../../../../../Common/Constants/Constants';


export default class BoostersViewModel extends ContentViewModel<BoostersViewState, BoostersViewProps>
{
    operation: BoostersOperations;
    languagesOperation: LanguagesOperations;
    boosterTypesOperations: BoosterTypesOperations;
    productsOperations: ProductsOperation;
    contentTypeKeyOperation: ContentTypeKeyOperations;
    _languages: Language[] = [];
    _contentTypeKeys : ContentTypeKey [] = [];
    interval: any;
    searchText: string;
    isSorting: boolean;
    indexHeaderSort: number;
    sortOrder: string;
    sortKey: string[];
    moment: any;
    _boosterTypes: BoosterType[] = [];
    _posIds: any[] = [];

    constructor(props: ContentView) {
        super(props);

        this.interval = null;
        this.searchText = '';
        this.isSorting = false;
        this.indexHeaderSort = -1;
        this.sortOrder = 'asc';
        this.sortKey = ['Id', 'PointsToEarn', 'Title', 'Img'];
        this.moment = require('moment');

        /* Initial State */
        this.initialState({ tableData: [], tableHeaders: ['Id', 'Points to earn', 'Title', 'Img'], isLoading: true, nbPage: 0, currentPage: 1, isResearching: false });

        this.operation = new BoostersOperations();
        this.languagesOperation = new LanguagesOperations();
        this.boosterTypesOperations = new BoosterTypesOperations();
        this.contentTypeKeyOperation = new ContentTypeKeyOperations();
        this.productsOperations = new ProductsOperation();

        this.languagesOperation.get((languages: any) => {
            this._languages = languages.data;
        });
        this.boosterTypesOperations.get((data: any) => {
            this._boosterTypes = data.data;
        });

        Constants.UseSettingsContent && this.contentTypeKeyOperation.getContentTypeKeys(ContentTypeEnum.BOOSTER,(contentTypeKeys:ContentTypeKey[]) => {
            this._contentTypeKeys = contentTypeKeys;
        },this.errorManagement)

        this.productsOperations.get((data: any) => {
            data.forEach((pos: any) => {
                if (pos.posId !== null && pos.title !== null)
                {
                    if(pos.title.translationValues !== null)
                    {
                        let productId = pos.id;
                        let posId = pos.posId;
                        
                        let search = pos.posId + " " +(pos.title.translationValues == null|| pos.title.translationValues.length==0) ? "": pos.title.translationValues[0].value;
                        this._posIds.push({ productId: productId, posId: posId, search: search });
                    }
                }
            })
        }, this.errorManagement);

        /* BINDINGS */
        this.editField = this.editField.bind(this);
        this.addField = this.addField.bind(this);
        this.deleteField = this.deleteField.bind(this);
        this.refresh = this.refresh.bind(this);
        this.getData = this.getData.bind(this);
        this.onPageChange = this.onPageChange.bind(this);
        this.onSortChange = this.onSortChange.bind(this);
        this.handleResearching = this.handleResearching.bind(this);
        this.errorManagement = this.errorManagement.bind(this);
        this.handleCloseResearching = this.handleCloseResearching.bind(this);
    }
    componentDidMount() {
        this.refresh();
    }

    refresh() {
        this.setField({ isLoading: true });
        let parameters =PaginationHelper.basicResearchPush(
            this.state().currentPage,
            20,
            this.sortKey[this.indexHeaderSort],
            this.sortOrder,
            Application.current.resolve<LanguageManager>(Managers.LANGUAGE_MANAGER).language ,
            this.searchText,
            this.isSorting,
            this.state().isResearching);
        this.getData(parameters);
    }

    getData(queryParameters: any[]) {
        this.operation.get(queryParameters, (boosters: any) => {
            let tableData: TableRow[] = [];
            boosters.data.objects.forEach((booster: Booster) => {
                let title = booster.titleTranslations[0].value;
                let img = booster.imageTranslation[0].url;
                tableData.push({ data: [booster.id, booster.pointsToEarn, title, img], isDeletable: true, isEditable: true, editCallback: () => { this.editField(booster) }, deleteCallback: () => { this.deleteField(booster) } });
            });
            this.setField({ tableData: tableData, boosters: boosters.data.object, nbPage: boosters.data.totalPage, isLoading: false });
        },this.errorManagement);
    }

    addField() {

        this.showPopUp(
            <BoosterPopUpView
                booster={{
                    pointsToEarn:0,
                    isPreview : false,
                    titleTranslations: Helpers.generateTranslationTable(this._languages),
                    descriptionTranslations: Helpers.generateTranslationTable(this._languages),
                    imageTranslation: Helpers.generateUrlTable(this._languages),
                    thumbnailTranslation: Helpers.generateUrlTable(this._languages),
                    
                    ltos: [{
                        titleTranslations: Helpers.generateTranslationTable(this._languages),
                        startDate: new Date(),
                        endDate: new Date(),
                        posIds: [],
                        StartDateInput : new Date(),
                        EndDateInput : new Date(),
                    }],
                    boosterType: {
                        title : '',
                        id: this._boosterTypes[0].id,
                        name: this._boosterTypes[0].name
                    },
                    contentTypeKeyValue:[],

                }}
                languages={this._languages}
                boosterTypes={this._boosterTypes}
                refreshParent={this.refresh}
                contentTypeKeys={this._contentTypeKeys} 
                posIds={this._posIds} />

        );
    }

    editField(booster: Booster) {

        let boosterCopy = Helpers.clone(booster);
        for (let i = 0; i < boosterCopy.ltos.length; i++) {
            boosterCopy.ltos[i].StartDateInput = new Date(boosterCopy.ltos[i].startDate);
            boosterCopy.ltos[i].EndDateInput = new Date(boosterCopy.ltos[i].endDate);
        }
        boosterCopy.imageTranslation = Helpers.generateUrlTableEdit(boosterCopy.imageTranslation, this._languages);
        boosterCopy.thumbnailTranslation = Helpers.generateUrlTableEdit(boosterCopy.thumbnailTranslation, this._languages);
        this.showPopUp(
            <BoosterPopUpView booster={boosterCopy} refreshParent={this.refresh} languages={this._languages} boosterTypes={this._boosterTypes} posIds={this._posIds} contentTypeKeys={this._contentTypeKeys} />
        );

    }

    deleteField(booster: any) {
        this.showPopUp(
            <ConfirmPopUpView callBack={() => {
                this.operation.delete(booster, () => {
                    this.showToast(ToastHelper.getToast("boosters.popup.success.deleted","success"),() => {},2500);
                    this.refresh();
                },this.errorManagement);
            }} />
        );
    }

    

    onPageChange(page: number) {
        this.state().currentPage = page + 1;
        this.refresh()
    }

    onSortChange(indexHeaderSort: number, sortOrder: string) {
        if (indexHeaderSort == -1) {
            this.isSorting = false;
        } else {
            this.indexHeaderSort = indexHeaderSort;
            this.sortOrder = sortOrder;
            this.isSorting = true;
        }
        this.refresh();
    }

    handleResearching(searchText: string) {
        this.setField({ isLoading: true, isResearching: true });
        this.searchText = searchText;

        if (this.interval) {
            clearInterval(this.interval);
        }
        this.interval = setTimeout(() => {
            this.onPageChange(0)
        }, 1000)
    }

    handleCloseResearching() {
        this.state().isResearching = false;
        this.onPageChange(0);
    }

    errorManagement(error : any) 
    {
        this.setField({isLoading : false});
        ErrorHelper.showMessageError(error.response);
    }

}