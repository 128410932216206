import {ContentViewStyle} from '@levelapp/softfabric';
import {StyleProp,ViewStyle} from 'react-native';

export default class ManuealEarnedPointsDetailsViewStyle extends ContentViewStyle
{
    page: StyleProp<ViewStyle> = {
        flex: 1
    }

    textArea: StyleProp<any> = {
        width: 300,
        height : 60,
        paddingHorizontal: 5,
        marginTop: 15,
        marginLeft: 20,
        borderRadius : 8,
        boxShadow: '0 5px 14px 0 rgba(0,0,0,0.1)',
    };

    title: StyleProp<any> = {
        textAlign: 'left',
        color: 'white',
        fontSize: 20,
        fontWeight: '600',
        marginTop: 20,
        marginLeft: 20,
        paddingBottom: 20,
        display: "flex",
        flexDirection: "column",
        flexBasis: "85%"
    };
}