 
import { ContentView } from '@levelapp/softfabric';
import SettingsPopupViewState from './SettingsPopupViewState';
import SettingsPopupViewProps from './SettingsPopupViewProps';
import SettingsPopupViewStyle from './SettingsPopupViewStyle';
import SettingsPopupViewModel from './SettingsPopupViewModel';
import { LottieView, TranslationView } from '@levelapp/softfabric-ui';
import { Animated, TouchableWithoutFeedback, View } from 'react-native';
import ExtendedTextInputView from '../../../../components/extendedtextinput/ExtendedTextInputView';
import PopupHeaderView from '../../../../components/popupheader/PopupHeaderView';
import Helpers from '../../../../../../../Common/Helpers/Helpers';
import SettingsLineView from './components/settingsline/SettingsLineView';

export default class SettingsPopupView extends ContentView<SettingsPopupViewProps, SettingsPopupViewState, SettingsPopupViewModel, SettingsPopupViewStyle>
{
  /* CONSTRUCTOR */
  constructor(props: SettingsPopupViewProps)
  {
    super(props);

    // Binding
    this.bind(new SettingsPopupViewModel(this), new SettingsPopupViewStyle(this));
  }

  /* RENDERING */
  render()
  {

    return (
      <Animated.View style={[this.style.kitchenModus, { transform: [{ translateY: 0 }] }]}>
        <PopupHeaderView
          full
          handleBackPressed={Helpers.closePopup}
          title={`kitchenapp.settings`}
          shouldShowButton={false}
          isLoading={false}
        />
        <View style={{ paddingVertical: 25, paddingLeft: 60, paddingRight: 20 }}>
          <SettingsLineView 
            infoKey={"kitchenapp.iskioskactive.info"} titleKey={"kitchenapp.iskioskactive.title"}
            onClick={() => this.binding.updateTemporary('kiosk')} isLoading={false}
            type={"toggle"} value={this.state.isKioskTemporaryDisabled}
          />
          {this.binding.hasClickAndCollect() && (
          <SettingsLineView 
            infoKey={"kitchenapp.isccactive.info"} titleKey={"kitchenapp.isccactive.title"}
            onClick={() => this.binding.updateTemporary('cc')} isLoading={false}
            type={"toggle"} value={this.state.isClickAndCollectTemporaryDisabled}
          />
          )}
          <SettingsLineView 
            infoKey={"kitchenapp.shownewestfirst.info"} titleKey={"kitchenapp.shownewestfirst.title"}
            onClick={() => this.binding.updateShowNewestOrdersFirst()} isLoading={false}
            type={"toggle"} value={this.state.showNewestOrdersFirst}
          />
          <SettingsLineView 
            infoKey={"kitchenapp.hidecontent.info"} titleKey={"kitchenapp.hidecontent.title"}
            onClick={() => this.binding.updateHideContent()} isLoading={false}
            type={"toggle"} value={this.state.hideContent}
          />
          <SettingsLineView 
            infoKey={"kitchenapp.ringsettings.info"} titleKey={"kitchenapp.ringsettings.title"}
            onClick={() => this.binding.updateRingingStatus(!this.state.isRingingLongerActivated)} isLoading={false}
            type={"toggle"} value={this.state.isRingingLongerActivated}
          />
          <SettingsLineView 
            infoKey={"kitchenapp.kitchenmodus.info"} titleKey={"kitchenapp.kitchenmodus.title"}
            onClick={() => this.binding.updateKitchenModus()} isLoading={false}
            type={"toggle"} value={this.state.isKitchenModusEnabled}
          />
          <SettingsLineView 
            infoKey={"kitchenapp.export.info"} titleKey={"kitchenapp.export.title"}
            buttonKey={"kitchenapp.export"}
            onClick={() => this.props.onExport()} isLoading={this.state.isLoadingExport}
            type={"button"} value={false}
          />
          
          {this.binding.hasPrinters() && (
            <View style={{ flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', paddingVertical: 10 }}>
              <ExtendedTextInputView
                uppercase={false}
                styleTitle={this.style.kitchenModusTxt}
                title={"kitchenapp.iptext"}
                showError={false}
                value={`${this.state.ip}`}
                disable={!this.state.changeIp}
                onChangeText={(text: string) => this.binding.handleIpChanged(text)}
              />
              <View style={{ alignItems: 'flex-start' }}>

                {this.state.changeIp ? (<View style={this.style.dayButtonsContainer}>
                  <TouchableWithoutFeedback disabled={this.state.isLoadingExport} onPress={() => this.binding.storeIp()}>
                    <View style={this.style.todayButton}>
                      {this.state.isLoadingExport ? <LottieView height={150} width={150} source={require('../../assets/loading.json')} /> : <TranslationView style={{ color: '#358DFF' }}>generic.button.save</TranslationView>}
                    </View>
                  </TouchableWithoutFeedback>
                </View>) :
                  (<View style={this.style.dayButtonsContainer}>
                    <TouchableWithoutFeedback disabled={this.state.isLoadingExport} onPress={() => this.binding.handleEditIp()}>
                      <View style={this.style.todayButton}>
                        {this.state.isLoadingExport ? <LottieView height={150} width={150} source={require('../../assets/loading.json')} /> : <TranslationView style={{ color: '#358DFF' }}>generic.button.change</TranslationView>}
                      </View>
                    </TouchableWithoutFeedback>
                    <TouchableWithoutFeedback onPress={() => this.props.testPrint()}>
                      <View style={[this.style.todayButton, { marginLeft: 10 }]}>
                        <TranslationView style={{ color: '#358DFF' }}>kitchenapp.testprint.title</TranslationView>
                      </View>
                    </TouchableWithoutFeedback>
                  </View>)}
              </View>
            </View>
          )}
        </View>






      </Animated.View>
    );
  }
}