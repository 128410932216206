import {ContentViewModel,ContentView} from '@levelapp/softfabric';
import PopupMenuItemViewState from './PopupMenuItemViewState';
import PopupMenuItemViewProps from './PopupMenuItemViewProps';

export default class PopupMenuItemViewModel extends ContentViewModel<PopupMenuItemViewState, PopupMenuItemViewProps>
{
      /* CONSTRUCTOR */
    constructor(view: ContentView)
    {
        super(view);

        this.initialState({}); // Initialize your state here

        // Bindings

    }
}