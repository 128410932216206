const assets = {
  arrowDownGray: require('./arrow-down-gray.svg').default,
  arrowDown: require('./arrow-down.svg').default,
  arrowRightGray: require('./arrow-right-gray.svg').default,
  arrowRight: require('./arrow-right.svg').default,
  noData: require('./no-data-placeholder.svg').default,
  trashIcon: require('./trash-solid.svg').default,
  buttonLoader: require('./button-loader.json'),
  vDots: require('./ellipsis-v-solid.svg').default,
  resto: require('./resto.svg').default,
  updateDelivery: require('./update-delivery.svg').default,
  deliverySettings: require('./delivery-settings.svg').default,
};

export default assets;
