import {ContentViewModel,ContentView} from '@levelapp/softfabric';
import MainInformationViewState from './MainInformationViewState';
import MainInformationViewProps from './MainInformationViewProps';

export default class MainInformationViewModel extends ContentViewModel<MainInformationViewState, MainInformationViewProps>
{
      /* CONSTRUCTOR */
    constructor(view: ContentView)
    {
        super(view);

        this.initialState({}); // Initialize your state here

        // Bindings

    }
}