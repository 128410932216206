 
import { ContentView } from '@levelapp/softfabric';
import MainInformationViewState from './MainInformationViewState';
import MainInformationViewProps from './MainInformationViewProps';
import MainInformationViewStyle from './MainInformationViewStyle';
import MainInformationViewModel from './MainInformationViewModel';
import { View } from 'react-native';
import InformationSectionView from './components/informationsection/InformationSectionView';
import LineView from '../../../../../../components/line/LineView';
import PointsSectionView from './components/pointssection/PointsSectionView';
import HistorySectionView from './components/historysection/HistorySectionView';
import DeleteSectionView from './components/deletesection/DeleteSectionView';
import AdvancedInformationSectionView from './components/advancedinformationsection/AdvancedInformationSectionView';
import Constants from '../../../../../../../../../Common/Constants/Constants';

export default class MainInformationView extends ContentView<MainInformationViewProps, MainInformationViewState, MainInformationViewModel, MainInformationViewStyle>
{
  /* CONSTRUCTOR */
  constructor(props: MainInformationViewProps)
  {
    super(props);

    // Binding
    this.bind(new MainInformationViewModel(this), new MainInformationViewStyle(this));
  }

  /* RENDERING */
  render()
  {
    const { container } = this.style;

    return (
      <View style={container}>
        {/*Section informations utilisateur */}
        <View style={{ flexDirection: "row" }}>
        <InformationSectionView
          user={this.props.user}
          handleValueChange={this.props.handleValueChange}
          handleCheckboxChange={this.props.handleCheckboxChange} />
          {(Constants.ShowUserAdvancedProfile || Constants.ShowUserOptins || Constants.ShowUserMemberships) &&
          <AdvancedInformationSectionView user={this.props.user} isLoadingAdvancedInformations={this.props.isLoadingAdvancedInformations}>
          </AdvancedInformationSectionView>
          }
          
        </View>

        <LineView />

        {/*section delete user */}
        <DeleteSectionView
          showInternalPopup={this.props.showInternalPopup}
          user={this.props.user}
        />

        <LineView />

        {/*Section des points d'un utilisateur */}
        <PointsSectionView
          user={this.props.user}

          handleValueChange={this.props.handleValueChange}
        />
        <LineView />

        {/*section de l'historique d'un utilisateur*/}
        <HistorySectionView
          showInternalPopup={this.props.showInternalPopup}
          refreshMainInfo={this.props.refreshMainInfo}
          user={this.props.user}
          saveUser={this.props.saveUser} 
          refresh={this.props.refresh}
        />
      </View>
    );
  }
}