 
import {ContentView} from '@levelapp/softfabric';
import {  Text, Animated,  View, TouchableWithoutFeedback } from 'react-native';
import ProfileMenuViewState from './ProfileMenuViewState';
import ProfileMenuViewProps from './ProfileMenuViewProps';
import ProfileMenuViewStyle from './ProfileMenuViewStyle';
import ProfileMenuViewModel from './ProfileMenuViewModel';
import Constants from '../../../../../Common/Constants/Constants';
import { TranslationView } from '@levelapp/softfabric-ui';

export default class ProfileMenuView extends ContentView<ProfileMenuViewProps, ProfileMenuViewState, ProfileMenuViewModel, ProfileMenuViewStyle>
{
  /* CONSTRUCTOR */
  constructor(props:ProfileMenuViewProps)
  {
    super(props);

    // Binding
    this.bind(new ProfileMenuViewModel(this), new ProfileMenuViewStyle(this));
  }

  /* RENDERING */
  render()
  {

    this.binding.animation();

    const transitionX = this.binding._transition.interpolate(
    {
      inputRange : [0,1],
      outputRange : [-50 , -125]
    })

    const transitionY = this.binding._transition.interpolate(
      {
        inputRange : [0,1],
        outputRange : [50, 170]
      })

    const scaleX = this.binding._transition.interpolate(
    {
      inputRange : [0,1],
      outputRange : [0 , 1]
    })

    const scaleY = this.binding._transition.interpolate(
      {
        inputRange : [0,1],
        outputRange : [0, 1]
      })

    const opacity = this.binding._transition.interpolate(
      {
        inputRange : [0,1],
        outputRange : [0 , 1]
      })

    const hover = this.binding._opacityAnimation.interpolate(
      {
        inputRange : [0,1],
        outputRange : [0,0.1],
      })

      
    return (
      <Animated.View style={[this.style.box,{opacity : opacity , transform : [{ translateY : transitionY}, {translateX : transitionX} , {scaleX : scaleX} , {scaleY : scaleY}]}]}>
        <View style={this.style.cosmos}>
          <Text style={this.style.text}>{this.props.user != null ? this.props.user.email : ''}</Text>
          <Text style={this.style.text}>{this.props.user != null ? this.props.user.roles != undefined && this.props.user.roles.length > 0 ? this.props.user.roles[0] : '' : ''}</Text>
          <View style={this.style.container}/>
          <TouchableWithoutFeedback onPress={() =>{ this.binding.logout()}}>
          <View style={{zIndex : 1}}>
            <View style={[this.style.link , {position : 'absolute' , zIndex : 1}]}>
              <div style={{width : '100%' ,  height : '100%'}} onMouseEnter={() => this.binding.handleAnimation(1)} onMouseLeave={() => this.binding.handleAnimation(0)}>
                <Animated.View style={[{width : '100%' , height : '100%' , backgroundColor : Constants.GradiantMenuStartColor , opacity : hover}]}/>
              </div>
            </View>
            <TranslationView style={[this.style.linkText , this.style.link]}>
              logout.title
            </TranslationView>  
          </View>
       
        </TouchableWithoutFeedback>
        </View>
      </Animated.View>
    );
  }
}