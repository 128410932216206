
import { ContentViewModel, ContentView, Application, LanguageManager, Managers } from '@levelapp/softfabric';
import OrdersViewState from './OrdersViewState';
import OrdersViewProps from './OrdersViewProps';
import StoresOperations from '../../../../../../../Transfer/StoresOperations';
import Store from '../../../../../../../Common/DTO/Store';
import BasketOperations from '../../../../../../../Transfer/BasketOperations';

import Basket from '../../../../../../../Common/DTO/Basket';
import ConfirmPopUpView from '../../../../../../../Common/Components/confirmpopup/ConfirmPopUpView';
import TableRow from '../../../../../../../Common/Components/TabelView/TableRow';
import ToastHelper from '../../../../../../../Common/Helpers/ToastHelper';
import PermissionHelper from '../../../../../../../Common/Helpers/PermissionHelper';
import ShowOrdersExtraData from '../../../../../../../Common/DTO/ExtraData/ShowOrdersExtraData';
import { OrderStatus } from '../../../../../../../Common/Enums/OrderStatus';
import { View, Text } from 'react-native';
import { ButtonView, TranslationView } from '@levelapp/softfabric-ui';
import OrdersViewStyle from './OrdersViewStyle';
import { Howl } from 'howler'
import BaseBasket from '../../../../../../../Common/DTO/BaseBasket';

import AdminProductOperations from '../../../../../../../Transfer/AdminProductOperations';
import { OrderSources } from '../../../../../../../Common/Enums/OrderSources';
import ErrorHelper from '../../../../../../../Common/Helpers/ErrorHelper';
import { DeliveryProviderKeys } from 'src/Common/Enums/DeliveryProviders';

/**
 *  Last thing TODO:
 *  * Change the hardcoded FIXME block by translation of the Translations file.
 *  * Change in the View The LinearGradient to a component
 *
 *  @Robert
 */

export default class OrdersViewModel extends ContentViewModel<OrdersViewState, OrdersViewProps>
{
    private _refreshInterval: any;
    private _lastRefreshTime: Date;
    private _notificationSound: any;
    private _basketIdsSoundPlayed = new Set<number>();
    private _baskets: BaseBasket[] = [];
    _storeOperations: StoresOperations;
    _basketOperations: BasketOperations;
    _adminProductOperations: AdminProductOperations;
    isSorting: boolean;
    sortKey: string[];
    indexHeaderSort: number;
    sortOrder: string;
    moment: any;
    _allStatus: OrderStatus[] = [OrderStatus.Paid, OrderStatus.Preparing, OrderStatus.Prepared, OrderStatus.PickedUp, OrderStatus.Refunded, OrderStatus.ToPay];
    _orderSources: { [key: string]: { translationKey: string, value: OrderSources } } = {
        [OrderSources.APP]: { translationKey: 'orders.source.body.app', value: OrderSources.APP },
        [OrderSources.KIOSK]: { translationKey: 'orders.source.body.kiosk', value: OrderSources.KIOSK },
        [OrderSources.TILL]: { translationKey: 'orders.source.body.till', value: OrderSources.TILL },
        [OrderSources.DRIVE]: { translationKey: 'orders.source.body.drive', value: OrderSources.DRIVE },
        [OrderSources.WEB]: { translationKey: 'orders.source.body.web', value: OrderSources.WEB },
        [OrderSources.DELIVERY]: { translationKey: 'orders.source.body.delivery', value: OrderSources.DELIVERY }
    };

    _deliveries = {
        [DeliveryProviderKeys.DELIVEROO]: 'Deliveroo',
        [DeliveryProviderKeys.JUSTEEAT]: 'Just Eat',
        [DeliveryProviderKeys.TAKEAWAY]: 'Takeway',
        [DeliveryProviderKeys.ClickAndCollect]: 'Click & Collect',
        [DeliveryProviderKeys.UBEREATS]: 'Uber Eats',
    }


    /* CONSTRUCTOR */
    constructor(view: ContentView)
    {
        super(view);

        this.isSorting = true;
        //FIXME:
        this.sortKey = ['Id', 'ExternalOrderNumber', 'Price', 'Date'];
        this.indexHeaderSort = 3;
        this.sortOrder = 'DSC';
        this.moment = require('moment');
        this._lastRefreshTime = new Date();

        const storedOrderSourceOptions = localStorage.getItem("OrderSourceFilterOptions");
        const initialOrderSourceOptions = storedOrderSourceOptions === null ? [OrderSources.APP, OrderSources.KIOSK, OrderSources.DRIVE] : JSON.parse(storedOrderSourceOptions);


        const storedStatusSourceOptions = localStorage.getItem("OrderStatusFilterOptions");
        const initialOrderStatusOptions = storedStatusSourceOptions === null ? [OrderStatus.Preparing, OrderStatus.Prepared, OrderStatus.PickedUp, OrderStatus.ToPay] : JSON.parse(storedStatusSourceOptions);


        //FIXME:
        this.initialState({
            tableData: [],
            tableHeaders: ['Id', 'Order Number', 'Price', 'Date of Check-in', 'Source', 'Status', 'Actions'],
            isLoading: true,
            refreshingCache: false,
            nbPage: 0,
            currentPage: 1,
            isResearching: false,
            selectedStore: { id: 0, slug: "", title: "", isClickAndCollectEnabled: true, isClickAndCollectTemporaryDisabled: false, isKioskTemporaryDisabled: false, brandIds: [] },
            selectedStatuses: initialOrderStatusOptions,
            stores: [],
            newOrdersCount: 0,
            selectedOrderSources: initialOrderSourceOptions
        });

        this._storeOperations = new StoresOperations();
        this._basketOperations = new BasketOperations();
        this._adminProductOperations = new AdminProductOperations();

        this._storeOperations.get([], (paginationObject: any) =>
        {
            let storeTemp: Store[] = [];
            paginationObject.data.objects.forEach((store: Store) =>
            {

                /* EXTRA DATA BLOCK */
                var tmp = (PermissionHelper.getExtraDataOrders() as any);
                if (Object.keys(tmp).length == 0 || tmp == null || tmp == undefined)
                {
                    //ALL STORES DISPLAY
                    storeTemp.push({ ...store, slug: "", title: store.slug});
                } else
                {
                    var id = (JSON.parse(tmp) as ShowOrdersExtraData).restaurant;
                    if (id == store.id)
                    {
                        //DISPLAY ONLY THAT FROM BE
                        storeTemp.push({ ...store, slug: "", title: store.slug });
                    }
                }

            })
            var state = this.state();
            state.stores = storeTemp;
            if (storeTemp[0] != null)
                state.selectedStore = storeTemp[0];

            this.setField({ state });
            this.refresh();
        }, this.errorManagement);

        this._notificationSound = new Howl({
            src: [require('../../assets/notification.mp3').default]
        });

        this.onPageChange = this.onPageChange.bind(this);
        this.onSortChange = this.onSortChange.bind(this);
        this.handleStoreChanged = this.handleStoreChanged.bind(this);
        this.confirmOrder = this.confirmOrder.bind(this);
        this.getNewOrdersInBackground = this.getNewOrdersInBackground.bind(this);
        this.handleStatusSelected = this.handleStatusSelected.bind(this);
        this.handleOrderSourceSelected = this.handleOrderSourceSelected.bind(this);
        this.isStatusSelected = this.isStatusSelected.bind(this);
        this.isOrderSourceSelected = this.isOrderSourceSelected.bind(this);
        this.getDropDownValues = this.getDropDownValues.bind(this);
        this.errorManagement = this.errorManagement.bind(this);
    }

    componentDidMount()
    {
        //this.refresh();
        this._refreshInterval = setInterval(this.getNewOrdersInBackground, 10000);
    }

    componentWillUnmount()
    {
        clearInterval(this._refreshInterval);
    }

    refresh()
    {
        this.setField({ isLoading: true });
        let parameters = [];
        parameters.push(
            { key: "storeId", value: this.state().selectedStore.id },
            { key: "page", value: this.state().currentPage },
            { key: "itemPage", value: 20 }
        );

        this.state().selectedStatuses.forEach(st =>
        {
            parameters.push({ key: "status", value: st })
        });

        this.state().selectedOrderSources.forEach(os =>
        {
            parameters.push({ key: "ordersource", value: os })
        })

        if (this.isSorting)
        {
            parameters.push(
                { key: 'columnSort', value: this.sortKey[this.indexHeaderSort] },
                { key: 'sortOrder', value: this.sortOrder },
                { key: 'userLanguage', value: Application.current.resolve<LanguageManager>(Managers.LANGUAGE_MANAGER).language });
        }
        this._lastRefreshTime = new Date();
        this.getData(parameters);
    }

    refreshCache()
    {
        this.setField({ refreshingCache: true });
        this._adminProductOperations.refreshCache(this.state().selectedStore, this.handleRefreshCacheResult = this.handleRefreshCacheResult.bind(this));
    }

    handleRefreshCacheResult()
    {
        this.setField({ refreshingCache: false });
        this.showToast(ToastHelper.getToast("orders.success.refreshProducts", "success"), () => { }, 2500);
    }

    async getNewOrdersInBackground()
    {
        const parameters = [{ key: "storeId", value: this.state().selectedStore.id },
        { key: "time", value: this.moment(this._lastRefreshTime).utc().format() }];

        this.state().selectedStatuses.forEach(st =>
        {
            parameters.push({ key: "status", value: st })
        });

        this.state().selectedOrderSources.forEach(os =>
        {
            parameters.push({ key: "ordersource", value: os })
        })

        const responses = await this._basketOperations.getNewBasketsFrom(parameters);
        if (responses && responses.data)
        {
            const baskets: BaseBasket[] = responses.data.data;
            const news = baskets.filter(x => !this._baskets.includes(x));

            if (news.length != 0)
            {
                this.setField({ newOrdersCount: news.length });

                // If no sound played yet => play sound
                const notPlayedIds = news.filter(x => (x.status == OrderStatus.Preparing || x.status == OrderStatus.ToPay) && !this._basketIdsSoundPlayed.has(x.id)).map(x => x.id);
                if (notPlayedIds.length != 0)
                {
                    notPlayedIds.forEach((n) => this._basketIdsSoundPlayed.add(n));
                    this._notificationSound.play();
                }
            }
        }
    }

    getData(queryParameter: any[])
    {
        this._basketOperations.get(queryParameter, (baskets: any) =>
        {
            let tableData: TableRow[] = [];
            const basketsTemp: BaseBasket[] = [];
            let stateBaskets = baskets.data.objects;

            stateBaskets.forEach((basket: Basket) =>
            {
                var stillUtc = this.moment.utc(basket.lastModificationDate).toDate();
                var local = this.moment(stillUtc).local()
                let date = "  " + local.format("DD-MM-YYYY, hh:mm:ss");
                let total = "  " + basket.paymentTotal + " €";
                basketsTemp.push({ id: basket.id, status: basket.status });
                tableData.push({ data: [basket.id, this.displayOrderNumber(basket), total, date, this.displayOrderSource(basket), this.displayStatus(basket), this.displayButton(basket)] })
            });
            this._baskets = basketsTemp;
            this.setField({ tableData: tableData, nbPage: baskets.data.totalPage, isLoading: false, newOrdersCount: 0 });
            this._basketIdsSoundPlayed.clear();
        });
    }

    getStatusTranslationKey(status: OrderStatus)
    {
        switch (status)
        {
            case OrderStatus.Paid:
                return 'orders.body.paid';

            case OrderStatus.Preparing:
                return 'orders.body.preparing';

            case OrderStatus.Prepared:
                return 'orders.body.prepared';

            case OrderStatus.PickedUp:
                return 'orders.body.pickedup';

            case OrderStatus.Refunded:
                return 'orders.body.refunded';

            case OrderStatus.ToPay:
                return 'orders.body.topay';
            default:
                return '';
        }
    }

    getStatusColor(status: OrderStatus)
    {
        switch (status)
        {
            case OrderStatus.Preparing:
                return 'green';

            case OrderStatus.Prepared:
                return '#007bff';

            case OrderStatus.PickedUp:
                return '#ff6600';

            case OrderStatus.Refunded:
                return '#ff0000';

            case OrderStatus.ToPay:
                return '#6f04cf';

            default:
                return '#a1a5a1';
        }
    }

    getDropDownValues()
    {
        return this.state().stores.sort(this.sortStore).map(x => { return { id: x.id, name: x.title, title: x.title } });
    }

    displayOrderNumber(basket: Basket): JSX.Element
    {

        const rawNumber = basket.desckNumber && basket.desckNumber !== "" ? basket.desckNumber : basket.externalOrderNumber;
        const number = rawNumber ? rawNumber.replace(" ", "\n") : "-";

        const { orderNumberTxt, orderNumberTxtMultiLine} = (this.style() as OrdersViewStyle);
        const textStyle = number.includes('\n') ? orderNumberTxtMultiLine : orderNumberTxt; 
        
        return (
            <View style={{ alignItems: 'center', minWidth: 120 }}>
                <Text style={orderNumberTxt}>-----</Text>
                <Text style={textStyle}>{number}</Text>
                <Text style={orderNumberTxt}>-----</Text>
            </View >
        );
    }

    displayOrderSource(basket: Basket)
    {
        if(basket.orderSourceId === OrderSources.DELIVERY && basket?.deliveryProvider?.key) 
                return ( 
                    <Text>{this._deliveries[basket.deliveryProvider.key]}</Text>
        );
        return (
            <TranslationView upperCase>{this._orderSources[basket.orderSourceId].translationKey}</TranslationView>
        );

    }

    displayStatus(basket: Basket): JSX.Element
    {
        return (
            <TranslationView style={{ color: this.getStatusColor(basket.status), fontWeight: 'bold', marginLeft: -10 }}>{this.getStatusTranslationKey(basket.status)}</TranslationView>
        );
    }

    displayButton(basket: Basket): JSX.Element
    {
        switch (basket.status)
        {
            case OrderStatus.Preparing:
                return (
                    <View style={(this.style() as OrdersViewStyle).buttonContainer}>
                        <ButtonView style={{ height: 90, width: 90, borderRadius: 200, alignItems: "center", justifyContent: "center" }} handleClick={() => this.confirmOrder(basket)} isLoading={false} animation="None" borders="Little" >
                            <View>
                                <TranslationView style={{ textAlign: 'center' }}>orders.body.markas</TranslationView>
                                <TranslationView style={{ textAlign: 'center', fontWeight: 'bold' }} upperCase>orders.body.prepared</TranslationView>
                            </View>
                        </ButtonView>
                    </View>
                );

            case OrderStatus.Prepared:
                return (
                    <View style={(this.style() as OrdersViewStyle).buttonContainer}>
                        <ButtonView style={{ height: 90, width: 90, borderRadius: 200, alignItems: "center", justifyContent: "center" }} backgroundColor="#ff6600" handleClick={() => this.markAsPickedUp(basket)} isLoading={false} animation="None" borders="Little" >
                            <View>
                                <TranslationView style={{ textAlign: 'center' }}>orders.body.markas</TranslationView>
                                <TranslationView style={{ textAlign: 'center', fontWeight: 'bold' }} upperCase>orders.body.pickedup</TranslationView>
                            </View>
                        </ButtonView>
                    </View>
                );

            default:
                return <View />
        }
    }

    confirmOrder(basket: Basket)
    {
        this.showPopUp(
            <ConfirmPopUpView title="orders.popup.title" noButtonStyle={{ height: 80, width: 80, borderRadius: 500 }} yesButtonStyle={{ height: 80, width: 80, borderRadius: 500 }} callBack={() => this.handleConfirmOrder(basket)} />
        );
    }

    markAsPickedUp(basket: Basket)
    {
        this.showPopUp(
            <ConfirmPopUpView title="orders.popup.titlepickedup" noButtonStyle={{ height: 80, width: 80, borderRadius: 500 }} yesButtonStyle={{ height: 80, width: 80, borderRadius: 500 }} callBack={() => this.handleOrderPickedUp(basket)} />
        );
    }

    async handleConfirmOrder(basket: Basket)
    {
        this.showToast(ToastHelper.getToast("orders.success.confirmorder", "success"), () => { }, 2500);
        await this._basketOperations.markAsPrepared(basket);
        this.refresh();
    }

    async handleOrderPickedUp(basket: Basket)
    {
        this.showToast(ToastHelper.getToast("orders.success.orderpickedup", "success"), () => { }, 2500);
        basket.status = OrderStatus.PickedUp;
        await this._basketOperations.updateStatus(basket);
        this.refresh();
    }

    handleStoreChanged(store: Store)
    {
        const newStore = this.state().stores.find(x => x.id == store.id);

        if (newStore)
        {
            this.setField((previousState) => previousState.selectedStore = newStore, () => this.refresh());
        }
    }

    handleClickAndCollectToggled()
    {
        const title = this.state().selectedStore.isClickAndCollectTemporaryDisabled ? 'orders.onlinetitle' : 'orders.offlinetitle'
        this.showPopUp(
            <ConfirmPopUpView title={title} noButtonStyle={{ height: 80, width: 80, borderRadius: 500 }} yesButtonStyle={{ height: 80, width: 80, borderRadius: 500 }} callBack={() => { this.updateClickAndCollectDisabled() }} />
        );
    }

    handleKioskToggled()
    {
        const title = this.state().selectedStore.isKioskTemporaryDisabled ? 'orders.kiosk.onlinetitle' : 'orders.kiosk.offlinetitle'
        this.showPopUp(
            <ConfirmPopUpView title={title} noButtonStyle={{ height: 80, width: 80, borderRadius: 500 }} yesButtonStyle={{ height: 80, width: 80, borderRadius: 500 }} callBack={() => { this.updateKioskDisabled() }} />
        );
    }


    handleStatusSelected(status: OrderStatus)
    {
        const state = this.state();

        if (state.selectedStatuses.includes(status))
        {
            state.selectedStatuses = state.selectedStatuses.filter(x => x != status);
        }
        else
        {
            state.selectedStatuses.push(status);
        }
        this.setField(state, () =>
        {
            this.refresh();
        });
        localStorage.setItem('OrderStatusFilterOptions', JSON.stringify(state.selectedStatuses));

    }

    handleOrderSourceSelected(orderSource: OrderSources): void
    {
        const state = this.state();
        const sourceIndex = state.selectedOrderSources.indexOf(orderSource);

        if (sourceIndex > -1)
        {

            state.selectedOrderSources.splice(sourceIndex, 1);
            this.setField(state, () => { this.refresh(); });
            localStorage.setItem('OrderSourceFilterOptions', JSON.stringify(state.selectedOrderSources));
            return;
        }

        state.selectedOrderSources.push(orderSource);
        this.setField(state, () => { this.refresh(); });
        localStorage.setItem('OrderSourceFilterOptions', JSON.stringify(state.selectedOrderSources));
    }


    isStatusSelected(status: OrderStatus)
    {
        return this.state().selectedStatuses.includes(status);
    }

    isOrderSourceSelected(orderSource: OrderSources): boolean
    {
        return this.state().selectedOrderSources.includes(orderSource);
    }

    onPageChange(page: number)
    {
        this.state().currentPage = page + 1;
        this.refresh()
    }

    onSortChange(indexHeaderSort: number, sortOrder: any)
    {
        if (indexHeaderSort == -1)
        {
            this.isSorting = false;
        } else
        {
            this.indexHeaderSort = indexHeaderSort;
            this.sortOrder = sortOrder;
            this.isSorting = true;
        }
        this.refresh();
    }

    private updateClickAndCollectDisabled()
    {
        // Update selected store
        const state = this.state();
        const newValue = !state.selectedStore.isClickAndCollectTemporaryDisabled;
        state.selectedStore.isClickAndCollectTemporaryDisabled = newValue;

        // Update store in list
        const storeIndex = state.stores.findIndex(x => x.id == state.selectedStore.id);
        if (storeIndex != -1)
        {
            const storesCopy = [...this.state().stores];
            storesCopy[storeIndex].isClickAndCollectTemporaryDisabled = newValue;
            state.stores = storesCopy;
        }

        this.setField(state, () =>
        {
            this._storeOperations.updateClickAndCollectTemporary(state.selectedStore, () => { });
        });
    }

    private updateKioskDisabled()
    {
        // Update selected store
        const state = this.state();
        const newValue = !state.selectedStore.isKioskTemporaryDisabled;
        state.selectedStore.isKioskTemporaryDisabled = newValue;

        // Update store in list
        const storeIndex = state.stores.findIndex(x => x.id == state.selectedStore.id);
        if (storeIndex != -1)
        {
            const storesCopy = [...this.state().stores];
            storesCopy[storeIndex].isKioskTemporaryDisabled = newValue;
            state.stores = storesCopy;
        }

        this.setField(state, () =>
        {
            this._storeOperations.updateKioskTemporary(state.selectedStore, () => { });
        });
    }

    private sortStore(store1: Store, store2: Store)
    {
        if (store1.title < store2.title)
        {
            return -1;
        }
        if (store1.title > store2.title)
        {
            return 1;
        }
        return 0;
    }

    errorManagement(error: any) 
    {
        this.setField({ isLoading: false });
        ErrorHelper.showMessageError(error.response);
    }

}
