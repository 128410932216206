import { ContentViewStyle, Application } from '@levelapp/softfabric';
import { ViewStyle, Dimensions, TextStyle } from 'react-native';
import { UiManagers, StyleManager } from '@levelapp/softfabric-ui';

export default class LoginViewStyle extends ContentViewStyle
{
    MyManagerStyle = Application.current.resolve<StyleManager>(UiManagers.STYLE_MANAGER);

    container: ViewStyle = {
        height: Dimensions.get('window').height,
        flex: 1,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    }

    loginBox: ViewStyle = {
        height: 400,
        width: 680,
        backgroundColor: 'white',
        borderRadius : 15,
        shadowColor : 'gray',
        shadowOpacity : 0.5,
        shadowOffset : {width : 1, height: 1},
        shadowRadius : 150,
    }
    

    boxTitle: ViewStyle = {
        width: 600,
        height: 150,
        borderRadius : 5,
        backgroundColor: this.MyManagerStyle.primaryColor,
        marginTop: -20,
        margin: 'auto'
    }

    title: TextStyle = {
        color: 'white',
        fontSize: 32,
        textAlign: 'center',
        padding: 40
    }

    loginContainer: ViewStyle = {
        display: 'flex',
        alignSelf: 'center',
        justifyContent: 'space-around',
        alignItems: 'center',
        height: 250,
        width: '100%',
    }

    textInput: ViewStyle = {
        minWidth : 300,
        borderColor : 'gray',
        borderRadius : 5,
        borderWidth : 1,
    }
}