import { ContentView } from '@levelapp/softfabric';
import ButtonViewState from './ButtonViewState';
import ButtonViewProps from './ButtonViewProps';
import ButtonViewStyle from './ButtonViewStyle';
import ButtonViewModel from './ButtonViewModel';
import { View, Text, TouchableOpacity } from 'react-native';
import { LottieView } from '@levelapp/softfabric-ui';
import Constants from '../../Constants/Constants';

export default class ButtonView extends ContentView<ButtonViewProps, ButtonViewState, ButtonViewModel, ButtonViewStyle>
{
  /* CONSTRUCTOR */
  constructor(props: ButtonViewProps)
  {
    super(props);

    // Binding
    this.bind(new ButtonViewModel(this), new ButtonViewStyle(this));
  }

  /* RENDERING */
  render()
  {
    return (
      <TouchableOpacity  activeOpacity={0.7} onPress={() =>{ this.props.isLoading == false && this.props.handleClick()}}>
        <View style={[this.props.style, this.style.button, { backgroundColor: this.props.backgroundColor != undefined ? this.props.backgroundColor : '#007bff' }]}>
          {this.props.isLoading ? (
            this.props.backgroundColor == Constants.GradiantStartColor ? <LottieView height={25} width={40} source={require('./assets/51-material-loader.json')} /> : 
            <LottieView height={25} width={40} source={this.props.backgroundColor == 'white' ? require('./assets/50-material-loader.json') : require('./assets/51-material-loader.json')} />
          )
            :
            (
              <Text style={{ color: this.props.textColor != undefined ? this.props.textColor : 'white' }}>
                {this.props.children}
              </Text>
            )
          }
        </View>
      </TouchableOpacity>
    );
  }
  
}