 
import {ContentShellView} from '@levelapp/softfabric';
import ShellMenuconfiguratorViewState from './ShellMenuconfiguratorViewState';
import ShellMenuconfiguratorViewProps from './ShellMenuconfiguratorViewProps';
import ShellMenuconfiguratorViewStyle from './ShellMenuconfiguratorViewStyle';
import ShellMenuconfiguratorViewModel from './ShellMenuconfiguratorViewModel';
import {View} from 'react-native';

export default class ShellMenuconfiguratorView extends ContentShellView<ShellMenuconfiguratorViewProps, ShellMenuconfiguratorViewState, ShellMenuconfiguratorViewModel, ShellMenuconfiguratorViewStyle>
{
  /* CONSTRUCTOR */
  constructor(props:ShellMenuconfiguratorViewProps)
  {
    super(props);

    // Binding
    this.bind(new ShellMenuconfiguratorViewModel(this), new ShellMenuconfiguratorViewStyle(this));
  }

  /* RENDERING */
  render()
  {
    return (
      <View style={{flex : 1}}>
        {this.getSwitch()}
      </View>
    );
  }
}