import { Operation } from "@levelapp/softfabric";
import PaymentSourceService from "../Proxy/PaymentSourceService";

export default class PaymentSourceOperation extends Operation
{
    service: PaymentSourceService;

    constructor()
    {
        super();
        this.service = new PaymentSourceService();
    }

    get(callback: Function)
    {
        this.service.get(callback);
    }
}