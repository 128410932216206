 
import {ContentView} from '@levelapp/softfabric';
import InformationSectionViewState from './InformationSectionViewState';
import InformationSectionViewProps from './InformationSectionViewProps';
import InformationSectionViewStyle from './InformationSectionViewStyle';
import InformationSectionViewModel from './InformationSectionViewModel';
import { View } from 'react-native';
import SectionTitleView from '../../../../../../../../components/sectiontitle/SectionTitleView';
import ExtendedTextInputView from '../../../../../../../../components/extendedtextinput/ExtendedTextInputView';
import ExtendedCheckBoxView from '../../../../../../../../components/extendedcheckbox/ExtendedCheckBoxView';
import ExtendedClickableTextView from '../../../../../../../../components/extendedclickabletext/ExtendedClickableTextView';
import RewardProduct from '../../../../../../../../../../../Common/DTO/RewardProduct';

export default class InformationSectionView extends ContentView<InformationSectionViewProps, InformationSectionViewState, InformationSectionViewModel, InformationSectionViewStyle>
{
  /* CONSTRUCTOR */
  constructor(props:InformationSectionViewProps)
  {
    super(props);

    // Binding
    this.bind(new InformationSectionViewModel(this), new InformationSectionViewStyle(this));
  }

  /* RENDERING */
  render()
  {
    return (
      <View>
        <SectionTitleView title={'form.informations'}/>
          <ExtendedTextInputView
                disable
                title={'form.id'}
                showError={false}
                value={`${this.props.reward.id == undefined ? ' ' :this.props.reward.id}`}
                onChangeText={() =>{}}
            />
          <View style={{flexDirection: 'row' }}>
            <ExtendedTextInputView
              style={{ zIndex: 1 }}
              title={'form.posid'}
              infoText={this.binding.translate('generic.posid.info').replace('{1}', this.binding.translate('rewards.title.singular').toLowerCase())}
              showError={this.props.showErrors && (this.props.reward.posId == null || this.props.reward.posId == "")}
              value={this.props.reward.posId}
              onChangeText={(text: string) => this.props.handleValueChange('posid', text)}
            />
            { <ExtendedClickableTextView
                title={'form.rewardproduct'}
                style={{zIndex : 1}}
                shouldShowMore
                onPressValue={this.props.handleOnMorePress}
                infoText={this.binding.translate('generic.rewardproduct.info').replace('{1}',this.binding.translate('rewards.title.singular').toLowerCase())}
                value={
                  this.props.reward.rewardProducts != undefined && this.props.reward.rewardProducts.length > 1 
                  ? this.binding.translate('rewards.popup.rewardproduct.value').replace("{1}",`${(this.props.reward.rewardProducts as RewardProduct[]).length}`)
                  : this.props.reward.rewardProducts == undefined || this.props.reward.rewardProducts.length == 0 ? this.binding.translate('rewards.popup.rewardproduct.no.value') : `${this.props.reward.rewardProducts[0].product?.posId}`}
                onMorePress={this.props.handleOnMorePress}
            /> }
             <ExtendedTextInputView
                title={'form.points'}
                showError={this.props.showErrors && (this.props.reward.points == null || this.props.reward.points < 0 )}
                value={`${this.props.reward.points}`}
                onChangeText={(text : string) => this.props.handleValueChange('points',text)}
              />
          </View>
          <View style={{ flexDirection: 'row' }}>
            <ExtendedCheckBoxView
                isTranslation
                title={'form.anonymous'}
                value={this.props.reward.isAnonymousVisible}
                onValueChange={(value : boolean) => this.props.handleCheckboxChange('isAnonymousVisible',value)}
              />

            <ExtendedCheckBoxView
              isTranslation
              title={'form.preview'}
              value={this.props.reward.isPreview}
              onValueChange={(value : boolean) => this.props.handleCheckboxChange('isPreviewVisible',value)}
            />     
          </View>
      </View>
    );
  }
}