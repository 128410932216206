import { Service, Application, HttpManager, Managers } from '@levelapp/softfabric';

export default class BasketStatusesService extends Service
{
    httpClient = Application.current.resolve<HttpManager>(Managers.HTTP_MANAGER);

    async get()
    {
        return await this.httpClient.get('basketstatuses');
    }
}