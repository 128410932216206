 
import {ContentView} from '@levelapp/softfabric';
import StoreSummaryViewState from './StoreSummaryViewState';
import StoreSummaryViewProps from './StoreSummaryViewProps';
import StoreSummaryViewStyle from './StoreSummaryViewStyle';
import StoreSummaryViewModel from './StoreSummaryViewModel';
import { View,Text,Image, TouchableOpacity,  } from 'react-native';
import HeaderView from '../../../../components/header/HeaderView';
import { LottieView, TranslationView } from '@levelapp/softfabric-ui';
import Constants from '../../../../../../../Common/Constants/Constants';
import ResearchInputView from '../../../../../../../Common/Components/researchinput/ResearchInputView';
import StepView from '../../../../../../../Common/Components/TabelView/modules/step/StepView';
import OrderSourcesHelper from '../../../../../../../Common/Helpers/OrderSourcesHelper';

export default class StoreSummaryView extends ContentView<StoreSummaryViewProps, StoreSummaryViewState, StoreSummaryViewModel, StoreSummaryViewStyle>
{
  
  /* CONSTRUCTOR */
  constructor(props:StoreSummaryViewProps)
  {
    
    super(props);

    // Binding
    this.bind(new StoreSummaryViewModel(this), new StoreSummaryViewStyle(this));
  }

  /* RENDERING */
  render()
  {
    return (
      <View style={this.style.container}>
        <View style={this.style.box}>
          
          <HeaderView
            title={"storessummary.title"}
            description={"storessummary.description"}
            shouldShowButton={false}
            onButtonPress={()=>{}}
          />
          
          <View style={this.style.boxContainer}>
            <ResearchInputView
              placeholder={this.binding.translate('generic.search')}
              handlerResearching={(searchText: string) => { this.binding.handleResearching(searchText) }}
              handlingClose={() => { this.binding.handleCloseResearching() }} />
           {this.state.isLoading ? 
            <LottieView height={150} width={150} source={require('../../../../../../../Common' + Constants.LottieAnimationPath)}/> 
           
            :
            
            <View>
              {this.state.stores.map((store : any, index : number)=>{return(
             <View style={this.style.rowStore} key={index}>
               <View style={this.style.storeName}>
                  <View  style={{flex : 1}}><TranslationView style={this.style.textTitle}>{"storessummary.name"}</TranslationView></View>
                  <View style={{flex : 1}}><Text>{store.slug}</Text></View>
               </View>
               <View style={this.style.orderSourcesListContainer}>
                <View style={this.style.orderSourceContainer}>
                    <View style={this.style.orderSourceNameContainer}>
                      <TranslationView style={this.style.textTitle}>{"storessummary.type"}</TranslationView>
                    </View>
                    <View style={this.style.orderSourceToggleContainer}>
                      <TranslationView style={this.style.textTitle}>{"storessummary.isTemporaryDisabled"}</TranslationView>
                    </View>
                    <View style={[this.style.orderSourceToggleContainer]}>
                      <TranslationView style={this.style.textTitle}>{"storessummary.isEnabled"}</TranslationView>
                    </View>
                    <View style={[this.style.settings]}>
                      <TouchableOpacity onPress={() => { this.binding.showStoreSumPopUp(store) }}>
                        <Image resizeMode='contain' style={{ width: 20, height: 20 }} source={require('../../../../../../../Common/Assets/settings.svg').default} />
                      </TouchableOpacity>
                    </View>
                  </View>
                 {store.orderSources.map((orderSource : any, index2 : number)=>{ return(
                  <View style={this.style.orderSourceContainer} key={index2}>
                    <View style={this.style.orderSourceNameContainer}>
                      <TranslationView>{OrderSourcesHelper.changeEnumToText(orderSource.orderSourceId)}</TranslationView>
                    </View>
                    <View style={this.style.orderSourceToggleContainer}> 
                        {orderSource.isTemporaryDisabled ?
                        <View><Image resizeMode='contain' style={{ width: 20, height: 20 }} source={require("../../assets/V.svg").default} /></View>:<View><Image resizeMode='contain' style={{ width: 20, height: 20 }} source={require("../../assets/X.svg").default}/></View>}
                    </View>
                    <View style={[this.style.orderSourceToggleContainer]}>
                        {orderSource.isEnabled ?
                        <View><Image resizeMode='contain' style={{ width: 20, height: 20 }} source={require("../../assets/V.svg").default}/></View>:<View><Image resizeMode='contain' style={{ width: 20, height: 20 }} source={require("../../assets/X.svg").default}/></View>}
                    </View> 
                    <View style={[this.style.settings]}/>
                  </View>  )
                 })}
               </View>       
              </View>)
           })}
           
            </View>
            }
            <StepView styleOfChoosenButton={this.style.stepColor} currentPage={this.state.currentPage-1} numberDisplays={this.state.nbPage} handleChangingPage={(page : number)=>this.binding.onPageChange(page)} minimumPage={2} />
          </View>
        </View>
      </View>
    );
  }
}