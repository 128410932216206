import {ContentShellViewModel, ContentShellView} from '@levelapp/softfabric';
import ShellMessagesViewState from './ShellMessagesViewState';
import ShellMessagesViewProps from './ShellMessagesViewProps';
 
//import {View} from 'react-native'
import EnumModules from '../../../../../Common/Constants/EnumModules';
import MessageCampaignListView from './pages/messagecampaignlist/MessageCampaignListView';

export default class ShellMessagesViewModel extends ContentShellViewModel<ShellMessagesViewState, ShellMessagesViewProps>
{

    constructor(view : ContentShellView)
    {
        super(view);

        this.registerEntryPoint('main'); // TODO : change with the route of your entry point
        this.register(<MessageCampaignListView/> ,'main'); // TODO : register your routes with the JSX (param1) and the route (param2)

        this.initialisation();
    }

    getModuleId()
    {
        return EnumModules.MESSAGES; // TODO : change the return value with your ModuleId
    }

}