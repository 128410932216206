import RolePermission from '../DTO/M&N/RolePermission';
import RoleConstants from "../Constants/RoleConstants";

export default class RoleManager 
{
    public KEY_ShowFaq =
        {
            key: 'ShowFaq',
            value: RoleConstants.ShowFaq,
            extradata: {},
        };
    public KEY_ShowNews =
        {
            key: 'ShowNews',
            value: RoleConstants.ShowNews,
            extradata: {},
        };
    public KEY_ShowUsers =
        {
            key: 'ShowUsers',
            value: RoleConstants.ShowUsers,
            extradata: {},
        };
    public KEY_ShowStats =
        {
            key: 'ShowStats',
            value: RoleConstants.ShowStats,
            extradata: {},
        };
    public KEY_ShowStores =
        {
            key: 'ShowStores',
            value: RoleConstants.ShowStores,
            extradata: {},
        };
    public KEY_ShowOrders =
        {
            key: 'ShowOrders',
            value: RoleConstants.ShowOrders,
            extradata: {},
        };
    public KEY_ShowRewards =
        {
            key: 'ShowRewards',
            value: RoleConstants.ShowRewards,
            extradata: {},
        };
    public KEY_ShowCoupons =
        {
            key: 'ShowCoupons',
            value: RoleConstants.ShowCoupons,
            extradata: {},
        };
    public KEY_ShowBoosters =
        {
            key: 'ShowBoosters',
            value: RoleConstants.ShowBoosters,
            extradata: {},
        };
    public KEY_ShowPurchase =
        {
            key: 'ShowPurchase',
            value: RoleConstants.ShowPurchase,
            extradata: {},
        };
    public KEY_ShowMessages =
        {
            key: 'ShowMessages',
            value: RoleConstants.ShowMessages,
            extradata: {},
        };
    public KEY_ShowOverview =
        {
            key: 'ShowOverview',
            value: RoleConstants.ShowOverview,
            extradata: {},
        };
    public KEY_ShowProducts =
        {
            key: 'ShowProducts',
            value: RoleConstants.ShowProducts,
            extradata: {},
        };
    public KEY_ShowLegalTexts =
        {
            key: 'ShowLegalTexts',
            value: RoleConstants.ShowLegalTexts,
            extradata: {},
        };
    public KEY_ShowNewsDiplay =
        {
            key: 'ShowNewsDiplay',
            value: RoleConstants.ShowNewsDiplay,
            extradata: {},
        };
    public KEY_ShowAdminUsers =
        {
            key: 'ShowAdminUsers',
            value: RoleConstants.ShowAdminUsers,
            extradata: {},
        };
    public KEY_ShowHealthStatus =
        {
            key: 'ShowHealthStatus',
            value: RoleConstants.ShowHealthStatus,
            extradata: {},
        };
    public KEY_ShowSavingCards =
        {
            key: 'ShowSavingCards',
            value: RoleConstants.ShowSavingCards,
            extradata: {},
        };

    public KEY_ShowTranslations =
        {
            key: 'ShowTranslations',
            value: RoleConstants.ShowTranslations,
            extradata: {},
        };
    public KEY_ShowPosSimulator =
        {
            key: 'ShowPosSimulator',
            value: RoleConstants.ShowPosSimulator,
            extradata: {},
        };
    
    public KEY_ShowLoyaltyRules =
        {
            key: 'ShowLoyaltyRules',
            value: RoleConstants.ShowLoyaltyRules,
            extradata: {},
        };
    public KEY_ShowEverydayValues =
    {
            key: 'ShowEverydayValues',
            value: RoleConstants.ShowEverydayValues,
            extradata: {},
    };
    public KEY_ShowRolePermissions =
        {
            key: 'ShowRolePermissions',
            value: RoleConstants.ShowRolePermissions,
            extradata: {},
        };
    public KEY_ShowProductCategories =
        {
            key: 'ShowProductCategories',
            value: RoleConstants.ShowProductCategories,
            extradata: {},
        };
    public KEY_ShowAutomatedMessages =
        {
            key: 'ShowAutomatedMessages',
            value: RoleConstants.ShowAutomatedMessages,
            extradata: {},
        };
    public KEY_ShowKitchenApp =
        {
            key: 'ShowKitchenApp',
            value: RoleConstants.ShowKitchenApp,
            extradata: {},
        }
    public KEY_ShowMenuConfigurator=
        {
            key: 'ShowMenuConfigurator',
            value: RoleConstants.ShowMenuConfigurator,
            extradata: {},
        }
    public KEY_ShowTickets =
        {
            key: 'ShowTickets',
            value: RoleConstants.ShowTickets,
            extradata: {},
        };
    public KEY_ShowPromoCodes =
        {
            key: 'ShowPromoCodes',
            value: RoleConstants.ShowPromoCodes,
            extradata: {},
        };
    public KEY_ShowCountries =
        {
            key: 'ShowCountries',
            value: RoleConstants.ShowCountries,
            extradata: {},
        }

    public KEY_ShowLanguage =
        {
            key: 'ShowLanguage',
            value: RoleConstants.ShowLanguage,
            extradata: {},
        };
        
    public KEY_ShowStoresSummary =
        {
            key: 'ShowStoresSummary',
            value: RoleConstants.ShowStoresSummary,
            extradata: {},
        };

    public KEY_ShowCrossSellconfigurator =
        {
            key: 'ShowCrossSellconfigurator',
            value: RoleConstants.ShowCrossSellconfigurator,
            extradata: {},
        };
    public KEY_OutOfStock =
        {
            key: 'ShowOutOfStock',
            value: RoleConstants.ShowOutOfStock,
            extradata: {},
        };
    
    public KEY_ContentTypeKeyConfig =
        {
            key: 'ShowContentTypeKeyConfig',
            value: RoleConstants.ShowContentTypeKeyConfig,
            extradata: {},
        };

    public KEY_ProductTool =
        {
            key: 'ShowProductTool',
            value: RoleConstants.ShowProductTool,
            extradata: {},
        };

    public KEY_ZoneTool =
        {
            key: 'ShowZoneTool',
            value: RoleConstants.ShowZoneTool,
            extradata: {},
        };

    public KEY_ShowBanners =
        {
            key: 'ShowBanners',
            value: RoleConstants.ShowBanners,
            extradata: {},
        };

    public KEY_ShowEarningPoints= 
    {
        key: 'ShowEarningPoints',
        value: RoleConstants.ShowEarningPoints,
        extradata: {},
    };

    public KEY_ShowMembership= 
    {
        key: 'ShowMembership',
        value: RoleConstants.ShowMembership,
        extradata: {},
    };

    public KEY_ShowVouchers =
    {
        key: 'ShowVouchers',
        value: RoleConstants.ShowVouchers,
        extradata: {},
    };
    public KEY_ShowProductAdditionalInfo = {
        key: 'ShowProductAdditionalInfo',
        value: RoleConstants.ShowProductAdditionalInfo,
        extradata: {},
    }

    private KEY_ARRAY = [
        this.KEY_ShowFaq,
        this.KEY_ShowNews,
        this.KEY_ShowUsers,
        this.KEY_ShowStats,
        this.KEY_ShowStores,
        this.KEY_ShowOrders,
        this.KEY_ShowRewards,
        this.KEY_ShowCoupons,
        this.KEY_ShowBoosters,
        this.KEY_ShowPurchase,
        this.KEY_ShowMessages,
        this.KEY_ShowProducts,
        this.KEY_ShowAdminUsers,
        this.KEY_ShowLegalTexts,
        this.KEY_ShowNewsDiplay,
        this.KEY_ShowSavingCards,
        this.KEY_ShowTranslations,
        this.KEY_ShowPosSimulator,
        this.KEY_ShowLoyaltyRules,
        this.KEY_ShowRolePermissions,
        this.KEY_ShowProductCategories,
        this.KEY_ShowAutomatedMessages,
        this.KEY_ShowKitchenApp,
        this.KEY_ShowMenuConfigurator,
        this.KEY_ShowPromoCodes,
        this.KEY_ShowCountries,
        this.KEY_ShowLanguage,
        this.KEY_ShowStoresSummary,
        this.KEY_ShowCrossSellconfigurator,
        this.KEY_OutOfStock,
        this.KEY_ContentTypeKeyConfig,
        this.KEY_ZoneTool,
        this.KEY_ProductTool,
        this.KEY_ShowBanners,
        this.KEY_ShowEarningPoints,
        this.KEY_ShowVouchers,
        this.KEY_ShowProductAdditionalInfo,
    ]

    constructor() { }

    async setPermissions(permissions: RolePermission[])
    {
        await permissions.forEach((value: RolePermission) =>
        {
            var tmp = this.KEY_ARRAY.find((x) => x.key == value.permission.key);
            if (tmp != undefined)
            {
                tmp.value = true;
                if (value.permission.permissionsUsers != null && value.permission.permissionsUsers.length > 0 && value.permission.permissionsUsers[0].extraData)
                {
                    tmp.extradata = value.permission.permissionsUsers[0].extraData;
                }
            }
        });
    }

}