 
import {ContentShellView} from '@levelapp/softfabric';
import ShellZonesViewState from './ShellZonesViewState';
import ShellZonesViewProps from './ShellZonesViewProps';
import ShellZonesViewStyle from './ShellZonesViewStyle';
import ShellZonesViewModel from './ShellZonesViewModel';
import {View} from 'react-native';

export default class ShellZonesView extends ContentShellView<ShellZonesViewProps, ShellZonesViewState, ShellZonesViewModel, ShellZonesViewStyle>
{
  /* CONSTRUCTOR */
  constructor(props:ShellZonesViewProps)
  {
    super(props);

    // Binding
    this.bind(new ShellZonesViewModel(this), new ShellZonesViewStyle(this));
  }

  /* RENDERING */
  render()
  {
    return (
      <View style={{flex : 1}}>
        {this.getSwitch()}
      </View>
    );
  }
}