import {ContentViewModel,ContentView} from '@levelapp/softfabric';
import GenericSettingsSectionViewState from './GenericSettingsSectionViewState';
import GenericSettingsSectionViewProps from './GenericSettingsSectionViewProps';
import ContentTypeKeyValue from '../../../../../Common/DTO/ContentTypeKeyValue';
import ContentTypeKey from '../../../../../Common/DTO/ContentTypeKey';

export default class GenericSettingsSectionViewModel extends ContentViewModel<GenericSettingsSectionViewState, GenericSettingsSectionViewProps>
{
      /* CONSTRUCTOR */
    constructor(view: ContentView)
    {
        super(view);

        this.initialState({
            currentContentTypeKeyValues : [],
        }); 

        this.handleKeyValueChange = this.handleKeyValueChange.bind(this);

    }

    componentDidUpdate(){
    }

    

    componentDidMount(){
        let tmpContentTypeKeyValues : ContentTypeKeyValue [] = []
        this.props().contentTypeKeys.map((contentTypeKey: ContentTypeKey)=>{
            let tmpContentTypeKeyValue : ContentTypeKeyValue = {
                contentTypeKeyId : contentTypeKey.id,
                contentTypeKey : contentTypeKey,
                value : "",
            }
            //When update the content informations
            if(this.props().contentTypeKeyValues && this.props().contentTypeKeyValues.length > 0){
                tmpContentTypeKeyValue.id = this.props().contentTypeKeyValues.find((c : ContentTypeKeyValue) => c.contentTypeKeyId === contentTypeKey.id)?.id;
                tmpContentTypeKeyValue.value = this.props().contentTypeKeyValues.find((c : ContentTypeKeyValue) => c.contentTypeKeyId === contentTypeKey.id)?.value;
            }
            tmpContentTypeKeyValues.push(tmpContentTypeKeyValue)
        })
        this.setField({currentContentTypeKeyValues : tmpContentTypeKeyValues})
        this.props().onKeyValueChange('contentType',tmpContentTypeKeyValues );
        
    }

    handleKeyValueChange(text : string, index : number){
        this.setField((previousState) => previousState.currentContentTypeKeyValues[index].value = text.replace(',', '.'),
        this.props().onKeyValueChange('contentType',this.state().currentContentTypeKeyValues ));
        
    }





}