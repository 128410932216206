import { Operation } from "@levelapp/softfabric";
import TriggersService from "../Proxy/TriggersService";

export default class TriggersOperations extends Operation
{
    service : TriggersService;

    constructor()
    {
        super();
        this.service = new TriggersService();
    }

    get(parameters : any[], callback : Function, onFailure : Function)
    {
        this.service.get(parameters, callback,onFailure);
    }
}