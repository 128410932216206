import {ContentViewModel,ContentView, Application, LanguageManager, Managers} from '@levelapp/softfabric';
import StatsSettingsViewState from './StatsSettingsViewState';
import StatsSettingsViewProps from './StatsSettingsViewProps';
import { TableRow } from '@levelapp/softfabric-ui';
import DashboardsOperations from '../../../../../../../Transfer/DashboardsOperations';
import Dashboard from '../../../../../../../Common/DTO/Dashboard';
import PaginationHelper from '../../../../../../../Common/Helpers/PaginationHelper';
import StatsSettingsPopupView from '../../popups/statssettingspopup/StatsSettingsPopupView';
import DashboardType from '../../../../../../../Common/DTO/DashboardType';
 
import Helpers from '../../../../../../../Common/Helpers/Helpers';
import ToastHelper from '../../../../../../../Common/Helpers/ToastHelper';
import ConfirmPopUpView from '../../../../../../../Common/Components/confirmpopup/ConfirmPopUpView';

export default class StatsSettingsViewModel extends ContentViewModel<StatsSettingsViewState, StatsSettingsViewProps>
{
    _dashboardOperation : DashboardsOperations;
    interval: any;
    searchText: string;
    isSorting: boolean;
    indexHeaderSort: number;
    sortOrder: string;
    sortKey: string[];
    moment : any;

    constructor(props: ContentView) {
        super(props);

        this.interval = null;
        this.searchText = '';
        this.isSorting = false;
        this.indexHeaderSort = -1;
        this.sortOrder = 'asc';
        this.sortKey = ['Id', 'ThirdPartyId', 'DashboardType'];
        this.moment = require('moment');
        /* Initial State */
        this.initialState({ tableData: [], dashboardType : [], tableHeaders: ['Id', 'ThirdPartyId', 'DashboardType'], isLoading: true, nbPage: 0, currentPage: 1, isResearching: false });


        this._dashboardOperation = new DashboardsOperations();

        /* BINDINGS */
        this.editField = this.editField.bind(this);
        this.addField = this.addField.bind(this);
        this.deleteField = this.deleteField.bind(this);
        this.refresh = this.refresh.bind(this);
        this.saveDashboard = this.saveDashboard.bind(this);
        this.onPageChange = this.onPageChange.bind(this);
        this.onSortChange = this.onSortChange.bind(this);
        this.handleResearching = this.handleResearching.bind(this);
        this.handleCloseResearching = this.handleCloseResearching.bind(this);
    }

    componentDidMount() {
        this.refresh();
    }

    refresh() {
        this.setField({ isLoading: true });
        let parameters =PaginationHelper.basicResearchPush(
            this.state().currentPage,
            20,
            this.sortKey[this.indexHeaderSort],
            this.sortOrder,
            Application.current.resolve<LanguageManager>(Managers.LANGUAGE_MANAGER).language ,
            this.searchText,
            this.isSorting,
            this.state().isResearching);
        this.getData(parameters);
        this._dashboardOperation.getDashboardTypes([],(dashboardType : DashboardType[]) =>
        {
            this.setField({dashboardType : dashboardType});
        })
    }

    getData(queryParamaters : any[]) {
        this._dashboardOperation.getwithparamaters(queryParamaters, (dashboards: any) => {
            let tableData: TableRow[] = [];
            dashboards.data.objects.forEach((dashboard: Dashboard) => {
                tableData.push({ data: [
                    dashboard.id,
                    dashboard.thirdPartyId,
                    (dashboard.dashboardType as DashboardType).key],
                    isDeletable: true, 
                    isEditable: true, 
                    editCallback: () => { this.editField(dashboard) }, 
                    deleteCallback: () => { this.deleteField(dashboard) } });
            });
            this.setField({ tableData: tableData, coupons: dashboards, nbPage: dashboards.data.totalPage, isLoading: false });
        })
    }

    addField() {
        this.showPopUp(
            <StatsSettingsPopupView
                dashboard={
                    {
                        thirdPartyId : ''
                    }}
                dashboardType={this.state().dashboardType}
                save={this.saveDashboard} />
        );
    }

    editField(dashboard: Dashboard) {
        let dashboardCopy = Helpers.clone(dashboard);
        this.showPopUp(
            <StatsSettingsPopupView
                dashboard={dashboardCopy}
                dashboardType={this.state().dashboardType}
                save={this.saveDashboard} />
        );
    }

    deleteField(dashboard: any) {
        this.showPopUp(
            <ConfirmPopUpView callBack={() => {
                this._dashboardOperation.delete(dashboard, () => {
                    this.showToast(ToastHelper.getToast("stats.settings.popup.success.deleted","success"),() => {},2500);
                    this.refresh();
                });
            }} />
        );
    }

    saveDashboard(dashboard: any) {
        if (dashboard.id !== undefined) {
            this._dashboardOperation.put(dashboard, () => {
                this.refresh();
                this.showToast(ToastHelper.getToast("stats.settings.popup.success.updated","success"),() => {},2500);
                this.closePopUp();
            });
        }

        else {
            this._dashboardOperation.post(dashboard, () => {
                this.refresh();
                this.showToast(ToastHelper.getToast("stats.settings.popup.success.added","success"),() => {},2500);
                this.closePopUp();
            });
        }
    }

    onPageChange(page: number) {
        this.state().currentPage = page + 1;
        this.refresh()
    }

    onSortChange(indexHeaderSort: number, sortOrder: string) {
        if (indexHeaderSort == -1) {
            this.isSorting = false;
        } else {
            this.indexHeaderSort = indexHeaderSort;
            this.sortOrder = sortOrder;
            this.isSorting = true;
        }
        this.refresh();
    }

    handleResearching(searchText: string) {
        this.setField({ isLoading: true, isResearching: true });
        this.searchText = searchText;

        if (this.interval) {
            clearInterval(this.interval);
        }
        this.interval = setTimeout(() => {
            this.onPageChange(0)
        }, 1000)
    }

    handleCloseResearching() {
        this.state().isResearching = false;
        this.onPageChange(0);
    }
}