import {ContentViewModel,ContentView, Application, LanguageManager, Managers} from '@levelapp/softfabric';
import OutofstockViewState from './OutofstockViewState';
import OutofstockViewProps from './OutofstockViewProps';
 
import AddProductPopUpView from '../../../menuconfigurator/popup/addproductpopup/AddProductPopUpView';
import Language from '../../../../../../../Common/DTO/Language';
import LanguagesOperations from '../../../../../../../Transfer/LanguagesOperations';
import PopupStoresListView from '../../../../components/popupstoreslist/PopupStoresListView';
import ErrorHelper from '../../../../../../../Common/Helpers/ErrorHelper';
import Store from '../../../../../../../Common/DTO/Store';
import Product from '../../../../../../../Common/DTO/Product';
import ToastHelper from '../../../../../../../Common/Helpers/ToastHelper';
import StoresOperations from '../../../../../../../Transfer/StoresOperations';

import OutOfStockOperations from '../../../../../../../Transfer/OutOfStockOperations';
import { TableRow } from '@levelapp/softfabric-ui';
import OutOfStock from '../../../../../../../Common/DTO/OutOfStock';
import Helpers from '../../../../../../../Common/Helpers/Helpers';
import ShowOrdersExtraData from '../../../../../../../Common/DTO/ExtraData/ShowOrdersExtraData';
import PermissionHelper from '../../../../../../../Common/Helpers/PermissionHelper';
import PaginationHelper from '../../../../../../../Common/Helpers/PaginationHelper';
import UsersOperations from '../../../../../../../Transfer/UsersOperations';


export default class OutofstockViewModel extends ContentViewModel<OutofstockViewState, OutofstockViewProps>
{
    _languagesOperation: LanguagesOperations;
    _storeOperations: StoresOperations;
    _outOfStocksOperations : OutOfStockOperations;
    _userOperation :  UsersOperations;
    _languages: Language[] = [];
    searchText: string;
    interval :any;
    
      /* CONSTRUCTOR */
    constructor(view: ContentView)
    {
        super(view);

        this.searchText = '';
        this.interval = null;

        this.initialState({ 
            tableData: [], 
            tableHeaders: ['Id', 'PosId', 'Title'],
            isLoading: true, 
            nbPage: 0, 
            currentPage: 1, 
            isResearching: false,
            productsOutOfStock : [],
            currentStore : {
                "id": -1,
                "slug": "",
                "title": "",
                "isClickAndCollectTemporaryDisabled": false,
                "isKioskTemporaryDisabled": false,
                "isClickAndCollectEnabled": false,
                brandIds: []
            },
            hasMultipleStores : false,
            stores : [],
        });


        this._outOfStocksOperations = new OutOfStockOperations();
        this._languagesOperation = new LanguagesOperations();
        this._storeOperations = new StoresOperations();
        this._userOperation = new UsersOperations();
        this._languagesOperation.get((languages: any) => {
            this._languages = languages.data;
        });

        // Get stores

       
        this._storeOperations.get([], (paginationObject: any) =>
        {
            // Check the stores we have access to (same permissions as the kitchenapp)
            let storeTemp: Store[] = [];
            paginationObject.data.objects.forEach((store: Store) =>
            {

                var tmp = (PermissionHelper.getExtraDataStoreOOS() as any);
                if (Object.keys(tmp).length === 0 || tmp == null || tmp === undefined)
                {
                    //ALL STORES 
                    storeTemp.push({ ...store, title: store.slug });
                } else
                {
                    var id = (JSON.parse(tmp) as ShowOrdersExtraData).restaurant;
                    if (id === store.id)
                    {
                        //DISPLAY ONLY THAT FROM BE
                        storeTemp.push({ ...store, title: store.slug });
                    }
                }
            });

            if (storeTemp.length == 1)
            {
                this.getProductsOutOfStock( storeTemp[0])
            }
            else
            {
                
                this.setField({hasMultipleStores : true, stores : storeTemp })
                this.showStoresList(storeTemp)
                
            }
        }, this.errorManagement)

        // Bindings
        this.showViewAddProduct = this.showViewAddProduct.bind(this);
        this.showStoresList = this.showStoresList.bind(this);
        this.errorManagement=this.errorManagement.bind(this);
        this.deleteField = this.deleteField.bind(this);

    }

 

    refresh() {
        let parameters = PaginationHelper.basicResearchPush(
            this.state().currentPage,
            20,
            "",
            "",
            Application.current.resolve<LanguageManager>(Managers.LANGUAGE_MANAGER).language ,
            this.searchText,
            false,
            this.state().isResearching);
        if(this.state().currentStore.id > 0){
            parameters.push({
                "key" : "storeId", 
                "value" : this.state().currentStore.id})
        }
        this.getData(parameters);
    }

    


    getData(parameters :any) {
        this.setField({isLoading : true});
        this._outOfStocksOperations.get(parameters,(outOfStocks : any)=>{
            let tableData: TableRow[] = [];
            let productOOS : Product[] = [];
            outOfStocks.data.objects.forEach((outOfStock: OutOfStock) => {
                if (outOfStock.product.title === null) {
                    outOfStock.product.title = Helpers.generateTranslationTable(this._languages);
                }
                let title = outOfStock.product.title.translationValues[0].value;
                let POSId = outOfStock.product.posId !== null ? outOfStock.product.posId : "No result";
                productOOS.push(outOfStock.product);
                tableData.push({ data: [outOfStock.product.id, POSId, title], isDeletable: true, isEditable: false,  deleteCallback: () => { this.deleteField(outOfStock) } });
            })
            this.setField({ tableData: tableData, productsOutOfStock:productOOS, nbPage: outOfStocks.data.totalPage, isLoading: false });
        },this.errorManagement)
    }

    onPageChange(page: number) {
        this.state().currentPage = page + 1;
        this.refresh()
    }

    errorManagement(error : any) 
    {
        ErrorHelper.showMessageError(error.response);
    }

   showViewAddProduct(){
        this.showPopUp(
            <AddProductPopUpView 
                clone={false} 
                languages={this._languages} 
                treeData={[]} 
                parentNode={null} 
                isForOutOfStock ={true}
                productsAlreadyOutOfStock ={this.state().productsOutOfStock}
                callback={(_:any,products: any) => this.addProductToOutOfStock(products) }/>)
    }
    
    showStoresList(stores : Store[]){
        this.showPopUp(
            <PopupStoresListView 
            callback={(store : any) =>{this.getProductsOutOfStock(store)}}
            stores ={stores}
            displayNameStore={"slug"}/>
        )
    }

    getProductsOutOfStock(store : Store){
        if(store){
            this.setField({currentStore : store});
            let parameters = PaginationHelper.basicResearchPush(
                this.state().currentPage,
                20,
                "",
                "",
                Application.current.resolve<LanguageManager>(Managers.LANGUAGE_MANAGER).language ,
                this.searchText,
                false,
                this.state().isResearching);
                parameters.push({
                    "key" : "storeId", 
                    "value" : store.id})
            
            this.getData(parameters)
            
        }  
    }

    addProductToOutOfStock(products : Product[]){
        let outOfStockList : any [] = [];
        products.forEach((product: Product)=>{
            outOfStockList.push({
                "productId" : product.id,
                "storeId" : this.state().currentStore.id,
                "isDisable" :false,
            })
        })
        this._outOfStocksOperations.post(outOfStockList,  ()=> {
            this.showToast(ToastHelper.getToast("outodstock.add.succes", 'success'), () => {}, 2500);
            this.refresh();
        },this.errorManagement)
        
    }

    deleteField(outOfStock : OutOfStock) {
        outOfStock.isDisable = true;
        this._outOfStocksOperations.delete(outOfStock,()=>{
            this.showToast(ToastHelper.getToast("outodstock.delete.succes", 'success'), () => {}, 2500);
            this.refresh();
        }, this.errorManagement)
    }

    handleResearching(searchText: string) {
        this.setField({ isLoading: true, isResearching: true });
        this.searchText = searchText;

        if (this.interval) {
            clearInterval(this.interval);
        }
        this.interval = setTimeout(() => {
            this.onPageChange(0)
        }, 1000)
    }

    handleCloseResearching() {
        this.state().isResearching = false;
        this.onPageChange(0);
    }

    

    
}