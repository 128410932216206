import {ContentViewStyle} from '@levelapp/softfabric';
import {StyleProp,TextStyle,ViewStyle} from 'react-native';

export default class GenericLogsViewStyle extends ContentViewStyle
{
    container: StyleProp<ViewStyle> = {
        backgroundColor: 'white',
        width: 515
    };

    header: StyleProp<ViewStyle> = {
        height: 110,
        backgroundColor: '#F6F6F6',
        alignItems : 'center',
        flexDirection : 'row',
        paddingVertical: 25,
        paddingHorizontal: 40
    };

    formtitle : StyleProp<TextStyle> =
    {
        fontWeight : 'bold',
        fontSize : 16,
    }

    title: StyleProp<TextStyle> = {
        fontSize: 21,
        fontWeight : 'bold',
    };

    historyContainer: StyleProp<ViewStyle> = {
        paddingVertical: 30,
        paddingHorizontal: 15
        
    };

    history: StyleProp<ViewStyle> = {
        flexDirection: 'row'
    };

    leftHistory: StyleProp<ViewStyle> = {
        paddingHorizontal: 26,
        alignItems: 'center'
    };

    middleHistory: StyleProp<ViewStyle> = {
        marginTop: -5,
    };

    rightHistory: StyleProp<ViewStyle> = {
        flex: 1,
        marginTop: -4,
    };

    bubble: StyleProp<ViewStyle> = {
        backgroundColor: '#CCCCCC',
        height: 12,
        width: 12,
        borderRadius: 6
    };

    line: StyleProp<ViewStyle> = {
        backgroundColor: '#CCCCCC',
        width: 2,
        height: '100%'
    };

    status: StyleProp<ViewStyle> = {
        height: 45,
        width: 250,
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: 3,
        marginTop: 8,
    };
    statusLabel: StyleProp<ViewStyle> = {
        height: 45,
        width: 100,
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: 3,
        marginTop: 8,
        flex : 1
    };

    timeline : StyleProp<ViewStyle> = {
        flexDirection : 'column',
        flex : 2, 
        overflow : 'scroll',
        paddingVertical : 20,
        marginLeft : 20,
    };
}