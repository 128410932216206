import OpeningHour from "../DTO/OpeningHour";

export default class OpeningHoursHelper 
{
    /**
     * Return true if the store is open
     * Return false if the store is closed OR is closed in - than 30 minutes
     * @param openingHours opening hours of the store
     * @param date specified date OR current date
     */
    static isStoreOpen(openingHours: OpeningHour[], date?: Date): boolean
    {
        const currentDate = date ?? new Date();

        for (let i = 0; i < openingHours.length; i++)
        {
            const { day, opening, closing } = openingHours[i];
            if (day == currentDate.getDay() && opening.length == 4 && closing.length == 4)
            {
                const currentYear = currentDate.getFullYear();
                const currentMonth = currentDate.getMonth();
                const today = currentDate.getDate();

                const start = new Date(currentYear, currentMonth, today, Number.parseInt(opening.substring(0, 2)), Number.parseInt(opening.substring(2, 4)));
                const end = new Date(currentYear, currentMonth, today, Number.parseInt(closing.substring(0, 2)), Number.parseInt(closing.substring(2, 4)));

                // Store is not closing today
                if (end < start)
                {
                    end.setDate(today + 1);
                }

                end.setMinutes(end.getMinutes() - 30);

                if (currentDate >= start && currentDate < end)
                {
                    return true;
                }
            }
        }
        return false;
    }

    /**
     * Return true if the store is open after midnight
     * @param openingHours opening hours of the store
     * @param date specified date OR current date
     */
    static isClosingToday(openingHours: OpeningHour[], date?: Date): boolean
    {
        const currentDate = date ?? new Date();

        for (let i = 0; i < openingHours.length; i++)
        {
            const { day, opening, closing } = openingHours[i];
            if (day == currentDate.getDay() && opening.length == 4 && closing.length == 4)
            {
                const currentYear = currentDate.getFullYear();
                const currentMonth = currentDate.getMonth();
                const today = currentDate.getDate();

                const start = new Date(currentYear, currentMonth, today, Number.parseInt(opening.substring(0, 2)), Number.parseInt(opening.substring(2, 4)));
                const end = new Date(currentYear, currentMonth, today, Number.parseInt(closing.substring(0, 2)), Number.parseInt(closing.substring(2, 4)));

                return start <= end;
            }
        }
        return true;
    }

    /**
     * Return the date & hour of the end of current shift
     * @param openingHours opening hours of the store
     * @param date specified date OR current date
     */
    static getCurrentShiftEnd(openingHours: OpeningHour[], date?: Date): Date
    {
        const currentDate = date ?? new Date();

        for (let i = 0; i < openingHours.length; i++)
        {
            const { day, opening, closing } = openingHours[i];
            if (day == currentDate.getDay() && opening.length == 4 && closing.length == 4)
            {
                const currentYear = currentDate.getFullYear();
                const currentMonth = currentDate.getMonth();
                const today = currentDate.getDate();

                const start = new Date(currentYear, currentMonth, today, Number.parseInt(opening.substring(0, 2)), Number.parseInt(opening.substring(2, 4)));
                const end = new Date(currentYear, currentMonth, today, Number.parseInt(closing.substring(0, 2)), Number.parseInt(closing.substring(2, 4)));

                // Store is not closing today
                if (end < start)
                {
                    end.setDate(today + 1);
                }

                return end;
            }
        }
        return currentDate;
    }

    /**
     * Returns true if the store is closing in - than 30 minutes
     * @param openingHours opening hours of the store
     * @param date specified date OR current date
     */
    static isAlmostClosed(openingHours: OpeningHour[], date?: Date)
    {
        const currentDate = date ?? new Date();

        for (let i = 0; i < openingHours.length; i++)
        {
            const { day, opening, closing } = openingHours[i];
            if (day == currentDate.getDay() && opening.length == 4 && closing.length == 4)
            {
                const start = new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate(), Number.parseInt(closing.substring(0, 2)), Number.parseInt(closing.substring(2, 4)));
                const end = new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate(), Number.parseInt(closing.substring(0, 2)), Number.parseInt(closing.substring(2, 4)));
                start.setMinutes(start.getMinutes() - 30);

                if (currentDate >= start && currentDate < end)
                {
                    return true;
                }
            }
        }
        return false;
    }
}