import {ContentShellViewModel, ContentShellView} from '@levelapp/softfabric';
import ShellPosSimulatorViewState from './ShellPosSimulatorViewState';
import ShellPosSimulatorViewProps from './ShellPosSimulatorViewProps';
 
import PosSimulatorView from './pages/possimulator/PosSimulatorView';
import EnumModules from '../../../../../Common/Constants/EnumModules';

export default class ShellPosSimulatorViewModel extends ContentShellViewModel<ShellPosSimulatorViewState, ShellPosSimulatorViewProps>
{

    constructor(view : ContentShellView)
    {
        super(view);

        this.registerEntryPoint('main'); // TODO : change with the route of your entry point
        this.register(<PosSimulatorView /> ,'main'); // TODO : register your routes with the JSX (param1) and the route (param2)
        this.register(<PosSimulatorView/>, 'possimulator');

        this.initialisation();
    }

    getModuleId()
    {
        return EnumModules.POS_SIMULATOR; // TODO : change the return value with your ModuleId
    }

}