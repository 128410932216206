 
import {ContentShellView} from '@levelapp/softfabric';
import ShellCountryViewState from './ShellCountryViewState';
import ShellCountryViewProps from './ShellCountryViewProps';
import ShellCountryViewStyle from './ShellCountryViewStyle';
import ShellCountryViewModel from './ShellCountryViewModel';
import {View} from 'react-native';

export default class ShellCountryView extends ContentShellView<ShellCountryViewProps, ShellCountryViewState, ShellCountryViewModel, ShellCountryViewStyle>
{
  /* CONSTRUCTOR */
  constructor(props:ShellCountryViewProps)
  {
    super(props);
    // Binding
    this.bind(new ShellCountryViewModel(this), new ShellCountryViewStyle(this));
  }

  /* RENDERING */
  render()
  {
    return (
      <View style={{flex : 1}}>
        {this.getSwitch()}
      </View>
    );
  }
}