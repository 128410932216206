import {ContentViewStyle} from '@levelapp/softfabric';
import {StyleProp,ViewStyle} from 'react-native';

export default class ExtendedTextInputViewStyle extends ContentViewStyle
{
    page: StyleProp<ViewStyle> = {
        flex: 1
    }

    sectionId: StyleProp<ViewStyle> = {
        alignSelf: 'flex-start',
        paddingRight : 15,
        paddingBottom : 15,
    };

    input: StyleProp<any> = {
        minwidth: 140,
        paddingHorizontal: 15,
        borderRadius : 8,
        boxShadow: '0 5px 14px 0 rgba(0,0,0,0.1)',
    };


    errorTextField: StyleProp<ViewStyle> = {
        borderColor: 'red',
        borderWidth: 1,
        width: 140,
        paddingHorizontal: 15,
        borderRadius : 8,
    };
}