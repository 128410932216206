import {ContentViewModel,ContentView} from '@levelapp/softfabric';
import ExtendedClickableTextViewState from './ExtendedClickableTextViewState';
import ExtendedClickableTextViewProps from './ExtendedClickableTextViewProps';

export default class ExtendedClickableTextViewModel extends ContentViewModel<ExtendedClickableTextViewState, ExtendedClickableTextViewProps>
{
      /* CONSTRUCTOR */
    constructor(view: ContentView)
    {
        super(view);

        this.initialState({}); // Initialize your state here

        // Bindings

    }
}