import { Service, Application, HttpManager, Managers } from '@levelapp/softfabric';


export default class TypesService extends Service {
    httpClient = Application.current.resolve<HttpManager>(Managers.HTTP_MANAGER);

    async get(parameters: any[], onSuccess: Function, onFailure: Function = () => { }) {
        this.httpClient.handleSomethingWentWrong = onFailure;
        this.httpClient.handleNotFound = onFailure;

        this.httpClient
            .get('Type', parameters)
            .then((response: any) => {
                if (response) {
                    if (response.data) {
                        onSuccess(response.data);
                    }
                }
            });
    }
}