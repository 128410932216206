 
import { ContentView } from '@levelapp/softfabric';
import OrdersViewState from './OrdersViewState';
import OrdersViewProps from './OrdersViewProps';
import OrdersViewStyle from './OrdersViewStyle';
import OrdersViewModel from './OrdersViewModel';
import ButtonViewGeneric from '../../../../../../../Common/Components/Button/ButtonView';
import { TranslationView, ButtonView } from '@levelapp/softfabric-ui';
import { View, Text, TouchableWithoutFeedback, CheckBox } from 'react-native';
import Constants from '../../../../../../../Common/Constants/Constants';
import LinearGradient from 'react-native-web-linear-gradient';
import TableView from '../../../../../../../Common/Components/TabelView/TableView';
import DropDown from '../../../../../../../Common/Components/Dropdown/DropDownView';
import SwitchView from '../../../../components/switch/SwitchView';



export default class OrdersView extends ContentView<OrdersViewProps, OrdersViewState, OrdersViewModel, OrdersViewStyle>
{
  /* CONSTRUCTOR */
  constructor(props: OrdersViewProps)
  {
    super(props);

    // Binding
    this.bind(new OrdersViewModel(this), new OrdersViewStyle(this));
  }

  /* RENDERING */
  render()
  {
    return (

      <View style={this.style.container}>

        <View style={this.style.box}>

          {/* COLOR BOX */}
          <LinearGradient start={{ x: 0, y: 0 }} end={{ x: 1, y: 0 }} colors={[Constants.GradiantStartColor, Constants.GradiantEndColor]} style={this.style.boxTitle}>
            <View style={this.style.boxSubtitle}>
              <View style={{ flexDirection: 'row', alignItems: 'flex-start', paddingTop: 30 }}>
                <TranslationView style={this.style.title}>
                  orders.at
              </TranslationView>
                <DropDown
                  values={this.binding.getDropDownValues()}
                  selectedValue={this.binding.getDropDownValues().find(x => x.id == this.state.selectedStore.id) || 0}
                  onValueChange={(value: any) => this.binding.handleStoreChanged(value)}
                />
              </View>
            </View>

            <View style={{ flexDirection: 'column', padding: 30, justifyContent: 'space-between', opacity: this.state.selectedStore.id != 0 ? 1 : 0 }}>
              <View style={{ flexDirection: 'row', alignItems: 'center', alignSelf: 'center' }}>
                <ButtonViewGeneric backgroundColor={'white'} handleClick={() => { this.binding.refreshCache() }}
                  isLoading={this.state.refreshingCache} style={this.style.refreshBtn}>
                  <TranslationView style={this.style.refreshBtnTxt}>orders.refreshProducts</TranslationView>
                </ButtonViewGeneric>
              </View>

              <View style={{ flexDirection: 'row', marginTop: 15 }}>
                <View style={{ flexDirection: 'column', marginRight: 15 }}>
                  <Text style={{ color: 'white', textAlign: 'center', marginBottom: 5 }}>App</Text>
                  <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                    <TouchableWithoutFeedback onPress={() => this.binding.handleClickAndCollectToggled()}>
                      <View>
                        <SwitchView enabledColor='#77e189' enabled={!this.state.selectedStore.isClickAndCollectTemporaryDisabled} />
                      </View>
                    </TouchableWithoutFeedback>
                  </View>
                </View>

                <View style={{ flexDirection: 'column' }}>
                  <Text style={{ color: 'white', textAlign: 'center', marginBottom: 5 }}>Kiosk</Text>
                  <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                    <TouchableWithoutFeedback onPress={() => this.binding.handleKioskToggled()}>
                      <View>
                        <SwitchView enabledColor='#77e189' enabled={!this.state.selectedStore.isKioskTemporaryDisabled} />
                      </View>
                    </TouchableWithoutFeedback>
                  </View>
                </View>
              </View>
            </View>

          </LinearGradient>

          {/* <HeaderView
            title={"orders.title"}
            description={"orders.description"}
            shouldShowButton={false}
          /> */}

          <View style={this.style.boxContainer}>
            <View style={{ flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', marginBottom: 10, opacity: this.state.selectedStore.id != 0 ? 1 : 0 }}>
              <View style={{ flexDirection: 'row' }}>
                <TranslationView style={{ marginRight: 5 }}>orders.statusfilters</TranslationView>
                {this.binding._allStatus.map((st, i) =>
                {
                  return (
                    <View key={i} style={{ marginRight: 20, flexDirection: 'row' }}>
                      <CheckBox
                        value={this.binding.isStatusSelected(st)}
                        onValueChange={() => this.binding.handleStatusSelected(st)}
                      />
                      <TranslationView style={{ marginLeft: 5, color: this.binding.getStatusColor(st) }} upperCase>{this.binding.getStatusTranslationKey(st)}</TranslationView>
                    </View>
                  )
                })}
              </View>

              <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                {this.state.newOrdersCount != 0 && (
                  <Text style={this.style.newOrdersTxt}>{this.binding.translate('orders.neworders').replace('%n', this.state.newOrdersCount + '')}</Text>
                )}
                <ButtonView animation="Bounce" borders="None" handleClick={() => { this.binding.refresh() }}
                  isLoading={false} style={this.style.refreshBtn}>
                  <TranslationView style={this.style.refreshBtnTxt}>orders.refresh</TranslationView>
                </ButtonView>
              </View>

            </View>
            <View style={{ flexDirection: 'row', alignItems: 'center', marginBottom: 20, opacity: this.state.selectedStore.id != 0 ? 1 : 0 }}>
              <Text>Source filters : </Text>
              {
                Object.keys(this.binding._orderSources).map((orderSourceKey)=>{
                  const orderSource = this.binding._orderSources[orderSourceKey]
                  return(

                    <View key={orderSourceKey} style={{ marginRight: 20, flexDirection: 'row' }}>
                      <CheckBox
                            value={this.binding.isOrderSourceSelected(orderSource.value)}
                            onValueChange={() => this.binding.handleOrderSourceSelected(orderSource.value)}
                      />
                      <TranslationView style={{ marginLeft: 5, color: 'black' }} upperCase>{orderSource.translationKey}</TranslationView>
                      <Text style={{ marginLeft: 5,  }}>{}</Text>
                    </View>
                  ) 
                })

              }
            </View>
            <TableView
              defaultSortIndice={this.binding.indexHeaderSort}
              defaultSortDesc={true}
              headerTextStyle={this.style.tableHeader}
              rowStyle={this.style.rowStyle}
              backgroundStepColor={this.style.stepColor}
              nothingToDisplayText={this.binding.translate('component.table.noresults')}
              isLastColumnActions={false}
              data={this.state.tableData}
              isLoading={this.state.isLoading}
              headers={this.state.tableHeaders}
              onPageChange={(page: number) => this.binding.onPageChange(page)}
              handleSortChange={(indexHeaderSort: number, sortOrder: string) => { this.binding.onSortChange(indexHeaderSort, sortOrder) }}
              nbPage={this.state.nbPage}
              currentPage={this.state.currentPage - 1}
              isSortable={true} />
          </View>
        </View >

      </View >
    );
  }
}
