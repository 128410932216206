 
import {ContentView} from '@levelapp/softfabric';
import ManuealEarnedPointsDetailsViewState from './ManuealEarnedPointsDetailsViewState';
import ManuealEarnedPointsDetailsViewProps from './ManuealEarnedPointsDetailsViewProps';
import ManuealEarnedPointsDetailsViewStyle from './ManuealEarnedPointsDetailsViewStyle';
import ManuealEarnedPointsDetailsViewModel from './ManuealEarnedPointsDetailsViewModel';
import {TranslationView} from '@levelapp/softfabric-ui';
import { TextInput, TouchableWithoutFeedback, View, Image } from 'react-native';
import Constants from 'src/Common/Constants/Constants';
import LinearGradient from "react-native-web-linear-gradient";

export default class ManuealEarnedPointsDetailsView extends ContentView<ManuealEarnedPointsDetailsViewProps, ManuealEarnedPointsDetailsViewState, ManuealEarnedPointsDetailsViewModel, ManuealEarnedPointsDetailsViewStyle>
{
  /* CONSTRUCTOR */
  constructor(props:ManuealEarnedPointsDetailsViewProps)
  {
    super(props);

    // Binding
    this.bind(new ManuealEarnedPointsDetailsViewModel(this), new ManuealEarnedPointsDetailsViewStyle(this));
  }

  /* RENDERING */
  render()
  {
    return (

      <View style={{ minHeight: '100%', height: 200, width: 400 }}>
        <View>
          <LinearGradient start={{ x: 0, y: 0 }} end={{ x: .1, y: 0 }} colors={[Constants.GradiantStartColor, Constants.GradiantEndColor]} >
            <View style={{ display: "flex", flexDirection: "row", alignItems: 'center' }}>
              <TranslationView style={this.style.title}>
                users.popup.points.message
              </TranslationView>



              <TouchableWithoutFeedback onPress={this.binding.close} >
                <Image style={{ height: 23, width: 23, resizeMode: 'contain', alignItems: 'center', marginRight: 20 }}
                  source={{ uri: 'https://appstoragflyx.blob.core.windows.net/app/Console/exit.png' }}></Image>
              </TouchableWithoutFeedback>
            </View>
          </LinearGradient>
        </View>
      <View>
        <TranslationView style={{marginTop: 20, marginLeft: 20,}}>
          user.info.points.details
        </TranslationView>
        <TextInput
          style={this.style.textArea}
          value={this.props.message}
          editable={true}
          multiline={true} />
      </View>
      </View>
    );
  }
}