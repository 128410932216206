 
import {ContentView} from '@levelapp/softfabric';
import ItemManagementViewState from './ItemManagementViewState';
import ItemManagementViewProps from './ItemManagementViewProps';
import ItemManagementViewStyle from './ItemManagementViewStyle';
import ItemManagementViewModel from './ItemManagementViewModel';
import { View, Text } from 'react-native';
import ImageButtonView from '../../../../../Common/Components/imagebutton/ImageButtonView';

export default class ItemManagementView extends ContentView<ItemManagementViewProps, ItemManagementViewState, ItemManagementViewModel, ItemManagementViewStyle>
{
  /* CONSTRUCTOR */
  constructor(props:ItemManagementViewProps)
  {
    super(props);

    // Binding
    this.bind(new ItemManagementViewModel(this), new ItemManagementViewStyle(this));
  }

  /* RENDERING */
  render()
  {
    const { content , inputTitle , deleteButton } = this.style;

    //FIXME: if a new languages is added. This will crash.
    return (
      <View style={content}>
        <Text style={inputTitle}>
          {this.props.children}
        </Text>
        <ImageButtonView 
          image={require('./assets/erase.svg').default} 
          imageHeight={20} 
          imageWidth={20} 
          onClick={() => this.props.onEraseClick(content)} 
          style={deleteButton} />
      </View>
    );
  }
}