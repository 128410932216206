import {Application, ContentViewStyle} from '@levelapp/softfabric';
import { StyleManager, UiManagers } from '@levelapp/softfabric-ui';
import {Dimensions, StyleProp,TextStyle,ViewStyle} from 'react-native';

export default class StoreSummaryPopUpViewStyle extends ContentViewStyle
{
    MyStyleManager : StyleManager = Application.current.resolve<StyleManager>(UiManagers.STYLE_MANAGER);
    
    container : StyleProp<ViewStyle> =
    {
        display: "flex", 
        paddingHorizontal: 50, 
        paddingVertical: 50,
        overflow : 'hidden'
    }

    popup : StyleProp<ViewStyle> =
    {
        minHeight : (Dimensions.get('window').height /100) * 80 , 
        overflow : 'hidden',
    }

    row : StyleProp<ViewStyle> =
    {
        flexDirection : 'row', 
        display : 'flex',
        marginVertical : 5,
    }

    textTitle : StyleProp<TextStyle> =
    {
        fontWeight : 'bold',
        fontSize : 18
    }
}