export enum ContentTypeEnum 
{
    COUPON = 1,
    BOOSTER = 2,
    PROMOCODE = 3,
    REWARD = 4,
    SAVINGCARD = 5,
    PRODUCT = 6,
    BANNER = 7,
    MODIFIERGROUP = 8,
    PURCHASE = 9,
    VOUCHER = 10
}