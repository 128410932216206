 
import {ContentShellView} from '@levelapp/softfabric';
import ShellProductcategoriesViewState from './ShellProductcategoriesViewState';
import ShellProductcategoriesViewProps from './ShellProductcategoriesViewProps';
import ShellProductcategoriesViewStyle from './ShellProductcategoriesViewStyle';
import ShellProductcategoriesViewModel from './ShellProductcategoriesViewModel';
import {View} from 'react-native';

export default class ShellProductcategoriesView extends ContentShellView<ShellProductcategoriesViewProps, ShellProductcategoriesViewState, ShellProductcategoriesViewModel, ShellProductcategoriesViewStyle>
{
  /* CONSTRUCTOR */
  constructor(props:ShellProductcategoriesViewProps)
  {
    super(props);

    // Binding
    this.bind(new ShellProductcategoriesViewModel(this), new ShellProductcategoriesViewStyle(this));
  }

  /* RENDERING */
  render()
  {
    return (
      <View style={{flex : 1}}>
        {this.getSwitch()}
      </View>
    );
  }
}