 
import { ContentView } from '@levelapp/softfabric';
import DropDownViewState from './DropDownViewState';
import DropDownViewProps from './DropDownViewProps';
import DropDownViewStyle from './DropDownViewStyle';
import DropDownViewModel from './DropDownViewModel';
import { Picker } from 'react-native';

export default class DropDownView extends ContentView<DropDownViewProps, DropDownViewState, DropDownViewModel, DropDownViewStyle>
{
  /* CONSTRUCTOR */
  constructor(props: DropDownViewProps) {
    super(props);

    // Binding
    this.bind(new DropDownViewModel(this), new DropDownViewStyle(this));
  }

  /* RENDERING */
  render() {
    this.binding.checkInitialValue();
    return (
        <Picker
          style={this.style.dropDown}
          onValueChange={(value: any) => this.binding.handleValueChanged(value)}
          selectedValue={this.state.selectedValue}>
          {this.props.values.length > 0 && this.props.values.map((value, i) => {
            return (<Picker.Item label={value.title} value={JSON.stringify(value)} key={i} />)
          })}
        </Picker>
    );
  }
}