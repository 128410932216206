 
import { ContentView } from '@levelapp/softfabric';
import PicturesViewState from './PicturesViewState';
import PicturesViewProps from './PicturesViewProps';
import PicturesViewStyle from './PicturesViewStyle';
import PicturesViewModel from './PicturesViewModel';
import { View } from 'react-native';
import ExtendedImageView from '../extendedimage/ExtendedImageView';
import Language from '../../../../../Common/DTO/Language';
import SectionTitleView from '../sectiontitle/SectionTitleView';

export default class PicturesView extends ContentView<PicturesViewProps, PicturesViewState, PicturesViewModel, PicturesViewStyle>
{
  /* CONSTRUCTOR */
  constructor(props: PicturesViewProps) {
    super(props);

    // Binding
    this.bind(new PicturesViewModel(this), new PicturesViewStyle(this));
  }

  /* RENDERING */
  render() {
    const { container } = this.style

    return (
      <View style={(!this.props.shouldSkipThumnail) ? container : null}>
        <SectionTitleView title={"form.images"} />
        <View style={{ display: "flex", flexDirection: "row", flexWrap: "wrap" }}>
          <View style={{ zIndex: 2 }}>
            {/* Images */}
            {this.props.languages.map((content: Language, j: any) => {
              return (
                <ExtendedImageView
                  title={'form.picture'}
                  index={j}
                  id={content.id as number}
                  source={this.props.listImage[j].url}
                  shouldUseDefaultImage={this.props.shouldUseDefaultImage}
                  value={content.key}
                  handleCheckboxChange={(value: boolean) => this.props.handleCheckboxChange('isDefaultImage', value)}
                  handleOnComplete={(base64 : any) => this.props.handleOnComplete('image', base64)}
                  handleOnPressImage={(index: number) => this.props.handleOnPressImage('image', index)}
                />
              );
            })}
          </View>
          {!this.props.shouldSkipThumnail &&
            <View style={{ zIndex: 1 }}>
              {/* Thumbnails */}
              {this.props.languages.map((content: Language, j: any) => {
                return (
                  <ExtendedImageView
                    title={'form.thumbnail'}
                    index={j}
                    id={content.id as number}
                    source={this.props.listThumbnail[j].url}
                    shouldUseDefaultImage={this.props.shouldUseDefaultThumnail}
                    value={content.key}
                    handleCheckboxChange={(value: boolean) => this.props.handleCheckboxChange('isDefaultThumbnail', value)}
                    handleOnComplete={(base64 : any) => this.props.handleOnComplete('thumbnail', base64)}
                    handleOnPressImage={(index: number) => this.props.handleOnPressImage('thumbnail', index)}
                  />

                );
              })}
            </View>
          }
        </View>
      </View>
    );
  }
}