 
import { ContentView } from '@levelapp/softfabric';
import AddProductPopUpViewState from './AddProductPopUpViewState';
import AddProductPopUpViewProps from './AddProductPopUpViewProps';
import AddProductPopUpViewStyle from './AddProductPopUpViewStyle';
import AddProductPopUpViewModel from './AddProductPopUpViewModel';
import { Text, View } from 'react-native';
import Constants from '../../../../../../../Common/Constants/Constants';
import ResearchInputView from '../../../../../../../Common/Components/researchinput/ResearchInputView';
import TableView from '../../../../../../../Common/Components/TabelView/TableView';
import ButtonView from '../../../../../../../Common/Components/Button/ButtonView';
import { TranslationView } from '@levelapp/softfabric-ui';
import Helpers from '../../../../../../../Common/Helpers/Helpers';
import ProductPopupView from '../../../products/pages/productpopup/ProductPopupView';
import PopupHeaderView from '../../../../components/popupheader/PopupHeaderView';
import SingleOrGroupView from './components/singleorgroup/SingleOrGroupView';

export default class AddProductPopUpView extends ContentView<AddProductPopUpViewProps, AddProductPopUpViewState, AddProductPopUpViewModel, AddProductPopUpViewStyle>
{
  /* CONSTRUCTOR */
  constructor(props: AddProductPopUpViewProps) {
    super(props);
    // Binding
    this.bind(new AddProductPopUpViewModel(this), new AddProductPopUpViewStyle(this));
  }
  /* RENDERING */
  render() {
    switch (this.state.windowTab)
    {
      case 'addProduct':
      return (
      <View style={{ minWidth: 600, minHeight: 850, width: Constants.PopUpWidth, maxWidth: "100%" }}>
        <PopupHeaderView 
          shouldShowButton={true} 
          title={this.state.isGroupProduct ? "menuconfigurator.popup.addproduct.header.title.addgroup" : "menuconfigurator.popup.addproduct.header.title.addproduct"} 
          handleOnPressButton={() => this.binding.addProducts()}
          handleBackPressed={() => this.binding.closePopUp()} />

      
        {!this.state.typeProduct ? (
        <View style={this.style.boxContainer}>
         
          <ResearchInputView
            placeholder={this.binding.translate('generic.search')}
            handlerResearching={(searchText: string) => { this.binding.handleResearching(searchText) }}
            handlingClose={() => { this.binding.handleCloseResearching() }} />
          <View>
            <Text>Your products :
            {this.state.listProducts.map((product) => {
              return (
                <Text style={{ marginHorizontal: 10 }}>{product.titleTranslations[0].value}</Text>
              );
            })}
            </Text>
          </View>
        
          {!this.props.isForOutOfStock && <ButtonView isLoading={false} handleClick={() => {this.binding.setField({windowTab:"newProduct"})}} backgroundColor={Constants.MainColor} textColor={"white"} style={{width: 250, marginVertical: 20}}>          
              <TranslationView>
                {this.state.isGroupProduct ? "menuconfigurator.popup.AddProduct.button.textcreationgroup" : "menuconfigurator.popup.AddProduct.button.textcreationproduct"}
              </TranslationView>
          </ButtonView>}
          <TableView
            headerTextStyle={this.style.tableHeader}
            rowStyle={this.style.rowStyle}
            backgroundStepColor={this.style.stepColor}
            nothingToDisplayText={this.binding.translate('component.table.noresults')}
            isLastColumnActions={false}
            isLastColumnCheckBox={true}
            data={this.state.tableData}
            isLoading={this.state.isLoading}
            headers={this.state.tableHeaders}
            onPageChange={(page: number) => this.binding.onPageChange(page)}
            handleSortChange={() => { }}
            nbPage={this.state.nbPage}
            currentPage={this.state.currentPage - 1}
            isSortable={false} />
        </View>
        ):(
          <SingleOrGroupView 
          onPressGroup={() =>
            {
              this.binding.setField({typeProduct:false,isGroupProduct:true},() => this.binding.refresh()); 
              
            }
            }
          onPressProduct={() =>
            {
              this.binding.setField({typeProduct:false,isGroupProduct:false},() => this.binding.refresh()); 
            }}
              />
        )}
      </View>
    );
    case 'newProduct':
        return  <ProductPopupView
        product={{
            productAllergens:[],
            titleTranslations: Helpers.generateTranslationTable(this.state.languages),
            descriptionTranslations: Helpers.generateTranslationTable(this.state.languages),
            imageTranslations: Helpers.generateUrlTable(this.state.languages),
            secondaryTitleTranslations: Helpers.generateTranslationTable(this.state.languages),
            secondaryDescriptionTranslations: Helpers.generateTranslationTable(this.state.languages),
            secondaryImageTranslations: Helpers.generateUrlTable(this.state.languages),
            thumbnailTranslations: Helpers.generateUrlTable(this.state.languages),
        }}
        languages={this.state.languages}
        refreshParent={this.binding.refresh}
        handleBack={()=>{ this.binding.refresh();this.binding.setField({windowTab:"addProduct"})}}
        isGroup={this.state.isGroupProduct}

        />
    default:
        return (<View></View>);   
    } 
  }
}