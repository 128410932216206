import { StyleProp, ViewStyle } from 'react-native';

import { ContentViewStyle } from '@levelapp/softfabric';
import Constants from 'src/Common/Constants/Constants';

export default class ToggleInsertTypeViewStyle extends ContentViewStyle {
  container: StyleProp<ViewStyle> = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'row',  
    borderRadius: 8,
    maxWidth: 270,
    width: '100%',

    shadowColor: "rgba(0,0,0,0.5)",
    shadowOffset: {
      width: 0,
      height: 7,
    },
    shadowOpacity: 0.41,
    shadowRadius: 9.11,
    
    elevation: 14,
    marginBottom: 15,
  }

  item: StyleProp<ViewStyle> = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flex: 1,

    paddingHorizontal: 12,
    paddingVertical: 14,
  }

  selected: StyleProp<ViewStyle> = {
    backgroundColor: Constants.MainColor,
    borderColor: Constants.MainColor,
    borderWidth: 1,
  }
}