import {ContentViewStyle} from '@levelapp/softfabric';
import {StyleProp,ViewStyle} from 'react-native';

export default class MembershipListViewStyle extends ContentViewStyle
{
    page: StyleProp<ViewStyle> = {
        flex: 1
    }

    checkbox: StyleProp<any> = {
        width : 20,
        height : 20,
        marginHorizontal: 5,
        marginBottom: 7
    }
}