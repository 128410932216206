import {ContentViewModel,ContentView} from '@levelapp/softfabric';
import HistoricItemTabNavigationViewState from './HistoricItemTabNavigationViewState';
import HistoricItemTabNavigationViewProps from './HistoricItemTabNavigationViewProps';

export default class HistoricItemTabNavigationViewModel extends ContentViewModel<HistoricItemTabNavigationViewState, HistoricItemTabNavigationViewProps>
{
      /* CONSTRUCTOR */
    constructor(view: ContentView)
    {
        super(view);

        this.initialState({}); // Initialize your state here

        // Bindings

    }
}