import { ContentViewModel, ContentView } from '@levelapp/softfabric';
import RoundButtonViewState from './RoundButtonViewState';
import RoundButtonViewProps from './RoundButtonViewProps';

export default class RoundButtonViewModel extends ContentViewModel<RoundButtonViewState, RoundButtonViewProps>
{

    /* CONSTRUCTOR */
    constructor(view: ContentView)
    {
        super(view);


    }
}