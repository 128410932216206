 
import { View, Text } from 'react-native';

import { ContentView } from '@levelapp/softfabric';

import LabelViewViewState from './LabelViewViewState';
import LabelViewViewProps from './LabelViewViewProps';
import LabelViewViewStyle from './LabelViewViewStyle';
import LabelViewViewModel from './LabelViewViewModel';

export default class LabelViewView extends ContentView<LabelViewViewProps, LabelViewViewState, LabelViewViewModel, LabelViewViewStyle>
{
  /* CONSTRUCTOR */
  constructor(props: LabelViewViewProps) {
    super(props);

    // Binding
    this.bind(new LabelViewViewModel(this), new LabelViewViewStyle(this));
  }

  /* RENDERING */
  render() {
    const { position, label } = this.props;
    const { style } = this;

    return (
      <View style={[
        style.storeGroup,
        {
          marginLeft: position === 'top' ? 15 : 0,
          maxWidth: position === 'top' ? 90 : 150,
          backgroundColor: position === 'top' ? '#f0f0f0' : '#f6e5e2',
        }
      ]}>
        <Text
          style={[
            {
              textAlign: position === 'top' ? 'center' : 'left',
              color: position === 'top' ? '#333333' : '#D83F28'
            },
          ]}
        >
          {label}
        </Text>
      </View>
    );
  }
}