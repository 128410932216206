import { ContentViewModel, ContentView, Application, LanguageManager, Managers } from '@levelapp/softfabric';
import CouponsViewState from './CouponsViewState';
import CouponsViewProps from './CouponsViewProps';
import { TableRow } from '@levelapp/softfabric-ui';
import CouponsOperations from '../../../../../../../Transfer/CouponsOperations';
import CouponsPopUpView from '../../popup/couponspopup/CouponsPopUpView';
 
import LanguagesOperations from '../../../../../../../Transfer/LanguagesOperations';
import ProductsOperations from '../../../../../../../Transfer/ProductsOperations';
import Helpers from '../../../../../../../Common/Helpers/Helpers';
import ConfirmPopUpView from '../../../../../../../Common/Components/confirmpopup/ConfirmPopUpView';
import ToastHelper from '../../../../../../../Common/Helpers/ToastHelper';
import Coupon from '../../../../../../../Common/DTO/Coupon';
import DateHelper from '../../../../../../../Common/Helpers/DateHelper';
import Language from '../../../../../../../Common/DTO/Language';
import CountryCoupon from '../../../../../../../Common/DTO/M&N/CountryCoupon';
import Product from '../../../../../../../Common/DTO/Product';
import PaginationHelper from '../../../../../../../Common/Helpers/PaginationHelper';
import ErrorHelper from '../../../../../../../Common/Helpers/ErrorHelper';
import CountryOperations from '../../../../../../../Transfer/CountryOperations';
import Country from '../../../../../../../Common/DTO/Country';
import ContentTypeKeyOperations from '../../../../../../../Transfer/ContentTypeKeyOperations';
import ContentTypeKey from '../../../../../../../Common/DTO/ContentTypeKey';
import { ContentTypeEnum } from '../../../../../../../Common/Enums/ContentTypeEnum';
import CouponTypesOperations from '../../../../../../../Transfer/CouponTypesOperations';
import CouponType from '../../../../../../../Common/DTO/CouponType';
import Constants from '../../../../../../../Common/Constants/Constants';
import MembershipTypeOperations from 'src/Transfer/MembershipTypeOperations';
import MembershipType from 'src/Common/DTO/MembershipType';
import LoyaltyRulesUnitsOperations from 'src/Transfer/LoyaltyRulesUnitsOperation';
import LoyaltyRulesUnit from 'src/Common/DTO/LoyaltyRulesUnit';
import { LoyaltyRulesKeys } from 'src/Common/Enums/LoyaltyRulesKeys';

export default class CouponsViewModel extends ContentViewModel<CouponsViewState, CouponsViewProps>
{
    operation: CouponsOperations;
    languagesOperation: LanguagesOperations;
    countryOperations : CountryOperations;
    contentTypeKeyOperation: ContentTypeKeyOperations;
    couponTypesOperations: CouponTypesOperations;
    productsOperations: ProductsOperations;
    membershipTypeOperations : MembershipTypeOperations;
    unitsOperations : LoyaltyRulesUnitsOperations;
    _languages: Language[] = [];
    _membershipTypes: MembershipType[] = [];
    _products: Product[] = [];
    _countries: Country[] = [];
    _contentTypeKeys : ContentTypeKey [] = [];
    _couponTypes: CouponType[] = [];
    units : LoyaltyRulesUnit[] = [];
    interval: any;
    searchText: string;
    isSorting: boolean;
    indexHeaderSort: number;
    sortOrder: string;
    sortKey: string[];
    moment : any;

    constructor(props: ContentView) {
        super(props);

        this.interval = null;
        this.searchText = '';
        this.isSorting = false;
        this.indexHeaderSort = -1;
        this.sortOrder = 'asc';
        this.sortKey = ['OrderIndex', 'PosId', 'StartDate', 'EndDate', 'Title', 'Image'];
        this.moment = require('moment');
        /* Initial State */
        this.initialState({ 
            tableData: [], 
            tableHeaders: ['OrderIndex', 'POS Id', 'StartDate', 'EndDate', 'Title',  'Image'], 
            isLoadingCoupon: true, 
            isLoadingProduct: true, 
            isLoadingLanguage: true, 
            isLoadingCountry: true, 
            isLoadingMembership: Constants.ShowMembership, 
            isLoadingContantTypeKeys : Constants.UseSettingsContent,
            nbPage: 0, 
            currentPage: 1, 
            isResearching: false });
        this.operation = new CouponsOperations();
        this.languagesOperation = new LanguagesOperations();
        this.productsOperations = new ProductsOperations();
        this.countryOperations = new CountryOperations();
        this.contentTypeKeyOperation = new ContentTypeKeyOperations();
        this.couponTypesOperations = new CouponTypesOperations();
        this.membershipTypeOperations = new MembershipTypeOperations();
        this.unitsOperations = new LoyaltyRulesUnitsOperations();

        Constants.ShowMembership && this.membershipTypeOperations.getAll((membershipTypes: MembershipType[]) => {
            this._membershipTypes = membershipTypes;
            this.setField({ isLoadingMembership: false });
        }, this.errorManagement);
        this.languagesOperation.get((languages: any) => {
            this._languages = languages.data;
            this.setField({ isLoadingLanguage: false });
        });
        this.countryOperations.get((countries:Country[]) => {
            this._countries = countries;
            this.setField({ isLoadingCountry: false });
        })
        Constants.UseSettingsContent && this.couponTypesOperations.get((data: any) => {
            data.data.forEach((couponType: CouponType) => {
                couponType.title = couponType.name
            });
            this._couponTypes = data.data;
            this.setField({ isLoadingContantTypeKeys: false });
        });
        

        this.unitsOperations.get((data: any) => {
            const units: LoyaltyRulesUnit[] = data.data
            this.units = units.filter(u => u.loyaltyRulesKeyId === LoyaltyRulesKeys.CouponRenewableCooldown)
        })

        /* BINDINGS */
        this.editField = this.editField.bind(this);
        this.addField = this.addField.bind(this);
        this.deleteField = this.deleteField.bind(this);
        this.refresh = this.refresh.bind(this);
        this.onPageChange = this.onPageChange.bind(this);
        this.onSortChange = this.onSortChange.bind(this);
        this.handleResearching = this.handleResearching.bind(this);
        this.errorManagement = this.errorManagement.bind(this);
        this.handleCloseResearching = this.handleCloseResearching.bind(this);
    }


    async componentDidMount() {
        this.refresh();

        const products = await this.productsOperations.getLight();

        this._products = products.filter((value: Product) => value.title != undefined && value.title.translationValues != undefined && value.title.translationValues.length > 0 && value.title.translationValues[0].value != undefined ? true : false);
        this._products.map((value : Product) =>
        {
            value.title = (value as any).title.translationValues[0].value;
        });
        this.setField({ isLoadingProduct: false });
    }

    refresh() {
        this.setField({ 
            isLoadingCoupon: true, 
            isLoadingContantTypeKeys : Constants.UseSettingsContent,
        });
        Constants.UseSettingsContent && this.contentTypeKeyOperation.getContentTypeKeys(ContentTypeEnum.COUPON,(contentTypeKeys:ContentTypeKey[]) => {
            this._contentTypeKeys = contentTypeKeys;
            this.setField({ isLoadingContantTypeKeys: false });
        },this.errorManagement)
        let parameters =PaginationHelper.basicResearchPush(
            this.state().currentPage,
            20,
            this.sortKey[this.indexHeaderSort],
            this.sortOrder,
            Application.current.resolve<LanguageManager>(Managers.LANGUAGE_MANAGER).language ,
            this.searchText,
            this.isSorting,
            this.state().isResearching);
        parameters.push(PaginationHelper.pushCustomKey('isEverydayValue',this.props().type == 'coupons' ? false : true))
        this.getData(parameters);

    }
    
    getData(queryParamaters : any[]) {
        this.operation.get(queryParamaters, (coupons: any) => {
            let tableData: TableRow[] = [];
            coupons.data.objects.forEach((coupon: Coupon) => {
                let title = (coupon.titleTranslations == null || coupon.titleTranslations.length==0) ? "" : coupon.titleTranslations[0].value;
                coupon.StartDateInput = new Date(coupon.startDate);
                coupon.EndDateInput = new Date(coupon.endDate);
                if (Constants.UseSettingsContent) coupon.couponType.title = coupon.couponType.name;
                let img = (coupon.imageTranslation == null|| coupon.imageTranslation.length==0) ? "" : coupon.imageTranslation[0].url;
                tableData.push({ data: [
                    coupon.orderIndex, 
                    coupon.posId, 
                    DateHelper.formatToEuropeanDate(coupon.startDate), 
                    DateHelper.formatToEuropeanDate(coupon.endDate), 
                    title, 
                    img], 
                    isDeletable: true, 
                    isEditable: true, 
                    editCallback: () => { this.editField(coupon) }, 
                    deleteCallback: () => { this.deleteField(coupon) } });
            });
            this.setField({ tableData: tableData, coupons: coupons.data.objects, nbPage: coupons.data.totalPage, isLoadingCoupon: false });
        },this.errorManagement)
    }

    addField() {
        const p : number[] = [];
        const rp : number[]= [];
        const cc : CountryCoupon[]= [];
        this.showPopUp(
            <CouponsPopUpView
                coupon={{
                    orderIndex : 0,
                    thridPartId : '',
                    subTitle : '',
                    posId : '',
                    validityDuration : 0,
                    validityDurationUnit : 0,
                    isPreview : false,
                    isAnonymousVisible : false,
                    isRenewable : false,
                    hasDuration : false,
                    isMarketingAutomation : false,
                    isMultipleScan: false,
                    isExclusive: false,
                    isEverydayValue : this.props().type == 'everyday' ? true : false,
                    EndDateInput : new Date(),
                    StartDateInput : new Date(),
                    endDate : new Date(),
                    startDate : new Date(),
                    titleTranslations: Helpers.generateTranslationTable(this._languages),
                    descriptionTranslations: Helpers.generateTranslationTable(this._languages),
                    imageTranslation: Helpers.generateUrlTable(this._languages),
                    thumbnailTranslation: Helpers.generateUrlTable(this._languages),
                    requiredProducts: rp,
                    freeProducts: p,
                    miniumValue: "0",
                    discountValue: "0",
                    countryCoupons: cc,
                    storeIds:[],
                    contentTypeKeyValue : [],
                    couponType: Constants.UseSettingsContent ? {
                        id: this._couponTypes[0].id,
                        name: this._couponTypes[0].name,
                        title : this._couponTypes[0].name
                    } :{
                        id: 1,
                        name: "Default",
                        title : "Default"
                    },
                    couponBasketOrderTypes: [],
                    availableHours: [],
                    couponOrderTypeOrderSources: [],
                    couponMemberships : [],
                    loyaltyRulesUnit : this.units[0],
                    loyaltyRulesUnitValue : this.units[0].defaultValue
                }}
                products={this._products}
                type={this.props().type}
                refreshParent={this.refresh}
                languages={this._languages}
                countries={this._countries}
                contentTypeKeys={this._contentTypeKeys} 
                couponType={this._couponTypes}
                membershipTypes={this._membershipTypes}
                units={this.units}/>
        );
    }

    editField(coupon: any) 
    {
        let couponCopy = Helpers.clone(coupon);
        if(couponCopy.requiredProduct == undefined)
        {
            const rp = {} as Product;
            couponCopy.requiredProduct = rp;
        }
        if(couponCopy.product == undefined)
        {
            const p = {} as Product;
            couponCopy.product = p;
        }
        if(couponCopy.countryCoupons == undefined)
        {
            const cc = {} as CountryCoupon[];
            couponCopy.countryCoupons = cc;
        }
        if(couponCopy.storeIds == undefined)
        {
            couponCopy.storeIds = [];
        }
        if(couponCopy.titleTranslations === null){
            couponCopy.titleTranslations = Helpers.generateTranslationTable(this._languages);
        }
        if(couponCopy.descriptionTranslations === null){
            couponCopy.descriptionTranslations = Helpers.generateTranslationTable(this._languages);
        }
        couponCopy.StartDateInput = new Date(couponCopy.startDate);
        couponCopy.EndDateInput = new Date(couponCopy.endDate);
        couponCopy.imageTranslation = Helpers.generateUrlTableEdit(couponCopy.imageTranslation, this._languages);
        couponCopy.thumbnailTranslation = Helpers.generateUrlTableEdit(couponCopy.thumbnailTranslation, this._languages);
        this.showPopUp(
            <CouponsPopUpView  
            type={this.props().type}
            coupon={couponCopy} 
            refreshParent={this.refresh} 
            languages={this._languages} 
            products={this._products}  
            countries={this._countries} 
            contentTypeKeys={this._contentTypeKeys}
            couponType={this._couponTypes}
            membershipTypes={this._membershipTypes}
            units={this.units}/>
        );
    }

    deleteField(coupon: any) {
        this.showPopUp(
            <ConfirmPopUpView callBack={() => {
                this.operation.delete(coupon, () => {
                    this.showToast(ToastHelper.getToast("coupons.popup.success.deleted","success"),() => {},2500);
                    this.refresh();
                },this.errorManagement);
            }} />
        );
    }

    

    onPageChange(page: number) {
        this.state().currentPage = page + 1;
        this.refresh()
    }

    onSortChange(indexHeaderSort: number, sortOrder: string) {
        if (indexHeaderSort == -1) {
            this.isSorting = false;
        } else {
            this.indexHeaderSort = indexHeaderSort;
            this.sortOrder = sortOrder;
            this.isSorting = true;
        }
        this.refresh();
    }

    handleResearching(searchText: string) {
        this.setField({ isLoading: true, isResearching: true });
        this.searchText = searchText;

        if (this.interval) {
            clearInterval(this.interval);
        }
        this.interval = setTimeout(() => {
            this.onPageChange(0)
        }, 1000)
    }

    handleCloseResearching() {
        this.state().isResearching = false;
        this.onPageChange(0);
    }

 

    errorManagement(error : any) 
    {
        this.setField({isLoading : false});
        ErrorHelper.showMessageError(error.response);
    }
}