 
import { Animated, View, Image, TouchableWithoutFeedback } from 'react-native';

import { ContentView } from '@levelapp/softfabric';
import { LottieView, TranslationView } from '@levelapp/softfabric-ui';

import DeliverySettingsPopupViewState from './DeliverySettingsPopupViewState';
import DeliverySettingsPopupViewProps from './DeliverySettingsPopupViewProps';
import DeliverySettingsPopupViewStyle from './DeliverySettingsPopupViewStyle';
import DeliverySettingsPopupViewModel from './DeliverySettingsPopupViewModel';

import PopupHeaderView from 'src/Presentation/modules/dashboard/components/popupheader/PopupHeaderView';
import Helpers from 'src/Common/Helpers/Helpers';
import Constants from 'src/Common/Constants/Constants';
import RoundButtonView from 'src/Presentation/modules/dashboard/components/roundbutton/RoundButtonView';
import SwitchView from 'src/Presentation/modules/dashboard/components/switch/SwitchView';
import assets from 'src/Common/Assets';

export default class DeliverySettingsPopupView extends ContentView<DeliverySettingsPopupViewProps, DeliverySettingsPopupViewState, DeliverySettingsPopupViewModel, DeliverySettingsPopupViewStyle>
{
  /* CONSTRUCTOR */
  constructor(props: DeliverySettingsPopupViewProps) {
    super(props);

    // Binding
    this.bind(new DeliverySettingsPopupViewModel(this), new DeliverySettingsPopupViewStyle(this));
  }

  /* RENDERING */
  render() {
    return (
      <Animated.View style={[this.style.kitchenModus, { transform: [{ translateY: 0 }] }]}>
        <PopupHeaderView
          full
          handleBackPressed={Helpers.closePopup}
          title="delivery.settings"
          shouldShowButton={false}
          isLoading={false}
        />
        {!this.state.isUberEatIntegrationButtonLoading && (
          <View style={{ paddingVertical: 25, paddingLeft: 60, paddingRight: 20 }}>
            <View style={{ flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', paddingVertical: 10 }}>
              <TranslationView>
                delivery.update
              </TranslationView>
              <RoundButtonView
                type="normal"
                shouldUseDefaultPadding
                handleOnPress={this.binding.updateDelivery}
                backgroundColor={this.state.isUpdateDeliveryLoading ? '#dcdcdc' : undefined}
              >
                {this.state.isUpdateDeliveryLoading ? (
                  <LottieView height={25} width={40} source={assets.buttonLoader} />
                ) : (
                  <Image
                    resizeMode="contain"
                    style={{ width: 35, height: 35 }}
                    source={assets.updateDelivery}
                  />
                )}
              </RoundButtonView>
            </View>
            <View style={{ flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', paddingVertical: 10 }}>
              <TranslationView>
                delivery.update.ubereat
              </TranslationView>
              <TouchableWithoutFeedback onPress={this.binding.updateUberEatDeliveryIntegration}>
                <View>
                  <SwitchView enabled={this.state.isUberEatIntegrationEnabled} enabledColor="#99D597" />
                </View>
              </TouchableWithoutFeedback>
            </View>
          </View>
        )}
        {this.state.isUberEatIntegrationButtonLoading  && (
          <LottieView height={150} width={150} source={require('src/Common' + Constants.LottieAnimationPath)} />
        )}


      </Animated.View>
    );
  }
}