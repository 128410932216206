 
import {ContentView} from '@levelapp/softfabric';
import MainInformationViewState from './MainInformationViewState';
import MainInformationViewProps from './MainInformationViewProps';
import MainInformationViewStyle from './MainInformationViewStyle';
import MainInformationViewModel from './MainInformationViewModel';
import LineView from '../../../../../../components/line/LineView';
import ExtendedBlockTextInputView from '../../../../../../components/extendedblocktextinput/ExtendedBlockTextInputView';
import { CheckBox, View } from 'react-native';
import ExtendedTextInputView from '../../../../../../components/extendedtextinput/ExtendedTextInputView';
import Language from '../../../../../../../../../Common/DTO/Language';
import Helpers from '../../../../../../../../../Common/Helpers/Helpers';
import { TranslationView } from '@levelapp/softfabric-ui';

export default class MainInformationView extends ContentView<MainInformationViewProps, MainInformationViewState, MainInformationViewModel, MainInformationViewStyle>
{
  /* CONSTRUCTOR */
  constructor(props:MainInformationViewProps)
  {
    super(props);

    // Binding
    this.bind(new MainInformationViewModel(this), new MainInformationViewStyle(this));
  }

  /* RENDERING */
  render()
  {

    const { block } = this.style;

    return (
      <View style={{ display: "flex", flexDirection: "column", paddingHorizontal: 170, paddingVertical: 50 }}>
            
            <View style={block}>
              <ExtendedTextInputView
                showError={this.props.showError && this.props.question.orderIndex == 0 }
                title={"form.index"}
                infoText={this.binding.translate('generic.orderindex.info').replace('{1}',this.binding.translate('faq.title.singular').toLowerCase())}
                value={this.props.question.orderIndex}
                onChangeText={(text : string) => this.props.handleChangeValue('orderindex',text)}
              />
              <View style={{ flex: 1, justifyContent: "flex-end", flexDirection: 'row' }}>
                <View style={{ flexDirection: 'row', alignItems:'center'}}>
                  <CheckBox style={this.style.checkbox} value={this.props.question.isPreview} onValueChange={(value : boolean) => this.props.handleCheckboxChange('isPreviewVisible',value)} />
                  <TranslationView style={this.style.titleActive}>form.preview</TranslationView>
                </View>
              </View>
            </View>
            

            <LineView/>

            
            <View style={block}>
              {/* Title */}
              {this.props.languages.map((content: Language) => {
                var _text = Helpers.getTranslationValueByLanguageKey(this.props.question.titleTranslations,content.key);
                return (
                  <ExtendedBlockTextInputView
                    onChangeText={(text : string) => this.props.handleChangeText('title',text, content.id as number)}
                    value={_text}
                    beforeTitle={"form.titlefield"}
                    title={content.key}
                    showError={this.props.showError}
                  />
                );
              })}
            </View>


            {/* Middle line */}
            <LineView/>

            <View style={block}>
              {/* Description */}
              {this.props.languages.map((content: Language) => {
                
                var _text = Helpers.getTranslationValueByLanguageKey(this.props.question.descriptionTranslations,content.key);
                return (
                  <ExtendedBlockTextInputView
                    onChangeText={(text : string) => this.props.handleChangeText('description',text, content.id as number)}
                    value={_text}
                    beforeTitle={"form.description"}
                    title={content.key}
                    showError={this.props.showError}/>
                );
              })}
            </View>


            

          </View>
    );
  }
}