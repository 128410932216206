import {ContentShellViewModel, ContentShellView} from '@levelapp/softfabric';
import ShellFaqViewState from './ShellFaqViewState';
import ShellFaqViewProps from './ShellFaqViewProps';
 
import EnumModules from '../../../../../Common/Constants/EnumModules';
import FaqView from './pages/FaqView';

export default class ShellFaqViewModel extends ContentShellViewModel<ShellFaqViewState, ShellFaqViewProps>
{
    constructor(view : ContentShellView)
    {
        super(view);

        this.registerEntryPoint('main');
        this.register(<FaqView/> ,'main');

        this.initialisation();
    }

    getModuleId()
    {
        return EnumModules.FAQ;
    }
}