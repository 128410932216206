import {ContentShellViewModel, ContentShellView} from '@levelapp/softfabric';
import ShellVouchersViewState from './ShellVouchersViewState';
import ShellVouchersViewProps from './ShellVouchersViewProps';
 
import EnumModules from '../../../../../Common/Constants/EnumModules';
import VouchersView from './pages/vouchers/VouchersView';

export default class ShellVouchersViewModel extends ContentShellViewModel<ShellVouchersViewState, ShellVouchersViewProps>
{

    constructor(view : ContentShellView)
    {
        super(view);

        this.registerEntryPoint('main');
        this.register(<VouchersView type={'vouchers'}/> ,'main');

        this.initialisation();
    }

    getModuleId()
    {
        return EnumModules.VOUCHERS;
    }

}