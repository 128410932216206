import { Service, Application, HttpManager, Managers } from '@levelapp/softfabric';


export default class RewardTagService extends Service
{
    httpClient = Application.current.resolve<HttpManager>(Managers.HTTP_MANAGER);

    async get(onSuccess: Function, onFailure : Function = () => {})
    {      
        this.httpClient.handleSomethingWentWrong = onFailure;
        this.httpClient.handleNotFound = onFailure;
        
        this.httpClient
        .get('rewardTag/Get/en-GB')
        .then((response : any) => {
            if (response)
            {
                if (response.data)
                {                  
                    onSuccess(response.data);
                }
            }
        });
    }

    put(rewardTag: {rewardId: number, tagId?: number, label?: string }, onSuccess : Function = () => {}, onFailure : Function = () => {})
    {
        this.httpClient.handleSomethingWentWrong = onFailure;
        this.httpClient.handleNotFound = onFailure;

        this.httpClient
            .put('rewardTag/Tag', [], rewardTag)
            .then((response : any) => 
            {
                if (response && response.data)
                {
                    onSuccess(response.data);
                }
            });
    }

    delete(rewardTag: {rewardId: number, tagId: number}, onSuccess : Function = () => {}, onFailure : Function = () => {})
    {
        this.httpClient.handleSomethingWentWrong = onFailure;
        this.httpClient.handleNotFound = onFailure;

        this.httpClient
            .delete(`rewardTag/Tag/${rewardTag.rewardId}/${rewardTag.tagId}`)
            .then((response : any) => 
            {
                if (response)
                {
                    onSuccess("");
                }
            });
    }
}