import { Service, Application, HttpManager, Managers } from '@levelapp/softfabric';
import Composition from '../Common/DTO/Composition';

export default class CompositionService extends Service
{
    httpClient = Application.current.resolve<HttpManager>(Managers.HTTP_MANAGER);

    async get(parameters : any[], onSuccess: Function)
    {       
        // var para:any[]=[];
        this.httpClient
        .get('compositions', parameters)
        .then((response : any) => {
            if (response)
            {
                if (response.data)
                {  
                    onSuccess(response.data);
                }
            }
        });
    }

    post(composition:Composition, callback : Function = () => {})
    {
        this.httpClient
            .post('compositions', [], composition)
            .then((response : any) => 
            {
                if (response)
                {
                    /*if (response.data)
                    {
                        callback(response.data);
                    }*/
                    callback("");
                }
            });
    }

    put(composition:any, callback : Function = () => {})
    {
        this.httpClient
            .put('compositions', [], composition)
            .then((response : any) => 
            {
                if (response)
                {
                    /*if (response.data)
                    {
                        callback(response.data);
                    }*/
                    callback("");
                }
            });
    }

    delete(composition:Composition, callback : Function = () => {})
    {
        this.httpClient
            .delete('compositions/delete/'+composition.parentProductId+"/"+composition.childProductId)
            .then((response : any) => 
            {
                if (response)
                {
                    callback("");
                }
            });

    }

    save(listSave:any, callback : Function = () => {})
    {
        this.httpClient
        .put('compositions/Save', [], listSave)
        .then((response : any) => 
        {
            if (response)
            {
                /*if (response.data)
                {
                    callback(response.data);
                }*/
                callback("");
            }
        });
    }
}