import { Operation } from "@levelapp/softfabric";
import PriceHistoryService from "src/Proxy/PriceHistoryService";

export default class PriceHistoryOperations extends Operation {
  service: PriceHistoryService;

  constructor() {
    super();
    this.service = new PriceHistoryService();
  }

  get(productId: number) {
    return this.service.get(productId);
  }
}