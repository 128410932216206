// 
import  PosSimulatorViewProps  from './PosSimulatorViewProps';
import  PosSimulatorViewState  from './PosSimulatorViewState';
import { ContentViewModel } from '@levelapp/softfabric';
import { PosSimulationOperations } from '../../../../../../../Transfer/PosSimulatorOperations';
import Profile from '../../../../../../../Common/DTO/Profile';
import CouponConsumption from '../../../../../../../Common/DTO/CouponConsumption';
import TicketLine from '../../../../../../../Common/DTO/TicketLine';
import { TableRow } from '@levelapp/softfabric-ui';
import LanguagesOperations from '../../../../../../../Transfer/LanguagesOperations';
import Purchase from '../../../../../../../Common/DTO/Purchase';
import Ticket from '../../../../../../../Common/DTO/Ticket';
import * as moment from 'moment';



export default class PosSimulatorViewModel extends ContentViewModel<PosSimulatorViewState, PosSimulatorViewProps>
{
    operation :PosSimulationOperations;
    isSorting: boolean;
    interval: any;
    langaugesOperation: LanguagesOperations;
    _languages: string[] = [];
    indexHeaderSort: number;
    sortOrder: string;
    sortKey: string[];
  
    constructor(props : any)
    {
        super(props);
        this.isSorting = false;
        this.indexHeaderSort = -1;
        this.sortOrder = 'asc';
        this.sortKey = ['Coupon/Reward', 'Date'];

        /* Initial State */ 
        var state : PosSimulatorViewState = this.getStateDefault();
        
        
        this.initialState({result : undefined , isProcessWorking : () => {}, qrcode : '', tableHeaders: state?.tableHeaders, tableData:  state.tableData, isLoading: state.isLoading, nbPage: state.nbPage, currentPage: state.currentPage, isResearching: state?.isResearching, purchaseAmount: state.purchaseAmount, userInfo: state.userInfo, userId: state.userId , purchase : state.purchase, disabled: true, disabledPurchaseButtons: true, opacity: 0.5, opacityPurchaseButton: 0.7 });
        this.operation = new PosSimulationOperations();
        this.langaugesOperation = new LanguagesOperations();

        this.langaugesOperation.get((langauges: any) => {
          this._languages = langauges.data;
      });

        /* BINDINGS */

        this.handleScan = this.handleScan.bind(this);
        this.handleError = this.handleError.bind(this);
        this.confirm = this.confirm.bind(this);
        this.reset = this.reset.bind(this);
        this.confirmPurchase = this.confirmPurchase.bind(this);
        this.resetPurchase = this.resetPurchase.bind(this);

    }


    private checkInWhichCase(data: any){

      var profiles = data as Profile;
      var coupon_reward = data as CouponConsumption;
      if(profiles.loyaltyCard != undefined){
          this.profilCodeIsAlreadyScanned(profiles);
          
      }
      else if(coupon_reward.posCouponId != undefined){
          var userInfo = this.state().userInfo;
          if(userInfo === ''){
            this.initiateStateCaseY(coupon_reward);
          }
          else{
              this.initiateStateCaseZ(coupon_reward);
          }
          
      }
       
  }

  private initiateStateCaseX(param: Profile)
  {
      let data: TableRow[]=[];
      var lines: TicketLine[] = [];
      var ticket = this.ticket(lines);
      var purchase = this.purchaseCaseX(ticket);
      this.setStateCaseX(param, purchase, data);
      
      
  }

  
  private initiateStateForUnidentifiedClient(){
    let data: TableRow[]=[];
    var lines: TicketLine[] = [];
    var ticket = this.ticket(lines);
    var purchase = this.purchaseCaseUnidentifiedClient(ticket);
    this.SetStateCaseUnidentifiedClient(purchase, data);
  }
  
  private initiateStateCaseY(coupon: CouponConsumption){
      
      let data: TableRow[]=[];
      var line = this.ticketLine(coupon);
      var lines: TicketLine[] = [];
      lines.push(line);
      var ticket = this.ticket(lines);
      var purchase = this.purchaseCaseY(coupon, ticket);
      lines.forEach((line: TicketLine) => {
          data.push({ data: [line.couponUUID, this.getDateForDob()]});
      });
      this.setStateCaseY(coupon, purchase, data);
     
  
  }

  private initiateStateCaseZ(coupon: CouponConsumption){
      let data: TableRow[]=[];
      var line = this.ticketLine(coupon);
      this.state().purchase.Ticket.lines.push(line);
      this.state().purchase.Ticket.lines.forEach((line: TicketLine) => {
          data.push({data: [line.couponUUID, this.getDateForDob()]});
      });
      this.setField({tableData: data});
      this.checkClient(coupon);

  }



    /* HANDLERS */

    handleScannedCode(qrcode: string) {
      this.setField({disabled: false});
      this.setField({opacity: 1.0});
      this.setField( {qrcode: qrcode});
    }

    handleScan(data : any)
    {
        if (data) {
          alert(data +' has been scanned');
            this.handleScannedCode(data);       
        }
    }
    
    handleError(err : any){
      alert(err);
      console.error(err);
    }

    handlePurchaseAmount(purchaseAmount: string) {
      this.setField({disabledPurchaseButtons: false});  
      this.setField({opacityPurchaseButton: 2.0});
      this.setField({ purchaseAmount: purchaseAmount});
  }


    /* FUNCTIONS */

    refresh() {       
      let tableData: TableRow[]=[];
      tableData.push({ data:this.state().tableData });
    }

      onPageChange(page: number){
         this.state().currentPage = page + 1;
         this.refresh()
       }

       onSortChange(indexHeaderSort: number, sortOrder: string){
        if (indexHeaderSort == -1) {
            this.isSorting = false;
        } else {
            this.indexHeaderSort = indexHeaderSort;
            this.sortOrder = sortOrder;
            this.isSorting = true;
        }
        this.refresh();
    }

      confirm(){
       
        this.HandleConfirmProcess();
      }
 
      reset(){
        let code = this.state().qrcode;
        if( code != ''){        
          this.setField({qrcode: ''});
          this.setField({disabled: true});
          this.setField({opacity: 0.5});
        }
      }

      forScanIdProfile(){
          let code = this.state().qrcode;        
          this.operation.get(code, (data: Profile) =>{
            if(data){
              data.qrcode = code;
              this.checkInWhichCase(data);
            }             
          });     
     } 

     forScanIdCoupon(){
      let code = this.state().qrcode;
      if(this.couponIsAlreadyScanned(code)){
        alert(code+' has already been scanned !');
      }
      else{
        this.operation.getCoupon(code, (data: CouponConsumption) =>{
          if(data){
              data.qrCode = code;
              this.checkInWhichCase(data);
          }
        });
      }
      
     }

     
     couponIsAlreadyScanned(qrcode: string){
        var res = false;       
        this.state().purchase.Ticket.lines.forEach((line: TicketLine) =>{
            if(line.couponUUID === qrcode){
              res = true;
              alert(qrcode+' has already been scanned !')
            }
        });
        return res;
     }    
     checkClient(coupon: CouponConsumption){
         var profil = this.state().userId;
         var coupon_profil = coupon.userInfos.cardCode;
         if(profil !== coupon_profil){
             this.setField({userId: coupon.userInfos.cardCode, userInfo: coupon.userInfos.firstName+" "+coupon.userInfos.lastName});
         }
     }

    getDateForDob(){
     return moment.utc().format("DD/MM/YYYY");
  }

    getDateForSysDate(){  
      return moment.utc().format();

  }

        HandleConfirmProcess(){
      var purchase = this.state().purchase;
      if(purchase != undefined){
          var length = this.state().purchase.Ticket.lines.length;
          if(length == 10){
            alert('You have reached the limit of coupon/reward you can scan !');
          }
          else{
              this.confirmActionAuthorised()
          }
      }
      else{
        this.confirmActionAuthorised();
      }
      
  }

  confirmActionAuthorised(){
    let code = this.state().qrcode;
    if( code != ''){
      let qrcode = this.state().qrcode;
      if(qrcode.length >= 8){
          this.forScanIdProfile();
      }
      else{
          this.forScanIdCoupon();
      }
      this.setField({qrcode : ""});          
    }
    else{
      alert('Please scan or enter a qrcode!');
    }
  }

  ticketLine(coupon:  CouponConsumption){
      
    var ticketLine : TicketLine = {

        entryid: 0,
        itemId: Number(coupon.posCouponId),
        itemname: "PosSimulatorPurchase_"+this.getDateForSysDate(),
        couponUUID: coupon.qrCode,
        quantity: 1,
        totalVatInclude: 7.7,
        vat: 0.0,
        taxid: 1,
        parententrid: 0,
        ismaincombo: true,
        incombo: false,
    }
    return ticketLine;
}

private setStateCaseY(coupon: CouponConsumption, purchase: Purchase, data: TableRow[]){
    this.setField({
        tableHeaders: ['Coupon/Reward', 'Date'],
        tableData: data,
        isLoading: false,
        nbPage: 0,
        currentPage: 1,
        isResearching: false,
        purchaseAmount: "",
        userInfo: coupon.userInfos.firstName+" "+coupon.userInfos.lastName,
        userId: coupon.userInfos.cardCode,
        purchase: purchase,
        disabledPurchaseButtons: false,
        disabled: true,
        opacity: 0.5,
        opacityPurchaseButton: 3.0,
    })
}

 private setStateCaseX(param: Profile, purchase: Purchase ,data: TableRow[]){
    this.setField({
        tableHeaders: ['Coupon/Reward', 'Date'],
        tableData: data,
        isLoading: false,
        nbPage: 0,
        currentPage: 1,
        isResearching: false,
        purchaseAmount: "",
        userInfo: param.firstName+' '+param.firstName,
        userId: param.loyaltyCard.code,
        purchase: purchase,
        disabledPurchaseButtons: false,
        disabled: true,
        opacity: 0.5,
        opacityPurchaseButton: 3.0,
    });

}

private SetStateCaseUnidentifiedClient(purchase: Purchase, data:TableRow[]){
    this.setField({
        tableHeaders: ['Coupon/Reward', 'Date'],
        tableData: data,
        isLoading: false,
        nbPage: 0,
        currentPage: 1,
        isResearching: false,
        purchaseAmount: "",
        userInfo: '',
        userId: '',
        purchase: purchase,
        disabledPurchaseButtons: false,
        disabled: true,
        opacity: 0.5,
        opacityPurchaseButton: 3.0,
    });
}

getStateDefault(){

    let result = undefined;
    let data: TableRow[]=[];
    data.push({ data:[ '', ''] });
    var lines: TicketLine[] = [];
    var ticket = this.ticket(lines);
    var purchase = this.purchaseCaseX(ticket);
    var state : PosSimulatorViewState =
    {
        isProcessWorking: () => {},
        result: result,
        qrcode: "",
        tableHeaders: ['Coupon/Reward', 'Date'],
        tableData: data,
        isLoading: false,
        nbPage: 0,
        currentPage: 1,
        isResearching: false,
        purchaseAmount: "",
        userInfo: '',
        userId: '',
        purchase: purchase,
    }
    return state;

}
getDateChekId(){
    var date = new Date();
    var hour = date.getHours().toString();
    var minute = date.getMinutes().toString();
    var seconde = date.getSeconds().toString();
    var checkId = hour+minute+seconde;
    var res = Number(checkId);
    return res;
}

ticket(lines: TicketLine[]){
    var Ticket: Ticket = {
        Id: 0,
        entityCode: 'PosSimulator',
        checkId: this.getDateChekId(),
        dob: this.getDateForDob(),
        sysDate: this.getDateForSysDate(),
        operatorId: 0,
        operatorName: "PosSimulator",
        terminalName: "Azure",
        totalAmount: 0,
        lines: lines,
    }

    return Ticket;

}

purchaseCaseY(coupon: CouponConsumption, ticket: Ticket){
    var purchase: Purchase = {
        CardCode:coupon.userInfos.cardCode,
        ClaimCode: null,
        Ticket: ticket,
        Language: "fr",
        IsMemberWithoutCard: false,
    };
    return purchase;

}
purchaseCaseX(ticket: Ticket){
    var purchase: Purchase = {
        CardCode: '',
        ClaimCode: null,
        Ticket: ticket,
        Language: '',
        IsMemberWithoutCard: false,
    };
    return purchase;

}

 purchaseCaseUnidentifiedClient(ticket: Ticket){
     var purchase: Purchase = {
        CardCode: '',
        ClaimCode: this.setCardCodeKey(),
        Ticket: ticket,
        Language: '',
        IsMemberWithoutCard: false,
     }
     return purchase;
 }

 setCardCodeKey(){
     var date = new Date();
     var div = date.getFullYear()/date.getMonth();
     var res = div * date.getDay();
     return Math.floor(res);
 }

setInitialState(){

  var tableData: TableRow[] = [];
  var amount = "";
  var userInfo = "";
  var lines: TicketLine[] = [];
  var ticket = this.ticket(lines);
  var purchase = this.purchaseCaseX(ticket);
  var qrcode = "";
  this.setField({ qrcode: qrcode, tableData: tableData, purchaseAmount: amount, userInfo: userInfo, userId: userInfo, purchase: purchase, disabledPurchaseButtons: true, opacityPurchaseButton: 0.7});

}

resetPurchase(){

    var coupons: any[] = [];
    this.state().purchase.Ticket.lines.forEach((line: TicketLine) => {
        coupons.push(line.couponUUID);
    });
    this.operation.cancelCoupons(coupons, () =>{
    });
    this.setInitialState();  
   
}
isPurchaseNotPossible(){
    var uInfos = this.state().userInfo;
    if(uInfos === ''){
        this.initiateStateForUnidentifiedClient();
        this.fillTicketTotalAmount();     
    }
    else{
        this.fillTicketTotalAmount();
    }
    
}

validatePurchase(){
    this.isPurchaseNotPossible();   
}

confirmPurchase(){
    this.validatePurchase();
   
}

sendPurchase(){
    var purchase = this.state().purchase;
   this.operation.confirmPurchase(purchase, () =>{
   });
   this.setInitialState();
}



fillTicketlineEntryId(){
    var cp = 1;
    this.state().purchase.Ticket.lines.forEach((line: TicketLine) => {
        line.entryid = cp;
        ++cp;
    });
    this.sendPurchase();
}

fillTicketTotalAmount(){
    var amount = this.state().purchaseAmount;
    if(amount !== ""){
        this.state().purchase.Ticket.totalAmount = Number(amount);
        if(this.state().userId !== ''){
            this.fillTicketlineEntryId();
        }
        else{
            this.sendPurchase();
        }      
    }
    else{
        alert('You need to enter an amount to confirm a purchase !');
    }
}



profilCodeIsAlreadyScanned(profile: Profile){
    var user_profile = this.state().userId; 
    if(user_profile === profile.qrcode){
        alert(user_profile+' has already been scanned !');
    }
    else{
        this.initiateStateCaseX(profile);
    }
}
}
