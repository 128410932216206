import LoginViewState from './LoginViewState';
import LoginViewProps from './LoginViewProps';
import { ContentViewModel, ContentView, Application } from '@levelapp/softfabric'
import LoginOperation from '../../../Transfer/LoginOperation';
import ConnectObject from '../../../Common/DTO/ConnectObject';
import UserInfosOperations from '../../../Transfer/UserInfosOperations';
import UserInfos from '../../../Common/DTO/UserInfos';
import ToastHelper from '../../../Common/Helpers/ToastHelper';
import PermissionOperation from '../../../Transfer/Identity/PermissionOperations';
import RoleManager from '../../../Common/Managers/RoleManager';
import ExtendedManagers from '../../../Common/Managers/ExtendedManagers';
import { TextInputKeyPressEventData, NativeSyntheticEvent } from 'react-native';

export default class LoginViewModel extends ContentViewModel<LoginViewState, LoginViewProps>
{
    /* OPERATIONS */
    loginOperation : LoginOperation;
    permissionOperation : PermissionOperation;
    userInfosOperation : UserInfosOperations;

    /* MANAGERS */
    roleManager : RoleManager;

    constructor(props: ContentView) 
    {
        super(props);

        /* INITIAL STATE */
        this.initialState({ email: '', password: '', isLoading: false });
        localStorage.setItem("isLogged", "false"); 
        localStorage.removeItem("Permissions");

        /* BINDING */
        this.login = this.login.bind(this);
        this.handleEnter = this.handleEnter.bind(this);
        this.handleGetConfig = this.handleGetConfig.bind(this);
        this.handleSuccessLogin = this.handleSuccessLogin.bind(this);
        this.handleFailureLogin = this.handleFailureLogin.bind(this);
        this.handleEmailChanged = this.handleEmailChanged.bind(this);
        this.handlePasswordChanged = this.handlePasswordChanged.bind(this);
        this.handleSuccessUsereInfos = this.handleSuccessUsereInfos.bind(this);
        this.handleFailureUsereInfos = this.handleFailureUsereInfos.bind(this);
        this.handleFailureGetConfig = this.handleFailureGetConfig.bind(this);
       
        /* NEW OPERATIONS*/
        this.loginOperation = new LoginOperation();
        this.userInfosOperation = new UserInfosOperations();
        this.permissionOperation = new PermissionOperation();

        /* MANAGERS */
        this.roleManager = Application.current.resolve<RoleManager>(ExtendedManagers.ROLE_MANAGER);
    }

    /* HANDLERS */
    handleEmailChanged(email: string) 
    {
        this.setField({ email: email });
    }

    handlePasswordChanged(password: string) 
    {
        this.setField({ password: password });
    }

    handleEnter(e : NativeSyntheticEvent<TextInputKeyPressEventData>)
    {
        if(e.nativeEvent.key == "Enter")
        {
            this.login();
        }
    }

    /* FUNCTIONS */

    //LOGIN
    login() 
    {
        if (this.state().email !== '' && this.state().password !== '') 
        {
            this.setField({ isLoading: true });
            /* We call the login service */
            let credentials: any = { "email": this.state().email, "password": this.state().password };
            this.loginOperation.post(credentials,this.handleSuccessLogin, this.handleFailureLogin);
        }
        else 
        {
            this.showToast( ToastHelper.getToast("login.form.incomplete" , "error") ,() => {}, 2500)
            this.setField({ isLoading: false });
        }
    }

    //LOGIN HANDLERS
    async handleSuccessLogin(data: ConnectObject)
    {
        if (data.access_token !== "") {
            await this.permissionOperation.get(this.handleGetConfig, this.handleFailureGetConfig);
            this.userInfosOperation.get(this.handleSuccessUsereInfos, this.handleFailureUsereInfos);
        }else 
        {
            //TODO:
        }
    }

    async handleGetConfig(data : any)
    {
        if(data != null)
        {
            localStorage.setItem("Permissions", JSON.stringify(data));  
            await this.roleManager.setPermissions(data);
        }
    }

    async handleFailureGetConfig()
    {
        this.showToast( ToastHelper.getToast("login.error.permissions" , "error") ,() => {}, 2500)
    }

    async handleFailureLogin(response : any)
    {
        if(response.status == 404)
        {
            this.showToast( ToastHelper.getToast("login.error.connection.notfound" , "error") ,() => {}, 2500)
        }else
        {
            if(response.status != undefined)
            {
                var errormessage = await response.json();
                if(errormessage != null)
                {
                    switch(errormessage.error)
                    {
                        case "invalid_grant":
                            this.showToast( ToastHelper.getToast("login.error.connection.grant" , "error") ,() => {}, 2500)
                            break;
                        case "invalid_client":
                            this.showToast( ToastHelper.getToast("login.error.connection.client" , "error") ,() => {}, 2500)
                            break;
                        
                    }
                }
            }else
            {
                this.showToast( ToastHelper.getToast("login.error.connection.notconnection" , "error") ,() => {}, 2500)
            }
            
        }
        
        

        this.setField({ isLoading: false });
    }

    //USERINFOS HANDLERS
    handleSuccessUsereInfos(_data: UserInfos)
    {
        this.setField({ isLoading: false }, () => {                            
            localStorage.setItem("isLogged", "true");   
            this.navigate('/dashboard', true, {});
        });
    }

    handleFailureUsereInfos()
    {
        //403 usually
        this.showToast( ToastHelper.getToast("login.error.role" , "error") ,() => {}, 2500)
        this.setField({isLoading : false});
    }
}   