import { Operation } from "@levelapp/softfabric";
import VouchersService from "../Proxy/VouchersService";

export default class VouchersOperations extends Operation
{
    service : VouchersService;

    constructor()
    {
        super();
        this.service = new VouchersService();
    }

    get(parameters : any[], callback : Function, onFailure : Function)
    {
        this.service.get(parameters, callback,onFailure);
    }

    post(coupon:any, callback : Function, onFailure : Function)
    {
        this.service.post(coupon, callback,onFailure);
    }

    put(coupon:any, callback : Function, onFailure : Function)
    {
        this.service.put(coupon, callback,onFailure);
    }

    delete(coupon:any, callback : Function, onFailure : Function)
    {
        this.service.delete(coupon, callback,onFailure);
    }
}