import { Operation } from "@levelapp/softfabric";
import BoosterTypesService from '../Proxy/BoosterTypesService';

export default class BoosterTypesOperations extends Operation{
    service : BoosterTypesService;

    constructor()
    {
        super();
        this.service = new BoosterTypesService();
    }

    get(callback : Function)
    {
        this.service.get(callback);
    }
}