import { StyleProp, TextStyle, ViewStyle } from 'react-native';

import { ContentViewStyle } from '@levelapp/softfabric';

export default class PriceTabViewStyle extends ContentViewStyle {
  container: StyleProp<ViewStyle> = {
    marginHorizontal: 35,
    paddingVertical: 15,
  }

  storeGroupContainer: StyleProp<ViewStyle> = {
    display: 'flex',
    marginTop: 18,
  }

  topLabels: StyleProp<ViewStyle> = {
    display: 'flex',
    flexDirection: 'row', 
    alignItems: 'center',
    marginLeft: 150,
  }

  row: StyleProp<ViewStyle> = {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    paddingVertical: 5,
    borderBottomColor: '#ECECEC',
    borderBottomWidth: 1,
  }

  inputs: StyleProp<ViewStyle> = {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginVertical: 8,
  }

  input: StyleProp<ViewStyle> = {
    borderWidth: 1,
    borderColor: '#DCCDCD',
    marginLeft: 15,
    borderRadius: 3,
    maxWidth: 90,
    maxHeight: 38,
  }

  inputView: StyleProp<ViewStyle> = {
    position: 'relative',
  }

  euroLabel: StyleProp<TextStyle> = {
    position: 'absolute',
    top: 11,
    right: 10,
    color: '#A8A8A8',
  }
}