 
import {ContentView} from '@levelapp/softfabric';
import { Text, TextInput, View } from 'react-native';
import { TranslationView} from '@levelapp/softfabric-ui';
import PointsSectionViewState from './PointsSectionViewState';
import PointsSectionViewProps from './PointsSectionViewProps';
import PointsSectionViewStyle from './PointsSectionViewStyle';
import PointsSectionViewModel from './PointsSectionViewModel';
import User from '../../../../../../../../../../../Common/DTO/User';
import LoyaltyCard from '../../../../../../../../../../../Common/DTO/LoyaltyCard';
import ButtonView from "../../../../../../../../../../../Common/Components/Button/ButtonView";
import SectionTitleView from '../../../../../../../../components/sectiontitle/SectionTitleView';
import Constants from '../../../../../../../../../../../Common/Constants/Constants';


export default class PointsSectionView extends ContentView<PointsSectionViewProps, PointsSectionViewState, PointsSectionViewModel, PointsSectionViewStyle>
{
  /* CONSTRUCTOR */
  constructor(props:PointsSectionViewProps)
  {
    super(props);

    // Binding
    this.bind(new PointsSectionViewModel(this), new PointsSectionViewStyle(this));
  }

  /* RENDERING */
  render()
  {
    const { containerTop , containerBottom, containerCenter , input} = this.style;

    return (
      <View>
        <View style={containerTop}>
          <SectionTitleView title={'form.updatepoints'}/>
          <Text style={{ paddingBottom: 20 }}> ({this.binding.translate('users.maininformation.section.points').replace('{1}',`${((this.state.user as User).loyaltyCard as LoyaltyCard).points}`)})</Text>
        </View>
        <View style={containerCenter}>
        <View style={this.style.addPoints}>
        <TranslationView>
          user.info.points.add
          </TranslationView>
        <TextInput 
          onChangeText={(points: string) => this.binding.handlePointsChanged(points)} 
          placeholder="0" style={input}/>
        </View>
          <View style={this.style.addedPoints}>
          <TranslationView>
          user.info.points.message
          </TranslationView>
          <TextInput
            style={this.style.textArea}
            onChangeText={(text: string) => this.binding.handleTextChanged(text)}/>
        </View>
        </View>
        <View style={containerBottom}>
          <ButtonView isLoading={this.state.isLoadingPoints} style={{ height: 40, width: 85 }} backgroundColor={Constants.GradiantStartColor} handleClick={this.binding.handlePointsAdded}>
            <TranslationView>generic.add</TranslationView>
          </ButtonView>

          <ButtonView isLoading={this.state.isLoadingPoints} style={{ marginLeft: 15, height: 40,  }} backgroundColor={Constants.GradiantStartColor} handleClick={this.binding.handleCodeGeneration}>
            <TranslationView>generic.generateCode</TranslationView>
          </ButtonView>
          {this.state.generatedCode.length > 0 && <View><Text style={this.style.code}>{this.state.generatedCode}</Text></View>}
        </View>
      </View>
    );
  }
}