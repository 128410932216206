import {ContentViewModel,ContentView} from '@levelapp/softfabric';
import ExtendedCheckBoxViewState from './ExtendedCheckBoxViewState';
import ExtendedCheckBoxViewProps from './ExtendedCheckBoxViewProps';

export default class ExtendedCheckBoxViewModel extends ContentViewModel<ExtendedCheckBoxViewState, ExtendedCheckBoxViewProps>
{
      /* CONSTRUCTOR */
    constructor(view: ContentView)
    {
        super(view);

        this.initialState({}); // Initialize your state here

        // Bindings

    }
}