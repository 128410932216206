import { Operation } from "@levelapp/softfabric";
import UserMetadataValuesService from "../Proxy/UserMetadataValuesService";

export default class UserMetadataValuesOperations extends Operation{
    
    service : UserMetadataValuesService;

    constructor(){
        super();
        this.service = new UserMetadataValuesService();
    }
    get(callBack:Function){
        this.service.get(callBack);
    }
}