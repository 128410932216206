import {ContentViewStyle, Application} from '@levelapp/softfabric';
import {StyleProp,ViewStyle, TextStyle, ImageStyle} from 'react-native';
import { StyleManager, UiManagers } from '@levelapp/softfabric-ui';

export default class PopupHeaderViewStyle extends ContentViewStyle
{
    MyStyleManager : StyleManager = Application.current.resolve<StyleManager>(UiManagers.STYLE_MANAGER);

    page: StyleProp<ViewStyle> = {
        flex: 1,
    }

    container : StyleProp<any> =
    {
        position : 'fixed',
        zIndex : 1,
    }

    title: StyleProp<TextStyle> = {
        textAlign: 'left',
        color: 'white',
        fontSize: 24,
        fontWeight: '600',
        display: "flex",
    };

    line : StyleProp<ViewStyle> =
    {
         display: "flex", 
         flexDirection: "row", 
         alignItems : 'center',
         paddingLeft : 60,
         padding : 20,
         justifyContent: 'space-between'
    }

    image : StyleProp<ImageStyle> =
    {
        height: 23, 
        width: 23, 
        resizeMode: 'contain', 

    }

    saveButton: StyleProp<TextStyle> = {
        color: this.MyStyleManager.primaryColor,
        fontSize: 10,
        fontWeight: '600',
        height: 27,
        width: 112
    };
}