 
import { View } from 'react-native';

import { ContentView } from '@levelapp/softfabric';
import { TranslationView } from '@levelapp/softfabric-ui';

import RemoveProductFromGroupModalViewState from './RemoveProductFromGroupModalViewState';
import RemoveProductFromGroupModalViewProps from './RemoveProductFromGroupModalViewProps';
import RemoveProductFromGroupModalViewStyle from './RemoveProductFromGroupModalViewStyle';
import RemoveProductFromGroupModalViewModel from './RemoveProductFromGroupModalViewModel';

import ButtonView from 'src/Common/Components/Button/ButtonView';
import Constants from 'src/Common/Constants/Constants';
export default class RemoveProductFromGroupModalView extends ContentView<RemoveProductFromGroupModalViewProps, RemoveProductFromGroupModalViewState, RemoveProductFromGroupModalViewModel, RemoveProductFromGroupModalViewStyle>
{
  /* CONSTRUCTOR */
  constructor(props: RemoveProductFromGroupModalViewProps) {
    super(props);

    // Binding
    this.bind(new RemoveProductFromGroupModalViewModel(this), new RemoveProductFromGroupModalViewStyle(this));
  }

  /* RENDERING */
  render() {
    const { id } = this.props;
    const { isRemoving } = this.state;
    const { style } = this;

    return (
      <View style={style.container}>
        <TranslationView style={style.title}>
          tools.products.deleteProduct
        </TranslationView>
        <TranslationView style={style.message}>
          tools.products.deleteGroup.children
        </TranslationView>
        <View style={style.buttons}>
          <ButtonView
            isLoading={isRemoving}
            handleClick={() => this.binding.onRemove(id)}
            backgroundColor={Constants.MainColor}
            style={style.addButton}
          >
            <TranslationView>
              generic.yes
            </TranslationView>
          </ButtonView>
          <ButtonView
            isLoading={false}
            handleClick={this.binding.onClose}
            textColor={Constants.MainColor}
            backgroundColor="transparent"
            style={style.cancelButton}
          >
            <TranslationView>
              generic.cancel
            </TranslationView>
          </ButtonView>
        </View>
      </View>
    );
  }
}