 
import {ContentShellView} from '@levelapp/softfabric';
import ShellOverviewViewState from './ShellOverviewViewState';
import ShellOverviewViewProps from './ShellOverviewViewProps';
import ShellOverviewViewStyle from './ShellOverviewViewStyle';
import ShellOverviewViewModel from './ShellOverviewViewModel';
import {View} from 'react-native';

export default class ShellOverviewView extends ContentShellView<ShellOverviewViewProps, ShellOverviewViewState, ShellOverviewViewModel, ShellOverviewViewStyle>
{
  /* CONSTRUCTOR */
  constructor(props:ShellOverviewViewProps)
  {
    super(props);

    // Binding
    this.bind(new ShellOverviewViewModel(this), new ShellOverviewViewStyle(this));
  }

  /* RENDERING */
  render()
  {
    return (
      <View style={{flex : 1}}>
        {this.getSwitch()}
      </View>
    );
  }
}