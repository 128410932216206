import {Application, ContentViewStyle} from '@levelapp/softfabric';
import { StyleManager, UiManagers } from '@levelapp/softfabric-ui';
import {Dimensions, StyleProp,ViewStyle} from 'react-native';

export default class MessageCampaignStatsViewStyle extends ContentViewStyle
{
    MyStyleManager : StyleManager = Application.current.resolve<StyleManager>(UiManagers.STYLE_MANAGER);
    
    container : StyleProp<ViewStyle> =
    {
        display: "flex", 
        paddingHorizontal: 170, 
        paddingVertical: 50,
        flexDirection : "row",
    }

    popup : StyleProp<ViewStyle> =
    {
        minHeight : (Dimensions.get('window').height /100) * 80 , 
    }

    verticalDivider : StyleProp<ViewStyle> = 
    {   zIndex: -1,
        backgroundColor: "#D8D8D8",
        width: 1,
        marginHorizontal : 30,
        height: "100%"
    }
}