import {ContentShellViewModel, ContentShellView} from '@levelapp/softfabric';
import ShellStatsViewState from './ShellStatsViewState';
import ShellStatsViewProps from './ShellStatsViewProps';
import EnumModules from '../../../../../Common/Constants/EnumModules';
import StatsView from './pages/stats/StatsView';
import StatsSettingsView from './pages/statssettings/StatsSettingsView';
 

export default class ShellStatsViewModel extends ContentShellViewModel<ShellStatsViewState, ShellStatsViewProps>
{
    constructor(view : ContentShellView)
    {
        super(view);

        this.registerEntryPoint('main');
        this.register(<StatsView/> ,'main');
        this.register(<StatsSettingsView/> , 'settings');

        this.initialisation();
    }

    getModuleId()
    {
        return EnumModules.STATS;
    }
}