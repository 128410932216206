import { ContentViewModel, ContentView } from '@levelapp/softfabric';
import CountriesViewState from './CountriesViewState';
import CountriesViewProps from './CountriesViewProps';
import VoucherCountry from '../../../../../../../../../Common/DTO/M&N/VoucherCountry';
import Country from '../../../../../../../../../Common/DTO/Country';
import Constants from '../../../../../../../../../Common/Constants/Constants';

export default class CountriesViewModel extends ContentViewModel<CountriesViewState, CountriesViewProps>
{
    constructor(view: ContentView)
    {
        super(view);
        /* Initial State */
        this.initialState({
            isForAllCountries: this.props().voucher.voucherCountries != undefined && this.props().voucher.voucherCountries.length == 0 ? true : false,
            selectedCountries: [],
            color: Constants.MainColor,
        });

        if (this.props().voucher.voucherCountries != undefined && this.props().voucher.voucherCountries.length > 0)
        {
            this.state().selectedCountries = this.props().countries.filter((country: Country) => this.props().voucher.voucherCountries.find((x: VoucherCountry) => x.countryId == country.id) != undefined)
        }

        this.handleCheckBoxChanged = this.handleCheckBoxChanged.bind(this);
    }

    handleCheckBoxChanged(value: boolean)
    {
        if (value)
        {
            this.props().handleValueChange('country', []);
        }
        this.setField({ isForAllCountries: value });
    }
}