import {ContentViewModel,ContentView} from '@levelapp/softfabric';
import AdvancedInformationSectionViewState from './AdvancedInformationSectionViewState';
import AdvancedInformationSectionViewProps from './AdvancedInformationSectionViewProps';

export default class AdvancedInformationSectionViewModel extends ContentViewModel<AdvancedInformationSectionViewState, AdvancedInformationSectionViewProps>
{
      /* CONSTRUCTOR */
    constructor(view: ContentView)
    {
        super(view);

        this.initialState({user: this.props().user, isLoadingAdvancedInformations: this.props().isLoadingAdvancedInformations}); // Initialize your state here

        // Bindings

    }
}