 
import {ContentView} from '@levelapp/softfabric';
import ExtendedSelectViewState from './ExtendedSelectViewState';
import ExtendedSelectViewProps from './ExtendedSelectViewProps';
import ExtendedSelectViewStyle from './ExtendedSelectViewStyle';
import ExtendedSelectViewModel from './ExtendedSelectViewModel';
import Select from "react-dropdown-select";
import { View, Text } from 'react-native';

export default class ExtendedSelectView extends ContentView<ExtendedSelectViewProps, ExtendedSelectViewState, ExtendedSelectViewModel, ExtendedSelectViewStyle>
{
  /* CONSTRUCTOR */
  constructor(props:ExtendedSelectViewProps)
  {
    super(props);

    // Binding
    this.bind(new ExtendedSelectViewModel(this), new ExtendedSelectViewStyle(this));
  }

  /* RENDERING */
  render()
  {
    //style={{ marginBottom: 13 }}
    return (
      <View style={{paddingVertical : 5}} >
          <Text>{this.props.label}</Text>
          <Select
            addPlaceholder={this.props.placeholder}
            multi
            color={this.props.shouldShowError ? "red" : undefined}
            searchable={true}
            values={this.props.value}
            options={this.props.options}
            onChange={(value: any[]) => this.props.onChange(value)}
            labelField={this.props.title}
            valueField={this.props.title}
            keepSelectedInList={true}
            onDropdownClose={() => this.props.onDropdownClose != undefined ? this.props.onDropdownClose() : () => {}}
            onDropdownOpen={() => this.props.onDropdownOpen != undefined ? this.props.onDropdownOpen() : () => {}}
            dropdownPosition={this.props.dropDownPosition != null ? this.props.dropDownPosition : "top"}
            disabled={this.props.disabled != null ? this.props.disabled : false}
            separator={true}
          />
        </View>
    );
  }
}