import { StyleProp, ViewStyle, TextStyle, ImageStyle } from 'react-native';

import { ContentViewStyle, Application } from '@levelapp/softfabric';
import { StyleManager, UiManagers } from '@levelapp/softfabric-ui';

export default class MenuItemViewStyle extends ContentViewStyle {
  MyStyleManager = Application.current.resolve<StyleManager>(UiManagers.STYLE_MANAGER);

  link: StyleProp<ViewStyle> = {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',

    maxWidth: 390,
    height: 55,
    borderColor: '#E4E4E4',
    paddingLeft: 5,
  };

  linkTextActive: StyleProp<TextStyle> = {
    paddingLeft: 25,
  };

  linkBoldText: StyleProp<TextStyle> = {
    color: 'black',
    paddingLeft: 25,
    fontFamily: 'Montserrat',
    fontWeight: '900'
  }

  item: StyleProp<ViewStyle> = {
    flexDirection: 'row',
  };

  row: StyleProp<ViewStyle> = {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'space-between'
  };

  image: StyleProp<ImageStyle> = {
    width: 10,
    height: 10,
    marginRight: 25,
    alignSelf: 'center',
  }

  animatedView: StyleProp<TextStyle> = {
    width: '100%',
    height: '100%',
  }

  button: StyleProp<ViewStyle> = {
    backgroundColor: 'red',
  }
}