 

import { ContentViewModel, ContentView } from '@levelapp/softfabric';

import PopupMenuZoneViewState from './PopupMenuZoneViewState';
import PopupMenuZoneViewProps from './PopupMenuZoneViewProps';
import AddZoneView from '../addzone/AddZoneView';
import StoreGroupOperations from 'src/Transfer/StoreGroupOperations';
import AddStoreGroup from 'src/Common/DTO/AddStoreGroup';
import ToastHelper from 'src/Common/Helpers/ToastHelper';

export default class PopupMenuZoneViewModel extends ContentViewModel<PopupMenuZoneViewState, PopupMenuZoneViewProps>
{
  storeGroupOperation: StoreGroupOperations

  /* CONSTRUCTOR */
  constructor(view: ContentView) {
    super(view);

    this.initialState({});

    this.storeGroupOperation = new StoreGroupOperations();

    // Bindings
    this.openEditModal = this.openEditModal.bind(this);
    this.onUpdate = this.onUpdate.bind(this);
  }

  openEditModal() {
    this.showPopUp(
      <AddZoneView
        onAddZone={this.onUpdate}
        storeGroup={this.props().storeGroup}
      />
    );
  }

  async onUpdate(storeGroup: AddStoreGroup) {
    try {
      await this.storeGroupOperation.update({
        id: storeGroup.id,
        name: storeGroup.name,
        parentId: storeGroup.parentId,
        stores: storeGroup.stores,
      });
      this.props().onPopupClose();
    } catch {
      this.showToast(ToastHelper.getToast("tools.zone.update.error", "error"), () => { }, 2500);
    }
  }
}