import { Operation } from "@levelapp/softfabric";
import OutOfStock from "../Common/DTO/OutOfStock";
import OutOfStockService from "../Proxy/OutOfStockService";

export default class OutOfStockOperations extends Operation
{
    service : OutOfStockService;

    constructor()
    {
        super();
        this.service = new OutOfStockService();
    }
    
    get(parameters:any[],callBack:Function, onFailure: Function){
        this.service.getOutOfStockProducts(parameters,callBack,onFailure);
    }

    post(outofstocks: OutOfStock[],  onSuccess: Function, onFailure: Function){
        this.service.post(outofstocks, onSuccess,onFailure);
    }
    delete(outofstock: OutOfStock,  onSuccess: Function, onFailure: Function){
        this.service.delete(outofstock, onSuccess,onFailure);
    }
}