import { ContentViewModel, ContentView } from '@levelapp/softfabric';
import ChangeStatusPopupViewState from './ChangeStatusPopupViewState';
import ChangeStatusPopupViewProps from './ChangeStatusPopupViewProps';
import BasketStatus from '../../../../../../../Common/DTO/BasketStatus';

export default class ChangeStatusPopupViewModel extends ContentViewModel<ChangeStatusPopupViewState, ChangeStatusPopupViewProps>
{
    /* CONSTRUCTOR */
    constructor(view: ContentView)
    {
        super(view);

        this.initialState({
            statusesLoading: this.props().statuses.map(() => false)
        }); // Initialize your state here

        // Bindings
        this.isButtonEnabled = this.isButtonEnabled.bind(this);
        this.handleButtonPressed = this.handleButtonPressed.bind(this);
    }

    isButtonEnabled(): boolean
    {
        return this.state().statusesLoading.find(x => x == true) == undefined;
    }

    handleButtonPressed(status: BasketStatus, index: number)
    {
        const { statusesLoading } = this.state();
        statusesLoading[index] = true;

        this.setField({ statusesLoading }, () =>
        {
            this.props().handleStatusSelected(status);
        })
    }
}