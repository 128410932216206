 
import { TouchableOpacity, View, Text, Image } from 'react-native';

import assets from 'src/Common/Assets';

import { ContentView } from '@levelapp/softfabric';

import TreeMenuViewState from './TreeMenuViewState';
import TreeMenuViewProps from './TreeMenuViewProps';
import TreeMenuViewStyle from './TreeMenuViewStyle';
import TreeMenuViewModel from './TreeMenuViewModel';
import PopupMenuZoneView from '../popupmenuzone/PopupMenuZoneView';

export default class TreeMenuView extends ContentView<TreeMenuViewProps, TreeMenuViewState, TreeMenuViewModel, TreeMenuViewStyle>
{
  /* CONSTRUCTOR */
  constructor(props: TreeMenuViewProps) {
    super(props);
    this.state = { show: false }

    // Binding
    this.bind(new TreeMenuViewModel(this), new TreeMenuViewStyle(this));
  }

  /* RENDERING */
  render() {
    const { node, data, level, childCount, totalStoreCount, showTree } = this.props;
    const { style } = this;

    const MARGIN = 40;
    const Z_INDEX = 1000 - level;

    const tree = data && data.map((node) => {
      if (!node.childrenStoreGroups) {
        return (
          <View style={style.treeElement}>
            <View style={style.horizontal} />
            <View
              style={style.leftContent}
              key={node.fakeId}
            >
              <Image
                source={assets.resto}
                style={{ height: 20, width: 20 }}
              />
              <Text
                // @ts-ignore
                title={node.name}
                style={[style.label, { maxWidth: 350 }]}
                numberOfLines={1}
              >
                {node.name}
              </Text>
            </View>
          </View>
        );
      }

      return (
        <TreeMenuView
          childCount={node.childCount}
          key={node.fakeId}
          node={node}
          data={node.childrenStoreGroups}
          totalStoreCount={node.totalStoreCount}
          level={level + 1}
          showTree
          onAdd={this.props.onAdd}
          onRemove={this.props.onRemove}
          onPopupClose={this.props.onPopupClose}
        />
      );
    });

    return (
      <>
        <TouchableOpacity
          activeOpacity={1}
          style={[
            style.treeElement,
            { zIndex: Z_INDEX },
          ]}
          onPress={this.binding.toggleView}
        >
          {showTree && <View style={style.horizontal} />}
          <View style={style.sideGroup}>
            <View style={style.leftContent}>
              {childCount !== undefined && childCount > 0 && (
                <Image
                  source={this.state.show ? assets.arrowDownGray : assets.arrowRightGray}
                  style={{ height: 15, width: 15 }}
                />
              )}
              <Text
                // @ts-ignore
                title={node.name}
                style={[style.label, { maxWidth: 275 }]}
                numberOfLines={1}
              >
                {node.name}
              </Text>
            </View>
            {totalStoreCount !== undefined && totalStoreCount > 0 && (
              <View style={style.count}>
                <Text style={{ color: 'white' }}>
                  {totalStoreCount}
                </Text>
              </View>
            )}
          </View>
          <View style={style.rightButtons}>
            <TouchableOpacity onPress={() => this.props.onAdd(node.id)} style={style.rightButton}>
              <Text style={{ fontSize: 18 }}>+</Text>
            </TouchableOpacity>
            <TouchableOpacity style={style.rightButton}>
              <PopupMenuZoneView
                onRemove={() => this.props.onRemove(node.id)}
                storeGroup={node}
                onPopupClose={this.props.onPopupClose}
              />
            </TouchableOpacity>
          </View>
        </TouchableOpacity>
        {this.state.show && (
          <View style={style.treeWrapper}>
            <View style={[style.treeIndicator, { marginLeft: MARGIN }]} />
            <View style={style.treeChildren}>{tree}</View>
          </View>
        )}
      </>
    );
  }
};