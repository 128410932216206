import { ContentViewModel, ContentView } from '@levelapp/softfabric';
import TranslationNonContentPopUpViewState from './TranslationNonContentPopUpViewState';
import TranslationNonContentPopUpViewProps from './TranslationNonContentPopUpViewProps';

export default class TranslationNonContentPopUpViewModel extends ContentViewModel<TranslationNonContentPopUpViewState, TranslationNonContentPopUpViewProps>
{
    constructor(props: ContentView)
    {
        super(props);
        /* Initial State */
        this.initialState({
            translation: this.props().translation,
            languages: this.props().languages,
            showErrors: false,
            isSaving: false,
            title: (this.props().translation.id == 0) ? this.translate("translation.Add") : this.translate("faq.popup.noncontent.title"),
        });

        /* BINDINGS */
        this.handleBackPressed = this.handleBackPressed.bind(this);
        this.handleSavePressed = this.handleSavePressed.bind(this);
        this.handleTextChanged = this.handleTextChanged.bind(this);
        this.handleKeyChanged = this.handleKeyChanged.bind(this);
    }
    /* COMPONENT EVENTS */
    componentWillMount() { }

    /* HANDLERS */
    handleBackPressed()
    {
        this.closePopUp();
    }

    handleKeyChanged(text: any)
    {
        this.setField((previousState) => previousState.translation.key = text);
    }

    handleTextChanged(text: any, lang: any)
    {
        this.setField((previousState) => previousState.translation.translationValues.find((x: any) => x.language.key == lang).value = text);
    }

    handleSavePressed()
    {
        this.setField((previousState) => previousState.isSaving = true);
        this.props().save(this.props().translation);
    }
}