import {ContentViewModel,ContentView} from '@levelapp/softfabric';
import AdminUsersPopupViewState from './AdminUsersPopupViewState';
import AdminUsersPopupViewProps from './AdminUsersPopupViewProps';
import UserRoles from '../../../../../../../Common/DTO/M&N/UserRoles';
import RoleOperations from '../../../../../../../Transfer/Identity/RoleOperations';
import Role from '../../../../../../../Common/DTO/Role';
import UserIdentity from '../../../../../../../Common/DTO/Identity/UserIdentity';
import PermissionUser from '../../../../../../../Common/DTO/M&N/PermissionUser';

export default class AdminUsersPopupViewModel extends ContentViewModel<AdminUsersPopupViewState, AdminUsersPopupViewProps>
{
    _roleOperation : RoleOperations;

      /* CONSTRUCTOR */
    constructor(view: ContentView)
    {
        super(view);

        this.initialState({
            userrole : this.props().userrole,
            allRoles : [],
            showErrors: false,
            index: 0,
            tab: "main",
            isLoading: false,
            isLoadingPermissions : false,
        });


        // Bindings
        this.changeTab = this.changeTab.bind(this);
        this.handleBackPressed = this.handleBackPressed.bind(this);
        this.handleSavePressed = this.handleSavePressed.bind(this);
        this.handleChangeEmail = this.handleChangeEmail.bind(this);
        this.handleFieldChanged = this.handleFieldChanged.bind(this);
        this.handleGetAllRoles = this.handleGetAllRoles.bind(this);
        this.handleChangePermissionUser = this.handleChangePermissionUser.bind(this);

        this._roleOperation = new RoleOperations();
    }

    componentDidMount()
    {
        this._roleOperation.getRoles(this.handleGetAllRoles);
    }

    handleGetAllRoles(data : Role[])
    {
        this.setField({allRoles : data});
    }

    changeTab(tab: any) {
        this.setField((previousState) => previousState.tab = tab);
    }

    handleBackPressed() {
        this.closePopUp();
    }

    handleSavePressed() {

        var role = (this.state().userrole as UserRoles).role;
        var user = ((this.state().userrole as UserRoles).user as UserIdentity);

        if (
            role == null || 
            (user.id == null && user.email == null ) ||
            (user.id == null && user.password == null ) ||
            (user.id == null && user.confirmPassword == null ) ||
            (user.id != null && user.email == null )
            )
            {
            this.setField((previousState) => previousState.showErrors = true);
        }
        else {
            this.setField((previousState) => previousState.showErrors = false);
            this.setField((previousState) => previousState.isLoading = true);
            this.props().saveAdminUser(this.state().userrole);
        }
    }

    handleFieldChanged(type : 'email' | 'role' | 'password' | 'confirmpassword' | 'permissionusers', any : any , index : number)
    {
        switch(type)
        {
            case 'email':
                this.handleChangeEmail(any);
                break;
            case 'password':
                this.handleChangePassword(any);
                break;
            case 'confirmpassword':
                this.handleChangeConfirmPassword(any);
                break;
            case 'role':
                this.handleChangeRole(any);
                break;
            case 'permissionusers':
                this.handleChangePermissionUser(any,index);
                break;
        }
        
    }

    private handleChangePermissionUser(text : string , permissionId : number)
    {
        var state = this.state();
        var pu = (((state.userrole as UserRoles).user as UserIdentity).permissionsUsers as PermissionUser[])
        if(pu == undefined)
        {
            (((state.userrole as UserRoles).user as UserIdentity).permissionsUsers as PermissionUser[]) = [];
            pu = (((state.userrole as UserRoles).user as UserIdentity).permissionsUsers as PermissionUser[]);
        };
        if( pu != undefined &&  pu.length > 0 )
        {
            var temp = pu.find(x => x.permissionId == permissionId);
            if(temp != null)
            {
                temp.extraData = text;
            }else
            {
                var newPu : PermissionUser = 
                {
                    extraData : text,
                    permissionId : permissionId
                };
                
                pu.push(newPu);
            }
        }else
        {
            var newPu : PermissionUser = 
                {
                    extraData : text,
                    permissionId : permissionId
                };
                
            pu.push(newPu);
        }

        this.setField({state});
    }

    private handleChangePassword(text : string)
    {
        this.setField((previousState) => ((previousState.userrole as UserRoles).user as UserIdentity).password = text)
    }

    private handleChangeConfirmPassword(text : string)
    {
        this.setField((previousState) => ((previousState.userrole as UserRoles).user as UserIdentity).confirmPassword = text)
    }

    private handleChangeEmail(text : string)
    {
        this.setField((previousState) => ((previousState.userrole as UserRoles).user as UserIdentity).email = text);
    }

    private handleChangeRole(role : Role)
    {
        this.setField((previousState) => ((previousState.userrole as UserRoles).role as Role) = role);
    }
}