import { Service, HttpManager, Managers, Application } from "@levelapp/softfabric"

export default class UserMetadataUnitsService extends Service{
    
    httpClient: HttpManager;

    constructor(){
        super();
        this.httpClient = Application.current.resolve(Managers.HTTP_MANAGER);
        this.httpClient.handleSomethingWentWrong = (response:any) => {
            console.warn("Erreur : "+response);
        }
    }

    getAllMetaData(callBack:Function){
        this.httpClient
            .get('userMetadata')
            .then((response: any) => 
            {
                if (response)
                {
                    if (response.data)
                    {
                        callBack(response.data.data);
                    }
                }
            });
    }
}