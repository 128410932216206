 
import {ContentView} from '@levelapp/softfabric';
import SeparationLineViewState from './SeparationLineViewState';
import SeparationLineViewProps from './SeparationLineViewProps';
import SeparationLineViewStyle from './SeparationLineViewStyle';
import SeparationLineViewModel from './SeparationLineViewModel';
import {View} from 'react-native';

export default class SeparationLineView extends ContentView<SeparationLineViewProps, SeparationLineViewState, SeparationLineViewModel, SeparationLineViewStyle>
{
  /* CONSTRUCTOR */
  constructor(props:SeparationLineViewProps)
  {
    super(props);

    // Binding
    this.bind(new SeparationLineViewModel(this), new SeparationLineViewStyle(this));
  }

  /* RENDERING */
  render()
  {
    return (
      <View style={this.style.line}></View>
    );
  }
}