import { Service, Application, HttpManager } from '@levelapp/softfabric';
import ExtendedManagers from 'src/Common/Managers/ExtendedManagers';


export default class KitchenService extends Service
{
    
    httpClient = Application.current.resolve<HttpManager>(ExtendedManagers.KITCHEN_MANAGER);

    async getByDate(date: Date, storeId: number, language?: string)
    {
        const parameters = [{ key: 'date', value: date.toISOString().split('T')[0] }, { key: 'storeId', value: storeId }];
        if (language)
        {
            parameters.push({ key: 'language', value: language });
        }
        return await this.httpClient.get('basket', parameters)
    }

    async getById(id: number, language?: string)
    {
        return await this.httpClient.get(`basket/${id}` + (language ? `?language=${language}` : ''));
    }

    async updateStatus(basketId: number, lines: number[], statusId: number)
    {
        
        return await this.httpClient.put('basket/UpdateStatus', [], {
            StatusId: statusId,
            LineIds: lines,
            BasketId: basketId
        });
    }
}