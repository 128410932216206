 
import { View } from 'react-native';

import { ContentView } from '@levelapp/softfabric';
import { TranslationView } from '@levelapp/softfabric-ui';

import RemoveProductGroupPopUpViewState from './RemoveProductGroupPopUpViewState';
import RemoveProductGroupPopUpViewProps from './RemoveProductGroupPopUpViewProps';
import RemoveProductGroupPopUpViewStyle from './RemoveProductGroupPopUpViewStyle';
import RemoveProductGroupPopUpViewModel from './RemoveProductGroupPopUpViewModel';

import ButtonView from 'src/Common/Components/Button/ButtonView';
import Constants from 'src/Common/Constants/Constants';

export default class RemoveProductGroupPopUpView extends ContentView<RemoveProductGroupPopUpViewProps, RemoveProductGroupPopUpViewState, RemoveProductGroupPopUpViewModel, RemoveProductGroupPopUpViewStyle>
{
  /* CONSTRUCTOR */
  constructor(props: RemoveProductGroupPopUpViewProps) {
    super(props);

    // Binding
    this.bind(new RemoveProductGroupPopUpViewModel(this), new RemoveProductGroupPopUpViewStyle(this));
  }

  /* RENDERING */
  render() {
    const { id } = this.props;
    const { isDeleting } = this.state;
    const { style } = this;

    return (
      <View style={style.container}>
        <TranslationView style={style.title}>
          tools.products.deleteGroup
        </TranslationView>
        <TranslationView style={style.message}>
          tools.products.deleteGroup.children
        </TranslationView>
        <View style={style.buttons}>
          <ButtonView
            isLoading={isDeleting}
            handleClick={() => this.binding.onDelete(id)}
            backgroundColor={Constants.MainColor}
            style={style.addButton}
          >
            <TranslationView>
              generic.yes
            </TranslationView>
          </ButtonView>
          <ButtonView
            isLoading={false}
            handleClick={this.binding.onPopupClose}
            textColor={Constants.MainColor}
            backgroundColor="transparent"
            style={style.cancelButton}
          >
            <TranslationView>
              tools.zone.popup.cancel
            </TranslationView>
          </ButtonView>
        </View>
      </View>
    );
  }
}