 
import {ContentShellView} from '@levelapp/softfabric';
import ShellCouponsViewState from './ShellCouponsViewState';
import ShellCouponsViewProps from './ShellCouponsViewProps';
import ShellCouponsViewStyle from './ShellCouponsViewStyle';
import ShellCouponsViewModel from './ShellCouponsViewModel';
import { View } from 'react-native';

export default class ShellCouponsView extends ContentShellView<ShellCouponsViewProps, ShellCouponsViewState, ShellCouponsViewModel, ShellCouponsViewStyle>
{
  /* CONSTRUCTOR */
  constructor(props:ShellCouponsViewProps)
  {
    super(props);

    // Binding
    this.bind(new ShellCouponsViewModel(this), new ShellCouponsViewStyle(this));
  }

  /* RENDERING */
  render()
  {
    return (
      <View style={{flex : 1}}>
        {this.getSwitch()}
      </View>
    );
  }
}