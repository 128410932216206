import { ContentViewStyle } from '@levelapp/softfabric';
import { StyleProp, ViewStyle } from 'react-native';

export default class FilterStatusViewStyle extends ContentViewStyle
{
    container: StyleProp<ViewStyle> = {
        paddingVertical: 10,
        paddingHorizontal: 15,
        zIndex: 2,
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'row'
    };

    background: StyleProp<ViewStyle> = {
        position: 'absolute',
        height: '100%',
        width: '100%',
        borderRadius: 22,
        opacity: 1,
        zIndex: 1
    };

    check: StyleProp<ViewStyle> = {
        height: 21,
        width: 21,
        borderRadius: 10.5,
        zIndex: 2,
        marginRight: 8,
        justifyContent: 'center',
        alignItems: 'center'
    };
}