import { Operation } from "@levelapp/softfabric";
import ContentType from "../Common/DTO/ContentType";
import ContentTypeService from "../Proxy/ContentTypeService";

export default class ContentTypeOperations extends Operation
{
    service : ContentTypeService;

    constructor()
    {
        super();
        this.service = new ContentTypeService();
    }

    get( callback : Function, onFailure : Function)
    {
        this.service.get( callback,onFailure);
    }

     createContentType(contentType : ContentType, callback : Function, onFailure : Function)
    {
        this.service.createContentType(contentType, callback,onFailure);
    }
}