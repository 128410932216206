 
import { ContentView } from '@levelapp/softfabric';
import BasketStatusViewState from './BasketStatusViewState';
import BasketStatusViewProps from './BasketStatusViewProps';
import BasketStatusViewStyle from './BasketStatusViewStyle';
import BasketStatusViewModel from './BasketStatusViewModel';
import { TouchableWithoutFeedback, View, Text } from 'react-native';

export default class BasketStatusView extends ContentView<BasketStatusViewProps, BasketStatusViewState, BasketStatusViewModel, BasketStatusViewStyle>
{
  /* CONSTRUCTOR */
  constructor(props: BasketStatusViewProps)
  {
    super(props);

    // Binding
    this.bind(new BasketStatusViewModel(this), new BasketStatusViewStyle(this));
  }

  /* RENDERING */
  render()
  {
    return (
      <TouchableWithoutFeedback onPress={() => this.props.handleSelected()}>
        <View style={this.style.statusContainer}>
          <Text>{this.props.label.toLocaleUpperCase()}</Text>
          <View style={[this.style.statusOrdersAmount, { backgroundColor: this.props.color }]}>
            <Text>{this.props.count}</Text>
          </View>
          {this.props.showBadge && <View style={this.style.badge} />}
          {this.props.isSelected && <View style={this.style.slider} />}
        </View>
      </TouchableWithoutFeedback >
    );
  }
}