import { Application, ContentView, ContentViewModel, LanguageManager, Managers } from '@levelapp/softfabric';
import AddProductPopUpViewState from './AddProductPopUpViewState';
import AddProductPopUpViewProps from './AddProductPopUpViewProps';
import ProductsOperations from '../../../../../../../Transfer/ProductsOperations';
import { TableRow } from '@levelapp/softfabric-ui';
import Product from '../../../../../../../Common/DTO/Product';
import Helpers from '../../../../../../../Common/Helpers/Helpers';
import LanguagesOperations from '../../../../../../../Transfer/LanguagesOperations';
import Language from '../../../../../../../Common/DTO/Language';
import ToastHelper from '../../../../../../../Common/Helpers/ToastHelper';
import PaginationHelper from '../../../../../../../Common/Helpers/PaginationHelper';
import ErrorHelper from '../../../../../../../Common/Helpers/ErrorHelper';

export default class AddProductPopUpViewModel extends ContentViewModel<AddProductPopUpViewState, AddProductPopUpViewProps>
{
    productsOperations: ProductsOperations;
    searchText: string;
    interval: any;
    languageOperation: LanguagesOperations;
    _languages: Language[] = [];

    /* CONSTRUCTOR */
    constructor(view: ContentView) {
        super(view);

        this.initialState({
            tableHeaders: ['Id', 'PosId', 'Title', 'Desc', 'Img'],
            tableData: [],
            isLoading: true,
            nbPage: 0,
            currentPage: 1,
            isResearching: false,
            products: [],
            listProducts:[],
            typeProduct:true,
            isGroupProduct:true,
            listGroup:[],
            tab: "main",
            windowTab : 'addProduct',
            languages:this.props().languages,
        });


        this.languageOperation = new LanguagesOperations();
        this.productsOperations = new ProductsOperations();

        this.languageOperation.get((languages: any) => {
            this._languages = languages.data;
        })
        this.searchText = '';
        this.interval = null;
        this.handleResearching = this.handleResearching.bind(this);
        this.handleCloseResearching = this.handleCloseResearching.bind(this);
        this.onPageChange = this.onPageChange.bind(this);
        this.refresh = this.refresh.bind(this);
        this.changeTab = this.changeTab.bind(this);
        this.save = this.save.bind(this);
        this.errorManagement = this.errorManagement.bind(this);

    }

    componentDidMount() {
        this.setField({typeProduct:!this.parentNodehaveChildren()})
        if(this.props().clone){
            this.setField({isGroupProduct:true},()=>{this.setField({typeProduct:false});this.refresh()})

        }else{
            this.setField({isGroupProduct:this.isParentGroup()},()=>{this.refresh()})

        }
    }

    parentNodehaveChildren(){
        if(this.props().parentNode==null){
            return true
        }
        if(this.props().parentNode.node["children"]!= undefined && this.props().parentNode.node.children.length>0){
            return true
        }
        return false
    }

    handleResearching(searchText: string) {
        this.setField({ isLoading: true, isResearching: true });
        this.searchText = searchText;

        if (this.interval) {
            clearInterval(this.interval);
        }
        this.interval = setTimeout(() => {
            this.onPageChange(0)
        }, 1000)
    }

    onPageChange(page: number) {
        this.state().currentPage = page + 1;
        this.refresh()
    }

    refresh() {
        this.setField({ isLoading: true });
        let parameters = PaginationHelper.basicResearchPush(
            this.state().currentPage,
            20, "","",
            Application.current.resolve<LanguageManager>(Managers.LANGUAGE_MANAGER).language ,
            this.searchText,
            false,
            this.state().isResearching);
        if(!this.state().isGroupProduct)
        {
            parameters.push(PaginationHelper.pushCustomKey('isGroupProduct', false));

        }else
        {
            parameters.push(PaginationHelper.pushCustomKey('isGroupProduct', true));
        }

        this.getData(parameters);
    }

    getData(parameters: any[]) {
        let listcompositions:any[]=[]
        this.getAllProductGroupInTree(this.props().treeData,null,listcompositions)
        this.setField({listGroup:listcompositions})
        this.productsOperations.getTable(parameters, (products: any) => {
            let tableData: TableRow[] = [];
            if(this.state().isGroupProduct) products.data.objects = products.data.objects
            products.data.objects.forEach((product: Product) => {
                if (product.titleTranslations === null) {
                    product.titleTranslations = Helpers.generateTranslationTable(this._languages);
                }
                if (product.descriptionTranslations === null) {
                    product.descriptionTranslations = Helpers.generateTranslationTable(this._languages);
                }
                if (product.imageTranslations === null) {
                    product.imageTranslations = Helpers.generateUrlTable(this._languages);
                }
                if (product.thumbnailTranslations === null) {
                    product.thumbnailTranslations = Helpers.generateUrlTable(this._languages);
                }
                let title = product.titleTranslations[0].value;
                let desc = product.descriptionTranslations[0].value;
                let img = (product.imageTranslations == null || product.imageTranslations.length == 0 ) ? "" : product.imageTranslations[0].url;
                product.productAllergens = product.productAllergens !== null ? product.productAllergens : [];
                let POSId = product.posId !== null ? product.posId : "No result";
                if(this.state().isGroupProduct ){
                    //If the product is not in list
                        tableData.push({ data: [product.id, POSId, title, desc, img], isDeletable: true, isEditable: true, editCallback: () => { this.addProduct(product) }, deleteCallback: () => { this.removeProduct(product) } });
                    
                }else if(!this.state().isGroupProduct){
                    if(!this.isChild(product)&&!product.isGroup){
                        //Check if the props exists and if the product id is already out of stock, if it does, it doesn't add the products to the table
                        if(this.props().productsAlreadyOutOfStock && this.props().productsAlreadyOutOfStock?.some(p => p.id === product.id)){
                            
                        }else{
                            tableData.push({ data: [product.id, POSId, title, desc, img], isDeletable: true, isEditable: true, editCallback: () => { this.addProduct(product) }, deleteCallback: () => { this.removeProduct(product) } });
                        }
                    }
                }
            })
            this.setField({ tableData: tableData, nbPage: products.data.totalPage, isLoading: false, });
        },this.errorManagement)
        
    }

    isParentGroup(){
        if(this.props().isForOutOfStock !=undefined)
            return !this.props().isForOutOfStock;
        if(this.props().parentNode==null){
            return true
        }
        if( this.props().parentNode.node["children"]!=undefined && this.props().parentNode.node.children.length>0){
            if(this.props().parentNode.node.children[0]['group']!=undefined && this.props().parentNode.node.children[0].group==true){
                return true
            }else{
                return false
            }
        }
        return false
    }

    isChild(product:Product){
        if(this.props().parentNode!=null&&this.props().parentNode.node["children"]!=undefined){
            for (let index = 0; index <  this.props().parentNode.node.children.length; index++) {
                const element =  this.props().parentNode.node.children[index];
                if(product.id==element.parent){
                    return true;
                }
            }
        }
        return false
    }

    getAllProductGroupInTree(treeData: any,parentComposition:any, listcompositions: any) {
        for (let index = 0; index < treeData.length; index++) {
            if(parentComposition!=null ){
                const num = listcompositions.indexOf(treeData[index].composition.childProduct.id);
                if(num<0 && (treeData[index]["group"]!=undefined && treeData[index]["group"]==true)){
                    listcompositions.push(treeData[index].composition.childProduct.id);
                }
            }else{
                const num = listcompositions.indexOf(treeData[index].composition.childProduct.id);
                if(num<0){
                    listcompositions.push(treeData[index].composition.childProduct.id);
                }
            }
            if (treeData[index].children) {
                this.getAllProductGroupInTree(treeData[index].children,treeData[index].composition ,listcompositions);
            }
        }
        return [];
    }

    handleCloseResearching() {
        this.state().isResearching = false;
        this.onPageChange(0);
    }

    addProduct(product: Product) {
        var listProducts = this.state().listProducts;
        
        if(this.state().isGroupProduct){
            product.isGroup=true;
        }
        const index = this.indexOf(product)
        if (index==-1){
            listProducts.push(product);
        }
        this.setField({listProducts:listProducts });
    }

    addProducts() {
        this.props().callback(this.props().parentNode,this.state().listProducts);
        this.closePopUp();
    }

    removeProduct(product: Product) {
        var listProducts = this.state().listProducts;
        const index = this.indexOf(product);
        if (index > -1) {
            listProducts.splice(index, 1);
        }
        this.setField({ listProducts:listProducts });
    }

    indexOf(product:Product) {
        let list = this.state().listProducts;
        if(product==null){
            return -2
        }
            for (let i = 0; i < list.length; i++) {
                const element = list[i];
                if (element.id==product.id)
                    return i;
            }
        
        return -1;
    }
    changeTab(tab: any) {
        this.setField({tab :tab});
    }
    
    save(product: Product) {
        for (var i = 0; i < this._languages.length; i++) {
            if (product.imageTranslations[i].url.includes("data:image"))
                product.imageTranslations[i].imageData = product.imageTranslations[i].url.replace("data:image/png;base64,", "").replace("data:image/jpeg;base64,", "");
            else
                product.imageTranslations[i].imageData = null;

            if (product.thumbnailTranslations[i].url?.includes("data:image"))
                product.thumbnailTranslations[i].imageData = product.thumbnailTranslations[i].url?.replace("data:image/png;base64,", "").replace("data:image/jpeg;base64,", "");
            else
                product.thumbnailTranslations[i].imageData = null;
        }

        if (product.id !== undefined) {
            this.productsOperations.put(product, () => {
                this.refresh();
                this.showToast(ToastHelper.getToast("products.popup.success.updated",'success'),() => {},2500)
                this.setField({windowTab:"addProduct"})
            },this.errorManagement);
        }
        else {
            this.productsOperations.post(product, () => {
                this.refresh();
                this.showToast(ToastHelper.getToast("products.popup.success.added",'success'),() => {},2500)
                this.setField({windowTab:"addProduct"})
            },this.errorManagement);
        }
    } 
    
    errorManagement(error : any) 
    {
        //set loading : false
        this.setField({isLoading : false});
        ErrorHelper.showMessageError(error.response);
    }
}