import { ContentViewModel, ContentView } from '@levelapp/softfabric';
import StatsViewState from './StatsViewState';
import StatsViewProps from './StatsViewProps';
import Dashboard from '../../../../../../../Common/DTO/Dashboard';

export default class StatsViewModel extends ContentViewModel<StatsViewState, StatsViewProps>
{

    constructor(props: ContentView)
    {
        super(props);


        this.initialState({ isLoading: true, dashboard: (this.parameters() as any).dashboard });

        this.createDashboards = this.createDashboards.bind(this);
    }

    componentDidMount()
    { 
        this.createDashboards();

    }

    shouldComponentUpdate()
    {
        if((this.parameters() as any).dashboard[0].dashboardTypeId == this.state().dashboard[0].dashboardTypeId)
        {
            this.createDashboards();
            return true;
        }else
        {
            this.setField({dashboard : (this.parameters() as any).dashboard})
            return true;
        }
        
    }

    createDashboards()
    {
        this.state().dashboard.forEach((d: Dashboard, i: number) =>
        {
            (window as any).Cumulio.addDashboard({
                dashboardId: d.thirdPartyId,
                container: '#dashboard' + i,
                key: d.key,
                token: d.token
            });
        });
    }
}