import { Service, Application, HttpManager, Managers } from '@levelapp/softfabric';


export default class TicketService extends Service
{
    httpClient = Application.current.resolve<HttpManager>(Managers.HTTP_MANAGER);

    async get(id: number, onSuccess: Function)
    {
        this.httpClient
            .get('ticket/get/' + id, [])
            .then((response: any) =>
            {
                if (response)
                {
                    if (response.data)
                    {                        
                        onSuccess(response.data);                        
                    }
                }
                else {
                    alert("Service not avaible")
                }
            });
    }   
    
    async getList(parameters : any[], onSuccess: Function)
    {
        this.httpClient
            .get('ticket/get/', parameters)
            .then((response: any) =>
            {
                if (response)
                {
                    if (response.data)
                    {                        
                        onSuccess(response.data.data);                        
                    }
                }
                else {
                    alert("Service not avaible")
                }
            });
    }  
}