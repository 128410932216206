import { Service, Application, HttpManager, Managers } from '@levelapp/softfabric';
import Basket from '../Common/DTO/Basket';


export default class BasketService extends Service
{
    httpClient = Application.current.resolve<HttpManager>(Managers.HTTP_MANAGER);

    async get(parameters: any[], onSuccess: Function)
    {
        this.httpClient
            .get('baskets', parameters)
            .then((response: any) =>
            {
                if (response)
                {
                    if (response.data)
                    {
                        onSuccess(response.data);
                    }
                }
            });
    }

    async getNewBasketsFrom(parameters: any[])
    {
        return await this.httpClient.get('baskets/GetNewBasketsFrom', parameters);
    }

    async getByDate(date: Date, storeId: number, language?: string)
    {
        const parameters = [{ key: 'date', value: date.toISOString().split('T')[0] }, { key: 'storeId', value: storeId }];
        if (language)
        {
            parameters.push({ key: 'language', value: language });
        }
        return await this.httpClient.get('baskets/GetByDate', parameters)
    }

    async getById(id: number, language?: string)
    {
        return await this.httpClient.get(`baskets/GetById/${id}` + (language ? `?language=${language}` : ''));
    }

    async markAsPrepared(basket: Basket)
    {
        return await this.httpClient.put('baskets/MarkAsPrepared', [], basket);
    }

    async updateStatus(basket: Basket)
    {
        return await this.httpClient.put('baskets/UpdateStatus', [], basket);
    }
}