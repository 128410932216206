enum EntityType {
  CATEGORY = 1,
  ITEM = 2,
  MODIFIER_GROUP = 3,
  CROSS_SELL = 4,
  UP_SELL = 5,
  COUPON = 6,
  REWARD = 7
};

type Coupon = {
  requiredProducts: number[];
  freeProducts: number[];
  requiredEntities: Entity[];
  freeEntities: Entity[];
};


type Entity = {
  id: number | string;
  type: EntityType;
};

const fillCouponProducts = (coupon: Coupon): Coupon => {
  return {
    ...coupon,
    requiredProducts: coupon.requiredEntities?.map(entityToProduct),
    freeProducts: coupon.freeEntities?.map(entityToProduct)
  }
};

const fillCouponEntities = (coupon: Coupon): Coupon => {
  console.log(coupon);
  return {
    ...coupon,
    requiredEntities: coupon.requiredProducts?.map(productToEntity),
    freeEntities: coupon.freeProducts?.map(productToEntity)
  }
};

const productToEntity = (productId: number): Entity => ({
  id: productId,
  type: EntityType.ITEM
});

const entityToProduct = (entity: Entity): number => entity.id as number;

export default {
  fillCouponEntities,
  fillCouponProducts
};