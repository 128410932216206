 
import { View } from 'react-native';

import { ContentView } from '@levelapp/softfabric';
import { TranslationView } from '@levelapp/softfabric-ui';

import RemoveZoneViewState from './RemoveZoneViewState';
import RemoveZoneViewProps from './RemoveZoneViewProps';
import RemoveZoneViewStyle from './RemoveZoneViewStyle';
import RemoveZoneViewModel from './RemoveZoneViewModel';

import Constants from 'src/Common/Constants/Constants';
import ButtonView from 'src/Common/Components/Button/ButtonView';

export default class RemoveZoneView extends ContentView<RemoveZoneViewProps, RemoveZoneViewState, RemoveZoneViewModel, RemoveZoneViewStyle>
{
  /* CONSTRUCTOR */
  constructor(props: RemoveZoneViewProps) {
    super(props);

    // Binding
    this.bind(new RemoveZoneViewModel(this), new RemoveZoneViewStyle(this));
  }

  /* RENDERING */
  render() {
    const { id } = this.props;
    const { style } = this;

    return (
      <View style={style.container}>
        <TranslationView style={style.title}>
          tools.zones.deleteZone.warning
        </TranslationView>
        <TranslationView style={style.message}>
          tools.zones.deleteZone.children
        </TranslationView>
        <View style={style.buttons}>
          <ButtonView
            isLoading={this.state.isDeleting}
            handleClick={() => this.binding.onDelete(id)}
            backgroundColor={Constants.MainColor}
            style={style.addButton}
          >
            <TranslationView>
              generic.yes
            </TranslationView>
          </ButtonView>
          <ButtonView
            isLoading={false}
            handleClick={this.binding.onPopupClose}
            textColor={Constants.MainColor}
            backgroundColor="transparent"
            style={style.cancelButton}
          >
            <TranslationView>
              tools.zone.popup.cancel
            </TranslationView>
          </ButtonView>
        </View>
      </View>
    );
  }
}