import {ContentViewStyle} from '@levelapp/softfabric';
import {StyleProp,TextStyle,ViewStyle} from 'react-native';

export default class LogDetailsViewStyle extends ContentViewStyle
{
    page: StyleProp<ViewStyle> = {
        flex: 1
    }

    formtitle : StyleProp<TextStyle> =
    {
        fontWeight : 'bold',
        fontSize : 16,
    }
}