import {ContentViewModel,ContentView, LanguageManager, Managers, Application} from '@levelapp/softfabric';
import AllergenViewState from './AllergenViewState';
import AllergenViewProps from './AllergenViewProps';
import Allergen from '../../../../../../../../../Common/DTO/Allergen';
import TranslationValue from '../../../../../../../../../Common/DTO/TranslationValue';

export default class AllergenViewModel extends ContentViewModel<AllergenViewState, AllergenViewProps>
{
    languageManager : LanguageManager;

      /* CONSTRUCTOR */
    constructor(view: ContentView)
    {
        super(view);

        this.initialState({}); // Initialize your state here

        // Bindings
        this.handleTranslations = this.handleTranslations.bind(this);
        this.handleCheckboxAllergenChange = this.handleCheckboxAllergenChange.bind(this);
        this.showGlobalAllergensManagementPopup = this.showGlobalAllergensManagementPopup.bind(this);

        this.languageManager = Application.current.resolve<LanguageManager>(Managers.LANGUAGE_MANAGER);
    }

   

    handleCheckboxAllergenChange(value : boolean , allergen : Allergen)
    {
        this.props().handleAllergens(value , allergen);
    }

    handleTranslations(allergen : Allergen)
    {
        if(allergen.titleTranslations.length === 0) return "No Translation";

        const translation = allergen.titleTranslations.find(
            (element: TranslationValue) => 
            {
                  return ( element.language.key == this.languageManager.language);
            }
          );
        
        if(translation !== undefined) return translation.value;
          
        return allergen.titleTranslations[0].value;
        
    }

    showGlobalAllergensManagementPopup()
    {
        this.props().navigateOnThePopup();
    }

    
}