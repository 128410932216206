 
import * as XLSX from 'xlsx';

import { ContentViewModel, ContentView, Application, LanguageManager, Managers } from "@levelapp/softfabric";
import { TableRow } from "@levelapp/softfabric-ui";

import ProductCategoriesViewState from "./ProductCategoriesViewState";
import ProductCategoriesViewProps from "./ProductCategoriesViewProps";

import DeliveryCategoriesOperations from "src/Transfer/DeliveryCategoriesOperations";
import AddDeliveryCategory from "src/Common/DTO/AddDeliveryCategory";

import ProductCategoriesOperations from "../../../../../../../Transfer/ProductCategoriesOperations";
import LanguagesOperations from "../../../../../../../Transfer/LanguagesOperations";
import ProductCategoriesPopUpView from "../../popup/ProductCategoriesPopUpView";
import Helpers from "../../../../../../../Common/Helpers/Helpers";
import ConfirmPopUpView from "../../../../../../../Common/Components/confirmpopup/ConfirmPopUpView";
import ToastHelper from "../../../../../../../Common/Helpers/ToastHelper";
import Language from "../../../../../../../Common/DTO/Language";
import PaginationHelper from "../../../../../../../Common/Helpers/PaginationHelper";
import ErrorHelper from "../../../../../../../Common/Helpers/ErrorHelper";


export default class ProductCategoriesViewModel extends ContentViewModel<ProductCategoriesViewState, ProductCategoriesViewProps>
{
  operation: ProductCategoriesOperations;
  deliveryOperation: DeliveryCategoriesOperations;
  languagesOperation: LanguagesOperations;
  _languages: Language[] = [];
  _languagesString: string[] = [];
  interval: any;
  searchText: string;
  isSorting: boolean;
  indexHeaderSort: number;
  sortOrder: string;
  sortKey: string[];


  constructor(props: ContentView) {
    super(props);

    this.interval = null;
    this.searchText = '';
    this.isSorting = false;
    this.indexHeaderSort = -1;
    this.sortOrder = 'asc';
    this.sortKey = ['Title', 'Image', 'OrderIndex'];


    /* Initial State */

    this.initialState({ tableData: [], tableHeaders: ['Title', 'Image', 'OrderIndex'], isLoading: true, nbPage: 0, currentPage: 1, isResearching: false });
    this.operation = new ProductCategoriesOperations();
    this.deliveryOperation = new DeliveryCategoriesOperations();
    this.languagesOperation = new LanguagesOperations();

    this.languagesOperation.get((languages: any) => {
      this._languages = languages.data;
    });

    /* BINDINGS */
    this.editField = this.editField.bind(this);
    this.addField = this.addField.bind(this);
    this.deleteField = this.deleteField.bind(this);
    this.refresh = this.refresh.bind(this);
    this.onPageChange = this.onPageChange.bind(this);
    this.onSortChange = this.onSortChange.bind(this);
    this.handleResearching = this.handleResearching.bind(this);
    this.errorManagement = this.errorManagement.bind(this);
    this.handleCloseResearching = this.handleCloseResearching.bind(this);
    this.export = this.export.bind(this);
  }

  /* BINDINGS */

  componentDidMount() {
    this.refresh();
  }


  refresh() {
    this.setField({ isLoading: true });
    let parameters = PaginationHelper.basicResearchPush(
      this.state().currentPage,
      20,
      this.sortKey[this.indexHeaderSort],
      this.sortOrder,
      Application.current.resolve<LanguageManager>(Managers.LANGUAGE_MANAGER).language,
      this.searchText,
      this.isSorting,
      this.state().isResearching);
    this.getData(parameters);
  }

  getData(queryParameters: any[]) {
    this.operation.get(queryParameters, (productcategories: any) => {
      let tableData: TableRow[] = [];
      productcategories.data.objects.forEach((productcategory: any) => {
        if (productcategory.titleTranslations == null || productcategory.titleTranslations.length == 0) {
          productcategory.titleTranslations = Helpers.generateTranslationTable(this._languages);
        }
        if (productcategory.imageTranslation == null || productcategory.imageTranslation.length == 0) {
          productcategory.imageTranslation = Helpers.generateUrlTable(this._languages);
        }
        if (productcategory.thumbnailTranslation == null || productcategory.thumbnailTranslation.length == 0) {
          productcategory.thumbnailTranslation = Helpers.generateUrlTable(this._languages);
        }

        let title = productcategory.titleTranslations[0].value;
        let img = productcategory.imageTranslation[0].url;
        tableData.push({ data: [title, img, productcategory.orderIndex], isDeletable: true, isEditable: true, editCallback: () => { this.editField(productcategory) }, deleteCallback: () => { this.deleteField(productcategory) } });
      });
      this.setField({ tableData: tableData, productcategories: productcategories.data.object, nbPage: productcategories.data.totalPage, isLoading: false });
    }, this.errorManagement);
  }

  addField() {
    this.showPopUp(
      <ProductCategoriesPopUpView
        productcategory={{
          titleTranslations: Helpers.generateTranslationTable(this._languages),
          appProductCategoryExterns: [],
          imageTranslation: Helpers.generateUrlTable(this._languages),
          thumbnailTranslation: Helpers.generateUrlTable(this._languages),

        }
        }
        languages={this._languages}
        refreshParent={this.refresh} />
    );
  }

  editField(productcategory: any) {
    productcategory.imageTranslation = Helpers.generateUrlTableEdit(productcategory.imageTranslation, this._languages);
    productcategory.thumbnailTranslation = Helpers.generateUrlTableEdit(productcategory.thumbnailTranslation, this._languages);

    let productcategoryCopy = Helpers.clone(productcategory);
    this.showPopUp(
      <ProductCategoriesPopUpView productcategory={productcategoryCopy} refreshParent={this.refresh} languages={this._languages} />
    );
  }

  deleteField(productcategory: any) {
    this.showPopUp(
      <ConfirmPopUpView callBack={() => {
        this.operation.delete(productcategory, () => {
          this.showToast(ToastHelper.getToast("products.categories.popup.success.deleted", "success"), () => { }, 2500);
          this.refresh();
        }, this.errorManagement);
      }} />
    )
  }



  onPageChange(page: number) {
    this.state().currentPage = page + 1;
    this.refresh()
  }

  onSortChange(indexHeaderSort: number, sortOrder: string) {
    if (indexHeaderSort == -1) {
      this.isSorting = false;
    } else {
      this.indexHeaderSort = indexHeaderSort;
      this.sortOrder = sortOrder;
      this.isSorting = true;
    }
    this.refresh();
  }

  handleResearching(searchText: string) {
    this.setField({ isLoading: true, isResearching: true });
    this.searchText = searchText;

    if (this.interval) {
      clearInterval(this.interval);
    }
    this.interval = setTimeout(() => {
      this.onPageChange(0)
    }, 1000)
  }

  handleCloseResearching() {
    this.state().isResearching = false;
    this.onPageChange(0);
  }

  errorManagement(error: any) {
    //set loading : false
    this.setField({ isLoading: false });
    ErrorHelper.showMessageError(error.response);
  }

  handleFile(file: File) {
    this.handleReading(file);
    this.setField({ isLoading: true });
  }

  handleReading(file: File) {
    if (file != undefined) {
      const fileReader = new FileReader();
      fileReader.onloadend = () => {
        let binary = "";
        const bytes = new Uint8Array(fileReader.result as ArrayBuffer);
        const length = bytes.byteLength;
        for (let i = 0; i < length; i++) {
          binary += String.fromCharCode(bytes[i]);
        }
        this.TransformExcelToData(binary);
      }
      fileReader.readAsArrayBuffer(file);
    }
  }

  async export() {
    try {
      const deliveryCategories = await this.deliveryOperation.get();

      if (deliveryCategories) {
        const headers = ["Cat_FR", "Cat_EN", "PLU", "Restaurant"];

        const mappedDeliveryCategories = deliveryCategories.map((deliveryCat) => ({
          Cat_FR: deliveryCat.cat_FR,
          Cat_EN: deliveryCat.cat_EN,
          PLU: deliveryCat.plu,
          Restaurant: deliveryCat.restaurant,
        }))

        const wb = XLSX.utils.book_new();
        const o = XLSX.utils.json_to_sheet(mappedDeliveryCategories, { header: headers, });

        XLSX.utils.book_append_sheet(wb, o, "DeliveryCategories");
        XLSX.writeFile(wb, "DeliveryCategories.xlsx");
      }
      this.showToast(ToastHelper.getToast("generic.success", "success"), () => { }, 2500);
    } catch (error) {
      this.showToast(ToastHelper.getToast("product.category.export", "error"), () => { }, 2500);
    }
  }


  async TransformExcelToData(data: any) {
    const headers: string[] = []
    const workbook: XLSX.WorkBook = XLSX.read(data, { type: "binary" });
    const range = XLSX.utils.decode_range(workbook.Sheets[workbook.SheetNames[0]]['!ref'] as string);

    const R = range.s.r;
    for (let C = range.s.c; C <= range.e.c; C++) {

      const cell = workbook.Sheets[workbook.SheetNames[0]][XLSX.utils.encode_cell({ c: C, r: R })]
      let hdr = "UNKNOWN";
      if (cell && cell.t) headers.push(hdr = XLSX.utils.format_cell(cell));

      if (hdr == "UNKNOWN") {
        break;
      }
    }

    const json = XLSX.utils.sheet_to_json(workbook.Sheets[workbook.SheetNames[0]]);

    try {
      await this.deliveryOperation.post(json as AddDeliveryCategory[]);

      this.showToast(ToastHelper.getToast("generic.success", "success"), () => { }, 2500);
      this.setField({ isLoading: false })
    } catch {
      this.showToast(ToastHelper.getToast("product.category.import", "error"), () => { }, 2500);
    }
  }
}
