import { Operation } from "@levelapp/softfabric";
import OpeningHoursService from "../Proxy/OpeningHoursService";

export default class OpeningHoursOperation extends Operation
{
    service: OpeningHoursService;

    constructor()
    {
        super();
        this.service = new OpeningHoursService();
    }

    async get(storeId: number)
    {
        return await this.service.get(storeId);
    }
}