import { Operation } from "@levelapp/softfabric";
import BrandService from "../Proxy/BrandService";

export default class BrandOperations extends Operation {
  service: BrandService;

  constructor() {
    super();
    this.service = new BrandService();
  }

  get() {
    return this.service.get();
  }
}