 
import { ContentShellView } from '@levelapp/softfabric';
import ShellKitchenAppViewState from './ShellKitchenAppViewState';
import ShellKitchenAppViewProps from './ShellKitchenAppViewProps';
import ShellKitchenAppViewStyle from './ShellKitchenAppViewStyle';
import ShellKitchenAppViewModel from './ShellKitchenAppViewModel';
import { View } from 'react-native';

export default class ShellKitchenAppView extends ContentShellView<ShellKitchenAppViewProps, ShellKitchenAppViewState, ShellKitchenAppViewModel, ShellKitchenAppViewStyle>
{
  /* CONSTRUCTOR */
  constructor(props: ShellKitchenAppViewProps)
  {
    super(props);

    // Binding
    this.bind(new ShellKitchenAppViewModel(this), new ShellKitchenAppViewStyle(this));
  }

  /* RENDERING */
  render()
  {
    return (
      <View style={[{ height: 'calc( 100vh - 50px)' }, this.style.disableSelect]}>
        {this.getSwitch()}
      </View>
    );
  }
}