 
import { ContentView, Application, Managers, NavigationManager, LanguageManager } from '@levelapp/softfabric';
import MenuViewState from './MenuViewState';
import MenuViewProps from './MenuViewProps';
import MenuViewStyle from './MenuViewStyle';
import MenuViewModel from './MenuViewModel';

import { View, ScrollView, Animated, TouchableWithoutFeedback, Image, Text } from 'react-native';
import Constants from '../../../../../Common/Constants/Constants';
import EnumModules from '../../../../../Common/Constants/EnumModules';
import MenuItemView from './components/menuitem/MenuItemView';
import PermissionHelper from '../../../../../Common/Helpers/PermissionHelper';

export default class MenuView extends ContentView<MenuViewProps, MenuViewState, MenuViewModel, MenuViewStyle>
{
  NavigationManager: NavigationManager = Application.current.resolve<NavigationManager>(Managers.NAVIGATION_MANAGER);
  LanguageManager: LanguageManager = Application.current.resolve<LanguageManager>(Managers.LANGUAGE_MANAGER);

  /* CONSTRUCTOR */
  constructor(props: MenuViewProps) {
    super(props);

    // Binding
    this.bind(new MenuViewModel(this), new MenuViewStyle(this));
  }

  /* RENDERING */
  render() {
    const totalWidth = this.binding.animated.interpolate(
      {
        inputRange: [0, 1],
        outputRange: [40, 300]
      })

    const width = this.binding.animated.interpolate(
      {

        inputRange: [0, 1],
        outputRange: [0, 260]
      })

    const imagewidth = this.binding.animated.interpolate(
      {

        inputRange: [0, 1],
        outputRange: [0, 160]
      })

    this.binding.manageAnimation(this.props.animation ? 1 : 0);
    const loyalty = this.binding.renderMenuLoyalty();
    const clickcollect = this.binding.renderMenuClickCollect();
    const stats = this.binding.renderStats();
    const admin = this.binding.renderMenuAdmin();
    const appContent = this.binding.renderMenuAppContent();
    const tools = this.binding.renderToolsMenu();

    return (
      <Animated.View style={{ flexDirection: 'row', backgroundColor: '#eee', width: totalWidth }}>
        <Animated.View style={[this.style.menu, { width: width }]}>
          <View style={this.style.logoContainer}>
            <Animated.Image
              style={{ width: imagewidth, height: 100, flex: 1, resizeMode: 'contain', display: 'flex' }}
              source={{ uri: Constants.LogoUrl }}
            />
          </View>
          <ScrollView contentContainerStyle={this.style.linksContainer}>

            <MenuItemView
              underMenu={false}
              isDirect={true}
              title={"overview.title"}
              display={PermissionHelper.shouldShowOverview()}
              selected={this.NavigationManager.route.indexOf('/overview') !== -1}
              onPress={() => this.binding.navigateToModule(EnumModules.OVERVIEW)} />


            <MenuItemView
              underMenu={false}
              isDirect={false}
              title={"loyalty.title"}
              display={PermissionHelper.shouldShowRewards() || PermissionHelper.shouldShowCoupons() ||
                PermissionHelper.shouldShowEverydayValues() ||
                PermissionHelper.shouldShowBoosters() || PermissionHelper.shouldShowSavingCards() ||
                PermissionHelper.shouldShowMessages() || PermissionHelper.shouldShowAutomatedMessages() || PermissionHelper.shouldShowLoyaltyRules() || PermissionHelper.shouldShowVouchers()}
              selected={
                this.NavigationManager.route.indexOf('/rewards') !== -1
                ||
                this.NavigationManager.route.indexOf('/coupons') !== -1
                ||
                this.NavigationManager.route.indexOf('/everyday') !== -1
                ||
                this.NavigationManager.route.indexOf('/boosters') !== -1
                ||
                this.NavigationManager.route.indexOf('/loyaltyrules') !== -1
                ||
                this.NavigationManager.route.indexOf('/savingcards') !== -1
                ||
                this.NavigationManager.route.indexOf('/badges') !== -1
                ||
                this.NavigationManager.route.indexOf('/automatedmessages') !== -1
                ||
                this.NavigationManager.route.indexOf('/messages') !== -1
                ||
                this.NavigationManager.route.indexOf('/earningpoints') !== -1
                ||
                this.NavigationManager.route.indexOf('/vouchers') !== -1
              }
              onPress={() => { }}>

              {loyalty}


            </MenuItemView>

            <MenuItemView
              underMenu={false}
              isDirect={false}
              title={"clickcollect.title"}
              display={PermissionHelper.shouldShowProducts() || PermissionHelper.shouldShowProductCategories() ||
                PermissionHelper.shouldShowOrders() || PermissionHelper.shouldShowKitchenApp() || PermissionHelper.shouldShowMenuConfiguration() ||
                PermissionHelper.shouldShowPromoCodes() || PermissionHelper.shouldShowStoresSummary() || PermissionHelper.shouldShowCrossSellconfigurator() ||
                PermissionHelper.shouldShowOutOfStock() || PermissionHelper.shouldShowBanners()
              }
              selected={
                this.NavigationManager.route.indexOf('/products') !== -1
                ||
                this.NavigationManager.route.indexOf('/productcategories') !== -1
                ||
                this.NavigationManager.route.indexOf('/orders') !== -1
                ||
                this.NavigationManager.route.indexOf('/kitchenapp') !== -1
                ||
                this.NavigationManager.route.indexOf('/menuconfigurator') !== -1
                ||
                this.NavigationManager.route.indexOf('/promocodes') !== -1
                ||
                this.NavigationManager.route.indexOf('/summarystores') !== -1
                ||
                this.NavigationManager.route.indexOf('/crosssellconfigurator') !== -1
                ||
                this.NavigationManager.route.indexOf('/outofstock') !== -1}
              onPress={() => { }}>

              {clickcollect}

            </MenuItemView>

            <MenuItemView
              underMenu={false}
              isDirect={false}
              title={"content.title"}
              display={PermissionHelper.shouldShowNews() || PermissionHelper.shouldShowTranslations() ||
                PermissionHelper.shouldShowLegalTexts() || PermissionHelper.shouldShowFaq() || PermissionHelper.shouldShowBanners()}
              selected={
                this.NavigationManager.route.indexOf('/news') !== -1
                ||
                this.NavigationManager.route.indexOf('/translation') !== -1
                ||
                this.NavigationManager.route.indexOf('/legaltexts') !== -1
                ||
                this.NavigationManager.route.indexOf('/faq') !== -1
                ||
                this.NavigationManager.route.indexOf('/banners') !== -1
              }
              onPress={() => { }}>

              {appContent}


            </MenuItemView>

            <MenuItemView
              underMenu={false}
              isDirect={false}
              title="tools.title"
              display={PermissionHelper.shouldShowZoneTool() || PermissionHelper.shouldShowProductTool()}
              selected={
                this.NavigationManager.route.includes('/tools/zones')
                ||
                this.NavigationManager.route.includes('/tools/tproducts')
              }
              onPress={() => { }}>
              {tools}
            </MenuItemView>

            {!this.state.isLoadingDashboard && <MenuItemView
              underMenu={false}
              isDirect={false}
              title={"stats.title"}
              display={PermissionHelper.shouldShowStats()}
              selected={this.NavigationManager.route.indexOf('/stats') !== -1}
              onPress={() => this.binding.navigateToModule(EnumModules.STATS)}>

              {stats}
            </MenuItemView>}

            <MenuItemView
              underMenu={false}
              isDirect={true}
              title={"users.title"}
              display={PermissionHelper.shouldShowUser()}
              selected={this.NavigationManager.route.indexOf('/users') !== -1}
              onPress={() => this.binding.navigateToModule(EnumModules.USERS)} />
            
            <MenuItemView
              underMenu={false}
              isDirect={true}
              title={"membership.title"}
              display={false} //TODO when admin done
              selected={this.NavigationManager.route.indexOf('/membership') !== -1}
              onPress={() => this.binding.navigateToModule(EnumModules.MEMBERSHIP)} />

            <MenuItemView
              underMenu={false}
              isDirect={true}
              title={"tickets.title"}
              display={PermissionHelper.shouldShowTickets()}
              selected={this.NavigationManager.route.indexOf('/tickets') !== -1}
              onPress={() => this.binding.navigateToModule(EnumModules.TICKETS)} />

            <MenuItemView
              underMenu={false}
              isDirect={true}
              title={"stores.title"}
              display={PermissionHelper.shouldShowStores()}
              selected={this.NavigationManager.route.indexOf('/stores') !== -1}
              onPress={() => this.binding.navigateToModule(EnumModules.STORES)} />

            <MenuItemView
              underMenu={false}
              isDirect={true}
              title={"language.title"}
              display={PermissionHelper.shouldShowLanguage()}
              selected={this.NavigationManager.route.indexOf('/language') !== -1}
              onPress={() => this.binding.navigateToModule(EnumModules.LANGUAGE)} />

            <MenuItemView
              underMenu={false}
              isDirect={true}
              title={"countries.title"}
              display={PermissionHelper.shouldShowCountries()}
              selected={this.NavigationManager.route.indexOf('/countries') !== -1}
              onPress={() => this.binding.navigateToModule(EnumModules.COUNTRIES)} />

            <MenuItemView
              underMenu={false}
              isDirect={true}
              title={"possimulator.title"}
              display={PermissionHelper.shouldShowPosSimulator()}
              selected={this.NavigationManager.route.indexOf('/possimulator') !== -1}
              onPress={() => this.binding.navigateToModule(EnumModules.POS_SIMULATOR)} />

            <MenuItemView
              underMenu={false}
              isDirect={true}
              title={"healthstatus.title"}
              display={PermissionHelper.shouldShowHealthStatus()}
              selected={this.NavigationManager.route.indexOf('/healthstatus') !== -1}
              onPress={() => this.binding.navigateToModule(EnumModules.HEALTHSTATUS)} />

            <MenuItemView
              underMenu={false}
              isDirect={true}
              title={"adminusers.title"}
              display={PermissionHelper.shouldShowAdminUsers()}
              selected={this.NavigationManager.route.indexOf('/adminusers') !== -1}
              onPress={() => this.binding.navigateToModule(EnumModules.ADMINUSERS)} />

              

            <MenuItemView
              underMenu={false}
              isDirect={false}
              title={"admin.title"}
              display={PermissionHelper.shouldShowRolePermissions() ||
                PermissionHelper.shouldShowContentTypeKeyConfig()}
              selected={
                this.NavigationManager.route.indexOf('/rolepermissions') !== -1
                ||
                this.NavigationManager.route.indexOf('/contenttypekeyconfig') !== -1

              }
              onPress={() => { }}>

              {admin}


            </MenuItemView>




            {/* HACK: To hide 'undermenu'  */}
            <View style={this.style.hack} />


          </ScrollView>
          <View style={{ width: '100%', alignItems: 'center', paddingBottom: 7 }}>
            {Constants.Version != '' && <Text style={{ color: '#a9afbb', fontSize: 12 }}>Version : {Constants.Version}</Text>}
          </View>
        </Animated.View >

        <TouchableWithoutFeedback onPress={() => this.props.handleAnimation()}>
          <View style={{ width: 40, height: 40 }} >
            <View style={{ backgroundColor: 'white', justifyContent: 'center', alignItems: 'center', marginTop: 15, width: 25, height: 30, borderTopRightRadius: 5, borderBottomRightRadius: 5 }}>
              <Image resizeMode='contain' style={{ width: 12.5, height: 12.5, }} source={this.binding.getImage()} />
            </View>
          </View>

        </TouchableWithoutFeedback>


      </Animated.View>

    );
  }
}