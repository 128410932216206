 
import { TextInput, View } from 'react-native';
import Select from 'react-dropdown-select';

import ButtonView from 'src/Common/Components/Button/ButtonView';
import Constants from 'src/Common/Constants/Constants';

import { ContentView } from '@levelapp/softfabric';
import { TranslationView } from '@levelapp/softfabric-ui';

import UpsertProductGroupViewState from './UpsertProductGroupViewState';
import UpsertProductGroupViewProps from './UpsertProductGroupViewProps';
import UpsertProductGroupViewStyle from './UpsertProductGroupViewStyle';
import UpsertProductGroupViewModel from './UpsertProductGroupViewModel';
import DropdownItemView from '../dropdownitem/DropdownItemView';

import './upsertProductGroup.css';
import ToggleInsertTypeView from '../toggleinserttype/ToggleInsertTypeView';
import CheckboxView from '../checkbox/CheckboxView';

export default class UpsertProductGroupView extends ContentView<UpsertProductGroupViewProps, UpsertProductGroupViewState, UpsertProductGroupViewModel, UpsertProductGroupViewStyle>
{
  /* CONSTRUCTOR */
  constructor(props: UpsertProductGroupViewProps) {
    super(props);

    // Binding
    this.bind(new UpsertProductGroupViewModel(this), new UpsertProductGroupViewStyle(this));
  }

  /* RENDERING */
  render() {
    const { productGroup } = this.props;
    const {
      isSaving,
      selectedTypeItem,
      groupName,
      showError,
      selectedProducts,
      products,
      search,
      productsAreLoading,
      shouldAddAnotherProduct,
    } = this.state;
    const { style } = this;

    const isSingleProduct = this.state.selectedTypeItem === 'single';

    return (
      <View style={style.container}>
        <View style={style.content}>
          <TranslationView style={style.title}>
            {productGroup ? 'tools.zone.editProductGroupTitle' : 'tools.zone.addProductGroupTitle'}
          </TranslationView>
          <View style={style.underTitle}>

            {!productGroup && (
              <>
                <TranslationView style={style.inputLabel}>
                  tools.zone.add.type
                </TranslationView>
                <ToggleInsertTypeView
                  selectedItem={selectedTypeItem}
                  onItemPress={this.binding.onItemPress}
                />
              </>
            )}

            {selectedTypeItem === 'group' && (
              <>
                <TranslationView style={style.inputLabel}>
                  tools.zone.add.inputLabel
                </TranslationView>
                <TextInput
                  value={groupName}
                  onChangeText={this.binding.handleGroupNameChanged}
                  style={[style.input, { borderColor: '#DCCDCD' }]}
                />
              </>
            )}
            <TranslationView
              style={{
                color: 'red',
                visibility: showError ? 'visible' : 'hidden'
              }}
            >
              tools.popup.error
            </TranslationView>

            <TranslationView style={style.inputLabel}>
              {productGroup
                ? 'tools.productGroup.edit.selectedProduct'
                : 'tools.productGroup.add.selectedProduct'
              }
            </TranslationView>

            <Select
              style={{ minHeight: 44, maxHeight: 152 }}
              values={selectedProducts}
              multi={!isSingleProduct}
              options={products
                .map((p) => ({
                  id: p.id,
                  name: this.binding.getProductTranslation(p),
                  itemGroupId: p.productGroupId,
                  posId: p.posId,
                }))
                .filter((a) => `${a.name.toLocaleLowerCase()} ${a.posId}`.includes(search.toLocaleLowerCase()))
                .sort((a, b) => a.name.localeCompare(b.name))}
              valueField="id"
              labelField="name"
              searchable
              addPlaceholder="Search..."
              searchBy="name"
              clearOnSelect={false}
              onChange={this.binding.updateProducts}
              loading={productsAreLoading}
              disabled={productsAreLoading}
              color={Constants.MainColor}
              itemRenderer={({ item, methods }) => (
                <DropdownItemView
                  item={item}
                  methods={methods}
                  itemGroupId={this.props.productGroup?.id}
                  clearSearch={this.binding.clearSearch}
                  isSingleElement={isSingleProduct}
                />
              )}
            />
          </View>
        </View>

        <View style={style.buttons}>
          {isSingleProduct && (
            <View style={style.addAnother}>
              <CheckboxView selected={shouldAddAnotherProduct} onPress={this.binding.onAddAnotherPress} />
              <TranslationView style={{ marginLeft: 10 }}>
                tools.product.add.addAnotherProduct
              </TranslationView>
            </View>
          )}
          <ButtonView
            isLoading={isSaving}
            handleClick={() => this.binding.onProductGroupSaved(productGroup?.id)}
            backgroundColor={Constants.MainColor}
            style={[style.addButton, isSingleProduct && { marginLeft: 15 }]}
          >
            <TranslationView>
              {productGroup ? 'generic.button.save' : 'tools.zone.popup.add'}
            </TranslationView>
          </ButtonView>
          <ButtonView
            isLoading={false}
            handleClick={this.binding.onPopupClose}
            textColor={Constants.MainColor}
            backgroundColor="transparent"
            style={style.cancelButton}
          >
            <TranslationView>
              tools.zone.popup.cancel
            </TranslationView>
          </ButtonView>
        </View>
      </View>
    );
  }
}
