import { ContentViewStyle } from '@levelapp/softfabric';
import { StyleProp, ViewStyle } from 'react-native';

export default class ResearchInputViewStyle extends ContentViewStyle {
    input: StyleProp<any> =
        {
            flexDirection: 'row',
            marginRight: 5,
            marginBottom: 48,
            height: 46,
            width: 223,
            borderRadius: 23,
            backgroundColor: 'white',
            boxShadow: '0 6px 16px 0 rgba(0,0,0,0.13)'
        }

    inputView: StyleProp<ViewStyle> =
        {
            flex: 1,
            flexDirection: 'row',
            alignSelf: 'stretch',

        };

    button: StyleProp<ViewStyle> =
        {
            alignSelf: 'center',
            alignItems: 'center',
            marginRight: 14.24,
            borderRadius: 21.12 / 2,
            backgroundColor: '#ebebeb'
        };

    containerInput: StyleProp<ViewStyle> =
        {
            flexDirection: 'row',
            flex: 1,
            borderRadius: 23,
        }
}