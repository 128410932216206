 
import { ContentView } from '@levelapp/softfabric';
import BasketHistoryPopupViewState from './BasketHistoryPopupViewState';
import BasketHistoryPopupViewProps from './BasketHistoryPopupViewProps';
import BasketHistoryPopupViewStyle from './BasketHistoryPopupViewStyle';
import BasketHistoryPopupViewModel from './BasketHistoryPopupViewModel';
import { View, Text } from 'react-native';
import { LottieView } from '@levelapp/softfabric-ui';
import { utc } from 'moment';

export default class BasketHistoryPopupView extends ContentView<BasketHistoryPopupViewProps, BasketHistoryPopupViewState, BasketHistoryPopupViewModel, BasketHistoryPopupViewStyle>
{
  /* CONSTRUCTOR */
  constructor(props: BasketHistoryPopupViewProps)
  {
    super(props);

    // Binding
    this.bind(new BasketHistoryPopupViewModel(this), new BasketHistoryPopupViewStyle(this));
  }

  /* RENDERING */
  render()
  {
    return (
      <View style={this.style.container}>
        <View style={this.style.header}>
          <Text style={this.style.title}>History order #{this.props.basket.externalOrderNumber}</Text>
        </View>

        {this.state.isLoading ? (
          <View style={{ justifyContent: 'center', alignItems: 'center', paddingVertical: 30 }}>
            <LottieView height={150} width={150} source={require('../../assets/loading.json')} />
          </View>
        ) : (
            <View style={this.style.historyContainer}>
              {this.state.histories.sort((a, b) => new Date(b.date as any as string).getTime() - new Date(a.date as any as string).getTime()).map((history, i) =>
              {
                const status = this.props.statuses.find(x => x.id == history.statusId);
                const label = status?.label.translationValues.find(x => x.language.key == this.binding.language)?.value ?? '';
                const time = utc(history.date).local().format("HH:mm - DD/MM/YYYY");

                return (
                  <View style={this.style.history} key={i}>
                    <View style={this.style.leftHistory}>
                      <View style={this.style.bubble} />
                      <View style={this.style.line} />
                    </View>

                    <View style={this.style.middleHistory}>
                      <Text>{time}</Text>
                      <View style={[this.style.status, { backgroundColor: status?.labelColor }]}>
                        <Text>{label}</Text>
                      </View>
                    </View>

                    <View style={this.style.rightHistory}>
                      <Text style={{ fontSize: 12, fontStyle: 'italic' }}>{this.binding.translate("kitchenapp.baskethistorypopup.movedby")} {history.username}</Text>
                    </View>
                  </View>
                )
              })}
            </View>
          )
        }
      </View>
    );
  }
}