 
import {ContentShellView} from '@levelapp/softfabric';
import ShellPromoCodesViewState from './ShellPromoCodesViewState';
import ShellPromoCodesViewProps from './ShellPromoCodesViewProps';
import ShellPromoCodesViewStyle from './ShellPromoCodesViewStyle';
import ShellPromoCodesViewModel from './ShellPromoCodesViewModel';
import {View} from 'react-native';

export default class ShellOrdersView extends ContentShellView<ShellPromoCodesViewState, ShellPromoCodesViewProps, ShellPromoCodesViewModel, ShellPromoCodesViewStyle>
{
  /* CONSTRUCTOR */
  constructor(props:ShellPromoCodesViewState)
  {
    super(props);

    // Binding
    this.bind(new ShellPromoCodesViewModel(this), new ShellPromoCodesViewStyle(this));
  }

  /* RENDERING */
  render()
  {
    return (
      <View style={{flex : 1}}>
        {this.getSwitch()}
      </View>
    );
  }
}