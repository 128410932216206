import { ContentViewModel, ContentView } from '@levelapp/softfabric';
import CustomDatePickerViewState from './CustomDatePickerViewState';
import CustomDatePickerViewProps from './CustomDatePickerViewProps';

export default class CustomDatePickerViewModel extends ContentViewModel<CustomDatePickerViewState, CustomDatePickerViewProps>
{
    /* CONSTRUCTOR */
    constructor(view: ContentView)
    {
        super(view);

        this.initialState({}); // Initialize your state here

        // Bindings

    }
}