 
import { ContentView } from '@levelapp/softfabric';
import ExtendedTranslationViewState from './ExtendedTranslationViewState';
import ExtendedTranslationViewProps from './ExtendedTranslationViewProps';
import ExtendedTranslationViewStyle from './ExtendedTranslationViewStyle';
import ExtendedTranslationViewModel from './ExtendedTranslationViewModel';
import { TranslationView } from '@levelapp/softfabric-ui';
import { Image, Text, View } from 'react-native';
import HoverableView from '../hoverable/HoverableView';

export default class ExtendedTranslationView extends ContentView<ExtendedTranslationViewProps, ExtendedTranslationViewState, ExtendedTranslationViewModel, ExtendedTranslationViewStyle>
{
  /* CONSTRUCTOR */
  constructor(props: ExtendedTranslationViewProps)
  {
    super(props);

    // Binding
    this.bind(new ExtendedTranslationViewModel(this), new ExtendedTranslationViewStyle(this));
  }

  /* RENDERING */
  render()
  {
    const { inputTitle } = this.style;

    return (
      <View style={{ flexDirection: 'row' , zIndex : 10 }}>
        <Text style={inputTitle} onLayout={this.binding.setTitleWidth}>
          <TranslationView style={this.props.styleText} upperCase={this.props.uppercase}>{this.props.children}</TranslationView>
          {this.props.afterTitle}
        </Text>
        {this.props.infoText != undefined && (
          <HoverableView onHoverIn={() => this.setState({ infoPopupVisible: true })} onHoverOut={() => this.setState({ infoPopupVisible: false })}>
            <Image source={require('../../assets/info.svg').default} style={{ height: 18, width: 18, marginLeft: 10 }} />
          </HoverableView>
        )}
        {this.state.infoPopupVisible && (
          <View style={[this.style.popup, { left: this.state.titleWidth + 28 }]}>
            <Text>{this.props.infoText}</Text>
          </View>
        )}
      </View>
    );
  }
}