 
import {ContentShellView} from '@levelapp/softfabric';
import ShellLoyaltyRulesViewState from './ShellLoyaltyRulesViewState';
import ShellLoyaltyRulesViewProps from './ShellLoyaltyRulesViewProps';
import ShellLoyaltyRulesViewStyle from './ShellLoyaltyRulesViewStyle';
import ShellLoyaltyRulesViewModel from './ShellLoyaltyRulesViewModel';
import { View } from 'react-native';

export default class ShellLoyaltyRulesView extends ContentShellView<ShellLoyaltyRulesViewProps, ShellLoyaltyRulesViewState, ShellLoyaltyRulesViewModel, ShellLoyaltyRulesViewStyle>
{
  /* CONSTRUCTOR */
  constructor(props:ShellLoyaltyRulesViewProps)
  {
    super(props);

    // Binding
    this.bind(new ShellLoyaltyRulesViewModel(this), new ShellLoyaltyRulesViewStyle(this));
  }

  /* RENDERING */
  render()
  {
    return (
      <View style={{flex : 1}}>
        {this.getSwitch()}
      </View>
    );
  }
}