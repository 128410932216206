import {ContentView, ContentViewModel} from '@levelapp/softfabric';
import TicketDetailsPopUpViewState from './TicketDetailsPopUpViewState';
import TicketDetailsPopUpViewProps from './TicketDetailsPopUpViewProps';
import { TableRow } from '@levelapp/softfabric-ui';


export default class TicketDetailsPopUpViewModel extends ContentViewModel<TicketDetailsPopUpViewState, TicketDetailsPopUpViewProps>
{
    constructor(props: ContentView) {
        super(props);

        this.initialState({
            ticket: this.props().ticket,   
            tableHeaders: ["Product", "Quantity", "Price", "ParentId"], 
            tableData: []       
        });


         /* BINDINGS */
         this.close = this.close.bind(this);
    }

    /* COMPONENT EVENTS */
    componentDidMount() {
        let tableData: TableRow[] = [];
        this.props().ticket.ticketLines.forEach((line: any) => {

            tableData.push({
                data: [line.itemName, line.quantity, line.totalVatInclude, line.parentEntryId
                    ]                   
            });
            
        })
        this.setField({ tableData: tableData,});
      
    }

    close() {
        this.closePopUp();
    }
}