 
import {ContentView} from '@levelapp/softfabric';
import CountryViewState from './CountryViewState';
import CountryViewProps from './CountryViewProps';
import CountryViewStyle from './CountryViewStyle';
import CountryViewModel from './CountryViewModel';
import { View } from 'react-native';
import HeaderView from '../../../../components/header/HeaderView';
import TableView from '../../../../../../../Common/Components/TabelView/TableView';

export default class CountryView extends ContentView<CountryViewProps, CountryViewState, CountryViewModel, CountryViewStyle>
{
  /* CONSTRUCTOR */
  constructor(props:CountryViewProps)
  {
    super(props);

    // Binding
    this.bind(new CountryViewModel(this), new CountryViewStyle(this));
  }

  /* RENDERING */
  render()
  {
    return (
        <View style={this.style.container}>
          <View style={this.style.box}>
          <HeaderView
            title={"countries.title"}
            description={"countries.description"}
            shouldShowButton={true}
            onButtonPress={this.binding.addField}
            buttonText={"countries.button.add"}
          />

          <View style={this.style.boxContainer}>
              <TableView
                headerTextStyle={this.style.tableHeader}
                backgroundStepColor={this.style.stepColor}
                rowStyle={this.style.rowStyle}
                nothingToDisplayText={this.binding.translate("component.table.noresults")}
                isLastColumnActions={true}
                data={this.state.tableData}
                isLoading={this.state.isLoading}
                headers={this.state.tableHeaders}
                onPageChange={() => {}}
                handleSortChange={() => {}}
                nbPage={this.state.nbPage}
                currentPage={this.state.currentPage - 1}
                isSortable={false}/>
            </View>
          </View>
        </View>
    );
  }
}