import {ContentViewStyle} from '@levelapp/softfabric';
import { StyleProp, ViewStyle } from 'react-native';

export default class ButtonImportFileWebViewStyle extends ContentViewStyle
{
    hide : StyleProp<ViewStyle> =
    {
        opacity : 0,
        width : 1,
        height : 1,
        position : 'absolute',
    }

    container : StyleProp<ViewStyle> =
    {
        flexDirection : 'row',
        flex : 1,
    }

    button : StyleProp<ViewStyle> =
    {
        width : 100,
    }
}