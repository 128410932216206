 
import { ContentView } from '@levelapp/softfabric';
import TimerViewState from './TimerViewState';
import TimerViewProps from './TimerViewProps';
import TimerViewStyle from './TimerViewStyle';
import TimerViewModel from './TimerViewModel';
import { View, Text } from 'react-native';

export default class TimerView extends ContentView<TimerViewProps, TimerViewState, TimerViewModel, TimerViewStyle>
{
  /* CONSTRUCTOR */
  constructor(props: TimerViewProps)
  {
    super(props);

    // Binding
    this.bind(new TimerViewModel(this), new TimerViewStyle(this));
  }

  /* RENDERING */
  render()
  {
    const { time: timer } = this.state;
    const { isTimeOut } = this.state;

    const time = Math.abs(timer);
    const sec = time % 60;
    const min = (time - sec) / 60;
    const minFormat = min < 10 ? `0${min}` : `${min}`;
    const secFormat = sec < 10 ? `0${sec}` : `${sec}`;
    const sign = timer < 0 ? '-' : '';

    return (
      <View style={[this.style.timeContainer, isTimeOut ? this.style.timeContainerTimeOut : {}]}>
        <Text style={[this.style.timeLabel, isTimeOut ? this.style.timeLabelTimeOut : {}]}>
          {sign}{minFormat}:{secFormat}
        </Text>
      </View>
    );
  }
}