export enum OrderStatus
{
    New = 0,
    InBasket = 1,
    Paid = 2,
    Preparing = 3,
    Prepared = 4,
    PickedUp = 7,
    Cancelled = 5,
    Refunded = 6,
    ToPay = 9
}