import { ContentViewModel, ContentView } from '@levelapp/softfabric';
import RemoveProductFromGroupModalViewState from './RemoveProductFromGroupModalViewState';
import RemoveProductFromGroupModalViewProps from './RemoveProductFromGroupModalViewProps';

export default class RemoveProductFromGroupModalViewModel extends ContentViewModel<RemoveProductFromGroupModalViewState, RemoveProductFromGroupModalViewProps>
{
  /* CONSTRUCTOR */
  constructor(view: ContentView) {
    super(view);

    this.initialState({ isRemoving: false });

    // Bindings
    this.onClose = this.onClose.bind(this);
    this.onRemove = this.onRemove.bind(this);
  }

  onClose() {
    this.closePopUp();
  }

  async onRemove(id: number) {
    this.setField({isRemoving: true});
    await this.props().onRemove(id);
    this.setField({isRemoving: false});
  }
}