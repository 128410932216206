import { Operation } from "@levelapp/softfabric";
import AllergenService from "../Proxy/AllergenService";
import Allergen from "../Common/DTO/Allergen";

export default class AllergenOperations extends Operation
{
    service : AllergenService;

    constructor()
    {
        super();
        this.service = new AllergenService();
    }

    get(callback : Function)
    {
        this.service.get(callback);
    }

    getProductsUsingAllergen(allergen : Allergen, callback : Function)
    {
        this.service.getProductsUsingAllergen(allergen, callback);
    }

    post(allergen : Allergen, callback : Function)
    {
        this.service.post(allergen, callback);
    }

    put(allergen : Allergen, callback : Function)
    {
        this.service.put(allergen, callback);
    }

    delete(allergen : Allergen, callback : Function)
    {
        this.service.delete(allergen, callback);
    }
    async getProductsAllergenByProductId(productId : number)
    {
        return await this.service.getProductsAllergenByProductId(productId);
    }
}