import {ContentViewModel,ContentView} from '@levelapp/softfabric';
import ExtendedSelectViewState from './ExtendedSelectViewState';
import ExtendedSelectViewProps from './ExtendedSelectViewProps';

export default class ExtendedSelectViewModel extends ContentViewModel<ExtendedSelectViewState, ExtendedSelectViewProps>
{
      /* CONSTRUCTOR */
    constructor(view: ContentView)
    {
        super(view);

        this.initialState({}); // Initialize your state here

        // Bindings

    }
}