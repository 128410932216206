import { Service, Application, HttpManager, Managers } from "@levelapp/softfabric";
import OutOfStock from "../Common/DTO/OutOfStock";

export default class OutOfStockService extends Service{

    httpClient = Application.current.resolve<HttpManager>(Managers.HTTP_MANAGER);

    async getOutOfStockProducts(parameters:any[],onSuccess: Function, onFailure : Function = () => {}){
        this.httpClient.handleSomethingWentWrong = onFailure;
        this.httpClient.handleNotFound = onFailure;

        this.httpClient
        .get('outofstock', parameters)
        .then((response : any) => {
            if (response)
            {
                if (response.data)
                {      
                    onSuccess(response.data);
                    
                }
            }
        });
    }

    post(outofstocks : OutOfStock [],onSuccess : Function, onFailure : Function = () => {}){
        this.httpClient.handleSomethingWentWrong = onFailure;
        this.httpClient.handleNotFound = onFailure;

        this.httpClient
        .post('outofstock',[],outofstocks)
        .then((response:any)=>{
            if(response){
                onSuccess("succes");
            }
        })
    }

    delete(outofstock: OutOfStock, callback : Function = () => {}, onFailure : Function = () => {})
    {
        this.httpClient.handleSomethingWentWrong = onFailure;
        this.httpClient.handleNotFound = onFailure;

        this.httpClient
            .delete('outofstock/Delete/'+outofstock.id)
            .then((response : any) => {
                if(response)
                {
                    callback("");
                }
            });
    }

    
}