 
import { View, TextInput, Text  } from 'react-native';
import QrReader from 'react-qr-reader'
import { ContentView } from '@levelapp/softfabric';
import  PosSimulatorViewModel  from './PosSimulatorViewModel';
import  PosSimulatorViewProps  from './PosSimulatorViewProps';
import  PosSimulatorViewStyle  from './PosSimulatorViewStyle';
import  PosSimulatorViewState  from './PosSimulatorViewState';
import TableView from '../../../../../../../Common/Components/TabelView/TableView';
import { TranslationView,  } from '@levelapp/softfabric-ui';
import PosSimButtonView from '../../../../../../../Common/Components/possimbutton/PosSimButtonView';
import HeaderView from '../../../../components/header/HeaderView';

export default class PosSimulatorView extends ContentView<PosSimulatorViewProps, PosSimulatorViewState, PosSimulatorViewModel, PosSimulatorViewStyle>
{
    /* CONSTRUCTOR */   
    constructor(props: any){
        super(props);

        // Binding 
        this.bind(new PosSimulatorViewModel(this), new PosSimulatorViewStyle(this));
    }

    /* RENDERING */
    render() 
        {
            return (
            <View style = {this.style.container}>
                <View style={this.style.box}>

                    <HeaderView
                        title={"possimulator.title"}
                        description={"possimulator.description"}
                        shouldShowButton={false}
                    />


                    <View style={this.style.WelcomeBox}>
                        <View>                  
                            <View style={this.style.qrScannerBoxContainer}>
                                    <View style={{flex : 1 , backgroundColor : 'red'}}>
                                            <QrReader 
                                            style={{width:300 , height : 300}}
                                            onScan={this.binding.handleScan}
                                            onError={this.binding.handleError}
                                            ></QrReader>
                                        </View>
                            </View>                  
                        </View> 
                        <View style={this.style.qrcodeBoxView}>
                            <View style={this.style.qrcodeContainer}>
                                <TranslationView style={this.style.infosStyle}>
                                    possimulator.body.input.title
                                </TranslationView>
                                <TextInput style={this.style.textInput} placeholder={this.binding.translate('possimulator.body.input.placeholder')}  onChangeText={(qrcode: string) => this.binding.handleScannedCode(qrcode)} value={this.state.qrcode} />
                            </View>
                            <PosSimButtonView disabled={this.state.disabled}   style={this.style.clearButton} isLoading={false} handleClick={this.binding.reset} backgroundColor={'red'} opacity={this.state.opacity}>{this.binding.translate("generic.clear")}</PosSimButtonView>
                            <PosSimButtonView disabled={this.state.disabled}   style={this.style.okButton} isLoading={false} handleClick={this.binding.confirm} backgroundColor={'green'} opacity={this.state.opacity}>{this.binding.translate("generic.validate")}</PosSimButtonView>
                        </View>
                    </View>
                    <View >
                        <View style={this.style.infos}>
                            <Text>
                                {this.binding.translate('form.client')} : {this.state.userInfo}
                            </Text>
                            <Text>
                                {this.binding.translate('form.clientid')} : {this.state.userId}
                            </Text>
                        </View>
                        
                        <TableView 
                        maxHeight = {true}
                        headerTextStyle={this.style.tableHeader}
                        backgroundStepColor={this.style.stepColor}
                        rowStyle={this.style.rowStyle}
                        nothingToDisplayText={this.binding.translate("component.table.noresults")}
                        isLastColumnActions={false}
                        data={this.state.tableData}
                        isLoading={this.state.isLoading}
                        headers={this.state.tableHeaders}
                        onPageChange={(page: number) => this.binding.onPageChange(page)}
                        handleSortChange={(indexHeaderSort : number, sortOrder : string) => {this.binding.onSortChange(indexHeaderSort, sortOrder)}}
                        nbPage={this.state.nbPage}
                        currentPage={this.state.currentPage - 1}
                        isSortable={true}/>
                        <View style={this.style.purchaseBoxView}> 
                        <View style={this.style.puchaseAmountContainer}>
                             <TextInput style={this.style.textInput} placeholder={this.binding.translate('form.amount')}  onChangeText={(purchaseAmount: string) => this.binding.handlePurchaseAmount(purchaseAmount)} value={this.state.purchaseAmount} />
                         </View>   
                                <PosSimButtonView disabled={this.state.disabledPurchaseButtons}  style={this.style.cancelButton} isLoading={false} handleClick={this.binding.resetPurchase} backgroundColor={'#FF9944'} opacity={this.state.opacityPurchaseButton} >{this.binding.translate('generic.cancel')}</PosSimButtonView>
                                <PosSimButtonView disabled={this.state.disabledPurchaseButtons}  style={this.style.performButton} isLoading={false} handleClick={this.binding.confirmPurchase} backgroundColor={'#38B8F2'} opacity={this.state.opacityPurchaseButton} >{this.binding.translate('generic.confirm')}</PosSimButtonView>
                        </View>                             
                        
                    </View>
                            
                    </View>

                </View>
        );
            
     }
}