import { Operation } from "@levelapp/softfabric";
import AutomatedMessagesService from "../Proxy/AutomatedMessagesService";
import AutomatedMessageHistory from "../Common/DTO/AutomatedMessageHistory";

export default class AutomatedMessagesOperations extends Operation
{
    service : AutomatedMessagesService;

    constructor()
    {
        super();
        this.service = new AutomatedMessagesService();
    }

    get(parameters : any[], callback : Function)
    {
        this.service.get(parameters,callback);
    }

    post(automatedMessage:AutomatedMessageHistory, callback : Function)
    {
        this.service.post(automatedMessage, callback);
    }
}