import { PspTypes } from "../Enums/PspTypes";

declare var process: {
     env: {
          REACT_APP_RESSOURCESURL: string,
          REACT_APP_RESSOURCESWITHOUTAPI: string,
          REACT_APP_IDENTITYURL: string,
          REACT_APP_EVENTSURL: string,
          REACT_APP_IAMCLIENTSECRET: string,
          REACT_APP_SHOULDWORKWITHROLE: string,
          REACT_APP_SHOWUSERS: string,
          REACT_APP_SHOWREWARDS: string,
          REACT_APP_SHOWCOUPONS: string,
          REACT_APP_SHOWBOOSTERS: string,
          REACT_APP_SHOWTRANSLATIONS: string,
          REACT_APP_SHOWNEWS: string,
          REACT_APP_SHOWPRODUCTCATEGORIES: string,
          REACT_APP_SHOWPRODUCTADDITIONALINFO: string,
          REACT_APP_ISNEWSHTML: string,
          REACT_APP_ISRECEIVINGBASKETOBJECT: string,
          REACT_APP_SHOWSTATS: string,
          REACT_APP_SHOWPOSSIMULATOR: string,
          REACT_APP_SHOWPURCHASE: string,
          REACT_APP_SHOWLOYALTYRULES: string,
          REACT_APP_SHOWLEGALTEXTS: string,
          REACT_APP_HASINAPPMESSAGE: string,
          REACT_APP_SHOWSTORES: string,
          REACT_APP_SHOWSAVINGCARDS: string,
          REACT_APP_SHOWFAQ: string,
          REACT_APP_SHOWMESSAGES: string,
          REACT_APP_SHOWAUTOMATEDMESSAGES: string,
          REACT_APP_SHOWPRODUCTS: string,
          REACT_APP_SHOWORDERS: string,
          REACT_APP_SHOWROLEPERMISSIONS: string,
          REACT_APP_SHOWADMINUSERS: string,
          REACT_APP_SHOWKITCHENAPP: string,
          REACT_APP_SHOWTICKETS: string,
          REACT_APP_LOTTIELOADINGANIMATIONURL: string,
          REACT_APP_UPLOADIMAGEURL: string,
          REACT_APP_POPUPWIDTH: string,
          REACT_APP_SHOULDDISPLAYMENU: string,
          REACT_APP_PROJECTNAME: string,
          REACT_APP_LOGOURL: string,
          REACT_APP_MAINCOLOR: string,
          REACT_APP_GRADIANTSTARTCOLOR: string,
          REACT_APP_GRADIANTENDCOLOR: string,
          REACT_APP_GRADIANTMENUSTARTCOLOR: string,
          REACT_APP_GRADIANTMENUENDCOLOR: string,
          REACT_APP_LOTTIEANIMATIONPATH: string,
          REACT_APP_GRADIANTLOGOUTSTARTCOLOR: string,
          REACT_APP_GRADIANTLOGOUTENDCOLOR: string,
          REACT_APP_SHOWSTOREOPENINGHOURS: string,
          REACT_APP_SHOWDRIVEOPENINGHOURS: string,
          REACT_APP_SHOWDELIVERYOPENINGHOURS: string,
          REACT_APP_SCHEDULETYPESTOREID: string,
          REACT_APP_SCHEDULETYPEDRIVEID: string,
          REACT_APP_SCHEDULETYPEDELIVERYID: string,
          REACT_APP_SHOWHEALTHSTATUS: string,
          REACT_APP_SHOWOVERVIEW: string,
          REACT_APP_SHOWMENUCONFIGURATOR: string,
          REACT_APP_SHOWPROMOCODES: string,
          REACT_APP_SHOWLANGUAGES: string,
          REACT_APP_SHOWEVERYDAYVALUES: string,
          REACT_APP_SHOWCOUNTRIES: string
          REACT_APP_SHOWSTORESSUMMARY : string
          REACT_APP_SHOWCROSSSELLCONFIGURATOR : string,
          REACT_APP_HASCLICKANDCOLLECT: string,
          REACT_APP_ORDERTIMEOUT: string,
          REACT_APP_ISORDERTIMEOUTDECREASING: string,
          REACT_APP_SHOW_KITCHEN_USER_QR: string,
          REACT_APP_ALLOWTAGS: string,
          REACT_APP_SHOWOUTOFSTOCK : string,
          REACT_APP_SHOWCONTENTTYPEKEYCONFIG : string,
          REACT_APP_SHOWCLICKANDCOLLECTCOUPON: string,
          REACT_APP_ISCOUNTRYUNIQUE: string,
          REACT_APP_ISSAVINGCARDSUSEDASBADGE: string
          REACT_APP_KITCHENAPPAMOUNT: string,
          REACT_APP_KITCHENAPPZOOM: string,
          REACT_APP_KITCHENAPPAMOUNTINCENT: string,
          REACT_APP_USESETTINGSCONTENT : string,
          REACT_APP_CURRENT_PSP : string,
          REACT_APP_SHOWPRODUCTTOOL : string,
          REACT_APP_SHOWZONETOOL : string,
          REACT_APP_USEREFRESHMENUCONFIG : string,
          REACT_APP_SHOWPRODUCTORDERINDEX: string,
          REACT_APP_SHOWBANNERS : string,
          REACT_APP_LONGERSOUNDKITCHENAPP : string,
          REACT_APP_CLOSEIMAGEPATH : string,
          REACT_APP_SHOWEARNINGPOINTS: string,
          REACT_APP_SHOWIMPORTEXPORTPRODUCTCATEGORIES: string,
          REACT_APP_USEPRODUCTDELIVERYTAB : string,
          REACT_APP_DELIVERYURL: string,
          REACT_APP_DEFAULTSEARCHLANGUAGE: string,
          REACT_APP_SHOWMEMBERSHIP : string
          REACT_APP_DEFAULT_KIOSK_USER_LANGUAGE: string,
          REACT_APP_SHOWACIVATECOUPONFORUSERSLIST : string,
          REACT_APP_AUTOREFRESHKITCHENAPP : string,
          REACT_APP_KITCHENAPPREFRESH: string,
          REACT_APP_HAS_DELIVERIES: string,
          REACT_APP_KITCHENPRINTERS: string,
          REACT_APP_KITCHENAPP_USE_PRODUCT_TREE_OTACOS : string,
          REACT_APP_KITCHEN_LANGUAGE : string,
          REACT_APP_KITCHENURL : string,
          REACT_APP_KITCHEN_BRANDS: string,
          REACT_APP_KITCHENAPP_SKIP_STATUS_POPUP: string
          REACT_APP_SHOWOPTINSADVANCEDPROFILE: string;
          REACT_APP_SHOWVOUCHERS: string;
          REACT_APP_USE_TENANT: string;
          REACT_APP_TENANT_ID: string;
          REACT_APP_SHOWEMAILVERIFIED: string;
          REACT_APP_SHOWUSERSOPTINS: string;
          REACT_APP_SHOWUSERSADVANCEDPROFILE: string;
          REACT_APP_SHOWUSERSMEMBERSHIPS: string;
          REACT_APP_SHOWSTOREDELIVERIESCHECKBOX: string;
          REACT_APP_COUPONHASDURATION: string;
     }
}
/**
 * Client Constants
 *
 */

const Constants = {
     RessourcesUrl: process.env.REACT_APP_RESSOURCESURL,
     RessourcesWithoutApi: process.env.REACT_APP_RESSOURCESWITHOUTAPI,
     IdentityUrl: process.env.REACT_APP_IDENTITYURL,
     EventsUrl: process.env.REACT_APP_EVENTSURL,
     DeliveryUrl: process.env.REACT_APP_DELIVERYURL,
     KitchenUrl: process.env.REACT_APP_KITCHENURL,
     // RessourcesUrl: "http://localhost:5004/api",
     // RessourcesWithoutApi : "http://localhost:5004", 
     // IdentityUrl: "http://localhost:5000",
     // EventsUrl: 'https://api.flyx.cloud/flyx/events',

     IAMClientSecret: process.env.REACT_APP_IAMCLIENTSECRET,

     ShouldWorkWithRole: toBoolean(process.env.REACT_APP_SHOULDWORKWITHROLE),

     ShowUsers: toBoolean(process.env.REACT_APP_SHOWUSERS),
     ShowRewards: toBoolean(process.env.REACT_APP_SHOWREWARDS),
     ShowCoupons: toBoolean(process.env.REACT_APP_SHOWCOUPONS),
     ShowBoosters: toBoolean(process.env.REACT_APP_SHOWBOOSTERS),
     ShowTranslations: toBoolean(process.env.REACT_APP_SHOWTRANSLATIONS),
     ShowNews: toBoolean(process.env.REACT_APP_SHOWNEWS),
     ShowProductCategories: toBoolean(process.env.REACT_APP_SHOWPRODUCTCATEGORIES),
     IsNewsHtml: toBoolean(process.env.REACT_APP_ISNEWSHTML),
     IsReceivingBasketObject: toBoolean(process.env.REACT_APP_ISRECEIVINGBASKETOBJECT),
     ShowStats: toBoolean(process.env.REACT_APP_SHOWSTATS),
     ShowPosSimulator: toBoolean(process.env.REACT_APP_SHOWPOSSIMULATOR),
     ShowPurchase: toBoolean(process.env.REACT_APP_SHOWPURCHASE),
     ShowLoyaltyRules: toBoolean(process.env.REACT_APP_SHOWLOYALTYRULES),
     ShowLegalTexts: toBoolean(process.env.REACT_APP_SHOWLEGALTEXTS),
     HasInAppMessage: toBoolean(process.env.REACT_APP_HASINAPPMESSAGE),
     ShowStores: toBoolean(process.env.REACT_APP_SHOWSTORES),
     ShowSavingCards: toBoolean(process.env.REACT_APP_SHOWSAVINGCARDS),
     ShowFaq: toBoolean(process.env.REACT_APP_SHOWFAQ),
     ShowMessages: toBoolean(process.env.REACT_APP_SHOWMESSAGES),
     ShowAutomatedMessages: toBoolean(process.env.REACT_APP_SHOWAUTOMATEDMESSAGES),
     ShowProducts: toBoolean(process.env.REACT_APP_SHOWPRODUCTS),
     ShowOrders: toBoolean(process.env.REACT_APP_SHOWORDERS),
     ShowRolePermissions: toBoolean(process.env.REACT_APP_SHOWROLEPERMISSIONS),
     ShowAdminUsers: toBoolean(process.env.REACT_APP_SHOWADMINUSERS),
     ShowKitchenApp: toBoolean(process.env.REACT_APP_SHOWKITCHENAPP),
     ShowTickets: toBoolean(process.env.REACT_APP_SHOWTICKETS),
     ShowHealthStatus: toBoolean(process.env.REACT_APP_SHOWHEALTHSTATUS),
     ShowOverview: toBoolean(process.env.REACT_APP_SHOWOVERVIEW),
     ShowMenuConfigurator: toBoolean(process.env.REACT_APP_SHOWMENUCONFIGURATOR),
     ShowPromoCodes: toBoolean(process.env.REACT_APP_SHOWPROMOCODES),
     ShowLanguage: toBoolean(process.env.REACT_APP_SHOWLANGUAGES),
     ShowEverydayValues: toBoolean(process.env.REACT_APP_SHOWEVERYDAYVALUES),
     ShowCountries: toBoolean(process.env.REACT_APP_SHOWCOUNTRIES),
     ShowStoresSummary : toBoolean(process.env.REACT_APP_SHOWSTORESSUMMARY),
     ShowCrossSellconfigurator : toBoolean(process.env.REACT_APP_SHOWCROSSSELLCONFIGURATOR),
     LottieLoadingAnimationUrl: process.env.REACT_APP_LOTTIELOADINGANIMATIONURL,
     UploadImageUrl: process.env.REACT_APP_UPLOADIMAGEURL,
     PopUpWidth: toNumber(process.env.REACT_APP_POPUPWIDTH),
     ShowOutOfStock : toBoolean(process.env.REACT_APP_SHOWOUTOFSTOCK),
     ShowProductTool : toBoolean(process.env.REACT_APP_SHOWPRODUCTTOOL),
     ShowZoneTool : toBoolean(process.env.REACT_APP_SHOWZONETOOL),
     ShowContentTypeKeyConfig : toBoolean(process.env.REACT_APP_SHOWCONTENTTYPEKEYCONFIG),
     ShowClickAndCollectForCoupons : toBoolean(process.env.REACT_APP_SHOWCLICKANDCOLLECTCOUPON),
     IsCountryUnique : toBoolean(process.env.REACT_APP_ISCOUNTRYUNIQUE),
     IsSavingCardsUsedAsBadge : toBoolean(process.env.REACT_APP_ISSAVINGCARDSUSEDASBADGE),
     UseSettingsContent : toBoolean(process.env.REACT_APP_USESETTINGSCONTENT),
     ShowRefreshButtonMenuConfig : toBoolean(process.env.REACT_APP_USEREFRESHMENUCONFIG),
     ShowBanners : toBoolean(process.env.REACT_APP_SHOWBANNERS),
     ShowProductOrderIndex: toBoolean(process.env.REACT_APP_SHOWPRODUCTORDERINDEX),
     ShowEarningPoints: toBoolean(process.env.REACT_APP_SHOWEARNINGPOINTS),
     ShowImportExportProductCategories: toBoolean(process.env.REACT_APP_SHOWIMPORTEXPORTPRODUCTCATEGORIES),
     ShowMembership : toBoolean(process.env.REACT_APP_SHOWMEMBERSHIP),
     ShowActivateCouponsForUsersList : toBoolean(process.env.REACT_APP_SHOWACIVATECOUPONFORUSERSLIST),
     ShowVouchers : toBoolean(process.env.REACT_APP_SHOWVOUCHERS),
     ShowEmailVerified : toBoolean(process.env.REACT_APP_SHOWEMAILVERIFIED),
     ShowUserOptins : toBoolean(process.env.REACT_APP_SHOWUSERSOPTINS),
     ShowUserAdvancedProfile : toBoolean(process.env.REACT_APP_SHOWUSERSADVANCEDPROFILE),
     ShowUserMemberships : toBoolean(process.env.REACT_APP_SHOWUSERSMEMBERSHIPS),
     ShowStoreDeliveriesCheckBoxes : toBoolean(process.env.REACT_APP_SHOWSTOREDELIVERIESCHECKBOX),
     ShowProductAdditionalInfo: toBoolean(process.env.REACT_APP_SHOWPRODUCTADDITIONALINFO),
  
     CloseImagePath : process.env.REACT_APP_CLOSEIMAGEPATH,
     ShouldDisplayMenu: toBoolean(process.env.REACT_APP_SHOULDDISPLAYMENU),
     ProjectName: process.env.REACT_APP_PROJECTNAME,
     LogoUrl: process.env.REACT_APP_LOGOURL,
     MainColor: process.env.REACT_APP_MAINCOLOR,
     GradiantStartColor: process.env.REACT_APP_GRADIANTSTARTCOLOR,
     GradiantEndColor: process.env.REACT_APP_GRADIANTENDCOLOR,
     GradiantMenuStartColor: process.env.REACT_APP_GRADIANTMENUSTARTCOLOR,
     GradiantMenuEndColor: process.env.REACT_APP_GRADIANTMENUENDCOLOR,
     LottieAnimationPath: process.env.REACT_APP_LOTTIEANIMATIONPATH,

     GradiantLogoutStartColor: process.env.REACT_APP_GRADIANTLOGOUTSTARTCOLOR,
     GradiantLogoutEndColor: process.env.REACT_APP_GRADIANTLOGOUTENDCOLOR,

     //PRODUCT
     ShowDeliveryProductTab : toBoolean(process.env.REACT_APP_USEPRODUCTDELIVERYTAB),
     DefaultSearchLanguage: process.env.REACT_APP_DEFAULTSEARCHLANGUAGE,
     
     //STORE
     ShowStoreOpeningHours: toBoolean(process.env.REACT_APP_SHOWSTOREOPENINGHOURS),
     ShowDriveOpeningHours: toBoolean(process.env.REACT_APP_SHOWDRIVEOPENINGHOURS),
     ShowDeliveryOpeningHours: toBoolean(process.env.REACT_APP_SHOWDELIVERYOPENINGHOURS),
     ScheduleTypeStoreId: toNumber(process.env.REACT_APP_SCHEDULETYPESTOREID),
     ScheduleTypeDriveId: toNumber(process.env.REACT_APP_SCHEDULETYPEDRIVEID),
     ScheduleTypeDeliveryId: toNumber(process.env.REACT_APP_SCHEDULETYPEDELIVERYID),

     // KITCHEN APP
     modifierRangeMin: 96001,
     modifierRangeMax: 96200,
     orderTimeOut: process.env.REACT_APP_ORDERTIMEOUT ? toNumber(process.env.REACT_APP_ORDERTIMEOUT) : 300,
     isOrderTimeOutDecreasing: toBoolean(process.env.REACT_APP_ISORDERTIMEOUTDECREASING),
     showAmountInKitchenApp: toBoolean(process.env.REACT_APP_KITCHENAPPAMOUNT),
     isAmountInCent: toBoolean(process.env.REACT_APP_KITCHENAPPAMOUNTINCENT),
     allowedToZoomKitchenApp: toBoolean(process.env.REACT_APP_KITCHENAPPZOOM),
     longerSoundKitchenApp : toBoolean(process.env.REACT_APP_LONGERSOUNDKITCHENAPP),
     allowedToRefreshCacheKitchenApp: toBoolean(process.env.REACT_APP_KITCHENAPPREFRESH),
     hasPrinters: toBoolean(process.env.REACT_APP_KITCHENPRINTERS),
     useTreeOtacos: toBoolean(process.env.REACT_APP_KITCHENAPP_USE_PRODUCT_TREE_OTACOS),
     kitchenLanguage: process.env.REACT_APP_KITCHEN_LANGUAGE,
     kitchenUseBrand: toBoolean(process.env.REACT_APP_KITCHEN_BRANDS),
     showKitchenUserQr: toBoolean(process.env.REACT_APP_SHOW_KITCHEN_USER_QR),
     kitchenAppSkipStatusPopup: toBoolean(process.env.REACT_APP_KITCHENAPP_SKIP_STATUS_POPUP),
     // PSP
     listPspAvailable: [PspTypes.MOLLIE, PspTypes.RABOBANK, PspTypes.STRIPE],
     
     currentPsp: process.env.REACT_APP_CURRENT_PSP,

     UseAutoRefresh : toBoolean(process.env.REACT_APP_AUTOREFRESHKITCHENAPP),

     // C AND C
     HasClickAndCollect: toBoolean(process.env.REACT_APP_HASCLICKANDCOLLECT),
     HasDeliveries: toBoolean(process.env.REACT_APP_HAS_DELIVERIES),

     // REWARDS 
     AllowTags: process.env.REACT_APP_ALLOWTAGS ? toBoolean(process.env.REACT_APP_ALLOWTAGS) : false,
     DefaultKioskUserLanguage: process.env.REACT_APP_DEFAULT_KIOSK_USER_LANGUAGE,

     //COUPONS
     hasCouponDuration : toBoolean(process.env.REACT_APP_COUPONHASDURATION),

     // Tenants
     UseTenant : toBoolean(process.env.REACT_APP_USE_TENANT),
     TenantId: process.env.REACT_APP_TENANT_ID,

     Version: '1.0.0'
}


function toBoolean(value: string): boolean
{
     return value == 'true';
}

function toNumber(value: string): number
{
     return Number.parseInt(value);
}
export default Constants;
