 
import {ContentShellView} from '@levelapp/softfabric';
import ShellRolePermissionViewState from './ShellRolePermissionViewState';
import ShellRolePermissionViewProps from './ShellRolePermissionViewProps';
import ShellRolePermissionViewStyle from './ShellRolePermissionViewStyle';
import ShellRolePermissionViewModel from './ShellRolePermissionViewModel';
import {View} from 'react-native';

export default class ShellRolePermissionView extends ContentShellView<ShellRolePermissionViewProps, ShellRolePermissionViewState, ShellRolePermissionViewModel, ShellRolePermissionViewStyle>
{
  /* CONSTRUCTOR */
  constructor(props:ShellRolePermissionViewProps)
  {
    super(props);

    // Binding
    this.bind(new ShellRolePermissionViewModel(this), new ShellRolePermissionViewStyle(this));
  }

  /* RENDERING */
  render()
  {
    return (
      <View style={{flex : 1}}>
        {this.getSwitch()}
      </View>
    );
  }
}