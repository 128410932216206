import {ContentViewModel,ContentView} from '@levelapp/softfabric';
import LogHistoryPopupViewState from './LogHistoryPopupViewState';
import LogHistoryPopupViewProps from './LogHistoryPopupViewProps';
import LogOperation from '../../../../../../../Transfer/LogOperations';
import Log from '../../../../../../../Common/DTO/Log';

export default class LogHistoryPopupViewModel extends ContentViewModel<LogHistoryPopupViewState, LogHistoryPopupViewProps>
{
    _logOperation : LogOperation;

      /* CONSTRUCTOR */
    constructor(view: ContentView)
    {
        super(view);

        this.initialState(
            {
                logs : [],
                details : false,
                currentLog : undefined,
                isLoading : true,
            }); // Initialize your state here

        // Bindings
        this._logOperation = new LogOperation();

        this.showDetails = this.showDetails.bind(this);
        this.hideDetails = this.hideDetails.bind(this); 
    }

    async componentDidMount()
    {
        const response = await this._logOperation.getBasketLog(this.props().basket.id);
        if (response && response.data)
        {
            return this.setField({ logs: response.data.data, isLoading: false });
        }
        alert('Error retrieving logs for this order');
        return this.setField({ isLoading: false });
    }

    showDetails(log : Log)
    {
        this.setField({details : true , currentLog : log});
    }

    hideDetails()
    {
        this.setField({details : false , currentLog : undefined});
    }

    errorCodeColor(codeerror : string)
    {
        var code = Number.parseInt(codeerror);
        if(Number.isNaN(code))
        {
            return "gray";
        }

        if(code >= 200 && code < 300)
        {
            return "green";
        }else if(code >= 400 )
        {
            return "red";
        }else
        {
            return "gray";
        }
    }
}