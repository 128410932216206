import { Operation } from "@levelapp/softfabric";
import { ContentTypeEnum } from "../Common/Enums/ContentTypeEnum";
import TagService from "../Proxy/TagService";

export default class TagOperations extends Operation
{
    service : TagService;
    constructor()
    {
        super();
        this.service = new TagService();
    }

    post(tagCreation: {label: string, prefixKey: string, contentTypeId: ContentTypeEnum }, callback : Function, onFailure : Function) {
        this.service.post(tagCreation, callback, onFailure);
    }

    get(callback : Function, onFailure : Function)
    {
        this.service.get(callback, onFailure);
    }

}