import {ContentViewModel,ContentView, StorageManager, Application, Managers} from '@levelapp/softfabric';
import OverviewViewState from './OverviewViewState';
import OverviewViewProps from './OverviewViewProps';
import LogOperation from '../../../../../../../Transfer/LogOperations';
import StoresOperations from '../../../../../../../Transfer/StoresOperations';
import EnumModules from '../../../../../../../Common/Constants/EnumModules';
import Store from '../../../../../../../Common/DTO/Store';
var ProgressBar = require('progressbar.js');

export default class OverviewViewModel extends ContentViewModel<OverviewViewState, OverviewViewProps>
{
    ref : any;
    bar : any;
    _refEndDate : any;

    _logOperation : LogOperation;
    _storeOperation : StoresOperations;

    _storeManager : StorageManager;

    unmount : boolean = false;

      /* CONSTRUCTOR */
    constructor(view: ContentView)
    {
        super(view);

        this.initialState(
            {
                stores:[],
                logs : new Map(),
                selectedDateFrom : new Date(Date.now()),
                selectedDateTo : new Date(Date.now()),
                isLoading : true,
                notparsed : []
            }); // Initialize your state here

        this.calculate = this.calculate.bind(this);
        // Bindings
        this._logOperation = new LogOperation();
        this._storeOperation = new StoresOperations();

        this._storeManager = Application.current.resolve<StorageManager>(Managers.STORAGE_MANAGER);
    }

    async componentDidMount()
    {
        this.bar = new ProgressBar.SemiCircle(this.ref, {
            strokeWidth: 6,
            trailColor: '#EEEEEE',
            trailWidth: 1,
            easing: 'easeInOut',
            duration: 1400,
            svgStyle: null,
            text: {
              value: '',
              alignToBottom: false
            },
            from: {color: '#FF6961'},
            to: {color: '#73C678'},
            // Set default step function for all animate calls
            step: (state : any, bar : any) => {
              bar.path.setAttribute('stroke', state.color);
              var value = Math.round(bar.value() * 100);
              if (value === 0) {
                bar.setText('');
              } else {
                bar.setText(`${value}%`);
              }
          
              bar.text.style.color = state.color;
            }
          });

        var stores = await this._storeManager.fetch("STORE_OVERVIEW");
        var logs = await this._storeManager.fetch("LOGS_OVERVIEW");
        var selectedDateFrom = await this._storeManager.fetch("SDF_OVERVIEW");
        var selectedDateTo = await this._storeManager.fetch("SDT_OVERVIEW");


        if(stores != undefined && logs != undefined)
        {
            this.calculate(logs);
            this.setField({stores : stores  , isLoading : true, selectedDateFrom : new Date(selectedDateFrom as number) , selectedDateTo : new Date(selectedDateTo as number)});
        }

        this._storeOperation.getallwithouttranslations(async (response : any) =>
        {
            this._storeManager.store("STORE_OVERVIEW",response.data)
            this.setField({stores : response.data},async () =>
            {
                var dateFrom = this.state().selectedDateFrom
 

                if(selectedDateFrom != undefined)
                {
                    dateFrom = new Date(selectedDateFrom as number);
                }else
                {
                    this._storeManager.store("SDF_OVERVIEW",dateFrom.getTime());
                }

                var dateTo = this.state().selectedDateTo
                if(selectedDateTo != undefined)
                {
                    dateTo = new Date(selectedDateTo as number);
                }else
                {
                    this._storeManager.store("SDT_OVERVIEW",dateTo.getTime());
                }
                var response = await this._logOperation.getAllLogsStatsforAllStores(dateFrom, dateTo);
                this.calculate(response);
            });
            
        })
    }


    componentWillUnmount()
    {
        this.unmount = true;
    }

    async calculate(response : any)
    {
        if(response && response.data)
        {
            var totalsuccess = 0;
            var totalfailure = 0;
            var logs = this.state().logs;

            var notparsed = this.state().notparsed;
            Object.keys(response.data.data)
                .forEach((x)=> {
                     
                        notparsed.push((response as any).data.data[x].notparsed);
                        logs.set(Number.parseInt(x),(response  as any).data.data[x]);
                        totalsuccess += (response as any).data.data[x].success;
                        totalfailure += (response as any).data.data[x].failure;
                    
                    

                });
            
            //If a call is done, but change page, the bar will crash
            if(!this.unmount)
            {
                if(this.bar != undefined)
                {
                    this.bar.text.style.fontFamily = '"Raleway", Helvetica, sans-serif';
                    this.bar.text.style.fontSize = '2rem';
                    
                    if(totalsuccess != 0 || totalfailure != 0)
                    {
                        this.bar.animate((totalsuccess / (totalsuccess + totalfailure)));
        
                    }else
                    {
                        this.bar.animate(0);
                    }
                }
                
                
            
            this._storeManager.store("LOGS_OVERVIEW",response);
            this.setField({logs : logs , stores : this.state().stores, isLoading : false});
            }

        }
    }

    handleOnChangeDate(type : 'startdate' | 'date' , value : Date)
    {
        var state = this.state();
        
        if(type == 'startdate')
        {
            this._refEndDate.getInnerRef().input.focus();
            state.selectedDateFrom = value;
            

        }else
        {
            state.selectedDateTo = value;
            state.isLoading = true;

        }
        this.setField({state},async () => 
        {
            this._storeManager.store("SDF_OVERVIEW",this.state().selectedDateTo);
            this._storeManager.store("SDT_OVERVIEW",this.state().selectedDateTo);

            if(type == 'date')
            {
                var response = await this._logOperation.getAllLogsStatsforAllStores(this.state().selectedDateTo,this.state().selectedDateTo);
                this.calculate(response);
            }
        });
        //this.props().handleDateChange(type,value);
    }

    handleNavigate( store : Store )
    {
        this.navigateToModulePage(EnumModules.HEALTHSTATUS,'main',true,{store : store , date : this.state().selectedDateTo })
    }
}