import {ContentShellViewModel, ContentShellView} from '@levelapp/softfabric';
import ShellPromoCodesViewState from './ShellPromoCodesViewState';
import ShellPromoCodesViewProps from './ShellPromoCodesViewProps';
 
import EnumModules from '../../../../../Common/Constants/EnumModules';
import PromoCodesView from './pages/promocodes/PromoCodesView';

export default class ShellOrdersViewModel extends ContentShellViewModel<ShellPromoCodesViewState, ShellPromoCodesViewProps>
{

    constructor(view : ContentShellView)
    {
        super(view);

        this.registerEntryPoint('main');
        this.register(<PromoCodesView/> ,'main');

        this.initialisation();
    }

    getModuleId()
    {
        return EnumModules.PROMOCODES;
    }

}