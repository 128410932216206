import {ContentShellViewModel, ContentShellView} from '@levelapp/softfabric';
import ShellOrdersViewState from './ShellOrdersViewState';
import ShellOrdersViewProps from './ShellOrdersViewProps';
 
import EnumModules from '../../../../../Common/Constants/EnumModules';
import OrdersView from './pages/orders/OrdersView';

export default class ShellOrdersViewModel extends ContentShellViewModel<ShellOrdersViewState, ShellOrdersViewProps>
{

    constructor(view : ContentShellView)
    {
        super(view);

        this.registerEntryPoint('main');
        this.register(<OrdersView/> ,'main');

        this.initialisation();
    }

    getModuleId()
    {
        return EnumModules.ORDERS;
    }

}