import { ContentViewModel, ContentView } from '@levelapp/softfabric';
import TraductionViewState from './TraductionViewState';
import TraductionViewProps from './TraductionViewProps';
import TranslationsNonContentOperations from '../../../../../../../Transfer/TranslationsNonContentOperations';
import { TableRow } from '@levelapp/softfabric-ui';
import LanguagesOperations from '../../../../../../../Transfer/LanguagesOperations';
import TranslationNonContentPopUpView from '../translationnoncontentpopup/TranslationNonContentPopUpView';
import Helpers from '../../../../../../../Common/Helpers/Helpers';
 
import * as XLSX from 'xlsx';
import Translation from '../../../../../../../Common/DTO/Translation';
import TranslationValue from '../../../../../../../Common/DTO/TranslationValue';
import Language from '../../../../../../../Common/DTO/Language';
import ConfirmPopUpView from '../../../../../../../Common/Components/confirmpopup/ConfirmPopUpView';
import ToastHelper from '../../../../../../../Common/Helpers/ToastHelper';
import ErrorHelper from '../../../../../../../Common/Helpers/ErrorHelper';

export default class TraductionViewModel extends ContentViewModel<TraductionViewState, TraductionViewProps>
{
    operation: TranslationsNonContentOperations;
    languagesOperation: LanguagesOperations;
    _languages: Language[] = [];
    _languagesString: string[] = [];
    interval: any;
    searchText: string;
    isSorting: boolean;
    indexHeaderSort: number;
    sortOrder: string;
    sortKey: string[];

    constructor(view: ContentView) {
        super(view);

        this.interval = null;
        this.searchText = '';
        this.isSorting = false;
        this.indexHeaderSort = -1;
        this.sortOrder = 'asc';
        this.sortKey = ['Id', 'Key', 'Translation', 'Translation', 'Translation', 'Translation'];

        /* InitialState */
        this.operation = new TranslationsNonContentOperations();
        this.languagesOperation = new LanguagesOperations();

        this.languagesOperation.get((languages: any) => {
            this._languages = languages.data;
            this._languagesString = [];
            this._languages.forEach((value: Language) => {
                this._languagesString.push(value.key);
            });
            this.setField({ headers: ["Id", "Key"].concat(this._languagesString), })
        });

        this.initialState({
            headers: ["Id", "Key"],
            data: [],
            isLoading: true,
            translations: [],
            nbPage: 0,
            currentPage: 1,
            isResearching: false
        });

        /* BINDINGS */
        this.editField = this.editField.bind(this);
        this.save = this.save.bind(this);
        this.export = this.export.bind(this);
        this.onPageChange = this.onPageChange.bind(this);
        this.handleResearching = this.handleResearching.bind(this);
        this.handleCloseResearching = this.handleCloseResearching.bind(this);
        this.addTranslation = this.addTranslation.bind(this);
        this.add = this.add.bind(this);
        this.deleteField = this.deleteField.bind(this);
    }

    componentDidMount() {
        this.refresh();
    }

    refresh() {

        this.setField({ isLoading: true });
        let parameters = [];
        parameters.push({ key: "page", value: this.state().currentPage }, { key: "itemPage", value: 20 });
        if (this.state().isResearching) {
            parameters.push({ key: "search", value: this.searchText });
        }
        if (this.isSorting) {
            parameters.push(
                { key: 'columnSort', value: this.sortKey[this.indexHeaderSort] },
                { key: 'sortOrder', value: this.sortOrder });

            //If the sort is on one of the translations
            if (this.indexHeaderSort > 1) {
                parameters.push({ key: 'userLanguage', value: this._languages[this.indexHeaderSort - 2] });
            }
        }
        this.getData(parameters);
    }

    getData(queryParameter: any[]) {
        this.operation.get(queryParameter, (response: any) => {
            let tableData: TableRow[] = [];
            const translations: Translation[] = response.data.objects;

            translations.forEach((translation: Translation) => {
                var temp = [translation.id, translation.key]
                this._languages.forEach(lang => {
                    let trans = translation.translationValues.find((element: TranslationValue) => element.language.key == lang.key);
                    if (trans) {
                        temp.push(trans.value);
                    }
                    else {
                        translation.translationValues.push({
                            value: "",
                            language: {
                                key: lang.key
                            }
                        });
                        temp.push("");
                    }
                })
                tableData.push({
                    data: temp, isEditable: true, isDeletable: true,
                    editCallback: () => { this.editField(translation) },                    
                    deleteCallback: () => { this.deleteField(translation) }
                });
            });
            this.setField({ data: tableData, translations: translations, nbPage: response.data.totalPage, isLoading: false });
        });
    }

    editField(translation: any) {
        //Doesn't edit, just display the popup
        this.showPopUp(
            <TranslationNonContentPopUpView translation={Helpers.clone(translation)} save={this.save} languages={this._languages} />
        );
    }

    deleteField(translation: any) {
        this.showPopUp(
            <ConfirmPopUpView callBack={() => {
                this.operation.delete(translation, () => {
                    this.showToast(ToastHelper.getToast("translation.popup.success.deleted","success"),() => {},2500);
                    this.refresh();
                },this.errorManagement,
                () => {
                    alert("The service deleteTranslation is not online")
                });
            }} />
        );
    }

    errorManagement(error : any) 
    {
        this.setField({isLoading : false});
        ErrorHelper.showMessageError(error.response);
    }

    save(translation: any) {
        this.operation.put(translation, () => {
            this.refresh();
            this.closePopUp();
        });
    }

    add(translation: any) {
        this.operation.addTranslation(translation, () => {
            this.refresh();
            this.closePopUp();
        });
    }

    addTranslation() {
        /* We create a empty translation */
        var translation = Helpers.clone(this.state().translations[0]);
        translation.id = 0;
        translation.key = "";
        translation.translationValues.forEach((x:any) => {
            x.id = 0;
            x.value = "";
        });
        this.showPopUp(<TranslationNonContentPopUpView translation={translation} save={this.add} languages={this._languages} ></TranslationNonContentPopUpView>)
    }

    export() {
        var headers = ["Key"].concat(this._languagesString);
        var jsonContent: any[] = [];
        this.operation.get([], (response: any) => {
            const translations: Translation[] = response.data.objects;

            translations.forEach((translation: Translation) => {
                var element = "{";
                var row = "";
                row += JSON.stringify(headers[0]) + ":" + JSON.stringify(translation.key);
                this._languages.forEach((lang, index) => {
                    const trans = translation.translationValues.find((element: TranslationValue) => element.language.key == lang.key);
                    const value = trans ? trans.value : "";

                    row += ",";
                    row += JSON.stringify(headers[index + 1]) + ":" + JSON.stringify(value);
                });
                element = element + row;
                element += "}";
                jsonContent.push(JSON.parse(element));
            });
            var wb = XLSX.utils.book_new();
            var o = XLSX.utils.json_to_sheet(jsonContent, { header: headers });
            XLSX.utils.book_append_sheet(wb, o, "Translations");
            XLSX.writeFile(wb, "Translations.xlsx");
        });
    }

    handleFile(file: File) {
        this.handleReading(file);
        this.setField({ isLoading: true });
    }

    handleReading(file: File) {
        if (file != undefined) {

            //Read Excel File
            var fileReader = new FileReader();
            fileReader.onloadend = () => {
                // Leave that
                var binary = "";
                var bytes = new Uint8Array(fileReader.result as ArrayBuffer);
                var length = bytes.byteLength;
                for (var i = 0; i < length; i++) {
                    binary += String.fromCharCode(bytes[i]);
                }
                this.TransformExcelToData(binary);
            }
            fileReader.readAsArrayBuffer(file);
        }
    }

    TransformExcelToData(data: any) {
        var headers: string[] = []
        var workbook: XLSX.WorkBook = XLSX.read(data, { type: "binary" });
        var range = XLSX.utils.decode_range(workbook.Sheets[workbook.SheetNames[0]]['!ref'] as string);

        var R = range.s.r;
        for (var C = range.s.c; C <= range.e.c; C++) {

            var cell = workbook.Sheets[workbook.SheetNames[0]][XLSX.utils.encode_cell({ c: C, r: R })]
            var hdr = "UNKNOWN";
            if (cell && cell.t) headers.push(hdr = XLSX.utils.format_cell(cell));

            if (hdr == "UNKNOWN") {
                break;
            }
        }

        var json = XLSX.utils.sheet_to_json(workbook.Sheets[workbook.SheetNames[0]]);
        // var arrayToSend : TranslationTableRow[] = [];
        // var keyNumber = 0;
        var translations: any[] = [];
        json.forEach((v: any) => {
            var translationValues: any[] = [];
            this._languagesString.forEach(lang => {
                translationValues.push({ "value": v[lang], language: { "Key": lang } })
            })
            let translation = { "Key": v.Key, "translationValues": translationValues };
            translations.push(translation);
        });

        this.operation.post({ "Translations": translations }, () => {
            this.refresh();
        });
    }

    onPageChange(page: number) {
        this.state().currentPage = page + 1;
        this.refresh()
    }

    onSortChange(indexHeaderSort: number, sortOrder: string) {
        if (indexHeaderSort == -1) {
            this.isSorting = false;
        } else {
            this.indexHeaderSort = indexHeaderSort;
            this.sortOrder = sortOrder;
            this.isSorting = true;
        }
        this.refresh();
    }

    handleResearching(searchText: string) {
        this.setField({ isLoading: true, isResearching: true });
        this.searchText = searchText;

        if (this.interval) {
            clearInterval(this.interval);
        }
        this.interval = setTimeout(() => {
            this.onPageChange(0)
        }, 1000)
    }

    handleCloseResearching() {
        this.state().isResearching = false;
        this.onPageChange(0);
    }
}