// app and web entry
import App from "./App";
import { AppRegistry, Platform } from "react-native";

// register the app
AppRegistry.registerComponent("FlyxAdminConsole", () => App);

// register the web
if (Platform.OS === "web") {
    AppRegistry.runApplication("FlyxAdminConsole", {
        rootTag: document.getElementById("root")
    });
}
