import {ContentViewModel,ContentView} from '@levelapp/softfabric';
import AvailabilityLogsTabViewState from './AvailabilityLogsTabViewState';
import AvailabilityLogsTabViewProps from './AvailabilityLogsTabViewProps';

export default class AvailabilityLogsTabViewModel extends ContentViewModel<AvailabilityLogsTabViewState, AvailabilityLogsTabViewProps>
{
      /* CONSTRUCTOR */
    constructor(view: ContentView)
    {
        super(view);

        this.initialState({}); // Initialize your state here

        // Bindings

    }
}