 
import { ContentView } from '@levelapp/softfabric';
import BoosterPopUpViewState from './BoosterPopUpViewState';
import BoosterPopUpViewProps from './BoosterPopUpViewProps';
import BoosterPopUpViewStyle from './BoosterPopUpViewStyle';
import BoosterPopUpViewModel from './BoosterPopUpViewModel';
import { View } from 'react-native';
import { TranslationView } from '@levelapp/softfabric-ui';
import Constants from '../../../../../../../Common/Constants/Constants';

import PopupMenuItemView from '../../../../components/popupmenuitem/PopupMenuItemView';
import PicturesView from '../../../../components/pictures/PicturesView';
import Helpers from '../../../../../../../Common/Helpers/Helpers';
import PopupHeaderView from '../../../../components/popupheader/PopupHeaderView';
import MainInformationView from './components/maininformation/MainInformationView';
import DescriptionView from '../../../../components/description/DescriptionView';
import LtoView from './components/lto/LtoView';
import GenericSettingsSectionView from '../../../../components/genericsettingssection/GenericSettingsSectionView';

export default class BoosterPopUpView extends ContentView<BoosterPopUpViewProps, BoosterPopUpViewState, BoosterPopUpViewModel, BoosterPopUpViewStyle>
{
  /* CONSTRUCTOR */
  constructor(props: BoosterPopUpViewProps) {
    super(props);

    // Binding
    this.bind(new BoosterPopUpViewModel(this), new BoosterPopUpViewStyle(this));
  }

  /* RENDERING */
  render() {
    return (
      <View style={{ minWidth: 600, minHeight: 850, width: Constants.PopUpWidth, maxWidth: "100%" }}>

        <PopupHeaderView
          handleBackPressed={() =>
            {
              this.props.refreshParent()
              Helpers.closePopup()
            }}
          title={"boosters.popup.title"}
          shouldShowButton
          isLoading={this.state.isLoading}
          handleOnPressButton={this.binding.handleSavePressed}
        />      

        <View style={{ display: "flex", flexDirection: "row", flexWrap: "wrap" }}>
          <PopupMenuItemView activeItem={this.state.tab} title={"form.maininformation"} item={"main"} handleChangeTab={() => this.binding.changeTab("main")}/>
          <PopupMenuItemView activeItem={this.state.tab} title={"form.description"} item={"desc"} handleChangeTab={() => this.binding.changeTab("desc")}/>
          <PopupMenuItemView activeItem={this.state.tab} title={"form.images"} item={"pictures"} handleChangeTab={() => this.binding.changeTab("pictures")}/>
          {this.state.booster.boosterType != null && this.state.booster.boosterType.title === "LimitedTimeOffer" &&
            <PopupMenuItemView activeItem={this.state.tab} title={"LTO"} item={"lto"} handleChangeTab={() => this.binding.changeTab("lto")}/>
          }
          {this.props.contentTypeKeys.length > 0 && <PopupMenuItemView activeItem={this.state.tab} title={"form.settings"} item={"settings"} handleChangeTab={() => this.binding.changeTab("settings")} />}

        </View>

        {this.state.tab == "main" &&
            <MainInformationView
              booster={this.state.booster}
              boosterTypes={this.state.boosterTypes}
              showErrors={this.state.showErrors}
              languages={this.state.languages}
              handleDropDownChange={this.binding.handleBoosterTypeChanged}
              handleCheckboxChange={this.binding._dispatcherManager.handleCheckboxChange}
              handleValueChange={this.binding._dispatcherManager.handleValueChange}
              handleTranslationsChange={this.binding._dispatcherManager.handleTranslationsChange}
            />
            
        }
        {this.state.tab == "desc" &&
          <DescriptionView
              listDesc={this.state.booster.descriptionTranslations}
              languages={this.state.languages}
              shouldShowError={this.state.showErrors}
              handleTranslationsChange={this.binding._dispatcherManager.handleTranslationsChange}
            />
         
        }

        {this.state.tab == "pictures" &&
            <PicturesView
            languages={this.props.languages}
            listImage={this.state.booster.imageTranslation}
            listThumbnail={this.state.booster.thumbnailTranslation}
            handleOnComplete={this.binding._dispatcherManager.handleAnyChange}
            handleOnPressImage={this.binding._dispatcherManager.handleNumberChange}
            handleCheckboxChange={this.binding._dispatcherManager.handleCheckboxChange}
            shouldUseDefaultImage={this.state.useDefaultImageChecked}
            shouldUseDefaultThumnail={this.state.useDefaultThumbnailChecked}
          />
        }

        {this.state.tab === 'lto' &&
          <LtoView
            booster={this.state.booster}
            showErrors={this.state.showErrors}
            languages={this.state.languages}
            posids={this.props.posIds}
            deleteLto={this.binding.deleteLTO}
            addLto={this.binding.addLTO}
            handleValueChange={this.binding._dispatcherManager.handleValueChange}
            handleDateChange={this.binding._dispatcherManager.handleDateChange}
            handleTitleLTOChanged={this.binding.handleTitleLTOChanged}

          />
        }

        {this.state.tab == "settings" && 
          <GenericSettingsSectionView
          onKeyValueChange={this.binding._dispatcherManager.handleAnyChange}
          contentTypeKeys={this.binding.getContentTypeKeys()}
          contentTypeKeyValues={this.props.booster.contentTypeKeyValue}/>
        }

        {this.state.showErrors &&
          < View style={this.style.footer} >
            <TranslationView style={{ color: "red" }}>error.please.fields</TranslationView>
          </View>
        }
      </View>
    );
  }
}