import { Operation } from "@levelapp/softfabric";
import HistoryService from "../Proxy/HistoryService";

export default class HistoryOperations extends Operation
{
    service : HistoryService;

    constructor()
    {
        super();
        this.service = new HistoryService();
    }

    get(parameters : any[], callback : Function)
    {
        this.service.get(parameters, callback);
    }
}