import {ContentShellViewModel, ContentShellView} from '@levelapp/softfabric';
import ShellLoyaltyRulesViewState from './ShellLoyaltyRulesViewState';
import ShellLoyaltyRulesViewProps from './ShellLoyaltyRulesViewProps';
import EnumModules from '../../../../../../Common/Constants/EnumModules';
import LoyaltyRulesView from './loyalty/LoyaltyRulesView';
import LoyaltyRulesV2 from './loyalty-v2/LoyaltyRulesV2';
 

export default class ShellLoyaltyRulesViewModel extends ContentShellViewModel<ShellLoyaltyRulesViewState, ShellLoyaltyRulesViewProps>
{
    constructor(view : ContentShellView)
    {
        super(view);

        this.registerEntryPoint('main');
        this.register(process.env.REACT_APP_ISLOYALTYRULES_V2 === 'true' ? LoyaltyRulesV2 : <LoyaltyRulesView />, 'main');

        this.initialisation();
    }

    getModuleId()
    {
        return EnumModules.LOYALTY_RULES;
    }
}