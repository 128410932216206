import { Operation } from "@levelapp/softfabric";
import AvailabilityHistoryService from "src/Proxy/AvailabilityHistoryService";

export default class RewardTagOperations extends Operation {
  service: AvailabilityHistoryService;

  constructor() {
    super();
    this.service = new AvailabilityHistoryService();
  }
  
  get(productId: number) {
    return this.service.get(productId);
  }
}