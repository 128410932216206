 
import Select from 'react-dropdown-select';
import { TextInput, View } from 'react-native';

import ButtonView from 'src/Common/Components/Button/ButtonView';
import Constants from 'src/Common/Constants/Constants';

import { TranslationView } from '@levelapp/softfabric-ui';
import { ContentView } from '@levelapp/softfabric';

import AddZoneViewState from './AddZoneViewState';
import AddZoneViewProps from './AddZoneViewProps';
import AddZoneViewStyle from './AddZoneViewStyle';
import AddZoneViewModel from './AddZoneViewModel';
import DropdownItemView from '../../productstool/pages/productstool/dropdownitem/DropdownItemView';
import DropdownContentView from '../../productstool/pages/productstool/dropdowncontent/DropdownContentView';

export default class AddZoneView extends ContentView<AddZoneViewProps, AddZoneViewState, AddZoneViewModel, AddZoneViewStyle>
{
  /* CONSTRUCTOR */
  constructor(props: AddZoneViewProps) {
    super(props);

    // Binding
    this.bind(new AddZoneViewModel(this), new AddZoneViewStyle(this));
  }

  /* RENDERING */
  render() {
    const { storeGroup } = this.props;
    const { style } = this;
    const {
      inputZone,
      search,
      showError,
      stores,
      selectedStores,
      storesAreLoading,
      isSaving,
    } = this.state;

    return (
      <View style={style.container}>
        <View style={style.content}>
          <TranslationView style={style.title}>
            {storeGroup ? 'tools.zone.editZoneTitle' : 'tools.zone.addZoneTitle'}
          </TranslationView>
          <View>
            <TranslationView style={style.inputLabel}>
              tools.zone.add.inputLabel
            </TranslationView>
            <TextInput
              value={inputZone}
              onChangeText={this.binding.onInputZoneChange}
              style={[style.input, { borderColor: '#DCCDCD' }]}
            />
            <TranslationView style={{ color: 'red', visibility: showError ? 'visible' : 'hidden' }}>
              tools.popup.error
            </TranslationView>


            <TranslationView style={style.inputLabel}>
              {storeGroup
                ? 'tools.zoneGroup.edit.selectedZones'
                : 'tools.zoneGroup.add.selectedZones'
              }
            </TranslationView>

            <Select
              style={{ minHeight: 44, maxHeight: 152 }}
              values={selectedStores}
              multi
              options={stores
                .map((p) => ({
                  id: p.id!,
                  name: this.binding.getStoreTranslation(p),
                  itemGroupId: p.storeGroupId,
                }))
                .filter((a) => a.name.toLocaleLowerCase().includes(search.toLocaleLowerCase()))
                .sort((a, b) => a.name.localeCompare(b.name))}
              valueField="id"
              labelField="name"
              onChange={(e) => this.binding.updateStores(e)}
              loading={storesAreLoading}
              disabled={storesAreLoading}
              color={Constants.MainColor}
              searchable
              searchBy="name"
              itemRenderer={({ item, methods }) => (
                <DropdownItemView
                  item={item}
                  methods={methods}
                  itemGroupId={storeGroup?.id}
                  clearSearch={this.binding.clearSearch}
                />
              )}
              contentRenderer={({ props, methods }) => (
                <DropdownContentView
                  props={props}
                  methods={methods}
                  onSearchChange={this.binding.onSearchChange}
                  search={search}
                />
              )}
            />
          </View>
        </View>

        <View style={style.buttons}>
          <ButtonView
            isLoading={isSaving}
            handleClick={() => this.binding.onAddZone(inputZone, storeGroup)}
            backgroundColor={Constants.MainColor}
            style={style.addButton}
          >
            <TranslationView>
              {storeGroup ? 'tools.zone.popup.save' : 'tools.zone.popup.add'}
            </TranslationView>
          </ButtonView>
          <ButtonView
            isLoading={false}
            handleClick={this.binding.onPopupClose}
            textColor={Constants.MainColor}
            backgroundColor="transparent"
            style={style.cancelButton}
          >
            <TranslationView>
              tools.zone.popup.cancel
            </TranslationView>
          </ButtonView>
        </View>
      </View>
    );
  }
}