 
import {ContentShellView} from '@levelapp/softfabric';
import ShellTicketsViewProps from './ShellTicketsViewProps';
import ShellTicketsViewModel from './ShellTicketsViewModel';
import ShellTicketsViewStyle from './ShellTicketsViewStyle';
import ShellTicketsViewState from './ShellTicketsViewState';
import { View } from 'react-native';

export default class ShellTicketsView extends ContentShellView<ShellTicketsViewProps, ShellTicketsViewState, ShellTicketsViewModel, ShellTicketsViewStyle>
{
  /* CONSTRUCTOR */
  constructor(props:ShellTicketsViewProps)
  {
    super(props);

    // Binding
    this.bind(new ShellTicketsViewModel(this), new ShellTicketsViewStyle(this));
  }

  /* RENDERING */
  render()
  {
    return (
      <View style={{flex : 1}}>
        {this.getSwitch()}
      </View>
    );
  }
}