import { View, Button } from "react-native";
import { ContentView } from "@levelapp/softfabric";
import BasketordertypeViewState from './BasketordertypeViewState';
import BasketordertypeViewProps from './BasketordertypeViewProps';
import BasketordertypeViewStyle from "./BasketordertypeViewStyle";
import BasketordertypeViewModel from "./BasketordertypeViewModel";
import { TranslationView } from "@levelapp/softfabric-ui";
import LineView from "../../../../../../components/line/LineView";
import CheckBoxHeaderSelectionView from "../../../../components/checkboxheaderselection/CheckBoxHeaderSelectionView";


export default class BasketordertypeView extends ContentView<BasketordertypeViewProps, BasketordertypeViewState, BasketordertypeViewModel, BasketordertypeViewStyle>
{
  /* CONSTRUCTOR */
  constructor(props: BasketordertypeViewProps) {
    super(props);

    // Binding
    this.bind(new BasketordertypeViewModel(this), new BasketordertypeViewStyle(this));
  }

  /* RENDERING */
  render() {

    const { container, bodyTitle } = this.style;

    return (
      <View style={container}>
        <CheckBoxHeaderSelectionView
          handleCheckBoxChanged={this.binding.handleCheckboxBasketOrderTypeChange}
          items={this.props.basketOrderTypes}
          allItems={this.props.allBasketOrderTypes}
          isLoading={this.props.isLoading}
        />
        <LineView />
        <TranslationView upperCase style={bodyTitle}>
          basketOrdertype.body.label
        </TranslationView>
        <View style={{ flexDirection: "row" }}>
          <Button
            // disabled={this.state.isLoadingProperties}
            title={this.binding.translate('basketOrderType.body.button')}
            onPress={() => {
              this.binding.showGlobalBasketOrderTypesManagementPopup();
            }}
          />
        </View>
      </View>
    );
  }
}