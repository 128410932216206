import {ContentViewModel,ContentView} from '@levelapp/softfabric';
import AdditionalInformationsListViewState from './AdditionalInformationsListViewState';
import AdditionalInformationsListViewProps from './AdditionalInformationsListViewProps';

export default class AdditionalInformationsListViewModel extends ContentViewModel<AdditionalInformationsListViewState, AdditionalInformationsListViewProps>
{
      /* CONSTRUCTOR */
    constructor(view: ContentView)
    {
        super(view);

        this.initialState({}); // Initialize your state here

        // Bindings

    }
}