import {ContentViewStyle} from '@levelapp/softfabric';
import {StyleProp,TextStyle,ViewStyle} from 'react-native';

export default class ExtendedClickableTextViewStyle extends ContentViewStyle
{
    page: StyleProp<ViewStyle> = {
        flex: 1
    }

    sectionId: StyleProp<ViewStyle> = {
        alignSelf: 'flex-start',
        paddingRight : 15,
        paddingBottom : 15,
    };

    text: StyleProp<TextStyle> = {
        textDecorationLine : 'underline',
        color : '#2f69ca'
    };


    errorTextField: StyleProp<ViewStyle> = {
        borderColor: 'red',
        borderWidth: 1,
        width: 140,
        paddingHorizontal: 15,
        borderRadius : 8,
    };
}