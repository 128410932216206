 
import { View, Text } from 'react-native';
import { format, parseISO } from 'date-fns';
import { fr } from 'date-fns/locale'

import { ContentView } from '@levelapp/softfabric';

import PriceLogsTabViewState from './PriceLogsTabViewState';
import PriceLogsTabViewProps from './PriceLogsTabViewProps';
import PriceLogsTabViewStyle from './PriceLogsTabViewStyle';
import PriceLogsTabViewModel from './PriceLogsTabViewModel';
import NoDataPlaceholderView from '../nodataplaceholder/NoDataPlaceholderView';
import LogTabHeaderView from '../logtabheader/LogTabHeaderView';

export default class PriceLogsTabView extends ContentView<PriceLogsTabViewProps, PriceLogsTabViewState, PriceLogsTabViewModel, PriceLogsTabViewStyle>
{
  /* CONSTRUCTOR */
  constructor(props: PriceLogsTabViewProps) {
    super(props);

    // Binding
    this.bind(new PriceLogsTabViewModel(this), new PriceLogsTabViewStyle(this));
  }

  /* RENDERING */
  render() {
    const { priceHistories } = this.props;

    return (
      <>
        {priceHistories.length > 0 && (
          <View style={this.style.content}>
            <LogTabHeaderView />
            {priceHistories.map((history) => (
              <View style={this.style.row}>
                <Text style={this.style.text}>{format(parseISO(history.date), "P", { locale: fr })}</Text>
                <Text style={this.style.text}>{history.locations}</Text>
                <Text style={this.style.text}>{history.orderSources}</Text>
                <Text style={this.style.text}>{history.oldPrice ? `${history.oldPrice} €` : 'Unset'}</Text>
                <Text style={this.style.text}>{history.newPrice ? `${history.newPrice} €` : 'Unset'}</Text>
              </View>
            ))}
          </View>
        )}
        {priceHistories.length <= 0 && (
          <NoDataPlaceholderView />
        )}
      </>
    );
  }
}