import {ContentViewModel,ContentView} from '@levelapp/softfabric';
import MainInformationViewState from './MainInformationViewState';
import MainInformationViewProps from './MainInformationViewProps';
import PromoCode from '../../../../../../../../../Common/DTO/PromoCode';
import ConfirmPopUpView from '../../../../../../../../../Common/Components/confirmpopup/ConfirmPopUpView';
import ToastHelper from '../../../../../../../../../Common/Helpers/ToastHelper';
import PromoCodesOperations from '../../../../../../../../../Transfer/PromoCodesOperations';
import { TableRow } from '@levelapp/softfabric-ui';
 
import ErrorHelper from '../../../../../../../../../Common/Helpers/ErrorHelper';

export default class MainInformationViewModel extends ContentViewModel<MainInformationViewState, MainInformationViewProps>
{
    _refEndDate : any;
    operation: PromoCodesOperations;

    /* CONSTRUCTOR */
    constructor(view: ContentView)
    {
        super(view);
        this.operation = new PromoCodesOperations();
        this.initialState({ tableData: [], tableHeaders: ['Id', 'Code'], isLoading: true, nbPage: 0, currentPage: 1 }); // Initialize your state here
        // Bindings
        this.handleOnChangeDate = this.handleOnChangeDate.bind(this);
        this.errorManagement = this.errorManagement.bind(this);

    }

    componentDidMount() {
        this.refresh();
    }

    refresh() {
        this.setField({ isLoading: true });
        this.loadData();
    }

    handleOnChangeDate(type : 'startdate' | 'enddate' , value : Date)
    {
        this._refEndDate.getInnerRef().input.focus();
        this.props().handleDateChange(type,value);
    }

    loadData()
    {
        let tableData: TableRow[] = [];
        if(this.props().promocodebatch.promoCodes !== undefined)
        {
            this.props().promocodebatch.promoCodes.forEach((promocode: PromoCode) => {
                tableData.push({ data: [
                    promocode.id, 
                    promocode.code],
                    isDeletable: true, 
                    isEditable: false,
                    deleteCallback: () => { this.deletePromoCode(promocode) } 
                });
            });
        }
        this.setField({ tableData: tableData, isLoading: false });
    }

    onPageChange(page: number) {
        this.state().currentPage = page + 1;
        this.refresh()
    }

    onSortChange() {
        this.refresh();
    }

    deletePromoCode(promocode: PromoCode) {
        this.showPopUp(
            <ConfirmPopUpView callBack={() => {
                this.operation.delete(promocode, () => {
                    this.showToast(ToastHelper.getToast("promocodes.popup.success.deleted","success"),() => {},2500);
                    this.props().refresh();
                },this.errorManagement);
            }} />
        );
    }

    errorManagement(error : any) 
    {
        this.setField({isLoading : false});
        ErrorHelper.showMessageError(error.response);
    }
}