import { ContentView } from '@levelapp/softfabric';
import ResearchInputViewState from './ResearchInputViewState';
import ResearchInputViewProps from './ResearchInputViewProps';
import ResearchInputViewStyle from './ResearchInputViewStyle';
import ResearchInputViewModel from './ResearchInputViewModel';
import { View, TextInput } from 'react-native';
import ImageButton from '../../Components/imagebutton/ImageButtonView'

export default class ResearchInputView extends ContentView<ResearchInputViewProps, ResearchInputViewState, ResearchInputViewModel, ResearchInputViewStyle>
{

  /* CONSTRUCTOR */
  constructor(props: ResearchInputViewProps) {
    super(props);

    // Binding
    this.bind(new ResearchInputViewModel(this), new ResearchInputViewStyle(this));
  }

  /* RENDERING */
  render() {
    return (
      <View style={this.style.input}>
        <View style={this.style.containerInput}>
          <View style={[this.style.inputView, this.props.inputStyle]}>
            <TextInput value={this.state.mySearchValue} onChangeText={this.binding.handleSearchValueChanged} placeholder={this.props.placeholder} />
          </View>
          <ImageButton image={require('./assets/erase.svg').default} imageWidth={22} imageHeight={22} onClick={() => { this.binding.handleResetPressed() }} style={this.style.button} />
        </View>

      </View>
    );
  }
}