import {ContentViewModel,ContentView} from '@levelapp/softfabric';
import HeaderViewState from './HeaderViewState';
import HeaderViewProps from './HeaderViewProps';

export default class HeaderViewModel extends ContentViewModel<HeaderViewState, HeaderViewProps>
{
      /* CONSTRUCTOR */
    constructor(view: ContentView)
    {
        super(view);

        this.initialState({}); // Initialize your state here

        // Bindings

    }
}