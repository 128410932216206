import {ContentShellViewModel, ContentShellView} from '@levelapp/softfabric';
import ShellOverviewViewState from './ShellOverviewViewState';
import ShellOverviewViewProps from './ShellOverviewViewProps';
 
import EnumModules from '../../../../../Common/Constants/EnumModules';
import OverviewView from './pages/overview/OverviewView';
import OverviewV2 from './pages/overview-v2/OverviewV2';

export default class ShellOverviewViewModel extends ContentShellViewModel<ShellOverviewViewState, ShellOverviewViewProps>
{

    constructor(view : ContentShellView)
    {
        super(view);

        this.registerEntryPoint('home'); 
        this.register(process.env.REACT_APP_ISOVERVIEW_V2 === 'true' ? OverviewV2 : <OverviewView/>, 'home');

        this.initialisation();
    }

    getModuleId()
    {
        return EnumModules.OVERVIEW;
    }

}