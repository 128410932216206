
import { TouchableWithoutFeedback, View, StyleSheet } from 'react-native';
import SectionTitleView from '../../../../../../components/sectiontitle/SectionTitleView';
import { LottieView, StyleManager, TranslationView, UiManagers } from '@levelapp/softfabric-ui';
import Constants from '../../../../../../../../../Common/Constants/Constants';
import Select from 'react-dropdown-select';
import Store from '../../../../../../../../../Common/DTO/Store';
import { Application, ContentViewProps } from '@levelapp/softfabric';
import { useMemo } from 'react';


interface StoresViewProps extends ContentViewProps {
  isLoading: boolean;
  stores: Store[];
  selectedIds: number[];
  handleValueChange: (value: Store[]) => void;
}

const StoresView = ({
  isLoading,
  selectedIds,
  stores,
  handleValueChange
}: StoresViewProps) => {

  const myStyleManager: StyleManager = Application.current.resolve<StyleManager>(UiManagers.STYLE_MANAGER);

  const selectedStores = useMemo(() => (
    selectedIds.map(x => stores.find((st) => st.id === x))
  ), [selectedIds, stores]);

  const addAllStores = () => {
    handleValueChange(stores);
  };

  return (
    <View style={styles.container}>
      <SectionTitleView title={'form.stores'} />
      {isLoading ? (
        <LottieView height={150} width={150} source={require("../../../../../../../../../Common" + Constants.LottieAnimationPath)} />
      ) :
        <View>
          <Select
            placeholder="Select stores"
            color={Constants.MainColor}
            values={selectedStores.sort((x1, x2) => x1!.slug.localeCompare(x2!.slug)) as Store[]}
            options={stores.sort((x1, x2) => x1.slug.localeCompare(x2.slug))}
            onChange={handleValueChange}
            multi={true}
            create={false}
            keepSelectedInList={false}
            clearable={true}
            closeOnSelect={true}
            labelField={"slug"}
            valueField={"id"}
            searchBy={"slug"}
          />
          <TouchableWithoutFeedback onPress={addAllStores}>
            <View>
              <TranslationView style={[
                styles.addAllStoresLabel,
                { color: myStyleManager.primaryColor }
              ]}>
                coupons.stores.addall
              </TranslationView>
            </View>
          </TouchableWithoutFeedback>

          {!selectedIds.length && <TranslationView>coupons.stores.warningnothingenabled</TranslationView>}
        </View>
      }
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    display: "flex",
    flexDirection: "column",
    paddingHorizontal: 170,
    paddingVertical: 50
  },
  addAllStoresLabel: {
    fontSize: 10,
    marginTop: 20,
    fontWeight: '600',
    height: 27,
    width: 112
  }
});

export default StoresView;