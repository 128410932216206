
export default class DispatcherManager {
    checkboxMarketingAutomation: ((value: boolean, index?: number) => void) | undefined;
    checkboxAnonymous: ((value: boolean, index?: number) => void) | undefined;
    checkboxPreview: ((value: boolean, index?: number) => void) | undefined;
    checkboxRenewable: ((value: boolean, index?: number) => void) | undefined;
    checkboxHasDuration: ((value: boolean, index?: number) => void) | undefined;
    checkboxDefaultImage: ((value: boolean, index?: number) => void) | undefined;
    checkboxDefaultSecondaryImage: ((value: boolean, index?: number) => void) | undefined;
    checkboxDefaultThumbnail: ((value: boolean, index?: number) => void) | undefined;
    checkboxDefaultImageStamped: ((value: boolean, index?: number) => void) | undefined;
    checkboxDefaultImageUnstamped: ((value: boolean, index?: number) => void) | undefined;
    checkboxMarketAutomation: ((value: boolean, index?: number) => void) | undefined;
    checkboxInbox: ((value: boolean, index?: number) => void) | undefined;
    checkboxHome: ((value: boolean, index?: number) => void) | undefined;
    checkboxList: ((value: boolean, index?: number) => void) | undefined;
    checkboxIsMandatory: ((value: boolean, index?: number) => void) | undefined;
    checkboxSingleCode: ((value: boolean, index?: number) => void) | undefined;
    checkboxIsPspProd: ((value: boolean, index?: number) => void) | undefined;
    checkboxIsEmail: ((value: boolean, index?: number) => void) | undefined;
    checkboxIsPushNotification: ((value: boolean, index?: number) => void) | undefined;
    checkboxIsInApp: ((value: boolean, index?: number) => void) | undefined;
    checkboxIsFreeProduct: ((value: boolean, index?: number) => void) | undefined;
    checkBoxIsMultipleScan: ((value: boolean, index?: number) => void) | undefined;
    checkBoxIsExclusive: ((value: boolean, index?: number) => void) | undefined;
    checkboxIsLanguageDefault: ((value: boolean, index?: number) => void) | undefined;
    checkboxIsLanguageActive: ((value: boolean, index?: number) => void) | undefined;
    checkboxIsShownInCrossSell: ((value: boolean, index?: number) => void) | undefined;
    checkBoxIsPriotity : ((value: boolean, index?: number) => void) | undefined;
    checkboxIsRenewableWithCooldown: ((value: boolean, index?: number) => void) | undefined;

    valueCode: ((value: string, index?: number) => void) | undefined;
    valueOrderIndex: ((value: string, index?: number) => void) | undefined;
    valueSecondaryOrderIndex: ((value: string, index?: number) => void) | undefined;
    valueThirdPartId: ((value: string, index?: number) => void) | undefined;
    valuePosId: ((value: string, index?: number) => void) | undefined;
    valuePointsToEarn: ((value: string, index?: number) => void) | undefined;
    valueOrderIndexPriority :  ((value: string, index?: number) => void) | undefined;
    valuePoints: ((value: string, index?: number) => void) | undefined;
    valueFirstname: ((value: string, index?: number) => void) | undefined;
    valueLastname: ((value: string, index?: number) => void) | undefined;
    valueProductId: ((value: string, index?: number) => void) | undefined;
    valueRequiredProductId: ((value: string, index?: number) => void) | undefined;
    valueDurationId: ((value: string, index?: number) => void) | undefined;
    valueConsumptionId: ((value: string, index?: number) => void) | undefined;
    valueStampQuantity: ((value: string, index?: number) => void) | undefined;
    valueValidityDuration: ((value: string, index?: number) => void) | undefined;
    valueQuantityMax: ((value: string, index?: number) => void) | undefined;
    valueQuantityMin: ((value: string, index?: number) => void) | undefined;
    valueCountry: ((value: any, index?: number) => void) | undefined;
    valueStore: ((value: any, index?: number) => void) | undefined;
    valueValue: ((value: any, index?: number) => void) | undefined;
    valueMinimum: ((value: any, index?: number) => void) | undefined;
    valueNumber: ((value: any, index?: number) => void) | undefined;
    valuePromoCode: ((value: any, index?: string) => void) | undefined;
    valuePspKey: ((value: any, index?: number) => void) | undefined;
    valuePspKeyDev: ((value: any, index?: number) => void) | undefined;
    valuePspKey2: ((value: any, index?: number) => void) | undefined;
    valuePspKey2Dev: ((value: any, index?: number) => void) | undefined;
    valueLanguageKey: ((value: string, index?: number) => void) | undefined;
    valueLoyaltyCardCode: ((value: string, index?: number) => void) | undefined;
    valueEmail: ((value: string, index?: number) => void) | undefined;
    valueArrayPosId: ((value: any[], index?: number) => void) | undefined;
    valueFreeProductIds: ((value: any, index?: number) => void) | undefined;
    valueRequiredProductIds: ((value: any, index?: number) => void) | undefined;

    dateStartDate: ((value: Date, index?: number) => void) | undefined;
    dateEndDate: ((value: Date, index?: number) => void) | undefined;

    translationURL: ((value: string, index: number) => void) | undefined;
    translationLink: ((value: string, index: number) => void) | undefined;
    translationTitle: ((value: string, index: number) => void) | undefined;
    translationSecondaryTitle: ((value: string, index: number) => void) | undefined;
    translationTitleLTO: ((value: string, index: number) => void) | undefined;
    translationDescription: ((value: string, index: number) => void) | undefined;
    translationSecondaryDescription: ((value: string, index: number) => void) | undefined;
    translationMessage: ((value: string, index: number) => void) | undefined;

    numberDuration: ((value: number, index?: number) => void) | undefined;
    numberIndexImage: ((value: number, index?: number) => void) | undefined;
    numberIndexSecondaryImage: ((value: number, index?: number) => void) | undefined;
    numberIndexThumbnail: ((value: number, index?: number) => void) | undefined;
    numberIndexStamped: ((value: number, index?: number) => void) | undefined;
    numberIndexUnstamped: ((value: number, index?: number) => void) | undefined;

    uriImage: ((value: any, index?: number) => void) | undefined;
    uriSecondaryImage: ((value: any, index?: number) => void) | undefined;
    uriThumbnail: ((value: any, index?: number) => void) | undefined;
    uriStamped: ((value: any, index?: number) => void) | undefined;
    uriUnstamped: ((value: any, index?: number) => void) | undefined;

    contentTypeKeyValue: ((value: any, index: number) => void) | undefined;

    constructor() {
        this.handleAnyChange = this.handleAnyChange.bind(this);
        this.handleDateChange = this.handleDateChange.bind(this);
        this.handleValueChange = this.handleValueChange.bind(this);
        this.handleNumberChange = this.handleNumberChange.bind(this);
        this.handleCheckboxChange = this.handleCheckboxChange.bind(this);
        this.handleTranslationsChange = this.handleTranslationsChange.bind(this);
    }

    private isUndefined(f: Function | undefined) {
        if (f == undefined) {
            alert('A Function in Dispatcher has not been set');
            return true;
        }

        return false;
    }

    handleCheckboxChange(type:
        'isAnonymousVisible' | 'isRenewable' | 'isDefaultImage' | 'isDefaultThumbnail' |
        'isInbox' | 'isHome' | 'isList' | 'isPspProd' | 'isMandatory' | 'isMarketingAutomation' |
        'isDefaultStamped' | 'isDefaultUnstamped' | 'isPreviewVisible' | 'isEmail' | 'hasDuration' |
        'isPushNotification' | 'isInApp' | 'isSingleCode' | 'isFreeProduct' | 'isLanguageDefault' | 'isLanguageActive' | 'isShownInCrossSell' |
        'isMarketAutomation' | 'isMultipleScan' | 'isDefaultSecondaryImage' | 'isExclusive' | 'isPriority' | 'isRenewableWithCooldown'

        , value: boolean, index: number = -1) {
        switch (type) {
            case 'isAnonymousVisible':
                !this.isUndefined(this.checkboxAnonymous) && (this.checkboxAnonymous as Function)(value, index);
                break;
            case 'isRenewable':
                !this.isUndefined(this.checkboxRenewable) && (this.checkboxRenewable as Function)(value, index);
                break;
            case 'hasDuration':
                !this.isUndefined(this.checkboxHasDuration) && (this.checkboxHasDuration as Function)(value, index);
                break;
            case 'isMarketingAutomation':
                !this.isUndefined(this.checkboxMarketingAutomation) && (this.checkboxMarketingAutomation as Function)(value, index);
                break;
            case 'isDefaultImage':
                !this.isUndefined(this.checkboxDefaultImage) && (this.checkboxDefaultImage as Function)(value, index);
                break;
            case 'isDefaultSecondaryImage':
                !this.isUndefined(this.checkboxDefaultSecondaryImage) && (this.checkboxDefaultSecondaryImage as Function)(value, index);
                break;
            case 'isDefaultThumbnail':
                !this.isUndefined(this.checkboxDefaultThumbnail) && (this.checkboxDefaultThumbnail as Function)(value, index);
                break;
            case 'isDefaultStamped':
                !this.isUndefined(this.checkboxDefaultImageStamped) && (this.checkboxDefaultImageStamped as Function)(value, index);
                break;
            case 'isDefaultUnstamped':
                !this.isUndefined(this.checkboxDefaultImageUnstamped) && (this.checkboxDefaultImageUnstamped as Function)(value, index);
                break;
            case 'isInbox':
                !this.isUndefined(this.checkboxInbox) && (this.checkboxInbox as Function)(value, index);
                break;
            case 'isHome':
                !this.isUndefined(this.checkboxHome) && (this.checkboxHome as Function)(value, index);
                break;
            case 'isList':
                !this.isUndefined(this.checkboxList) && (this.checkboxList as Function)(value, index);
                break;
            case 'isMandatory':
                !this.isUndefined(this.checkboxIsMandatory) && (this.checkboxIsMandatory as Function)(value, index);
                break;
            case 'isPreviewVisible':
                !this.isUndefined(this.checkboxPreview) && (this.checkboxPreview as Function)(value, index);
                break;
            case 'isSingleCode':
                !this.isUndefined(this.checkboxSingleCode) && (this.checkboxSingleCode as Function)(value, index);
                break;
            case 'isPspProd':
                !this.isUndefined(this.checkboxIsPspProd) && (this.checkboxIsPspProd as Function)(value, index);
                break;
            case 'isEmail':
                !this.isUndefined(this.checkboxIsEmail) && (this.checkboxIsEmail as Function)(value, index);
                break;
            case 'isPushNotification':
                !this.isUndefined(this.checkboxIsPushNotification) && (this.checkboxIsPushNotification as Function)(value, index);
                break;
            case 'isInApp':
                !this.isUndefined(this.checkboxIsInApp) && (this.checkboxIsInApp as Function)(value, index);
                break;
            case 'isFreeProduct':
                !this.isUndefined(this.checkboxIsFreeProduct) && (this.checkboxIsFreeProduct as Function)(value, index);
                break;
            case 'isLanguageDefault':
                !this.isUndefined(this.checkboxIsLanguageDefault) && (this.checkboxIsLanguageDefault as Function)(value, index);
                break;
            case 'isLanguageActive':
                !this.isUndefined(this.checkboxIsLanguageActive) && (this.checkboxIsLanguageActive as Function)(value, index);
                break;
            case 'isShownInCrossSell':
                !this.isUndefined(this.checkboxIsShownInCrossSell) && (this.checkboxIsShownInCrossSell as Function)(value, index);
                break;
            case 'isMarketAutomation':
                !this.isUndefined(this.checkboxMarketAutomation) && (this.checkboxMarketAutomation as Function)(value, index);
                break;
            case 'isMultipleScan':
                !this.isUndefined(this.checkBoxIsMultipleScan) && (this.checkBoxIsMultipleScan as Function)(value, index);
                break;
            case 'isExclusive':
                !this.isUndefined(this.checkBoxIsExclusive) && (this.checkBoxIsExclusive as Function)(value, index);
                break;
            case 'isPriority':
                !this.isUndefined(this.checkBoxIsPriotity) && (this.checkBoxIsPriotity as Function)(value, index);
                break;
            case 'isRenewableWithCooldown':
                !this.isUndefined(this.checkboxIsRenewableWithCooldown) && (this.checkboxIsRenewableWithCooldown as Function)(value, index);
                break;
            default:
                this.isUndefined(undefined);
        }
    }

    handleValueArrayChange(type: 'posid', value: any[]) {
        switch (type) {
            case 'posid':
                !this.isUndefined(this.valueArrayPosId) && (this.valueArrayPosId as Function)(value);
                break;
            default:
                this.isUndefined(undefined);
        }
    }

    handleValueChange(type: 'tpid' | 'posid' | 'orderindex' | 'orderindexpriority' | 'secondaryOrderindex' | 'pointstoearn' |
        'pspkey' | 'pspkeyDev' | 'pspkey2' | 'pspkey2Dev' |
        'points' | 'code' | 'productId' | 'requiredProductId' | 'consumptionId' | 'stampquantity' | 'country' | 'store' |
        'validityduration' | 'durationunit' | 'firstname' | 'lastname' | 'quantityMax' | 'quantityMin' | 'value' |
        'number' | 'promocode' | 'valueMinimum' | 'languageKey' | 'loyaltycardCode' | 'email' | 'freeProductIds' | 'requiredProductIds', value: any, index: number = -1) {

        switch (type) {
            case 'pspkey':
                !this.isUndefined(this.valuePspKey) && (this.valuePspKey as Function)(value, index);
                break;
            case 'pspkeyDev':
                !this.isUndefined(this.valuePspKeyDev) && (this.valuePspKeyDev as Function)(value, index);
                break;
            case 'pspkey2':
                !this.isUndefined(this.valuePspKey2) && (this.valuePspKey2 as Function)(value, index);
                break;
            case 'pspkey2Dev':
                !this.isUndefined(this.valuePspKey2Dev) && (this.valuePspKey2Dev as Function)(value, index);
                break;
            case 'tpid':
                !this.isUndefined(this.valueThirdPartId) && (this.valueThirdPartId as Function)(value, index);
                break;
            case 'orderindex':
                !this.isUndefined(this.valueOrderIndex) && (this.valueOrderIndex as Function)(value, index);
                break;
            case 'orderindexpriority':
                !this.isUndefined(this.valueOrderIndexPriority) && (this.valueOrderIndexPriority as Function)(value, index);
                break;
            case 'secondaryOrderindex':
                !this.isUndefined(this.valueSecondaryOrderIndex) && (this.valueSecondaryOrderIndex as Function)(value, index);
                break;
            case 'posid':
                !this.isUndefined(this.valuePosId) && (this.valuePosId as Function)(value, index);
                break;
            case 'pointstoearn':
                !this.isUndefined(this.valuePointsToEarn) && (this.valuePointsToEarn as Function)(value, index);
                break;
            case 'points':
                !this.isUndefined(this.valuePoints) && (this.valuePoints as Function)(value, index);
                break;
            case 'code':
                !this.isUndefined(this.valueCode) && (this.valueCode as Function)(value, index);
                break;
            case 'productId':
                !this.isUndefined(this.valueProductId) && (this.valueProductId as Function)(value, index);
                break;
            case 'requiredProductId':
                !this.isUndefined(this.valueRequiredProductId) && (this.valueRequiredProductId as Function)(value, index);
                break;
            case 'consumptionId':
                !this.isUndefined(this.valueConsumptionId) && (this.valueConsumptionId as Function)(value, index);
                break;
            case 'stampquantity':
                !this.isUndefined(this.valueStampQuantity) && (this.valueStampQuantity as Function)(value, index);
                break;
            case 'validityduration':
                !this.isUndefined(this.valueValidityDuration) && (this.valueValidityDuration as Function)(value, index);
                break;
            case 'durationunit':
                !this.isUndefined(this.valueDurationId) && (this.valueDurationId as Function)(value, index);
                break;
            case 'firstname':
                !this.isUndefined(this.valueFirstname) && (this.valueFirstname as Function)(value, index);
                break;
            case 'lastname':
                !this.isUndefined(this.valueLastname) && (this.valueLastname as Function)(value, index);
                break;
            case 'quantityMax':
                !this.isUndefined(this.valueQuantityMax) && (this.valueQuantityMax as Function)(value, index);
                break;
            case 'quantityMin':
                !this.isUndefined(this.valueQuantityMin) && (this.valueQuantityMin as Function)(value, index);
                break;
            case 'country':
                !this.isUndefined(this.valueCountry) && (this.valueCountry as Function)(value, index);
                break;
            case 'store':
                !this.isUndefined(this.valueStore) && (this.valueStore as Function)(value, index);
                break;
            case 'value':
                !this.isUndefined(this.valueValue) && (this.valueValue as Function)(value, index);
                break;
            case 'valueMinimum':
                !this.isUndefined(this.valueMinimum) && (this.valueMinimum as Function)(value, index);
                break;
            case 'number':
                !this.isUndefined(this.valueNumber) && (this.valueNumber as Function)(value, index);
                break;
            case 'promocode':
                !this.isUndefined(this.valuePromoCode) && (this.valuePromoCode as Function)(value, index);
                break;
            case 'languageKey':
                !this.isUndefined(this.valueLanguageKey) && (this.valueLanguageKey as Function)(value, index);
                break;
            case 'freeProductIds':
                !this.isUndefined(this.valueFreeProductIds) && (this.valueFreeProductIds as Function)(value, index);
                break;
            case 'requiredProductIds':
                !this.isUndefined(this.valueRequiredProductIds) && (this.valueRequiredProductIds as Function)(value, index);
                break;
            case 'loyaltycardCode':
                !this.isUndefined(this.valueLoyaltyCardCode) && (this.valueLoyaltyCardCode as Function)(value, index);
                break;
            case 'email':
                !this.isUndefined(this.valueEmail) && (this.valueEmail as Function)(value, index);
                break;
            default:
                this.isUndefined(undefined);
        }
    }

    handleDateChange(type: 'startdate' | 'enddate', value: Date, index: number = -1) {
        switch (type) {
            case 'startdate':
                !this.isUndefined(this.dateStartDate) && (this.dateStartDate as Function)(value, index);
                break;
            case 'enddate':
                !this.isUndefined(this.dateEndDate) && (this.dateEndDate as Function)(value, index);
                break;
            default:
                this.isUndefined(undefined);
        }
    }

    handleTranslationsChange(type: 'title' | 'secondaryTitle' | 'titlelto' | 'description' | 'secondaryDescription' | 'url' | 'link' | 'message', value: string, index: number = -1) {
        switch (type) {
            case 'title':
                !this.isUndefined(this.translationTitle) && (this.translationTitle as Function)(value, index);
                break;
            case 'description':
                !this.isUndefined(this.translationDescription) && (this.translationDescription as Function)(value, index);
                break;
            case 'secondaryTitle':
                !this.isUndefined(this.translationSecondaryTitle) && (this.translationSecondaryTitle as Function)(value, index);
                break;
            case 'secondaryDescription':
                !this.isUndefined(this.translationSecondaryDescription) && (this.translationSecondaryDescription as Function)(value, index);
                break;
            case 'titlelto':
                !this.isUndefined(this.translationTitleLTO) && (this.translationTitleLTO as Function)(value, index);
                break;
            case 'url':
                !this.isUndefined(this.translationURL) && (this.translationURL as Function)(value, index);
                break;
            case 'link':
                !this.isUndefined(this.translationLink) && (this.translationLink as Function)(value, index);
                break;
            case 'message':
                !this.isUndefined(this.translationMessage) && (this.translationMessage as Function)(value, index);
                break;
            default:
                this.isUndefined(undefined);
        }
    }

    handleNumberChange(type: 'image' | 'secondaryImage' | 'thumbnail' | 'stamped' | 'unstamped' | 'duration', value: number, index: number = -1) {
        switch (type) {
            case 'image':
                !this.isUndefined(this.numberIndexImage) && (this.numberIndexImage as Function)(value, index);
                break;
            case 'secondaryImage':
                !this.isUndefined(this.numberIndexSecondaryImage) && (this.numberIndexSecondaryImage as Function)(value, index);
                break;
            case 'thumbnail':
                !this.isUndefined(this.numberIndexThumbnail) && (this.numberIndexThumbnail as Function)(value, index);
                break;
            case 'stamped':
                !this.isUndefined(this.numberIndexStamped) && (this.numberIndexStamped as Function)(value, index);
                break;
            case 'unstamped':
                !this.isUndefined(this.numberIndexUnstamped) && (this.numberIndexUnstamped as Function)(value, index);
                break;
            case 'duration':
                !this.isUndefined(this.numberDuration) && (this.numberDuration as Function)(value, index);
                break;
            default:
                this.isUndefined(undefined);
        }
    }

    handleAnyChange(type: 'image' | 'secondaryImage' | 'thumbnail' | 'stamped' | 'unstamped' | 'contentType', value: any, index: number = -1) {
        switch (type) {
            case 'image':
                !this.isUndefined(this.uriImage) && (this.uriImage as Function)(value, index);
                break;
            case 'secondaryImage':
                !this.isUndefined(this.uriSecondaryImage) && (this.uriSecondaryImage as Function)(value, index);
                break;
            case 'thumbnail':
                !this.isUndefined(this.uriThumbnail) && (this.uriThumbnail as Function)(value, index);
                break;
            case 'stamped':
                !this.isUndefined(this.uriStamped) && (this.uriStamped as Function)(value, index);
                break;
            case 'unstamped':
                !this.isUndefined(this.uriUnstamped) && (this.uriUnstamped as Function)(value, index);
                break;
            case 'contentType':
                !this.isUndefined(this.contentTypeKeyValue) && (this.contentTypeKeyValue as Function)(value, index);
                break;
            default:
                this.isUndefined(undefined);
        }
    }


}