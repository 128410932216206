import {ContentViewModel,ContentView} from '@levelapp/softfabric';
import AutomatedMessagePopUpViewState from './AutomatedMessagePopUpViewState';
import AutomatedMessagePopUpViewProps from './AutomatedMessagePopUpViewProps';
import TranslationHelper from '../../../../../../../Common/Helpers/TranslationHelper';
import DispatcherManager from '../../../../../../../Common/Managers/DispatcherManager';

export default class AutomatedMessageHistoryPopUpViewModel extends ContentViewModel<AutomatedMessagePopUpViewState, AutomatedMessagePopUpViewProps>
{
    _dispatcherManager : DispatcherManager

      /* CONSTRUCTOR */
    constructor(view: ContentView)
    {
        super(view);

        this.initialState({automatedMessageHistory: this.props().automatedMessageHistory, showErrors: false, languages: this.props().languages, tab:"main",isSaving:false});
        // Bindings

        this.handleBackPressed= this.handleBackPressed.bind(this);
        this.handleSavePressed = this.handleSavePressed.bind(this);
        this.changeTab = this.changeTab.bind(this);
        this.handleEmailChecked = this.handleEmailChecked.bind(this);
        this.handleNotificationChecked = this.handleNotificationChecked.bind(this);
        this.handleInAppChecked = this.handleInAppChecked.bind(this);
        this.handleMessageChanged = this.handleMessageChanged.bind(this);
        this.handleActivateChecked = this.handleActivateChecked.bind(this);
        this.handleDurationChanged = this.handleDurationChanged.bind(this);
        
        this._dispatcherManager = new DispatcherManager();

    }


    componentDidMount()
    {
        this._dispatcherManager.checkboxIsEmail = this.handleEmailChecked;
        this._dispatcherManager.checkboxIsInApp = this.handleInAppChecked;
        this._dispatcherManager.checkboxIsPushNotification = this.handleNotificationChecked;

        this._dispatcherManager.translationTitle = this.handleTitleChanged;
        this._dispatcherManager.translationMessage = this.handleMessageChanged;

        this._dispatcherManager.numberDuration = this.handleDurationChanged;

    }

    handleBackPressed(){
        this.closePopUp();
    }

    handleActivateChecked(value:boolean){
        this.setField((previousState)=>previousState.automatedMessageHistory.isActivated = value);
    }

    handleSavePressed(){
        if(this.state().automatedMessageHistory.messageTranslations.length !== this.state().languages.length 
        || this.state().automatedMessageHistory.titleTranslations.length !== this.state().languages.length
        || (this.hasNotOneType()===true)
        || !this.isFilledTranslations()){
            this.setField({showErrors: true});
        }else{
            this.setField((previousState)=>previousState.isSaving = true);
            this.props().save(this.state().automatedMessageHistory);
        }
    }

    isFilledTranslations(){
        for(let i=0;i<this.state().languages.length;i++){
            if(this.state().automatedMessageHistory.titleTranslations[i].value==="" || this.state().automatedMessageHistory.titleTranslations[i].value===" " ){
                return false;
            }
            if(this.state().automatedMessageHistory.messageTranslations[i].value==="" || this.state().automatedMessageHistory.messageTranslations[i].value===" "){
                return false;
            }
        }
        return true;
    }

    hasNotOneType(){
        return this.state().automatedMessageHistory.isEmail===false 
            && this.state().automatedMessageHistory.isMessageCenter===false
            && this.state().automatedMessageHistory.isPushNotification==false;  
    }

    changeTab(tab: string){
        this.setField({tab:tab});
    }

    

    
    /* NUMBER */
    private handleDurationChanged(value:number){
        this.setField((previousState=>previousState.automatedMessageHistory.automatedMessage.durationInDays = value));
    }

    
    /* TRANSLATIONS */
    private handleTitleChanged(text: string, index: number){
        var state = this.state();
        TranslationHelper.translation(state.automatedMessageHistory.titleTranslations,state.languages,index,text);
        this.setField({state});
    }
    private handleMessageChanged(text: string, index: number){
        var state = this.state();
        TranslationHelper.translation(state.automatedMessageHistory.messageTranslations,state.languages,index,text);
        this.setField({state});
    }

    /* CHECKBOX */
    private handleEmailChecked(value:boolean){
        this.setField((previousState)=>previousState.automatedMessageHistory.isEmail=value);
    }

    private handleNotificationChecked(value: boolean){
        this.setField((previousState)=>previousState.automatedMessageHistory.isPushNotification=value);
    }

    private handleInAppChecked(value:boolean){
        this.setField((previousState)=>previousState.automatedMessageHistory.isMessageCenter=value);
    }
}