import { Operation } from "@levelapp/softfabric";
import UsersService from "../Proxy/UsersService";
import PointUpdate from "../Common/DTO/PointUpdate";
import User from '../Common/DTO/User';
import UserRoles from "src/Common/DTO/M&N/UserRoles";


export default class UsersOperations extends Operation
{
    service : UsersService;

    constructor()
    {
        super();
        this.service = new UsersService();
    }

    get(parameters : any[], callback : Function)
    {
        this.service.get(parameters, callback);
    }

    getUserById(id : number, callback : Function , callbackError : Function)
    {
        this.service.getUserById(id, callback,callbackError);
    }

    getUserByLoyaltyCode(code : string ,callBack : Function , callbackError : Function)
    {
        this.service.getUserByLoyaltyCode(code,callBack,callbackError);

    }

    getProfile(callback : Function)
    {
        this.service.getProfile(callback);
    }

    getAllWithoutEmail(callback : Function)
    {
        this.service.getAll(callback);
    }

    put(user:any,callback : Function)
    {
        this.service.put(user, callback);
    }

    updatePoints(parameter:PointUpdate,callBack : Function){
        this.service.updatePoints(parameter,callBack);
    }

    banUser(user:User, onSuccess: Function)
    {
        this.service.banUser(user, onSuccess);
    }

    deleteUser(guid : string, onSuccess: Function)
    {
        this.service.deleteUser(guid, onSuccess);
    }

    loyalatyCardExist(code : string, onSuccess : Function)
    {
        this.service.loyaltyCardExist(code,onSuccess)
    }

    generateActivationCode(parameter:PointUpdate,callBack : Function){
        this.service.generateActivationCode(parameter,callBack);
    }

    async kioskRegister(user:UserRoles){
        return await this.service.kioskRegister(user);
    }
}
