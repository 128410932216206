 
import {ContentView} from '@levelapp/softfabric';
import PopupConfirmationViewState from './PopupConfirmationViewState';
import PopupConfirmationViewProps from './PopupConfirmationViewProps';
import PopupConfirmationViewStyle from './PopupConfirmationViewStyle';
import PopupConfirmationViewModel from './PopupConfirmationViewModel';
import {TranslationView, ButtonView} from '@levelapp/softfabric-ui';
import { View, TouchableWithoutFeedback, Text } from 'react-native';
import Constants from '../../Constants/Constants';

export default class PopupConfirmationView extends ContentView<PopupConfirmationViewProps, PopupConfirmationViewState, PopupConfirmationViewModel, PopupConfirmationViewStyle>
{
  /* CONSTRUCTOR */
  constructor(props:PopupConfirmationViewProps)
  {
    super(props);

    // Binding
    this.bind(new PopupConfirmationViewModel(this), new PopupConfirmationViewStyle(this));
  }

  /* RENDERING */
  render()
  {
    const { container , background , box  , titlePopup , contentPopup , noButtonText , footer , yesButton , noButton} = this.style;


    return (
        <View style={container}>
            <TouchableWithoutFeedback onPress={() => this.binding.handleBackPopupPressed()}>
              <View style={background}/>
            </TouchableWithoutFeedback>

            <View style={box}>
              <TranslationView style={titlePopup}>{this.props.title}</TranslationView>
              <Text style={contentPopup}>{this.props.content}</Text>


              {this.props.confirmationPopup ?
                <View style={footer}>
                  <ButtonView animation={'Bounce'} borders={'Little'} isLoading={false} handleClick={() => this.binding.handleBackPopupPressed()} backgroundColor="#CCCCCC" style={noButton}>
                    <TranslationView upperCase style={noButtonText}>generic.cancel</TranslationView>
                  </ButtonView>
                  <ButtonView animation={'Bounce'} borders={'Little'} isLoading={false} handleClick={() => { this.binding.handleAcceptPressed()}} backgroundColor={Constants.MainColor} style={yesButton}>
                    <TranslationView upperCase>generic.delete</TranslationView>
                  </ButtonView>
                </View>
                :
                <View style={footer}>
                  <ButtonView animation={'Bounce'} borders={'Little'} isLoading={false} handleClick={() => this.binding.handleBackPopupPressed()} backgroundColor={Constants.MainColor} style={yesButton}>
                    <TranslationView upperCase>generic.ok</TranslationView>
                  </ButtonView>
                </View>
              }
            </View>
          </View>
    );
  }
}