 
import {ContentView} from '@levelapp/softfabric';
import FilterLogViewState from './FilterLogViewState';
import FilterLogViewProps from './FilterLogViewProps';
import FilterLogViewStyle from './FilterLogViewStyle';
import FilterLogViewModel from './FilterLogViewModel';
import { View , Text, TouchableWithoutFeedback } from 'react-native';
import ExtendedCheckBoxView from '../../../../../../components/extendedcheckbox/ExtendedCheckBoxView';

export default class FilterLogView extends ContentView<FilterLogViewProps, FilterLogViewState, FilterLogViewModel, FilterLogViewStyle>
{
  /* CONSTRUCTOR */
  constructor(props:FilterLogViewProps)
  {
    super(props);

    // Binding
    this.bind(new FilterLogViewModel(this), new FilterLogViewStyle(this));
  }

  /* RENDERING */
  render()
  {
    return (
      <View>
          <View style={{padding : 10 , borderRadius : 20 , backgroundColor : 'white' }}>
            <TouchableWithoutFeedback onPress={() =>{ this.binding.showDetails(!this.state.showDetails)}}>
              <View>
                <Text style={{fontSize : 16}}>{this.props.children}</Text>
              </View>
            </TouchableWithoutFeedback>
            {this.state.showDetails && <View style={{position :'absolute' , minWidth : '100%', top : 40, backgroundColor : 'white' , padding : 10}}>
              
              {this.props.dataToSelect.map((v : any) =>
              {
                if(v == undefined) return;
                return <ExtendedCheckBoxView isTranslation title={v.title} value={v.selected} onValueChange={(bool : boolean) => this.binding.changeValue(v , bool)}/>
              })}
          </View>}
          </View>

      </View>
      
      
    );
  }
}