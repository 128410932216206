 
import {ContentView} from '@levelapp/softfabric';
import HistoricSectionNavigationViewState from './HistoricSectionNavigationViewState';
import HistoricSectionNavigationViewProps from './HistoricSectionNavigationViewProps';
import HistoricSectionNavigationViewStyle from './HistoricSectionNavigationViewStyle';
import HistoricSectionNavigationViewModel from './HistoricSectionNavigationViewModel';
import { View } from 'react-native';
import HistoricItemTabNavigationView from '../historicitemtabnavigation/HistoricItemTabNavigationView';
import SectionTitleView from '../../../../../../../../../components/sectiontitle/SectionTitleView';
import Constants from '../../../../../../../../../../../../Common/Constants/Constants';

export default class HistoricSectionNavigationView extends ContentView<HistoricSectionNavigationViewProps, HistoricSectionNavigationViewState, HistoricSectionNavigationViewModel, HistoricSectionNavigationViewStyle>
{
  /* CONSTRUCTOR */
  constructor(props:HistoricSectionNavigationViewProps)
  {
    super(props);

    // Binding
    this.bind(new HistoricSectionNavigationViewModel(this), new HistoricSectionNavigationViewStyle(this));
  }

  /* RENDERING */
  render()
  {
    const { navigationBar } = this.style;

    return (
      <View>
        <SectionTitleView title={'generic.historic'}/>
        <View style={navigationBar}>
          <HistoricItemTabNavigationView
            tab={"All"}
            currentTab={this.props.currentTab}
            onChangeTab={this.props.onChangeTab}
            title= {"generic.all"}
          />
          <HistoricItemTabNavigationView
            tab={"Purchase"}
            currentTab={this.props.currentTab}
            onChangeTab={this.props.onChangeTab}
            title= {"purchases.title"}
          />
          <HistoricItemTabNavigationView
            tab={"Booster"}
            currentTab={this.props.currentTab}
            onChangeTab={this.props.onChangeTab}
            title= {"boosters.title"}
          />
          <HistoricItemTabNavigationView
            tab={"Reward"}
            currentTab={this.props.currentTab}
            onChangeTab={this.props.onChangeTab}
            title= {"rewards.title"}
          />
          <HistoricItemTabNavigationView
            tab={"SavingCard"}
            currentTab={this.props.currentTab}
            onChangeTab={this.props.onChangeTab}
            title={(Constants.IsSavingCardsUsedAsBadge) ? "badges.title" : "savingcards.title"}
          />
          <HistoricItemTabNavigationView
            tab={"Coupon"}
            currentTab={this.props.currentTab}
            onChangeTab={this.props.onChangeTab}
            title= {"coupons.title"}
          />
          <HistoricItemTabNavigationView
            tab={"Manual Earned Point"}
            currentTab={this.props.currentTab}
            onChangeTab={this.props.onChangeTab}
            title= {"mep.title"}
          />
        </View>
      </View>
    );
  }
}