import {ContentViewModel,ContentView} from '@levelapp/softfabric';
import { TableRow } from '@levelapp/softfabric-ui';
 
import CountryViewState from './CountryViewState';
import CountryViewProps from './CountryViewProps';
import CountryOperations from '../../../../../../../Transfer/CountryOperations';
import Country from '../../../../../../../Common/DTO/Country';
import CountrypopupView from '../../popup/countrypopup/CountrypopupView';
import ToastHelper from '../../../../../../../Common/Helpers/ToastHelper';
import ConfirmPopUpView from '../../../../../../../Common/Components/confirmpopup/ConfirmPopUpView';
import ErrorHelper from '../../../../../../../Common/Helpers/ErrorHelper';

export default class CountryViewModel extends ContentViewModel<CountryViewState, CountryViewProps>
{
    operation: CountryOperations;



      /* CONSTRUCTOR */
    constructor(view: ContentView)
    {
        super(view);


        this.initialState({ tableData: [], tableHeaders: ['Country Code','ID'], isLoading: false, nbPage: 0, currentPage: 1, isResearching: false });
        this.operation = new CountryOperations()
        // Bindings
        this.getData = this.getData.bind(this);
        this.addField = this.addField.bind(this);
        this.errorManagement = this.errorManagement.bind(this);
        this.refresh = this.refresh.bind(this);
    }

    componentDidMount() {
        this.refresh();
    }

    addField(){
        this.showPopUp(
            <CountrypopupView 
                country={{
                    id : 0,
                    countryCode : '',
                    labelTranslations : []
                    }}
                    refreshParent = {this.refresh}
                    >

            </CountrypopupView>
        )
    }



    getData() {
        this.operation.get( (countries: any) => {
            let tableData: TableRow[] = [];
            countries.forEach((country: Country) => {
                tableData.push({ data: [
                    country.countryCode, 
                    country.id], 
                    isDeletable: true, 
                    isEditable: true, 
                    editCallback: () => { this.editField(country) }, 
                    deleteCallback: () => { this.deleteField(country) } });
            });
            this.setField({ tableData: tableData, coupons: countries, isLoading: false });
        },this.errorManagement)
    }

    refresh() {
        this.setField({ isLoading: true });
        this.getData();

    }

    editField(country : Country){
        this.showPopUp(
            <CountrypopupView 
                country={country}
                refreshParent = {this.refresh}
                    >

            </CountrypopupView>
        )
    }

    deleteField(country : Country){
        this.showPopUp(
            <ConfirmPopUpView callBack={() => {
                this.operation.delete(country, () => {
                    this.showToast(ToastHelper.getToast("countries.popup.success.deleted","success"),() => {},2500);
                    this.refresh();
                },this.errorManagement);
            }} />
        );
    }

    errorManagement(error : any) 
    {
        this.setField({isLoading : false});
        ErrorHelper.showMessageError(error.response);
    }

}