 
import {ContentView} from '@levelapp/softfabric';
import ConfirmGenericPopUpViewState from './ConfirmGenericPopUpViewState';
import ConfirmGenericPopUpViewProps from './ConfirmGenericPopUpViewProps';
import ConfirmGenericPopUpViewStyle from './ConfirmGenericPopUpViewStyle';
import ConfirmGenericPopUpViewModel from './ConfirmGenericPopUpViewModel';
import {TranslationView, ButtonView} from '@levelapp/softfabric-ui';
import { View, TouchableWithoutFeedback, Text } from 'react-native';
import Constants from '../../Constants/Constants';
export default class ConfirmGenericPopUpView extends ContentView<ConfirmGenericPopUpViewProps, ConfirmGenericPopUpViewState, ConfirmGenericPopUpViewModel, ConfirmGenericPopUpViewStyle>
{
  /* CONSTRUCTOR */
  constructor(props:ConfirmGenericPopUpViewProps)
  {
    super(props);

    // Binding
    this.bind(new ConfirmGenericPopUpViewModel(this), new ConfirmGenericPopUpViewStyle(this));
  }

  /* RENDERING */
  render()
  {
    const { container , background , box  , titlePopup , contentPopup , noButtonText , footer , yesButton , noButton} = this.style;

    return (
      <View style={container}>
      <TouchableWithoutFeedback onPress={() => this.binding.handleBackPopupPressed()}>
        <View style={background}/>
      </TouchableWithoutFeedback>

      <View style={box}>
        <TranslationView style={titlePopup}>{this.props.title}</TranslationView>
        <Text style={contentPopup}>{this.props.content}</Text>


        {this.props.confirmationPopup ?
          <View style={footer}>
            <ButtonView animation={'Bounce'} borders={'Little'} isLoading={false} handleClick={() => this.binding.handleBackPopupPressed()} backgroundColor="#CCCCCC" style={noButton}>
              <TranslationView upperCase style={noButtonText}>{this.props.titleButtonBack}</TranslationView>
            </ButtonView>
            <ButtonView animation={'Bounce'} borders={'Little'} isLoading={this.state.isLoadingAccept} handleClick={() => { this.binding.handleAcceptPressed()}} backgroundColor={Constants.MainColor} style={yesButton}>
              <TranslationView upperCase>{this.props.titleButtonAccepted}</TranslationView>
            </ButtonView>
          </View>
          :
          <View style={footer}>
            <ButtonView animation={'Bounce'} borders={'Little'} isLoading={false} handleClick={() => this.binding.handleBackPopupPressed()} backgroundColor={Constants.MainColor} style={yesButton}>
              <TranslationView upperCase>generic.ok</TranslationView>
            </ButtonView>
          </View>
        }
      </View>
    </View>
);
}
}