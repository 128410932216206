import {ContentViewModel,ContentView} from '@levelapp/softfabric';
import MainInformationViewState from './MainInformationViewState';
import MainInformationViewProps from './MainInformationViewProps';
import Product from '../../../../../../../../../Common/DTO/Product';
import Constants from '../../../../../../../../../Common/Constants/Constants';

export default class MainInformationViewModel extends ContentViewModel<MainInformationViewState, MainInformationViewProps>
{
    _refEndDate : any;

    /* CONSTRUCTOR */
    constructor(view: ContentView)
    {
        super(view);

        this.initialState(
            {
                zIndexSelect : -3,
                color: Constants.MainColor,
                selectedRequiredProducts: [],
                selectedFreeProducts: [],
            });

        if (this.props().coupon.requiredProducts != undefined && this.props().coupon.requiredProducts.length > 0)
        {
            this.state().selectedRequiredProducts = this.props().products.filter((product: Product) => this.props().coupon.requiredProducts.find((x: Number) => x == product.id) != undefined)
        }

        if (this.props().coupon.freeProducts != undefined && this.props().coupon.freeProducts.length > 0)
        {
            this.state().selectedFreeProducts = this.props().products.filter((product: Product) => this.props().coupon.freeProducts.find((x: Number) => x == product.id) != undefined)

        }

        // Bindings
        this.handleOnChangeDate = this.handleOnChangeDate.bind(this);
        this.onDropDownOpen = this.onDropDownOpen.bind(this);
        this.onDropDownClose = this.onDropDownClose.bind(this);

       
    }

    componentDidMount()
    {
    }

    handleOnChangeDate(type : 'startdate' | 'enddate' , value : Date)
    {
        this._refEndDate.getInnerRef().input.focus();
        this.props().handleDateChange(type,value);
    }

    onDropDownOpen()
    {
        this.setField({zIndexSelect : 3});
    }

    onDropDownClose()
    {
        this.setField({zIndexSelect : -3});
    }
}