import Store from "../DTO/Store";

export default class SortHelper
{
    static sortStoreByTitle(storeList: Store[])
    {
        return storeList.sort(this.compareStore);   
    }

    static compareStore(store1: Store, store2: Store)
    {
        if (store1.title < store2.title)
        {
            return -1;
        }
        if (store1.title > store2.title)
        {
            return 1;
        }
        return 0;
    }
}