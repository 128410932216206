import { ContentViewStyle } from '@levelapp/softfabric';
import { StyleProp, ViewStyle } from 'react-native';

export default class SwitchViewStyle extends ContentViewStyle
{
    container: StyleProp<ViewStyle> = {
        width: 60,
        height: 30,
        backgroundColor: 'white',
        borderWidth: 1,
        borderColor: 'gray',
        borderRadius: 30,
        overflow: 'hidden'
    };

    toggle: StyleProp<ViewStyle> = {
        width: 30,
        height: 30,
        backgroundColor: 'white',
        borderWidth: 1,
        borderColor: 'gray',
        borderRadius: 15,
        position: 'absolute',
        zIndex: 5
    };

    enabledBg: StyleProp<ViewStyle> = {
        width: 59,
        height: 29,
        borderRadius: 30,
        position: 'absolute'
    };

    disabedBg: StyleProp<ViewStyle> = {
        width: 59,
        height: 29,
        backgroundColor: 'white',
        borderRadius: 30,
        position: 'absolute'
    };
}