import { Service, Application, HttpManager } from '@levelapp/softfabric';
import ExtendedManagers from '../Common/Managers/ExtendedManagers';
import Permission from '../Common/DTO/Permission';

export default class PermissionService extends Service {
    httpClient = Application.current.resolve<HttpManager>(ExtendedManagers.IDENTITY_MANAGER);

    async get(onSuccess: Function, onFailure: Function) {

        this.httpClient.handleSomethingWentWrong = () => onFailure();

        await this.httpClient
            .get('api/Permission/ByRoleUser', [])
            .then((response: any) => {
                if (response) {
                    if (response.data) {
                        onSuccess(response.data.data);
                    }
                }
            });
    }

    async getAll(onSuccess: Function) {

        this.httpClient.handleSomethingWentWrong = () => onSuccess([]);

        await this.httpClient
            .get('api/Permission', [])
            .then((response: any) => {
                if (response) {
                    if (response.data) {
                        onSuccess(response.data.data);
                    }
                }
            });
    }

    async delete(permissionId : number , onSuccess: Function , onFailure : Function) {

        this.httpClient.handleSomethingWentWrong = onFailure;

        await this.httpClient
            .delete(`api/Permission/${permissionId}`, [])
            .then((response: any) => {
                if (response) {
                    if (response.data) {
                        onSuccess();
                    }
                }
            });
    }

    async post(permission : Permission , onSuccess: Function , onFailure : Function) {

        this.httpClient.handleSomethingWentWrong = onFailure;

        await this.httpClient
            .post(`api/Permission`, [], permission)
            .then((response: any) => {
                if (response) {
                    if (response.data) {
                        onSuccess();
                    }
                }
            });
    }

}