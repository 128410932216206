 
import {ContentView} from '@levelapp/softfabric';
import GenericLogsViewState from './GenericLogsViewState';
import GenericLogsViewProps from './GenericLogsViewProps';
import GenericLogsViewStyle from './GenericLogsViewStyle';
import GenericLogsViewModel from './GenericLogsViewModel';
import {TranslationView} from '@levelapp/softfabric-ui';
import { utc } from 'moment';
import { View, Text,Image, TouchableOpacity } from 'react-native';
import ReactDiffViewer from 'react-diff-viewer';
import Constants from '../../../../../Common/Constants/Constants';

export default class GenericLogsView extends ContentView<GenericLogsViewProps, GenericLogsViewState, GenericLogsViewModel, GenericLogsViewStyle>
{
  /* CONSTRUCTOR */
  constructor(props:GenericLogsViewProps)
  {
    super(props);

    // Binding
    this.bind(new GenericLogsViewModel(this), new GenericLogsViewStyle(this));
  }

  /* RENDERING */
  render()
  {
    return (
      <View style={[this.style.historyContainer, {height : this.props.height}]}>
        
        {this.props.timeLineLogs.length > 0 ? 
        <View>
        <View style={{flexDirection : 'row', display : 'flex'}}>
          <View style={{flex : 1, marginLeft : 20}}>
            <TranslationView style={this.style.title}>{"genericLogs.property"}</TranslationView>
          </View>
          <View style={{flex : 2, marginLeft : 60}}>
            <TranslationView style={this.style.title}>{"genericLogs.history"}</TranslationView>
          </View>
          <View style={{flex : 4, marginLeft : 60}}>
            <TranslationView style={this.style.title}>{"genericLogs.details"}</TranslationView>
          </View>
        </View>
        <View style={{flexDirection : 'row', display : 'flex'}}>
          <View style={{flexDirection : 'column',flex : 1}}>
            {this.state.propertiesModifiedName.map((property : string)=>{return(
              <View style={{flexDirection : 'row'}}>
                <TouchableOpacity  onPress={() => {this.binding.handlePropertySelect(property);}}>
                <View style={[this.style.status, { backgroundColor: this.state.propertySelected == property ? Constants.MainColor : "#B8BBC1"}]}>
                  <Text style={{fontSize : 16 , color : "white"}}>{property}</Text>
                </View>
                </TouchableOpacity>
              </View>   
            )})}
          </View> 
          <View style={[this.style.timeline,{height : this.props.height}]}>
          {this.state.timeLineLogs.map((log, i) =>{
            
            const labels = this.binding.getPropertyNameModified(log);
            const time = utc(log.creationDate).local().format("dddd, MMMM Do YYYY, H:mm");
            return (
              <View style={this.style.history} key={i}>
                <View style={this.style.leftHistory}>
                  <View style={this.style.bubble} />
                  <View style={this.style.line} />
                </View>

                <View style={this.style.middleHistory}>
                  <Text>{time}</Text>
                  <Text>{"User"} : {log.emailGuid}</Text>
                  <View style={{flexDirection : 'row', display : 'flex'}}>
                    {Object.entries(labels).map((label : any, index : number) =>{
                      return(
                        <View key={index} style={[this.style.statusLabel, { backgroundColor: this.binding.getColorLabel(label[1]) }]}>
                          <Text style={{fontSize : 16 , color : "white"}}>{label[1].toString().toLocaleUpperCase()}</Text>
                        </View>
                      )})}  
                  </View> 

                 <TouchableOpacity  onPress={() => {this.binding.handleMoreDetails(log.version);}}>
                    <View style={{paddingTop : 10 , paddingBottom : 20 , flexDirection : 'row' , alignItems : 'center'}}>
                      <TranslationView>kitchenapp.moredetails</TranslationView>
                      <Image source={require('../../assets/right.svg').default} style={{ marginLeft : 5, width: 7, height: 14 }} />
                    </View> 
                  </TouchableOpacity >
                </View>               
              </View>
            )
          }) }
          </View>
          <View style={{marginLeft : 20,flex : 4,overflow : 'scroll',paddingVertical : 15,height : this.props.height}}>
          {this.state.showLogs && 
              <View>
                <ReactDiffViewer oldValue={JSON.stringify(this.state.originalValue,null,2)} newValue={JSON.stringify(this.state.currentValue,null,2)} splitView={true} />
              </View>}
                       
          </View>
          
        </View>
        </View>
          
        : 
        <View style={{alignItems : 'center'}}>
          <Text>No log found</Text>
        </View>}

    
              
      </View>
    );
  }
}