import { OrderSources } from "../Enums/OrderSources";

export default class OrderSourcesHelper
{
    static changeEnumToText(enumerator : OrderSources)
    {
        switch(enumerator)
       {
            case OrderSources.APP :
               return 'ordersources.app';
            case OrderSources.DELIVERY : 
               return 'ordersources.delivery'
            case OrderSources.KIOSK :
                return 'ordersources.kiosk'
            default :
                return 'ordersources.unknown'
       }
    }

   
}