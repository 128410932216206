import {ContentViewStyle} from '@levelapp/softfabric';
import { ImageStyle, StyleProp, TextStyle, ViewStyle } from 'react-native';
// import {StyleProp,ViewStyle} from 'react-native';

export default class TagSectionViewStyle extends ContentViewStyle
{
    tags: StyleProp<ViewStyle> = {
        flexDirection: 'row',
        alignItems: 'center',
    }
    

    tag: StyleProp<any> = {
        backgroundColor:'#358DFF',
        boxShadow: '0 2px 4px 2px rgba(164,164,164,0.5)',
        paddingHorizontal: 15,
        paddingVertical: 7,
        borderRadius: 5,
        marginHorizontal: 7,
        flexDirection: 'row',
        alignItems: 'center',
        marginRight: 10
    }

    tagIcon: StyleProp<ImageStyle>= {
        marginLeft: 10,
        width: 16, 
        height: 16,
    }

    tagText: StyleProp<TextStyle> = {
        color: '#FFFFFF',
        fontSize: 16
    }

}