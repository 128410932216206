 
import { ContentView } from '@levelapp/softfabric';
import MainInformationViewState from './MainInformationViewState';
import MainInformationViewProps from './MainInformationViewProps';
import MainInformationViewStyle from './MainInformationViewStyle';
import MainInformationViewModel from './MainInformationViewModel';
import { ButtonView, TranslationView } from '@levelapp/softfabric-ui';
import { TextInput, View } from 'react-native';
import Helpers from '../../../../../../../../../Common/Helpers/Helpers';
import LineView from '../../../../../../components/line/LineView';
import SectionTitleView from '../../../../../../components/sectiontitle/SectionTitleView';
import Language from '../../../../../../../../../Common/DTO/Language';
import ExtendedBlockTextInputView from '../../../../../../components/extendedblocktextinput/ExtendedBlockTextInputView';
import ExtendedTextInputView from '../../../../../../components/extendedtextinput/ExtendedTextInputView';
import ExtendedDatePickerView from '../../../../../../components/extendeddatepicker/ExtendedDatePickerView';
import ExtendedCheckBoxView from '../../../../../../components/extendedcheckbox/ExtendedCheckBoxView';
import DurationUnitHelper from '../../../../../../../../../Common/Helpers/DurationUnitHelper';
import ExtendedDropDownView from '../../../../../../components/extendeddropdown/ExtendedDropDownView';
import VALUE_TYPES from './ValueTypesEnum';
import Constants from '../../../../../../../../../Common/Constants/Constants';

export default class MainInformationView extends ContentView<MainInformationViewProps, MainInformationViewState, MainInformationViewModel, MainInformationViewStyle>
{
  /* CONSTRUCTOR */
  constructor(props: MainInformationViewProps) {
    super(props);

    // Binding
    this.bind(new MainInformationViewModel(this), new MainInformationViewStyle(this));
  }



  /* RENDERING */
  /* RENDERING */
  render() {
    return (
      <View style={{ display: "flex", flexDirection: "column", paddingHorizontal: 170, paddingVertical: 50 }}>

        {/* <TranslationView style={this.style.subTitle} upperCase>form.savingcardconfig</TranslationView> */}
        <SectionTitleView title={(Constants.IsSavingCardsUsedAsBadge) ? 'form.badgeconfig' : 'form.savingcardconfig'} />
        {/* UpperSide */}
        <View style={{ flexDirection: "row", zIndex: 3 }}>

          {/* Left Section */}
          <View style={this.style.section}>

            {/* Id side */}
            <View style={{ flexDirection: 'row' }}>
              <ExtendedTextInputView
                showError={false}
                title={"form.externalid"}
                value={this.props.savingCard.thridPartId}
                onChangeText={(text: string) => this.props.handleValueChange('tpid', text)}
              />
              <ExtendedTextInputView
                showError={this.props.showErrors && (this.props.savingCard.earningPOSId == null || this.props.savingCard.earningPOSId == "")}
                title={"form.productid"}
                value={this.props.savingCard.earningPOSId}
                onChangeText={(text: string) => this.props.handleValueChange('productId', text)}
              />
              <ExtendedTextInputView
                showError={this.props.showErrors && (this.props.savingCard.stampsQuantities == null || this.props.savingCard.earningPOSId == "")}
                title={(Constants.IsSavingCardsUsedAsBadge) ? "form.steps" : "form.stampquantity"}
                value={`${this.props.savingCard.stampsQuantities}`}
                onChangeText={(text: string) => this.props.handleValueChange('stampquantity', text)}
              />
            </View>

            <View style={{ flexDirection: 'row', zIndex: 1 }}>
              <ExtendedDatePickerView
                zIndex={2}
                title={'form.startdate'}
                selected={this.props.savingCard.StartDateInput}
                placeholder={this.binding.translate('form.startdate.placeholder')}
                handleOnChange={(date: Date) => {
                  // this.binding._refEndDate.getInnerRef().input.focus();
                  this.props.handleDateChange('startdate', date);
                }}
              />

              <ExtendedDatePickerView
                zIndex={1}
                title={'form.enddate'}
                selected={this.props.savingCard.EndDateInput}
                // ref={(r : any) => this.binding._refEndDate = r}
                placeholder={this.binding.translate('form.enddate.placeholder')}
                handleOnChange={(date: Date) => this.props.handleDateChange('enddate', date)}
              />
            </View>
          </View>

          {/* Right Section */}
          <View style={this.style.checkboxSection}>
            <ExtendedCheckBoxView
              isTranslation
              title={'form.preview'}
              value={this.props.savingCard.isPreview}
              onValueChange={(value: boolean) => this.props.handleCheckboxChange('isPreviewVisible', value)}
            />
            <ExtendedCheckBoxView
              isTranslation
              title={'form.anonymous'}
              value={this.props.savingCard.isAnonymousVisible}
              onValueChange={(value: boolean) => this.props.handleCheckboxChange('isAnonymousVisible', value)}
            />
            <ExtendedCheckBoxView
              isTranslation
              title={'form.renewable'}
              value={this.props.savingCard.isRenewableAutomaticaly}
              onValueChange={(value: boolean) => this.props.handleCheckboxChange('isRenewable', value)}
            />
            <ExtendedCheckBoxView
              isTranslation
              title={'form.marketingautomation'}
              value={this.props.savingCard.isMarketingAutomation}
              onValueChange={(value: boolean) => this.props.handleCheckboxChange('isMarketingAutomation', value)}
            />
          </View>
        </View>

        <View>
          <SectionTitleView title={'form.couponconfig'} />


          <View style={[this.style.section, { zIndex: -1 }]}>
            {!this.props.savingCard.id &&
              <View style={this.style.buttonGroup}>
                <ButtonView
                  handleClick={() => this.binding.setValueType(VALUE_TYPES.POINTS)}
                  isLoading={false}
                  animation={"None"}
                  borders={"None"}
                  style={this.state.valueType === VALUE_TYPES.POINTS ? this.style.activeButton : this.style.button}>
                  <TranslationView upperCase style={this.style.buttonText}>form.points</TranslationView>
                </ButtonView>
                <ButtonView
                  handleClick={() => this.binding.setValueType(VALUE_TYPES.COUPON_ID)}
                  isLoading={false}
                  animation={"None"}
                  borders={"None"}
                  style={this.state.valueType === VALUE_TYPES.COUPON_ID ? this.style.activeButton : this.style.button}>
                  <TranslationView upperCase style={this.style.buttonText}>form.couponid</TranslationView>
                </ButtonView>
                <ButtonView
                  handleClick={() => this.binding.setValueType(VALUE_TYPES.COUPON_POS)}
                  isLoading={false}
                  animation={"None"}
                  borders={"None"}
                  style={this.state.valueType === VALUE_TYPES.COUPON_POS ? this.style.activeButton : this.style.button}>
                  <TranslationView upperCase style={this.style.buttonText}>form.couponposid</TranslationView>
                </ButtonView>
              </View>
            }

            <View style={{ flexDirection: "row" }}>
              {this.state.valueType === VALUE_TYPES.POINTS &&
                <View>
                  <TranslationView style={this.style.inputTitle} upperCase>form.points</TranslationView>
                  <View style={this.style.inputContainer}>
                    <TextInput
                      style={this.style.largeInput}
                      value={this.props.savingCard?.points?.toString()}
                      onChangeText={(text: string) => this.props.handlePointsChange(text)}
                    />
                  </View>
                </View>
              }
              {this.state.valueType === VALUE_TYPES.COUPON_ID &&
                <View>                  
                  <View style={this.style.inputContainer}>
                    {/* 
                      <TextInput
                        style={this.style.largeInput}
                        value={this.props.savingCard?.couponId?.toString()}
                        onChangeText={(text: string) => this.props.handleCouponIdChange(text)}
                      />
                      */}
                         <ExtendedDropDownView
                  title={'form.couponid'}
                  selectedValue={null}
                  onValueChange={(value: any) => this.props.handleCouponIdChange(value.value)}
                  values={this.binding.changeTypesToDropdown()}
                />                   
                  </View>
                </View>
              }

              {this.state.valueType === VALUE_TYPES.COUPON_POS &&
                <View style={this.style.inputContainer}>
                  <TranslationView style={this.style.inputTitle} upperCase>form.couponposid</TranslationView>
                  <TextInput
                    style={this.props.showErrors && (this.props.savingCard.consumptionPosId == null || this.props.savingCard.consumptionPosId == "") ? this.style.largeErrorTextField : this.style.largeInput}
                    value={this.props.savingCard.consumptionPosId}
                    onChangeText={(text: string) => this.props.handleValueChange('consumptionId', text)} />
                </View>
              }

              <ExtendedTextInputView
                title={"form.validityduration"}
                value={this.props.savingCard.validityDuration != undefined ? this.props.savingCard.validityDuration.toString() : "0"}
                showError={this.props.showErrors && (this.props.savingCard.validityDuration == null) ? true : false}
                onChangeText={(text: string) => this.props.handleValueChange('validityduration', text)}
              />

              <ExtendedDropDownView
                title={"form.durationunit"}
                values={DurationUnitHelper.getDurationUnit()}
                selectedValue={DurationUnitHelper.getSelectedUnit(this.props.savingCard.validityDurationUnit)}
                onValueChange={(value: any) => this.props.handleValueChange('durationunit', value.value)}
              />

            </View>
          </View>
        </View>

        {/* Middle line */}
        <LineView />
        <SectionTitleView title={'form.titlefield'} />
        <View style={{ zIndex: -1, display: 'flex', flexDirection: 'row', flexWrap: 'wrap', justifyContent: 'space-between' }}>
          {/* Title */}
          {this.props.languages.map((content: Language) => {
            var _text = Helpers.getTranslationValueByLanguageKey(this.props.savingCard.titleTranslations, content.key);
            return (
              <ExtendedBlockTextInputView
                title={content.key.toUpperCase()}
                value={_text}
                showError={this.props.showErrors && (_text == null || _text == "")}
                onChangeText={(text: string) => this.props.handleTranslationsChange('title', text, content.id)}
              />
            );
          })}
        </View>
      </View>
    );
  }
}