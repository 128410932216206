import { Service, Application, HttpManager, Managers } from '@levelapp/softfabric';

import StoreGroup from 'src/Common/DTO/StoreGroup';
import AddStoreGroup from 'src/Common/DTO/AddStoreGroup';

export default class StoreGroupService extends Service {

  httpClient = Application.current.resolve<HttpManager>(Managers.HTTP_MANAGER);

  async get(query?: string): Promise<StoreGroup[]> {
    const response = await this.httpClient.get(`storeGroup${query ? query : ''}`);

    if (response && response.data) {
      return response.data.data;
    }

    return [];
  }

  async post(storeGroup: AddStoreGroup): Promise<StoreGroup> {
    const response = await this.httpClient.post('storeGroup', [], { ...storeGroup });

    if (response && response.data) {
      return response.data.data;
    }

    return Promise.reject();
  }

  async delete(storeGroupId: number): Promise<StoreGroup> {
    const response = await this.httpClient.delete(`storeGroup/${storeGroupId}`);

    if (response && response.data) {
      return response.data.data;
    }

    return Promise.reject();
  }

  async update(storeGroup: AddStoreGroup): Promise<StoreGroup> {
    const response = await this.httpClient.put('storeGroup', [], storeGroup);

    if (response && response.data) {
      return response.data.data;
    }

    return Promise.reject();
  }
}