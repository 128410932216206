 
import {ContentShellView} from '@levelapp/softfabric';
import ShellLegalTextsViewState from './ShellLegalTextsViewState';
import ShellLegalTextsViewProps from './ShellLegalTextsViewProps';
import ShellLegalTextsViewStyle from './ShellLegalTextsViewStyle';
import ShellLegalTextsViewModel from './ShellLegalTextsViewModel';
import { View } from 'react-native';

export default class ShellLegalTextsView extends ContentShellView<ShellLegalTextsViewProps, ShellLegalTextsViewState, ShellLegalTextsViewModel, ShellLegalTextsViewStyle>
{
  /* CONSTRUCTOR */
  constructor(props:ShellLegalTextsViewProps)
  {
    super(props);

    // Binding
    this.bind(new ShellLegalTextsViewModel(this), new ShellLegalTextsViewStyle(this));
  }

  /* RENDERING */
  render()
  {
    return (
      <View style={{flex : 1}}>
        {this.getSwitch()}
      </View>
    );
  }
}