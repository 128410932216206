import { ContentViewStyle } from '@levelapp/softfabric';
import { StyleProp, TextStyle, ViewStyle } from 'react-native';

export default class AddZoneViewStyle extends ContentViewStyle {
  container: StyleProp<ViewStyle> = {
    display: 'flex',
    justifyContent: 'space-between',

    marginHorizontal: 50,
    width: 1000,
    minHeight: 700,
  }

  title: StyleProp<TextStyle> = {
    fontSize: 26,
    marginTop: 35,
  }

  input: StyleProp<ViewStyle> = {
    borderRadius: 3,
    height: 44,
    borderColor: '#DCCDCD',
    borderWidth: 1,
    marginTop: 5,
  }

  content: StyleProp<ViewStyle> = {
    zIndex: 10,
  }

  inputLabel: StyleProp<TextStyle> = {
    marginTop: 31,
    marginBottom: 10,
  }

  buttons: StyleProp<ViewStyle> = {
    display: 'flex',
    flexDirection: 'row',
    marginTop: 15,
    marginVertical: 35,
  }

  addButton: StyleProp<ViewStyle> = {
    width: 160,
    height: 44,
  }

  cancelButton: StyleProp<ViewStyle> = {
    width: 160,
    height: 44,
    marginLeft: 15,
  }
}