import { ContentViewModel, ContentView } from '@levelapp/softfabric';
import LoyaltyRulesTranslationPopupViewState from './LoyaltyRulesTranslationPopupViewState';
import LoyaltyRulesTranslationPopupViewProps from './LoyaltyRulesTranslationPopupViewProps';
import TranslationHelper from '../../../../../../../Common/Helpers/TranslationHelper';

export default class LoyaltyRulesTranslationPopupViewModel extends ContentViewModel<LoyaltyRulesTranslationPopupViewState, LoyaltyRulesTranslationPopupViewProps>
{
    constructor(props: ContentView)
    {
        super(props);

        /* Initial State */
        this.initialState({
            isSaving: false,
            shouldShowError : false,
            languages: this.props().languages,
            translation: JSON.parse(JSON.stringify(this.props().translation))
        });

        /* BINDINGS */
        this.handleBackPressed = this.handleBackPressed.bind(this);
        this.handleSavePressed = this.handleSavePressed.bind(this);
        this.handleTextChanged = this.handleTextChanged.bind(this);
    }

    /* HANDLERS */
    handleBackPressed()
    {
        this.closePopUp();
    }

    handleSavePressed()
    {
        if(this.state().translation.loyaltyRulesTranslationValues.length != this.state().languages.length)
        {
            this.setField({shouldShowError : true});
        }else
        {
            this.setField((previousState) => previousState.isSaving = true);
            this.props().save(this.state().translation);
        }


    }

    handleTextChanged(text: string, index: number)
    {
        var state = this.state();
        TranslationHelper.translation(state.translation.loyaltyRulesTranslationValues,state.languages,index,text);
        this.setField({state});
    }
}