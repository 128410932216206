import { ContentViewModel, ContentView } from '@levelapp/softfabric';
import SwitchViewState from './SwitchViewState';
import SwitchViewProps from './SwitchViewProps';
import { Animated } from 'react-native';

export default class SwitchViewModel extends ContentViewModel<SwitchViewState, SwitchViewProps>
{
    // Variables
    //private _isEnabled = true;

    // Animated values
    enabledTransX = new Animated.Value(0);
    disabledTransX = new Animated.Value(-30);
    toggleTransX = new Animated.Value(0);

    /* CONSTRUCTOR */
    constructor(view: ContentView)
    {
        super(view);

        this.initialState({}); // Initialize your state here




        // Bindings
        this.animateOff = this.animateOff.bind(this);
        this.animateOn = this.animateOn.bind(this);
    }

    componentDidMount()
    {
        if (!this.props().enabled)
        {
            this.animateOff();
        }
    }

    componentDidUpdate()
    {
        if (this.props().enabled)
        {
            this.animateOn();
        }
        else
        {
            this.animateOff();
        }
    }

    private animateOff()
    {
        Animated.parallel([
            Animated.spring(this.enabledTransX, {
                useNativeDriver: true,
                toValue: 30
            }),
            Animated.spring(this.disabledTransX, {
                useNativeDriver: true,
                toValue: 0
            }),
            Animated.spring(this.toggleTransX, {
                useNativeDriver: true,
                toValue: 30
            }),
        ]).start();
    }

    private animateOn()
    {
        Animated.parallel([
            Animated.spring(this.enabledTransX, {
                useNativeDriver: true,
                toValue: 0
            }),
            Animated.spring(this.disabledTransX, {
                useNativeDriver: true,
                toValue: -30
            }),
            Animated.spring(this.toggleTransX, {
                useNativeDriver: true,
                toValue: 0
            }),
        ]).start();
    }
}