import { Service, Application, HttpManager, Managers } from '@levelapp/softfabric';
import Allergen from '../Common/DTO/Allergen';

export default class AllergenService extends Service {
    httpClient = Application.current.resolve<HttpManager>(Managers.HTTP_MANAGER);

    async get(onSuccess: Function) {
        this.httpClient
            .get('allergen', [])
            .then((response: any) => {
                if (response) {
                    if (response.data) {
                        onSuccess(response.data.data);
                    }
                }
            });
    }

    post(allergen: Allergen, callback: Function = () => { }) {
        this.httpClient
            .post('allergen', [], allergen)
            .then((response: any) => {
                if (response) {

                    callback("");
                }
            });
    }

    put(allergen: Allergen, callback: Function = () => { }) {
        this.httpClient
            .put('allergen', [], allergen)
            .then((response: any) => {
                if (response) {
                    /*if (response.data)
                    {
                        callback(response.data);
                    }*/
                    callback("");
                }
            });
    }

    delete(allergen: Allergen, callback: Function = () => { }) {
        this.httpClient
            .delete('allergen/delete/' + allergen.id)
            .then((response: any) => {
                if (response) {
                    /*if (response.data)
                   {
                       callback(response.data);
                   }*/
                    callback("");
                }
            });
    }

    getProductsUsingAllergen(allergen: Allergen, onSuccess: Function = () => { }) {
        this.httpClient
            .get('allergen/GetProductsUsingAllergen/' + allergen.id, [])
            .then((response: any) => {
                if (response) {
                    if (response.data) {
                        onSuccess(response.data.data);
                    }
                }
            });
    }
    async getProductsAllergenByProductId(productId : number){
        return await this.httpClient.get(`allergen/GetProductAllergenByProductId/${productId}`);
    }
}