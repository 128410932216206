import {ContentViewModel,ContentView} from '@levelapp/softfabric';
import ExtendedBlockTextInputViewState from './ExtendedBlockTextInputViewState';
import ExtendedBlockTextInputViewProps from './ExtendedBlockTextInputViewProps';

export default class ExtendedBlockTextInputViewModel extends ContentViewModel<ExtendedBlockTextInputViewState, ExtendedBlockTextInputViewProps>
{
      /* CONSTRUCTOR */
    constructor(view: ContentView)
    {
        super(view);

        this.initialState({}); // Initialize your state here

        // Bindings

    }
}