import { Operation } from "@levelapp/softfabric";
import BannersService from "../Proxy/BannersService";

export default class BannersOperation extends Operation
{
    service : BannersService;

    constructor()
    {
        super();
        this.service = new BannersService();
    }

    get(parameters : any[], callback : Function, onFailure : Function)
    {
        this.service.get(parameters, callback, onFailure);
    }

    post(banner:any, callback : Function, onFailure : Function)
    {
        this.service.post(banner, callback, onFailure);
    }

    put(banner:any, callback : Function, onFailure : Function)
    {
        this.service.put(banner, callback, onFailure);
    }

    delete(banner:any, callback : Function, onFailure : Function)
    {
        this.service.delete(banner, callback, onFailure);
    }
}