import {ContentViewModel, ContentView} from '@levelapp/softfabric';
import FaqpopupViewState from './FaqpopupViewState';
import FaqpopupViewProps from './FaqpopupViewProps';
import DispatcherManager from '../../../../../../../Common/Managers/DispatcherManager';
import NumberHelper from '../../../../../../../Common/Helpers/NumberHelper';
import TranslationHelper from '../../../../../../../Common/Helpers/TranslationHelper';
import FaqOperations from '../../../../../../../Transfer/FaqOperations';
import ToastHelper from '../../../../../../../Common/Helpers/ToastHelper';
import ErrorHelper from '../../../../../../../Common/Helpers/ErrorHelper';

export default class FaqpopupViewModel extends ContentViewModel<FaqpopupViewState, FaqpopupViewProps>{

    _dispatcherManager : DispatcherManager;
    _faqOperation : FaqOperations;

    constructor(props: ContentView) {
        super(props);


        /* Initial State */
        this.initialState({ 
            isLoading : false,
            showErrors : false,
            languages : this.props().languages,
            question : this.props().question,
            tab : "main"
         });

         this._faqOperation = new FaqOperations();
         this._dispatcherManager = new DispatcherManager();

         this.changeTab = this.changeTab.bind(this);
         this.isLinkEmpty = this.isLinkEmpty.bind(this);
         this.handleSavePressed = this.handleSavePressed.bind(this);
         this.handleLinkChanged = this.handleLinkChanged.bind(this);
         this.handleTitleChanged = this.handleTitleChanged.bind(this);
         this.handleOrderIndexChanged = this.handleOrderIndexChanged.bind(this);
         this.handleDescriptionChanged = this.handleDescriptionChanged.bind(this);
         this.handlePreviewChanged = this.handlePreviewChanged.bind(this);
         this.errorManagement = this.errorManagement.bind(this);
 
         
    }

    componentDidMount()
    {
        this._dispatcherManager.valueOrderIndex = this.handleOrderIndexChanged;
        this._dispatcherManager.checkboxPreview = this.handlePreviewChanged;
        this._dispatcherManager.translationLink = this.handleLinkChanged;
        this._dispatcherManager.translationTitle = this.handleTitleChanged;
        this._dispatcherManager.translationDescription = this.handleDescriptionChanged;

    }

    /* OTHERS */

    handleSavePressed() {
        if(
            this.state().question.titleTranslations.length != this.state().languages.length ||
            this.state().question.descriptionTranslations.length != this.state().languages.length ||
            this.state().question.orderIndex == 0
        ) {
            this.setField({showErrors : true});
        } else {
            //Call the props function into the setField's callback function because setting linkTranslations to null during the render (cause : setField isLoading) throws an error
            this.setField({isLoading : true}, () => {
                if (this.state().question.linkTranslations.length == 0 || this.isLinkEmpty()) {
                    this.state().question.linkTranslations = [];
                }
                this.saveQuestion(this.state().question);
            })
        }
    }

    saveQuestion(question : any) {
        if(question.id == undefined) {
            this._faqOperation.post(question,() => {
                this.props().refreshParent();
                this.closePopUp()
                this.showToast(ToastHelper.getToast("faq.popup.success.added","success"),() => {},2500);
            },this.errorManagement);
        } else {
            this._faqOperation.put(question,() => {
                this.props().refreshParent();
                this.closePopUp();
                this.showToast(ToastHelper.getToast("faq.popup.success.updated","success"),() => {},2500);

            },this.errorManagement);
        }
    }

    changeTab(tab : string) {
        this.setField({ tab : tab});
    }

    isLinkEmpty() {
        return this.state().question.linkTranslations.every((link : any) => {
            return link.value == ""
        });
    }

    errorManagement(error : any) 
    {
        this.setField({isLoading : false});
        ErrorHelper.showMessageError(error.response);
    }
    
    /* VALUES */

    private handleOrderIndexChanged(text : string)
    {
        var number = NumberHelper.convertStringToInt(text);
        this.setField((previousState) => previousState.question.orderIndex = number);
    }

    /* TRANSLATIONS */

    private handleTitleChanged(text: string, index: any) {
        var state = this.state();
        TranslationHelper.translation(state.question.titleTranslations,state.languages,index,text);
        this.setField({state});
    }

    private handleDescriptionChanged(text: string, index: any) {
        var state = this.state();
        TranslationHelper.translation(state.question.descriptionTranslations,state.languages,index,text);
        this.setField({state});
    }

    private handleLinkChanged(text: any, index: any) {
        var state = this.state();
        TranslationHelper.translation(state.question.linkTranslations,state.languages,index,text);
        this.setField({state});
    }

    private handlePreviewChanged(value : boolean) {
        this.setField((previousState) => previousState.question.isPreview = value);
    }



    
}