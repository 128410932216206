import {ContentViewModel,ContentView} from '@levelapp/softfabric';
import MembershipListViewState from './MembershipListViewState';
import MembershipListViewProps from './MembershipListViewProps';

export default class MembershipListViewModel extends ContentViewModel<MembershipListViewState, MembershipListViewProps>
{
      /* CONSTRUCTOR */
    constructor(view: ContentView)
    {
        super(view);

        this.initialState({}); // Initialize your state here

        // Bindings

    }
}