import UsersViewState from "./UsersViewState";
import UsersViewProps from "./UsersViewProps";
import { ContentViewModel, ContentView } from "@levelapp/softfabric";
import UsersPopUpView from "../userspopup/UsersPopUpView";
import UsersOperations from "../../../../../../../Transfer/UsersOperations";
import { TableRow } from "@levelapp/softfabric-ui";
import Helpers from "../../../../../../../Common/Helpers/Helpers";
import FlyxResponse from '../../../../../../../Common/DTO/FlyxResponse';
import User from "../../../../../../../Common/DTO/User";
import ToastHelper from "../../../../../../../Common/Helpers/ToastHelper";
import LoyaltyCard from "../../../../../../../Common/DTO/LoyaltyCard";
import PaginationHelper from "../../../../../../../Common/Helpers/PaginationHelper";

export default class UsersViewModel extends ContentViewModel<
  UsersViewState,
  UsersViewProps
> {
  operation: UsersOperations;
  interval: any;
  searchText: string;
  isSorting: boolean;
  indexHeaderSort: number;
  sortOrder: string;
  sortKey: string[];

  constructor(props: ContentView) {
    super(props);


    this.interval = null;
    this.searchText = '';
    this.isSorting = false;
    this.sortOrder = 'asc';
    this.indexHeaderSort = -1;
    this.sortKey = [ 'LoyaltyCode', 'FirstName', 'LastName', 'Points' , 'Email'];

    /* Initial State */
    this.initialState({
      tableData: [],
      tableHeaders: [],
      isLoading: true,
      nbPage: 0,
      currentPage: 1,
      isResearching: false
    });
    this.operation = new UsersOperations();

    /* BINDINGS */

    this.refresh = this.refresh.bind(this);
    this.saveUser = this.saveUser.bind(this);
    this.editField = this.editField.bind(this);
    this.onPageChange = this.onPageChange.bind(this);
    this.onSortChange = this.onSortChange.bind(this);
    this.handleResearching = this.handleResearching.bind(this);
    this.handleSuccessGetUsers = this.handleSuccessGetUsers.bind(this);
    this.handleCloseResearching = this.handleCloseResearching.bind(this);
  }

  componentDidMount() {
    this.refresh();
  }

  refresh() {
    this.setField({ isLoading: true });

    let parameters = [];
    parameters.push(PaginationHelper.pushPage(this.state().currentPage));
    parameters.push(PaginationHelper.pushItemPage(20));

    if (this.isSorting) {
      parameters.push(PaginationHelper.pushColumnSort(this.sortKey[this.indexHeaderSort]));
      parameters.push(PaginationHelper.pushSortOrder(this.sortOrder))
    } 
    if (this.state().isResearching) {
      parameters.push(PaginationHelper.pushSearch(encodeURIComponent(this.searchText)))
      this.operation.get(parameters ,(flyx : FlyxResponse) => this.handleSuccessGetUsers(flyx))
    } 
    else 
    {
      this.operation.get(parameters ,(flyx : FlyxResponse) => this.handleSuccessGetUsers(flyx , [ "Loyalty code", "Firstname", "Lastname", "Points", "Email"]))
    }
  }     
  
  handleSuccessGetUsers(flyx : FlyxResponse , customHeader : string[] | undefined = undefined)
  {
    let tableData: TableRow[] = [];

    if(flyx.objects != null){
      flyx.objects.forEach((user: User) => {
        
        tableData.push({
          data: [
            ((user as User).loyaltyCard as LoyaltyCard).code,
            user.firstName,
            user.lastName,
            ((user as User).loyaltyCard as LoyaltyCard).points,
            user.email
          ],
          isEditable: true,
          editCallback: () => {
            this.editField(user);
          }
        });
      });
    }

    this.setField({
      tableHeaders : customHeader != undefined ? customHeader : this.state().tableHeaders,
      tableData: tableData,
      users: flyx,
      nbPage: flyx.totalPage,
      isLoading: false
    });
  }
  

  editField(user: User) {
    this.showPopUp(
      <UsersPopUpView user={Helpers.clone(user)} saveUser={this.saveUser} refresh={this.refresh} />
    );
    this.refresh();
  }

  saveUser(user: any) {
    this.operation.put(user, () => {
      this.refresh();
      this.showToast(ToastHelper.getToast('users.popup.success.updated','success'),() => {} , 2500);
      this.closePopUp();
    });
  }

  onPageChange(page: number) {
    this.state().currentPage = page + 1;
    this.refresh();
  }

  onSortChange(indexHeaderSort: number, sortOrder: string) {
    if (indexHeaderSort == -1) {
      this.isSorting = false;
    } else {
      this.indexHeaderSort = indexHeaderSort;
      this.sortOrder = sortOrder;
      this.isSorting = true;
    }
    this.refresh();
  }

  handleResearching(searchText: string) {
    this.setField({ isLoading: true, isResearching: true });
    this.searchText = searchText;

    if (this.interval) {
      clearInterval(this.interval);
    }
    this.interval = setTimeout(() => {
      this.onPageChange(0);
    }, 1000);
  }

  handleCloseResearching() {
    this.state().isResearching = false;
    this.onPageChange(0);
  }
}
