import { Service, Application, HttpManager, Managers } from "@levelapp/softfabric";

export default class OrderSourceService extends Service {
  httpClient = Application.current.resolve<HttpManager>(Managers.HTTP_MANAGER);

  async get(onSuccess: Function, query?: string) {
    return await this.httpClient.get(`orderSource${query || ''}`).then((response: any) => {
      if (response) {
        if (response.data) {
          onSuccess(response.data.data);
        }
      }
    });;
  }
}
