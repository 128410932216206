import Basket from "../DTO/Basket";
import { utc } from 'moment';
import { Application, Managers, StorageManager } from "@levelapp/softfabric";
import StorageConstants from "../Constants/StorageConstants";

export default class PrinterHelper
{
    static async print(storeName: string, basket: Basket)
    {
        const ip = await Application.current.resolve<StorageManager>(Managers.STORAGE_MANAGER).fetch<string>(StorageConstants.IP_PRINTER) ?? "";
        const basketLines = basket.basketLines.filter(x => x.title != null);
        const ePosDev = new (window as any).epson.ePOSDevice();
        const port = '8043';

        ePosDev.connect(ip, port, (connectResult: any) =>
        {
            if ((connectResult == 'OK') || (connectResult == 'SSL_CONNECT_OK'))
            {
                //Retrieves the Printer object
                ePosDev.createDevice('local_printer', ePosDev.DEVICE_TYPE_PRINTER, { 'crypto': false, 'buffer': true }, (deviceObj: any) =>
                {
                    if (deviceObj === null)
                    {
                        //alert('Unable to retrieve printer');
                        return;
                    }
                    const printer = deviceObj;

                    // Print store
                    printer.addText("Store: ");
                    printer.addText(`${storeName}\n`);

                    // Print basket
                    printer.addText("Basket id: ");
                    printer.addText(`${basket.id}\n`);

                    // Print order
                    printer.addText("Order: ");
                    printer.addText(`${basket.desckNumber}\n`);

                    // Print total
                    printer.addText("Total: ");
                    printer.addText(`${basket.total.toFixed(2).replace('.', ',')}€\n`);

                    // Print date
                    printer.addText("Date: ");
                    printer.addText(utc(basket.lastModificationDate).local().format("HH:mm") + "\n\n");

                    // Print products
                    printer.addText("Products: \n");

                    // Loop on parent lines
                    for (const line of basketLines.filter(x => !x.parentBasketLineId))
                    {
                        const productName = line.quantity + "x " + line.title;
                        printer.addText(this.leftRight(productName, line.totalVatInclude ? line.totalVatInclude.toFixed(2).replace('.', ',') + "€" : ""));

                        // Loop on children lines
                        for (const childLine of basketLines.filter(x => x.parentBasketLineId == line.basketLineId))
                        {
                            printer.addText(this.leftRight("   " + childLine.title, childLine.totalVatInclude ? childLine.totalVatInclude.toFixed(2).replace('.', ',') + "€" : ""))
                        }
                    }

                    printer.addText("\n\n");

                    let qr = undefined

                    // Get unique UUID 
                    const rewardsCount = [...new Set(basketLines.filter(x => x.couponUUID != null).map(x => x.couponUUID))].length;

                    switch (rewardsCount)
                    {
                        // If no rewards/coupons => print bk-id
                        case 0:
                            qr = basket.externalOrderNumber;
                            break;

                        // Only 1 rewards/coupons => print uuid
                        case 1:
                            qr = basketLines.filter(x => x.couponUUID != null)[0].couponUUID;
                            break;
                    }

                    printer.addTextAlign(printer.ALIGN_CENTER);
                    if (qr != undefined)
                    {
                        printer.addText(rewardsCount == 0 ? "USER ID :\n" : "UUID :\n");
                        printer.addSymbol(qr, printer.SYMBOL_QRCODE_MODEL_2, printer.LEVEL_DEFAULT, 8);
                    }
                    // Several rewards => no QR printed
                    else
                    {
                        printer.addText("MUST SCAN QR FROM TABLET\n");
                    }

                    printer.addCut();
                    printer.send();
                });
            }
            else
            {
                //alert('Unable to reach printer');
            }
        });
    }

    static testPrint(): Promise<void>
    {
        return new Promise<void>(async (resolve, reject) =>
        {
            const ip = await Application.current.resolve<StorageManager>(Managers.STORAGE_MANAGER).fetch<string>(StorageConstants.IP_PRINTER) ?? "";
            const ePosDev = new (window as any).epson.ePOSDevice();
            const port = '8043';

            ePosDev.connect(ip, port, (connectResult: any) =>
            {
                if ((connectResult == 'OK') || (connectResult == 'SSL_CONNECT_OK'))
                {
                    //Retrieves the Printer object
                    ePosDev.createDevice('local_printer', ePosDev.DEVICE_TYPE_PRINTER, { 'crypto': false, 'buffer': true }, (deviceObj: any) =>
                    {
                        if (deviceObj === null)
                        {
                            reject();
                            return;
                        }
                        const printer = deviceObj;

                        printer.addText("Test print : successful");
                        printer.addText("\n\n");

                        printer.addSymbol("TEST", printer.SYMBOL_QRCODE_MODEL_2, printer.LEVEL_DEFAULT, 8);
                        printer.addText("\n\n");

                        printer.addCut();
                        printer.send();
                        resolve();
                    });
                }
                else
                {
                    reject();
                }
            });
        });
    }

    private static leftRight(left: string, right: string)
    {
        let line = "";
        const width = 48 - left.length - right.length - 1;

        line = line + left;
        for (var i = 0; i < width; i++)
        {
            line = line + " ";
        }
        line = line + right;
        line = line + "\n";
        return line;
    }
}