 
import { ContentView } from '@levelapp/softfabric';
import LegalTextsPopUpViewState from './LegalTextsPopUpViewState';
import LegalTextsPopUpViewProps from './LegalTextsPopUpViewProps';
import LegalTextsPopUpViewStyle from './LegalTextsPopUpViewStyle';
import LegalTextsPopUpViewModel from './LegalTextsPopUpViewModel';
import { TranslationView } from '@levelapp/softfabric-ui';
import { View, TouchableWithoutFeedback, Image } from 'react-native';
import Constants from '../../../../../../../Common/Constants/Constants';
import LinearGradient from "react-native-web-linear-gradient";
import ButtonView from '../../../../../../../Common/Components/Button/ButtonView';
import Helpers from '../../../../../../../Common/Helpers/Helpers';
import ExtendedBlockTextInputView from '../../../../components/extendedblocktextinput/ExtendedBlockTextInputView';
import ExtendedTextInputView from '../../../../components/extendedtextinput/ExtendedTextInputView';
import LineView from '../../../../components/line/LineView';
import Language from '../../../../../../../Common/DTO/Language';

export default class LegalTextsPopUpView extends ContentView<LegalTextsPopUpViewProps, LegalTextsPopUpViewState, LegalTextsPopUpViewModel, LegalTextsPopUpViewStyle>
{
  /* CONSTRUCTOR */
  constructor(props: LegalTextsPopUpViewProps) {
    super(props);

    // Binding
    this.bind(new LegalTextsPopUpViewModel(this), new LegalTextsPopUpViewStyle(this));
  }

  /* RENDERING */
  render() {
    return (
      <View style={{ minWidth: 600, minHeight: 850, width: Constants.PopUpWidth, maxWidth: "100%" }}>
        <View>
          <LinearGradient start={{ x: 0, y: 0 }} end={{ x: 1, y: 0 }} colors={[Constants.GradiantStartColor, Constants.GradiantEndColor]} >
            <View style={{ display: "flex", flexDirection: "row" }}>
              <TranslationView style={this.style.title}>
                legaltexts.popup.title
              </TranslationView>
              <ButtonView isLoading={this.state.isSaving} handleClick={this.binding.handleSavePressed} backgroundColor="white" style={{ marginTop: 20 }}>
                <TranslationView upperCase style={this.style.saveButton}>
                  generic.button.saveclose
                </TranslationView>
              </ButtonView>
              <TouchableWithoutFeedback onPress={Helpers.closePopup} >
                <Image style={{ height: 23, width: 23, resizeMode: 'contain', marginTop: 25, marginLeft: 20 }}
                  source={{ uri: 'https://appstoragflyx.blob.core.windows.net/app/Console/exit.png' }}></Image>
              </TouchableWithoutFeedback>
            </View>
          </LinearGradient>
        </View>
        <View style={{ flexDirection: 'column', paddingHorizontal: 170, paddingVertical: 50 }}>

          <ExtendedTextInputView
            disable={this.state.text.id != 0}
            showError={false}
            title={'form.code'}
            value={this.state.text.key}
            onChangeText={(text: string) => this.binding._dispatcherManager.handleValueChange('code', text)}
          />

          {/* Middle line */}
          <LineView />
          {/* Description */}
          <View style={{ display: "flex", flexDirection: "row", flexWrap: "wrap", justifyContent: 'space-between' }}>
            {this.state.languages.map((content: Language) => {
              var _text = Helpers.getTranslationValueByLanguageKey(this.state.text.contentTranslations, content.key);
              return (
                <ExtendedBlockTextInputView
                  beforeTitle={'form.text'}
                  title={content.key}
                  value={_text}
                  showError={false}
                  style={{height: 200}}
                  onChangeText={(text: string) => this.binding._dispatcherManager.handleTranslationsChange('title', text, content.id)}
                />
              );
            })}
          </View>
        </View>

      </View>
    );
  }
}