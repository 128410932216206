import { ContentViewModel, ContentView, Application, LanguageManager, Managers } from '@levelapp/softfabric';
import SavingCardsViewState from './SavingCardsViewState';
import SavingCardsViewProps from './SavingCardsViewProps';
import LanguagesOperations from '../../../../../../../Transfer/LanguagesOperations';
import Helpers from '../../../../../../../Common/Helpers/Helpers';
import ConfirmPopUpView from '../../../../../../../Common/Components/confirmpopup/ConfirmPopUpView';
import SavingCardsPopUpView from '../../popup/savingcardspopup/SavingCardsPopUpView';
 
import { TableRow } from '@levelapp/softfabric-ui';
import SavingCardsOperations from '../../../../../../../Transfer/SavingCardsOperations';
import ToastHelper from '../../../../../../../Common/Helpers/ToastHelper';
import Language from '../../../../../../../Common/DTO/Language';
import SavingCard from '../../../../../../../Common/DTO/SavingCard';
import PaginationHelper from '../../../../../../../Common/Helpers/PaginationHelper';
import ErrorHelper from '../../../../../../../Common/Helpers/ErrorHelper';
import Constants from '../../../../../../../Common/Constants/Constants';

export default class SavingCardsViewModel extends ContentViewModel<SavingCardsViewState, SavingCardsViewProps>{
    operation: SavingCardsOperations;
    languagesOperation: LanguagesOperations;
    _languages: Language[] = [];
    interval: any;
    searchText: string;
    isSorting: boolean;
    indexHeaderSort: number;
    sortOrder: string;
    sortKey: string[];

    constructor(props: ContentView) {
        super(props);

        this.interval = null;
        this.searchText = '';
        this.isSorting = false;
        this.indexHeaderSort = -1;
        this.sortOrder = 'asc';
        this.sortKey = ['Id', 'PosId', 'StartDate', 'EndDate', 'Title', 'Image'];

        /* Initial State */
        this.initialState({ tableData: [], tableHeaders: ['Id', 'Earning POS Id', 'StartDate', 'EndDate', 'Title',  'Image'], isLoading: true, nbPage: 0, currentPage: 1, isResearching: false });
        this.operation = new SavingCardsOperations();
        this.languagesOperation = new LanguagesOperations();

        /* BINDINGS */
        this.editField = this.editField.bind(this);
        this.addField = this.addField.bind(this);
        this.deleteField = this.deleteField.bind(this);
        this.refresh = this.refresh.bind(this);
        this.onPageChange = this.onPageChange.bind(this);
        this.onSortChange = this.onSortChange.bind(this);
        this.handleResearching = this.handleResearching.bind(this);
        this.handleCloseResearching = this.handleCloseResearching.bind(this);
        this.errorManagement = this.errorManagement.bind(this);
    }

    componentDidMount() {
        this.languagesOperation.get((languages: any) => {
            this._languages = languages.data;
        });
        this.refresh();
    }

    refresh() {
        this.setField({ isLoading: true });
        let parameters = PaginationHelper.basicResearchPush(
            this.state().currentPage,
            20,
            this.sortKey[this.indexHeaderSort],
            this.sortOrder,
            Application.current.resolve<LanguageManager>(Managers.LANGUAGE_MANAGER).language ,
            this.searchText,
            this.isSorting,
            this.state().isResearching);
        this.getData(parameters);
    }

    getData(queryParamaters: any[]) {
        this.operation.get(queryParamaters, (savingcards: any) => {
            let tableData: TableRow[] = [];
            savingcards.data.objects.forEach((savingCard: any) => {
                let title = savingCard.titleTranslations[0].value;
                savingCard.StartDateInput = new Date(savingCard.startDate);
                savingCard.EndDateInput = new Date(savingCard.endDate);
                let img = savingCard.imageTranslation[0].url;
                tableData.push({ data: [savingCard.id, savingCard.earningPOSId, savingCard.startDate.slice(0, 10), savingCard.endDate.slice(0, 10), title, img], isDeletable: true, isEditable: true, editCallback: () => { this.editField(savingCard) }, deleteCallback: () => { this.deleteField(savingCard) } });
            });
            this.setField({ tableData: tableData, coupons: savingcards.data.objects, nbPage: savingcards.data.totalPage, isLoading: false });
        },this.errorManagement)
    }

    addField() {
        let savingCard = this.initSavingCard();
        this.showPopUp(
            <SavingCardsPopUpView
                savingCard={savingCard}
                languages={this._languages}
                refreshParent={this.refresh} />
        );
    }

    initSavingCard() : SavingCard {
        let imageTranslations = Helpers.generateUrlTable(this._languages);
        return {
            isPreview : false,
            isDelete : false,
            isAnonymousVisible : false,
            isMarketingAutomation : false,
            isRenewableAutomaticaly : false,
            stampsQuantities : 0,
            consumptionPosId : '0',
            earningPOSId : '',
            validityDuration : 0,
            EndDateInput : new Date(),
            StartDateInput : new Date(),
            endDate : new Date(),
            startDate : new Date(),
            titleTranslations: Helpers.generateTranslationTable(this._languages),
            descriptionTranslations: Helpers.generateTranslationTable(this._languages),
            imageTranslation: Helpers.clone(imageTranslations),
            thumbnailTranslation:  Helpers.clone(imageTranslations),
            imageUnstampedTranslation:  Helpers.clone(imageTranslations),
            imageStampedTranslation:  Helpers.clone(imageTranslations),
            thridPartId: "",
            validityDurationUnit : 0,
            contentTypeKeyValue:[],
        } 
    }

    editField(savingCard: any) {
        let savingCardCopy = Helpers.clone(savingCard);
        savingCardCopy.StartDateInput = new Date(savingCardCopy.startDate);
        savingCardCopy.EndDateInput = new Date(savingCardCopy.endDate);
        savingCardCopy.imageTranslation = Helpers.generateUrlTableEdit(savingCard.imageTranslation, this._languages);
        savingCardCopy.thumbnailTranslation = Helpers.generateUrlTableEdit(savingCard.thumbnailTranslation, this._languages);
        savingCardCopy.imageStampedTranslation = Helpers.generateUrlTableEdit(savingCard.imageStampedTranslation, this._languages);
        savingCardCopy.imageUnstampedTranslation = Helpers.generateUrlTableEdit(savingCard.imageUnstampedTranslation, this._languages);

        this.showPopUp(
            <SavingCardsPopUpView savingCard={savingCardCopy} refreshParent={this.refresh} languages={this._languages} />
        );
    }

    deleteField(savingCard: any) {
        this.showPopUp(
            <ConfirmPopUpView callBack={() => {
                this.operation.delete(savingCard, () => {
                    this.showToast(ToastHelper.getToast((Constants.IsSavingCardsUsedAsBadge) ? "badges.popup.success.deleted" :"savingcards.popup.success.deleted","success"),() => {},2500);
                    this.refresh();
                },this.errorManagement);
            }} />
        );
    }

    onPageChange(page: number) {
        this.state().currentPage = page + 1;
        this.refresh()
    }

    onSortChange(indexHeaderSort: number, sortOrder: string) {
        if (indexHeaderSort == -1) {
            this.isSorting = false;
        } else {
            this.indexHeaderSort = indexHeaderSort;
            this.sortOrder = sortOrder;
            this.isSorting = true;
        }
        this.refresh();
    }

    handleResearching(searchText: string) {
        this.setField({ isLoading: true, isResearching: true });
        this.searchText = searchText;

        if (this.interval) {
            clearInterval(this.interval);
        }
        this.interval = setTimeout(() => {
            this.onPageChange(0)
        }, 1000)
    }

    handleCloseResearching() {
        this.state().isResearching = false;
        this.onPageChange(0);
    }


    errorManagement(error : any) 
    {
        this.setField({isLoading : false});
        ErrorHelper.showMessageError(error.response);
    }
}