import { ContentViewModel, ContentView } from '@levelapp/softfabric';
import HoverableViewState from './HoverableViewState';
import HoverableViewProps from './HoverableViewProps';
import createHoverMonitor from './createHoverMonitor';
const hover = createHoverMonitor();

export default class HoverableViewModel extends ContentViewModel<HoverableViewState, HoverableViewProps>
{
    /* CONSTRUCTOR */
    constructor(view: ContentView)
    {
        super(view);

        this.initialState({
            isHovered: false
        });

        // Bindings
        this.handleMouseEnter = this.handleMouseEnter.bind(this);
        this.handleMouseLeave = this.handleMouseLeave.bind(this);
    }

    handleMouseEnter()
    {
        if (hover.isEnabled && !this.state().isHovered)
        {
            const { onHoverIn } = this.props();
            if (onHoverIn)
            {
                onHoverIn();
            }
            this.setField({ isHovered: true });
        }
    };

    handleMouseLeave()
    {
        if (this.state().isHovered)
        {
            const { onHoverOut } = this.props();
            if (onHoverOut)
            {
                onHoverOut();
            }
            this.setField({ isHovered: false });
        }
    };
}