 
import { View } from 'react-native';
import { LottieView } from '@levelapp/softfabric-ui';
import { ContentView } from '@levelapp/softfabric';

import ZonesViewState from './ZonesViewState';
import ZonesViewProps from './ZonesViewProps';
import ZonesViewStyle from './ZonesViewStyle';
import ZonesViewModel from './ZonesViewModel';

import TreeMenuView from '../../treemenu/TreeMenuView';
import NoDataPlaceholderView from '../../../productstool/pages/productstool/nodataplaceholder/NoDataPlaceholderView';

import Constants from 'src/Common/Constants/Constants';
import HeaderView from 'src/Presentation/modules/dashboard/components/header/HeaderView';

export default class ZonesView extends ContentView<ZonesViewProps, ZonesViewState, ZonesViewModel, ZonesViewStyle>
{
  /* CONSTRUCTOR */
  constructor(props: ZonesViewProps) {
    super(props);

    // Binding
    this.bind(new ZonesViewModel(this), new ZonesViewStyle(this));
  }

  /* RENDERING */
  render() {
    const { style } = this;

    return (
      <View style={style.container}>
        <HeaderView
          title="tools.zones"
          description="tools.zones.description"
          shouldShowButton
          onButtonPress={this.binding.onAddZoneClick}
          buttonText="tools.addNewZone"
        />
        {!this.state.isLoading ? (
          this.state.storeGroups.length > 0 ? (
            <View style={style.treeView}>
              {this.state.storeGroups.map((storeGroup) => (
                <TreeMenuView
                  key={storeGroup.fakeId}
                  data={storeGroup.childrenStoreGroups}
                  level={0}
                  childCount={storeGroup.childCount}
                  totalStoreCount={storeGroup.totalStoreCount}
                  showTree={false}
                  onPopupClose={this.binding.onPopUpClose}
                  node={storeGroup}
                  onAdd={this.binding.onAddZoneClick}
                  onRemove={this.binding.onRemoveZoneClick}
                />
              ))}
            </View>
          ) : (
            <View style={style.noData}>
              <NoDataPlaceholderView />
            </View>
          )
        ) : (
          <LottieView height={150} width={150} source={require('src/Common' + Constants.LottieAnimationPath)} />
        )}
      </View>
    );
  }
}