 
import {ContentView} from '@levelapp/softfabric';
import StatsSettingsViewState from './StatsSettingsViewState';
import StatsSettingsViewProps from './StatsSettingsViewProps';
import StatsSettingsViewStyle from './StatsSettingsViewStyle';
import StatsSettingsViewModel from './StatsSettingsViewModel';
import { View } from 'react-native';
import HeaderView from '../../../../components/header/HeaderView';
import ResearchInputView from '../../../../../../../Common/Components/researchinput/ResearchInputView';
import TableView from '../../../../../../../Common/Components/TabelView/TableView';

export default class StatsSettingsView extends ContentView<StatsSettingsViewProps, StatsSettingsViewState, StatsSettingsViewModel, StatsSettingsViewStyle>
{
  /* CONSTRUCTOR */
  constructor(props:StatsSettingsViewProps)
  {
    super(props);

    // Binding
    this.bind(new StatsSettingsViewModel(this), new StatsSettingsViewStyle(this));
  }

  /* RENDERING */
  render()
  {
    return (
      <View style={this.style.container}>
        <View style={this.style.box}>
          
          <HeaderView
            title={"stats.settings.title"}
            description={"stats.settings.description"}
            shouldShowButton={true}
            onButtonPress={this.binding.addField}
            buttonText={"stats.settings.button.add"}
          />

          <View style={this.style.boxContainer}>
            <ResearchInputView
              placeholder={this.binding.translate("generic.search")}
              handlerResearching={(searchText: string) => { this.binding.handleResearching(searchText) }}
              handlingClose={() => { this.binding.handleCloseResearching() }} />
            <TableView
              headerTextStyle={this.style.tableHeader}
              backgroundStepColor={this.style.stepColor}
              rowStyle={this.style.rowStyle}
              nothingToDisplayText={this.binding.translate("component.table.noresults")}
              isLastColumnActions={true}
              data={this.state.tableData}
              isLoading={this.state.isLoading}
              headers={this.state.tableHeaders}
              onPageChange={(page: number) => this.binding.onPageChange(page)}
              handleSortChange={(indexHeaderSort : number, sortOrder : string) => {this.binding.onSortChange(indexHeaderSort, sortOrder)}}
              nbPage={this.state.nbPage}
              currentPage={this.state.currentPage - 1}
              isSortable={true}/>
          </View>
        </View>
      </View>
    );
  }
}