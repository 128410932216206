import { Operation } from "@levelapp/softfabric";
import BasketService from "../Proxy/BasketService";
import Basket from "../Common/DTO/Basket";

export default class BasketOperations extends Operation
{
    service: BasketService;

    constructor()
    {
        super();
        this.service = new BasketService();
    }

    get(parameters: any[], callback: Function)
    {
        this.service.get(parameters, callback);
    }

    async getNewBasketsFrom(parameters: any[])
    {
        return await this.service.getNewBasketsFrom(parameters);
    }

    async getByDate(date: Date, storeId: number, language?: string)
    {
        return await this.service.getByDate(date, storeId, language);
    }

    async getById(id: number, language?: string)
    {
        return await this.service.getById(id, language);
    }

    async markAsPrepared(basket: Basket)
    {
        return await this.service.markAsPrepared(basket);
    }

    async updateStatus(basket: Basket)
    {
        return await this.service.updateStatus(basket);
    }
}