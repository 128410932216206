import { ContentViewModel, ContentView } from '@levelapp/softfabric';
import DeliveryProviderPopupViewState from './DeliveryProviderPopupViewState';
import DeliveryProviderPopupViewProps from './DeliveryProviderPopupViewProps';
import DeliveryProvidersOperations from '../../../../../../../../../Transfer/DeliveryProvidersOperation';
import Helpers from '../../../../../../../../../Common/Helpers/Helpers';
import Constants from '../../../../../../../../../Common/Constants/Constants';

export default class DeliveryProviderPopupViewModel extends ContentViewModel<DeliveryProviderPopupViewState, DeliveryProviderPopupViewProps>{

    _providersOperation: DeliveryProvidersOperations;
    _providerToDelete: any;

    constructor(props: ContentView) {
        super(props);

        this._providerToDelete = null;

        this._providersOperation = new DeliveryProvidersOperations();

        this.initialState({
            languages: this.props().languages,
            list: this.props().list,
            provider: {},
            showErrors: false,
            isSendingProvider: false,
            showPopup: false,
            titlePopup: '',
            confirmationPopup: false,
            contentPopup: '',
        });

        this.state().provider = this.initialProvider();

        this.handleBackPressed = this.handleBackPressed.bind(this);
        this.handleProviderNameChanged = this.handleProviderNameChanged.bind(this);
        this.handleImage = this.handleImage.bind(this);
        this.handleCreateProvider = this.handleCreateProvider.bind(this);
        this.handleDeleteProvider = this.handleDeleteProvider.bind(this);
        this.handleBackPopupPressed = this.handleBackPopupPressed.bind(this);
        this.deleteProvider = this.deleteProvider.bind(this);
        this.isSelectedImage = this.isSelectedImage.bind(this);
    }

    initialProvider() {
        var provider = {
            "name": "",
            "imageTranslations": Helpers.generateUrlTableSize(1),
        };
        provider.imageTranslations[0].language = {};
        return provider;
    }

    handleBackPressed() {
        this.props().handleBackPressed();
    }

    handleProviderNameChanged(name: string) {
        this.setField((previousState) => previousState.provider.name = name);
    }

    handleImage(base64: any) {
        var state = this.state();
        state.provider.imageTranslations[0].url = base64;
        state.provider.imageTranslations[0].language.key = state.languages[0].key;
        state.provider.imageTranslations[0].imageData = state.provider.imageTranslations[0].url.replace("data:image/png;base64,", "").replace("data:image/jpeg;base64,", "");
        this.setField({state});
    }

    handleCreateProvider() {
        if (this.hasName() && this.isSelectedImage()) {
            this.setField((previousState) => previousState.isSendingProvider = true)
            this._providersOperation.post(this.state().provider, () => {
                this.setField((previousState) => previousState.showErrors = false);
                this.refresh();
            })
        } else {
            this.setField((previousState) => previousState.showErrors = true);
        }
    }

    handleDeleteProvider(provider: any) {
        this._providerToDelete = provider;
        this._providersOperation.getStoresUsingProvider(provider, (data: any) => {
            if (data.data.length == 0) {
                this.setField((previousState) => previousState.contentPopup = '');
                this.setField((previousState) => previousState.confirmationPopup = true);
                this.setField((previousState) => previousState.titlePopup = 'generic.confirmation.delete');
                this.setField((previousState) => previousState.showPopup = true);
            } else {
                var stores = '';
                (data.data as any[]).forEach((store: any) => {
                    stores = stores + store.slug + ', '
                });
                //TODO
                //Trunc string if too long
                this.setField((previousState) => previousState.contentPopup = stores);
                this.setField((previousState) => previousState.confirmationPopup = true);
                this.setField((previousState) => previousState.titlePopup = 'delivery.popup.warning.provider');
                this.setField((previousState) => previousState.showPopup = true);
            }
        })
    }

    deleteProvider() {
        this._providersOperation.delete(this._providerToDelete, () => {
            this.setField((previousState) => previousState.contentPopup = '');
            this.setField((previousState) => previousState.confirmationPopup = false)
            this.setField((previousState) => previousState.titlePopup = 'delivery.popup.deleted.provider');
            this.setField((previousState) => previousState.showPopup = true);
            this.refresh()
        });
    }

    hasName() {
        return this.state().provider.name != "";
    }

    refresh() {
        this._providersOperation.get((data: any) => {
            this.setField((previousState) => previousState.list = data.data);
            this.setField((previousState) => previousState.isSendingProvider = false)
            this.setField((previousState) => previousState.provider = this.initialProvider())
        })
    }

    handleBackPopupPressed() {
        this.setField((previousState) => previousState.showPopup = false);
    }

    isSelectedImage() {
        return (this.state().provider.imageTranslations[0].url !== Constants.UploadImageUrl);
    }
}