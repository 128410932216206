 

import {ContentShellViewModel, ContentShellView} from '@levelapp/softfabric';

import ShellProductsToolViewState from './ShellProductsToolViewState';
import ShellProductsToolViewProps from './ShellProductsToolViewProps';
import EnumModules from '../../../../../Common/Constants/EnumModules';
import ProductsToolView from './pages/productstool/ProductsToolView';

export default class ShellProductsToolViewModel extends ContentShellViewModel<ShellProductsToolViewState, ShellProductsToolViewProps>
{
    constructor(view : ContentShellView)
    {
        super(view);

        this.registerEntryPoint('main');
        this.register(<ProductsToolView/> ,'main');

        this.initialisation();
    }

    getModuleId()
    {
        return EnumModules.PRODUCTS_TOOLS;
    }

}