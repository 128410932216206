import { Service, Application, HttpManager, Managers } from "@levelapp/softfabric";
import Membership from "src/Common/DTO/Membership";

export default class MembershipService extends Service{

    httpClient = Application.current.resolve<HttpManager>(Managers.HTTP_MANAGER);

    async getPagination(parameters:any[], onSuccess: Function, onFailure : Function = () => {}){
        this.httpClient.handleSomethingWentWrong = onFailure;
        this.httpClient.handleNotFound = onFailure;

        this.httpClient
        .get('membership',parameters)
        .then((response:any)=>{
            if(response){
                if(response.data){
                    onSuccess(response.data.data);
                }
            }
        })
    }

    post(membership:Membership,onSuccess : Function, onFailure : Function = () => {}){
        this.httpClient.handleSomethingWentWrong = onFailure;
        this.httpClient.handleNotFound = onFailure;

        this.httpClient
        .post('membership',[],membership)
        .then((response:any)=>{
            if(response){
                onSuccess("succes");
            }
        })
    }

    put(membership:Membership,onSuccess : Function, onFailure : Function = () => {}){
        this.httpClient.handleSomethingWentWrong = onFailure;
        this.httpClient.handleNotFound = onFailure;

        this.httpClient
        .put('membership',[],membership)
        .then((response:any)=>{
            if(response){
                onSuccess("succes");
            }
        })
    }

    delete(membershipId:number,onSuccess:Function, onFailure : Function = () => {}){
        this.httpClient.handleSomethingWentWrong = onFailure;
        this.httpClient.handleNotFound = onFailure;

        this.httpClient
        .delete('membership',[{key : "membershipId", value : membershipId}])
        .then((response:any)=>{
            if(response)
                onSuccess("succes");
        })
    }
}