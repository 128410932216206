import { ContentViewModel, ContentView, Application, LanguageManager, Managers } from '@levelapp/softfabric';
import NewsViewState from './NewsViewState';
import NewsViewProps from './NewsViewProps';
import NewsOperations from '../../../../../../../Transfer/NewsOperations';
import LanguagesOperations from '../../../../../../../Transfer/LanguagesOperations';
import { TableRow } from '@levelapp/softfabric-ui';
import NewsPopUpView from '../newspopup/NewsPopUpView';
import Constants from '../../../../../../../Common/Constants/Constants';
import Helpers from '../../../../../../../Common/Helpers/Helpers';
 
import ConfirmPopUpView from '../../../../../../../Common/Components/confirmpopup/ConfirmPopUpView';
import ToastHelper from '../../../../../../../Common/Helpers/ToastHelper';
import News from '../../../../../../../Common/DTO/News';
import Language from '../../../../../../../Common/DTO/Language';
import CountryOperations from '../../../../../../../Transfer/CountryOperations';
import Country from '../../../../../../../Common/DTO/Country';
import ErrorHelper from '../../../../../../../Common/Helpers/ErrorHelper';

export default class NewsViewModel extends ContentViewModel<NewsViewState, NewsViewProps>
{
    operation: NewsOperations;
    languagesOperation: LanguagesOperations;
    countryOperations : CountryOperations;
    _languages: Language[] = [];
    interval: any;
    searchText: string;
    isSorting: boolean;
    indexHeaderSort: number;
    sortOrder: string;
    sortKey: string[];

    constructor(props: ContentView) {
        super(props);

        this.interval = null;
        this.searchText = '';
        this.isSorting = false;
        this.indexHeaderSort = -1;
        this.sortOrder = 'asc';
        this.sortKey = Constants.IsNewsHtml ? ['Id', 'Title', 'Img'] : ['Id', 'Title', 'Desc', 'Img'];

        /* Initial State */
        this.initialState({ tableData: [], tableHeaders: Constants.IsNewsHtml ? ['Id', 'Title', 'Img'] : ['Id', 'Title', 'Description', 'Img'], isLoading: true, nbPage: 0, currentPage: 1, isResearching: false, countries: [] });

        this.operation = new NewsOperations();
        this.languagesOperation = new LanguagesOperations();
        this.countryOperations = new CountryOperations();

        this.languagesOperation.get((languages: any) => {
            this._languages = languages.data;
        });

        /* BINDINGS */
        this.editField = this.editField.bind(this);
        this.addField = this.addField.bind(this);
        this.deleteField = this.deleteField.bind(this);
        this.refresh = this.refresh.bind(this);
        this.getData = this.getData.bind(this);
        this.onPageChange = this.onPageChange.bind(this);
        this.onSortChange = this.onSortChange.bind(this);
        this.errorManagement = this.errorManagement.bind(this);
        this.handleResearching = this.handleResearching.bind(this);
        this.handleCloseResearching = this.handleCloseResearching.bind(this);
    }

    componentDidMount() {
        this.refresh();
        this.countryOperations.get((list:Country[]) => {
            this.setField({ countries:list });
        })
    }

    refresh() {
        this.setField({ isLoading: true });
        let parameters = [];
        parameters.push({ key: "page", value: this.state().currentPage }, { key: "itemPage", value: 20 });
        if (this.isSorting) {
            parameters.push(
                { key: 'columnSort', value: this.sortKey[this.indexHeaderSort] },
                { key: 'sortOrder', value: this.sortOrder },
                { key: 'userLanguage', value: Application.current.resolve<LanguageManager>(Managers.LANGUAGE_MANAGER).language });
        }
        if (this.state().isResearching) {
            parameters.push({ key: "search", value: this.searchText });
        }
        this.getData(parameters);
    }

    getData(queryParameters : any[]) {
        this.operation.get(queryParameters, (news: any) => {
            let tableData: TableRow[] = [];
            if (Constants.IsNewsHtml) {
                news.data.objects.forEach((_news: News) => {
                    let title = _news.titleTranslations[0].value;
                    let img = _news.imageTranslation[0].url;
                    _news.StartDateInput = new Date(_news.startDate);
                    _news.EndDateInput = new Date(_news.endDate);
                    tableData.push({ data: [_news.id, title, img], isDeletable: true, isEditable: true, editCallback: () => { this.editField(_news) }, deleteCallback: () => { this.deleteField(_news) } });
                });
                this.setField({ tableData: tableData, nbPage: news.data.totalPage, isLoading: false });
            } else {
                news.data.objects.forEach((_news: News) => {
                    let title = _news.titleTranslations[0].value;
                    let desc = _news.descriptionTranslations[0].value;
                    let img = _news.imageTranslation[0].url;
                    _news.StartDateInput = new Date(_news.startDate);
                    _news.EndDateInput = new Date(_news.endDate);
                    tableData.push({ data: [_news.id, title, desc, img], isDeletable: true, isEditable: true, editCallback: () => { this.editField(_news) }, deleteCallback: () => { this.deleteField(_news) } });
                });
                this.setField({ tableData: tableData, nbPage: news.data.totalPage, isLoading: false });
            }
        },this.errorManagement);
    }

    addField() {
        this.showPopUp(
            <NewsPopUpView
                news={{
                    titleTranslations: Helpers.generateTranslationTable(this._languages),
                    descriptionTranslations: Helpers.generateTranslationTable(this._languages),
                    urlTranslation : Helpers.generateTranslationTable(this._languages),
                    imageTranslation: Helpers.generateUrlTable(this._languages),
                    thumbnailTranslation: Helpers.generateUrlTable(this._languages),
                }
                }
                languages={this._languages}
                refreshParent={this.refresh}
                countries={this.state().countries} />
        );
    }

    editField(news: any) {
        let newsCopy = Helpers.clone(news);

        newsCopy.StartDateInput = new Date(newsCopy.startDate);
        newsCopy.EndDateInput = new Date(newsCopy.endDate);
        newsCopy.imageTranslation = Helpers.generateUrlTableEdit(news.imageTranslation, this._languages);
        newsCopy.thumbnailTranslation = Helpers.generateUrlTableEdit(news.thumbnailTranslation, this._languages);
        
        this.showPopUp(
            <NewsPopUpView news={newsCopy} refreshParent={this.refresh}
            languages={this._languages} countries={this.state().countries} />
        );
        //this.NavigationManager.navigate('/dashboard/coupons/edit', true, coupon); 
        this.refresh();
    }

    deleteField(coupon: any) {
        this.showPopUp(
            <ConfirmPopUpView callBack={() => {
                this.operation.delete(coupon, () => {
                    this.showToast(ToastHelper.getToast("news.popup.success.deleted","success"),() => {},2500);
                    this.refresh();
                },this.errorManagement);
            }} />
        );
    }

    onPageChange(page: number) {
        this.state().currentPage = page + 1;
        this.refresh()
    }

    onSortChange(indexHeaderSort: number, sortOrder: string) {
        if (indexHeaderSort == -1) {
            this.isSorting = false;
        } else {
            this.indexHeaderSort = indexHeaderSort;
            this.sortOrder = sortOrder;
            this.isSorting = true;
        }
        this.refresh();
    }

    handleResearching(searchText: string) {
        this.setField({ isLoading: true, isResearching: true });
        this.searchText = searchText;

        if (this.interval) {
            clearInterval(this.interval);
        }
        this.interval = setTimeout(() => {
            this.onPageChange(0)
        }, 1000)
    }

    handleCloseResearching() {
        this.state().isResearching = false;
        this.onPageChange(0);
    }

    errorManagement(error : any) 
    {
        this.setField({isLoading : false});
        ErrorHelper.showMessageError(error.response);
    }
}