import { Service, Application, HttpManager, Managers } from '@levelapp/softfabric';
import PromoCodeBatch from "../Common/DTO/PromoCodeBatch";
import PromoCode from "../Common/DTO/PromoCode";

export default class PromoCodesService extends Service
{
    httpClient = Application.current.resolve<HttpManager>(Managers.HTTP_MANAGER);

    async get(parameters:any[], onSuccess: Function, onFailure : Function = () => {})
    {       
        this.httpClient.handleSomethingWentWrong = onFailure;
        this.httpClient.handleNotFound = onFailure;

        this.httpClient
        .get('promocode', parameters)
        .then((response : any) => {
            if (response)
            {
                if (response.data)
                {                  
                    onSuccess(response.data);
                }
            }
        });
    }

    post(pcgo:PromoCodeBatch, callback : Function = () => {}, onFailure : Function = () => {})
    {
        this.httpClient.handleSomethingWentWrong = onFailure;
        this.httpClient.handleNotFound = onFailure;

        this.httpClient
            .post('promocode', [], pcgo)
            .then((response : any) => 
            {
                if (response)
                {
                    callback("");
                }
            });
    }

    delete(promoCode:PromoCode, callback : Function = () => {}, onFailure : Function = () => {})
    {
        this.httpClient.handleSomethingWentWrong = onFailure;
        this.httpClient.handleNotFound = onFailure;

        this.httpClient
            .delete('promocode/DeletePromoCode/'+promoCode.id)
            .then((response : any) => 
            {
                if (response)
                {
                    callback("");
                }
            });
    }

    deleteBatch(batch:PromoCodeBatch, callback : Function = () => {}, onFailure : Function = () => {})
    {
        this.httpClient.handleSomethingWentWrong = onFailure;
        this.httpClient.handleNotFound = onFailure;

        this.httpClient
            .delete('promocode/DeleteBatch/'+batch.id)
            .then((response : any) => 
            {
                if (response)
                {
                    callback("");
                }
            });
    }

    put(pcb:PromoCodeBatch, callback : Function = () => {}, onFailure : Function = () => {})
    {
        this.httpClient.handleSomethingWentWrong = onFailure;
        this.httpClient.handleNotFound = onFailure;

        this.httpClient
            .put('promocode', [], pcb)
            .then((response : any) => 
            {
                if (response)
                {
                    callback("");
                }
            });
    }
}