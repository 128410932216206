import { ContentViewModel, ContentView, LanguageManager, Application, Managers } from '@levelapp/softfabric';
import PropertiesManagementPopupViewState from './PropertiesManagementPopupViewState';
import PropertiesManagementPopupViewProps from './PropertiesManagementPopupViewProps';
import Helpers from '../../../../../../../../../Common/Helpers/Helpers';
import PropertiesOperations from '../../../../../../../../../Transfer/PropertiesOperations';
import Constants from '../../../../../../../../../Common/Constants/Constants';

export default class PropertiesManagementPopupViewModel extends ContentViewModel<PropertiesManagementPopupViewState, PropertiesManagementPopupViewProps>{

    _propertiesOperation: PropertiesOperations;
    _propertyToDelete : any;

    _languageManager : LanguageManager;

    constructor(props: ContentView) {
        super(props);

        this._propertiesOperation = new PropertiesOperations();
        this._propertyToDelete = null;

        this.initialState({
            languages: this.props().languages,
            list: this.props().list,
            property: {},
            showErrors: false,
            isSendingProperty: false,
            showPopup: false,
            titlePopup: '',
            confirmationPopup: false,
            contentPopup : '',
        });

        this.state().property = this.initialProperty();

        this.handleBackPressed = this.handleBackPressed.bind(this);
        this.handlePropertyNameChanged = this.handlePropertyNameChanged.bind(this);
        this.handleImage = this.handleImage.bind(this);
        this.handleCreateProperty = this.handleCreateProperty.bind(this);
        this.handleDeleteProperty = this.handleDeleteProperty.bind(this);
        this.handleBackPopupPressed = this.handleBackPopupPressed.bind(this);
        this.deleteProperty = this.deleteProperty.bind(this);
        this.isSelectedImage = this.isSelectedImage.bind(this);

        this._languageManager = Application.current.resolve<LanguageManager>(Managers.LANGUAGE_MANAGER);       
    }

    handleBackPressed() {
        this.props().handleBackPressed();
    }

    initialProperty() {
        var property = {
            "titleTranslations": ([] as any[]),
            "imageTranslations": Helpers.generateUrlTableSize(1),
        };
        this.state().languages.forEach(languageKey => {
            property.titleTranslations.push({
                "value": "",
                "language": {
                    "key": languageKey.key,
                }
            });
        });
        property.imageTranslations[0].language = {};
        return property;
    }

    handlePropertyNameChanged(name: string, languageKey: string) {
        this.setField((previousState) => previousState.property.titleTranslations.find((titleTranslation: any) => { return titleTranslation.language.key == languageKey }).value = name)
    }

    handleImage(base64: any) {
        var state = this.state();
        state.property.imageTranslations[0].url = base64;
        state.property.imageTranslations[0].language.key = state.languages[0].key;
        state.property.imageTranslations[0].imageData = state.property.imageTranslations[0].url.replace("data:image/png;base64,", "").replace("data:image/jpeg;base64,", "");
        this.setField({state});
    }

    handleCreateProperty() {
        if (this.hasTitleForeachLanguage() && this.isSelectedImage()) {
            this.setField((previousState) => previousState.isSendingProperty = true)
            this._propertiesOperation.post(this.state().property, () => {
                this.setField((previousState) => previousState.showErrors = false)
                this.refresh();
            })
        } else {
            this.setField((previousState) => previousState.showErrors = true);
        }
    }

    handleDeleteProperty(property: any) {
        this._propertyToDelete = property;
        this._propertiesOperation.getStoresUsingProperty(property, (data: any) => {
            if (data.data.length == 0) {
                this.setField((previousState) => previousState.contentPopup = '');
                this.setField((previousState) => previousState.confirmationPopup = true);
                this.setField((previousState) => previousState.titlePopup = 'generic.confirmation.delete');
                this.setField((previousState) => previousState.showPopup = true);
            } else {
                //Ask confirmation
                var stores = '';
                (data.data as any[]).forEach((store: any) => {
                    if(store)
                    stores = stores + store.slug + ', '
                });
                //TODO
                //Trunc string if too long
                this.setField((previousState) => previousState.contentPopup = stores);
                this.setField((previousState) => previousState.confirmationPopup = true);
                this.setField((previousState) => previousState.titlePopup = 'delivery.popup.warning.property');
                this.setField((previousState) => previousState.showPopup = true);
            }
        })
    }

    deleteProperty() {
        this._propertiesOperation.delete(this._propertyToDelete, () => {
            this.setField((previousState) => previousState.contentPopup = '');
            this.setField((previousState) => previousState.confirmationPopup = false)
            this.setField((previousState) => previousState.titlePopup = 'delivery.popup.deleted.property');
            this.setField((previousState) => previousState.showPopup = true);
            this.refresh()
        });
    }

    handleBackPopupPressed() {
        this.setField((previousState) => previousState.showPopup = false);
    }

    hasTitleForeachLanguage() {
        var nbLanguage = this.state().languages.length;
        var i = 0;
        var hasTitle = true;
        while (i < nbLanguage && hasTitle) {
            hasTitle = this.state().property.titleTranslations[i].value != '';
            i++;
        }
        return hasTitle;
    }

    refresh() {
        this._propertiesOperation.get((data: any) => {
            this.setField((previousState) => previousState.list = data.data);
            this.setField((previousState) => previousState.isSendingProperty = false)
            this.setField((previousState) => previousState.property = this.initialProperty())
        })
    }

    isSelectedImage() {
        return (this.state().property.imageTranslations[0].url !== Constants.UploadImageUrl);
    }
}