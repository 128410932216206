 
import { ContentView } from '@levelapp/softfabric';
import TicketsViewState from './TicketsViewState';
import TicketsViewProps from './TicketsViewProps';
import TicketsViewStyle from './TicketsViewStyle';
import TicketsViewModel from './TicketsViewModel';
import HeaderView from '../../../../components/header/HeaderView';
import { Text, View } from 'react-native';
import TableView from '../../../../../../../Common/Components/TabelView/TableView';
import ResearchInputView from '../../../../../../../Common/Components/researchinput/ResearchInputView';
import DropDownView from '../../../../../../../Common/Components/Dropdown/DropDownView';

export default class TicketsView extends ContentView<TicketsViewProps, TicketsViewState, TicketsViewModel, TicketsViewStyle>
{
  /* CONSTRUCTOR */
  constructor(props: TicketsViewProps) {
    super(props);

    // Binding
    this.bind(new TicketsViewModel(this), new TicketsViewStyle(this));
  }

  /* RENDERING */
  render() {
    const { container, box, boxContainer, tableHeader, stepColor, } = this.style;
    const { translate, onPageChange, onSortChange, handleCloseResearching, handleResearching } = this.binding;
    const { tableData, isLoading, tableHeaders, nbPage, currentPage, searchOptions, searchOption } = this.state;
    return (
      <View style={container}>
        <View style={box}>

          <HeaderView
            title={"tickets.title"}
            buttonText={"stores.button.add"}
            description={"tickets.description"}
          />

          <View style={boxContainer}>

            <View style={{ flexDirection: "row",  }}>
              <View style={{ alignSelf: 'flex-start' }}>
                <ResearchInputView
                  placeholder={translate("generic.search")}
                  handlerResearching={(searchText: string) => { handleResearching(searchText) }}
                  handlingClose={handleCloseResearching} />
              </View>
              <View style={{ paddingTop: 12, paddingLeft: 10, paddingRight: 10 }}>
                <Text>in</Text>
              </View>
              <View style={{ alignSelf: 'flex-start' }}>
                <DropDownView
                  values={searchOptions}
                  selectedValue={searchOption}
                  onValueChange={(value: any) => this.binding.selectItem(value)}
                />
              </View>
            </View>


            <TableView
              headerTextStyle={tableHeader}
              backgroundStepColor={stepColor}
              nothingToDisplayText={translate("component.table.noresults")}
              isLastColumnActions={true}
              data={tableData}
              isLoading={isLoading}
              headers={tableHeaders}
              nbPage={nbPage}
              onPageChange={(page: number) => onPageChange(page)}
              handleSortChange={() => { onSortChange() }}
              currentPage={currentPage - 1}
              isSortable={false} />
          </View>
        </View>
      </View>
    );
  }
}