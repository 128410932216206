 
import { View, } from 'react-native';
import UsersViewState from './UsersViewState';
import UsersViewProps from './UsersViewProps';
import UsersViewStyle from './UsersViewStyle';
import UsersViewModel from './UsersViewModel';
import { ContentView } from '@levelapp/softfabric';
import TableView from '../../../../../../../Common/Components/TabelView/TableView';
import ResearchInputView from '../../../../../../../Common/Components/researchinput/ResearchInputView';
import HeaderView from '../../../../components/header/HeaderView';


export default class UsersView extends ContentView<UsersViewProps, UsersViewState, UsersViewModel, UsersViewStyle>
{
  /* CONSTRUCTOR */
  constructor(props: UsersViewProps) {
    super(props);


    // Binding
    this.bind(new UsersViewModel(this), new UsersViewStyle(this));
  }

  /* RENDERING */
  render() {

    const { container , box , boxContainer , tableHeader , stepColor} = this.style; 
    const { translate , handleCloseResearching , handleResearching , onPageChange , onSortChange } = this.binding; 
    const { tableData , isLoading , tableHeaders , nbPage , currentPage } = this.state;
    return (
      <View style={container}>
        <View style={box}>

          <HeaderView
            title={"users.title"}
            buttonText={"stores.button.add"}
            description={"users.description"}
          />

          <View style={boxContainer}>
            <ResearchInputView
              placeholder={translate("generic.search")}
              handlerResearching={(searchText: string) => { handleResearching(searchText) }}
              handlingClose={handleCloseResearching} />
            <TableView
              headerTextStyle={tableHeader}
              backgroundStepColor={stepColor}
              nothingToDisplayText={translate("component.table.noresults")}
              isLastColumnActions={true}
              data={tableData}
              isLoading={isLoading}
              headers={tableHeaders}
              onPageChange={(page: number) => onPageChange(page)}
              handleSortChange={(indexHeaderSort : number, sortOrder : string) => {onSortChange(indexHeaderSort, sortOrder)}}
              nbPage={nbPage}
              currentPage={currentPage - 1}
              isSortable={true}/>
          </View>
        </View>
      </View>
    );
  }
}