import { Operation } from "@levelapp/softfabric";
import LoyaltyRulesSettingsService from "../Proxy/LoyaltyRulesSettingsService";

export default class LoyaltyRulesSettingsOperations extends Operation
{
    service: LoyaltyRulesSettingsService;

    constructor()
    {
        super();
        this.service = new LoyaltyRulesSettingsService();
    }

    get(callback: Function)
    {
        this.service.get(callback);
    }

    put(user: any, callback: Function)
    {
        this.service.put(user, callback);
    }

}