import {ContentViewModel,ContentView} from '@levelapp/softfabric';
import ItemManagementViewState from './ItemManagementViewState';
import ItemManagementViewProps from './ItemManagementViewProps';

export default class ItemManagementViewModel extends ContentViewModel<ItemManagementViewState, ItemManagementViewProps>
{

      /* CONSTRUCTOR */
    constructor(view: ContentView)
    {
        super(view);

        this.initialState({}); // Initialize your state here

        // Bindings

    }
}