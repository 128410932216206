 
import {ContentView} from '@levelapp/softfabric';
import HistoricItemTabNavigationViewState from './HistoricItemTabNavigationViewState';
import HistoricItemTabNavigationViewProps from './HistoricItemTabNavigationViewProps';
import HistoricItemTabNavigationViewStyle from './HistoricItemTabNavigationViewStyle';
import HistoricItemTabNavigationViewModel from './HistoricItemTabNavigationViewModel';
import {TranslationView} from '@levelapp/softfabric-ui';
import { View, TouchableWithoutFeedback } from 'react-native';

export default class HistoricItemTabNavigationView extends ContentView<HistoricItemTabNavigationViewProps, HistoricItemTabNavigationViewState, HistoricItemTabNavigationViewModel, HistoricItemTabNavigationViewStyle>
{
  /* CONSTRUCTOR */
  constructor(props:HistoricItemTabNavigationViewProps)
  {
    super(props);

    // Binding
    this.bind(new HistoricItemTabNavigationViewModel(this), new HistoricItemTabNavigationViewStyle(this));
  }

  /* RENDERING */
  render()
  {
    const { onglet } = this.style;

    return (
      <View style={onglet}>
        <TouchableWithoutFeedback onPress={() => this.props.onChangeTab(this.props.tab)}>
          <View style={this.props.currentTab == this.props.tab ? this.style.tabActive : this.style.tab}>
            <View  >
              <TranslationView style={this.props.currentTab == this.props.tab ? this.style.titleActive : this.style.titleInactive}>
                {this.props.title}
              </TranslationView>
            </View>
          </View>
        </TouchableWithoutFeedback>
      </View>
    );
  }
}