import { Operation } from "@levelapp/softfabric";
import Dashboard from "../Common/DTO/Dashboard";
import DashboardsService from "../Proxy/DashboardsService";

export default class DahboardsOperations extends Operation
{
    service: DashboardsService;

    constructor()
    {
        super();
        this.service = new DashboardsService();
    }

    get(parameters: any[], callback: Function)
    {
        this.service.get(parameters, callback);
    }

    getDashboardTypes(parameters: any[], callback: Function)
    {
        this.service.getDashboardTypes(parameters, callback);
    }

    getwithparamaters(parameters: any[], callback: Function)
    {
        this.service.getwithparamaters(parameters, callback);
    }

    post(dashbord:Dashboard, callback : Function)
    {
        this.service.post(dashbord, callback);
    }

    put(dashbord:Dashboard, callback : Function)
    {
        this.service.put(dashbord, callback);
    }

    delete(dashbord:Dashboard, callback : Function)
    {
        this.service.delete(dashbord, callback);
    }
}