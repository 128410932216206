import {ContentViewStyle} from '@levelapp/softfabric';
import {StyleProp,ViewStyle} from 'react-native';

export default class ExtendedImageViewStyle extends ContentViewStyle
{
    page: StyleProp<ViewStyle> = {
        flex: 1
    }

    section: StyleProp<ViewStyle> = {
        paddingBottom: 15,
        paddingRight:15,
    };

    defaultStyle : StyleProp<ViewStyle> =
    {
        flexDirection: 'column', 
        paddingTop : 5,
    }
}