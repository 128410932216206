import { Service, Application, HttpManager } from '@levelapp/softfabric';
import ExtendedManagers from '../Common/Managers/ExtendedManagers';
import Role from '../Common/DTO/Role';
import UserRoles from '../Common/DTO/M&N/UserRoles';
export default class RoleService extends Service
{
    httpClient = Application.current.resolve<HttpManager>(ExtendedManagers.IDENTITY_MANAGER);  

    async update(role : Role , onSuccess: Function)
    {       
        this.httpClient
        .put('api/Role/UpdateRole',[],
        {
            role : role
        })
        .then((response : any) => {
            if (response)
            {
                if (response.data) 
                {                  
                    onSuccess(response.data.data);
                }
            }
        });
    }

    async post(role : Role , onSuccess: Function)
    {       
        this.httpClient
        .post('api/Role/CreateRole',[],
        {
            role : role
        })
        .then((response : any) => {
            if (response)
            {
                if (response.data) 
                {                  
                    onSuccess(response.data.data);
                }
            }
        });
    }

    async delete(roleId : string , onSuccess: Function)
    {       
        this.httpClient
        .delete(`api/Role/DeleteRole/${roleId}`)
        .then((response : any) => {
            if (response)
            {
                if (response.data) 
                {                  
                    onSuccess(response.data.data);
                }
            }
        });
    }

    async get(onSuccess: Function)
    {       
        this.httpClient
        .get('api/role')
        .then((response : any) => {
            if (response)
            {
                if (response.data) 
                {                  
                    onSuccess(response.data.data);
                }
            }
        });
    }

    async getPermissionsByRole(role : string,onSuccess: Function)
    {       
        this.httpClient
        .get(`api/role/ByRole/${role}`)
        .then((response : any) => {
            if (response)
            {
                if (response.data) 
                {                  
                    onSuccess(response.data.data);
                }
            }
        });
    }

    async getUsersWithRole(onSuccess: Function)
    {       
        this.httpClient
        .get(`api/role/UsersWithRole`)
        .then((response : any) => {
            if (response)
            {
                if (response.data) 
                {                  
                    onSuccess(response.data.data);
                }
            }
        });
    }

    async deleteUserWithRole(userrole : UserRoles ,onSuccess: Function , onFailure : Function)
    {
        this.httpClient.handleSomethingWentWrong = onFailure;

        this.httpClient
        .post(`api/Role/DeleteUserWithRole`,[],userrole)
        .then((response : any) => {
            if (response)
            {
                if (response.data) 
                {                  
                    onSuccess(response.data.data);
                }
            }
        })
    }

    async updateUserWithRole(userrole : UserRoles ,onSuccess: Function , onFailure : Function)
    {
        this.httpClient.handleSomethingWentWrong = onFailure;

        this.httpClient
        .put(`api/Role/UpdateUserWithRole`,[],userrole)
        .then((response : any) => {
            if (response)
            {
                if (response.data) 
                {                  
                    onSuccess(response.data.data);
                }
            }
        })
    }

    async createUserWithRole(userrole : UserRoles ,onSuccess: Function , onFailure : Function)
    {
        this.httpClient.handleSomethingWentWrong = onFailure;

        this.httpClient
        .post(`api/Role/CreateUserWithRole`,[],userrole)
        .then((response : any) => {
            if (response)
            {
                if (response.data) 
                {                  
                    onSuccess(response.data.data);
                }
            }
        })
    }
}