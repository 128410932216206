 
import { View } from 'react-native';
import { ContentView } from '@levelapp/softfabric';
import TitleDescriptionViewState from './TitleDescriptionViewState';
import TitleDescritpionViewProps from './TitleDescritpionViewProps';
import TitleDescritpionViewStyle from './TitleDescritpionViewStyle';
import TitleDescriptionViewModel from './TitleDescriptionViewModel';
import LineView from '../../../../../../components/line/LineView';
import SectionTitleView from '../../../../../../components/sectiontitle/SectionTitleView';
import ExtendedTextInputView from '../../../../../../components/extendedtextinput/ExtendedTextInputView';
import ExtendedBlockTextInputView from '../../../../../../components/extendedblocktextinput/ExtendedBlockTextInputView';
import Helpers from '../../../../../../../../../Common/Helpers/Helpers';
import Language from '../../../../../../../../../Common/DTO/Language';

export default class TitleDescriptionView extends ContentView<TitleDescritpionViewProps, TitleDescriptionViewState, TitleDescriptionViewModel, TitleDescritpionViewStyle>
{
  /* CONSTRUCTOR */
  constructor(props:TitleDescritpionViewProps)
  {
    super(props);

    // Binding
    this.bind(new TitleDescriptionViewModel(this), new TitleDescritpionViewStyle(this));
  }

  /* RENDERING */
  render()
  {
    const { container } = this.style;

    return (
      <View style={container}>
            {/* UpperSide */}
            <View style={{flexDirection : 'column'}}>

            <SectionTitleView title={'form.titlefield'}/>
            <View style={{ zIndex: -1, display: 'flex', flexDirection: 'row', flexWrap: 'wrap', justifyContent: 'space-between' }}>
              {/* Title */}
              {this.props.languages.map((content: Language) =>
              {
                var _text = Helpers.getTranslationValueByLanguageKey(this.props.promocodebatch.titleTranslations,content.key);
                return (
                  <ExtendedTextInputView
                    title={content.key.toUpperCase()}
                    showError={this.props.shouldShowError && (_text == null || _text == "") ? true : false}
                    value={_text}
                    onChangeText={(text : string ) => this.props.handleTranslationsChange('title',text,content.id as number)}
                  />
                );
              })}
            </View>

            </View>

            {/* Middle line */}
            <LineView/>

            <SectionTitleView title={'form.description'}/>
            <View style={{ zIndex: -1, display: 'flex', flexDirection: 'row', flexWrap: 'wrap', justifyContent: 'space-between' }}>
              {/* Title */}
              {this.props.languages.map((content: Language) =>
              {
                var _text = Helpers.getTranslationValueByLanguageKey(this.props.promocodebatch.descriptionTranslations,content.key);
                return (
                  <ExtendedBlockTextInputView
                    title={content.key.toUpperCase()}
                    showError={this.props.shouldShowError && (_text == null || _text == "") ? true : false}
                    value={_text}
                    onChangeText={(text : string ) => this.props.handleTranslationsChange('description',text,content.id as number)}
                  />
                );
              })}
            </View>
          </View>
    );
  }
}