import { NativeSyntheticEvent, TextInputFocusEventData } from 'react-native';

import { OrderSourceOperations } from 'src/Transfer/OrderSourceOperations';
import OrderSource from 'src/Common/DTO/OrderSource';
import StoreGroupOperations from 'src/Transfer/StoreGroupOperations';
import ProductOrderSourcePriceOperations from 'src/Transfer/ProductOrderSourcePriceOperations';
import StoreGroup from 'src/Common/DTO/StoreGroup';
import ProductOrderSourcePrice from 'src/Common/DTO/ProductOrderSourcePrice';
import ToastHelper from 'src/Common/Helpers/ToastHelper';
import MappedOrderSource from 'src/Common/DTO/MappedOrderSource';
import MappedTabPrice from 'src/Common/DTO/MappedTabPrice';

import { ContentViewModel, ContentView } from '@levelapp/softfabric';

import PriceTabViewState from './PriceTabViewState';
import PriceTabViewProps from './PriceTabViewProps';

export default class PriceTabViewModel extends ContentViewModel<PriceTabViewState, PriceTabViewProps>
{
  orderSourceOperations: OrderSourceOperations;
  storeGroupOperations: StoreGroupOperations;
  productOrderSourcePriceOperations: ProductOrderSourcePriceOperations;

  /* CONSTRUCTOR */
  constructor(view: ContentView) {
    super(view);

    this.initialState({
      orderSources: [],
      mappedPrices: [],
      currentSelectedProduct: this.props().selectedItem,
      pricesToUpdate: [],
      isDirty: false,
      isLoading: false,
      buttonIsLoading: false,
      storeGroups: [],
    });

    this.orderSourceOperations = new OrderSourceOperations();
    this.storeGroupOperations = new StoreGroupOperations();
    this.productOrderSourcePriceOperations = new ProductOrderSourcePriceOperations();

    // Bindings
    this.getData = this.getData.bind(this);
    this.refresh = this.refresh.bind(this);
    this.mapper = this.mapper.bind(this);
    this.getFilteredPrices = this.getFilteredPrices.bind(this);
    this.updatePrices = this.updatePrices.bind(this);
    this.updateModifiedPrices = this.updateModifiedPrices.bind(this);
    this.createNewPrices = this.createNewPrices.bind(this);
    this.deletePrices = this.deletePrices.bind(this);
    this.showErrorToast = this.showErrorToast.bind(this);
    this.getPrice = this.getPrice.bind(this);
  }

  componentDidUpdate() {
    if (this.state().currentSelectedProduct?.id !== this.props().selectedItem?.id) {
      this.refresh();
      this.setField({ currentSelectedProduct: this.props().selectedItem });
      this.setField({ isDirty: false });
    }
  }

  componentDidMount() {
    this.refresh();
  }

  refresh() {
    this.getData();
  }

  async getData() {
    try {
      this.setField({ isLoading: true });

      this.orderSourceOperations.get((orderSources: OrderSource[]) => {
        this.setField({ orderSources });
      }, "?isForAvailabilityTool=true");

      const storeGroups = await this.storeGroupOperations.get('?flat=true');
      this.setField({ storeGroups });

      const prices = await this.productOrderSourcePriceOperations.get(this.props().selectedItem?.id || 0);
      this.setField({ pricesToUpdate: prices });

      this.mapper(storeGroups, prices);
    } catch {
      this.showToast(ToastHelper.getToast("tools.generic.get.error", "error"), () => { }, 2500);
    } finally {
      this.setField({ isLoading: false });
    }
  }

  mapper(storeGroups: StoreGroup[], prices: ProductOrderSourcePrice[]) {
    const mappedPrices = storeGroups.map((sg) => ({
      id: sg.id,
      name: sg.name,
      childIds: sg.allChildren,
      sources: this.state().orderSources.map((os) => ({
        id: os.id,
        name: os.label,
        price: prices.find(
          (price) => price.storeGroupId === sg.id
            && price.orderSourceId === os.id
            && price.productId === this.state().currentSelectedProduct?.id),
      })),
    }));

    this.setField({ mappedPrices });
  }

  getFilteredPrices(changeType: string, filteredPrices: ProductOrderSourcePrice[]) {
    return filteredPrices.filter((price) => price.changeType && price.changeType === changeType)
  }

  async updatePrices() {
    const isParent = this.state().currentSelectedProduct?.isParent;

    const filteredPrices = this.state().pricesToUpdate;
    const pricesToDelete = this.getFilteredPrices('deleted', filteredPrices);
    const pricesToUpdated = this.getFilteredPrices('updated', filteredPrices);
    const newPrices = this.getFilteredPrices('added', filteredPrices);

    try {
      this.setField({ buttonIsLoading: true });
      if (isParent && !newPrices.length && !pricesToUpdated.length && !pricesToDelete.length) {
        await this.productOrderSourcePriceOperations.deleteByProductGroupId(this.state().currentSelectedProduct?.id || 0);

        this.showToast(ToastHelper.getToast("tools.toast.product.success", "success"), () => { }, 2500);
        return;
      }

      if (isParent) {
        await this.createNewPrices([...newPrices, ...pricesToUpdated], isParent);

      } else {
        await this.deletePrices(pricesToDelete);
        await this.updateModifiedPrices(pricesToUpdated);
        await this.createNewPrices(newPrices, isParent);
      }

      this.showToast(ToastHelper.getToast("tools.toast.product.success", "success"), () => { }, 2500);
      this.refresh();
    } catch (error) {
      this.showErrorToast();
    } finally {
      this.setField({ buttonIsLoading: false });
    }
  }

  async updateModifiedPrices(prices: ProductOrderSourcePrice[]) {
    if (!prices.length) {
      return;
    }

    await this.productOrderSourcePriceOperations.put(prices);
  }

  async createNewPrices(prices: ProductOrderSourcePrice[], isParent: boolean = false) {
    if (!prices.length) {
      return;
    }

    const categoryId = isParent && this.state().currentSelectedProduct?.id

    await this.productOrderSourcePriceOperations.post(prices, categoryId || undefined);
  }

  async deletePrices(prices: ProductOrderSourcePrice[]) {
    if (!prices.length) {
      return;
    }

    await this.productOrderSourcePriceOperations.delete(prices);
  }

  showErrorToast() {
    this.showToast(ToastHelper.getToast("tools.toast.product.error", "error"), () => { }, 2500);
  }

  onBlur(e: NativeSyntheticEvent<TextInputFocusEventData>, orderSource: MappedOrderSource, m: MappedTabPrice) {
    const value = e.nativeEvent.text;

    const { pricesToUpdate } = this.state();
    let { price } = orderSource;

    let prices: ProductOrderSourcePrice[] = [];
    let changes = false;

    const childIds = [...m.childIds, m.id];

    const selectedPrice = pricesToUpdate.find(
      (currentPrice) => currentPrice.storeGroupId === price?.storeGroupId
        && currentPrice.orderSourceId === price?.orderSourceId
        && currentPrice.productId === price?.productId);

    if (!value && !selectedPrice) {
      return;
    }

    if (!selectedPrice) {
      prices = pricesToUpdate.filter((p) => !(childIds.some(
        (id) => id === p.storeGroupId)
        && p.productId === this.state().currentSelectedProduct?.id
        && p.orderSourceId === orderSource.id))

      const pr = childIds.map((id): ProductOrderSourcePrice => ({
        productId: this.state().currentSelectedProduct?.id || 0,
        price: Number(value),
        storeId: id,
        storeGroupId: id,
        orderSourceId: orderSource.id,
        changeType: 'added',
      }));

      prices = [...prices, ...pr];
      changes = true;
    } else if (selectedPrice.changeType !== 'added' && !value) {
      const newPrices = pricesToUpdate.map((currentPrice) => {
        if (childIds.some((id) => currentPrice.storeGroupId === id)
          && currentPrice.orderSourceId === price.orderSourceId
          && currentPrice.productId === price.productId) {
          currentPrice.changeType = 'deleted';
        }

        return currentPrice;
      });

      prices = newPrices;
      changes = true;
    } else {
      const newPrices = pricesToUpdate.map((currentPrice) => {
        if (childIds.some((id) => currentPrice.storeGroupId === id)
          && currentPrice.orderSourceId === price.orderSourceId
          && currentPrice.productId === price.productId) {
          currentPrice.changeType = 'updated';
          currentPrice.price = Number(value);
        }

        return currentPrice;
      });

      const missingPricesToUpdate = childIds.filter((p) => !newPrices.some(x => x.storeGroupId === p));

      const pr = missingPricesToUpdate.map((id): ProductOrderSourcePrice => ({
        productId: this.state().currentSelectedProduct?.id || 0,
        price: Number(value),
        storeId: id,
        storeGroupId: id,
        orderSourceId: orderSource.id,
        changeType: 'added',
      }));

      prices = [...newPrices, ...pr];
      changes = true;
    }

    if (changes) {
      this.setField({ pricesToUpdate: prices });
      this.mapper(this.state().storeGroups, prices);
    }
  }

  getPrice(source: MappedOrderSource) {
    if (!source.price) {
      return '';
    }

    if (source.price.changeType === 'deleted') {
      return '';
    }

    if (typeof source.price.price === 'undefined') {
      return '';
    }

    if (source.price.price === -999) {
      return '';
    }

    return source.price.price;
  }

  onChange(value: string, orderSource: MappedOrderSource, m: MappedTabPrice) {
    this.setField({ isDirty: true });
    if (!orderSource.price) {
      orderSource.price = {
        productId: this.state().currentSelectedProduct?.id || 0,
        price: Number(value),
        storeId: m.id,
        storeGroupId: m.id,
        orderSourceId: orderSource.id,
        changeType: 'added',
      }

      return;
    }

    if (orderSource.price.changeType === 'added' && !value) {
      orderSource.price.changeType = 'deleted'

      return;
    }

    if (orderSource.price.changeType !== 'added' && !value) {
      orderSource.price.changeType = 'deleted'

      return;
    }

    if (orderSource.price.changeType !== 'added') {
      orderSource.price.changeType = 'updated';
    }

    orderSource.price.price = Number(value);
  }
}