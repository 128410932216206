import {ContentShellViewModel, ContentShellView} from '@levelapp/softfabric';
import ShellStoresSummaryViewState from './ShellStoresSummaryViewState';
import ShellStoresSummaryViewProps from './ShellStoresSummaryViewProps';
 
import StoreSummaryView from './pages/storesummary/StoreSummaryView';
import EnumModules from '../../../../../Common/Constants/EnumModules';

export default class ShellStoresSummaryViewModel extends ContentShellViewModel<ShellStoresSummaryViewState, ShellStoresSummaryViewProps>
{

    constructor(view : ContentShellView)
    {
        super(view);

        this.registerEntryPoint('main'); 
        this.register(<StoreSummaryView/> ,'main');

        this.initialisation();
    }

    getModuleId()
    {
        return EnumModules.STORES_SUMMARY;
    }

}