import {ContentViewStyle, Application} from '@levelapp/softfabric';
import {StyleProp,ViewStyle, TextStyle} from 'react-native';
import { StyleManager, UiManagers } from '@levelapp/softfabric-ui';

export default class MenuItemViewStyle extends ContentViewStyle
{
    MyStyleManager = Application.current.resolve<StyleManager>(UiManagers.STYLE_MANAGER);

    page: StyleProp<ViewStyle> = {
        flex: 1
    }

    link: StyleProp<ViewStyle> = {
        width: 230,
        height: 50,
        borderRadius: 3,
        display: 'flex',

        alignItems: 'center',
        flexDirection: 'row'
    };

    linkTextActive: StyleProp<TextStyle> = {
        color: this.MyStyleManager.activeTextColor,
        fontSize: 14,
        paddingLeft : 25,
    };

    linkBoldText : StyleProp<TextStyle> =
    {
        color: 'black',
        fontSize: 14,
        paddingLeft : 25,
        fontFamily: 'Montserrat',
        fontWeight: '900'
    }

    linkText: StyleProp<TextStyle> = {
        color: '#a9afbb',
        paddingLeft : 25,
        fontSize: this.MyStyleManager.fontSize
    };
}