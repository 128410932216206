import { LottieView } from "@levelapp/softfabric-ui";
import { useMemo } from "react";
import Select from "react-dropdown-select";
import { View, StyleSheet } from "react-native";
import Constants from "../../../../../../../../../Common/Constants/Constants";
import Brand from "../../../../../../../../../Common/DTO/Brand";
import SectionTitleView from "../../../../../../../../../Presentation/modules/dashboard/components/sectiontitle/SectionTitleView";

interface Props {
  isLoading: boolean;
  brands: Brand[];
  selectedIds: number[];
  handleValueChanged: (brands: Brand[]) => void;
}

const BrandsView = ({
  selectedIds,
  brands,
  handleValueChanged,
  isLoading
}: Props) => {
  const selectedBrands = useMemo(() => (
    selectedIds.map(x => brands.find((st) => st.id === x))
  ), [selectedIds, brands]);

  console.log(brands)

  return (
    <View style={styles.container}>
      <SectionTitleView title={'form.brands'} />
      {isLoading ? (
        <LottieView height={150} width={150} source={require("../../../../../../../../../Common" + Constants.LottieAnimationPath)} />
      ) :
        <Select
          placeholder="Select brands"
          color={Constants.MainColor}
          values={selectedBrands.sort((x1, x2) => x1!.name.localeCompare(x2!.name)) as Brand[]}
          options={brands.sort((x1, x2) => x1.name.localeCompare(x2.name))}
          onChange={handleValueChanged}
          multi={true}
          create={false}
          keepSelectedInList={false}
          clearable={true}
          closeOnSelect={true}
          labelField={"name"}
          valueField={"id"}
          searchBy={"name"}
        />
      }
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    display: "flex",
    flexDirection: "column",
    paddingHorizontal: 170,
    paddingVertical: 50
  },
  addAllStoresLabel: {
    fontSize: 10,
    marginTop: 20,
    fontWeight: '600',
    height: 27,
    width: 112
  }
});

export default BrandsView;