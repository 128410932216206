import { ContentView, ContentViewModel } from '@levelapp/softfabric';
import TicketsViewState from './TicketsViewState';
import TicketsViewProps from './TicketsViewProps';
import TicketOperations from '../../../../../../../Transfer/TicketOperation';
import PaginationHelper from '../../../../../../../Common/Helpers/PaginationHelper';
import FlyxResponse from '../../../../../../../Common/DTO/FlyxResponse';
import { TableRow } from '@levelapp/softfabric-ui';
import TicketDetailsPopUpView from './components/ticketdetailspopup/TicketDetailsPopUpView';
 

export default class TicketsViewModel extends ContentViewModel<TicketsViewState, TicketsViewProps>
{
    operation: TicketOperations;
    interval: any;
    searchText: string;

    constructor(props: ContentView) {
        super(props);

        this.interval = null;
        this.searchText = '';

        const options = [
            { id: 'code', title: 'Entity Code' },
            { id: 'hash', title: 'Hash' },
            { id: 'number', title: 'Number' },
            { id: 'amount', title: 'Amount' }
          ]
          

        /* Initial State */
        this.initialState({
            tableData: [],
            tableHeaders: [],
            isLoading: true,
            nbPage: 0,
            currentPage: 1,
            isResearching: false,
            searchOptions: options,
            searchOption: "code"
        });

        this.operation = new TicketOperations();

        /* BINDINGS */

        this.refresh = this.refresh.bind(this);
        this.handleResearching = this.handleResearching.bind(this);
        this.handleCloseResearching = this.handleCloseResearching.bind(this);
        this.selectItem = this.selectItem.bind(this);
        this.onPageChange = this.onPageChange.bind(this);
    }

    componentDidMount() {
        this.refresh();
    }

    refresh() {
        this.setField({ isLoading: true });

        let parameters = [];
        parameters.push(PaginationHelper.pushPage(this.state().currentPage));
        parameters.push(PaginationHelper.pushItemPage(20));
       
        if (this.state().isResearching) {
          parameters.push(PaginationHelper.pushSearch(this.searchText))
          parameters.push(PaginationHelper.pushSearchColumn(this.state().searchOption))
        } 

        this.operation.getList(parameters, (flyx: FlyxResponse) => this.handleSuccess(flyx))
    }

    onPageChange(page: number) {
        this.state().currentPage = page + 1;
        this.refresh();
    }

    onSortChange() {
        this.refresh();
    }

    handleResearching(searchText: string) {
        this.setField({ isLoading: true, isResearching: true });
        this.searchText = searchText;

        if (this.interval) {
            clearInterval(this.interval);
        }
        this.interval = setTimeout(() => {
            this.onPageChange(0);
        }, 1000);
    }

    handleCloseResearching() {
        this.state().isResearching = false;
        this.onPageChange(0);
    }

    selectItem(itemsSelected: any) {      
        this.setField({ searchOption: itemsSelected.id}, () => {this.refresh()});
    }

    handleTicketsDetails(ticket: any) {              
        this.operation.get(ticket.id, (ret: any) => {
            this.showPopUp(
                <TicketDetailsPopUpView ticket={ret.data} />
              );
        });
    }

    handleSuccess(flyx: any) {
        let tableData: TableRow[] = [];
        flyx.objects.forEach((ticket: any) => {

            tableData.push({
                data: [
                    ticket.id,
                    ticket.entityCode,
                    ticket.hash,
                    ticket.number,
                    ticket.operatorId,
                    ticket.operatorName,
                    ticket.terminalName,
                    ticket.totalAmount,
                ],
                isEditable: true,
                editCallback: () => {
                    this.handleTicketsDetails(ticket);
                }
            });
        });
        this.setField({
            tableHeaders: ["ID", "Entity code", "Hash", "Number", "Operator Id", "Operator Name", "Terminal Name", "Total Amount"],
            tableData: tableData,
            users: flyx,
            nbPage: flyx.totalPage,
            isLoading: false
        });
    }
}