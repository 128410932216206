import {ContentViewStyle} from '@levelapp/softfabric';
import {StyleProp,ViewStyle, TextStyle} from 'react-native';

export default class ExtendedBlockTextInputViewStyle extends ContentViewStyle
{
    page: StyleProp<ViewStyle> = {
        flex: 1
    }

    section: StyleProp<ViewStyle> = {
        alignSelf: 'flex-end',
        marginVertical: 15,
    };

    inputTitle: StyleProp<TextStyle> = {
        fontSize: 14,
        fontWeight: '600',
        color: '#3F3F3F',
        fontFamily : 'Montserrat SemiBold',
        marginBottom : 13,
    };

    textArea: StyleProp<any> = {
        width: '100%',
        minWidth: 675,
        minHeight : 337,
        height : '100%',
        paddingHorizontal: 5,
        borderRadius : 8,
        boxShadow: '0 5px 14px 0 rgba(0,0,0,0.1)',
    };

    smallTextArea: StyleProp<any> = {
        width: '100%',
        minWidth: 475,
        minHeight : 92,
        height : '100%',
        paddingHorizontal: 5,
        borderRadius : 8,
        boxShadow: '0 5px 14px 0 rgba(0,0,0,0.1)',
    };

    errorTextArea: StyleProp<ViewStyle> = {
        borderColor: 'red',
        borderWidth: 1,
        width: '100%',
        minWidth: 675,
        minHeight : 337,
        paddingHorizontal: 5,
        borderRadius : 8,
    };
}