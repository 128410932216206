 
import { ContentView } from '@levelapp/softfabric';
import AutomatedMessageHistoryPopUpViewState from './AutomatedMessagePopUpViewState';
import AutomatedMessageHistoryPopUpViewProps from './AutomatedMessagePopUpViewProps';
import AutomatedMessageHistoryPopUpViewStyle from './AutomatedMessagePopUpViewStyle';
import AutomatedMessageHistoryPopUpViewModel from './AutomatedMessagePopUpViewModel';
import { TranslationView } from '@levelapp/softfabric-ui';
import { View } from 'react-native';
import Constants from '../../../../../../../Common/Constants/Constants';
import SliderView from '../../../../../../../Common/Components/slider/SliderView';
import Language from '../../../../../../../Common/DTO/Language';
import Helpers from '../../../../../../../Common/Helpers/Helpers';
import PopupHeaderView from '../../../../components/popupheader/PopupHeaderView';
import PopupMenuItemView from '../../../../components/popupmenuitem/PopupMenuItemView';
import ExtendedCheckBoxView from '../../../../components/extendedcheckbox/ExtendedCheckBoxView';
import SectionTitleView from '../../../../components/sectiontitle/SectionTitleView';
import LineView from '../../../../components/line/LineView';
import ExtendedBlockTextInputView from '../../../../components/extendedblocktextinput/ExtendedBlockTextInputView';


export default class AutomatedMessageHistoryPopUpView extends ContentView<AutomatedMessageHistoryPopUpViewProps, AutomatedMessageHistoryPopUpViewState, AutomatedMessageHistoryPopUpViewModel, AutomatedMessageHistoryPopUpViewStyle>
{
  /* CONSTRUCTOR */
  constructor(props: AutomatedMessageHistoryPopUpViewProps) {
    super(props);

    // Binding
    this.bind(new AutomatedMessageHistoryPopUpViewModel(this), new AutomatedMessageHistoryPopUpViewStyle(this));
  }

  /* RENDERING */
  render() {
    return (
      <View style={{ minWidth: 600, minHeight: 850, width: Constants.PopUpWidth, maxWidth: "100%" }}>
      
        <PopupHeaderView
          handleBackPressed={Helpers.closePopup}
          title={"automatedmessages.popup.title"}
          shouldShowButton
          isLoading={this.state.isSaving}
          handleOnPressButton={this.binding.handleSavePressed}
        />  
        <View style={{ display: "flex", flexDirection: "row", flexWrap: "wrap" }}>
          <PopupMenuItemView activeItem={this.state.tab} title={"form.maininformation"} item={"main"} handleChangeTab={() => this.binding.changeTab("main")}/>
          <PopupMenuItemView activeItem={this.state.tab} title={"form.messagefields"} item={"message"} handleChangeTab={() => this.binding.changeTab("message")}/>
        </View>

        {this.state.tab == "main" &&
          <View style={{ display: "flex", flexDirection: "column", paddingHorizontal: 170, paddingVertical: 50 }}>

            {/**TypeOfMessage */}
            <View>
              <SectionTitleView title={'form.informations'}/>
              <ExtendedCheckBoxView 
                isTranslation
                title={'form.email'} 
                value={this.state.automatedMessageHistory.isEmail} 
                onValueChange={(value : boolean) => this.binding._dispatcherManager.handleCheckboxChange('isEmail',value)}/>

              <ExtendedCheckBoxView 
                isTranslation
                title={'form.pushnotification'} 
                value={this.state.automatedMessageHistory.isPushNotification} 
                onValueChange={(value : boolean) => this.binding._dispatcherManager.handleCheckboxChange('isPushNotification',value)}/>
            
              <ExtendedCheckBoxView 
                isTranslation
                title={'form.inapp'} 
                value={this.state.automatedMessageHistory.isMessageCenter} 
                onValueChange={(value : boolean) => this.binding._dispatcherManager.handleCheckboxChange('isInApp',value)}/>
            </View>

            {/* Middle line */}
            <LineView/>
            
            {this.state.automatedMessageHistory.automatedMessage.durationInDays !== null &&
              
              <>
              <View>
                <SectionTitleView title={'form.numberofdays'} afterTitle={this.state.automatedMessageHistory.automatedMessage.automatedMessageType.functionName.toUpperCase()}/>
                <SliderView minValue={0} maxValue={365} currentValue={this.state.automatedMessageHistory.automatedMessage.durationInDays} 
                handleChange={(value : number) => this.binding._dispatcherManager.handleNumberChange('duration',value)} backgroundColor='#5f7ef4' />
              </View>
              <LineView/>
              </>
            }

            <SectionTitleView title={'form.titlefield'}/>
            <View style={{ display: "flex", flexDirection: "row", flexWrap: "wrap", justifyContent: 'space-between' }}>
              {/* Title */}
              {this.state.languages.map((content: Language) => {
                var _text = Helpers.getTranslationValueByLanguageKey(this.state.automatedMessageHistory.titleTranslations,content.key)
                return (
                  <ExtendedBlockTextInputView
                    title={content.key.toUpperCase()}
                    value={_text}
                    onChangeText={(text: string) => this.binding._dispatcherManager.handleTranslationsChange('title',text, content.id as number)}
                    showError={this.state.showErrors && (_text == "" || _text == null) }
                  />
                );
              })}
            </View>
          </View>
        }
        {this.state.tab == "message" &&
        <View style={{ paddingHorizontal: 170, paddingVertical: 50 }}>
          <SectionTitleView title={'form.messagefields'}/>
          <View style={{ display: "flex", flexDirection: "row", flexWrap: "wrap", justifyContent: 'space-between'}}>
            {/**Content of message */}
            {this.state.languages.map((content: Language) => {
              var _text = Helpers.getTranslationValueByLanguageKey(this.state.automatedMessageHistory.messageTranslations,content.key)
              return (
                <ExtendedBlockTextInputView
                    title={content.key.toUpperCase()}
                    value={_text}
                    onChangeText={(text: string) => this.binding._dispatcherManager.handleTranslationsChange('message',text,content.id as number)}
                    showError={this.state.showErrors && (_text == "" || _text == null) }
                />
              );
            })}
          </View>
        </View>
        }
        {this.state.showErrors &&
          < View style={this.style.footer} >
            <TranslationView style={{ color: "red" }}>error.please.fields</TranslationView>
          </View>
        }
      </View>
    );
  }
}