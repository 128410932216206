import { ImageStyle, StyleProp, TextStyle, ViewStyle } from 'react-native';
import { ContentViewStyle } from '@levelapp/softfabric';

export default class NoDataPlaceholderViewStyle extends ContentViewStyle {
  container: StyleProp<ViewStyle> = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    marginTop: 40,
  }

  text: StyleProp<TextStyle> = {
    fontSize: 18,
    marginTop: 16,
  }

  image: StyleProp<ImageStyle> = {
    width: 350,
    height: 350,
  }
}