import { StyleProp, TextStyle, ViewStyle } from 'react-native';

import { ContentViewStyle } from '@levelapp/softfabric';

export default class ProductsToolViewStyle extends ContentViewStyle {
  wrapper: StyleProp<ViewStyle> = { 
    position: 'relative',
    backgroundColor: 'white',
    margin: 20,
    marginTop: 35,
  }
  
  container: StyleProp<ViewStyle> = {
    display: 'flex',
    flex: 1,
    backgroundColor: 'white',
    minHeight: '90vh',
    marginTop: 35,
  }
  
  content: StyleProp<ViewStyle> = {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    borderTopWidth: 1,
    borderTopColor: '#E6E6E6',
    height: '100%',
  }

  noData: StyleProp<ViewStyle> = {
    paddingVertical: 25,
  }
  
  leftContent: StyleProp<ViewStyle> = {
    paddingTop: 20,
  }
  
  left: StyleProp<ViewStyle> = {
    display: 'flex',
    flex: 1,
    maxWidth: 500,
    minWidth: 300,
    borderRightColor: '#E6E6E6',
    borderRightWidth: 1,
  }

  right: StyleProp<ViewStyle> = {
    display: 'flex',
    flex: 3,
  }

  category: StyleProp<TextStyle> = {
    color: '#A7A7A7',
    fontSize: 14,
    lineHeight: 19,
    paddingHorizontal: 25,
    paddingTop: 25,
  }
}