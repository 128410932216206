import {ContentViewModel,ContentView} from '@levelapp/softfabric';
import PopupConfirmationViewState from './PopupConfirmationViewState';
import PopupConfirmationViewProps from './PopupConfirmationViewProps';

export default class PopupConfirmationViewModel extends ContentViewModel<PopupConfirmationViewState, PopupConfirmationViewProps>
{
      /* CONSTRUCTOR */
    constructor(view: ContentView)
    {
        super(view);

        this.initialState({}); // Initialize your state here

        // Bindings
        this.handleAcceptPressed = this.handleAcceptPressed.bind(this);
        this.handleBackPopupPressed = this.handleBackPopupPressed.bind(this);
    }

    componentDidMount()
    {
        //TODO: Launch animation in;
    }

    handleBackPopupPressed()
    {
        //TODO: Launch animation out;
        this.props().handleOnPressBack();
    }

    handleAcceptPressed()
    {
        this.props().handleOnPressAccepted();
    }
}