 
import { View } from 'react-native';
import {ContentView} from '@levelapp/softfabric';
import BasketordertypesmanagementViewState from './BasketordertypesmanagementViewState';
import BasketordertypesmanagementViewProps from './BasketordertypesmanagementViewProps';
import BasketordertypesmanagementViewStyle from './BasketordertypesmanagementViewStyle';
import BasketordertypesmanagementViewModel from './BasketordertypesmanagementViewModel';
import Constants from '../../../../../../../../../Common/Constants/Constants';
import PopupHeaderView from '../../../../../../components/popupheader/PopupHeaderView';
import PopupConfirmationView from '../../../../../../../../../Common/Components/popupconfirmation/PopupConfirmationView';
import BasketOrderType from '../../../../../../../../../Common/DTO/BasketOrderType';
import ItemManagementView from '../../../../../../components/itemmanagement/ItemManagementView';
import LineView from '../../../../../../components/line/LineView';
import { TranslationView, ButtonView } from '@levelapp/softfabric-ui';
import ItemLanguageView from '../../../../../../components/itemlanguage/ItemLanguageView';
import Language from '../../../../../../../../../Common/DTO/Language';
import Helpers from '../../../../../../../../../Common/Helpers/Helpers';

export default class BasketordertypesmanagementpopupView extends ContentView<BasketordertypesmanagementViewProps, BasketordertypesmanagementViewState, BasketordertypesmanagementViewModel, BasketordertypesmanagementViewStyle>
{
  /* CONSTRUCTOR */
  constructor(props:BasketordertypesmanagementViewProps)
  {
    super(props);

    // Binding
    this.bind(new BasketordertypesmanagementViewModel(this), new BasketordertypesmanagementViewStyle(this));
  }

  /* RENDERING */
  render()
  {
    const { body, text, createButton}  = this.style;

    return (
      <View style={{ minWidth: 600, minHeight: 850, width: Constants.PopUpWidth, maxWidth: "100%" }}>

        {this.state.showPopup &&
          <PopupConfirmationView 
          title={this.state.labelPopup} 
          content={this.state.contentPopup}
          confirmationPopup={this.state.confirmationPopup} 
          handleOnPressBack={ () => this.binding.handleBackPopupPressed()} 
          handleOnPressAccepted={() =>{ this.binding.deleteBasketOrderType() }} />
        }
       
       <PopupHeaderView shouldShowButton={false} title={"basketOrderTypes.management.header.title"} handleBackPressed={this.binding.handleCloseManagement}/>
       <View style={body}>
          <View style={{ flex: 1, maxHeight: 225, flexWrap: 'wrap' }}>
            {this.state.basketOrderTypes.map((content: BasketOrderType) => {
              return (
                <ItemManagementView onEraseClick={() => this.binding.handleDeleteBasketOrderType(content)}>
                {content.labelTranslations
                    .find(
                  (element : any) => 
                  { 
                    return element.language.key == this.binding._languageManager.language
                  }
                  )?.value}
                  </ItemManagementView>
              );
            })}
          </View>

          <LineView/>

          <TranslationView upperCase style={[text]}>basketOrderTypes.management.title</TranslationView>

          <View style={{ display: "flex", flexDirection: "row", flexWrap: "wrap" }}>
            {this.state.languages.map((content: Language) => {
              return (
                  <ItemLanguageView value={this.state.basketOrderType != undefined && Helpers.getTranslationValueByLanguageKey(this.state.basketOrderType.labelTranslations , content.key)} 
                  language={content.key} 
                  placeholder={this.binding.translate("component.item.language.basketOrderType.placeholder")} 
                  isError={false} 
                  handleOnChangeText={this.binding.handleBasketOrderTypeNameChanged} />
              );
            })}
          </View>         

          <View style={createButton}>
            <ButtonView animation={"Bounce"} borders={"Little"} handleClick={this.binding.handleCreateBasketOrderType} isLoading={this.state.isCreating} backgroundColor='#2196f3'>
              <TranslationView>basketOrderTypes.management.button.add</TranslationView>
            </ButtonView>
          </View>
         
        </View>

      </View>
    );
  }
}