import {ContentViewModel,ContentView} from '@levelapp/softfabric';
import LogTabHeaderViewState from './LogTabHeaderViewState';
import LogTabHeaderViewProps from './LogTabHeaderViewProps';

export default class LogTabHeaderViewModel extends ContentViewModel<LogTabHeaderViewState, LogTabHeaderViewProps>
{
      /* CONSTRUCTOR */
    constructor(view: ContentView)
    {
        super(view);

        this.initialState({}); // Initialize your state here

        // Bindings

    }
}