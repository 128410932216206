 
import { ContentView } from '@levelapp/softfabric';
import RolePermissionPopupViewState from './RolePermissionPopupViewState';
import RolePermissionPopupViewProps from './RolePermissionPopupViewProps';
import RolePermissionPopupViewStyle from './RolePermissionPopupViewStyle';
import RolePermissionPopupViewModel from './RolePermissionPopupViewModel';
import { TranslationView } from '@levelapp/softfabric-ui';
import { View } from 'react-native';
import Constants from '../../../../../../../Common/Constants/Constants';
import PopupHeaderView from '../../../../components/popupheader/PopupHeaderView';
import PopupMenuItemView from '../../../../components/popupmenuitem/PopupMenuItemView';
import MainInformationView from './components/maininformation/MainInformationView';
import PermissionView from './components/permission/PermissionView';
import Permission from '../../../../../../../Common/DTO/Permission';
import PermissionsManagementView from './pages/permissionsmanagement/PermissionsManagementView';

export default class RolePermissionPopupView extends ContentView<RolePermissionPopupViewProps, RolePermissionPopupViewState, RolePermissionPopupViewModel, RolePermissionPopupViewStyle>
{
  /* CONSTRUCTOR */
  constructor(props: RolePermissionPopupViewProps) {
    super(props);

    // Binding
    this.bind(new RolePermissionPopupViewModel(this), new RolePermissionPopupViewStyle(this));
  }

  /* RENDERING */
  render() {
    switch(this.state.windowTab)
    {
      case 'main' :
          return (
            <View style={{ minWidth: 600, minHeight: 850, width: Constants.PopUpWidth, maxWidth: "100%" }}>
      
              <PopupHeaderView
              shouldShowButton={true} 
              title={"rolepermissions.popup.title"} 
              isLoading={this.state.isLoading}
              handleOnPressButton={this.binding.handleSavePressed}
              handleBackPressed={this.binding.handleBackPressed}/>
      
              {/*Tabs*/}
              <View style={{ display: "flex", flexDirection: "row", flexWrap: "wrap" }}>
                <PopupMenuItemView activeItem={this.state.tab} title={"form.maininformation"} item={"main"} handleChangeTab={() => this.binding.changeTab("main")}/>
                <PopupMenuItemView activeItem={this.state.tab} title={"form.permissions"} item={"permission"} handleChangeTab={() => this.binding.changeTab("permission")}/>
              </View>
      
              {this.state.tab == "main" &&
                <MainInformationView role={this.state.role} showError={this.state.showErrors} handleChangeText={this.binding.handleFieldChanged}/>
              }
              {this.state.tab == "permission" &&
                <PermissionView 
                permissions={this.state.role.rolePermissions} 
                allPermissions={this.state.allPermissions} 
                navigateOnThePopup={() => this.binding.handleNavigationTo('permissions')}
                handlePermissions={(bool : boolean , permission : Permission) => this.binding.handleChangePermission(bool , permission)} 
                isLoading={this.state.isLoadingPermissions}/>
              }
      
              
              {this.state.showErrors &&
                < View style={this.style.footer} >
                  <TranslationView style={{ color: "red" }}>error.please.fields</TranslationView>
                </View>
              }
            </View>
          );
      case 'permissions':
        return (<PermissionsManagementView
                handleBackPressed={() => this.binding.handleNavigationTo('main')}
        />);  
      default:
        return (<View></View>);  
    }
    
  }
}