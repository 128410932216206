import { StyleProp, TextStyle, ViewStyle } from 'react-native';
import { ContentViewStyle } from '@levelapp/softfabric';

export default class RemoveZoneViewStyle extends ContentViewStyle {
  container: StyleProp<ViewStyle> = {
    display: 'flex',
    justifyContent: 'space-between',
    marginHorizontal: 50,
    minWidth: 600,
    minHeight: 300,
    paddingBottom: 25
  }


  title: StyleProp<TextStyle> = {
    fontSize: 26,
    marginTop: 35,
  }

  message: StyleProp<TextStyle> = {
    marginTop: 10,
  }

  buttons: StyleProp<ViewStyle> = {
    display: 'flex',
    flexDirection: 'row',
    marginTop: 15,
  }

  addButton: StyleProp<ViewStyle> = {
    width: 160,
    height: 44,
  }

  cancelButton: StyleProp<ViewStyle> = {
    width: 160,
    height: 44,
    marginLeft: 15,
  }
}