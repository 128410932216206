import { ContentViewModel, ContentView } from '@levelapp/softfabric';
import StoresViewState from './StoresViewState';
import StoresViewProps from './StoresViewProps';

export default class StoresViewModel extends ContentViewModel<StoresViewState, StoresViewProps>
{
    constructor(view: ContentView)
    {
        super(view);
        /* Initial State */
        this.initialState({});

        this.addAllStores = this.addAllStores.bind(this);
    }

    addAllStores()
    {
        const { stores } = this.props();
        this.props().handleValueChange('store', stores);
    }
}