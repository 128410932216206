 
import { View } from "react-native";

import { ContentView } from "@levelapp/softfabric";
import ButtonImportFileView from '../../../../../../../Common/Components/ButtonImportFileWebView/ButtonImportFileWebView';
import { TranslationView } from "@levelapp/softfabric-ui";

import Constants from "../../../../../../../Common/Constants/Constants";
import ButtonView from "../../../../../../../Common/Components/Button/ButtonView";

import ProductCategoriesViewProps from "./ProductCategoriesViewProps";
import ProductCategoriesViewState from "./ProductCategoriesViewState";
import ProductCategoriesViewModel from "./ProductCategoriesViewModel";
import ProductCategoriesViewStyle from "./ProductCategoriesViewStyle";

import TableView from '../../../../../../../Common/Components/TabelView/TableView';
import ResearchInputView from '../../../../../../../Common/Components/researchinput/ResearchInputView';
import HeaderView from "../../../../components/header/HeaderView";

export default class ProductCategoriesView extends ContentView<ProductCategoriesViewProps, ProductCategoriesViewState, ProductCategoriesViewModel, ProductCategoriesViewStyle>
{
  /*CONSTRUCTOR */
  constructor(props: ProductCategoriesViewProps) {
    super(props);

    //Binding 
    this.bind(new ProductCategoriesViewModel(this), new ProductCategoriesViewStyle(this));
  }

  /* RENDERING */
  render() {
    return (
      <View style={this.style.container}>
        <View style={this.style.box}>

          <HeaderView
            title={"products.categories.title"}
            description={"products.categories.description"}
            onButtonPress={this.binding.addField}
            buttonText="products.categories.button.add"
            buttonText2="form.export"
            shouldShowButton
            othersButtons={Constants.ShowImportExportProductCategories ? (
              <>
                <View style={this.style.buttonBox}>
                  <View style={this.style.exportButton}>
                    <ButtonImportFileView
                      buttonColor="white"
                      textColor={Constants.MainColor}
                      accepted=".xlsx"
                      action={(file: any) => this.binding.handleFile(file)}
                    />
                  </View>
                </View>
                <View style={this.style.buttonBox}>
                  <View style={this.style.exportButton}>
                    <ButtonView
                      isLoading={false}
                      handleClick={this.binding.export}
                      backgroundColor='white'
                      textColor='#FE7E5C'
                    >
                      <TranslationView>
                        form.export
                      </TranslationView>
                    </ButtonView>
                  </View>
                </View>
              </>
            ) : <></>}
          />

          <View style={this.style.boxContainer}>
            <ResearchInputView
              placeholder={this.binding.translate('generic.search')}
              handlerResearching={(searchText: string) => { this.binding.handleResearching(searchText) }}
              handlingClose={() => { this.binding.handleCloseResearching() }} />
            <TableView
              headerTextStyle={this.style.tableHeader}
              rowStyle={this.style.rowStyle}
              backgroundStepColor={this.style.stepColor}
              nothingToDisplayText={this.binding.translate("component.table.noresults")}
              isLastColumnActions={true}
              data={this.state.tableData}
              isLoading={this.state.isLoading}
              headers={this.state.tableHeaders}
              onPageChange={(page: number) => this.binding.onPageChange(page)}
              handleSortChange={(indexHeaderSort: number, sortOrder: string) => { this.binding.onSortChange(indexHeaderSort, sortOrder) }}
              nbPage={this.state.nbPage}
              currentPage={this.state.currentPage - 1}
              isSortable={true} />
          </View>
        </View>
      </View>

    );
  }
}