import {ContentViewStyle, Application} from '@levelapp/softfabric';
import {StyleProp,ViewStyle, TextStyle} from 'react-native';
import { StyleManager, UiManagers } from '@levelapp/softfabric-ui';

export default class ProductsViewStyle extends ContentViewStyle
{
    
    MyStyleManager : StyleManager = Application.current.resolve<StyleManager>(UiManagers.STYLE_MANAGER);

    page: StyleProp<ViewStyle> = {
        flex: 1
    }
    container: StyleProp<ViewStyle> = {
        display: 'flex',
        flex: 1,
        alignItems: 'center'
    }
    box: StyleProp<ViewStyle> = {
        width: '95%',
        backgroundColor: 'white',
        borderRadius: 6,
        marginTop: 40,
        shadowColor: "#0000006b",
        shadowOffset: {
            width: 1,
            height: 0,
        },
        shadowOpacity: 0.70,
        shadowRadius: 20.00,
        elevation: 60,
    }

    
    boxContainer: StyleProp<ViewStyle> = {
        display: 'flex',
        alignSelf: 'center',
        minHeight: 250,
        width: '100%',
        paddingTop: 30,
        paddingBottom: 30,
        paddingLeft: 50,
        paddingRight: 50,
    }
    tableHeader : StyleProp<TextStyle> =
    {
        color: '#575757',
        fontSize : 15,
        fontFamily : 'Montserrat',
        fontWeight : 'bold',
    }
    rowStyle: StyleProp<ViewStyle> =
    {
        minHeight: 170,
    }
    stepColor : StyleProp<ViewStyle> =
    {
        backgroundColor : this.MyStyleManager.primaryColor,
    }}