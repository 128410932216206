 
import {ContentView} from '@levelapp/softfabric';
import LtoViewState from './LtoViewState';
import LtoViewProps from './LtoViewProps';
import LtoViewStyle from './LtoViewStyle';
import LtoViewModel from './LtoViewModel';
import { TranslationView} from '@levelapp/softfabric-ui';
import { View } from 'react-native';
import Language from '../../../../../../../../../Common/DTO/Language';
import Helpers from '../../../../../../../../../Common/Helpers/Helpers';
import ExtendedBlockTextInputView from '../../../../../../components/extendedblocktextinput/ExtendedBlockTextInputView';
import SectionTitleView from '../../../../../../components/sectiontitle/SectionTitleView';
import ExtendedDatePickerView from '../../../../../../components/extendeddatepicker/ExtendedDatePickerView';
import ExtendedSelectView from '../../../../../../components/checkboxheaderselection/extendedselect/ExtendedSelectView';
import LTO from '../../../../../../../../../Common/DTO/LTO';
import ButtonView from '../../../../../../../../../Common/Components/Button/ButtonView';
import LineView from '../../../../../../components/line/LineView';

export default class LtoView extends ContentView<LtoViewProps, LtoViewState, LtoViewModel, LtoViewStyle>
{
  /* CONSTRUCTOR */
  constructor(props:LtoViewProps)
  {
    super(props);

    // Binding
    this.bind(new LtoViewModel(this), new LtoViewStyle(this));
  }

  /* RENDERING */
  render()
  {

    const { container } = this.style;

    return (
      <View style={container}>
            {this.props.booster.ltos.map((lto: LTO, i: any) => {
              return (

                <View style={{ display: 'flex', flexDirection: "column", flexWrap: 'wrap',  marginBottom: 15, zIndex: (i == 0 ? 0 : -i) }}>
                  <SectionTitleView title={'form.titlefield'}/>
                  <View style={{ flexDirection: "row", flexWrap: 'wrap' , justifyContent: 'space-between' }}>
                    {this.props.languages.map((content: Language) => {

                      var _text = Helpers.getTranslationValueByLanguageKey(lto.titleTranslations,content.key);
                      return (
                        <ExtendedBlockTextInputView
                          title={content.key.toUpperCase()}
                          value={_text}
                          showError={this.props.showErrors && (_text==null || _text=="")}
                          onChangeText={(text) => this.props.handleTitleLTOChanged(text, content.key, i)}
                        />
    
                      )
                    })}
                  </View>

                  <View style={{ flexDirection: "row" , flexWrap: 'wrap'}}>
                    <ExtendedDatePickerView
                      zIndex={2}
                      title={'form.startdate'}
                      selected={lto.StartDateInput}
                      placeholder={this.binding.translate('form.startdate.placeholder')}
                      handleOnChange={(date : Date) =>
                        {
                          // this.binding._refEndDate.getInnerRef().input.focus();
                          this.props.handleDateChange('startdate',date,i);
                        } }
                    />

                    <ExtendedDatePickerView
                      zIndex={1}
                      title={'form.enddate'}
                      selected={lto.EndDateInput}
                      // ref={(r : any) => this.binding._refEndDate = r}
                      
                      placeholder={this.binding.translate('form.enddate.placeholder')}
                      handleOnChange={(date : Date) => this.props.handleDateChange('enddate',date,i)}
                    /> 
                  </View>

                  <View style={{ flexDirection: "row" , flexWrap: 'wrap', zIndex: this.state.zIndexSelect}}>
                    <ExtendedSelectView
                      title={'posId'}
                      label="search"
                      value={lto.posIds}
                      onDropdownOpen={this.binding.onDropDownOpen}
                      onDropdownClose={this.binding.onDropDownClose}
                      disabled={false}
                      shouldShowError={false}
                      onChange={(value: any) => this.props.handleValueChange('posid',value, i)}
                      placeholder={''}
                      options={this.props.posids}
                    />
                    {/* <View style={this.style.section}>
                      <TranslationView style={this.style.inputTitle} upperCase>LTO.POSID</TranslationView>
                      <Select
                        portal={document.body}
                        values={this.state.booster.ltos[i].posIds}
                        options={this.binding.getPos()}
                        onChange={(value: any) => this.binding._dispatcherManager.handleValueChange('posid',value, i)}
                        searchable={true}
                        searchBy="search"
                        keepSelectedInList={true}
                        labelField="search"
                        valueField="posId"
                        dropdownPosition="auto"
                        multi={true}
                        clearable={true}
                      />
                    </View> */}
                  </View>
                  <View style={{ flexDirection: "row", flexWrap: 'wrap', zIndex: -3 }}>
                    <View style={[ { justifyContent: "flex-end" }]} >
                      <ButtonView isLoading={false} backgroundColor="#b44035" handleClick={() => this.props.deleteLto(i)}>
                        <TranslationView>generic.delete</TranslationView> 
                      </ButtonView>
                    </View>
                  </View>
                  <LineView/>
                </View>
              );
            })}
            <View style={{ display: 'flex', flexDirection: "row", zIndex: -5 }}>
              <View style={{ flexDirection: "column" }}>
                <ButtonView isLoading={false} handleClick={() => this.props.addLto()}><TranslationView>generic.add</TranslationView></ButtonView>
              </View>
            </View>

          </View>
    );
  }
}