import { StyleProp, TextStyle, ViewStyle } from 'react-native';

import { ContentViewStyle } from '@levelapp/softfabric';
import Constants from 'src/Common/Constants/Constants';

export default class HeaderViewStyle extends ContentViewStyle {
  header: StyleProp<TextStyle> = {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: 24,
  }

  title: StyleProp<TextStyle> = {
    fontSize: 26,
    lineHeight: 35,
  }
   
  disabledButton: StyleProp<ViewStyle> = {
    backgroundColor: 'grey',
    padding: 12,
    borderRadius: 10,
  }

  saveButton: StyleProp<ViewStyle> = {
    display: 'flex',
    alignItems: 'center',
    backgroundColor: Constants.MainColor,
    padding: 10,
    borderRadius: 10,
    width: 200,
  }
 
}