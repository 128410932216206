 
import { ContentView } from '@levelapp/softfabric';
import { TranslationView } from '@levelapp/softfabric-ui';
import { View,  } from 'react-native';
import "react-datepicker/dist/react-datepicker.css";
import 'react-datepicker/dist/react-datepicker-cssmodules.css';
import Constants from '../../../../../../../Common/Constants/Constants';
import PopupMenuItemView from '../../../../components/popupmenuitem/PopupMenuItemView';
import Helpers from '../../../../../../../Common/Helpers/Helpers';
import PopupHeaderView from '../../../../components/popupheader/PopupHeaderView';
import StatsSettingsPopupViewProps from './StatsSettingsPopupViewProps';
import StatsSettingsPopupViewModel from './StatsSettingsPopupViewModel';
import StatsSettingsPopupViewStyle from './StatsSettingsPopupViewStyle';
import StatsSettingsPopupViewState from './StatsSettingsPopupViewState';
import MainInformationView from './components/maininformation/MainInformationView';

export default class StatsSettingsPopupView extends ContentView<StatsSettingsPopupViewProps, StatsSettingsPopupViewState, StatsSettingsPopupViewModel, StatsSettingsPopupViewStyle>
{
  /* CONSTRUCTOR */
  constructor(props: StatsSettingsPopupViewProps)
  {
    super(props);

    // Binding
    this.bind(new StatsSettingsPopupViewModel(this), new StatsSettingsPopupViewStyle(this));
  }

  /* RENDERING */
  render()
  {
    return (
      <View style={{ minWidth: 600, minHeight: 850, width: Constants.PopUpWidth, maxWidth: "100%" }}>

        <PopupHeaderView
          handleBackPressed={Helpers.closePopup}
          title={"stats.settings.popup.title"}
          shouldShowButton
          isLoading={this.state.isLoading}
          handleOnPressButton={this.binding.handleSavePressed}
        />      

        <View style={{ display: "flex", flexDirection: "row", flexWrap: "wrap" }}>
          <PopupMenuItemView activeItem={this.state.tab} title={"form.maininformation"} item={"main"} handleChangeTab={() => this.binding.changeTab("main")}/>

        </View>

        {this.state.tab == "main" &&
          <MainInformationView
            dashboard={this.state.dashboard}
            dashboardType={this.props.dashboardType}
            handleDropDownChange={this.binding.handleChangeDashboardType}
            handleValueChange={this.binding._dispatcherManager.handleValueChange}
          />

        }

       
        {this.state.showErrors &&
          < View style={this.style.footer} >
            <TranslationView style={{ color: "red" }}>error.please.fields</TranslationView>
          </View>
        }
      </View>
    );
  }
}