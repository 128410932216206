
import LoginViewState from './LoginViewState';
import LoginViewProps from './LoginViewProps';
import LoginViewStyle from './LoginViewStyle';
import LoginViewModel from './LoginViewModel';
import { View, TextInput, Text } from 'react-native';
import { UiManagers, StyleManager, TranslationView } from '@levelapp/softfabric-ui';
import { ContentView, Application } from '@levelapp/softfabric';
import Constants from '../../../Common/Constants/Constants';
import ButtonView from '../../../Common/Components/Button/ButtonView';


export default class LoginView extends ContentView<LoginViewProps, LoginViewState, LoginViewModel, LoginViewStyle>
{
  MyManager = Application.current.resolve<StyleManager>(UiManagers.STYLE_MANAGER);
  /* CONSTRUCTOR */
  constructor(props: LoginViewProps) {
    super(props);

    // Binding
    this.bind(new LoginViewModel(this), new LoginViewStyle(this));
  }

  /* RENDERING */
  render() {
    return (
      <View style={this.style.container}>

        <View style={this.style.loginBox}>
          <View style={this.style.boxTitle}>
            <Text style={this.style.title}>
              {Constants.ProjectName} <TranslationView>login.title</TranslationView>
            </Text>
          </View>

          <View style={this.style.loginContainer}>
            <TextInput style={this.style.textInput} placeholder={this.binding.translate('form.email.placeholder')} onChangeText={(email: string) => this.binding.handleEmailChanged(email)} value={this.state.email} />
            <TextInput onKeyPress={this.binding.handleEnter} style={this.style.textInput} secureTextEntry={true} placeholder={this.binding.translate('form.password.placeholder')} onChangeText={(pwd: string) => this.binding.handlePasswordChanged(pwd)} value={this.state.password} />
            <ButtonView handleClick={this.binding.login} isLoading={this.state.isLoading} textColor="white" backgroundColor={this.MyManager.primaryColor}>
              <TranslationView>
                login.button
              </TranslationView>
            </ButtonView>
          </View>
        </View>
      </View>
    );
  }
}