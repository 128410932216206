import {ContentViewStyle} from '@levelapp/softfabric';
import {Dimensions, StyleProp,ViewStyle} from 'react-native';

export default class TestPopupViewStyle extends ContentViewStyle
{
    page: StyleProp<ViewStyle> = {
        flex: 1
    }

    popup : StyleProp<ViewStyle> =
    {
        minHeight : (Dimensions.get('window').height /100) * 80 , 
        width : '100%'
    }

    container : StyleProp<ViewStyle> =
    {
        display: "flex", 
        flexDirection : "column",
        paddingHorizontal: 170, 
        paddingVertical: 50,
        width : '100%' ,
        alignItems : "flex-start"
    }

    addSection : StyleProp<ViewStyle> =
    {
        display : 'flex',
        flexDirection : "row",
        alignItems : "center",
        justifyContent : "center",
    }
}