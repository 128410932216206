import {ContentViewModel,ContentView} from '@levelapp/softfabric';
import LanguagePopUpViewState from './LanguagePopUpViewState';
import LanguagePopUpViewProps from './LanguagePopUpViewProps';
import DispatcherManager from '../../../../../../../Common/Managers/DispatcherManager';
import LanguagesOperations from '../../../../../../../Transfer/LanguagesOperations';
import ToastHelper from '../../../../../../../Common/Helpers/ToastHelper';
import ErrorHelper from '../../../../../../../Common/Helpers/ErrorHelper';

export default class LanguagePopUpViewModel extends ContentViewModel<LanguagePopUpViewState, LanguagePopUpViewProps>
{
    _dispatcherHelper : DispatcherManager;
    _languagesOperations : LanguagesOperations;
      /* CONSTRUCTOR */
    constructor(view: ContentView)
    {
        super(view);
        

        this.initialState({
            isLoading: false,
            language: this.props().language,
            showErrors : false
        });

        this._dispatcherHelper = new DispatcherManager();
        this._languagesOperations = new LanguagesOperations();


        // Bindings
        this.errorManagement = this.errorManagement.bind(this);
        this.handleSavePressed = this.handleSavePressed.bind(this);
        this.handleDefaultChanged = this.handleDefaultChanged.bind(this);
        this.handleActiveChanged = this.handleActiveChanged.bind(this);
        this.handleLanguageKeydChanged = this.handleLanguageKeydChanged.bind(this);

        
        

    }
    componentDidMount()
    {
        this._dispatcherHelper.checkboxIsLanguageDefault = this.handleDefaultChanged;
        this._dispatcherHelper.checkboxIsLanguageActive = this.handleActiveChanged;

        this._dispatcherHelper.valueLanguageKey = this.handleLanguageKeydChanged;

    }

    handleSavePressed()
    {
        if (
            this.state().language.key == ' ' )
        {
            this.setField((previousState) => previousState.showErrors = true);
        }
        else
        {
            this.setField((previousState) => previousState.isLoading = true,() => this.saveLanguage(this.state().language));
        }
    }

    saveLanguage(language: any) { 
        if (language.id !== undefined) {
            this._languagesOperations.put(language, () => {
                this.props().refreshParent();
                this.showToast(ToastHelper.getToast("language.popup.success.updated","success"),() => {},2500);
                this.closePopUp();
            },this.errorManagement);
        }

        else {
            this._languagesOperations.post(language, () => {
                this.props().refreshParent();
                this.showToast(ToastHelper.getToast("language.popup.success.added","success"),() => {},2500);
                this.closePopUp();
            },this.errorManagement);
        }
    }

    errorManagement(error : any) 
    {
        this.setField({isLoading : false});
        ErrorHelper.showMessageError(error.response);
    }

    private handleDefaultChanged(value: boolean)
    {
        this.setField((previousState) => previousState.language.isDefault = value);
    }

    private handleActiveChanged(value: boolean)
    {
        this.setField((previousState) => previousState.language.isActive = value);
    }
    private handleLanguageKeydChanged(text: string)
    {
        this.setField((previousState) => previousState.language.key = text);
    }
    

}