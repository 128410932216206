 
import { CheckBox, View } from 'react-native';
import { ContentView } from '@levelapp/softfabric';
import { TranslationView } from '@levelapp/softfabric-ui';
import CountriesViewState from './CountriesViewState';
import CountriesViewProps from './CountriesViewProps';
import CountriesViewStyle from './CountriesViewStyle';
import CountriesViewModel from './CountriesViewModel';
import Select from "react-dropdown-select";
import SectionTitleView from '../../../../../../components/sectiontitle/SectionTitleView';
import LineView from '../../../../../../components/line/LineView';

export default class CountriesView extends ContentView<CountriesViewProps, CountriesViewState, CountriesViewModel, CountriesViewStyle>
{
  /* CONSTRUCTOR */
  constructor(props: CountriesViewProps)
  {
    super(props);

    // Binding
    this.bind(new CountriesViewModel(this), new CountriesViewStyle(this));
  }

  /* RENDERING */
  render()
  {
    return (
      <View style={this.style.container}>
        <SectionTitleView title={'form.countries'} />
        <View>
          <View style={{ flexDirection: 'row' }}>
            <CheckBox
              value={this.state.isForAllCountries}
              onValueChange={this.binding.handleCheckBoxChanged}
            />
            <TranslationView style={{ marginLeft: 5 }}>coupons.countries.enableforall</TranslationView>
          </View>

          {/* List of Countries*/}
          {!this.state.isForAllCountries &&
            <View>
              <LineView />
              <Select
                placeholder="Select countries"
                color={this.state.color}
                values={this.state.selectedCountries}
                options={this.props.countries}
                onChange={(value: any) => this.props.handleValueChange('country', value)}
                multi={true}
                create={false}
                keepSelectedInList={false}
                clearable={true}
                labelField={"countryCode"}
                valueField={"id"}
                searchBy={"countryCode"}
              />
            </View>
          }
        </View>
      </View >
    );
  }
}