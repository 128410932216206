import { ContentViewStyle } from '@levelapp/softfabric';
import { StyleProp, ViewStyle, TextStyle } from 'react-native';

export default class DashboardshellViewStyle extends ContentViewStyle
{
    container: StyleProp<ViewStyle> = {
        display: 'flex',
        height: "100%",
        backgroundColor: '#eee',
        flexDirection: 'row'
    }

    viewContainer: StyleProp<ViewStyle> = {
        height: "100vh",
        zIndex: 20
    }

    header: StyleProp<ViewStyle> = {
        height: 70,
        display: 'flex',
        justifyContent: 'center'
    }

    headerTitle: StyleProp<TextStyle> = {
        marginLeft: 20,
        fontSize: 18,
        fontWeight: '100'
    }
}