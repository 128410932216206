import { ContentViewModel, ContentView, Application, LanguageManager, Managers } from '@levelapp/softfabric';
import MainInformationViewState from './MainInformationViewState';
import MainInformationViewProps from './MainInformationViewProps';
import VALUE_TYPES from './ValueTypesEnum';
import CouponsOperations from '../../../../../../../../../Transfer/CouponsOperations';
import PaginationHelper from '../../../../../../../../../Common/Helpers/PaginationHelper';
import Coupon from '../../../../../../../../../Common/DTO/Coupon';

export default class MainInformationViewModel extends ContentViewModel<MainInformationViewState, MainInformationViewProps>
{

    operation: CouponsOperations;

    /* CONSTRUCTOR */
    constructor(view: ContentView) {
        super(view);


        this.initialState({
            valueType: this.determineValueType(this.props().savingCard.points, this.props().savingCard.couponId),
            coupons: []
        }); // Initialize your state here

        this.operation = new CouponsOperations();

        // Bindings
        this.setValueType = this.setValueType.bind(this);

    }

    setValueType(value: VALUE_TYPES) {
        this.setField({
            valueType: value
        });
    }

    determineValueType(points: number | undefined, couponId: number | undefined): VALUE_TYPES {
        if (points && points > 0) return VALUE_TYPES.POINTS;
        if (couponId && couponId > 0) return VALUE_TYPES.COUPON_ID;
        return VALUE_TYPES.COUPON_POS;
    }

    componentDidMount() {
        let parameters = PaginationHelper.basicResearchPush(
            0,
            200,
            "",
            "",
            Application.current.resolve<LanguageManager>(Managers.LANGUAGE_MANAGER).language,
            "",
            false,
            false);
        this.operation.get(parameters, (coupons: any) => {
            this.setField({ coupons: coupons.data.objects });
        }, () => { });
    }

    changeTypesToDropdown() {
        var structure: any[] = [];
        this.state().coupons.map((value: Coupon) => {
            let title = ""
            if (value.titleTranslations)
                title = value.titleTranslations[0].value;
            structure.push({ title: value.id + " " + title, value: value.id });

        })
        return structure;
    }
}