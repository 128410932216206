 
import {ContentShellView} from '@levelapp/softfabric';
import ShellAutomatedMessagesViewState from './ShellAutomatedMessagesViewState';
import ShellAutomatedMessagesViewProps from './ShellAutomatedMessagesViewProps';
import ShellAutomatedMessagesViewStyle from './ShellAutomatedMessagesViewStyle';
import ShellAutomatedMessagesViewModel from './ShellAutomatedMessagesViewModel';
import {View} from 'react-native';

export default class ShellAutomatedMessagesView extends ContentShellView<ShellAutomatedMessagesViewProps, ShellAutomatedMessagesViewState, ShellAutomatedMessagesViewModel, ShellAutomatedMessagesViewStyle>
{
  /* CONSTRUCTOR */
  constructor(props:ShellAutomatedMessagesViewProps)
  {
    super(props);

    // Binding
    this.bind(new ShellAutomatedMessagesViewModel(this), new ShellAutomatedMessagesViewStyle(this));
  }

  /* RENDERING */
  render()
  {
    return (
      <View style={{flex : 1}}>
        {this.getSwitch()}
      </View>
    );
  }
}