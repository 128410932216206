 
import {ContentView} from '@levelapp/softfabric';
import CrossSellConfiguratorViewState from './CrossSellConfiguratorViewState';
import CrossSellConfiguratorViewProps from './CrossSellConfiguratorViewProps';
import CrossSellConfiguratorViewStyle from './CrossSellConfiguratorViewStyle';
import CrossSellConfiguratorViewModel from './CrossSellConfiguratorViewModel';
import { View,Image } from 'react-native';
import SortableTree from 'react-sortable-tree';
import 'react-sortable-tree/style.css';
import FileExplorerTheme from 'react-sortable-tree-theme-full-node-drag';
import './composition.css'
import ReactLogo from './assets/delete.svg';
import ReactLogoPlus from './assets/add.svg';
import ReactLogoTag from './assets/tag.svg';
import ReactLogoTagOne from './assets/tagOne.svg';
import { LottieView, TranslationView } from '@levelapp/softfabric-ui';
import Constants from '../../../../../../../Common/Constants/Constants';
import ButtonView from '../../../../../../../Common/Components/Button/ButtonView';
import LinearGradient from 'react-native-web-linear-gradient';


export default class CrossSellConfiguratorView extends ContentView<CrossSellConfiguratorViewProps, CrossSellConfiguratorViewState, CrossSellConfiguratorViewModel, CrossSellConfiguratorViewStyle>
{
  /* CONSTRUCTOR */
  constructor(props:CrossSellConfiguratorViewProps)
  {
    super(props);

    // Binding
    this.bind(new CrossSellConfiguratorViewModel(this), new CrossSellConfiguratorViewStyle(this));
  }

  /* RENDERING */
  render()
  {
    return (
      <View style={this.style.container}>
        <View style={this.style.box}>
          
          <LinearGradient start={{x: 0, y: 0}} end={{x: 1, y: 0}} colors={[Constants.GradiantStartColor, Constants.GradiantEndColor]} style={this.style.boxTitle}>
            <View style={this.style.boxSubtitle}>
              <TranslationView style={this.style.title}>
              crosssellconfig.title
              </TranslationView>

              <TranslationView style={this.style.subTitle}>
              crosssellconfig.description
              </TranslationView>
            </View>
            <View style={this.style.buttonBox}>
            <ButtonView style={{margin:5}} isLoading={false} handleClick={() => {this.binding.displayViewAddProduct(null)}} backgroundColor='white' textColor={Constants.MainColor}>
                <TranslationView upperCase={true}>
                menuconfigurator.button.add
                </TranslationView>
              </ButtonView>
              <ButtonView isLoading={this.state.saveLoading} handleClick={() => {this.binding.saveComposition()}} backgroundColor='white' textColor={Constants.MainColor}>
                <View style={{flexDirection:"row"}}>
                
                <TranslationView upperCase={true}>
                menuconfigurator.button.save
                </TranslationView>
                {this.state.treeModified ?(
                <Image
                  style={{marginLeft:10, alignItems:"flex-end",width:18,height:18}}
                  source={require('./assets/exclamation.svg').default}
                />
                ):(
                  <Image
                  style={{marginLeft:10, alignItems:"flex-end",width:18,height:18}}
                  source={require('./assets/checked.svg').default}
                />
                )
                }
                </View>
              </ButtonView>
              
            </View>
          </LinearGradient>
          
          <View style={this.style.boxContainer}>
          {!this.state.isLoading ? (
            <View style={{height:600}}>

              <SortableTree
                    style={{}}
                    treeData={this.state.treeData}
                    onChange={(treeData:any) => {this.binding.setField({ treeData:treeData })}}
                    theme={FileExplorerTheme}
                    canNodeHaveChildren={ (node:any) => { return node.group }}
                    onMoveNode={(moveDetail:any)=>{ this.binding.changeParentInTree(moveDetail);}}
                    canDrop={(node:any)=>{ return this.binding.canDrop(node);}}
                    generateNodeProps={(node:any)=>{
                      if(node.node.group && this.binding.haveChildrenGroup(node)){
                      return ({title:(<div className="title">
                                          <img className="titleImg" src={ReactLogoTag}/>
                                          <span className="titleText"> {node.node.title}</span>
                                      </div>),
                      buttons:[
                          <img id="test" width="40" height="25" src={ReactLogoPlus} onClick={()=>{this.binding.displayViewAddProduct(node)}}/>,
                          <img id="test" width="40" height="25" src={ReactLogo} onClick={()=>{this.binding.deletePopupVerification(node);}}/>]
                      })
                    }else if(node.node.group){
                      return ({title:(<div className="title">
                                          <img className="titleImg" src={ReactLogoTag}/>
                                          <span className="titleText"> {node.node.title}</span>
                                      </div>),
                      buttons:[
                          <img id="test" width="40" height="25" src={ReactLogoPlus} onClick={()=>{this.binding.displayViewAddProduct(node)}}/>,
                          <img id="test" width="40" height="25" src={ReactLogo} onClick={()=>{this.binding.deletePopupVerification(node);}}/>]
                      })
                    }
                    else{
                      return ({title:(<div className="title"><img className="titleImg" src={ReactLogoTagOne}/><span className="titleText"> {node.node.title}</span></div>),
                        buttons:[<img id="test" width="40" height="25" src={ReactLogo} onClick={()=>{this.binding.deletePopupVerification(node)}}/>
                        ]
                        })
                    }
                    }}
              />
            </View>
            )
            :
            (
              <LottieView height={150} width={150} source={require('../../../../../../../Common' + Constants.LottieAnimationPath)} />
            )}
          </View>
        </View>
      </View>
    );
  }
}