import { ReferenceTypeEnum } from "../Enums/ReferenceTypeEnum";

export default class ReferenceTypeHelper
{
    static changeEnumToText(enumerator : ReferenceTypeEnum)
    {
        switch(enumerator)
       {
            case ReferenceTypeEnum.BASKET :
               return 'referencetypeenum.basket';
            case ReferenceTypeEnum.BOOSTER : 
               return 'referencetypeenum.booster'
            case ReferenceTypeEnum.COUPON :
                return 'referencetypeenum.coupon'
            case ReferenceTypeEnum.PAYMENT:
                return 'referencetypeenum.payment'
            case ReferenceTypeEnum.POS:
                return 'referencetypeenum.pos'
            case ReferenceTypeEnum.REWARDS:
                return 'referencetypeenum.rewards'
            default :
                return 'No Text'
       }
    }

    static onlyUniqueReferenceType(array  : any , isText : boolean) {
        var flags = [], output : any[] = [], l = array.length, i;
        for( i=0; i<l; i++) {
            if( flags[array[i].referenceTypeId]) continue;
            flags[array[i].referenceTypeId] = true;
            output.push(array[i].referenceTypeId);
        }
        if(isText)
        {
            return this.changeEnumsToText(output)
        }else
        {
            return output;
        }
    }

    static changeEnumsToText(enumerators : any[])
    {
        var text : string[] = []
        for(var i = 0 ; i<enumerators.length;i++)
        {
            text.push(this.changeEnumToText(enumerators[i]));
        }
        return text;
    } 
}