 
import {ContentView} from '@levelapp/softfabric';
import GenericSettingsSectionViewState from './GenericSettingsSectionViewState';
import GenericSettingsSectionViewProps from './GenericSettingsSectionViewProps';
import GenericSettingsSectionViewStyle from './GenericSettingsSectionViewStyle';
import GenericSettingsSectionViewModel from './GenericSettingsSectionViewModel';
import {  View,Text } from 'react-native';
import SectionTitleView from '../sectiontitle/SectionTitleView';
import ExtendedTranslationView from '../extendedtranslation/ExtendedTranslationView';
import { TranslationView } from '@levelapp/softfabric-ui';
import ContentTypeKeyValue from '../../../../../Common/DTO/ContentTypeKeyValue';
import ExtendedTextInputView from '../extendedtextinput/ExtendedTextInputView';

export default class GenericSettingsSectionView extends ContentView<GenericSettingsSectionViewProps, GenericSettingsSectionViewState, GenericSettingsSectionViewModel, GenericSettingsSectionViewStyle>
{
  /* CONSTRUCTOR */
  constructor(props:GenericSettingsSectionViewProps)
  {
    super(props);

    // Binding
    this.bind(new GenericSettingsSectionViewModel(this), new GenericSettingsSectionViewStyle(this));
  }

  /* RENDERING */
  render()
  {
    const { container,columnNameContainer} = this.style
    return (
      <View style={container}>
        <SectionTitleView title={'form.settings'} />

          <View style = {{marginVertical : 15,flexDirection : 'row', justifyContent : 'space-between'}}>
            <TranslationView>{'form.settings.desc'}</TranslationView>
          </View>
          
          <View style={columnNameContainer}>
            <View style={{width:150}}>
              <ExtendedTranslationView uppercase>form.settings.key</ExtendedTranslationView>
            </View>
            <View style={{width:150}}>
              <ExtendedTranslationView uppercase>form.settings.value</ExtendedTranslationView>
            </View>
  
          </View>

          {this.state.currentContentTypeKeyValues.map((contentTypeKeyValue:ContentTypeKeyValue,index : number) => {return(
          <View style={columnNameContainer} key={index}>
            <View style={{flexDirection:"column",width:150}}>
              <View  style={{justifyContent : 'center',paddingVertical : 10,}}>
                <Text style = {{fontSize : 15}}>{this.props.contentTypeKeys[index].key}</Text>
              </View>
            </View>
            <View style={{flexDirection:"column",width:150}}>
              <ExtendedTextInputView 
              value={contentTypeKeyValue.value ?? ""}
              onChangeText={(text : string)=>this.binding.handleKeyValueChange(text,index)}
              showError={false}
              />
            </View>
     
          </View>)})}

          


      

        
      </View>
    );
  }
}