 
import {ContentView} from '@levelapp/softfabric';
import PopupHeaderViewState from './PopupHeaderViewState';
import PopupHeaderViewProps from './PopupHeaderViewProps';
import PopupHeaderViewStyle from './PopupHeaderViewStyle';
import PopupHeaderViewModel from './PopupHeaderViewModel';
import {TranslationView} from '@levelapp/softfabric-ui';
import { View, TouchableWithoutFeedback , Image, LayoutChangeEvent } from 'react-native';
import LinearGradient from "react-native-web-linear-gradient";
import Constants from '../../../../../Common/Constants/Constants';
import ButtonView from '../../../../../Common/Components/Button/ButtonView';

export default class PopupHeaderView extends ContentView<PopupHeaderViewProps, PopupHeaderViewState, PopupHeaderViewModel, PopupHeaderViewStyle>
{
  /* CONSTRUCTOR */
  constructor(props:PopupHeaderViewProps)
  {
    super(props);

    // Binding
    this.bind(new PopupHeaderViewModel(this), new PopupHeaderViewStyle(this));
  }

  /* RENDERING */
  render()
  {
    const { container, title , line , image , saveButton} = this.style;

      
    return (
      <View style={{width : '100%' , zIndex : 100 }}>
      <View onLayout={(event : LayoutChangeEvent)=>this.binding.handleOnLayout(event)} style={[container , {width : this.props.full ? '100%' : '95%'}]}>
        <LinearGradient style={{borderTopRightRadius : 8,borderTopLeftRadius : 8}} start={{ x: 0, y: 0 }} end={{ x: 1, y: 0 }} colors={[Constants.GradiantStartColor, Constants.GradiantEndColor]} >
          <View style={line}>
            <TranslationView style={title}>
              {this.props.title}
            </TranslationView>
            <View style={{flexDirection : 'row' , alignItems : 'center' }}>
              {this.props.children}
              
              {this.props.shouldShowButton &&
              <ButtonView isLoading={this.props.isLoading != undefined ? this.props.isLoading : false} handleClick={this.props.handleOnPressButton != undefined ? this.props.handleOnPressButton : () =>{}}backgroundColor="white">
                <TranslationView upperCase style={saveButton}>
                  generic.button.save
                </TranslationView>
              </ButtonView>
              }

              <View style={{width : 60 , alignItems : 'center'}}>
                <TouchableWithoutFeedback onPress={() => this.props.handleBackPressed()} >
                  <Image style={image}
                    source={{ uri: Constants.CloseImagePath }}></Image>
                </TouchableWithoutFeedback>
              </View>
            </View>
          </View>
        </LinearGradient>
      </View>
       <View style={{width : this.state.width , height : this.state.height }}/>
      </View>
    );
  }
}