 
import {ContentView} from '@levelapp/softfabric';
import MaiInformationViewState from './MaiInformationViewState';
import MaiInformationViewProps from './MaiInformationViewProps';
import MaiInformationViewStyle from './MaiInformationViewStyle';
import MaiInformationViewModel from './MaiInformationViewModel';
import { View } from 'react-native';
import LineView from '../../../../../../components/line/LineView';
import InformationSectionView from './components/informationsection/InformationSectionView';
import TitleSectionView from './components/titlesection/TitleSectionView';
import TagSectionView from './components/tagsection/TagSectionView';
import Constants from '../../../../../../../../../Common/Constants/Constants';

export default class MaiInformationView extends ContentView<MaiInformationViewProps, MaiInformationViewState, MaiInformationViewModel, MaiInformationViewStyle>
{
  /* CONSTRUCTOR */
  constructor(props:MaiInformationViewProps)
  {
    super(props);

    // Binding
    this.bind(new MaiInformationViewModel(this), new MaiInformationViewStyle(this));
  }

  /* RENDERING */
  render()
  {
    const { container } = this.style;

    return (
      <View style={container}>
        {/* InformationSection */}

        <InformationSectionView
          showErrors={this.props.showErrors}
          handleCheckboxChange={this.props.handleCheckboxChange}
          handleValueChange={this.props.handleValueChange}
          handleOnMorePress={this.props.handleMorePress}
          reward={this.props.reward}
          languages={this.props.languages}
        />
        <LineView/>

        <TitleSectionView
          reward={this.props.reward}
          showErrors={this.props.showErrors}
          languages={this.props.languages}
          handleTranslationsChange={this.props.handleTranslationsChange}
        />

        {Constants.AllowTags ? <TagSectionView tags={this.props.tags} reward={this.props.reward} />: <View/>}
         
      </View>
    );
  }
}