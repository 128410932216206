 
import {ContentView} from '@levelapp/softfabric';
import PspSectionViewState from './PspSectionViewState';
import PspSectionViewProps from './PspSectionViewProps';
import PspSectionViewStyle from './PspSectionViewStyle';
import PspSectionViewModel from './PspSectionViewModel';
import { View } from 'react-native';
import ExtendedTextInputView from '../../../../../../components/extendedtextinput/ExtendedTextInputView';
import ExtendedCheckBoxView from '../../../../../../components/extendedcheckbox/ExtendedCheckBoxView';
import ExtendedDropDownView from '../../../../../../components/extendeddropdown/ExtendedDropDownView';
import { PspTypes } from '../../../../../../../../../Common/Enums/PspTypes';

export default class PspSectionView extends ContentView<PspSectionViewProps, PspSectionViewState, PspSectionViewModel, PspSectionViewStyle>
{
  /* CONSTRUCTOR */
  constructor(props:PspSectionViewProps)
  {
    super(props);

    // Binding
    this.bind(new PspSectionViewModel(this), new PspSectionViewStyle(this));
  }

  /* RENDERING */
  render()
  {
    return (
        <View>
          <View style={{flexDirection : 'row'}}>
            {/* PSP Type */}
            <ExtendedDropDownView title={'form.psptype'} values={this.state.psplist} selectedValue={this.state.currentselection} onValueChange={(value) => this.binding.handleChangePsp(value)}/>
          </View>
          <View>
            <View style={{flexDirection : 'row'}}>
              {/* PSP KEY*/}
              <ExtendedTextInputView
                title={"form.pspkey"}
                showError={this.props.showErrors && (this.props.store.pspKey == null || this.props.store.pspKey == "") ? true : false}
                value={this.props.store.pspKey}
                onChangeText={(text: string) => this.props.handleValueChange('pspkey',text)}
              />

              {/* PSP KEY DEV*/}
              <ExtendedTextInputView
                title={"form.pspkeydev"}
                showError={this.props.showErrors && (this.props.store.pspKey_dev == null || this.props.store.pspKey_dev == "") ? true : false}
                value={this.props.store.pspKey_Dev}
                onChangeText={(text: string) => this.props.handleValueChange('pspkeyDev',text)}
              />    
            </View>
            {this.state.currentselection != undefined && 
            (this.state.currentselection.title == PspTypes.RABOBANK ||  this.state.currentselection.title == PspTypes.STRIPE ) && 
            <View style={{flexDirection : 'row'}}>
              {/* More Info about PSP (exmpl : Signature for Rabobank)*/}
              <ExtendedTextInputView
                title={"form.pspkey2"}
                showError={this.props.showErrors && (this.props.store.pspKey2 == null || this.props.store.pspKey2 == "") ? true : false}
                value={this.props.store.pspKey2}
                onChangeText={(text: string) => this.props.handleValueChange('pspkey2',text)}
              />

              {/* More Info about PSP DEV (exmpl : Signature for Rabobank)*/}
              <ExtendedTextInputView
                    title={"form.pspkey2dev"}
                    showError={this.props.showErrors && (this.props.store.pspKey2_dev == null || this.props.store.pspKey2_Dev == "") ? true : false}
                    value={this.props.store.pspKey2_Dev}
                    onChangeText={(text: string) => this.props.handleValueChange('pspkey2Dev',text)}
                  />    
            </View>}
            <ExtendedCheckBoxView isTranslation title={'store.popup.ispspprod'} value={this.props.store.isPspProd} onValueChange={(value) => this.props.handleCheckboxChange('isPspProd',value)}/>
          </View>
        </View>
    );
  }
}