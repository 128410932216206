import {ContentViewStyle} from '@levelapp/softfabric';
import {StyleProp,ViewStyle} from 'react-native';

export default class DescriptionViewStyle extends ContentViewStyle
{
    container: StyleProp<ViewStyle> = 
    { 
        display: "flex", 
        flexDirection: "row", 
        flexWrap: "wrap", 
        justifyContent: 'space-between', 
        
    }
}