import {ContentViewModel,ContentView} from '@levelapp/softfabric';
import ProfileMenuViewState from './ProfileMenuViewState';
import ProfileMenuViewProps from './ProfileMenuViewProps';
import { Animated } from 'react-native';

export default class ProfileMenuViewModel extends ContentViewModel<ProfileMenuViewState, ProfileMenuViewProps>
{
    _transition : Animated.Value;
    _opacityAnimation : Animated.Value;
    
      /* CONSTRUCTOR */
    constructor(view: ContentView)
    {
        super(view);



        this._transition = new Animated.Value(0);
        this._opacityAnimation = new Animated.Value(0);

        this.handleAnimation = this.handleAnimation.bind(this);
    }

    animation()
    {
        var value : number = this.props().activeMenuProfile ? 1 : 0;

        Animated.spring(this._transition,
            {
                useNativeDriver: true,
                toValue : value
            }).start();
    }

    handleAnimation(value : number)
    {
        Animated.spring(this._opacityAnimation,
            {
                useNativeDriver: true,
                toValue : value,
            }).start();
    }

    logout()
    {
        localStorage.clear();
        this.navigate('login');
    }
}