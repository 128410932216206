 
import { View } from 'react-native';

import { ContentView } from '@levelapp/softfabric';
import { LottieView, TranslationView } from '@levelapp/softfabric-ui';
import ProductsToolViewState from './ProductsToolViewState';
import ProductsToolViewProps from './ProductsToolViewProps';
import ProductsToolViewStyle from './ProductsToolViewStyle';
import ProductsToolViewModel from './ProductsToolViewModel';

import MenuItemView from '../productstool/menuitem/MenuItemView';
import ManageProductsMenuView from './manageproductsmenu/ManageProductsMenuView';

import Constants from 'src/Common/Constants/Constants';
import HeaderView from 'src/Presentation/modules/dashboard/components/header/HeaderView';
import NoDataPlaceholderView from './nodataplaceholder/NoDataPlaceholderView';

export default class ProductsToolView extends ContentView<ProductsToolViewProps, ProductsToolViewState, ProductsToolViewModel, ProductsToolViewStyle>
{
  /* CONSTRUCTOR */
  constructor(props: ProductsToolViewProps) {
    super(props);

    // Binding
    this.bind(new ProductsToolViewModel(this), new ProductsToolViewStyle(this));
  }

  /* RENDERING */
  render() {
    const showProductGroups = this.state.productGroups.filter((pg) => !pg.isDefault).length > 0;
    const showDefaultProductGroups = this.state.productGroups
      .filter((pg) => pg.isDefault)[0] && this.state.productGroups
        .filter((pg) => pg.isDefault)[0].products.length > 0;

    return (
      <View style={this.style.wrapper}>
        <HeaderView
          title="tools.products"
          shouldShowButton
          description="tools.products.description"
          onButtonPress={this.binding.onAddProductGroupClick}
          buttonText="tools.addNewProduct"
        />
        {!this.state.isLoading ? (
          showProductGroups || showDefaultProductGroups ? (
            <View style={this.style.container}>
              <View style={this.style.content}>
                <View style={this.style.left}>
                  <View>
                    <TranslationView upperCase style={this.style.category}>
                      tools.products.group
                    </TranslationView>
                  </View>
                  <View style={this.style.leftContent}>
                    <>
                      {this.state.productGroups
                        .filter((productGroup) => !productGroup.isDefault)
                        .map((productGroup) => (
                          <MenuItemView
                            key={productGroup.id}
                            productGroup={productGroup}
                            underMenu={false}
                            isDirect={false}
                            title={productGroup.name}
                            onPopupClose={this.binding.onPopUpClosed}
                            display
                            isSingleProduct={false}
                            childrenCount={productGroup.products.length}
                            selected={this.state.selectedItem?.id === productGroup.id && this.state.selectedItem?.isParent}
                            onPress={() => this.binding.onPress(productGroup.id, true)}>
                            {productGroup.products.map((product) => (
                              <MenuItemView
                                key={product.id}
                                title={this.binding.getProductTranslation(product)}
                                display
                                onPopupClose={this.binding.onPopUpClosed}
                                underMenu
                                isDirect
                                selected={this.state.selectedItem?.id === product.id && !this.state.selectedItem?.isParent}
                                onPress={() => this.binding.onPress(product.id, false)}
                                isSingleProduct={false}
                              />
                            ))}
                          </MenuItemView>
                        ))}
                      {this.state.productGroups
                        .filter((productGroup) => productGroup.isDefault)
                        .map((productGroup) => (
                          productGroup.products.map((product) => (
                            <MenuItemView
                              isSingleProduct
                              key={product.id}
                              title={this.binding.getProductTranslation(product)}
                              display
                              onPopupClose={this.binding.onPopUpClosed}
                              underMenu
                              isDirect
                              product={product}
                              onRemove={this.binding.onProductRemovedFromGroup}
                              selected={this.state.selectedItem?.id === product.id && !this.state.selectedItem?.isParent}
                              onPress={() => this.binding.onPress(product.id, false)}
                            />
                          ))
                        ))}
                    </>
                  </View>
                </View>
                <View style={this.style.right}>
                  <ManageProductsMenuView selectedItem={this.state.selectedItem} />
                </View>
              </View>
            </View>
          ) : (
            <View style={this.style.noData}>
              <NoDataPlaceholderView />
            </View>
          )
        ) : (
          <LottieView
            height={150}
            width={150}
            source={require('src/Common' + Constants.LottieAnimationPath)}
          />
        )
        }
      </View>
    );
  }
}