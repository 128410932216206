 
import {ContentShellView} from '@levelapp/softfabric';
import ShellNewsViewState from './ShellNewsViewState';
import ShellNewsViewProps from './ShellNewsViewProps';
import ShellNewsViewStyle from './ShellNewsViewStyle';
import ShellNewsViewModel from './ShellNewsViewModel';
import { View } from 'react-native';

export default class ShellNewsView extends ContentShellView<ShellNewsViewProps, ShellNewsViewState, ShellNewsViewModel, ShellNewsViewStyle>
{
  /* CONSTRUCTOR */
  constructor(props:ShellNewsViewProps)
  {
    super(props);

    // Binding
    this.bind(new ShellNewsViewModel(this), new ShellNewsViewStyle(this));
  }

  /* RENDERING */
  render()
  {
    return (
      <View style={{flex : 1}}>
      {this.getSwitch()}
      </View>
    );
  }
}