import {ContentViewModel,ContentView} from '@levelapp/softfabric';
import VouchersPopUpViewState from './VouchersPopUpViewState';
import VouchersPopUpViewProps from './VouchersPopUpViewProps';
import ErrorHelper from '../../../../../../../Common/Helpers/ErrorHelper';
import TranslationHelper from '../../../../../../../Common/Helpers/TranslationHelper';
import ToastHelper from '../../../../../../../Common/Helpers/ToastHelper';
import DateHelper from '../../../../../../../Common/Helpers/DateHelper';
import ContentTypeKey from '../../../../../../../Common/DTO/ContentTypeKey';
import Helpers from '../../../../../../../Common/Helpers/Helpers';
import TranslationValue from '../../../../../../../Common/DTO/TranslationValue';
import DispatcherManager from '../../../../../../../Common/Managers/DispatcherManager';
import VouchersOperations from '../../../../../../../Transfer/VouchersOperations';
import Type from '../../../../../../../Common/DTO/Type';
import Trigger from '../../../../../../../Common/DTO/Trigger';
import Country from '../../../../../../../Common/DTO/Country';
import NumberHelper from '../../../../../../../Common/Helpers/NumberHelper';

export default class VouchersPopUpViewModel extends ContentViewModel<VouchersPopUpViewState, VouchersPopUpViewProps>
{
    _dispatcherHelper: DispatcherManager;
    _vouchersOperation: VouchersOperations;
    _regexTimeFormat: RegExp;
  
  
    constructor(props: ContentView) {
      super(props);
      /* Initial State */
      this.initialState({
        voucher: this.props().voucher,
        languages: this.props().languages,
        showErrors: false,
        useDefaultImageChecked: true,
        useDefaultThumbnailChecked: true,
        tab: "main",
        isLoading: false,
        page: '',
        index: 0,
        indexThumbnail: 0,
        countries: this.props().countries,
      });
  
      this._regexTimeFormat = new RegExp('^(0[0-9]|1[0-9]|2[0-3]|[0-9]):[0-5][0-9]$');
      this._dispatcherHelper = new DispatcherManager();
      this._vouchersOperation = new VouchersOperations();
  
      /* BINDINGS */
      this.saveVoucher = this.saveVoucher.bind(this);
      this.changeTab = this.changeTab.bind(this);
      this.handleImage = this.handleImage.bind(this);
      this.errorManagement = this.errorManagement.bind(this);
      this.handleThumbnail = this.handleThumbnail.bind(this);
      this.handleSavePressed = this.handleSavePressed.bind(this);
      this.handleTitleChanged = this.handleTitleChanged.bind(this);
      this.handleEndDateChanged = this.handleEndDateChanged.bind(this);
      this.handleStartDateChanged = this.handleStartDateChanged.bind(this);
      this.handlePreviewChanged = this.handlePreviewChanged.bind(this);
      this.handleDescriptionChanged = this.handleDescriptionChanged.bind(this);
      this.handleDefaultCheckBoxChanged = this.handleDefaultCheckBoxChanged.bind(this);
      this.handleDefaultCheckBoxThumbnailChanged = this.handleDefaultCheckBoxThumbnailChanged.bind(this);
      this.isRespectingTimeFormat = this.isRespectingTimeFormat.bind(this);
      this.handleDayChange = this.handleDayChange.bind(this);
      this.setIndexImage = this.setIndexImage.bind(this);
      this.setIndexThumbnail = this.setIndexThumbnail.bind(this);
      this.getContentTypeKeys = this.getContentTypeKeys.bind(this);
      this.handleContentTypeKeyValueChange = this.handleContentTypeKeyValueChange.bind(this);
      this.handleTypeChange = this.handleTypeChange.bind(this);
      this.handlePrizeTypeChange = this.handlePrizeTypeChange.bind(this);
      this.handleTriggerChange = this.handleTriggerChange.bind(this);
      this.handleCountryChanged = this.handleCountryChanged.bind(this);
      this.handleDurationChanged = this.handleDurationChanged.bind(this);
    }
  
    componentDidMount() {
      this._dispatcherHelper.checkboxPreview = this.handlePreviewChanged;
      this._dispatcherHelper.checkboxDefaultImage = this.handleDefaultCheckBoxChanged;
      this._dispatcherHelper.checkboxDefaultThumbnail = this.handleDefaultCheckBoxThumbnailChanged;
  
      this._dispatcherHelper.dateEndDate = this.handleEndDateChanged;
      this._dispatcherHelper.dateStartDate = this.handleStartDateChanged;
  
      this._dispatcherHelper.translationTitle = this.handleTitleChanged;
      this._dispatcherHelper.translationDescription = this.handleDescriptionChanged;
  
      this._dispatcherHelper.uriImage = this.handleImage;
      this._dispatcherHelper.uriThumbnail = this.handleThumbnail;

      this._dispatcherHelper.numberIndexImage = this.setIndexImage;
      this._dispatcherHelper.numberIndexThumbnail = this.setIndexThumbnail;

      this._dispatcherHelper.contentTypeKeyValue = this.handleContentTypeKeyValueChange;
      this._dispatcherHelper.valueCountry = this.handleCountryChanged;
      this._dispatcherHelper.valueValidityDuration = this.handleDurationChanged;
    }
  
    /* COMPONENT EVENTS */
    componentWillMount() { }
  
    /* OTHERS */
  
    changeTab(tab: any) {
      this.setField((previousState) => previousState.tab = tab);
    }
  
    isRespectingTimeFormat(value: string) {
      return this._regexTimeFormat.test(value);
    }
  
    fillAllPicturesWithDefault() {
      if (this.state().useDefaultImageChecked) {
        var state = this.state();
        var list = Helpers.fillAllImagesWithDefault(state.voucher.imageTranslation, state.languages) as TranslationValue[];
        this.setField((prevouisState) => prevouisState.voucher.imageTranslation = list)
      }
    }
  
    fillAllThumbnailsWithDefault() {
      if (this.state().useDefaultThumbnailChecked) {
        var state = this.state();
        var list = Helpers.fillAllImagesWithDefault(state.voucher.thumbnailTranslation, state.languages) as TranslationValue[];
        this.setField((prevouisState) => prevouisState.voucher.thumbnailTranslation = list)
      }
    }
  
    handleSavePressed() {
      if (
        this.state().voucher.titleTranslations.length != this.state().languages.length ||
        this.state().voucher.descriptionTranslations.length != this.state().languages.length ||
        !Helpers.areImagesComplete(this.state().voucher.thumbnailTranslation) ||
        !Helpers.areImagesComplete(this.state().voucher.imageTranslation)) {
        this.setField((previousState) => previousState.showErrors = true);
      }
      else {
        this.setField((previousState) => previousState.isLoading = true);
        this.saveVoucher(this.state().voucher);
      }
    }
  
    handleDayChange(value: any, openingHour: any) {
      openingHour.day = value.value;
    }

    handleTypeChange(newType : Type){

      this.setField((previousState) => previousState.voucher.type = newType);
    
    }

    handlePrizeTypeChange(newType : Type){

      this.setField((previousState) => previousState.voucher.prizeType = newType);
    
    }

    handleTriggerChange(newTrigger : Trigger){

      this.setField((previousState) => previousState.voucher.trigger = newTrigger);
    
    }
  
    getContentTypeKeys(): ContentTypeKey[] {
      return this.props().contentTypeKeys
    }
  
    /* SAVE */
  
    saveVoucher(newVoucher: any) {
      let voucher = JSON.parse(JSON.stringify(newVoucher))
      var startResult = DateHelper.changeDateToBeValidUTC(voucher.StartDateInput);
      var endResult = DateHelper.changeDateToBeValidUTC(voucher.EndDateInput);
      voucher.startDate = startResult;
      voucher.endDate = endResult;
  
      for (var i = 0; i < this.props().languages.length; i++) {
        if (voucher.imageTranslation[i].url.includes("data:image"))
        voucher.imageTranslation[i].ImageData = voucher.imageTranslation[i].url.replace("data:image/png;base64,", "").replace("data:image/jpeg;base64,", "");
        else
        voucher.imageTranslation[i].ImageData = null;
  
        if (voucher.thumbnailTranslation[i].url.includes("data:image"))
        voucher.thumbnailTranslation[i].ImageData = voucher.thumbnailTranslation[i].url.replace("data:image/png;base64,", "").replace("data:image/jpeg;base64,", "");
        else
        voucher.thumbnailTranslation[i].ImageData = null;
      }
  
      voucher.prizeType = newVoucher.prizeType;
      voucher.type = newVoucher.type;
      voucher.trigger = newVoucher.trigger;

      voucher.contentTypeKeyValue.map((contentTypeKeyValue: any) => {
        contentTypeKeyValue.contentTypeKey = null;
      })
  
  
      if (voucher.id !== undefined) {
        this._vouchersOperation.put(voucher, () => {
          this.props().refreshParent();
          this.showToast(ToastHelper.getToast("vouchers.popup.success.updated", "success"), () => { }, 2500);
          this.closePopUp();
        }, this.errorManagement);
      }
  
      else {
        this._vouchersOperation.post(voucher, () => {
          this.props().refreshParent();
          this.showToast(ToastHelper.getToast("vouchers.popup.success.added", "success"), () => { }, 2500);
          this.closePopUp();
        }, this.errorManagement);
      }
    }
  
    errorManagement(error: any) {
      this.setField({ isLoading: false });
      ErrorHelper.showMessageError(error.response);
    }
  
    /* URI */
  
    private handleImage(base64: any) {
      var state = this.state();
      TranslationHelper.imageTranslation(state.voucher.imageTranslation, state.languages, state.index, base64);
      this.setField({ state });
      this.fillAllPicturesWithDefault();
    }
  
    private handleThumbnail(base64: any) {
      var state = this.state();
      TranslationHelper.imageTranslation(state.voucher.thumbnailTranslation, state.languages, state.indexThumbnail, base64);
      this.setField({ state });
      this.fillAllThumbnailsWithDefault();
    }
    /* DATE */
  
    private handleStartDateChanged(text: Date) {
      this.setField((previousState) => previousState.voucher.StartDateInput = text);
    }
  
    private handleEndDateChanged(text: Date) {
      this.setField((previousState) => previousState.voucher.EndDateInput = text);
    }
  
    /* TRANSLATIONS */
  
    private handleTitleChanged(text: any, index: any) {
      var state = this.state();
      TranslationHelper.translation(state.voucher.titleTranslations, state.languages, index, text);
      this.setField({ state });
    }
  
    private handleDescriptionChanged(text: any, index: any) {
      var state = this.state();
      TranslationHelper.translation(state.voucher.descriptionTranslations, state.languages, index, text);
      this.setField({ state });
    }
  
    /* CHECKBOXES */
  
    private handlePreviewChanged(value: boolean) {
      this.setField((previousState) => previousState.voucher.isPreview = value);
    }
  
    private handleDefaultCheckBoxChanged(value: boolean) {
      this.setField((previousState) => previousState.useDefaultImageChecked = value, () => {
        this.fillAllPicturesWithDefault();
      });
    }
  
    private handleDefaultCheckBoxThumbnailChanged(value: boolean) {
      this.setField((previousState) => previousState.useDefaultThumbnailChecked = value, () => {
        this.fillAllThumbnailsWithDefault();
      });
    }


    /* INDEX */

    private setIndexImage(index: any) {
        this.setField((previousState) => previousState.index = index);
    }

    private setIndexThumbnail(index: any) {
        this.setField((previousState) => previousState.indexThumbnail = index);
    }

    /* SETTINGS */

    private handleContentTypeKeyValueChange(value: any) {
        this.setField((previousState) => previousState.voucher.contentTypeKeyValue = value);
    }

    /* DURATION */

    private handleCountryChanged(value: Country[]) {
      var state = this.state();
      state.voucher.voucherCountries = [];
  
      value.forEach((country: Country) => {
        state.voucher.voucherCountries.push({ countryId: country.id, voucherId: state.voucher.id });
      })
  
      this.setField({ state });
    }

    private handleDurationChanged(text: string) {
      var number = NumberHelper.convertStringToInt(text);
      this.setField((previousState) => previousState.voucher.duration = number);
    }
  }