import {ContentViewModel,ContentView} from '@levelapp/softfabric';
import ToggleInsertTypeViewState from './ToggleInsertTypeViewState';
import ToggleInsertTypeViewProps from './ToggleInsertTypeViewProps';

export default class ToggleInsertTypeViewModel extends ContentViewModel<ToggleInsertTypeViewState, ToggleInsertTypeViewProps>
{
      /* CONSTRUCTOR */
    constructor(view: ContentView)
    {
        super(view);

        this.initialState({}); // Initialize your state here

        // Bindings

    }
}