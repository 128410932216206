import { Service, Application, HttpManager, Managers } from '@levelapp/softfabric';

export default class CountryService extends Service
{
    httpClient = Application.current.resolve<HttpManager>(Managers.HTTP_MANAGER);

    async get(onSuccess: Function, onFailure : Function = () => {}) {
        this.httpClient.handleSomethingWentWrong = onFailure;
        this.httpClient.handleNotFound = onFailure;

        this.httpClient
            .get('countries', [])
            .then((response: any) => {
                if (response) {
                    if (response.data) {
                        onSuccess(response.data.data);
                    }
                }
            });
    }

    post(country:any, callback : Function = () => {}, onFailure : Function = () => {})
    {
        this.httpClient.handleSomethingWentWrong = onFailure;
        this.httpClient.handleNotFound = onFailure;

        this.httpClient
            .post('countries', [], country)
            .then((response : any) => 
            {
                if (response)
                {
                    /*if (response.data)
                    {
                        callback(response.data);
                    }*/
                    callback("");
                }
            });
    }

    put(country:any, callback : Function = () => {}, onFailure : Function = () => {})
    {
        this.httpClient.handleSomethingWentWrong = onFailure;
        this.httpClient.handleNotFound = onFailure;
        
        this.httpClient
            .put('countries', [], country)
            .then((response : any) => 
            {
                if (response)
                {
                    /*if (response.data)
                    {
                        callback(response.data);
                    }*/
                    callback("");
                }
            });
    }

    delete(country:any, callback : Function = () => {}, onFailure : Function = () => {})
    {
        this.httpClient.handleSomethingWentWrong = onFailure;
        this.httpClient.handleNotFound = onFailure;

        this.httpClient
            .delete('countries/delete/'+country.id)
            .then((response : any) => 
            {
                if (response)
                {
                     /*if (response.data)
                    {
                        callback(response.data);
                    }*/
                    callback("");
                }
            });
    }
}