import {ContentViewModel,ContentView} from '@levelapp/softfabric';
import MessageCampaignStatsViewState from './MessageCampaignStatsViewState';
import MessageCampaignStatsViewProps from './MessageCampaignStatsViewProps';
import MessageCampaignsOperations from '../../../../../../../Transfer/MessageCampaignOperations';

export default class MessageCampaignStatsViewModel extends ContentViewModel<MessageCampaignStatsViewState, MessageCampaignStatsViewProps>
{
    
    messageCampaignOperations : MessageCampaignsOperations
    /* CONSTRUCTOR */
    constructor(view: ContentView)
    {
        super(view);
        this.messageCampaignOperations = new MessageCampaignsOperations()
        this.initialState({details:{}}); // Initialize your state here
        
        // Bindings
        this.getDetail = this.getDetail.bind(this)
        this.getData = this.getData.bind(this)
        this.getStatusData = this.getStatusData.bind(this)
    }

    componentDidMount()
    {
        this.getDetail()
    }

    getDetail(){
        this.messageCampaignOperations.getDetails(this.props().messageCampaignId, 
            (res : any) =>{
                this.setField({details:res.data.data})
            })
    }

    getData(details : any,label1 : string,label2 : string, details2? : any){
        if(details == undefined) return null

        var labels: string[] = []
        var pn_data: number[] = []
        Object.keys(details).forEach(x => {
            labels.push(x)
            pn_data.push(details[x])
        })

        const data = {
            labels : labels,
            options : {
              legend:{
                display: true,
                labels:{
                  fontColor: 'rgb(255,255, 132)'
                }
              }
            },
            datasets: [
              {
                label: label1,
                data: pn_data,
                fill: false,
                backgroundColor: 'rgb(255, 99, 132)',
                borderColor: 'rgba(255, 99, 132, 0.4)',

              },
            ]
          }
        
        if(details2 != undefined){
          let pn_data2: number[] = []
          Object.keys(details2).forEach(x => {
              pn_data2.push(details2[x])
          })
          var new_data =               {
            label: label2,
            data: pn_data2,
            fill: false,
            backgroundColor: 'rgb(235, 220, 52)',
            borderColor: 'rgba(235, 220, 52, 0.4)',
          }
          data.datasets.push(new_data)
        }
        return data
    }

    getStatusData(details : any){

        var bg_color = [
          'rgba(255, 99, 132, 0.2)',
          'rgba(54, 162, 235, 0.2)',
          'rgba(255, 206, 86, 0.2)',
          'rgba(75, 192, 192, 0.2)',
          'rgba(153, 102, 255, 0.2)',
          'rgba(255, 159, 64, 0.2)',
          'rgba(12, 128, 64, 0.2)',
          'rgba(107, 252, 3, 0.2)',
        ]

        var bd_color = [
          'rgba(255, 99, 132, 1)',
          'rgba(54, 162, 235, 1)',
          'rgba(255, 206, 86, 1)',
          'rgba(75, 192, 192, 1)',
          'rgba(153, 102, 255, 1)',
          'rgba(255, 159, 64, 1)',
          'rgba(12, 128, 64, 1)',
          'rgba(107, 252, 3, 1)',
        ]

        if(details == undefined) return null
        var labels: string[] = []
        var pn_data: number[] = []
        Object.keys(details).forEach(x => {
            labels.push(x)
            pn_data.push(details[x])
        })

        var index_found = labels.findIndex(x => x == "Completed" || x == "delivered")
        if(index_found > -1){
          bg_color[index_found] = 'rgba(235, 220, 52,0.2)'
          bd_color[index_found] = 'rgba(235, 220, 52)'
        }

        const data = {
            labels : labels,
            datasets: [
              {
                label: '# of Push Notification by status',
                data: pn_data,
                backgroundColor: bg_color,
                borderColor: bd_color ,
                borderWidth : 1,   
              },
            ]
          }
        return data
    }
}