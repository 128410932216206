import {ContentShellViewModel, ContentShellView} from '@levelapp/softfabric';
import ShellEarningPointsViewState from './ShellEarningpointsViewState';
import ShellEarningPointsViewProps from './ShellEarningpointsViewProps';
 
import EnumModules from '../../../../../Common/Constants/EnumModules';
import ActivationCodeView from './pages/ActivationCodeView';


export default class ShellEarningPointsViewModel extends ContentShellViewModel<ShellEarningPointsViewState, ShellEarningPointsViewProps>
{

    constructor(view : ContentShellView)
    {
        super(view);

        this.registerEntryPoint('main'); // TODO : change with the route of your entry point
        this.register(<ActivationCodeView/> ,'main');

        this.initialisation();
    }

    getModuleId()
    {
        return EnumModules.EARNINGPOINT; 
    }

}