import { ContentViewModel, ContentView } from '@levelapp/softfabric';
import AdditionalInfosViewState from './AdditionalInfosViewState';
import AdditionalInfosViewProps from './AdditionalInfosViewProps';
import DispatcherManager from 'src/Common/Managers/DispatcherManager';
import AdditionalInfosOperations from 'src/Transfer/AdditionalInfosOperations';
import PaginationHelper from 'src/Common/Helpers/PaginationHelper';

export default class AdditionalInfosViewModel extends ContentViewModel<AdditionalInfosViewState, AdditionalInfosViewProps>
{
    _dispatcherManager: DispatcherManager;
    _additionalInfosOperation: AdditionalInfosOperations;

    /* CONSTRUCTOR */
    constructor(view: ContentView) {
        super(view);

        let additionalInfos = this.props().additionalInfos;
        this.initialState({
            value: "",
            infos: [],
            types: [],
            selectedType: null,
            selectedInfos: undefined,
            additionalInfos
        }); // Initialize your state here

        // Bindings
        this.add = this.add.bind(this);
        this.delete = this.delete.bind(this);

        this._dispatcherManager = new DispatcherManager();
        this._additionalInfosOperation = new AdditionalInfosOperations();
    }

    componentDidMount(): void {

        console.log(this.props().additionalInfos);

        let parameters = [];
        parameters.push(PaginationHelper.pushPage(0));
        parameters.push(PaginationHelper.pushItemPage(1000));

        this._additionalInfosOperation.get(parameters, (ret: any) => {
            let infos = this.state().infos;
            ret.objects.forEach((element: any) => {
                element.title = element.titleTranslations[0].value;
                infos.push(element);
            });
            this.setField({ infos, selectedInfos: infos[0] });
        })

        this._additionalInfosOperation.getTypes((ret: any) => {
            let types = this.state().types;
            ret.forEach((element: any) => {
                element.title = element.key
                types.push(element);
            });
            this.setField({ types, selectedType: types[0] });
        })
    }

    handleValueChanged(value: any, index: number) {
        let state = this.state();
        state.additionalInfos[index].value = value;
        this.setField({ state });
    }

    handleDropDownTypeChange(selectedType: any, index: number) {
        let state = this.state();
        state.additionalInfos[index].additionalInfoType = selectedType;
        this.setField({ state });
    }

    handleDropDownInfoChange(selectedInfos: any, index: number) {
        let state = this.state();
        state.additionalInfos[index].additionalInfo = selectedInfos;
        this.setField({ state });
    }

    add() {
        let additionalInfos = this.state().additionalInfos;

        let additionalInfo = {
            additionalInfoType: this.state().types[0],
            additionalInfo: this.state().infos[0],
            value: ""
        };
        additionalInfos.push(additionalInfo);

        this.setField({ additionalInfos });
        this.props().handleAdditionalInfos(additionalInfos)
    }

    delete(index: any) {
        let additionalInfos = this.state().additionalInfos;     
        this.state().additionalInfos.splice(index, 1);     
        this.setField({ additionalInfos });       
    }
}