import { Operation } from "@levelapp/softfabric";
import MembershipType from "src/Common/DTO/MembershipType";
import MembershipTypeService from "src/Proxy/MembershipTypeService";

export default class MembershipTypeOperations extends Operation
{
    service :  MembershipTypeService;

    constructor()
    {
        super();
        this.service = new MembershipTypeService();
    }
    
    getAll(callBack:Function, onFailure: Function){
        this.service.getAll(callBack,onFailure);
    }

    post(membershipType: MembershipType, onSuccess: Function, onFailure: Function){
        this.service.post(membershipType, onSuccess,onFailure);
    }

    put(membershipType: MembershipType, onSuccess: Function, onFailure: Function){
        this.service.put(membershipType, onSuccess,onFailure);
    }

    delete(membershipTypeId: number, onSuccess: Function, onFailure: Function){
        this.service.delete(membershipTypeId, onSuccess,onFailure);
    }
}