import React from 'react';
import { ContentView } from '@levelapp/softfabric';
import HeaderViewState from './HeaderViewState';
import HeaderViewProps from './HeaderViewProps';
import HeaderViewStyle from './HeaderViewStyle';
import HeaderViewModel from './HeaderViewModel';
import { TranslationView } from '@levelapp/softfabric-ui';
import LinearGradient from 'react-native-web-linear-gradient';
import { View } from 'react-native';
import ButtonView from '../../../../../Common/Components/Button/ButtonView';
import Constants from '../../../../../Common/Constants/Constants';

export default class HeaderView extends ContentView<HeaderViewProps, HeaderViewState, HeaderViewModel, HeaderViewStyle>
{
  /* CONSTRUCTOR */
  constructor(props: HeaderViewProps) {
    super(props);

    // Binding
    this.bind(new HeaderViewModel(this), new HeaderViewStyle(this));
  }

  /* RENDERING */
  render() {
    const { boxSubtitle, title, subTitle, buttonBox, boxTitle, buttonsContainer } = this.style;
    const { othersButtons } = this.props;

    return (
      <LinearGradient start={{ x: 0, y: 0 }} end={{ x: 1, y: 0 }} colors={[Constants.GradiantStartColor, Constants.GradiantEndColor]} style={boxTitle}>
        <View style={boxSubtitle}>
          <TranslationView style={title}>
            {this.props.title}
          </TranslationView>

          <TranslationView style={subTitle}>
            {this.props.description}
          </TranslationView>
        </View>
        <View style={buttonsContainer}>
          {othersButtons && othersButtons}
          <View style={buttonBox}>
            {this.props.shouldShowButton2 && React.Children.count(this.props.children) == 0 && <ButtonView isLoading={this.props.button2Loading == undefined ? false : this.props.button2Loading} handleClick={this.props.onButtonPress2 != undefined ? this.props.onButtonPress2 : () => { }} backgroundColor='white' textColor={Constants.MainColor}>
              <TranslationView upperCase={true}>
                {this.props.buttonText2}
              </TranslationView>
            </ButtonView>}
            {!this.props.shouldShowButton2 && React.Children.count(this.props.children) > 0 && this.props.children}
          </View>
          <View style={buttonBox}>
            {this.props.shouldShowButton && React.Children.count(this.props.children) == 0 && <ButtonView isLoading={this.props.button1Loading == undefined ? false : this.props.button1Loading} handleClick={this.props.onButtonPress != undefined ? this.props.onButtonPress : () => { }} backgroundColor='white' textColor={Constants.MainColor}>
              <TranslationView upperCase={true}>
                {this.props.buttonText}
              </TranslationView>
            </ButtonView>}
            {!this.props.shouldShowButton && React.Children.count(this.props.children) > 0 && this.props.children}
          </View>
        </View>

      </LinearGradient>
    );
  }
}