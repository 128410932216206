import {ContentViewModel , ContentView} from '@levelapp/softfabric';
import EditableDropDownViewState from './EditableDropDownViewState';
import EditableDropDownViewProps from './EditableDropDownViewProps';

export default class EditableDropDownViewModel extends ContentViewModel<EditableDropDownViewState, EditableDropDownViewProps>{
    constructor(props: ContentView) {
        super(props);

        this.initialState({searchValue : '' });

        this.handleSearchChanged = this.handleSearchChanged.bind(this);
        this.handleOptionClicked = this.handleOptionClicked.bind(this);
        this.isValidationAvailable = this.isValidationAvailable.bind(this);
        this.clearSearch = this.clearSearch.bind(this);
        this.validate = this.validate.bind(this);
        this.getAvailableOptions = this.getAvailableOptions.bind(this);
    } 


    handleSearchChanged(searchValue: string):void {
        this.setField({searchValue});
    }

    handleOptionClicked(option: any):void {
        this.handleSearchChanged(option.title);
    }

    clearSearch():void {
        this.handleSearchChanged("");
    }

    isValidationAvailable(): boolean {
        const searchTerm = this.state().searchValue.toLowerCase();
        return (
            searchTerm.length > 2 && 
            !this.props().blockedValues.find(value=> value.title.toLowerCase() === searchTerm)
        );
    }

    validate():void {
        const searchTerm = this.state().searchValue.toLowerCase();
        const option = this.props().values.find(item => {
            return  item.title.toLowerCase() === searchTerm;
          });
        
          if(option === undefined){
            this.props().onValidation({title: searchTerm});
            return;
          }

          this.props().onValidation(option);
    }

    handleValueChanged (value : any):void {
        this.setField({selectedValue : value});
    }

    getAvailableOptions (): any[] {
        const searchTerm = this.state().searchValue.toLowerCase();

        if(searchTerm === "") return [];

        const options = this.props().values.filter(item => {
          return  (
              item.title.toLowerCase().includes(searchTerm) && 
              !this.props().blockedValues.find(blockedItem => blockedItem.title === item.title)
          );
        });

        if(options.length === 1 && options[0].title === searchTerm) return [];

        return options;
    }


}