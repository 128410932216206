import {ContentViewModel, ContentView} from '@levelapp/softfabric';
import ConfirmPopUpViewState from './ConfirmPopUpViewState';
import ConfirmPopUpViewProps from './ConfirmPopUpViewProps';

export default class ConfirmPopUpViewModel extends ContentViewModel<ConfirmPopUpViewState, ConfirmPopUpViewProps>
{
    constructor(props: ContentView)
    {
        super(props);

        /* Initial State */
        this.initialState({
        });

        /* BINDINGS */
        this.handleBackPressed = this.handleBackPressed.bind(this);
        this.handleSavePressed = this.handleSavePressed.bind(this);
    }

    /* COMPONENT EVENTS */
    componentWillMount(){}

    /* HANDLERS */
    handleBackPressed()
    {
        this.closePopUp();
    }

    handleSavePressed(){        
        this.closePopUp();
        this.props().callBack();
    }
}
