 
import { ContentView } from '@levelapp/softfabric';
import ActivationCodeViewState from './ActivationCodeViewState';
import ActivationCodeViewProps from './ActivationCodeViewProps';
import ActivationCodeViewStyle from './ActivationCodeViewStyle';
import ActivationCodeViewModel from './ActivationCodeViewModel';
import { View } from 'react-native';
import HeaderView from '../../../components/header/HeaderView';
import ExtendedTextInputView from '../../../components/extendedtextinput/ExtendedTextInputView';
import ExtendedDatePickerView from '../../../components/extendeddatepicker/ExtendedDatePickerView';

import { ButtonView, TranslationView } from '@levelapp/softfabric-ui';
import Constants from 'src/Common/Constants/Constants';

export default class ActivationCodeView extends ContentView<ActivationCodeViewProps, ActivationCodeViewState, ActivationCodeViewModel, ActivationCodeViewStyle>
{
  /* CONSTRUCTOR */
  constructor(props: ActivationCodeViewProps) {
    super(props);

    // Binding
    this.bind(new ActivationCodeViewModel(this), new ActivationCodeViewStyle(this));
  }




  /* RENDERING */
  render() {
    return (
      <View style={this.style.container}>
        <View style={this.style.box}>
          
          <HeaderView
            title={"ActivationCode.title"}
            description={"ActivationCode.description"}
            shouldShowButton={false}
          />
          <View style={this.style.boxContainer}>
          <ExtendedTextInputView
              title={"form.pointstoearn"}
              showError={false}
              value={`${this.state.payload.points}`}
              onChangeText={(text: string) => this.binding.handleValuePointsChange(text)}
            />   

            <ExtendedTextInputView
              title={"form.limitouse"}
              showError={false}
              value={`${this.state.payload.limit}`}
              onChangeText={(text: string) => this.binding.handleValueLimitChange(text)}
            />  

            <ExtendedTextInputView
              title={"form.activationcode"}
              showError={false}
              value={`${this.state.payload.code}`}
              onChangeText={(text: string) => this.binding.handleCodeChange(text)}
            />  

            <ExtendedDatePickerView
                      zIndex={1}
                      title={'form.enddate'}
                      selected={this.state.payload.validityEndDate}
                      placeholder={this.binding.translate('form.enddate.placeholder')}
                      handleOnChange={(date : Date) => this.binding.handleValueDateChange(date)}
                    /> 
            <View style={this.style.generator}>

            <ButtonView 
                isLoading={this.state.isLoading}
                style={this.style.button} 
                backgroundColor={Constants.GradiantStartColor}
                handleClick={this.binding.handleGenerate} 
                animation={"None"} 
                borders={"Little"}>
                <TranslationView>generic.generateCode</TranslationView>
            </ButtonView>
            </View>
          </View>
        </View>
      </View>
    );
  }
}