import { ContentViewStyle } from '@levelapp/softfabric';
import { StyleProp, ViewStyle, TextStyle } from 'react-native';

export default class OrderViewStyle extends ContentViewStyle
{
    order: StyleProp<any> = {
        width: 275,
        marginRight: 12,
        borderRadius: 5,
        marginBottom: 10,
        borderWidth: 2,
        boxShadow: '4px 4px 13px -6px rgba(140,140,140,0.5)'
    };

    borderTimeOut: StyleProp<ViewStyle> = {
        borderColor: '#C81616'
    }

    borderDefault: StyleProp<ViewStyle> = {
        borderColor: 'transparent'
    }

    orderHeader: StyleProp<ViewStyle> = {
        paddingRight: 15,
        paddingLeft: 20,
        paddingTop: 12,
        paddingBottom: 9
    };

    orderHeaderSequelLabel: StyleProp<TextStyle> = {
        color: '#0C8EF4'
    }

    basketLines: StyleProp<ViewStyle> = {
        backgroundColor: 'white'
    };

    basketLine: StyleProp<ViewStyle> = {
        paddingVertical: 12,
        paddingHorizontal: 20
    };

    firstLineContainer: StyleProp<ViewStyle> = {
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'flex-start',
        width: '100%'
    };

    secondLineContainer: StyleProp<ViewStyle> = {
        alignItems: 'center',
        flexDirection: 'row',
        justifyContent: 'space-between'
    };

    seeMore: StyleProp<ViewStyle> = {
        flex: 1,
        backgroundColor: 'white',
        justifyContent: 'flex-end',
        alignItems: 'center',
        paddingBottom: 5
    };

    nextScreen: StyleProp<ViewStyle> = {
        backgroundColor: 'white',
        justifyContent: 'center',
        alignItems: 'center',
        paddingTop: 20,
        paddingBottom: 5
    };

    menu: StyleProp<any> = {
        width: 250,
        backgroundColor: 'white',
        position: 'absolute',
        right: -125,
        top: 40,
        paddingHorizontal: 20,
        paddingVertical: 12.5,
        boxShadow: 'rgba(164, 164, 164, 0.5) 0px 2px 4px 2px'
    };

    menuOption: StyleProp<TextStyle> = {
        paddingVertical: 12.5
    };

    overlay: StyleProp<ViewStyle> = {
        position: 'absolute',
        height: '100%',
        width: '100%',
        backgroundColor: 'black',
        opacity: 0.3
    };

    selectedContainer: StyleProp<ViewStyle> = {
        position: 'absolute',
        top: 17,
        left: 17
    };

    orderNumber: StyleProp<ViewStyle> = {
        backgroundColor: 'white',
        justifyContent: 'center',
        alignItems: 'center',
        paddingVertical: 5,
        paddingHorizontal: 10,
        borderRadius: 3
    };

    orderPriceContainer: StyleProp<ViewStyle> = {
        flexDirection: 'row',
        backgroundColor: 'white',
        justifyContent: 'space-between',
        padding: 20,
        paddingLeft: 58,
        borderTopWidth: 1,
        borderTopColor: '#DDDDDD'
    };

    orderPriceLabel: StyleProp<TextStyle> = {
        fontSize: 18,
    }

    orderPrice: StyleProp<TextStyle> = {
        fontSize: 21,
        fontWeight: 'bold'
    }
    remark: StyleProp<ViewStyle> = {
        flexDirection: 'row',
    }
    remarkHeader: StyleProp<TextStyle> = {
        fontSize: 14,
        color: '#FF9600',
        fontWeight: "700"
    }
    remarkText: StyleProp<TextStyle> = {
        fontSize: 14,
        color: '#FF9600',
        fontWeight: "500"
    }
}