import {ContentViewModel,ContentView} from '@levelapp/softfabric';
import SingleOrGroupViewState from './SingleOrGroupViewState';
import SingleOrGroupViewProps from './SingleOrGroupViewProps';

export default class SingleOrGroupViewModel extends ContentViewModel<SingleOrGroupViewState, SingleOrGroupViewProps>
{
      /* CONSTRUCTOR */
    constructor(view: ContentView)
    {
        super(view);

        this.initialState({}); // Initialize your state here

        // Bindings

    }
}