import {ContentViewModel,ContentView} from '@levelapp/softfabric';
import StatsSettingsPopupViewState from './StatsSettingsPopupViewState';
import StatsSettingsPopupViewProps from './StatsSettingsPopupViewProps';
import DispatcherManager from '../../../../../../../Common/Managers/DispatcherManager';

export default class StatsSettingsPopupViewModel extends ContentViewModel<StatsSettingsPopupViewState, StatsSettingsPopupViewProps>
{
    _dispatcherManager : DispatcherManager;


      /* CONSTRUCTOR */
    constructor(view: ContentView)
    {
        super(view);

        this.initialState({
            dashboard: this.props().dashboard,
            showErrors: false,
            tab: "main",
            isLoading: false,
        }); // Initialize your state here

        // Bindings
        this._dispatcherManager = new DispatcherManager();
        this.changeTab = this.changeTab.bind(this);
        this.handleSavePressed = this.handleSavePressed.bind(this);
        this.handleThirdPartIdChanged = this.handleThirdPartIdChanged.bind(this);
        this.handleChangeDashboardType = this.handleChangeDashboardType.bind(this);
    }

    componentDidMount()
    {
        this._dispatcherManager.valueThirdPartId = this.handleThirdPartIdChanged;  
    }

    changeTab(tab: any)
    {
        this.setField((previousState) => previousState.tab = tab);
    }

    handleSavePressed()
    {
        if (
            this.state().dashboard.thirdPartyId == null ||
            this.state().dashboard.dashboardType == undefined )
        {
            this.setField((previousState) => previousState.showErrors = true);
        }
        else
        {
            this.setField((previousState) => previousState.isLoading = true);
            this.props().save(this.props().dashboard);
        }
    }


    handleChangeDashboardType(value: any) {
        var state = this.state();
        state.dashboard.dashboardType = { id :value.value , key : value.title};
        state.dashboard.dashboardTypeId = value.value;
        this.setField({state});
    }

    /* VALUES */

    private handleThirdPartIdChanged(text: string)
    {
        this.setField((previousState) => previousState.dashboard.thirdPartyId = text);
    }
}