 
import {ContentView} from '@levelapp/softfabric';
import SingleOrGroupViewState from './SingleOrGroupViewState';
import SingleOrGroupViewProps from './SingleOrGroupViewProps';
import SingleOrGroupViewStyle from './SingleOrGroupViewStyle';
import SingleOrGroupViewModel from './SingleOrGroupViewModel';
import ButtonView from '../../../../../../../../../Common/Components/Button/ButtonView';

import {TranslationView} from '@levelapp/softfabric-ui';
import { View } from 'react-native';
import Constants from '../../../../../../../../../Common/Constants/Constants';

export default class SingleOrGroupView extends ContentView<SingleOrGroupViewProps, SingleOrGroupViewState, SingleOrGroupViewModel, SingleOrGroupViewStyle>
{
  /* CONSTRUCTOR */
  constructor(props:SingleOrGroupViewProps)
  {
    super(props);

    // Binding
    this.bind(new SingleOrGroupViewModel(this), new SingleOrGroupViewStyle(this));
  }

  /* RENDERING */
  render()
  {
    const { boxContainer , boxContainerText , boxContainerButton , styleButton} = this.style;

    return (
      <View style={boxContainer}>
        <View style={boxContainerText}>
          <TranslationView upperCase>
            menuconfigurator.popup.AddProduct.button.TextProduct
          </TranslationView>
        </View>
        <View style={boxContainerButton}>
          <ButtonView isLoading={false} handleClick={this.props.onPressProduct} backgroundColor={Constants.MainColor} style={[styleButton]} >
            <TranslationView upperCase>
            menuconfigurator.popup.AddProduct.button.simpleProduct
            </TranslationView>
          </ButtonView>
          <ButtonView isLoading={false} handleClick={this.props.onPressGroup} backgroundColor={Constants.MainColor} style={[styleButton]} >
            <TranslationView upperCase>
                menuconfigurator.popup.AddProduct.button.groupProduct
            </TranslationView>
          </ButtonView>
        </View>
      </View>
    );
  }
}