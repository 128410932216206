import { Service, Application, HttpManager , Managers } from '@levelapp/softfabric';
import { ReferenceTypeEnum } from '../Common/Enums/ReferenceTypeEnum';

export default class LogService extends Service {
    
    httpClient = Application.current.resolve<HttpManager>(Managers.HTTP_MANAGER);

    async getByReferenceType(referenceTypeId: ReferenceTypeEnum)
    {       
        return await this.httpClient
        .get(`log/GetByReferenceType/${referenceTypeId}`, [])
        
    }

    async getByReferenceValueAndType(referenceTypeId: ReferenceTypeEnum , referencevalue : string)
    {       
        return await this.httpClient
        .get(`log/GetByReferenceValueAndType/${referencevalue}/${referenceTypeId}`, [])
        
    }


    async getBasketLog(basketId: number)
    {       
        return await this.httpClient
        .get(`log/GetBasketLog/${basketId}`, [])
        
    }


    async getAllBasketByStoreIdAndDate(storeId: number, from : Date , to : Date)
    {    
        var param = [{key : 'storeId' , value : storeId} , { key : 'from' , value : from.toISOString().split('T')[0]}]
        if(to != undefined)
        {
            param.push( { key : 'to' , value : to.toISOString().split('T')[0]});
        }
        return await this.httpClient
        .get(`log/GetByStoreIdAndDate`, param)
        
    }

    async GetAllLogForAllStores(from : Date , to : Date)
    {    
        var param = [{ key : 'from' , value : from.toISOString().split('T')[0]} , { key : 'to' , value : to.toISOString().split('T')[0]}];

        return await this.httpClient
        .get(`log/GetAllLogForAllStores`, param)
        
    }
}