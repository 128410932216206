import { ContentView } from "@levelapp/softfabric";
import MembershipListViewState from "./MembershipListViewState";
import MembershipListViewProps from "./MembershipListViewProps";
import MembershipListViewStyle from "./MembershipListViewStyle";
import MembershipListViewModel from "./MembershipListViewModel";
import { TranslationView } from "@levelapp/softfabric-ui";
import { CheckBox, View, Text } from "react-native";
import ExtendedTranslationView from "src/Presentation/modules/dashboard/components/extendedtranslation/ExtendedTranslationView";

export default class MembershipListView extends ContentView<
    MembershipListViewProps,
    MembershipListViewState,
    MembershipListViewModel,
    MembershipListViewStyle
> {
    /* CONSTRUCTOR */
    constructor(props: MembershipListViewProps) {
        super(props);

        // Binding
        this.bind(
            new MembershipListViewModel(this),
            new MembershipListViewStyle(this)
        );
    }

    /* RENDERING */
    render() {
        const { checkbox } = this.style;

        return (
            <View style={[this.props.style]}>
                {this.props.title != "" &&
                this.props.title != undefined &&
                this.props.value &&
                this.props.value.length > 0 ? (
                    <>
                        <ExtendedTranslationView
                            styleText={this.props.styleTitle}
                            uppercase={
                                this.props.uppercase != undefined
                                    ? this.props.uppercase
                                    : true
                            }
                            afterTitle={this.props.extraTitle}
                            infoText={this.props.infoText}
                        >
                            {this.props.title}
                        </ExtendedTranslationView>
                        {this.props.value.map((data: any) => {
                            return (
                                <View style={{ flexDirection: "column" }}>
                                    <View style={{ flexDirection: "row" }}>
                                        <CheckBox
                                            disabled={true}
                                            style={checkbox}
                                            value={data.isChecked}
                                            onValueChange={() => {}}
                                        />
                                        <Text>{data.name}</Text>
                                    </View>
                                    {data.startDate != null && (
                                        <View style={{ flexDirection: "row" }}>
                                            <Text
                                                style={{
                                                    marginBottom: 3,
                                                    fontWeight: "bold",
                                                }}
                                            >
                                                {" "}
                                                Creation date :{" "}
                                            </Text>
                                            <Text style={{ marginBottom: 3 }}>
                                                •{" "}
                                                {data.startDate.substring(
                                                    0,
                                                    10
                                                )}
                                            </Text>
                                        </View>
                                    )}
                                    {data.endDate != null && (
                                        <View style={{ flexDirection: "row" }}>
                                            <Text
                                                style={{
                                                    marginBottom: 3,
                                                    fontWeight: "bold",
                                                }}
                                            >
                                                {" "}
                                                Expiration date :{" "}
                                            </Text>
                                            <Text style={{ marginBottom: 3 }}>
                                                •{" "}
                                                {data.endDate.substring(0, 10)}
                                            </Text>
                                        </View>
                                    )}
                                </View>
                            );
                        })}
                    </>
                ) : (
                    <>
                        <ExtendedTranslationView
                            styleText={this.props.styleTitle}
                            uppercase={
                                this.props.uppercase != undefined
                                    ? this.props.uppercase
                                    : true
                            }
                            afterTitle={this.props.extraTitle}
                            infoText={this.props.infoText}
                        >
                            {this.props.title}
                        </ExtendedTranslationView>
                        <View style={{ flexDirection: "row" }}>
                            <Text>
                                {this.props.user.firstName}{" "}
                                {this.props.user.lastName}
                            </Text>
                            <TranslationView style={{ paddingBottom: "5%" }}>
                                {"form.nomemberships"}
                            </TranslationView>
                        </View>
                    </>
                )}
            </View>
        );
    }
}
