import {ContentViewStyle, Application} from '@levelapp/softfabric';
import {StyleProp,ViewStyle, TextStyle} from 'react-native';
import { StyleManager, UiManagers } from '@levelapp/softfabric-ui';

export default class ProfileMenuViewStyle extends ContentViewStyle
{
    MyStyleManager = Application.current.resolve<StyleManager>(UiManagers.STYLE_MANAGER);

    page: StyleProp<ViewStyle> = {
        flex: 1
    }

    container: StyleProp<ViewStyle> = {      
        zIndex: -1,
        backgroundColor: "#D8D8D8",
        width: "100%",
        height: 1,
    }

    box : StyleProp<any> = {
        boxShadow: '0 5px 14px 0 rgba(169, 169, 169,0.7)',
        height : 175 , 
        width : 175 , 
        backgroundColor : 'white',
        zIndex : 1, 
        borderRadius : 5,
        position : 'absolute' , 
        bottom : 0
    }

    cosmos : StyleProp<ViewStyle> =
    {
        marginHorizontal : 10,
        flex : 1,
        justifyContent : 'space-evenly', 
    }

    link: StyleProp<ViewStyle> = {
        borderRadius: 3,
        width : '100%',
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'row',

        height: 35,
    };

    linkTextActive: StyleProp<TextStyle> = {
        color: this.MyStyleManager.activeTextColor,
        fontSize: 14,
        paddingLeft : 25,
    };

    linkText: StyleProp<TextStyle> = {
        color: '#a9afbb',
        fontSize: this.MyStyleManager.fontSize
    };

    text: StyleProp<TextStyle> = {
        paddingVertical : 5,
        fontSize: 12
    };
}