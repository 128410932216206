 
import { ContentShellView} from '@levelapp/softfabric';
import ShellRewardsViewState from './ShellRewardsViewState';
import ShellRewardsViewProps from './ShellRewardsViewProps';
import ShellRewardsViewStyle from './ShellRewardsViewStyle';
import ShellRewardsViewModel from './ShellRewardsViewModel';
import { View } from 'react-native';

export default class ShellRewardsView extends ContentShellView<ShellRewardsViewProps, ShellRewardsViewState, ShellRewardsViewModel, ShellRewardsViewStyle>
{
  /* CONSTRUCTOR */
  constructor(props:ShellRewardsViewProps)
  {
    super(props);

    // Binding
    this.bind(new ShellRewardsViewModel(this), new ShellRewardsViewStyle(this));
  }

  /* RENDERING */
  render()
  {
    return (
      <View style={{flex : 1}}>
        {this.getSwitch()}
      </View>
    );
  }
}