import {ContentShellViewModel, ContentShellView} from '@levelapp/softfabric';
import ShellCrosssellconfiguratorViewState from './ShellCrosssellconfiguratorViewState';
import ShellCrosssellconfiguratorViewProps from './ShellCrosssellconfiguratorViewProps';
 
import CrossSellConfiguratorView from './pages/crosssellconfigurator/CrossSellConfiguratorView';
import EnumModules from '../../../../../Common/Constants/EnumModules';

export default class ShellCrosssellconfiguratorViewModel extends ContentShellViewModel<ShellCrosssellconfiguratorViewState, ShellCrosssellconfiguratorViewProps>
{

    constructor(view : ContentShellView)
    {
        super(view);

        this.registerEntryPoint('main'); 
        this.register(<CrossSellConfiguratorView/> ,'main'); 

        this.initialisation();
    }

    getModuleId()
    {
        return EnumModules.CROSS_SELL_CONFIGURATOR; 
    }

}