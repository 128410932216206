import { ContentViewStyle } from '@levelapp/softfabric';
import { StyleProp, ViewStyle, TextStyle } from 'react-native';
import Constants from 'src/Common/Constants/Constants';

export default class AdditionalInfosViewStyle extends ContentViewStyle {
    page: StyleProp<ViewStyle> = {
        flex: 1
    }

    section: StyleProp<ViewStyle> = {
        alignSelf: 'flex-end',
        marginVertical: 15,
        flexDirection: 'row'
    };

    inputTitle: StyleProp<TextStyle> = {
        fontSize: 14,
        fontWeight: '600',
        color: '#3F3F3F',
        fontFamily: 'Montserrat SemiBold',
        marginBottom: 13,
    };

    saveButton: StyleProp<ViewStyle> = {
        display: 'flex',
        alignItems: 'center',
        backgroundColor: Constants.MainColor,
        padding: 10,
        borderRadius: 10,
        width: 200,
        height: 40,
        marginTop: 30
      }
}