import { ContentViewStyle } from '@levelapp/softfabric';
import { StyleProp, ViewStyle } from 'react-native';

export default class CountriesViewStyle extends ContentViewStyle
{
    container: StyleProp<ViewStyle> =
        {
            display: "flex",
            flexDirection: "column",
            paddingHorizontal: 170,
            paddingVertical: 50
        };
}