 
import {ContentView} from '@levelapp/softfabric';
import SelectionViewState from './SelectionViewState';
import SelectionViewProps from './SelectionViewProps';
import SelectionViewStyle from './SelectionViewStyle';
import SelectionViewModel from './SelectionViewModel';
import { FlatList, View } from 'react-native';
import PopupHeaderView from '../popupheader/PopupHeaderView';
import ExtendedCheckBoxView from '../extendedcheckbox/ExtendedCheckBoxView';
import SectionTitleView from '../sectiontitle/SectionTitleView';
import ButtonView from '../../../../../Common/Components/Button/ButtonView';
import { TranslationView } from '@levelapp/softfabric-ui';
import ResearchInputView from '../../../../../Common/Components/researchinput/ResearchInputView';
import Product from '../../../../../Common/DTO/Product';
import TranslationValue from '../../../../../Common/DTO/TranslationValue';
import StepView from '../../../../../Common/Components/step/StepView';
import Constants from '../../../../../Common/Constants/Constants';

export default class SelectionView extends ContentView<SelectionViewProps, SelectionViewState, SelectionViewModel, SelectionViewStyle>
{
  private maxItemPerPage = 40;
   
  /* CONSTRUCTOR */
  constructor(props:SelectionViewProps)
  {
    super(props);

    // Binding
    this.bind(new SelectionViewModel(this), new SelectionViewStyle(this));
  }

  /* RENDERING */
  render()
  {
    // var sorted = this.props.objects.sort((y) => this.state.activeSelection.find(x => x.id == y.id) != undefined ? -1 : 1)
    var searched = this.props.objects.filter((x : Product) => x.titleTranslations == undefined ? false : (x.titleTranslations as any[]).find((y : TranslationValue) => y.value.toLocaleLowerCase().includes(this.state.searchText.toLocaleLowerCase())) != null);
    var skiped = searched.length > this.maxItemPerPage ? searched.slice((this.state.page - 1) * this.maxItemPerPage,this.state.page * this.maxItemPerPage) : searched;

    return (
      <View style={{width : '100%'}}>
        <PopupHeaderView
          handleBackPressed={() =>
            {
              this.props.handleClose();
            }}
          title={this.props.headerTitle}
          shouldShowButton={false}
          isLoading={false}

          handleOnPressButton={this.binding.onPressSave}
        />
        <View style={{paddingHorizontal : 100 , paddingVertical : 50}}>
          <SectionTitleView title={'rewards.popup.selection.description'}/>
          <ResearchInputView
              placeholder={this.binding.translate('generic.search')}
              handlerResearching={(searchText: string) => { this.binding.handleResearching(searchText) }}
              handlingClose={() => { this.binding.handleCloseResearching() }} />
            <View style={{flexWrap  : 'wrap' , height : 300 , }}>
              <FlatList

                columnWrapperStyle={{alignItems : 'flex-start' , justifyContent : 'space-between' , flex : 1/4 }}
                data={skiped}
                horizontal={false}
                numColumns={4}
                renderItem={({item})=>
                  {
                    var value = item;
                    return <ExtendedCheckBoxView
                    rewardProductsList={true} 
                    title={this.props.objectTitle(value)} 
                    isTranslation={false} 
                    value={this.state.activeSelection.find(x => x.id == value.id) != undefined} 
                    onValueChange={() =>{this.binding.handleOnChangeValue(value)}}/>
                  }}
              >

              </FlatList>
          </View>
          <StepView 
            style={{alignSelf : 'flexStart' , paddingBottom : 20}}
            currentPage={this.state.page - 1} 
            handleChangingPage={this.binding.changePage} 
            minimumPage={2} 
            numberDisplays={Math.ceil(searched.length / this.maxItemPerPage)} />
          <View style={{flexDirection : 'row' }}>
            <ButtonView isLoading={false} handleClick={this.props.handleOnPressButton} backgroundColor="#4696ec">
              <TranslationView upperCase>
                {this.props.buttonTitle}
              </TranslationView>
            </ButtonView>
            <View style={{paddingLeft : 20}}>
              <ButtonView isLoading={false} handleClick={this.binding.onPressSave} backgroundColor={Constants.MainColor}>
                <TranslationView upperCase>
                  generic.button.save
                </TranslationView>
              </ButtonView>
            </View>
            
          </View>
          
        </View>
        
      </View>
    );
  }
}