 
import {ContentShellView} from '@levelapp/softfabric';
import ShellMembershipViewState from './ShellMembershipViewState';
import ShellMembershipViewProps from './ShellMembershipViewProps';
import ShellMembershipViewStyle from './ShellMembershipViewStyle';
import ShellMembershipViewModel from './ShellMembershipViewModel';
import {View} from 'react-native';

export default class ShellMembershipView extends ContentShellView<ShellMembershipViewProps, ShellMembershipViewState, ShellMembershipViewModel, ShellMembershipViewStyle>
{
  /* CONSTRUCTOR */
  constructor(props:ShellMembershipViewProps)
  {
    super(props);

    // Binding
    this.bind(new ShellMembershipViewModel(this), new ShellMembershipViewStyle(this));
  }

  /* RENDERING */
  render()
  {
    return (
      <View style={{flex : 1}}>
        {this.getSwitch()}
      </View>
    );
  }
}