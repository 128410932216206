 
import {ContentShellView} from '@levelapp/softfabric';
import ShellProductsToolViewState from './ShellProductsToolViewState';
import ShellProductsToolViewProps from './ShellProductsToolViewProps';
import ShellProductsToolViewStyle from './ShellProductsToolViewStyle';
import ShellProductsToolViewModel from './ShellProductsToolViewModel';
import {View} from 'react-native';

export default class ShellProductsToolView extends ContentShellView<ShellProductsToolViewProps, ShellProductsToolViewState, ShellProductsToolViewModel, ShellProductsToolViewStyle>
{
  /* CONSTRUCTOR */
  constructor(props:ShellProductsToolViewProps)
  {
    super(props);

    // Binding
    this.bind(new ShellProductsToolViewModel(this), new ShellProductsToolViewStyle(this));
  }

  /* RENDERING */
  render()
  {
    return (
      <View style={{flex : 1}}>
        {this.getSwitch()}
      </View>
    );
  }
}