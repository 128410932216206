 
import {ContentView} from '@levelapp/softfabric';
import AddCrossSellProductPopUpViewState from './AddCrossSellProductPopUpViewState';
import AddCrossSellProductPopUpViewProps from './AddCrossSellProductPopUpViewProps';
import AddCrossSellProductPopUpViewStyle from './AddCrossSellProductPopUpViewStyle';
import AddCrossSellProductPopUpViewModel from './AddCrossSellProductPopUpViewModel';
import {TranslationView} from '@levelapp/softfabric-ui';
import { View } from 'react-native';
import Helpers from '../../../../../../../Common/Helpers/Helpers';
import DescriptionView from '../../../../components/description/DescriptionView';
import PopupHeaderView from '../../../../components/popupheader/PopupHeaderView';
import MainInformationView from './maininformation/MainInformationView';
import LineView from '../../../../components/line/LineView';

export default class AddCrossSellProductPopUpView extends ContentView<AddCrossSellProductPopUpViewProps, AddCrossSellProductPopUpViewState, AddCrossSellProductPopUpViewModel, AddCrossSellProductPopUpViewStyle>
{
  /* CONSTRUCTOR */
  constructor(props:AddCrossSellProductPopUpViewProps)
  {
    super(props);

    // Binding
    this.bind(new AddCrossSellProductPopUpViewModel(this), new AddCrossSellProductPopUpViewStyle(this));
  }

  /* RENDERING */
  render()
  {
    return (
      <View style={this.style.popup}>

            <PopupHeaderView
              handleBackPressed={() =>
                {
                  this.props.refreshParent()
                  Helpers.closePopup()
                }}
              title={"crosssell.popup.add.title"}
              shouldShowButton
              isLoading={this.state.isLoading}
              handleOnPressButton={this.binding.handleSavePressed}
            />

            {/*Pages*/}

              <MainInformationView
                product={this.state.product}
                languages={this.state.languages}
                showErrors={this.state.showErrors}
                handleTranslationsChange={this.binding._dispatcherManager.handleTranslationsChange}
                handleCheckboxChange={this.binding._dispatcherManager.handleCheckboxChange}
                handleValueChange={this.binding._dispatcherManager.handleValueChange}
              />

              <LineView/>

              <DescriptionView
                listDesc={this.state.product.descriptionTranslations}
                languages={this.state.languages}
                shouldShowError={this.state.showErrors}
                handleTranslationsChange={this.binding._dispatcherManager.handleTranslationsChange}

              />
            {this.state.showErrors &&
              < View style={this.style.footer} >
                <TranslationView style={{ color: "red" }}>error.please.fields</TranslationView>  
              </View>
            }
          </View>
    );
  }
}