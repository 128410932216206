import { Application, ContentViewStyle } from '@levelapp/softfabric';
import { StyleManager, UiManagers } from '@levelapp/softfabric-ui';
import { ViewStyle, StyleProp, TextStyle, Dimensions } from 'react-native';

export default class MessagesViewStyle extends ContentViewStyle {
    MyStyleManager : StyleManager = Application.current.resolve<StyleManager>(UiManagers.STYLE_MANAGER);
    
    container : StyleProp<ViewStyle> =
    {
        display: "flex", 
        paddingHorizontal: 170, 
        paddingVertical: 50,
    }

    popup : StyleProp<ViewStyle> =
    {
        minHeight : (Dimensions.get('window').height /100) * 80 , 
        
    }

    box: StyleProp<ViewStyle> = {
        width: '100%',
        backgroundColor: 'white',
        borderRadius: 6,
        marginTop: 20,
        shadowColor: "#0000006b",
        shadowOffset: {
            width: 1,
            height: 0,
        },
        shadowOpacity: 0.70,
        shadowRadius: 20.00,
        elevation: 60,
        
    }
    
    boxContainer: StyleProp<ViewStyle> = {
        display: 'flex',
        alignSelf: 'center',
        minHeight: 250,
        width: '100%',
        paddingTop: 30,
        paddingBottom: 30,
        paddingLeft: 50,
        paddingRight: 50,
    }
    
    subHeading: StyleProp<TextStyle> = {
        fontSize: 19,
    }
    itemStyle: StyleProp<ViewStyle> = {
        padding: 10,
        marginTop: 2,
        backgroundColor: '#ddd',
        borderColor: '#bbb',
        borderWidth: 1,
        borderRadius: 5,
    }
    
    buttonBox : StyleProp<any> =
    {
        position : 'sticky',
        bottom : 30,
        width : 200,
        marginRight : 40,
        left : '100%'
    }

    checkBoxLabel : StyleProp<ViewStyle> =
    {
        flexDirection: 'row',
        flex:1,
        paddingLeft: 30,
        
    }
    
    checkBoxContainer : StyleProp<ViewStyle> =
    {
        flexDirection : 'column',
        marginBottom : 16
    }
}