import { Service, Application, HttpManager, Managers } from '@levelapp/softfabric';

export default class NewsService extends Service
{
    httpClient = Application.current.resolve<HttpManager>(Managers.HTTP_MANAGER);

    async get(parameters : any[], onSuccess: Function, onError : Function = () => {})
    {       
        this.httpClient.handleSomethingWentWrong = onError;
        this.httpClient.handleNotFound = onError;

        this.httpClient
        .get('news', parameters)
        .then((response : any) => {
            if (response)
            {
                if (response.data)
                {                  
                    onSuccess(response.data);
                }
            }
        });
    }

    post(news:any, callback : Function = () => {}, onError : Function = () => {})
    {
        this.httpClient.handleSomethingWentWrong = onError;
        this.httpClient.handleNotFound = onError;

        this.httpClient
            .post('news', [], news)
            .then((response : any) => 
            {
                if (response)
                {
                    /*if (response.data)
                    {
                        callback(response.data);
                    }*/
                    callback("");
                }
            });
    }

    put(news:any, callback : Function = () => {}, onError : Function = () => {})
    {
        this.httpClient.handleSomethingWentWrong = onError;
        this.httpClient.handleNotFound = onError;

        this.httpClient
            .put('news', [], news)
            .then((response : any) => 
            {
                if (response)
                {
                    /*if (response.data)
                    {
                        callback(response.data);
                    }*/
                    callback("");
                }
            });
    }

    delete(news:any, callback : Function = () => {}, onError : Function = () => {})
    {
        this.httpClient.handleSomethingWentWrong = onError;
        this.httpClient.handleNotFound = onError;

        this.httpClient
            .delete('news/delete/'+news.id)
            .then((response : any) => 
            {
                if (response)
                {
                     /*if (response.data)
                    {
                        callback(response.data);
                    }*/
                    callback("");
                }
            });
    }
}