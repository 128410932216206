 
import { View } from 'react-native';
import {ContentView} from '@levelapp/softfabric';
import HistorySectionViewState from './HistorySectionViewState';
import HistorySectionViewProps from './HistorySectionViewProps';
import HistorySectionViewStyle from './HistorySectionViewStyle';
import HistorySectionViewModel from './HistorySectionViewModel';
import TableView from '../../../../../../../../../../../Common/Components/TabelView/TableView';

import HistoricSectionNavigationView from './historicsectionnavigation/HistoricSectionNavigationView';

export default class HistorySectionView extends ContentView<HistorySectionViewProps, HistorySectionViewState, HistorySectionViewModel, HistorySectionViewStyle>
{
  /* CONSTRUCTOR */
  constructor(props:HistorySectionViewProps)
  {
    super(props);

    // Binding
    this.bind(new HistorySectionViewModel(this), new HistorySectionViewStyle(this));
  }

  /* RENDERING */
  render()
  {
    const { boxContainer , tableHeader , stepColor} = this.style;

    return (

       <View style={{ flexDirection: "column", }}>

          {/*Onglets de navigation*/}
          <HistoricSectionNavigationView
            currentTab={this.state.historyTab}
            onChangeTab={this.binding.changeTab}/>


          <View style={boxContainer}>
            <TableView
              headerTextStyle={tableHeader}
              backgroundStepColor={stepColor}
              nothingToDisplayText="No results"
              isLastColumnActions={true}
              data={this.state.tableData}
              isLoading={this.state.isLoading}
              headers={this.state.tableHeaders}
              onPageChange={(page: number) => { this.binding.onPageChange(page) }}
              handleSortChange={(indexHeaderSort: number, sortOrder: string) => { this.binding.onSortChange(indexHeaderSort, sortOrder) }}
              nbPage={this.state.nbPage}
              currentPage={this.state.currentPage - 1}                  
              isSortable={true} />
          </View>

      </View>
    );
  }
}