import {ContentViewModel,ContentView} from '@levelapp/softfabric';
import ConfirmGenericPopUpViewState from './ConfirmGenericPopUpViewState';
import ConfirmGenericPopUpViewProps from './ConfirmGenericPopUpViewProps';

export default class ConfirmGenericPopUpViewModel extends ContentViewModel<ConfirmGenericPopUpViewState, ConfirmGenericPopUpViewProps>
{
    constructor(view: ContentView)
    {
        super(view);

        this.initialState(
            {
                isLoadingAccept : false
            }); // Initialize your state here

        // Bindings
        this.handleAcceptPressed = this.handleAcceptPressed.bind(this);
        this.handleBackPopupPressed = this.handleBackPopupPressed.bind(this);
    }

    componentDidMount()
    {
        //TODO: Launch animation in;
    }

    handleBackPopupPressed()
    {
        //TODO: Launch animation out;
        this.props().handleOnPressBack();
    }

    handleAcceptPressed()
    {
        this.setField({isLoadingAccept : true});
        this.props().handleOnPressAccepted();
    }
}
