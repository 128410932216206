import { Service, HttpManager, Application, Managers } from '@levelapp/softfabric';
import Constants from 'src/Common/Constants/Constants';
import UserRoles from 'src/Common/DTO/M&N/UserRoles';
import PointUpdate from '../Common/DTO/PointUpdate';
import User from '../Common/DTO/User';

export default class UsersService extends Service
{
    httpClient: HttpManager;

    constructor()
    {
        super();
        this.httpClient = Application.current.resolve(Managers.HTTP_MANAGER);
        this.httpClient.handleSomethingWentWrong = (response: any) =>
        {
            console.warn('Erreur : ' + response.data);
        };
    }

    get(parameters: any[], callback: Function = () => { })
    {
        this.httpClient
            .get('users', parameters)
            .then((response: any) =>
            {
                if (response)
                {
                    if (response.data)
                    {
                        callback(response.data.data);
                    }
                }
            });
    }

    getProfile(callback: Function = () => { })
    {
        this.httpClient
            .get('users/GetProfile', [])
            .then((response: any) =>
            {
                if (response)
                {
                    if (response.data)
                    {
                        callback(response.data.data);
                    }
                }
            });
    }

    getAll(callback: Function = () => { })
    {
        this.httpClient
            .get('users/GetAll')
            .then((response: any) =>
            {
                if (response)
                {
                    if (response.data)
                    {
                        callback(response.data.data);
                    }
                }
            });
    }

    getUserById(id : number, callback: Function = () => { }, callBackError: Function = () => { })
    {
        this.httpClient.handleSomethingWentWrong = callBackError;

        this.httpClient
            .get(`users/Get/${id}`)
            .then((response: any) =>
            {
                if (response)
                {
                    if (response.data)
                    {
                        callback(response.data.data);
                    }
                }
            });
    }

    getUserByLoyaltyCode(code: string, callBack: Function = () => { }, callBackError: Function = () => { })
    {
        this.httpClient.handleSomethingWentWrong = callBackError;

        this.httpClient
            .get(`users/GetUserByLoyaltyCode/${code}`)
            .then((response: any) =>
            {
                if (response)
                {
                    if (response.data)
                    {
                        callBack(response.data.data);
                    }
                }
            });
    }

    put(user: any, callback: Function = () => { })
    {
        this.httpClient
            .put('users', [], user)
            .then((response: any) =>
            {
                if (response)
                {
                    /*if (response.data)
                  {
                      callback(response.data);
                  }*/
                    callback("");
                }
            });
    }

    updatePoints(parameter: PointUpdate, callBack: Function)
    {

        this.httpClient
            .put('users/updatePoints', [], parameter)
            .then((response: any) =>
            {
                if (response)
                {
                    callBack(response.data);
                }
            });
    }

    banUser(user: User, onSuccess: Function)
    {
        this.httpClient.post('users/BanUser/', [], user)
            .then((response: any) =>
            {
                if (response)
                {
                    onSuccess("succes");
                }
            })
    }


    deleteUser(guid: string, onSuccess: Function)
    {
        this.httpClient.delete(`users/DeleteByGuid/${guid}`)
            .then((response: any) =>
            {
                if (response)
                {
                    onSuccess();
                }
            })
    }

    loyaltyCardExist(code: string, onSuccess: Function)
    {
        this.httpClient.get(`users/LoyaltyCardExist/${code}`)
            .then((response: any) =>
            {
                if (response)
                {
                    onSuccess(response)
                }
            })
    }

    generateActivationCode(parameter: PointUpdate, callBack: Function)
    {

        this.httpClient
            .put('users/addPointsWithActivation', [], parameter)
            .then((response: any) =>
            {
                if (response)
                {
                    callBack(response.data);
                }
            });
    }

    async kioskRegister(user: UserRoles)
    {

        return await this.httpClient
            .post('users/KioskRegister', [],
                {
                    FirstName: user.user?.email?.split('@')[0],
                    LastName: "Kiosk",
                    Email: user.user?.email,
                    Language: Constants.DefaultKioskUserLanguage,
                    Password: user.user?.password,
                    ConfirmPassword: user.user?.confirmPassword
                });
    }
}
