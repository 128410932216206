import {ContentViewModel,ContentView, Application, LanguageManager, Managers} from '@levelapp/softfabric';
import VouchersViewState from './VouchersViewState';
import VouchersViewProps from './VouchersViewProps';
import LanguagesOperations from '../../../../../../../Transfer/LanguagesOperations';
import ContentTypeKeyOperations from '../../../../../../../Transfer/ContentTypeKeyOperations';
import Language from '../../../../../../../Common/DTO/Language';
import ContentTypeKey from '../../../../../../../Common/DTO/ContentTypeKey';
import Constants from '../../../../../../../Common/Constants/Constants';
import PaginationHelper from '../../../../../../../Common/Helpers/PaginationHelper';
import { ContentTypeEnum } from '../../../../../../../Common/Enums/ContentTypeEnum';
import TableRow from '../../../../../../../Common/Components/TabelView/TableRow';
import DateHelper from '../../../../../../../Common/Helpers/DateHelper';
import ErrorHelper from '../../../../../../../Common/Helpers/ErrorHelper';
import ConfirmPopUpView from '../../../../../../../Common/Components/confirmpopup/ConfirmPopUpView';
import ToastHelper from '../../../../../../../Common/Helpers/ToastHelper';
import Helpers from '../../../../../../../Common/Helpers/Helpers';
import VouchersOperations from '../../../../../../../Transfer/VouchersOperations';
import Voucher from '../../../../../../../Common/DTO/Voucher';
 
import VouchersPopUpView from '../../popup/voucherspopup/VouchersPopUpView';
import Type from '../../../../../../../Common/DTO/Type';
import Trigger from '../../../../../../../Common/DTO/Trigger';
import CountryOperations from '../../../../../../../Transfer/CountryOperations';
import Country from '../../../../../../../Common/DTO/Country';
import VoucherCountry from '../../../../../../../Common/DTO/M&N/VoucherCountry';

export default class VouchersViewModel extends ContentViewModel<VouchersViewState, VouchersViewProps>
{
    operation: VouchersOperations;
    languagesOperation: LanguagesOperations;
    contentTypeKeyOperation: ContentTypeKeyOperations;
    countryOperations : CountryOperations;
    _countries: Country[] = [];
    _languages: Language[] = [];
    _contentTypeKeys : ContentTypeKey [] = [];
    interval: any;
    searchText: string;
    isSorting: boolean;
    indexHeaderSort: number;
    sortOrder: string;
    sortKey: string[];
    moment : any;

    constructor(props: ContentView) {
        super(props);

        this.interval = null;
        this.searchText = '';
        this.isSorting = false;
        this.indexHeaderSort = -1;
        this.sortOrder = 'asc';
        this.sortKey = ['StartDate', 'EndDate', 'Title', 'Image'];
        this.moment = require('moment');
        /* Initial State */
        this.initialState({ 
            tableData: [], 
            tableHeaders: ['StartDate', 'EndDate', 'Title',  'Image'], 
            isLoadingVoucher: true,  
            isLoadingLanguage: true,  
            isLoadingContantTypeKeys : Constants.UseSettingsContent,
            nbPage: 0, 
            currentPage: 1, 
            isResearching: false });
        this.operation = new VouchersOperations();
        this.languagesOperation = new LanguagesOperations();
        this.contentTypeKeyOperation = new ContentTypeKeyOperations();
        this.countryOperations = new CountryOperations();

        this.countryOperations.get((countries:Country[]) => {
            this._countries = countries;
            this.setField({ isLoadingCountry: false });
        })
        

        this.languagesOperation.get((languages: any) => {
            this._languages = languages.data;
            this.setField({ isLoadingLanguage: false });
        });

        /* BINDINGS */
        this.editField = this.editField.bind(this);
        this.addField = this.addField.bind(this);
        this.deleteField = this.deleteField.bind(this);
        this.refresh = this.refresh.bind(this);
        this.onPageChange = this.onPageChange.bind(this);
        this.onSortChange = this.onSortChange.bind(this);
        this.handleResearching = this.handleResearching.bind(this);
        this.errorManagement = this.errorManagement.bind(this);
        this.handleCloseResearching = this.handleCloseResearching.bind(this);
    }


    componentDidMount() {
        this.refresh();
    }

    refresh() {
        this.setField({ 
            isLoadingVoucher: true, 
            isLoadingContantTypeKeys : Constants.UseSettingsContent,
        });
        Constants.UseSettingsContent && this.contentTypeKeyOperation.getContentTypeKeys(ContentTypeEnum.VOUCHER,(contentTypeKeys:ContentTypeKey[]) => {
            this._contentTypeKeys = contentTypeKeys;
            this.setField({ isLoadingContantTypeKeys: false });
        },this.errorManagement)
        let parameters =PaginationHelper.basicResearchPush(
            this.state().currentPage,
            20,
            this.sortKey[this.indexHeaderSort],
            this.sortOrder,
            Application.current.resolve<LanguageManager>(Managers.LANGUAGE_MANAGER).language ,
            this.searchText,
            this.isSorting,
            this.state().isResearching);
        this.getData(parameters);

    }
    
    getData(queryParamaters : any[]) {
        this.operation.get(queryParamaters, (vouchers: any) => {
            let tableData: TableRow[] = [];
            vouchers.data.objects.forEach((voucher: Voucher) => {
                let title = (voucher.title.translationValues == null || voucher.title.translationValues.length==0) ? "" : voucher.title.translationValues[0].value;
                voucher.StartDateInput = new Date(voucher.startDate);
                voucher.EndDateInput = new Date(voucher.endDate);
                let img = (voucher.image.translationValues == null|| voucher.image.translationValues.length==0) ? "" : voucher.image.translationValues[0].url;
                tableData.push({ data: [
                    DateHelper.formatToEuropeanDate(voucher.startDate), 
                    DateHelper.formatToEuropeanDate(voucher.endDate), 
                    title, 
                    img], 
                    isDeletable: true, 
                    isEditable: true, 
                    editCallback: () => { this.editField(voucher) }, 
                    deleteCallback: () => { this.deleteField(voucher) } });
            });
            this.setField({ tableData: tableData, vouchers: vouchers.data.objects, nbPage: vouchers.data.totalPage, isLoadingVoucher: false });
        },this.errorManagement)
    }

    addField() {
        const vc : VoucherCountry[]= [];
        this.showPopUp(
            <VouchersPopUpView
                voucher={{
                    isDelete : false,
                    isPreview : false,
                    EndDateInput : new Date(),
                    StartDateInput : new Date(),
                    endDate : new Date(),
                    startDate : new Date(),
                    titleTranslations: Helpers.generateTranslationTable(this._languages),
                    descriptionTranslations: Helpers.generateTranslationTable(this._languages),
                    imageTranslation: Helpers.generateUrlTable(this._languages),
                    thumbnailTranslation: Helpers.generateUrlTable(this._languages),
                    contentTypeKeyValue : [],
                    duration: 0,
                    prizeType: {} as Type,
                    type:{} as Type,
                    trigger:{} as Trigger,
                    voucherCountries: vc,
                }}
                refreshParent={this.refresh}
                languages={this._languages}
                contentTypeKeys={this._contentTypeKeys}
                countries={this._countries} />
        );
    }

    editField(voucher: any) 
    {
        let voucherCopy = Helpers.clone(voucher);
        voucherCopy.StartDateInput = new Date(voucherCopy.startDate);
        voucherCopy.EndDateInput = new Date(voucherCopy.endDate);
        voucherCopy.imageTranslation = Helpers.generateUrlTableEdit(voucherCopy.image.translationValues, this._languages);
        voucherCopy.thumbnailTranslation = Helpers.generateUrlTableEdit(voucherCopy.thumbnail.translationValues, this._languages);
        voucherCopy.titleTranslations = Helpers.generateUrlTableEdit(voucherCopy.title.translationValues, this._languages);
        voucherCopy.descriptionTranslations = Helpers.generateUrlTableEdit(voucherCopy.description.translationValues, this._languages);
        voucherCopy.trigger.title = voucherCopy.trigger.key;
        voucherCopy.type.title = voucherCopy.type.key;
        voucherCopy.prizeType.title = voucherCopy.prizeType.key;
        this.showPopUp(
            <VouchersPopUpView  
            voucher={voucherCopy} 
            refreshParent={this.refresh} 
            languages={this._languages} 
            contentTypeKeys={this._contentTypeKeys}
            countries={this._countries}/>
        );
    }

    deleteField(voucher: any) {
        this.showPopUp(
            <ConfirmPopUpView callBack={() => {
                this.operation.delete(voucher, () => {
                    this.showToast(ToastHelper.getToast("vouchers.popup.success.deleted","success"),() => {},2500);
                    this.refresh();
                },this.errorManagement);
            }} />
        );
    }

    

    onPageChange(page: number) {
        this.state().currentPage = page + 1;
        this.refresh()
    }

    onSortChange(indexHeaderSort: number, sortOrder: string) {
        if (indexHeaderSort == -1) {
            this.isSorting = false;
        } else {
            this.indexHeaderSort = indexHeaderSort;
            this.sortOrder = sortOrder;
            this.isSorting = true;
        }
        this.refresh();
    }

    handleResearching(searchText: string) {
        this.setField({ isLoading: true, isResearching: true });
        this.searchText = searchText;

        if (this.interval) {
            clearInterval(this.interval);
        }
        this.interval = setTimeout(() => {
            this.onPageChange(0)
        }, 1000)
    }

    handleCloseResearching() {
        this.state().isResearching = false;
        this.onPageChange(0);
    }

 

    errorManagement(error : any) 
    {
        this.setField({isLoading : false});
        ErrorHelper.showMessageError(error.response);
    }
}