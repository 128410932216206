 
import { ContentView } from '@levelapp/softfabric';
import StatsViewState from './StatsViewState';
import StatsViewProps from './StatsViewProps';
import StatsViewStyle from './StatsViewStyle';
import StatsViewModel from './StatsViewModel';
import { View } from 'react-native';

export default class StatsView extends ContentView<StatsViewProps, StatsViewState, StatsViewModel, StatsViewStyle>
{
  /* CONSTRUCTOR */
  constructor(props: StatsViewProps)
  {
    super(props);

    // Binding
    this.bind(new StatsViewModel(this), new StatsViewStyle(this));
  }

  /* RENDERING */
  render()
  {
    return (
      <View>
        {this.state.dashboard.map(({ }, i: number) =>
        {
          return (<div key={i} id={'dashboard' + i} />)
        })}
      </View>

    );
  }
}