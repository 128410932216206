 
import { ContentView } from '@levelapp/softfabric';
import ConfirmPopUpViewState from './ConfirmPopUpViewState';
import ConfirmPopUpViewProps from './ConfirmPopUpViewProps';
import ConfirmPopUpViewStyle from './ConfirmPopUpViewStyle';
import ConfirmPopUpViewModel from './ConfirmPopUpViewModel';
import { TranslationView } from '@levelapp/softfabric-ui';
import { View } from 'react-native';
import Constants from '../../Constants/Constants';
import ButtonView from '../Button/ButtonView';

export default class ConfirmPopUpView extends ContentView<ConfirmPopUpViewProps, ConfirmPopUpViewState, ConfirmPopUpViewModel, ConfirmPopUpViewStyle>
{
  /* CONSTRUCTOR */
  constructor(props: ConfirmPopUpViewProps) {
    super(props);

    // Binding
    this.bind(new ConfirmPopUpViewModel(this), new ConfirmPopUpViewStyle(this));
  }

  /* RENDERING */
  render() {
    return (
      <View style={{ marginHorizontal: 50, minWidth: 600 }}>

        {this.props.title !== undefined &&
          <TranslationView style={this.style.title}>
            {this.props.title}
          </TranslationView>
        }
        {this.props.title === undefined &&
          <TranslationView style={this.style.title}>
            generic.confirmation.delete
          </TranslationView>
        }


        <View style={[this.style.footer]}>
          <ButtonView isLoading={false} handleClick={this.binding.handleBackPressed} backgroundColor="#CCCCCC" style={[this.style.NoButton,this.props.noButtonStyle]}>
            <TranslationView upperCase style={this.style.NoButtonText}>
              generic.no
          </TranslationView>
          </ButtonView>
          <ButtonView isLoading={false} handleClick={this.binding.handleSavePressed} backgroundColor={Constants.MainColor} style={[this.style.YesButton,this.props.yesButtonStyle]} >
            <TranslationView upperCase>
              generic.yes
          </TranslationView>
          </ButtonView>
        </View>
      </View>
    );
  }
}