import { ContentViewModel, ContentView } from '@levelapp/softfabric';

import ManageProductsMenuViewState from './ManageProductsMenuViewState';
import ManageProductsMenuViewProps from './ManageProductsMenuViewProps';

export default class ManageProductsMenuViewModel extends ContentViewModel<ManageProductsMenuViewState, ManageProductsMenuViewProps>
{
  /* CONSTRUCTOR */
  constructor(view: ContentView) {
    super(view);

    this.initialState({ selectedTab: 'availability' });

    // Bindings
    this.onTabClick = this.onTabClick.bind(this);
  }

  componentDidUpdate(): void {
    if (this.state().selectedTab === 'log' && this.props().selectedItem?.isParent) {
      this.setField({ selectedTab: 'availability' });
    }
  }

  onTabClick(id: string) {
    this.setField({ selectedTab: id });
  }
}