import { ContentViewModel, ContentView } from '@levelapp/softfabric';
import BoosterPopUpViewState from './BoosterPopUpViewState';
import BoosterPopUpViewProps from './BoosterPopUpViewProps';
import Helpers from '../../../../../../../Common/Helpers/Helpers';
import BoosterType from '../../../../../../../Common/DTO/BoosterType';
import LTO from '../../../../../../../Common/DTO/LTO';
import LTOProductOperations from '../../../../../../../Transfer/LTOProductOperations';
import TranslationValue from '../../../../../../../Common/DTO/TranslationValue';
import DispatcherManager from '../../../../../../../Common/Managers/DispatcherManager';
import TranslationHelper from '../../../../../../../Common/Helpers/TranslationHelper';
import ImageTranslation from '../../../../../../../Common/DTO/ImageTranslation';
import DateHelper from '../../../../../../../Common/Helpers/DateHelper';
import NumberHelper from '../../../../../../../Common/Helpers/NumberHelper';
import ErrorHelper from '../../../../../../../Common/Helpers/ErrorHelper';
import ToastHelper from '../../../../../../../Common/Helpers/ToastHelper';
import BoostersOperations from '../../../../../../../Transfer/BoostersOperations';
import ContentTypeKey from '../../../../../../../Common/DTO/ContentTypeKey';

export default class BoosterPopUpViewModel extends ContentViewModel<BoosterPopUpViewState, BoosterPopUpViewProps>
{
    LTOProductOperations: LTOProductOperations;
    moment: any;
    _dispatcherManager : DispatcherManager
    _boostersOperation : BoostersOperations;

    constructor(props: ContentView) {
        super(props);

        this.LTOProductOperations = new LTOProductOperations();
        this.moment = require('moment');

        /* Initial State */
        this.initialState({
            booster: this.props().booster,
            languages: this.props().languages,
            showErrors: false,
            index: 0,
            indexThumbnail: 0,
            useDefaultImageChecked: Helpers.hasDuplicates(this.props().booster.imageTranslation),
            useDefaultThumbnailChecked: Helpers.hasDuplicates(this.props().booster.thumbnailTranslation),
            tab: "main",
            isLoading: false,
            boosterTypes: this.props().boosterTypes,
            messageError: []
        });

        this._boostersOperation = new BoostersOperations();

        /* BINDINGS */
        this.addLTO = this.addLTO.bind(this);
        this.getPos = this.getPos.bind(this);
        this.changeTab = this.changeTab.bind(this);
        this.deleteLTO = this.deleteLTO.bind(this);
        this.handleImage = this.handleImage.bind(this);
        this.setIndexImage = this.setIndexImage.bind(this);
        this.handleThumbnail = this.handleThumbnail.bind(this);
        this.handleSavePressed = this.handleSavePressed.bind(this);
        this.setIndexThumbnail = this.setIndexThumbnail.bind(this);
        this.handleTitleChanged = this.handleTitleChanged.bind(this);  
        this.handlePOSIDChanged = this.handlePOSIDChanged.bind(this);
        this.handleEndDateChanged = this.handleEndDateChanged.bind(this); 
        this.handleTitleLTOChanged = this.handleTitleLTOChanged.bind(this);    
        this.handleStartDateChanged = this.handleStartDateChanged.bind(this);
        this.handleDescriptionChanged = this.handleDescriptionChanged.bind(this);
        this.handleBoosterTypeChanged = this.handleBoosterTypeChanged.bind(this);
        this.handlePointsToEarnChanged = this.handlePointsToEarnChanged.bind(this);
        this.handleDefaultCheckBoxChanged = this.handleDefaultCheckBoxChanged.bind(this);
        this.handleDefaultCheckBoxThumbnailChanged = this.handleDefaultCheckBoxThumbnailChanged.bind(this);
        this.handlePreviewChanged = this.handlePreviewChanged.bind(this);
        this.errorManagement = this.errorManagement.bind(this);

        this.handleContentTypeKeyValueChange = this.handleContentTypeKeyValueChange.bind(this);
        
        this._dispatcherManager = new DispatcherManager();
    }

    /* COMPONENT EVENTS */
    componentWillMount() { }

    componentDidMount() {

        this._dispatcherManager.checkboxPreview = this.handlePreviewChanged;
        this._dispatcherManager.checkboxDefaultImage = this.handleDefaultCheckBoxChanged;
        this._dispatcherManager.checkboxDefaultThumbnail = this.handleDefaultCheckBoxThumbnailChanged;

        this._dispatcherManager.valuePointsToEarn = this.handlePointsToEarnChanged;
        this._dispatcherManager.valuePosId = this.handlePOSIDChanged;

        this._dispatcherManager.dateEndDate = this.handleEndDateChanged;
        this._dispatcherManager.dateStartDate = this.handleStartDateChanged;

        this._dispatcherManager.translationTitle = this.handleTitleChanged;
        this._dispatcherManager.translationDescription = this.handleDescriptionChanged;

        this._dispatcherManager.numberIndexImage = this.setIndexImage;
        this._dispatcherManager.numberIndexThumbnail = this.setIndexThumbnail;

        this._dispatcherManager.uriImage = this.handleImage;
        this._dispatcherManager.uriThumbnail = this.handleThumbnail;

        this._dispatcherManager.contentTypeKeyValue = this.handleContentTypeKeyValueChange;

        if (this.state().booster.id !== undefined) {
            for (let i = 0; i < this.state().booster.ltos.length; i++) {
                this.LTOProductOperations.get(this.state().booster.ltos[i].id, (products: any) => {
                    let posTmp: any[] = [];
                    products.data.forEach((product: any) => {
                        let productId = product.productId;
                        let posId = product.product.posId;
                        let search = product.product.posId + " " + product.product.title.translationValues[0].value;
                        posTmp.push({ productId: productId, posId: posId, search: search });
                    })
                    this.setField((previousState) => previousState.booster.ltos[i].posIds = posTmp);
                });
            }
        }
    }


    handleSavePressed() {
        var state = this.state();
        this.setField({messageError: []});
        if (
            this.state().booster.pointsToEarn === null || this.state().booster.pointsToEarn < 0 ||
            this.state().booster.titleTranslations.length !== this.state().languages.length ||
            this.state().booster.descriptionTranslations.length !== this.state().languages.length ||
            !Helpers.areImagesComplete(this.state().booster.imageTranslation) || 
            !Helpers.areImagesComplete(this.state().booster.thumbnailTranslation) ||
            !this.isLTOCorrect() 
        ) {
            state.messageError.push("PleaseFillAllFields");
            state.showErrors = true;
            this.setField({state});
        }
        else {
            this.setField((previousState) => previousState.isLoading = true);
            for (let i = 0; i < this.state().booster.ltos.length; i++) {
                state.booster.ltos[i].startDate  = DateHelper.changeDateToBeValidUTC(state.booster.ltos[i].startDate).toDate();
                state.booster.ltos[i].endDate = DateHelper.changeDateToBeValidUTC(state.booster.ltos[i].endDate).toDate();
            }
            this.setField({state},() => this.saveBooster(state.booster));

        }
    }

    saveBooster(booster: any) {

        for(var i=0; i<booster.ltos.length;i++)
        {
            var startResult = DateHelper.changeDateToBeValidUTC(booster.ltos[i].StartDateInput);      
            var endResult = DateHelper.changeDateToBeValidUTC(booster.ltos[i].EndDateInput);
            booster.ltos[i].startDate = startResult;
            booster.ltos[i].endDate = endResult;
        }

        for (var i = 0; i < this.props().languages.length; i++) {
            if (booster.imageTranslation[i].url.includes("data:image"))
                booster.imageTranslation[i].ImageData = booster.imageTranslation[i].url.replace("data:image/png;base64,", "").replace("data:image/jpeg;base64,", "");
            else
                booster.imageTranslation[i].ImageData = null;

            if (booster.thumbnailTranslation[i].url.includes("data:image"))
                booster.thumbnailTranslation[i].ImageData = booster.thumbnailTranslation[i].url.replace("data:image/png;base64,", "").replace("data:image/jpeg;base64,", "");
            else
                booster.thumbnailTranslation[i].ImageData = null;
        }
        if (booster.id !== undefined) {
            this._boostersOperation.put(booster, () => {
                this.props().refreshParent();
                this.showToast(ToastHelper.getToast("boosters.popup.success.updated","success"),() => {},2500);
                this.closePopUp();
            },this.errorManagement);
        }
        else {
            this._boostersOperation.post(booster, () => {
                this.props().refreshParent();
                this.showToast(ToastHelper.getToast("boosters.popup.success.added","success"),() => {},2500);
                this.closePopUp();
            },this.errorManagement);
        }
    }

    errorManagement(error : any) 
    {
        this.setField({isLoading : false});
        ErrorHelper.showMessageError(error.response);
    }

    getContentTypeKeys(): ContentTypeKey[]{
        return this.props().contentTypeKeys.filter((c : ContentTypeKey)=> c.boosterTypeId == this.props().booster.boosterType.id)
    }

    isLTOCorrect(){
        let ltoCorrect = true;
        //check que titres sont remplis, qu'il y ai au moins 1 pos id, et 1 start date et 1 end date et si LTO, au -1 lto
        if (this.state().booster.boosterType.name === "LimitedTimeOffer") {
            if (this.state().booster.ltos.length == 0 || this.state().booster.ltos == null) {
                this.setField((previousState)=>previousState.messageError.push("OneLTO"));
                ltoCorrect=false;
            }
            else {
                if (this.isNotSelectedPosIds()){
                    this.setField((previousState)=>previousState.messageError.push("OneItem"));
                    ltoCorrect = false;
                }
                if (this.isEmptyTitleLTO()){
                    this.setField((previousState)=>previousState.messageError.push("AllTitles"));
                    ltoCorrect=false;
                }
                if(!this.isDateCorrect()){
                    this.setField((previousState)=>previousState.messageError.push("DateNotCorrect"));
                    ltoCorrect = false;
                }
            }
        }
        return ltoCorrect;
    }

    isDateCorrect(){
        for (let i = 0; i < this.state().booster.ltos.length; i++) {
            if(this.state().booster.ltos[i].StartDateInput === null || this.state().booster.ltos[i].EndDateInput === null){
                return false;
            }
            else if(this.state().booster.ltos[i].StartDateInput >= this.state().booster.ltos[i].EndDateInput){
                return false;
            }
        }
        return true;
    }

    isEmptyTitleLTO() {
        for (let i = 0; i < this.state().booster.ltos.length; i++) {
            for (let j = 0; j < this.state().booster.ltos[i].titleTranslations.length; j++) {
                if (this.state().booster.ltos[i].titleTranslations[j].value === "" || this.state().booster.ltos[i].titleTranslations[j].value === null) {
                    return true;
                }
            }
            if (this.state().booster.ltos[i].titleTranslations.length !== this.state().languages.length) {
                return true;
            }
        }
        return false;
    }

    isNotSelectedPosIds() {
        for (let i = 0; i < this.state().booster.ltos.length; i++) {
            if (this.state().booster.ltos[i].posIds == null || this.state().booster.ltos[i].posIds.length === 0) {
                return true;
            }
        }
        return false;
    }

    getPos() {
        return this.props().posIds;
    }

    handleBoosterTypeChanged(value: BoosterType) {
        (value.id === 5 ? this.setField({ isLTO: true }) : this.setField({ isLTO: false }));
        this.setField((previousState) => previousState.booster.boosterType = value);
    }

    handleTitleLTOChanged(value: string, keyLanguage: string, ltoIndex: any) {
        var state = this.state();
        var translation = state.booster.ltos[ltoIndex].titleTranslations.find(x => x.language.key == keyLanguage);
        if (translation != undefined) { 
            translation.value = value;
        }
        else {
            var language = state.languages.find(x => x.key == keyLanguage);
            if(language != undefined)
            {
                let trans: TranslationValue = {
                    id: 0,
                    value: value,
                    language: language
                };
                state.booster.ltos[ltoIndex].titleTranslations.push(trans);
            }  
        }
        this.setField({ state });

    }

    deleteLTO(index: any) {
        this.setField((previousState) => previousState.booster.ltos.splice(index, 1));
    }
    addLTO() {

        let item: LTO = {
            id: 0,
            titleId: 0,
            titleTranslations: [],
            startDate: new Date(),
            endDate: new Date(),
            StartDateInput : new Date(),
            EndDateInput : new Date(),
            boosterId: 0,
            posIds: []
        }
        this.setField((previousState) => previousState.booster.ltos.push(item));
    }


    /* OTHERS */

    fillAllPicturesWithDefault() {
        if (this.state().useDefaultImageChecked)
        {
            var list = Helpers.fillAllImagesWithDefault(this.state().booster.imageTranslation,this.state().languages);
            this.setField((previousState) => previousState.booster.imageTranslation = list as ImageTranslation[]);
        }
    }
    
    fillAllThumbnailsWithDefault() {
        if (this.state().useDefaultThumbnailChecked) 
        {
            var list = Helpers.fillAllImagesWithDefault(this.state().booster.thumbnailTranslation,this.state().languages);
            this.setField((previousState) => previousState.booster.thumbnailTranslation = list as ImageTranslation[]);
        }
    }

    changeTab(tab: any) {
        this.setField((previousState) => previousState.tab = tab);
    }

    /* CHECKBOXES */
    private handleDefaultCheckBoxChanged(value : boolean) {
        this.setField((previousState) => previousState.useDefaultImageChecked = value, () => {
            this.fillAllPicturesWithDefault();
        });
    }

    private handleDefaultCheckBoxThumbnailChanged(value : boolean) {
        this.setField((previousState) => previousState.useDefaultThumbnailChecked = value, () => {
            this.fillAllThumbnailsWithDefault();
        });
    }

    /* VALUES */

    private handlePointsToEarnChanged(text: string) {
        
        this.setField((previousState) => previousState.booster.pointsToEarn = NumberHelper.convertStringToInt(text));
    }

    private handlePOSIDChanged(value: any, index: any) {
        this.setField((previousState) => previousState.booster.ltos[index].posIds = value);
    }


    /* TRANSLATIONS */

    private handleTitleChanged(text: any, index: any) {
        var state = this.state();
        TranslationHelper.translation(state.booster.titleTranslations,state.languages,index,text);
        this.setField({state});
    }

    private handleDescriptionChanged(text: any, index: any) {
        var state = this.state();
        TranslationHelper.translation(state.booster.descriptionTranslations,state.languages,index,text);
        this.setField({state});
    }

    /* DATES */ 

    private handleStartDateChanged(date: Date, index: any) {
        this.setField((previousState) => previousState.booster.ltos[index].StartDateInput = date );
    }

    private handleEndDateChanged(date: Date, index: any) {
        this.setField((previousState) => previousState.booster.ltos[index].EndDateInput = date);
    }

    /* INDEX IMAGE */

    private setIndexImage(index: any) {
        this.setField((previousState) => previousState.index = index);
    }

    private setIndexThumbnail(index: any) {
        this.setField((previousState) => previousState.indexThumbnail = index);
    }

    /* URI IMAGE */

    private handleImage(base64 : any) {
        var state = this.state();
        TranslationHelper.image(state.booster.imageTranslation,state.languages,state.index,base64);
        this.setField({state});
        this.fillAllPicturesWithDefault();
    }

    private handleThumbnail(base64 : any) {
        var state = this.state();
        TranslationHelper.image(state.booster.thumbnailTranslation,state.languages,state.indexThumbnail,base64);
        this.setField({state});
        this.fillAllThumbnailsWithDefault();
    }

    private handlePreviewChanged(value : boolean) {
        this.setField((previousState) => previousState.booster.isPreview = value);
    }

    /* Content Type */

    private handleContentTypeKeyValueChange(value : any)
    {
        this.setField((previousState) => previousState.booster.contentTypeKeyValue = value);
    }

} 
