import {ContentViewModel,ContentView} from '@levelapp/softfabric';
import PermissionsManagementViewState from './PermissionsManagementViewState';
import PermissionsManagementViewProps from './PermissionsManagementViewProps';
import PermissionOperation from '../../../../../../../../../Transfer/Identity/PermissionOperations';
import Permission from '../../../../../../../../../Common/DTO/Permission';
import ToastHelper from '../../../../../../../../../Common/Helpers/ToastHelper';

export default class PermissionsManagementViewModel extends ContentViewModel<PermissionsManagementViewState, PermissionsManagementViewProps>
{
     _permissionOperation : PermissionOperation;
    _permissionToDelete : Permission | undefined ;
      /* CONSTRUCTOR */
    constructor(view: ContentView)
    {
        super(view);

        this.initialState({
            showPopup : false,
            isCreating : false,
            confirmationPopup : false,
            permissions : [],
            permission : undefined,
            titlePopup : "",
            contentPopup : "",
        }); 

        // Bindings
        this.refresh = this.refresh.bind(this);
        // this.deletePermission = this.deletePermission.bind(this);
        this.handleCreatePermission = this.handleCreatePermission.bind(this);
        this.handleSuccessDeleted = this.handleSuccessDeleted.bind(this);
        this.handleCloseManagement = this.handleCloseManagement.bind(this);
        this.handleSuccessPermissions = this.handleSuccessPermissions.bind(this);
        this.handleBackPopupPressed = this.handleBackPopupPressed.bind(this);
        this.handlePermissionNameChanged = this.handlePermissionNameChanged.bind(this);

        this._permissionOperation =  new PermissionOperation();
    }

    componentDidMount()
    {
        this.refresh();
    }

    refresh()
    {
        this._permissionOperation.getAll(this.handleSuccessPermissions);
    }

    handleSuccessPermissions(data : Permission[])
    {
        var state = this.state();
        state.permissions = data;
        state.permission = this.initialPermission();
        this.setField({state});
    }

    initialPermission() 
    { 
        let permission : Permission = {
            "id" : -1,
            "key" : "",
            "permissionsUsers" :[] 
        };
       
        return permission;
    }

    deletePermission()
    {
        this._permissionOperation.delete((this._permissionToDelete as Permission).id, this.handleSuccessDeleted , this.handleFailureDeleted);
    }

    handleSuccessDeleted()
    {
        this.showToast(ToastHelper.getToast("permission.popup.success.deleted","success"),() => {},2500);
        this.setField({contentPopup : '' , confirmationPopup : false , titlePopup : '' , showPopup : false });
        this.refresh()
    }

    handleFailureDeleted()
    {
        this.showToast(ToastHelper.getToast("permission.popup.failure.deleted","success"),() => {},2500);
        this.setField({contentPopup : '' , confirmationPopup : false , titlePopup : '' , showPopup : false });
        this.refresh()
    }

    handleDeletePermission(permission: Permission) {
        this._permissionToDelete = permission;
        this.setField({contentPopup : "" , confirmationPopup : true , titlePopup : 'generic.confirmation.delete' , showPopup : true});
 
    }

    handleBackPopupPressed() {
        this.setField({showPopup : false});
    }

    handleCloseManagement()
    {
        this.props().handleBackPressed();
    }



    handlePermissionNameChanged(value : string)
    {
        try
        {
            this.setField((previousState) => (previousState.permission as Permission).key = value);
        }catch(error)
        {
            alert('error');
        }

    }

    handleCreatePermission() {
        if (this.state().permission != null && (this.state().permission as Permission).key != "") {
            this.setField({isCreating : true});
            this._permissionOperation.post((this.state().permission as Permission), () => {
                this.setField({isCreating : false});
                this.showToast(ToastHelper.getToast("permission.popup.success.added","success"),() => {},2500);
                this.refresh();
            },() =>
            {
                this.setField({isCreating : false});
                this.showToast(ToastHelper.getToast("permission.popup.error.added","error"),() => {},2500);

            })
        } else {
            this.showToast(ToastHelper.getToast("permission.popup.warning.added","warning"),() => {},2500);

        }
    }


    
    
}