import { ContentViewModel, ContentView, Application, LanguageManager, Managers } from '@levelapp/softfabric';
import FaqViewState from './FaqViewState';
import FaqViewProps from './FaqViewProps';
import { TableRow } from '@levelapp/softfabric-ui';
 
import LanguagesOperations from '../../../../../../Transfer/LanguagesOperations';
import FaqOperations from '../../../../../../Transfer/FaqOperations';
import Helpers from '../../../../../../Common/Helpers/Helpers';
import ConfirmPopUpView from '../../../../../../Common/Components/confirmpopup/ConfirmPopUpView';
import FaqpopupView from '../popup/faqpopup/FaqpopupView';
import ToastHelper from '../../../../../../Common/Helpers/ToastHelper';
import FAQ from '../../../../../../Common/DTO/FAQ';
import TranslationValue from '../../../../../../Common/DTO/TranslationValue';
import Language from '../../../../../../Common/DTO/Language';
import ErrorHelper from '../../../../../../Common/Helpers/ErrorHelper';

export default class FaqViewModel extends ContentViewModel<FaqViewState, FaqViewProps>{

    faqOperation : FaqOperations;
    languagesOperation: LanguagesOperations;
    _languages: Language[] = [];
    interval: any;
    searchText: string;
    isSorting: boolean;
    indexHeaderSort: number;
    sortOrder: string;
    sortKey: string[];

    constructor(props: ContentView) {
        super(props);

        this.interval = null;
        this.searchText = '';
        this.isSorting = false;
        this.indexHeaderSort = -1;
        this.sortOrder = 'asc';
        this.sortKey = ['Id', 'Title' , 'OrderIndex'];

        this.faqOperation = new FaqOperations();
        this.languagesOperation = new LanguagesOperations();

        this.languagesOperation.get((languages: any) => {
            this._languages = languages.data;
        });

        /* Initial State */
        this.initialState({ tableData: [], tableHeaders: ['Id', 'Question' , 'OrderIndex'], isLoading: true, nbPage: 0, currentPage: 1, isResearching: false });

        /* BINDING */
        this.refresh = this.refresh.bind(this);
        this.addQuestion = this.addQuestion.bind(this);
        this.errorManagement = this.errorManagement.bind(this);
    }

    componentDidMount() {
        this.refresh()
    }

    refresh() {
        this.setField({ isLoading: true });
        let parameters = [];
        parameters.push({ key: "page", value: this.state().currentPage }, { key: "itemPage", value: 20 });
        if (this.isSorting) {
            parameters.push(
                { key: 'columnSort', value: this.sortKey[this.indexHeaderSort] },
                { key: 'sortOrder', value: this.sortOrder },
                { key: 'userLanguage', value: Application.current.resolve<LanguageManager>(Managers.LANGUAGE_MANAGER).language });
        }
        if (this.state().isResearching) {
            parameters.push({ key: "search", value: this.searchText });
        }
        this.getData(parameters);
    }

    getData(queryParameters : any[]) {
        this.faqOperation.get(queryParameters, (faq: any) => {
            let tableData: TableRow[] = [];
            faq.data.objects.forEach((question: FAQ) => {
                let result = question.titleTranslations.find((translation : TranslationValue) => { return translation.language.key == Application.current.resolve<LanguageManager>(Managers.LANGUAGE_MANAGER).language});
                var title = ''
                if(result)
                {
                    title = result.value
                }
                tableData.push({ data: [question.id, title, question.orderIndex], isDeletable: true, isEditable: true, editCallback: () => { this.editField(question) }, deleteCallback: () => { this.deleteField(question) } });
            });
            this.setField({ tableData: tableData, isLoading: false , nbPage: faq.data.totalPage});
        },this.errorManagement);
    }

    editField(question: any) {
        if (question.linkTranslations == null) {
            question.linkTranslations = [];
        }
        this.showPopUp(
            <FaqpopupView question={Helpers.clone(question)} languages={this._languages} refreshParent={this.refresh}/>
        );
    }

    deleteField(question : any) {
        this.showPopUp(
            <ConfirmPopUpView callBack={() => {
                this.faqOperation.delete(question, () => {
                    this.refresh();
                    this.showToast(ToastHelper.getToast("faq.popup.success.deleted","success"),() => {},2500);

                },this.errorManagement);
            }} />
        );
    }

    addQuestion() {

        this.showPopUp(<FaqpopupView refreshParent={this.refresh} languages={this._languages} 
        question={
            {
                titleTranslations: Helpers.generateTranslationTable(this._languages), 
                descriptionTranslations: Helpers.generateTranslationTable(this._languages), 
                linkTranslations: []}}> </FaqpopupView>)
    }

    

    onPageChange(page: number) {
        this.state().currentPage = page + 1;
        this.refresh()
    }

    onSortChange(indexHeaderSort: number, sortOrder: string) {
        if (indexHeaderSort == -1) {
            this.isSorting = false;
        } else {
            this.indexHeaderSort = indexHeaderSort;
            this.sortOrder = sortOrder;
            this.isSorting = true;
        }
        this.refresh();
    }

    handleResearching(searchText: string) {
        this.setField({ isLoading: true, isResearching: true });
        this.searchText = searchText;

        if (this.interval) {
            clearInterval(this.interval);
        }
        this.interval = setTimeout(() => {
            this.onPageChange(0)
        }, 1000)
    }

    handleCloseResearching() {
        this.state().isResearching = false;
        this.onPageChange(0);
    }

    errorManagement(error : any) 
    {
        this.setField({isLoading : false});
        ErrorHelper.showMessageError(error.response);
    }
}