import { Service, Application, HttpManager, Managers } from '@levelapp/softfabric';


export default class CouponsService extends Service {
    httpClient = Application.current.resolve<HttpManager>(Managers.HTTP_MANAGER);

    async get(parameters: any[], onSuccess: Function, onFailure: Function = () => { }) {
        this.httpClient.handleSomethingWentWrong = onFailure;
        this.httpClient.handleNotFound = onFailure;

        this.httpClient
            .get('coupons', parameters)
            .then((response: any) => {
                if (response) {
                    if (response.data) {
                        onSuccess(response.data);
                    }
                }
            });
    }

    post(coupon: any, callback: Function = () => { }, onFailure: Function = () => { }) {
        this.httpClient.handleSomethingWentWrong = onFailure;
        this.httpClient.handleNotFound = onFailure;

        this.httpClient
            .post('coupons', [], coupon)
            .then((response: any) => {
                if (response) {
                    /*if (response.data)
                    {
                        callback(response.data);
                    }*/
                    callback("");
                }
            });
    }

    put(coupon: any, callback: Function = () => { }, onFailure: Function = () => { }) {
        this.httpClient.handleSomethingWentWrong = onFailure;
        this.httpClient.handleNotFound = onFailure;

        this.httpClient
            .put('coupons', [], coupon)
            .then((response: any) => {
                if (response) {
                    /*if (response.data)
                    {
                        callback(response.data);
                    }*/
                    callback("");
                }
            });
    }

    delete(coupon: any, callback: Function = () => { }, onFailure: Function = () => { }) {
        this.httpClient.handleSomethingWentWrong = onFailure;
        this.httpClient.handleNotFound = onFailure;

        this.httpClient
            .delete('coupons/delete/' + coupon.id)
            .then((response: any) => {
                if (response) {
                    /*if (response.data)
                   {
                       callback(response.data);
                   }*/
                    callback("");
                }
            });
    }

    activateById(couponId: any, body: any, callback: Function = () => { }, onFailure: Function = () => { }) {
        this.httpClient.handleSomethingWentWrong = onFailure;
        this.httpClient.handleNotFound = onFailure;

        this.httpClient
            .post('couponConsumption/ActivateByIdForUsers/' + couponId, [], body)
            .then((response: any) => {
                if (response) {
                    if (response.data) {
                        callback(response.data);
                    }
                }
            });
    }

    getByCouponId(couponId: any, callback: Function = () => { }, onFailure: Function = () => { }) {
        this.httpClient.handleSomethingWentWrong = onFailure;
        this.httpClient.handleNotFound = onFailure;

        this.httpClient
            .get('couponConsumption/GetUsersByCouponId/' + couponId, [])
            .then((response: any) => {
                if (response) {
                    if (response.data) {
                        callback(response.data);
                    }
                }
            });
    }
}