import {ContentViewModel,ContentView} from '@levelapp/softfabric';
import LtoViewState from './LtoViewState';
import LtoViewProps from './LtoViewProps';

export default class LtoViewModel extends ContentViewModel<LtoViewState, LtoViewProps>
{
      /* CONSTRUCTOR */
    constructor(view: ContentView)
    {
        super(view);

        this.initialState(
            {
                zIndexSelect : -3
            }); // Initialize your state here

        // Bindings
        this.onDropDownOpen = this.onDropDownOpen.bind(this);
        this.onDropDownClose = this.onDropDownClose.bind(this);
    }

    onDropDownOpen()
    {
        this.setField({zIndexSelect : 3});
    }

    onDropDownClose()
    {
        this.setField({zIndexSelect : -3});
    }
}