import {ContentViewModel,ContentView} from '@levelapp/softfabric';
import HistoricTabNavigationViewState from './HistoricSectionNavigationViewState';
import HistoricTabNavigationViewProps from './HistoricSectionNavigationViewProps';

export default class HistoricTabNavigationViewModel extends ContentViewModel<HistoricTabNavigationViewState, HistoricTabNavigationViewProps>
{
      /* CONSTRUCTOR */
    constructor(view: ContentView)
    {
        super(view);

        this.initialState({}); // Initialize your state here

        // Bindings

    }
}