import { ContentViewModel, ContentView } from "@levelapp/softfabric";
import ProductAdditionalInfosViewState from './ProductAdditionalInfosViewState';
import ProductAdditionalInfosViewProps from './ProductAdditionalInfosViewProps';
import AdditionalInfosOperations from "src/Transfer/AdditionalInfosOperations";
import PaginationHelper from "src/Common/Helpers/PaginationHelper";
import FlyxResponse from "src/Common/DTO/FlyxResponse";
import { TableRow } from "@levelapp/softfabric-ui";
import Helpers from "src/Common/Helpers/Helpers";
import Language from "src/Common/DTO/Language";
import LanguagesOperations from "src/Transfer/LanguagesOperations";
import ProductAdditionalInfosPopUpView from "../../popup/ProductAdditionalInfosPopUpView";
import AdditionalInfo from "src/Common/DTO/AdditionalInfo";
import ConfirmPopUpView from "src/Common/Components/confirmpopup/ConfirmPopUpView";
//remplacer => src
export default class ProductAdditionalInfosViewModel extends ContentViewModel<ProductAdditionalInfosViewState, ProductAdditionalInfosViewProps>
{
  operation: AdditionalInfosOperations;
  languagesOperation: LanguagesOperations;
  indexHeaderSort: number;
  sortOrder: string;
  sortKey: string[];
  searchText: string;
  isSorting: boolean;
  _languages: Language[] = [];

  constructor(props: ContentView) {
    super(props);

    this.operation = new AdditionalInfosOperations();
    this.languagesOperation = new LanguagesOperations();
    this.indexHeaderSort = -1;
    this.sortOrder = 'asc';
    this.sortKey = ['Title', 'Image', 'OrderIndex'];
    this.searchText = '';
    this.isSorting = false;

    this.initialState({ tableData: [], tableHeaders: ["Name", "Description"], isLoading: true, nbPage: 0, currentPage: 1, isResearching: false });

    this.languagesOperation.get((languages: any) => {
      this._languages = languages.data;
    });
   

    /* BINDINGS */
    this.refresh = this.refresh.bind(this);
    this.handleSuccess = this.handleSuccess.bind(this);
    this.onPageChange = this.onPageChange.bind(this);
    this.onSortChange = this.onSortChange.bind(this);
    this.editField = this.editField.bind(this);
    this.addField = this.addField.bind(this);
    this.deleteById = this.deleteById.bind(this);
  }

  componentDidMount() {
    this.refresh();
  }

  refresh() {
    this.setField({ isLoading: true });

    let parameters = [];
    parameters.push(PaginationHelper.pushPage(this.state().currentPage));
    parameters.push(PaginationHelper.pushItemPage(20));

    if (this.isSorting) {
      parameters.push(PaginationHelper.pushColumnSort(this.sortKey[this.indexHeaderSort]));
      parameters.push(PaginationHelper.pushSortOrder(this.sortOrder))
    }
    if (this.state().isResearching) {
      parameters.push(PaginationHelper.pushSearch(this.searchText))
      this.operation.get(parameters, (flyx: FlyxResponse) => this.handleSuccess(flyx))
    }
    else {
      this.operation.get(parameters, (flyx: FlyxResponse) => this.handleSuccess(flyx, ["Name", "Description", "Image"]))
    }
  }

  handleSuccess(flyx: FlyxResponse, customHeader: string[] | undefined = undefined) {
    let tableData: TableRow[] = [];
    if (flyx.objects != null) {
      flyx.objects.forEach((info:any) => {
        
        tableData.push({
          data: [
            info?.name?.translationValues[2]?.value,
            info?.description?.translationValues[2]?.value,
            info.imageTranslation[0].url
          ],
          isEditable: true,
          editCallback: () => {
            this.editField(info);
          },
          isDeletable: true,
          deleteCallback: ()=>{
            this.deleteById(info.id);
          }
        });
      });
    }

    this.setField({
      tableHeaders: customHeader != undefined ? customHeader : this.state().tableHeaders,
      tableData: tableData,
      users: flyx,
      nbPage: flyx.totalPage,
      isLoading: false
    });
  }

  onPageChange(page: number) {
    this.state().currentPage = page + 1;
    this.refresh()
  }

  onSortChange(indexHeaderSort: number, sortOrder: string) {
    if (indexHeaderSort == -1) {
      this.isSorting = false;
    } else {
      this.indexHeaderSort = indexHeaderSort;
      this.sortOrder = sortOrder;
      this.isSorting = true;
    }
    this.refresh();
  }

  addField() {
    this.showPopUp(
      <ProductAdditionalInfosPopUpView
        productcategory={{
          titleTranslations: Helpers.generateTranslationTable(this._languages),
          descriptionTranslations: Helpers.generateTranslationTable(this._languages),
          appProductCategoryExterns: [],
          imageTranslation: Helpers.generateUrlTable(this._languages),
          thumbnailTranslation: Helpers.generateUrlTable(this._languages),

        }
        }
        languages={this._languages}
        refreshParent={this.refresh} />
    );
  }

  editField(info: AdditionalInfo) {
    info.imageTranslation = Helpers.generateUrlTableEdit(info.imageTranslation, this._languages);

    let productcategoryCopy = Helpers.clone(info);
    this.showPopUp(
      <ProductAdditionalInfosPopUpView productcategory={productcategoryCopy} refreshParent={this.refresh} languages={this._languages} />
    );
  }
  deleteById(id : Number){
    if(id!==undefined){
      this.showPopUp(
            <ConfirmPopUpView callBack={() => {
                this.operation.deleteAdditionalInfoValuesById(id).then(() => {
                  this.refresh();
                });
                this.setField({ isLoading: true });
                
            }} />
        );
    }
    
  }
}
