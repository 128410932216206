 
import {ContentView} from '@levelapp/softfabric';
import AdvancedInformationSectionViewState from './AdvancedInformationSectionViewState';
import AdvancedInformationSectionViewProps from './AdvancedInformationSectionViewProps';
import AdvancedInformationSectionViewStyle from './AdvancedInformationSectionViewStyle';
import AdvancedInformationSectionViewModel from './AdvancedInformationSectionViewModel';
import { View } from 'react-native';
import SectionTitleView from '../../../../../../../../../../../Presentation/modules/dashboard/components/sectiontitle/SectionTitleView';
import OptinsListView from '../../../../../../../../../../../Presentation/modules/dashboard/modules/users/pages/userspopup/components/maininformation/components/advancedinformationsection/components/optinslist/OptinsListView';
import { LottieView } from '@levelapp/softfabric-ui';
import Constants from '../../../../../../../../../../../Common/Constants/Constants';
import AdditionalInformationsListView from './components/additionalinformationslist/AdditionalInformationsListView';
import MembershipListView from './components/membershiplist/MembershipListView';

export default class AdvancedInformationSectionView extends ContentView<AdvancedInformationSectionViewProps, AdvancedInformationSectionViewState, AdvancedInformationSectionViewModel, AdvancedInformationSectionViewStyle>
{
  /* CONSTRUCTOR */
  constructor(props:AdvancedInformationSectionViewProps)
  {
    super(props);

    // Binding
    this.bind(new AdvancedInformationSectionViewModel(this), new AdvancedInformationSectionViewStyle(this));
  }

  /* RENDERING */
  render()
  {

    return (
      <View style={{ flexDirection: "column", paddingLeft : "5%"}}>
        {this.props.isLoadingAdvancedInformations === false ? (
        <><SectionTitleView title={'form.advancedinformations'} />
        {Constants.ShowUserOptins &&
        <OptinsListView user={this.props.user} title={'form.optins'} value={this.props.user.optins}></OptinsListView>
        }
        {Constants.ShowUserMemberships &&
        <MembershipListView user={this.props.user} title={'form.membership'} value={this.props.user.memberships}></MembershipListView>
        }
        {Constants.ShowUserAdvancedProfile &&
        <AdditionalInformationsListView user={this.props.user} style={{paddingTop: 15}} title={'form.additionalinformations'} value={this.props.user.userMetadataValues}></AdditionalInformationsListView>
        }</>
      ) :
      (
        <LottieView height={150} width={150} source={require('../../../../../../../../../../../Common' + Constants.LottieAnimationPath)} />
      )}
      </View>
    );
  }
}