import {ContentViewModel,ContentView} from '@levelapp/softfabric';
import MaiInformationViewState from './MaiInformationViewState';
import MaiInformationViewProps from './MaiInformationViewProps';

export default class MaiInformationViewModel extends ContentViewModel<MaiInformationViewState, MaiInformationViewProps>
{
      /* CONSTRUCTOR */
    constructor(view: ContentView)
    {
        super(view);

        this.initialState({}); // Initialize your state here

        // Bindings

    }
}