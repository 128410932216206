import StoreGroupOperations from 'src/Transfer/StoreGroupOperations';
import { OrderSourceOperations } from 'src/Transfer/OrderSourceOperations';
import StoreGroup from 'src/Common/DTO/StoreGroup';
import OrderSource from 'src/Common/DTO/OrderSource';
import ProductOrderSourceAvailability from 'src/Common/DTO/ProductOrderSourceAvailability';
import ProductOrderSourceAvailabilityOperations from 'src/Transfer/ProductOrderSourceAvailabilityOperations';
import MappedTabAvailability from 'src/Common/DTO/MappedTabAvailability';
import MappedOrderSourceAvailability from 'src/Common/DTO/MappedOrderSourceAvailability';
import ToastHelper from 'src/Common/Helpers/ToastHelper';

import { ContentViewModel, ContentView } from '@levelapp/softfabric';

import AvailabilityTabViewState from './AvailabilityTabViewState';
import AvailabilityTabViewProps from './AvailabilityTabViewProps';

export default class AvailabilityTabViewModel extends ContentViewModel<AvailabilityTabViewState, AvailabilityTabViewProps>
{
  orderSourceOperations: OrderSourceOperations;
  storeGroupOperations: StoreGroupOperations;
  productOrderSourceAvailability: ProductOrderSourceAvailabilityOperations;

  /* CONSTRUCTOR */
  constructor(view: ContentView) {
    super(view);

    this.initialState({
      orderSources: [],
      mappedAvailabilities: [],
      currentSelectedProduct: this.props().selectedItem,
      isDirty: false,
      isLoading: true,
      buttonIsLoading: false,
      storeGroups: [],
      selectedAvailabilities: [],
    });

    this.orderSourceOperations = new OrderSourceOperations();
    this.storeGroupOperations = new StoreGroupOperations();
    this.productOrderSourceAvailability = new ProductOrderSourceAvailabilityOperations;

    // Bindings
    this.getData = this.getData.bind(this);
    this.refresh = this.refresh.bind(this);
    this.isSelected = this.isSelected.bind(this);
    this.updateAvailabilities = this.updateAvailabilities.bind(this);
    this.deleteAvailabilities = this.deleteAvailabilities.bind(this);
    this.createNewAvailabilities = this.createNewAvailabilities.bind(this);
  }

  componentDidUpdate() {
    if (this.state().currentSelectedProduct?.id !== this.props().selectedItem?.id) {
      this.refresh();
      this.setField({ isDirty: false });
      this.setField({ currentSelectedProduct: this.props().selectedItem });
    }
  }

  componentDidMount() {
    this.refresh();
  }

  refresh() {
    this.getData();
  }

  async getData() {
    try {
      this.setField({ isLoading: true });

      this.orderSourceOperations.get((orderSources: OrderSource[]) => {
        this.setField({ orderSources });
      }, "?isForAvailabilityTool=true");

      const storeGroups = await this.storeGroupOperations.get('?flat=true');
      this.setField({ storeGroups });

      const availabilities = await this.productOrderSourceAvailability.get(this.props().selectedItem?.id || 0);
      this.setField({ selectedAvailabilities: availabilities });

      this.mapper(storeGroups, availabilities);
    } catch {
      this.showToast(ToastHelper.getToast("tools.generic.get.error", "error"), () => { }, 2500);
    } finally {
      this.setField({ isLoading: false });
    }
  }

  mapper(storeGroups: StoreGroup[], availabilities: ProductOrderSourceAvailability[]) {
    const mappedAvailabilities = storeGroups.map((sg) => ({
      id: sg.id,
      name: sg.name,
      childIds: sg.allChildren,
      sources: this.state().orderSources.map((os) => ({
        id: os.id,
        name: os.label,
        availability: availabilities.find(
          (availability) => availability.storeGroupId === sg.id
            && availability.orderSourceId === os.id
            && availability.productId === this.state().currentSelectedProduct?.id),
      })),
    }));

    this.setField({ mappedAvailabilities });
  }

  isSelected(source: MappedOrderSourceAvailability): boolean {
    const { selectedAvailabilities } = this.state();
    const { availability } = source;

    const selectedAvailability = selectedAvailabilities.find(
      (avail) => avail.storeId === availability?.storeId
        && avail.orderSourceId === availability.orderSourceId
        && avail.productId === availability.productId);

    if (!selectedAvailability) {
      return true;
    }

    if (selectedAvailability.changeType === 'added') {
      return false;
    }

    if (selectedAvailability.changeType === 'deleted') {
      return true;
    }

    if (selectedAvailability) {
      return false;
    }

    return true;
  }

  onPress(orderSource: MappedOrderSourceAvailability, m: MappedTabAvailability) {
    this.setField({ isDirty: true });

    let { availability } = orderSource;
    const { selectedAvailabilities } = this.state();

    const selectedAvailability = selectedAvailabilities.find((avail) => avail.storeId === availability?.storeId
      && avail.orderSourceId === availability.orderSourceId
      && avail.productId === this.state().currentSelectedProduct?.id);

    let availabilities: ProductOrderSourceAvailability[] = [];

    const childIds = [...m.childIds, m.id];

    if (!selectedAvailability) {
      const av = childIds.map((id): ProductOrderSourceAvailability => ({
        productId: this.state().currentSelectedProduct?.id || 0,
        storeGroupId: id,
        storeId: id,
        orderSourceId: orderSource.id,
        changeType: 'added',
      }));
      availabilities = [...selectedAvailabilities, ...av];
    } else if (selectedAvailability.changeType === 'added') {
      const newAvailabilities = selectedAvailabilities
        .filter((avail) => !(childIds.some((id) => avail.storeGroupId === id)
          && avail.orderSourceId === availability.orderSourceId
          && avail.productId === availability.productId));

      availabilities = newAvailabilities;
    } else if (selectedAvailability && !selectedAvailability.changeType) {
      const newAvailabilities = selectedAvailabilities.map((avail) => {
        if (childIds.some((id) => avail.storeGroupId === id)
          && avail.orderSourceId === availability.orderSourceId
          && avail.productId === availability.productId) {
          avail.changeType = 'deleted';
        }

        return avail;
      });

      availabilities = newAvailabilities;
    } else if (availability?.changeType === 'deleted') {
      const newAvailabilities = selectedAvailabilities.map((avail) => {
        if (childIds.some((id) => avail.storeGroupId === id)
          && avail.orderSourceId === availability.orderSourceId
          && avail.productId === availability.productId) {
          avail.changeType = undefined;
        }

        return avail;
      });
      availabilities = newAvailabilities;
    }

    this.setField({ selectedAvailabilities: availabilities });
    this.mapper(this.state().storeGroups, availabilities);
  }

  getFilteredPrices(changeType: string, filteredPrices: ProductOrderSourceAvailability[]) {
    return filteredPrices
      .filter((availability) => availability.changeType && availability.changeType === changeType);
  }

  async updateAvailabilities() {
    const isParent = this.state().currentSelectedProduct?.isParent

    const filteredAvailabilities = this.state().selectedAvailabilities;

    const newAvailabilities = this.getFilteredPrices('added', filteredAvailabilities);
    const availabilityToDelete = this.getFilteredPrices('deleted', filteredAvailabilities);

    try {
      this.setField({ buttonIsLoading: true });
      if (isParent && !newAvailabilities.length && !availabilityToDelete.length) {
        await this.productOrderSourceAvailability.deleteByProductGroupId(this.state().currentSelectedProduct?.id || 0);

        this.showToast(ToastHelper.getToast("tools.toast.productavailabilities.success", "success"), () => { }, 2500);

        return;
      }

      await this.deleteAvailabilities(availabilityToDelete);
      await this.createNewAvailabilities(newAvailabilities, isParent);
      this.showToast(ToastHelper.getToast("tools.toast.productavailabilities.success", "success"), () => { }, 2500);
      this.refresh();
    } catch (error) {
      this.showErrorToast();
    } finally {
      this.setField({ buttonIsLoading: false });
    }
  }

  async deleteAvailabilities(availabilities: ProductOrderSourceAvailability[]) {
    if (!availabilities.length) {
      return;
    }

    await this.productOrderSourceAvailability.delete(availabilities);
  }

  async createNewAvailabilities(availabilities: ProductOrderSourceAvailability[], isParent: boolean = false) {
    if (!availabilities.length) {
      return;
    }

    const categoryId = isParent && this.state().currentSelectedProduct?.id

    await this.productOrderSourceAvailability.post(availabilities, categoryId || undefined);
  }

  showErrorToast() {
    this.showToast(ToastHelper.getToast("tools.toast.product.error", "error"), () => { }, 2500);
  }
}