import {ContentViewModel,ContentView} from '@levelapp/softfabric';
import MainInformationViewState from './MainInformationViewState';
import MainInformationViewProps from './MainInformationViewProps';
import DashboardType from '../../../../../../../../../Common/DTO/DashboardType';

export default class MainInformationViewModel extends ContentViewModel<MainInformationViewState, MainInformationViewProps>
{
      /* CONSTRUCTOR */
    constructor(view: ContentView)
    {
        super(view);

        this.initialState({}); // Initialize your state here

        // Bindings

    }


    changeTypesToDropdown()
    {
        var structure : any[] = [];
        this.props().dashboardType.map((value : DashboardType) =>
        {
            structure.push({title : value.key , value : value.id});

        })
        return structure;
    }

    changeTypeToDropdown()
    {
        if(this.props().dashboard.dashboardType != undefined)
        {
            return {title : this.props().dashboard.dashboardType?.key , value : this.props().dashboard.dashboardType?.id}
        }

        return '';
    }
}