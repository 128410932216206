import { Operation } from "@levelapp/softfabric";
import LanguagesService from "../Proxy/LanguagesService";

export default class LanguagesOperations extends Operation
{
    service : LanguagesService;

    constructor()
    {
        super();
        this.service = new LanguagesService();
    }

    get(callback : Function, onFailure : Function = () => {})
    {
        this.service.get(callback, onFailure);
    }
    post(language:any, callback : Function, onFailure : Function)
    {
        this.service.post(language, callback, onFailure);
    }

    put(language:any, callback : Function, onFailure : Function)
    {
        this.service.put(language, callback, onFailure);
    }
    delete(coupon:any, callback : Function, onFailure : Function)
    {
        this.service.delete(coupon, callback, onFailure);
    }
}