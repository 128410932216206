import { Operation } from "@levelapp/softfabric";
import NewsService from "../Proxy/NewsService";

export default class NewsOperations extends Operation
{
    service : NewsService;

    constructor()
    {
        super();
        this.service = new NewsService();
    }

    get(parameters : any[], callback : Function, onFailure : Function)
    {
        this.service.get(parameters, callback, onFailure);
    }

    post(news:any, callback : Function, onFailure : Function)
    {
        this.service.post(news, callback, onFailure);
    }

    put(news:any, callback : Function, onFailure : Function)
    {
        this.service.put(news, callback, onFailure);
    }

    delete(news:any, callback : Function, onFailure : Function)
    {
        this.service.delete(news, callback, onFailure);
    }
}