
import {ContentShellView} from '@levelapp/softfabric';
import {View} from 'react-native';
import ShellProductAdditionalInfosViewProps from './ShellProductAdditionalInfosViewProps';
import ShellProductAdditionalInfosViewState from './ShellProductAdditionalInfosViewState';
import ShellProductAdditionalInfosViewModel from './ShellProductAdditionalInfosViewModel';
import ShellProductAdditionalInfosViewStyle from './ShellProductAdditionalInfosViewStyle';

export default class ShellProductAdditionalInfosView extends ContentShellView<ShellProductAdditionalInfosViewProps, ShellProductAdditionalInfosViewState, ShellProductAdditionalInfosViewModel, ShellProductAdditionalInfosViewStyle>
{
  /* CONSTRUCTOR */
  constructor(props:ShellProductAdditionalInfosViewProps)
  {
    super(props);

    // Binding
    this.bind(new ShellProductAdditionalInfosViewModel(this), new ShellProductAdditionalInfosViewStyle(this));
  }

  /* RENDERING */
  render()
  {
    return (
      <View style={{flex : 1}}>
        {this.getSwitch()}
      </View>
    );
  }
}