import { ContentShellViewStyle } from '@levelapp/softfabric';

export default class ShellKitchenAppViewStyle extends ContentShellViewStyle
{
    disableSelect: any = {
        "-webkit-touch-callout": "none",
        "-webkit-user-select": "none",
        "-khtml-user-select": "none",
        "-moz-user-select": "none",
        "-ms-user-select": "none",
        "user-select": "none"
    };
}