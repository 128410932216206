 
import {ContentView} from '@levelapp/softfabric';
import MembershipViewState from './MembershipViewState';
import MembershipViewProps from './MembershipViewProps';
import MembershipViewStyle from './MembershipViewStyle';
import MembershipViewModel from './MembershipViewModel';
import { View } from 'react-native';
import ResearchInputView from '../../../../../../../Common/Components/researchinput/ResearchInputView';
import HeaderView from '../../../../components/header/HeaderView';
import TableView from 'src/Common/Components/TabelView/TableView';

export default class MembershipView extends ContentView<MembershipViewProps, MembershipViewState, MembershipViewModel, MembershipViewStyle>
{
  /* CONSTRUCTOR */
  constructor(props:MembershipViewProps)
  {
    super(props);

    // Binding
    this.bind(new MembershipViewModel(this), new MembershipViewStyle(this));
  }

  /* RENDERING */
  render()
  {
    return (
      <View style={this.style.container}>
        <View style={this.style.box}>

          <HeaderView
              title={"membership.title"}
              description={"membership.description"}
              shouldShowButton={true}
              onButtonPress={this.binding.addField}
              buttonText={"membership.button.add"}
            />

          <View style={this.style.boxContainer}>
            <ResearchInputView
              placeholder={this.binding.translate('generic.search')}
              handlerResearching={(searchText: string) => { this.binding.handleResearching(searchText) }}
              handlingClose={() => { this.binding.handleCloseResearching() }} />
            <TableView
              headerTextStyle={this.style.tableHeader}
              rowStyle={this.style.rowStyle}
              backgroundStepColor={this.style.stepColor}
              nothingToDisplayText={this.binding.translate('component.table.noresults')}
              isLastColumnActions={true}
              data={this.state.tableData}
              isLoading={this.state.isLoading}
              headers={this.state.tableHeaders}
              onPageChange={(page: number) => this.binding.onPageChange(page)}
              handleSortChange={(indexHeaderSort : number, sortOrder : string) => {this.binding.onSortChange(indexHeaderSort, sortOrder)}}
              nbPage={this.state.nbPage}
              currentPage={this.state.currentPage - 1}
              isSortable={true}/>
          </View>

        </View>
      </View>
    );
  }
}