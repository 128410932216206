import {ContentViewStyle, Application} from '@levelapp/softfabric';
import {StyleProp,ViewStyle, TextStyle} from 'react-native';
import { StyleManager, UiManagers } from '@levelapp/softfabric-ui';



export default class MainInformationViewStyle extends ContentViewStyle
{
    MyStyleManager : StyleManager = Application.current.resolve<StyleManager>(UiManagers.STYLE_MANAGER);

    page: StyleProp<ViewStyle> = {
        flex: 1
    }

    section : StyleProp<ViewStyle> = {
        flexDirection: "column",
        flex: 1,
        marginVertical : 35
    }

    inputContainer : StyleProp<ViewStyle> = {
        marginRight : 20,
    };

    inputTitle: StyleProp<TextStyle> = {
        fontSize: 14,
        fontWeight: '600',
        color: '#3F3F3F',
        fontFamily : 'Montserrat SemiBold',
        marginBottom : 13,
    };

    checkbox: StyleProp<any> = {
        width : 20,
        height : 20,
        marginHorizontal: 5,
    };

    checkboxSection : StyleProp<ViewStyle> = {
        flexDirection: "column",
        marginVertical : 35,
        justifyContent : "center"
    }

    largeInput: StyleProp<any> = {
        zIndex : -1, 
        width: 175,
        paddingHorizontal: 15,
        borderRadius : 8,
        boxShadow: '0 5px 14px 0 rgba(0,0,0,0.1)',
    };

    largeErrorTextField: StyleProp<ViewStyle> = {
        zIndex : -1,
        borderColor: 'red',
        borderWidth: 1,
        width: 175,
        paddingHorizontal: 15,
        borderRadius : 8,
    };

    errorTextField: StyleProp<ViewStyle> = {
        zIndex : -1,
        borderColor: 'red',
        borderWidth: 1,
        width: 140,
        paddingHorizontal: 15,
        borderRadius : 8,
    };

    input: StyleProp<any> = {
        zIndex : -1, 
        width: 140,
        paddingHorizontal: 15,
        borderRadius : 8,
        boxShadow: '0 5px 14px 0 rgba(0,0,0,0.1)',
    };

    buttonGroup: StyleProp<ViewStyle> = {
       flexDirection: 'row',
       paddingVertical: 8,
    }

    button: StyleProp<unknown> = {
        paddingVertical: 8,
        paddingHorizontal: 25,
        backgroundColor: '#BBBBBB',
        border: '1px solid #CCC',
        borderRadius: 8,
        marginRight: 5
    }

    activeButton: StyleProp<ViewStyle> = {
        paddingVertical: 8,
        paddingHorizontal: 25,
        backgroundColor: this.MyStyleManager.primaryColor,
        borderRadius: 8,
        marginRight: 5
    }
    buttonText: StyleProp<TextStyle> = {
        textAlign: 'center'
    }

}