 
import {ContentShellView} from '@levelapp/softfabric';
import ShellEarningpointsViewState from './ShellEarningpointsViewState';
import ShellEarningpointsViewProps from './ShellEarningpointsViewProps';
import ShellEarningpointsViewStyle from './ShellEarningpointsViewStyle';
import ShellEarningpointsViewModel from './ShellEarningpointsViewModel';
import {View} from 'react-native';

export default class ShellEarningpointsView extends ContentShellView<ShellEarningpointsViewProps, ShellEarningpointsViewState, ShellEarningpointsViewModel, ShellEarningpointsViewStyle>
{
  /* CONSTRUCTOR */
  constructor(props:ShellEarningpointsViewProps)
  {
    super(props);

    // Binding
    this.bind(new ShellEarningpointsViewModel(this), new ShellEarningpointsViewStyle(this));
  }

  /* RENDERING */
  render()
  {
    return (
      <View style={{flex : 1}}>
        {this.getSwitch()}
      </View>
    );
  }
}