 
import { ContentShellView } from '@levelapp/softfabric';
import ShellStoresViewState from './ShellStoresViewState';
import ShellStoresViewProps from './ShellStoresViewProps';
import ShellStoresViewStyle from './ShellStoresViewStyle';
import ShellStoresViewModel from './ShellStoresViewModel';
import { View } from 'react-native';

export default class ShellStoresView extends ContentShellView<ShellStoresViewProps, ShellStoresViewState, ShellStoresViewModel, ShellStoresViewStyle>
{
  /* CONSTRUCTOR */
  constructor(props:ShellStoresViewProps)
  {
    super(props);

    // Binding
    this.bind(new ShellStoresViewModel(this), new ShellStoresViewStyle(this));
  }

  /* RENDERING */
  render()
  {
    return (
      <View style={{flex : 1}}>
      {this.getSwitch()}
    </View>
    );
  }
}