import { ContentViewModel, ContentView } from '@levelapp/softfabric';

import DeliverySettingsPopupViewState from './DeliverySettingsPopupViewState';
import DeliverySettingsPopupViewProps from './DeliverySettingsPopupViewProps';

import ToastHelper from 'src/Common/Helpers/ToastHelper';
import DashboardHelper from 'src/Common/Helpers/DashboardHelper';
import AdminProductOperations from 'src/Transfer/AdminProductOperations';

export default class DeliverySettingsPopupViewModel extends ContentViewModel<DeliverySettingsPopupViewState, DeliverySettingsPopupViewProps>
{
  adminProductOperation: AdminProductOperations;

  /* CONSTRUCTOR */
  constructor(view: ContentView) {
    super(view);

    this.initialState({
      isUpdateDeliveryLoading: false,
      isUberEatIntegrationEnabled: false,
      isUberEatIntegrationButtonLoading: true,
    });

    this.adminProductOperation = new AdminProductOperations();

    // Bindings
    this.updateDelivery = this.updateDelivery.bind(this);
    this.fetchDeliveryIntegration = this.fetchDeliveryIntegration.bind(this);
    this.updateUberEatDeliveryIntegration = this.updateUberEatDeliveryIntegration.bind(this);
  }

  componentDidMount(): void {
    this.fetchDeliveryIntegration();
  }

  async fetchDeliveryIntegration() {
    try {
      this.setField({ isUberEatIntegrationButtonLoading: true });
      const uberEatIntegration = await this.adminProductOperation.fetchUberEatIntegrationStatus(DashboardHelper.getSelectedStoreId());
      this.setField({ isUberEatIntegrationEnabled: uberEatIntegration });
      this.setField({ isUberEatIntegrationButtonLoading: false });
    } catch {
      this.showToast(ToastHelper.getToast("generic.error.serverside", "error"), () => { }, 2500);
      this.setField({ isUberEatIntegrationButtonLoading: false });
    }
  }

  async updateUberEatDeliveryIntegration() {
    try {
      this.setField({ isUberEatIntegrationButtonLoading: true });
      await this.adminProductOperation.updateUberEatIntegration({
        isEnabled: !this.state().isUberEatIntegrationEnabled,
        storeId: DashboardHelper.getSelectedStoreId(),
      });
      this.setField({
        isUberEatIntegrationButtonLoading: true,
        isUberEatIntegrationEnabled: !this.state().isUberEatIntegrationEnabled,
      });
      this.setField({ isUberEatIntegrationButtonLoading: false });
      this.showToast(ToastHelper.getToast("generic.success", "success"), () => { }, 2500);
    } catch {
      this.showToast(ToastHelper.getToast("generic.error.serverside", "error"), () => { }, 2500);
      this.setField({ isUberEatIntegrationButtonLoading: true });
    }
  }

  async updateDelivery() {
    if (this.state().isUpdateDeliveryLoading) {
      return;
    };

    try {
      this.setField({ isUpdateDeliveryLoading: true });
      await this.adminProductOperation.updateDelivery(DashboardHelper.getSelectedStoreId());
      this.setField({ isUpdateDeliveryLoading: false });
      this.showToast(ToastHelper.getToast("generic.success", "success"), () => { }, 2500);
    } catch {
      this.showToast(ToastHelper.getToast("generic.error.serverside", "error"), () => { }, 2500);
      this.setField({ isUpdateDeliveryLoading: false });
    }
  }
}