import {ContentViewModel,ContentView} from '@levelapp/softfabric';
import ItemLanguageViewState from './ItemLanguageViewState';
import ItemLanguageViewProps from './ItemLanguageViewProps';

export default class ItemLanguageViewModel extends ContentViewModel<ItemLanguageViewState, ItemLanguageViewProps>
{
      /* CONSTRUCTOR */
    constructor(view: ContentView)
    {
        super(view);

        this.initialState({}); // Initialize your state here

        // Bindings

    }
}