import  moment from "moment";

export default class DateHelper 
{

    /**
     * HttpClient will always send the UTC date.
     * If the date is 14/07 at 00:00:00 +GMT:2. HttpClient will change it to 13/07 at 22:00:00
     * This method add GMT hours to the initial date.
     * 
     * @param date 
     * 
     */
    static changeDateToBeValidUTC(date : any | Date)
    {
        var mDate = moment(date);
        return moment(mDate).utc().add(mDate.utcOffset(), 'm');
    }



    static formatToEuropeanDate(date : Date | string) : string
    {
        return moment(date).format('DD/MM/YYYY');
    }


}