 
import {ContentView} from '@levelapp/softfabric';
import CrossSellConfigPopUpViewState from './CrossSellConfigPopUpViewState';
import CrossSellConfigPopUpViewProps from './CrossSellConfigPopUpViewProps';
import CrossSellConfigPopUpViewStyle from './CrossSellConfigPopUpViewStyle';
import CrossSellConfigPopUpViewModel from './CrossSellConfigPopUpViewModel';
import { Text, View } from 'react-native';
import Constants from '../../../../../../../Common/Constants/Constants';
import ResearchInputView from '../../../../../../../Common/Components/researchinput/ResearchInputView';
import TableView from '../../../../../../../Common/Components/TabelView/TableView';
import ButtonView from '../../../../../../../Common/Components/Button/ButtonView';
import { TranslationView } from '@levelapp/softfabric-ui';
import Helpers from '../../../../../../../Common/Helpers/Helpers';
import PopupHeaderView from '../../../../components/popupheader/PopupHeaderView';
import AddCrossSellProductPopUpView from '../addcrosssellproductpopup/AddCrossSellProductPopUpView';
import ProductPopupView from '../../../products/pages/productpopup/ProductPopupView';

export default class CrossSellConfigPopUpView extends ContentView<CrossSellConfigPopUpViewProps, CrossSellConfigPopUpViewState, CrossSellConfigPopUpViewModel, CrossSellConfigPopUpViewStyle>
{
  /* CONSTRUCTOR */
  constructor(props:CrossSellConfigPopUpViewProps)
  {
    super(props);

    // Binding
    this.bind(new CrossSellConfigPopUpViewModel(this), new CrossSellConfigPopUpViewStyle(this));
  }

  /* RENDERING */
  render() {
    switch (this.state.windowTab)
    {
      case 'addProduct':
      return (
      <View style={{ minWidth: 600, minHeight: 850, width: Constants.PopUpWidth, maxWidth: "100%" }}>
        <PopupHeaderView 
          shouldShowButton={true} 
          title={this.state.isGroupProduct ? "crosssell.popup.addproduct.header.title.addgroup" : "crosssell.popup.addproduct.header.title.addproduct"} 
          handleOnPressButton={() => this.binding.addProducts()}
          handleBackPressed={() => this.binding.closePopUp()} />

      
        
        <View style={this.style.boxContainer}>
         
          <ResearchInputView
            placeholder={this.binding.translate('generic.search')}
            handlerResearching={(searchText: string) => { this.binding.handleResearching(searchText) }}
            handlingClose={() => { this.binding.handleCloseResearching() }} />
          <View>
            <Text>Your products :
            {this.state.listProducts.map((product) => {
              return (
                <Text style={{ marginHorizontal: 10 }}>{product.titleTranslations[0].value}</Text>
              );
            })}
            </Text>
          </View>
        
          <ButtonView isLoading={false} handleClick={() => {this.binding.setField({windowTab:"newProduct"})}} backgroundColor={Constants.MainColor} textColor={"white"} style={{width: 300, marginVertical: 20}}>          
              <TranslationView>
                {this.state.isGroupProduct ? "crosssell.popup.AddProduct.button.textcreationgroup" : "crosssell.popup.AddProduct.button.textcreationproduct"}
              </TranslationView>
          </ButtonView>
          <TableView
            headerTextStyle={this.style.tableHeader}
            rowStyle={this.style.rowStyle}
            backgroundStepColor={this.style.stepColor}
            nothingToDisplayText={this.binding.translate('component.table.noresults')}
            isLastColumnActions={false}
            isLastColumnCheckBox={true}
            data={this.state.tableData}
            isLoading={this.state.isLoading}
            headers={this.state.tableHeaders}
            onPageChange={(page: number) => this.binding.onPageChange(page)}
            handleSortChange={() => { }}
            nbPage={this.state.nbPage}
            currentPage={this.state.currentPage - 1}
            isSortable={false} />
        </View>
        
      </View>
    );
    case 'newProduct':
      if(this.state.isGroupProduct){
        return  <AddCrossSellProductPopUpView
        product={{
            productAllergens:[],
            titleTranslations: Helpers.generateTranslationTable(this.state.languages),
            descriptionTranslations: Helpers.generateTranslationTable(this.state.languages),
            imageTranslations: Helpers.generateUrlTable(this.state.languages),
            thumbnailTranslations: Helpers.generateUrlTable(this.state.languages),
        }} 
        languages={this.state.languages}
        refreshParent={this.binding.refresh}
        handleBack={()=>{ this.binding.refresh();this.binding.setField({windowTab:"addProduct"})}}
        />
      }else{
        return  <ProductPopupView
        product={{
            productAllergens:[],
            titleTranslations: Helpers.generateTranslationTable(this.state.languages),
            descriptionTranslations: Helpers.generateTranslationTable(this.state.languages),
            imageTranslations: Helpers.generateUrlTable(this.state.languages),
            thumbnailTranslations: Helpers.generateUrlTable(this.state.languages),
        }}
        languages={this.state.languages}
        refreshParent={this.binding.refresh}
        handleBack={()=>{ this.binding.refresh();this.binding.setField({windowTab:"addProduct"})}}
        isGroup={this.state.isGroupProduct}
        />
      }
        
    default:
        return (<View></View>);   
    } 
  }
}