 
import { ContentView } from '@levelapp/softfabric';
import CustomDatePickerViewState from './CustomDatePickerViewState';
import CustomDatePickerViewProps from './CustomDatePickerViewProps';
import CustomDatePickerViewStyle from './CustomDatePickerViewStyle';
import CustomDatePickerViewModel from './CustomDatePickerViewModel';
import { View, TouchableWithoutFeedback, Text, Image } from 'react-native';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import 'react-datepicker/dist/react-datepicker-cssmodules.css';

export default class CustomDatePickerView extends ContentView<CustomDatePickerViewProps, CustomDatePickerViewState, CustomDatePickerViewModel, CustomDatePickerViewStyle>
{
  _refDatePicker: any;

  /* CONSTRUCTOR */
  constructor(props: CustomDatePickerViewProps)
  {
    super(props);

    // Binding
    this.bind(new CustomDatePickerViewModel(this), new CustomDatePickerViewStyle(this));
  }

  getInnerRef()
  {
    return this._refDatePicker;
  }

  /* RENDERING */
  render()
  {
    const { isClosingToday, currentShiftEnd } = this.props;

    const CustomInput = (props: any) =>
    {
      let dateStr = props.value;

      if (!isClosingToday)
      {
        dateStr += " " + this.binding.translate('kitchenapp.shiftendson')
          .replace('$0', `${this.roundNumber(currentShiftEnd.getDate())}/${this.roundNumber(currentShiftEnd.getMonth() + 1)}`)
          .replace('$1', `${this.roundNumber(currentShiftEnd.getHours())}:${this.roundNumber(currentShiftEnd.getMinutes())}`);
      }

      return (
        <TouchableWithoutFeedback onPress={props.onClick}>
          <View style={{ flex: 1, backgroundColor: 'white', paddingVertical: 13, paddingHorizontal: 13, borderRadius: 5, flexDirection: 'row', alignItems: 'center' }}>
            <Text style={{ paddingRight: 18 }}>
              {dateStr}
            </Text>
            <Image source={require('./assets/calendar.svg').default} style={{ height: 21, width: 18 }} />
          </View>
        </TouchableWithoutFeedback>
      );
    }

    return (
      <View style={{ zIndex: this.props.zIndex }}>
        <DatePicker
          ref={(r: any) => this._refDatePicker = r}
          selected={this.props.selected}
          dateFormat="d/MM/yyyy"
          onChange={(d: Date) => this.props.handleOnChange(d)}
          customInput={<CustomInput />} />
      </View>
    );
  }

  private roundNumber(number: number)
  {
    return ('0' + number).slice(-2);
  }

}