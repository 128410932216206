import {ContentShellViewModel, ContentShellView} from '@levelapp/softfabric';
import ShellAdminUsersViewState from './ShellAdminUsersViewState';
import ShellAdminUsersViewProps from './ShellAdminUsersViewProps';
 
import AdminUsers from './pages/adminusers/AdminUsersView';
import EnumModules from '../../../../../Common/Constants/EnumModules';

export default class ShellAdminUsersViewModel extends ContentShellViewModel<ShellAdminUsersViewState, ShellAdminUsersViewProps>
{

    constructor(view : ContentShellView)
    {
        super(view);

        this.registerEntryPoint('main'); // TODO : change with the route of your entry point
        this.register(<AdminUsers/> ,'main'); // TODO : register your routes with the JSX (param1) and the route (param2)

        this.initialisation();
    }

    getModuleId()
    {
        return EnumModules.ADMINUSERS; // TODO : change the return value with your ModuleId
    }

}