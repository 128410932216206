 
import {ContentView} from '@levelapp/softfabric';
import ExtendedClickableTextViewState from './ExtendedClickableTextViewState';
import ExtendedClickableTextViewProps from './ExtendedClickableTextViewProps';
import ExtendedClickableTextViewStyle from './ExtendedClickableTextViewStyle';
import ExtendedClickableTextViewModel from './ExtendedClickableTextViewModel';
import ExtendedTranslationView from '../extendedtranslation/ExtendedTranslationView';
import RoundButtonView from '../roundbutton/RoundButtonView';


import { Image, Text, TouchableWithoutFeedback, View } from 'react-native';

export default class ExtendedClickableTextView extends ContentView<ExtendedClickableTextViewProps, ExtendedClickableTextViewState, ExtendedClickableTextViewModel, ExtendedClickableTextViewStyle>
{
  /* CONSTRUCTOR */
  constructor(props:ExtendedClickableTextViewProps)
  {
    super(props);

    // Binding
    this.bind(new ExtendedClickableTextViewModel(this), new ExtendedClickableTextViewStyle(this));
  }

  /* RENDERING */
  render()
  {
    const { sectionId , text } = this.style; 


    return (
      <View style={[sectionId,this.props.style]}>
        <ExtendedTranslationView styleText={this.props.styleTitle} uppercase={this.props.uppercase != undefined ? this.props.uppercase : true} afterTitle={this.props.extraTitle} infoText={this.props.infoText}>
          {this.props.title}
        </ExtendedTranslationView>

        <View style={{flexDirection : 'row'}}>
          
          <TouchableWithoutFeedback onPress={() => this.props.onPressValue != undefined && this.props.onPressValue()}>
            <View style={{height : 38 , justifyContent : 'center'}}>
              <Text style={[this.props.styleValue,text]}>{this.props.value}</Text>
            </View>
          </TouchableWithoutFeedback>

            {this.props.shouldShowMore && <RoundButtonView type={'little'} style={{paddingLeft : 10 , paddingRight : 15}} shouldUseDefaultPadding={false} backgroundColor={'white'} handleOnPress={() => { this.props.onMorePress != undefined && this.props.onMorePress()}}>
              <Image  resizeMode='contain'  style={{height : 15 , width : 15}} source={require('./assets/more.png').default}/>
            </RoundButtonView>}
        </View>
        
      </View>
    );
  }
}