 
import {ContentView} from '@levelapp/softfabric';
import SavingCardsViewState from './SavingCardsViewState';
import SavingCardsViewProps from './SavingCardsViewProps';
import SavingCardsViewStyle from './SavingCardsViewStyle';
import SavingCardsViewModel from './SavingCardsViewModel';
import { View } from 'react-native';
import ResearchInputView from '../../../../../../../Common/Components/researchinput/ResearchInputView';
import TableView from '../../../../../../../Common/Components/TabelView/TableView';
import HeaderView from '../../../../components/header/HeaderView';
import Constants from '../../../../../../../Common/Constants/Constants';

export default class SavingCardsView extends ContentView<SavingCardsViewProps, SavingCardsViewState, SavingCardsViewModel, SavingCardsViewStyle>
{
  /* CONSTRUCTOR */
  constructor(props: SavingCardsViewProps) {
    super(props);

    // Binding
    this.bind(new SavingCardsViewModel(this), new SavingCardsViewStyle(this));
  }

  /* RENDERING */
  render() {
    return (
      <View style={this.style.container}>
        <View style={this.style.box}>

          <HeaderView
              title={(Constants.IsSavingCardsUsedAsBadge) ? "badges.title" : "savingcards.title"}
              description={(Constants.IsSavingCardsUsedAsBadge) ? "badges.description" : "savingcards.description"}
              shouldShowButton={true}
              onButtonPress={this.binding.addField}
              buttonText={(Constants.IsSavingCardsUsedAsBadge) ? "badges.button.add" : "savingcards.button.add"}
            />
          <View style={this.style.boxContainer}>
          <ResearchInputView
              placeholder={this.binding.translate('generic.search')}
              handlerResearching={(searchText: string) => { this.binding.handleResearching(searchText) }}
              handlingClose={() => { this.binding.handleCloseResearching() }} />
            <TableView
              headerTextStyle={this.style.tableHeader}
              backgroundStepColor={this.style.stepColor}
              rowStyle={this.style.rowStyle}
              nothingToDisplayText={this.binding.translate('component.table.noresults')}
              isLastColumnActions={true}
              data={this.state.tableData}
              isLoading={this.state.isLoading}
              headers={this.state.tableHeaders}
              onPageChange={(page: number) => this.binding.onPageChange(page)}
              handleSortChange={(indexHeaderSort : number, sortOrder : string) => {this.binding.onSortChange(indexHeaderSort, sortOrder)}}
              nbPage={this.state.nbPage}
              currentPage={this.state.currentPage - 1}
              isSortable={true}/>
          </View>
        </View>
      </View>
    );
  }
}