 
import { ContentView } from '@levelapp/softfabric';
import AutomatedMessagesViewState from './AutomatedMessagesViewState';
import AutomatedMessagesViewProps from './AutomatedMessagesViewProps';
import AutomatedMessagesViewStyle from './AutomatedMessagesViewStyle';
import AutomatedMessagesViewModel from './AutomatedMessagesViewModel';
import { View } from 'react-native';
import ResearchInputView from '../../../../../../../Common/Components/researchinput/ResearchInputView';
import TableView from '../../../../../../../Common/Components/TabelView/TableView';
import HeaderView from '../../../../components/header/HeaderView';

export default class AutomatedMessagesView extends ContentView<AutomatedMessagesViewProps, AutomatedMessagesViewState, AutomatedMessagesViewModel, AutomatedMessagesViewStyle>
{
  /* CONSTRUCTOR */
  constructor(props: AutomatedMessagesViewProps) {
    super(props);

    // Binding
    this.bind(new AutomatedMessagesViewModel(this), new AutomatedMessagesViewStyle(this));
  }

  /* RENDERING */
  render() {
    return (
      <View style={this.style.container}>
        <View style={this.style.box}>

          <HeaderView
            title={"automatedmessages.title"}
            description={"automatedmessages.description"}
            shouldShowButton={false}
          />

          <View style={this.style.boxContainer}>

          <ResearchInputView
              placeholder={this.binding.translate('generic.search')}
              handlerResearching={(searchText: string) => { this.binding.handleResearching(searchText) }}
              handlingClose={() => { this.binding.handleCloseResearching() }} />
            <TableView
              headerTextStyle={this.style.tableHeader}
              rowStyle={this.style.rowStyle}
              backgroundStepColor={this.style.stepColor}
              nothingToDisplayText={this.binding.translate('component.table.noresults')}
              isLastColumnActions={true}
              data={this.state.tableData}
              isLoading={this.state.isLoading}
              headers={this.state.tableHeaders}
              onPageChange={(page: number) => this.binding.onPageChange(page)}
              handleSortChange={(indexHeaderSort : number, sortOrder : string) => {this.binding.onSortChange(indexHeaderSort, sortOrder)}}
              nbPage={this.state.nbPage}
              currentPage={this.state.currentPage - 1}
              isSortable={true}/>
          </View>
        </View>
      </View>
    );
  }
}