import { ContentViewModel, ContentView, LanguageManager, Application, Managers } from '@levelapp/softfabric';
import AllergensManagementViewState from './AllergensManagementViewState';
import AllergensManagementViewProps from './AllergensManagementViewProps';
import AllergenOperations from '../../../../../../../../../Transfer/AllergenOperations';
import Allergen from '../../../../../../../../../Common/DTO/Allergen';
import Helpers from '../../../../../../../../../Common/Helpers/Helpers';
import TranslationValue from '../../../../../../../../../Common/DTO/TranslationValue';
import Product from '../../../../../../../../../Common/DTO/Product';
import Constants from '../../../../../../../../../Common/Constants/Constants';
import ToastHelper from '../../../../../../../../../Common/Helpers/ToastHelper';

export default class AllergensManagementViewModel extends ContentViewModel<AllergensManagementViewState, AllergensManagementViewProps>
{
    _allergensOperation: AllergenOperations;
    _allergenToDelete: Allergen | undefined;

    _languageManager: LanguageManager;
    /* CONSTRUCTOR */
    constructor(view: ContentView) {
        super(view);

        this.initialState({
            showPopup: false,
            isCreating: false,
            confirmationPopup: false,
            allergens: [],
            allergen: undefined,
            languages: this.props().languages,
            titlePopup: "",
            contentPopup: "",
        });

        // Bindings
        this.refresh = this.refresh.bind(this);
        this.handleImage = this.handleImage.bind(this);
        this.deleteAllergen = this.deleteAllergen.bind(this);
        this.handleCreateAllergen = this.handleCreateAllergen.bind(this);
        this.handleSuccessDeleted = this.handleSuccessDeleted.bind(this);
        this.handleCloseManagement = this.handleCloseManagement.bind(this);
        this.handleSuccessAllergens = this.handleSuccessAllergens.bind(this);
        this.handleBackPopupPressed = this.handleBackPopupPressed.bind(this);
        this.handleAllergenNameChanged = this.handleAllergenNameChanged.bind(this);
        this.handleTranslations = this.handleTranslations.bind(this);

        this._allergensOperation = new AllergenOperations();
        this._languageManager = Application.current.resolve<LanguageManager>(Managers.LANGUAGE_MANAGER);
    }

    componentDidMount() {
        this.refresh();
    }

    refresh() {
        this._allergensOperation.get(this.handleSuccessAllergens);
    }

    handleSuccessAllergens(data: Allergen[]) {
        var state = this.state();
        state.allergens = data;
        state.allergen = this.initialAllergen();
        this.setField({ state });
    }

    initialAllergen() {
        let allergen: Allergen = {
            "id": -1,
            "titleTranslations": ([] as any[]),
            "imageTranslations": Helpers.generateUrlTableSize(1),
        };
        this.state().languages.forEach(languageKey => {
            allergen.titleTranslations.push({
                "id": -1,
                "value": "",
                "language": {
                    "key": languageKey.key,
                }
            });
        });
        allergen.imageTranslations[0].language = { key: "" };
        return allergen;
    }

    deleteAllergen() {
        this._allergensOperation.delete((this._allergenToDelete as Allergen), this.handleSuccessDeleted);
    }

    handleSuccessDeleted() {
        this.showToast(ToastHelper.getToast("allergens.popup.success.deleted", "success"), () => { }, 2500);
        this.setField({ contentPopup: '', confirmationPopup: false, titlePopup: 'PropertyDeleted', showPopup: false });
        this.refresh()
    }

    handleDeleteAllergen(allergen: Allergen) {
        this._allergenToDelete = allergen;
        this._allergensOperation.getProductsUsingAllergen(allergen, (data: Product[]) => {
            if (data.length == 0) {
                this.setField({ contentPopup: '', confirmationPopup: true, titlePopup: 'generic.confirmation.delete', showPopup: true });
            } else {

                var products = '';
                data.forEach((product: Product) => {
                    if (product)
                        products = products + product.posId + ', '
                });

                this.setField({ contentPopup: products, confirmationPopup: true, titlePopup: 'products.popup.warning.allergen', showPopup: true });
            }
        })
    }

    handleImage(base64: any) {
        var state = this.state();
        (state.allergen as Allergen).imageTranslations[0].url = base64;
        (state.allergen as Allergen).imageTranslations[0].language.key = state.languages[0].key;
        (state.allergen as Allergen).imageTranslations[0].imageData = (state.allergen as Allergen).imageTranslations[0].url.replace("data:image/png;base64,", "").replace("data:image/jpeg;base64,", "");
        this.setField({ state });
    }

    handleBackPopupPressed() {
        this.setField({ showPopup: false });
    }

    handleCloseManagement() {
        this.props().handleBackPressed();
    }



    handleAllergenNameChanged(value: string, language: string) {
        try {
            this.setField((previousState) => (((previousState.allergen as Allergen).titleTranslations as TranslationValue[]).find((titleTranslation: any) => { return titleTranslation.language.key == language }) as TranslationValue).value = value)
        } catch (error) {
            alert('error');
        }

    }

    handleCreateAllergen() {
        if (this.hasTitleForeachLanguage() && this.isSelectedImage()) {
            this.setField({ isCreating: true });
            this._allergensOperation.post((this.state().allergen as Allergen), () => {
                this.setField({ isCreating: false });
                this.showToast(ToastHelper.getToast("allergens.popup.success.added", "success"), () => { }, 2500);
                this.refresh();
            })
        } else {
            //TODO: show toast
        }
    }

    handleTranslations(allergen : Allergen)
    {
        if(allergen.titleTranslations.length === 0) return "No Translation";

        const translation = allergen.titleTranslations.find(
            (element: TranslationValue) => 
            {
                  return ( element.language.key == this._languageManager.language);
            }
          );
        
        if(translation !== undefined) return translation.value;
          
        return allergen.titleTranslations[0].value;
        
    }

    private hasTitleForeachLanguage() {
        var nbLanguage = this.state().languages.length;
        var i = 0;
        var hasTitle = true;
        while (i < nbLanguage && hasTitle) {
            hasTitle = (this.state().allergen as Allergen).titleTranslations[i].value != '';
            i++;
        }
        return hasTitle;
    }

    private isSelectedImage() {
        return (this.state().allergen as Allergen).imageTranslations[0].url !== Constants.UploadImageUrl;
    }



}