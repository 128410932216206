import { Service, Application, HttpManager, Managers } from "@levelapp/softfabric";
import MembershipType from "src/Common/DTO/MembershipType";

export default class MembershipTypeService extends Service{

    httpClient = Application.current.resolve<HttpManager>(Managers.HTTP_MANAGER);

    async getAll(onSuccess: Function, onFailure : Function = () => {}){
        this.httpClient.handleSomethingWentWrong = onFailure;
        this.httpClient.handleNotFound = onFailure;

        this.httpClient
        .get('membershipType')
        .then((response:any)=>{
            if(response){
                if(response.data){
                    onSuccess(response.data.data);
                }
            }
        })
    }

    post(membershipType:MembershipType,onSuccess : Function, onFailure : Function = () => {}){
        this.httpClient.handleSomethingWentWrong = onFailure;
        this.httpClient.handleNotFound = onFailure;

        this.httpClient
        .post('membershipType',[],membershipType)
        .then((response:any)=>{
            if(response){
                onSuccess("succes");
            }
        })
    }

    put(membershipType:MembershipType,onSuccess : Function, onFailure : Function = () => {}){
        this.httpClient.handleSomethingWentWrong = onFailure;
        this.httpClient.handleNotFound = onFailure;

        this.httpClient
        .put('membershipType',[],membershipType)
        .then((response:any)=>{
            if(response){
                onSuccess("succes");
            }
        })
    }

    delete(membershipTypeId:number,onSuccess:Function, onFailure : Function = () => {}){
        this.httpClient.handleSomethingWentWrong = onFailure;
        this.httpClient.handleNotFound = onFailure;

        this.httpClient
        .delete('membershipType',[{key : "membershipTypeId", value : membershipTypeId}])
        .then((response:any)=>{
            if(response)
                onSuccess("succes");
        })
    }
}