import { ContentShellView, ContentShellViewModel } from '@levelapp/softfabric';
import ShellUsersViewState from './ShellUsersViewState';
import ShellUsersViewProps from './ShellUsersViewProps';
import UsersView from './pages/users/UsersView';
 
import EnumModules from '../../../../../Common/Constants/EnumModules';

export default class ShellUsersViewModel extends ContentShellViewModel<ShellUsersViewState, ShellUsersViewProps>
{
    constructor(view : ContentShellView)
    {
        super(view);

        this.registerEntryPoint('main');
        this.register(<UsersView/> ,'main');

        this.initialisation();
    }

    getModuleId()
    {
        return EnumModules.USERS;
    }
}