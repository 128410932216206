 
import {ContentShellView} from '@levelapp/softfabric';
import ShellProductsViewState from './ShellProductsViewState';
import ShellProductsViewProps from './ShellProductsViewProps';
import ShellProductsViewStyle from './ShellProductsViewStyle';
import ShellProductsViewModel from './ShellProductsViewModel';
import {View} from 'react-native';

export default class ShellProductsView extends ContentShellView<ShellProductsViewProps, ShellProductsViewState, ShellProductsViewModel, ShellProductsViewStyle>
{
  /* CONSTRUCTOR */
  constructor(props:ShellProductsViewProps)
  {
    super(props);

    // Binding
    this.bind(new ShellProductsViewModel(this), new ShellProductsViewStyle(this));
  }

  /* RENDERING */
  render()
  {
    return (
      <View style={{flex : 1}}>
        {this.getSwitch()}
      </View>
    );
  }
}