import { ContentViewModel, ContentView } from '@levelapp/softfabric';
import TableViewState from './TableViewState';
import TableViewProps from './TableViewProps';

export default class TableViewModel extends ContentViewModel<TableViewState, TableViewProps>
{
    imageDesc: any = require('./assets/sort_desc.png').default;
    imageAsc: any = require('./assets/sort_asc.png').default;
    image: any = require('./assets/sort.png').default;

    constructor(view: ContentView) {
        super(view);
        var tmp = "asc";
        if (this.props().defaultSortDesc != undefined && this.props().defaultSortDesc) {

            tmp = "desc";
        }

        this.initialState(
            {
                currentPage: this.props().currentPage,
                currentSort: tmp,
                defaultSortDesc: this.props().defaultSortDesc,
                image: this.image,
                imageSort: this.imageDesc,
                sortIndice: this.props().defaultSortIndice != undefined ? this.props().defaultSortIndice as number : -1,
                currentArray: (this.props().data != undefined && this.props().data.length > 0) ? this.props().data.slice(0) : [],
                initialArrayState: (this.props().data != undefined && this.props().data.length > 0) ? this.props().data : [],
            }
        );

        this.OnPress = this.OnPress.bind(this);
        this.changePage = this.changePage.bind(this);
        this.getCurrentPage = this.getCurrentPage.bind(this);
        this.handleCheckBoxClicked = this.handleCheckBoxClicked.bind(this);
    }

    /* METHODS */
    getCurrentArray() {
        if (this.props().data != this.state().initialArrayState) {
            this.setField({ currentArray: this.props().data.slice(0), initialArrayState: this.props().data });
            return [];
        }
        else {
            return this.state().currentArray;
        }
    }

    getCurrentPage() {
        if (this.props().currentPage != this.state().currentPage) {
            this.setField({ currentPage: this.props().currentPage })
            return 0;
        } else {
            return this.state().currentPage;
        }
    }

    /* PAGING */
    getPages() {
        return this.props().nbPage;
    }

    /* Paging */
    getElementsForPage() {
        return this.getCurrentArray();
    }

    handleCheckBoxClicked(data: any) {
        var productId = data.data[0];
        var lst = this.props().data;
        lst.forEach(element => {
            if (element.data[0] == productId) {
                 element.isCheckBoxChecked = !element.isCheckBoxChecked;
                 if(element.isCheckBoxChecked) {
                    element.editCallback();
                 }
                 else {
                    element.deleteCallback();
                 }
            }
               
        });
        this.setField({ data: lst })
        
    }

    changePage(nextPage: number) {
        this.props().onPageChange(nextPage);
        this.setField({ currentPage: nextPage });
    }

    OnPress(key: number) {
        if (key != this.state().sortIndice) {
            if (this.props().defaultSortDesc != undefined && this.props().defaultSortDesc) {
                this.setField({ currentSort: 'desc', sortIndice: key, imageSort: this.imageDesc }, this.props().handleSortChange(key, 'DSC'));
            } else {
                this.setField({ currentSort: 'asc', sortIndice: key, imageSort: this.imageAsc }, this.props().handleSortChange(key, 'ASC'));
            }
        }
        else {
            this.setField({ sortIndice: key });
            if (this.props().defaultSortDesc != undefined && this.props().defaultSortDesc) {
                //First 
                //Desc - Asc - Default
                if (this.state().currentSort == 'desc') {
                    this.setField({ currentSort: 'asc', sortIndice: key, imageSort: this.imageAsc }, this.props().handleSortChange(key, 'ASC'));
                } else if (this.state().currentSort == 'asc') {
                    this.setField({ currentSort: 'default', sortIndice: key, imageSort: this.image }, this.props().handleSortChange(-1, ''));
                } else if (this.state().currentSort == 'default') {
                    this.setField({ currentSort: 'desc', sortIndice: key, imageSort: this.imageDesc }, this.props().handleSortChange(key, 'DSC'));
                }
            }
            else {
                //Second
                //Asc - Desc - Default
                if (this.state().currentSort == 'desc') {
                    this.setField({ currentSort: 'default', sortIndice: key, imageSort: this.image }, this.props().handleSortChange(-1, ''));
                } else if (this.state().currentSort == 'asc') {
                    this.setField({ currentSort: 'desc', sortIndice: key, imageSort: this.imageDesc }, this.props().handleSortChange(key, 'DSC'));
                } else if (this.state().currentSort == 'default') {
                    this.setField({ currentSort: 'asc', sortIndice: key, imageSort: this.imageAsc }, this.props().handleSortChange(key, 'ASC'));
                }
            }
        }
    }
}