import { ContentViewModel, ContentView } from '@levelapp/softfabric';
import LegalTextsViewState from './LegalTextsViewState';
import LegalTextsViewProps from './LegalTextsViewProps';
import LegalTextsOperations from '../../../../../../../Transfer/LegalTextsOperations';
import { TableRow } from '@levelapp/softfabric-ui';
import LegalTextsPopUpView from '../../popup/legaltextspopup/LegalTextsPopUpView';
 
import LanguagesOperations from '../../../../../../../Transfer/LanguagesOperations';
import Helpers from '../../../../../../../Common/Helpers/Helpers';
import LegalText from '../../../../../../../Common/DTO/LegalText';
import Language from '../../../../../../../Common/DTO/Language';
import ToastHelper from '../../../../../../../Common/Helpers/ToastHelper';
import ErrorHelper from '../../../../../../../Common/Helpers/ErrorHelper';

export default class LegalTextsViewModel extends ContentViewModel<LegalTextsViewState, LegalTextsViewProps>
{
    operation: LegalTextsOperations;
    languagesOperation: LanguagesOperations;
    _languages: Language[] = [];
    interval: any;
    searchText: string;
    isSorting: boolean;
    indexHeaderSort: number;
    sortOrder: string;
    sortKey: string[];

    constructor(props: ContentView) {
        super(props);

        this.interval = null;
        this.searchText = '';
        this.isSorting = false;
        this.indexHeaderSort = -1;
        this.sortOrder = 'asc';
        this.sortKey = ['Id', 'Key'];

        /* Initial State */
        this.initialState({ tableData: [], tableHeaders: ['Id', 'Type'], isLoading: true, nbPage: 0, currentPage: 1, isResearching: false });
        this.operation = new LegalTextsOperations(); this.languagesOperation = new LanguagesOperations();

        this.languagesOperation.get((languages: any) => {
            this._languages = languages.data;
        });

        /* BINDINGS */
        this.editField = this.editField.bind(this);
        this.save = this.save.bind(this);
        this.onPageChange = this.onPageChange.bind(this);
        this.handleResearching = this.handleResearching.bind(this);
        this.handleCloseResearching = this.handleCloseResearching.bind(this);
        this.addField = this.addField.bind(this);
        this.errorManagement = this.errorManagement.bind(this);
    }

    componentDidMount() {
        this.refresh();
    }

    refresh() {
        this.setField({ isLoading: true });
        let parameters = [];
        parameters.push({ key: "page", value: this.state().currentPage }, { key: "itemPage", value: 20 });
        if (this.isSorting) {
            parameters.push(
                { key: 'columnSort', value: this.sortKey[this.indexHeaderSort] },
                { key: 'sortOrder', value: this.sortOrder });
            if (this.state().isResearching) {
                parameters.push({ key: "search", value: this.searchText });

            }
            this.getData(parameters);
        } else {
            if (this.state().isResearching) {
                parameters.push({ key: "search", value: this.searchText });
            }
            this.getData(parameters);
        }
    }

    getData(queryParameters: any[]) {
        this.operation.get(queryParameters, (texts: any) => {
            let tableData: TableRow[] = [];
            texts.data.objects.forEach((text: LegalText) => {
                tableData.push({ data: [text.id, text.key], isDeletable: false, isEditable: true, editCallback: () => { this.editField(text) }, deleteCallback: () => { } });
            });
            this.setField({ tableData: tableData, nbPage: texts.data.totalPage, isLoading: false });
        })
    }

    addField() {
        let text = {
            "id": 0,
            "key": "",
            "contentTranslations": Helpers.generateTranslationTable(this._languages),
            "contentId": 0,
            "content": null
        };
        this.showPopUp(
            <LegalTextsPopUpView text={Helpers.clone(text)} save={this.save} languages={this._languages} />
        );
        this.refresh();
    }

    editField(text: LegalText) {
        this.showPopUp(
            <LegalTextsPopUpView text={Helpers.clone(text)} save={this.save} languages={this._languages} />
        );
        this.refresh();
    }

    save(text: LegalText) {
        if (text.id > 0)
            this.operation.put(text, () => {
                this.refresh();
                this.showToast(ToastHelper.getToast("legaltexts.popup.success.updated", "success"), () => { }, 2500);
                this.closePopUp();
            });
        else
            this.operation.post(text,
                () => {
                    this.refresh();
                    this.showToast(ToastHelper.getToast("legaltexts.popup.success.added", "success"), () => { }, 2500);
                    this.closePopUp();
                },
                (error: any) => {
                    this.errorManagement(error);
                },
                () => {
                    alert("The service AddLegalText is not online");
                    this.closePopUp();
                }
            );
    }

    errorManagement(error: any) {
        this.closePopUp();
        ErrorHelper.showMessageError(error.response);
    }

    onPageChange(page: number) {
        this.state().currentPage = page + 1;
        this.refresh()
    }

    onSortChange(indexHeaderSort: number, sortOrder: string) {
        if (indexHeaderSort == -1) {
            this.isSorting = false;
        } else {
            this.indexHeaderSort = indexHeaderSort;
            this.sortOrder = sortOrder;
            this.isSorting = true;
        }
        this.refresh();
    }

    handleResearching(searchText: string) {
        this.setField({ isLoading: true, isResearching: true });
        this.searchText = searchText;

        if (this.interval) {
            clearInterval(this.interval);
        }
        this.interval = setTimeout(() => {
            this.onPageChange(0)
        }, 1000)
    }

    handleCloseResearching() {
        this.state().isResearching = false;
        this.onPageChange(0);
    }
}