import {ContentViewStyle} from '@levelapp/softfabric';
import {StyleProp,ViewStyle} from 'react-native';

export default class RoundButtonViewStyle extends ContentViewStyle
{
    container: StyleProp<ViewStyle> = {
        paddingHorizontal : 20 , 
        paddingBottom : 0 , 
        paddingTop : 10
    }

    block : StyleProp<any> =
    {
        borderRadius : 25 , 
        backgroundColor : 'white', 
        justifyContent : 'center' , 
        alignItems : 'center' ,
        boxShadow: '0 5px 14px 0 rgba(0,0,0,0.1)'
    }

    little : StyleProp<ViewStyle> =
    {
        height : 25,
        width : 25,
    }

    normal : StyleProp<ViewStyle> =
    {
        height : 40,
        width : 40,
    }
}