import {ContentViewStyle} from '@levelapp/softfabric';
import {StyleProp,ViewStyle} from 'react-native';

export default class StatsSettingsPopupViewStyle extends ContentViewStyle
{
    page: StyleProp<ViewStyle> = {
        flex: 1
    }

    footer: StyleProp<ViewStyle> = {
        display: 'flex',
        flexDirection: 'row',
        margin: 'auto',
        marginTop: 50,
        marginBottom: 20
    };


}