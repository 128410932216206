import {ContentViewModel,ContentView} from '@levelapp/softfabric';
import SwitchItemViewState from './SwitchItemViewState';
import SwitchItemViewProps from './SwitchItemViewProps';

export default class SwitchItemViewModel extends ContentViewModel<SwitchItemViewState, SwitchItemViewProps>
{
      /* CONSTRUCTOR */
    constructor(view: ContentView)
    {
        super(view);

        this.initialState({}); // Initialize your state here

        // Bindings

    }
}