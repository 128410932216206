 
import { View, Text, TouchableWithoutFeedback, Image } from 'react-native';
import {ContentView} from '@levelapp/softfabric';
import TagSectionViewState from './TagSectionViewState';
import TagSectionViewProps from './TagSectionViewProps';
import TagSectionViewStyle from './TagSectionViewStyle';
import TagSectionViewModel from './TagSectionViewModel';
import SectionTitleView from '../../../../../../../../components/sectiontitle/SectionTitleView';
import EditableDropDownViewProps from '../../../../../../../../../../../Common/Components/EditableDropDown/EditableDropDownView';
import SeparationLineView from '../../../../../../../../../../../Common/Components/separationline/SeparationLineView';


export default class TagSectionView extends ContentView<TagSectionViewProps, TagSectionViewState, TagSectionViewModel, TagSectionViewStyle>
{
  
  /* CONSTRUCTOR */
  constructor(props:TagSectionViewProps)
  {
    super(props);
    this.bind(new TagSectionViewModel(this), new TagSectionViewStyle(this));
  }

  

  renderRewardTag(item: {id:number, key: string, title: string}): JSX.Element {    
    return (
      <View key={`${item.id}-${item.key}`}>
        <TouchableWithoutFeedback onPress={()=>{this.binding.removeTag(item)}}>
          <View style={this.style.tag}>
            <Text style={this.style.tagText}>{item.title}</Text>
            <Image style={this.style.tagIcon} source={require('../../../../../../assets/clear.svg').default}></Image>
          </View>
        </TouchableWithoutFeedback>
      </View>
    )
  }

  render(): JSX.Element
  {
    const usedRewardTag = this.binding.getLinkedTags();
    return (
      <View>
        <SeparationLineView />
        <SectionTitleView title={'form.tags'} />
        <EditableDropDownViewProps values={this.binding.getFormattedRewardTags(this.props.tags)} onValidation={this.binding.addTag} blockedValues={usedRewardTag}/>   
        <View style={this.style.tags}>
          {
            usedRewardTag.map(rewardTag => this.renderRewardTag(rewardTag))       
          }
        </View>
      </View>
    );
  }
}