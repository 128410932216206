import { ContentViewStyle, Application } from '@levelapp/softfabric';
import { TextStyle, StyleProp, ViewStyle } from 'react-native';
import { StyleManager, UiManagers } from '@levelapp/softfabric-ui';

export default class UsersPopUpViewStyle extends ContentViewStyle {
    MyStyleManager: StyleManager = Application.current.resolve<StyleManager>(UiManagers.STYLE_MANAGER);

    title: StyleProp<any> = {
        textAlign: 'left',
        color: 'white',
        fontSize: 24,
        fontWeight: '600',
        marginTop: 20,
        marginLeft: 60,
        paddingBottom: 20,
        display: "flex",
        flexDirection: "column",
        flexBasis: "85%"
    };

    subTitle: StyleProp<TextStyle> = {
        fontSize: 17,
        color: 'black',
        paddingTop: 2,
        marginLeft: 54,
        marginBottom: 10,
        fontWeight: '100'
    }

    label: StyleProp<TextStyle> = {
        fontSize: 16,
        fontWeight: '600',
        color: '#525f7f',
        marginBottom: 10
    };

    section: StyleProp<ViewStyle> = {
        marginTop: 50,
        marginLeft: 270,
        
    };

    radioContainer: StyleProp<ViewStyle> = {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        marginTop: 5,
        marginBottom: 5
    };

    radioLabel: StyleProp<TextStyle> = {
        fontSize: 16,
        fontWeight: '600',
        color: '#8898aa',
        marginLeft: 10
    };

    translationContainer: StyleProp<ViewStyle> = {
        marginTop: 10,
        marginBottom: 10
    };

    footer: StyleProp<ViewStyle> = {
        display: 'flex',
        flexDirection: 'row',
        margin: 'auto',
        marginTop: 50,
        marginBottom: 20
    };

    input: StyleProp<any> = {

        paddingHorizontal: 15,
        borderRadius: 8,
        boxShadow: '0 5px 14px 0 rgba(0,0,0,0.1)',
    };

    inputTitle: StyleProp<TextStyle> = {
        fontSize: 14,
        fontWeight: '600',
        color: '#3F3F3F',
        fontFamily: 'Montserrat SemiBold',
        marginBottom: 13,
    };

    textArea: StyleProp<ViewStyle> = {
        width: 300,
        height: 92,
        marginTop: 10,
        borderLeftColor: "#32325D26",
        borderLeftWidth: 1,
        borderTopColor: "#32325D26",
        borderTopWidth: 1,
    };

    errorInput: StyleProp<ViewStyle> = {
        borderColor: 'red',
        borderWidth: 1,
        width: 300,
    };

    step: StyleProp<ViewStyle> = {
        display: "flex",
        flexDirection: "row",
        flexWrap: "wrap"
    };

   

    saveButton: StyleProp<TextStyle> = {
        color: this.MyStyleManager.primaryColor,
        fontSize: 10,
        fontWeight: '600',
        height: 27,
        width: 112
    };
    subHeading: StyleProp<TextStyle> = {
        fontSize: 19,
    };
    

    titlePopup: StyleProp<TextStyle> = {
        textAlign: 'center',
        color: '#4A495C',
        fontSize: 21,
        fontWeight: '600',
        marginTop: 100
    }
    YesButton: StyleProp<ViewStyle> = {
        height: 42,
        width: 119,
        marginLeft: '5px'
    };

    test : StyleProp<any> =
    {
        position : 'fixed',
        zIndex : 100,
        width : '100%'
    }
    
  
    

}