import {ContentShellViewModel, ContentShellView} from '@levelapp/softfabric';
import ShellNewsViewState from './ShellNewsViewState';
import ShellNewsViewProps from './ShellNewsViewProps';
import NewsView from './pages/news/NewsView';
import EnumModules from '../../../../../Common/Constants/EnumModules';
 

export default class ShellNewsViewModel extends ContentShellViewModel<ShellNewsViewState, ShellNewsViewProps>
{
    constructor(view : ContentShellView)
    {
        super(view);

        this.registerEntryPoint('main');
        this.register(<NewsView/> ,'main');

        this.initialisation();
    }

    getModuleId()
    {
        return EnumModules.NEWS;
    }
}