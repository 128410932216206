import {ContentShellViewModel, ContentShellView} from '@levelapp/softfabric';
import ShellProductsViewState from './ShellProductsViewState';
import ShellProductsViewProps from './ShellProductsViewProps';
 
import EnumModules from '../../../../../Common/Constants/EnumModules';
import ProductsView from './pages/products/ProductsView';

export default class ShellProductsViewModel extends ContentShellViewModel<ShellProductsViewState, ShellProductsViewProps>
{

    constructor(view : ContentShellView)
    {
        super(view);

        this.registerEntryPoint('main'); 
        this.register(<ProductsView/> ,'main');

        this.initialisation();
    }

    getModuleId()
    {
        return EnumModules.PRODUCTS;
    }

}