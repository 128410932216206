import {ContentViewStyle} from '@levelapp/softfabric';
import {StyleProp,TextStyle,ViewStyle} from 'react-native';

export default class LogHistoryPopupViewStyle extends ContentViewStyle
{
    container: StyleProp<ViewStyle> = {
        backgroundColor: 'white',
        width: 515
    };

    header: StyleProp<ViewStyle> = {
        height: 110,
        backgroundColor: '#F6F6F6',
        alignItems : 'center',
        flexDirection : 'row',
        paddingVertical: 25,
        paddingHorizontal: 40
    };

    formtitle : StyleProp<TextStyle> =
    {
        fontWeight : 'bold',
        fontSize : 16,
    }

    title: StyleProp<TextStyle> = {
        color: '#4A4A4A',
        fontSize: 25
    };

    historyContainer: StyleProp<ViewStyle> = {
        paddingTop: 30,
        paddingBottom: 45,
        paddingHorizontal: 8
    };

    history: StyleProp<ViewStyle> = {
        flexDirection: 'row'
    };

    leftHistory: StyleProp<ViewStyle> = {
        paddingHorizontal: 26,
        alignItems: 'center'
    };

    middleHistory: StyleProp<ViewStyle> = {
        marginTop: -5,
    };

    rightHistory: StyleProp<ViewStyle> = {
        flex: 1,
        marginTop: -4,
    };

    bubble: StyleProp<ViewStyle> = {
        backgroundColor: '#CCCCCC',
        height: 12,
        width: 12,
        borderRadius: 6
    };

    line: StyleProp<ViewStyle> = {
        backgroundColor: '#CCCCCC',
        width: 2,
        height: '100%'
    };

    status: StyleProp<ViewStyle> = {
        height: 45,
        width: 150,
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: 3,
        marginTop: 8
    };
}