import {Application, ContentViewStyle} from '@levelapp/softfabric';
import { StyleManager, UiManagers } from '@levelapp/softfabric-ui';
import {StyleProp,TextStyle,ViewStyle} from 'react-native';

export default class HistorySectionViewStyle extends ContentViewStyle
{
    MyStyleManager: StyleManager = Application.current.resolve<StyleManager>(UiManagers.STYLE_MANAGER);

    page: StyleProp<ViewStyle> = {
        flex: 1
    }


    tableHeader: StyleProp<TextStyle> =
    {
        color: '#575757',//575757
        fontSize: 15,
        fontFamily: 'Montserrat',
        fontWeight: 'bold',
    }

    stepColor: StyleProp<ViewStyle> =
    {
        backgroundColor: this.MyStyleManager.primaryColor,
    }

    boxContainer: StyleProp<ViewStyle> = {
        display: 'flex',
        alignSelf: 'center',
        minHeight: 50,
        width: '100%'
    }

    addedPoints: StyleProp<ViewStyle> = {
        alignSelf: 'flex-start',
        marginVertical: 15,
    };

    textArea: StyleProp<any> = {
        width: '100%',
        minWidth: 675,
        minHeight : 200,
        height : '100%',
        paddingHorizontal: 5,
        marginTop: 15,
        borderRadius : 8,
        boxShadow: '0 5px 14px 0 rgba(0,0,0,0.1)',
    };

    noMessage: StyleProp<any> = {
        width: '100%',
        height: '100%',
    }
}