import { ContentViewModel, ContentView } from '@levelapp/softfabric';
import PaymentSourcesViewState from './PaymentSourcesViewState';
import PaymentSourcesViewProps from './PaymentSourcesViewProps';
import { OrderSources } from '../../../../../../../../../Common/Enums/OrderSources';
import { PaymentSources } from '../../../../../../../../../Common/Enums/PaymentSources';

export default class PaymentSourcesViewModel extends ContentViewModel<PaymentSourcesViewState, PaymentSourcesViewProps>
{
    /* CONSTRUCTOR */
    constructor(view: ContentView)
    {
        super(view);

        this.initialState({}); // Initialize your state here

        // Bindings
        this.isSelected = this.isSelected.bind(this);
    }

    isSelected(orderSource: OrderSources, paymentSource: PaymentSources)
    {
        const { storePaymentSources } = this.props();
        return storePaymentSources.find(x => x.orderSourceId == orderSource && x.paymentSourceId == paymentSource) != undefined;
    }
}