 
import {ContentShellView} from '@levelapp/softfabric';
import ShellStatsViewState from './ShellStatsViewState';
import ShellStatsViewProps from './ShellStatsViewProps';
import ShellStatsViewStyle from './ShellStatsViewStyle';
import ShellStatsViewModel from './ShellStatsViewModel';
import { View } from 'react-native';

export default class ShellStatsView extends ContentShellView<ShellStatsViewProps, ShellStatsViewState, ShellStatsViewModel, ShellStatsViewStyle>
{
  /* CONSTRUCTOR */
  constructor(props:ShellStatsViewProps)
  {
    super(props);

    // Binding
    this.bind(new ShellStatsViewModel(this), new ShellStatsViewStyle(this));
  }

  /* RENDERING */
  render()
  {
    return (
      <View style={{flex : 1}}>
        {this.getSwitch()}
      </View>
    );
  }
}