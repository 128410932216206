import { ContentView } from '@levelapp/softfabric';
import AdditionalInfosViewState from './AdditionalInfosViewState';
import AdditionalInfosViewProps from './AdditionalInfosViewProps';
import AdditionalInfosViewStyle from './AdditionalInfosViewStyle';
import AdditionalInfosViewModel from './AdditionalInfosViewModel';
import { TranslationView } from '@levelapp/softfabric-ui';
import { View, TouchableOpacity } from 'react-native';
import ExtendedTextInputView from 'src/Presentation/modules/dashboard/components/extendedtextinput/ExtendedTextInputView';
import ExtendedDropDownView from '../../../../../../components/extendeddropdown/ExtendedDropDownView';
import ImageButtonView from 'src/Common/Components/imagebutton/ImageButtonView';

export default class AdditionalInfosView extends ContentView<AdditionalInfosViewProps, AdditionalInfosViewState, AdditionalInfosViewModel, AdditionalInfosViewStyle>
{
  /* CONSTRUCTOR */
  constructor(props: AdditionalInfosViewProps) {
    super(props);

    // Binding
    this.bind(new AdditionalInfosViewModel(this), new AdditionalInfosViewStyle(this));
  }

  /* RENDERING */
  render() {
    return (
      <View style={{ display: "flex", flexDirection: "row", flexWrap: "wrap", justifyContent: 'space-between', paddingHorizontal: 170, paddingVertical: 50 }}>
        <View style={this.style.section}>
          <View>
            {this.state.additionalInfos.map((info: any, i: any) => {
              console.log(this.state.infos)
              console.log(info)
              return (
                <View style={{ flexDirection: "row" }}>
                  <View>
                    <TranslationView style={this.style.inputTitle} upperCase>additionalInfos.type.title</TranslationView>
                    <ExtendedDropDownView
                      values={this.state.types}
                      selectedValue={this.state.types.find(x => x.key == info.additionalInfoType.key)}                      
                      onValueChange={(value) => this.binding.handleDropDownTypeChange(value, i)}                    
                    />
                  </View>
                  <View>
                    <TranslationView style={this.style.inputTitle} upperCase>additionalInfos.title</TranslationView>
                    <ExtendedDropDownView
                      values={this.state.infos}                      
                      selectedValue={this.state.infos.find(x => x.title == info.additionalInfo.name.translationValues[0].value)}
                      onValueChange={(value) => this.binding.handleDropDownInfoChange(value, i)}
                    />
                  </View>
                  <ExtendedTextInputView
                    title={"form.settings.value"}
                    showError={false}
                    onChangeText={(value: any) => this.binding.handleValueChanged(value, i)}
                    value={info.value} />
                    <ImageButtonView                         
                          image={require('./assets/delete.svg').default}
                          onClick={() => this.binding.delete(i)}
                          imageHeight={24}
                          imageWidth={24}
                          style={{ padding: 3, marginTop: 35 }} />
                </View>
              );
            })}
            <TouchableOpacity
              onPress={this.binding.add}
              style={this.style.saveButton}
              disabled={false}>
              <TranslationView style={{ color: 'white' }}>
                generic.add
              </TranslationView>
            </TouchableOpacity>
          </View>
        </View>
      </View>
    );
  }
}