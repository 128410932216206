 
import { ContentView } from '@levelapp/softfabric';
import DeliveryProviderPopupViewState from './DeliveryProviderPopupViewState';
import DeliveryProviderPopupViewProps from './DeliveryProviderPopupViewProps';
import DeliveryProviderPopupViewStyle from './DeliveryProviderPopupViewStyle';
import DeliveryProviderPopupViewModel from './DeliveryProviderPopupViewModel';
import { View, TouchableWithoutFeedback, Image, Text, TextInput } from 'react-native';
import Constants from '../../../../../../../../../Common/Constants/Constants';
import LinearGradient from "react-native-web-linear-gradient";
import { TranslationView } from '@levelapp/softfabric-ui';
import ImageButton from '../../../../../../../../../Common/Components/imagebutton/ImageButtonView'
import ButtonView from '../../../../../../../../../Common/Components/Button/ButtonView';
import Helpers from '../../../../../../../../../Common/Helpers/Helpers';



export default class DeliveryProviderPopupView extends ContentView<DeliveryProviderPopupViewProps, DeliveryProviderPopupViewState, DeliveryProviderPopupViewModel, DeliveryProviderPopupViewStyle>
{
  /* CONSTRUCTOR */
  constructor(props: DeliveryProviderPopupViewProps) {
    super(props);

    // Binding
    this.bind(new DeliveryProviderPopupViewModel(this), new DeliveryProviderPopupViewStyle(this));
  }
  /* RENDERING */
  render() {
    return (
      <View style={{ minWidth: 600, minHeight: 850, width: Constants.PopUpWidth, maxWidth: "100%" }}>

        {/* Popup information/confirmation */}
        {this.state.showPopup &&
          <View style={{ position: 'absolute', width: '100%', height: '100%', zIndex: 500, justifyContent: 'center', alignItems: 'center' }}>
            <TouchableWithoutFeedback onPress={() => this.binding.handleBackPopupPressed()}>
              <View style={{ width: '100%', height: '100%', opacity: 0.5, backgroundColor: 'black' }}></View>
            </TouchableWithoutFeedback>
            <View style={{ position: 'absolute', width: 600, height: 350, backgroundColor: 'white', zIndex: 600, borderRadius: 8 }}>
              <TranslationView style={this.style.titlePopup}>{this.state.titlePopup}</TranslationView><Text style={this.style.contentPopup}>{this.state.contentPopup}</Text>

              {this.state.confirmationPopup ?
                <View style={this.style.footer}>
                  <ButtonView isLoading={false} handleClick={() => this.binding.handleBackPopupPressed()} backgroundColor="#CCCCCC" style={this.style.NoButton}>
                    <TranslationView upperCase style={this.style.NoButtonText}>generic.cancel</TranslationView>
                  </ButtonView>
                  <ButtonView isLoading={false} handleClick={() => { this.binding.deleteProvider() }} backgroundColor={Constants.MainColor} style={this.style.YesButton}>
                    <TranslationView upperCase>generic.delete</TranslationView>
                  </ButtonView>
                </View>
                :
                <View style={this.style.footer}>
                  <ButtonView isLoading={false} handleClick={() => this.binding.handleBackPopupPressed()} backgroundColor={Constants.MainColor} style={this.style.YesButton}>
                    <TranslationView upperCase>generic.ok</TranslationView>
                  </ButtonView>
                </View>
              }
            </View>
          </View>
        }

        {/* Color Box */}
        <View>
          <LinearGradient start={{ x: 0, y: 0 }} end={{ x: 1, y: 0 }} colors={[Constants.GradiantStartColor, Constants.GradiantEndColor]} >
            <View style={{ display: "flex", flexDirection: "row", justifyContent: 'space-between' }}>
              <TranslationView style={this.style.title}>
                providers.popup.title
            </TranslationView>
              <TouchableWithoutFeedback onPress={this.binding.handleBackPressed} >
                <Image style={{ height: 23, width: 23, resizeMode: 'contain', marginTop: 25, marginRight: 30 }}
                  source={{ uri: 'https://appstoragflyx.blob.core.windows.net/app/Console/exit.png' }}></Image>
              </TouchableWithoutFeedback>
            </View>
          </LinearGradient>
        </View>

        <View style={{ display: "flex", flexDirection: "column", paddingHorizontal: 170, paddingVertical: 50 }}>
          <View style={{ flex: 1, maxHeight: 225, flexWrap: 'wrap' }}>
            {this.state.list.map((content: any) => {
              return (
                <View style={{ flexDirection: 'row', marginBottom: 10 }}>
                  <Text style={this.style.inputTitle}>{content.name}</Text>
                  <ImageButton image={require('../../assets/erase.svg').default} imageHeight={20} imageWidth={20} onClick={() => { this.binding.handleDeleteProvider(content) }} style={this.style.deleteButton} />
                </View>
              );
            })}
          </View>

          {/* Line */}
          <View style={{ zIndex: -1, backgroundColor: '#D8D8D8', width: '100%', height: 1, marginTop: 30, marginBottom: 50 }}></View>

          <Text style={{
            fontSize: 25,
            fontWeight: '600',
            color: '#3F3F3F',
            fontFamily: 'Montserrat SemiBold',
            marginBottom: 13,
          }}><TranslationView upperCase>form.providercreation</TranslationView></Text>

          <View style={{ display: "flex", flexDirection: "row", flexWrap: "wrap" }}>

            <View style={this.style.section}>
              <TextInput
                placeholder='Provider name'
                style={this.state.showErrors && (this.state.provider.name == "") ? [this.style.errorInput, { marginRight: 10 }] : [this.style.input, { marginRight: 10 }]}
                onChangeText={this.binding.handleProviderNameChanged}
                value={this.state.provider.name} />
            </View>
          </View>

          <View style={{ marginBottom: 20, width : 140 }}>
            <Text style={[this.style.inputTitle, { marginBottom: 10 }]}>Logo</Text>
             <label >
              <img style={{ cursor: "default" }} width="140" height="100" src={this.state.provider.imageTranslations[0].url} />
              <input type="file" style={{ display : "none"}}  onChange={async (e) => 
                {
                  if(e.target.files != undefined && e.target.files.length > 0)
                  {
                    var base =  Helpers.getBase64((e.target.files as any)[0]);
                    base.then((result) => this.binding.handleImage(result));
                  }              
                  }} />
            </label>
          </View>
          {this.state.showErrors && !this.binding.isSelectedImage() &&
            <View>
              <TranslationView style={{ color: "red" }}>error.please.images</TranslationView>
            </View>
          }

          <View style={this.style.createButton}>
            <ButtonView handleClick={this.binding.handleCreateProvider} isLoading={this.state.isSendingProvider} backgroundColor='#2196f3'>
              <TranslationView>providers.popup.button</TranslationView>
            </ButtonView>
          </View>
        </View>
      </View>
    );
  }
}