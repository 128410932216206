import { Service, Application, HttpManager , AuthManager, Managers } from '@levelapp/softfabric';
import Constants from '../Common/Constants/Constants';

export default class LoginService extends Service {
    httpClient: HttpManager;
    storage: StorageManager;
    auth: AuthManager;

    constructor() {
        super();

        this.httpClient = Application.current.resolve<HttpManager>(Managers.AUTH_MANAGER);
        this.httpClient.handleSomethingWentWrong = (error: any) => { console.warn('Erreur ' + JSON.stringify(error)) }

        this.storage = Application.current.resolve(Managers.STORAGE_MANAGER);
        this.auth = Application.current.resolve(Managers.AUTH_MANAGER);
    }

    async post(credentials: any, onSuccess: Function, onFailure: Function) {
      let headers:any = {
        'Content-Type': 'application/x-www-form-urlencoded'
      };

      if(Constants.UseTenant){
        headers = { ...headers, "tenant-id": Constants.TenantId };
      }
        fetch(`${Constants.RessourcesWithoutApi}/Connect/Token`, {
            method: 'POST',
            headers,
            body: `grant_type=password&username=${credentials.email}&` +
                `password=${credentials.password}&` +
                `client_id=admin&` +
                `client_secret=${Constants.IAMClientSecret}&` +
                `scope=admin_api identity_api delivery_api offline_access openid`
        })
            .then(async response => 
                {
                    if(response.status != 200)
                    {
                        throw response;
                    }
                    return response.json()
                })
            .then((json: any) => {
                if (json.access_token !== undefined) {
                    //console.log(json.access_token);
                    // Storing the response (token and refresh) on disk
                    this.auth
                        .setToken(json, (success: boolean) => {             
                            onSuccess(success);
                        });
                }
                else {
                    onFailure();
                }
            })
            .catch((error) => {

                onFailure(error);
            });
    }
}