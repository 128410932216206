import { StyleProp, TextStyle, ViewStyle } from 'react-native';
import { ContentViewStyle } from '@levelapp/softfabric';

export default class AvailabilityLogsTabViewStyle extends ContentViewStyle {
  row: StyleProp<ViewStyle> = {
    display: 'flex',
    flexDirection: 'row',
    marginTop: 20,
    marginLeft: 35,
    paddingBottom: 20,
    borderBottomWidth: 2,
    borderColor: '#E8E7E7',
  }

  content: StyleProp<ViewStyle> = {
    marginTop: 30,
  }

  text: StyleProp<TextStyle> = {
    flex: 1,
  }
}