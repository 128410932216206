import { Operation } from "@levelapp/softfabric";
import ProductsService from "../Proxy/ProductsService";
import Product from "../Common/DTO/Product";

export default class ProductsOperations extends Operation
{
    service : ProductsService;

    constructor()
    {
        super();
        this.service = new ProductsService();
    }

    getLight() {
      return this.service.getLight();
    }
    
    get(callBack:Function, onFailure: Function){
        this.service.get(callBack,onFailure);
    }
    
    getProductsBasicInfo(parameters:any[],callBack:Function, onFailure: Function){
        this.service.getProductsBasicInfo(parameters,callBack,onFailure);
    }

    getTable(parameters: any[], callBack:Function, onFailure: Function){
        this.service.getTable(parameters,callBack,onFailure);
    }

    post(product: Product, onSuccess: Function, onFailure: Function){
        this.service.post(product, onSuccess,onFailure);
    }

    put(product: Product, onSuccess: Function, onFailure: Function){
        this.service.put(product, onSuccess,onFailure);
    }

    delete(product: Product, onSuccess: Function, onFailure: Function){
        this.service.delete(product, onSuccess,onFailure);
    }
}