import { ContentViewModel, ContentView } from '@levelapp/softfabric';
import NewsPopUpViewState from './NewsPopUpViewState';
import NewsPopUpViewProps from './NewsPopUpViewProps';
import Helpers from '../../../../../../../Common/Helpers/Helpers';
import DispatcherManager from '../../../../../../../Common/Managers/DispatcherManager';
import TranslationValue from '../../../../../../../Common/DTO/TranslationValue';
import TranslationHelper from '../../../../../../../Common/Helpers/TranslationHelper';
import Constants from '../../../../../../../Common/Constants/Constants';
import Country from '../../../../../../../Common/DTO/Country';
import CountryNews from '../../../../../../../Common/DTO/M&N/CountryNews';
import DateHelper from '../../../../../../../Common/Helpers/DateHelper';
import ToastHelper from '../../../../../../../Common/Helpers/ToastHelper';
import NewsOperations from '../../../../../../../Transfer/NewsOperations';
import ErrorHelper from '../../../../../../../Common/Helpers/ErrorHelper';
import NumberHelper from 'src/Common/Helpers/NumberHelper';
import StoresOperations from '../../../../../../../Transfer/StoresOperations';
import Store from '../../../../../../../Common/DTO/Store';


export default class NewsPopUpViewModel extends ContentViewModel<NewsPopUpViewState, NewsPopUpViewProps>
{
    _dispatcherManager : DispatcherManager;
    _newsOperations: NewsOperations;
    _storesOperations: StoresOperations

    constructor(props: ContentView) {
        super(props);
        /* Initial State */
        this.initialState({
            news: this.props().news,
            languages: this.props().languages,
            showErrors: false,
            index: 0,
            indexThumbnail: 0,
            useDefaultImageChecked: Helpers.hasDuplicates(this.props().news.imageTranslation),
            useDefaultThumbnailChecked: Helpers.hasDuplicates(this.props().news.thumbnailTranslation),
            tab: "main",
            isLoading: false,
            color: Constants.MainColor,
            selectedCountries: [],
            isStoresLoading: true,
            stores: [],
        });

        if(this.props().news.countryNews != undefined)
        {
           this.state().selectedCountries = this.props().countries.filter((country:Country) => this.props().news.countryNews.find((x:CountryNews) => x.countryId == country.id) != undefined)
        }

        /* BINDINGS */
        this.handleSavePressed = this.handleSavePressed.bind(this);
        this.handlePointsToEarnChanged = this.handlePointsToEarnChanged.bind(this);
        this.handleTitleChanged = this.handleTitleChanged.bind(this);
        this.handleDescriptionChanged = this.handleDescriptionChanged.bind(this);
        this.handleImage = this.handleImage.bind(this);
        this.setIndexImage = this.setIndexImage.bind(this);
        this.setIndexThumbnail = this.setIndexThumbnail.bind(this);
        this.handleThumbnail = this.handleThumbnail.bind(this);
        this.handleDefaultCheckBoxChanged = this.handleDefaultCheckBoxChanged.bind(this);
        this.handleDefaultCheckBoxThumbnailChanged = this.handleDefaultCheckBoxThumbnailChanged.bind(this);
        this.changeTab = this.changeTab.bind(this);
        this.handleStartDateChanged = this.handleStartDateChanged.bind(this);
        this.handleEndDateChanged = this.handleEndDateChanged.bind(this);
        this.handleInboxChanged = this.handleInboxChanged.bind(this);
        this.handleHomeChanged = this.handleHomeChanged.bind(this);
        this.handleListChanged = this.handleListChanged.bind(this);
        this.handleUrlChanged = this.handleUrlChanged.bind(this);
        this.handleAnonymousChanged = this.handleAnonymousChanged.bind(this);
        this.handlePreviewChanged = this.handlePreviewChanged.bind(this);
        this.handleCountryChanged = this.handleCountryChanged.bind(this);
        this.handleStoreChanged = this.handleStoreChanged.bind(this);
        this.errorManagement = this.errorManagement.bind(this);
        this.handleOrderIndexChanged = this.handleOrderIndexChanged.bind(this);
        this.handleIsPriorityCheckBoxChanged = this.handleIsPriorityCheckBoxChanged.bind(this);

        this._dispatcherManager = new DispatcherManager();
        this._newsOperations = new NewsOperations();
        this._storesOperations = new StoresOperations();
    }

    /* COMPONENT EVENTS */
    componentWillMount() { }

    componentDidMount()
    {
        this._dispatcherManager.checkboxPreview = this.handlePreviewChanged;
        this._dispatcherManager.checkboxHome = this.handleHomeChanged;
        this._dispatcherManager.checkboxList = this.handleListChanged;
        this._dispatcherManager.checkboxInbox = this.handleInboxChanged;
        this._dispatcherManager.checkboxAnonymous = this.handleAnonymousChanged;
        this._dispatcherManager.checkboxDefaultImage = this.handleDefaultCheckBoxChanged;
        this._dispatcherManager.checkboxDefaultThumbnail = this.handleDefaultCheckBoxThumbnailChanged;
        this._dispatcherManager.checkBoxIsPriotity = this.handleIsPriorityCheckBoxChanged;
 
        this._dispatcherManager.valuePointsToEarn = this.handlePointsToEarnChanged;
        this._dispatcherManager.valueOrderIndexPriority = this.handleOrderIndexChanged;

        this._dispatcherManager.translationURL = this.handleUrlChanged;
        this._dispatcherManager.translationTitle = this.handleTitleChanged;
        this._dispatcherManager.translationDescription = this.handleDescriptionChanged;

        this._dispatcherManager.numberIndexImage = this.setIndexImage;
        this._dispatcherManager.numberIndexThumbnail = this.setIndexThumbnail;

        this._dispatcherManager.uriImage = this.handleImage;
        this._dispatcherManager.uriThumbnail = this.handleThumbnail;

        this._dispatcherManager.dateStartDate = this.handleStartDateChanged;
        this._dispatcherManager.dateEndDate = this.handleEndDateChanged;

        this._dispatcherManager.valueCountry = this.handleCountryChanged;
        this._dispatcherManager.valueStore = this.handleStoreChanged

        this._storesOperations.getallwithouttranslations((stores: any) => {
          this.setField({ stores: stores.data, isStoresLoading: false });
        });
    }

    /* OTHERS  */

    handleSavePressed() {
        if (
            this.state().news.titleTranslations.length != this.state().languages.length ||
            this.state().news.descriptionTranslations.length != this.state().languages.length ||
            !Helpers.areImagesComplete(this.state().news.imageTranslation) ||
            !Helpers.areImagesComplete(this.state().news.thumbnailTranslation)
            ) {
            this.setField((previousState) => previousState.showErrors = true);
        }
        else {
            this.setField((previousState) => previousState.isLoading = true,() => this.saveNews(this.state().news) );
        }
    }

    saveNews(news: any) {
        var startResult = DateHelper.changeDateToBeValidUTC(news.StartDateInput);      
        var endResult = DateHelper.changeDateToBeValidUTC(news.EndDateInput);
        news.startDate = startResult;
        news.endDate = endResult;
        for (var i = 0; i < this.props().languages.length; i++) {
            if (news.imageTranslation[i].url.includes("data:image"))
                news.imageTranslation[i].ImageData = news.imageTranslation[i].url.replace("data:image/png;base64,", "").replace("data:image/jpeg;base64,", "");
            else
                news.imageTranslation[i].ImageData = null;

            if (news.thumbnailTranslation[i].url.includes("data:image"))
                news.thumbnailTranslation[i].ImageData = news.thumbnailTranslation[i].url.replace("data:image/png;base64,", "").replace("data:image/jpeg;base64,", "");
            else
                news.thumbnailTranslation[i].ImageData = null;
        }

        if (news.id !== undefined) {
            this._newsOperations.put(news, () => {
                this.props().refreshParent();
                this.showToast(ToastHelper.getToast("news.popup.success.updated","success"),() => {},2500);
                this.closePopUp();
            },this.errorManagement);
        }
        else {
            this._newsOperations.post(news, () => {
                this.props().refreshParent();
                this.showToast(ToastHelper.getToast("news.popup.success.added","success"),() => {},2500);
                this.closePopUp();
            },this.errorManagement);
        }
    }

    errorManagement(error : any) 
    {
        this.setField({isLoading : false});
        ErrorHelper.showMessageError(error.response);
    }

    fillAllPicturesWithDefault() {
        if (this.state().useDefaultImageChecked) {
            var list = Helpers.fillAllImagesWithDefault(this.state().news.imageTranslation,this.state().languages) as TranslationValue[];
            this.setField((previousState) => previousState.news.imageTranslation = list);
        }
    }

    fillAllThumbnailsWithDefault() {
        if (this.state().useDefaultThumbnailChecked) {
            var list = Helpers.fillAllImagesWithDefault(this.state().news.thumbnailTranslation,this.state().languages) as TranslationValue[];
            this.setField((previousState) => previousState.news.thumbnailTranslation = list);

        }
    }

    changeTab(tab: any) {
        this.setField((previousState) => previousState.tab = tab);
    }

    /* VALUES */

    private handlePointsToEarnChanged(text: string) {
        this.setField((previousState) => previousState.news.pointsToEarn = text);
    }

    private handleOrderIndexChanged(text : string)
    {
        var number = NumberHelper.convertStringToInt(text);
        this.setField((previousState) => previousState.news.priority = number);
    }

    /* TRANSLATION */

    private handleTitleChanged(text: any, index: any) {
        var state = this.state();
        TranslationHelper.translation(state.news.titleTranslations,state.languages,index,text);
        this.setField({state});
    }

    private handleUrlChanged(text: any, index: any) {
        var state = this.state();
        TranslationHelper.translation(state.news.urlTranslation,state.languages,index,text);
        this.setField({state});
    }

    private handleDescriptionChanged(text: any, index: any) {
        var state = this.state();
        TranslationHelper.translation(state.news.descriptionTranslations,state.languages,index,text);
        this.setField({state});
    }

    /* INDEX IMAGE */

    private setIndexImage(index: any) {
        this.setField((previousState) => previousState.index = index);
    }

    private setIndexThumbnail(index: any) {
        this.setField((previousState) => previousState.indexThumbnail = index);
    }

    /* URI IMAGES */

    private handleImage(base64 : any) {
        var state = this.state();
        TranslationHelper.imageTranslation(state.news.imageTranslation,state.languages,state.index,base64);
        this.setField({state});
        this.fillAllPicturesWithDefault();
    }

    private handleThumbnail(base64 : any) {
        var state = this.state();
        TranslationHelper.imageTranslation(state.news.thumbnailTranslation,state.languages,state.indexThumbnail,base64);
        this.setField({state});
        this.fillAllThumbnailsWithDefault();
    }

    /* CHECKBOX */

    private handleDefaultCheckBoxChanged(value : boolean) {
        this.setField((previousState) => previousState.useDefaultImageChecked = value, () => {
            this.fillAllPicturesWithDefault();
        });
    }
    
    private handleDefaultCheckBoxThumbnailChanged(value : boolean) {
        this.setField((previousState) => previousState.useDefaultThumbnailChecked = value, () => {
            this.fillAllThumbnailsWithDefault();
        });
    }

    private handleAnonymousChanged(value: boolean) {
        this.setField((previousState) => previousState.news.isAnonymousVisible = value);
    }

    private handleIsPriorityCheckBoxChanged(value : boolean) {
        this.setField((previousState) => previousState.news.priority = value ? 1 : 0);
    }
    
    /* DATES */
    
    private handleStartDateChanged(text: Date) {
        this.setField((previousState) => previousState.news.StartDateInput = text);
    }

    private handleEndDateChanged(text: Date) {
        this.setField((previousState) => previousState.news.EndDateInput = text);
    }

    

    private handleInboxChanged(value: boolean) {
        this.setField((previousState) => previousState.news.isDisplayInInbox = value);
    }

    private handleHomeChanged(value: boolean) {
        this.setField((previousState) => previousState.news.isDisplayInHome = value);
    }

    private handleListChanged(value: boolean) {
        this.setField((previousState) => previousState.news.isDisplayInList = value);
    }

    private handlePreviewChanged(value : boolean) {
        this.setField((previousState) => previousState.news.isPreview = value);
    }
    
    private handleCountryChanged(value: Country[]) {
        var state = this.state();
        state.news.countryNews = [];

        value.forEach((country : Country) => {
            state.news.countryNews.push({ countryId : country.id, newsId : state.news.id});
        })

        this.setField({state});
    }

    private handleStoreChanged(value:Store[]) {
      this.setField({
        ...this.state(),
        news:{
          ...this.state().news,
          storeIds: value.map(x => x.id)
        }
      })
    }
}
