 
import {ContentView} from '@levelapp/softfabric';
import MyWysiwygViewState from './MyWysiwygViewState';
import MyWysiwygViewProps from './MyWysiwygViewProps';
import MyWysiwygViewStyle from './MyWysiwygViewStyle';
import MyWysiwygViewModel from './MyWysiwygViewModel';
import { TranslationView } from '@levelapp/softfabric-ui';
import { Platform } from 'react-native';

export default class MyWysiwygView extends ContentView<MyWysiwygViewProps, MyWysiwygViewState, MyWysiwygViewModel, MyWysiwygViewStyle>
{
  /* CONSTRUCTOR */
  constructor(props:MyWysiwygViewProps)
  {
    super(props);

    // Binding
    this.bind(new MyWysiwygViewModel(this), new MyWysiwygViewStyle(this));
  }

  /* RENDERING */
  render()
  {
    if(Platform.OS == "web")
    {      
      var MyWysiwygViewWebView = require('./modules/mywysiwygviewweb/MyWysiwygViewWebView').default;
      return (
       <MyWysiwygViewWebView onChangeText={this.props.onChangeText} text={this.props.text}></MyWysiwygViewWebView>
      );
      
    }
      return (
        <TranslationView>Other</TranslationView>
      );
  }
}