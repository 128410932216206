import { ContentViewModel, ContentView } from '@levelapp/softfabric';
import RemoveZoneViewState from './RemoveZoneViewState';
import RemoveZoneViewProps from './RemoveZoneViewProps';

export default class RemoveZoneViewModel extends ContentViewModel<RemoveZoneViewState, RemoveZoneViewProps>
{
  /* CONSTRUCTOR */
  constructor(view: ContentView) {
    super(view);

    this.initialState({ isDeleting: false });

    // Bindings
    this.onPopupClose = this.onPopupClose.bind(this);
    this.onDelete = this.onDelete.bind(this);
  }

  onPopupClose() {
    this.closePopUp();
  }

  async onDelete(id: number) {
    this.setField({ isDeleting: true });
    await this.props().onRemove(id);
    this.setField({ isDeleting: false });
  }
}