import { Service, Application, HttpManager, Managers } from '@levelapp/softfabric';


export default class PropertiesService extends Service {
    httpClient = Application.current.resolve<HttpManager>(Managers.HTTP_MANAGER);

    async get(onSuccess: Function) {
        this.httpClient
            .get('properties', [])
            .then((response: any) => {
                if (response) {
                    if (response.data) {
                        onSuccess(response.data);
                    }
                }
            });
    }

    post(property: any, callback: Function = () => { }) {
        this.httpClient
            .post('properties', [], property)
            .then((response: any) => {
                if (response) {
                    /*if (response.data)
                    {
                        callback(response.data);
                    }*/
                    callback("");
                }
            });
    }

    put(property: any, callback: Function = () => { }) {
        this.httpClient
            .put('properties', [], property)
            .then((response: any) => {
                if (response) {
                    /*if (response.data)
                    {
                        callback(response.data);
                    }*/
                    callback("");
                }
            });
    }

    delete(property: any, callback: Function = () => { }) {
        this.httpClient
            .delete('properties/delete/' + property.id)
            .then((response: any) => {
                if (response) {
                    /*if (response.data)
                   {
                       callback(response.data);
                   }*/
                    callback("");
                }
            });
    }

    getStoresUsingProperty(property: any, onSuccess: Function = () => { }) {
        this.httpClient
            .get('properties/getStoresUsingProperty/' + property.id, [])
            .then((response: any) => {
                if (response) {
                    if (response.data) {
                        onSuccess(response.data);
                    }
                }
            });
    }
}