import {ContentViewModel,ContentView} from '@levelapp/softfabric';
import PicturesSavingCardsViewState from './PicturesSavingCardsViewState';
import PicturesSavingCardsViewProps from './PicturesSavingCardsViewProps';

export default class PicturesSavingCardsViewModel extends ContentViewModel<PicturesSavingCardsViewState, PicturesSavingCardsViewProps>
{
      /* CONSTRUCTOR */
    constructor(view: ContentView)
    {
        super(view);

        this.initialState({}); // Initialize your state here

        // Bindings

    }
}