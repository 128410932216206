import {ContentShellViewModel, ContentShellView} from '@levelapp/softfabric';
import ShellLanguageViewState from './ShellLanguageViewState';
import ShellLanguageViewProps from './ShellLanguageViewProps';
 
import LanguageView from './pages/language/LanguageView';
import EnumModules from '../../../../../Common/Constants/EnumModules';
import LanguageV2 from './language-v2/LanguageV2';


export default class ShellLanguageViewModel extends ContentShellViewModel<ShellLanguageViewState, ShellLanguageViewProps>
{

    constructor(view : ContentShellView)
    {
        super(view);

        this.registerEntryPoint('main');
        this.register(process.env.REACT_APP_ISLANGUAGES_V2 === 'true' ? LanguageV2 : <LanguageView />, 'main');

        this.initialisation();
    }

    getModuleId()
    {
        return EnumModules.LANGUAGE;
    }

}