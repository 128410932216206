import { Application, HttpManager, Managers, Service } from "@levelapp/softfabric";

export default class TimeZonesService extends Service
{
    httpClient = Application.current.resolve<HttpManager>(Managers.HTTP_MANAGER);

    async getAll(onSuccess: Function, onFailure: Function = () => {}) 
    {
        this.httpClient.handleSomethingWentWrong = onFailure;
        this.httpClient.handleNotFound = onFailure;

        this.httpClient
            .get("timeZones")
            .then((response: any) => {
                if (response && response.data) {
                    onSuccess(response.data);
                }
            })
    }
}