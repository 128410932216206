import { View, Button } from "react-native";
import { ContentView } from "@levelapp/softfabric";
import AllergenViewState from "./AllergenViewState";
import AllergenViewProps from "./AllergenViewProps";
import AllergenViewStyle from "./AllergenViewStyle";
import AllergenViewModel from "./AllergenViewModel";
import { TranslationView } from "@levelapp/softfabric-ui";
import LineView from "../../../../../../components/line/LineView";
import CheckBoxHeaderSelectionView from "../../../../../../components/checkboxheaderselection/CheckBoxHeaderSelectionView";

export default class AllergenView extends ContentView<AllergenViewProps,AllergenViewState,AllergenViewModel,AllergenViewStyle> {
    

    /* CONSTRUCTOR */
    constructor(props: AllergenViewProps) {
        super(props);

        // Binding
        this.bind(new AllergenViewModel(this), new AllergenViewStyle(this));
    }

    /* RENDERING */
    render() {

      const { container ,  bodyTitle } = this.style;

        return (
            <View style={container}>
                <CheckBoxHeaderSelectionView handleCheckBoxChanged={this.binding.handleCheckboxAllergenChange} items={this.props.allergens} allItems={this.props.allAllergens} isLoading={this.props.isLoading}/>
                <LineView/>
                <TranslationView upperCase style={bodyTitle}>
                    allergen.body.title
                </TranslationView>
                <View style={{ flexDirection: "row" }}>
                    <Button
                        // disabled={this.state.isLoadingProperties}
                        title={this.binding.translate('allergen.body.button')}
                        onPress={() => {
                            this.binding.showGlobalAllergensManagementPopup();
                        }}
                    />
                </View>
            </View>
        );
    }
}
