import {ContentView} from '@levelapp/softfabric';
import PosSimButtonViewState from './PosSimButtonViewState';
import PosSimButtonViewProps from './PosSimButtonViewProps';
import PosSimButtonViewStyle from './PosSimButtonViewStyle';
import PosSimButtonViewModel from './PosSimButtonViewModel';
import {  View, Text, TouchableOpacity } from 'react-native';
import { LottieView } from '@levelapp/softfabric-ui';

export default class PosSimButtonView extends ContentView<PosSimButtonViewProps, PosSimButtonViewState, PosSimButtonViewModel, PosSimButtonViewStyle>
{
  /* CONSTRUCTOR */
  constructor(props:PosSimButtonViewProps)
  {
    super(props);

    // Binding
    this.bind(new PosSimButtonViewModel(this), new PosSimButtonViewStyle(this));
  }

  /* RENDERING */
  render()
  {
    return (

      <TouchableOpacity disabled = {this.props.disabled} activeOpacity={0.7} onPress={() =>{ this.props.isLoading == false && this.props.handleClick()}}>
        <View style={[this.props.style, this.style.button, { backgroundColor: this.props.backgroundColor != undefined ? this.props.backgroundColor : '#007bff', opacity: this.props.opacity != undefined ? this.props.opacity: 5.0 }]}>
          {this.props.isLoading ? (
            <LottieView height={25} width={40} source={require('./assets/50-material-loader.json')} />
          )
            :
            (
              <Text style={{ color: this.props.textColor != undefined ? this.props.textColor : 'white' }}>
                {this.props.children}
              </Text>
            )
          }
        </View>
      </TouchableOpacity>
    );
  }
}