 
import {ContentView} from '@levelapp/softfabric';
import CountrypopupViewState from './CountrypopupViewState';
import CountrypopupViewProps from './CountrypopupViewProps';
import CountrypopupViewStyle from './CountrypopupViewStyle';
import CountrypopupViewModel from './CountrypopupViewModel';
import { View } from 'react-native';
import PopupHeaderView from '../../../../components/popupheader/PopupHeaderView';
import Helpers from '../../../../../../../Common/Helpers/Helpers';
import ExtendedTextInputView from '../../../../components/extendedtextinput/ExtendedTextInputView';

export default class CountrypopupView extends ContentView<CountrypopupViewProps, CountrypopupViewState, CountrypopupViewModel, CountrypopupViewStyle>
{
  /* CONSTRUCTOR */
  constructor(props:CountrypopupViewProps)
  {
    super(props);

    // Binding
    this.bind(new CountrypopupViewModel(this), new CountrypopupViewStyle(this));
  }

  /* RENDERING */
  render()
  {
    const { popup , container } = this.style;


    return (
        <View style={popup}>
          <PopupHeaderView
            handleBackPressed={Helpers.closePopup}
            title={"countries.popup.title"}
            shouldShowButton
            isLoading={this.state.isLoading}
            handleOnPressButton={this.binding.handleSavePressed}
          />   

          <View style={container}>
            <ExtendedTextInputView
              title={"countries.popup.countryCode"}
              infoText={this.binding.translate('countries.popup.countrycode.info')}
              showError={false}
              value={`${this.props.country.countryCode}`}
              onChangeText={(country: string) => this.binding._dispatcherHelper.handleValueChange('country',country)}
            />
          </View>

        </View>
    );
  }
}