 
import { ContentView } from '@levelapp/softfabric';
import MapRenderViewState from './MapRenderViewState';
import MapRenderViewProps from './MapRenderViewProps';
import MapRenderViewStyle from './MapRenderViewStyle';
import MapRenderViewModel from './MapRenderViewModel';
import { Platform, Text, View } from 'react-native';

export default class MapRenderView extends ContentView<MapRenderViewProps, MapRenderViewState, MapRenderViewModel, MapRenderViewStyle>
{
  /* CONSTRUCTOR */
  constructor(props: MapRenderViewProps) {
    super(props);

    // Binding
    this.bind(new MapRenderViewModel(this), new MapRenderViewStyle(this));
  }

  /* RENDERING */
  render() {
    if (Platform.OS == "web") {
      const MapRenderWebView = require('./components/maprenderweb/MapRenderWebView').default;
      return (
        <View style={{flex: 1}}>
          <MapRenderWebView
            defaultCenter={this.props.defaultCenter}
            scrollEnabled={this.props.scrollEnabled == undefined ? true : this.props.scrollEnabled}
            zoomEnabled={this.props.zoomEnabled == undefined ? true : this.props.zoomEnabled}
            disableDefaultUI={this.props.disableDefaultUI == undefined ? false : this.props.disableDefaultUI}
            markers={this.props.markers == undefined ? [] : this.props.markers}
            oneMarker={this.props.oneMarker == undefined ? false : this.props.oneMarker}/>
        </View>
      )
    } else {
      return (
        <View>
          <Text>
            Not Available on Mobile
        </Text>
        </View>
      );
    }


  }
}