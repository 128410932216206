
import Tag from "../DTO/Tag";

export default class TagHelper 
{
    static filterTag(tags : Tag[] , name : 'reward')
    {
        if(tags != undefined && tags.length > 0)
        {
            return tags.filter(x => x.label.key.includes(name));
        }

        return tags;
    }
}