 
import {ContentViewModel,ContentView} from '@levelapp/softfabric';
import HealthStatusViewState from './HealthStatusViewState';
import HealthStatusViewProps from './HealthStatusViewProps';
import LogOperation from '../../../../../../../Transfer/LogOperations';
import StoresOperations from '../../../../../../../Transfer/StoresOperations';
import Store from '../../../../../../../Common/DTO/Store';
import SortHelper from '../../../../../../../Common/Helpers/SortHelper';
import Log from '../../../../../../../Common/DTO/Log';
import TableRow from '../../../../../../../Common/Components/TabelView/TableRow';
import ThirdPartyHelper from '../../../../../../../Common/Helpers/ThirdPartyHelper';
import LogDetailsView from '../../../../components/logdetails/LogDetailsView';
import ReferenceTypeHelper from '../../../../../../../Common/Helpers/ReferenceTypeHelper';
import { ReferenceTypeEnum } from '../../../../../../../Common/Enums/ReferenceTypeEnum';
import { ThirdPartyEnum } from '../../../../../../../Common/Enums/ThirdPartyEnum';
import * as XLSX from 'xlsx';
import LogToExcel from '../../../../../../../Common/DTO/LogToExcel';
import ToastHelper from '../../../../../../../Common/Helpers/ToastHelper';
var ProgressBar = require('progressbar.js');



export default class HealthStatusViewModel extends ContentViewModel<HealthStatusViewState, HealthStatusViewProps>
{
    ref : any;
    bar : any;
    _refEndDate : any;
    _logOperation : LogOperation;
    _storeOperation : StoresOperations;

      /* CONSTRUCTOR */
    constructor(view: ContentView)
    {
        super(view);

        var param = this.parameters() as any;
        var currentStore = undefined;
        var selectedDateFrom = new Date(Date.now());
        var selectedDateTo = new Date(Date.now());
        if(param != undefined && param.store)
        {
            currentStore = param.store;
            currentStore.title = param.store.slug;
            selectedDateFrom = param.date;
            selectedDateTo = param.date;
        }



        this.initialState(
            {
                currentStore : currentStore,
                stores :[],
                logs : [],
                success :[],
                failure : [],
                successTP : [],
                failureTP : [],
                selectedDateFrom : selectedDateFrom,
                selectedDateTo : selectedDateTo,
                referenceType : this.getReferenceType(),
                thirdpartyType : this.getThirdPartyType(),
                type : this.getTypes(),
                isLoading : true,
                isLoadingExport : false,
                tableData: [],
                nbPage: 0, currentPage: 1,
                tableHeaders: ['Name', 'Method', 'ErrorCode'],
            }); // Initialize your state here

        // Bindings
        this._logOperation = new LogOperation();
        this._storeOperation = new StoresOperations();

        this.setCurrentStore = this.setCurrentStore.bind(this);
        this.calculateChart = this.calculateChart.bind(this);

        

        
    }

    async componentDidMount()
    {
        this.bar = new ProgressBar.SemiCircle(this.ref, {
            strokeWidth: 6,
            trailColor: '#EEEEEE',
            trailWidth: 1,
            easing: 'easeInOut',
            duration: 1400,
            svgStyle: null,
            text: {
              value: '',
              alignToBottom: false
            },
            from: {color: '#FF6961'},
            to: {color: '#73C678'},
            // Set default step function for all animate calls
            step: (state : any, bar : any) => {
              bar.path.setAttribute('stroke', state.color);
              var value = Math.round(bar.value() * 100);
              if (value === 0) {
                bar.setText('');
              } else {
                bar.setText(`${value}%`);
              }
          
              bar.text.style.color = state.color;
            }
          });

        this._storeOperation.getallwithouttranslations(async (response: any) =>
        {
            // Check the stores we have access to
            let storeTemp: Store[] = [];
            response.data.forEach((store: Store) =>
            {

                // var tmp = (PermissionHelper.getExtraDataHealthStatus() as any);
                // if (Object.keys(tmp).length == 0 || tmp == null || tmp == undefined)
                // {
                //     //ALL STORES 
                store.title = store.slug;
                    storeTemp.push(store);
                // } else
                // {
                //     var id = (JSON.parse(tmp) as ShowOrdersExtraData).restaurant;
                //     if (id == store.id)
                //     {
                //         //DISPLAY ONLY THAT FROM BE
                //         storeTemp.push({ id: store.id, slug: "", title: store.slug, isClickAndCollectEnabled: store.isClickAndCollectEnabled, isClickAndCollectTemporaryDisabled: store.isClickAndCollectTemporaryDisabled, isKioskTemporaryDisabled : store.isKioskTemporaryDisabled });
                //     }
                // }
            });

            var stores = SortHelper.sortStoreByTitle(storeTemp);

            if(this.state().currentStore != undefined)
            {
               this.getAllBasketByStoreIdAndDate((this.state().currentStore as Store).id,this.state().selectedDateFrom,this.state().selectedDateTo);
            }else
            {
                if(stores.length > 0)
                {
                    this.getAllBasketByStoreIdAndDate(stores[0].id,this.state().selectedDateFrom,this.state().selectedDateTo);
                }
            }

            // var state = this.state();
            // if (storeTemp.length == 1)
            // {
            //     state.currentStore = storeTemp[0];
            //     state.stores = storeTemp;

            //     this.getAllBasketByStoreIdAndDate(storeTemp[0].id,this.state().selectedDateFrom,this.state().selectedDateTo);

            // }
            // else
            // {
            //     state.stores = SortHelper.sortStoreByTitle(storeTemp);
            // }

            this.setField({ stores : stores });
        })

    }

    async handleExport()
    {
        if(this.state().currentStore != undefined)
        {
            this.setField({ isLoadingExport: true });

            var listlog = await this._logOperation.getAllBasketByStoreIdAndDate(this.state().currentStore?.id as number, this.state().selectedDateFrom, this.state().selectedDateTo);
            if (listlog != null && listlog.data != null)
            {
                var listLogExcel: LogToExcel[] = [];
                listlog.data.data.forEach((l: Log) =>
                {
                    var lExcel = l as unknown as LogToExcel;
                    lExcel.referenceType = l.referenceType.key;
                    lExcel.thirdPartyType = l.thirdPartyType.key;
                    listLogExcel.push(lExcel);
                })
                var wb = XLSX.utils.book_new();
                var o = XLSX.utils.json_to_sheet(listLogExcel);
                XLSX.utils.book_append_sheet(wb, o, "Logs");
                XLSX.writeFile(wb, `Logs_${this.state().currentStore?.slug}.xlsx`);
                this.showToast(ToastHelper.getToast("kitchenapp.success.export", "success"), () => { }, 2500);
    
            } else
            {
                this.showToast(ToastHelper.getToast("kitchenapp.error.export", "error"), () => { }, 2500);
            }
            this.setField({ isLoadingExport: false });
        }
        

    }

    async getAllBasketByStoreIdAndDate(storeId : number , from : Date , to : Date)
    {
        var response = await this._logOperation.getAllBasketByStoreIdAndDate(storeId,from,to)
        if(response && response.data)
        {
            this.calculateChart(response.data.data);
        }
    }

    handleValueChange(value : any)
    {
        this.setField({currentStore : value.value},() => this.getAllBasketByStoreIdAndDate(value.value.id,this.state().selectedDateFrom,this.state().selectedDateTo));
    }

    

    handleOnChangeDate(type : 'startdate' | 'enddate' , value : Date)
    {
        var state = this.state();
        
        if(type == 'startdate')
        {
            this._refEndDate.getInnerRef().input.focus();
            state.selectedDateFrom = value;
        }else
        {
            state.selectedDateTo = value;
        }

        this.setField({state},() => 
        {
            if(type == 'enddate')
            {
                this.getAllBasketByStoreIdAndDate(this.state().currentStore?.id as number,this.state().selectedDateFrom,this.state().selectedDateTo);
            }
        });
        //this.props().handleDateChange(type,value);
    }

    switchThirdParty(thirdpartysAvailable : number[])
    {
        var state = this.state();
        state.thirdpartyType.map((value : any) =>
        {
            if(thirdpartysAvailable.find(x => x == value.id))
            {
                value.selected = true;
            }else
            {
                value.selected = false;
            }
        });

        this.setField({thirdpartyType : state.thirdpartyType});
    }

    switchReference(referencesAvailable : number[])
    {
        var state = this.state();
        state.referenceType.map((value : any) =>
        {
            if(referencesAvailable.find(x => x == value.id))
            {
                value.selected = true;
            }else
            {
                value.selected = false;
            }
        });

        this.setField({referenceType : state.referenceType});
    }

    calculateChart(logs : Log[])
    {

        let tableData: TableRow[] = [];

        var success : number[] = [0,0,0,0,0,0];
        var failure : number[] = [0,0,0,0,0,0];

        var successTP : number[] = [0,0,0,0,0,0,0];
        var failureTP : number[] = [0,0,0,0,0,0,0];

        // var uniqueTP = ThirdPartyHelper.onlyUniqueThirdPartyType(logs,false);
        // this.switchThirdParty(uniqueTP);
        // var uniqueRF = ReferenceTypeHelper.onlyUniqueReferenceType(logs,false);
        // this.switchReference(uniqueRF);
        var isSuccessCheck = this.state().type.find(x => x.id == 1 && x.selected == true);
        var isFailureCheck = this.state().type.find(x => x.id == 2 && x.selected == true);

        var filtredLogs = 
            logs.filter(x => 
                this.state().referenceType.find(y => y.id == x.referenceTypeId && y.selected) && 
                this.state().thirdpartyType.find(y => y.id == x.thirdPartyTypeId && y.selected) && ((
                Number.parseInt(x.errorCode) >= 400 && Number.parseInt(x.errorCode) <=599 && isFailureCheck ) ||
                (Number.parseInt(x.errorCode) >= 200 && Number.parseInt(x.errorCode) <399 && isSuccessCheck )))
        if(filtredLogs.length > 0)
        {
            

            for(var i=0;i<filtredLogs.length;i++)
            {
                var code = Number.parseInt(filtredLogs[i].errorCode);
                if(!Number.isNaN(code))
                {
                    if(code >= 400 && code <= 599)
                    {
                      
                            failureTP[filtredLogs[i].thirdPartyTypeId - 1]++;
                            failure[filtredLogs[i].referenceTypeId - 1]++;
                      
                    }else
                    {
                      
                            successTP[filtredLogs[i].thirdPartyTypeId - 1]++;
                            success[filtredLogs[i].referenceTypeId - 1]++;
                           
                    }
                }
            }

            var totalsuccess : number = 0;
            success.map((x : number)=> 
            {
                totalsuccess +=x ;
            });

            var totalfailure : number = 0;
            failure.map((x : number) =>
            {
                totalfailure +=x;
            })
            
            this.bar.text.style.fontFamily = '"Raleway", Helvetica, sans-serif';
            this.bar.text.style.fontSize = '2rem';
            
            this.bar.animate((totalsuccess / (totalsuccess + totalfailure)));
        
            filtredLogs.slice(0,1 * 25).forEach((log: Log) => {
                tableData.push({ data: [
                    log.name,
                    log.method,
                    log.errorCode], 
                    isDeletable: false, 
                    isEditable: true, 
                    editCallback: () => { this.showDetails(log) }, 
                    deleteCallback: () => { } });
            });
        }
       

        this.setField({
            logs : logs , 
            isLoading : false,
            nbPage : Math.floor(filtredLogs.length/25), 
            tableData : tableData, 
            currentPage : 1, 
            success : success , 
            failure : failure , 
            successTP : successTP , 
            failureTP : failureTP});
    }

    changeValue(value : any , type : string)
    {

        switch(type)
        {
            case 'reference':
                this.setField({referenceType : value},() => this.calculateChart(this.state().logs));
                break;
            case 'tp':
                this.setField({thirdpartyType : value},() => this.calculateChart(this.state().logs));
                break;
            case 'type':
                this.setField({type : value},() => this.calculateChart(this.state().logs));
                break;
        }

    }

    showDetails(log : Log)
    {
        this.showPopUp(<LogDetailsView log={log}/>)
    }

    async setCurrentStore(value : Store)
    {
        var response = await this._logOperation.getAllBasketByStoreIdAndDate(value.id,new Date(), new Date())
        if(response && response.data)
        {
            this.calculateChart(response.data.data);
            this.setField({currentStore : value , isLoading: true});
        }
        
    }

    changePage(page : number)
    {
        let tableData: TableRow[] = [];
        this.state().logs.slice(page == 0 ? 0 : page * 25,(page +1) * 25).forEach((log: Log) => {
            tableData.push({ data: [
                log.name,
                log.method,
                log.errorCode], 
                isDeletable: false, 
                isEditable: true, 
                editCallback: () => { this.showDetails(log) }, 
                deleteCallback: () => { } });
        });
        this.setField({currentPage : page +1 , tableData : tableData});
    }


    getReferenceType()
    {
        return [
            {title : ReferenceTypeHelper.changeEnumToText(ReferenceTypeEnum.BASKET) , id : ReferenceTypeEnum.BASKET , selected : true },
            {title : ReferenceTypeHelper.changeEnumToText(ReferenceTypeEnum.COUPON) , id : ReferenceTypeEnum.COUPON , selected : true},
            {title : ReferenceTypeHelper.changeEnumToText(ReferenceTypeEnum.REWARDS) , id : ReferenceTypeEnum.REWARDS , selected : true},
            {title : ReferenceTypeHelper.changeEnumToText(ReferenceTypeEnum.BOOSTER) , id : ReferenceTypeEnum.BOOSTER , selected : true},
            {title : ReferenceTypeHelper.changeEnumToText(ReferenceTypeEnum.POS) , id : ReferenceTypeEnum.POS , selected : true},
            {title : ReferenceTypeHelper.changeEnumToText(ReferenceTypeEnum.PAYMENT) , id : ReferenceTypeEnum.PAYMENT , selected : true}
        ];
    }

    getThirdPartyType()
    {
        return [
            {title : ThirdPartyHelper.changeEnumToText(ThirdPartyEnum.MOLLIE) , id : ThirdPartyEnum.MOLLIE , selected : true },
            {title : ThirdPartyHelper.changeEnumToText(ThirdPartyEnum.RABOBANK) , id : ThirdPartyEnum.RABOBANK , selected : true},
            {title : ThirdPartyHelper.changeEnumToText(ThirdPartyEnum.STRIPE) , id : ThirdPartyEnum.STRIPE , selected : true},
            {title : ThirdPartyHelper.changeEnumToText(ThirdPartyEnum.ADYEN) , id : ThirdPartyEnum.ADYEN , selected : true},
            {title : ThirdPartyHelper.changeEnumToText(ThirdPartyEnum.ORACLE) , id : ThirdPartyEnum.ORACLE , selected : true},
            {title : ThirdPartyHelper.changeEnumToText(ThirdPartyEnum.NCR) , id : ThirdPartyEnum.NCR , selected : true},
            {title : ThirdPartyHelper.changeEnumToText(ThirdPartyEnum.ATO) , id : ThirdPartyEnum.ATO , selected : true}
        ];
    }

    getTypes()
    {
        return [
            {title : 'generic.success' , id : 1 , selected : true },
            {title : 'generic.failure' , id : 2 , selected : true},
        ];
    }
}