import { ContentViewModel, ContentView } from "@levelapp/softfabric";
import Helpers from "../../../../../../Common/Helpers/Helpers";
import DispatcherManager from "../../../../../../Common/Managers/DispatcherManager";
import TranslationHelper from "../../../../../../Common/Helpers/TranslationHelper";
import ToastHelper from "../../../../../../Common/Helpers/ToastHelper";
import ErrorHelper from "../../../../../../Common/Helpers/ErrorHelper";
import ProductAdditionalInfosPopUpViewState from "./ProductAdditionalInfosPopUpViewState";
import ProductAdditionalInfosPopUpViewProps from "./ProductAdditionalInfosPopUpViewProps";
import AdditionalInfosOperations from "src/Transfer/AdditionalInfosOperations";

export default class ProductAdditionalInfosPopUpViewModel extends ContentViewModel<ProductAdditionalInfosPopUpViewState, ProductAdditionalInfosPopUpViewProps>
{
    _dispatcherManager: DispatcherManager;
    _additionalInfosOperation: AdditionalInfosOperations;

    constructor(props: ContentView) {
        super(props);

        /* Initial State */
        
        this.initialState({
            info: this.props().productcategory,
            languages: this.props().languages,
            showErrors: false,
            externalError: false,
            index: 0,
            indexThumbnail: 0,
            useDefaultImageChecked: Helpers.hasDuplicates(this.props().productcategory.imageTranslation),
            useDefaultThumbnailChecked: false,
            tab: "main",
            isLoading: false,
            showErrorsDescription: false,
        })

        /* BINDINGS */
        this.handleSavePressed = this.handleSavePressed.bind(this);
        this.handleImage = this.handleImage.bind(this);
        this.setIndexImage = this.setIndexImage.bind(this);
        this.handleTitleChanged = this.handleTitleChanged.bind(this);
        this.handleDescriptionChanged = this.handleDescriptionChanged.bind(this);
        this.setIndexThumbnail = this.setIndexThumbnail.bind(this);
        this.handleThumbnail = this.handleThumbnail.bind(this);
        this.handleDefaultCheckBoxChanged = this.handleDefaultCheckBoxChanged.bind(this);
        this.handleDefaultCheckBoxThumbnailChanged = this.handleDefaultCheckBoxThumbnailChanged.bind(this);
        this.changeTab = this.changeTab.bind(this);
        this.errorManagement = this.errorManagement.bind(this);
        this.saveInfo = this.saveInfo.bind(this);

        this._dispatcherManager = new DispatcherManager();
        this._additionalInfosOperation = new AdditionalInfosOperations();
    }

    componentDidMount() {
        console.log(this.state().info);
        this._dispatcherManager.checkboxDefaultImage = this.handleDefaultCheckBoxChanged;
        this._dispatcherManager.checkboxDefaultThumbnail = this.handleDefaultCheckBoxThumbnailChanged;

        this._dispatcherManager.translationTitle = this.handleTitleChanged;
        this._dispatcherManager.translationDescription = this.handleDescriptionChanged;

        this._dispatcherManager.numberIndexImage = this.setIndexImage;
        this._dispatcherManager.numberIndexThumbnail = this.setIndexThumbnail;

        this._dispatcherManager.uriImage = this.handleImage;
        this._dispatcherManager.uriThumbnail = this.handleThumbnail;
    }


    /* COMPONENT EVENTS */
    componentWillMount() { }

    /* OTHERS */

    fillAllThumbnailsWithDefault() {
        if (this.state().useDefaultThumbnailChecked) {
            var list = Helpers.fillAllImagesWithDefault(this.state().info.thumbnailTranslation, this.state().languages);
            this.setField((previousState) => previousState.info.thumbnailTranslation = list);
        }
    }

    fillAllPicturesWithDefault() {
        if (this.state().useDefaultImageChecked) {
            var list = Helpers.fillAllImagesWithDefault(this.state().info.imageTranslation, this.state().languages);
            this.setField((previousState) => previousState.info.imageTranslation = list);
        }
    }

    isFilledTranslations() {
        for (let i = 0; i < this.state().info.titleTranslations.length; i++) {
            if (this.state().info.titleTranslations[i].value === "" ||
                this.state().info.titleTranslations[i].value === " ") {
                return false;
            }
        }
        return true;
    }

    handleSavePressed() {
        if (
            this.state().info.titleTranslations.length != this.state().languages.length ||
            !this.isFilledTranslations() ||
            !Helpers.areImagesComplete(this.state().info.imageTranslation)
        ) {
            this.setField((previousState) => previousState.showErrors = true);
        }
        else {
            this.setField((previousState) => previousState.isLoading = true);
            this.saveInfo(this.state().info);
        }

    }

    saveInfo(productcategory: any) {
        for (var i = 0; i < this.props().languages.length; i++) {
            if (productcategory.imageTranslation[i].url.includes("data:image"))
                productcategory.imageTranslation[i].ImageData = productcategory.imageTranslation[i].url.replace("data:image/png;base64,", "").replace("data:image/jpeg;base64,", "");
            else
                productcategory.imageTranslation[i].ImageData = null;
        }

        if (productcategory.id !== undefined) {
            this._additionalInfosOperation.put(productcategory, () => {
                this.props().refreshParent();
                this.showToast(ToastHelper.getToast("products.additionalInfos.popup.success.updated", "success"), () => { }, 2500);
                this.closePopUp();
            }, this.errorManagement);
        }

        else {
            this._additionalInfosOperation.post(productcategory, () => {
                this.props().refreshParent();
                this.showToast(ToastHelper.getToast("products.additionalInfos.popup.success.added", "success"), () => { }, 2500);
                this.closePopUp();
            }, this.errorManagement);
        }

    }

    errorManagement(error: any) {
        this.setField({ isLoading: false });
        ErrorHelper.showMessageError(error.response);
    }

    changeTab(tab: any) {
        this.setField((previousState) => previousState.tab = tab);
    }

    /* INDEX IMAGE */

    private setIndexImage(index: any) {
        this.setField((previousState) => previousState.index = index);
    }

    private setIndexThumbnail(index: any) {
        this.setField((previousState) => previousState.indexThumbnail = index);
    }

    /* URI IMAGE */

    private handleImage(base64: any) {
        var state = this.state();
        TranslationHelper.image(state.info.imageTranslation, state.languages, state.index, base64);
        this.setField({ state });
        this.fillAllPicturesWithDefault();
    }

    private handleThumbnail(base64: any) {
        var state = this.state();
        TranslationHelper.image(state.info.thumbnailTranslation, state.languages, state.indexThumbnail, base64);
        this.setField({ state });
        this.fillAllThumbnailsWithDefault();
    }

    /* CHECKBOX */

    private handleDefaultCheckBoxChanged(value: boolean) {
        this.setField((previousState) => previousState.useDefaultImageChecked = value);
        this.fillAllPicturesWithDefault();
    }

    private handleDefaultCheckBoxThumbnailChanged(value: boolean) {
        this.setField((previousState) => previousState.useDefaultThumbnailChecked = value);
        this.fillAllThumbnailsWithDefault();
    }

    /* TRANSLATIONS */

    private handleTitleChanged(text: any, index: any) {
        var state = this.state();
        TranslationHelper.translation(state.info.titleTranslations, state.languages, index, text);
        this.setField({ state });
    }

    private handleDescriptionChanged(text: any, index: any) {
        var state = this.state();
        TranslationHelper.translation(state.info.descriptionTranslations, state.languages, index, text);
        this.setField({ state });
    }
}