import {ContentViewModel,ContentView} from '@levelapp/softfabric';
import PriceLogsTabViewState from './PriceLogsTabViewState';
import PriceLogsTabViewProps from './PriceLogsTabViewProps';

export default class PriceLogsTabViewModel extends ContentViewModel<PriceLogsTabViewState, PriceLogsTabViewProps>
{
      /* CONSTRUCTOR */
    constructor(view: ContentView)
    {
        super(view);

        this.initialState({}); // Initialize your state here

        // Bindings

    }
}