import {ContentViewStyle} from '@levelapp/softfabric';
import {StyleProp,ViewStyle, TextStyle} from 'react-native';

export default class SwitchItemViewStyle extends ContentViewStyle
{
    page: StyleProp<ViewStyle> = {
        flex: 1
    }

    item : StyleProp<ViewStyle> =
    {
        paddingVertical : 7,  
        backgroundColor : 'white' ,
        borderWidth : 1,
        borderColor : '#b3b3b3',
        width : 125
    }

    active : StyleProp<ViewStyle> =
    {
        borderColor : '#4b8cca',
        backgroundColor : '#f0f7fb',
        borderWidth : 1,
    }

    left : StyleProp<ViewStyle> =
    {
        borderTopLeftRadius : 5,
        borderBottomLeftRadius : 5
    }

    right : StyleProp<ViewStyle> =
    {
        borderTopRightRadius : 5,
        borderBottomRightRadius : 5
    }

    activeText : StyleProp<TextStyle> =
    {
        color : "#0c77bf"
    }

    text : StyleProp<TextStyle> =
    {
        color : "#b3b3b3",
        fontWeight : '800',
        textAlign : 'center'
    }
}