import {ContentViewModel,ContentView} from '@levelapp/softfabric';
import MainInformationViewState from './MainInformationViewState';
import MainInformationViewProps from './MainInformationViewProps';
import Constants from '../../../../../../../../../Common/Constants/Constants';
import TriggerOperations from '../../../../../../../../../Transfer/TriggerOperations';
import TypeOperations from '../../../../../../../../../Transfer/TypeOperations';
import ErrorHelper from '../../../../../../../../../Common/Helpers/ErrorHelper';

export default class MainInformationViewModel extends ContentViewModel<MainInformationViewState, MainInformationViewProps>
{
    _refEndDate : any;
    _triggersOperation: TriggerOperations;
    _typesOperation: TypeOperations;

    /* CONSTRUCTOR */
    constructor(view: ContentView)
    {
        super(view);

        this.initialState(
            {
                zIndexSelect : -3,
                color: Constants.MainColor,
                voucherTypes: [],
                prizeTypes: [],
                triggers: [],
            });

        this._triggersOperation = new TriggerOperations();
        this._typesOperation = new TypeOperations();

        // Bindings
        this.handleOnChangeDate = this.handleOnChangeDate.bind(this);
        this.onDropDownOpen = this.onDropDownOpen.bind(this);
        this.onDropDownClose = this.onDropDownClose.bind(this);
        this.errorManagement = this.errorManagement.bind(this);
        this.getTypes = this.getTypes.bind(this);
        this.getTriggers = this.getTriggers.bind(this);


    }

    componentDidMount()
    {
        this.getTypes();
        this.getTriggers();
    }

    handleOnChangeDate(type : 'startdate' | 'enddate' , value : Date)
    {
        this._refEndDate.getInnerRef().input.focus();
        this.props().handleDateChange(type,value);
    }

    onDropDownOpen()
    {
        this.setField({zIndexSelect : 3});
    }

    onDropDownClose()
    {
        this.setField({zIndexSelect : -3});
    }

    getTriggers() {
        this._triggersOperation.get( [] , (response : any) => {
            response.data.forEach((trigger :any) => {
                trigger.title = trigger.key; 
            });
          this.setField({ triggers: [{title: undefined},...response.data]});
      },this.errorManagement, )
      }
  
    getTypes() {
    let voucherTypes: any[] = [];
    let prizeTypes: any[] = [];

    this._typesOperation.get( [] , (response : any) => {
        response.data.forEach((type :any) => {
            type.title = type.key;
            if(type.purposeId == 1){
                voucherTypes.push(type);
            }
            else if(type.purposeId == 2){
                prizeTypes.push(type)
            }
        });
        this.setField({voucherTypes : [{title: undefined},...voucherTypes], prizeTypes: [{title: undefined},...prizeTypes]});
    },this.errorManagement)
    }

    errorManagement(error: any) {
        this.setField({ isLoading: false });
        ErrorHelper.showMessageError(error.response);
    }
}