 
import { ContentView } from '@levelapp/softfabric';
import ProductPopupViewState from './ProductPopupViewState';
import ProductPopupViewProps from './ProductPopupViewProps';
import ProductPopupViewStyle from './ProductPopupViewStyle';
import ProductPopupViewModel from './ProductPopupViewModel';
import { View } from 'react-native';
import { TranslationView } from '@levelapp/softfabric-ui';
import PopupMenuItemView from "../../../../components/popupmenuitem/PopupMenuItemView";
import AllergenView from './components/allergen/AllergenView';
import AllergensManagementView from './components/allergensmanagement/AllergensManagementView';
import Allergen from '../../../../../../../Common/DTO/Allergen';
import PicturesView from '../../../../components/pictures/PicturesView';
import DescriptionView from '../../../../components/description/DescriptionView';
import MainInformationView from './components/maininformation/MainInformationView';
import PopupHeaderView from '../../../../components/popupheader/PopupHeaderView';
import Constants from 'src/Common/Constants/Constants';
import DeliveryInfoView from './components/deliveryinfo/DeliveryInfoView';
import AdditionalInfosView from './components/additionalinfos/AdditionalInfosView';



export default class ProductPopupView extends ContentView<ProductPopupViewProps, ProductPopupViewState, ProductPopupViewModel, ProductPopupViewStyle>
{



  /* CONSTRUCTOR */
  constructor(props: ProductPopupViewProps) {
    super(props);

    // Binding
    this.bind(new ProductPopupViewModel(this), new ProductPopupViewStyle(this));
  }

  /* RENDERING */
  render() {
    switch (this.state.windowTab)
    {
      case 'product':
      return (
          <View style={this.style.popup}>

            <PopupHeaderView
              handleBackPressed={() =>
                {
                  this.props.handleBack();
                }}
              title={"products.popup.title"}
              shouldShowButton
              isLoading={this.state.isLoading}
              handleOnPressButton={this.binding.handleSavePressed}
            />

            {/*Tabs*/}
            <View style={{ display: "flex", flexDirection: "row", flexWrap: "wrap" }}>
              <PopupMenuItemView activeItem={this.state.tab} title={"form.maininformation"} item={"main"} handleChangeTab={() => this.binding.changeTab("main")}/>
              <PopupMenuItemView activeItem={this.state.tab} title={"form.description"} item={"desc"} handleChangeTab={() => this.binding.changeTab("desc")}/>
              {Constants.ShowDeliveryProductTab &&<PopupMenuItemView activeItem={this.state.tab} title={"form.delivery"} item={"delivery"} handleChangeTab={() => this.binding.changeTab("delivery")}/>}
              <PopupMenuItemView activeItem={this.state.tab} title={"form.images"} item={"pictures"} handleChangeTab={() => this.binding.changeTab("pictures")}/>
              {/* {!this.props.isGroup && */}
              { process.env.REACT_APP_PROJECTNAME && process.env.REACT_APP_PROJECTNAME !== 'Quick' &&                 
                <PopupMenuItemView activeItem={this.state.tab} title={"popup.product.title.allergens"} item={"allergens"} handleChangeTab={() => this.binding.changeTab("allergens")}/>
              }
               
              {/* } */}
              <PopupMenuItemView activeItem={this.state.tab} title={"additionalInfos.title"} item={"additionalInfos"} handleChangeTab={() => this.binding.changeTab("additionalInfos")}/>
            </View>

            {/*Pages*/}
            {this.state.tab == "main" &&
              <MainInformationView
                product={this.state.product}
                languages={this.state.languages}
                showErrors={this.state.showErrors}
                handleTranslationsChange={this.binding._dispatcherManager.handleTranslationsChange}
                handleCheckboxChange={this.binding._dispatcherManager.handleCheckboxChange}
                handleValueChange={this.binding._dispatcherManager.handleValueChange}
              />
            }
            {this.state.tab == "desc" &&
              <DescriptionView
                listDesc={this.state.product.descriptionTranslations}
                languages={this.state.languages}
                shouldShowError={this.state.showErrors}
                handleTranslationsChange={this.binding._dispatcherManager.handleTranslationsChange}

              /> 
            }

            {Constants.ShowDeliveryProductTab && this.state.tab == "delivery" &&
              <DeliveryInfoView
                product={this.state.product}
                languages={this.state.languages}
                shouldShowError={this.state.showErrors}
                handleValueChange={this.binding._dispatcherManager.handleValueChange}
                handleTranslationsChange={this.binding._dispatcherManager.handleTranslationsChange}
                handleOnComplete={this.binding._dispatcherManager.handleAnyChange}
                handleOnPressImage={this.binding._dispatcherManager.handleNumberChange}
                handleCheckboxChange={this.binding._dispatcherManager.handleCheckboxChange}
                shouldUseDefaultSecondaryImage={this.state.useDefaultSecondaryImageChecked}
              />
            }

            {this.state.tab == "pictures" &&
              <PicturesView
                languages={this.props.languages}
                listImage={this.state.product.imageTranslations}
                listThumbnail={this.state.product.thumbnailTranslations}
                handleOnComplete={this.binding._dispatcherManager.handleAnyChange}
                handleOnPressImage={this.binding._dispatcherManager.handleNumberChange}
                handleCheckboxChange={this.binding._dispatcherManager.handleCheckboxChange}
                shouldUseDefaultImage={this.state.useDefaultImageChecked}
                shouldUseDefaultThumnail={this.state.useDefaultThumbnailChecked}
              />
            }
            {this.state.tab == "allergens" &&
                <AllergenView 
                  handleAllergens={(bool : boolean , allergen : Allergen) => this.binding.handleChangeAllergen(bool , allergen)}
                  navigateOnThePopup={() => this.binding.handleNavigationTo('allergens')}
                  isLoading={this.state.isLoadingAllergens} 
                  allergens={this.state.product.productAllergens} 
                  allAllergens={this.state.allergens}/>
            }
             {this.state.tab == "additionalInfos" &&
                <AdditionalInfosView 
                 handleAdditionalInfos={(infos:any) => this.binding.handleChangeAdditionalInfos(infos)}
                 additionalInfos={this.state.product.additionalInfoValues}/>
            }
            {this.state.showErrors &&
              < View style={this.style.footer} >
                <TranslationView style={{ color: "red" }}>error.please.fields</TranslationView>  
              </View>
            }
          </View>
        );
      case 'allergens':
        return <AllergensManagementView 
        handleBackPressed={() => this.binding.handleNavigationTo('product')}
        languages = {this.state.languages}
        />
      default:
        return (<View></View>);     
    }
  }
}