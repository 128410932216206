 
import {ContentView} from '@levelapp/softfabric';
import RoleViewState from './RoleViewState';
import RoleViewProps from './RoleViewProps';
import RoleViewStyle from './RoleViewStyle';
import RoleViewModel from './RoleViewModel';
import { View, Text, TextInput } from 'react-native';
import SwitchView from './components/switch/SwitchView';
import Role from '../../../../../../../../../Common/DTO/Role';
import LineView from '../../../../../../components/line/LineView';
import RolePermission from '../../../../../../../../../Common/DTO/M&N/RolePermission';
import { TranslationView } from '@levelapp/softfabric-ui';

export default class RoleView extends ContentView<RoleViewProps, RoleViewState, RoleViewModel, RoleViewStyle>
{
  /* CONSTRUCTOR */
  constructor(props:RoleViewProps)
  {
    super(props);

    // Binding
    this.bind(new RoleViewModel(this), new RoleViewStyle(this));
  }

  /* RENDERING */
  render()
  {
    const { block , header , subheading , inputTitle} = this.style;

    return (
      <View style={{ display: "flex", flexDirection: "column", paddingHorizontal: 170, paddingVertical: 50 }}>    
          <View style={block}>
          <TranslationView style={subheading} upperCase>form.role</TranslationView>
          <SwitchView changeRole={(role : Role) => this.binding.handleChangeRole(role)} allRoles={this.props.allRoles} selectedRole={this.props.role}/>
          <LineView/>
          <View>

          {this.props.role != undefined &&
          <>
          <TranslationView style={subheading} upperCase>form.permissions</TranslationView>
          <View style={header}>
            {this.props.role != undefined && this.props.role.rolePermissions.length > 0  ?
              this.props.role.rolePermissions.map((content: RolePermission) => {
                  return (
                      <View style={{ flexDirection: "row" , alignItems : 'baseline' }}>
                        <View style={{paddingHorizontal : 10}}>
                          <View style={{width : 8 , height :8 , borderRadius : 20 , backgroundColor : 'black'  }}/>
                        </View>
                          <Text style={inputTitle}>
                              {content.permission.key}
                          </Text>
                        
                          <TextInput style={{borderColor : '#D8D8D8' , borderWidth : 1}} value={this.binding.find(content.permissionId)} onChangeText={(text : string) => this.props.handleChangePermissionUser('permissionusers',text, content.permissionId)}>
                            
                          </TextInput>
                      </View>
                  );
              })
              :
                <View>
                  <TranslationView>form.permissions.no.permissions</TranslationView>
                </View>
              }
          </View>
          </>
          }

          </View>
        </View>
      </View>
    );
  }
}