import {ContentShellViewModel, ContentShellView} from '@levelapp/softfabric';
import ShellStoresViewState from './ShellStoresViewState';
import ShellStoresViewProps from './ShellStoresViewProps';
import StoresView from './pages/stores/StoresView';
import EnumModules from '../../../../../Common/Constants/EnumModules';
 

export default class ShellStoresViewModel extends ContentShellViewModel<ShellStoresViewState, ShellStoresViewProps>
{
    constructor(view : ContentShellView)
    {
        super(view);

        this.registerEntryPoint('main');
        this.register(<StoresView/> ,'main');

        this.initialisation();
    }

    getModuleId()
    {
        return EnumModules.STORES;
    }
}