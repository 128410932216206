 
import { ContentView } from '@levelapp/softfabric';
import FaqpopupViewState from './FaqpopupViewState';
import FaqpopupViewProps from './FaqpopupViewProps';
import FaqpopupViewStyle from './FaqpopupViewStyle';
import FaqpopupViewModel from './FaqpopupViewModel';
import Constants from '../../../../../../../Common/Constants/Constants';
import ButtonView from "../../../../../../../Common/Components/Button/ButtonView";
import LinearGradient from "react-native-web-linear-gradient";
import { View, TouchableWithoutFeedback, Image, TextInput, Text } from 'react-native';
import { TranslationView } from '@levelapp/softfabric-ui';
import PopupMenuItemView from '../../../../components/popupmenuitem/PopupMenuItemView';
import MainInformationView from './components/maininformation/MainInformationView';
import Helpers from '../../../../../../../Common/Helpers/Helpers';
import Language from '../../../../../../../Common/DTO/Language';

export default class FaqpopupView extends ContentView<FaqpopupViewProps, FaqpopupViewState, FaqpopupViewModel, FaqpopupViewStyle>
{
  /* CONSTRUCTOR */
  constructor(props: FaqpopupViewProps) {
    super(props);

    // Binding
    this.bind(new FaqpopupViewModel(this), new FaqpopupViewStyle(this));
  }

  /* RENDERING */
  render() {
    return (
      <View style={{ minWidth: 600, minHeight: 850, width: Constants.PopUpWidth, maxWidth: "100%" }}>
        <View>
          <LinearGradient start={{ x: 0, y: 0 }} end={{ x: 1, y: 0 }} colors={[Constants.GradiantStartColor, Constants.GradiantEndColor]} >
            <View style={{ display: "flex", flexDirection: "row" }}>
              <TranslationView style={this.style.title}>
                faq.popup.title
              </TranslationView>
              <ButtonView isLoading={this.state.isLoading} handleClick={this.binding.handleSavePressed} backgroundColor="white" style={{ marginTop: 20 }}>
                <TranslationView upperCase style={this.style.saveButton}>
                  generic.button.saveclose
                </TranslationView>
              </ButtonView>
              <TouchableWithoutFeedback onPress={Helpers.closePopup}>
                <Image style={{ height: 23, width: 23, resizeMode: 'contain', marginTop: 25, marginLeft: 20 }}
                  source={{ uri: 'https://appstoragflyx.blob.core.windows.net/app/Console/exit.png' }}></Image>
              </TouchableWithoutFeedback>
            </View>
          </LinearGradient>
        </View>

        <View style={{ display: "flex", flexDirection: "row", flexWrap: "wrap" }}>
            <PopupMenuItemView activeItem={this.state.tab} title={"form.maininformation"} item={"main"} handleChangeTab={() => this.binding.changeTab("main")}/>
            {/* <PopupMenuItemView activeItem={this.state.tab} title={"form.link"} item={"link"} handleChangeTab={() => this.binding.changeTab("link")}/> */}
        </View>

        {this.state.tab == "main" &&
          <MainInformationView
            handleChangeText = {this.binding._dispatcherManager.handleTranslationsChange}
            handleChangeValue = {this.binding._dispatcherManager.handleValueChange}
            handleCheckboxChange = {this.binding._dispatcherManager.handleCheckboxChange}
            question = {this.state.question}
            languages = {this.state.languages}
            showError = {this.state.showErrors}
          />
        }

        {this.state.tab == "link" && 
          <View style={{ display: "flex", flexDirection: "column", paddingHorizontal: 170, paddingVertical: 50 }}>
            <View style={{ zIndex: -1, display: 'flex', flexDirection: 'row', flexWrap: 'wrap', justifyContent: 'space-between' }}>
              {/* Link */}
              {this.state.languages.map((content: Language) => {
                var _text = Helpers.getTranslationValueByLanguageKey(this.state.question.linkTranslations,content.key);
                return (
                  <View style={this.style.section}>
                    <Text style={this.style.inputTitle}><TranslationView upperCase>form.link</TranslationView> ({content.key.toUpperCase()})</Text>
                    <TextInput
                      style={this.style.textArea}
                      value={_text}
                      onChangeText={(text: string) => this.binding._dispatcherManager.handleTranslationsChange('link',text, content.id)}
                      multiline={true} />
                  </View>
                );
              })}
            </View>
          </View>
        }
      </View>
    );
  }
}