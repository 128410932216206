import { StyleProp, ViewStyle } from 'react-native';

import { ContentViewStyle } from '@levelapp/softfabric';

export default class AvailabilityTabViewStyle extends ContentViewStyle {
  container: StyleProp<ViewStyle> = {
    marginHorizontal: 35,
    paddingVertical: 15,
  }

  storeGroupContainer: StyleProp<ViewStyle> = {
    display: 'flex',
    marginTop: 18,
  }

  topLabels: StyleProp<ViewStyle> = {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginLeft: 150,
  }

  row: StyleProp<ViewStyle> = {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    paddingVertical: 5,
    borderBottomColor: '#ECECEC',
    borderBottomWidth: 1,
  }

  inputs: StyleProp<ViewStyle> = {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginVertical: 8,
  }
  
  inputView: StyleProp<ViewStyle> = {
    position: 'relative',
    maxWidth: 90,
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    marginLeft: 15,
  }
}