import { ContentViewStyle } from '@levelapp/softfabric';
import { StyleProp, ViewStyle, TextStyle } from 'react-native';

export default class ChangeStatusPopupViewStyle extends ContentViewStyle
{
    container: StyleProp<ViewStyle> = {
        paddingHorizontal: 50,
        paddingVertical: 25,
        alignItems: 'center'
    };

    buttonsContainer: StyleProp<ViewStyle> = {
        flexDirection: 'row'
    };

    buttonTxt: StyleProp<TextStyle> = {
        color: 'black',
        fontSize: 16
    };

    buttonStyle: StyleProp<ViewStyle> = {
        marginVertical: 5,
        paddingHorizontal: 20,
        marginHorizontal: 8,
        paddingVertical: 11
    };
}