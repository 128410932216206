import {ContentViewModel,ContentView} from '@levelapp/softfabric';
import StatsBlockViewState from './StatsBlockViewState';
import StatsBlockViewProps from './StatsBlockViewProps';

export default class StatsBlockViewModel extends ContentViewModel<StatsBlockViewState, StatsBlockViewProps>
{
      /* CONSTRUCTOR */
    constructor(view: ContentView)
    {
        super(view);

        this.initialState({}); // Initialize your state here

        // Bindings

    }
}