import { Operation } from "@levelapp/softfabric";
import CountryService from "../Proxy/CountryService";
import Country from "../Common/DTO/Country";

export default class CountryOperations extends Operation
{
    service : CountryService;

    constructor()
    {
        super();
        this.service = new CountryService();
    }

    get(callback : Function, onFailure : Function = () => {})
    {
        this.service.get(callback, onFailure);
    }

    post(country : Country, callback : Function, onFailure : Function)
    {
        this.service.post(country, callback, onFailure);
    }

    put(country : Country, callback : Function, onFailure : Function)
    {
        this.service.put(country, callback, onFailure);
    }

    delete(country : Country, callback : Function, onFailure : Function)
    {
        this.service.delete(country, callback, onFailure);
    }
}