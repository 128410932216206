export enum LoyaltyRulesKeys
{
    PointsMoneyRatio = 1,
    InactivityTime = 2,
    MaxPurchasesPerPeriod = 3,
    PeriodPurchase = 4,
    MaxTicketRetrievalPerPeriod = 5,
    TicketRetrievalPeriod = 6,
    MaxMemberSponsorship = 7,
    MaxPointsByUser = 8,
    MaxPointsPerTransfer = 9,
    MaxTransfersPerPeriod = 10,
    MinPurchaseForTransfer = 11,
    PointsTransfersPeriod = 12,
    CouponRenewableCooldown = 13
}