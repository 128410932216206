import {ContentViewStyle} from '@levelapp/softfabric';
import {StyleProp,ViewStyle} from 'react-native';

export default class PicturesSavingCardsViewStyle extends ContentViewStyle
{
    page: StyleProp<ViewStyle> = {
        flex: 1
    }

    container: StyleProp<ViewStyle> = { 
        paddingHorizontal: 170, 
        paddingVertical: 50 
    }

    block : StyleProp<ViewStyle> =
    {
        paddingHorizontal : 10
    }
}