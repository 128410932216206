 
import { ContentView } from '@levelapp/softfabric';
import PromoCodeBatchPopupViewState from './PromoCodeBatchPopupViewState';
import PromoCodeBatchPopupViewProps from './PromoCodeBatchPopupViewProps';
import PromoCodeBatchPopupViewStyle from './PromoCodeBatchPopupViewStyle';
import PromoCodeBatchPopupViewModel from './PromoCodeBatchPopupViewModel';
import { TranslationView } from '@levelapp/softfabric-ui';
import { View } from 'react-native';
import "react-datepicker/dist/react-datepicker.css";
import 'react-datepicker/dist/react-datepicker-cssmodules.css';
import Constants from '../../../../../../../Common/Constants/Constants';
import PopupMenuItemView from '../../../../components/popupmenuitem/PopupMenuItemView';
import MainInformationView from './components/maininformation/MainInformationView';
import TitleDescriptionView from './components/titledescription/TitleDescriptionView';
import Helpers from '../../../../../../../Common/Helpers/Helpers';
import PopupHeaderView from '../../../../components/popupheader/PopupHeaderView';
import CountriesView from './components/countries/CountriesView';

export default class PromoCodeBatchPopupView extends ContentView<PromoCodeBatchPopupViewProps, PromoCodeBatchPopupViewState, PromoCodeBatchPopupViewModel, PromoCodeBatchPopupViewStyle>
{
  /* CONSTRUCTOR */
  constructor(props: PromoCodeBatchPopupViewProps)
  {
    super(props);

    // Binding
    this.bind(new PromoCodeBatchPopupViewModel(this), new PromoCodeBatchPopupViewStyle(this));
  }

  /* RENDERING */
  render()
  {
    return (
      <View style={{ minWidth: 600, minHeight: 850, width: Constants.PopUpWidth, maxWidth: "100%" }}>

        <PopupHeaderView
        handleBackPressed={() =>
          {
            this.props.refreshParent()
            Helpers.closePopup()
          }}          
          title={"promocodes.popup.title"}
          shouldShowButton
          isLoading={this.state.isLoading}
          handleOnPressButton={this.binding.handleSavePressed}
        /> 

        <View style={{ display: "flex", flexDirection: "row", flexWrap: "wrap" }}>
          <PopupMenuItemView activeItem={this.state.tab} title={"form.maininformation"} item={"main"} handleChangeTab={() => this.binding.changeTab("main")}/>
          <PopupMenuItemView activeItem={this.state.tab} title={"form.titledescription"} item={"desc"} handleChangeTab={() => this.binding.changeTab("desc")}/>
          <PopupMenuItemView activeItem={this.state.tab} title={"form.countries"} item={"countries"} handleChangeTab={() => this.binding.changeTab("countries")}/>
        </View>

        {this.state.tab == "main" &&
          <MainInformationView
            languages={this.state.languages}
            promocodebatch={this.state.promocodebatch}
            shouldShowError={this.state.showErrors}
            handleDateChange={this.binding._dispatcherHelper.handleDateChange}
            handleValueChange={this.binding._dispatcherHelper.handleValueChange}
            handleCheckboxChange={this.binding._dispatcherHelper.handleCheckboxChange}
            refresh={this.props.refreshParent}
          />
        }
        {this.state.tab == "desc" &&
          <TitleDescriptionView
            promocodebatch={this.state.promocodebatch}
            languages={this.state.languages}
            shouldShowError={this.state.showErrors}
            handleTranslationsChange={this.binding._dispatcherHelper.handleTranslationsChange}
            refresh={this.props.refreshParent}
          />
        }
        {this.state.tab == "countries" &&
          <CountriesView
            promoCodeBatch={this.state.promocodebatch}
            countries={this.state.countries}
            handleValueChange={this.binding._dispatcherHelper.handleValueChange}
          />
        }
        {this.state.showErrors &&
          < View style={this.style.footer} >
            <TranslationView style={{ color: "red" }}>error.please.fields</TranslationView>
          </View>
        }
      </View>
    );
  }
}