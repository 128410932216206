import { Operation } from "@levelapp/softfabric";
import TranslationsNonContentService from "../Proxy/TranslationsNonContentService";

export default class TranslationsNonContentOperations extends Operation {
    service: TranslationsNonContentService;

    constructor() {
        super();
        this.service = new TranslationsNonContentService();
    }

    get(parameters: any[], callback: Function) {
        this.service.get(parameters, callback);
    }

    put(translations: any, callback: Function) {
        this.service.put(translations, callback);
    }

    post(translations: any, callback: Function) {
        this.service.post(translations, callback);
    }

    addTranslation(translations: any, callback: Function) {
        this.service.addTranslation(translations, callback);
    }

    delete(translations: any, callback: Function, error: Function, notFound: Function) {
        this.service.delete(translations, callback, error, notFound);
    }

}