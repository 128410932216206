 
import {ContentView} from '@levelapp/softfabric';
import DeliveryInfoViewState from './DeliveryInfoViewState';
import DeliveryInfoViewProps from './DeliveryInfoViewProps';
import DeliveryInfoViewStyle from './DeliveryInfoViewStyle';
import DeliveryInfoViewModel from './DeliveryInfoViewModel';
import { View } from 'react-native';
import Language from 'src/Common/DTO/Language';
import ExtendedBlockTextInputView from 'src/Presentation/modules/dashboard/components/extendedblocktextinput/ExtendedBlockTextInputView';
import LineView from 'src/Presentation/modules/dashboard/components/line/LineView';
import SectionTitleView from 'src/Presentation/modules/dashboard/components/sectiontitle/SectionTitleView';
import ExtendedImageView from 'src/Presentation/modules/dashboard/components/extendedimage/ExtendedImageView';
import ExtendedTextInputView from 'src/Presentation/modules/dashboard/components/extendedtextinput/ExtendedTextInputView';

export default class DeliveryInfoView extends ContentView<DeliveryInfoViewProps, DeliveryInfoViewState, DeliveryInfoViewModel, DeliveryInfoViewStyle>
{
  /* CONSTRUCTOR */
  constructor(props:DeliveryInfoViewProps)
  {
    super(props);

    // Binding
    this.bind(new DeliveryInfoViewModel(this), new DeliveryInfoViewStyle(this));
  }

  /* RENDERING */
  render()
  {
    return (
      <View style={{ display: "flex", flexDirection: "column", paddingHorizontal: 170, paddingVertical: 50 }}>
        {!this.props.product.isGroup && <View>
          <SectionTitleView title={'form.informations'} />
          <ExtendedTextInputView
            title={'form.index'}
            showError={false}
            onChangeText={(text: string) => this.props.handleValueChange('secondaryOrderindex', text)}
            value={`${this.props.product.secondaryOrderIndex == undefined ? ' ' : this.props.product.secondaryOrderIndex}`} />
        </View>}
        {!this.props.product.isGroup && <LineView /> }

        
        <SectionTitleView title={'form.titlefield'} />
        <View style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', justifyContent: 'space-between' }}>
          {this.props.languages.map((content: Language) => {
            var result = this.props.product.secondaryTitleTranslations.find((x: any) => x.language.id == content.id);
            var _text = result ? result.value : "";
            return (
              <ExtendedBlockTextInputView
                value={_text}
                type={'small'}
                title={content.key.toUpperCase()}
                showError={this.props.shouldShowError}
                onChangeText={(text: string) => this.props.handleTranslationsChange('secondaryTitle', text, content.id)}
              />
            );
          })}
        </View>
        <LineView />
        <SectionTitleView title={'form.description'} />
        <View style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', justifyContent: 'space-between' }}>
          {this.props.languages.map((content: Language) => {
            var result = this.props.product.secondaryDescriptionTranslations.find((x: any) => x.language.id == content.id);
            var _text = result ? result.value : "";
            return (
              <ExtendedBlockTextInputView
                value={_text}
                type={'small'}
                title={content.key.toUpperCase()}
                showError={this.props.shouldShowError}
                onChangeText={(text: string) => this.props.handleTranslationsChange('secondaryDescription', text, content.id)}
              />
            );
          })}
        </View>
        <LineView />
        <SectionTitleView title={"form.images"} />
          <View style={{ zIndex: 2 }}>
            {/* Images */}
            {this.props.languages.map((content: Language, j: any) => {
              return (
                <ExtendedImageView
                  title={'form.picture'}
                  index={j}
                  id={content.id as number}
                  source={this.props.product.secondaryImageTranslations[j].url}
                  shouldUseDefaultImage={this.props.shouldUseDefaultSecondaryImage}
                  value={content.key}
                  handleCheckboxChange={(value: boolean) => this.props.handleCheckboxChange('isDefaultSecondaryImage', value)}
                  handleOnComplete={(base64 : any) => this.props.handleOnComplete('secondaryImage', base64)}
                  handleOnPressImage={(index: number) => this.props.handleOnPressImage('secondaryImage', index)}
                />
              );
            })}
          </View>
      </View>
    );
  }
}