import { ContentViewModel, ContentView } from '@levelapp/softfabric';
import RefreshProductViewState from './RefreshProductViewState';
import RefreshProductViewProps from './RefreshProductViewProps';
import AdminProductOperations from 'src/Transfer/AdminProductOperations';
import ToastHelper from 'src/Common/Helpers/ToastHelper';
import Store from 'src/Common/DTO/Store';

export default class RefreshProductViewModel extends ContentViewModel<RefreshProductViewState, RefreshProductViewProps>
{
    private _adminProductOperations: AdminProductOperations;

    /* CONSTRUCTOR */
    constructor(view: ContentView)
    {
        super(view); 
        
        this.initialState({
            isProgressing: false
        }); 

        this._adminProductOperations = new AdminProductOperations();
    }

    refreshCache()
    {
        if(this.props().selectedStore === undefined) return;

        this.setField({ isProgressing: true });
        this._adminProductOperations.refreshCache(
            this.props().selectedStore as Store, 
            this.handleRefreshCacheResult = this.handleRefreshCacheResult.bind(this)
        );
    }

    handleRefreshCacheResult()
    {
        this.setField({ isProgressing: false });
        this.showToast(
            ToastHelper.getToast("orders.success.refreshProducts", "success"), 
            () => { }, 
            2500
        );
    }
  
}
