 
import { ContentViewModel, ContentView } from '@levelapp/softfabric';
import LoyaltyRulesViewState from './LoyaltyRulesViewState';
import LoyaltyRulesViewProps from './LoyaltyRulesViewProps';
import LoyaltyRulesSettingsOperation from '../../../../../../../Transfer/LoyaltyRulesSettingsOperations';
import LoyaltyRulesTranslationsOperation from '../../../../../../../Transfer/LoyaltyRulesTranslationsOperation';
import LoyaltyRulesUnitsOperation from '../../../../../../../Transfer/LoyaltyRulesUnitsOperation';

import { LoyaltyRulesKeys } from '../../../../../../../Common/Enums/LoyaltyRulesKeys';
import LoyaltyRulesUnit from '../../../../../../../Common/DTO/LoyaltyRulesUnit';
import ToastHelper from '../../../../../../../Common/Helpers/ToastHelper';
import LoyaltyRulesTranslation from '../../../../../../../Common/DTO/LoyaltyRulesTranslation';
import LoyaltyRulesTranslationPopupView from '../popup/LoyaltyRulesTranslationPopupView';
import LanguagesOperations from '../../../../../../../Transfer/LanguagesOperations';
import Language from '../../../../../../../Common/DTO/Language';

export default class LoyaltyRulesViewModel extends ContentViewModel<LoyaltyRulesViewState, LoyaltyRulesViewProps>
{
    settingsOperation: LoyaltyRulesSettingsOperation;
    unitsOperation: LoyaltyRulesUnitsOperation;
    languagesOperation : LanguagesOperations;
    translationsOperation: LoyaltyRulesTranslationsOperation;

    _languages: Language[] = [];

    constructor(props: ContentView)
    {
        super(props);

        /* Initial State */
        this.initialState({
            units: [],
            settings: [],
            translations: [],
            showErrors: false,
            isSaving: false,
            isLoading: true
        });
        this.settingsOperation = new LoyaltyRulesSettingsOperation();
        this.unitsOperation = new LoyaltyRulesUnitsOperation();
        this.translationsOperation = new LoyaltyRulesTranslationsOperation();

        this.languagesOperation = new LanguagesOperations();

        this.languagesOperation.get((languages: any) => {
            this._languages = languages.data;
        });

        /* BINDINGS */
        this.save = this.save.bind(this);
        this.getUnits = this.getUnits.bind(this);
        this.getUnit = this.getUnit.bind(this);
        this.getValue = this.getValue.bind(this);
        this.handleValueChanged = this.handleValueChanged.bind(this);
        this.handleUnitChanged = this.handleUnitChanged.bind(this);
        this.saveLoyaltyRuleTranslation = this.saveLoyaltyRuleTranslation.bind(this);
    }

    componentDidMount()
    {
        this.refresh();
    }

    refresh()
    {
        this.setField({ isLoading: true }, () =>
        {
            this.translationsOperation.get((data: any) =>
            {
                this.setField({ translations: data.data }, () =>
                {
                    this.unitsOperation.get((data: any) =>
                    {
                        this.setField({ units: data.data }, () =>
                        {
                            this.settingsOperation.get((data: any) =>
                            {
                                this.setField({ settings: data.data.loyaltyRules, isLoading: false });
                            });
                        });
                    });
                });
            });
        });
    }

    /**
     * Returns all possible dropdown values for given LoyaltyRule
     * @param key given loyalty rule's key
     */
    getUnits(key: LoyaltyRulesKeys): any[]
    {
        let units: any[] = [];
        this.state().units.filter(u => u.loyaltyRulesKeyId == key).forEach((unit: LoyaltyRulesUnit) =>
        {
            units.push({ id: unit.id, title: unit.label.toUpperCase(), name: unit.label.toUpperCase() });
        });
        return units;
    }

    /**
     * Get the unit of given LoyaltyRuleSetting
     * @param key given loyalty rule's key
     */
    getUnit(key: LoyaltyRulesKeys): LoyaltyRulesUnit | null
    {
        const results = this.state().settings.filter(x => x.loyaltyRulesKeyId == key);
        if (results.length == 0)
        {
            return null;
        }
        const units = this.state().units.filter(x => x.id == results[0].loyaltyRulesUnitId);
        if (units.length == 0)
        {
            return null;
        }
        return units[0];
    }

    /**
     * Get the unit of given LoyaltyRule
     * @param key given loyalty rule's key
     */
    getValue(key: LoyaltyRulesKeys): number
    {
        const results = this.state().settings.filter(x => x.loyaltyRulesKeyId == key);
        return results.length != 0 ? results[0].value : 0;
    }

    /**
     * Change the value of a LoyaltyRule
     * @param key given loyalty rule's key
     * @param value given loyalty rule's new value
     */
    handleValueChanged(key: LoyaltyRulesKeys, value: number | string)
    {
        // Slider value changed
        if (typeof value == "number")
        {
            this.setField((previousState) => previousState.settings.filter(x => x.loyaltyRulesKeyId == key)[0].value = value);
        }

        // Textinput value changed
        else
        {
            if (value.length > 0 && !isNaN(Number(value)))
            {
                this.setField((previousState) => previousState.settings.filter(x => x.loyaltyRulesKeyId == key)[0].value = Number(value));
            }
            else
            {
                this.setField((previousState) => previousState.settings.filter(x => x.loyaltyRulesKeyId == key)[0].value = -1);
            }
        }
        this.setField({ showErrors: false });
    }

    /**
     * Change the unit of a LoyaltyRule
     * Set the new unit value to default
     * @param key given loyalty rule's key
     * @param newUnit given loyalty rule's new unit
     */
    handleUnitChanged(key: LoyaltyRulesKeys, newUnit: number)
    {
        const unit = this.state().units.filter(x => x.id == newUnit)[0];
        this.setField((previousState) => previousState.settings.filter(x => x.loyaltyRulesKeyId == key)[0].loyaltyRulesUnitId = newUnit);
        this.setField((previousState) => previousState.settings.filter(x => x.loyaltyRulesKeyId == key)[0].value = unit.defaultValue);
    }

    /**
     * Open a popup containing the translation in the different languages
     * @param translation the translation pressed 
     */
    editLoyaltyRuleTranslation(translation: LoyaltyRulesTranslation)
    {
        this.showPopUp(<LoyaltyRulesTranslationPopupView save={this.saveLoyaltyRuleTranslation} translation={translation} languages={this._languages} />)
    }

    /**
     * Save the edited translation and close the popup
     * @param translation the translation edited
     */
    saveLoyaltyRuleTranslation(translation: LoyaltyRulesTranslation)
    {
        this.translationsOperation.put(translation, () =>
        {
            this.refresh();
            this.showToast(ToastHelper.getToast('loyaltyrules.messages.success.updated', 'success'), () => { }, 2500);
            this.closePopUp();
        });
    }

    save()
    {
        this.setField((previousState) => previousState.showErrors = false);
        this.setField((previousState) => previousState.isSaving = true);
        if (this.state().settings.filter(x => x.value == -1).length > 0)
        {
            this.setField((previousState) => previousState.showErrors = true);
            this.setField((previousState) => previousState.isSaving = false);
        }
        else
        {
            this.settingsOperation.put({ loyaltyRules: this.state().settings }, () =>
            {
                this.setField((previousState) => previousState.isSaving = false);
                this.showToast(ToastHelper.getToast('loyaltyrules.success.updated', 'success'), () => { }, 2500);
                this.refresh();
            });
        }
    }
}