import {Application, ContentViewStyle} from '@levelapp/softfabric';
import { StyleManager, UiManagers } from '@levelapp/softfabric-ui';
import {Dimensions, StyleProp,TextStyle,ViewStyle} from 'react-native';

export default class PopupStoresListViewStyle extends ContentViewStyle
{
    

    MyStyleManager : StyleManager = Application.current.resolve<StyleManager>(UiManagers.STYLE_MANAGER);
    
    page: StyleProp<ViewStyle> = {
        flex: 1
    }
    container : StyleProp<ViewStyle> =
    {
        display: "flex", 
        paddingHorizontal: 50, 
        paddingVertical: 50,
        overflow : 'hidden'
    }

    popup : StyleProp<ViewStyle> =
    {
        minHeight : (Dimensions.get('window').height /100) * 80 , 
    }

    storesContainer : StyleProp<ViewStyle> =
    {
        display : 'flex', 
        flexDirection : 'row',
        justifyContent : 'space-evenly',
        flexWrap : 'wrap',

        
    }

    storeItem : StyleProp<any> =
    {
        borderWidth: 0.5,
        paddingVertical : 5,
        margin: 10,
        borderRadius : 5,
        width : 400,
        alignItems : 'center',
        boxShadow: '0 1px 10px 0 rgba(0,0,0,0.13)'
    }
    

    selectedTxt  : StyleProp<TextStyle> =
    {
        color : "white"
    }

    unselectedTxt  : StyleProp<TextStyle> =
    {
    }

    
}
