import { Service, Application, HttpManager, Managers } from '@levelapp/softfabric';


export default class VouchersService extends Service {
    httpClient = Application.current.resolve<HttpManager>(Managers.HTTP_MANAGER);

    async get(parameters: any[], onSuccess: Function, onFailure: Function = () => { }) {
        this.httpClient.handleSomethingWentWrong = onFailure;
        this.httpClient.handleNotFound = onFailure;

        this.httpClient
            .get('Voucher', parameters)
            .then((response: any) => {
                if (response) {
                    if (response.data) {
                        onSuccess(response.data);
                    }
                }
            });
    }

    post(voucher: any, callback: Function = () => { }, onFailure: Function = () => { }) {
        this.httpClient.handleSomethingWentWrong = onFailure;
        this.httpClient.handleNotFound = onFailure;

        this.httpClient
            .post('Voucher', [], voucher)
            .then((response: any) => {
                if (response) {
                    /*if (response.data)
                    {
                        callback(response.data);
                    }*/
                    callback("");
                }
            });
    }

    put(voucher: any, callback: Function = () => { }, onFailure: Function = () => { }) {
        this.httpClient.handleSomethingWentWrong = onFailure;
        this.httpClient.handleNotFound = onFailure;

        this.httpClient
            .put('Voucher', [], voucher)
            .then((response: any) => {
                if (response) {
                    /*if (response.data)
                    {
                        callback(response.data);
                    }*/
                    callback("");
                }
            });
    }

    delete(voucher: any, callback: Function = () => { }, onFailure: Function = () => { }) {
        this.httpClient.handleSomethingWentWrong = onFailure;
        this.httpClient.handleNotFound = onFailure;

        this.httpClient
            .delete('Voucher/delete/' + voucher.id)
            .then((response: any) => {
                if (response) {
                    /*if (response.data)
                   {
                       callback(response.data);
                   }*/
                    callback("");
                }
            });
    }
}