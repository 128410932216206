import { ContentViewModel, ContentView } from '@levelapp/softfabric';
import SavingCardsPopUpViewState from './SavingCardsPopUpViewState';
import SavingCardsPopUpViewProps from './SavingCardsPopUpViewProps';
import Helpers from '../../../../../../../Common/Helpers/Helpers';
import DispatcherManager from '../../../../../../../Common/Managers/DispatcherManager';
import NumberHelper from '../../../../../../../Common/Helpers/NumberHelper';
import ImageTranslation from '../../../../../../../Common/DTO/ImageTranslation';
import TranslationHelper from '../../../../../../../Common/Helpers/TranslationHelper';
import DateHelper from '../../../../../../../Common/Helpers/DateHelper';
import SavingCardsOperations from '../../../../../../../Transfer/SavingCardsOperations';
import ToastHelper from '../../../../../../../Common/Helpers/ToastHelper';
import ErrorHelper from '../../../../../../../Common/Helpers/ErrorHelper';
import Constants from '../../../../../../../Common/Constants/Constants';

export default class SavingCardsPopUpViewModel extends ContentViewModel<SavingCardsPopUpViewState, SavingCardsPopUpViewProps>{

    durationUnits: any[];
    _refEndDate: any;
    _dispatcherManager: DispatcherManager;
    _savingCardOperation : SavingCardsOperations;

    constructor(props: ContentView)
    {
        super(props);

        this.durationUnits = [
            { title: 'Seconds', value: 0 },
            { title: 'Minutes', value: 1 },
            { title: 'Hours', value: 2 },
            { title: 'Days', value: 3 },
            { title: 'Weeks', value: 4 },
            { title: 'Months', value: 5 },
            { title: 'Years', value: 6 }
        ];

        this._savingCardOperation = new SavingCardsOperations();

        /* Initial State */
        this.initialState({
            savingCard: this.props().savingCard,
            languages: this.props().languages,
            showErrors: false,
            index: 0,
            indexThumbnail: 0,
            indexUnstamped: 0,
            indexStamped: 0,
            useDefaultImageChecked: Helpers.hasDuplicates(this.props().savingCard.imageTranslation),
            useDefaultThumbnailChecked: Helpers.hasDuplicates(this.props().savingCard.thumbnailTranslation),
            useDefaultImageStampedChecked: Helpers.hasDuplicates(this.props().savingCard.imageStampedTranslation),
            useDefaultImageUnstampedChecked: Helpers.hasDuplicates(this.props().savingCard.imageUnstampedTranslation),

            tab: "main",
            isLoading: false,
        });


        /* BINDINGS */
        this.changeTab = this.changeTab.bind(this);
        this.handleImage = this.handleImage.bind(this);
        this.setIndexImage = this.setIndexImage.bind(this);
        this.saveSavingCard = this.saveSavingCard.bind(this);
        this.setIndexStamped = this.setIndexStamped.bind(this);
        this.handleThumbnail = this.handleThumbnail.bind(this);
        this.errorManagement = this.errorManagement.bind(this);
        this.setIndexUnstamped = this.setIndexUnstamped.bind(this);
        this.handleSavePressed = this.handleSavePressed.bind(this);
        this.setIndexThumbnail = this.setIndexThumbnail.bind(this);
        this.handleTitleChanged = this.handleTitleChanged.bind(this);
        this.handleStampedImage = this.handleStampedImage.bind(this);
        this.handleStampedImage = this.handleStampedImage.bind(this);
        this.handleUnstampedImage = this.handleUnstampedImage.bind(this);
        this.handleEndDateChanged = this.handleEndDateChanged.bind(this);
        this.handleUnstampedImage = this.handleUnstampedImage.bind(this);
        this.handleStartDateChanged = this.handleStartDateChanged.bind(this);
        this.handleAnonymousChanged = this.handleAnonymousChanged.bind(this);
        this.handleRenewableChanged = this.handleRenewableChanged.bind(this);
        this.handleDescriptionChanged = this.handleDescriptionChanged.bind(this);
        this.handleDurationUnitChange = this.handleDurationUnitChange.bind(this);
        this.handleThirdPartIdChanged = this.handleThirdPartIdChanged.bind(this);
        this.handleEarningPosIdChanged = this.handleEarningPosIdChanged.bind(this);
        this.handleDefaultCheckBoxChanged = this.handleDefaultCheckBoxChanged.bind(this);
        this.handleConsumptionPosIdChanged = this.handleConsumptionPosIdChanged.bind(this);
        this.handleStampsQuantitiesChanged = this.handleStampsQuantitiesChanged.bind(this);
        this.handleValidityDurationChanged = this.handleValidityDurationChanged.bind(this);
        this.handleMarketingAutomationChanged = this.handleMarketingAutomationChanged.bind(this);
        this.handleDefaultCheckBoxStampedChanged = this.handleDefaultCheckBoxStampedChanged.bind(this);
        this.handleDefaultCheckBoxUnstampedChanged = this.handleDefaultCheckBoxUnstampedChanged.bind(this);
        this.handleDefaultCheckBoxThumbnailChanged = this.handleDefaultCheckBoxThumbnailChanged.bind(this);
        this.handlePreviewChanged = this.handlePreviewChanged.bind(this);
        this.handlePointsChange = this.handlePointsChange.bind(this);
        this.handleCouponIdChange = this.handleCouponIdChange.bind(this);

        this._dispatcherManager = new DispatcherManager();
    }

    /* COMPONENT EVENTS */
    componentWillMount()
    {
    }

    componentDidMount()
    {
        this._dispatcherManager.checkboxPreview = this.handlePreviewChanged;
        this._dispatcherManager.checkboxAnonymous = this.handleAnonymousChanged;
        this._dispatcherManager.checkboxRenewable = this.handleRenewableChanged;
        this._dispatcherManager.checkboxMarketingAutomation = this.handleMarketingAutomationChanged;
        this._dispatcherManager.checkboxDefaultImage = this.handleDefaultCheckBoxChanged;
        this._dispatcherManager.checkboxDefaultThumbnail = this.handleDefaultCheckBoxThumbnailChanged;
        this._dispatcherManager.checkboxDefaultImageUnstamped = this.handleDefaultCheckBoxUnstampedChanged;
        this._dispatcherManager.checkboxDefaultImageStamped = this.handleDefaultCheckBoxStampedChanged;

        this._dispatcherManager.valueConsumptionId = this.handleConsumptionPosIdChanged;
        this._dispatcherManager.valueThirdPartId = this.handleThirdPartIdChanged;
        this._dispatcherManager.valueProductId = this.handleEarningPosIdChanged;
        this._dispatcherManager.valueStampQuantity = this.handleStampsQuantitiesChanged;
        this._dispatcherManager.valueValidityDuration = this.handleValidityDurationChanged;
        this._dispatcherManager.valueDurationId = this.handleDurationUnitChange;

        this._dispatcherManager.dateEndDate = this.handleEndDateChanged;
        this._dispatcherManager.dateStartDate = this.handleStartDateChanged;

        this._dispatcherManager.translationTitle = this.handleTitleChanged;
        this._dispatcherManager.translationDescription = this.handleDescriptionChanged;

        this._dispatcherManager.numberIndexImage = this.setIndexImage;
        this._dispatcherManager.numberIndexStamped = this.setIndexStamped;
        this._dispatcherManager.numberIndexThumbnail = this.setIndexThumbnail;
        this._dispatcherManager.numberIndexUnstamped = this.setIndexUnstamped;

        this._dispatcherManager.uriImage = this.handleImage;
        this._dispatcherManager.uriStamped = this.handleStampedImage;
        this._dispatcherManager.uriThumbnail = this.handleThumbnail;
        this._dispatcherManager.uriUnstamped = this.handleUnstampedImage;
    }

    /* OTHERS */

    handleSavePressed()
    {
        // If Saving Card is used as badge : no imageStamp
        if (
            this.state().savingCard.earningPOSId == null || this.state().savingCard.earningPOSId == "" ||
            this.state().savingCard.consumptionPosId == null || this.state().savingCard.consumptionPosId == "" ||
            this.state().savingCard.validityDuration == null ||
            this.state().savingCard.stampsQuantities == null ||
            this.state().savingCard.StartDateInput == null ||
            this.state().savingCard.EndDateInput == null ||
            this.state().savingCard.titleTranslations.length != this.state().languages.length ||
            this.state().savingCard.descriptionTranslations.length != this.state().languages.length ||
            !Helpers.areImagesComplete(this.state().savingCard.thumbnailTranslation) ||
            !Helpers.areImagesComplete(this.state().savingCard.imageTranslation) ||
            (!Constants.IsSavingCardsUsedAsBadge && !Helpers.areImagesComplete(this.state().savingCard.imageStampedTranslation)) ||
            (!Constants.IsSavingCardsUsedAsBadge && !Helpers.areImagesComplete(this.state().savingCard.imageUnstampedTranslation)))
        {
            this.setField((previousState) => previousState.showErrors = true);
        }
        else
        {

            this.setField((previousState) => previousState.isLoading = true,() => this.saveSavingCard(this.state().savingCard));
        }
    }

    saveSavingCard(savingCard: any) {
        var startResult = DateHelper.changeDateToBeValidUTC(savingCard.StartDateInput);      
        var endResult = DateHelper.changeDateToBeValidUTC(savingCard.EndDateInput);
        savingCard.startDate = startResult;
        savingCard.endDate = endResult;

        for (var i = 0; i < this.props().languages.length; i++) {
            if (savingCard.imageTranslation[i].url.includes("data:image"))
                savingCard.imageTranslation[i].ImageData = savingCard.imageTranslation[i].url.replace("data:image/png;base64,", "").replace("data:image/jpeg;base64,", "");
            else
                savingCard.imageTranslation[i].ImageData = null;

            if (savingCard.thumbnailTranslation[i].url.includes("data:image"))
                savingCard.thumbnailTranslation[i].ImageData = savingCard.thumbnailTranslation[i].url.replace("data:image/png;base64,", "").replace("data:image/jpeg;base64,", "");
            else
                savingCard.thumbnailTranslation[i].ImageData = null;
            
            if(savingCard.imageStampedTranslation[i].url.includes("data:image")){
                savingCard.imageStampedTranslation[i].ImageData = savingCard.imageStampedTranslation[i].url.replace("data:image/png;base64,", "").replace("data:image/jpeg;base64,", "");
            }else{
                savingCard.imageStampedTranslation.ImageData = null;
            }
            if(savingCard.imageUnstampedTranslation[i].url.includes("data:image")){
                savingCard.imageUnstampedTranslation[i].ImageData = savingCard.imageUnstampedTranslation[i].url.replace("data:image/png;base64,", "").replace("data:image/jpeg;base64,", "");
            }else{
                savingCard.imageUnstampedTranslation.ImageData = null;
            }
            
        }

        if (savingCard.id !== undefined) {
            this._savingCardOperation.put(savingCard, () => {
                this.props().refreshParent();
                this.showToast(ToastHelper.getToast((Constants.IsSavingCardsUsedAsBadge) ? "badges.popup.success.updated" : "savingcards.popup.success.updated","success"),() => {},2500);
                this.closePopUp();
            },this.errorManagement);
        }

        else {
            this._savingCardOperation.post(savingCard, () => {
                this.props().refreshParent();
                this.showToast(ToastHelper.getToast((Constants.IsSavingCardsUsedAsBadge) ? "badges.popup.success.added" :"savingcards.popup.success.added","success"),() => {},2500);
                this.closePopUp();
            },this.errorManagement);
        }
    }

    errorManagement(error : any) 
    {
        this.setField({isLoading : false});
        ErrorHelper.showMessageError(error.response);
    }

    changeTab(tab: any)
    {
        this.setField((previousState) => previousState.tab = tab);
    }

    fillAllThumbnailsWithDefault()
    {
        if (this.state().useDefaultThumbnailChecked)
        {
            var list = Helpers.fillAllImagesWithDefault(this.state().savingCard.thumbnailTranslation, this.state().languages) as ImageTranslation[]
            this.setField((previousState) => previousState.savingCard.thumbnailTranslation = list);
        }
    }

    fillAllPicturesWithDefault()
    {
        if (this.state().useDefaultImageChecked)
        {
            var list = Helpers.fillAllImagesWithDefault(this.state().savingCard.imageTranslation, this.state().languages) as ImageTranslation[]
            this.setField((previousState) => previousState.savingCard.imageTranslation = list);
        }
    }

    fillAllStampedWithDefault()
    {
        if (this.state().useDefaultImageStampedChecked)
        {
            var list = Helpers.fillAllImagesWithDefault(this.state().savingCard.imageStampedTranslation, this.state().languages) as ImageTranslation[]
            this.setField((previousState) => previousState.savingCard.imageStampedTranslation = list);
        }
    }

    fillAllUnstampedWithDefault() 
    {
        if (this.state().useDefaultImageUnstampedChecked)
        {
            var list = Helpers.fillAllImagesWithDefault(this.state().savingCard.imageUnstampedTranslation, this.state().languages) as ImageTranslation[]
            this.setField((previousState) => previousState.savingCard.imageUnstampedTranslation = list);
        }
    }

    getDurationUnit()
    {
        return this.durationUnits;
    }

    /* VALUES */

    private handleThirdPartIdChanged(text: string)
    {
        this.setField((previousState) => previousState.savingCard.thridPartId = text);
    }

    private handleEarningPosIdChanged(text: string)
    {
        this.setField((previousState) => previousState.savingCard.earningPOSId = text);
    }

    private handleConsumptionPosIdChanged(text: string)
    {
        this.setField((previousState) => previousState.savingCard.consumptionPosId = text);
    }

    private handleStampsQuantitiesChanged(text: string)
    {
        var number = NumberHelper.convertStringToInt(text);
        this.setField((previousState) => previousState.savingCard.stampsQuantities = number);
    }

    private handleValidityDurationChanged(text: string)
    {
            var number = NumberHelper.convertStringToInt(text);
            this.setField((previousState) => previousState.savingCard.validityDuration = number);
    }

    private handleDurationUnitChange(text: any)
    {
        var check = NumberHelper.isAValidInt(text);
        if (check)
        {
            var number = NumberHelper.convertStringToInt(text);
            this.setField((previousState) => previousState.savingCard.validityDurationUnit = number);
        }
    }

    public handlePointsChange(text: any) {
        const points = parseInt(text, 10);
        const savingCard = this.state().savingCard;

        savingCard.points = points;
        savingCard.consumptionPosId = '0';
        savingCard.couponId = 0;
        this.setField(savingCard);
    }

    public handleCouponIdChange(text: any) {
        const couponId = parseInt(text, 10);
        const savingCard = this.state().savingCard;

        savingCard.points = 0;
        savingCard.consumptionPosId = '0';
        savingCard.couponId = couponId;
        this.setField(savingCard);
    }


    /* DATES */

    private handleStartDateChanged(text: Date)
    {
        this.setField((previousState) => previousState.savingCard.StartDateInput = text);
    }

    private handleEndDateChanged(text: Date)
    {
        this.setField((previousState) => previousState.savingCard.EndDateInput = text);
    }

    /* INDEX IMAGES */

    private setIndexImage(index: any)
    {
        this.setField((previousState) => previousState.index = index);
    }

    private setIndexThumbnail(index: any)
    {
        this.setField((previousState) => previousState.indexThumbnail = index);
    }

    private setIndexStamped(index: any)
    {
        this.setField((previousState) => previousState.indexStamped = index);
    }

    private setIndexUnstamped(index: any)
    {
        this.setField((previousState) => previousState.indexUnstamped = index);
    }


    /* URI IMAGES */

    private handleImage(base64 : any)
    {
        var state = this.state();
        TranslationHelper.image(state.savingCard.imageTranslation, state.languages, state.index, base64);
        this.setField({ state });
        this.fillAllPicturesWithDefault();
    }

    private handleThumbnail(base64 : any)
    {
        var state = this.state();
        TranslationHelper.image(state.savingCard.thumbnailTranslation, state.languages, state.indexThumbnail, base64);
        this.setField({ state });
        this.fillAllThumbnailsWithDefault();
    }

    private handleStampedImage(base64 : any)
    {
        var state = this.state();
        TranslationHelper.image(state.savingCard.imageStampedTranslation, state.languages, state.indexStamped, base64);
        this.setField({ state });
        this.fillAllStampedWithDefault();
    }

    private handleUnstampedImage(base64 : any)
    {
        var state = this.state();
        TranslationHelper.image(state.savingCard.imageUnstampedTranslation, state.languages, state.indexUnstamped, base64);
        this.setField({ state });
        this.fillAllUnstampedWithDefault();
    }

    /* TRANSLATIONS */

    private handleTitleChanged(text: any, index: any)
    {
        var state = this.state();
        TranslationHelper.translation(state.savingCard.titleTranslations, state.languages, index, text);
        this.setField({ state });
    }

    private handleDescriptionChanged(text: any, index: any)
    {
        var state = this.state();
        TranslationHelper.translation(state.savingCard.descriptionTranslations, state.languages, index, text);
        this.setField({ state });
    }

    /* CHECKBOX */

    private handleAnonymousChanged(value: boolean)
    {
        this.setField((previousState) => previousState.savingCard.isAnonymousVisible = value);
    }

    private handleRenewableChanged(value: boolean)
    {
        this.setField((previousState) => previousState.savingCard.isRenewableAutomaticaly = value);
    }

    private handleMarketingAutomationChanged(value: boolean)
    {
        this.setField((previousState) => previousState.savingCard.isMarketingAutomation = value);
    }

    private handleDefaultCheckBoxChanged(value: boolean)
    {
        this.setField((previousState) => previousState.useDefaultImageChecked = value);
        this.fillAllPicturesWithDefault();
    }

    private handleDefaultCheckBoxThumbnailChanged(value: boolean)
    {
        this.setField((previousState) => previousState.useDefaultThumbnailChecked = value);
        this.fillAllThumbnailsWithDefault();
    }

    private handleDefaultCheckBoxStampedChanged(value: boolean)
    {
        this.setField((previousState) => previousState.useDefaultImageStampedChecked = value);
        this.fillAllStampedWithDefault();
    }

    private handleDefaultCheckBoxUnstampedChanged(value: boolean)
    {
        this.setField((previousState) => previousState.useDefaultImageUnstampedChecked = value);
        this.fillAllUnstampedWithDefault();
    }

    private handlePreviewChanged(value : boolean) {
        this.setField((previousState) => previousState.savingCard.isPreview = value);
    }

}