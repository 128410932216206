 
import { ContentView } from '@levelapp/softfabric';
import TicketDetailsPopUpViewState from './TicketDetailsPopUpViewState';
import TicketDetailsPopUpViewProps from './TicketDetailsPopUpViewProps';
import TicketDetailsPopUpViewStyle from './TicketDetailsPopUpViewStyle';
import TicketDetailsPopUpViewModel from './TicketDetailsPopUpViewModel';
import { Image, Text, TouchableWithoutFeedback, View } from 'react-native';
import Constants from '../../../../../../../../../Common/Constants/Constants';
import LinearGradient from "react-native-web-linear-gradient";
import { TranslationView } from '@levelapp/softfabric-ui';
import TableView from '../../../../../../../../../Common/Components/TabelView/TableView';


export default class TicketDetailsPopUpView extends ContentView<TicketDetailsPopUpViewProps, TicketDetailsPopUpViewState, TicketDetailsPopUpViewModel, TicketDetailsPopUpViewStyle>
{
  /* CONSTRUCTOR */
  constructor(props: TicketDetailsPopUpViewProps) {
    super(props);

    // Binding
    this.bind(new TicketDetailsPopUpViewModel(this), new TicketDetailsPopUpViewStyle(this));
  }

  /* RENDERING */
  render() {
    return (
      <View style={{ minWidth: 600, minHeight: 850, width: Constants.PopUpWidth, maxWidth: "100%" }}>
        <View>
          <LinearGradient start={{ x: 0, y: 0 }} end={{ x: .1, y: 0 }} colors={[Constants.GradiantStartColor, Constants.GradiantEndColor]} >
            <View style={{ display: "flex", flexDirection: "row", alignItems: 'center' }}>
              <TranslationView style={this.style.title}>
                users.popup.title
              </TranslationView>



              <TouchableWithoutFeedback onPress={this.binding.close} >
                <Image style={{ height: 23, width: 23, resizeMode: 'contain', marginTop: 25, marginLeft: 20 }}
                  source={{ uri: 'https://appstoragflyx.blob.core.windows.net/app/Console/exit.png' }}></Image>
              </TouchableWithoutFeedback>
            </View>
          </LinearGradient>
        </View>

        <View style={this.style.ticketDetailsBox}>
          <Text>Hash : {this.state.ticket.hash}</Text>
          <Text>Number : {this.state.ticket.number}</Text>
          <Text>Operator : {this.state.ticket.operatorName}</Text>          
          <Text>Total price : {this.state.ticket.totalAmount}</Text>
          <Text>Terminal : {this.state.ticket.terminalName}</Text>          
          <Text>Date : {this.state.ticket.creationDate}</Text>
          <Text>Store : {this.state.ticket.entityCode} {this.state.storeName}</Text>

        </View>

        <View style={this.style.boxContainer}>
          <TableView
            headerTextStyle={this.style.tableHeader}
            backgroundStepColor={this.style.stepColor}
            nothingToDisplayText="No results"
            isLastColumnActions={false}
            data={this.state.tableData}
            isLoading={false}
            headers={this.state.tableHeaders}
            handleSortChange={() => { }}
            nbPage={1}
            onPageChange={() => { }}
            currentPage={1}
            isSortable={true} />
        </View>

      </View>
    );
  }
}