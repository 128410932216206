import { Operation } from "@levelapp/softfabric";
import StoresService from "../Proxy/StoresService";

export default class StoresOperations extends Operation
{
    service: StoresService;

    constructor()
    {
        super();
        this.service = new StoresService();
    }

    get(parameters: any[], callback: Function, onFailure : Function)
    {
        this.service.get(parameters, callback, onFailure);
    }
    getAll( callback: Function, onFailure : Function)
    {
        this.service.getAll(callback, onFailure);
    }

    getLight() {
      return this.service.getLight();
    }

    getallwithouttranslations(callback: Function)
    {
        this.service.getallwithouttranslations(callback);
    }

    getStoreTypes(callback: Function)
    {
        this.service.getStoreTypes(callback);
    }

    getStoreOrderSourcesLogs(storeId : number, callback: Function, onFailure : Function)
    {
        this.service.getStoreOrderSourcesLogs(storeId ,callback, onFailure);
    }

    post(store: any, callback: Function, onFailure : Function)
    {
        this.service.post(store, callback, onFailure);
    }

    put(store: any, callback: Function, onFailure : Function)
    {
        this.service.put(store, callback, onFailure);
    }

    updateClickAndCollectTemporary(store: any, callback: Function)
    {
        this.service.updateClickAndCollectTemporary(store, callback);
    }

    updateKioskTemporary(store: any, callback: Function)
    {
        this.service.updateKioskTemporary(store, callback);
    }

    delete(store: any, callback: Function, onFailure : Function)
    {
        this.service.delete(store, callback, onFailure);
    }
}