import { ContentView } from "@levelapp/softfabric";
 
import ProductCategoriesPopUpViewProps from "./ProductCategoriesPopUpViewProps";
import ProductCategoriesPopUpViewState from "./ProductCategoriesPopUpViewState";
import ProductCategoriesPopUpViewModel from "./ProductCategoriesPopUpViewModel";
import ProductCategoriesPopUpViewStyle from "./ProductCategoriesPopUpViewStyle";
import { View } from "react-native";
import Constants from "../../../../../../Common/Constants/Constants";
import { TranslationView } from "@levelapp/softfabric-ui";
import Select from "react-dropdown-select";
import LineView from "../../../components/line/LineView";
import ExtendedTextInputView from "../../../components/extendedtextinput/ExtendedTextInputView";
import PopupMenuItemView from "../../../components/popupmenuitem/PopupMenuItemView";
import PicturesView from "../../../components/pictures/PicturesView";
import Helpers from "../../../../../../Common/Helpers/Helpers";
import Language from "../../../../../../Common/DTO/Language";
import PopupHeaderView from "../../../components/popupheader/PopupHeaderView";
import SectionTitleView from "../../../components/sectiontitle/SectionTitleView";
import ExtendedCheckBoxView from "../../../components/extendedcheckbox/ExtendedCheckBoxView";
import ExtendedBlockTextInputView from "../../../components/extendedblocktextinput/ExtendedBlockTextInputView";



export default class ProductCategoriesPopUpView extends ContentView<ProductCategoriesPopUpViewProps, ProductCategoriesPopUpViewState, ProductCategoriesPopUpViewModel, ProductCategoriesPopUpViewStyle>
{
    constructor(props: ProductCategoriesPopUpViewProps)
    {
        super(props);

        //Binding 

        this.bind(new ProductCategoriesPopUpViewModel(this), new ProductCategoriesPopUpViewStyle(this));
    }

    /* RENDERING */
    render() {
        return(
        <View style={{ minWidth: 600, minHeight: 850, width: Constants.PopUpWidth, maxWidth: "100%" }}>

            <PopupHeaderView
              handleBackPressed={() =>
                {
                  this.props.refreshParent()
                  Helpers.closePopup()
                }}
              title={"products.categories.popup.title"}
              shouldShowButton
              isLoading={this.state.isLoading}
              handleOnPressButton={this.binding.handleSavePressed}
            /> 
            <View style={{ display: "flex", flexDirection: "row", flexWrap: "wrap" }}>
              <PopupMenuItemView activeItem={this.state.tab} title={"form.maininformation"} item={"main"} handleChangeTab={() => this.binding.changeTab("main")}/>
              <PopupMenuItemView activeItem={this.state.tab} title={"form.externalid"} item={"external"} handleChangeTab={() => this.binding.changeTab("external")}/>
              <PopupMenuItemView activeItem={this.state.tab} title={"form.images"} item={"pictures"} handleChangeTab={() => this.binding.changeTab("pictures")}/>  
            </View>
        {this.state.tab == "main" &&
          <View style={{ display: "flex", flexDirection: "column", paddingHorizontal: 170, paddingVertical: 50}}>
            <SectionTitleView title={'form.informations'}/>
            <View>

              <ExtendedTextInputView
                showError={this.state.showErrors && this.state.productcategory.orderIndex == 0}
                title={"form.index"}
                infoText={this.binding.translate('generic.orderindex.info').replace('{1}',this.binding.translate('products.categories.title.singular').toLowerCase())}
                value={this.state.productcategory.orderIndex}
                onChangeText={(text : string) => this.binding._dispatcherManager.handleValueChange('orderindex',text)}/>
              
              <ExtendedCheckBoxView
                isTranslation
                title={'form.anonymous'}
                value={this.state.productcategory.isAnonymousVisible}
                onValueChange={(value : boolean) => this.binding._dispatcherManager.handleCheckboxChange('isAnonymousVisible',value)}
              />
            
            </View>



            {/* Middle line */}
            <LineView/>

            <SectionTitleView title={'form.description'}/>
            <View style={{ zIndex : -1, display :'flex', flexDirection:'row', flexWrap:'wrap', justifyContent:'space-between'}}>
              {/* Title */}
              {this.state.languages.map((content: Language) => {
                var _text = Helpers.getTranslationValueByLanguageKey(this.state.productcategory.titleTranslations,content.key);
                return (
                  <ExtendedBlockTextInputView
                    value={_text}
                    title={content.key.toUpperCase()}
                    showError={this.state.showErrors}
                    onChangeText={(text: string) => this.binding._dispatcherManager.handleTranslationsChange('title',text,content.id)}
                  />
                );
              })}
            </View>
          </View>
        }
        {/* FIXME: refractor this */}        
        {this.state.tab == "external" &&
          <View style={{ display: "flex", flexDirection: "row", flexWrap: "wrap", justifyContent: 'space-between', paddingHorizontal : 170, paddingVertical:50}}>
              {/* List of externalIDs*/}
              <View style={this.style.section}>
                
                <TranslationView style={this.style.inputTitle} upperCase>form.externalid</TranslationView>
                <Select 
                values={this.state.productcategory.appProductCategoryExterns}
                options={this.state.productcategory.appProductCategoryExterns} 
                onChange={(value: any) => this.binding._dispatcherManager.handleValueChange('tpid',value)}
                multi={true}
                create={true}
                keepSelectedInList={true}
                clearable={true}
                labelField={"externalId"}
                valueField={"externalId"}
                
                />

              </View>
          </View>

        }
        {this.state.tab == "pictures" &&
          <PicturesView
            languages={this.state.languages}
            listImage={this.state.productcategory.imageTranslation}
            listThumbnail={this.state.productcategory.thumbnailTranslation}
            shouldUseDefaultImage={this.state.useDefaultImageChecked}
            shouldUseDefaultThumnail={this.state.useDefaultThumbnailChecked}
            handleOnComplete={this.binding._dispatcherManager.handleAnyChange}
            handleOnPressImage={this.binding._dispatcherManager.handleNumberChange}
            handleCheckboxChange={this.binding._dispatcherManager.handleCheckboxChange}
          />
        }
        {this.state.externalError &&
          < View style={this.style.footer} >
            <TranslationView style={{ color: "red" }}>ProductCategories.ExternalIdError</TranslationView>
          </View>
        }
        {this.state.showErrors &&
          < View style={this.style.footer} >
            <TranslationView style={{ color: "red" }}>error.please.fields</TranslationView>
          </View>
        }

            </View>

        );
    }

}