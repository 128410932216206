 
import {ContentView} from '@levelapp/softfabric';
import MainInformationViewState from './MainInformationViewState';
import MainInformationViewProps from './MainInformationViewProps';
import MainInformationViewStyle from './MainInformationViewStyle';
import MainInformationViewModel from './MainInformationViewModel';
import { View } from 'react-native';
import Language from '../../../../../../../../Common/DTO/Language';
import ExtendedBlockTextInputView from '../../../../../components/extendedblocktextinput/ExtendedBlockTextInputView';
import SectionTitleView from '../../../../../components/sectiontitle/SectionTitleView';


export default class MainInformationView extends ContentView<MainInformationViewProps, MainInformationViewState, MainInformationViewModel, MainInformationViewStyle>
{
  /* CONSTRUCTOR */
  constructor(props:MainInformationViewProps)
  {
    super(props);

    // Binding
    this.bind(new MainInformationViewModel(this), new MainInformationViewStyle(this));
  }

  /* RENDERING */
  render()
  {
    return (
      <View style={{ display: "flex", flexDirection: "column", paddingHorizontal: 170, paddingVertical: 50 }}>
        <SectionTitleView title={'form.titlefield'}/>

        <View style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', justifyContent: 'space-between' }}>
          {this.props.languages.map((content: Language) => {
            var result = this.props.product.titleTranslations.find((x: any) => x.language.id == content.id);
            var _text = result ? result.value : "";      
            return (
              <ExtendedBlockTextInputView
                value={_text}
                title={content.key.toUpperCase()}
                showError={this.props.showErrors && (_text == null || _text == "")}
                onChangeText={(text: string) => this.props.handleTranslationsChange('title',text, content.id)}
              />
            );
          })}
        </View>
      </View>
    );
  }
}