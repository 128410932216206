import { StyleProp, TextStyle, ViewStyle } from 'react-native';
import Constants from 'src/Common/Constants/Constants';

import { ContentViewStyle } from '@levelapp/softfabric';

export default class ManageProductsMenuViewStyle extends ContentViewStyle {
  tabs: StyleProp<ViewStyle> = {
    display: 'flex',
    flexDirection: 'row',
    marginTop: 10,
    paddingLeft: 10,
    borderBottomWidth: 1,
    borderBottomColor: '#E7E0E0'
  }

  tab: StyleProp<ViewStyle> = {
    marginLeft: 15,
    padding: 15,
  }

  label: StyleProp<TextStyle> = {
    fontSize: 18,
    color: '#B0B0B0',
  }

  selected: StyleProp<ViewStyle> = {
    borderBottomWidth: 3,
    borderBottomColor: Constants.MainColor,
  }

  labelSelected: StyleProp<TextStyle> = {
    color: "black",
  }
}