 
import MenuView from './components/menu/MenuView';
import { View, ScrollView, Image } from 'react-native';
import { Switch, Route, Redirect } from 'react-router';
import DashboardshellViewState from './DashboardshellViewState';
import DashboardshellViewProps from './DashboardshellViewProps';
import DashboardshellViewStyle from './DashboardshellViewStyle';
import DashboardshellViewModel from './DashboardshellViewModel';
import { ContentView, Application, LanguageManager, Managers } from '@levelapp/softfabric';
import ShellUsersView from './modules/users/ShellUsersView';
import ShellRewardsView from './modules/rewards/ShellRewardsView';
import ShellMenuconfiguratorView from './modules/menuconfigurator/ShellMenuconfiguratorView';
import ShellBoostersView from './modules/boosters/ShellBoostersView';
import ShellStoresView from './modules/stores/ShellStoresView';
import ShellStatsView from './modules/stats/ShellStatsView';
import ShellNewsView from './modules/news/ShellNewsView';
import ShellCouponsView from './modules/coupons/ShellCouponsView';
import ShellLegalTextsView from './modules/legaltexts/ShellLegalTextsView';
import ShellTraductionView from './modules/traduction/ShellTraductionView';
import ShellLoyaltyRulesView from './modules/loyaltyrules/pages/ShellLoyaltyRulesView';
import ShellFaqView from './modules/faq/ShellFaqView';
import ShellSavingCardsView from './modules/savingcards/ShellSavingCardsView';
import ShellMessagesView from './modules/messages/ShellMessagesView';
import ShellAutomatedMessagesView from './modules/automatedmessages/ShellAutomatedMessagesView';
import ShellPosSimulatorView from './modules/possimulator/ShellPosSimulatorView';
import ShellProductcategoriesView from './modules/productcategories/ShellProductcategoriesView';
import ShellOrdersView from './modules/orders/ShellOrdersView';
import ProfileMenuView from './components/profilemenu/ProfileMenuView';
import ShellAdminUsersView from './modules/adminusers/ShellAdminUsersView';
import RoundButtonView from './components/roundbutton/RoundButtonView';
import PermissionHelper from '../../../Common/Helpers/PermissionHelper';
import ShellRolePermissionView from './modules/rolepermission/ShellRolePermissionView';
import ShellPromoCodesView from './modules/promocodes/ShellPromoCodesView';
import ShellLanguageView from './modules/language/ShellLanguageView'
import ShellKitchenAppView from './modules/kitchenapp/ShellKitchenAppView';
import ShellTicketsView from './modules/tickets/ShellTicketsView';
import ShellEverydayValuesView from './modules/everydayvalues/ShellEverydayValuesView';
import ShellHealthStatusView from './modules/healthstatus/ShellHealthStatusView';
import ShellCountryView from './modules/country/ShellCountryView';
import ShellOverviewView from './modules/overview/ShellOverviewView';
import ShellStoresSummaryView from './modules/storessummary/ShellStoresSummaryView';
import ShellCrosssellconfiguratorView from './modules/crosssellconfigurator/ShellCrosssellconfiguratorView';
import ShellOutofstockView from './modules/outofstock/ShellOutofstockView';
import ShellContentTypeKeyConfiguratorView from './modules/contenttypekeyconfigurator/ShellContentTypeKeyConfiguratorView';
import Constants from '../../../Common/Constants/Constants';
import BannersView from './modules/banners/pages/banners/BannersView';
import ShellZonesView from './modules/zones/ShellZonesView';
import ShellProductsToolView from './modules/productstool/ShellProductsToolView';
import EarningpointsView from './modules/earningpoints/ShellEarningpointsView';

import assets from 'src/Common/Assets';
import ShellMembershipView from './modules/membership/ShellMembershipView';
import ShellVouchersView from './modules/vouchers/ShellVouchersView';
import ShellProductAdditionalInfosView from './modules/productadditionalinfos/ShellProductAdditionalInfosView';
import ShellProductsView from './modules/products/ShellProductsView';

export default class DashboardshellView extends ContentView<DashboardshellViewProps, DashboardshellViewState, DashboardshellViewModel, DashboardshellViewStyle>
{
  private startingPage: string = "/dashboard/users";
  private savingCardPath = (Constants.IsSavingCardsUsedAsBadge) ? "/:language/dashboard/badges" : "/:language/dashboard/savingCards";


  /* CONSTRUCTOR */
  constructor(props: DashboardshellViewProps) {
    super(props);

    // Binding
    this.bind(new DashboardshellViewModel(this), new DashboardshellViewStyle(this));
  }

  /* RENDERING */
  render() {

    return (
      <View style={this.style.container}>
        <MenuView animation={this.state.activeMenu} handleAnimation={this.binding.menuAnimation} />
        <ScrollView style={this.style.viewContainer}>
          <View style={{ flexDirection: 'row', zIndex: 2, width: '95%', alignSelf: 'center', justifyContent: 'flex-end' }}>


            <View>
              <View style={{ flexDirection: 'row' }}>
                <RoundButtonView type={'normal'} shouldUseDefaultPadding handleOnPress={() => { this.binding.fullScreen() }}>
                  <Image resizeMode='contain' style={{ width: 20, height: 20 }} source={this.binding.getImage()} />
                </RoundButtonView>
                {this.binding.showPrinterIcon() && (
                  <>
                  {this.binding.showDeliveryOptions() && (
                    <RoundButtonView
                      type="normal"
                      shouldUseDefaultPadding
                      handleOnPress={this.binding.showDeliveryPopUp}
                    >
                      <Image
                        resizeMode="contain"
                        style={{ width: 30, height: 30 }}
                        source={assets.deliverySettings}
                      />
                    </RoundButtonView>
                  )}
                    
                    <RoundButtonView
                      type="normal"
                      shouldUseDefaultPadding
                      handleOnPress={this.binding.clickSettings}>
                      <Image
                        resizeMode='contain'
                        style={{ width: 20, height: 20 }}
                        source={require('../../../Common/Assets/settings.svg').default}
                      />
                    </RoundButtonView>
                  </>
                )}
                <View>
                  <RoundButtonView type={'normal'} shouldUseDefaultPadding handleOnPress={() => { this.binding.menuProfileAnimation() }}>
                    <Image resizeMode='contain' style={{ width: 15, height: 15 }} source={require('../../../Common/Assets/profile.svg').default} />
                  </RoundButtonView>
                  <ProfileMenuView user={this.state.userClaims} activeMenuProfile={this.state.activeMenuProfile} />
                </View>

              </View>


            </View>

          </View>
          <Switch>
            {PermissionHelper.shouldShowUser() && <Route path="/:language/dashboard/users" render={() => <ShellUsersView />} />}
            {PermissionHelper.shouldShowTickets() && <Route path="/:language/dashboard/tickets" render={() => <ShellTicketsView />} />}
            {PermissionHelper.shouldShowRewards() && <Route path="/:language/dashboard/rewards" render={() => <ShellRewardsView />} />}
            {PermissionHelper.shouldShowCoupons() && <Route path="/:language/dashboard/coupons" render={() => <ShellCouponsView />} />}
            {PermissionHelper.shouldShowBoosters() && <Route path="/:language/dashboard/boosters" render={() => <ShellBoostersView />} />}
            {PermissionHelper.shouldShowProducts() && <Route path="/:language/dashboard/productAdditionalInfos" render={() => <ShellProductAdditionalInfosView />} />}
            {PermissionHelper.shouldShowProducts() && <Route path="/:language/dashboard/products" render={() => <ShellProductsView />} />}
            {PermissionHelper.shouldShowNews() && <Route path="/:language/dashboard/news" render={() => <ShellNewsView />} />}
            {PermissionHelper.shouldShowTranslations() && <Route path="/:language/dashboard/translation" render={() => <ShellTraductionView />} />}
            {PermissionHelper.shouldShowStats() && <Route path="/:language/dashboard/stats" render={() => <ShellStatsView />} />}
            {PermissionHelper.shouldShowLoyaltyRules() && <Route path="/:language/dashboard/loyaltyrules" render={() => <ShellLoyaltyRulesView />} />}
            {PermissionHelper.shouldShowLegalTexts() && <Route path="/:language/dashboard/legaltexts" render={() => <ShellLegalTextsView />} />}
            {PermissionHelper.shouldShowStores() && <Route path="/:language/dashboard/stores" render={() => <ShellStoresView />} />}
            {PermissionHelper.shouldShowFaq() && <Route path="/:language/dashboard/faq" render={() => <ShellFaqView />} />}
            {PermissionHelper.shouldShowSavingCards() && <Route path={this.savingCardPath} render={() => <ShellSavingCardsView />} />}
            {PermissionHelper.shouldShowMessages() && <Route path="/:language/dashboard/messages" render={() => < ShellMessagesView />} />}
            {PermissionHelper.shouldShowAutomatedMessages() && <Route path="/:language/dashboard/automatedmessages" render={() => <ShellAutomatedMessagesView />} />}
            {PermissionHelper.shouldShowRolePermissions() && <Route path="/:language/dashboard/rolepermissions" render={() => <ShellRolePermissionView />} />}
            {PermissionHelper.shouldShowAdminUsers() && <Route path="/:language/dashboard/adminusers" render={() => <ShellAdminUsersView />} />}
            {PermissionHelper.shouldShowPosSimulator() && <Route path="/:language/dashboard/possimulator" render={() => <ShellPosSimulatorView />} />}
            {PermissionHelper.shouldShowProductCategories() && <Route path="/:language/dashboard/productcategories" render={() => <ShellProductcategoriesView />} />}
            {PermissionHelper.shouldShowOrders() && <Route path="/:language/dashboard/orders" render={() => <ShellOrdersView />} />}
            {PermissionHelper.shouldShowKitchenApp() && <Route path="/:language/dashboard/kitchenapp" render={() => <ShellKitchenAppView />} />}
            {PermissionHelper.shouldShowMenuConfiguration() && <Route path="/:language/dashboard/menuconfigurator" render={() => <ShellMenuconfiguratorView />} />}
            {PermissionHelper.shouldShowPromoCodes() && <Route path="/:language/dashboard/promocodes" render={() => <ShellPromoCodesView />} />}
            {PermissionHelper.shouldShowEverydayValues() && <Route path="/:language/dashboard/everyday" render={() => <ShellEverydayValuesView />} />}
            {PermissionHelper.shouldShowHealthStatus() && <Route path="/:language/dashboard/healthstatus" render={() => <ShellHealthStatusView />} />}
            {PermissionHelper.shouldShowLanguage() && <Route path="/:language/dashboard/language" render={() => < ShellLanguageView />} />}
            {PermissionHelper.shouldShowCountries() && <Route path="/:language/dashboard/countries" render={() => <ShellCountryView />} />}
            {PermissionHelper.shouldShowOverview() && <Route path="/:language/dashboard/overview" render={() => <ShellOverviewView />} />}
            {PermissionHelper.shouldShowStoresSummary() && <Route path="/:language/dashboard/summarystores" render={() => <ShellStoresSummaryView />} />}
            {PermissionHelper.shouldShowCrossSellconfigurator() && <Route path="/:language/dashboard/crosssellconfigurator" render={() => <ShellCrosssellconfiguratorView />} />}
            {PermissionHelper.shouldShowOutOfStock() && <Route path="/:language/dashboard/outofstock" render={() => <ShellOutofstockView />} />}

            {PermissionHelper.shouldShowZoneTool() && <Route path="/:language/dashboard/tools/zones" render={() => <ShellZonesView />} />}
            {PermissionHelper.shouldShowProductTool() && <Route path="/:language/dashboard/tools/tproducts" render={() => <ShellProductsToolView />} />}
            <Route path="/:language/dashboard/banners" render={() => <BannersView />} />
            {PermissionHelper.shouldShowContentTypeKeyConfig() && <Route path="/:language/dashboard/contenttypekeyconfig" render={() => <ShellContentTypeKeyConfiguratorView />} />}
            {<Route path="/:language/dashboard/earningpoints" render={() => <EarningpointsView />} />}
            {PermissionHelper.shouldShowMembership() && <Route path="/:language/dashboard/membership" render={() => <ShellMembershipView />} />}
            {PermissionHelper.shouldShowVouchers() && <Route path="/:language/dashboard/vouchers" render={() => <ShellVouchersView />} />}

            <Route path="/:language/dashboard/" render={() => <Redirect to={"/" + Application.current.resolve<LanguageManager>(Managers.LANGUAGE_MANAGER).language + this.startingPage} />} />
          </Switch>
        </ScrollView>
      </View>

    );
  }
}