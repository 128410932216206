 
import {
  View,
  Image,
  TouchableOpacity
} from 'react-native';
import Popup from 'reactjs-popup';

import { ContentView } from '@levelapp/softfabric';
import { TranslationView } from '@levelapp/softfabric-ui';

import PopupMenuViewState from './PopupMenuViewState';
import PopupMenuViewProps from './PopupMenuViewProps';
import PopupMenuViewStyle from './PopupMenuViewStyle';
import PopupMenuViewModel from './PopupMenuViewModel';
import './popup.css';

import Assets from 'src/Common/Assets';

export default class PopupMenuView extends ContentView<PopupMenuViewProps, PopupMenuViewState, PopupMenuViewModel, PopupMenuViewStyle>
{
  /* CONSTRUCTOR */
  constructor(props: PopupMenuViewProps) {
    super(props);

    // Binding
    this.bind(new PopupMenuViewModel(this), new PopupMenuViewStyle(this));
  }

  /* RENDERING */ 
  render() {
    return (
      <Popup
        trigger={
          <button className="popup-trigger">
            <Image
              resizeMode='contain'
              style={this.style.image}
              source={Assets.vDots}
            />
          </button>
        }
        position="right center"
      >
        <View style={this.style.popup}>
          <TouchableOpacity
            style={this.style.popupItem}
            onPress={() => this.binding.openEditModal(this.props.productGroup!)}
          >
            <TranslationView style={this.style.text}>
              tools.products.popup.editGroup
            </TranslationView>
          </TouchableOpacity>
          <TouchableOpacity
            style={[this.style.popupItem, { marginTop: 10 }]}
            onPress={this.binding.openDeleteModal}
          >
            <TranslationView style={this.style.text}>
              tools.products.popup.deleteGroup
            </TranslationView>
          </TouchableOpacity>
        </View>
      </Popup>
    );
  }
}