import { Operation } from "@levelapp/softfabric";
import AdminProductService from "../Proxy/AdminProductService";
import Store from "../Common/DTO/Store";
import UpdateIntegration from "src/Common/DTO/UpdateIntegration";

export default class AdminProductOperations extends Operation {
  service: AdminProductService;

  constructor() {
    super();
    this.service = new AdminProductService();
  }

  refreshCache(store: Store, onSuccess: Function) {
    this.service.refreshCache(store, onSuccess);
  }

  updateMenuConfig(onSuccess: Function) {
    this.service.updateMenuConfig(onSuccess);
  }

  updateDelivery(storeId: number) {
    return this.service.updateDelivery(storeId);
  }

  fetchUberEatIntegrationStatus(storeId: number) {
    return this.service.fetchUberEatIntegrationStatus(storeId);
  }

  updateUberEatIntegration(integration: UpdateIntegration) {
    return this.service.updateUberEatIntegration(integration);
  }
}