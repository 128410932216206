import { Service, Application, HttpManager, Managers } from '@levelapp/softfabric';

export default class BasketStatusHistoriesService extends Service
{
    httpClient = Application.current.resolve<HttpManager>(Managers.HTTP_MANAGER);

    async get(basketId: number)
    {
        return await this.httpClient.get(`basketstatushistories?basketId=${basketId}`);
    }
}