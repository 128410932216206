 
import { ContentView } from '@levelapp/softfabric';
import FilterStatusViewState from './FilterStatusViewState';
import FilterStatusViewProps from './FilterStatusViewProps';
import FilterStatusViewStyle from './FilterStatusViewStyle';
import FilterStatusViewModel from './FilterStatusViewModel';
import { View, Text, Image } from 'react-native';

export default class FilterStatusView extends ContentView<FilterStatusViewProps, FilterStatusViewState, FilterStatusViewModel, FilterStatusViewStyle>
{
  /* CONSTRUCTOR */
  constructor(props: FilterStatusViewProps)
  {
    super(props);

    // Binding
    this.bind(new FilterStatusViewModel(this), new FilterStatusViewStyle(this));
  }

  /* RENDERING */
  render()
  {
    const { backgroundColor, secondaryColor, label, isSelected } = this.props;
    return (
      <View style={this.style.container}>
        <View style={[this.style.check, { backgroundColor: secondaryColor }]}>
          {isSelected && <Image source={require('../../assets/check.svg').default} style={{ height: 11, width: 14 }} />}
        </View>
        <Text style={{ zIndex: 2 }}>{label}</Text>
        <View style={[this.style.background, { backgroundColor: backgroundColor }]} />
      </View>
    );
  }
}