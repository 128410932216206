import { Operation } from "@levelapp/softfabric";
import StoreBrandService from '../Proxy/StoreBrandService';

export default class StoreBrandOperations extends Operation{
    service : StoreBrandService;

    constructor()
    {
        super();
        this.service = new StoreBrandService();
    }

    async get(storeId : number)
    {
        return await this.service.get(storeId);
    }
}