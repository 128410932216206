const ExtendedManagers =
{
    USER_MANAGER: "USER_MANAGER",
    APP_MANAGER: 'APP_MANAGER',
    INCIDENT_MANAGER: 'INCIDENT_MANAGER',
    PERMISSION_MANAGER: 'PERMISSION_MANAGER',
    STORAGE_MANAGER: 'STORAGE_MANAGER',
    INCIDENT_DATA_MANAGER: 'INCIDENT_DATA_MANAGER',
    COMMENTS_DATA_MANAGER: 'COMMENTS_DATA_MANAGER',
    PROFILE_DATA_MANAGER: 'PROFILE_DATA_MANAGER',
    AD_MANAGER: 'AD_MANAGER',
    AUTH_MANAGER: 'AUTH_MANAGER',
    DEVICE_MANAGER: 'DEVICE_MANAGER',
    IDENTITY_MANAGER: 'IDENTITY_MANAGER',
    ROLE_MANAGER: 'ROLE_MANAGER',
    DELIVERY_MANAGER: 'DELIVERY_MANAGER',
    KITCHEN_MANAGER: 'KITCHEN_MANAGER'
}

export default ExtendedManagers;