import {ContentViewStyle} from '@levelapp/softfabric';
import {StyleProp,ViewStyle} from 'react-native';

export default class LineViewStyle extends ContentViewStyle
{
    container: StyleProp<ViewStyle> = {      
        zIndex: -1,
        backgroundColor: "#D8D8D8",
        width: "100%",
        height: 1,
        marginTop: 30,
        marginBottom: 50
    }
}