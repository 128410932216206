import { Operation } from "@levelapp/softfabric";
import TimeZonesService from "../Proxy/TimeZonesService";

export default class TimeZonesOperations extends Operation
{
    service : TimeZonesService;

    constructor()
    {
        super();
        this.service = new TimeZonesService();
    }

    getAll(callback : Function, onFailure? : Function)
    {
        this.service.getAll(callback, onFailure);
    }
}