import { ContentViewModel, ContentView } from '@levelapp/softfabric';
import RewardsPopUpViewState from './RewardsPopUpViewState';
import RewardsPopUpViewProps from './RewardsPopUpViewProps';
import Helpers from '../../../../../../../Common/Helpers/Helpers';
import DispatcherManager from '../../../../../../../Common/Managers/DispatcherManager';
import TranslationHelper from '../../../../../../../Common/Helpers/TranslationHelper';
import ProductOperations from '../../../../../../../Transfer/ProductsOperations';
import RewardsOperations from '../../../../../../../Transfer/RewardsOperations';
import ToastHelper from '../../../../../../../Common/Helpers/ToastHelper';
import ErrorHelper from '../../../../../../../Common/Helpers/ErrorHelper';
import Product from '../../../../../../../Common/DTO/Product';
import NumberHelper from '../../../../../../../Common/Helpers/NumberHelper';
import TranslationValue from '../../../../../../../Common/DTO/TranslationValue';
import RewardProduct from '../../../../../../../Common/DTO/RewardProduct';

export default class RewardsPopUpViewModel extends ContentViewModel<RewardsPopUpViewState, RewardsPopUpViewProps>
{
    _dispatcherManager : DispatcherManager;
    _rewardOperation : RewardsOperations;
    _productOperation : ProductOperations;

    constructor(props: ContentView) {
        super(props);

        /* Initial State */
        this.initialState({
            reward: this.props().reward,
            languages: this.props().languages,
            showErrors: false,
            index: 0,
            indexThumbnail: 0,
            useDefaultImageChecked: Helpers.hasDuplicates(this.props().reward.imageTranslation),
            useDefaultThumbnailChecked: Helpers.hasDuplicates(this.props().reward.thumbnailTranslation),
            tab: "main",
            popups: "main",
            isLoading: false,
            products : []
        });

        this._rewardOperation = new RewardsOperations();
        this._productOperation = new ProductOperations();

        /* BINDINGS */
        this.changeTab = this.changeTab.bind(this);
        this.saveRewards = this.saveRewards.bind(this);
        this.setIndexImage = this.setIndexImage.bind(this);
        this.handleImage = this.handleImage.bind(this);
        this.errorManagement = this.errorManagement.bind(this);
        this.setIndexThumbnail = this.setIndexThumbnail.bind(this);
        this.handleThumbnail = this.handleThumbnail.bind(this);
        this.handleGetProducts = this.handleGetProducts.bind(this);
        this.handleSavePressed = this.handleSavePressed.bind(this);
        this.handlePosIdChanged = this.handlePosIdChanged.bind(this);
        this.handleTitleChanged = this.handleTitleChanged.bind(this);
        this.handleAddNewProduct = this.handleAddNewProduct.bind(this);
        this.handlePointsChanged = this.handlePointsChanged.bind(this);
        this.handleAnonymousChanged = this.handleAnonymousChanged.bind(this);
        this.handlePreviewChanged = this.handlePreviewChanged.bind(this);
        this.handleRefreshProducts = this.handleRefreshProducts.bind(this);
        this.handleArrayPosIdChanged = this.handleArrayPosIdChanged.bind(this);
        this.handleDescriptionChanged = this.handleDescriptionChanged.bind(this);
        this.handleDefaultCheckBoxChanged = this.handleDefaultCheckBoxChanged.bind(this);
        this.handleDefaultCheckBoxThumbnailChanged = this.handleDefaultCheckBoxThumbnailChanged.bind(this);

        this.handleContentTypeKeyValueChange = this.handleContentTypeKeyValueChange.bind(this);

        this._dispatcherManager = new DispatcherManager();

    }

    componentDidMount()
    {
        this._dispatcherManager.checkboxAnonymous = this.handleAnonymousChanged;
        this._dispatcherManager.checkboxPreview = this.handlePreviewChanged;
        this._dispatcherManager.checkboxDefaultImage = this.handleDefaultCheckBoxChanged;
        this._dispatcherManager.checkboxDefaultThumbnail = this.handleDefaultCheckBoxThumbnailChanged;

        this._dispatcherManager.valuePosId = this.handlePosIdChanged;
        this._dispatcherManager.valuePoints = this.handlePointsChanged;

        this._dispatcherManager.valueArrayPosId = this.handleArrayPosIdChanged;

        this._dispatcherManager.translationTitle = this.handleTitleChanged;
        this._dispatcherManager.translationDescription = this.handleDescriptionChanged;

        this._dispatcherManager.numberIndexImage = this.setIndexImage;
        this._dispatcherManager.numberIndexThumbnail = this.setIndexThumbnail;

        this._dispatcherManager.uriImage = this.handleImage;
        this._dispatcherManager.uriThumbnail = this.handleThumbnail;

        this._dispatcherManager.contentTypeKeyValue = this.handleContentTypeKeyValueChange;

        this._productOperation.getProductsBasicInfo([],this.handleGetProducts,()=>{});
    }

    /* COMPONENT EVENTS */
    componentWillMount() { }

    /* OTHERS */

    changeTab(tab: any) {
        this.setField((previousState) => previousState.tab = tab);
    }

    changePopups(popups: any) {
        this.setField((previousState) => previousState.popups = popups);
    }

    handleRefreshProducts()
    {
        this._productOperation.getProductsBasicInfo([],this.handleGetProducts,()=>{});
    }

    handleAddNewProduct()
    {
        this.changePopups("product")
    }

    // CHECK SAVE LOGIC

    handleSavePressed() {

        if (
            this.state().reward.posId == null || this.state().reward.posId == "" ||
            this.state().reward.points == null ||
            this.state().reward.titleTranslations.length != this.state().languages.length ||
            this.state().reward.descriptionTranslations.length != this.state().languages.length ||
            !Helpers.areImagesComplete(this.state().reward.thumbnailTranslation) || 
            !Helpers.areImagesComplete(this.state().reward.imageTranslation)) {

            this.setField((previousState) => previousState.showErrors = true);
        }
        else {
            this.saveRewards(this.state().reward);
        }
    }
    
    // SAVE LOGIC

    saveRewards(reward: any) {
        this.setField({isLoading : true});
        for (var i = 0; i < this.props().languages.length; i++) {
            if (reward.imageTranslation[i].url.includes("data:image"))
                reward.imageTranslation[i].ImageData = reward.imageTranslation[i].url.replace("data:image/png;base64,", "").replace("data:image/jpeg;base64,", "");
            else
                reward.imageTranslation[i].ImageData = null;

            if (reward.thumbnailTranslation[i].url.includes("data:image"))
                reward.thumbnailTranslation[i].ImageData = reward.thumbnailTranslation[i].url.replace("data:image/png;base64,", "").replace("data:image/jpeg;base64,", "");
            else
                reward.thumbnailTranslation[i].ImageData = null;
        }



        if (reward.id !== undefined) {
            this._rewardOperation.put(reward, () => {
                this.props().refreshParent();
                this.setField({isLoading : false});
                this.showToast(ToastHelper.getToast("rewards.popup.success.updated","success"),() => {},2500);
                this.closePopUp();
            },this.errorManagement);
        }
        else {
            reward.contentTypeKeyValue.map((contentTypeKeyValue: any) => {
                contentTypeKeyValue.contentTypeKey = null;
              })
            this._rewardOperation.post(reward, () => {
                this.props().refreshParent();
                this.setField({isLoading : false});
                this.showToast(ToastHelper.getToast("rewards.popup.success.added","success"),() => {},2500);
                this.closePopUp();
            },this.errorManagement);
        }
    }

    /* ERROR MANAGEMENT */

    errorManagement(error : any) 
    {
        //set loading : false
        this.setField({isLoading : false});
        ErrorHelper.showMessageError(error.response);
    }

    /* DEFAULT IMAGES */

    fillAllPicturesWithDefault() {
        if (this.state().useDefaultImageChecked) {
            var list = Helpers.fillAllImagesWithDefault(this.state().reward.imageTranslation,this.state().languages) as TranslationValue[];
            this.setField((previousState) => previousState.reward.imageTranslation = list);
        }
    }

    fillAllThumbnailsWithDefault() {
        if (this.state().useDefaultThumbnailChecked) {
            var list = Helpers.fillAllImagesWithDefault(this.state().reward.thumbnailTranslation,this.state().languages) as TranslationValue[] ;
            this.setField((previousState) => previousState.reward.thumbnailTranslation = list);
        }
    }

    /* GET VALUES */

    handleGetProducts(data : any)
    {
        this.setField({products : data});
    }

    /* VALUES */

    private handlePosIdChanged(text: string) {
        this.setField((previousState) => previousState.reward.posId = text);
    }

    private handleArrayPosIdChanged(products: Product[]) {
        var state = this.state();
        var list : RewardProduct[] = [];
        products.forEach((value : Product) =>
        {
            list.push({rewardId : this.state().reward.id == undefined ? -1 : this.state().reward.id as number, productId : value.id , product : value});
        });
        state.reward.rewardProducts = list;
        this.setField({state});
    }

    private handlePointsChanged(text: string) {
        this.setField((previousState) => previousState.reward.points = NumberHelper.convertStringToInt(text));
    }

    /* INDEX IMAGE */

    private setIndexImage(index: any) {
        this.setField((previousState) => previousState.index = index);
    }

    private setIndexThumbnail(index: any) {
        this.setField((previousState) => previousState.indexThumbnail = index);
    }

    /* URI IMAGE */

    private handleImage(base64 : any) {
        var state = this.state();
        TranslationHelper.imageTranslation(state.reward.imageTranslation,state.languages,state.index,base64);
        this.setField({state});
        this.fillAllPicturesWithDefault();
    }

    private handleThumbnail(base64 : any) {
        var state = this.state();
        TranslationHelper.imageTranslation(state.reward.thumbnailTranslation,state.languages,state.indexThumbnail,base64);
        this.setField({state});
        this.fillAllThumbnailsWithDefault();
    }

    /* CHECKBOXES */

    private handleDefaultCheckBoxChanged(value : boolean) {
        this.setField((previousState) => previousState.useDefaultImageChecked = value, () => {
            this.fillAllPicturesWithDefault();
        });
    }

    private handleDefaultCheckBoxThumbnailChanged(value : boolean) {
        this.setField((previousState) => previousState.useDefaultThumbnailChecked = value, () => {
            this.fillAllThumbnailsWithDefault();
        });
    }

   /* TRANSLATIONS */

    private handleTitleChanged(text: any, index: any) {
        var state = this.state();
        TranslationHelper.translation(state.reward.titleTranslations,state.languages,index,text);
        this.setField({state});
    }

    private handleDescriptionChanged(text: any, index: any) {
        var state = this.state();
        TranslationHelper.translation(state.reward.descriptionTranslations,state.languages,index,text);
        this.setField({state});

    }

    /* CHECKBOX */

    private handleAnonymousChanged(value : boolean) {
        this.setField((previousState) => previousState.reward.isAnonymousVisible = value);
    }
    private handlePreviewChanged(value : boolean) {
        this.setField((previousState) => previousState.reward.isPreview = value);
    }

    /* Content key value */

    private handleContentTypeKeyValueChange(value : any)
    {
        this.setField((previousState) => previousState.reward.contentTypeKeyValue = value);
    }
} 