import { ContentViewModel, ContentView, Application, LanguageManager, Managers } from '@levelapp/softfabric';
import PromoCodesViewState from './PromoCodesViewState';
import PromoCodesViewProps from './PromoCodesViewProps';
import { TableRow } from '@levelapp/softfabric-ui';
 
import ConfirmPopUpView from '../../../../../../../Common/Components/confirmpopup/ConfirmPopUpView';
import PromoCodesOperations from '../../../../../../../Transfer/PromoCodesOperations';
import LanguagesOperations from '../../../../../../../Transfer/LanguagesOperations';
import Language from '../../../../../../../Common/DTO/Language';
import Helpers from '../../../../../../../Common/Helpers/Helpers';
import DateHelper from '../../../../../../../Common/Helpers/DateHelper';
import PromoCodeBatch from '../../../../../../../Common/DTO/PromoCodeBatch';
import PromoCodeBatchPopupView from '../../popup/promocodebatchpopup/PromoCodeBatchPopupView';
import ToastHelper from '../../../../../../../Common/Helpers/ToastHelper';
import PromoCode from '../../../../../../../Common/DTO/PromoCode';
import Product from '../../../../../../../Common/DTO/Product';
import PaginationHelper from '../../../../../../../Common/Helpers/PaginationHelper';
import ErrorHelper from '../../../../../../../Common/Helpers/ErrorHelper';
import CountryOperations from '../../../../../../../Transfer/CountryOperations';
import Country from '../../../../../../../Common/DTO/Country';
import CountryPromoCodeBatch from '../../../../../../../Common/DTO/M&N/CountryPromoCodeBatch';

export default class PromoCodesViewModel extends ContentViewModel<PromoCodesViewState, PromoCodesViewProps>
{
    _promoCodesOperations: PromoCodesOperations;
    languagesOperation: LanguagesOperations;
    countryOperations : CountryOperations;
    _languages: Language[] = [];
    _countries: Country[] = [];
    interval: any;
    searchText: string;
    isSorting: boolean;
    indexHeaderSort: number;
    sortOrder: string;
    sortKey: string[];
    moment : any;

     constructor(props: ContentView) {
        super(props);

        this.interval = null;
        this.searchText = '';
        this.isSorting = false;
        this.indexHeaderSort = -1;
        this.sortOrder = 'asc';
        this.sortKey = ['StartDate', 'EndDate', 'Value', 'IsSingleCode'];
        this.moment = require('moment');
        /* Initial State */
        this.initialState({ tableData: [], tableHeaders: ['StartDate', 'EndDate', 'Value', 'Is Single Code', 'Is Free Product'], isLoading: true, nbPage: 0, currentPage: 1, isResearching: false });
        this._promoCodesOperations = new PromoCodesOperations();
        this.languagesOperation = new LanguagesOperations();
        this.countryOperations = new CountryOperations();

        this.languagesOperation.get((languages: any) => {
            this._languages = languages.data;
        });
        this.countryOperations.get((countries:Country[]) => {
            this._countries = countries;
        })

        /* BINDINGS */
        this.editPCB = this.editPCB.bind(this);
        this.addPCB = this.addPCB.bind(this);
        this.deletePCB = this.deletePCB.bind(this);
        this.refresh = this.refresh.bind(this);
        this.onPageChange = this.onPageChange.bind(this);
        this.onSortChange = this.onSortChange.bind(this);
        this.handleResearching = this.handleResearching.bind(this);
        this.handleCloseResearching = this.handleCloseResearching.bind(this);
        this.errorManagement = this.errorManagement.bind(this);
    }

    componentDidMount() {
        this.refresh();
    }

    refresh() {
        this.setField({ isLoading: true });
        let parameters = PaginationHelper.basicResearchPush(
            this.state().currentPage,20,
            this.sortKey[this.indexHeaderSort],
            this.sortOrder,
            Application.current.resolve<LanguageManager>(Managers.LANGUAGE_MANAGER).language,
            this.searchText,
            this.isSorting,
            this.state().isResearching)
       
        this.getData(parameters);
    }

    addPCB() {
        const p = {} as Product;
        const rp = {} as Product;
        const cpb = [] as CountryPromoCodeBatch[];
        this.showPopUp(
            <PromoCodeBatchPopupView
                promocodebatch={{
                    posId : "0", 
                    titleTranslations: Helpers.generateTranslationTable(this._languages),
                    descriptionTranslations: Helpers.generateTranslationTable(this._languages),
                    isFreeProduct : false,
                    type : 1,
                    product : p,
                    requiredProduct : rp,
                    countryPromoCodeBatches : cpb
                }}
                languages={this._languages}
                refreshParent={this.refresh}
                countries={this._countries} />
        );
    }

    getData(queryParamaters : any[]) {
        this._promoCodesOperations.get(queryParamaters, (promocodebatches: any) => {
            let tableData: TableRow[] = [];
            promocodebatches.data.objects.forEach((promocodebatch: PromoCodeBatch) => {
                promocodebatch.StartDateInput = new Date(promocodebatch.startDate);
                promocodebatch.EndDateInput = new Date(promocodebatch.endDate);
                let singleCodeValue = "No";
                if(promocodebatch.isSingleCode)
                    singleCodeValue = "Yes";
                let freeProductValue = "No";
                if(promocodebatch.type == 2)
                {
                    promocodebatch.isFreeProduct = true;
                    freeProductValue = "Yes";
                }
                else
                    promocodebatch.isFreeProduct = false;
                tableData.push({ data: [
                    DateHelper.formatToEuropeanDate(promocodebatch.startDate), 
                    DateHelper.formatToEuropeanDate(promocodebatch.endDate), 
                    promocodebatch.value,
                    singleCodeValue,
                    freeProductValue],
                    isDeletable: true, 
                    isEditable: true, 
                    editCallback: () => { this.editPCB(promocodebatch) }, 
                    deleteCallback: () => { this.deletePCB(promocodebatch) } });
            });
            this.setField({ tableData: tableData, promocodebatches: promocodebatches.data.objects, nbPage: promocodebatches.data.totalPage, isLoading: false });
        },this.errorManagement)
    }

    editPCB(promocodebatch: any) {
        let pcbCopy = Helpers.clone(promocodebatch);
        pcbCopy.StartDateInput = new Date(pcbCopy.startDate);
        pcbCopy.EndDateInput = new Date(pcbCopy.endDate);
        if (pcbCopy.titleTranslations === undefined || pcbCopy.titleTranslations === null) {
            pcbCopy.titleTranslations = Helpers.generateTranslationTable(this._languages);
        }
        if (pcbCopy.descriptionTranslations === undefined || pcbCopy.descriptionTranslations === null) {
            pcbCopy.descriptionTranslations = Helpers.generateTranslationTable(this._languages);
        }
        this.showPopUp(
            <PromoCodeBatchPopupView promocodebatch={pcbCopy} refreshParent={this.refresh} languages={this._languages} countries={this._countries} />
        );
        this.refresh();
    }

    deletePCB(promocodebatch: PromoCodeBatch) {
        this.showPopUp(
            <ConfirmPopUpView callBack={() => {
                this._promoCodesOperations.deleteBatch(promocodebatch, () => {
                    this.showToast(ToastHelper.getToast("promocodes.popup.success.deleted","success"),() => {},2500);
                    this.refresh();
                },this.errorManagement);
            }} />
        );
    }

    

    deletePromoCode(promocode: PromoCode) {
        this.showPopUp(
            <ConfirmPopUpView callBack={() => {
                this._promoCodesOperations.delete(promocode, () => {
                    this.showToast(ToastHelper.getToast("promocodes.popup.success.deleted","success"),() => {},2500);
                    this.refresh();
                },this.errorManagement);
            }} />
        );
    }

    onPageChange(page: number) {
        this.state().currentPage = page + 1;
        this.refresh()
    }

    onSortChange(indexHeaderSort: number, sortOrder: string) {
        if (indexHeaderSort == -1) {
            this.isSorting = false;
        } else {
            this.indexHeaderSort = indexHeaderSort;
            this.sortOrder = sortOrder;
            this.isSorting = true;
        }
        this.refresh();
    }

    handleResearching(searchText: string) {
        this.setField({ isLoading: true, isResearching: true });
        this.searchText = searchText;

        if (this.interval) {
            clearInterval(this.interval);
        }
        this.interval = setTimeout(() => {
            this.onPageChange(0)
        }, 1000)
    }

    handleCloseResearching() {
        this.state().isResearching = false;
        this.onPageChange(0);
    }

    errorManagement(error : any) 
    {
        this.setField({isLoading : false});
        ErrorHelper.showMessageError(error.response);
    }
}
