import {ContentViewStyle} from '@levelapp/softfabric';
import {StyleProp,ViewStyle, TextStyle} from 'react-native';

export default class BasketordertypesmanagementViewStyle extends ContentViewStyle
{
    page: StyleProp<ViewStyle> = {
        flex: 1
    }
    body : StyleProp<ViewStyle> =
    { 
        display: "flex", 
        flexDirection: "column", 
        paddingHorizontal: 170, 
        paddingVertical: 50 
    }

    text : StyleProp<TextStyle> =
    {
        fontSize: 25,
        fontWeight: '600',
        color: '#3F3F3F',
        fontFamily: 'Montserrat SemiBold',
        marginBottom: 13,   
    } 

    inputTitle: StyleProp<TextStyle> = {
        fontSize: 14,
        fontWeight: '600',
        color: '#3F3F3F',
        fontFamily: 'Montserrat SemiBold'
    };

    createButton: StyleProp<ViewStyle> = {
        height : 20,
        width : 300
    };
}