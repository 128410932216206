import { Operation } from '@levelapp/softfabric';
import RoleService from '../../Proxy/RoleService';
import Role from '../../Common/DTO/Role';
import UserRoles from '../../Common/DTO/M&N/UserRoles';

export default class RoleOperations extends Operation
{
    _service : RoleService;

    constructor()
    {
        super();
        this._service = new RoleService();
    }

    async updateRole(role : Role,  callback: Function = () => { })
    {
        await (this._service as RoleService).update(role,callback);
    }

    async deleteRole(roleID : string , callback: Function = () => { })
    {
        await (this._service as RoleService).delete(roleID,callback);
    }

    async createRole(role : Role , callback: Function = () => { })
    {
        await (this._service as RoleService).post(role,callback);
    }

    async getRoles(callback: Function = () => { })
    {
        await (this._service as RoleService).get(callback);
    }

    async getPermissionByRoles(role : string , callback : Function = () => { })
    {
        await (this._service as RoleService).getPermissionsByRole(role,callback);
    }

    async getUsersWithRoles(callback : Function = () => { })
    {
        await (this._service as RoleService).getUsersWithRole(callback);
    }

    async deleteUserRole(userRole : UserRoles ,callback : Function = () => { } , onError : Function = () => {})
    {
        await (this._service as RoleService).deleteUserWithRole(userRole,callback,onError);
    }

    async updateUserRole(userRole : UserRoles ,callback : Function = () => { } , onError : Function = () => {})
    {
        await (this._service as RoleService).updateUserWithRole(userRole,callback,onError);
    }

    async createUserRole(userRole : UserRoles ,callback : Function = () => { } , onError : Function = () => {})
    {
        await (this._service as RoleService).createUserWithRole(userRole,callback,onError);
    }
}