import {ContentViewModel,ContentView} from '@levelapp/softfabric';
import InformationSectionViewState from './InformationSectionViewState';
import InformationSectionViewProps from './InformationSectionViewProps';

export default class InformationSectionViewModel extends ContentViewModel<InformationSectionViewState, InformationSectionViewProps>
{
      /* CONSTRUCTOR */
    constructor(view: ContentView)
    {
        super(view);

        this.initialState({}); // Initialize your state here

        // Bindings

    }
}