 
import { ContentView } from '@levelapp/softfabric';
import BannersPopUpViewState from './BannersPopUpViewState';
import BannersPopUpViewProps from './BannersPopUpViewProps';
import BannersPopUpViewStyle from './BannersPopUpViewStyle';
import BannersPopUpViewModel from './BannersPopUpViewModel';
import { View, Text, TouchableWithoutFeedback, Image } from 'react-native';
import { TranslationView } from '@levelapp/softfabric-ui';
import "react-datepicker/dist/react-datepicker.css";
import 'react-datepicker/dist/react-datepicker-cssmodules.css';
import Constants from '../../../../../../../Common/Constants/Constants';
import PopupMenuItemView from '../../../../components/popupmenuitem/PopupMenuItemView';
import LineView from '../../../../components/line/LineView';
import PicturesView from '../../../../components/pictures/PicturesView';
import Helpers from '../../../../../../../Common/Helpers/Helpers';
import Select from "react-dropdown-select";
import SectionTitleView from '../../../../components/sectiontitle/SectionTitleView';
import SeparationLineView from '../../../../../../../Common/Components/separationline/SeparationLineView';
import EditableDropDownView from '../../../../../../../Common/Components/EditableDropDown/EditableDropDownView';
import ExtendedDropDownView from '../../../../components/extendeddropdown/ExtendedDropDownView';
import ExtendedTextInputView from '../../../../components/extendedtextinput/ExtendedTextInputView';
import PopupHeaderView from 'src/Presentation/modules/dashboard/components/popupheader/PopupHeaderView';

export default class BannersPopUpView extends ContentView<BannersPopUpViewProps, BannersPopUpViewState, BannersPopUpViewModel, BannersPopUpViewStyle>
{
  /* CONSTRUCTOR */
  constructor(props: BannersPopUpViewProps) {
    super(props);
    // Binding
    this.bind(new BannersPopUpViewModel(this), new BannersPopUpViewStyle(this));
  }

  renderRewardTag(item: { id: number, key: string, title: string }): JSX.Element {
    return (
      <View key={`${item.id}-${item.key}`}>
        <TouchableWithoutFeedback onPress={() => { this.binding.removeTag(item) }}>
          <View style={this.style.tag}>
            <Text style={this.style.tagText}>{item.title}</Text>
            <Image style={this.style.tagIcon} source={require('../../../../assets/clear.svg').default}></Image>
          </View>
        </TouchableWithoutFeedback>
      </View>
    )
  }

  /* RENDERING */
  render() {
    return (
      <View style={{ minWidth: 600, minHeight: 850, width: Constants.PopUpWidth, maxWidth: "100%" }}>
        <View>
          <PopupHeaderView
            handleBackPressed={Helpers.closePopup}
            title={"banner.popup.title"}
            shouldShowButton
            isLoading={this.state.isLoading}
            handleOnPressButton={this.binding.handleSavePressed}
          />  
        </View>

        <View style={{ display: "flex", flexDirection: "row", flexWrap: "wrap" }}>
          <PopupMenuItemView activeItem={this.state.tab} title={"form.maininformation"} item={"main"} handleChangeTab={() => this.binding.changeTab("main")} />
        </View>

        {this.state.tab == "main" &&
          <View style={{ display: 'flex', flexDirection: 'column', paddingHorizontal: 170, paddingVertical: 50 }}>

           <View>
           <ExtendedTextInputView
                disable
                title={'form.id'}
                showError={false}
                value={`${this.props.banner.id == undefined ? ' ' :this.props.banner.id}`}
                onChangeText={() =>{}}
            />
            </View>

            <View>
              <View>
                < SeparationLineView />
                <ExtendedDropDownView
                  title={"kitchenapp.filters.sources"}
                  values={this.state.filters}
                  selectedValue={(this.state.filters.filter(x => x.id == this.state.banner.orderSource.id)[0]) ? 
                    this.state.filters.filter(x => x.id == this.state.banner.orderSource.id)[0]
                    : "KIOSQ"}
                  onValueChange={(value: any) => this.binding.handleDropDownChange(value)}
                />
              </View>

              <View>
                < SeparationLineView />

                <SectionTitleView title={'form.screen'} />
                <EditableDropDownView values={this.binding.getFormattedRewardTags()} onValidation={this.binding.addTag} blockedValues={this.binding.getLinkedTags()} />
                <View style={this.style.tags}>
                  {
                    this.binding.getLinkedTags().map(rewardTag => this.renderRewardTag(rewardTag))
                  }
                </View>
              </View>

              <LineView />

              <PicturesView
                languages={this.props.languages}
                listImage={this.state.banner.imageTranslation}
                listThumbnail={this.state.banner.imageTranslation}
                handleOnComplete={this.binding._dispatcherManager.handleAnyChange}
                handleOnPressImage={this.binding._dispatcherManager.handleNumberChange}
                handleCheckboxChange={this.binding._dispatcherManager.handleCheckboxChange}
                shouldUseDefaultImage={this.state.useDefaultImageChecked}
                shouldUseDefaultThumnail={true}
                shouldSkipThumnail={true}
              />

              <View>
                <SectionTitleView title={"form.country"} />
                <Select
                  placeholder="Select countries"
                  color={this.state.color}
                  values={this.state.selectedCountries}
                  options={this.props.countries}
                  onChange={(value: any) => this.binding._dispatcherManager.handleValueChange('country', value)}
                  multi={true}
                  create={false}
                  keepSelectedInList={false}
                  clearable={true}
                  labelField={"countryCode"}
                  valueField={"id"}
                  searchBy={"countryCode"}
                />
              </View>
            </View>
          </View>
        }

        {this.state.showErrors &&
          < View style={this.style.footer} >
            <TranslationView style={{ color: "red" }}>error.please.fields</TranslationView>
          </View>
        }
      </View>
    );
  }
}