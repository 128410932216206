import {ContentViewModel,ContentView} from '@levelapp/softfabric';
import AdminUsersViewState from './AdminUsersViewState';
import AdminUsersViewProps from './AdminUsersViewProps';
import RoleOperations from '../../../../../../../Transfer/Identity/RoleOperations';
import UserRole from '../../../../../../../Common/DTO/M&N/UserRoles';
import { TableRow } from '@levelapp/softfabric-ui';
import ToastHelper from '../../../../../../../Common/Helpers/ToastHelper';
import AdminUsersPopupView from '../adminuserspopup/AdminUsersPopupView';
 
import Role from '../../../../../../../Common/DTO/Role';
import UserIdentity from '../../../../../../../Common/DTO/Identity/UserIdentity';
import ConfirmPopUpView from '../../../../../../../Common/Components/confirmpopup/ConfirmPopUpView';
import UsersOperations from 'src/Transfer/UsersOperations';

export default class AdminUsersViewModel extends ContentViewModel<AdminUsersViewState, AdminUsersViewProps>
{
    //_adminUsersOperations : AdminUsersOperations;
    _roleOperations : RoleOperations;
    _usersOperation : UsersOperations;

      /* CONSTRUCTOR */
    constructor(view: ContentView)
    {
        super(view);

        this.initialState(
            { 
            tableData: [], 
            tableHeaders: ['Id', 'Email', 'Roles'], 
            isLoading: false, 
            nbPage: 0, 
            currentPage: 1, 
            isResearching: false 
            });


        // Bindings
        this.getData = this.getData.bind(this);
        this.refresh = this.refresh.bind(this);
        this.addField = this.addField.bind(this);
        this.editField = this.editField.bind(this);
        this.handlCreateError = this.handlCreateError.bind(this);
        this.handleGetInfo = this.handleGetInfo.bind(this);
        this.saveAdminUser = this.saveAdminUser.bind(this);

        // Operations
        //this._adminUsersOperations = new AdminUsersOperations();
        this._roleOperations = new RoleOperations();
        this._usersOperation = new UsersOperations();
    }

    componentDidMount() {
        this.refresh();
    }

    refresh() {
        this.setField({ isLoading: true });
        // let parameters = [];
        // parameters.push({ key: "page", value: this.state().currentPage }, { key: "itemPage", value: 20 });
        // if (this.state().isResearching) {
        //     parameters.push({ key: "search", value: this.searchText });
        // }
        // if (this.isSorting) {
        //     parameters.push(
        //         { key: 'columnSort', value: this.sortKey[this.indexHeaderSort] },
        //         { key: 'sortOrder', value: this.sortOrder },
        //         { key: 'userLanguage', value: this._languageManage.language });
        // } 
        this.getData();
    }

    getData() {
        this._roleOperations.getUsersWithRoles(this.handleGetInfo)
    }

    handleGetInfo(roles : UserRole[])
    {
        let tableData: TableRow[] = [];
        roles.forEach((userrole: UserRole) => {
            tableData.push({ data: [(userrole as UserRole).userId, ((userrole as UserRole).user as UserIdentity).email , ((userrole as UserRole).role as Role).name], isDeletable: true, isEditable: true, editCallback: () => { this.editField(userrole) }, deleteCallback: () => { this.deleteField(userrole) } });
        });
        this.setField({ tableData: tableData, roles: roles, isLoading: false });
    }

    addField() {
        //Doesn't add, just display the popup

        this.showPopUp(
            <AdminUsersPopupView
            saveAdminUser={this.saveAdminUser}
            userrole =
            {{
                roleId : '',
                userId : '',
                role : undefined,
                user : 
                {
                    id : undefined
                },
                
            }}/>);
    }

    editField(role: UserRole) {
        this.showPopUp(<AdminUsersPopupView saveAdminUser={this.saveAdminUser} userrole={role}/>);
        // this.refresh();
    }

    deleteField(role: UserRole) {
        this.showPopUp(
            <ConfirmPopUpView callBack={() => {
                this._roleOperations.deleteUserRole(role, () => {
                    this.handleSuccess('adminusers.popup.success.deleted');
                });
            }} />
        );
    }

    async saveAdminUser(role: UserRole) {
        if ((role.user as UserIdentity).id != null) {
            this._roleOperations.updateUserRole(role,()=> this.handleSuccess('adminusers.popup.success.updated'));
        }
        else {
            if(role.role?.normalizedName === "KIOSK")
            {
                var result =  await this._usersOperation.kioskRegister(role);
                if(result)
                {
                    return result.status === 200 ? this.handleSuccess('adminusers.popup.success.added') : this.handlCreateError(result.data);
                }
            }
            this._roleOperations.createUserRole(role,()=> this.handleSuccess('adminusers.popup.success.added'),this.handlCreateError);
        }
    }

    handlCreateError(error : any)
    {
        console.log(error)
    }

    handleSuccess(message : string)
    {
        this.refresh();
        this.showToast(ToastHelper.getToast(message,"success"),() => {},2500);
        this.closePopUp();
    }

    onPageChange(page: number) {
        this.state().currentPage = page + 1;
        this.refresh()
    }

    

    handleCloseResearching() {
        this.state().isResearching = false;
        this.onPageChange(0);
    }
}