import { ContentViewModel, ContentView } from '@levelapp/softfabric';
import TimerViewState from './TimerViewState';
import TimerViewProps from './TimerViewProps';
import moment from 'moment';
import Constants from '../../../../../../../Common/Constants/Constants';

export default class TimerViewModel extends ContentViewModel<TimerViewState, TimerViewProps>
{
    interval?: NodeJS.Timeout = undefined;

    /* CONSTRUCTOR */
    constructor(view: ContentView)
    {
        super(view);

        const { startDate } = this.props();

        const now = moment.utc();
        const modification = moment.utc(startDate);
        const nowInSec = now.hours() * 3600 + now.minutes() * 60 + now.seconds();
        const modificationInSec = modification.hours() * 3600 + modification.minutes() * 60 + modification.seconds();

        const diff = Math.abs(nowInSec - modificationInSec);
        const remaining = Constants.orderTimeOut - diff;

        this.initialState({
            isTimeOut: Constants.isOrderTimeOutDecreasing? this.getIsTimeOut(remaining) : this.getIsTimeOut(diff),
            time: Constants.isOrderTimeOutDecreasing ? remaining : diff,
        });

        // Notify parent if is timeout
        if (Constants.isOrderTimeOutDecreasing && this.getIsTimeOut(remaining))
        {
            this.props().setIsTimeout(true);
        }
        else if(!Constants.isOrderTimeOutDecreasing && this.getIsTimeOut(diff))
        {
          this.props().setIsTimeout(true);
        }

        // Bindings
        this.updateTimer = this.updateTimer.bind(this);
    }

    componentDidMount()
    {
        setInterval(this.updateTimer, 1000);
    }

    componentWillUnmount()
    {
        if (this.interval)
        {
            clearInterval(this.interval);
        }
    }

    private updateTimer()
    {
        const { time, isTimeOut } = this.state();

        const newIsTimeOut = this.getIsTimeOut(time);

        // Notify only if is timeout is new
        if (!isTimeOut && newIsTimeOut)
        {
            this.props().setIsTimeout(true);
        }

        this.setField({ time: this.getNewTime(time), isTimeOut: newIsTimeOut });
    }

    private getNewTime(time: number)
    {
      if (Constants.isOrderTimeOutDecreasing)
      {
        return time - 1;
      }
      return time + 1;
    }

    private getIsTimeOut(time: number)
    {
      if (Constants.isOrderTimeOutDecreasing)
      {
        return time <= 0;
      }
      return time >= Constants.orderTimeOut;
    }
}
