 

import { ContentViewModel, ContentView } from '@levelapp/softfabric';

import Product from 'src/Common/DTO/Product';
import { TranslationValue } from 'src/Common/DTO/MessageCampaign';
import ToastHelper from 'src/Common/Helpers/ToastHelper';
import Constants from 'src/Common/Constants/Constants';

import ProductsToolViewState from './ProductsToolViewState';
import ProductsToolViewProps from './ProductsToolViewProps';

import ProductGroupOperations from '../../../../../../../Transfer/ProductGroupOperations';
import UpsertProductGroupView from './upsertproductgroup/UpsertProductGroupView';

export default class ProductsToolViewModel extends ContentViewModel<ProductsToolViewState, ProductsToolViewProps>
{
  productGroupOperations: ProductGroupOperations;

  /* CONSTRUCTOR */
  constructor(view: ContentView) {
    super(view);

    this.initialState({
      productGroups: [],
      isLoading: false,
    });
    this.productGroupOperations = new ProductGroupOperations();
    // Bindings
    this.getData = this.getData.bind(this);
    this.refresh = this.refresh.bind(this);
    this.onPress = this.onPress.bind(this);
    this.getProductTranslation = this.getProductTranslation.bind(this);
    this.onAddProductGroupClick = this.onAddProductGroupClick.bind(this);
    this.onPopUpClosed = this.onPopUpClosed.bind(this);
    this.onProductRemovedFromGroup = this.onProductRemovedFromGroup.bind(this);
  }

  componentDidMount() {
    this.refresh();
  }

  async getData() {
    try {
      this.setField({ isLoading: true });
      const productGroups = await this.productGroupOperations.get();
      this.setField({ productGroups });

      const productGroupWithoutDefault = productGroups.filter((pg) => !pg.isDefault);

      if (productGroupWithoutDefault.length > 0) {
        this.setField({
          selectedItem: {
            id: productGroupWithoutDefault[0].id,
            isParent: true,
          }
        });
      }

    } catch {
      this.showToast(ToastHelper.getToast("tools.generic.get.error", "error"), () => { }, 2500);
    } finally {
      this.setField({ isLoading: false });
    }
  }

  refresh() {
    this.getData();
  }

  getProductTranslation(product: Product) {
    return product.title.translationValues
      .find((translation: TranslationValue) =>
        translation.language.key === Constants.DefaultSearchLanguage)?.value
  }

  onPress(selectedId: number, isParent: boolean) {
    this.setField({
      selectedItem:
        { id: selectedId, isParent }
    });
  }

  onPopUpClosed() {
    this.refresh();
    this.closePopUp();
  }

  onAddProductGroupClick() {
    this.showPopUp(<UpsertProductGroupView closePopUp={this.onPopUpClosed} />);
  }


  async onProductRemovedFromGroup(id: number) {
    try {
      await this.productGroupOperations.removeProductFromGroup(id);
      this.closePopUp();
      this.refresh();
      this.showToast(ToastHelper.getToast("tools.product.remove.singleProduct.success", "success"), () => { }, 2500);
    } catch {
      this.showToast(ToastHelper.getToast("tools.product.remove.singleProduct.error", "error"), () => { }, 2500);
    }
  }
}