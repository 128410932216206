import { ContentViewModel, ContentView, Application, LanguageManager, Managers } from '@levelapp/softfabric';
import ProductsViewState from './ProductsViewState';
import ProductsViewProps from './ProductsViewProps';
import ProductPopupView from '../productpopup/ProductPopupView';
import LanguagesOperations from '../../../../../../../Transfer/LanguagesOperations';
import ProductsOperations from '../../../../../../../Transfer/ProductsOperations';
import AllergenOperations from 'src/Transfer/AllergenOperations';
import AdditionalInfosOperations from 'src/Transfer/AdditionalInfosOperations';
import { TableRow } from '@levelapp/softfabric-ui';
import Product from '../../../../../../../Common/DTO/Product';
import Helpers from '../../../../../../../Common/Helpers/Helpers';
 
import ConfirmPopUpView from '../../../../../../../Common/Components/confirmpopup/ConfirmPopUpView';
import ToastHelper from '../../../../../../../Common/Helpers/ToastHelper';
import Language from '../../../../../../../Common/DTO/Language';
import PaginationHelper from '../../../../../../../Common/Helpers/PaginationHelper';
import ErrorHelper from '../../../../../../../Common/Helpers/ErrorHelper';
import ProductAllergen from 'src/Common/DTO/M&N/ProductAllergen';

export default class ProductsViewModel extends ContentViewModel<ProductsViewState, ProductsViewProps>
{

    _languages: Language[] = [];
    languageOperation: LanguagesOperations;
    productsOperations: ProductsOperations;
    allergenOperations: AllergenOperations;
    additionalInfosOperations: AdditionalInfosOperations;
    searchText: string;
    interval: any;
    isSorting: boolean;
    indexHeaderSort: number;
    sortOrder: string;
    sortKey: string[];

    /* CONSTRUCTOR */
    constructor(view: ContentView) {
        super(view);
        this.interval = null;
        this.searchText = '';
        this.isSorting = false;
        this.indexHeaderSort = -1;
        this.sortOrder = 'asc';
        this.sortKey = ['Id', 'PosId', 'Title', 'Img'];

        this.initialState({
            tableHeaders: ['Id', 'PosId', 'Title', 'Img'],
            tableData: [],
            isLoading: true,
            nbPage: 0,
            currentPage: 1,
            isResearching: false
        });

        this.languageOperation = new LanguagesOperations();
        this.productsOperations = new ProductsOperations();
        this.allergenOperations = new AllergenOperations();
        this.additionalInfosOperations = new AdditionalInfosOperations();

        this.languageOperation.get((languages: any) => {
            this._languages = languages.data;
        })

        this.addField = this.addField.bind(this);
        this.handleResearching = this.handleResearching.bind(this);
        this.handleCloseResearching = this.handleCloseResearching.bind(this);
        this.onPageChange = this.onPageChange.bind(this);
        this.onSortChange = this.onSortChange.bind(this);
        this.deleteField = this.deleteField.bind(this);
        this.editField = this.editField.bind(this);
        this.errorManagement = this.errorManagement.bind(this);
        this.refresh = this.refresh.bind(this);

    }

    componentDidMount() {
        this.refresh();
    }

    refresh() {
        this.setField({ isLoading: true });
        let parameters = PaginationHelper.basicResearchPush(
            this.state().currentPage,
            20,
            this.sortKey[this.indexHeaderSort],
            this.sortOrder,
            Application.current.resolve<LanguageManager>(Managers.LANGUAGE_MANAGER).language ,
            this.searchText,
            this.isSorting,
            this.state().isResearching);
        this.getData(parameters);

    }

    getData(parameters: any[]) {
        this.productsOperations.getTable(parameters, (products: any) => {
            let tableData: TableRow[] = [];
            products.data.objects.forEach((product: Product) => {
                if (product.titleTranslations === null) {
                    product.titleTranslations = Helpers.generateTranslationTable(this._languages);
                }
                if (product.descriptionTranslations === null) {
                    product.descriptionTranslations = Helpers.generateTranslationTable(this._languages);
                }
                if (product.imageTranslations === null) {
                    product.imageTranslations = Helpers.generateUrlTable(this._languages);
                }
                if (product.secondaryTitleTranslations === null) {
                    product.secondaryTitleTranslations = Helpers.generateTranslationTable(this._languages);
                }
                if (product.secondaryDescriptionTranslations === null) {
                    product.secondaryDescriptionTranslations = Helpers.generateTranslationTable(this._languages);
                }
                if (product.secondaryImageTranslations === null) {
                    product.secondaryImageTranslations = Helpers.generateUrlTable(this._languages);
                }
                if (product.thumbnailTranslations === null) {
                    product.thumbnailTranslations = Helpers.generateUrlTable(this._languages);
                }
                let title = product.titleTranslations[0].value;
                let img = (product.imageTranslations == null || product.imageTranslations.length == 0) ? "" : product.imageTranslations[0].url;
                product.productAllergens = product.productAllergens !== null ? product.productAllergens : [];
                let POSId = product.posId !== null ? product.posId : "No result";
                tableData.push({ data: [product.id, POSId, title, img], isDeletable: true, isEditable: true, editCallback: () => { this.editField(product) }, deleteCallback: () => { this.deleteField(product) } });
            })
            this.setField({ tableData: tableData, products: products.data.object, nbPage: products.data.totalPage, isLoading: false });
        },this.errorManagement)
    }

    deleteField(product: Product) {
        this.showPopUp(
            <ConfirmPopUpView callBack={() => {
                this.productsOperations.delete(product, () => {
                    this.showToast(ToastHelper.getToast("products.popup.success.deleted",'success'),() => {},2500)
                    this.refresh();
                },this.errorManagement);
            }} />
        );
    }

    async editField(product: Product) {
        
        let productAllergenResponse  = await this.allergenOperations.getProductsAllergenByProductId(product.id);
        if (productAllergenResponse && productAllergenResponse.data) {
            let productAllergen : ProductAllergen[] = productAllergenResponse.data.data;
            product.productAllergens = productAllergen;
        }
        let additionalInfosResponse = await this.additionalInfosOperations.getAdditionalInfoValuesByProductId(product.id);
        if (additionalInfosResponse && additionalInfosResponse.data) {
            let additionalInfoValues : any[] = additionalInfosResponse.data.data;
            product.additionalInfoValues = additionalInfoValues;
        }

        
        if(product.secondaryTitleTranslations === null) product.secondaryTitleTranslations = Helpers.generateTranslationTable(this._languages);

        if(product.secondaryDescriptionTranslations === null) product.secondaryDescriptionTranslations = Helpers.generateTranslationTable(this._languages);

        if(product.secondaryImageTranslations === null)
            product.secondaryImageTranslations = Helpers.generateUrlTable(this._languages);
        else
            product.secondaryImageTranslations = Helpers.generateUrlTableEdit(product.secondaryImageTranslations,this._languages);

        product.imageTranslations = Helpers.generateUrlTableEdit(product.imageTranslations, this._languages);
        product.thumbnailTranslations = Helpers.generateUrlTableEdit(product.thumbnailTranslations, this._languages);
        this.showPopUp(
            <ProductPopupView
                product={Helpers.clone(product)}
                languages={this._languages}
                refreshParent={this.refresh}
                handleBack={()=>{Helpers.closePopup()}}
                isGroup={product.posId==null}
            />
        );
        this.refresh();
    }

    addField() {
        this.showPopUp(
            <ProductPopupView
                product={{
                    productAllergens:[],
                    titleTranslations: Helpers.generateTranslationTable(this._languages),
                    descriptionTranslations: Helpers.generateTranslationTable(this._languages),
                    imageTranslations: Helpers.generateUrlTable(this._languages),
                    secondaryTitleTranslations: Helpers.generateTranslationTable(this._languages),
                    secondaryDescriptionTranslations: Helpers.generateTranslationTable(this._languages),
                    secondaryImageTranslations: Helpers.generateUrlTable(this._languages),
                    thumbnailTranslations: Helpers.generateUrlTable(this._languages),
                    additionalInfoValues:[],
                }}
                languages={this._languages}
                refreshParent={this.refresh}
                handleBack={()=>{Helpers.closePopup()}}
                isGroup={false}
            />
        );
    }

    

    handleResearching(searchText: string) {
        this.setField({ isLoading: true, isResearching: true });
        this.searchText = searchText;

        if (this.interval) {
            clearInterval(this.interval);
        }
        this.interval = setTimeout(() => {
            this.onPageChange(0)
        }, 1000)
    }

    handleCloseResearching() {
        this.state().isResearching = false;
        this.onPageChange(0);
    }

    onPageChange(page: number) {
        this.state().currentPage = page + 1;
        this.refresh()
    }

    onSortChange(indexHeaderSort: number, sortOrder: string) {
        if (indexHeaderSort == -1) {
            this.isSorting = false;
        } else {
            this.indexHeaderSort = indexHeaderSort;
            this.sortOrder = sortOrder;
            this.isSorting = true;
        }
        this.refresh();
    }

    errorManagement(error : any) 
    {
        this.setField({isLoading : false});
        ErrorHelper.showMessageError(error.response);
    }
}


