import { ContentViewStyle } from '@levelapp/softfabric';
import { ViewStyle, StyleProp } from 'react-native';

export default class SliderViewStyle extends ContentViewStyle
{
    minMax: StyleProp<ViewStyle> = {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        marginTop: 5
    };

    currentValue: StyleProp<ViewStyle> = {
        display: 'flex',
        flexDirection: 'row',
        marginTop: 10,
        alignItems: 'center'
    }
}