 
import { View, Text, TouchableOpacity, TextInput } from 'react-native';

import DropdownGroupItem from 'src/Common/DTO/DropdownGroupItem';

import { ContentView } from '@levelapp/softfabric';

import DropdownContentViewState from './DropdownContentViewState';
import DropdownContentViewProps from './DropdownContentViewProps';
import DropdownContentViewStyle from './DropdownContentViewStyle';
import DropdownContentViewModel from './DropdownContentViewModel';

export default class DropdownContentView extends ContentView<DropdownContentViewProps, DropdownContentViewState, DropdownContentViewModel, DropdownContentViewStyle>
{
  /* CONSTRUCTOR */
  constructor(props: DropdownContentViewProps) {
    super(props);

    // Binding
    this.bind(new DropdownContentViewModel(this), new DropdownContentViewStyle(this));
  }

  /* RENDERING */
  render() {
    const { props, methods, onSearchChange, search } = this.props;

    return (
      <View style={this.style.container}>
        {props.values
          .map((item: DropdownGroupItem) => (
            <View key={item.id} style={this.style.item}>
              <Text style={this.style.text}>{item.name || "(No name)"}</Text>
              <TouchableOpacity style={this.style.delete} onPress={() => methods.removeItem(null, item, false)}>
                <Text style={[this.style.text, { fontWeight: '900' }]}>X</Text>
              </TouchableOpacity>
            </View>
          ))}
        <TextInput
          onChangeText={onSearchChange}
          placeholder="Search..."
          value={search}
        />
      </View>
    );
  }
}