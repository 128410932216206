import { ContentViewStyle } from '@levelapp/softfabric';
import { StyleProp, ViewStyle } from 'react-native';

export default class QrPopupViewStyle extends ContentViewStyle
{
    container: StyleProp<ViewStyle> = {
        height: 300,
        width: 300,
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: 'white',
        borderRadius: 25
    }
}