 
import {ContentShellView} from '@levelapp/softfabric';
import ShellOrdersViewState from './ShellOrdersViewState';
import ShellOrdersViewProps from './ShellOrdersViewProps';
import ShellOrdersViewStyle from './ShellOrdersViewStyle';
import ShellOrdersViewModel from './ShellOrdersViewModel';
import {View} from 'react-native';

export default class ShellOrdersView extends ContentShellView<ShellOrdersViewProps, ShellOrdersViewState, ShellOrdersViewModel, ShellOrdersViewStyle>
{
  /* CONSTRUCTOR */
  constructor(props:ShellOrdersViewProps)
  {
    super(props);

    // Binding
    this.bind(new ShellOrdersViewModel(this), new ShellOrdersViewStyle(this));
  }

  /* RENDERING */
  render()
  {
    return (
      <View style={{flex : 1}}>
        {this.getSwitch()}
      </View>
    );
  }
}