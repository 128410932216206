import {ContentShellViewModel, ContentShellView} from '@levelapp/softfabric';
import ShellAutomatedMessagesViewState from './ShellAutomatedMessagesViewState';
import ShellAutomatedMessagesViewProps from './ShellAutomatedMessagesViewProps';
 
import EnumModules from '../../../../../Common/Constants/EnumModules';
import AutomatedMessagesView from './pages/automatedmessages/AutomatedMessagesView';

export default class ShellAutomatedMessagesViewModel extends ContentShellViewModel<ShellAutomatedMessagesViewState, ShellAutomatedMessagesViewProps>
{

    constructor(view : ContentShellView)
    {
        super(view);

        this.registerEntryPoint('main');
        this.register(<AutomatedMessagesView/> ,'main');

        this.initialisation();
    }

    getModuleId()
    {
        return EnumModules.AUTOMATED_MESSAGES;
    }

}