import { ContentViewStyle, Application } from '@levelapp/softfabric';
import { TextStyle, StyleProp, ViewStyle } from 'react-native';
import { StyleManager, UiManagers } from '@levelapp/softfabric-ui';

export default class StorespopupViewStyle extends ContentViewStyle {

    MyStyleManager: StyleManager = Application.current.resolve<StyleManager>(UiManagers.STYLE_MANAGER);

    title: StyleProp<TextStyle> = {
        textAlign: 'left',
        color: 'white',
        fontSize: 24,
        fontWeight: '600',
        marginTop: 20,
        marginLeft: 60,
        paddingBottom: 20,
        display: "flex",
        flexDirection: "column",
        flexBasis: "85%"
    };

    saveButton: StyleProp<TextStyle> = {
        color: this.MyStyleManager.primaryColor,
        fontSize: 10,
        fontWeight: '600',
        height: 27,
        width: 112
    };

    tabActive: StyleProp<ViewStyle> = {
        backgroundColor: "white",
        width: "100%",
        alignItems: "center",
        height: "50px",
        display: "flex",
        justifyContent: "center",
        borderBottomColor: '#E4E4E4',
        borderBottomWidth: 1
    };

    titleActive: StyleProp<TextStyle> = {
        fontSize: 14,
        color: '#3F3F3F',
        fontFamily: 'Montserrat SemiBold',
    };

    titleInactive: StyleProp<TextStyle> = {
        color: '#B8BBC1',
        fontSize: 14,
        fontFamily: 'Montserrat SemiBold',
    };

    tab: StyleProp<ViewStyle> = {
        backgroundColor: "#EEF0F4",
        width: "100%",
        alignItems: "center",
        height: "50px",
        display: "flex",
        justifyContent: "center",
        borderBottomColor: '#E4E4E4',
        borderBottomWidth: 1
    };

    inputTitle: StyleProp<TextStyle> = {
        fontSize: 14,
        fontWeight: '600',
        color: '#3F3F3F',
        fontFamily: 'Montserrat SemiBold',
        marginBottom: 13,
    };

    errorTextField: StyleProp<ViewStyle> = {
        borderColor: 'red',
        borderWidth: 1,
        width: 140,
        paddingHorizontal: 15,
        borderRadius: 8,
    };

    input: StyleProp<any> = {
        width: 140,
        paddingHorizontal: 15,
        borderRadius: 8,
        boxShadow: '0 5px 14px 0 rgba(0,0,0,0.1)',
    };

    inputError: StyleProp<ViewStyle> = {
        borderColor: 'red',
        borderWidth: 1,
        width: 140,
        paddingHorizontal: 15,
        borderRadius: 8,
    };

    inputStreetFixedSize: StyleProp<any> = {
        width: 70,
        paddingHorizontal: 15,
        borderRadius: 8,
        boxShadow: '0 5px 14px 0 rgba(0,0,0,0.1)',
    }

    inputStreetFixedSizeError: StyleProp<ViewStyle> = {
        borderColor: 'red',
        borderWidth: 1,
        width: 70,
        paddingHorizontal: 15,
        borderRadius: 8,
    };

    inputStreetVariableSize: StyleProp<any> = {
        flex: 1,
        paddingHorizontal: 15,
        borderRadius: 8,
        boxShadow: '0 5px 14px 0 rgba(0,0,0,0.1)',
    };

    inputStreetVariableSizeError: StyleProp<ViewStyle> = {
        flex: 1,
        borderColor: 'red',
        borderWidth: 1,
        paddingHorizontal: 15,
        borderRadius: 8,
    };

    section: StyleProp<ViewStyle> = {
        marginVertical: 15,
    };

    errorTextArea: StyleProp<ViewStyle> = {
        borderColor: 'red',
        borderWidth: 1,
        width: '100%',
        minWidth: 475,
        height: 92,
        paddingHorizontal: 5,
        borderRadius: 8,
    };

    textArea: StyleProp<any> = {
        width: '100%',
        minWidth: 475,
        height: 92,
        paddingHorizontal: 5,
        borderRadius: 8,
        boxShadow: '0 5px 14px 0 rgba(0,0,0,0.1)',
    };

    deleteButton: StyleProp<ViewStyle> = {
        marginLeft: 15,
        alignSelf: 'center',
        alignItems: 'center'
    }

    scheduleTitle: StyleProp<TextStyle> = {
        fontSize: 25,
        fontWeight: '600',
        color: '#3F3F3F',
        fontFamily: 'Montserrat SemiBold',
        marginBottom: 13,
    };

    titlePopup: StyleProp<TextStyle> = {
        textAlign: 'center',
        color: '#4A495C',
        fontSize: 21,
        fontWeight: '600',
        marginTop: 100
    };

    footer: StyleProp<ViewStyle> = {
        display: 'flex',
        flexDirection: 'row',
        margin: 'auto',
        marginTop:75,
        marginBottom:100
    };

    YesButton: StyleProp<ViewStyle> =
    {       
        height: 42,
        width: 119,
        marginLeft : '5px'
    } 
    checkbox: StyleProp<any> = {
        width : 20,
        height : 20,
        marginHorizontal: 5,
    };
}