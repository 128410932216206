import {ContentViewStyle} from '@levelapp/softfabric';
import {Dimensions, StyleProp,ViewStyle} from 'react-native';

export default class CountrypopupViewStyle extends ContentViewStyle
{
    page: StyleProp<ViewStyle> = {
        flex: 1
    }

    popup : StyleProp<ViewStyle> =
    {
        minHeight : (Dimensions.get('window').height /100) * 80 , 
    }

    container : StyleProp<ViewStyle> =
    {
        display: "flex", 
        paddingHorizontal: 170, 
        paddingVertical: 50,
    }
}