 
import { ContentView } from '@levelapp/softfabric';
import EditableDropDownViewState from './EditableDropDownViewState';
import EditableDropDownViewProps from './EditableDropDownViewProps';
import EditableDropDownViewStyle from './EditableDropDownViewStyle';
import EditableDropDownViewModel from './EditableDropDownViewModel';
import { View, Text, TouchableWithoutFeedback, TextInput, Image } from 'react-native';
import ImageButton from '../../Components/imagebutton/ImageButtonView'


export default class EditableDropDownView extends ContentView<EditableDropDownViewProps, EditableDropDownViewState, EditableDropDownViewModel, EditableDropDownViewStyle>
{
  /* CONSTRUCTOR */
  constructor(props: EditableDropDownViewProps) {
    super(props);

    // Binding
    this.bind(new EditableDropDownViewModel(this), new EditableDropDownViewStyle(this));
  }

  renderOption(option: any): JSX.Element {
    return (
      <View key={option.id} style={this.style.editableDropDownOption}>
        <TouchableWithoutFeedback onPress={()=> this.binding.handleOptionClicked(option)}>
          <View>
            <Text>{option.title}</Text> 
          </View>
        </TouchableWithoutFeedback>
      </View>
    )
  }

  renderOptions(availableOptions: any[]): JSX.Element {   

    if(availableOptions.length < 1) return (<View />);

    return (
      <View style={this.style.editableDropDownOptions}>
        {availableOptions.map(option => this.renderOption(option))}
      </View>
    );
  }

  renderInput(hasOptions: boolean): JSX.Element {


    const radiusStyle = {
      borderTopLeftRadius: 23,
      borderTopRightRadius: this.state.searchValue.length > 0 ? 0: 23,
      borderBottomRightRadius: this.state.searchValue.length > 0 ? 0: 23,
      borderBottomLeftRadius: hasOptions ? 0: 23,
    }

    return (
      <View style={[this.style.input, radiusStyle]}>
        <View style={this.style.containerInput}>
          <View style={this.style.inputView}>
            <TextInput value={this.state.searchValue} onChangeText={this.binding.handleSearchChanged}/>
          </View>
          <ImageButton image={require('./assets/erase.svg').default} imageWidth={22} imageHeight={22} onClick={() => { this.binding.clearSearch() }} style={this.style.button} />
        </View>
      </View>
    );
  }

  renderValidation(): JSX.Element {
    if(!this.binding.isValidationAvailable()) return (<View/>)
    return (
      <View>
        <TouchableWithoutFeedback onPress={this.binding.validate}>
          <View style={this.style.validateButton}>
            <Image source={require('./assets/check.svg').default} style={this.style.validateIcon}></Image>
            <Text style={this.style.validateLabel}>Add</Text>
          </View>
        </TouchableWithoutFeedback>
         
      </View>
    );
  }

  render(): JSX.Element {
    const availableOptions = this.binding.getAvailableOptions();

    return (
        <View style={this.style.editableDropDown}>
          <View style={this.style.editableDropDownContainer}>
            {this.renderInput(availableOptions.length > 0)}
            {this.renderValidation()}
          </View>
          
          {this.renderOptions(availableOptions)}
        </View>
    );
  }
}