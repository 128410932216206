 
import { View, Image } from 'react-native';

import { ContentView } from '@levelapp/softfabric';
import { TranslationView } from '@levelapp/softfabric-ui';

import Assets from 'src/Common/Assets';

import NoDataPlaceholderViewState from './NoDataPlaceholderViewState';
import NoDataPlaceholderViewProps from './NoDataPlaceholderViewProps';
import NoDataPlaceholderViewStyle from './NoDataPlaceholderViewStyle';
import NoDataPlaceholderViewModel from './NoDataPlaceholderViewModel';

export default class NoDataPlaceholderView extends ContentView<NoDataPlaceholderViewProps, NoDataPlaceholderViewState, NoDataPlaceholderViewModel, NoDataPlaceholderViewStyle>
{
  /* CONSTRUCTOR */
  constructor(props: NoDataPlaceholderViewProps) {
    super(props);

    // Binding
    this.bind(new NoDataPlaceholderViewModel(this), new NoDataPlaceholderViewStyle(this));
  }

  /* RENDERING */
  render() {
    const { noDataLabel } = this.props;

    return (
      <View style={this.style.container}>
        <Image style={this.style.image} source={Assets.noData} resizeMode='contain' />
        <TranslationView style={this.style.text}>{noDataLabel || 'tools.emptyData'}</TranslationView>
      </View>
    );
  }
}