 
import { ContentViewModel, ContentView, Application, LanguageManager, Managers, StorageManager } from '@levelapp/softfabric';
import KitchenAppViewState from './KitchenAppViewState';
import KitchenAppViewProps from './KitchenAppViewProps';
import BasketStatusesOperation from '../../../../../../../Transfer/BasketStatusesOperation';
import BasketStatus from '../../../../../../../Common/DTO/BasketStatus';
import { TouchableWithoutFeedback, Animated, Easing } from 'react-native';
import BasketOperations from '../../../../../../../Transfer/BasketOperations';
import Basket from '../../../../../../../Common/DTO/Basket';
import OrderView from '../../components/order/OrderView';
import moment, { utc } from 'moment';
import ChangeStatusPopupView from '../../components/changestatuspopup/ChangeStatusPopupView';
import BasketHistoryPopupView from '../../components/baskethistorypopup/BasketHistoryPopupView';
import QrPopupView from '../../components/qrpopup/QrPopupView';
import OpeningHoursOperation from '../../../../../../../Transfer/OpeningHoursOperations';
import OpeningHour from '../../../../../../../Common/DTO/OpeningHour';
import { FlowDirections } from '../../../../../../../Common/Enums/FlowDirections';
import * as signalR from '@aspnet/signalr';
import Constants from '../../../../../../../Common/Constants/Constants';
import ExtendedBasket from '../../../../../../../Common/DTO/ExtendedBasket';
import ExtendedBasketStatus from '../../../../../../../Common/DTO/ExtendedBasketStatus';
import StoresOperations from '../../../../../../../Transfer/StoresOperations';
import Store from '../../../../../../../Common/DTO/Store';
import PermissionHelper from '../../../../../../../Common/Helpers/PermissionHelper';
import ShowOrdersExtraData from '../../../../../../../Common/DTO/ExtraData/ShowOrdersExtraData';
import { Howl } from 'howler'
import LogHistoryPopupView from '../../components/loghistorypopup/LogHistoryPopupView';
import LogOperation from '../../../../../../../Transfer/LogOperations';
import ToastHelper from '../../../../../../../Common/Helpers/ToastHelper';
import * as XLSX from 'xlsx';
import Log from '../../../../../../../Common/DTO/Log';
import LogToExcel from '../../../../../../../Common/DTO/LogToExcel';
import PrinterHelper from '../../../../../../../Common/Helpers/PrinterHelper';
import SortHelper from '../../../../../../../Common/Helpers/SortHelper';
import BasketLine from '../../../../../../../Common/DTO/BasketLine';
import SettingsPopupView from '../../components/settingspopup/SettingsPopupView';
import DashboardHelper from '../../../../../../../Common/Helpers/DashboardHelper';
import ErrorHelper from '../../../../../../../Common/Helpers/ErrorHelper';
import { OrderStatus } from '../../../../../../../Common/Enums/OrderStatus';
import PopupStoresListView from '../../../../components/popupstoreslist/PopupStoresListView';
import StorageConstants from '../../../../../../../Common/Constants/StorageConstants';
import StoreBrandOperations from '../../../../../../../Transfer/StoreBrandOperations';
import KitchenOperations from 'src/Transfer/KitchenOperations';
import { FilterType } from '../../enum/FilterType';

export default class KitchenAppViewModel extends ContentViewModel<KitchenAppViewState, KitchenAppViewProps>
{
    // Constants for calculations
    private readonly _header_height = 90;
    private readonly _sequel_height = 43;
    private readonly _product_case_height = 43;
    private readonly _next_status_height = 100;
    private readonly _subProduct_height = 29;
    private readonly _order_width = 285;
    private readonly _fontsizeTxt = 16;

    private _statusesOperation: BasketStatusesOperation;
    private _basketsOperation: BasketOperations;
    private _kitchenOperation: KitchenOperations;
    private _openingHoursOperation: OpeningHoursOperation;
    private _storeBrandOperation : StoreBrandOperations;
    private __storeOperations: StoresOperations;
    private _logOperations: LogOperation;
    private _storageManager: StorageManager;

    private readonly _signalRConnection: signalR.HubConnection;
    private readonly _signalRSubscriptionName = 'store_';
    private readonly _kitchenLanguage = Constants.kitchenLanguage !== 'null' ? Constants.kitchenLanguage : undefined;
    private _selectedStoreSlug = '';
    private _reconnectLoop?: NodeJS.Timeout;
    private _notificationSound: any;

    private _useBrands = Constants.kitchenUseBrand;


    language: string = '';
    footerTransY = new Animated.Value(75);
    modusOverlayOpacity = new Animated.Value(0);
    modusTransY = new Animated.Value(-100);

    /* CONSTRUCTOR */
    constructor(view: ContentView) {
        super(view);

        this.initialState({
            statuses: [],
            brands : undefined,
            baskets: [],
            isLoadingExport: false,
            openingHours: [],
            selectedStatusId: -1,
            orderMaxHeight: 0,
            selectedDate: new Date(),
            isLoading: true,
            orderSettingsOpenId: undefined,
            selectedBasketIds: [],
            flowDirection: FlowDirections.VERTICAL,
            showModusPopup: false,
            showNewestOrdersFirst: localStorage.getItem('showNewestOrdersFirst') ? localStorage.getItem('showNewestOrdersFirst') === 'true' : false,
            hideContent: localStorage.getItem('hideContent') ? localStorage.getItem('hideContent') === 'true' : false,
            isKitchenModusEnabled: false,
            selectedFilters: [],
            _selectedStore: undefined,
            filters: {
                brands: [],
                types: [],
                sources: [],
                status: []
            },
            header_height: this._header_height,
            sequel_height: this._sequel_height,
            product_case_height: this._product_case_height,
            next_status_height: this._next_status_height,
            subProduct_height: this._subProduct_height,
            order_width: this._order_width,
            fontsizeTxt: this._fontsizeTxt,
            currentZoomPercentage: 1,
            hasRingingStarted: false,
        });

        this._statusesOperation = new BasketStatusesOperation();
        this._basketsOperation = new BasketOperations();
        this._kitchenOperation = new KitchenOperations();
        this._openingHoursOperation = new OpeningHoursOperation();
        this.__storeOperations = new StoresOperations();
        this._logOperations = new LogOperation();
        this._storeBrandOperation = new StoreBrandOperations();
        this._storageManager = Application.current.resolve<StorageManager>(Managers.STORAGE_MANAGER);

        this._signalRConnection = new signalR.HubConnectionBuilder()
            .withUrl(`${Constants.EventsUrl}/basketHub`)
            .build();



        // Bindings
        this.handleStatusSelected = this.handleStatusSelected.bind(this);
        this.getBasketsCountByStatus = this.getBasketsCountByStatus.bind(this);
        this.getStatuses = this.getStatuses.bind(this);
        this.getBrands = this.getBrands.bind(this);
        this.getBaskets = this.getBaskets.bind(this);
        this.getBasket = this.getBasket.bind(this);
        this.handleNextDayPressed = this.handleNextDayPressed.bind(this);
        this.handlePreviousDayPressed = this.handlePreviousDayPressed.bind(this);
        this.handleSelectBasket = this.handleSelectBasket.bind(this);
        this.handleLongSelectBasket = this.handleLongSelectBasket.bind(this);
        this.handleDateChanged = this.handleDateChanged.bind(this);
        this.handleFilterSelected = this.handleFilterSelected.bind(this);
        this.handleDisplayHistory = this.handleDisplayHistory.bind(this);
        this.handleShowQr = this.handleShowQr.bind(this);
        this.toggleKitchenModus = this.toggleKitchenModus.bind(this);
        this.toggleShowNewestOrdersFirst = this.toggleShowNewestOrdersFirst.bind(this);
        this.toggleHideContent = this.toggleHideContent.bind(this);
        this.formatOrders = this.formatOrders.bind(this);
        this.handleExport = this.handleExport.bind(this);
        this.handleTestPrint = this.handleTestPrint.bind(this);
        this.handleClickSettings = this.handleClickSettings.bind(this);
        this.errorManagement = this.errorManagement.bind(this);
        this.fetchRingingStatus = this.fetchRingingStatus.bind(this)

        this.updateKioskDisabled = this.updateKioskDisabled.bind(this);
        this.updateClickAndCollectDisabled = this.updateClickAndCollectDisabled.bind(this);
        this.handleUpdateTemporary = this.handleUpdateTemporary.bind(this);
        this.setFilterSelection = this.setFilterSelection.bind(this);
        DashboardHelper.setClickSettings(() => this.handleClickSettings());
        this.zoom = this.zoom.bind(this);
        this.reset = this.reset.bind(this);
        this.getStatus = this.getStatus.bind(this);
        this.getOrderCount = this.getOrderCount.bind(this);
        this.getFilteredOrderCount = this.getFilteredOrderCount.bind(this);

    }

    componentWillUnmount() {
        DashboardHelper.setClickSettings(() => { });
    }


    async componentDidMount() {
        await this.fetchRingingStatus().then((result) => {
            if (result) {
                this._notificationSound = new Howl({
                    src: [require('../../assets/notification.mp3').default],
                    loop: false,
                    rate: 2.5,
                    onend: () => {
                        if (this.state().hasRingingStarted) {
                            this._notificationSound.play()
                        } else {
                        }

                    }
                })
            } else {
                this._notificationSound = new Howl({ src: [require('../../assets/notification.mp3').default] })
            }
        })






        const tmp = (PermissionHelper.getExtraDataKitchenApp() as any);
        const isAllStores = (Object.keys(tmp).length == 0 || tmp == null || tmp == undefined);
        if (!isAllStores && (JSON.parse(tmp) as ShowOrdersExtraData).restaurant == -1) {
            alert("Kitchen app not enabled for this restaurant.");
            return this.navigateToPrevious();
        }

        this.language = Application.current.resolve<LanguageManager>(Managers.LANGUAGE_MANAGER).language;
        this.startHubConnection();


        this._signalRConnection.onclose((error) => {
            console.log(`SignalR disconnected. Reason : ${error?.message}`);
            this._reconnectLoop = setInterval(() => {
                this.startHubConnection();
            }, 2500);
        });

        // Get stores
        this.__storeOperations.get([], async (paginationObject: any) => {
            // Check the stores we have access to
            let storeTemp: Store[] = [];
            let selectedStore: Store;
            paginationObject.data.objects.forEach((store: Store) => {
                if (isAllStores) {
                    //ALL STORES 
                    storeTemp.push({ ...store, slug: "", title: store.slug });
                } else {
                    var id = (JSON.parse(tmp) as ShowOrdersExtraData).restaurant;
                    if (id == store.id) {
                        //DISPLAY ONLY THAT FROM BE
                        storeTemp.push({ ...store, slug: "", title: store.slug });
                    }
                }
            });

            if (storeTemp.length == 1) {
                selectedStore = storeTemp[0];
                this._selectedStoreSlug = storeTemp[0].title;
            }
            else {
                storeTemp = SortHelper.sortStoreByTitle(storeTemp);
                selectedStore = await this.showStoreChoose(storeTemp);
                this._selectedStoreSlug = storeTemp.find(x => x.id == selectedStore.id)?.title ?? '';
            }

            DashboardHelper.setStoreId(selectedStore.id);

            await Promise.all([this.getStatuses(), this.getBrands(selectedStore.id), this.getOpeningHours(selectedStore.id)]);
            this.setField({ isLoading: false, _selectedStore: selectedStore }, () => this.getBaskets());
            this._signalRConnection.on(`${this._signalRSubscriptionName}${selectedStore.id}`, this.getBasket);
            this.startAutoRefreshLogic();
        }, this.errorManagement);
    }

    /** CALLS */
    private async getBrands(storeId : number)
    {
        const brandsResponse = await this._storeBrandOperation.get(storeId);
        if (brandsResponse && brandsResponse.data) {
            this.setField({brands : brandsResponse.data.data});
        }
    }


    private async getStatuses() {
        const statusesResponse = await this._statusesOperation.get();
        if (statusesResponse && statusesResponse.data) {
            const statuses: ExtendedBasketStatus[] = statusesResponse.data.data;

            statuses.forEach(st => st.showBadge = false);

            const filtersAsString = window.localStorage.getItem('filters');


            if (!filtersAsString) {
                this.setField({ statuses: statuses, selectedFilters: statuses.map(x => x.id), selectedStatusId: statuses.find(x => x.isDefaultSelected)?.id ?? -1 });
                return;
            }

            const filters = JSON.parse(filtersAsString);
            if (filters.types.status === 0) {
                this.setField({ statuses: statuses, filters, selectedFilters: statuses.map(x => x.id), selectedStatusId: statuses.find(x => x.isDefaultSelected)?.id ?? -1 });
            }

            this.setField({ statuses: statuses, filters, selectedFilters: statuses.map(x => x.id), selectedStatusId: filters.status[0] });

        }
    }

    private async getBaskets() {
        if (this.state()._selectedStore != undefined) {
            const selectedDate = this.state().selectedDate;

            const operation = this._useBrands ? this._kitchenOperation : this._basketsOperation;

            const basketsResponse = await operation.getByDate(selectedDate, (this.state()._selectedStore as Store).id, this._kitchenLanguage);

            if (basketsResponse && basketsResponse.data) {
                const baskets: ExtendedBasket[] = basketsResponse.data.data;
                baskets.forEach((basket) => {
                    basket.opacity = new Animated.Value(1);
                    basket.width = new Animated.Value(this.state().order_width);
                })
                this.setField({ baskets: baskets });
            }
        }
    }

    private areOrdersIdentical(order: ExtendedBasket, orderToCompare: ExtendedBasket): boolean {
        const hasSameOrigin = order.orderOrigin === orderToCompare.orderOrigin;
        const hasSameId = order.id === orderToCompare.id;

        if (hasSameOrigin && hasSameId) return true;
        if (order.referenceBasketId === orderToCompare.id) return true;

        return order.id === orderToCompare.referenceBasketId;

    }

    private findBasket(basket: ExtendedBasket): ExtendedBasket | undefined {
        const { baskets } = this.state();
        return baskets.find(x => this.areOrdersIdentical(x, basket));
    }

    private AreBasketLineStatusChanged(currentBasket: ExtendedBasket, basket: ExtendedBasket): boolean{
        const hasChanges = currentBasket.basketLines.reduce((hasChanged, currentBasketLine):boolean => {
            const basketLine = basket.basketLines.find(basketLine=>basketLine.id === currentBasketLine.id);
            
            return hasChanged || basketLine === undefined || currentBasketLine.status !== basketLine.status;         
        }, false);

        return hasChanges;
    }
    
    private async getBasket(id: number | Basket) {
        const basketId = Constants.IsReceivingBasketObject ? (id as Basket).id : id as number;
        const operation = this._useBrands ? this._kitchenOperation : this._basketsOperation;
        const basketResponse = await operation.getById(basketId, this._kitchenLanguage);
        const { baskets, statuses } = this.state();
        
        if (basketResponse && basketResponse.data) {
            const basket: ExtendedBasket = basketResponse.data.data;
            const currentBasket = this.findBasket(basket);
            // Check if basket is known

            if (currentBasket) {
                // Check if status has changed
                if (currentBasket.status != basket.status) {
                    return this.handleBasketStatusChanged(currentBasket, basket);
                }

                // Check if basket status has changed
                if(this._useBrands && this.AreBasketLineStatusChanged(currentBasket, basket)) {
                    return this.handleBasketStatusChanged(currentBasket, basket);
                }
            }
            else {
                basket.opacity = new Animated.Value(1);
                basket.width = new Animated.Value(this.state().order_width);

                if (basket.status == this.state().selectedStatusId) {
                    let { baskets } = this.state();

                    basket.opacity = new Animated.Value(0);
                    basket.width = new Animated.Value(0);
                    baskets.push(basket);

                    this.setField({ baskets: baskets }, () => {
                        this.launchAppearingOrderAnimation(basket);
                    });
                }
                else {
                    baskets.push(basket);

                    const status = statuses.find(x => x.id == basket.status);
                    if (status) {
                        status.showBadge = true;
                    }
                    this.setField({ baskets, statuses });
                }

                if (basket.status === OrderStatus.Preparing) {
                    this.setField({ hasRingingStarted: true, }, () => this._notificationSound.play())
                    PrinterHelper.print(this._selectedStoreSlug, basket);
                }


            }
        }
    }
    
    private async getOpeningHours(storeid: number) {
        const openingHoursResponse = await this._openingHoursOperation.get(storeid);
        if (openingHoursResponse && openingHoursResponse.data) {
            const openingHours: OpeningHour[] = openingHoursResponse.data.data;
            this.setField({ openingHours: openingHours });
        }
    }

    /* #region  HANDLERS */

    /**
     * A status has been selected
     * Display an indicator under the new selected status
     */
    handleStatusSelected(status: ExtendedBasketStatus) {
        status.showBadge = false;
        this.setField({ selectedStatusId: status.id });
    }

    /**
     * Display orders for all checked statuses
     */
    handleAllPressed() {
        this.setField({ selectedStatusId: -1 });
    }

    /**
     * Open the popup and prompt the next status
     * @param basket the current basket to update
     */
    handleNextStatusPressed(baskets: Basket[]) {
        this.onTouchScreen();
        
        const { statuses, selectedStatusId } = this.state();
        const  currentStatus = statuses.find(x => x.id == selectedStatusId);
        let nextIds: number[] = [];

        if (currentStatus) {
          nextIds = currentStatus.nextStatusIds;
        }
        else
        {
          for(let basket of baskets)
          {
            const status = statuses.find(x => x.id === basket.status);
            if(status)
            {
              const { nextStatusIds } = status;

              for(let i = 0; i < nextStatusIds.length; i++)
              {
                if(!nextIds.includes(nextStatusIds[i]))
                {
                  nextIds.push(nextStatusIds[i]);
                }
              }
            }
          }
        }

        if(Constants.kitchenAppSkipStatusPopup && nextIds.length !== 0)
        {
          const next = statuses.find(x => x.id === nextIds[nextIds.length-1]);

          if(next)
          {
            this.handleStatusChanged(next,baskets);
            return;
          }
        }

        this.showPopUp(
          <ChangeStatusPopupView statuses={statuses.filter(x => nextIds.includes(x.id))}
              language={this.language} handleStatusSelected={(status: BasketStatus) => this.handleStatusChanged(status, baskets)} />
      );
    }

    /**
     * Open the popup and prompt the next status
     * @param basket the current basket to update
     */
    handlePreviousStatusPressed(basket: Basket) {
        this.setField({ orderSettingsOpenId: undefined });

        const { statuses } = this.state();
        const currentStatus = statuses.find(x => x.id == basket.status);

        if (currentStatus) {
            this.showPopUp(
                <ChangeStatusPopupView statuses={statuses.filter(x => x.nextStatusIds.includes(currentStatus.id))}
                    language={this.language} handleStatusSelected={(status: BasketStatus) => this.handleStatusChanged(status, [basket])} />
            );
        }
    }

    handleDisplayLogs(basket: Basket) {
        this.setField({ orderSettingsOpenId: undefined });

        this.showPopUp(
            <LogHistoryPopupView basket={basket} />
        );
    }

    handleDisplayHistory(basket: Basket) {
        this.setField({ orderSettingsOpenId: undefined });

        this.showPopUp(
            <BasketHistoryPopupView basket={basket} statuses={this.state().statuses} />
        );
    }

    async handleStatusChanged(newStatus: BasketStatus, baskets: Basket[]) {
        for (const basket of baskets) {
            let response;
            // Clone the basket
            const basketClone = JSON.parse(JSON.stringify(basket));

            basketClone.status = newStatus.id;

            if(this._useBrands) {
                const  brandIds = this.state().filters['brands'];
                
                const lines = basket.basketLines
                    .filter(x => 
                        (brandIds.length === 0 || brandIds.includes(x.groupId)) && 
                        x.parentBasketLineId === 0
                    )
                    .map(x=>x.id);

                response = await this._kitchenOperation.updateStatus(basket.id, lines, newStatus.id);
            } 
            else{
                response = await this._basketsOperation.updateStatus(basketClone);
            }
            
            if (!response || response.status >= 400) {
                alert(`Error updating status of order #${basket.externalOrderNumber}`);
                continue;
            }
        }

        this.closePopUp();
        if (this.state().selectedBasketIds.length != 0) {
            this.setField({ selectedBasketIds: [] }, () => {
                this.hiderFooter();
            });
        }
    }

    private isToday(date: string): boolean {
        return date.split('T')[0] === new Date().toISOString().split('T')[0];
    }

    handleNextDayPressed() {
        this.onTouchScreen()
        this.startAutoRefreshLogic();
        const { selectedDate } = this.state();
        selectedDate.setDate(selectedDate.getDate() + 1);

        // If not today, remove all websocketshandlers
        const isSelectedDateToday = this.isToday(selectedDate.toISOString());
        if (!isSelectedDateToday) {
            this._signalRConnection.off(`${this._signalRSubscriptionName}${this.state()._selectedStore?.id}`);
        }
        // Or resubscribe
        else {
            this._signalRConnection.on(`${this._signalRSubscriptionName}${this.state()._selectedStore?.id}`, this.getBasket);
        }

        this.setField({ selectedDate: selectedDate, isLoading: true }, async () => {
            await this.getBaskets();
            this.setField({ isLoading: false });
        });
    }

    handlePreviousDayPressed() {
        this.onTouchScreen()
        this.startAutoRefreshLogic();
        const { selectedDate } = this.state();
        selectedDate.setDate(selectedDate.getDate() - 1);

        // If not today, remove all websocketshandlers
        const isSelectedDateToday = this.isToday(selectedDate.toISOString());
        if (!isSelectedDateToday) {
            this._signalRConnection.off(`${this._signalRSubscriptionName}${this.state()._selectedStore?.id}`);
        }
        // Or resubscribe
        else {
            this._signalRConnection.on(`${this._signalRSubscriptionName}${this.state()._selectedStore?.id}`, this.getBasket);
        }

        this.setField({ selectedDate: selectedDate, isLoading: true }, async () => {
            await this.getBaskets();
            this.setField({ isLoading: false });
        });
    }

    handleDateChanged(newDate: Date) {
        this.onTouchScreen()
        this.startAutoRefreshLogic();
        // If not today, remove all websocketshandlers
        const isSelectedDateToday = this.isToday(newDate.toISOString());
        if (!isSelectedDateToday) {
            this._signalRConnection.off(`${this._signalRSubscriptionName}${this.state()._selectedStore?.id}`);
        }
        // Or resubscribe
        else {
            this._signalRConnection.on(`${this._signalRSubscriptionName}${this.state()._selectedStore?.id}`, this.getBasket);
        }

        this.setField({ selectedDate: newDate, isLoading: true }, async () => {
            await this.getBaskets();
            this.setField({ isLoading: false });
        });
    }

    async handleExport() {
        if (this.state()._selectedStore != undefined) {
            this.setField({ isLoadingExport: true });

            var listlog = await this._logOperations.getAllBasketByStoreIdAndDate(this.state()._selectedStore?.id as number, this.state().selectedDate, this.state().selectedDate);
            if (listlog != null && listlog.data != null) {
                var listLogExcel: LogToExcel[] = [];
                listlog.data.data.forEach((l: Log) => {
                    var lExcel = l as unknown as LogToExcel;
                    lExcel.referenceType = l.referenceType.key;
                    lExcel.thirdPartyType = l.thirdPartyType.key;
                    listLogExcel.push(lExcel);
                })
                var wb = XLSX.utils.book_new();
                var o = XLSX.utils.json_to_sheet(listLogExcel);
                XLSX.utils.book_append_sheet(wb, o, "Logs");
                XLSX.writeFile(wb, `Logs_${this.state()._selectedStore?.slug}.xlsx`);
                this.showToast(ToastHelper.getToast("kitchenapp.success.export", "success"), () => { }, 2500);

            } else {
                this.showToast(ToastHelper.getToast("kitchenapp.error.export", "error"), () => { }, 2500);
            }
            this.setField({ isLoadingExport: false });
        }
    }

    handleTestPrint() {
        PrinterHelper.testPrint().then(() => {
            this.showToast(ToastHelper.getToast("kitchenapp.success.print", "success"), () => { }, 2500);

        }).catch(() => {
            this.showToast(ToastHelper.getToast("kitchenapp.error.print", "error"), () => { }, 2500);
        });
    }

    handleSelectBasket(basketId: number) {
        const { selectedBasketIds, orderSettingsOpenId } = this.state();

        if (selectedBasketIds.length == 0) {
            if (orderSettingsOpenId) {
                // Hide settings menu if open
                this.setField({ orderSettingsOpenId: undefined });
            }
            return;
        }
        if (selectedBasketIds.includes(basketId)) {
            const newList = selectedBasketIds.filter(x => x != basketId);

            // If no selection => hide footer
            if (newList.length == 0) {
                this.hiderFooter();
            }
            return this.setField({ selectedBasketIds: newList });
        }

        selectedBasketIds.push(basketId);
        this.setField({ selectedBasketIds: selectedBasketIds });
    }

    /**
     * Enable multi-select mode and select the basket in parameter
     * If already enabled, this method disable it 
     * @param basketId the basket that has been long pressed
     */
    handleLongSelectBasket(basketId: number) {
        const { selectedBasketIds } = this.state();

        if (selectedBasketIds.length == 0) {
            selectedBasketIds.push(basketId);
            this.showFooter();
            return this.setField({ selectedBasketIds: selectedBasketIds });
        }
        this.hiderFooter();
        return this.setField({ selectedBasketIds: [] });
    }



    handleClickSettings() {
        const { isKitchenModusEnabled, showNewestOrdersFirst, hideContent } = this.state();
        if (this.state()._selectedStore != undefined) {
            this.showPopUp(
            <SettingsPopupView
                onExport={this.handleExport}
                updateTemporary={this.handleUpdateTemporary}
                isLoadingExport={this.state().isLoadingExport}
                store={this.state()._selectedStore as Store}
                isKitchenModusEnabled={isKitchenModusEnabled}
                hideContent={hideContent}
                showNewestOrdersFirst={showNewestOrdersFirst}
                toggleshowNewestOrdersFirst={this.toggleShowNewestOrdersFirst}
                toggleHideContent={this.toggleHideContent}
                testPrint={this.handleTestPrint}
                toggleKitchenModus={this.toggleKitchenModus} 
              />
            );
        }
    }


    handleUpdateTemporary(type: 'cc' | 'kiosk', value: boolean) {
        switch (type) {
            case 'cc':
                this.updateClickAndCollectDisabled(value);
                break;
            case 'kiosk':
                this.updateKioskDisabled(value);
                break;
        }
    }

    private updateClickAndCollectDisabled(value: boolean) {
        // Update selected store
        if (this.state()._selectedStore != undefined) {

            (this.state()._selectedStore as Store).isClickAndCollectTemporaryDisabled = value;


            this.__storeOperations.updateClickAndCollectTemporary(this.state()._selectedStore, () => {
                this.showToast(ToastHelper.getToast(`kitchenapp.settings.popup.cctemporary.${value ? 'off' : 'on'}.success.updated`, "success"), () => { }, 2500);
            });

        }

    }

    private updateKioskDisabled(value: boolean) {
        // Update selected store
        if (this.state()._selectedStore != undefined) {

            (this.state()._selectedStore as Store).isKioskTemporaryDisabled = value;


            this.__storeOperations.updateKioskTemporary(this.state()._selectedStore, () => {
                this.showToast(ToastHelper.getToast(`kitchenapp.settings.popup.kiosktemporary.${value ? 'off' : 'on'}.success.updated`, "success"), () => { }, 2500);
            });

        }
    }

    handleFilterSelected(statusId: number) {
        this.onTouchScreen();
        const { selectedFilters } = this.state();

        if (selectedFilters.includes(statusId)) {
            return this.setField({ selectedFilters: selectedFilters.filter(x => x != statusId) });
        }

        selectedFilters.push(statusId);
        this.setField({ selectedFilters: selectedFilters });
    }

    handleBasketStatusChanged(oldBasket: ExtendedBasket, newBasket: ExtendedBasket) {
        const { selectedStatusId } = this.state();

        // Disappearing animation
        if (oldBasket.status == selectedStatusId) {
            this.launchDisappearingOrderAnimation(oldBasket, () => {
                let { statuses, baskets } = this.state();

                newBasket.opacity = new Animated.Value(1);
                newBasket.width = new Animated.Value(this.state().order_width);

                const index = baskets.findIndex(x => x.id == oldBasket.id);
                baskets.splice(index, 1);
                baskets.push(newBasket);

                const status = statuses.find(x => x.id == newBasket.status);
                if (status) {
                    status.showBadge = true;
                }

                this.setField({ baskets: baskets, statuses, isLoading: true }, () => {
                    this.setField({ isLoading: false })
                });
            });
        }

        // Appearing animation
        else if (newBasket.status == selectedStatusId) {
            let { baskets } = this.state();

            newBasket.opacity = new Animated.Value(0);
            newBasket.width = new Animated.Value(0);

            const index = baskets.findIndex(x => x.id == oldBasket.id);
            baskets.splice(index, 1);
            baskets.push(newBasket);

            this.setField({ baskets: baskets }, () => {
                this.launchAppearingOrderAnimation(newBasket);
            });
        }

        // The basket go from not selected status to another status not selected
        // No animation
        else {
            let { statuses, baskets } = this.state();

            newBasket.opacity = new Animated.Value(1);
            newBasket.width = new Animated.Value(this.state().order_width);

            const index = baskets.findIndex(x => x.id == oldBasket.id);
            baskets.splice(index, 1);
            baskets.push(newBasket);

            const status = statuses.find(x => x.id == newBasket.status);
            if (status) {
                status.showBadge = true;
            }

            this.setField({ baskets: baskets, statuses, isLoading: true }, () => {
                this.setField({ isLoading: false })
            });
        }

        // If "preparing" => notification
        if (newBasket.status === OrderStatus.Preparing) {

            this.setField({ hasRingingStarted: true, }, () => this._notificationSound.play())
            PrinterHelper.print(this._selectedStoreSlug, newBasket);
        }
    }

    handleShowQr(content: string) {
        this.showPopUp(
            <QrPopupView content={content} />
        );
    }
    /* #endregion */

    /**
     * Returns the amount of baskets for given status
     * @param st The given status
     */
    getBasketsCountByStatus(st: BasketStatus) {
        const filteredBaskets = this.filterOrder(this.state().baskets, false);
        return filteredBaskets.filter(x => x.status == st.id).length;
    }

    getOrderCount() {
        const status = this.state().statuses.filter(f => f.isDisplayed);
        const statusIds = status.map(s => s.id);
        return this.state().baskets.filter(o => statusIds.includes(o.status)).length;
    }

    getFilteredOrderCount() {
        const filteredOrders = this.filterOrder(this.state().baskets, false);
        const status = this.state().statuses.filter(f => f.isDisplayed);
        const statusIds = status.map(s => s.id);
        const finalOrders = filteredOrders.filter(o => statusIds.includes(o.status));

        return finalOrders.length;
    }

    private isOrderStatusValid(availableStatuses: number[], order: ExtendedBasket) {
        // Filtre sur les status

        // -> Soit un status est selectioné-> easy compare 
        if (this.state().selectedStatusId !== -1)
            return order.status === this.state().selectedStatusId;

        // -> soit tout si selectedStatusId == -1 
        // --> mais le status doit être montrable et dans les filtres sélectionés
        return (
            availableStatuses.includes(order.status) &&
            this.state().selectedFilters.includes(order.status)
        );
    }

    private isOrderValidForFilter(id: number, filterKey: string) {
        const filter = this.state().filters[filterKey];
        if (filter.length === 0) return true;
        return filter.includes(id);
    }

    filterOrder(orders: ExtendedBasket[], onlyVisibleStatus: boolean = true): ExtendedBasket[] {
        this.determineBasketStatusBasedOnBrand(this.state().baskets, this.state().filters[FilterType.BRANDS]);
        const { showNewestOrdersFirst } = this.state();

        const availableStatuses = this.state().statuses.filter(y => y.isDisplayed).map(y => y.id);
        return orders.filter(order => {
            const statusValid = !onlyVisibleStatus || this.isOrderStatusValid(availableStatuses, order);

            return (
                statusValid &&
                this.isOrderValidForFilter(order.orderType, "types") &&
                this.isOrderValidForFilter(order.orderSourceId, "sources")
            )
        })
        .sort((a,b) => showNewestOrdersFirst ?  moment.utc(b.lastModificationDate).unix() - moment.utc(a.lastModificationDate).unix(): moment.utc(a.lastModificationDate).unix() - moment.utc(b.lastModificationDate).unix());

    }

    determineBasketStatusBasedOnBrand(baskets: ExtendedBasket[], brandIds: number[] | undefined) {
        if(!this._useBrands) return;
        
        const noBrand = brandIds === undefined || brandIds.length === 0; 


        baskets.forEach(basket => {
            if(basket.status === 3 || basket.status === 4) {
                const lines = basket.basketLines.filter(x => noBrand || brandIds.includes(x.groupId)); 
                basket.status  =  lines.reduce((min: number, line): number=>{
                    return min < line.status ? min: line.status; 
                }, 4);
            }
        });
    }
    /**
     * Returns orders as DOM elements
     */
    formatOrders(): JSX.Element[] {
        const orders: JSX.Element[] = [];

        
        const filteredBaskets = this.filterOrder(this.state().baskets);

        let numberOfItem = filteredBaskets.length;
        for (const basket of filteredBaskets) {
            const currentStatus = this.state().statuses.find(x => x.id == basket.status);
            const bgColor = currentStatus?.labelColor ?? '';
            const time = utc(basket.lastModificationDate).local().format("HH:mm");
            
            let remainingLines = basket.basketLines;

            const brandIds = this.state().filters[FilterType.BRANDS];
            if(brandIds !== undefined && brandIds.length > 0){

                remainingLines = remainingLines.filter(x => brandIds.includes(x.groupId));
            };
            remainingLines = remainingLines.filter(x => x.title != null);

            let linesToRender = [];

            // Calculate the total height of the order (-5 for border of timed out order, +60 for price at the bottom)
            let totalHeight = this.state().header_height + this.state().next_status_height - 5 + ((2 - this.state().currentZoomPercentage) * 60);
            let isSequel = false;

            // Add products height
            for (const line of remainingLines.filter(x => !x.parentBasketLineId)) {
                const subProductsLines = this.state().hideContent ? [] :this.getAllSubLines(line.basketLineId, remainingLines);

                //if last line => add heigth based on zoom
                let tmpHeigth = (2 - this.state().currentZoomPercentage) * (totalHeight + (this.state().product_case_height + subProductsLines.length * this.state().subProduct_height + (isSequel ? this.state().sequel_height : 0)))

                // If we reached the max height => render without others lines
                if (tmpHeigth > this.state().orderMaxHeight) {

                    orders.push(
                        <TouchableWithoutFeedback onPress={() => this.handleSelectBasket(basket.id)} onLongPress={() => this.handleLongSelectBasket(basket.id)} disabled={isSequel} key={line.id}>
                            <Animated.View style={{ opacity: basket.opacity, width: this.state().order_width, zIndex: isSequel ? 0 : numberOfItem }}>
                                <OrderView hasSequel={true} total={basket.total}
                                    zIndex={isSequel  ? 0 : numberOfItem} deskNumber={basket.desckNumber}
                                    basketId={basket.id}
                                    cardCode={basket.loyaltyCard?.code}
                                    remark={basket.remark}
                                    hasNextStatus={currentStatus?.nextStatusIds.length != 0 }
                                    handleHistory={() => this.handleDisplayHistory(basket)}
                                    hasPreviousStatus={this.state().statuses.filter(x => x.nextStatusIds.includes(currentStatus?.id ?? 0)).length != 0}
                                    handleShowQR={this.handleShowQr}
                                    isSequel={isSequel} handleNext={() => { this.handleNextStatusPressed([basket]) }}
                                    handleLog={() => { this.handleDisplayLogs(basket) }}
                                    handlePrevious={() => { this.handlePreviousStatusPressed(basket) }}
                                    headerColor={bgColor} lines={linesToRender.sort((a, b) => a.basketLineId - b.basketLineId)} setShouldHideMenu={(value: boolean) => this.setField({ orderSettingsOpenId: value ? undefined : basket.id })}
                                    time={time} height={this.state().flowDirection == FlowDirections.HORIZONTAL ? this.state().orderMaxHeight : undefined}
                                    maxHeight={this.state().flowDirection == FlowDirections.VERTICAL ? this.state().orderMaxHeight : undefined} isSettingsMenuOpen={this.state().orderSettingsOpenId == basket.id && (!isSequel)}
                                    isSelected={this.state().selectedBasketIds.includes(basket.id)} orderType={basket.orderType} orderSource={basket.orderSourceId}
                                    deliveryProviderId={basket.deliveryProviderId}
                                    deliveryProvider={basket.deliveryProvider}

                                    lastModificationDate={basket.lastModificationDate} status={basket.status} showTimer={this.isToday(this.state().selectedDate.toISOString())}
                                    fontsizeTxt={this.state().fontsizeTxt}
                                    orderWidth={this.state().order_width - 10}
                                />
                            </Animated.View>
                        </TouchableWithoutFeedback>
                    );
                    linesToRender = [];
                    isSequel = true;
                    totalHeight = this.state().next_status_height;
                }

                totalHeight += (this.state().product_case_height + subProductsLines.length * this.state().subProduct_height)
                linesToRender.push(line);
                linesToRender.push(...subProductsLines);
                remainingLines = remainingLines.filter(x => x.id != line.id && !subProductsLines.map(y => y.id).includes(x.id));
            }

            if (linesToRender.length != 0) {
                orders.push(
                    <TouchableWithoutFeedback onPress={() => this.handleSelectBasket(basket.id)} onLongPress={() => this.handleLongSelectBasket(basket.id)} disabled={isSequel} key={basket.id}>
                        <Animated.View style={{ zIndex: isSequel ? 0 : numberOfItem, opacity: basket.opacity, width: this.state().order_width }}>
                            <OrderView hasSequel={false} total={basket.total}
                                cardCode={basket.loyaltyCard?.code}
                                basketId={basket.id}
                                zIndex={isSequel ? 0 : numberOfItem} deskNumber={basket.desckNumber}
                                remark={basket.remark}
                                hasNextStatus={currentStatus?.nextStatusIds.length != 0 }
                                hasPreviousStatus={this.state().statuses.filter(x => x.nextStatusIds.includes(currentStatus?.id ?? 0)).length != 0}
                                isSequel={isSequel} handleNext={() => { this.handleNextStatusPressed([basket]) }}
                                handlePrevious={() => { this.handlePreviousStatusPressed(basket) }}
                                handleHistory={() => this.handleDisplayHistory(basket)}
                                handleLog={() => { this.handleDisplayLogs(basket) }}
                                handleShowQR={this.handleShowQr}
                                headerColor={bgColor} lines={linesToRender.sort((a, b) => a.basketLineId - b.basketLineId)} setShouldHideMenu={(value: boolean) => this.setField({ orderSettingsOpenId: value ? undefined : basket.id })}
                                time={time} isSettingsMenuOpen={(this.state().orderSettingsOpenId == basket.id &&(!isSequel))}
                                height={this.state().flowDirection == FlowDirections.HORIZONTAL ? this.state().orderMaxHeight : undefined}
                                maxHeight={this.state().flowDirection == FlowDirections.VERTICAL ? this.state().orderMaxHeight : undefined}
                                isSelected={this.state().selectedBasketIds.includes(basket.id)} orderType={basket.orderType} orderSource={basket.orderSourceId}
                                deliveryProviderId={basket.deliveryProviderId}
                                deliveryProvider={basket.deliveryProvider}
                                lastModificationDate={basket.lastModificationDate} status={basket.status} showTimer={this.isToday(this.state().selectedDate.toISOString())}
                                fontsizeTxt={this.state().fontsizeTxt}
                                orderWidth={this.state().order_width - 10} />
                        </Animated.View>
                    </TouchableWithoutFeedback>
                );
                numberOfItem--;
            }
        }

        return orders;
    }

    toggleKitchenModus() {
        //this.closePopUp();
        const { isKitchenModusEnabled, selectedDate, statuses } = this.state();
        const isSelectedDateToday = selectedDate.toISOString().split('T')[0] == new Date().toISOString().split('T')[0];
        const preparingStatus = statuses.find(x => x.id == 3)

        this.setField({ isKitchenModusEnabled: !isKitchenModusEnabled, selectedDate: new Date(), isLoading: !isSelectedDateToday, selectedStatusId: preparingStatus?.id }, () => {
            if (!isSelectedDateToday) {
                // Resubscribe
                this._signalRConnection.off(`${this._signalRSubscriptionName}${this.state()._selectedStore?.id}`);
                this._signalRConnection.on(`${`${this._signalRSubscriptionName}${this.state()._selectedStore?.id}`}${this.state()._selectedStore?.id}`, this.getBasket);

                this.getBaskets().then(() => {
                    this.setField({ isLoading: false });
                })
            }
        });
    }

    toggleShowNewestOrdersFirst(){
      this.setField(prev => prev.showNewestOrdersFirst = !prev.showNewestOrdersFirst);
    }

    toggleHideContent(){
      this.setField(prev => prev.hideContent = !prev.hideContent);
    }

    private showFooter() {
        Animated.timing(this.footerTransY, {
            useNativeDriver: true,
            toValue: 0,
            easing: Easing.inOut(Easing.sin)
        }).start();
    }

    private getAllSubLines(basketLineId: number, basketLines: BasketLine[]) {
        const subLines: BasketLine[] = [];
        const temp = basketLines.filter(x => x.parentBasketLineId == basketLineId);

        for (let i = 0; i < temp.length; i++) {
            subLines.push(temp[i]);
            const subSubLines = this.getAllSubLines(temp[i].basketLineId, basketLines);

            for (let j = 0; j < subSubLines.length; j++) {
                subLines.push(subSubLines[j]);
            }
        }

        return subLines;
    }

    private hiderFooter() {
        Animated.timing(this.footerTransY, {
            useNativeDriver: true,
            toValue: 75,
            easing: Easing.inOut(Easing.sin)
        }).start();
    }



    private launchAppearingOrderAnimation(basket: ExtendedBasket, callback?: Function) {
        Animated.parallel([
            Animated.timing(basket.opacity, {
                toValue: 1,
                easing: Easing.inOut(Easing.sin),
                useNativeDriver: true
            }),
            Animated.timing(basket.width, {
                toValue: this.state().order_width,
                delay: 600,
                easing: Easing.inOut(Easing.sin),
                useNativeDriver: true
            })
        ]).start(() => { if (callback) { callback(); } });
    }

    private launchDisappearingOrderAnimation(basket: ExtendedBasket, callback?: Function) {
        Animated.parallel([
            Animated.timing(basket.opacity, {
                toValue: 0,
                easing: Easing.inOut(Easing.sin),
                useNativeDriver: true
            }),
            Animated.timing(basket.width, {
                toValue: 0,
                delay: 600,
                easing: Easing.inOut(Easing.sin),
                useNativeDriver: true
            }),
        ]).start(() => { if (callback) callback() });
    }

    private startHubConnection() {
        this._signalRConnection.start().then(async () => {
            if (this._reconnectLoop) {
                clearInterval(this._reconnectLoop);

                // Websocket has been disconnected => retrieve missing updates
                const selectedDate = this.state().selectedDate;
                const basketsResponse = await this._basketsOperation.getByDate(selectedDate, (this.state()._selectedStore as Store).id, this._kitchenLanguage);

                if (basketsResponse && basketsResponse.data) {
                    const baskets: ExtendedBasket[] = basketsResponse.data.data;
                    baskets.forEach((basket) => {
                        this.getBasket(Constants.IsReceivingBasketObject ? basket : basket.id);
                    })
                }
            }
        });
    }

    setFilterSelection(filterKey: string, filterValues: number[]) {
        const { filters } = this.state();
        filters[filterKey] = filterValues;
        this.setField({ filters });
        window.localStorage.setItem('filters', JSON.stringify(filters));

        if (filterKey !== 'status') return;
        const { selectedStatusId } = this.state();
        
        if (filterValues.includes(selectedStatusId) || filterValues.length === 0) return;
        this.setField({ selectedStatusId: filterValues[0] })
    }

    errorManagement(error: any) {
        this.setField({ isLoading: false });
        ErrorHelper.showMessageError(error.response);
    }

    private async showStoreChoose(stores: Store[]): Promise<Store> {
        return new Promise<Store>((resolve) => {
            this.showPopUp(
                <PopupStoresListView
                    stores={stores}
                    callback={(store: Store) => { this.closePopUp(); resolve(store) }}
                    displayNameStore={"title"} />
            );
        });
    }

    zoom(value: number) {
        this.onTouchScreen();
        let zoomValue = this.state().currentZoomPercentage;
        zoomValue += value / 10;

        this.setField({
            currentZoomPercentage: zoomValue,
            header_height: this._header_height * zoomValue,
            sequel_height: this._sequel_height * zoomValue,
            product_case_height: this._product_case_height * zoomValue,
            next_status_height: this._next_status_height * zoomValue,
            subProduct_height: this._subProduct_height * zoomValue,
            order_width: this._order_width * zoomValue,
            fontsizeTxt: this._fontsizeTxt * zoomValue,
            //orderMaxHeight : this.state().orderMaxHeight - value*10,
        }, () => this.formatOrders())
    }

    reset() {
        this.onTouchScreen();




        this.setField({

            currentZoomPercentage: 1,
            header_height: this._header_height,
            sequel_height: this._sequel_height,
            product_case_height: this._product_case_height,
            next_status_height: this._next_status_height,
            subProduct_height: this._subProduct_height,
            order_width: this._order_width,
            fontsizeTxt: this._fontsizeTxt,
        }, () => { this.formatOrders() })
    }

    getStatus() {
        const { statuses, filters } = this.state();

        return statuses.filter(status => {
            return status.isDisplayed && (
                filters.status.length === 0 ||
                filters.status.includes(status.id)
            )
        }).sort((x, y) => x.displayIndex - y.displayIndex);
    }

    fetchRingingStatus() {
        return this._storageManager.fetch<boolean>(StorageConstants.IS_RINGING_LONGER_ACTIVATED)
            .then((isRingingLongerActivated) => {
                if (isRingingLongerActivated === undefined) {
                    this._storageManager.store(StorageConstants.IS_RINGING_LONGER_ACTIVATED, Constants.longerSoundKitchenApp);
                    return Constants.longerSoundKitchenApp;
                } else {
                    return isRingingLongerActivated;
                }
            });


    }

    onTouchScreen() {
        this.setField({ hasRingingStarted: false })
    }

    startAutoRefreshLogic() {
        if (Constants.UseAutoRefresh) {
            let midnight =  moment().endOf('day');
            let now = moment(new Date());
            let nextDay = now.add(1, 'days');

            // calculate the duration
            var durationUntilRefresh = moment.duration(midnight.diff(now)).asMilliseconds();
            durationUntilRefresh+=10000;
            if (durationUntilRefresh > 0) {
                setTimeout(() => {
                    this.handleDateChanged(nextDay.toDate())
                }, durationUntilRefresh);
            }
        }
    }

}
