import { Operation } from "@levelapp/softfabric";
import PosSimulatorService from "../Proxy/PosSimulatorService";


export class PosSimulationOperations extends Operation
{
    
    service : PosSimulatorService;

    constructor()
    {
        super();
        this.service = new PosSimulatorService();
    }
    get(posSimulator:any, callback : Function)
    {
        this.service.getUserFromScan(posSimulator, callback);
    }

    getCoupon(posSimulator:any, callback : Function)
    {
        this.service.getCouponFromScan(posSimulator, callback);
    }

    cancelCoupons(coupons: any[], callback: Function)
    {
        this.service.cancelCoupons(coupons, callback);      
    }

    confirmPurchase(purchase: any, callback: Function){
        this.service.confirmPurchase(purchase, callback);
    }
}