import { View } from "react-native";
import { ContentView } from "@levelapp/softfabric";
import ProductAdditionalInfosViewProps from './ProductAdditionalInfosViewProps';
import ProductAdditionalInfosViewState from './ProductAdditionalInfosViewState';
import ProductAdditionalInfosViewModel from './ProductAdditionalInfosViewModel';
import ProductAdditionalInfosViewStyle from './ProductAdditionalInfosViewStyle';
import TableView from 'src/Common/Components/TabelView/TableView';
import HeaderView from 'src/Presentation/modules/dashboard/components/header/HeaderView';

export default class ProductAdditionalInfosView extends ContentView<ProductAdditionalInfosViewProps, ProductAdditionalInfosViewState, ProductAdditionalInfosViewModel, ProductAdditionalInfosViewStyle>
{
  /*CONSTRUCTOR */
  constructor(props: ProductAdditionalInfosViewProps) {
    super(props);

    //Binding 
    this.bind(new ProductAdditionalInfosViewModel(this), new ProductAdditionalInfosViewStyle(this));
  }

  /* RENDERING */
  render() {
    return (
      <View style={this.style.container}>
        <View style={this.style.box}>     

         <HeaderView
            title={"products.additionalInfos.title"}
            buttonText={"products.additionalInfos.add"}
            description={"products.additionalInfos.message"}
            onButtonPress={this.binding.addField}
            shouldShowButton
          />     

        <View style={this.style.boxContainer}>          
            <TableView
              headerTextStyle={this.style.tableHeader}
              rowStyle={this.style.rowStyle}
              backgroundStepColor={this.style.stepColor}
              nothingToDisplayText={this.binding.translate("component.table.noresults")}
              isLastColumnActions={true}
              data={this.state.tableData}
              isLoading={this.state.isLoading}
              headers={this.state.tableHeaders}
              onPageChange={(page: number) => this.binding.onPageChange(page)}
              handleSortChange={(indexHeaderSort: number, sortOrder: string) => { this.binding.onSortChange(indexHeaderSort, sortOrder) }}
              nbPage={this.state.nbPage}
              currentPage={this.state.currentPage - 1}
              isSortable={true} />
          </View>
        </View>
      </View>

    );
  }
}