 
import {ContentShellView} from '@levelapp/softfabric';
import ShellAdminUsersViewState from './ShellAdminUsersViewState';
import ShellAdminUsersViewProps from './ShellAdminUsersViewProps';
import ShellAdminUsersViewStyle from './ShellAdminUsersViewStyle';
import ShellAdminUsersViewModel from './ShellAdminUsersViewModel';
import {View} from 'react-native';

export default class ShellAdminUsersView extends ContentShellView<ShellAdminUsersViewProps, ShellAdminUsersViewState, ShellAdminUsersViewModel, ShellAdminUsersViewStyle>
{
  /* CONSTRUCTOR */
  constructor(props:ShellAdminUsersViewProps)
  {
    super(props);

    // Binding
    this.bind(new ShellAdminUsersViewModel(this), new ShellAdminUsersViewStyle(this));
  }

  /* RENDERING */
  render()
  {
    return (
      <View style={{flex : 1}}>
        {this.getSwitch()}
      </View>
    );
  }
}