import Constants from "../Constants/Constants";


export default class PriceHelper {

    static format(amount: number) {
        if(Constants.isAmountInCent) return PriceHelper.centToEuro(amount);
        return PriceHelper.formatEuro(amount);
    }

    static formatEuro(amount: number) {
        return `${amount.toFixed(2).replace('.', ',')} €`
    }

    
    static centToEuro(amount: number) {
        return PriceHelper.formatEuro(amount/100);
    }
}