import { Operation } from "@levelapp/softfabric";

import AddProductGroup from "src/Common/DTO/AddProductGroup";

import ProductGroupService from "../Proxy/ProductGroupService";

export default class ProductsOperations extends Operation {
  service: ProductGroupService;

  constructor() {
    super();
    this.service = new ProductGroupService();
  }

  get() {
    return this.service.get();
  }

  post(productGroups: AddProductGroup) {
    return this.service.post(productGroups);
  }

  update(productGroup: AddProductGroup) {
    return this.service.update(productGroup);
  }
  
  addSingleProduct(products: any[]) {
    return this.service.addSingleProduct(products);
  }
  
  delete(id: number) {
    return this.service.delete(id);
  }

  removeProductFromGroup(id: number) {
    return this.service.removeProductFromGroup(id);
  }
}