 
import { ContentShellView } from '@levelapp/softfabric';
import ShellUsersViewState from './ShellUsersViewState';
import ShellUsersViewProps from './ShellUsersViewProps';
import ShellUsersViewStyle from './ShellUsersViewStyle';
import ShellUsersViewModel from './ShellUsersViewModel';
import { View } from 'react-native';

export default class ShellUsersView extends ContentShellView<ShellUsersViewProps, ShellUsersViewState, ShellUsersViewModel, ShellUsersViewStyle>
{
  /* CONSTRUCTOR */
  constructor(props:ShellUsersViewProps)
  {
    super(props);

    // Binding
    this.bind(new ShellUsersViewModel(this), new ShellUsersViewStyle(this));
  }

  /* RENDERING */
  render()
  {
    return (
      <View style={{flex : 1}}>
        {this.getSwitch()}
      </View>
    );
  }
}