import {ContentViewStyle, Application} from '@levelapp/softfabric';
import {StyleProp,ViewStyle, TextStyle} from 'react-native';
import { StyleManager, UiManagers } from '@levelapp/softfabric-ui';

export default class AutomatedMessagesViewStyle extends ContentViewStyle
{
    page: StyleProp<ViewStyle> = {
        flex: 1
    }
    MyStyleManager : StyleManager = Application.current.resolve<StyleManager>(UiManagers.STYLE_MANAGER);

    container: StyleProp<ViewStyle> = {
        display: 'flex',
        flex: 1,
        alignItems: 'center'
    }

    box: StyleProp<ViewStyle> = {
        width: '95%',
        backgroundColor: 'white',
        borderRadius: 6,
        marginTop: 40,
        shadowColor: "#0000006b",
        shadowOffset: {
            width: 1,
            height: 0,
        },
        shadowOpacity: 0.70,
        shadowRadius: 20.00,
        elevation: 60,
    }

    

    boxContainer: StyleProp<ViewStyle> = {
        display: 'flex',
        alignSelf: 'center',
        minHeight: 250,
        width: '100%',
        paddingTop: 30,
        paddingBottom: 30,
        paddingLeft: 50,
        paddingRight: 50,
    }

    

    

    section: StyleProp<ViewStyle> = {
        alignSelf: 'flex-end',
        marginVertical: 15,
        paddingHorizontal: 10
    };

    
    textArea: StyleProp<any> = {
        width: '100%',
        minWidth: 475,
        height : 92,
        paddingHorizontal: 5,
        borderRadius : 8,
        boxShadow: '0 5px 14px 0 rgba(0,0,0,0.1)',
    };

    errorTextArea: StyleProp<ViewStyle> = {
        borderColor: 'red',
        borderWidth: 1,
        width: '100%',
        minWidth: 475,
        height : 92,
        paddingHorizontal: 5,
        borderRadius : 8,
    };
    inputTitle: StyleProp<TextStyle> = {
        fontSize: 14,
        fontWeight: '600',
        color: '#3F3F3F',
        fontFamily : 'Montserrat SemiBold',
        marginBottom : 13,
    };

    textInput: StyleProp<any> = {
        width: 140,
        paddingHorizontal: 15,
        borderRadius : 8,
        boxShadow: '0 5px 14px 0 rgba(0,0,0,0.1)'
    }

    errorTextInput : StyleProp<any> = {
        borderColor: 'red',
        borderWidth: 1,
        width: 140,
        paddingHorizontal: 15,
        borderRadius : 8,
        boxShadow: '0 5px 14px 0 rgba(0,0,0,0.1)'
    }

    buttonBox : StyleProp<ViewStyle> =
    {
        flexDirection: 'row',
        justifyContent: 'flex-end',
        alignItems:'center',
        flex:1,
        padding: 50,
    }

    tableHeader : StyleProp<TextStyle> =
    {
        color: '#575757',
        fontSize : 15,
        fontFamily : 'Montserrat',
        fontWeight : 'bold',
    }

    rowStyle: StyleProp<ViewStyle> =
    {
        minHeight: 55
    }

    stepColor : StyleProp<ViewStyle> =
    {
        backgroundColor : this.MyStyleManager.primaryColor,
    }
}