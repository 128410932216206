import { Service, Application, Managers, HttpManager } from "@levelapp/softfabric";
import AddDeliveryCategory from "src/Common/DTO/AddDeliveryCategory";
import DeliveryCategory from "src/Common/DTO/DeliveryCategory";


export default class DeliveryCategoriesService extends Service {
  httpClient = Application.current.resolve<HttpManager>(Managers.HTTP_MANAGER);

  async post(deliveryCategories: AddDeliveryCategory[]): Promise<DeliveryCategory> {
    const response = await this.httpClient.post('deliveryCategories/import', [], deliveryCategories);

    if (response && response.data) {
      return response.data.data;
    }

    return Promise.reject();
  }

  async get(): Promise<AddDeliveryCategory[]> {
    const response = await this.httpClient.get('deliveryCategories/export');

    if (response && response.data) {
      return response.data.data;
    }

    return Promise.reject();
  }
}