import { Operation } from "@levelapp/softfabric";
import LoyaltyRulesTranslationsService from "../Proxy/LoyaltyRulesTranslationsService";

export default class LoyaltyRulesTranslationsOperation extends Operation
{
    service: LoyaltyRulesTranslationsService;

    constructor()
    {
        super();
        this.service = new LoyaltyRulesTranslationsService();
    }

    get(callback: Function)
    {
        this.service.get(callback);
    }

    put(translation: any, callback: Function)
    {
        this.service.put(translation, callback);
    }
}