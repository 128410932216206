import { ContentViewModel, ContentView } from '@levelapp/softfabric';
import MessagesViewState from './MessagesViewState';
import MessagesViewProps from './MessagesViewProps';
import LanguagesOperations from '../../../../../../Transfer/LanguagesOperations';
import UsersOperations from '../../../../../../Transfer/UsersOperations';
import Option from '../../../../../../Common/DTO/Option';
import UserMetadataUnitsOperations from '../../../../../../Transfer/UserMetadataUnitsOperations';
import UserMetadataValuesOperations from '../../../../../../Transfer/UserMetadataValuesOperations';
import Metadata from '../../../../../../Common/DTO/Metadata';
import SearchItem from './SearchItem';
import ToastHelper from '../../../../../../Common/Helpers/ToastHelper';
import Helpers from '../../../../../../Common/Helpers/Helpers';
import MessageCampaignsOperations from '../../../../../../Transfer/MessageCampaignOperations';
import TranslationValue from '../../../../../../Common/DTO/TranslationValue';
 
import TestPopupView from './testpopup/TestPopupView';
import DispatcherManager from '../../../../../../Common/Managers/DispatcherManager';
import UserMetaDataOperations from '../../../../../../Transfer/UserMetaDataOperations';

export default class MessagesViewModel extends ContentViewModel<MessagesViewState, MessagesViewProps>{

    languagesOperation: LanguagesOperations;
    usersOperation: UsersOperations;
    messageCampaignOperation: MessageCampaignsOperations;
    userMetadataUnitsOperation: UserMetadataUnitsOperations;
    userMetadataValuesOperation: UserMetadataValuesOperations;
    userMetaDataOperation : UserMetaDataOperations;
    metaDataNames: Metadata[];
    _dispatcherHelper : DispatcherManager

    constructor(props: ContentView) {
        super(props);
        this.languagesOperation = new LanguagesOperations();
        this.usersOperation = new UsersOperations();
        this.messageCampaignOperation = new MessageCampaignsOperations();
        this.userMetadataUnitsOperation = new UserMetadataUnitsOperations();
        this.userMetadataValuesOperation = new UserMetadataValuesOperations();
        this.userMetaDataOperation = new UserMetaDataOperations();
        this.metaDataNames = [];
        this._dispatcherHelper = new DispatcherManager()
        if(this.props().editPopup){
            let props = this.props()
            this.initialState({
                languages: [], selectedCodes: [], loyaltyCardCodes: [], messageTranslations: props.mc.description.translationValues , showErrors: false,
                isLoading: false, showErrorSelect: false, isEmail: props.mc.isEmail, isInApp: props.mc.isInApp, isPushNotif: props.mc.isPushNotification, titleTranslations: props.mc.title.translationValues, isSegmentation: false
                , isCode: false, genders: [], status: [], countries: [], selectedOptions: [], showErrorTypeMessage: false, optionMap: [],
                optionOnDrop: [],isLoadingFirst:false, isEdit : true, nbNotifToSend : 0, nbMailToSend : 0, isButton1Loading : false, isButton2Loading : false
                ,isStartButtonLoading : false, emailToNotify: props.mc.emailToNotify,tab: "main", metaData : [],comboBoxSelected :[],segmentation : []
            })
        }
        else{
            this.initialState({
                languages: [], selectedCodes: [], loyaltyCardCodes: [], messageTranslations: [], showErrors: false,
                isLoading: false, showErrorSelect: false, isEmail: false, isInApp: false, isPushNotif: false, titleTranslations: [], isSegmentation: false
                , isCode: false, genders: [], status: [], countries: [], selectedOptions: [], showErrorTypeMessage: false, optionMap: [],
                optionOnDrop: [],isLoadingFirst:false, isEdit : false, nbNotifToSend : 0, nbMailToSend : 0, isButton1Loading : false, isButton2Loading : false
                ,isStartButtonLoading : false, emailToNotify: "",tab: "main",metaData : [], comboBoxSelected : [],segmentation : []
            })
        }

        this.refresh = this.refresh.bind(this);
        this.saveMessageCampaign = this.saveMessageCampaign.bind(this);
        this.selectItem = this.selectItem.bind(this);
        this.changeColor = this.changeColor.bind(this);
        this.handleCheckBoxEmailChanged = this.handleCheckBoxEmailChanged.bind(this);
        this.handleCheckBoxInAppChanged = this.handleCheckBoxInAppChanged.bind(this);
        this.handleCheckBoxPushChanged = this.handleCheckBoxPushChanged.bind(this);
        this.handleItExist = this.handleItExist.bind(this);
        this.itExist = this.itExist.bind(this);
        this.handleItNotExist = this.handleItNotExist.bind(this);
        this.handleTitleChanged = this.handleTitleChanged.bind(this);
        this.changeState = this.changeState.bind(this);
        this.changeAnotherState = this.changeAnotherState.bind(this);
        this.getMetadataName = this.getMetadataName.bind(this);
        this.selectOptions = this.selectOptions.bind(this);
        this.selectSegmentation = this.selectSegmentation.bind(this);
        this.testCampaign = this.testCampaign.bind(this);
        this.startCampaign = this.startCampaign.bind(this);
        this.handleEmailChanged = this.handleEmailChanged.bind(this);
        this.changeTab = this.changeTab.bind(this);
        this.changeComboBoxSelected = this.changeComboBoxSelected.bind(this);
        this.changeSegmentation = this.changeSegmentation.bind(this)
    }

    componentDidMount() {
        this.refresh();
        this._dispatcherHelper.valueEmail = this.handleEmailChanged
    }

    changeTab(value : string){
        this.setField({tab : value })
    }

    refresh() {
        this.setField({isLoadingFirst:true},this.handleOperationGetLanguages());
        if(this.state().isEmail){
            this.messageCampaignOperation.getNbUserSegmentation(this.state().segmentation,this.state().metaData,"email",(response : any) =>{
                this.setField({nbMailToSend : response.data.data})
            })}
        if(this.state().isPushNotif){
            this.messageCampaignOperation.getNbUserSegmentation(this.state().segmentation,this.state().metaData,"pn",(response : any) =>{
                this.setField({nbNotifToSend : response.data.data})
            })}
        
        this.userMetaDataOperation.getAllMetaData((response : any) =>{
            this.setField({metaData : response})
            response.forEach( (el : any) => {if(el.userMetadataUnits.length > 0) this.state().comboBoxSelected.push([])})
        })
    }
    
    handleOperationGetLanguages(){
        this.languagesOperation.get((languages: any) => 
            {
                var messageTable = Helpers.generateTranslationTable(languages.data)
                var titleTable = Helpers.generateTranslationTable(languages.data)

                if(this.props().editPopup){
                    messageTable.forEach((item,index) => item.value = this.state().messageTranslations[index].value)
                    titleTable.forEach((item,index) => item.value = this.state().titleTranslations[index].value)
                }
                this.setField({ languages: languages.data, messageTranslations : messageTable, titleTranslations : titleTable }, this.handleOperationGetMetadatas());
            });
    }
    handleOperationGetMetadatas(){
        let optionMap: Option[] = [];
        let options: any[] = [];
        let selected: Option[] = [];
        this.userMetadataUnitsOperation.get((data: any) => {
            data.forEach((unit: any) => {
                if (options.indexOf(unit.userMetadataId) === -1) {
                    optionMap.push({ key: unit.userMetadataId, values: [{ Id: unit.id, Name: unit.label }] });
                    options.push(unit.userMetadataId);
                    this.metaDataNames.push({ Id: unit.userMetadataId, Name: unit.userMetadata.name });
                    selected.push({key:unit.userMetadataId,values:[]});
                } else {
                    optionMap[options.indexOf(unit.userMetadataId)].values.push({ Id: unit.id, Name: unit.label });
                }
            });
            this.setField({optionMap: optionMap,isLoadingFirst:false,selectedOptions:selected});
        });
    }

    changeComboBoxSelected(value : any , index : number){
        var new_combo = this.state().comboBoxSelected
        new_combo[index] = value
        this.setField({comboBoxSelected : new_combo})
    }

    changeSegmentation(value : any, segmentationIndex : number){
        var newSegmentation = this.state().segmentation
        newSegmentation[segmentationIndex] = value
        this.setField({segmentation : newSegmentation})
    }

    itExist(item : SearchItem)
    {
        this.usersOperation.getUserByLoyaltyCode(`${item.Code}` , this.handleItExist , this.handleItNotExist);
    }

    handleItExist()
    {
        //NOTHING TO DO
    }

    handleItNotExist()
    {
        this.showToast(ToastHelper.getToast('message.itexists.failed','error'),() => {},2500);
        var state = this.state();
        state.selectedCodes.pop();   
        this.setField({selectedCodes : state.selectedCodes});
    }

    getMetadataName(Id: Number): string | null {
        const names = this.metaDataNames.filter(x => x.Id == Id);
        if (names.length == 0) {
            return null;
        }
        return names[0].Name;
    }

    selectOptions(Id: Number) {
        const options = this.state().optionMap.filter(x => x.key == Id);
        this.setField({ optionOnDrop: options[0].values });
    }

    handleMessageChanged(text: string, index: any) {
        this.state().showErrorSelect = false;
        let messageTranslation = this.state().messageTranslations;
        if (this.state().messageTranslations.length > index) {
            messageTranslation[index].value = text;
            messageTranslation[index].language = this.state().languages[index] ;
        } else {
            let trans = { value: text, language: this.state().languages[index] };
            messageTranslation.push(trans);
        }
        this.setField({ messageTranslations: messageTranslation });
    }

    handleEmailChanged(text: string){
        this.setField({emailToNotify : text})
    }

    handleTitleChanged(text: string, index: any) {
        this.state().showErrorSelect = false;
        let titleTranslationsTmp = this.state().titleTranslations;
        if (this.state().titleTranslations.length > index) {
            titleTranslationsTmp[index].value = text;
            titleTranslationsTmp[index].language = this.state().languages[index]
        } else {
            let trans = { value: text, language: this.state().languages[index]  };
            titleTranslationsTmp.push(trans);
        }
        this.setField({ titleTranslations: titleTranslationsTmp });
    }

    isSegmentationEmpty():boolean{
        for(let i=0;i<this.state().selectedOptions.length;i++){
            if(this.state().selectedOptions[i].values.length>=1){
                return false;
            }
        }
        return true;
    }

    testCampaign(){
        this.showPopUp(<TestPopupView messageCampaignId={this.props().messageCampaignId}/>)
    }

    startCampaign(){
        this.setField({isStartButtonLoading : true})
        this.messageCampaignOperation.start(this.props().messageCampaignId,() => { },()=>{this.showToast(ToastHelper.getToast("messageCampaignForm.start.failure","error"),() => {},2500);})
        // Non awaiting -> because start take a lot of time.
        this.setField({ isStartButtonLoading: false });
        this.closePopUp();
        this.props().refreshParent()
        this.showToast(ToastHelper.getToast("messageCampaignForm.start.success", "success"), () => { }, 2500);
    }

    saveMessageCampaign() {
        this.setField({isButton1Loading : true})
        let i = 0;
        if (this.state().messageTranslations.length !== this.state().languages.length ||
            this.state().titleTranslations.length !== this.state().languages.length) {
            this.setField({ showErrors: true });
            i++;
        }
        if (this.state().isEmail === false && this.state().isInApp === false && this.state().isPushNotif === false) {
            this.setField({ showErrorTypeMessage: true });
            i++;
        }
        if (i === 0) {
            //envoyer au back
            this.setField((previousState) => previousState.isLoading = true);
            let title : TranslationValue[] = []
            this.state().titleTranslations.forEach(translation => {  
                title.push({
                    language : {key:translation.language.key},
                    value : translation.value
                })
            })
            let description : TranslationValue[] = []
            this.state().messageTranslations.forEach(translation => {
                description.push({
                    language : {key:translation.language.key},
                    value : translation.value
                })
            })
            this.messageCampaignOperation.getCardCodeListFromSegmentation(this.state().segmentation,this.state().metaData,"email",(response : any) =>{
                var msg = {
                    titleTranslations : title,
                    descriptionTranslations: description,
                    subtitleTranslations : description,
                    isEmail: this.state().isEmail,
                    isMessageCenter: this.state().isInApp,
                    isPushNotification: this.state().isPushNotif,
                    loyaltyCardCodes : response.data.data,
                    EmailToNotify : this.state().emailToNotify,
                    id : this.props().mc == undefined ? 0 : this.props().mc.id,
                    state : this.props().mc == undefined ? null : this.props().mc.state
                }
                if(this.props().mc == undefined){
                    this.messageCampaignOperation.post(msg, () => {},(error : any) => {     
                            console.error(error)       
                            this.showToast(ToastHelper.getToast("messageCampaigns.popup.failure.added","error"),() => {},2500);        
                            this.closePopUp();})
                    this.setField({ isLoading: false, isButton1Loading: false });
                    this.closePopUp();
                    this.props().refreshParent()
                    this.showToast(ToastHelper.getToast("messageCampaigns.popup.success.added", "success"), () => { }, 2500);
                }
                else{
                    this.messageCampaignOperation.updateMessageCampaign(msg ,() => {},(error : any) => {
                            console.error(error)            
                            this.showToast(ToastHelper.getToast("messageCampaigns.popup.failure.added","error"),() => {},2500);        
                            this.closePopUp();})
                    this.setField({ isLoading: false, isButton1Loading: false });
                    this.closePopUp();
                    this.props().refreshParent()
                    this.showToast(ToastHelper.getToast("messageCampaigns.popup.success.added", "success"), () => { }, 2500);
                }
            })
        }
    }

    
    selectItem(itemsSelected: any) {
        if (itemsSelected.length > 0) {
            this.setField({ isSegmentation: false, isCode: true });
        } else {
            this.setField({ isSegmentation: false, isCode: false });
        }
        this.setField({ selectedCodes: itemsSelected });
    }

    selectSegmentation(itemsSelected: any , index : number){
        if(itemsSelected.length > 0 || this.checkOthersEmpty(index)){
            this.setField({ isSegmentation: true, isCode: false });
        }else{
            this.setField({ isSegmentation: false, isCode: false });
        }       
        this.setField((previousState)=>previousState.selectedOptions[index].values = itemsSelected);
    }

    checkOthersEmpty(index:number):boolean{
        for(let i=0;i<this.state().selectedOptions.length;i++){
            if(i!==index && this.state().selectedOptions[i].values.length>0){
                return true;
            }
        }
        return false;
    }

    changeState() {
        this.setField({ isSegmentation: true, isCode: false });
    }
    changeAnotherState() {
        this.setField({ isSegmentation: false });
    }
    changeColor() {
        this.setField((previousState) => previousState.showErrorSelect = false);
    }
    handleCheckBoxEmailChanged(value: any) {
        this.setField({ isEmail: value,showErrorTypeMessage: false });
        if(value){
        this.messageCampaignOperation.getNbUserSegmentation(this.state().segmentation,this.state().metaData,"email",(response : any) =>{
            this.setField({nbMailToSend : response.data.data})
        })}
        else{
            this.setField({nbMailToSend : 0})
        }
    }
    handleCheckBoxInAppChanged(value: any) {
        this.setField({ isInApp: value,showErrorTypeMessage: false });
    }
    handleCheckBoxPushChanged(value: any) {
        this.setField({ isPushNotif: value,showErrorTypeMessage: false });
        if(value){
            this.messageCampaignOperation.getNbUserSegmentation(this.state().segmentation,this.state().metaData,"pn",(response : any) =>{
                this.setField({nbNotifToSend : response.data.data})
            })}
        else{
            this.setField({nbNotifToSend : 0})
        }
    }

}