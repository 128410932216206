 
import {ContentView} from '@levelapp/softfabric';
import SwitchItemViewState from './SwitchItemViewState';
import SwitchItemViewProps from './SwitchItemViewProps';
import SwitchItemViewStyle from './SwitchItemViewStyle';
import SwitchItemViewModel from './SwitchItemViewModel';
import { View, Text, TouchableWithoutFeedback } from 'react-native';

export default class SwitchItemView extends ContentView<SwitchItemViewProps, SwitchItemViewState, SwitchItemViewModel, SwitchItemViewStyle>
{
  /* CONSTRUCTOR */
  constructor(props:SwitchItemViewProps)
  {
    super(props);

    // Binding
    this.bind(new SwitchItemViewModel(this), new SwitchItemViewStyle(this));
  }

  /* RENDERING */
  render()
  {
    return (
      <TouchableWithoutFeedback onPress={() =>this.props.handleOnPress()}>
        <View style={[this.props.shouldRadiusLeft && this.style.left , this.props.shouldRadiusRight && this.style.right , this.style.item  , this.props.active && this.style.active]}>
          <Text style={[this.style.text , this.props.active && this.style.activeText]}>{this.props.title}</Text>
        </View>
      </TouchableWithoutFeedback>

    );
  }
}