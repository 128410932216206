 
import { View } from 'react-native';
import ShellViewState from './ShellViewState';
import ShellViewProps from './ShellViewProps';
import ShellViewStyle from './ShellViewStyle';
import ShellViewModel from './ShellViewModel';
import LoginView from './modules/login/LoginView';
import { Switch, Route, Redirect } from 'react-router';
import { ContentView, Application, NavigationManager, Managers, LanguageManager } from '@levelapp/softfabric';
import DashboardshellView from './modules/dashboard/DashboardshellView';

export default class ShellView extends ContentView<ShellViewProps, ShellViewState, ShellViewModel, ShellViewStyle>
{
  NavigationManager : NavigationManager = Application.current.resolve<NavigationManager>(Managers.NAVIGATION_MANAGER);
  LanguageManager : LanguageManager = Application.current.resolve<LanguageManager>(Managers.LANGUAGE_MANAGER);

  /* CONSTRUCTOR */
  constructor(props: ShellViewProps)
  {
    super(props);

    // Binding
    this.bind(new ShellViewModel(this), new ShellViewStyle(this));
  }

  /* RENDERING */
  render()
  {
    return (
      <View style={this.style.shell}>

        {/* TODO */}
        {this.NavigationManager.route.endsWith('/login') ? (
          <Switch>
            <Route path="/:language/login" render={() => <LoginView />} />
            <Route path="/:language" render={() => <Redirect to={"/" + this.LanguageManager.language.toString() + "/login"} />} />
          </Switch>
        )
          :
          (
            <Switch>
              <Route path="/:language/dashboard" render={() => <DashboardshellView />} />
              <Route path="/:language" render={() => <Redirect to={"/" + this.LanguageManager.language.toString() + "/dashboard"} />} />
            </Switch>
          )}

      </View>
    )
  }
}