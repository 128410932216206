 
import {ContentView} from '@levelapp/softfabric';
import PermissionViewState from './PermissionViewState';
import PermissionViewProps from './PermissionViewProps';
import PermissionViewStyle from './PermissionViewStyle';
import PermissionViewModel from './PermissionViewModel';
import { View, CheckBox, Text, Button } from 'react-native';
import { LottieView, TranslationView } from '@levelapp/softfabric-ui';
import Constants from '../../../../../../../../../Common/Constants/Constants';
import Permission from '../../../../../../../../../Common/DTO/Permission';
import LineView from '../../../../../../components/line/LineView';

export default class PermissionView extends ContentView<PermissionViewProps, PermissionViewState, PermissionViewModel, PermissionViewStyle>
{
  /* CONSTRUCTOR */
  constructor(props:PermissionViewProps)
  {
    super(props);

    // Binding
    this.bind(new PermissionViewModel(this), new PermissionViewStyle(this));
  }

  /* RENDERING */
  render()
  {

    const { header , inputTitle , bodyTitle} = this.style;

    return (
      <View style={{ display: "flex", flexDirection: "column", paddingHorizontal: 170, paddingVertical: 50 }}>    
            <View>
              {this.props.isLoading ? (
                  <LottieView height={150} width={150} source={require("../../../../../../../../../Common" + Constants.LottieAnimationPath)}/>
              ) : 
              (
              <View style={header}>
                  { this.props.allPermissions.map((content: Permission) => {
                      return (
                          <View style={{ flexDirection: "row" }}>
                              <CheckBox style={{ marginRight: 10 }} value={ this.binding.isChecked(content)} onValueChange={(value: boolean) => this.binding.handleCheckboxPermissionChange( value, content )}/>
                              <Text style={inputTitle}>
                                  {content.key}
                              </Text>
                          </View>
                      );
                  })}
              </View>
              )}
            </View>
            <LineView/>
            <TranslationView upperCase style={bodyTitle}>
                permission.body.title
            </TranslationView>
            <View style={{ flexDirection: "row" }}>
                <Button
                    title={this.binding.translate('permission.body.button')}
                    onPress={() => {
                        this.binding.showGlobalPermissionsManagementPopup();
                    }}
                />
            </View>
      </View>
    );
  }
}