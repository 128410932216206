import {ContentViewModel,ContentView} from '@levelapp/softfabric';
import PspSectionViewState from './PspSectionViewState';
import PspSectionViewProps from './PspSectionViewProps';
import PspHelper from '../../../../../../../../../Common/Helpers/PspHelper';
import { PspTypes } from '../../../../../../../../../Common/Enums/PspTypes';

export default class PspSectionViewModel extends ContentViewModel<PspSectionViewState, PspSectionViewProps>
{
      /* CONSTRUCTOR */
    constructor(view: ContentView)
    {
        super(view);

        this.initialState(
            {
                psplist : [],
                currentselection : 
                {
                    title : PspHelper.getCurrentPsp()
                },
            }); // Initialize your state here

        // Bindings
        this.handleChangePsp = this.handleChangePsp.bind(this);
    }

    componentDidMount()
    {
        this.getPspList();
    }

    handleChangePsp(value : any)
    {

        this.setField({currentselection : value})
    }

    getPspList()
    {
        var psplist : any[] = [];
        var list = PspHelper.getList()
        list.map((value : PspTypes) =>
        {
            var json : any ={};
            json.title = value;
            psplist.push(json);
        })
        this.setField({psplist : psplist})
    }
}