import { CSSProperties } from 'react';
import { StyleProp, ViewStyle } from 'react-native';

import { ContentViewStyle } from '@levelapp/softfabric';

export default class CouponOrderSourceOrderTypeViewStyle extends ContentViewStyle {
  page: StyleProp<ViewStyle> = {
    flex: 1
  };

  container: StyleProp<ViewStyle> = {
    flexDirection: "column",
    paddingHorizontal: 170,
    paddingVertical: 50
  };

  th: CSSProperties = {
    backgroundColor: '#e4e4e4',
    border: '1px solid black'
  };

  td: CSSProperties = {
    border: '1px solid black'
  };

  checkbox: StyleProp<any> = {
    width: 20,
    height: 20
  };
}