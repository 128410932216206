import {ContentViewModel,ContentView, Application, LanguageManager, Managers} from '@levelapp/softfabric';
import MembershipViewState from './MembershipViewState';
import MembershipViewProps from './MembershipViewProps';
import LanguagesOperations from '../../../../../../../Transfer/LanguagesOperations';
import { TableRow, TranslationView } from '@levelapp/softfabric-ui';
 
import ConfirmPopUpView from '../../../../../../../Common/Components/confirmpopup/ConfirmPopUpView';
import ToastHelper from '../../../../../../../Common/Helpers/ToastHelper';
import Language from '../../../../../../../Common/DTO/Language';
import PaginationHelper from '../../../../../../../Common/Helpers/PaginationHelper';
import ErrorHelper from '../../../../../../../Common/Helpers/ErrorHelper';
import { View } from 'react-native';
import MembershipOperations from 'src/Transfer/MembershipOperations';
import Membership from 'src/Common/DTO/Membership';
import Helpers from '../../../../../../../Common/Helpers/Helpers';

export default class MembershipViewModel extends ContentViewModel<MembershipViewState, MembershipViewProps>
{
    _languages: Language[] = [];
    languageOperation: LanguagesOperations;
    membershipOperations: MembershipOperations;
    searchText: string;
    interval: any;
    isSorting: boolean;
    indexHeaderSort: number;
    sortOrder: string;
    sortKey: string[];

    /* CONSTRUCTOR */
    constructor(view: ContentView) {
        super(view);
        this.interval = null;
        this.searchText = '';
        this.isSorting = false;
        this.indexHeaderSort = -1;
        this.sortOrder = 'asc';
        this.sortKey = ['Id', 'MembershipType', 'Title'];

        this.initialState({
            tableHeaders: ['Id', 'PosId', 'Title'],
            tableData: [],
            isLoading: true,
            nbPage: 0,
            currentPage: 1,
            isResearching: false
        });

        this.languageOperation = new LanguagesOperations();
        this.membershipOperations = new MembershipOperations();

        this.languageOperation.get((languages: any) => {
            this._languages = languages.data;
        })

        this.addField = this.addField.bind(this);
        this.handleResearching = this.handleResearching.bind(this);
        this.handleCloseResearching = this.handleCloseResearching.bind(this);
        this.onPageChange = this.onPageChange.bind(this);
        this.onSortChange = this.onSortChange.bind(this);
        this.deleteField = this.deleteField.bind(this);
        this.editField = this.editField.bind(this);
        this.errorManagement = this.errorManagement.bind(this);
        this.refresh = this.refresh.bind(this);

    }

    componentDidMount() {
        this.refresh();
    }

    refresh() {
        this.setField({ isLoading: true });
        let parameters = PaginationHelper.basicResearchPush(
            this.state().currentPage,
            20,
            this.sortKey[this.indexHeaderSort],
            this.sortOrder,
            Application.current.resolve<LanguageManager>(Managers.LANGUAGE_MANAGER).language ,
            this.searchText,
            this.isSorting,
            this.state().isResearching);
        this.getData(parameters);

    }

    getData(parameters: any[]) {
        this.membershipOperations.getPagination(parameters, (memberships: any) => {
            let tableData: TableRow[] = [];
            memberships.objects.forEach((membership: Membership) => {
                if (membership.titleTranslations === null) {
                    membership.titleTranslations = Helpers.generateTranslationTable(this._languages);
                }

                let title = membership.titleTranslations[0].value;
                tableData.push({ data: [membership.id, membership.id.toString(), title,], isDeletable: true, isEditable: true, editCallback: () => { this.editField() }, deleteCallback: () => { this.deleteField(membership) } });
            })
            this.setField({ tableData: tableData, products: memberships.object, nbPage: memberships.totalPage, isLoading: false });
        },this.errorManagement)
    }

    deleteField(membership: Membership) {
        this.showPopUp(
            <ConfirmPopUpView callBack={() => {
                this.membershipOperations.delete(membership.id, () => {
                    this.showToast(ToastHelper.getToast("products.popup.success.deleted",'success'),() => {},2500)
                    this.refresh();
                },this.errorManagement);
            }} />
        );
    }

    editField() {
        this.refresh();
    }

    addField() {
        this.showPopUp(
            <View>
                <TranslationView>TMP</TranslationView>
            </View>
        );
    }

    

    handleResearching(searchText: string) {
        this.setField({ isLoading: true, isResearching: true });
        this.searchText = searchText;

        if (this.interval) {
            clearInterval(this.interval);
        }
        this.interval = setTimeout(() => {
            this.onPageChange(0)
        }, 1000)
    }

    handleCloseResearching() {
        this.state().isResearching = false;
        this.onPageChange(0);
    }

    onPageChange(page: number) {
        this.state().currentPage = page + 1;
        this.refresh()
    }

    onSortChange(indexHeaderSort: number, sortOrder: string) {
        if (indexHeaderSort == -1) {
            this.isSorting = false;
        } else {
            this.indexHeaderSort = indexHeaderSort;
            this.sortOrder = sortOrder;
            this.isSorting = true;
        }
        this.refresh();
    }

    errorManagement(error : any) 
    {
        this.setField({isLoading : false});
        ErrorHelper.showMessageError(error.response);
    }
}