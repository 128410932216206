import {ContentShellViewModel, ContentShellView} from '@levelapp/softfabric';
import ShellProductcategoriesViewState from './ShellProductcategoriesViewState';
import ShellProductcategoriesViewProps from './ShellProductcategoriesViewProps';
 
import ProductCategoriesView from './pages/productcategories/ProductCategoriesView';
import EnumModules from '../../../../../Common/Constants/EnumModules';

export default class ShellProductcategoriesViewModel extends ContentShellViewModel<ShellProductcategoriesViewState, ShellProductcategoriesViewProps>
{

    constructor(view : ContentShellView)
    {
        super(view);

        this.registerEntryPoint('main'); // TODO : change with the route of your entry point
        this.register(<ProductCategoriesView/> ,'main'); // TODO : register your routes with the JSX (param1) and the route (param2)

        this.initialisation();
    }

    getModuleId()
    {
        return EnumModules.PRODUCT_CATEGORIES;
    }

}