import { Operation } from "@levelapp/softfabric";
import Membership from "src/Common/DTO/Membership";
import MembershipService from "src/Proxy/MembershipService";

export default class MembershipOperations extends Operation
{
    service :  MembershipService;

    constructor()
    {
        super();
        this.service = new MembershipService();
    }
    
    getPagination(parameters:any[],callBack:Function, onFailure: Function){
        this.service.getPagination(parameters,callBack,onFailure);
    }

    post(membership: Membership, onSuccess: Function, onFailure: Function){
        this.service.post(membership, onSuccess,onFailure);
    }

    put(membership: Membership, onSuccess: Function, onFailure: Function){
        this.service.put(membership, onSuccess,onFailure);
    }

    delete(membershipId: number, onSuccess: Function, onFailure: Function){
        this.service.delete(membershipId, onSuccess,onFailure);
    }
}