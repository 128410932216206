import { ContentViewStyle } from '@levelapp/softfabric';
import { ImageStyle, StyleProp, TextStyle, ViewStyle } from 'react-native';

export default class OrderFiltersPopupViewStyle extends ContentViewStyle
{

    panelContainer: StyleProp<any> = {
        backgroundColor:'#FFFFFF',
        borderRadius: 5,
        boxShadow: '0 2px 4px 2px rgba(164,164,164,0.5)',
        borderWidth: 1,
        borderColor: '#358DFF',
        marginLeft: 10,
        height: 45,
        flexDirection: 'row',
        justifyContent: 'center',
        alignItem: 'center'
    }

    panelTitle: StyleProp<ViewStyle>= {
        flexDirection: 'row',
        alignItems: 'center',
        paddingLeft: 25,
        paddingVertical: 15,
    }

    panelText: StyleProp<TextStyle>= {
        color: '#358DFF',
        fontSize: 18
    }

    panelIconContainer: StyleProp<ViewStyle>= {
        paddingVertical: 15,
        paddingRight: 25,
    }

    panelIcon: StyleProp<ImageStyle>= {
        marginLeft: 10,
        width: 10, 
        height: 17, 
    }

    selectionItems: StyleProp<ViewStyle> = {
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-around',
        marginHorizontal: 7,
    }
    

    selectionItem: StyleProp<any> = {
        backgroundColor:'#358DFF',
        boxShadow: '0 2px 4px 2px rgba(164,164,164,0.5)',
        paddingHorizontal: 15,
        paddingVertical: 7,
        borderRadius: 5,
        marginHorizontal: 7,
        flexDirection: 'row',
        alignItems: 'center'
    }

    selectionIcon: StyleProp<ImageStyle>= {
        marginLeft: 10,
        width: 16, 
        height: 16,
    }

    selectionItemText: StyleProp<TextStyle> = {
        color: '#FFFFFF',
        fontSize: 16
    }

    popup: StyleProp<any> = {
        backgroundColor:'#FFFFFF',
        boxShadow: '0 2px 4px 2px rgba(164,164,164,0.5)',
        borderRadius: 5,
        paddingHorizontal: 25,
        paddingVertical: 15,
        borderWidth: 1,
        borderColor: '#358DFF',
        flexDirection: 'column',
        justifyContent: 'center',
        marginLeft: 10,
        position: 'fixed'
    }

    popupHeader: StyleProp<ViewStyle>= {
        paddingVertical: 15,
        flexDirection: 'row',
        justifyContent: 'space-between',
    }

    filterContainer: StyleProp<ViewStyle>= {
        marginTop: 15,
        padding: 15,
        backgroundColor: "#EDF4FC"
    }

    filterTitle: StyleProp<TextStyle>= {
        color: '#358DFF',
        fontSize: 16
    }

    filterItemContainer: StyleProp<ViewStyle>= {
        flexDirection: 'row',
        marginTop: 20,

    }
    filterItemSpace: StyleProp<ViewStyle>= {
        marginRight: 10
    }

    filterItem: StyleProp<any>= {
        backgroundColor: '#FFFFFF',
        borderRadius: 5,
        boxShadow: '0 2px 4px 2px rgba(164,164,164,0.5)',
        paddingHorizontal: 15,
        paddingVertical: 10
    }

    filterItemActive: StyleProp<any>= {
        backgroundColor: '#358DFF',
        borderRadius: 5,
        boxShadow: '0 2px 4px 2px rgba(164,164,164,0.5)',
        paddingHorizontal: 15,
        paddingVertical: 10
    }

    filterItemActiveLabel: StyleProp<TextStyle>= {
        color: '#FFFFFF'
    } 

}