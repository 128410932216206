import { Operation } from "@levelapp/softfabric";
import AdditionalInfosService from "src/Proxy/AdditionalInfosService";

export default class AdditionalInfosOperations extends Operation
{
    service : AdditionalInfosService;

    constructor()
    {
        super();
        this.service = new AdditionalInfosService();
    }

    get(parameters : any[], callback : Function)
    {
        this.service.get(parameters, callback);
    }

    getTypes(callback : Function)
    {
        this.service.getTypes(callback);
    }

    post(info:any, callback : Function, onFailure: Function)
    {
        this.service.post(info, callback,onFailure);
    }

    put(info:any, callback : Function, onFailure: Function)
    {
        this.service.put(info, callback,onFailure);
    }
    async getAdditionalInfoValuesByProductId(productId : number)
    {
        return await this.service.getAdditionalInfoValuesByProductId(productId);
    }
    async deleteAdditionalInfoValuesById(id : Number)
    {
        return await this.service.deleteAdditionalInfoValuesById(id);

    }
}