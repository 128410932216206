import { Service, Application, HttpManager, Managers } from '@levelapp/softfabric';
import AutomatedMessageHistory from '../Common/DTO/AutomatedMessageHistory';


export default class AutomatedMessagesService extends Service {
    httpClient = Application.current.resolve<HttpManager>(Managers.HTTP_MANAGER);

    async get(parameters: any[], onSuccess: Function) {
        this.httpClient
            .get('automatedMessages', parameters)
            .then((response: any) => {
                if (response) {
                    if (response.data) {
                        onSuccess(response.data);
                    }
                }
            });
    }

    post(automatedMessageHistory: AutomatedMessageHistory, callback: Function = () => { }) {
        this.httpClient
            .post('automatedMessages', [], automatedMessageHistory)
            .then((response: any) => {
                if (response) {
                    callback("");
                }
            });
    }
}