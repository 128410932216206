import { ContentViewModel, ContentView } from '@levelapp/softfabric';
import DeleteSectionViewState from './DeleteSectionViewState';
import DeleteSectionViewProps from './DeleteSectionViewProps';
import UsersOperations from '../../../../../../../../../../../Transfer/UsersOperations';

export default class DeleteSectionViewModel extends ContentViewModel<DeleteSectionViewState, DeleteSectionViewProps>
{
    _userOperation: UsersOperations;
    /* CONSTRUCTOR */
    constructor(view: ContentView)
    {
        super(view);

        this.initialState({}); // Initialize your state here

        this._userOperation = new UsersOperations();
        // Bindings
        this.handleOnClick = this.handleOnClick.bind(this);
    }


    handleOnClick()
    {
        this.props().showInternalPopup('users.popup.confirmation.title', 'users.popup.confirmation.content', (callback: Function) =>
        {
            this._userOperation.deleteUser(this.props().user.guid as string, callback);
        });
    }
}