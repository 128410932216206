import {ContentViewStyle} from '@levelapp/softfabric';
import { StyleProp, ViewStyle, ImageStyle, TextStyle } from 'react-native';

export default class EditableDropDownViewStyle extends ContentViewStyle{

    editableDropDown: StyleProp<ViewStyle> = {
        zIndex: 202
    }

    editableDropDownContainer: StyleProp<ViewStyle> = {
        flexDirection: 'row',
    }

    input: StyleProp<any> =
    {
        flexDirection: 'row',
        marginRight: 5,
        marginBottom: 15,
        height: 46,
        width: 223,
        backgroundColor: 'white',
        boxShadow: '0 6px 16px 0 rgba(0,0,0,0.13)'
    }

    inputView: StyleProp<ViewStyle> =
    {
        flex: 1,
        flexDirection: 'row',
        alignSelf: 'stretch',
    };

    button: StyleProp<ViewStyle> =
    {
        alignSelf: 'center',
        alignItems: 'center',
        marginRight: 14.24,
        borderRadius: 21.12 / 2,
        backgroundColor: '#ebebeb'
    };

    containerInput: StyleProp<ViewStyle> =
    {
        flexDirection: 'row',
        flex: 1,
        borderRadius: 23,
    }

    validateButton:  StyleProp<ViewStyle>    =
    {
        alignItems: 'center',
        backgroundColor: 'green',
        flexDirection: 'row',
        paddingHorizontal: 15,
        paddingVertical: 12,
        height: 46,
    }
        
    validateIcon: StyleProp<ImageStyle> = {
        width: 16, 
        height: 16,
    }

    validateLabel: StyleProp<TextStyle> = {
        color: "#FFFFFF",
        marginLeft: 15
    }

    editableDropDownOptions: StyleProp<any> = {
        backgroundColor:'#FFFFFF',
        boxShadow: '0 6px 16px 0 rgba(0,0,0,0.13)',
        borderBottomLeftRadius: 23,
        borderBottomRightRadius: 23,
        paddingHorizontal: 15,
        paddingVertical: 7,
        flexDirection: 'column',
        justifyContent: 'center',
        position: 'absolute',
        top: 50,
        width: 223,
    }

    editableDropDownOption: StyleProp<ViewStyle> = {
        paddingVertical: 7,
    }
}