import { ContentView } from "@levelapp/softfabric";
import { View } from "react-native";
import Constants from "../../../../../../Common/Constants/Constants";
import { TranslationView } from "@levelapp/softfabric-ui";
import PopupMenuItemView from "../../../components/popupmenuitem/PopupMenuItemView";
import PicturesView from "../../../components/pictures/PicturesView";
import Helpers from "../../../../../../Common/Helpers/Helpers";
import Language from "../../../../../../Common/DTO/Language";
import PopupHeaderView from "../../../components/popupheader/PopupHeaderView";
import SectionTitleView from "../../../components/sectiontitle/SectionTitleView";
import ExtendedBlockTextInputView from "../../../components/extendedblocktextinput/ExtendedBlockTextInputView";
import ProductAdditionalInfosPopUpViewProps from "./ProductAdditionalInfosPopUpViewProps";
import ProductAdditionalInfosPopUpViewStyle from "./ProductAdditionalInfosPopUpViewStyle";
import ProductAdditionalInfosPopUpViewState from "./ProductAdditionalInfosPopUpViewState";
import ProductAdditionalInfosPopUpViewModel from "./ProductAdditionalInfosPopUpViewModel";
import LineView from "../../../components/line/LineView";

export default class ProductAdditionalInfosPopUpView extends ContentView<ProductAdditionalInfosPopUpViewProps, ProductAdditionalInfosPopUpViewState, ProductAdditionalInfosPopUpViewModel, ProductAdditionalInfosPopUpViewStyle>
{
  constructor(props: ProductAdditionalInfosPopUpViewProps) {
    super(props);

    //Binding 

    this.bind(new ProductAdditionalInfosPopUpViewModel(this), new ProductAdditionalInfosPopUpViewStyle(this));
  }

  /* RENDERING */
  render() {
    return (
      <View style={{ minWidth: 600, minHeight: 850, width: Constants.PopUpWidth, maxWidth: "100%" }}>

        <PopupHeaderView
          handleBackPressed={() => {
            this.props.refreshParent()
            Helpers.closePopup()
          }}
          title={"products.additionalInfos.edit"}
          shouldShowButton
          isLoading={this.state.isLoading}
          handleOnPressButton={this.binding.handleSavePressed}
        />
        <View style={{ display: "flex", flexDirection: "row", flexWrap: "wrap" }}>
          <PopupMenuItemView activeItem={this.state.tab} title={"form.maininformation"} item={"main"} handleChangeTab={() => this.binding.changeTab("main")} />
          <PopupMenuItemView activeItem={this.state.tab} title={"form.images"} item={"pictures"} handleChangeTab={() => this.binding.changeTab("pictures")} />
        </View>
        {this.state.tab == "main" &&
          <View style={{ display: "flex", flexDirection: "column", paddingHorizontal: 170, paddingVertical: 50 }}>
            <SectionTitleView title={'form.name'} />
            <View style={{ zIndex: -1, display: 'flex', flexDirection: 'row', flexWrap: 'wrap', justifyContent: 'space-between' }}>
              {this.state.languages.map((content: Language) => {
                if(this.state.info.titleTranslations===null) this.state.info.titleTranslations ="";
                if(this.state.info.descriptionTranslations===null) this.state.info.descriptionTranslations =[];
                var _text = Helpers.getTranslationValueByLanguageKey(this.state.info.titleTranslations, content.key);
                return (
                  <ExtendedBlockTextInputView
                    value={_text}
                    title={content.key.toUpperCase()}
                    showError={this.state.showErrors}
                    onChangeText={(text: string) => this.binding._dispatcherManager.handleTranslationsChange('title', text, content.id)}
                  />
                );
              })}
            </View>
            <LineView />
            <SectionTitleView title={'form.description'} />
            <View style={{ zIndex: -1, display: 'flex', flexDirection: 'row', flexWrap: 'wrap', justifyContent: 'space-between' }}>
              {this.state.languages.map((content: Language) => {
                var _text = Helpers.getTranslationValueByLanguageKey(this.state.info.descriptionTranslations, content.key);
                return (
                  <ExtendedBlockTextInputView
                    value={_text}
                    title={content.key.toUpperCase()}
                    showError={this.state.showErrorsDescription}
                    onChangeText={(text: string) => this.binding._dispatcherManager.handleTranslationsChange('description', text, content.id)}
                  />
                );
              })}
            </View>
          </View>
        }
        {this.state.tab == "pictures" &&
          <View style={{ display: "flex", flexDirection: "column", paddingHorizontal: 170, paddingVertical: 50 }}>
            <PicturesView
              languages={this.state.languages}
              listImage={this.state.info.imageTranslation}
              listThumbnail={this.state.info.thumbnailTranslation}
              shouldUseDefaultImage={this.state.useDefaultImageChecked}
              shouldUseDefaultThumnail={this.state.useDefaultThumbnailChecked}
              handleOnComplete={this.binding._dispatcherManager.handleAnyChange}
              handleOnPressImage={this.binding._dispatcherManager.handleNumberChange}
              handleCheckboxChange={this.binding._dispatcherManager.handleCheckboxChange}
              shouldSkipThumnail={true}
            />
          </View>
        }
        {this.state.externalError &&
          < View style={this.style.footer} >
            <TranslationView style={{ color: "red" }}>ProductCategories.ExternalIdError</TranslationView>
          </View>
        }
        {this.state.showErrors &&
          < View style={this.style.footer} >
            <TranslationView style={{ color: "red" }}>error.please.fields</TranslationView>
          </View>
        }

      </View>

    );
  }

}