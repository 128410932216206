 

import { ContentViewModel, ContentView } from '@levelapp/softfabric';

import ProductGroupOperations from 'src/Transfer/ProductGroupOperations';

import PopupMenuViewState from './PopupMenuViewState';
import PopupMenuViewProps from './PopupMenuViewProps';
import RemoveProductGroupPopUpView from '../pages/productstool/removeproductgrouppopup/RemoveProductGroupPopUpView';
import ToastHelper from 'src/Common/Helpers/ToastHelper';
import UpsertProductGroupView from '../pages/productstool/upsertproductgroup/UpsertProductGroupView';
import ProductGroup from 'src/Common/DTO/ProductGroup';

export default class PopupMenuViewModel extends ContentViewModel<PopupMenuViewState, PopupMenuViewProps>
{
  productGroupOperations: ProductGroupOperations;

  /* CONSTRUCTOR */
  constructor(view: ContentView) {
    super(view);

    this.initialState({});

    this.productGroupOperations = new ProductGroupOperations();

    // Bindings
    this.openDeleteModal = this.openDeleteModal.bind(this);
    this.onRemove = this.onRemove.bind(this);
  }

  async onRemove(id: number) {
    try {
      await this.productGroupOperations.delete(id);
      this.props().onPopupClose();
      this.showToast(ToastHelper.getToast("tools.products.deleteGroup.success", "success"), () => { }, 2500);
    } catch {
      this.showToast(ToastHelper.getToast("tools.products.deleteGroup.error", "error"), () => { }, 2500);
    }
  }

  openDeleteModal() {
    this.showPopUp(<RemoveProductGroupPopUpView id={this.props().productGroup?.id!} onRemove={this.onRemove} />);
  }

  openEditModal(productGroup: ProductGroup) {
    this.showPopUp(<UpsertProductGroupView closePopUp={this.props().onPopupClose} productGroup={productGroup} />);
  }
}