import { ContentViewStyle, Application } from '@levelapp/softfabric';
import { TextStyle, StyleProp, ViewStyle } from 'react-native';
import { StyleManager, UiManagers } from '@levelapp/softfabric-ui';

export default class BoosterPopUpViewStyle extends ContentViewStyle
{
    
    MyStyleManager : StyleManager = Application.current.resolve<StyleManager>(UiManagers.STYLE_MANAGER);

    title: StyleProp<TextStyle> = {
        textAlign: 'left',
        color: 'white',
        fontSize: 24,
        fontWeight: '600',
        marginTop: 20,
        marginLeft: 60,
        paddingBottom: 20,
        display: "flex", 
        flexDirection: "column", 
        flexBasis: "85%"
    };

    label: StyleProp<TextStyle> = {
        fontSize: 16,
        fontWeight: '600',
        color: '#525f7f',
        marginBottom: 10
    };

    section: StyleProp<ViewStyle> = {
        
        marginHorizontal: 15,
        marginBottom : 15
    };

    radioContainer: StyleProp<ViewStyle> = {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        marginTop: 5,
        marginBottom: 5
    };

    radioLabel: StyleProp<TextStyle> = {
        fontSize: 16,
        fontWeight: '600',
        color: '#8898aa',
        marginLeft: 10
    };

    translationContainer: StyleProp<ViewStyle> = {
        marginTop: 10,
        marginBottom: 10
    };

    footer: StyleProp<ViewStyle> = {
        display: 'flex',
        flexDirection: 'row',
        margin: 'auto',
        marginTop: 50,
        marginBottom: 20
    };

    input: StyleProp<any> = {
        width: 240,//140
        paddingHorizontal: 15,
        borderRadius : 8,
        boxShadow: '0 5px 14px 0 rgba(0,0,0,0.1)',
    };

    errorInput: StyleProp<ViewStyle> = {
        borderColor: 'red',
        borderWidth: 1,
        width: 300,
    };

    step: StyleProp<ViewStyle> = {
        display: "flex",
        flexDirection: "row",
        flexWrap: "wrap"
    };

    tabActive: StyleProp<ViewStyle> = {
        backgroundColor: "white",
        width: "100%",
        alignItems: "center",
        height: "50px",
        display: "flex",
        justifyContent: "center",
        borderBottomColor : '#E4E4E4',
        borderBottomWidth : 1
      };
  
      tab: StyleProp<ViewStyle> = {
          backgroundColor: "#EEF0F4",
          width: "100%",
          alignItems: "center",
          height: "50px",
          display: "flex",
          justifyContent: "center",
          borderBottomColor : '#E4E4E4',
          borderBottomWidth : 1
      };

    inputTitle: StyleProp<TextStyle> = {
        fontSize: 14,
        fontWeight: '600',
        color: '#3F3F3F',
        fontFamily : 'Montserrat SemiBold',
        marginBottom : 13,
    };

    saveButton: StyleProp<TextStyle> = {
        color: this.MyStyleManager.primaryColor,
        fontSize: 10,
        fontWeight: '600',
        height: 27,
        width: 112
    };

    textArea: StyleProp<any> = {
        width: '100%',
        minWidth: 475,
        height : 92,
        paddingHorizontal: 5,
        borderRadius : 8,
        boxShadow: '0 5px 14px 0 rgba(0,0,0,0.1)',
    };

    errorTextArea: StyleProp<ViewStyle> = {
        borderColor: 'red',
        borderWidth: 1,
        width: '100%',
        minWidth: 475,
        height : 92,
        paddingHorizontal: 5,
        borderRadius : 8,
    };

    errorTextField: StyleProp<ViewStyle> = {
        borderColor: 'red',
        borderWidth: 1,
        width: 240,
        paddingHorizontal: 15,
        borderRadius : 8,
    };

    titleActive: StyleProp<TextStyle> = {
        fontSize: 14,
        color: '#3F3F3F',
        fontFamily : 'Montserrat SemiBold',
    }

    titleInactive: StyleProp<TextStyle> = {
        color : '#B8BBC1',
        fontSize: 14,
        fontFamily : 'Montserrat SemiBold',
    }
    sectionDatePicker: StyleProp<ViewStyle> = {
        alignSelf: 'flex-end',
        marginHorizontal: 15,
        marginBottom : 15
    };
    checkbox: StyleProp<any> = {
        width : 20,
        height : 20,
        marginHorizontal: 5,
    }
}