import {ContentViewModel,ContentView} from '@levelapp/softfabric';
import LanguageViewState from './LanguageViewState';
import LanguageViewProps from './LanguageViewProps';
import LanguagesOperations from '../../../../../../../Transfer/LanguagesOperations';
import Language from '../../../../../../../Common/DTO/Language';
import { TableRow } from '@levelapp/softfabric-ui';
 
import LanguagePopUpView from '../../popup/languagepopup/LanguagePopUpView';
import ToastHelper from '../../../../../../../Common/Helpers/ToastHelper';
import Helpers from '../../../../../../../Common/Helpers/Helpers';
import ConfirmPopUpView from '../../../../../../../Common/Components/confirmpopup/ConfirmPopUpView';
import ErrorHelper from '../../../../../../../Common/Helpers/ErrorHelper';

export default class LanguageViewModel extends ContentViewModel<LanguageViewState, LanguageViewProps>
{
    languagesOperations: LanguagesOperations;
    _languages: Language[] = [];
    interval: any;
    moment : any;
      /* CONSTRUCTOR */
    constructor(view: ContentView)
    {
        
        super(view);

        this.interval = null;
        this.moment = require('moment');

        this.initialState({ tableData: [], tableHeaders: ['ID', 'Key','Active', 'Default'], isLoading: true, nbPage: 0, currentPage: 1, isResearching: false });
        this.languagesOperations = new LanguagesOperations();

        // Bindings
        this.refresh = this.refresh.bind(this);
        this.onPageChange = this.onPageChange.bind(this);
        this.addField = this.addField.bind(this);
        this.editField = this.editField.bind(this);
        this.deleteField = this.deleteField.bind(this);
        this.errorManagement = this.errorManagement.bind(this);

    }

    componentDidMount() {
        this.refresh();
    }

    refresh() {
        this.setField({ isLoading: true });
        this.getData();
    }

    getData() {
        this.languagesOperations.get((languages: any) => {
            let tableData: TableRow[] = [];
            languages.data.forEach((language: Language) => {
                tableData.push({ data: [
                    language.id,
                    language.key,
                    language.isActive ? 'Yes' : 'No',
                    language.isDefault? 'Yes' : 'No'], 
                    isDeletable: true, 
                    isEditable: true,
                    editCallback: () => { this.editField(language) },
                    deleteCallback: () => { this.deleteField(language) }
                });
            });
            this.setField({ tableData: tableData, coupons: languages.data.objects, nbPage: languages.data.totalPage, isLoading: false });
        });
    }
    
    onPageChange(page: number) {
        this.state().currentPage = page + 1;
        this.refresh()
    }
    addField() {
        this.showPopUp(
            <LanguagePopUpView
            type={'new'}
            language={{
                key : " ",
                isDefault : false,
                isActive : false
            }}
            refreshParent={this.refresh}
/>
        );
    }
    editField(language: any) {
        let languageCopy = Helpers.clone(language);
        this.showPopUp(
            <LanguagePopUpView type={'edit'}  language={languageCopy} refreshParent={this.refresh} />
        );
    }
    deleteField(language: any) {
        this.showPopUp(
            <ConfirmPopUpView callBack={() => {
                this.languagesOperations.delete(language, () => {
                    this.showToast(ToastHelper.getToast("language.popup.success.deleted","success"),() => {},2500);
                    this.refresh();
                },this.errorManagement);
            }} />
        );
        this.refresh();
    }

    


    errorManagement(error : any) 
    {
        this.setField({isLoading : false});
        ErrorHelper.showMessageError(error.response);
    }
}