import { Service, Application, HttpManager, Managers } from '@levelapp/softfabric';
import LightStore from 'src/Common/DTO/LightStore';

export default class StoresService extends Service
{
    httpClient = Application.current.resolve<HttpManager>(Managers.HTTP_MANAGER);

    async get(parameters: any[], onSuccess: Function , onFailure: Function)
    {
        this.httpClient.handleSomethingWentWrong = onFailure;
        this.httpClient.handleNotFound = onFailure;

        this.httpClient
            .get('stores', parameters)
            .then((response: any) =>
            {
                if (response)
                {
                    if (response.data)
                    {
                        onSuccess(response.data);
                    }
                }
            });
    }
    async getAll(onSuccess: Function , onFailure: Function)
    {
        this.httpClient.handleSomethingWentWrong = onFailure;
        this.httpClient.handleNotFound = onFailure;

        this.httpClient
            .get('stores')
            .then((response: any) =>
            {
                if (response)
                {
                    if (response.data)
                    {
                        onSuccess(response.data);
                    }
                }
            });
    }

    async getLight(): Promise<LightStore[]> {
      const response = await this.httpClient.get('stores/light');
  
      if (response && response.data) {
        return response.data.data;
      }
  
      return [];
    }

    async getallwithouttranslations(onSuccess: Function)
    {
        this.httpClient
            .get('stores/GetAllWithoutTranslations', [])
            .then((response: any) =>
            {
                if (response)
                {
                    if (response.data)
                    {
                        onSuccess(response.data);
                    }
                }
            });
    }

    post(store: any, callback: Function = () => { }, onFailure: Function )
    {
        this.httpClient.handleSomethingWentWrong = onFailure;
        this.httpClient.handleNotFound = onFailure;

        this.httpClient
            .post('stores', [], store)
            .then((response: any) => 
            {
                if (response)
                {
                    /*if (response.data)
                    {
                        callback(response.data);
                    }*/
                    callback("");
                }
            });
    }

    async getStoreOrderSourcesLogs(storeId : number, onSuccess: Function , onFailure: Function)
    {
        this.httpClient.handleSomethingWentWrong = onFailure;
        this.httpClient.handleNotFound = onFailure;

        this.httpClient
            .get('stores/LogHistory?storeId='+storeId, [])
            .then((response: any) =>
            {
                if (response)
                {
                    if (response.data)
                    {
                        onSuccess(response.data);
                    }
                }
            });
    }

    put(store: any, callback: Function = () => { }, onFailure: Function )
    {
        this.httpClient.handleSomethingWentWrong = onFailure;
        this.httpClient.handleNotFound = onFailure;

        this.httpClient
            .put('stores', [], store)
            .then((response: any) => 
            {
                if (response)
                {
                    /*if (response.data)
                    {
                        callback(response.data);
                    }*/
                    callback("");
                }
            });
    }

    updateClickAndCollectTemporary(store: any, callback: Function = () => { })
    {
        delete store.title;
        this.httpClient
            .put('stores/updateClickAndCollectTemporary', [], store)
            .then((response: any) => 
            {
                if (response)
                {
                    /*if (response.data)
                    {
                        callback(response.data);
                    }*/
                    callback("");
                }
            });
    }

    updateKioskTemporary(store: any, callback: Function = () => { })
    {
        delete store.title;
        this.httpClient
            .put('stores/updateKioskTemporary', [], store)
            .then((response: any) => 
            {
                if (response)
                {
                    /*if (response.data)
                    {
                        callback(response.data);
                    }*/
                    callback("");
                }
            });
    }

    delete(store: any, callback: Function = () => { }, onFailure: Function )
    {
        this.httpClient.handleSomethingWentWrong = onFailure;
        this.httpClient.handleNotFound = onFailure;

        this.httpClient
            .delete('stores/delete/' + store.id)
            .then((response: any) => 
            {
                if (response)
                {
                    /*if (response.data)
                   {
                       callback(response.data);
                   }*/
                    callback("");
                }
            });
    }

    async getStoreTypes(onSuccess: Function = () => { })
    {
        this.httpClient
            .get('stores/types/', [])
            .then((response: any) =>
            {
                if (response)
                {
                    if (response.data)
                    {
                        onSuccess(response.data);
                    }
                }
            });
    }
}