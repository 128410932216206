 
import {ContentView} from '@levelapp/softfabric';
import MessageCampaignStatsViewState from './MessageCampaignStatsViewState';
import MessageCampaignStatsViewProps from './MessageCampaignStatsViewProps';
import MessageCampaignStatsViewStyle from './MessageCampaignStatsViewStyle';
import MessageCampaignStatsViewModel from './MessageCampaignStatsViewModel';
import { View } from 'react-native';
import StatsBlockView from './statsblock/StatsBlockView';
import PopupHeaderView from '../../../../components/popupheader/PopupHeaderView';
import Helpers from '../../../../../../../Common/Helpers/Helpers';

export default class MessageCampaignStatsView extends ContentView<MessageCampaignStatsViewProps, MessageCampaignStatsViewState, MessageCampaignStatsViewModel, MessageCampaignStatsViewStyle>
{
  /* CONSTRUCTOR */
  constructor(props:MessageCampaignStatsViewProps)
  {
    super(props);

    // Binding
    this.bind(new MessageCampaignStatsViewModel(this), new MessageCampaignStatsViewStyle(this));
  }

  /* RENDERING */
  render()
  {
    const {container,popup, verticalDivider} = this.style;
    return (
      <View style={popup}>

          <PopupHeaderView
            handleBackPressed={Helpers.closePopup}
            shouldShowButton = {false}
            title={"messageCampaign.stats.title"}
          /> 
          <View style={container}>
            {this.props.showStatsBlock1 &&
            <StatsBlockView 
                line_chart_data={this.binding.getData(this.state.details.pn_by_time,"pn_sent","pn_delivered",this.state.details.pn_received_by_time)}
                pie_chart_data={this.binding.getStatusData(this.state.details.pn_by_status)}
                value2 = {this.state.details.received_pn}
                value2Text = {"messageCampaign.stats.total.pn.received"}
                value1 = {this.state.details.total_pn}
                value1Text = {"messageCampaign.stats.total.pn"}
                title = {"Total push notifications"}/>}
            
            {this.props.showStatsBlock1 && this.props.showStatsBlock2 && <View style={verticalDivider}/>
            }
            {this.props.showStatsBlock2 &&
            <StatsBlockView 
                line_chart_data={this.binding.getData(this.state.details.mail_by_time,"mail_sent","mail_delivered",this.state.details.mail_received_by_time)}
                pie_chart_data={this.binding.getStatusData(this.state.details.mail_by_status)}
                value2 = {this.state.details.received_mail}
                value2Text = {"messageCampaign.stats.total.mail.received"}
                value1 = {this.state.details.total_mail}
                value1Text = {"messageCampaign.stats.total.mail"}
                title = {"Total mails"}/>}
          </View>
      </View>
    );
  }
}