 
import { ContentView } from '@levelapp/softfabric';
import LogHistoryPopupViewState from './LogHistoryPopupViewState';
import LogHistoryPopupViewProps from './LogHistoryPopupViewProps';
import LogHistoryPopupViewStyle from './LogHistoryPopupViewStyle';
import LogHistoryPopupViewModel from './LogHistoryPopupViewModel';
import { View, Text,Image,  TouchableWithoutFeedback } from 'react-native';
import { LottieView, TranslationView } from '@levelapp/softfabric-ui';
import { utc } from 'moment';
import ThirdPartyHelper from '../../../../../../../Common/Helpers/ThirdPartyHelper';
import LogDetailsView from '../../../../components/logdetails/LogDetailsView';

export default class LogHistoryPopupView extends ContentView<LogHistoryPopupViewProps, LogHistoryPopupViewState, LogHistoryPopupViewModel, LogHistoryPopupViewStyle>
{
  /* CONSTRUCTOR */
  constructor(props: LogHistoryPopupViewProps)
  {
    super(props);

    // Binding
    this.bind(new LogHistoryPopupViewModel(this), new LogHistoryPopupViewStyle(this));
  }

  /* RENDERING */
  render()
  {
    return (
      <View style={this.style.container}>
        <View style={this.style.header}>
          {this.state.details && <TouchableWithoutFeedback onPress={() => this.binding.hideDetails()}>
            <View style={{paddingRight : 10}}>
              <Image source={require('./assets/left.svg').default} style={{ width: 10, height: 17 }} />
            </View>
          </TouchableWithoutFeedback>}
          <Text style={this.style.title}>{this.state.details ? `${this.state.currentLog?.thirdPartyType.key} - ${this.state.currentLog?.referenceType.key} #${this.state.currentLog?.referenceTypeValue}` : `Basket ID: ${this.props.basket.id}`}</Text>
          
        </View>

        {this.state.isLoading ? (
          <View style={{ justifyContent: 'center', alignItems: 'center', paddingVertical: 30 }}>
            <LottieView height={150} width={150} source={require('../../assets/loading.json')} />
          </View>
        ) : ( this.state.details ? 
              <LogDetailsView log={this.state.currentLog}/>
            :
             <View style={this.style.historyContainer}>
              {this.state.logs.length > 0 ? this.state.logs.map((log, i) =>
              {
                const label = log.thirdPartyType.key;
                const time = utc(log.creationDate).local().format("HH:mm - DD/MM/YYYY");

                return (
                  <View style={this.style.history} key={i}>
                    <View style={this.style.leftHistory}>
                      <View style={this.style.bubble} />
                      <View style={this.style.line} />
                    </View>

                    <View style={this.style.middleHistory}>
                    <Text>{log.name != null && log.name != "" ? `${log.name}` : 'No Name'} - <Text style={{ fontSize: 14, fontWeight:'bold', color : this.binding.errorCodeColor(log.errorCode) }}> {log.errorCode}</Text></Text>
                      <Text>{time}</Text>
                      <View style={{flexDirection : 'row'}}>
                        <View style={[this.style.status, { backgroundColor: ThirdPartyHelper.changeEnumToBackgroundColor(log.thirdPartyTypeId) }]}>
                          <Text style={{fontSize : 16 , color : ThirdPartyHelper.changeEnumToTextColor(log.thirdPartyTypeId)}}>{label}</Text>
                        </View>
                      </View> 
                      <TouchableWithoutFeedback onPress={() => {this.binding.showDetails(log)}}>
                        <View style={{paddingTop : 10 , paddingBottom : 20 , flexDirection : 'row' , alignItems : 'center'}}>
                          <TranslationView>kitchenapp.moredetails</TranslationView>
                          <Image source={require('./assets/right.svg').default} style={{ marginLeft : 5, width: 7, height: 14 }} />
                        </View> 
                      </TouchableWithoutFeedback> 
                    </View>

                    
                  </View>
                )
              }) : <View style={{alignItems : 'center'}}><Text>No log for this basket</Text></View>}
              
            </View>
          )
        }
      </View>
    );
  }
}