 
import { View } from 'react-native';
import {ContentView} from '@levelapp/softfabric';
import User from '../../../../../../../../../../../Common/DTO/User';
import InformationSectionViewState from './InformationSectionViewState';
import InformationSectionViewProps from './InformationSectionViewProps';
import InformationSectionViewStyle from './InformationSectionViewStyle';
import InformationSectionViewModel from './InformationSectionViewModel';
import LoyaltyCard from '../../../../../../../../../../../Common/DTO/LoyaltyCard';
import SectionTitleView from '../../../../../../../../components/sectiontitle/SectionTitleView';
import ExtendedTextInputView from '../../../../../../../../components/extendedtextinput/ExtendedTextInputView';
import ExtendedCheckBoxView from '../../../../../../../../components/extendedcheckbox/ExtendedCheckBoxView';
import Constants from '../../../../../../../../../../../Common/Constants/Constants';

export default class InformationSectionView extends ContentView<InformationSectionViewProps, InformationSectionViewState, InformationSectionViewModel, InformationSectionViewStyle>
{
  /* CONSTRUCTOR */
  constructor(props:InformationSectionViewProps)
  {
    super(props);

    // Binding
    this.bind(new InformationSectionViewModel(this), new InformationSectionViewStyle(this));
  }

  /* RENDERING */
  render()
  {
    return (
      <View style={{ flexDirection: "column" }}>
        <SectionTitleView title={'form.informations'} />
        <View style={{ flexDirection: "row" }}>
          <ExtendedTextInputView
            title={"form.firstname"}
            value={(this.props.user as User).firstName as string}
            onChangeText={(firstname: string) => this.props.handleValueChange('firstname', firstname)}
            showError={false} />
          <ExtendedTextInputView
            title={"form.lastname"}
            value={(this.props.user as User).lastName as string}
            onChangeText={(lastname: string) => this.props.handleValueChange('lastname', lastname)}
            showError={false} />
        </View>
        <ExtendedTextInputView
          disable
          style={{ width: "30%" }}
          title={"form.email"}
          value={(this.props.user as User).email as string}
          onChangeText={() => { } }
          //(email: string) => this.binding.handleEmailChanged(email)
          showError={false} />
        <ExtendedTextInputView
          style={{ width: "30%" }}
          title={"form.loyaltycode"}
          value={((this.props.user as User).loyaltyCard as LoyaltyCard).code as string}
          onChangeText={(code: string) => this.props.handleValueChange('loyaltycardCode', code)}
          showError={false} />
        <ExtendedTextInputView
          disable
          style={{ width: "40%" }}
          title={"form.guid"}
          value={(this.props.user as User).guid as string}
          onChangeText={() => { } }
          //(email: string) => this.binding.handleEmailChanged(email)
          showError={false} />
        <ExtendedCheckBoxView
          isTranslation
          title={'form.preview'}
          value={this.props.user.isPreview}
          onValueChange={(value: boolean) => this.props.handleCheckboxChange('isPreviewVisible', value)} />
        {Constants.ShowEmailVerified &&
          <ExtendedCheckBoxView
          isTranslation
          title={'form.email.verified'}
          value={this.props.user.isEmailVerified == null ? false : this.props.user.isEmailVerified}
          disable={true}
          onValueChange={() => {}} />
        }
      </View>
    );
  }
}