import { Operation } from "@levelapp/softfabric";
import CouponsService from "../Proxy/CouponsService";

export default class CouponsOperations extends Operation
{
    service : CouponsService;

    constructor()
    {
        super();
        this.service = new CouponsService();
    }

    get(parameters : any[], callback : Function, onFailure : Function)
    {
        this.service.get(parameters, callback,onFailure);
    }

    post(coupon:any, callback : Function, onFailure : Function)
    {
        this.service.post(coupon, callback,onFailure);
    }

    put(coupon:any, callback : Function, onFailure : Function)
    {
        this.service.put(coupon, callback,onFailure);
    }

    delete(coupon:any, callback : Function, onFailure : Function)
    {
        this.service.delete(coupon, callback,onFailure);
    }

    activateById(couponId: any, body: any, callback: Function = () => { }, onFailure: Function = () => { }) {
        this.service.activateById(couponId, body, callback,onFailure);
    }

    getByCouponId(couponId: any, callback: Function = () => { }, onFailure: Function = () => { }) {
        this.service.getByCouponId(couponId, callback,onFailure);
    }
}