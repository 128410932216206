import { Application, HttpManager, Managers, Service } from "@levelapp/softfabric";
import PointEarningActivationCode from "src/Common/DTO/PointEarningActivationCode";


export default class PointEarningActivationCodeService extends Service
{
    httpClient = Application.current.resolve<HttpManager>(Managers.HTTP_MANAGER);


    post(question:PointEarningActivationCode, callback : Function = () => {}, onFailure: Function)
    {
        this.httpClient.handleSomethingWentWrong = onFailure;
        this.httpClient.handleNotFound = onFailure;

        this.httpClient
            .post('PointEarningActivationCode', [], question)
            .then((response : any) => 
            {
                if (response)
                {
                    if (response.data)
                    {
                        callback(response.data.data);
                    }
                }
            });
    }
}