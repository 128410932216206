 
import {ContentView} from '@levelapp/softfabric';
import AdminUsersPopupViewState from './AdminUsersPopupViewState';
import AdminUsersPopupViewProps from './AdminUsersPopupViewProps';
import AdminUsersPopupViewStyle from './AdminUsersPopupViewStyle';
import AdminUsersPopupViewModel from './AdminUsersPopupViewModel';
import {TranslationView} from '@levelapp/softfabric-ui';
import { View } from 'react-native';
import PopupMenuItemView from '../../../../components/popupmenuitem/PopupMenuItemView';
import PopupHeaderView from '../../../../components/popupheader/PopupHeaderView';
import Constants from '../../../../../../../Common/Constants/Constants';
import MainInformationView from './components/maininformation/MainInformationView';
import RoleView from './components/role/RoleView';
import UserRoles from '../../../../../../../Common/DTO/M&N/UserRoles';
import Role from '../../../../../../../Common/DTO/Role';
import UserIdentity from '../../../../../../../Common/DTO/Identity/UserIdentity';

export default class AdminUsersPopupView extends ContentView<AdminUsersPopupViewProps, AdminUsersPopupViewState, AdminUsersPopupViewModel, AdminUsersPopupViewStyle>
{
  /* CONSTRUCTOR */
  constructor(props:AdminUsersPopupViewProps)
  {
    super(props);

    // Binding
    this.bind(new AdminUsersPopupViewModel(this), new AdminUsersPopupViewStyle(this));
  }

  /* RENDERING */
  render()
  {
    return (
      <View style={{ minWidth: 600, minHeight: 850, width: Constants.PopUpWidth, maxWidth: "100%" }}>

        <PopupHeaderView
        shouldShowButton={true} 
        title={"adminusers.popup.title"} 
        isLoading={this.state.isLoading}
        handleOnPressButton={(this.binding.handleSavePressed)}
        handleBackPressed={this.binding.handleBackPressed}/>

        {/*Tabs*/}
        <View style={{ display: "flex", flexDirection: "row", flexWrap: "wrap" }}>
          <PopupMenuItemView activeItem={this.state.tab} title={"form.maininformation"} item={"main"} handleChangeTab={() => this.binding.changeTab("main")}/>
          <PopupMenuItemView activeItem={this.state.tab} title={"form.role"} item={"role"} handleChangeTab={() => this.binding.changeTab("role")}/>
        </View>

        {this.state.tab == "main" &&
          <MainInformationView 
          user={((this.state.userrole as UserRoles).user as UserIdentity)} 
          showError={this.state.showErrors} 
          handleChangeText={this.binding.handleFieldChanged}/>
        }
        {this.state.tab == "role" &&
          <RoleView 
          handleChangeRole={this.binding.handleFieldChanged}
          user={((this.state.userrole as UserRoles).user as UserIdentity)} 
          role={((this.state.userrole as UserRoles).role as Role)} 
          handleChangePermissionUser={this.binding.handleFieldChanged}
          allRoles={this.state.allRoles} permissionUsers={[]}/>
        }

        
        {this.state.showErrors &&
          < View style={this.style.footer} >
            <TranslationView style={{ color: "red" }}>error.please.fields</TranslationView>
          </View>
        }
      </View>
    );
  }
}