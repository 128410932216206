import { ContentShellViewModel, ContentShellView } from '@levelapp/softfabric';
import ShellRewardsViewState from './ShellRewardsViewState';
import ShellRewardsViewProps from './ShellRewardsViewProps';
import EnumModules from '../../../../../Common/Constants/EnumModules';
import RewardsView from './pages/rewards/RewardsView';
import RewardsV2 from './pages/rewards-v2/RewardsV2';
 

export default class ShellRewardsViewModel extends ContentShellViewModel<ShellRewardsViewState, ShellRewardsViewProps>
{
    constructor(view : ContentShellView)
    {
        super(view);

        this.registerEntryPoint('main');
        this.register(process.env.REACT_APP_ISREWARDS_V2 === 'true' ? RewardsV2 : <RewardsView />, 'main');

        this.initialisation();
    }

    getModuleId()
    {
        return EnumModules.REWARDS;
    }
}