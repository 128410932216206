import ProductOrderSourcePrice from 'src/Common/DTO/ProductOrderSourcePrice';

import { Service, Application, HttpManager, Managers } from '@levelapp/softfabric';

export default class ProductOrderSourcesPricesService extends Service {
  httpClient = Application.current.resolve<HttpManager>(Managers.HTTP_MANAGER);

  async get(productId: number): Promise<ProductOrderSourcePrice[]> {
    const response = await this.httpClient.get(`ProductOrderSourcePrice/products/${productId}`);

    if (response && response.data) {
      return response.data.data;
    }

    return [];
  }

  async post(prices: ProductOrderSourcePrice[], categoryId?: number): Promise<ProductOrderSourcePrice[]> {
    const response = await this.httpClient
      .post(`ProductOrderSourcePrice${categoryId ? `?categoryId=${categoryId}` : ''}`, [], prices);

    if (response && response.data) {
      return response.data.data;
    }

    return Promise.reject();
  }

  async put(prices: ProductOrderSourcePrice[]): Promise<ProductOrderSourcePrice[]> {
    const response = await this.httpClient.put('ProductOrderSourcePrice', [], prices);

    if (response && response.data) {
      return response.data.data;
    }

    return [];
  }

  async delete(prices: ProductOrderSourcePrice[]): Promise<boolean> {
    const success = await this.httpClient.post('ProductOrderSourcePrice/delete', [], prices);

    if (success) {
      return true;
    }

    return false;
  }

  async deleteByProductGroupId(id: number): Promise<boolean> {
    const success = await this.httpClient.delete(`ProductOrderSourcePrice/${id}`);

    if (success) {
      return true;
    }

    return false;
  }
}