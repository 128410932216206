import {ContentViewModel,ContentView} from '@levelapp/softfabric';
import OrderFiltersPopupViewState from './OrderFiltersPopupViewState';
import OrderFiltersPopupViewProps from './OrderFiltersPopupViewProps';
import BasketOrderTypeOperations from '../../../../../../../Transfer/BasketOrderTypeOperations';
import BasketOrderType from '../../../../../../../Common/DTO/BasketOrderType';
import { Animated } from 'react-native';
import { OrderSourceOperations } from '../../../../../../../Transfer/OrderSourceOperations';
import ExtendedBasketStatus from '../../../../../../../Common/DTO/ExtendedBasketStatus';
import StoreBrand from '../../../../../../../Common/DTO/StoreBrand';

interface SelectionViewItem {id: number, type:string, label:string};

export default class OrderFiltersPopupViewModel extends ContentViewModel<OrderFiltersPopupViewState, OrderFiltersPopupViewProps>
{
    basketOrderTypeOperations : BasketOrderTypeOperations;
    orderSourceOperations: OrderSourceOperations;
    animation:Animated.Value;

    constructor(view: ContentView)
    {
        super(view);

        
        this.initialState({
            isOpen: false,
            filters: {}
        });

        this.animation = new Animated.Value(0);
        this.basketOrderTypeOperations = new BasketOrderTypeOperations();
        this.orderSourceOperations = new OrderSourceOperations();

        this.toggle = this.toggle.bind(this);
        this.loadFilters = this.loadFilters.bind(this);
        this.handleOrderTypeRecieved = this.handleOrderTypeRecieved.bind(this); 
        this.handleOrderSourceRecieved = this.handleOrderSourceRecieved.bind(this);
        this.setFilterSelection = this.setFilterSelection.bind(this);
        this.onClick = this.onClick.bind(this);
        this.loadFilters();
    }

    componentDidMount() {
        document.addEventListener('click', this.onClick)
    }
    componentWillUnmount() {
        document.removeEventListener('click', this.onClick);
    }

    onClick(event: Event) {
        const { isOpen } = this.state();

        if(!isOpen) return;

        const popup = document.getElementsByClassName('kitchenapp-filters')[0];
        if(!(event.target instanceof Node) || popup.contains(event.target)) return;
        
        return this.closePopup();
    }

    componentDidUpdate() {
        const {filters} = this.state();
        if((filters.status && filters.status.length > 0) && (filters.brands)) return;

        const {status , brands} = this.props();
        filters.status = this.formatStatus(status);
        if(brands != undefined)
        {
            filters.brands = this.formatBrands(brands);
        }
    }

    private formatStatus(status: ExtendedBasketStatus[]): {id:number, label:string}[] {
        const {language} = this.props();
        

        return status.filter(sta=> sta.isDisplayed)
        .sort((x, y) => x.displayIndex - y.displayIndex)
        .map(statusItem => {
            const translation = statusItem.label.translationValues.find(x => x.language.key === language);
            return {
                id: statusItem.id,
                label: translation?.value || ''
            }
        });
    }


    private formatBrands(brands: StoreBrand[]): {id:number, label:string}[] {

        return brands.sort((x, y) => x.brandId - y.brandId)
        .map(brandItem => {
            
            return {
                id: brandItem.brandId,
                label: brandItem.brandName
            }
        });
    }
    private handleOrderTypeRecieved(orderTypes: BasketOrderType[]): void {
        const {filters} = this.state();
        filters.types = orderTypes.map(item => {
            return {
                id: item.id,
                label: item.labelTranslations[0].value
            }
        });
        filters.types.push({id: 0, label: "Unknown"});
        this.setField({filters});
    }

    private handleOrderSourceRecieved(orderSources: {id:number, label:string}[]) {
        
        const {filters} = this.state();
        filters.sources = orderSources;
        this.setField({filters});
        
    }

    private loadFilters(): void {
        this.basketOrderTypeOperations.get(this.handleOrderTypeRecieved);
        this.orderSourceOperations.get(this.handleOrderSourceRecieved);
    }

    private openPopup(): void {
        this.setField({isOpen: true});
        Animated.timing(this.animation, {
            useNativeDriver: true,
            toValue:  1,
            duration: 500
        }).start();
    }

    private closePopup(): void {
        Animated.timing(this.animation, {
            useNativeDriver: true,
            toValue: 0,
            duration: 500
        }).start(()=> this.setField({isOpen: false}));
    }

    toggle(): void {
        const { isOpen } = this.state();
        if(isOpen) return this.closePopup();
        this.openPopup();      
    }

    setFilterSelection(type:string, value: number): void {
        const items = this.props().filters[type];
        const index = items.indexOf(value);

        if(index === -1) {
            items.push(value);
            this.props().setFilterSelection(type, items);
            return;
        }

        items.splice(index,1);
        this.props().setFilterSelection(type, items);
    }

    setFilterAllOption(type:string): void {
        const selectedIds = this.props().filters[type];
        const items = this.state().filters[type]

        if(items.length === selectedIds.length) {
            this.props().setFilterSelection(type, []);
            return;
        }

        this.props().setFilterSelection(type, items.map(item=>item.id));
    }

    getFilterOptions():SelectionViewItem[]  {
        const options:SelectionViewItem[] = [];
        const {filters} = this.state();
        
        Object.keys(filters).forEach(filterKey=>{
            filters[filterKey].forEach(item => {
                if(this.props().filters[filterKey].includes(item.id)){
                    options.push({ ...item, type:filterKey});
                }
            });
        });

        return options;
    }
}