import {ContentViewStyle} from '@levelapp/softfabric';
import {StyleProp,ViewStyle} from 'react-native';

export default class ExtendedDatePickerViewStyle extends ContentViewStyle
{
    page: StyleProp<ViewStyle> = {
        flex: 1
    }

    container : StyleProp<ViewStyle> =
    {
        paddingRight : 15,
    
        paddingBottom : 15,
    }


}