import { Operation } from "@levelapp/softfabric";
import BasketStatusesService from "../Proxy/BasketStatusesService";

export default class BasketStatusesOperation extends Operation
{
    service: BasketStatusesService;

    constructor()
    {
        super();
        this.service = new BasketStatusesService();
    }

    async get()
    {
        return await this.service.get();
    }
}