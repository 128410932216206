import {ContentShellViewModel, ContentShellView} from '@levelapp/softfabric';
import ShellContentTypeKeyConfiguratorViewState from './ShellContentTypeKeyConfiguratorViewState';
import ShellContentTypeKeyConfiguratorViewProps from './ShellContentTypeKeyConfiguratorViewProps';
 
import ContentTypeKeyConfiguratorView from './pages/contenttypekeyconfigurator/ContentTypeKeyConfiguratorView';
import EnumModules from '../../../../../Common/Constants/EnumModules';

export default class ShellContentTypeKeyConfiguratorViewModel extends ContentShellViewModel<ShellContentTypeKeyConfiguratorViewState, ShellContentTypeKeyConfiguratorViewProps>
{

    constructor(view : ContentShellView)
    {
        super(view);

        this.registerEntryPoint('main'); 
        this.register(<ContentTypeKeyConfiguratorView/> ,'main'); 

        this.initialisation();
    }

    getModuleId()
    {
        return EnumModules.CONTENT_TYPE_KEY_CONFIGURATOR; // TODO : change the return value with your ModuleId
    }

}