 
import { ContentView } from '@levelapp/softfabric';
import TranslationNonContentPopUpViewState from './TranslationNonContentPopUpViewState';
import TranslationNonContentPopUpViewProps from './TranslationNonContentPopUpViewProps';
import TranslationNonContentPopUpViewStyle from './TranslationNonContentPopUpViewStyle';
import TranslationNonContentPopUpViewModel from './TranslationNonContentPopUpViewModel';
import { TranslationView } from '@levelapp/softfabric-ui';
import { View, TouchableWithoutFeedback, Image, Text, TextInput } from 'react-native';
import LinearGradient from "react-native-web-linear-gradient";
import Constants from '../../../../../../../Common/Constants/Constants';
import ButtonView from "../../../../../../../Common/Components/Button/ButtonView";
import Language from '../../../../../../../Common/DTO/Language';
import Helpers from '../../../../../../../Common/Helpers/Helpers';

export default class TranslationNonContentPopUpView extends ContentView<TranslationNonContentPopUpViewProps, TranslationNonContentPopUpViewState, TranslationNonContentPopUpViewModel, TranslationNonContentPopUpViewStyle>
{
  /* CONSTRUCTOR */
  constructor(props: TranslationNonContentPopUpViewProps) {
    super(props);

    // Binding
    this.bind(new TranslationNonContentPopUpViewModel(this), new TranslationNonContentPopUpViewStyle(this));
  }

  /* RENDERING */
  render() {
    return (
      <View style={{ minWidth: 600, minHeight: 850, width: Constants.PopUpWidth, maxWidth: "100%" }}>
        <View>
          <LinearGradient start={{ x: 0, y: 0 }} end={{ x: 1, y: 0 }} colors={[Constants.GradiantStartColor, Constants.GradiantEndColor]} >
            <View style={{ display: "flex", flexDirection: "row" }}>
              <Text style={this.style.title}>
                {this.state.title}
              </Text>
              <ButtonView isLoading={this.state.isSaving} handleClick={this.binding.handleSavePressed} backgroundColor="white" style={{ marginTop: 20 }}>
                <TranslationView upperCase style={this.style.saveButton}>
                  generic.button.saveclose
                </TranslationView>
              </ButtonView>
              <TouchableWithoutFeedback onPress={this.binding.handleBackPressed} >
                <Image style={{ height: 23, width: 23, resizeMode: 'contain', marginTop: 25, marginLeft: 20 }}
                  source={{ uri: 'https://appstoragflyx.blob.core.windows.net/app/Console/exit.png' }}></Image>
              </TouchableWithoutFeedback>
            </View>
          </LinearGradient>
        </View>
        <View style={{  paddingHorizontal: 170, paddingVertical: 50 }}>
          <Text style={this.style.inputTitle}><TranslationView upperCase>form.keyfield</TranslationView></Text>
          <TextInput
            style={this.state.showErrors ? this.style.errorKeyInput : this.style.keyInput}
            value={this.state.translation.key}
            editable = {this.state.translation.id == 0}
            onChangeText={(text: string) => { this.binding.handleKeyChanged(text)}}
            multiline={false} />
        </View>
        <View style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', justifyContent: 'space-between', paddingHorizontal: 170, paddingVertical: 50 }}>
          {this.state.languages.map((content: Language) => {
            var _text = Helpers.getTranslationValueByLanguageKey(this.state.translation.translationValues, content.key);
            return (
              <View style={this.style.section}>
                <Text style={this.style.inputTitle}><TranslationView upperCase>form.titlefield</TranslationView> ({content.key.toUpperCase()})</Text>
                <TextInput
                  style={this.state.showErrors && (_text == null || _text == "") ? this.style.errorTextArea : this.style.textArea}
                  value={_text}
                  onChangeText={(text: string) => this.binding.handleTextChanged(text, content.key)}
                  multiline={true} />
              </View>
            );
          })}
        </View>
      </View>
    );
  }
}