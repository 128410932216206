 
import { ContentShellView} from '@levelapp/softfabric';
import ShellTraductionViewState from './ShellTraductionViewState';
import ShellTraductionViewProps from './ShellTraductionViewProps';
import ShellTraductionViewStyle from './ShellTraductionViewStyle';
import ShellTraductionViewModel from './ShellTraductionViewModel';
import { View } from 'react-native';

export default class ShellTraductionView extends ContentShellView<ShellTraductionViewProps, ShellTraductionViewState, ShellTraductionViewModel, ShellTraductionViewStyle>
{
  /* CONSTRUCTOR */
  constructor(props:ShellTraductionViewProps)
  {
    super(props);

    // Binding
    this.bind(new ShellTraductionViewModel(this), new ShellTraductionViewStyle(this));
  }

  /* RENDERING */
  render()
  {
    return (
      <View style={{flex : 1}}>
        {this.getSwitch()}
      </View>
    );
  }
}