import {ContentShellViewModel, ContentShellView} from '@levelapp/softfabric';
import EnumModules from '../../../../../Common/Constants/EnumModules';
import ProductAdditionalInfosView from './pages/productAdditionalInfos/ProductAdditionalInfosView';
import ShellProductAdditionalInfosViewProps from './ShellProductAdditionalInfosViewProps';
import ShellProductAdditionalInfosViewState from './ShellProductAdditionalInfosViewState';

export default class ShellProductAdditionalInfosViewModel extends ContentShellViewModel<ShellProductAdditionalInfosViewState, ShellProductAdditionalInfosViewProps>
{

    constructor(view : ContentShellView)
    {
        super(view);

        this.registerEntryPoint('main'); // TODO : change with the route of your entry point
        this.register(<ProductAdditionalInfosView/> ,'main');

        this.initialisation();
    }

    getModuleId()
    {
        return EnumModules.PRODUCT_ADDITIONAL_INFOS;
    }

}