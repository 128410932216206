import {Application, ContentViewStyle} from '@levelapp/softfabric';
import { StyleManager, UiManagers } from '@levelapp/softfabric-ui';
import {StyleProp,TextStyle,ViewStyle} from 'react-native';

export default class SettingsPopupViewStyle extends ContentViewStyle
{
    MyStyleManager: StyleManager = Application.current.resolve<StyleManager>(UiManagers.STYLE_MANAGER);

    page: StyleProp<ViewStyle> = {
        flex: 1
    }

    kitchenModus: StyleProp<any> = {
        width : 800,
        top: 0,
        backgroundColor: 'white',
        borderBottomLeftRadius: 3,
        borderBottomRightRadius: 3,

        boxShadow: '0 2px 4px 0 rgba(0,0,0,0.5)',
        justifyContent: 'space-between'
    };

    kitchenModusTxt: StyleProp<TextStyle> = {
        fontSize: 14,
        color: '#358DFF'
    };

    kitchenModusInfoTxt: StyleProp<TextStyle> = {
        fontSize: 12,
        color: '#B0B0B0'
    };

    dayButtonsContainer: StyleProp<ViewStyle> = {
        flexDirection: 'row'
    };

    todayButton: StyleProp<any> = {
        height: 45,
        backgroundColor: 'white',
        borderRadius: 5,
        justifyContent: 'center',
        alignItems: 'center',
        boxShadow: '0 2px 4px 2px rgba(164,164,164,0.5)',
        paddingHorizontal: 30
    };

    saveBtn: StyleProp<TextStyle> = {
        color: 'white',
        fontSize: 10,
        fontWeight: '600',
        height: 27,
        width: 112
    };
    
}