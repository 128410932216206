import {ContentViewStyle} from '@levelapp/softfabric';
import {StyleProp,ViewStyle, TextStyle} from 'react-native';

export default class PopupMenuItemViewStyle extends ContentViewStyle
{
    container: StyleProp<ViewStyle> = {
        display: "flex",
        flex: 1,
        flexDirection: "column", 
        alignItems: "center" 
    }

    titleActive: StyleProp<TextStyle> = {
        fontSize: 14,
        color: '#3F3F3F',
        fontFamily : 'Montserrat Bold',
    }

    titleInactive: StyleProp<TextStyle> = {
        color : '#B8BBC1',
        fontSize: 14,
        fontFamily : 'Montserrat SemiBold',
    }

    tabActive: StyleProp<ViewStyle> = {
        backgroundColor: "white",
        width: "100%",
        alignItems: "center",
        height: "50px",
        display: "flex",
        justifyContent: "center",
        borderBottomColor : '#E4E4E4',
        borderBottomWidth : 1
      };
  
      tab: StyleProp<ViewStyle> = {
          backgroundColor: "#EEF0F4",
          width: "100%",
          alignItems: "center",
          height: "50px",
          display: "flex",
          justifyContent: "center",
          borderBottomColor : '#E4E4E4',
          borderBottomWidth : 1
      };
}