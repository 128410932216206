 
import { ContentView } from '@levelapp/softfabric';
import { TranslationView } from '@levelapp/softfabric-ui';
import { View, TouchableWithoutFeedback, Image } from 'react-native';
import Constants from '../../../../../../../Common/Constants/Constants';
import LinearGradient from "react-native-web-linear-gradient";
import ButtonView from '../../../../../../../Common/Components/Button/ButtonView';
import LoyaltyRulesTranslationPopupViewProps from './LoyaltyRulesTranslationPopupViewProps';
import LoyaltyRulesTranslationPopupViewState from './LoyaltyRulesTranslationPopupViewState';
import LoyaltyRulesTranslationPopupViewModel from './LoyaltyRulesTranslationPopupViewModel';
import LoyaltyRulesTranslationPopupViewStyle from './LoyaltyRulesTranslationPopupViewStyle';
import Language from '../../../../../../../Common/DTO/Language';
import Helpers from '../../../../../../../Common/Helpers/Helpers';
import ExtendedBlockTextInputView from '../../../../components/extendedblocktextinput/ExtendedBlockTextInputView';

export default class LoyaltyRulesTranslationPopupView extends ContentView<LoyaltyRulesTranslationPopupViewProps, LoyaltyRulesTranslationPopupViewState, LoyaltyRulesTranslationPopupViewModel, LoyaltyRulesTranslationPopupViewStyle>
{
    /* CONSTRUCTOR */
    constructor(props: LoyaltyRulesTranslationPopupViewProps)
    {
        super(props);

        // Binding
        this.bind(new LoyaltyRulesTranslationPopupViewModel(this), new LoyaltyRulesTranslationPopupViewStyle(this));
    }

    /* RENDERING */
    render()
    {
        return (
            <View style={{ minWidth: 600, minHeight: 850, width: Constants.PopUpWidth, maxWidth: "100%" }}>
                <View>
                    <LinearGradient start={{ x: 0, y: 0 }} end={{ x: 1, y: 0 }} colors={[Constants.GradiantStartColor, Constants.GradiantEndColor]} >
                        <View style={{ display: "flex", flexDirection: "row" }}>
                            <TranslationView style={this.style.title}>form.edittexts</TranslationView>
                            <ButtonView isLoading={this.state.isSaving} handleClick={this.binding.handleSavePressed} backgroundColor="white" style={{ marginTop: 20 }}>
                                <TranslationView upperCase style={this.style.saveButton}>
                                    loyaltyrules.button.save
                                </TranslationView>
                            </ButtonView>
                            <TouchableWithoutFeedback onPress={this.binding.handleBackPressed} >
                                <Image style={{ height: 23, width: 23, resizeMode: 'contain', marginTop: 25, marginLeft: 20 }}
                                    source={{ uri: 'https://appstoragflyx.blob.core.windows.net/app/Console/exit.png' }}></Image>
                            </TouchableWithoutFeedback>
                        </View>
                    </LinearGradient>
                </View>
                <View style={{ flexDirection: 'column', paddingHorizontal: 170, paddingVertical: 50 }}>

                <View style={{ zIndex: -1, display: 'flex', flexDirection: 'row', flexWrap: 'wrap', justifyContent: 'space-between' }}>
                    {/* Title */}
                    {this.state.languages.map((content: Language) =>
                    {
                        var _text = Helpers.getTranslationValueByLanguageKey(this.state.translation.loyaltyRulesTranslationValues,content.key);
                        return (
                        <ExtendedBlockTextInputView
                            title={content.key.toUpperCase()}
                            showError={this.state.shouldShowError && (_text == null || _text == "") ? true : false}
                            value={_text}
                            onChangeText={(text : string ) => this.binding.handleTextChanged(text,content.id as number)}
                        />
                        
                        );
                    })}
                </View>


                </View>

            </View >
        );
    }
}