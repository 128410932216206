 
import {ContentView} from '@levelapp/softfabric';
import MainInformationViewState from './MainInformationViewState';
import MainInformationViewProps from './MainInformationViewProps';
import MainInformationViewStyle from './MainInformationViewStyle';
import MainInformationViewModel from './MainInformationViewModel';
import { View } from 'react-native';
import SectionTitleView from '../../../../../../components/sectiontitle/SectionTitleView';
import ExtendedTextInputView from '../../../../../../components/extendedtextinput/ExtendedTextInputView';
import ExtendedDropDownView from '../../../../../../components/extendeddropdown/ExtendedDropDownView';
import LineView from '../../../../../../components/line/LineView';
import DashboardType from '../../../../../../../../../Common/DTO/DashboardType';

export default class MainInformationView extends ContentView<MainInformationViewProps, MainInformationViewState, MainInformationViewModel, MainInformationViewStyle>
{
  /* CONSTRUCTOR */
  constructor(props:MainInformationViewProps)
  {
    super(props);

    // Binding
    this.bind(new MainInformationViewModel(this), new MainInformationViewStyle(this));
  }

  /* RENDERING */
  render()
  {
    const { container , leftblock } = this.style;

    return (
      <View style={container}>

            <SectionTitleView title={'form.informations'}/>
            {/* UpperSide */}
            <View style={{ flexDirection: "row" , zIndex : 1 }}>
              {/* Id side */}
              <View style={{zIndex : 1}}>
                <View style={leftblock}>
                  <ExtendedTextInputView
                      title={"form.id"}
                      disable
                      showError={false}
                      value={`${this.props.dashboard.id == undefined ? ' ' :this.props.dashboard.id}`}
                      onChangeText={() => {}}
                    />
                    <ExtendedTextInputView
                      title={"form.externalid"}
                      showError={false}
                      value={this.props.dashboard.thirdPartyId}
                      onChangeText={(text: string) => this.props.handleValueChange('tpid',text)}
                    />
                </View>
              </View>
            </View>

            <LineView/>

            <SectionTitleView title={'stats.settings.dashboardtype.title'}/>

            <ExtendedDropDownView
                  title={'stats.settings.dashboardtype'}
                  selectedValue={this.binding.changeTypeToDropdown()}
                  onValueChange={(value : DashboardType) => this.props.handleDropDownChange(value)}
                  values={this.binding.changeTypesToDropdown()}
                />
      </View>
    );
  }
}