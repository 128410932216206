import "reflect-metadata";
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import ShellView from './Presentation/ShellView';
import { Application, AuthManager, StorageManager, TranslationManager, HttpManager, Managers, LanguageManager, NavigationManager, PopUpManager, ModuleManager, ToastManager } from '@levelapp/softfabric';
import { UiManagers } from '@levelapp/softfabric-ui';
import MyStyleManager from './Common/Managers/MyStyleManager';
import Constants from "./Common/Constants/Constants";
import EnumModules from "./Common/Constants/EnumModules";
import ExtendedManagers from "./Common/Managers/ExtendedManagers";
import RoleManager from "./Common/Managers/RoleManager";
import PermissionHelper from "./Common/Helpers/PermissionHelper";

export default class App extends Application
{
  initialisation()
  {
    Application.current = this; 

    this.register(ExtendedManagers.ROLE_MANAGER, new RoleManager());

    this.register(Managers.TOAST_MANAGER, new ToastManager());

    this.register(Managers.STORAGE_MANAGER, new StorageManager());

    this.register(Managers.NAVIGATION_MANAGER, new NavigationManager());

    this.register(Managers.AUTH_MANAGER,
      new AuthManager(`${Constants.RessourcesWithoutApi}/connect/token`,
        "admin",
        Constants.IAMClientSecret,
        this.resolve(Managers.STORAGE_MANAGER))); 

    this.resolve<AuthManager>(Managers.AUTH_MANAGER).handleNoTokenFound = this.handleNoTokenFound;

    //Current language && Array Accepted Languages
    this.register(Managers.LANGUAGE_MANAGER, new LanguageManager("en-GB", ["en-GB"]));

    //Registrer The Translation Manager with a path to the translation.json
    this.register(Managers.TRANSLATION_MANAGER, new TranslationManager(require('./Translations/Translations.json')));

    //To modify the theme of your app. Specify a new Style Manager.
    //Create a ExtendedManager extends StyleManager 
    this.register(UiManagers.STYLE_MANAGER, new MyStyleManager());

    //Set a httpClient with an url and a callback
    this.register(Managers.HTTP_MANAGER, new HttpManager(Constants.RessourcesUrl, async () =>
    {
      let auth = this.resolve<AuthManager>(Managers.AUTH_MANAGER);
      let token = await auth.getTokenFromDisk();
      if (token)
      {
        return "Bearer" + ' ' + token.access_token;
      }

      else
      {
        return '';
      }
    }, this.resolve(Managers.AUTH_MANAGER)));

    this.register(ExtendedManagers.DELIVERY_MANAGER, new HttpManager(Constants.DeliveryUrl, async () =>
    {
      let auth = this.resolve<AuthManager>(Managers.AUTH_MANAGER);
      let token = await auth.getTokenFromDisk();
      if (token)
      {
        return "Bearer" + ' ' + token.access_token;
      }

      else
      {
        return '';
      }
    }, this.resolve(Managers.AUTH_MANAGER)));

    this.register(ExtendedManagers.KITCHEN_MANAGER, new HttpManager(Constants.KitchenUrl, async () =>
    {
      let auth = this.resolve<AuthManager>(Managers.AUTH_MANAGER);
      let token = await auth.getTokenFromDisk();
      if (token)
      {
        return "Bearer" + ' ' + token.access_token;
      }

      else
      {
        return '';
      }
    }, this.resolve(Managers.AUTH_MANAGER)));

    this.register(ExtendedManagers.IDENTITY_MANAGER, new HttpManager(Constants.IdentityUrl, async () =>
    {
      let auth = this.resolve<AuthManager>(Managers.AUTH_MANAGER);
      let token = await auth.getTokenFromDisk();
      if (token)
      {
        return "Bearer" + ' ' + token.access_token;
      }

      else
      {
        return '';
      }
    }, this.resolve(Managers.AUTH_MANAGER)));

    this.register(Managers.MODULE_MANAGER, new ModuleManager());

    this.registerModule(EnumModules.FAQ, 'dashboard/faq');
    this.registerModule(EnumModules.NEWS, 'dashboard/news');
    this.registerModule(EnumModules.USERS, 'dashboard/users');
    this.registerModule(EnumModules.STATS, 'dashboard/stats');
    this.registerModule(EnumModules.STORES, 'dashboard/stores');
    this.registerModule(EnumModules.ORDERS, 'dashboard/orders');
    this.registerModule(EnumModules.REWARDS, 'dashboard/rewards');
    this.registerModule(EnumModules.COUPONS, 'dashboard/coupons');
    this.registerModule(EnumModules.TICKETS, 'dashboard/tickets');
    this.registerModule(EnumModules.LANGUAGE, 'dashboard/language');
    this.registerModule(EnumModules.BOOSTERS, 'dashboard/boosters');
    this.registerModule(EnumModules.PRODUCTS, 'dashboard/products');
    this.registerModule(EnumModules.MESSAGES, 'dashboard/messages');
    this.registerModule(EnumModules.OVERVIEW, 'dashboard/overview');
    this.registerModule(EnumModules.COUNTRIES, 'dashboard/countries');
    this.registerModule(EnumModules.PROMOCODES, 'dashboard/promocodes');
    this.registerModule(EnumModules.KITCHENAPP, 'dashboard/kitchenapp');
    this.registerModule(EnumModules.ADMINUSERS, 'dashboard/adminusers');
    this.registerModule(EnumModules.LEGAL_TEXTS, 'dashboard/legaltexts');
    this.registerModule(EnumModules.TRANSLATION, 'dashboard/translation');
    this.registerModule(EnumModules.SAVINGCARDS, (Constants.IsSavingCardsUsedAsBadge) ? 'dashboard/badges' : 'dashboard/savingCards');
    this.registerModule(EnumModules.EVERYDAYVALUES, 'dashboard/everyday');
    this.registerModule(EnumModules.HEALTHSTATUS, 'dashboard/healthstatus');
    this.registerModule(EnumModules.LOYALTY_RULES, 'dashboard/loyaltyrules');
    this.registerModule(EnumModules.POS_SIMULATOR, 'dashboard/possimulator');
    this.registerModule(EnumModules.STORES_SUMMARY, 'dashboard/summarystores');
    this.registerModule(EnumModules.ROLEPERMISSIONS, 'dashboard/rolepermissions');
    this.registerModule(EnumModules.MENUCONFIGURATOR, 'dashboard/menuconfigurator');
    this.registerModule(EnumModules.PRODUCT_CATEGORIES, 'dashboard/productcategories');
    this.registerModule(EnumModules.AUTOMATED_MESSAGES, 'dashboard/automatedmessages');
    this.registerModule(EnumModules.CROSS_SELL_CONFIGURATOR, 'dashboard/crosssellconfigurator');
    this.registerModule(EnumModules.OUT_OF_STOCK, 'dashboard/outofstock');
    this.registerModule(EnumModules.CONTENT_TYPE_KEY_CONFIGURATOR, 'dashboard/contenttypekeyconfig');
    this.registerModule(EnumModules.BANNERS, 'dashboard/banners');
    this.registerModule(EnumModules.ZONES_TOOLS, 'dashboard/tools/zones');
    this.registerModule(EnumModules.PRODUCTS_TOOLS, 'dashboard/tools/tproducts');
    this.registerModule(EnumModules.EARNINGPOINT, 'dashboard/earningpoints');
    this.registerModule(EnumModules.MEMBERSHIP, 'dashboard/membership');
    this.registerModule(EnumModules.VOUCHERS, 'dashboard/vouchers');
    this.registerModule(EnumModules.PRODUCT_ADDITIONAL_INFOS, 'dashboard/productAdditionalInfos');

    //Set the PopUp Manager
    this.register(Managers.POPUP_MANAGER, new PopUpManager());

    //TODO
    //Set a callback for handling errors differents than 404 and 401
    this.resolve<HttpManager>(Managers.HTTP_MANAGER).handleSomethingWentWrong = this.handleSomethingWentWrong;
    // this.resolve<HttpManager>(Managers.HTTP_MANAGER).handleUnauthorized = this.handleUnauthorized;
    this.resolve<HttpManager>(Managers.HTTP_MANAGER).handleNotFound = this.handleNotFound;

    //Set tenant if needed
    if(Constants.UseTenant)
    {
      this.resolve<HttpManager>(Managers.HTTP_MANAGER).addCustomHeader("tenant-id",Constants.TenantId);
      this.resolve<HttpManager>(ExtendedManagers.IDENTITY_MANAGER).addCustomHeader("tenant-id",Constants.TenantId);
      this.resolve<HttpManager>(ExtendedManagers.KITCHEN_MANAGER).addCustomHeader("tenant-id",Constants.TenantId);
      this.resolve<HttpManager>(ExtendedManagers.DELIVERY_MANAGER).addCustomHeader("tenant-id",Constants.TenantId);
    }

    PermissionHelper.init();
  }

  handleNotFound(error: any)
  {
    console.log(error);
    // alert('404');
  }

  handleSomethingWentWrong(error: any)
  {
    console.log(error);
    //Application.current.resolve<NavigationManager>(Managers.NAVIGATION_MANAGER).navigate('/login');
    // alert('!404 && !401');
  }

  handleError()
  {
    Application.current.resolve<NavigationManager>(Managers.NAVIGATION_MANAGER).navigate('/login');
  }

  handleNoTokenFound()
  {
    Application.current.resolve<NavigationManager>(Managers.NAVIGATION_MANAGER).navigate('/login');
  }

  // handleUnauthorized()
  // {
  //   Application.current.resolve<NavigationManager>(Managers.NAVIGATION_MANAGER).navigate('/login');
  // }

  renderApplication()
  {
    return (<ShellView />);
  }
}
