import { ContentViewModel, ContentView } from '@levelapp/softfabric';
import SliderViewState from './SliderViewState';
import SliderViewProps from './SliderViewProps';

export default class SliderViewModel extends ContentViewModel<SliderViewState, SliderViewProps>
{
    constructor(props: ContentView)
    {
        super(props);

    }
}