 
import {ContentView} from '@levelapp/softfabric';
import SectionTitleViewState from './SectionTitleViewState';
import SectionTitleViewProps from './SectionTitleViewProps';
import SectionTitleViewStyle from './SectionTitleViewStyle';
import SectionTitleViewModel from './SectionTitleViewModel';
import {TranslationView} from '@levelapp/softfabric-ui';
import { Text, View } from 'react-native';

export default class SectionTitleView extends ContentView<SectionTitleViewProps, SectionTitleViewState, SectionTitleViewModel, SectionTitleViewStyle>
{
  /* CONSTRUCTOR */
  constructor(props:SectionTitleViewProps)
  {
    super(props);

    // Binding
    this.bind(new SectionTitleViewModel(this), new SectionTitleViewStyle(this));
  }

  /* RENDERING */
  render()
  {
    return (
      <View style={[{ paddingBottom: 20 }]}>
        <Text style={this.style.subHeading}><TranslationView style={this.style.subHeading} upperCase>{this.props.title}</TranslationView> {this.props.afterTitle}</Text>
      </View>
    );
  }
}