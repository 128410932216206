 
import { ContentView } from '@levelapp/softfabric';
import LoyaltyRulesViewState from './LoyaltyRulesViewState';
import LoyaltyRulesViewProps from './LoyaltyRulesViewProps';
import LoyaltyRulesViewStyle from './LoyaltyRulesViewStyle';
import LoyaltyRulesViewModel from './LoyaltyRulesViewModel';
import { TranslationView, LottieView } from '@levelapp/softfabric-ui';
import { View, TextInput } from 'react-native';
import Constants from "../../../../../../../Common/Constants/Constants";
import ButtonView from "../../../../../../../Common/Components/Button/ButtonView";
import SliderView from '../../../../../../../Common/Components/slider/SliderView';
import DropDown from '../../../../../../../Common/Components/Dropdown/DropDownView';
import { LoyaltyRulesKeys } from '../../../../../../../Common/Enums/LoyaltyRulesKeys';
import LoyaltyRulesTranslation from '../../../../../../../Common/DTO/LoyaltyRulesTranslation';
import HeaderView from '../../../../components/header/HeaderView';

export default class LoyaltyRulesView extends ContentView<LoyaltyRulesViewProps, LoyaltyRulesViewState, LoyaltyRulesViewModel, LoyaltyRulesViewStyle>
{
  /* CONSTRUCTOR */
  constructor(props: LoyaltyRulesViewProps)
  {
    super(props);

    // Binding
    this.bind(new LoyaltyRulesViewModel(this), new LoyaltyRulesViewStyle(this));
  }

  /* RENDERING */
  render()
  {
    return (
      <View style={this.style.container}>
        <View style={this.style.box}>

          <HeaderView
            title={"loyaltyrules.title"}
            description={"loyaltyrules.description"}
            shouldShowButton={true}
            onButtonPress={this.binding.save}
            buttonText={"loyaltyrules.button.save"}
          />

          <View style={{ display: "flex", flexDirection: "column", paddingHorizontal: 100, paddingVertical: 50 }}>

            {!this.state.isLoading ? (<View>
              <View style={{ flexDirection: "column" }}>
                <TranslationView style={this.style.subheading} upperCase>form.pointsrules</TranslationView>
                <View style={{ paddingBottom: 25, }}>
                  <TranslationView style={this.style.inputTitle} upperCase>form.numberofpointsbyeuro</TranslationView>
                  <TextInput keyboardType={'numeric'} style={this.state.showErrors && this.binding.getValue(LoyaltyRulesKeys.PointsMoneyRatio) == -1 ? this.style.errorTextField : this.style.input}
                    value={this.binding.getValue(LoyaltyRulesKeys.PointsMoneyRatio) != -1 ? this.binding.getValue(LoyaltyRulesKeys.PointsMoneyRatio) + "" : ""}
                    onChangeText={(text: string) => { this.binding.handleValueChanged(LoyaltyRulesKeys.PointsMoneyRatio, text) }} />
                </View>
                <View style={{ paddingBottom: 25 }}>
                  <TranslationView style={this.style.inputTitle} upperCase>NumberOfPointsMax</TranslationView>
                  <TextInput keyboardType={'numeric'} style={this.state.showErrors && this.binding.getValue(LoyaltyRulesKeys.MaxPointsByUser) == -1 ? this.style.errorTextField : this.style.input}
                    value={this.binding.getValue(LoyaltyRulesKeys.MaxPointsByUser) != -1 ? this.binding.getValue(LoyaltyRulesKeys.MaxPointsByUser) + "" : ""}
                    onChangeText={(text: string) => { this.binding.handleValueChanged(LoyaltyRulesKeys.MaxPointsByUser, text) }} />
                </View>
                <View>
                  <View style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginBottom: 10 }}>
                    <TranslationView style={[this.style.inputTitle, { marginBottom: 0 }]} upperCase>form.numberof</TranslationView>
                    <DropDown
                      values={this.binding.getUnits(LoyaltyRulesKeys.InactivityTime)}
                      selectedValue={this.binding.getUnits(LoyaltyRulesKeys.InactivityTime).find((element: any) => { return element.id == this.binding.getUnit(LoyaltyRulesKeys.InactivityTime)?.id || 0 })}
                      onValueChange={(value: any) => { this.binding.handleUnitChanged(LoyaltyRulesKeys.InactivityTime, value.id) }} />
                    <TranslationView style={[this.style.inputTitle, { marginBottom: 0 }]} upperCase>form.ofinactivitytoremove</TranslationView>
                  </View>
                  <SliderView minValue={this.binding.getUnit(LoyaltyRulesKeys.InactivityTime)?.minValue || 0} maxValue={this.binding.getUnit(LoyaltyRulesKeys.InactivityTime)?.maxValue || 0} currentValue={this.binding.getValue(LoyaltyRulesKeys.InactivityTime)} handleChange={(value: number) => { this.binding.handleValueChanged(LoyaltyRulesKeys.InactivityTime, value) }} backgroundColor={Constants.GradiantStartColor} />
                </View>

                {this.state.translations.filter(t => t.loyaltyRulesKeyId == LoyaltyRulesKeys.PointsMoneyRatio).length > 0 &&
                  (
                    <View style={this.style.editTexts}>
                      <TranslationView style={this.style.inputTitle} upperCase>form.edittexts</TranslationView>
                      <View style={this.style.loyaltyTranslationButtons}>
                        {this.state.translations.filter(t => t.loyaltyRulesKeyId == LoyaltyRulesKeys.PointsMoneyRatio).map((t: LoyaltyRulesTranslation, key: number) =>
                        {
                          return (<ButtonView isLoading={false} style={{ marginRight: 10 }} backgroundColor={Constants.GradiantStartColor} handleClick={() => this.binding.editLoyaltyRuleTranslation(t)} key={key}><TranslationView>{t.label}</TranslationView></ButtonView>);
                        })}
                      </View>
                    </View>
                  )}
              </View>

              {/* Line */}
              <View style={{ backgroundColor: '#D8D8D8', width: '100%', height: 1, marginTop: 30, marginBottom: 30 }}></View>


              <View style={{ flexDirection: "column" }}>
                <TranslationView style={this.style.subheading} upperCase>form.purchases</TranslationView>
                <View style={{ paddingBottom: 15, }}>
                  <TranslationView style={this.style.inputTitle} upperCase>form.numberofpurchaseperperiod</TranslationView>
                  <TextInput keyboardType={'numeric'} style={this.state.showErrors && this.binding.getValue(LoyaltyRulesKeys.MaxPurchasesPerPeriod) == -1 ? this.style.errorTextField : this.style.input}
                    value={this.binding.getValue(LoyaltyRulesKeys.MaxPurchasesPerPeriod) != -1 ? this.binding.getValue(LoyaltyRulesKeys.MaxPurchasesPerPeriod) + "" : ""}
                    onChangeText={(text: string) => { this.binding.handleValueChanged(LoyaltyRulesKeys.MaxPurchasesPerPeriod, text) }} />
                </View>
                <View style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginBottom: 10 }}>
                  <TranslationView style={[this.style.inputTitle, { marginBottom: 0 }]} upperCase>form.periodofpurchase</TranslationView>
                  <DropDown
                    values={this.binding.getUnits(LoyaltyRulesKeys.PeriodPurchase)}
                    selectedValue={this.binding.getUnits(LoyaltyRulesKeys.PeriodPurchase).find((element: any) => { return element.id == this.binding.getUnit(LoyaltyRulesKeys.PeriodPurchase)?.id || 0 })}
                    onValueChange={(value: any) => { this.binding.handleUnitChanged(LoyaltyRulesKeys.PeriodPurchase, value.id) }} />
                </View>
                <SliderView minValue={this.binding.getUnit(LoyaltyRulesKeys.PeriodPurchase)?.minValue || 0} maxValue={this.binding.getUnit(LoyaltyRulesKeys.PeriodPurchase)?.maxValue || 0} currentValue={this.binding.getValue(LoyaltyRulesKeys.PeriodPurchase)} handleChange={(value: number) => { this.binding.handleValueChanged(LoyaltyRulesKeys.PeriodPurchase, value) }} backgroundColor={Constants.GradiantStartColor} />
                {this.state.translations.filter(t => t.loyaltyRulesKeyId == LoyaltyRulesKeys.MaxPurchasesPerPeriod).length > 0 &&
                  (
                    <View style={this.style.editTexts}>
                      <TranslationView style={this.style.inputTitle} upperCase>form.edittexts</TranslationView>
                      <View style={this.style.loyaltyTranslationButtons}>
                        {this.state.translations.filter(t => t.loyaltyRulesKeyId == LoyaltyRulesKeys.MaxPurchasesPerPeriod).map((t: LoyaltyRulesTranslation, key: number) =>
                        {
                          return (<ButtonView isLoading={false} style={{ marginRight: 10 }} backgroundColor={Constants.GradiantStartColor} handleClick={() => this.binding.editLoyaltyRuleTranslation(t)} key={key}><TranslationView>{t.label}</TranslationView></ButtonView>);
                        })}
                      </View>
                    </View>
                  )}
              </View>

              {/* Line */}
              <View style={{ backgroundColor: '#D8D8D8', width: '100%', height: 1, marginTop: 30, marginBottom: 30 }}></View>

              <View style={{ flexDirection: "column" }}>
                <TranslationView style={this.style.subheading} upperCase>form.numberofticketsretrievalperperiod</TranslationView>
                <View style={{ paddingBottom: 15, }}>
                  <TranslationView style={this.style.inputTitle} upperCase>form.numberofdaysmanualticketsretrieval</TranslationView>
                  <TextInput keyboardType={'numeric'} style={this.state.showErrors && this.binding.getValue(LoyaltyRulesKeys.MaxTicketRetrievalPerPeriod) == -1 ? this.style.errorTextField : this.style.input}
                    value={this.binding.getValue(LoyaltyRulesKeys.MaxTicketRetrievalPerPeriod) != -1 ? this.binding.getValue(LoyaltyRulesKeys.MaxTicketRetrievalPerPeriod) + "" : ""}
                    onChangeText={(text: string) => { this.binding.handleValueChanged(LoyaltyRulesKeys.MaxTicketRetrievalPerPeriod, text) }} />
                </View>
                <View style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginBottom: 10 }}>
                  <TranslationView style={[this.style.inputTitle, { marginBottom: 0 }]} upperCase>NumberOfDaysManualTicketsRetrieval</TranslationView>
                  <DropDown
                    values={this.binding.getUnits(LoyaltyRulesKeys.TicketRetrievalPeriod)}
                    selectedValue={this.binding.getUnits(LoyaltyRulesKeys.TicketRetrievalPeriod).find((element: any) => { return element.id == this.binding.getUnit(LoyaltyRulesKeys.TicketRetrievalPeriod)?.id || 0 })}
                    onValueChange={(value: any) => { this.binding.handleUnitChanged(LoyaltyRulesKeys.TicketRetrievalPeriod, value.id) }} />
                </View>
                <SliderView minValue={this.binding.getUnit(LoyaltyRulesKeys.TicketRetrievalPeriod)?.minValue || 0} maxValue={this.binding.getUnit(LoyaltyRulesKeys.TicketRetrievalPeriod)?.maxValue || 0} currentValue={this.binding.getValue(LoyaltyRulesKeys.TicketRetrievalPeriod)} handleChange={(value: number) => { this.binding.handleValueChanged(LoyaltyRulesKeys.TicketRetrievalPeriod, value) }} backgroundColor={Constants.GradiantStartColor} />
                {this.state.translations.filter(t => t.loyaltyRulesKeyId == LoyaltyRulesKeys.MaxTicketRetrievalPerPeriod).length > 0 &&
                  (
                    <View style={this.style.editTexts}>
                      <TranslationView style={this.style.inputTitle} upperCase>form.edittexts</TranslationView>
                      <View style={this.style.loyaltyTranslationButtons}>
                        {this.state.translations.filter(t => t.loyaltyRulesKeyId == LoyaltyRulesKeys.MaxTicketRetrievalPerPeriod).map((t: LoyaltyRulesTranslation, key: number) =>
                        {
                          return (<ButtonView isLoading={false} style={{ marginRight: 10 }} backgroundColor={Constants.GradiantStartColor} handleClick={() => this.binding.editLoyaltyRuleTranslation(t)} key={key}><TranslationView>{t.label}</TranslationView></ButtonView>);
                        })}
                      </View>
                    </View>
                  )}
              </View>

              {/* Line */}
              <View style={{ backgroundColor: '#D8D8D8', width: '100%', height: 1, marginTop: 30, marginBottom: 30 }}></View>

              <View style={{ flexDirection: "column" }}>
                <TranslationView style={this.style.subheading} upperCase>form.membersponsorship</TranslationView>
                <View>
                  <TranslationView style={this.style.inputTitle} upperCase>form.numberofmemberssponsorship</TranslationView>
                  <TextInput keyboardType={'numeric'} style={this.state.showErrors && this.binding.getValue(LoyaltyRulesKeys.MaxMemberSponsorship) == -1 ? this.style.errorTextField : this.style.input}
                    value={this.binding.getValue(LoyaltyRulesKeys.MaxMemberSponsorship) != -1 ? this.binding.getValue(LoyaltyRulesKeys.MaxMemberSponsorship) + "" : ""}
                    onChangeText={(text: string) => { this.binding.handleValueChanged(LoyaltyRulesKeys.MaxMemberSponsorship, text) }} />
                </View>
                {this.state.translations.filter(t => t.loyaltyRulesKeyId == LoyaltyRulesKeys.MaxMemberSponsorship).length > 0 &&
                  (
                    <View style={this.style.editTexts}>
                      <TranslationView style={this.style.inputTitle} upperCase>form.edittexts</TranslationView>
                      <View style={this.style.loyaltyTranslationButtons}>
                        {this.state.translations.filter(t => t.loyaltyRulesKeyId == LoyaltyRulesKeys.MaxMemberSponsorship).map((t: LoyaltyRulesTranslation, key: number) =>
                        {
                          return (<ButtonView isLoading={false} style={{ marginRight: 10 }} backgroundColor={Constants.GradiantStartColor} handleClick={() => this.binding.editLoyaltyRuleTranslation(t)} key={key}><TranslationView>{t.label}</TranslationView></ButtonView>);
                        })}
                      </View>
                    </View>
                  )}
              </View>

              {/* Line */}
              <View style={{ backgroundColor: '#D8D8D8', width: '100%', height: 1, marginTop: 30, marginBottom: 30 }}></View>

              <View style={{ flexDirection: "column" }}>
                <TranslationView style={this.style.subheading} upperCase>form.pointstransfer</TranslationView>
                <View style={{ paddingBottom: 25 }}>
                  <TranslationView style={this.style.inputTitle} upperCase>form.maxpointstransfer</TranslationView>
                  <TextInput keyboardType={'numeric'} style={this.state.showErrors && this.binding.getValue(LoyaltyRulesKeys.MaxPointsPerTransfer) == -1 ? this.style.errorTextField : this.style.input}
                    value={this.binding.getValue(LoyaltyRulesKeys.MaxPointsPerTransfer) != -1 ? this.binding.getValue(LoyaltyRulesKeys.MaxPointsPerTransfer) + "" : ""}
                    onChangeText={(text: string) => { this.binding.handleValueChanged(LoyaltyRulesKeys.MaxPointsPerTransfer, text) }} />
                </View>
                <View style={{ paddingBottom: 25 }}>
                  <TranslationView style={this.style.inputTitle} upperCase>form.minpurchasesfortransfer</TranslationView>
                  <TextInput keyboardType={'numeric'} style={this.state.showErrors && this.binding.getValue(LoyaltyRulesKeys.MinPurchaseForTransfer) == -1 ? this.style.errorTextField : this.style.input}
                    value={this.binding.getValue(LoyaltyRulesKeys.MinPurchaseForTransfer) != -1 ? this.binding.getValue(LoyaltyRulesKeys.MinPurchaseForTransfer) + "" : ""}
                    onChangeText={(text: string) => { this.binding.handleValueChanged(LoyaltyRulesKeys.MinPurchaseForTransfer, text) }} />
                </View>

                <View style={{ paddingBottom: 25 }}>
                  <TranslationView style={this.style.inputTitle} upperCase>form.transfersperiod</TranslationView>
                  <TextInput keyboardType={'numeric'} style={this.state.showErrors && this.binding.getValue(LoyaltyRulesKeys.PointsTransfersPeriod) == -1 ? this.style.errorTextField : this.style.input}
                    value={this.binding.getValue(LoyaltyRulesKeys.PointsTransfersPeriod) != -1 ? this.binding.getValue(LoyaltyRulesKeys.PointsTransfersPeriod) + "" : ""}
                    onChangeText={(text: string) => { this.binding.handleValueChanged(LoyaltyRulesKeys.PointsTransfersPeriod, text) }} />
                </View>

                <View style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginBottom: 10 }}>
                  <TranslationView style={[this.style.inputTitle, { marginBottom: 0 }]} upperCase>form.maxtransfersperiod</TranslationView>
                  <DropDown
                    values={this.binding.getUnits(LoyaltyRulesKeys.MaxTransfersPerPeriod)}
                    selectedValue={this.binding.getUnits(LoyaltyRulesKeys.MaxTransfersPerPeriod).find((element: any) => { return element.id == this.binding.getUnit(LoyaltyRulesKeys.MaxTransfersPerPeriod)?.id || 0 })}
                    onValueChange={(value: any) => { this.binding.handleUnitChanged(LoyaltyRulesKeys.MaxTransfersPerPeriod, value.id) }} />
                </View>
                <SliderView minValue={this.binding.getUnit(LoyaltyRulesKeys.MaxTransfersPerPeriod)?.minValue || 0} maxValue={this.binding.getUnit(LoyaltyRulesKeys.MaxTransfersPerPeriod)?.maxValue || 0} currentValue={this.binding.getValue(LoyaltyRulesKeys.MaxTransfersPerPeriod)} handleChange={(value: number) => { this.binding.handleValueChanged(LoyaltyRulesKeys.MaxTransfersPerPeriod, value) }} backgroundColor={Constants.GradiantStartColor} />
              </View>
            </View>)
              : (<LottieView height={150} width={150} source={require('../../../../../../../Common' + Constants.LottieAnimationPath)} />)}
          </View>
        </View>
      </View>
    );
  }
}