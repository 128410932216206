 
import {ContentView} from '@levelapp/softfabric';
import AdditionalInformationsListViewState from './AdditionalInformationsListViewState';
import AdditionalInformationsListViewProps from './AdditionalInformationsListViewProps';
import AdditionalInformationsListViewStyle from './AdditionalInformationsListViewStyle';
import AdditionalInformationsListViewModel from './AdditionalInformationsListViewModel';
import {TranslationView} from '@levelapp/softfabric-ui';
import ExtendedTranslationView from '../../../../../../../../../../../../../Presentation/modules/dashboard/components/extendedtranslation/ExtendedTranslationView';
import { Text, View } from 'react-native';

export default class AdditionalInformationsListView extends ContentView<AdditionalInformationsListViewProps, AdditionalInformationsListViewState, AdditionalInformationsListViewModel, AdditionalInformationsListViewStyle>
{
  /* CONSTRUCTOR */
  constructor(props:AdditionalInformationsListViewProps)
  {
    super(props);

    // Binding
    this.bind(new AdditionalInformationsListViewModel(this), new AdditionalInformationsListViewStyle(this));
  }

  /* RENDERING */
  render()
  {

      return (
        <View style={[this.props.style]}>
          {this.props.title != '' && this.props.title != undefined && this.props.value && this.props.value.length > 0 ? (
          <>
          <ExtendedTranslationView styleText={this.props.styleTitle} uppercase={this.props.uppercase != undefined ? this.props.uppercase : true} afterTitle={this.props.extraTitle} infoText={this.props.infoText}>
            {this.props.title}
          </ExtendedTranslationView>
          {this.props.value.map((data : any) => {
            return(
            <View style={{flexDirection : "row"}}>
            {data.value != null ? (
              <>
                <Text style={{ marginBottom: 3, fontWeight: 'bold' }}>{data.userMetadataName}  </Text><Text>{data.value}</Text>
              </>
            )
            :
            (
              <View style={{flexDirection: "row"}}>
                <Text style={{ marginBottom: 3, fontWeight: 'bold' }}>{data.userMetadataName}  </Text>
                <View style={{flexDirection: "column", marginBottom: 3}}>
                  {data.userMetadataValues.map((info : any) => {
                    return(
                      <Text style={{marginBottom: 3}}>• {info}</Text>
                    )
                  })}
                </View>
              </View>
            )}      
            </View>
            )
          })}
          </>
          
          )
          :
          (
            <>
            <ExtendedTranslationView styleText={this.props.styleTitle} uppercase={this.props.uppercase != undefined ? this.props.uppercase : true} afterTitle={this.props.extraTitle} infoText={this.props.infoText}>
                {this.props.title}
              </ExtendedTranslationView>
              <View style={{flexDirection: "row"}}>
                <Text>{this.props.user.firstName} {this.props.user.lastName}</Text>
                <TranslationView style={{paddingBottom: "5%"}}>
                  {"form.noadvancedprofile"}
                </TranslationView>
              </View>
            </>
          )}
        </View>
    );
  }
}