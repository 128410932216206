import {ContentViewModel,ContentView} from '@levelapp/softfabric';
import ExtendedDropDownViewState from './ExtendedDropDownViewState';
import ExtendedDropDownViewProps from './ExtendedDropDownViewProps';

export default class ExtendedDropDownViewModel extends ContentViewModel<ExtendedDropDownViewState, ExtendedDropDownViewProps>
{
      /* CONSTRUCTOR */
    constructor(view: ContentView)
    {
        super(view);

        this.initialState({}); // Initialize your state here

        // Bindings
        this.getValues = this.getValues.bind(this);
    }

    getValues() {
        const { values, selectedValue } = this.props();

        return values.reduce((val,current) => { 
            if(current.title === undefined && (selectedValue !== undefined && selectedValue !== null && selectedValue.title !== undefined))
            {
                return val;
            }
            val.push(current);
            return val;
        },[])
    }
}