import { ContentViewModel, ContentView } from '@levelapp/softfabric';

import RemoveProductGroupPopUpViewState from './RemoveProductGroupPopUpViewState';
import RemoveProductGroupPopUpViewProps from './RemoveProductGroupPopUpViewProps';

export default class RemoveProductGroupPopUpViewModel extends ContentViewModel<RemoveProductGroupPopUpViewState, RemoveProductGroupPopUpViewProps>
{
  /* CONSTRUCTOR */
  constructor(view: ContentView) {
    super(view);

    this.initialState({ isDeleting: false });

    // Bindings
    this.onPopupClose = this.onPopupClose.bind(this);
    this.onDelete = this.onDelete.bind(this);
  }

  onPopupClose() {
    this.closePopUp();
  }

  async onDelete(id: number) {
    this.setField({isDeleting: true});
    await this.props().onRemove(id);
    this.setField({isDeleting: false});
  }
}