import { Service, Application, HttpManager, Managers } from '@levelapp/softfabric';


export default class PurchaseService extends Service
{
    httpClient = Application.current.resolve<HttpManager>(Managers.HTTP_MANAGER);

    async cancel(ticketId: number, onSuccess: Function)
    {
        this.httpClient
            .post(`purchase/cancel/${ticketId}`, [], {})
            .then((response: any) =>
            {
                if (response)
                {
                    if (response.data)
                    {
                        onSuccess(response.data);
                    }
                }
                else
                {
                    alert("Service not avaible")
                }
            });
    }
}