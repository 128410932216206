import {Application, ContentViewStyle} from '@levelapp/softfabric';
import { StyleManager, UiManagers } from '@levelapp/softfabric-ui';
import {ImageStyle, StyleProp,TextStyle,ViewStyle} from 'react-native';

export default class CrossSellConfigPopUpViewStyle extends ContentViewStyle
{
    MyStyleManager : StyleManager = Application.current.resolve<StyleManager>(UiManagers.STYLE_MANAGER);

    boxContainer: StyleProp<ViewStyle> = {
        display: 'flex',
        alignSelf: 'center',
        minHeight: 250,
        width: '100%',
        paddingTop: 30,
        paddingBottom: 30,
        paddingLeft: 50,
        paddingRight: 50,
    }
    boxContainerButton: StyleProp<ViewStyle> = {
        // display: 'flex',
        // alignSelf: 'center',
        flexDirection: 'row',
        justifyContent:'center',
        // minHeight: 250,
        width: '100%',
        
    }
    boxContainerText: StyleProp<ViewStyle> = {
        // display: 'flex',
        // alignSelf: 'center',
        flexDirection: 'row',
        justifyContent:'center',
        // minHeight: 250,
        width: '100%',
        // paddingTop: 30,
        paddingBottom: 20,
        // paddingLeft: 50,
        // paddingRight: 50,
    }

    tableHeader : StyleProp<TextStyle> =
    {
        color: '#575757',
        fontSize : 15,
        fontFamily : 'Montserrat',
        fontWeight : 'bold',
    }
    rowStyle: StyleProp<ViewStyle> =
    {
        minHeight: 170,
    }
    stepColor : StyleProp<ViewStyle> =
    {
        backgroundColor : this.MyStyleManager.primaryColor,
    }
    styleButton: StyleProp<ViewStyle> =
    {       
        height: 42,
        width: 119,
        marginLeft : '10px'
    } 


    title: StyleProp<TextStyle> = {
        textAlign: 'left',
        color: 'white',
        fontSize: 24,
        fontWeight: '600',
        marginTop: 20,
        marginLeft: 60,
        paddingBottom: 20,
        display: "flex", 
        flexDirection: "column", 
        flexBasis: "85%"
    }
    image: StyleProp<ImageStyle> = {
        height: 23, 
        width: 23, 
        resizeMode: 'contain', 
        marginTop: 25, 
        marginLeft: 20
    }
    inputTitle: StyleProp<TextStyle> = {
        fontSize: 14,
        fontWeight: '600',
        color: '#3F3F3F',
        fontFamily : 'Montserrat SemiBold',
        marginBottom : 13,
    };
    input: StyleProp<any> = {
        width: 240,//140
        paddingHorizontal: 15,
        borderRadius : 8,
        boxShadow: '0 5px 14px 0 rgba(0,0,0,0.1)',
    };
    errorTextField: StyleProp<ViewStyle> = {
        borderColor: 'red',
        borderWidth: 1,
        width: 240,
        paddingHorizontal: 15,
        borderRadius : 8,
    };
    section: StyleProp<ViewStyle> = {
        
        marginHorizontal: 15,
        marginBottom : 15
    };

    line : StyleProp<ViewStyle> =
    {
         display: "flex", 
         flexDirection: "row", 
         justifyContent: 'space-between'
    }

    saveButton: StyleProp<TextStyle> = {
        color: this.MyStyleManager.primaryColor,
        fontSize: 10,
        fontWeight: '600',
        height: 27,
        width: 112
    };


}