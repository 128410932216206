 
import {ContentView} from '@levelapp/softfabric';
import MessageBlockViewState from './MessageBlockViewState';
import MessageBlockViewProps from './MessageBlockViewProps';
import MessageBlockViewStyle from './MessageBlockViewStyle';
import MessageBlockViewModel from './MessageBlockViewModel';
import {TranslationView} from '@levelapp/softfabric-ui';
import { View, Text, TextInput } from 'react-native';

export default class MessageBlockView extends ContentView<MessageBlockViewProps, MessageBlockViewState, MessageBlockViewModel, MessageBlockViewStyle>
{
  /* CONSTRUCTOR */
  constructor(props:MessageBlockViewProps)
  {
    super(props);

    // Binding
    this.bind(new MessageBlockViewModel(this), new MessageBlockViewStyle(this));
  }

  /* RENDERING */
  render()
  {

    const { inputTitle , errorInput , input , errorTextArea , textArea , container} = this.style;
    //style={{ marginBottom: 20 }}
    return (
      <View style={container}>
        <Text style={inputTitle}>
          {this.props.title != undefined && <TranslationView upperCase>{this.props.title}</TranslationView> } ({this.props.titleExtra != undefined && this.props.titleExtra.toUpperCase()})
        </Text>
        <TextInput
          defaultValue={this.props.messageTitle}
          style={this.props.shouldShowErrorFirstInput ? errorInput : input}
          multiline={false}
          onChangeText={(text: string) => this.props.handleChangeTextFirstInput(text)}
          placeholder={this.props.placeholderFirstInput}/>
        <TextInput
          defaultValue={this.props.message}
          style={this.props.shouldShowErrorSecondInput ? errorTextArea : textArea}
          multiline={true}
          onChangeText={(text: string) => this.props.handleChangeTextSecondInput(text)}
          placeholder={this.props.placeholderSecondInput}
        />
      </View>
    );
  }
}