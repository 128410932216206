import {ContentViewModel,ContentView, StorageManager, Application, Managers} from '@levelapp/softfabric';
import SettingsPopupViewState from './SettingsPopupViewState';
import SettingsPopupViewProps from './SettingsPopupViewProps';
import StorageConstants from '../../../../../../../Common/Constants/StorageConstants';
import ToastHelper from '../../../../../../../Common/Helpers/ToastHelper';
import Constants from 'src/Common/Constants/Constants';

export default class SettingsPopupViewModel extends ContentViewModel<SettingsPopupViewState, SettingsPopupViewProps>
{
    private _storageManager : StorageManager;

      /* CONSTRUCTOR */
    constructor(view: ContentView)
    {
        super(view);

        const { isKitchenModusEnabled, isLoadingExport, showNewestOrdersFirst, hideContent, store } = this.props();
        const { isKioskTemporaryDisabled, isClickAndCollectTemporaryDisabled } = store;
        
        this.initialState({
            isKitchenModusEnabled,
            isLoadingExport,
            showNewestOrdersFirst,
            hideContent,
            isKioskTemporaryDisabled,
            isClickAndCollectTemporaryDisabled,
            changeIp : false,
            isRingingLongerActivated: false,
            ip: ''
        
        }); // Initialize your state here

        this._storageManager = Application.current.resolve<StorageManager>(Managers.STORAGE_MANAGER);

        // Bindings
        this.updateKitchenModus = this.updateKitchenModus.bind(this);
        this.updateShowNewestOrdersFirst = this.updateShowNewestOrdersFirst.bind(this);
        this.updateHideContent = this.updateHideContent.bind(this);
        this.updateTemporary = this.updateTemporary.bind(this);
        this.storeIp = this.storeIp.bind(this);
        this.fetchIp = this.fetchIp.bind(this);
        this.updateRingingStatus = this.updateRingingStatus.bind(this);
        this.fetchRingingStatus = this.fetchRingingStatus.bind(this);
        this.hasClickAndCollect = this.hasClickAndCollect.bind(this);
        this.hasPrinters = this.hasPrinters.bind(this);
    }

    componentDidMount()
    {
        this.fetchRingingStatus()
        this.fetchIp();
    }


    updateKitchenModus()
    {
        this.setField({ isKitchenModusEnabled: !this.state().isKitchenModusEnabled },() =>  this.props().toggleKitchenModus());
    }

    updateHideContent()
    {
      localStorage.setItem('hideContent', !this.state().hideContent + '');
      this.setField({ hideContent: !this.state().hideContent },() =>  this.props().toggleHideContent());
    }

    updateShowNewestOrdersFirst()
    {
        localStorage.setItem('showNewestOrdersFirst', !this.state().showNewestOrdersFirst + '');
        this.setField({ showNewestOrdersFirst: !this.state().showNewestOrdersFirst },() =>  this.props().toggleshowNewestOrdersFirst());
    }

    updateTemporary(type : 'kiosk' | 'cc')
    {
        switch(type)
        {
            case 'kiosk':
                this.setField({isKioskTemporaryDisabled : !this.state().isKioskTemporaryDisabled},() =>{this.props().updateTemporary(type, this.state().isKioskTemporaryDisabled)});
                break;
            case 'cc':
                this.setField({isClickAndCollectTemporaryDisabled : !this.state().isClickAndCollectTemporaryDisabled},() =>{this.props().updateTemporary(type, this.state().isClickAndCollectTemporaryDisabled)});
                break;
            
        }
    }

    fetchRingingStatus()
    {
        this._storageManager.fetch<boolean>(StorageConstants.IS_RINGING_LONGER_ACTIVATED)
            .then((isRingingLongerActivated) =>
            {
                if (isRingingLongerActivated !== undefined)
                {
                    this.setField({ isRingingLongerActivated: isRingingLongerActivated });
                }
            });
    }

    updateRingingStatus(status : boolean)
    {
        this._storageManager.store(StorageConstants.IS_RINGING_LONGER_ACTIVATED, status);
        this.setField({isRingingLongerActivated : status});
        alert("Reload the page to update the ringing settings");
    }

    fetchIp()
    {
        this._storageManager.fetch<string>(StorageConstants.IP_PRINTER)
            .then((ip) =>
            {
                if (ip)
                {
                    this.setField({ ip: ip });
                }
            });
    }

    storeIp()
    {
        this._storageManager.store(StorageConstants.IP_PRINTER, this.state().ip);

        this.showToast(ToastHelper.getToast("kitchenapp.settings.popup.ip.success.updated","success"),() => {},2500);
        this.setField({changeIp : false});
    }

    handleIpChanged(ip: string)
    {
        this.setField({ ip: ip });
    }

    handleEditIp()
    {
        this.setField({changeIp : true});
    }

    hasClickAndCollect() {
        return Constants.HasClickAndCollect;
    }

    hasPrinters() {
        return Constants.hasPrinters;
    }
}