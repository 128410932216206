 
import DashboardshellViewState from './DashboardshellViewState';
import DashboardshellViewProps from './DashboardshellViewProps';
import { ContentView, Application, Managers, NavigationManager, ContentViewModel } from '@levelapp/softfabric';
import Constants from '../../../Common/Constants/Constants';
import RoleManager from '../../../Common/Managers/RoleManager';
import ExtendedManagers from '../../../Common/Managers/ExtendedManagers';
import PermissionOperation from '../../../Transfer/Identity/PermissionOperations';
import UsersOperations from '../../../Transfer/UsersOperations';
import UserClaims from '../../../Common/DTO/UserClaims';
import DashboardHelper from '../../../Common/Helpers/DashboardHelper';
import AdminProductOperations from 'src/Transfer/AdminProductOperations';
import DeliverySettingsPopupView from './modules/kitchenapp/components/deliverysettingspopup/DeliverySettingsPopupView';

export default class DashboardshellViewModel extends ContentViewModel<DashboardshellViewState, DashboardshellViewProps>
{
  _isFullScreen: boolean;

  permissionOperation: PermissionOperation;
  adminProductOperation: AdminProductOperations;
  userOperation: UsersOperations;

  _navigationManager: NavigationManager;
  _rolerManager: RoleManager;

  constructor(props: ContentView) {
    super(props);

    this.initialState(
      {
        activeMenu: Constants.ShouldDisplayMenu,
        activeMenuProfile: false,
        userClaims: null,
      })
    this._isFullScreen = false;
    /* BINDING */
    this.getHeaderTitle = this.getHeaderTitle.bind(this);
    this.handleGetProfile = this.handleGetProfile.bind(this);
    this.menuAnimation = this.menuAnimation.bind(this);
    this.clickSettings = this.clickSettings.bind(this);
    this.clickSettings = this.clickSettings.bind(this);
    this.showDeliveryPopUp = this.showDeliveryPopUp.bind(this);
    this.showDeliveryOptions = this.showDeliveryOptions.bind(this);
    // this.handleGetConfig = this.handleGetConfig.bind(this);

    /* OPERATIONS */
    this.permissionOperation = new PermissionOperation();
    this.adminProductOperation = new AdminProductOperations();
    this.userOperation = new UsersOperations();

    /* MANAGERS */
    this._rolerManager = Application.current.resolve<RoleManager>(ExtendedManagers.ROLE_MANAGER);
    this._navigationManager = Application.current.resolve<NavigationManager>(Managers.NAVIGATION_MANAGER);

    var string = localStorage.getItem("Permissions");
    if (string != null) {
      this._rolerManager.setPermissions(JSON.parse(string as string) as any);
    }
  }

  componentDidMount() {
    var isLogged = localStorage.getItem("isLogged");
    if (isLogged !== "true") {
      this._navigationManager.navigate('/login');
    }

    this.userOperation.getProfile(this.handleGetProfile);

  }

  /**
   * Hide menu for kitchen & orders
   */
  componentDidUpdate() {
    if (this._navigationManager.route !== "/") {
      let routes = this._navigationManager.route.split('/');

      let route = routes[routes.length - 1];
      if (route == 'kitchenapp' || route == 'orders') {
        this.menuAnimation();
      }
    }
  }


  handleGetProfile(data: UserClaims) {
    this.setField({ userClaims: data });
  }

  menuAnimation() {
    this.setField({ activeMenu: !this.state().activeMenu });
  }

  fullScreen() {
    if (this._isFullScreen) {
      this.exitFullscreen();
    } else {
      this.launchIntoFullscreen(document.documentElement);
    }
  }

  private launchIntoFullscreen(element: any) {

    if (element.requestFullscreen) {
      element.requestFullscreen();
      this._isFullScreen = true;
    } else if (element.mozRequestFullScreen) {
      element.mozRequestFullScreen();
      this._isFullScreen = true;
    } else if (element.webkitRequestFullscreen) {
      element.webkitRequestFullscreen();
      this._isFullScreen = true;
    } else if (element.msRequestFullscreen) {
      element.msRequestFullscreen();
      this._isFullScreen = true;
    }

  }

  private exitFullscreen() {
    if (document.exitFullscreen) {
      document.exitFullscreen();
      this._isFullScreen = false;
    } else if ((document as any).mozCancelFullScreen) {
      (document as any).mozCancelFullScreen();
      this._isFullScreen = false;
    } else if ((document as any).webkitExitFullscreen) {
      (document as any).webkitExitFullscreen();
      this._isFullScreen = false;
    }
  }

  menuProfileAnimation() {
    this.setField({ activeMenuProfile: !this.state().activeMenuProfile });
  }

  getImage() {
    if (document.fullscreenEnabled && document.fullscreenElement != null) {
      return require('../../../Common/Assets/minimize.svg').default;
    } else {
      return require('../../../Common/Assets/maximize.svg').default;
    }
  }


  /* METHODS */
  getHeaderTitle() {
    // Take the path 
    // Remove / and take to last of the array 
    // To display the name of the current page.

    // Feel Free to delete that if you want to use something else.
    if (this._navigationManager.route !== "/") {
      let routes = this._navigationManager.route.split('/');

      let route = routes[routes.length - 1];
      if (route.search("rules") > 0) {
        return "Edit your Loyalty rules";
      }
      else {
        return route[0].toUpperCase() + route.slice(1, route.length);
      }
    }
    return;
  }

  showPrinterIcon() {
    return this._navigationManager.route.includes("kitchenapp");
  }
  showDeliveryOptions() {
    return Constants.HasDeliveries;
  }



  clickSettings() {
    DashboardHelper.clickSettings();
  }

  showDeliveryPopUp() {
    this.showPopUp(<DeliverySettingsPopupView />);
  }
}