import { Service, Application, HttpManager, Managers } from '@levelapp/softfabric';


export default class LanguagesService extends Service
{
    httpClient = Application.current.resolve<HttpManager>(Managers.HTTP_MANAGER);

    async get(onSuccess: Function, onFailure : Function = () => {})
    {       
        this.httpClient.handleSomethingWentWrong = onFailure;
        this.httpClient.handleNotFound = onFailure;

        this.httpClient
        .get('languages', [])
        .then((response : any) => {
            if (response)
            {
                if (response.data)
                {                  
                    onSuccess(response.data);
                }
            }
        });
    }
    post(language:any, callback : Function = () => {}, onFailure : Function = () => {})
    {
        this.httpClient.handleSomethingWentWrong = onFailure;
        this.httpClient.handleNotFound = onFailure;

        this.httpClient
            .post('languages', [], language)
            .then((response : any) => 
            {
                if (response)
                {
                    /*if (response.data)
                    {
                        callback(response.data);
                    }*/
                    callback("");
                }
            });
    }

    put(language:any, callback : Function = () => {}, onFailure : Function = () => {})
    {
        this.httpClient.handleSomethingWentWrong = onFailure;
        this.httpClient.handleNotFound = onFailure;

        this.httpClient
            .put('languages', [], language)
            .then((response : any) => 
            {
                if (response)
                {
                    /*if (response.data)
                    {
                        callback(response.data);
                    }*/
                    callback("");
                }
            });
    }
    delete(language:any, callback : Function = () => {}, onFailure : Function = () => {})
    {
        this.httpClient.handleSomethingWentWrong = onFailure;
        this.httpClient.handleNotFound = onFailure;

        this.httpClient
            .delete('languages/DeleteLanguage/'+language.id)
            .then((response : any) => 
            {
                if (response)
                {
                     /*if (response.data)
                    {
                        callback(response.data);
                    }*/
                    callback("");
                }
            });
    }
}