import {ContentViewStyle} from '@levelapp/softfabric';
import {ImageStyle, StyleProp,TextStyle,ViewStyle} from 'react-native';

export default class PopupMenuZoneViewStyle extends ContentViewStyle
{
  popup: StyleProp<ViewStyle> = {
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: 'white',
    width: 200,
    height: 100,
    paddingHorizontal: 15,
    borderRadius: 10,

    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 4,
    },
    shadowOpacity: 0.30,
    shadowRadius: 4.65,
    
    elevation: 8,    
  }

  popupItem: StyleProp<ViewStyle> = {
    display: 'flex',
    width: '100%',
    minHeight: 25,
  }

  image: StyleProp<ImageStyle> = {
    width: '100%',
    height: 15,
    marginRight: 25,
    alignSelf: 'center',
  }

  text: StyleProp<TextStyle> = {
    fontSize: 18,
  }
}