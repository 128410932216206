//  
import { ContentView } from "@levelapp/softfabric";
import OrderFiltersPopupViewState from './OrderFiltersPopupViewState';
import OrderFiltersPopupViewProps from './OrderFiltersPopupViewProps';
import OrderFiltersPopupViewStyle from './OrderFiltersPopupViewStyle';
import OrderFiltersPopupViewModel from './OrderFiltersPopupViewModel';
import { TouchableWithoutFeedback, View,Text, Image, Animated } from 'react-native';
import { FilterType } from "../../enum/FilterType";
import { TranslationView } from "@levelapp/softfabric-ui";


export default class OrderFiltersPopupView extends ContentView<OrderFiltersPopupViewProps, OrderFiltersPopupViewState, OrderFiltersPopupViewModel, OrderFiltersPopupViewStyle>
{
  /* CONSTRUCTOR */
  constructor(props: OrderFiltersPopupViewProps)
  {
    super(props);

    // Binding
    this.bind(new OrderFiltersPopupViewModel(this), new OrderFiltersPopupViewStyle(this));
  }


  renderAllOption(items: any[], type: string): JSX.Element {
    const isActive = this.props.filters[type].length === items.length;
    const itemStyle = isActive ? this.style.filterItemActive: this.style.filterItem;

    return (
      <View style={[itemStyle, this.style.filterItemSpace]} >
        <TouchableWithoutFeedback onPress={()=>this.binding.setFilterAllOption(type)}>
            <View>
              <Text style={[isActive ? this.style.filterItemActiveLabel:{}]}>
                All
              </Text>
            </View>
        </TouchableWithoutFeedback>
      </View>
    );
  }

  renderFilterItem(item: any, type: string) : JSX.Element{
    const isActive = this.props.filters[type].includes(item.id);
    const itemStyle = isActive ? this.style.filterItemActive: this.style.filterItem;
    return (
      <View key={item.id}>
        <TouchableWithoutFeedback onPress={()=> this.binding.setFilterSelection(type, item.id)}>
            <View style={[itemStyle, this.style.filterItemSpace]}>
              <Text style={[isActive ? this.style.filterItemActiveLabel:{}]}>
                {item.label}
              </Text>
            </View>
        </TouchableWithoutFeedback>
      </View>
    );
  }

  renderFilterItems(items: any[], type: string): JSX.Element {
    if(items === undefined || items.length === 0) return (<View></View>);
    const translationKey = `kitchenapp.filters.${type}`;
    return ( 
      <View style={this.style.filterContainer}>
          <View>
            <TranslationView style={this.style.filterTitle}>{translationKey}</TranslationView>
          </View>
          <View style={this.style.filterItemContainer}>
            {items.map(item=> this.renderFilterItem(item, type))}
            {this.renderAllOption(items, type)}
          </View>
        </View>
    );
  }


  renderPopup(): JSX.Element {
    const display = this.state.isOpen ? 'block': 'none';
    return (
      <Animated.View style={[this.style.popup, {display},{opacity: this.binding.animation}]} >
        <View>
          <TouchableWithoutFeedback onPress={() => this.binding.toggle()}>
            <View style={this.style.popupHeader}>
              <Text style={this.style.panelText}>Filter</Text>
              <Image style={this.style.panelIcon} source={require('../../assets/DropUp.svg').default} />
            </View>  
          </TouchableWithoutFeedback>
        </View>
        {this.renderCount()}
        {this.renderFilterItems(this.state.filters.brands, FilterType.BRANDS)}
        {this.renderFilterItems(this.state.filters.types, FilterType.TYPES)}
        {this.renderFilterItems(this.state.filters.sources, FilterType.SOURCES)}
        {this.renderFilterItems(this.state.filters.status, FilterType.STATUS)}
      </Animated.View>
    );
  }

  renderSelectionItem(item: {id: number, type:string, label:string}): JSX.Element {
    return (
      <View key={`${item.type}-${item.id}`}>
        <TouchableWithoutFeedback onPress={()=> this.binding.setFilterSelection(item.type, item.id)}>
          <View style={this.style.selectionItem}>
            <Text style={this.style.selectionItemText}>{item.label}</Text>
            <Image style={this.style.selectionIcon} source={require('../../assets/clear.svg').default}></Image>
          </View>
        </TouchableWithoutFeedback>
      </View>
    )
  }

  renderRemainingSelection(number: number): JSX.Element {
    if(number < 1) return (<View></View>);  
    return (
      <View>
          <View style={this.style.selectionItem}>
            <Text style={this.style.selectionItemText}>+ {number}</Text>
          </View>
      </View>
    )
  }

  renderSelection(): JSX.Element {
    const maxItems = 3;
    const items = this.binding.getFilterOptions();
    const showableItems = items.length < maxItems ? items: items.slice(0, 3);

    return (
      <View style={this.style.selectionItems}>
        {showableItems.map(item=>this.renderSelectionItem(item))}
        {this.renderRemainingSelection(items.length - maxItems)}
      </View>

    );
  }

  renderCount() {
    let text = this.binding.translate("kitchenapp.filter.count")
    .replace("{count}", `${this.props.count.filtered}`)
    .replace("{total}", `${this.props.count.total}`);

    if(this.props.count.filtered < 2)
      text = text.replace("orders", "order");

    return (
      <Text>
        {text}
      </Text>
    );
  }

  render(): JSX.Element {
    return (
      <div className="kitchenapp-filters">
        <View style={this.style.panelContainer}> 
            <TouchableWithoutFeedback onPress={() => this.binding.toggle()}>
              <View style={this.style.panelTitle}>
                <Text style={this.style.panelText}>Filter</Text>
              </View>
            </TouchableWithoutFeedback>


              {this.renderSelection()}

              <TouchableWithoutFeedback onPress={() => this.binding.toggle()}>
                <View style={this.style.panelIconContainer}>
                  <Image style={this.style.panelIcon} source={require('../../assets/DropDown.svg').default} />
                </View>
              </TouchableWithoutFeedback>
          
        </View>
        
        {this.renderPopup()}
   
    </div>
    );
  }
}