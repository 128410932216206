 
import {ContentShellView} from '@levelapp/softfabric';
import ShellCrosssellconfiguratorViewState from './ShellCrosssellconfiguratorViewState';
import ShellCrosssellconfiguratorViewProps from './ShellCrosssellconfiguratorViewProps';
import ShellCrosssellconfiguratorViewStyle from './ShellCrosssellconfiguratorViewStyle';
import ShellCrosssellconfiguratorViewModel from './ShellCrosssellconfiguratorViewModel';
import {View} from 'react-native';

export default class ShellCrosssellconfiguratorView extends ContentShellView<ShellCrosssellconfiguratorViewProps, ShellCrosssellconfiguratorViewState, ShellCrosssellconfiguratorViewModel, ShellCrosssellconfiguratorViewStyle>
{
  /* CONSTRUCTOR */
  constructor(props:ShellCrosssellconfiguratorViewProps)
  {
    super(props);

    // Binding
    this.bind(new ShellCrosssellconfiguratorViewModel(this), new ShellCrosssellconfiguratorViewStyle(this));
  }

  /* RENDERING */
  render()
  {
    return (
      <View style={{flex : 1}}>
        {this.getSwitch()}
      </View>
    );
  }
}