 
import {ContentShellView} from '@levelapp/softfabric';
import ShellOutofstockViewState from './ShellOutofstockViewState';
import ShellOutofstockViewProps from './ShellOutofstockViewProps';
import ShellOutofstockViewStyle from './ShellOutofstockViewStyle';
import ShellOutofstockViewModel from './ShellOutofstockViewModel';
import {View} from 'react-native';

export default class ShellOutofstockView extends ContentShellView<ShellOutofstockViewProps, ShellOutofstockViewState, ShellOutofstockViewModel, ShellOutofstockViewStyle>
{
  /* CONSTRUCTOR */
  constructor(props:ShellOutofstockViewProps)
  {
    super(props);

    // Binding
    this.bind(new ShellOutofstockViewModel(this), new ShellOutofstockViewStyle(this));
  }

  /* RENDERING */
  render()
  {
    return (
      <View style={{flex : 1}}>
        {this.getSwitch()}
      </View>
    );
  }
}