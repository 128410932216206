import {ContentViewModel,ContentView, Application, LanguageManager, Managers} from '@levelapp/softfabric';
import RolePermissionViewState from './RolePermissionViewState';
import RolePermissionViewProps from './RolePermissionViewProps';
import RoleOperations from '../../../../../../../Transfer/Identity/RoleOperations';
import { TableRow } from '@levelapp/softfabric-ui';
import Role from '../../../../../../../Common/DTO/Role';
import RolePermissionPopupView from '../rolepermissionpopup/RolePermissionPopupView';

 
import ToastHelper from '../../../../../../../Common/Helpers/ToastHelper';
import ConfirmPopUpView from '../../../../../../../Common/Components/confirmpopup/ConfirmPopUpView';


export default class RolePermissionViewModel extends ContentViewModel<RolePermissionViewState, RolePermissionViewProps>
{
    _languageManage : LanguageManager;

    interval: any;
    searchText: string;
    isSorting: boolean;
    sortOrder: string;
    sortKey: string[];
    indexHeaderSort: number;
    _languages: string[] = [];

    _roleOperations : RoleOperations;


    constructor(props: ContentView) {
        super(props);

        this.interval = null;
        this.searchText = '';
        this.isSorting = false;
        this.indexHeaderSort = -1;
        this.sortOrder = 'asc';
        this.sortKey = ['Id', 'Role'];


        this.initialState(
            {
                 tableData: [], 
                 tableHeaders: ['Id', 'Role'], 
                 isLoading: true, 
                 nbPage: 0, currentPage: 1, isResearching: false });

        //Binding
        this.getData = this.getData.bind(this);
        this.refresh = this.refresh.bind(this);
        this.addField = this.addField.bind(this);
        this.editField = this.editField.bind(this);
        this.handleGetInfo = this.handleGetInfo.bind(this);
        this.saveRolePermission = this.saveRolePermission.bind(this);


        // Managers
        this._languageManage = Application.current.resolve<LanguageManager>(Managers.LANGUAGE_MANAGER);
    
        // Operations
        this._roleOperations = new RoleOperations();
    }

    componentDidMount() {
        this.refresh();
    }

    refresh() {
        this.setField({ isLoading: true });
        this.getData();
    }

    getData() {
        this._roleOperations.getRoles(this.handleGetInfo)
    }

    handleGetInfo(roles : Role[])
    {
        let tableData: TableRow[] = [];
        roles.forEach((role: Role) => {
            tableData.push({ data: [role.id, role.name], isDeletable: true, isEditable: true, editCallback: () => { this.editField(role) }, deleteCallback: () => { this.deleteField(role) } });
        });
        this.setField({ tableData: tableData, roles: roles, isLoading: false });
    }

    addField() {
        //Doesn't add, just display the popup

        this.showPopUp(
            <RolePermissionPopupView
                saveRolePermission={this.saveRolePermission}
                role={
                {
                    name : '',
                    concurrencyStamp : '',
                    id : undefined,
                    normalizedName : '',
                    rolePermissions :[]
                }
                }/>
        );
    }

    editField(role: Role) {
        this.showPopUp(<RolePermissionPopupView role={role} saveRolePermission={this.saveRolePermission}/>);
        this.refresh();
    }

    deleteField(role: Role) {
        this.showPopUp(
            <ConfirmPopUpView callBack={() => {
                this._roleOperations.deleteRole(role.id as string, () => {
                    this.handleSuccess('rolepermissions.popup.success.deleted');
                    this.refresh();
                });
            }} />
        );
    }

    saveRolePermission(role: Role) {
        if (role.id !== undefined) {
            this._roleOperations.updateRole(role,()=> this.handleSuccess('rolepermissions.popup.success.updated'));
        }
        else {
            this._roleOperations.createRole(role,()=> this.handleSuccess('rolepermissions.popup.success.added'));
        }
    }

    handleSuccess(message : string)
    {
        this.refresh();
        this.showToast(ToastHelper.getToast(message,"success"),() => {},2500);
        this.closePopUp();
    }

    onPageChange(page: number) {
        this.state().currentPage = page + 1;
        this.refresh()
    }

    

    handleCloseResearching() {
        this.state().isResearching = false;
        this.onPageChange(0);
    }
}