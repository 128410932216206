import { ContentViewModel, ContentView } from '@levelapp/softfabric';
import DropdownContentViewState from './DropdownContentViewState';
import DropdownContentViewProps from './DropdownContentViewProps';

export default class DropdownContentViewModel extends ContentViewModel<DropdownContentViewState, DropdownContentViewProps>
{
  /* CONSTRUCTOR */
  constructor(view: ContentView) {
    super(view);

    this.initialState({}); 
    
    // Bindings
  }

}