import { ContentViewModel, ContentView } from '@levelapp/softfabric';

import TreeMenuViewState from './TreeMenuViewState';
import TreeMenuViewProps from './TreeMenuViewProps';

export default class TreeMenuViewModel extends ContentViewModel<TreeMenuViewState, TreeMenuViewProps>
{
  /* CONSTRUCTOR */
  constructor(view: ContentView) {
    super(view);

    this.initialState({ show: false });

    // Bindings
    this.toggleView = this.toggleView.bind(this);
  }

  toggleView = () => {
    this.setField({ show: !this.state().show })
  }
}