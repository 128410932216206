 
import {ContentView} from '@levelapp/softfabric';
import DescriptionViewState from './DescriptionViewState';
import DescriptionViewProps from './DescriptionViewProps';
import DescriptionViewStyle from './DescriptionViewStyle';
import DescriptionViewModel from './DescriptionViewModel';
import { View } from 'react-native';
import ExtendedBlockTextInputView from '../extendedblocktextinput/ExtendedBlockTextInputView';
import Language from '../../../../../Common/DTO/Language';
import Helpers from '../../../../../Common/Helpers/Helpers';
import SectionTitleView from '../sectiontitle/SectionTitleView';

export default class DescriptionView extends ContentView<DescriptionViewProps, DescriptionViewState, DescriptionViewModel, DescriptionViewStyle>
{
  /* CONSTRUCTOR */
  constructor(props:DescriptionViewProps)
  {
    super(props);

    // Binding
    this.bind(new DescriptionViewModel(this), new DescriptionViewStyle(this));
  }

  /* RENDERING */
  render()
  {
    const { container } = this.style

    return (
      <View style={{paddingHorizontal: 170, paddingVertical: 50 }}>
        <SectionTitleView title={'form.description'}/>
        <View style={container}>
          {/* Description */}
          {this.props.languages.map((content: Language) =>
          {
            var _text = Helpers.getTranslationValueByLanguageKey(this.props.listDesc,content.key);
            return (
              <ExtendedBlockTextInputView
                title={content.key.toUpperCase()}
                showError={this.props.shouldShowError}
                value={_text}
                onChangeText={(text : string) => this.props.handleTranslationsChange('description',text,content.id as number)}
              />
            );
          })}
        </View>
      </View>
      
    );
  }
}