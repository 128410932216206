import { ContentViewStyle } from '@levelapp/softfabric';
import { StyleProp, ViewStyle, TextStyle, ImageStyle } from 'react-native';

export default class TableViewStyle extends ContentViewStyle
{
    thRow: StyleProp<ViewStyle> = {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        paddingTop: 12,
        paddingBottom: 12,
        backgroundColor : '#F2F2F3',
        //borderTopLeftRadius : 5,
        //borderTopRightRadius : 5,
        paddingHorizontal : 15
    };

    maxHeight : StyleProp<any> =
    {
        maxHeight : 250,
        minHeight : 325,
        overflowY : 'auto'
    }

    tdRow: StyleProp<ViewStyle> = {
        display: 'flex',
        flex: 1,
        flexDirection: 'row',
        justifyContent: 'space-between',
        borderBottomWidth: 1,
        paddingVertical : 12,
        borderColor: '#e0e0e0',
        paddingHorizontal : 15
    };

    thText: StyleProp<TextStyle> = {
        color: '#575757',
        fontFamily: 'Montserrat',
        fontWeight: 'bold',
        fontSize : 15,
        lineHeight : 14
    };

    tdText: StyleProp<TextStyle> = {
        color: '#3f3f3f',
        fontWeight: '300',
        flexWrap : 'wrap',
    };

    showImage : StyleProp<ImageStyle> =
    {
        opacity : 1,
        marginLeft:5,
        alignSelf:'center',
        height: 12,
        width: 12 
    }

    notShowingImage : StyleProp<ImageStyle> =
    {
        opacity : 0,
    }

    containerResearch : StyleProp<ViewStyle> =
    {
        marginTop : 15,
        flexDirection : 'row',
        flex : 1,
        justifyContent : 'flex-start',
    }

    nothingToDisplay : StyleProp<ViewStyle> =
    {
        flex : 1,
        marginTop : 15,
        alignItems : 'center',
    }

    
}