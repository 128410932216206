import { CheckBox, View } from "react-native";
import { ContentView } from "@levelapp/softfabric";
import { LottieView } from "@levelapp/softfabric-ui";

import PaymentSourcesViewState from './PaymentSourcesViewState';
import PaymentSourcesViewProps from './PaymentSourcesViewProps';
import PaymentSourcesViewStyle from "./PaymentSourcesViewStyle";
import PaymentSourcesViewModel from "./PaymentSourcesViewModel";

import Constants from "src/Common/Constants/Constants";

export default class PaymentSourcesView extends ContentView<PaymentSourcesViewProps, PaymentSourcesViewState, PaymentSourcesViewModel, PaymentSourcesViewStyle>
{
  /* CONSTRUCTOR */
  constructor(props: PaymentSourcesViewProps)
  {
    super(props);

    // Binding
    this.bind(new PaymentSourcesViewModel(this), new PaymentSourcesViewStyle(this));
  }

  /* RENDERING */
  render()
  {

    const { container, th, td, checkbox } = this.style;
    return (
      <View style={container}>
        {this.props.isLoading ?
          <LottieView height={150} width={150} source={require('src/Common' + Constants.LottieAnimationPath)} />
          :
          <View>
            <table>
              {/* HEADER */}
              <tr>
                <th />
                {this.props.paymentSources.map((ps, i) =>
                  <th key={i} style={th}>{ps.key}</th>
                )}
              </tr>

              {/* CELLS */}
              {this.props.orderSources.map((os, i) =>
                <tr>
                  <th key={i} style={th}>{os.label}</th>
                  {this.props.paymentSources.map(({ id, key }) =>
                    <td key={key} style={td} align='center'>
                      <CheckBox style={checkbox} value={this.binding.isSelected(os.id, id)} onValueChange={(value) => this.props.handleValueChanged(value, os.id, id)} />
                    </td>
                  )}
                </tr>
              )}
            </table>
          </View>
        }
      </View>
    );
  }
}