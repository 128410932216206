import { Service, Application, HttpManager, Managers } from "@levelapp/softfabric";
import { MessageCampaign } from "../Common/DTO/MessageCampaign";
//import {MessageCampaign} from "../Common/DTO/MessageCampaign";

export default class MessageCampaignsService extends Service{

    httpClient = Application.current.resolve<HttpManager>(Managers.HTTP_MANAGER);

    get(onSuccess: Function){
        this.httpClient
        .get('messageCampaign/GetAll')
        .then((response : any) => {
            if (response)
            {
                if (response.data)
                {                 
                    onSuccess(response.data);
                }
            }
        });
    }

    getById(id: number,onSuccess: Function)
    {
        this.httpClient
        .get(`messageCampaign/?id=${id}`)
        .then((response : any) => {
            if (response)
            {
                if (response.data){
                    onSuccess(response.data)
                }
            }
        })
    }

    post(messageCampaign : MessageCampaign, callback : Function = () => {}, onFailure : Function = () => {})
    {
        this.httpClient.handleSomethingWentWrong = onFailure;
        this.httpClient.handleNotFound = onFailure;

        this.httpClient
            .post('messageCampaign',[],messageCampaign)
            .then((response : any) =>{
                if(response){
                    callback(response)
                }
            })
    }

    test(messageCampaignId : number, idToSend : string[], callback : Function, onFailure : Function)
    {
        this.httpClient.handleSomethingWentWrong = onFailure;
        this.httpClient.handleNotFound = onFailure;

        this.httpClient
            .post(`messageCampaign/Test/?messsageCampaignId=${messageCampaignId}`,[],idToSend)
            .then((response : any) =>{
                if(response){
                    callback(response)
                }
            })
    }

    start(messageCampaignId : number, callback : Function, onFailure : Function)
    {
        this.httpClient.handleSomethingWentWrong = onFailure;
        this.httpClient.handleNotFound = onFailure;
        this.httpClient
            .put(`messageCampaign/Start/?messageCampaignId=${messageCampaignId}`,[],[])
            .then((response : any) =>{
                if(response){
                    callback(response)
                }
            })
    }

    getDetails(messageCampaignId : number, callback : Function)
    {
        this.httpClient
        .get(`messageCampaign/GetDetails/?messageCampaignId=${messageCampaignId}`)
        .then((response : any) =>{
            if(response){
                callback(response)
            }
        })
    }

    getNbUserSegmentation(segmentation : any,type : "pn" | "email",callback : Function)
    {
        this.httpClient
        .put(`messageCampaign/GetNbUserSegmentation/?type=`+type,[],segmentation)
        .then((response : any) =>{
            if(response){
                callback(response)
            }
        })
    }

    getCardCodeListFromSegmentation(segmentation : any,type : "pn" | "email",callback : Function)
    {
        this.httpClient
        .put(`messageCampaign/GetCardCodeListFromSegmentation/?type=`+type,[],segmentation)
        .then((response : any) =>{
            if(response){
                callback(response)
            }
        })
    }

    delete(messageCampaignId : number, callback : Function, onFailure : Function)
    {
        this.httpClient.handleSomethingWentWrong = onFailure;
        this.httpClient.handleNotFound = onFailure;

        this.httpClient
        .delete(`messageCampaign/?messageCampaignId=${messageCampaignId}`)
        .then((response : any) => {
            if(response){
                callback(response)
            }
        })
    }

    updateMessageCampaign(messageCampaign : any,callback : Function,onFailure : Function)
    {
        this.httpClient.handleSomethingWentWrong = onFailure;
        this.httpClient.handleNotFound = onFailure;
        this.httpClient
        .put('messageCampaign/UpdateMessageCampaign',[],messageCampaign)
        .then((response :any) => {
            if(response){
                callback(response)
            }
        })
    }
}