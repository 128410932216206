import { ContentShellViewModel, ContentShellView } from '@levelapp/softfabric';
import ShellMenuconfiguratorViewState from './ShellMenuconfiguratorViewState';
import ShellMenuconfiguratorViewProps from './ShellMenuconfiguratorViewProps';
import EnumModules from '../../../../../Common/Constants/EnumModules';
import MenuconfiguratorView from './pages/menuconfigurator/MenuconfiguratorView';
 
import MenuConfiguratorV2 from './pages/menuconfigurator-v2/MenuConfiguratorV2';

export default class ShellMenuconfiguratorViewModel extends ContentShellViewModel<ShellMenuconfiguratorViewState, ShellMenuconfiguratorViewProps>
{

  constructor(view: ContentShellView) {
    super(view);

    this.registerEntryPoint('main');
    this.register(process.env.REACT_APP_ISMENUCONFIG_V2 === 'true' ? MenuConfiguratorV2 : <MenuconfiguratorView />, 'main');

    this.initialisation();
  }

  getModuleId() {
    return EnumModules.MENUCONFIGURATOR;
  }

}