 
import {ContentView} from '@levelapp/softfabric';
import ContentTypeKeyConfiguratorViewState from './ContentTypeKeyConfiguratorViewState';
import ContentTypeKeyConfiguratorViewProps from './ContentTypeKeyConfiguratorViewProps';
import ContentTypeKeyConfiguratorViewStyle from './ContentTypeKeyConfiguratorViewStyle';
import ContentTypeKeyConfiguratorViewModel from './ContentTypeKeyConfiguratorViewModel';
import { View,  } from 'react-native';
import HeaderView from '../../../../components/header/HeaderView';
import TranslationView from '@levelapp/softfabric-ui/components/Both/Translation/TranslationView';
import DropDownView from '../../../../../../../Common/Components/Dropdown/DropDownView';
import ExtendedTextInputView from '../../../../components/extendedtextinput/ExtendedTextInputView';
import ButtonView from '../../../../../../../Common/Components/Button/ButtonView';

import TableView from '../../../../../../../Common/Components/TabelView/TableView';


export default class ContentTypeKeyConfiguratorView extends ContentView<ContentTypeKeyConfiguratorViewProps, ContentTypeKeyConfiguratorViewState, ContentTypeKeyConfiguratorViewModel, ContentTypeKeyConfiguratorViewStyle>
{
  /* CONSTRUCTOR */
  constructor(props:ContentTypeKeyConfiguratorViewProps)
  {
    super(props);

    // Binding
    this.bind(new ContentTypeKeyConfiguratorViewModel(this), new ContentTypeKeyConfiguratorViewStyle(this));
  }

  /* RENDERING */
  render()
  {
    const{container, box, boxContainer,label,topContainer,buttonSaveKey} = this.style
    return (
      <View style={container}>
        <View style={box}>

          <HeaderView
            title={"contenttypekeyconfig.title"}
            description={"contenttypekeyconfig.description"}


            shouldShowButton={true}
            onButtonPress={this.binding.showAddNewKeySection}
            buttonText={'contenttypekeyconfig.add'}

          />

          {!this.state.isLoading && <View style={boxContainer}>
            <View >
              <View style={topContainer}>
                <TranslationView style ={label}>{'contenttypekeyconfig.dropdown.label'}</TranslationView>
                <DropDownView
                values={this.binding._contentTypes}
                selectedValue={this.state.currentContentDropdownValue}
                onValueChange={(value : any) => {this.binding.handleContentIdChange(value)}}/>
              </View>
              {this.state.currentTypeList.length > 0 && <View style={[topContainer,{marginLeft : 70}]}>
                <TranslationView style ={label}>{'contenttypekeyconfig.dropdown.label.type'}</TranslationView>
                <DropDownView
                  values={this.state.currentTypeList}
                  selectedValue={this.state.currentTypeDropdownValue}
                  onValueChange={(value : any) => {this.binding.handleTypeIdChange(value)}}/>
              </View>}

 
              
            </View>
              <TableView
              headerTextStyle={this.style.tableHeader}
              rowStyle={this.style.rowStyle}
              nothingToDisplayText={this.binding.translate("component.table.noresults")}
              isLastColumnActions={true}
              data={this.state.tableData}
              isLoading={this.state.isLoading}
              headers={this.state.tableHeaders}
              onPageChange={() => {}}
              handleSortChange={() => {}}
              nbPage={1}
              currentPage={1}
              isSortable={false}/>
            <View>

              {this.state.showAddNewKey && 
              <View>
                <ExtendedTextInputView value={this.state.newContentTypeKey} onChangeText={(text : string) => this.binding.handleInputChange(text)} showError={false}/>
                <View style={buttonSaveKey}>
                  <ButtonView handleClick={this.binding.handleButtonPressed} isLoading={this.state.buttonLoading}>
                    <TranslationView>{'contenttypekeyconfig.savekey'}</TranslationView>
                  </ButtonView>
                </View>
              </View>}
              </View>


          </View>}
          
        </View>
      </View>
    ); 
  }
}