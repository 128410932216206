 
import { View } from 'react-native';

import { ContentView } from '@levelapp/softfabric';
import { LottieView } from '@levelapp/softfabric-ui';

import AvailabilityTabViewState from './AvailabilityTabViewState';
import AvailabilityTabViewProps from './AvailabilityTabViewProps';
import AvailabilityTabViewStyle from './AvailabilityTabViewStyle';
import AvailabilityTabViewModel from './AvailabilityTabViewModel';
import HeaderView from '../header/HeaderView';
import LabelViewView from '../labelview/LabelViewView';
import CheckboxView from '../checkbox/CheckboxView';

import Constants from 'src/Common/Constants/Constants';
import NoDataPlaceholderView from '../nodataplaceholder/NoDataPlaceholderView';

export default class AvailabilityTabView extends ContentView<AvailabilityTabViewProps, AvailabilityTabViewState, AvailabilityTabViewModel, AvailabilityTabViewStyle>
{
  /* CONSTRUCTOR */
  constructor(props: AvailabilityTabViewProps) {
    super(props);

    // Binding
    this.bind(new AvailabilityTabViewModel(this), new AvailabilityTabViewStyle(this));
  }

  /* RENDERING */
  render() {
    const { orderSources, mappedAvailabilities, isDirty, currentSelectedProduct, storeGroups } = this.state;

    return (
      <View style={this.style.container}>
        <HeaderView
          buttonIsLoading={this.state.buttonIsLoading}
          disabled={isDirty || !!currentSelectedProduct?.isParent}
          onSaveClick={this.binding.updateAvailabilities}
          title="tools.products.tabs.availabilty"
        />
        {!this.state.isLoading ? (
          storeGroups.length > 0 ? (
            <View style={this.style.storeGroupContainer}>
              <View style={this.style.topLabels}>
                {orderSources.map((os) => (
                  <LabelViewView key={os.id} label={os.label} position='top' />
                ))}
              </View>
              {mappedAvailabilities.map((m) => (
                <View key={m.id} style={this.style.row}>
                  <LabelViewView label={m.name} position='left' />
                  <View style={this.style.inputs}>
                    {m.sources.map((source) => (
                      <View style={this.style.inputView} key={source.id}>
                        <CheckboxView
                          selected={this.binding.isSelected(source)}
                          onPress={() => this.binding.onPress(source, m)}
                        />
                      </View>
                    ))}
                  </View>
                </View>
              ))}
            </View>
          ) : <NoDataPlaceholderView noDataLabel="tools.product.storeGroup.empty" />
        ) : (
          <LottieView height={150} width={150} source={require('src/Common' + Constants.LottieAnimationPath)} />
        )}
      </View>
    );
  }
}