import { Application, HttpManager, Managers, Service } from "@levelapp/softfabric";

export default class AdditionalInfosService extends Service {

    httpClient: HttpManager;

    constructor()
    {
        super();
        this.httpClient = Application.current.resolve(Managers.HTTP_MANAGER);
        this.httpClient.handleSomethingWentWrong = (response: any) =>
        {
            console.warn('Erreur : ' + response.data);
        };
    }

    get(parameters: any[], callback: Function = () => { })
    {
        this.httpClient
            .get('additionalInfos', parameters)
            .then((response: any) =>
            {
                if (response)
                {
                    if (response.data)
                    {
                        callback(response.data.data);
                    }
                }
            });
    }

    getTypes(callback: Function = () => { })
    {
        this.httpClient
            .get('additionalInfos/GetAdditionalInfoTypes')
            .then((response: any) =>
            {
                if (response)
                {
                    if (response.data)
                    {
                        callback(response.data.data);
                    }
                }
            });
    }

    post(info:any, callback : Function = () => {}, onFailure : Function = () => {})
    {
        this.httpClient.handleSomethingWentWrong = onFailure;
        this.httpClient.handleNotFound = onFailure;

        this.httpClient
            .post('additionalInfos', [], info)
            .then((response : any) => 
            {
                if (response)
                {
                    callback("");
                }

            })
    }

    put(info: any, callback : Function = () => {}, onFailure : Function = () => {})
    {
        this.httpClient.handleSomethingWentWrong = onFailure;
        this.httpClient.handleNotFound = onFailure;

        this.httpClient
            .put('additionalInfos', [], info)
            .then((response : any) => 
            {
                if(response)
                {
                    callback("");
                }
            });
    }
    async getAdditionalInfoValuesByProductId(productId : number){
        return await this.httpClient.get(`additionalInfos/AdditionalInfoValuesByProductId/${productId}`);
    }
    async deleteAdditionalInfoValuesById(id : Number){
        return await this.httpClient.delete(`additionalInfos/${id}`);
    }
}