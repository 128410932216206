 
import {ContentShellView} from '@levelapp/softfabric';
import ShellEverydayValuesViewState from './ShellEverydayValuesViewState';
import ShellEverydayValuesViewProps from './ShellEverydayValuesViewProps';
import ShellEverydayValuesViewStyle from './ShellEverydayValuesViewStyle';
import ShellEverydayValuesViewModel from './ShellEverydayValuesViewModel';
import {View} from 'react-native';

export default class ShellEverydayValuesView extends ContentShellView<ShellEverydayValuesViewProps, ShellEverydayValuesViewState, ShellEverydayValuesViewModel, ShellEverydayValuesViewStyle>
{
  /* CONSTRUCTOR */
  constructor(props:ShellEverydayValuesViewProps)
  {
    super(props);

    // Binding
    this.bind(new ShellEverydayValuesViewModel(this), new ShellEverydayValuesViewStyle(this));
  }

  /* RENDERING */
  render()
  {
    return (
      <View style={{flex : 1}}>
        {this.getSwitch()}
      </View>
    );
  }
}