import { Operation } from "@levelapp/softfabric";
import SavingCardsService from "../Proxy/SavingCardsService";

export default class SavingCardsOperations extends Operation
{
    service : SavingCardsService;

    constructor()
    {
        super();
        this.service = new SavingCardsService();
    }

    get(parameters : any[], callback : Function, onFailure : Function)
    {
        this.service.get(parameters,callback,onFailure);
    }

    post(booster:any, callback : Function, onFailure : Function)
    {
        this.service.post(booster, callback,onFailure);
    }

    put(booster:any, callback : Function, onFailure : Function)
    {
        this.service.put(booster, callback,onFailure);
    }

    delete(booster:any, callback : Function, onFailure : Function)
    {
        this.service.delete(booster, callback,onFailure);
    }
}