 
import { ContentView } from '@levelapp/softfabric';
import TraductionViewState from './TraductionViewState';
import TraductionViewProps from './TraductionViewProps';
import TraductionViewStyle from './TraductionViewStyle';
import TraductionViewModel from './TraductionViewModel';
import { View } from 'react-native';
import { TranslationView } from '@levelapp/softfabric-ui';
import TableView from '../../../../../../../Common/Components/TabelView/TableView';
import Constants from '../../../../../../../Common/Constants/Constants';
import LinearGradient from 'react-native-web-linear-gradient';
import ResearchInputView from '../../../../../../../Common/Components/researchinput/ResearchInputView';
import ButtonView from '../../../../../../../Common/Components/Button/ButtonView';
import ButtonImportFileView from '../../../../../../../Common/Components/ButtonImportFileWebView/ButtonImportFileWebView';


/**
 *  * Implement HeaderView but there is a specific button. 
 */
export default class TraductionView extends ContentView<TraductionViewProps, TraductionViewState, TraductionViewModel, TraductionViewStyle>
{
  /* CONSTRUCTOR */
  constructor(props: TraductionViewProps) {
    super(props);
    
    // Binding
    this.bind(new TraductionViewModel(this), new TraductionViewStyle(this));
  }

  /* RENDERING */
  render() {
    return (
      <View style={this.style.container}>
        <View style={this.style.box}>

          {/* Color BOX */}
          <LinearGradient start={{ x: 0, y: 0 }} end={{ x: 1, y: 0 }} colors={[Constants.GradiantStartColor, Constants.GradiantEndColor]} style={this.style.boxTitle}>
            <TranslationView style={this.style.title}>
              translation.title
            </TranslationView>

            <View style={this.style.exportButton}>
              <ButtonView isLoading={false} handleClick={this.binding.export} backgroundColor='white' textColor='#FE7E5C'>
                <TranslationView>
                  form.export
               </TranslationView>
              </ButtonView>
            </View>

            <View style={this.style.exportButton}>
              <ButtonImportFileView buttonColor="white" textColor={Constants.MainColor} accepted=".json , .xlsx" action={(file: any) => this.binding.handleFile(file)} />
            </View>

            <View style={this.style.addTranslationButton}>
              <ButtonView isLoading={false} handleClick={this.binding.addTranslation} backgroundColor='white' textColor='#FE7E5C'>
                <TranslationView>
                  translation.add
               </TranslationView>
              </ButtonView>
            </View>

          </LinearGradient>
          

          <View style={this.style.boxContainer}>
            <ResearchInputView
              placeholder={this.binding.translate("generic.search")}
              handlerResearching={(searchText: string) => { this.binding.handleResearching(searchText) }}
              handlingClose={() => { this.binding.handleCloseResearching() }} />
            <TableView
              backgroundStepColor={this.style.stepColor}
              nothingToDisplayText={this.binding.translate("component.table.noresults")}
              isLastColumnActions={true}
              data={this.state.data}
              isLoading={this.state.isLoading}
              headers={this.state.headers}
              onPageChange={(page: number) => this.binding.onPageChange(page)}
              handleSortChange={(indexHeaderSort : number, sortOrder : string) => {this.binding.onSortChange(indexHeaderSort, sortOrder)}}
              nbPage={this.state.nbPage}
              currentPage={this.state.currentPage - 1}
              isSortable={true}/>
          </View>
        </View>
      </View>
    );
  }
}