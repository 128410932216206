import { ContentViewStyle } from '@levelapp/softfabric';
import { StyleProp, TextStyle, ViewStyle } from 'react-native';

export default class LogTabHeaderViewStyle extends ContentViewStyle {
  tableHeader: StyleProp<ViewStyle> = {
    display: 'flex',
    flexDirection: 'row',
    marginTop: 35,
    marginLeft: 35,
  }

  headerText: StyleProp<TextStyle> = {
    flex: 1,
    color: '#BDBDBD',
  }
}