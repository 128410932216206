 
import { ContentViewModel, ContentView } from '@levelapp/softfabric';

import SingleProductPopUpMenuViewState from './SingleProductPopUpMenuViewState';
import SingleProductPopUpMenuViewProps from './SingleProductPopUpMenuViewProps';
import RemoveProductFromGroupModalView from '../removeproductfromgroupmodal/RemoveProductFromGroupModalView';

export default class SingleProductPopUpMenuViewModel extends ContentViewModel<SingleProductPopUpMenuViewState, SingleProductPopUpMenuViewProps>
{
  /* CONSTRUCTOR */
  constructor(view: ContentView) {
    super(view);

    this.initialState({});

    // Bindings
    this.openDeleteModal = this.openDeleteModal.bind(this);
  }

  openDeleteModal() {
    this.showPopUp(<RemoveProductFromGroupModalView
      id={this.props().id}
      onRemove={this.props().onRemove}
    />);
  }
}