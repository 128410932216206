import { Service, HttpManager, Application, Managers } from '@levelapp/softfabric';

export default class LoyaltyRulesTranslationsService extends Service
{
    httpClient: HttpManager;

    constructor()
    {
        super();
        this.httpClient = Application.current.resolve(Managers.HTTP_MANAGER);
        this.httpClient.handleSomethingWentWrong = (response: any) =>
        {
            console.warn('Erreur : ' + response.data);
        };
    }

    get(callback: Function = () => { })
    {
        this.httpClient
            .get('LoyaltyRulesTranslations')
            .then((response: any) => 
            {
                if (response)
                {
                    if (response.data)
                    {
                        callback(response.data);
                    }
                }
            });
    }

    put(translation: any, callback: Function = () => { })
    {
        this.httpClient
            .put('LoyaltyRulesTranslations', [], translation)
            .then((response: any) => 
            {
                if (response)
                {
                    if (response.data)
                    {
                        callback(response.data);
                    }
                }
            });
    }
}