 
import {ContentView} from '@levelapp/softfabric';
import StatsBlockViewState from './StatsBlockViewState';
import StatsBlockViewProps from './StatsBlockViewProps';
import StatsBlockViewStyle from './StatsBlockViewStyle';
import StatsBlockViewModel from './StatsBlockViewModel';
import {LottieView, TranslationView} from '@levelapp/softfabric-ui';
import { View, Text } from 'react-native';
import { Line, Pie } from '@reactchartjs/react-chart.js';
import Constants from '../../../../../../../../Common/Constants/Constants';
import ExtendedTranslationView from '../../../../../components/extendedtranslation/ExtendedTranslationView';

export default class StatsBlockView extends ContentView<StatsBlockViewProps, StatsBlockViewState, StatsBlockViewModel, StatsBlockViewStyle>
{
  /* CONSTRUCTOR */
  constructor(props:StatsBlockViewProps)
  {
    super(props);

    // Binding
    this.bind(new StatsBlockViewModel(this), new StatsBlockViewStyle(this));
  }

  /* RENDERING */
  render()
  {
    const options = {
      legend: {
          display: true,
      },
      scales: {
        yAxes: [
          {
            ticks: {
              beginAtZero: true,
              userCallback: function(label: number) {
                // when the floored value is the same as the value we have a whole number
                if (Math.floor(label) === label) {
                    return label;
                }
                return ""
              }
            },
          },
        ],
      },
    }

    const pie_options = {
      responsive : false,
      legend: {
        display: false,
      }
    }

    const props = this.props

    const {titleStyle,page,chartContainer,headerContainer,headerContent,textContainer,legend,legend_status} = this.style


    return (
      <View style={page}>
        <View style = {headerContainer}>
          <Text style={titleStyle}>{props.title}</Text>
          <View style={headerContent}>
            <View style={[textContainer,{backgroundColor:'rgba(255, 99, 132,0.8)'}]}>
              <TranslationView>{props.value1Text}</TranslationView>
              <Text>{": " + props.value1}</Text>
            </View>
            <View style={[textContainer,{backgroundColor: 'rgba(235, 220, 52,0.8)'}]}>
              <TranslationView>{props.value2Text}</TranslationView>
              <Text>{": " + props.value2}</Text>
            </View>
          </View>
        </View>

        <View>
          {props.line_chart_data == null ? 
            <LottieView height={150} width={150} source={require('../../../../../../../../Common' + Constants.LottieAnimationPath)} /> 
            : <View style={chartContainer}>
                <Line type={"line"} data={props.line_chart_data} options={options} />
              </View>}
          {props.pie_chart_data == null ? 
          <LottieView height={150} width={150} source={require('../../../../../../../../Common' + Constants.LottieAnimationPath)} /> : 
          <View style={{display : 'flex',flexDirection : 'row'}}>
            <Pie type={"line"} data={props.pie_chart_data} options={pie_options}/>
            <View style={{justifyContent : 'center'}}>
            {props.pie_chart_data.labels.map((label : string,index :number) =>
               <View style={legend_status}>
                 <View style={[legend,{backgroundColor:props.pie_chart_data.datasets[0].backgroundColor[index]}]}/>
                 <ExtendedTranslationView infoText={this.binding.translate("messageCampaign.stats.legend.info."+label)}>{"messageCampaign.stats.legend."+label}</ExtendedTranslationView>
               </View> )}
            </View>
          </View>
          }
        </View>
      </View>
      );
  }
}