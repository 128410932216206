import {ContentViewStyle} from '@levelapp/softfabric';
import { StyleProp } from 'react-native';

export default class DropDownViewStyle extends ContentViewStyle{
    dropDown: StyleProp<any> = {
        minWidth: 180,
        paddingHorizontal: 15,
        borderRadius : 8,
        boxShadow: '0 5px 14px 0 rgba(0,0,0,0.1)',
    }
}