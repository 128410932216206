import {ContentViewStyle} from '@levelapp/softfabric';
import {StyleProp,ViewStyle} from 'react-native';

export default class MainInformationViewStyle extends ContentViewStyle
{
    page: StyleProp<ViewStyle> = {
        flex: 1
    }

    container : StyleProp<ViewStyle> =
    { 
        display: "flex", 
        flexDirection: "column", 
        paddingHorizontal: 170,
        paddingVertical: 50 
    }

    leftblock : StyleProp<ViewStyle> =
    { 
        flex: 1.5, 
        justifyContent: "flex-start", 
        flexDirection: 'row' 
    }
}