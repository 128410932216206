import {ContentViewModel,ContentView} from '@levelapp/softfabric';
import ContentTypeKeyConfiguratorViewState from './ContentTypeKeyConfiguratorViewState';
import ContentTypeKeyConfiguratorViewProps from './ContentTypeKeyConfiguratorViewProps';
import ContentTypeKeyOperations from '../../../../../../../Transfer/ContentTypeKeyOperations';
import ContentTypeKey from '../../../../../../../Common/DTO/ContentTypeKey';
import ErrorHelper from '../../../../../../../Common/Helpers/ErrorHelper';
import ContentType from '../../../../../../../Common/DTO/ContentType';
import ContentTypeOperations from '../../../../../../../Transfer/ContentTypeOperations';
import BoosterTypesOperations from '../../../../../../../Transfer/BoosterTypesOperations';
import CouponTypesOperations from '../../../../../../../Transfer/CouponTypesOperations';
import BoosterType from '../../../../../../../Common/DTO/BoosterType';
import CouponType from '../../../../../../../Common/DTO/CouponType';
import TableRow from '../../../../../../../Common/Components/TabelView/TableRow';
import { ContentTypeEnum } from '../../../../../../../Common/Enums/ContentTypeEnum';

export default class ContentTypeKeyConfiguratorViewModel extends ContentViewModel<ContentTypeKeyConfiguratorViewState, ContentTypeKeyConfiguratorViewProps>
{
    _contentTypeKeyOperation : ContentTypeKeyOperations;
    _contentTypeOperation : ContentTypeOperations;
    _couponTypeOperation : CouponTypesOperations;
    _boosterTypeOperation : BoosterTypesOperations;
    _contentTypes : any[] = [];
    _contentTypeKeys : any[] = [];
    _boosterTypes: any[] = [];
    _couponTypes: any[] = [];
    _callContentTypeKeyIsFinished = false;
    _callContentTypeIsFinished = false;
    _callCouponTypeIsFinished = false;
    _callBoosterTypeIsFinished = false;
      /* CONSTRUCTOR */
    constructor(view: ContentView)
    {
        super(view);

        this.initialState({
            isLoading : false,
            currentContentDropdownValue : {"title" : " ", "value" : 0},
            currentTypeDropdownValue : {"title" : " ", "value" : 0},
            currentContentTypeKeys : [],
            currentTypeList : [],
            newContentTypeKey : "",
            buttonLoading : false,
            showAddNewKey : false,
            tableData: [], 
            tableHeaders: ['Key Name']
        }); // Initialize your state here

        this._contentTypeKeyOperation = new ContentTypeKeyOperations();
        this._contentTypeOperation = new ContentTypeOperations();
        this._couponTypeOperation = new CouponTypesOperations();
        this._boosterTypeOperation = new BoosterTypesOperations();

       
     

        // Bindings
        this.handleContentIdChange = this.handleContentIdChange.bind(this)
        this.handleTypeIdChange = this.handleTypeIdChange.bind(this)
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleButtonPressed = this.handleButtonPressed.bind(this);
        this.showAddNewKeySection = this.showAddNewKeySection.bind(this);
        this.errorManagement = this.errorManagement.bind(this);

    }

    componentDidMount(){
        this.getData();
    }

    getData(){
        this.setField({isLoading : true})
        this.getContentTypeKey();
         this._couponTypeOperation.get((couponTypes : any)=>{
            couponTypes.data.map((c : CouponType)=>{
                this._couponTypes = [...this._couponTypes,{"title" : c.name, "value": c.id}]
            })
            this.setField({currentTypeList : this._couponTypes[0]})
            this._callCouponTypeIsFinished = true;
            this.checkCallsAreDone();
        })
        this._boosterTypeOperation.get((boosterTypes : any)=>{
            boosterTypes.data.map((b : BoosterType)=>{
                this._boosterTypes = [...this._boosterTypes,{"title" : b.name, "value": b.id}]
            })
            this._callBoosterTypeIsFinished = true;
            this.checkCallsAreDone();
        })
        this._contentTypeOperation.get((contentTypes : ContentType [])=>{
            contentTypes.map((c : ContentType)=>{
                this._contentTypes = [...this._contentTypes,{"title" : c.key, "value": c.id}]
            })
            this.setField({currentContentDropdownValue : this._contentTypes[0]})
            this._callContentTypeIsFinished = true;
            this.checkCallsAreDone();
        },this.errorManagement) 
    }

    getContentTypeKey(){
        this._contentTypeKeyOperation.get((contentTypeKeysList : ContentTypeKey [])=>{
            this._contentTypeKeys = contentTypeKeysList;
            this._callContentTypeKeyIsFinished = true;
            this.setField({ 
                currentContentTypeKeys : this._contentTypeKeys.filter((c : ContentTypeKey) => c.contentTypeId === ContentTypeEnum.COUPON )
            })
            this.checkCallsAreDone();
        },this.errorManagement)
    }

    
    handleContentIdChange(value : any){
        this.setField({
            currentContentDropdownValue : value,
            currentContentTypeKeys : this.getCurrentKeysFiltered(value.value, 1),
            currentTypeList : this.getCurrentTypeList(value.value),
            currentTypeDropdownValue : this.getFirstDropdownTypeValue(value.value)
        },()=>{
          
            this.fillTable() 
        });
        
    }

    handleTypeIdChange(value : any){
        this.setField({
            currentTypeDropdownValue : value,
            currentContentTypeKeys : this.getCurrentKeysFiltered(this.state().currentContentDropdownValue.value, value.value)
        },()=>{
            this.fillTable() 
        });  
    }

    handleInputChange(text : string){
        this.setField({newContentTypeKey : text})
    }

    handleButtonPressed(){
        this.setField({buttonLoading : true})
        let newContentTypeKey : any = {
            "key" : this.state().newContentTypeKey.replace(" ","_"),
            "contentTypeId" : this.state().currentContentDropdownValue.value,
            "boosterTypeId" : this.state().currentContentDropdownValue.value === ContentTypeEnum.BOOSTER ? this.state().currentTypeDropdownValue.value : null,
            "couponTypeId" :this.state().currentContentDropdownValue.value === ContentTypeEnum.COUPON ? this.state().currentTypeDropdownValue.value : null,
        }
        
        this._contentTypeKeyOperation.createContentTypeKey(newContentTypeKey,()=>{
            this.setField({buttonLoading : false, newContentTypeKey :""} )
            this.getContentTypeKey()
        },this.errorManagement)
    }

    showAddNewKeySection(){
        this.setField({showAddNewKey : !this.state().showAddNewKey})
    }

    getCurrentKeysFiltered(contentId: number,typeId : number) : ContentTypeKey[]{
        switch(contentId){
            case ContentTypeEnum.COUPON :
                return this._contentTypeKeys.filter((c : ContentTypeKey) => c.contentTypeId === contentId && c.couponTypeId === typeId);
            case ContentTypeEnum.BOOSTER :
                return this._contentTypeKeys.filter((c : ContentTypeKey) => c.contentTypeId === contentId && c.boosterTypeId === typeId);
            default:
                return this._contentTypeKeys.filter((c : ContentTypeKey) => c.contentTypeId === contentId);
        }
    }

    getFirstDropdownTypeValue(contentId: number) : {"title" :string, "value" : number}{
        switch(contentId){
            case ContentTypeEnum.COUPON :
                return this._couponTypes[0];
            case ContentTypeEnum.BOOSTER :
                return this._boosterTypes[0];
            default:
                return {"title" :"", "value" : 0};
        }
    }

    getCurrentTypeList(contentId : number) : any[]
    {
        switch(contentId){
            case ContentTypeEnum.COUPON :
                return this._couponTypes;
            case ContentTypeEnum.BOOSTER :
                return this._boosterTypes;
            default:
                return [];
        }
    }

    deleteKey(contentTypeKeyId :number){
        this.setField({isLoading : true})
        this._contentTypeKeyOperation.delete(contentTypeKeyId,()=>{
            this.getContentTypeKey()
        },this.errorManagement)
    }

    checkCallsAreDone(){
        if( this._callBoosterTypeIsFinished && this._callContentTypeIsFinished && this._callContentTypeKeyIsFinished && this._callCouponTypeIsFinished ){
            this.setField({
                currentTypeDropdownValue : this._couponTypes[0],
                currentContentTypeKeys : this.getCurrentKeysFiltered(this._couponTypes[0].value,1),
                currentTypeList : this.getCurrentTypeList(ContentTypeEnum.COUPON)
            })
            this.fillTable();  
            
        }
    }

    fillTable() {
        let tableData: TableRow[] = [];
        this.state().currentContentTypeKeys.forEach((contentTypeKey: ContentTypeKey) => {
            let data: any[] = [contentTypeKey.key];
            tableData.push({ 
                data: data, 
                isDeletable: true, 
                deleteCallback: () => {this.deleteKey(contentTypeKey.id) } });
        });
        this.setField(
            {
                tableData: tableData,
                isLoading: false 
            });
    }

    errorManagement(error : any) 
    {
        this.setField({isLoading : false});
        ErrorHelper.showMessageError(error.response);
    }

}