import {ContentViewModel,ContentView} from '@levelapp/softfabric';
import ExtendedTextInputViewState from './ExtendedTextInputViewState';
import ExtendedTextInputViewProps from './ExtendedTextInputViewProps';

export default class ExtendedTextInputViewModel extends ContentViewModel<ExtendedTextInputViewState, ExtendedTextInputViewProps>
{
      /* CONSTRUCTOR */
    constructor(view: ContentView)
    {
        super(view);

        this.initialState({}); // Initialize your state here

        // Bindings

    }
}