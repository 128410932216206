import ProductOrderSourcesAvailabilitiesService from "src/Proxy/ProductOrderSourcesAvailabilitiesService";

import { Operation } from "@levelapp/softfabric";
import ProductOrderSourceAvailability from "src/Common/DTO/ProductOrderSourceAvailability";

export default class ProductOrderSourceAvailabilityOperations extends Operation {
  service: ProductOrderSourcesAvailabilitiesService;

  constructor() {
    super();
    this.service = new ProductOrderSourcesAvailabilitiesService();
  }

  get(productId: number) {
    return this.service.get(productId);
  }

  post(product: ProductOrderSourceAvailability[], categoryId?: number) {
    return this.service.post(product, categoryId);
  }

  delete(product: ProductOrderSourceAvailability[]) {
    return this.service.delete(product);
  }

  deleteByProductGroupId(id: number) {
    return this.service.deleteByProductGroupId(id);
  }
}