import { Service, Application, HttpManager, Managers } from '@levelapp/softfabric';


export default class SavingCardsService extends Service
{
    httpClient = Application.current.resolve<HttpManager>(Managers.HTTP_MANAGER);

    async get(parameters : any[], onSuccess: Function, onFailure : Function = () => {})
    {      
        this.httpClient.handleSomethingWentWrong = onFailure;
        this.httpClient.handleNotFound = onFailure;
        
        this.httpClient
        .get('savingCards', parameters)
        .then((response : any) => {
            if (response)
            {
                if (response.data)
                {                  
                    onSuccess(response.data);
                }
            }
        });
    }

    post(savingCard:any, callback : Function = () => {}, onFailure : Function = () => {})
    {
        this.httpClient.handleSomethingWentWrong = onFailure;
        this.httpClient.handleNotFound = onFailure;

        this.httpClient
            .post('savingCards', [], savingCard)
            .then((response : any) => 
            {
                if (response)
                {
                    /*if (response.data)
                    {
                        callback(response.data);
                    }*/
                    callback("");
                }
            });
    }

    put(savingCard:any, callback : Function = () => {}, onFailure : Function = () => {})
    {
        this.httpClient.handleSomethingWentWrong = onFailure;
        this.httpClient.handleNotFound = onFailure;

        this.httpClient
            .put('savingCards', [], savingCard)
            .then((response : any) => 
            {
                if (response)
                {
                    /*if (response.data)
                    {
                        callback(response.data);
                    }*/
                    callback("");
                }
            });
    }

    delete(savingCard:any, callback : Function = () => {}, onFailure : Function = () => {})
    {
        this.httpClient.handleSomethingWentWrong = onFailure;
        this.httpClient.handleNotFound = onFailure;

        this.httpClient
            .delete('savingCards/delete/'+savingCard.id)
            .then((response : any) => 
            {
                if (response)
                {
                     /*if (response.data)
                    {
                        callback(response.data);
                    }*/
                    callback("");
                }
            });
    }
}