 
import { TouchableOpacity, View } from 'react-native';

import { TranslationView } from '@levelapp/softfabric-ui';
import { ContentView } from '@levelapp/softfabric';

import ManageProductsMenuViewState from './ManageProductsMenuViewState';
import ManageProductsMenuViewProps from './ManageProductsMenuViewProps';
import ManageProductsMenuViewStyle from './ManageProductsMenuViewStyle';
import ManageProductsMenuViewModel from './ManageProductsMenuViewModel';
import PriceTabView from '../pricetab/PriceTabView';
import AvailabilityTabView from '../availabilitytab/AvailabilityTabView';
import LogTabView from '../logtab/LogTabView';

export default class ManageProductsMenuView extends ContentView<ManageProductsMenuViewProps, ManageProductsMenuViewState, ManageProductsMenuViewModel, ManageProductsMenuViewStyle>
{
  /* CONSTRUCTOR */
  constructor(props: ManageProductsMenuViewProps) {
    super(props);

    // Binding
    this.bind(new ManageProductsMenuViewModel(this), new ManageProductsMenuViewStyle(this));
  }

  /* RENDERING */
  render() {
    const tabs = [
      {
        id: 'availability',
        bgColor: "transparent",
        textColor: "black",
        label: "tools.products.tabs.availabilty",
        shown: true,
      },
      {
        id: 'price',
        bgColor: "transparent",
        textColor: "black",
        label: "tools.products.tabs.price",
        shown: true,
      },
      {
        id: 'log',
        bgColor: "transparent",
        textColor: "black",
        label: "tools.products.tabs.log",
        shown: !this.props.selectedItem?.isParent,
      },
    ];

    return (
      <>
        <View style={this.style.tabs}>
          {tabs.map((tab) => (
            tab.shown && (
              <TouchableOpacity
                key={tab.id}
                onPress={() => this.binding.onTabClick(tab.id)}
                style={[
                  this.style.tab,
                  tab.id === this.state.selectedTab && this.style.selected,
                  { backgroundColor: tab.bgColor }
                ]}
              >
                <TranslationView
                  upperCase
                  style={[
                    this.style.label,
                    tab.id === this.state.selectedTab && this.style.labelSelected,
                    { color: tab.textColor }
                  ]}
                >
                  {tab.label}
                </TranslationView>
              </TouchableOpacity>
            )
          ))}

        </View>
        {this.state.selectedTab === 'availability' && (
          <AvailabilityTabView selectedItem={this.props.selectedItem} />
        )}
        {this.state.selectedTab === 'price' && (
          <PriceTabView selectedItem={this.props.selectedItem} />
        )}
        {this.state.selectedTab === 'log' && !this.props.selectedItem?.isParent && (
          <LogTabView selectedItem={this.props.selectedItem} />
        )}
      </>
    );
  }
};