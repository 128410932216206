import { StyleProp, TextStyle, ViewStyle } from 'react-native';

import { ContentViewStyle } from '@levelapp/softfabric';

export default class DropdownContentViewStyle extends ContentViewStyle {
  container: StyleProp<ViewStyle> = {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    flex: 1,
    overflow: 'hidden',
  }

  item: StyleProp<ViewStyle> = {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',

    backgroundColor: '#f6e5e2',
    margin: 5,
    paddingHorizontal: 10,
    paddingVertical: 5,
    borderRadius: 5,
  }

  delete: StyleProp<ViewStyle> = {
    marginLeft: 10,
  }

  text: StyleProp<TextStyle> = {
    color: '#D83F28',
  }
}