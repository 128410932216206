import { StyleProp, ViewStyle } from 'react-native';

import { ContentViewStyle } from '@levelapp/softfabric';

export default class CheckboxViewStyle extends ContentViewStyle {
  checkbox: StyleProp<ViewStyle> = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: 20,
    width: 20,
    borderWidth: 1,
    borderRadius: 3,
    borderColor: '#DCCDCD',
  }

  selected: StyleProp<ViewStyle> = {
    backgroundColor: '#D83F28',
    borderColor: '#D83F28',
  }
}