 

import { ContentView } from '@levelapp/softfabric';

import DropdownItemViewState from './DropdownItemViewState';
import DropdownItemViewProps from './DropdownItemViewProps';
import DropdownItemViewStyle from './DropdownItemViewStyle';
import DropdownItemViewModel from './DropdownItemViewModel';

import './dropdownitem.css';

export default class DropdownItemView extends ContentView<DropdownItemViewProps, DropdownItemViewState, DropdownItemViewModel, DropdownItemViewStyle>
{
  /* CONSTRUCTOR */
  constructor(props: DropdownItemViewProps) {
    super(props);

    // Binding
    this.bind(new DropdownItemViewModel(this), new DropdownItemViewStyle(this));
  }

  /* RENDERING */
  render() {
    const { methods, item, itemGroupId, isSingleElement } = this.props;

    const disabled = !!item.itemGroupId && itemGroupId !== item.itemGroupId;

    return (
      <label className="container">
        {!disabled ? (
          <input
            onChange={() => methods.addItem(item)}
            type="checkbox"
            className={`checkbox ${isSingleElement && 'round'}`}
            checked={methods.isSelected(item)}
            disabled={disabled}
          />
        ) : (
          <div className={`fake-checkbox ${isSingleElement && 'round'}`} />
        )}
        <span className={disabled ? "disabled-item" : ""}>
          {`${item.name} ${item.posId ? `(${item.posId})` : ''}` || "(No name)"}
        </span>
      </label>
    );
  }
}
