 
import {ContentView} from '@levelapp/softfabric';
import LogDetailsViewState from './LogDetailsViewState';
import LogDetailsViewProps from './LogDetailsViewProps';
import LogDetailsViewStyle from './LogDetailsViewStyle';
import LogDetailsViewModel from './LogDetailsViewModel';
import {TranslationView} from '@levelapp/softfabric-ui';
import { Text, View } from 'react-native';
import ReactJson from 'react-json-view';
import { utc } from 'moment';



export default class LogDetailsView extends ContentView<LogDetailsViewProps, LogDetailsViewState, LogDetailsViewModel, LogDetailsViewStyle>
{
  /* CONSTRUCTOR */
  constructor(props:LogDetailsViewProps)
  {
    super(props);

    // Binding
    this.bind(new LogDetailsViewModel(this), new LogDetailsViewStyle(this));
  }

  /* RENDERING */
  render()
  {
    return (
      <View style={{paddingHorizontal : 26 , paddingVertical : 10}}>
        <View style={{paddingVertical : 5}}>
          <Text>
            <TranslationView style={this.style.formtitle}>kitchenapp.loghistory.name</TranslationView> {this.props.log?.name}
          </Text>
        </View>
        <View style={{paddingVertical : 5}}>
          <Text>
            <TranslationView style={this.style.formtitle}>kitchenapp.loghistory.date</TranslationView> {utc(this.props.log?.creationDate).local().format("HH:mm - DD/MM/YYYY")}
          </Text>
        </View>
        <View style={{paddingVertical : 5}}>
          <Text>
            <TranslationView style={this.style.formtitle}>kitchenapp.loghistory.errorcode</TranslationView> {this.props.log?.errorCode}
          </Text>
        </View> 
        <View style={{paddingVertical : 5}}>
          <Text>
            <TranslationView style={this.style.formtitle}>kitchenapp.loghistory.method</TranslationView>{this.props.log?.method} 
          </Text>
        </View>
        <View style={{paddingVertical : 5}}>
          <Text>
            <TranslationView style={this.style.formtitle}>kitchenapp.loghistory.url</TranslationView>{this.props.log?.url}
          </Text>
        </View>
        <View style={{paddingVertical : 5}}>
            <TranslationView style={this.style.formtitle}>kitchenapp.loghistory.body</TranslationView>{this.props.log != undefined ? <ReactJson src={JSON.parse(this.props.log?.body)} /> : <View/>}
        </View> 
      </View>
    );
  }
}