import Constants from "../Constants/Constants";
import RoleManager from "../Managers/RoleManager";
import { Application } from "@levelapp/softfabric";
import ExtendedManagers from "../Managers/ExtendedManagers";

export default class PermissionHelper
{
    static _roleManager: RoleManager;

    static init()
    {
        this._roleManager = Application.current.resolve<RoleManager>(ExtendedManagers.ROLE_MANAGER);
    }

    private static template(client_constant: boolean, role_constant: boolean)
    {
        if (Constants.ShouldWorkWithRole)
        {
            return client_constant && role_constant;
        } else
        {
            return client_constant;
        }
    }

    static shouldShowUser()
    {
        return this.template(Constants.ShowUsers, this._roleManager.KEY_ShowUsers.value);
    }

    static shouldShowHealthStatus()
    {
        return this.template(Constants.ShowHealthStatus, this._roleManager.KEY_ShowUsers.value);
    }

    static shouldShowRewards()
    {
        return this.template(Constants.ShowRewards, this._roleManager.KEY_ShowRewards.value);
    }

    static shouldShowCoupons()
    {
        return this.template(Constants.ShowCoupons, this._roleManager.KEY_ShowCoupons.value);
    }

    static shouldShowBoosters()
    {
        return this.template(Constants.ShowBoosters, this._roleManager.KEY_ShowBoosters.value);
    }

    static shouldShowTranslations()
    {
        return this.template(Constants.ShowTranslations, this._roleManager.KEY_ShowTranslations.value);
    }

    static shouldShowNews()
    {
        return this.template(Constants.ShowNews, this._roleManager.KEY_ShowNews.value);
    }

    static shouldShowProductCategories()
    {
        return this.template(Constants.ShowProductCategories, this._roleManager.KEY_ShowProductCategories.value);
    }

    static shouldShowStats()
    {
        return this.template(Constants.ShowStats, this._roleManager.KEY_ShowStats.value);
    }

    static shouldShowPosSimulator()
    {
        return this.template(Constants.ShowPosSimulator, this._roleManager.KEY_ShowPosSimulator.value);
    }

    static shouldShowOverview()
    {
        return this.template(Constants.ShowOverview, this._roleManager.KEY_ShowOverview.value);
    }

    static shouldShowPurchase()
    {
        return this.template(Constants.ShowPurchase, this._roleManager.KEY_ShowPurchase.value);
    }

    static shouldShowLoyaltyRules()
    {
        return this.template(Constants.ShowLoyaltyRules, this._roleManager.KEY_ShowLoyaltyRules.value);
    }
    static shouldShowEarningPoints()
    {
        return this.template(Constants.ShowEarningPoints, this._roleManager.KEY_ShowEarningPoints.value);
    }

    static shouldShowLegalTexts()
    {
        return this.template(Constants.ShowLegalTexts, this._roleManager.KEY_ShowLegalTexts.value);
    }

    static shouldShowNewsDiplay()
    {
        return this.template(Constants.HasInAppMessage, this._roleManager.KEY_ShowNewsDiplay.value);
    }

    static shouldShowStores()
    {
        return this.template(Constants.ShowStores, this._roleManager.KEY_ShowStores.value);
    }

    static shouldShowSavingCards()
    {
        return this.template(Constants.ShowSavingCards, this._roleManager.KEY_ShowSavingCards.value);
    }

    static shouldShowFaq()
    {
        return this.template(Constants.ShowFaq, this._roleManager.KEY_ShowFaq.value);
    }

    static shouldShowMessages()
    {
        return this.template(Constants.ShowMessages, this._roleManager.KEY_ShowMessages.value);
    }

    static shouldShowAutomatedMessages()
    {
        return this.template(Constants.ShowAutomatedMessages, this._roleManager.KEY_ShowAutomatedMessages.value);
    }

    static shouldShowProducts()
    {
        return this.template(Constants.ShowProducts, this._roleManager.KEY_ShowProducts.value);
    }

    static shouldShowOrders()
    {
        return this.template(Constants.ShowOrders, this._roleManager.KEY_ShowOrders.value);
    }

    static shouldShowKitchenApp()
    {
        return this.template(Constants.ShowKitchenApp, this._roleManager.KEY_ShowKitchenApp.value);
    }

    static shouldShowRolePermissions()
    {
        return this.template(Constants.ShowRolePermissions,this._roleManager.KEY_ShowRolePermissions.value);
    }

    static shouldShowAdminUsers()
    {
        return this.template(Constants.ShowAdminUsers,this._roleManager.KEY_ShowAdminUsers.value);
    }
    static shouldShowMenuConfiguration()
    {
        return this.template(Constants.ShowMenuConfigurator, this._roleManager.KEY_ShowMenuConfigurator.value);
    }
    
    static shouldShowTickets()
    {
        return this.template(Constants.ShowTickets, this._roleManager.KEY_ShowTickets.value);
    }

    static shouldShowPromoCodes()
    {
        return this.template(Constants.ShowPromoCodes, this._roleManager.KEY_ShowPromoCodes.value);
    }
    static shouldShowLanguage()
    {
        return this.template(Constants.ShowLanguage, this._roleManager.KEY_ShowLanguage.value);
    }

    static shouldShowEverydayValues()
    {
        return this.template(Constants.ShowEverydayValues, this._roleManager.KEY_ShowBoosters.value);
    }

    static shouldShowCountries()
    {
        return this.template(Constants.ShowCountries, this._roleManager.KEY_ShowCountries.value);
    }
    static shouldShowStoresSummary()
    {
        return this.template(Constants.ShowStoresSummary, this._roleManager.KEY_ShowStoresSummary.value);
    }
    static shouldShowCrossSellconfigurator()
    {
        return this.template(Constants.ShowCrossSellconfigurator, this._roleManager.KEY_ShowCrossSellconfigurator.value);
    }

    static shouldShowOutOfStock()
    {
        return this.template(Constants.ShowOutOfStock, this._roleManager.KEY_OutOfStock.value);
    }
    static shouldShowContentTypeKeyConfig()
    {
        return this.template(Constants.ShowContentTypeKeyConfig, this._roleManager.KEY_ContentTypeKeyConfig.value);
    }
    static shouldShowBanners()
    {
        return this.template(Constants.ShowBanners, this._roleManager.KEY_ShowBanners.value);
    }

    static shouldShowProductTool()
    {
        return this.template(Constants.ShowProductTool, this._roleManager.KEY_ProductTool.value);
    }
    static shouldShowZoneTool()
    {
        return this.template(Constants.ShowZoneTool, this._roleManager.KEY_ZoneTool.value);
    }
    static shouldShowMembership()
    {
        return this.template(Constants.ShowMembership, this._roleManager.KEY_ShowMembership.value);
    }

    static getExtraDataOrders()
    {
        return this._roleManager.KEY_ShowOrders.extradata;
    }

    static getExtraDataKitchenApp()
    {
        return this._roleManager.KEY_ShowKitchenApp.extradata;
    }

    static getExtraDataStoreOOS()
    {
        return this._roleManager.KEY_OutOfStock.extradata;
    }

    static getExtraDataHealthStatus()
    {
        return this._roleManager.KEY_ShowHealthStatus.extradata;
    }

    static getExtraDataOverview()
    {
        return this._roleManager.KEY_ShowOverview.extradata;
    }

    static shouldShowVouchers()
    {
        return this.template(Constants.ShowVouchers, this._roleManager.KEY_ShowVouchers.value);
    }
    static shouldShowProductAdditionalInfo(){
        return this.template(Constants.ShowProductAdditionalInfo, this._roleManager.KEY_ShowProductAdditionalInfo.value);
    }
}