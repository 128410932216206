import { Service, Application, HttpManager, Managers } from '@levelapp/softfabric';
import BasketOrderType from '../Common/DTO/BasketOrderType';

export default class BasketOrderTypeService extends Service {
    httpClient = Application.current.resolve<HttpManager>(Managers.HTTP_MANAGER);

    async get(onSuccess: Function) {
        this.httpClient
            .get('BasketOrderType', [])
            .then((response: any) => {
                if (response) {
                    if (response.data) {
                        onSuccess(response.data.data);
                    }
                }
            });
    }

    post(basketOrderType: BasketOrderType, callback: Function = () => { }) {
        this.httpClient
            .post('BasketOrderType', [], basketOrderType)
            .then((response: any) => {
                if (response) {

                    callback("");
                }
            });
    }

    put(basketOrderType: BasketOrderType, callback: Function = () => { }) {
        this.httpClient
            .put('allergen', [], basketOrderType)
            .then((response: any) => {
                if (response) {
                    /*if (response.data)
                    {
                        callback(response.data);
                    }*/
                    callback("");
                }
            });
    }

    delete(basketOrderType: BasketOrderType, callback: Function = () => { }) {
        this.httpClient
            .delete('BasketOrderType/delete/' + basketOrderType.id)
            .then((response: any) => {
                if (response) {
                    /*if (response.data)
                   {
                       callback(response.data);
                   }*/
                    callback("");
                }
            });
    }
}