import { Service, Application, HttpManager, Managers} from "@levelapp/softfabric";

export default class StoreBrandService extends Service{

    httpClient = Application.current.resolve<HttpManager>(Managers.HTTP_MANAGER);

    async get(storeId : number)
    {        
        this.httpClient.handleSomethingWentWrong = () => {};
        this.httpClient.handleNotFound = () => {};
        return  await this.httpClient.get(`storeBrand?storeId=${storeId}`);
    }
}