import { ContentViewModel, ContentView } from '@levelapp/softfabric';
import CouponsPopUpViewState from './CouponsPopUpViewState';
import CouponsPopUpViewProps from './CouponsPopUpViewProps';
import Helpers from 'src/Common/Helpers/Helpers';
import NumberHelper from 'src/Common/Helpers/NumberHelper';
import DispatcherManager from 'src/Common/Managers/DispatcherManager';
import TranslationValue from 'src/Common/DTO/TranslationValue';
import TranslationHelper from 'src/Common/Helpers/TranslationHelper';
import DateHelper from 'src/Common/Helpers/DateHelper';
import ToastHelper from 'src/Common/Helpers/ToastHelper';
import CouponsOperations from 'src/Transfer/CouponsOperations';
import ErrorHelper from 'src/Common/Helpers/ErrorHelper';
import Country from 'src/Common/DTO/Country';
import Product from 'src/Common/DTO/Product';
import StoresOperations from 'src/Transfer/StoresOperations';
import Store from 'src/Common/DTO/Store';
import CouponType from 'src/Common/DTO/CouponType';
import ContentTypeKey from 'src/Common/DTO/ContentTypeKey';
import BasketOrderType from 'src/Common/DTO/BasketOrderType';
import BasketOrderTypeOperations from 'src/Transfer/BasketOrderTypeOperations';
import CouponAvailableHour from 'src/Common/DTO/M&N/CouponAvailableHour';
import { OrderSourceOperations } from 'src/Transfer/OrderSourceOperations';
import { OrderSources } from 'src/Common/Enums/OrderSources';
import CouponOrderTypeOrderSources from 'src/Common/DTO/CouponOrderTypeOrderSources';
import CouponMembership from 'src/Common/DTO/CouponMembership';
import * as XLSX from 'xlsx';
import LoyaltyRulesUnit from 'src/Common/DTO/LoyaltyRulesUnit';
import CouponProductMapper from 'src/Common/Mappers/CouponProductMapper';

export default class CouponsPopUpViewModel extends ContentViewModel<CouponsPopUpViewState, CouponsPopUpViewProps>
{
  _dispatcherHelper: DispatcherManager;
  _couponsOperation: CouponsOperations;
  _storesOperation: StoresOperations;
  basketOrderTypeOperations: BasketOrderTypeOperations;
  _regexTimeFormat: RegExp;
  weekdays: any[];
  orderSourceOperations: OrderSourceOperations;


  constructor(props: ContentView) {
    super(props);
    /* Initial State */
    this.initialState({
      coupon: CouponProductMapper.fillCouponProducts(this.props().coupon as any) as any,
      languages: this.props().languages,
      countries: this.props().countries,
      showErrors: false,
      index: 0,
      indexThumbnail: 0,
      useDefaultImageChecked: Helpers.hasDuplicates(this.props().coupon.imageTranslation),
      useDefaultThumbnailChecked: Helpers.hasDuplicates(this.props().coupon.thumbnailTranslation),
      tab: "main",
      isLoading: false,
      products: this.props().products,
      isStoresLoading: true,
      stores: [],
      isLoadingBasketOrderTypes: false,
      basketOrderTypes: [],
      page: '',
      orderSources: [],
      isRenewableWithCooldown : this.props().coupon.loyaltyRulesUnit != null && this.props().coupon.loyaltyRulesUnitValue != null ? true : false
    });

    this._regexTimeFormat = new RegExp('^(0[0-9]|1[0-9]|2[0-3]|[0-9]):[0-5][0-9]$');
    this._dispatcherHelper = new DispatcherManager();
    this._couponsOperation = new CouponsOperations();
    this._storesOperation = new StoresOperations();
    this.orderSourceOperations = new OrderSourceOperations();
    this.basketOrderTypeOperations = new BasketOrderTypeOperations();

    this.weekdays = [
      { title: 'Monday', value: 1 },
      { title: 'Tuesday', value: 2 },
      { title: 'Wednesday', value: 3 },
      { title: 'Thursday', value: 4 },
      { title: 'Friday', value: 5 },
      { title: 'Saturday', value: 6 },
      { title: 'Sunday', value: 7 }
    ];

    /* BINDINGS */
    this.saveCoupon = this.saveCoupon.bind(this);
    this.changeTab = this.changeTab.bind(this);
    this.handleImage = this.handleImage.bind(this);
    this.setIndexImage = this.setIndexImage.bind(this);
    this.errorManagement = this.errorManagement.bind(this);
    this.handleThumbnail = this.handleThumbnail.bind(this);
    this.setIndexThumbnail = this.setIndexThumbnail.bind(this);
    this.handleSavePressed = this.handleSavePressed.bind(this);
    this.handleTitleChanged = this.handleTitleChanged.bind(this);
    this.handlePosIdChanged = this.handlePosIdChanged.bind(this);
    this.handleEndDateChanged = this.handleEndDateChanged.bind(this);
    this.handleDurationChanged = this.handleDurationChanged.bind(this);
    this.handleStartDateChanged = this.handleStartDateChanged.bind(this);
    this.handlePreviewChanged = this.handlePreviewChanged.bind(this);
    this.handleIsExlusiveChanged = this.handleIsExlusiveChanged.bind(this);
    this.handleMultipleScanChanged = this.handleMultipleScanChanged.bind(this);
    this.handleMarketAutomationChanged = this.handleMarketAutomationChanged.bind(this);
    this.handleAnonymousChanged = this.handleAnonymousChanged.bind(this);
    this.handleRenewableChanged = this.handleRenewableChanged.bind(this);
    this.handleDurationIdChanged = this.handleDurationIdChanged.bind(this);
    this.handleHasDurationChanges = this.handleHasDurationChanges.bind(this);
    this.handleDurationIdChanged = this.handleDurationIdChanged.bind(this);
    this.handleOrderIndexChanged = this.handleOrderIndexChanged.bind(this);
    this.handleThirdPartIdChanged = this.handleThirdPartIdChanged.bind(this);
    this.handleDescriptionChanged = this.handleDescriptionChanged.bind(this);
    this.handleDefaultCheckBoxChanged = this.handleDefaultCheckBoxChanged.bind(this);
    this.handleDefaultCheckBoxThumbnailChanged = this.handleDefaultCheckBoxThumbnailChanged.bind(this);
    this.handleCountryChanged = this.handleCountryChanged.bind(this);
    this.handleStoreChanged = this.handleStoreChanged.bind(this);
    this.handleContentTypeKeyValueChange = this.handleContentTypeKeyValueChange.bind(this);
    this.handleCouponTypeChanged = this.handleCouponTypeChanged.bind(this);
    this.handleNavigationTo = this.handleNavigationTo.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.addAvailableHours = this.addAvailableHours.bind(this);
    this.handleDeleteHour = this.handleDeleteHour.bind(this);
    this.isRespectingTimeFormat = this.isRespectingTimeFormat.bind(this);
    this.handleDayChange = this.handleDayChange.bind(this);
    this.handleTimeChange = this.handleTimeChange.bind(this);
    this.handleMembershipChanged = this.handleMembershipChanged.bind(this);
    this.downloadUsersForCoupon = this.downloadUsersForCoupon.bind(this);
    this.handleRenewableUnitChange = this.handleRenewableUnitChange.bind(this);
    this.handleRenewableValueChange = this.handleRenewableValueChange.bind(this);
    this.handleIsRenewableWithCooldownChanged = this.handleIsRenewableWithCooldownChanged.bind(this);

    // Click & Collect
    this.handleRequiredProductsChanged = this.handleRequiredProductsChanged.bind(this);
    this.handleFreeProductsChanged = this.handleFreeProductsChanged.bind(this);
    this.handleValueChange = this.handleValueChange.bind(this);
    this.handleMinimumValueChange = this.handleMinimumValueChange.bind(this);

    this.getContentTypeKeys = this.getContentTypeKeys.bind(this);
  }

  componentDidMount() {
    this._dispatcherHelper.checkboxPreview = this.handlePreviewChanged;
    this._dispatcherHelper.checkBoxIsExclusive = this.handleIsExlusiveChanged;
    this._dispatcherHelper.checkBoxIsMultipleScan = this.handleMultipleScanChanged;
    this._dispatcherHelper.checkboxAnonymous = this.handleAnonymousChanged;
    this._dispatcherHelper.checkboxRenewable = this.handleRenewableChanged;
    this._dispatcherHelper.checkboxHasDuration = this.handleHasDurationChanges;

    this._dispatcherHelper.valueDurationId = this.handleDurationIdChanged;
    this._dispatcherHelper.checkboxDefaultImage = this.handleDefaultCheckBoxChanged;
    this._dispatcherHelper.checkboxDefaultThumbnail = this.handleDefaultCheckBoxThumbnailChanged;
    this._dispatcherHelper.checkboxMarketAutomation = this.handleMarketAutomationChanged;
    this._dispatcherHelper.checkboxIsRenewableWithCooldown = this.handleIsRenewableWithCooldownChanged;

    this._dispatcherHelper.valuePosId = this.handlePosIdChanged;
    this._dispatcherHelper.valueOrderIndex = this.handleOrderIndexChanged;
    this._dispatcherHelper.valueDurationId = this.handleDurationIdChanged;
    this._dispatcherHelper.valueValidityDuration = this.handleDurationChanged;

    this._dispatcherHelper.valueThirdPartId = this.handleThirdPartIdChanged;

    this._dispatcherHelper.dateEndDate = this.handleEndDateChanged;
    this._dispatcherHelper.dateStartDate = this.handleStartDateChanged;

    this._dispatcherHelper.translationTitle = this.handleTitleChanged;
    this._dispatcherHelper.translationDescription = this.handleDescriptionChanged;

    this._dispatcherHelper.numberIndexImage = this.setIndexImage;
    this._dispatcherHelper.numberIndexThumbnail = this.setIndexThumbnail;

    this._dispatcherHelper.uriImage = this.handleImage;
    this._dispatcherHelper.uriThumbnail = this.handleThumbnail;

    this._dispatcherHelper.valueValue = this.handleValueChange;
    this._dispatcherHelper.valueMinimum = this.handleMinimumValueChange;

    this._dispatcherHelper.valueRequiredProductIds = this.handleRequiredProductsChanged;
    this._dispatcherHelper.valueFreeProductIds = this.handleFreeProductsChanged;

    this._dispatcherHelper.valueCountry = this.handleCountryChanged;
    this._dispatcherHelper.valueStore = this.handleStoreChanged;

    this._dispatcherHelper.contentTypeKeyValue = this.handleContentTypeKeyValueChange;

    this._storesOperation.getallwithouttranslations((stores: any) => {
      this.setField({ stores: stores.data, isStoresLoading: false });
    });

    this.orderSourceOperations.get((orderSources: any) => {
      this.setField({ orderSources });
    });

    this.refreshBasketOrderTypes();
  }

  /* COMPONENT EVENTS */
  componentWillMount() { }

  /* OTHERS */

  changeTab(tab: any) {
    this.setField((previousState) => previousState.tab = tab);
  }

  isRespectingTimeFormat(value: string) {
    return this._regexTimeFormat.test(value);
  }

  fillAllPicturesWithDefault() {
    if (this.state().useDefaultImageChecked) {
      var state = this.state();
      var list = Helpers.fillAllImagesWithDefault(state.coupon.imageTranslation, state.languages) as TranslationValue[];
      this.setField((prevouisState) => prevouisState.coupon.imageTranslation = list)
    }
  }

  fillAllThumbnailsWithDefault() {
    if (this.state().useDefaultThumbnailChecked) {
      var state = this.state();
      var list = Helpers.fillAllImagesWithDefault(state.coupon.thumbnailTranslation, state.languages) as TranslationValue[];
      this.setField((prevouisState) => prevouisState.coupon.thumbnailTranslation = list)
    }
  }

  refreshBasketOrderTypes() {
    this.setField({ isLoadingBasketOrderTypes: true });
    this.basketOrderTypeOperations.get((data: BasketOrderType[]) => this.setField({ basketOrderTypes: data, isLoadingBasketOrderTypes: false }));
  }

  handleDeleteHour(index: number) {
    this.setField(previousState => previousState.coupon.availableHours.splice(index, 1));
  }

  handleTimeChange(hour: string, index: number, opening: boolean) {
    if (opening) {
      this.setField(previousState => previousState.coupon.availableHours[index].availableFrom = hour);
    } else {
      this.setField(previousState => previousState.coupon.availableHours[index].availableTo = hour);
    }
  }

  handleSavePressed() {
    if (
      this.state().coupon.posId == null ||
      this.state().coupon.titleTranslations.length != this.state().languages.length ||
      this.state().coupon.descriptionTranslations.length != this.state().languages.length ||
      !Helpers.areImagesComplete(this.state().coupon.thumbnailTranslation) ||
      !Helpers.areImagesComplete(this.state().coupon.imageTranslation)) {
      this.setField((previousState) => previousState.showErrors = true);
    }
    else {
      this.setField((previousState) => previousState.isLoading = true);
      this.saveCoupon(this.state().coupon);
    }
  }

  handleDayChange(value: any, openingHour: any) {
    openingHour.day = value.value;
  }

  getContentTypeKeys(): ContentTypeKey[] {
    return this.props().contentTypeKeys.filter((c: ContentTypeKey) => c.couponTypeId == this.props().coupon.couponType.id)
  }

  handleRenewableUnitChange(unit: LoyaltyRulesUnit) {
    this.setField(previousState => previousState.coupon.loyaltyRulesUnit = unit)
  }

  handleRenewableValueChange(value : number) {
    this.setField(previousState => previousState.coupon.loyaltyRulesUnitValue = value)
  }

  handleIsRenewableWithCooldownChanged(value : boolean) {
    this.setField(previousState => previousState.isRenewableWithCooldown = value)
    if (value) {
      this.setField(previousState => {
        previousState.coupon.loyaltyRulesUnit = this.props().units[0]
        previousState.coupon.loyaltyRulesUnitId = this.props().units[0].id
        previousState.coupon.loyaltyRulesUnitValue = this.props().units[0].defaultValue
      })
    }else {
      this.setField(previousState => {
        previousState.coupon.loyaltyRulesUnit = undefined
        previousState.coupon.loyaltyRulesUnitId = undefined
        previousState.coupon.loyaltyRulesUnitValue = undefined
      })
    }
  }

  /* SAVE */

  saveCoupon(newCoupon: any) { 
    let coupon = JSON.parse(JSON.stringify(newCoupon))
    var startResult = DateHelper.changeDateToBeValidUTC(coupon.StartDateInput);
    var endResult = DateHelper.changeDateToBeValidUTC(coupon.EndDateInput);
    coupon.startDate = startResult;
    coupon.endDate = endResult;

    for (var i = 0; i < this.props().languages.length; i++) {
      if (coupon.imageTranslation[i].url.includes("data:image"))
        coupon.imageTranslation[i].ImageData = coupon.imageTranslation[i].url.replace("data:image/png;base64,", "").replace("data:image/jpeg;base64,", "");
      else
        coupon.imageTranslation[i].ImageData = null;

      if (coupon.thumbnailTranslation[i].url.includes("data:image"))
        coupon.thumbnailTranslation[i].ImageData = coupon.thumbnailTranslation[i].url.replace("data:image/png;base64,", "").replace("data:image/jpeg;base64,", "");
      else
        coupon.thumbnailTranslation[i].ImageData = null;
    }
    
    if (coupon.isRenewable && this.state().isRenewableWithCooldown) {
      coupon.loyaltyRulesUnitId = coupon.loyaltyRulesUnit.id;
      coupon.loyaltyRulesUnit = null;
    }else{
      coupon.loyaltyRulesUnitValue = null;
      coupon.loyaltyRulesUnitId = null;
      coupon.loyaltyRulesUnit = null;
    }

    coupon.contentTypeKeyValue.map((contentTypeKeyValue: any) => {
      contentTypeKeyValue.contentTypeKey = null;
    })
    coupon.couponTypeId = coupon.couponType.id;
    coupon.couponType = null;


    if (coupon.id !== undefined) {
      this._couponsOperation.put(CouponProductMapper.fillCouponEntities(coupon), () => {
        this.props().refreshParent();
        this.showToast(ToastHelper.getToast("coupons.popup.success.updated", "success"), () => { }, 2500);
        this.closePopUp();
      }, this.errorManagement);
    }

    else {
      this._couponsOperation.post(CouponProductMapper.fillCouponEntities(coupon), () => {
        this.props().refreshParent();
        this.showToast(ToastHelper.getToast("coupons.popup.success.added", "success"), () => { }, 2500);
        this.closePopUp();
      }, this.errorManagement);
    }
  }

  errorManagement(error: any) {
    this.setField({ isLoading: false });
    ErrorHelper.showMessageError(error.response);
  }

  /* URI */

  private handleImage(base64: any) {
    var state = this.state();
    TranslationHelper.imageTranslation(state.coupon.imageTranslation, state.languages, state.index, base64);
    this.setField({ state });
    this.fillAllPicturesWithDefault();
  }

  private handleThumbnail(base64: any) {
    var state = this.state();
    TranslationHelper.imageTranslation(state.coupon.thumbnailTranslation, state.languages, state.indexThumbnail, base64);
    this.setField({ state });
    this.fillAllThumbnailsWithDefault();
  }

  /* VALUES */

  private handleThirdPartIdChanged(text: string) {
    this.setField((previousState) => previousState.coupon.thridPartId = text);
  }

  private handleOrderIndexChanged(text: string) {
    var number = NumberHelper.convertStringToInt(text);
    this.setField((previousState) => previousState.coupon.orderIndex = number);
  }

  private handlePosIdChanged(text: string) {
    this.setField((previousState) => previousState.coupon.posId = text);
  }

  private handleDurationChanged(text: string) {
    var number = NumberHelper.convertStringToInt(text);
    this.setField((previousState) => previousState.coupon.validityDuration = number);
  }

  private handleDurationIdChanged(text: string) {
    var number = NumberHelper.convertStringToInt(text);
    this.setField((previousState) => previousState.coupon.validityDurationUnit = number);
  }

  private handleValueChange(value: string) {
    this.setField((previousState) => previousState.coupon.discountValue = value);
  }

  private handleMinimumValueChange(value: string) {
    this.setField((previousState) => previousState.coupon.miniumValue = value);
  }

  private handleCountryChanged(value: Country[]) {
    var state = this.state();
    state.coupon.countryCoupons = [];

    value.forEach((country: Country) => {
      state.coupon.countryCoupons.push({ countryId: country.id, couponId: state.coupon.id });
    })

    this.setField({ state });
  }

  private handleStoreChanged(value: Store[]) {
    var state = this.state();
    state.coupon.storeIds = [];

    value.forEach((store: Store) => {
      state.coupon.storeIds.push(store.id)
    })
    this.setField({ state });
  }

  handleCouponTypeChanged(value: CouponType) {
    this.setField((previousState) => previousState.coupon.couponType = value);
  }
  /* DATE */

  private handleStartDateChanged(text: Date) {
    this.setField((previousState) => previousState.coupon.StartDateInput = text);
  }

  private handleEndDateChanged(text: Date) {
    this.setField((previousState) => previousState.coupon.EndDateInput = text);
  }

  /* TRANSLATIONS */

  private handleTitleChanged(text: any, index: any) {
    var state = this.state();
    TranslationHelper.translation(state.coupon.titleTranslations, state.languages, index, text);
    this.setField({ state });
  }

  private handleDescriptionChanged(text: any, index: any) {
    var state = this.state();
    TranslationHelper.translation(state.coupon.descriptionTranslations, state.languages, index, text);
    this.setField({ state });
  }

  /* CHECKBOXES */

  private handlePreviewChanged(value: boolean) {
    this.setField((previousState) => previousState.coupon.isPreview = value);
  }

  private handleIsExlusiveChanged(value: boolean) {
    this.setField((previousState) => previousState.coupon.isExclusive = value);
  }

  public handleMultipleScanChanged(value: boolean) {
    this.setField((previousState) => previousState.coupon.isMultipleScan = value);
  }

  private handleMarketAutomationChanged(value: boolean) {
    this.setField((previousState) => previousState.coupon.isMarketingAutomation = value);
  }

  private handleAnonymousChanged(value: boolean) {
    this.setField((previousState) => previousState.coupon.isAnonymousVisible = value);
  }

  private handleRenewableChanged(value: boolean) {
    this.setField((previousState) => previousState.coupon.isRenewable = value);
    if (!value) {
      this.setField((previousState) => {
        previousState.isRenewableWithCooldown = false
        previousState.coupon.loyaltyRulesUnit = undefined
        previousState.coupon.loyaltyRulesUnitId = undefined
        previousState.coupon.loyaltyRulesUnitValue = undefined
      });
    }
  }

  private handleDefaultCheckBoxChanged(value: boolean) {
    this.setField((previousState) => previousState.useDefaultImageChecked = value, () => {
      this.fillAllPicturesWithDefault();
    });
  }

  private handleDefaultCheckBoxThumbnailChanged(value: boolean) {
    this.setField((previousState) => previousState.useDefaultThumbnailChecked = value, () => {
      this.fillAllThumbnailsWithDefault();
    });
  }

  private handleHasDurationChanges(value: boolean)
  {
      this.setField((previousState) => previousState.coupon.hasDuration = value);
  }

  /* INDEX */

  private setIndexImage(index: any) {
    this.setField((previousState) => previousState.index = index);
  }

  private setIndexThumbnail(index: any) {
    this.setField((previousState) => previousState.indexThumbnail = index);
  }

  /* Products */
  private handleRequiredProductsChanged(value: Product[]) {
    var state = this.state();
    state.coupon.requiredProducts = [];

    value.forEach((product: Product) => {
      state.coupon.requiredProducts.push(product.id);
    })

    this.setField({ state });
  }

  private handleFreeProductsChanged(value: Product[]) {
    var state = this.state();
    state.coupon.freeProducts = [];

    value.forEach((product: Product) => {
      state.coupon.freeProducts.push(product.id);
    })

    this.setField({ state });
  }

  /* Content Type */

  private handleContentTypeKeyValueChange(value: any) {
    this.setField((previousState) => previousState.coupon.contentTypeKeyValue = value);
  }

  handleNavigationTo(page: string) {
    this.setField({ page });
    this.refreshBasketOrderTypes();
  }

  addAvailableHours(scheduleType: number) {
    var openingHour: CouponAvailableHour = {
      couponId: this.state().coupon.id!,
      day: 1,
      availableFrom: '',
      availableTo: '',
      scheduleTypeId: scheduleType,
    }

    this.setField((previousState) => previousState.coupon.availableHours.push(openingHour));
  }

  handleChange(value: boolean, orderSourceId: OrderSources, basketOrderTypeId: number) {
    let { couponOrderTypeOrderSources }: { couponOrderTypeOrderSources: CouponOrderTypeOrderSources[] } = this.state().coupon;

    if (value) {
      couponOrderTypeOrderSources.push({
        couponId: this.state().coupon.id!,
        orderSourceId,
        basketOrderTypeId,
      });

      return this.setField((previousState) => previousState.coupon.couponOrderTypeOrderSources = couponOrderTypeOrderSources);
    }

    couponOrderTypeOrderSources = couponOrderTypeOrderSources.filter(x => x.orderSourceId != orderSourceId || x.basketOrderTypeId != basketOrderTypeId);
    return this.setField((previousState) => previousState.coupon.couponOrderTypeOrderSources = couponOrderTypeOrderSources);
  }

  handleFile(file: File) {
    this.handleReading(file);
  }

  handleReading(file: File) {
    if (file != undefined) {

      //Read Excel File
      var fileReader = new FileReader();
      fileReader.onloadend = () => {
        // Leave that
        var binary = "";
        var bytes = new Uint8Array(fileReader.result as ArrayBuffer);
        var length = bytes.byteLength;
        for (var i = 0; i < length; i++) {
          binary += String.fromCharCode(bytes[i]);
        }
        this.TransformExcelToData(binary);
      }
      fileReader.readAsArrayBuffer(file);
    }
  }

  downloadUsersForCoupon() {
    this.setField({ isLoading: true });
    let ope = new CouponsOperations();
    var headers = ["Guid", "Email", "CardCode"];
    var jsonContent: any[] = [];
    var title = "Export for coupon " + this.state().coupon.id;
    ope.getByCouponId(this.state().coupon.id, (ret: any) => {
      /* Export XLS */
      ret.data.forEach((value: any) => {
        var element = "{";
        var row = "";
        row += JSON.stringify(headers[0]) + ":" + JSON.stringify(value.guid);
        row += ",";
        row += JSON.stringify(headers[1]) + ":" + JSON.stringify(value.email);
        row += ",";
        row += JSON.stringify(headers[2]) + ":" + JSON.stringify(value.loyaltyCard.code);
        element = element + row;
        element += "}";
        jsonContent.push(JSON.parse(element));
      });
      var wb = XLSX.utils.book_new();
      var o = XLSX.utils.json_to_sheet(jsonContent, { header: headers });
      XLSX.utils.book_append_sheet(wb, o, title);
      XLSX.writeFile(wb, title + ".xls");

      this.setField({ isLoading: false });
    }, () => {
      alert("Service is not avaible");
      this.setField({ isLoading: false });
    });
  }

  TransformExcelToData(data: any) {
    var headers: string[] = []
    var workbook: XLSX.WorkBook = XLSX.read(data, { type: "binary" });
    var range = XLSX.utils.decode_range(workbook.Sheets[workbook.SheetNames[0]]['!ref'] as string);

    var R = range.s.r;
    for (var C = range.s.c; C <= range.e.c; C++) {

      var cell = workbook.Sheets[workbook.SheetNames[0]][XLSX.utils.encode_cell({ c: C, r: R })]
      var hdr = "UNKNOWN";
      if (cell && cell.t) headers.push(hdr = XLSX.utils.format_cell(cell));

      if (hdr == "UNKNOWN") {
        break;
      }
    }
    var json = XLSX.utils.sheet_to_json(workbook.Sheets[workbook.SheetNames[0]]);
    var body: string[] = []

    json.forEach((v: any) => {
      body.push(v.Email);
    });

    let ope = new CouponsOperations();
    ope.activateById(this.state().coupon.id, body, () => {
      alert("Users have been imported for your coupon")
    },
      () => {
        alert("This service is not avaible")
      })
  }

  handleMembershipChanged(membershipTypeId: number, value: boolean) {
    let tmpCouponMembership = this.state().coupon.couponMemberships
    if (value) {
      tmpCouponMembership.push({ couponId: this.state().coupon.id ?? 0, membershipTypeId: membershipTypeId })
    } else {
      let index = tmpCouponMembership.findIndex((couponMembership: CouponMembership) => couponMembership.membershipTypeId === membershipTypeId)
      if (index >= 0) {
        tmpCouponMembership.splice(index, 1)
      }
    }
    this.setField((previousState) => previousState.coupon.couponMemberships = tmpCouponMembership);
  }

}