import {ContentViewModel,ContentView} from '@levelapp/softfabric';
import SettingsLineViewState from './SettingsLineViewState';
import SettingsLineViewProps from './SettingsLineViewProps';

export default class SettingsLineViewModel extends ContentViewModel<SettingsLineViewState, SettingsLineViewProps>
{
      /* CONSTRUCTOR */
    constructor(view: ContentView)
    {
        super(view);

        this.initialState({}); // Initialize your state here

        // Bindings

    }
}