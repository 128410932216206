 
import {ContentView} from '@levelapp/softfabric';
import PopupMenuItemViewState from './PopupMenuItemViewState';
import PopupMenuItemViewProps from './PopupMenuItemViewProps';
import PopupMenuItemViewStyle from './PopupMenuItemViewStyle';
import PopupMenuItemViewModel from './PopupMenuItemViewModel';
import {TranslationView} from '@levelapp/softfabric-ui';
import { View, TouchableWithoutFeedback } from 'react-native';

export default class PopupMenuItemView extends ContentView<PopupMenuItemViewProps, PopupMenuItemViewState, PopupMenuItemViewModel, PopupMenuItemViewStyle>
{
  /* CONSTRUCTOR */
  constructor(props:PopupMenuItemViewProps)
  {
    super(props);

    // Binding
    this.bind(new PopupMenuItemViewModel(this), new PopupMenuItemViewStyle(this));
  }

  /* RENDERING */
  render()
  {
    const { container ,  tabActive , tab , titleActive , titleInactive } = this.style; 

    return (
      <View style={container}>
        <TouchableWithoutFeedback onPress={() => this.props.handleChangeTab(this.props.item)}>
          <View style={this.props.activeItem == this.props.item ? tabActive : tab}>
            <View>
              <TranslationView style={this.props.activeItem == this.props.item ? titleActive : titleInactive}>
                {this.props.title}
              </TranslationView>
            </View>
          </View>
        </TouchableWithoutFeedback>
      </View>
    );
  }
}