import {ContentViewModel,ContentView, LanguageManager, Application, Managers} from '@levelapp/softfabric';
import BasketordertypesmanagementViewState from './BasketordertypesmanagementViewState';
import BasketordertypesmanagementViewProps from './BasketordertypesmanagementViewProps';
import BasketOrderTypeOperations from '../../../../../../../../../Transfer/BasketOrderTypeOperations';
import BasketOrderType from '../../../../../../../../../Common/DTO/BasketOrderType';
import TranslationValue from '../../../../../../../../../Common/DTO/TranslationValue';
import ToastHelper from '../../../../../../../../../Common/Helpers/ToastHelper';

export default class BasketordertypesmanagementpopupViewModel extends ContentViewModel<BasketordertypesmanagementViewState, BasketordertypesmanagementViewProps>
{
    _basketOrderTypeOperation : BasketOrderTypeOperations;
    _basketOrderTypeToDelete : BasketOrderType | undefined ;

    _languageManager : LanguageManager;
      /* CONSTRUCTOR */
    constructor(view: ContentView)
    {
        super(view);

        this.initialState({
            showPopup : false,
            isCreating : false,
            confirmationPopup : false,
            basketOrderTypes : [],
            basketOrderType : undefined,
            languages : this.props().languages,
            labelPopup : "",
            contentPopup : "",
        }); 

        // Bindings
        this.refresh = this.refresh.bind(this);
        this.deleteBasketOrderType = this.deleteBasketOrderType.bind(this);
        this.handleCreateBasketOrderType = this.handleCreateBasketOrderType.bind(this);
        this.handleSuccessDeleted = this.handleSuccessDeleted.bind(this);
        this.handleCloseManagement = this.handleCloseManagement.bind(this);
        this.handleSuccessBasketOrderTypes = this.handleSuccessBasketOrderTypes.bind(this);
        this.handleBackPopupPressed = this.handleBackPopupPressed.bind(this);
        this.handleBasketOrderTypeNameChanged = this.handleBasketOrderTypeNameChanged.bind(this);

        this._basketOrderTypeOperation =  new BasketOrderTypeOperations();
        this._languageManager = Application.current.resolve<LanguageManager>(Managers.LANGUAGE_MANAGER);
    }

    componentDidMount()
    {
        this.refresh();
    }

    refresh()
    {
        this._basketOrderTypeOperation.get(this.handleSuccessBasketOrderTypes);
    }

    handleSuccessBasketOrderTypes(data : BasketOrderType[])
    {
        var state = this.state();
        state.basketOrderTypes = data;
        state.basketOrderType = this.initialBasketOrderType();
        this.setField({state});
    }

    initialBasketOrderType() 
    { 
        let basketOrderType : BasketOrderType = {
            "id" : 0,
            "labelTranslations": ([] as TranslationValue []),
        };
        this.state().languages.forEach(languageKey => {
            basketOrderType.labelTranslations.push({
                "id" : 0,
                "value": "",
                "language": {
                    "key": languageKey.key,
                }
            });
        });
        return basketOrderType;
    }

    deleteBasketOrderType()
    {
        this._basketOrderTypeOperation.delete((this._basketOrderTypeToDelete as BasketOrderType), this.handleSuccessDeleted);
    }

    handleSuccessDeleted()
    {
        this.showToast(ToastHelper.getToast("allergens.popup.success.deleted","success"),() => {},2500);
        this.setField({contentPopup : '' , confirmationPopup : false , titlePopup : 'PropertyDeleted' , showPopup : false });
        this.refresh()
    }

    handleDeleteBasketOrderType(basketOrderType: BasketOrderType) {
        this._basketOrderTypeToDelete = basketOrderType;
        // this._basketOrderTypeOperation.getProductsUsingAllergen(basketOrderType, (data: Product[]) => {
        //     if (data.length == 0) {
        //         this.setField({contentPopup : '' , confirmationPopup : true , titlePopup : 'generic.confirmation.delete' , showPopup : true});
        //     }
        //     else {

        //         var products = '';
        //         data.forEach((product: Product) => {
        //             products = products + product.posId + ', '
        //         });

        //         this.setField({contentPopup : products , confirmationPopup : true , titlePopup : 'products.popup.warning.allergen' , showPopup : true});
        //     }
        // })
    }

    handleBackPopupPressed() {
        alert("handleBackPopupPressed")
        this.setField({showPopup : false});
    }

    handleCloseManagement()
    {
        this.props().handleBackPressed();
    }



    handleBasketOrderTypeNameChanged(value : string, language : string )
    {
        try
        {
            this.setField((previousState) => (((previousState.basketOrderType as BasketOrderType).labelTranslations as TranslationValue[]).find((titleTranslation: any) => { return titleTranslation.language.key == language }) as TranslationValue).value = value)
        }catch(error)
        {
            alert('error');
        }

    }

    handleCreateBasketOrderType() {
        if (this.hasTitleForeachLanguage()) {
            this.setField({isCreating : true});
            this._basketOrderTypeOperation.post((this.state().basketOrderType as BasketOrderType), () => {
                this.setField({isCreating : false});
                this.showToast(ToastHelper.getToast("allergens.popup.success.added","success"),() => {},2500);
                this.refresh();
            })
        } else {
            //TODO: show toast
        }
    }


    private hasTitleForeachLanguage() {
        var nbLanguage = this.state().languages.length;
        var i = 0;
        var hasTitle = true;
        while (i < nbLanguage && hasTitle) {
            hasTitle = (this.state().basketOrderType as BasketOrderType).labelTranslations[i].value != '';
            i++;
        }
        return hasTitle;
    }
}