export default class DurationUnitHelper
{
    private static _durationUnit  :  any[] =[
        { title: 'Seconds', value: 0 },
        { title: 'Minutes', value: 1 },
        { title: 'Hours', value: 2 },
        { title: 'Days', value: 3 },
        { title: 'Weeks', value: 4 },
        { title: 'Months', value: 5 },
        { title: 'Years', value: 6 }
    ]


    static getDurationUnit()
    {
        return this._durationUnit;
    }

    static getSelectedUnit(validityDurationUnit : number)
    {
        return this._durationUnit.find((element: any) => { return element.value == validityDurationUnit })
    }
}