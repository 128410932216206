import {ContentViewStyle} from '@levelapp/softfabric';
import {StyleProp,TextStyle,ViewStyle} from 'react-native';

export default class ExtendedDropDownViewStyle extends ContentViewStyle
{
    page: StyleProp<ViewStyle> = {
        flex: 1
    }

    inputTitle: StyleProp<TextStyle> = {
        fontSize: 14,
        fontWeight: '600',
        color: '#3F3F3F',
        fontFamily : 'Montserrat SemiBold',
        marginBottom : 13,
    };

    inputContainer : StyleProp<ViewStyle> = {
        alignSelf: 'flex-start',
        paddingRight : 15,
        paddingBottom : 15,
    };
}