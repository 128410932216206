 
import {ContentView} from '@levelapp/softfabric';
import ImageButtonViewState from './ImageButtonViewState';
import ImageButtonViewProps from './ImageButtonViewProps';
import ImageButtonViewStyle from './ImageButtonViewStyle';
import ImageButtonViewModel from './ImageButtonViewModel';
import { Image, TouchableWithoutFeedback } from 'react-native';

export default class ImageButtonView extends ContentView<ImageButtonViewProps, ImageButtonViewState, ImageButtonViewModel, ImageButtonViewStyle>
{
  /* CONSTRUCTOR */
  constructor(props:ImageButtonViewProps)
  {
    super(props);

    this.state = {
      image: props.image
    }

    // Binding
    this.bind(new ImageButtonViewModel(this), new ImageButtonViewStyle(this));
  }

  /* RENDERING */
  render()
  {
    return (
      <div onMouseEnter={this.binding.handleMouseEnter} onMouseLeave={this.binding.handleMouseLeave} style={this.props.style}>
        <TouchableWithoutFeedback onPress={() => {this.props.onClick()}}>
          <Image source={this.state.image} style={{ height: this.props.imageHeight, width: this.props.imageWidth }} resizeMode="stretch"></Image>
        </TouchableWithoutFeedback>
      </div>
    );
  }
}