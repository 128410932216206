import PaginationConstants from '../Constants/PaginationConstants';
export default class PaginationHelper 
{

    static basicResearchPush(currentPage : number , itemPerPage : number , columnSort : string , sortOrder : string , userLanguage : string , search : string , isSorting : boolean , isResearching : boolean )
    {
        let parameters = [];
        parameters.push(PaginationHelper.pushPage(currentPage));
        parameters.push(PaginationHelper.pushItemPage(itemPerPage));
        if (isSorting) {
            parameters.push(PaginationHelper.pushColumnSort(columnSort));
            parameters.push(PaginationHelper.pushSortOrder(sortOrder));
            parameters.push(PaginationHelper.pushUserLanguage(userLanguage));
        }
        if (isResearching) {
            parameters.push(PaginationHelper.pushSearch(search));
        }
        return parameters;
    }


    static pushPage(value : any)
    {
        return { key: PaginationConstants.PAGE, value: value }
    }
    
    static pushItemPage(value : any)
    {
        return { key: PaginationConstants.ITEM_PAGE, value: value }
    }

    static pushColumnSort(value : any)
    {
        return { key: PaginationConstants.COLUMN_SORT, value: value }
    }

    static pushSortOrder(value : any)
    {
        return { key: PaginationConstants.SORT_ORDER, value: value }
    }

    static pushSearch(value : any)
    {
        return { key: PaginationConstants.SEARCH, value: value }
    }

    static pushSearchColumn(value : any)
    {
        return { key: PaginationConstants.SEARCH_COLUMN, value: value }
    }

    static pushAction(value : any)
    {
        return { key: PaginationConstants.ACTION, value: value }
    }

    static pushUserLanguage(value : any)
    {
        return { key: PaginationConstants.USER_LANGUAGE, value: value }
    }


    static pushCustomKey(key : string , value : any)
    {
        return { key: key, value: value }
    }
}