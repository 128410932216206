import StoreGroupService from "src/Proxy/StoreGroupService";

import { Operation } from "@levelapp/softfabric";
import AddStoreGroup from "src/Common/DTO/AddStoreGroup";

export default class StoreGroupOperations extends Operation {
  service: StoreGroupService;

  constructor() {
    super();
    this.service = new StoreGroupService();
  }

  get(query?: string) {
    return this.service.get(query);
  }

  post(store: AddStoreGroup) {
    return this.service.post(store);
  }

  delete(id: number) {
    return this.service.delete(id);
  }

  update(productGroup: AddStoreGroup) {
    return this.service.update(productGroup);
  }
}