import {ContentViewModel,ContentView} from '@levelapp/softfabric';
import PicturesViewState from './PicturesViewState';
import PicturesViewProps from './PicturesViewProps';

export default class PicturesViewModel extends ContentViewModel<PicturesViewState, PicturesViewProps>
{
      /* CONSTRUCTOR */
    constructor(view: ContentView)
    {
        super(view);

        this.initialState({}); // Initialize your state here

        // Bindings

    }
}