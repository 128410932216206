 
import {ContentView} from '@levelapp/softfabric';
import LanguageViewState from './LanguageViewState';
import LanguageViewProps from './LanguageViewProps';
import LanguageViewStyle from './LanguageViewStyle';
import LanguageViewModel from './LanguageViewModel';
import { View } from 'react-native';
import TableView from '../../../../../../../Common/Components/TabelView/TableView';
import HeaderView from '../../../../components/header/HeaderView';

export default class LanguageView extends ContentView<LanguageViewProps, LanguageViewState, LanguageViewModel, LanguageViewStyle>
{
  /* CONSTRUCTOR */
  constructor(props:LanguageViewProps)
  {
    super(props);

    // Binding
    this.bind(new LanguageViewModel(this), new LanguageViewStyle(this));
  }

  /* RENDERING */
  render()
  {
    return (
      <View style={this.style.container}>
        <View style={this.style.box}>
          
          <HeaderView
            title={"language.title"}
            description={"language.description"}
            shouldShowButton={true}
            onButtonPress={this.binding.addField}
            buttonText={"language.button.add"}
          />
          <View style={this.style.boxContainer}>

            <TableView
              headerTextStyle={this.style.tableHeader}
              backgroundStepColor={this.style.stepColor}
              rowStyle={this.style.rowStyle}
              nothingToDisplayText={this.binding.translate("component.table.noresults")}
              isLastColumnActions={true}
              data={this.state.tableData}
              isLoading={this.state.isLoading}
              headers={this.state.tableHeaders}
              onPageChange={(page: number) => this.binding.onPageChange(page)}
              handleSortChange={() => {}}
              nbPage={this.state.nbPage}
              currentPage={this.state.currentPage - 1}
              isSortable={false}/>
          </View>
        </View>
      </View>
    );
  }
}