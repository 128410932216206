 
import { ContentView } from '@levelapp/softfabric';
import QrPopupViewState from './QrPopupViewState';
import QrPopupViewProps from './QrPopupViewProps';
import QrPopupViewStyle from './QrPopupViewStyle';
import QrPopupViewModel from './QrPopupViewModel';
import { Text, View } from 'react-native';
import QRCode from 'qrcode.react';

export default class QrPopupView extends ContentView<QrPopupViewProps, QrPopupViewState, QrPopupViewModel, QrPopupViewStyle>
{
  /* CONSTRUCTOR */
  constructor(props: QrPopupViewProps)
  {
    super(props);

    // Binding
    this.bind(new QrPopupViewModel(this), new QrPopupViewStyle(this));
  }

  /* RENDERING */
  render()
  {
    return (
      <View style={this.style.container}>
        <QRCode value={this.props.content} renderAs={"svg"} size={200} />
        <Text style={{ marginTop: 20 }}>{this.props.content}</Text>
      </View>
    );
  }
}