import { ContentViewModel, ContentView, Application, LanguageManager, Managers } from '@levelapp/softfabric';
import BasketHistoryPopupViewState from './BasketHistoryPopupViewState';
import BasketHistoryPopupViewProps from './BasketHistoryPopupViewProps';
import BasketStatusHistoriesOperation from '../../../../../../../Transfer/BasketStatusHistoriesOperation';

export default class BasketHistoryPopupViewModel extends ContentViewModel<BasketHistoryPopupViewState, BasketHistoryPopupViewProps>
{
    private _historyOperation: BasketStatusHistoriesOperation;

    language = Application.current.resolve<LanguageManager>(Managers.LANGUAGE_MANAGER).language;

    /* CONSTRUCTOR */
    constructor(view: ContentView)
    {
        super(view);

        this.initialState({
            isLoading: true,
            histories: []
        });

        this._historyOperation = new BasketStatusHistoriesOperation();

        // Bindings
    }

    async componentDidMount()
    {
        const response = await this._historyOperation.get(this.props().basket.id);
        if (response && response.data)
        {
            return this.setField({ histories: response.data.data, isLoading: false });
        }
        alert('Error retrieving history for this order');
        return this.setField({ isLoading: false });
    }
}