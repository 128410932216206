import {ContentShellViewModel, ContentShellView} from '@levelapp/softfabric';
import ShellCountryViewState from './ShellCountryViewState';
import ShellCountryViewProps from './ShellCountryViewProps';
 
import CountryView from './pages/country/CountryView';
import EnumModules from '../../../../../Common/Constants/EnumModules';
import CountryV2 from './pages/country-v2/CountryV2';

export default class ShellCountryViewModel extends ContentShellViewModel<ShellCountryViewState, ShellCountryViewProps>
{

    constructor(view : ContentShellView)
    {
        super(view);

        this.registerEntryPoint('main');
        this.register(process.env.REACT_APP_ISCOUNTRIES_V2 === 'true' ? CountryV2 : <CountryView />, 'main');

        this.initialisation();
    }

    getModuleId()
    {
        return EnumModules.COUNTRIES;
    }

}