import { Operation } from "@levelapp/softfabric";
import AddDeliveryCategory from "src/Common/DTO/AddDeliveryCategory";
import DeliveryCategoriesService from "src/Proxy/DeliveryCategoriesService";


export default class DeliveryCategoriesOperations extends Operation {
  service: DeliveryCategoriesService;

  constructor() {
    super();
    this.service = new DeliveryCategoriesService();
  }

  post(deliveryCategories: AddDeliveryCategory[]) {
    return this.service.post(deliveryCategories);
  }

  get() {
    return this.service.get();
  }
}