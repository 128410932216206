import { Service, HttpManager, Application, Managers } from '@levelapp/softfabric';

export default class LoyaltyRulesSettingsService extends Service
{
    httpClient : HttpManager;

    constructor()
    {
        super();
        this.httpClient = Application.current.resolve(Managers.HTTP_MANAGER);
        this.httpClient.handleSomethingWentWrong = (response : any) => {
            console.warn('Erreur : ' + response.data);
        };
    }

    get(callback : Function = () => {})
    {
        this.httpClient
            .get('LoyaltyRulesSettings')
            .then((response : any) => 
            {
                if (response)
                {
                    if (response.data)
                    {
                        callback(response.data);
                    }
                }
            });
    }

    put(user:any, callback : Function = () => {})
    {
        this.httpClient
            .put('LoyaltyRulesSettings', [], user)
            .then((response : any) => 
            {
                if (response)
                {
                      /*if (response.data)
                    {
                        callback(response.data);
                    }*/
                    callback("");
                }
            });
    }
}