import { Operation } from "@levelapp/softfabric";
import CompositionService from "../Proxy/CompositionService";

export default class CompisitionOperations extends Operation
{
    service : CompositionService;

    constructor()
    {
        super();
        this.service = new CompositionService();
    }

    get(parameters : any[], callback : Function)
    {
        this.service.get(parameters, callback);
    }

    post(composition:any, callback : Function)
    {
        this.service.post(composition, callback);
    }

    put(composition:any, callback : Function)
    {
        this.service.put(composition, callback);
    }

    delete(composition:any, callback : Function)
    {
        this.service.delete(composition, callback);
    }
    save(stringList:any, callback : Function)
    {
        this.service.save(stringList, callback);
    }
}