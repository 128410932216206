 
import { View } from 'react-native';
import { ContentView } from '@levelapp/softfabric';
import MainInformationViewState from './MainInformationViewState';
import MainInformationViewProps from './MainInformationViewProps';
import MainInformationViewStyle from './MainInformationViewStyle';
import MainInformationViewModel from './MainInformationViewModel';
import LineView from '../../../../../../components/line/LineView';
import ExtendedTextInputView from '../../../../../../components/extendedtextinput/ExtendedTextInputView';
import ExtendedDatePickerView from '../../../../../../components/extendeddatepicker/ExtendedDatePickerView';
import TableView from '../../../../../../../../../Common/Components/TabelView/TableView';
import ExtendedCheckBoxView from '../../../../../../components/extendedcheckbox/ExtendedCheckBoxView';

export default class MainInformationView extends ContentView<MainInformationViewProps, MainInformationViewState, MainInformationViewModel, MainInformationViewStyle>
{
  /* CONSTRUCTOR */
  constructor(props:MainInformationViewProps)
  {
    super(props);

    // Binding
    this.bind(new MainInformationViewModel(this), new MainInformationViewStyle(this));
  }

  /* RENDERING */
  render()
  {

    const { container , leftblock , centerblock , rightblock } = this.style;
    
    return (
      <View style={container}>
            {/* UpperSide */}
            <View style={{flexDirection : 'row', zIndex:1}}>

              {/* Id side */}
              <View style={leftblock}>
                  {this.props.promocodebatch.id === undefined &&
                    <ExtendedCheckBoxView isTranslation title={'form.freeProduct'} value={this.props.promocodebatch.isFreeProduct} onValueChange={(value : boolean) => this.props.handleCheckboxChange('isFreeProduct',value)}/> 
                  }
                <View style={{flexDirection : 'row'}}>
                  {this.props.promocodebatch.isFreeProduct === false &&
                    <ExtendedTextInputView
                      title={"form.discount"}
                      showError={false}
                      value={this.props.promocodebatch.value}
                      onChangeText={(text: string) => this.props.handleValueChange('value',text)}
                    />
                  }
                  {this.props.promocodebatch.isFreeProduct === true &&
                    <ExtendedTextInputView
                      title={"form.productid"}
                      showError={false}
                      value={this.props.promocodebatch.product.posId}
                      onChangeText={(text: string) => this.props.handleValueChange('productId',text)}
                    />
                  }
                  <ExtendedTextInputView
                    title={"form.requiredProduct"}
                    showError={false}
                    value={this.props.promocodebatch.requiredProduct.posId}
                    onChangeText={(text: string) => this.props.handleValueChange('requiredProductId',text)}
                  />
                </View>
              </View>

              {/* DatePicker center container */}
              <View style={centerblock}>
                <View style={{zIndex : 2}}>
                  <ExtendedDatePickerView
                  zIndex={2}
                  title={'form.startdate'}
                  selected={this.props.promocodebatch.StartDateInput}
                  placeholder={this.binding.translate('form.startdate.placeholder')}
                  handleOnChange={(date : Date) => this.binding.handleOnChangeDate('startdate',date)}
                />
                </View>

                <View style={{zIndex : 1}}>
                  <ExtendedDatePickerView
                    zIndex={1}
                    title={'form.enddate'}
                    selected={this.props.promocodebatch.EndDateInput}
                    ref={(r : any) => this.binding._refEndDate = r}
                    placeholder={this.binding.translate('form.enddate.placeholder')}
                    handleOnChange={(date : Date) => this.props.handleDateChange('enddate',date)}
                  />
                </View>
              </View> 
            </View>

            <View style={rightblock}>

            </View>

            {/* Number of promocdoes section */}
            <LineView/>
            {this.props.promocodebatch.id === undefined &&
              <View>
                <View style={{flex : 2, flexDirection: 'column'}}>
                  {this.props.promocodebatch.id === undefined &&
                    <ExtendedCheckBoxView isTranslation title={'form.singleCode'} value={this.props.promocodebatch.isSingleCode} onValueChange={(value : boolean) => this.props.handleCheckboxChange('isSingleCode',value)}/> 
                  }
                  {(this.props.promocodebatch.isSingleCode === false || this.props.promocodebatch.isSingleCode === undefined) &&
                    <ExtendedTextInputView
                      title={"form.numberOfCodes"}
                      showError={false}
                      value={this.props.promocodebatch.NumberOfCodes}
                      onChangeText={(text: string) => this.props.handleValueChange('number',text)}
                    />
                  }
                  {this.props.promocodebatch.isSingleCode === true &&
                    <ExtendedTextInputView
                      title={"form.code"}
                      showError={false}
                      value={this.props.promocodebatch.code}
                      onChangeText={(text: string) => this.props.handleValueChange('promocode',text)}
                    />
                  }
                </View>
              </View>
              }

            {/* Promocode list */}
            <LineView/>

            <View>
            {this.props.promocodebatch.id !== undefined &&
              <TableView
                headerTextStyle={this.style.tableHeader}
                backgroundStepColor={this.style.stepColor}
                rowStyle={this.style.rowStyle}
                nothingToDisplayText={this.binding.translate("component.table.noresults")}
                isLastColumnActions={true}
                data={this.state.tableData}
                isLoading={this.state.isLoading}
                headers={this.state.tableHeaders}
                onPageChange={(page: number) => this.binding.onPageChange(page)}
                handleSortChange={() => {this.binding.onSortChange()}}
                nbPage={this.state.nbPage}
                currentPage={this.state.currentPage - 1}
                isSortable={false}/>
            }
            </View>
          </View>
    );
  }
}