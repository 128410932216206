import { ContentViewStyle } from '@levelapp/softfabric';
import { StyleProp } from 'react-native';

export default class DeliverySettingsPopupViewStyle extends ContentViewStyle {
  kitchenModus: StyleProp<any> = {
    width: 800,
    top: 0,
    backgroundColor: 'white',
    justifyContent: 'space-between'
  };
}