 
import { ContentView } from '@levelapp/softfabric';
import RefreshProductViewState from './RefreshProductViewState';
import RefreshProductViewProps from './RefreshProductViewProps';
import RefreshProductViewStyle from './RefreshProductViewStyle';
import RefreshProductViewModel from './RefreshProductViewModel';
import { TranslationView } from '@levelapp/softfabric-ui';
import { TouchableWithoutFeedback, View } from 'react-native';


export default class RefreshProductView extends ContentView<RefreshProductViewProps, RefreshProductViewState, RefreshProductViewModel, RefreshProductViewStyle>
{
  /* CONSTRUCTOR */
  constructor(props: RefreshProductViewProps)
  {
    super(props);
    this.bind(new RefreshProductViewModel(this), new RefreshProductViewStyle(this));
  }

  /* RENDERING */
  render()
  {
    return (
      <TouchableWithoutFeedback onPress={() => { this.binding.refreshCache() }}>
        <View style={this.style.refreshBtn} >
          <TranslationView style={this.style.refreshBtnTxt}>
            orders.refreshProducts
          </TranslationView>
        </View>
      </TouchableWithoutFeedback>
    );
  }
}