import { ContentViewModel, ContentView } from '@levelapp/softfabric';
import UsersPopUpViewState from './UsersPopUpViewState';
import UsersPopUpViewProps from './UsersPopUpViewProps';
//import { LoyaltyRulesKeys } from '../../../../../../../Common/Enums/LoyaltyRulesKeys';
import HistoryOperations from '../../../../../../../Transfer/HistoryOperations';
//import { TableRow } from '@levelapp/softfabric-ui';
import UsersOperations from '../../../../../../../Transfer/UsersOperations';
import DispatcherManager from '../../../../../../../Common/Managers/DispatcherManager';
import TicketOperations from '../../../../../../../Transfer/TicketOperation';
import Helpers from '../../../../../../../Common/Helpers/Helpers';
import ToastHelper from '../../../../../../../Common/Helpers/ToastHelper';
import LoyaltyCard from '../../../../../../../Common/DTO/LoyaltyCard';
import User from 'src/Common/DTO/User';

export default class UsersPopUpViewModel extends ContentViewModel<UsersPopUpViewState, UsersPopUpViewProps>
{
    historyOperation: HistoryOperations;
    ticketOperation: TicketOperations;
    usersOperation: UsersOperations;
    _dispatcherManager: DispatcherManager;


    constructor(props: ContentView)
    {
        super(props);

        /* Initial State */
        this.initialState({
            user: this.props().user,
            isLoading: false,
            showPopup: false,
            showPopupCallback: undefined,
            titlePopup: "",
            contentPopup: "",
            isAdvancedInformationsLoading: true
        });

        this._dispatcherManager = new DispatcherManager();

        this.historyOperation = new HistoryOperations();
        this.ticketOperation = new TicketOperations();
        this.usersOperation = new UsersOperations();



        //console.log(this.state().user);
        /* BINDINGS */
        this.handleSavePressed = this.handleSavePressed.bind(this);
        this.handleLastnameChanged = this.handleLastnameChanged.bind(this);
        this.handleBackPopupPressed = this.handleBackPopupPressed.bind(this);
        this.handleFirstnameChanged = this.handleFirstnameChanged.bind(this);
        this.handleBanToggled = this.handleBanToggled.bind(this);
        this.handleBanToggledResult = this.handleBanToggledResult.bind(this);
        this.handlePreviewChanged = this.handlePreviewChanged.bind(this);
        this.showInternalPopup = this.showInternalPopup.bind(this);
        this.handleClick = this.handleClick.bind(this);
        this.refreshMainInfo = this.refreshMainInfo.bind(this);
        this.handleLoyaltyCardCodeChanged = this.handleLoyaltyCardCodeChanged.bind(this);
        this.handleGetUserById = this.handleGetUserById.bind(this);
    }

    /* COMPONENT EVENTS */
    componentDidMount()
    {

        this._dispatcherManager.valueFirstname = this.handleFirstnameChanged;
        this._dispatcherManager.valueLastname = this.handleLastnameChanged;
        this._dispatcherManager.valueLoyaltyCardCode = this.handleLoyaltyCardCodeChanged;
        this._dispatcherManager.checkboxPreview = this.handlePreviewChanged;

        this.usersOperation.getUserById(this.state().user.id as number, this.handleGetUserById, () => {})
    }



    /* HANDLERS */
    handleGetUserById(user : User)
    {
        this.setField({ user : user , isAdvancedInformationsLoading : false })
    }

    handleSavePressed()
    {
        this.setField((previousState) => previousState.isLoading = true);
        this.props().saveUser(this.state().user);
    }

    handleBanToggled()
    {
        //this.usersOperation.banUser(this.state().user, this.handleBanToggledResult);
        this.handleBanToggledResult();
    }

    handleBanToggledResult()
    {
        const state = this.state();
        const newValue = !state.user.isBanned;
        state.user.isBanned = newValue;
        this.setField(state);
    }


    handleBackPopupPressed()
    {
        this.props().refresh();
        this.setField((previousState) => previousState.showPopup = false);
    }

    handleClick()
    {
        if (this.state().showPopupCallback != undefined)
        {
            (this.state().showPopupCallback as Function)(
                () =>
                {
                    this.showToast(ToastHelper.getToast('users.maininformation.section.delete.success.updated', 'success'), () => { }, 2500);
                    this.props().refresh();
                    Helpers.closePopup();
                });
        } else
        {
            this.handleBackPopupPressed();
        }
    }

    showInternalPopup(titlePopupKey: string, popupContentKey: string, callback: Function)
    {
        this.setField({ titlePopup: titlePopupKey, contentPopup: this.translate(popupContentKey), showPopup: true, showPopupCallback: callback });
    }

    refreshMainInfo()
    {
        this.props().refresh();
        Helpers.closePopup();
    }


    /* VALUES */

    private handleFirstnameChanged(firstname: string)
    {
        this.setField((previousState) => previousState.user.firstName = firstname);
    }

    private handleLastnameChanged(lastname: string)
    {
        this.setField((previousState) => previousState.user.lastName = lastname);
    }

    private handlePreviewChanged(value: boolean)
    {
        this.setField((previousState) => previousState.user.isPreview = value);
    }

    private handleLoyaltyCardCodeChanged(LoyaltyCardCode: string)
    {
        this.setField((previousState) => (previousState.user.loyaltyCard as LoyaltyCard).code = LoyaltyCardCode);
    }
}
