 
import {
  TouchableWithoutFeedback,
  View,
  Animated,
  Image,
  Text,
} from 'react-native';

import { ContentView } from '@levelapp/softfabric';

import MenuItemViewState from './MenuItemViewState';
import MenuItemViewProps from './MenuItemViewProps';
import MenuItemViewStyle from './MenuItemViewStyle';
import MenuItemViewModel from './MenuItemViewModel';
import PopupMenuView from '../../../popupmenu/PopupMenuView';
import SingleProductPopUpMenuView from '../singleproductpopupmenu/SingleProductPopUpMenuView';

export default class MenuItemView extends ContentView<MenuItemViewProps, MenuItemViewState, MenuItemViewModel, MenuItemViewStyle>
{
  /* CONSTRUCTOR */
  constructor(props: MenuItemViewProps) {
    super(props);

    // Binding
    this.bind(new MenuItemViewModel(this), new MenuItemViewStyle(this));
  }

  /* RENDERING */
  render() {
    const {
      underMenu,
      selected,
      display,
      childrenCount,
      title,
      isSingleProduct,
      product,
      productGroup,
    } = this.props;

    const height = this.binding._heightAnimation.interpolate(
      {
        inputRange: [0, 1],
        outputRange: [50, (((childrenCount || 1) + (childrenCount ? 1 : 0)) * 55)],

      });

    const borderWidth = (underMenu || selected) && !isSingleProduct ? 0 : 1;

    return (
      <View style={{ paddingVertical: display ? 2 : 0, backgroundColor: 'white' }}>
        <Animated.View style={{ height: display ? height : 0 }}>
          {display &&
            <Animated.View>
              <View>
                <View style={[this.style.link, { position: 'absolute', zIndex: 1 }]}>
                  <div
                    style={{ width: '100%', height: '100%' }}
                    onMouseEnter={() => this.binding.handleAnimation(1)}
                    onMouseLeave={() => this.binding.handleAnimation(0)}
                  >
                    <Animated.View style={[this.style.animatedView]} />
                  </div>
                </View>
                <View style={[
                  this.style.link,
                  {
                    backgroundColor: selected ? 'rgba(216, 63, 40, 0.11)' : 'white',
                    borderTopWidth: borderWidth,
                    borderBottomWidth: borderWidth,
                  }
                ]}>
                  <View
                    style={[this.style.row, this.binding.getTextStyle()]}
                  >
                    <View style={this.style.item}>
                      <TouchableWithoutFeedback
                        onPress={this.binding.handlePressTick}
                      >
                        <Image
                          resizeMode='contain'
                          style={this.style.image}
                          source={this.binding.getImage()}
                        />
                      </TouchableWithoutFeedback>
                      <TouchableWithoutFeedback
                        onPress={this.binding.handleMenuPress}
                      >
                        <Text
                          numberOfLines={1}
                          // @ts-ignore
                          title={title}
                          style={[
                            {
                              fontSize: underMenu ? 14 : 15,
                              maxWidth: underMenu ? 210 : 180,
                              width: '100%',
                            }
                          ]}>
                          {title || '(No name)'}
                        </Text>
                      </TouchableWithoutFeedback>
                    </View>

                    {!underMenu && <PopupMenuView onPopupClose={this.props.onPopupClose} productGroup={productGroup!} />}
                    {isSingleProduct && underMenu && product && (
                      <SingleProductPopUpMenuView
                        id={product?.id}
                        onRemove={this.props.onRemove!}
                      />
                    )}
                  </View>
                </View>
              </View>
              {this.state.active && (
                <View style={{ height: 55 }}>
                  {this.props.children}
                </View>
              )}
            </Animated.View>}
        </Animated.View>
      </View>
    );
  }
}