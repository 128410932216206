 
import { ContentView } from '@levelapp/softfabric';
import RoundButtonViewState from './RoundButtonViewState';
import RoundButtonViewProps from './RoundButtonViewProps';
import RoundButtonViewStyle from './RoundButtonViewStyle';
import RoundButtonViewModel from './RoundButtonViewModel';
import { TouchableWithoutFeedback, View } from 'react-native';

export default class RoundButtonView extends ContentView<RoundButtonViewProps, RoundButtonViewState, RoundButtonViewModel, RoundButtonViewStyle>
{
  /* CONSTRUCTOR */
  constructor(props: RoundButtonViewProps)
  {
    super(props);


    // Binding
    this.bind(new RoundButtonViewModel(this), new RoundButtonViewStyle(this));
  }

  /* RENDERING */
  render()
  {
    const { container, block } = this.style;

    

    return (
      <View>
        <View style={[this.props.shouldUseDefaultPadding && container,this.props.style]}>
          <TouchableWithoutFeedback onPress={() => this.props.handleOnPress()}>
            <View style={[block,this.props.type == 'normal' ? this.style.normal : this.style.little,this.props.backgroundColor != undefined && { backgroundColor : this.props.backgroundColor}]}>
              {this.props.children}
            </View>
          </TouchableWithoutFeedback>
        </View>
      </View>
    );
  }
}