 
import {ContentShellView} from '@levelapp/softfabric';
import ShellFaqViewState from './ShellFaqViewState';
import ShellFaqViewProps from './ShellFaqViewProps';
import ShellFaqViewStyle from './ShellFaqViewStyle';
import ShellFaqViewModel from './ShellFaqViewModel';
import { View } from 'react-native';

export default class ShellFaqView extends ContentShellView<ShellFaqViewProps, ShellFaqViewState, ShellFaqViewModel, ShellFaqViewStyle>
{
  /* CONSTRUCTOR */
  constructor(props:ShellFaqViewProps)
  {
    super(props);

    // Binding
    this.bind(new ShellFaqViewModel(this), new ShellFaqViewStyle(this));
  }

  /* RENDERING */
  render()
  {
    return (
      
      <View style={{flex : 1}}>
        {this.getSwitch()}
      </View>
    );
  }
}