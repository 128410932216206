import TranslationValue from '../DTO/TranslationValue';
import Language from '../DTO/Language';
import Helpers from './Helpers';
import ImageTranslation from '../DTO/ImageTranslation';

export default class TranslationHelper
{
    static translation(translations: TranslationValue[] , languages : Language[], languageId : number , text : string)
    {
        var response = translations.find(x => x.language.id == languageId);
        if (response) {
            response.value = text;
            response.language = Helpers.getLanguageById(languages,languageId) as Language;
        }
        else {
            let trans = { value: text, language: Helpers.getLanguageById(languages,languageId) as Language , languageId : languageId };
            translations.push(trans);
        }
    }

    static image(images : ImageTranslation[] , languages : Language[], languageId : number , url : any )
    {
        var response = images.find(x => x.language.id == languageId);
        if (response) {
            response.url = url;
            response.language = Helpers.getLanguageById(languages,languageId) as Language;
        }
    }

    static imageTranslation(images : TranslationValue[] , languages : Language[], languageId : number , url : any )
    {
        var response = images.find(x => x.language.id == languageId);
        if (response) {
            response.url = url;
            response.language = Helpers.getLanguageById(languages,languageId) as Language;
        }
    }


    
}