 
import { TouchableOpacity, View } from 'react-native';
import { ContentView } from '@levelapp/softfabric';
import { TranslationView } from '@levelapp/softfabric-ui';

import ToggleInsertTypeViewState from './ToggleInsertTypeViewState';
import ToggleInsertTypeViewProps from './ToggleInsertTypeViewProps';
import ToggleInsertTypeViewStyle from './ToggleInsertTypeViewStyle';
import ToggleInsertTypeViewModel from './ToggleInsertTypeViewModel';

export default class ToggleInsertTypeView extends ContentView<ToggleInsertTypeViewProps, ToggleInsertTypeViewState, ToggleInsertTypeViewModel, ToggleInsertTypeViewStyle>
{
  /* CONSTRUCTOR */
  constructor(props: ToggleInsertTypeViewProps) {
    super(props);

    // Binding
    this.bind(new ToggleInsertTypeViewModel(this), new ToggleInsertTypeViewStyle(this));
  }

  /* RENDERING */
  render() {
    const { selectedItem, onItemPress } = this.props;

    const items = [
      {
        id: 'group',
        title: 'tools.product.add.toggle.productGroup',
        style: {
          borderTopLeftRadius: 8,
          borderBottomLeftRadius: 8,
        }
      },
      {
        id: 'single',
        title: 'tools.product.add.toggle.singleProduct',
        style: {
          borderTopRightRadius: 8,
          borderBottomRightRadius: 8,
        }
      }
    ];

    return (
      <View style={this.style.container}>
        {items.map((item) => (
          <TouchableOpacity
            onPress={() => onItemPress(item.id)}
            style={[
              this.style.item,
              item.style,
              item.id === selectedItem && this.style.selected,
            ]}
          >
            <TranslationView
              style={item.id === selectedItem && {
                color: 'white',
              }}
            >
              {item.title}
            </TranslationView>
          </TouchableOpacity>
        ))}
      </View>
    );
  }
}