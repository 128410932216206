import {ContentViewModel,ContentView} from '@levelapp/softfabric';
import PointsSectionViewState from './PointsSectionViewState';
import PointsSectionViewProps from './PointsSectionViewProps';
import PointUpdate from '../../../../../../../../../../../Common/DTO/PointUpdate';
import User from '../../../../../../../../../../../Common/DTO/User';
import LoyaltyCard from '../../../../../../../../../../../Common/DTO/LoyaltyCard';
import LoyaltyRulesSettingsOperations from '../../../../../../../../../../../Transfer/LoyaltyRulesSettingsOperations';
import { LoyaltyRulesKeys } from '../../../../../../../../../../../Common/Enums/LoyaltyRulesKeys';
import UsersOperations from '../../../../../../../../../../../Transfer/UsersOperations';

export default class PointsSectionViewModel extends ContentViewModel<PointsSectionViewState, PointsSectionViewProps>
{
    
    usersOperation : UsersOperations;
    settingsOperation: LoyaltyRulesSettingsOperations;

      /* CONSTRUCTOR */
    constructor(view: ContentView)
    {
        super(view);

        this.initialState(
            {
                isLoadingPoints : false,
                settings: [],
                points : 0,
                user : this.props().user,
                generatedCode: '',
                text : ""
            }); // Initialize your state here

        // Bindings
        this.usersOperation = new UsersOperations();
        this.settingsOperation = new LoyaltyRulesSettingsOperations();

        this.updatePoints = this.updatePoints.bind(this);
        this.handlePointsAdded = this.handlePointsAdded.bind(this);
        this.handlePointsChanged = this.handlePointsChanged.bind(this);
        this.handleCodeGeneration = this.handleCodeGeneration.bind(this);
        this.handleTextChanged = this.handleTextChanged.bind(this);

    }

    componentDidMount()
    {
        this.settingsOperation.get((data: any) => {
            this.setField({ settings: data.data.loyaltyRules, isLoading: false });
        })
    }

    handlePointsChanged(points: string) {
        this.setField((previousState) => previousState.points = parseInt(points));
    }

    handleTextChanged(text: string) {
        this.setField((previousState) => previousState.text = text);
    }


    handlePointsAdded(): void {

        const payload: PointUpdate = { Id: ((this.props().user as User).loyaltyCard as LoyaltyCard).id, Points: this.state().points, Message : this.state().text };
        
        if(!this.additionalPointsValid()) { // Dont know why this part was commented
            // this.setField((previousState) => previousState.titlePopup = "PointsError");
            // this.setField((previousState) => previousState.showPopup = true);
            return;
        }
        this.setField((previousState) => previousState.isLoadingPoints = true);
        this.updatePoints(payload);
    }

    additionalPointsValid(): boolean {
        const rules = this.state().settings.filter(x => x.loyaltyRulesKeyId == LoyaltyRulesKeys.MaxPointsByUser);

        if(rules.length === 0){
            console.error('Loyalty Rule not set !');
            return false;
        }

        const maxPoints = rules[0].value;
        const total = ((this.props().user as User).loyaltyCard as LoyaltyCard).points + this.state().points;
        return total >= 0 && total <= maxPoints && this.state().points !== 0;

    }

    handleCodeGeneration() {
        const payload: PointUpdate = { Id: ((this.props().user as User).loyaltyCard as LoyaltyCard).id, Points: this.state().points, Message: this.state().text };
        
        if(!this.additionalPointsValid()) { // Dont know why this part was commented
            // this.setField((previousState) => previousState.titlePopup = "PointsError");
            // this.setField((previousState) => previousState.showPopup = true);
            return;
        }
        this.setField((previousState) => previousState.isLoadingPoints = true);
        this.setField({generatedCode: ''});
        this.generateActivationCode(payload);

    }

    generateActivationCode(parameters: PointUpdate) {
        this.usersOperation.generateActivationCode(parameters, (payload: any) => {
            this.setField({ generatedCode: payload.data, isLoadingPoints: false });
        })
    }

    updatePoints(parameters: PointUpdate) {
        this.usersOperation.updatePoints(parameters, (userRet: any) => {
            this.setField({ user: userRet.data, isLoadingPoints: false });            
            document.dispatchEvent(new Event('pointsAdded'));
        })
    }
}