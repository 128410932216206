import {ContentShellViewModel, ContentShellView} from '@levelapp/softfabric';
import ShellMembershipViewState from './ShellMembershipViewState';
import ShellMembershipViewProps from './ShellMembershipViewProps';
 
import EnumModules from 'src/Common/Constants/EnumModules';
import MembershipView from './pages/membership/MembershipView';

export default class ShellMembershipViewModel extends ContentShellViewModel<ShellMembershipViewState, ShellMembershipViewProps>
{

    constructor(view : ContentShellView)
    {
        super(view);

        this.registerEntryPoint('route'); // TODO : change with the route of your entry point
        this.register(<MembershipView/> ,'route'); // TODO : register your routes with the JSX (param1) and the route (param2)

        this.initialisation();
    }

    getModuleId()
    {
        return EnumModules.MEMBERSHIP; // TODO : change the return value with your ModuleId
    }

}