 
import {ContentView} from '@levelapp/softfabric';
import MenuconfiguratorViewState from './MenuconfiguratorViewState';
import MenuconfiguratorViewProps from './MenuconfiguratorViewProps';
import MenuconfiguratorViewStyle from './MenuconfiguratorViewStyle';
import MenuconfiguratorViewModel from './MenuconfiguratorViewModel';
import { View,Image} from 'react-native';
// import HeaderView from '../../../../components/header/HeaderView';
// import ResearchInputView from '../../../../../../../Common/Components/researchinput/ResearchInputView';
import SortableTree from 'react-sortable-tree';
import 'react-sortable-tree/style.css';
import FileExplorerTheme from 'react-sortable-tree-theme-full-node-drag';
import './composition.css'
import ReactLogo from './assets/delete.svg';
import ReactLogoEditQuantity from './assets/edit-quantity.svg';
import ReactLogoEdit from './assets/edit.svg';
import ReactLogoPlus from './assets/add.svg';
import ReactLogoTag from './assets/tag.svg';
import ReactLogoCopyPast from './assets/copypaste.svg';
import ReactLogoTagOne from './assets/tagOne.svg';
import { LottieView } from '@levelapp/softfabric-ui';
import Constants from '../../../../../../../Common/Constants/Constants';

import {TranslationView} from '@levelapp/softfabric-ui';
import LinearGradient from 'react-native-web-linear-gradient';
import ButtonView from '../../../../../../../Common/Components/Button/ButtonView';

export default class MenuconfiguratorView extends ContentView<MenuconfiguratorViewProps, MenuconfiguratorViewState, MenuconfiguratorViewModel, MenuconfiguratorViewStyle>
{
  /* CONSTRUCTOR */
  constructor(props:MenuconfiguratorViewProps)
  {
    super(props);

    // Binding
    this.bind(new MenuconfiguratorViewModel(this), new MenuconfiguratorViewStyle(this));
  }

  /* RENDERING */
  render()
  {
    const { boxSubtitle , title , subTitle , buttonBox , boxTitle,container , boxItem  } = this.style;

    return (
      <View style={container}>
        <View style={boxItem}>
            <LinearGradient start={{x: 0, y: 0}} end={{x: 1, y: 0}} colors={[Constants.GradiantStartColor, Constants.GradiantEndColor]} style={boxTitle}>
        <View style={boxSubtitle}>
          <TranslationView style={title}>
          menuconfigurator.title
          </TranslationView>

          <TranslationView style={subTitle}>
          menuconfigurator.description
          </TranslationView>

        </View>
        <View style={buttonBox}>
        <ButtonView style={{marginRight:150}} isLoading={this.state.isRefreshMenuLoading} handleClick={() => {this.binding.refreshMenu()}} backgroundColor='white' textColor={Constants.MainColor}>
            <TranslationView upperCase={true}>
            menuconfigurator.button.refreshmenu
            </TranslationView>
          </ButtonView>
        <ButtonView style={{margin:5}} isLoading={false} handleClick={() => {this.binding.displayViewAddProduct(null)}} backgroundColor='white' textColor={Constants.MainColor}>
            <TranslationView upperCase={true}>
            menuconfigurator.button.add
            </TranslationView>
          </ButtonView>
          <ButtonView isLoading={this.state.saveLoading} handleClick={() => {this.binding.saveComposition()}} backgroundColor='white' textColor={Constants.MainColor}>
            <View style={{flexDirection:"row"}}>
            
            <TranslationView upperCase={true}>
            menuconfigurator.button.save
            </TranslationView>
            {this.state.treeModified ?(
            <Image
              style={{marginLeft:10, alignItems:"flex-end",width:18,height:18}}
              source={require('./assets/exclamation.svg').default}
            />
            ):(
              <Image
              style={{marginLeft:10, alignItems:"flex-end",width:18,height:18}}
              source={require('./assets/checked.svg').default}
            />
            )
            }
            </View>
          </ButtonView>
          
        </View>
      </LinearGradient>
        {/* {this.state.treeModified &&
          <View style={{flexDirection: 'row',
          justifyContent: 'flex-end',
          alignItems:'center',flex:1,marginTop:15,marginRight:15}}>
          <Text style={{borderRadius:15,backgroundColor:"red",padding:10,color:"white"}}>
              Modification are not saved
                  </Text>
          </View>
        } */}

            <View style={this.style.boxItemContainer}>
            {!this.state.isLoading ? (
            <View style={{height:600}}>

              <SortableTree
                    style={{}}
                    treeData={this.state.treeData}
                    onChange={(treeData:any) => {this.binding.setField({ treeData:treeData })}}
                    theme={FileExplorerTheme}
                    canNodeHaveChildren={ (node:any) => { return node.group }}
                    onMoveNode={(moveDetail:any)=>{ this.binding.changeParentInTree(moveDetail);}}
                    canDrop={(node:any)=>{ return this.binding.canDrop(node);}}
                    generateNodeProps={(node:any)=>{
                      if(node.node.group && this.binding.haveChildrenGroup(node)){
                      return ({title:(<div className="title">
                                          <img className="titleImg" src={ReactLogoTag}/>
                                          <span className="titleText"> {node.node.title}</span>
                                      </div>),
                      buttons:[
                          <img id="test" width="40" height="25" src={ReactLogoPlus} onClick={()=>{this.binding.displayViewAddProduct(node)}}/>,
                          <img id="test" width="40" height="25" src={ReactLogoEditQuantity} onClick={()=>{this.binding.editField(node)}}/>,
                          <img id="test" width="40" height="25" src={ReactLogoEdit} onClick={() => { this.binding.editFieldParent(node) }} />,
                          <img id="test" width="40" height="25" src={ReactLogoCopyPast} onClick={()=>{this.binding.displayViewClone(node)}}/>,
                          <img id="test" width="40" height="25" src={ReactLogo} onClick={()=>{this.binding.deletePopupVerification(node);}}/>]
                      })
                    }else if(node.node.group){
                      return ({title:(<div className="title">
                                          <img className="titleImg" src={ReactLogoTag}/>
                                          <span className="titleText"> {node.node.title}</span>
                                      </div>),
                      buttons:[
                          <img id="test" width="40" height="25" src={ReactLogoPlus} onClick={()=>{this.binding.displayViewAddProduct(node)}}/>,
                         <img id="test" width="40" height="25" src={ReactLogoEdit} onClick={() => { this.binding.editFieldParent(node) }} />,
                          <img id="test" width="40" height="25" src={ReactLogoCopyPast} onClick={()=>{this.binding.displayViewClone(node)}}/>,
                          <img id="test" width="40" height="25" src={ReactLogo} onClick={()=>{this.binding.deletePopupVerification(node);}}/>]
                      })
                    }
                    else{
                      return ({title:(<div className="title"><img className="titleImg" src={ReactLogoTagOne}/><span className="titleText"> {node.node.title}</span></div>),
                        buttons:[<img id="test" width="40" height="25" src={ReactLogo} onClick={()=>{this.binding.deletePopupVerification(node)}}/>
                        ]
                        })
                    }
                    }}
              />
            </View>
            )
            :
            (
              <LottieView height={150} width={150} source={require('../../../../../../../Common' + Constants.LottieAnimationPath)} />
            )}
          </View>
         </View>
      </View>

    );
  }
}