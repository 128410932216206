import {ContentViewModel,ContentView} from '@levelapp/softfabric';
import DetailCompositionViewState from './DetailCompositionViewState';
import DetailCompositionViewProps from './DetailCompositionViewProps';
import DispatcherManager from '../../../../../../../../../Common/Managers/DispatcherManager';
import NumberHelper from '../../../../../../../../../Common/Helpers/NumberHelper';
import ToastHelper from '../../../../../../../../../Common/Helpers/ToastHelper';

export default class DetailCompositionViewModel extends ContentViewModel<DetailCompositionViewState, DetailCompositionViewProps>
{
    _dispatcherManager : DispatcherManager;
      /* CONSTRUCTOR */
    constructor(view: ContentView)
    {
        super(view);

        this.initialState({
            index: 0,
            tab: "main",
            isSaving: false,
            showErrors: false,
            indexThumbnail: 0,
            windowTab : 'product',
            isLoadingAllergens : true,
            node: this.props().node,
            languages: this.props().languages, 
            composition:{
            creationDate :this.props().node.node.composition.creationDate,
            lastModificationDate : this.props().node.node.composition.lastModificationDate,
            isMandatory:this.props().node.node.composition.isMandatory,
            quantityMin:this.props().node.node.composition.quantityMin,
            quantityMax:this.props().node.node.composition.quantityMax,
            parentProduct : this.props().node.node.composition.parentProduct,
            parentProductId : this.props().node.node.composition.parentProductId,
            childProduct : this.props().node.node.composition.childProduct,
            childProductId : this.props().node.node.composition.childProductId
            }      
        }); 

        // Bindings     
       
        this.handleSavePressed = this.handleSavePressed.bind(this);
        this.handleQantityMaxChanged = this.handleQantityMaxChanged.bind(this);
        this.handleQantityMinChanged = this.handleQantityMinChanged.bind(this);
        this.handleIsMandatoryChanged = this.handleIsMandatoryChanged.bind(this);

        

        this._dispatcherManager = new DispatcherManager();

    }
    componentDidMount()
    {
        this._dispatcherManager.valueQuantityMax = this.handleQantityMaxChanged;
        this._dispatcherManager.valueQuantityMin = this.handleQantityMinChanged;
        this._dispatcherManager.checkboxIsMandatory = this.handleIsMandatoryChanged;

    }

    handleSavePressed(){
        let node = this.state().node
        let compo=this.state().composition
        
        if(node.node.composition.isMandatory != compo.isMandatory ||
            node.node.composition.quantityMax != compo.quantityMax||
            node.node.composition.quantityMin != compo.quantityMin
            ){
            this.props().save();
            this.showToast(ToastHelper.getToast("menuConfigurator.popup.success.updated.values", 'success'), () => {}, 2500)    
        }
        node.node.composition.isMandatory = compo.isMandatory;
        node.node.composition.quantityMax = compo.quantityMax;
        node.node.composition.quantityMin = compo.quantityMin;
        this.setField({node:node});    

        this.closePopUp()

    }
    private handleQantityMaxChanged(value: string)
    {
        var number = NumberHelper.convertStringToInt(value);
        let composition=this.state().composition;
        composition.quantityMax = number
        if(composition.quantityMin>composition.quantityMax){
           composition.quantityMin = composition.quantityMax
        }
        this.setField({composition:composition});
    }
    private handleQantityMinChanged(value: string)
    {
        var number = NumberHelper.convertStringToInt(value);
        let composition=this.state().composition;
        composition.quantityMin = number
        if(composition.quantityMin>composition.quantityMax){
            composition.quantityMax = number

        }
        this.setField({composition:composition});
    }
    private handleIsMandatoryChanged(value: boolean)
    {
        let composition=this.state().composition;
        composition.isMandatory = value
        this.setField({composition:composition});
    }

}