import { Operation } from "@levelapp/softfabric";
import KitchenService from "../Proxy/KitchenService";

export default class KitchenOperations extends Operation
{
    service: KitchenService;

    constructor()
    {
        super();
        this.service = new KitchenService();
    }
    async getByDate(date: Date, storeId: number, language?: string)
    {
        return await this.service.getByDate(date, storeId, language);
    }

    async getById(id: number, language?: string)
    {
        return await this.service.getById(id, language);
    }

    async updateStatus(basketId: number, lines: number[], statusId: number)
    {
        return await this.service.updateStatus(basketId, lines, statusId);
    }
}