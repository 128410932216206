import {ContentViewStyle} from '@levelapp/softfabric';
import { StyleProp, TextStyle, ViewStyle } from 'react-native';

export default class DeliveryProviderPopupViewStyle extends ContentViewStyle{
    title: StyleProp<TextStyle> = {
        textAlign: 'left',
        color: 'white',
        fontSize: 24,
        fontWeight: '600',
        marginTop: 20,
        marginLeft: 60,
        paddingBottom: 20,
        display: "flex",
        flexDirection: "column",
        flexBasis: "85%"
    };

    section: StyleProp<ViewStyle> = {
        alignSelf: 'flex-end',
        marginVertical: 15,
    };

    inputTitle: StyleProp<TextStyle> = {
        fontSize: 14,
        fontWeight: '600',
        color: '#3F3F3F',
        fontFamily: 'Montserrat SemiBold'
    };

    input: StyleProp<any> = {
        width: 140,
        paddingHorizontal: 15,
        borderRadius: 8,
        boxShadow: '0 5px 14px 0 rgba(0,0,0,0.1)',
    };

    errorInput: StyleProp<any> = {
        width: 140,
        paddingHorizontal: 15,
        borderRadius: 8,
        borderColor: 'red',
        borderWidth: 1,
    };

    deleteButton: StyleProp<ViewStyle> = {
        marginLeft: 15, 
        alignSelf: 'center',
        alignItems: 'center'
    }

    createButton: StyleProp<ViewStyle> = {
        height : 20,
        width : 200
    };

    NoButton: StyleProp<ViewStyle> =
    {       
        height: 42,
        width: 119,
        marginRight : '5px'
    }  

   YesButton: StyleProp<ViewStyle> =
    {       
        height: 42,
        width: 119,
        marginLeft : '5px'
    } 

    footer: StyleProp<ViewStyle> = {
        display: 'flex',
        flexDirection: 'row',
        margin: 'auto',
        marginTop:75,
        marginBottom:100
    };

    NoButtonText: StyleProp<TextStyle> = {
        color: '#9C9C9C',
     };

     titlePopup: StyleProp<TextStyle> = {
        textAlign: 'center',
        color: '#4A495C',
        fontSize: 21,
        fontWeight: '600',
        marginTop: 100
    };

    contentPopup: StyleProp<TextStyle> = {
        textAlign: 'center',
        color: '#4A495C',
        fontSize: 21,
        fontWeight: '600',
    };
}