 
import { ContentView } from '@levelapp/softfabric';
import OrderViewState from './OrderViewState';
import OrderViewProps from './OrderViewProps';
import OrderViewStyle from './OrderViewStyle';
import OrderViewModel from './OrderViewModel';
import { TranslationView, ButtonView } from '@levelapp/softfabric-ui';
import { View, Text, TouchableWithoutFeedback, Image } from 'react-native';
import { OrderTypes } from '../../../../../../../Common/Enums/OrderTypes';
import QRCode from 'qrcode.react';
import TimerView from '../timer/TimerView';
import { OrderStatus } from '../../../../../../../Common/Enums/OrderStatus';
import Constants from '../../../../../../../Common/Constants/Constants';
import PriceHelper from '../../../../../../../Common/Helpers/PriceHelper';
import BasketLine from '../../../../../../../Common/DTO/BasketLine';


export default class OrderView extends ContentView<OrderViewProps, OrderViewState, OrderViewModel, OrderViewStyle>
{
  /* CONSTRUCTOR */
  constructor(props: OrderViewProps) {
    super(props);

    // Binding
    this.bind(new OrderViewModel(this), new OrderViewStyle(this));
  }

  renderOrderType(orderType: OrderTypes) {
    if (orderType === OrderTypes.TAKEOUT) {
      return <Image source={require('../../assets/TakeOut.svg').default} style={{ width: 39, height: 21 }} />;
    }

    return <Image source={require('../../assets/DineIn.svg').default} style={{ width: 39, height: 21 }} />;
  }

  renderOrderHeader(isSequel: boolean, headerColor: string, orderType: OrderTypes, time: string) {
    if (isSequel) {
      return (
        <View style={this.style.nextScreen}>
          <TranslationView style={[this.style.orderHeaderSequelLabel, this.binding.getFontSizeTxtSeeMore()]}>kitchenapp.nextscreen</TranslationView>
        </View>
      );
    }
    return (
      <View style={[this.style.orderHeader, { backgroundColor: headerColor }]}>
        <View style={this.style.firstLineContainer}>

          <View style={this.style.orderNumber}>
            <Text style={this.binding.getFontSizeTxt()}>{this.props.deskNumber ?? '-'}</Text>
          </View>

          <TouchableWithoutFeedback onPress={() => this.binding.handleSettingsPressed()}>
            <View style={{ height: 48, width: 48, justifyContent: 'flex-start', alignItems: 'flex-end' }}>
              <Image source={require('../../assets/menu.svg').default} style={{ width: 5, height: 21 }} />
            </View>
          </TouchableWithoutFeedback>

        </View>
        <View style={this.style.secondLineContainer}>
          <View style={{ flexDirection: 'row' }}>
            {this.renderOrderType(orderType)}
            {this.binding.getOrderSource()}
          </View>
          <Text style={this.binding.getFontSizeTxt()}>{time}</Text>
          {this.props.showTimer && this.props.status == OrderStatus.Preparing && (
            <TimerView setIsTimeout={(value: boolean) => { this.setState({ isTimeOut: value }) }} startDate={this.props.lastModificationDate} />
          )}
        </View>
      </View>
    );
  }

  renderOrderBottom(hasSequel: boolean, hasNextStatus: boolean, handleNext: Function, total: number) {
    if (hasSequel) {
      return (
        <View style={this.style.seeMore}>
          <TranslationView style={[this.binding.getFontSizeTxtSeeMore(), { color: '#0C8EF4' }]}>kitchenapp.seemore</TranslationView>
        </View>
      );
    }

    const priceView = Constants.showAmountInKitchenApp ? (
      <View style={this.style.orderPriceContainer} >
        <TranslationView style={[this.style.orderPriceLabel, this.binding.getFontSizeTxt()]}>kitchenapp.total</TranslationView>
        <View>
          <Text style={[this.style.orderPrice, this.binding.getFontSizeTxt()]}>{PriceHelper.format(total)}</Text>
        </View>
      </View>
    ) : (<View />);
    
    if (hasNextStatus) {
      return (
        <View style={{ flex: 1, backgroundColor: 'white', justifyContent: 'flex-end' }}>
          {priceView}
          <ButtonView animation={'Bounce'} borders={'None'} isLoading={false} handleClick={handleNext} backgroundColor={'#358DFF'} >
            <TranslationView>kitchenapp.nextstatus</TranslationView>
          </ButtonView >
        </View>
      )
    }

    return priceView;
  }

  renderRemark(line: BasketLine) {
    if (line.remark && line.remark.length > 0) {
      return (
        <View style={this.style.remark}>
          <Text style={this.style.remarkHeader}>Note: </Text>
          <Text style={this.style.remarkText}>{line.remark}</Text>
        </View>
      );
    };
    return;
  }

  renderBasketRemark(remark: string) {
    return (
      <View style={this.style.remark}>
        <Text style={this.style.remarkHeader}>Note: </Text>
        <Text style={this.style.remarkText}>{remark}</Text>
      </View>
    );
  }

  /* RENDERING */
  render() {

    const { headerColor, time, lines, handleNext, handlePrevious, handleLog, orderType, total, handleHistory, hasSequel, maxHeight, height, isSequel, hasNextStatus, zIndex, isSelected } = this.props;

    return (
      <View style={[this.binding.getOrderContainer(), this.state.isTimeOut ? this.style.borderTimeOut : this.style.borderDefault, { height: height ?? 'auto', maxHeight: maxHeight ?? 'auto', zIndex: zIndex }]}>

        {this.renderOrderHeader(isSequel, headerColor, orderType, time)}
        <View style={this.style.basketLines}>
          {lines.filter(x => !x.parentBasketLineId).map((line, j) => {
            const subLines = this.binding.getAllSubLines(line.basketLineId, lines, this.binding.getIndexStarter(true, line));
            return (
              <View style={[this.style.basketLine, { borderBottomWidth: 1, borderColor: '#DDDDDD', }]} key={j}>
                <TouchableWithoutFeedback disabled={line.couponUUID == null} onPress={() => this.props.handleShowQR(line.couponUUID)}>
                  <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
                    <Text style={this.binding.getFontSizeTxt()}>{`${line.quantity}x`}</Text>
                    <Text style={[this.binding.getFontSizeTxt(), { marginLeft: 10, paddingRight: 5, width:'100%', textTransform: 'capitalize' }]}>
                      {line.title.toLowerCase()}
                    </Text>
                    {line.couponUUID != null ? (
                      <Image source={require('../../assets/qr.svg').default} style={{ height: 15, width: 15 }} />
                    ) :
                      line.totalVatInclude != 0 && Constants.showAmountInKitchenApp && (<Text style={[this.binding.getFontSizeTxt(), { whiteSpace: 'nowrap' } as any]}>{PriceHelper.format(line.totalVatInclude)}
                      </Text>)}
                  </View>
                </TouchableWithoutFeedback>

                {subLines.length != 0 && (
                  <ul style={{ marginBlockStart: 0, marginBlockEnd: 0 }}>
                    {subLines.filter(x => x.title != null || x.totalVatInclude != 0).map((childLine, k) => {
                      return (
                        <View style={{ marginLeft: 0, paddingVertical: 5 }} key={k}>
                          <li>
                            <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
                              <Text style={[this.binding.getFontSizeTxt(), { color: this.binding.getChildLineColor(childLine), flex: 1, paddingRight: 5 }]}>{childLine.quantity > 1 && `${childLine.quantity}x `}{childLine.title}</Text>
                              {childLine.totalVatInclude != 0 && Constants.showAmountInKitchenApp && (<Text style={[this.binding.getFontSizeTxt(), { color: this.binding.getChildLineColor(childLine) }]}>{
                                PriceHelper.format(childLine.totalVatInclude)}
                              </Text>)}
                            </View>
                            {this.renderRemark(childLine)}
                          </li>
                        </View>
                      )
                    })}
                    {this.renderRemark(line)}
                  </ul>

                )}
                {this.props.remark && this.renderBasketRemark(this.props.remark)}
              </View>
            )
          })}
        </View>

        {this.renderOrderBottom(hasSequel, hasNextStatus, handleNext, total)}

        {this.props.isSettingsMenuOpen && (
          <View style={[this.style.menu, { zIndex: zIndex }]}>
            {/*<TouchableWithoutFeedback onPress={() => alert('TODO')}>
                <TranslationView style={this.style.menuOption}>kitchenapp.printticket</TranslationView>
              </TouchableWithoutFeedback>*/}

            {this.props.hasPreviousStatus && (
              <TouchableWithoutFeedback onPress={() => handlePrevious()}>
                <View>
                  <TranslationView style={this.style.menuOption}>kitchenapp.previousstatus</TranslationView>
                </View>
              </TouchableWithoutFeedback>
            )}

            <TouchableWithoutFeedback onPress={() => handleHistory()}>
              <View>
                <TranslationView style={this.style.menuOption}>kitchenapp.history</TranslationView>
              </View>
            </TouchableWithoutFeedback>

            <TouchableWithoutFeedback onPress={() => handleLog()}>
              <View>
                <TranslationView style={this.style.menuOption}>kitchenapp.log</TranslationView>
              </View>
            </TouchableWithoutFeedback>

            {Constants.showKitchenUserQr && this.props.cardCode && (
              <View style={{ alignItems: 'center', borderTopWidth: 1, borderTopColor: '#DDDDDD' }}>
                <Text style={[this.binding.getFontSizeTxt(), { marginVertical: 5 }]}>Card code :</Text>
                <QRCode value={this.props.cardCode} />
                <Text style={[this.binding.getFontSizeTxt(), { marginVertical: 20 }]}>{this.props.cardCode}</Text>
              </View>
            )}

          </View>
        )
        }

        {
          isSelected && (
            <>
              <View style={this.style.overlay} />
              {!isSequel && (
                <View style={this.style.selectedContainer}>
                  <Image source={require('../../assets/selected.svg').default} style={{ height: 43, width: 43 }} />
                </View>
              )}
            </>
          )
        }
      </View >
    );
  }
}