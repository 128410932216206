import {ContentViewModel,ContentView} from '@levelapp/softfabric';
import LabelViewViewState from './LabelViewViewState';
import LabelViewViewProps from './LabelViewViewProps';

export default class LabelViewViewModel extends ContentViewModel<LabelViewViewState, LabelViewViewProps>
{
      /* CONSTRUCTOR */
    constructor(view: ContentView)
    {
        super(view);

        this.initialState({}); // Initialize your state here

        // Bindings

    }
}