import {ContentViewModel,ContentView} from '@levelapp/softfabric';
import TagSectionViewState from './TagSectionViewState';
import TagSectionViewProps from './TagSectionViewProps';
import RewardTagOperations from '../../../../../../../../../../../Transfer/RewardTagOperations';
import TagOperations from '../../../../../../../../../../../Transfer/TagOperations';
import RewardTag from '../../../../../../../../../../../Common/DTO/RewardTag';
import Tag from '../../../../../../../../../../../Common/DTO/Tag';
import { ContentTypeEnum } from '../../../../../../../../../../../Common/Enums/ContentTypeEnum';

interface TagListItem {
    id:number; 
    key: string;
    title: string
}

export default class TagSectionViewModel extends ContentViewModel<TagSectionViewState, TagSectionViewProps>
{
    
    rewardTagOperations : RewardTagOperations;
    tagOperations: TagOperations;
    rewardTagKey: string = 'rewardtag';
    
    constructor(view: ContentView)
    {
        super(view);

        this.initialState({reward: this.props().reward, rewardTags: [], addedRewardTag:[]}); 

        this.rewardTagOperations = new RewardTagOperations();
        this.tagOperations = new TagOperations();

        // Bindings
        this.removeTag = this.removeTag.bind(this);
        this.handleRewardTagRecieved = this.handleRewardTagRecieved.bind(this);
        this.getFormattedRewardTags = this.getFormattedRewardTags.bind(this);
        this.getLinkedTags = this.getLinkedTags.bind(this);
        this.addTag = this.addTag.bind(this);
        

        this.rewardTagOperations.get(this.handleRewardTagRecieved, this.handleError);

    }

    addTag(tag: any): void {
        const payload = {
            prefixKey: this.rewardTagKey,
            label: tag.title,
            contentTypeId: ContentTypeEnum.REWARD
        }
        this.tagOperations.post(payload, (response: any) => {
            const key = `${this.rewardTagKey}.${tag.title.trim().replace(' ', '.')}`;
            const {reward, rewardTags} = this.state();
            const newRewardTag = {rewardId: reward.id as number, tagId: response.id, tag: response};

            rewardTags.push(newRewardTag);
   
            if(!reward.tags) reward.tags = [];
            if(!reward.rewardTags) reward.rewardTags = [];

            reward.tags.push(key);
            reward.rewardTags.push(newRewardTag);

            this.setField({reward, rewardTags});

        },this.handleError)
    }

    removeTag(tag: TagListItem): void {
        const {reward} = this.state();

        reward.rewardTags = reward.rewardTags?.filter(rewardTag => rewardTag.tagId !== tag.id);
        reward.tags = reward.tags?.filter(tagKey => tagKey !== tag.key);
        this.setField({reward});

    }

    getFormattedRewardTags(tags : Tag[]):  TagListItem[] {
        var list: RewardTag[] = [];
               
        list = tags.map<RewardTag>((t : Tag ) =>
        {
            return {
                rewardId : this.props().reward.id,
                tag :  t,
                tagId : t.id
            } as RewardTag
        });
        return this.formatRewardTags(list);
    }

    getLinkedTags(): TagListItem[] {
        const {reward, rewardTags} = this.state();
        if(reward.tags === undefined) return [];

        const list = rewardTags.filter(rewardTag =>{
            const key = rewardTag.tag.label?.key;
           
            return (
                  reward.tags?.includes(key) &&  
                rewardTag.rewardId === reward.id
            );
        });

        return this.formatRewardTags(list);
    }

    private formatRewardTags(rewardTags: RewardTag[]): TagListItem[] {
        return rewardTags.map(rewardTag=> {
            var title = rewardTag.tag.label.translationValues != null ? rewardTag.tag.label.translationValues[0].value : rewardTag.tag.label.key.split('.')[1];

            return {
                id: rewardTag.tag.id as number,
                key: rewardTag.tag.label.key,
                title: title  
            }
        });
    }

    private handleRewardTagRecieved(resultData: any): void {
        this.setField({rewardTags: resultData.data});
    }

    private handleError():void {}





}