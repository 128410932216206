 
import {ContentShellView} from '@levelapp/softfabric';
import ShellPosSimulatorViewState from './ShellPosSimulatorViewState';
import ShellPosSimulatorViewProps from './ShellPosSimulatorViewProps';
import ShellPosSimulatorViewStyle from './ShellPosSimulatorViewStyle';
import ShellPosSimulatorViewModel from './ShellPosSimulatorViewModel';
import {View} from 'react-native';

export default class ShellPosSimulatorView extends ContentShellView<ShellPosSimulatorViewProps, ShellPosSimulatorViewState, ShellPosSimulatorViewModel, ShellPosSimulatorViewStyle>
{
  /* CONSTRUCTOR */
  constructor(props:ShellPosSimulatorViewProps)
  {
    super(props);

    // Binding
    this.bind(new ShellPosSimulatorViewModel(this), new ShellPosSimulatorViewStyle(this));
  }

  /* RENDERING */
  render()
  {
    return (
      <View style={{flex : 1}}>
        {this.getSwitch()}
      </View>
    );
  }
}