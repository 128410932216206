import {ContentShellViewModel, ContentShellView} from '@levelapp/softfabric';
import ShellSavingCardsViewState from './ShellSavingCardsViewState';
import ShellSavingCardsViewProps from './ShellSavingCardsViewProps';
 
import EnumModules from '../../../../../Common/Constants/EnumModules';
import SavingCardsView from './pages/savingcards/SavingCardsView';
import SavingCardsV2 from './savingcards-v2/SavingCardsV2';

export default class ShellSavingCardsViewModel extends ContentShellViewModel<ShellSavingCardsViewState, ShellSavingCardsViewProps>
{

    constructor(view : ContentShellView)
    {
        super(view);

        this.registerEntryPoint('main');
        this.register(process.env.REACT_APP_ISSAVINGCARDS_V2 === 'true' ? SavingCardsV2 : <SavingCardsView />, 'main');

        this.initialisation();
    }

    getModuleId()
    {
        return EnumModules.SAVINGCARDS;
    }

}