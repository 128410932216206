import { Operation } from '@levelapp/softfabric';
import { ReferenceTypeEnum } from '../Common/Enums/ReferenceTypeEnum';
import LogService from '../Proxy/LogService';

export default class LogOperation extends Operation {
    _service: LogService;

    constructor() {
        super();
        this._service = new LogService();
    }

    async getBasketLog(basketId: number) {
        return await (this._service as LogService).getBasketLog(basketId);
    }

    async getByReferenceType(referenceTypeId: ReferenceTypeEnum) {
        return await (this._service as LogService).getByReferenceType(referenceTypeId);
    }

    async getByReferenceValueAndType(referenceTypeId: ReferenceTypeEnum , referenceTypeValue : string) {
        return await (this._service as LogService).getByReferenceValueAndType(referenceTypeId,referenceTypeValue);
    }

    async getAllBasketByStoreIdAndDate(storeId: number, fromdate : Date , todate : Date)
    {
        return await (this._service as LogService).getAllBasketByStoreIdAndDate(storeId,fromdate,todate);
    }
    
    async getAllLogsStatsforAllStores( fromdate : Date , todate : Date)
    {
        return await (this._service as LogService).GetAllLogForAllStores(fromdate,todate);
    }
}