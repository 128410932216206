 
import { ContentView } from '@levelapp/softfabric';
import ExtendedDatePickerViewState from './ExtendedDatePickerViewState';
import ExtendedDatePickerViewProps from './ExtendedDatePickerViewProps';
import ExtendedDatePickerViewStyle from './ExtendedDatePickerViewStyle';
import ExtendedDatePickerViewModel from './ExtendedDatePickerViewModel';
import { View } from 'react-native';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import 'react-datepicker/dist/react-datepicker-cssmodules.css';
import ExtendedTranslationView from '../extendedtranslation/ExtendedTranslationView';

export default class ExtendedDatePickerView extends ContentView<ExtendedDatePickerViewProps, ExtendedDatePickerViewState, ExtendedDatePickerViewModel, ExtendedDatePickerViewStyle>
{
  _refDatePicker: any;

  /* CONSTRUCTOR */
  constructor(props: ExtendedDatePickerViewProps)
  {
    super(props);

    // Binding
    this.bind(new ExtendedDatePickerViewModel(this), new ExtendedDatePickerViewStyle(this));
  }

  getInnerRef()
  {
    return this._refDatePicker;
  }

  /* RENDERING */
  render()
  {
    const { container } = this.style;

    return (
      <View style={[container, { zIndex: this.props.zIndex }]}>
        <ExtendedTranslationView uppercase>
          {this.props.title}
        </ExtendedTranslationView>
        <DatePicker
          ref={(r: any) => this._refDatePicker = r}
          placeholderText={this.props.placeholder}
          selected={this.props.selected}
          dateFormat="d/MM/yyyy"
          showYearDropdown
          showMonthDropdown
          scrollableYearDropdown
          onChange={(d: Date) => this.props.handleOnChange(d)} />
      </View>
    );
  }
}