import {ContentViewStyle, Application} from '@levelapp/softfabric';
import { StyleProp, TextStyle, ViewStyle } from 'react-native';
import { StyleManager, UiManagers } from '@levelapp/softfabric-ui';

export default class SavingCardsPopUpViewStyle extends ContentViewStyle{
    MyStyleManager : StyleManager = Application.current.resolve<StyleManager>(UiManagers.STYLE_MANAGER);
    
    title: StyleProp<TextStyle> = {
        textAlign: 'left',
        color: 'white',
        fontSize: 24,
        fontWeight: '600',
        marginTop: 20,
        marginLeft: 60,
        paddingBottom: 20,
        display: "flex", 
        flexDirection: "column", 
        flexBasis: "85%"
    };

    translationInput: StyleProp<ViewStyle> = {
        alignSelf: 'flex-end',
        marginVertical: 15,
    };

    footer: StyleProp<ViewStyle> = {
        display: 'flex',
        flexDirection: 'row',
        margin: 'auto',
        marginTop: 50,
        marginBottom: 20
    };

    input: StyleProp<any> = {
        zIndex : -1, 
        width: 140,
        paddingHorizontal: 15,
        borderRadius : 8,
        boxShadow: '0 5px 14px 0 rgba(0,0,0,0.1)',
    };

    largeInput: StyleProp<any> = {
        zIndex : -1, 
        width: 175,
        paddingHorizontal: 15,
        borderRadius : 8,
        boxShadow: '0 5px 14px 0 rgba(0,0,0,0.1)',
    };

    textArea: StyleProp<any> = {
        width: '100%',
        minWidth: 475,
        height : 92,
        paddingHorizontal: 5,
        borderRadius : 8,
        boxShadow: '0 5px 14px 0 rgba(0,0,0,0.1)',
    };

    errorTextArea: StyleProp<ViewStyle> = {
        borderColor: 'red',
        borderWidth: 1,
        width: '100%',
        minWidth: 475,
        height : 92,
        paddingHorizontal: 5,
        borderRadius : 8,
    };

    errorTextField: StyleProp<ViewStyle> = {
        zIndex : -1,
        borderColor: 'red',
        borderWidth: 1,
        width: 140,
        paddingHorizontal: 15,
        borderRadius : 8,
    };

    largeErrorTextField: StyleProp<ViewStyle> = {
        zIndex : -1,
        borderColor: 'red',
        borderWidth: 1,
        width: 175,
        paddingHorizontal: 15,
        borderRadius : 8,
    };

    step: StyleProp<ViewStyle> = {
        display: "flex",
        flexDirection: "row",
        flexWrap: "wrap"
    };

    tabActive: StyleProp<ViewStyle> = {
      backgroundColor: "white",
      width: "100%",
      alignItems: "center",
      height: "50px",
      display: "flex",
      justifyContent: "center",
      borderBottomColor : '#E4E4E4',
      borderBottomWidth : 1
    };

    tab: StyleProp<ViewStyle> = {
        backgroundColor: "#EEF0F4",
        width: "100%",
        alignItems: "center",
        height: "50px",
        display: "flex",
        justifyContent: "center",
        borderBottomColor : '#E4E4E4',
        borderBottomWidth : 1
    };

  

    subTitle: StyleProp<TextStyle> = {
        fontSize: 26,
        fontWeight: '600',
        color: '#3F3F3F',
        fontFamily : 'Montserrat SemiBold',
    };

    titleActive: StyleProp<TextStyle> = {
        fontSize: 14,
        color: '#3F3F3F',
        fontFamily : 'Montserrat SemiBold',
    }

    titleInactive: StyleProp<TextStyle> = {
        color : '#B8BBC1',
        fontSize: 14,
        fontFamily : 'Montserrat SemiBold',
    }

    saveButton: StyleProp<TextStyle> = {
        color: this.MyStyleManager.primaryColor,
        fontSize: 10,
        fontWeight: '600',
        height: 27,
        width: 112
    };

   

    componentsLine : StyleProp<ViewStyle> = {
        flexDirection : "row",
        marginVertical : 15
    }

    line : StyleProp<ViewStyle> = {
        marginBottom : 50,
        zIndex: -1, 
        backgroundColor: '#D8D8D8', 
        width: '100%', 
        height: 1 
    }
}