import { Operation } from "@levelapp/softfabric";
import RewardsService from "../Proxy/RewardsService";

export default class RewardsOperations extends Operation
{
    service : RewardsService;

    constructor()
    {
        super();
        this.service = new RewardsService();
    }

    get(parameters : any[], callback : Function, onFailure : Function)
    {
        this.service.get(parameters, callback,onFailure);
    }
    
    post(reward:any, callback : Function, onFailure : Function)
    {
        this.service.post(reward, callback, onFailure);
    }

    put(reward:any, callback : Function, onFailure : Function)
    {
        this.service.put(reward, callback, onFailure);
    }

    delete(reward:any, callback : Function, onFailure : Function)
    {
        this.service.delete(reward, callback, onFailure);
    }
}