 
import { ContentView } from '@levelapp/softfabric';
import RewardsPopUpViewState from './RewardsPopUpViewState';
import RewardsPopUpViewProps from './RewardsPopUpViewProps';
import RewardsPopUpViewStyle from './RewardsPopUpViewStyle';
import RewardsPopUpViewModel from './RewardsPopUpViewModel';
import { TranslationView } from '@levelapp/softfabric-ui';
import { View } from 'react-native';
import PicturesView from '../../../../components/pictures/PicturesView';
import PopupMenuItemView from '../../../../components/popupmenuitem/PopupMenuItemView';
import PopupHeaderView from '../../../../components/popupheader/PopupHeaderView';
import MainInformationView from './components/maiinformation/MaiInformationView';
import DescriptionView from '../../../../components/description/DescriptionView';
import SelectionView from '../../../../components/selection/SelectionView';
import Product from '../../../../../../../Common/DTO/Product';
import Helpers from '../../../../../../../Common/Helpers/Helpers';
import RewardProduct from '../../../../../../../Common/DTO/RewardProduct';
import ProductPopupView from '../../../products/pages/productpopup/ProductPopupView';
import GenericSettingsSectionView from '../../../../components/genericsettingssection/GenericSettingsSectionView';

export default class RewardsPopUpView extends ContentView<RewardsPopUpViewProps, RewardsPopUpViewState, RewardsPopUpViewModel, RewardsPopUpViewStyle>
{
  /* CONSTRUCTOR */
  constructor(props: RewardsPopUpViewProps) {
    super(props);

    // Binding
    this.bind(new RewardsPopUpViewModel(this), new RewardsPopUpViewStyle(this));
  }

  /* RENDERING */
  render() {

    const { popup } = this.style;

    return (
      <View style={popup}>
        
        {this.state.popups == 'product' && <ProductPopupView
                product={{
                    productAllergens:[],
                    titleTranslations: Helpers.generateTranslationTable(this.state.languages),
                    descriptionTranslations: Helpers.generateTranslationTable(this.state.languages),
                    imageTranslations: Helpers.generateUrlTable(this.state.languages),
                    thumbnailTranslations: Helpers.generateUrlTable(this.state.languages),
                }}
                languages={this.state.languages}
                refreshParent={this.binding.handleRefreshProducts}
                handleBack={()=>{this.binding.changePopups("selection")}}
                isGroup={false}
            />}
         

        {this.state.popups == 'selection' && 
        <SelectionView 
        handleSave={(values : any[]) => this.binding._dispatcherManager.handleValueArrayChange('posid',values)}
        handleOnPressButton={this.binding.handleAddNewProduct}
        buttonTitle={'rewards.popup.selection.button'}
        objects={this.state.products.sort((x) =>  this.state.reward.rewardProducts != undefined ? this.state.reward.rewardProducts.map((rp : RewardProduct) =>{return rp.product }).find(y => x.id == (y as Product).id) != undefined ? -1 : 1 : 1)} 
        defaultObjects={this.state.reward.rewardProducts != undefined ? this.state.reward.rewardProducts.map((rp : RewardProduct) =>{return rp.product }) : []}
        objectTitle={(object : Product)=> 
          {
           return  `${object.posId} - ${object.titleTranslations != undefined && object.titleTranslations.length > 0  ? object.titleTranslations[0].value  : 'Name not defined'}` 
          }}
        handleClose={() => this.binding.changePopups("main")} 
        headerTitle={'rewards.popup.selection.title'}/>}

        {this.state.popups == 'main' && <>

        <PopupHeaderView
          handleBackPressed={() =>
            {
              this.props.refreshParent()
              Helpers.closePopup()
            }}
          title={"rewards.popup.title"}
          shouldShowButton
          isLoading={this.state.isLoading}
          handleOnPressButton={this.binding.handleSavePressed}
        />

        <View style={{ display: "flex", flexDirection: "row", flexWrap: "wrap" }}>
          <PopupMenuItemView activeItem={this.state.tab} title={"form.maininformation"} item={"main"} handleChangeTab={() => this.binding.changeTab("main")}/>
          <PopupMenuItemView activeItem={this.state.tab} title={"form.description"} item={"desc"} handleChangeTab={() => this.binding.changeTab("desc")}/>
          <PopupMenuItemView activeItem={this.state.tab} title={"form.images"} item={"pictures"} handleChangeTab={() => this.binding.changeTab("pictures")}/>
          {this.props.contentTypeKeys.length > 0 && <PopupMenuItemView activeItem={this.state.tab} title={"form.settings"} item={"settings"} handleChangeTab={() => this.binding.changeTab("settings")} />}
        </View>

        {this.state.tab == "main" &&
          <MainInformationView 
            showErrors={this.state.showErrors}
            languages={this.state.languages}
            reward={this.state.reward}
            tags={this.props.tags}
            handleMorePress={() => this.binding.changePopups("selection")}
            handleTranslationsChange={this.binding._dispatcherManager.handleTranslationsChange}
            handleValueChange={this.binding._dispatcherManager.handleValueChange}
            handleCheckboxChange={this.binding._dispatcherManager.handleCheckboxChange}
          />
        }
        {this.state.tab == "desc" &&
          <DescriptionView
            shouldShowError={this.state.showErrors}
            languages={this.state.languages}
            listDesc={this.props.reward.descriptionTranslations}
            handleTranslationsChange={this.binding._dispatcherManager.handleTranslationsChange}
          />
        }

        {this.state.tab == "pictures" &&
          <PicturesView
            languages={this.props.languages}
            listImage={this.state.reward.imageTranslation}
            listThumbnail={this.state.reward.thumbnailTranslation}
            handleOnComplete={this.binding._dispatcherManager.handleAnyChange}
            handleOnPressImage={this.binding._dispatcherManager.handleNumberChange}
            handleCheckboxChange={this.binding._dispatcherManager.handleCheckboxChange}
            shouldUseDefaultImage={this.state.useDefaultImageChecked}
            shouldUseDefaultThumnail={this.state.useDefaultThumbnailChecked}
          />
        }

        {this.state.tab == "settings" && 
          <GenericSettingsSectionView
          onKeyValueChange={this.binding._dispatcherManager.handleAnyChange}
          contentTypeKeys={this.props.contentTypeKeys}
          contentTypeKeyValues={this.props.reward.contentTypeKeyValue}/>
        }

        {this.state.showErrors &&
          < View style={this.style.footer} >
            <TranslationView style={{ color: "red" }}>error.please.fields</TranslationView>
          </View>
        }
        </>}
      </View>
    );
  }
}