import {ContentViewModel,ContentView} from '@levelapp/softfabric';
import MessageCampaignListViewState from './MessageCampaignListViewState';
import MessageCampaignListViewProps from './MessageCampaignListViewProps';
import MessageCampaignsOperations from '../../../../../../../Transfer/MessageCampaignOperations';
 
import ErrorHelper from '../../../../../../../Common/Helpers/ErrorHelper';
import { TableRow } from '@levelapp/softfabric-ui';
import {MessageCampaign} from '../../../../../../../Common/DTO/MessageCampaign'
import MessagesView from '../../popup/MessagesView';
import MessageCampaignStatsView from '../../popup/messagecampaignstats/MessageCampaignStatsView';
import ConfirmPopUpView from '../../../../../../../Common/Components/confirmpopup/ConfirmPopUpView';
import ToastHelper from '../../../../../../../Common/Helpers/ToastHelper';

export default class MessageCampaignListViewModel extends ContentViewModel<MessageCampaignListViewState, MessageCampaignListViewProps>
{
    operation: MessageCampaignsOperations;
      /* CONSTRUCTOR */
    constructor(view: ContentView)
    {
        super(view);

        this.initialState({ tableData: [], tableHeaders: ['Title','State','isEmail','isPushNotification'], isLoading: false, nbPage: 0, currentPage: 1, isResearching: false }); // Initialize your state here
        this.operation = new MessageCampaignsOperations()
        // Bindings
        this.getData = this.getData.bind(this);
        this.addField = this.addField.bind(this);
        this.errorManagement = this.errorManagement.bind(this);
        this.editMessageCampaign = this.editMessageCampaign.bind(this)
        this.delete = this.delete.bind(this)
        this.refresh = this.refresh.bind(this)

    }

    componentDidMount(){
        this.refresh();
    }

    addField(messageCampaignId : number){
        this.showPopUp(<MessagesView refreshParent={this.refresh} editPopup={false} messageCampaignId={messageCampaignId}/>)
    }

    editMessageCampaign(mc : MessageCampaign){
        if(mc.state == "Completed" || mc.state == "Started"){
            this.showPopUp(<MessageCampaignStatsView messageCampaignId={mc.id} showStatsBlock1={mc.isPushNotification} showStatsBlock2={mc.isEmail}/>)
        }
        else{
            this.showPopUp(<MessagesView mc={mc} refreshParent={this.refresh} editPopup={true} messageCampaignId={mc.id}/>)
        }
    }

    getData(){
        this.operation.get((res: any) => {
            let tableData: TableRow[] = []
            res.data.forEach( (mc : MessageCampaign) => {
                tableData.push({
                    data : [mc.title.translationValues[0].value, mc.state, mc.isEmail ? "Yes" : "No", mc.isPushNotification ? "Yes" : "No" ],
                    isDeletable : true,
                    isEditable : true,
                    editCallback : () => {this.editMessageCampaign(mc)},
                    deleteCallback : () => {this.delete(mc.id)}
                })
            })
            this.setField({tableData: tableData,isLoading:false})
        })
    }

    refresh(){
        this.setField({isLoading: true})
        this.getData()
    }

    delete(messageCampaignId : number){
        this.showPopUp(
            <ConfirmPopUpView callBack={() => {
                this.operation.delete(messageCampaignId, () => {
                    this.showToast(ToastHelper.getToast("messageCampaigns.popup.success.deleted","success"),() => {},2500);
                    this.refresh();
                },this.errorManagement);
            }} />
        );
    }

    errorManagement(error : any) 
    {
        this.setField({isLoading : false});
        ErrorHelper.showMessageError(error.response);
    }
}