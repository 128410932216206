 
import {ContentView} from '@levelapp/softfabric';
import ExtendedTextInputViewState from './ExtendedTextInputViewState';
import ExtendedTextInputViewProps from './ExtendedTextInputViewProps';
import ExtendedTextInputViewStyle from './ExtendedTextInputViewStyle';
import ExtendedTextInputViewModel from './ExtendedTextInputViewModel';
import { View, TextInput, Image} from 'react-native';
import ExtendedTranslationView from '../extendedtranslation/ExtendedTranslationView';
import RoundButtonView from '../roundbutton/RoundButtonView';

export default class ExtendedTextInputView extends ContentView<ExtendedTextInputViewProps, ExtendedTextInputViewState, ExtendedTextInputViewModel, ExtendedTextInputViewStyle>
{
  /* CONSTRUCTOR */
  constructor(props:ExtendedTextInputViewProps)
  {
    super(props);

    // Binding
    this.bind(new ExtendedTextInputViewModel(this), new ExtendedTextInputViewStyle(this));
  }

  /* RENDERING */
  render()
  {

    const { sectionId ,  input , errorTextField } = this.style; 

    return (
      <View style={[sectionId,this.props.style]}>
        {this.props.title != '' && this.props.title != undefined &&<ExtendedTranslationView styleText={this.props.styleTitle} uppercase={this.props.uppercase != undefined ? this.props.uppercase : true} afterTitle={this.props.extraTitle} infoText={this.props.infoText}>
          {this.props.title}
        </ExtendedTranslationView>}

        <View style={{flexDirection : 'row'}}>
          
          <TextInput
            secureTextEntry={this.props.isPassword != undefined && this.props.isPassword ? true : false}
            editable={this.props.disable != undefined && this.props.disable ? false : true}
            style={[this.props.showError ? errorTextField : input , this.props.disable != undefined && this.props.disable && { backgroundColor :"#ebe7e6" }] }
            value={this.props.value}
            keyboardType={this.props.keyBoardType}
            onChangeText={(text: string) => this.props.onChangeText(text)} />

            {this.props.shouldShowMore && <RoundButtonView type={'little'} style={{paddingLeft : 10 , paddingRight : 15}} shouldUseDefaultPadding={false} backgroundColor={'white'} handleOnPress={() => { this.props.onMorePress != undefined && this.props.onMorePress()}}>
              <Image  resizeMode='contain'  style={{height : 15 , width : 15}} source={require('./assets/more.png').default}/>
            </RoundButtonView>}
        </View>
        
      </View>
    );
  }
}