import {ContentViewModel, ContentView} from '@levelapp/softfabric';
import MapRenderWebViewState from './MapRenderWebViewState';
import MapRenderWebViewProps from './MapRenderWebViewProps';

export default class MapRenderWebViewModel extends ContentViewModel<MapRenderWebViewState, MapRenderWebViewProps>
{
    lastLatitude : any;
    lastLongitude : any;
    isTheFirstTimePass : boolean;

    constructor(view : ContentView)
    {
        super(view);
        this.isTheFirstTimePass = false;
    }
}