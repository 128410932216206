 
import {
  View,
  Image,
  TouchableOpacity
} from 'react-native';
import Popup from 'reactjs-popup';

import { ContentView } from '@levelapp/softfabric';
import { TranslationView } from '@levelapp/softfabric-ui';
import Assets from 'src/Common/Assets';

import PopupMenuZoneViewState from './PopupMenuZoneViewState';
import PopupMenuZoneViewProps from './PopupMenuZoneViewProps';
import PopupMenuZoneViewStyle from './PopupMenuZoneViewStyle';
import PopupMenuZoneViewModel from './PopupMenuZoneViewModel';

export default class PopupMenuZoneView extends ContentView<PopupMenuZoneViewProps, PopupMenuZoneViewState, PopupMenuZoneViewModel, PopupMenuZoneViewStyle>
{
  /* CONSTRUCTOR */
  constructor(props: PopupMenuZoneViewProps) {
    super(props);

    // Binding
    this.bind(new PopupMenuZoneViewModel(this), new PopupMenuZoneViewStyle(this));
  }

  /* RENDERING */
  render() {
    const { style } = this;

    return (
      <Popup
        trigger={
          <button className="popup-trigger">
            <Image
              resizeMode='contain'
              style={style.image}
              source={Assets.vDots}
            />
          </button>
        }
        position="right center"
      >
        <View style={style.popup}>
          <TouchableOpacity
            style={style.popupItem}
            onPress={this.binding.openEditModal}
          >
            <TranslationView style={style.text}>
              tools.zone.popup.editZone
            </TranslationView>
          </TouchableOpacity>
          <TouchableOpacity
            style={[style.popupItem, { marginTop: 10 }]}
            onPress={this.props.onRemove}
          >
            <TranslationView style={style.text}>
              tools.zone.popup.delete
            </TranslationView>
          </TouchableOpacity>
        </View>
      </Popup>
    );
  }
}