 
import { View } from 'react-native';

import { ContentView } from '@levelapp/softfabric';
import { TranslationView } from '@levelapp/softfabric-ui';

import LogTabHeaderViewState from './LogTabHeaderViewState';
import LogTabHeaderViewProps from './LogTabHeaderViewProps';
import LogTabHeaderViewStyle from './LogTabHeaderViewStyle';
import LogTabHeaderViewModel from './LogTabHeaderViewModel';

export default class LogTabHeaderView extends ContentView<LogTabHeaderViewProps, LogTabHeaderViewState, LogTabHeaderViewModel, LogTabHeaderViewStyle>
{
  /* CONSTRUCTOR */
  constructor(props: LogTabHeaderViewProps) {
    super(props);

    // Binding
    this.bind(new LogTabHeaderViewModel(this), new LogTabHeaderViewStyle(this));
  }

  /* RENDERING */
  render() {
    return (
      <>
        <View style={this.style.tableHeader}>
          <TranslationView style={this.style.headerText}>
            tools.logs.table.date
          </TranslationView>
          <TranslationView style={this.style.headerText}>
            tools.logs.table.locations
          </TranslationView>
          <TranslationView style={this.style.headerText}>
            tools.logs.table.sources
          </TranslationView>
          <TranslationView style={this.style.headerText}>
            tools.logs.table.from
          </TranslationView>
          <TranslationView style={this.style.headerText}>
            tools.logs.table.to
          </TranslationView>
        </View>
      </>
    );
  }
}