import { Operation } from "@levelapp/softfabric";
import DeliveryProvidersService from "../Proxy/DeliveryProvidersService";

export default class DeliveryProvidersOperations extends Operation
{
    service : DeliveryProvidersService;

    constructor()
    {
        super();
        this.service = new DeliveryProvidersService();
    }

    get(callback : Function)
    {
        this.service.get(callback);
    }

    getStoresUsingProvider(provider:any, callback : Function)
    {
        this.service.getStoresUsingProvider(provider, callback);
    }

    post(deliveryProvider:any, callback : Function)
    {
        this.service.post(deliveryProvider, callback);
    }

    put(deliveryProvider:any, callback : Function)
    {
        this.service.put(deliveryProvider, callback);
    }

    delete(deliveryProvider:any, callback : Function)
    {
        this.service.delete(deliveryProvider, callback);
    }
}