import { ContentViewModel, ContentView } from '@levelapp/softfabric';
import StorespopupViewState from './StorespopupViewState';
import StorespopupViewProps from './StorespopupViewProps';
import PropertiesOperations from '../../../../../../../Transfer/PropertiesOperations';
import DeliveryProvidersOperations from '../../../../../../../Transfer/DeliveryProvidersOperation';
import Constants from '../../../../../../../Common/Constants/Constants';
import Helpers from '../../../../../../../Common/Helpers/Helpers';
import TranslationHelper from '../../../../../../../Common/Helpers/TranslationHelper';
import BasketOrderType from '../../../../../../../Common/DTO/BasketOrderType';
import StoreBasketOrderType from '../../../../../../../Common/DTO/M&N/StoreBasketOrderType';
import BasketOrderTypeOperations from '../../../../../../../Transfer/BasketOrderTypeOperations';
import DispatcherManager from '../../../../../../../Common/Managers/DispatcherManager';
import StoresOperations from '../../../../../../../Transfer/StoresOperations';
import ToastHelper from '../../../../../../../Common/Helpers/ToastHelper';
import ErrorHelper from '../../../../../../../Common/Helpers/ErrorHelper';
import PaymentSourceOperation from '../../../../../../../Transfer/PaymentSourceOperation';
import PaymentSource from '../../../../../../../Common/DTO/PaymentSource';
import { OrderSourceOperations } from '../../../../../../../Transfer/OrderSourceOperations';
import { OrderSources } from '../../../../../../../Common/Enums/OrderSources';
import { PaymentSources } from '../../../../../../../Common/Enums/PaymentSources';
import StorePaymentOrderSource from '../../../../../../../Common/DTO/StorePaymentOrderSource';
import Language from '../../../../../../../Common/DTO/Language';
import BrandOperations from '../../../../../../../Transfer/BrandOperations';
import Brand from '../../../../../../../Common/DTO/Brand';

export default class StorespopupViewModel extends ContentViewModel<StorespopupViewState, StorespopupViewProps>{

  propertiesOperation: PropertiesOperations;
  deliveryProvidersOperation: DeliveryProvidersOperations;
  basketOrderTypeOperations: BasketOrderTypeOperations;
  paymentSourcesOperation: PaymentSourceOperation;
  orderSourceOperations: OrderSourceOperations;
  storesOperations: StoresOperations;
  brandOperations: BrandOperations
  deliveryProviderName: string = '';
  weekdays: any[];
  _timeZones : any[] = [];
  _storeTypes: any[] = [];
  _regexTimeFormat: RegExp;
  _regexLongitudeFormat: RegExp;
  _regexLatitudeFormat: RegExp;
  _favoriteLanguages: any[] = []

  _dispatcherManager: DispatcherManager;

  constructor(props: ContentView) {
    super(props);


    this._dispatcherManager = new DispatcherManager();

    this.propertiesOperation = new PropertiesOperations();
    this.deliveryProvidersOperation = new DeliveryProvidersOperations();
    this.basketOrderTypeOperations = new BasketOrderTypeOperations();
    this.storesOperations = new StoresOperations();
    this.paymentSourcesOperation = new PaymentSourceOperation();
    this.orderSourceOperations = new OrderSourceOperations();
    this.brandOperations = new BrandOperations();

    //Regex time (ex : 11:20)
    this._regexTimeFormat = new RegExp('^(0[0-9]|1[0-9]|2[0-3]|[0-9]):[0-5][0-9]$');
    //Regex latitude (ex : -90° to 90°)
    this._regexLatitudeFormat = new RegExp("^[-+]?([1-8]?\\d(\\.\\d+)?|90(\\.0+)?)$");
    //Regex longitude (ex : -180° to 180°)
    this._regexLongitudeFormat = new RegExp("^[-+]?(180(\\.0+)?|((1[0-7]\\d)|([1-9]?\\d))(\\.\\d+)?)$");

    this.weekdays = [
      { title: 'Monday', value: 1 },
      { title: 'Tuesday', value: 2 },
      { title: 'Wednesday', value: 3 },
      { title: 'Thursday', value: 4 },
      { title: 'Friday', value: 5 },
      { title: 'Saturday', value: 6 },
      { title: 'Sunday', value: 7 }
    ];

    var favoriteLanguage = this.props().store.language
    if (favoriteLanguage != null) {
      this.props().store.language = { ...favoriteLanguage, title: favoriteLanguage.key }
    }

    /* Initial State */
    this.initialState({
      store: this.props().store,
      isLoading: false,
      isLoadingProperties: false,
      isLoadingProviders: false,
      isLoadingBasketOrderTypes: false,
      isLoadingPaymentSources: false,
      isLoadingOrderSources: false,
      isLoadingBrands: false,
      storeTab: 'main',
      windowTab: 'store',
      showErrors: false,
      languages: this.props().languages,
      deliveryProviders: [],
      properties: [],
      basketOrderTypes: [],
      paymentSources: [],
      orderSources: [],
      brands: [],
      indexImage: 0,
      useDefaultImageChecked: Helpers.hasDuplicates(this.props().store.imageTranslations),
      showPopup: false,
      titlePopup: "error.something.wrong",
      isClickAndCollectEnabled: this.props().store.isClickAndCollectEnabled,
      index: 0
    });

    if (this.props().store.language === null) {
      this._favoriteLanguages.push({ title: undefined })
    }
    var languages = this.props().languages
    if (languages !== null) {
      languages.forEach((language: Language) => {
        this._favoriteLanguages.push({ ...language, title: language.key })
      })
    }

    //Format object storeType to be lisible by the dropdown 
    this.props().storeTypes.forEach((storeType: any) => {
      storeType.title = storeType.name;
      this._storeTypes.push(storeType);
    })

    if (!this.props().store.timeZone) {
      this._timeZones.push({title: undefined})
    }
    let timeZonesFormatted = this.props().timeZones.map(tz => {return {...tz, title: tz.displayName}});
    for(let tz of timeZonesFormatted) {
      this._timeZones.push(tz)
    }

    this.convertOpeningHoursToDisplay();

    /* BINDING HANDLE ACTIONS */
    this.handleBackPressed = this.handleBackPressed.bind(this);
    this.handleBackPopupPressed = this.handleBackPopupPressed.bind(this);
    this.handlePropertiesBackPressed = this.handlePropertiesBackPressed.bind(this);
    this.handleProvidersBackPressed = this.handleProvidersBackPressed.bind(this);
    this.handleSavePressed = this.handleSavePressed.bind(this);
    this.handleDayChange = this.handleDayChange.bind(this);
    this.handleDeliveriesProviderChange = this.handleDeliveriesProviderChange.bind(this);
    this.handleCheckboxPropertyChange = this.handleCheckboxPropertyChange.bind(this);
    this.handleExternalIdChange = this.handleExternalIdChange.bind(this);
    this.handleSlugChange = this.handleSlugChange.bind(this);
    this.handleTitleChanged = this.handleTitleChanged.bind(this);
    this.handleStreetNumberChange = this.handleStreetNumberChange.bind(this);
    this.handleZipCodeChange = this.handleZipCodeChange.bind(this);
    this.handleCountryChange = this.handleCountryChange.bind(this);
    this.handleLatitudeChange = this.handleLatitudeChange.bind(this);
    this.handleLongitudeChange = this.handleLongitudeChange.bind(this);
    this.handlePhoneNumberChange = this.handlePhoneNumberChange.bind(this);
    this.handleEmailChange = this.handleEmailChange.bind(this);
    this.handleManagerChange = this.handleManagerChange.bind(this);
    this.handleDescriptionChanged = this.handleDescriptionChanged.bind(this);
    this.handleURLChanged = this.handleURLChanged.bind(this);
    this.handleTimeChange = this.handleTimeChange.bind(this);
    this.handleDeliveriesURLChange = this.handleDeliveriesURLChange.bind(this);
    this.handleDeleteHour = this.handleDeleteHour.bind(this);
    this.handleDeleteDelivery = this.handleDeleteDelivery.bind(this);
    this.handleStoreTypeChange = this.handleStoreTypeChange.bind(this);
    this.handlePosId = this.handlePosId.bind(this);
    this.handleStreetChanged = this.handleStreetChanged.bind(this);
    this.handleCityChanged = this.handleCityChanged.bind(this);
    this.handleImageChange = this.handleImageChange.bind(this);
    this.handlePspKeyDevChange = this.handlePspKeyDevChange.bind(this);
    this.handlePspKeyChange = this.handlePspKeyChange.bind(this);
    this.handleDefaultCheckBoxChanged = this.handleDefaultCheckBoxChanged.bind(this);
    this.handleClickAndCollectChanged = this.handleClickAndCollectChanged.bind(this);
    this.handlePreviewChanged = this.handlePreviewChanged.bind(this);
    this.handleTimeZoneChanged = this.handleTimeZoneChanged.bind(this);
    this.handleChangeBrands = this.handleChangeBrands.bind(this);
    this.errorManagement = this.errorManagement.bind(this);


    this.fillAllPicturesWithDefault = this.fillAllPicturesWithDefault.bind(this);

    this.changeTab = this.changeTab.bind(this);

    this.getProperties = this.getProperties.bind(this);
    this.getDeliveryProviders = this.getDeliveryProviders.bind(this);
    this.getWeekDays = this.getWeekDays.bind(this);
    this.getStoreTypes = this.getStoreTypes.bind(this);
    this.getTimeZones = this.getTimeZones.bind(this);
    this.getFavoriteLanguages = this.getFavoriteLanguages.bind(this);

    this.addOpeningHour = this.addOpeningHour.bind(this);
    this.addDelivery = this.addDelivery.bind(this);
    this.showDeliveryProviderManagementPopup = this.showDeliveryProviderManagementPopup.bind(this);
    this.showGlobalPropertiesManagementPopup = this.showGlobalPropertiesManagementPopup.bind(this);

    this.clickImage = this.clickImage.bind(this);
    this.isRespectingTimeFormat = this.isRespectingTimeFormat.bind(this);
    this.isDeliveriesURLempty = this.isDeliveriesURLempty.bind(this);
    this.isValidLatitude = this.isValidLatitude.bind(this);
    this.isValidLongitude = this.isValidLongitude.bind(this);
    this.isValidCoordinate = this.isValidCoordinate.bind(this);
    this.areImagesFilled = this.areImagesFilled.bind(this);
    this.isValidOpeningHours = this.isValidOpeningHours.bind(this);
    this.refreshBasketOrderTypes = this.refreshBasketOrderTypes.bind(this);

    // Delivery
    this.handleMenuInjectionEnabledChange = this.handleMenuInjectionEnabledChange.bind(this);
    this.handleOrderInjectionEnabledChange = this.handleOrderInjectionEnabledChange.bind(this);
    this.handleInAppDeliveryEnabledChange = this.handleInAppDeliveryEnabledChange.bind(this);
    this.handleImage = this.handleImage.bind(this);
    this.handleDefaultCheckBoxDeliveryChanged = this.handleDefaultCheckBoxDeliveryChanged.bind(this);
    this.setIndexImage = this.setIndexImage.bind(this);

    //PSP SECTION
    this.handleIsPspProd = this.handleIsPspProd.bind(this);
    this.handlePspKeyChange = this.handlePspKeyChange.bind(this);
    this.handlePspKey2Change = this.handlePspKey2Change.bind(this);
    this.handlePspKeyDevChange = this.handlePspKeyDevChange.bind(this);
    this.handlePspKey2DevChange = this.handlePspKey2DevChange.bind(this);
  }

  componentDidMount() {
    this._dispatcherManager.checkboxIsPspProd = this.handleIsPspProd;
    this._dispatcherManager.valuePspKey = this.handlePspKeyChange;
    this._dispatcherManager.valuePspKey2 = this.handlePspKey2Change;
    this._dispatcherManager.valuePspKeyDev = this.handlePspKeyDevChange;
    this._dispatcherManager.valuePspKey2Dev = this.handlePspKey2DevChange;

    this.refreshProperties();
    this.refreshProviders();
    this.refreshBasketOrderTypes();
    this.refreshPaymentSources();
    this.refreshOrderSources();
    this.refreshBrands();

    // Fill images of deliveries
    this.state().store.deliveries.forEach((delivery: any) => {
      if (delivery.imageTranslation == null) {
        delivery.imageTranslation = Helpers.generateUrlTable(this.props().languages);
        delivery.useDefaultImageChecked = true;
      }
      else {
        delivery.useDefaultImageChecked = Helpers.hasDuplicates(delivery.imageTranslation);
      }
    });
  }

  //Get the global properties from the back-end
  refreshProperties() {
    this.setField(previousState => previousState.isLoadingProperties = true);
    this.propertiesOperation.get((properties: any) => {
      this.setField(previousState => previousState.properties = properties.data);
      this.setField(previousState => previousState.isLoadingProperties = false);
    })
  }

  //Get the delivery providers from the back-end
  refreshProviders() {
    this.setField(previousState => previousState.isLoadingProviders = true);
    this.deliveryProvidersOperation.get((providers: any) => {
      var deliveryProviders = providers.data;
      deliveryProviders.forEach((element: any) => {
        element.title = element.name;
      });
      this.setField((previousState) => previousState.deliveryProviders = deliveryProviders);
      this.setField(previousState => previousState.isLoadingProviders = false);
    })
  }

  refreshPaymentSources() {
    this.setField({ isLoadingPaymentSources: true });
    this.paymentSourcesOperation.get((sources: PaymentSource[]) => {
      this.setField({ paymentSources: sources, isLoadingPaymentSources: false });
    })
  }

  refreshOrderSources() {
    this.setField({ isLoadingOrderSources: true });
    this.orderSourceOperations.get((sources: { id: number, label: string }[]) => {
      this.setField({ orderSources: sources, isLoadingOrderSources: false });
    })
  }

  async refreshBrands(){
    this.setField({ isLoadingBrands: true });
    const brands = await this.brandOperations.get();
    this.setField({ brands, isLoadingBrands: false });
  }

  handleBackPressed() {
    this.closePopUp();
  }

  //Close the warning (fields not correct) popup
  handleBackPopupPressed() {
    this.setField((previousState) => previousState.showPopup = false);
  }

  changeTab(tab: any) {
    this.setField((previousState) => previousState.storeTab = tab);
  }

  showDeliveryProviderManagementPopup() {
    this.setField(previousState => previousState.windowTab = 'providers');
  }

  showGlobalPropertiesManagementPopup() {
    this.setField(previousState => previousState.windowTab = 'properties');
  }

  //Close the popup 'global properties management'
  handlePropertiesBackPressed() {
    this.setField((previousState) => previousState.windowTab = 'store');
    this.refreshProperties();
  }

  //Close the popup 'delivery providers management'
  handleProvidersBackPressed() {
    this.setField((previousState) => previousState.windowTab = 'store');
    this.refreshProviders();
  }

  handleSavePressed() {
    //Check all the fields
    if (
      this.state().store.externalId == null || this.state().store.externalId == "" ||
      this.state().store.slug == null || this.state().store.slug == "" ||
      this.state().store.zipCode == null || this.state().store.zipCode == "" ||
      this.state().store.country == null || this.state().store.country == "" ||
      //this.state().store.phone == null || this.state().store.phone == "" ||
      //this.state().store.email == null || this.state().store.email == "" ||
      //this.state().store.lattitude == null || this.state().store.lattitude == "" ||
      //this.state().store.longitude == null || this.state().store.longitude == "" ||
      this.state().store.titleTranslations.length != this.state().languages.length ||
      this.state().store.streetTranslations.length != this.state().languages.length ||
      //(this.state().store.urlTranslations.length > 0 && this.state().store.urlTranslations.length < this.state().languages.length )||
      this.state().store.cityTranslations.length != this.state().languages.length ||
      //(this.state().store.descriptionTranslations.length > 0 && this.state().store.descriptionTranslations.length < this.state().languages.length) ||
      !this.isValidCoordinate() ||
      //!this.areImagesFilled() ||
      !this.isValidOpeningHours()) {
      this.setField((previousState) => previousState.showPopup = true);
      this.setField((previousState) => previousState.showErrors = true);
    } else {
      this.setField((previousState) => previousState.isLoading = true);
      this.setField((previousState) => previousState.showErrors = false);
      this.setField((previousState) => previousState.store.isClickAndCollectEnabled = previousState.isClickAndCollectEnabled);
      this.convertOpeningHoursToStore();
      this.saveStore(this.state().store);
    }
  }

  saveStore(store: any) {    
    this.state().store.deliveries.forEach((delivery: any) => {
      for (var i = 0; i < this.props().languages.length; i++) {
        if (delivery.imageTranslation[i].url.includes("data:image"))
          delivery.imageTranslation[i].ImageData = delivery.imageTranslation[i].url.replace("data:image/png;base64,", "").replace("data:image/jpeg;base64,", "");
        else
          delivery.imageTranslation[i].ImageData = null;
      }
    });

    if (store.id == undefined) {
      this.storesOperations.post(store, () => {
        this.props().refreshParent();
        this.showToast(ToastHelper.getToast("stores.popup.success.added", "success"), () => { }, 2500);
        this.closePopUp();
      }, this.errorManagement);
    } else {
      this.storesOperations.put(store, () => {
        this.props().refreshParent();
        this.showToast(ToastHelper.getToast("stores.popup.success.updated", "success"), () => { }, 2500);
        this.closePopUp();
      }, this.errorManagement)
    }
  }

  errorManagement(error: any) {
    this.setField({ isLoading: false });
    ErrorHelper.showMessageError(error.response);
  }

  //Format openingHours to be storable in the DB
  convertOpeningHoursToStore() {
    this.state().store.storeOpeningHours.forEach((storeOpeningHour: any) => {
      storeOpeningHour.opening = storeOpeningHour.opening.replace(':', '');
      storeOpeningHour.closing = storeOpeningHour.closing.replace(':', '');
    });
    this.state().store.driveOpeningHours.forEach((driveOpeningHour: any) => {
      driveOpeningHour.opening = driveOpeningHour.opening.replace(':', '');
      driveOpeningHour.closing = driveOpeningHour.closing.replace(':', '');
    });
    this.state().store.deliveryOpeningHours.forEach((deliveryOpeningHour: any) => {
      deliveryOpeningHour.opening = deliveryOpeningHour.opening.replace(':', '');
      deliveryOpeningHour.closing = deliveryOpeningHour.closing.replace(':', '');
    });
  }

  //Format hour to be readable by the user
  convertOpeningHoursToDisplay() {
    this.state().store.storeOpeningHours.forEach((openingHour: any) => {
      openingHour.opening = openingHour.opening.slice(0, 2) + ':' + openingHour.opening.slice(2, 4);
      openingHour.closing = openingHour.closing.slice(0, 2) + ':' + openingHour.closing.slice(2, 4);
    });

    this.state().store.driveOpeningHours.forEach((openingHour: any) => {
      openingHour.opening = openingHour.opening.slice(0, 2) + ':' + openingHour.opening.slice(2, 4);
      openingHour.closing = openingHour.closing.slice(0, 2) + ':' + openingHour.closing.slice(2, 4);
    });

    this.state().store.deliveryOpeningHours.forEach((openingHour: any) => {
      openingHour.opening = openingHour.opening.slice(0, 2) + ':' + openingHour.opening.slice(2, 4);
      openingHour.closing = openingHour.closing.slice(0, 2) + ':' + openingHour.closing.slice(2, 4);
    });
  }

  getProperties() {
    return this.state().properties;
  }

  getDeliveryProviders() {
    return this.state().deliveryProviders;
  }

  getWeekDays() {
    return this.weekdays;
  }

  getStoreTypes() {
    return this._storeTypes;
  }

  getTimeZones() {
    return this._timeZones;
  }
  
  getFavoriteLanguages() {
    return this._favoriteLanguages;
  }

  addOpeningHour(scheduleType: Number) {
    var openingHour = {
      "storeId": this.state().store.id,
      "day": 1,
      "opening": '',
      "closing": '',
      "scheduleTypeId": scheduleType
    }
    switch (scheduleType) {
      case Constants.ScheduleTypeStoreId:
        this.setField((previousState) => previousState.store.storeOpeningHours.push(openingHour));
        break;
      case Constants.ScheduleTypeDriveId:
        this.setField((previousState) => previousState.store.driveOpeningHours.push(openingHour));
        break;
      case Constants.ScheduleTypeDeliveryId:
        this.setField((previousState) => previousState.store.deliveryOpeningHours.push(openingHour));
        break;
      default:
        break;
    }

  }

  addDelivery() {
    this.setField((previousState) => previousState.store.deliveries.push({
      "url": "",
      "storeId": previousState.store.id,
      "providerId": this.getDeliveryProviders().length != 0 ? this.getDeliveryProviders()[0].id : 0,
      "hasMenuInjection": false,
      "hasOrderInjection": false,
      "hasInAppDelivery": false,
      imageTranslation: Helpers.generateUrlTable(this.props().languages),
      useDefaultImageChecked: true
    }));
  }

  handleExternalIdChange(externalId: string) {
    this.setField((previousState) => previousState.store.externalId = externalId)
  }

  handlePspKeyChange(pspKey: string) {
    this.setField((previousState) => previousState.store.pspKey = pspKey)
  }

  handlePspKeyDevChange(pspKeyDev: string) {
    this.setField((previousState) => previousState.store.pspKey_Dev = pspKeyDev)
  }

  handlePspKey2Change(pspKey2: string) {
    this.setField((previousState) => previousState.store.pspKey2 = pspKey2)
  }

  handlePspKey2DevChange(pspKey2Dev: string) {
    this.setField((previousState) => previousState.store.pspKey2_Dev = pspKey2Dev);
  }

  handleDropDownLanguageChange(language: Language) {
    this.setField((previousState) => previousState.store.language = language);
  }

  handleSlugChange(slug: string) {
    this.setField((previousState) => previousState.store.slug = slug)
  }

  handlePosId(posId: string) {
    this.setField((previousState) => previousState.store.posId = posId)
  }

  handleStoreTypeChange(value: any) {
    this.setField((previousState) => previousState.store.typeId = value.id);
  }

  handleTitleChanged(text: any, index: any) {
    var state = this.state();
    TranslationHelper.translation(state.store.titleTranslations, state.languages, index, text);
    this.setField({ state });
  }

  handleStreetNumberChange(streetNumber: string) {
    this.setField((previousState) => previousState.store.streetNumber = streetNumber)
  }

  handleStreetChanged(text: any, index: any) {
    var state = this.state();
    TranslationHelper.translation(state.store.streetTranslations, state.languages, index, text);
    this.setField({ state });
  }

  handleZipCodeChange(zipCode: string) {
    this.setField((previousState) => previousState.store.zipCode = zipCode)
  }

  handleCityChanged(text: any, index: any) {
    var state = this.state();
    TranslationHelper.translation(state.store.cityTranslations, state.languages, index, text);
    this.setField({ state });
  }

  handleCountryChange(country: string) {
    this.setField((previousState) => previousState.store.country = country)
  }

  handleLatitudeChange(latitude: string) {
    //Check the last character to avoid problem parsing string to float
    //Float is required to display the marker on the map 
    if (latitude.length != 0 && (latitude[latitude.length - 1] != '.' && latitude[latitude.length - 1] != '-' && latitude[latitude.length - 1] != '0')) {
      this.setField((previousState) => previousState.store.lattitude = parseFloat(latitude))
    } else {
      this.setField((previousState) => previousState.store.lattitude = latitude)
    }
  }

  handleLongitudeChange(longitude: string) {
    //Check the last character to avoid problem parsing string to float
    //Float is required to display the marker on the map 
    if (longitude.length != 0 && (longitude[longitude.length - 1] != '.' && longitude[longitude.length - 1] != '-' && longitude[longitude.length - 1] != '0')) {
      this.setField((previousState) => previousState.store.longitude = parseFloat(longitude))
    } else {
      this.setField((previousState) => previousState.store.longitude = longitude)
    }
  }

  handlePhoneNumberChange(phoneNumber: string) {
    this.setField((previousState) => previousState.store.phone = phoneNumber)
  }

  handleEmailChange(email: string) {
    this.setField((previousState) => previousState.store.email = email)
  }

  handleManagerChange(manager: string) {
    this.setField((previousState) => previousState.store.manager = manager)
  }

  handleDescriptionChanged(text: any, index: any) {
    var state = this.state();
    if(state.store.descriptionTranslations===null){
      state.store.descriptionTranslations = Helpers.generateTranslationTable(state.languages);
    }else{
      TranslationHelper.translation(state.store.descriptionTranslations, state.languages, index, text);
    }
    this.setField({ state });
  }

  handleURLChanged(text: any, index: any) {
    var state = this.state();
    TranslationHelper.translation(state.store.urlTranslations, state.languages, index, text);
    this.setField({ state });
  }

  clickImage(index: any) {
    this.setField((previousState) => previousState.indexImage = index);
  }

  handleImageChange(base64: any) {
    this.setField((previousState) => previousState.store.imageTranslations[this.state().indexImage].url = base64);
    this.setField((previousState) => previousState.store.imageTranslations[this.state().indexImage].language = this.state().languages[this.state().indexImage]);
    this.fillAllPicturesWithDefault();
  }

  fillAllPicturesWithDefault() {
    if (this.state().useDefaultImageChecked) {
      let url = this.state().store.imageTranslations[0].url;
      for (let i = 1; i < this.state().languages.length; i++) {
        this.setField((previousState) => previousState.store.imageTranslations[i].url = url);
        this.setField((previousState) => previousState.store.imageTranslations[i].language = this.state().languages[i]);
      }
    }
  }

  handleDefaultCheckBoxChanged(value: boolean) {
    this.setField({ useDefaultImageChecked: value }, () => this.fillAllPicturesWithDefault());
  }

  handleDayChange(value: any, openingHour: any) {
    openingHour.day = value.value;
  }

  handleTimeChange(hour: string, index: number, opening: boolean, scheduleTypeIndex: number) {
    switch (scheduleTypeIndex) {
      case Constants.ScheduleTypeStoreId:
        if (opening) {
          this.setField(previousState => previousState.store.storeOpeningHours[index].opening = hour);
        } else {
          this.setField(previousState => previousState.store.storeOpeningHours[index].closing = hour);
        }
        break;
      case Constants.ScheduleTypeDriveId:
        if (opening) {
          this.setField(previousState => previousState.store.driveOpeningHours[index].opening = hour);
        } else {
          this.setField(previousState => previousState.store.driveOpeningHours[index].closing = hour);
        }
        break;
      case Constants.ScheduleTypeDeliveryId:
        if (opening) {
          this.setField(previousState => previousState.store.deliveryOpeningHours[index].opening = hour);
        } else {
          this.setField(previousState => previousState.store.deliveryOpeningHours[index].closing = hour);
        }
        break;
      default:
        break;
    }
  }

  handleDeleteHour(index: number, scheduleTypeIndex: number) {
    switch (scheduleTypeIndex) {
      case Constants.ScheduleTypeStoreId:
        this.setField(previousState => previousState.store.storeOpeningHours.splice(index, 1));
        break;
      case Constants.ScheduleTypeDriveId:
        this.setField(previousState => previousState.store.driveOpeningHours.splice(index, 1));
        break;
      case Constants.ScheduleTypeDeliveryId:
        this.setField(previousState => previousState.store.deliveryOpeningHours.splice(index, 1));
        break;
      default:
        break;
    }
  }

  handleCheckboxPropertyChange(value: any, property: any) {
    if (value) {
      // Add property to list property of the store
      this.setField(previousState => previousState.store.properties.push({
        "storeId": this.state().store.id,
        "propertyId": property.id
      }))
    } else {
      // Remove property to list property of the store
      var obj = this.state().store.properties.find((element: any) => element.propertyId == property.id);
      this.setField(previousState => previousState.store.properties.splice(this.state().store.properties.indexOf(obj), 1));
    }
  }

  handleDeliveriesProviderChange(value: any, deliveries: any) {
    deliveries.providerId = value.id;
  }

  handleDeliveriesURLChange(url: string, deliveryIndex: number) {
    this.setField((previousState) => previousState.store.deliveries[deliveryIndex].url = url);
  }

  handleMenuInjectionEnabledChange(value: boolean, deliveryIndex: number) {
    this.setField((previousState) => previousState.store.deliveries[deliveryIndex].hasMenuInjection = value);
  }

  handleOrderInjectionEnabledChange(value: boolean, deliveryIndex: number) {
    this.setField((previousState) => previousState.store.deliveries[deliveryIndex].hasOrderInjection = value);
  }

  handleInAppDeliveryEnabledChange(value: boolean, deliveryIndex: number) {
    this.setField((previousState) => previousState.store.deliveries[deliveryIndex].hasInAppDelivery = value);
  }

  handleDefaultCheckBoxDeliveryChanged(value: any, deliveryIndex: number) {
    value = this.state().store.deliveries[deliveryIndex].useDefaultImageChecked;
    value = !value;
    this.setField((previousState) => previousState.store.deliveries[deliveryIndex].useDefaultImageChecked = value);
  }

  setIndexImage(value: any) {
    this.setField((previousState) => previousState.index = value);
  }

  handleImage(base64: any, deliveryIndex: any) {    
    var state = this.state();
    TranslationHelper.imageTranslation(state.store.deliveries[deliveryIndex].imageTranslation, state.languages, state.index, base64);
    this.setField({ state });
    if (this.state().store.deliveries[deliveryIndex].useDefaultImageChecked) {
      let url = state.store.deliveries[deliveryIndex].imageTranslation[0].url;     
      for (let i = 1; i < this.state().languages.length; i++) {
        this.setField((previousState) => previousState.store.deliveries[deliveryIndex].imageTranslation[i].url = url);
        this.setField((previousState) => previousState.store.deliveries[deliveryIndex].imageTranslation[i].language = this.state().languages[i]);
      }
    }
  }

  handleDeliveriesKeyChange(key: string, deliveryIndex: number) {
    this.setField((previousState) => previousState.store.deliveries[deliveryIndex].key = key);
  }

  handleDeleteDelivery(index: number) {
    this.setField(previousState => previousState.store.deliveries.splice(index, 1));
  }

  handleIsPspProd(value: boolean) {
    this.setField(previousState => previousState.store.isPspProd = value);
  }

  handleClickAndCollectChanged(value: boolean) {
    this.setField({ isClickAndCollectEnabled: value });
  }

  handlePreviewChanged(value: boolean) {
    this.setField((previousState) => previousState.store.isPreview = value);
  }

  handleTimeZoneChanged(value : any) {
    this.setField((previousState) => previousState.store.timeZoneInfo = value);
    this.setField((previousState) => previousState.store.timeZoneId = value.id);
  }


  //Check if the string value is respecting the time format (Ex: 12:00 )
  isRespectingTimeFormat(value: string) {
    return this._regexTimeFormat.test(value);
  }

  //Check if any deliveries is empty
  isDeliveriesURLempty() {
    var result = (this.state().store.deliveries as any[]).every((deliveries: any) => {
      if (deliveries.url == null || deliveries.url == "") {
        return false;
      }
      return true;
    });
    return !result;
  }

  //Check if the coordinate respect the coordinate format
  isValidCoordinate() {
    return this.isValidLongitude() && this.isValidLatitude();
  }

  isValidLongitude() {
    return this._regexLongitudeFormat.test(this.state().store.longitude);
  }

  isValidLatitude() {
    return this._regexLatitudeFormat.test(this.state().store.lattitude)
  }

  //Check if all the openingHours respect the time format (Ex: 12:00 )
  isValidOpeningHours() {
    var result = (this.state().store.storeOpeningHours as any[]).every((storeOpeningHour: any) => {
      return this.isRespectingTimeFormat(storeOpeningHour.opening) && this.isRespectingTimeFormat(storeOpeningHour.closing);
    });

    //Continue checking if all the store opening hours is correct
    if (result) {
      result = (this.state().store.driveOpeningHours as any[]).every((driveOpeningHour: any) => {
        return this.isRespectingTimeFormat(driveOpeningHour.opening) && this.isRespectingTimeFormat(driveOpeningHour.closing);
      });
    }
    //Continue checking if all the store & drive opening hours is correct
    if (result) {
      result = (this.state().store.deliveryOpeningHours as any[]).every((deliveryOpeningHour: any) => {
        return this.isRespectingTimeFormat(deliveryOpeningHour.opening) && this.isRespectingTimeFormat(deliveryOpeningHour.closing);
      });
    }

    return result;
  }

  //Check if all the images are filled.
  areImagesFilled() {
    let isCorrect = true;
    let index = 0;

    while (index < this.state().store.imageTranslations.length && isCorrect) {
      isCorrect = (this.state().store.imageTranslations[index].url !== Constants.UploadImageUrl);
      index++;
    }
    return isCorrect;
  }

  /* BasketOrderTypes */

  refreshBasketOrderTypes() {
    this.setField({ isLoadingBasketOrderTypes: true });
    this.basketOrderTypeOperations.get((data: BasketOrderType[]) => this.setField({ basketOrderTypes: data, isLoadingBasketOrderTypes: false }));
  }

  handleChangeBasketOrderType(value: boolean, basketOrderType: BasketOrderType) {
    if (value) {
      // Add basketOrderType to list basketOrderTypes of the store
      var state = this.state();
      state.store.orderTypes.push(
        {
          basketOrderTypeId: basketOrderType.id,
          storeId: state.store.id
        });
      this.setField({ state });
    } else {
      // Remove property to list property of the store
      var obj = this.state().store.orderTypes.find((element: StoreBasketOrderType) => element.basketOrderTypeId == basketOrderType.id);
      if (obj != undefined) {
        this.setField(previousState => previousState.store.orderTypes.splice(this.state().store.orderTypes.indexOf(obj as StoreBasketOrderType), 1));
      }
    }
  }

  handleChangePaymentOrderSource(value: boolean, orderSourceId: OrderSources, paymentSourceId: PaymentSources) {
    let { storePaymentOrderSources }: { storePaymentOrderSources: StorePaymentOrderSource[] } = this.state().store;
    if (value) {
      storePaymentOrderSources.push({
        storeId: this.state().store.id,
        orderSourceId: orderSourceId,
        paymentSourceId: paymentSourceId,
      });
      return this.setField((previousState) => previousState.store.storePaymentOrderSources = storePaymentOrderSources);
    }

    storePaymentOrderSources = storePaymentOrderSources.filter(x => x.orderSourceId != orderSourceId || x.paymentSourceId != paymentSourceId);
    return this.setField((previousState) => previousState.store.storePaymentOrderSources = storePaymentOrderSources);
  }

  handleChangeBrands(brands: Brand[]){
    this.setField({
      ...this.state(),
      store:{
        ...this.state().store,
        brandIds: brands.map(x => x.id)
      }
    })
  }

  handleNavigationTo(page: string) {
    this.setField({ windowTab: page });
    this.refreshBasketOrderTypes();
  }
}