import { Service, HttpManager, Application, Managers } from '@levelapp/softfabric';

export default class TranslationsNonContentService extends Service
{
    httpClient : HttpManager;

    constructor()
    {
        super();
        this.httpClient = Application.current.resolve(Managers.HTTP_MANAGER);
        this.httpClient.handleSomethingWentWrong = (response : any) => {
            console.warn('Erreur : ' + response.data);
        };
    }

    async get(parameters : any[], callback : Function = () => {})
    {
        this.httpClient
            .get('translationsnoncontent', parameters)
            .then((response : any) => 
            {
                if (response)
                {
                    if (response.data)
                    {
                        callback(response.data);
                    }
                }
            });
    }

    put(translations:any, callback : Function = () => {})
    {
        this.httpClient
            .put('translationsnoncontent', [], translations)
            .then((response : any) => 
            {
                if (response)
                {
                      /*if (response.data)
                    {
                        callback(response.data);
                    }*/
                    callback("");
                }
            });
    }

    post(translations:any, callback : Function = () => {})
    {
        this.httpClient
            .post('translationsnoncontent', [], translations)
            .then((response : any) => 
            {
                if (response)
                {
                      /*if (response.data)
                    {
                        callback(response.data);
                    }*/
                    callback("");
                }
            });
    }

    addTranslation(translations:any, callback : Function = () => {})
    {
        this.httpClient
            .post('translationsnoncontent/addTranslation', [], translations)
            .then((response : any) => 
            {
                if (response)
                {
                      /*if (response.data)
                    {
                        callback(response.data);
                    }*/
                    callback("");
                }
            });
    }

    delete(translation:any, callback : Function = () => {}, onFailure : Function = () => {}, notFound : Function = () => {})
    {
    
        this.httpClient.handleSomethingWentWrong = onFailure;
        this.httpClient.handleNotFound = notFound;

        this.httpClient
            .delete('translationsnoncontent/delete/'+translation.id, [])
            .then((response : any) => 
            {
                if (response)
                {
                      /*if (response.data)
                    {
                        callback(response.data);
                    }*/
                    callback("");
                }
            });
    }
}