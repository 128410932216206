import { ContentViewStyle, Application } from '@levelapp/softfabric';
import { StyleProp, ViewStyle, TextStyle } from 'react-native';
import { StyleManager, UiManagers } from '@levelapp/softfabric-ui';

export default class OrdersViewStyle extends ContentViewStyle
{
    MyStyleManager: StyleManager = Application.current.resolve<StyleManager>(UiManagers.STYLE_MANAGER);

    container: StyleProp<ViewStyle> = {
        display: 'flex',
        flex: 1,
        alignItems: 'center'
    }

    box: StyleProp<ViewStyle> = {
        width: '95%',
        backgroundColor: 'white',
        borderRadius: 6,
        marginTop: 40,
        shadowColor: "#0000006b",
        shadowOffset: {
            width: 1,
            height: 0,
        },
        shadowOpacity: 0.70,
        shadowRadius: 20.00,
        elevation: 60,
    }

    boxTitle: StyleProp<ViewStyle> = {
        width: '98%',
        minHeight: 141,
        marginTop: -20,
        margin: 'auto',
        borderRadius: 8,
        flexDirection: 'row',
    }

    title: StyleProp<TextStyle> = {
        color: 'white',
        fontSize: 30,
        paddingLeft: 54,
        paddingRight: 10
    }

    tableHeader: StyleProp<TextStyle> =
        {
            color: '#575757',
            fontSize: 15,
            fontFamily: 'Montserrat',
            fontWeight: 'bold',
        }
    inputTitle: StyleProp<TextStyle> = {
        fontSize: 16,
        fontWeight: '600',
        color: '#3F3F3F',
        fontFamily: 'Montserrat SemiBold',
        marginRight: 10

    };

    

    label: StyleProp<TextStyle> = {
        fontSize: 16,
        fontWeight: '600',
        color: '#525f7f',
        marginBottom: 10
    }

    boxContainer: StyleProp<ViewStyle> = {
        display: 'flex',
        alignSelf: 'center',
        minHeight: 250,
        width: '100%',
        paddingTop: 30,
        paddingBottom: 30,
        paddingLeft: 50,
        paddingRight: 50,
    }

    availableIn: StyleProp<ViewStyle> = {
        marginTop: 50
    }

    subCategories: StyleProp<ViewStyle> = {
        marginTop: 50
    }

    radioContainer: StyleProp<ViewStyle> = {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        marginTop: 5,
        marginBottom: 5
    }

    radioLabel: StyleProp<TextStyle> = {
        fontSize: 16,
        fontWeight: '600',
        color: '#8898aa',
        marginLeft: 10
    }

    buttonBox: StyleProp<ViewStyle> =
        {
            flexDirection: 'row',
            justifyContent: 'flex-end',
            alignItems: 'center',
            flex: 1,
            padding: 50,
        }

    stepColor: StyleProp<ViewStyle> =
        {
            backgroundColor: this.MyStyleManager.primaryColor,
        }

    boxSubtitle: StyleProp<ViewStyle> =
        {
            flex: 1,
            flexDirection: 'row'
        }

    rowStyle: StyleProp<ViewStyle> =
        {
            minHeight: 90,
            paddingRight: 0,
            marginRight: 0,
            marginHorizontal: 0

        }
    noButtonStyle: StyleProp<ViewStyle> =
        {
            height: 90,
            width: 90,
            borderRadius: 500
        }

    yesButtonStyle: StyleProp<ViewStyle> =
        {
            height: 90,
            width: 90,
            borderRadius: 500
        }
    footer: StyleProp<ViewStyle> = {
        display: 'flex',
        flexDirection: 'row',
        margin: 'auto',
        marginTop: 75,
        marginBottom: 100
    };

    titlePopup: StyleProp<TextStyle> = {
        textAlign: 'center',
        color: '#4A495C',
        fontSize: 21,
        fontWeight: '600',
        marginTop: 100
    };

    YesButton: StyleProp<ViewStyle> =
        {
            height: 42,
            width: 119,
            marginLeft: '5px'
        }

    refreshContainer: StyleProp<ViewStyle> = {
        marginBottom: 20,
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center'
    };

    refreshBtn: StyleProp<ViewStyle> = {
        marginLeft: 10,
        backgroundColor: 'white',
        paddingVertical: 8,
        paddingHorizontal: 25,
        borderColor: this.MyStyleManager.primaryColor,
        borderWidth: 1,
        borderRadius: 8
    };

    refreshBtnTxt: StyleProp<TextStyle> = {
        color: this.MyStyleManager.primaryColor
    };

    orderNumberTxt: StyleProp<TextStyle> = {
        fontSize: 30,
        fontWeight: 'bold'
    };
    orderNumberTxtMultiLine: StyleProp<TextStyle> = {
        fontSize: 20,
        textAlign: 'center',
        fontWeight: 'bold'
    };
    
    newOrdersTxt: StyleProp<TextStyle> = {
        color: '#8898aa',
        fontSize: 12,
        fontStyle: 'italic'
    };

    buttonContainer: StyleProp<TextStyle> = {
        width: 90,
        height: 90,
        justifyContent: "center",
        marginLeft: -25
    };
}