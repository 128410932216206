import {Application, ContentViewStyle} from '@levelapp/softfabric';
import { StyleManager, UiManagers } from '@levelapp/softfabric-ui';
import {StyleProp,ViewStyle} from 'react-native';

export default class GenericSettingsSectionViewStyle extends ContentViewStyle
{
    page: StyleProp<ViewStyle> = {
        flex: 1
    }
    MyStyleManager: StyleManager = Application.current.resolve<StyleManager>(UiManagers.STYLE_MANAGER);

    container: StyleProp<ViewStyle> = {
        display: "flex",
        flexDirection: "column",
        paddingHorizontal: 170,
        paddingVertical: 50
    };

    columnNameContainer : StyleProp<ViewStyle> = {
        flexDirection : 'row',
        justifyContent : 'space-between', 
        width : 500,
        paddingVertical : 10,
    };


    
}