import { ContentView, ContentViewModel } from '@levelapp/softfabric';
 
import Composition from '../../../../../../../Common/DTO/Composition';
import Language from '../../../../../../../Common/DTO/Language';
import CompositionOperations from '../../../../../../../Transfer/CompositionOperations';
import LanguagesOperations from '../../../../../../../Transfer/LanguagesOperations';
import AddProductPopUpView from '../../popup/addproductpopup/AddProductPopUpView';
import DetailCompositionView from '../../popup/menuconfiguratorpopup/components/detailcomposition/DetailCompositionView';
import MenuconfiguratorViewProps from './MenuconfiguratorViewProps';
import MenuconfiguratorViewState from './MenuconfiguratorViewState';
import ConfirmPopUpView from '../../../../../../../Common/Components/confirmpopup/ConfirmPopUpView';
import ToastHelper from '../../../../../../../Common/Helpers/ToastHelper';
import ProductPopupView from '../../../products/pages/productpopup/ProductPopupView';
import Helpers from '../../../../../../../Common/Helpers/Helpers';
import AdminProductOperations from '../../../../../../../Transfer/AdminProductOperations';

export default class MenuconfiguratorViewModel extends ContentViewModel<MenuconfiguratorViewState, MenuconfiguratorViewProps>
{
  operation: CompositionOperations;
  adminProduct: AdminProductOperations;
  languagesOperation: LanguagesOperations;
  _languages: Language[] = [];

  /* CONSTRUCTOR */
  constructor(props: ContentView) {
    super(props);


    this.initialState({
      parentWithListChildren: [],
      compositions: [],
      listIdGenerate: [],
      listCompositionsWithModification: [],
      treeData: [
        // {id: 1,title: 'Chicken',canDrop: false,subtitle: 'hello',test: "ppp",children: [{title: 'Egg'}]},
        // { id:2,title: (<span className={'hello'}><i aria-hidden="true"></i>hello</span>),test:"ppp",canDrop:true, children: [{ title: 'fingerline',children: [{ title: 'coca' },{ title: 'fanta' }] }] },
        // { id:3,title: 'boisson',test:"ppp",canDrop:false, children: [{ title: 'coca' }] },
        // { id:5,title: 'menu Giant',test:"ppp",canDrop:true, children: [{ title: 'coca' },{ title: 'fanta' }] },
        // { id:6,title: 'sauces',test:"ppp",expanded:true, children: [{ title: 'ketchup' }] },
        // { id:7,title: 'boisson',test:"ppp",canDrop:false,noChildren:true,noDragging:true },
        // { id:8,title: 'boisson',test:"ppp", canDrop:true,children: [{ title: 'coca' }] },
        // { id:9,title: 'boisson',test:"ppp", canDrop:true,children: [{ title: 'coca' }] },
        // { id:10,title: 'boisson',test:"ppp", canDrop:true,children: [{ title: 'coca' }] },
      ],
      isLoading: true,
      saveLoading: false,
      treeModified: false,
      isRefreshMenuLoading: false,
    });
    this.adminProduct = new AdminProductOperations();
    this.operation = new CompositionOperations();
    this.languagesOperation = new LanguagesOperations();
    this.languagesOperation.get((languages: any) => {
      this._languages = languages.data;
    });

    /* BINDINGS */
    this.editField = this.editField.bind(this);
    this.editFieldParent = this.editFieldParent.bind(this);
    this.displayViewAddProduct = this.displayViewAddProduct.bind(this);
    this.displayViewClone = this.displayViewClone.bind(this);
    this.deletePopupVerification = this.deletePopupVerification.bind(this);
    this.refresh = this.refresh.bind(this);
    this.save = this.save.bind(this);
    this.changeParentInTree = this.changeParentInTree.bind(this);
    this.canDrop = this.canDrop.bind(this);
    this.haveChildrenGroup = this.haveChildrenGroup.bind(this);
    this.saveComposition = this.saveComposition.bind(this);
    this.deleteElementInTree = this.deleteElementInTree.bind(this);
    this.refreshMenu = this.refreshMenu.bind(this)
  }

  componentDidMount() {
    this.setField({ saveLoading: false })
    this.refresh();
  }


  refresh() {
    this.setField({ isLoading: true })
    this.getData();
  }
  getData() {
    this.operation.get([], (compositions: any) => {
      this.setField({ compositions: compositions.data.objects.filter((comp: Composition) => comp.parentProduct.isGroup), isLoading: false });
      this.treeData()
      this.initListCompositionsWithhModification();
      this.setField({ isLoading: false })
    })
  }
  /*
  * this method allows you to initialize the list and set all 
  * the composition to none to say that nothing is modified.
  * this list will be modified when we want to save.
  */
  initListCompositionsWithhModification() {
    let list: any[] = [];
    this.state().compositions.forEach((element: Composition) => {
      let compo: Composition = {
        quantityMax: element.quantityMax,
        quantityMin: element.quantityMin,
        isMandatory: element.isMandatory,
        parentProductId: element.parentProductId,
        childProductId: element.childProductId,
        childProduct: element.childProduct,
        parentProduct: element.parentProduct,
        lastModificationDate: element.lastModificationDate,
        creationDate: element.creationDate,

        orderIndex: 0,
      }
      list.push({ composition: compo, change: "none" })
    });
    this.setField({
      listCompositionsWithModification: list
    })
  }
  /*
  * this method allow to display the view addProductPopupView
  */
  displayViewAddProduct(node: any) {
    this.showPopUp(<AddProductPopUpView clone={false} languages={this._languages} treeData={this.state().treeData} parentNode={node} callback={(parentNode: any, products: any) => this.addProducts(parentNode, products)}></AddProductPopUpView>)
  }
  generateNumberInListIdGenerate() {
    let listNumber = this.state().listIdGenerate
    var randomNumber = Math.floor(Math.random() * 100000) + 1;
    let num
    num = listNumber.indexOf(-randomNumber)
    while (num != -1) {
      var randomNumber = Math.floor(Math.random() * 100000) + 1;

      num = listNumber.indexOf(-randomNumber)
    }
    listNumber.push(-randomNumber)

    this.setField({ listIdGenerate: listNumber })
    return -randomNumber
  }
  displayViewClone(node: any) {
    this.addClone(node);
    // this.showPopUp(<AddProductPopUpView clone={true} languages={this._languages} treeData={this.state().treeData} parentNode={node} callback={(parentNode:any,products: any) => this.addCloneProductsInRacineTree(parentNode,products) }></AddProductPopUpView>)
  }
  copyNode(node: any) {
    let copyNode: any;
    if (node != null) {
      let composition: Composition = {
        quantityMax: node.node.composition.quantityMax,
        quantityMin: node.node.composition.quantityMin,
        isMandatory: node.node.composition.isMandatory,
        parentProductId: node.node.composition.parentProductId,
        childProductId: node.node.composition.childProductId,
        childProduct: node.node.composition.childProduct,
        parentProduct: node.node.composition.parentProduct,
        lastModificationDate: node.node.composition.lastModificationDate,
        creationDate: node.node.composition.creationDate,
        orderIndex: 0,
      }
      // composition.childProduct.titleTranslations[0].value="copy "+composition.childProduct.titleTranslations[0].value;
      if (node.node.group != undefined && node.node.group == true) {
        let randomNumber = this.generateNumberInListIdGenerate()
        composition.childProductId = randomNumber;
        composition.childProduct.id = randomNumber
        copyNode = { composition: composition, group: true, title: composition.childProduct.titleTranslations[0].value, parent: composition.childProductId }
      } else {
        copyNode = { composition: composition, title: composition.childProduct.titleTranslations[0].value, parent: composition.childProductId }
      }
      if (node.node.children) {
        this.recursiveCloneNode(copyNode, node.node.children, composition.childProductId)
      }
    }
    return copyNode
  }
  recursiveCloneNode(copyNode: any, listChildren: any, idParent: any) {
    let listChildrenCopy = [];
    for (let index = 0; index < listChildren.length; index++) {
      const element = listChildren[index];
      let composition: Composition = {
        orderIndex: element.composition.orderIndex,
        quantityMax: element.composition.quantityMax,
        quantityMin: element.composition.quantityMin,
        isMandatory: element.composition.isMandatory,
        parentProductId: element.composition.parentProductId,
        childProductId: element.composition.childProductId,
        childProduct: element.composition.childProduct,
        parentProduct: element.composition.parentProduct,
        lastModificationDate: element.composition.lastModificationDate,
        creationDate: element.composition.creationDate
      }

      if (element.group != undefined && element.group == true) {
        let randomNumber = this.generateNumberInListIdGenerate()
        composition.childProductId = randomNumber;
        composition.childProduct.id = randomNumber
        composition.parentProductId = idParent
        composition.parentProduct.id = idParent
        listChildrenCopy.push({ composition: composition, group: true, title: composition.childProduct.titleTranslations[0].value, parent: composition.childProductId })
      } else {
        composition.parentProductId = idParent
        composition.parentProduct.id = idParent
        listChildrenCopy.push({ composition: composition, title: composition.childProduct.titleTranslations[0].value, parent: composition.childProductId })
      }
      if (element.children) {

        this.recursiveCloneNode(listChildrenCopy[index], element.children, composition.childProductId)
      }
    }
    copyNode["children"] = listChildrenCopy

  }
  addClone(node: any) {
    let copyNode = this.copyNode(node);
    let treeData: any = this.state().treeData
    let path: any;
    let find = false;
    for (let index = 0; index < treeData.length; index++) {
      let element: any = treeData[index]
      if (node.node.composition.childProductId == element.composition.childProductId
        && node.node.composition.parentProductId == element.composition.parentProductId) {
        // treeData[index]["expanded"]=true;
        copyNode["expanded"] = true;
        treeData.push(copyNode)
        this.setField({ treeModified: true })
        find = true
        break
      } else if (element.children && !find) {
        path = this.recursiveAddCloneNode(node, element.children, treeData[index], copyNode)
        if (path != undefined) {
          find = true;
          break
        }
      }
    }
    this.setField({
      treeData: [...treeData]
    })
  }
  recursiveAddCloneNode(target: any, listChildren: any[], path: any, copyNode: any): any {
    for (let index = 0; index < listChildren.length; index++) {
      let element: any = listChildren[index]
      if (target.node.composition.childProductId == element.composition.childProductId
        && target.node.composition.parentProductId == element.composition.parentProductId) {
        // path.children[index]["expanded"]=true;
        copyNode["expanded"] = true;
        path.children.push(copyNode)
        this.setField({ treeModified: true })
        return { path: path, index: index };
      } else if (element.children) {
        this.recursiveAddCloneNode(target, element.children, path.children[index], copyNode)
      }
    }
  }
  // addCloneProductsInRacineTree(node: any,products:any){
  //     let treeData: any = this.state().treeData
  //     for (let index = 0; index < products.length; index++) {
  //         const element = products[index];
  //         let compo: any = {
  //             quantityMax: node.node.composition.quantityMax,
  //             quantityMin: node.node.composition.quantityMin,
  //             isMandatory: node.node.composition.isMandatory,
  //             parentProductId: -1,
  //             childProductId: element.id,
  //             childProduct: element,
  //             parentProduct: null,
  //             lastModificationDate: new Date(),
  //             creationDate: new Date(),
  //             isGroup:element.isGroup
  //         }
  //         let children =[]
  //         for (let index = 0; index < node.node.children.length; index++) {
  //             const elem = node.node.children[index];
  //             // elem.composition.parentProduct=compo.childProduct
  //             // elem.composition.parentProductId=compo.childProductId
  //             let composi: any = {
  //                 quantityMax: node.node.composition.quantityMax,
  //                 quantityMin: node.node.composition.quantityMin,
  //                 isMandatory: node.node.composition.isMandatory,
  //                 parentProductId: compo.childProductId,
  //                 childProductId: elem.composition.childProductId,
  //                 childProduct: elem.composition.childProduct,
  //                 parentProduct: compo.childProduct,
  //                 lastModificationDate: new Date(),
  //                 creationDate: new Date()

  //             }
  //             children.push({composition:composi,newNode:true,title:elem.composition.childProduct.titleTranslations[0].value,parent:elem.composition.childProductId})
  //         }
  //         if(!element.isGroup){
  //             treeData.push({composition:compo,newNode:true,title:element.titleTranslations[0].value,parent:element.id})
  //         }else{
  //             treeData.push({composition:compo,group:true,expanded:true,newNode:true,title:element.titleTranslations[0].value,parent:element.id,children:children})
  //             this.setField({treeModified:true})
  //         }
  //     }
  //     this.setField({
  //         treeData: [...treeData]
  //     })
  //     console.log(this.state().treeData)


  // }
  /*
  * this method allow to create a list with the new node with the new products 
  */
  createListNewNodeWithListProduct(node: any, products: any, listNewNode: any[]) {
    if (node != null) {
      for (let index = 0; index < products.length; index++) {
        const element = products[index];
        let compo: any = {
          quantityMax: node.node.composition.quantityMax,
          quantityMin: node.node.composition.quantityMin,
          isMandatory: node.node.composition.isMandatory,
          parentProductId: node.node.composition.childProductId,
          childProductId: element.id,
          childProduct: element,
          parentProduct: node.node.composition.childProduct,
          lastModificationDate: new Date(),
          creationDate: new Date()
        }
        if (!element.isGroup) {
          listNewNode.push({ composition: compo, title: element.titleTranslations[0].value, parent: element.id })
        } else {
          listNewNode.push({ composition: compo, group: true, title: element.titleTranslations[0].value, parent: element.id })
        }
      }
    }
  }
  /*
  *
  */
  addProductsInRacineTree(products: any, treeData: any) {
    for (let index = 0; index < products.length; index++) {
      const element = products[index];
      let compo: any = {
        quantityMax: 1,
        quantityMin: 1,
        isMandatory: false,
        parentProductId: -1,
        childProductId: element.id,
        childProduct: element,
        parentProduct: null,
        lastModificationDate: new Date(),
        creationDate: new Date()
      }
      if (!element.isGroup) {
        treeData.push({ composition: compo, newNode: true, title: element.titleTranslations[0].value, parent: element.id })
      } else {
        treeData.push({ composition: compo, group: true, newNode: true, title: element.titleTranslations[0].value, parent: element.id })
        this.setField({ treeModified: true })
      }
    }

  }
  /*
  * his method allows you to add all the products chosen in the tree.
  */
  addProducts(node: any, products: any) {
    let listNewNode: any[] = []
    let treeData: any = this.state().treeData
    if (node != null) {
      this.createListNewNodeWithListProduct(node, products, listNewNode);
      let path: any;
      let find = false;
      for (let index = 0; index < treeData.length; index++) {
        let element: any = treeData[index]
        if (node.node.composition.childProductId == element.composition.childProductId
          && node.node.composition.parentProductId == element.composition.parentProductId) {
          treeData[index]["expanded"] = true;
          for (let i = 0; i < listNewNode.length; i++) {
            const element = listNewNode[i];
            if (treeData[index]["children"] != undefined) {
              treeData[index]["children"].push(element)
            } else {
              treeData[index]["children"] = [element]
            }
          }
          this.setField({ treeModified: true })
          find = true
          break
        } else if (element.children && !find) {
          path = this.recursiveAddNode(node, element.children, treeData[index], listNewNode)
          if (path != undefined) {
            find = true;
            break
          }
        }
      }
    } else {
      this.addProductsInRacineTree(products, treeData)
    }
    this.setField({
      treeData: [...treeData]
    })
  }

  /*
  * this method allows to find the parents in the tree where we want to add the products.
  */
  recursiveAddNode(target: any, listChildren: any[], path: any, listNewNode: any): any {
    for (let index = 0; index < listChildren.length; index++) {
      let element: any = listChildren[index]
      if (target.node.composition.childProductId == element.composition.childProductId
        && target.node.composition.parentProductId == element.composition.parentProductId) {
        path.children[index]["expanded"] = true;

        for (let i = 0; i < listNewNode.length; i++) {
          const element = listNewNode[i];
          if (path.children[index]["children"] != undefined) {
            path.children[index]["children"].push(element)
          } else {
            path.children[index]["children"] = [element]
          }
        }
        this.setField({ treeModified: true })
        return { path: path, index: index };
      } else if (element.children) {
        this.recursiveAddNode(target, element.children, path.children[index], listNewNode)
      }
    }
  }
  /*
  * this method allows you to know if a node has child groups
  */
  haveChildrenGroup(node: any) {
    let find = false
    if (!node.node.children || node.node.children.length == 0) {
      return true;
    }
    node.node.children.forEach((element: any) => {
      if (!element.group) {
        find = true;
      }
    });
    return find;
  }
  canDrop(node: any) {
    let selectedNode = node.node;
    let newParent = node.nextParent;
    let canDrop = true
    if (newParent == null && (selectedNode.group == undefined || selectedNode.group == false)) {
      return false;
    }
    if (newParent == null && selectedNode.group == true) {
      return true;
    }
    let find = false
    if (newParent.children) {
      newParent.children.forEach((element: any) => {
        if (selectedNode.group == true && !element.group
          || selectedNode.group == false && element.group
          || (selectedNode.group == false || selectedNode.group == undefined) && element.group
          || (selectedNode.group == true) && !element.group) {
          canDrop = false
        }
        if (find && selectedNode.composition.childProductId == element.composition.childProductId) {
          canDrop = false
        }
        if (selectedNode.composition.childProductId == element.composition.childProductId && !find) {
          find = true
        }
      });
    }
    return canDrop;
  }
  treeData() {
    this.getParentWithAListChildren();
    let list: any[] = []
    this.state().parentWithListChildren.forEach((element: any) => {
      let children: any = [];
      element.children.forEach((element2: any) => {
        children.push(this.getChildrenOfAParentIdrecursive(element2));
      });
      if (!this.haveParentInCompositions(element.parent)) {


        children = this.recursiveSort(children);

        list.push({
          title: element.title,
          composition: element.composition,
          group: true,
          parent: element.parent,
          children: children
        });
      }
    });
    this.setField({
      treeData: list
    })
  }

  recursiveSort(list: any[]) {

    for (var i = 0; i < list.length; i++) {
      if (list[i].children != undefined) {
        this.recursiveSort(list[i].children);
      }
    }

    return list.sort((a: any, b: any) => { return a.composition.orderIndex - b.composition.orderIndex });
  }

  /*
  * this method allows to know if a product have a parentProduct in composition
  */
  haveParentInCompositions(productId: number) {
    let find = false;
    this.state().compositions.forEach((composition: Composition) => {
      if (composition.childProductId == productId) {
        find = true
      }
    });
    return find;
  }

  /*
  * this method allow to creat a list with  the parent and all children
  */
  getParentWithAListChildren() {
    let list: any[] = []
    this.state().compositions.forEach((composition: Composition) => {
      let find = false;
      list.forEach(element => {
        if (composition.parentProductId == element.parent) {
          find = true;
        }
      });
      if (!find) {
        let compo: any = {
          quantityMax: composition.quantityMax,
          quantityMin: composition.quantityMin,
          isMandatory: composition.isMandatory,
          parentProductId: -1,
          childProductId: composition.parentProductId,
          childProduct: composition.parentProduct,
          parentProduct: null,
          lastModificationDate: composition.lastModificationDate,
          creationDate: composition.creationDate
        }
        if (composition.childProduct.isGroup == true) {
          list.push({
            composition: compo,
            title: "" + composition.parentProduct.titleTranslations[0].value,
            group: true,
            parent: composition.parentProductId,
            children: this.getChildrenOfAParent(composition.parentProductId)
          });
        } else {
          list.push({
            composition: compo,
            title: "" + composition.parentProduct.titleTranslations[0].value,
            parent: composition.parentProductId,
            children: this.getChildrenOfAParent(composition.parentProductId)
          });
        }
      }
    });
    this.setField({ parentWithListChildren: list })
  }
  getChildrenOfAParent(parent: any) {
    let childList: any = [];
    for (let index = 0; index < this.state().compositions.length; index++) {
      let composition = this.state().compositions[index];
      if (parent == composition.parentProductId) {

        if (composition.childProduct.isGroup == true) {
          childList.push({
            composition: composition,
            title: "" + composition.childProduct.titleTranslations[0].value,
            parent: composition.childProductId,
            group: true
          });
        } else {

          childList.push({
            composition: composition,
            title: "" + composition.childProduct.titleTranslations[0].value,
            parent: composition.childProductId
          });
        }
      }
    }
    return childList;
  }

  getChildrenOfAParentIdrecursive(produit: any) {
    for (let index = 0; index < this.state().parentWithListChildren.length; index++) {
      const element = this.state().parentWithListChildren[index];
      if (element.parent == produit.parent) {
        let list: any = [];
        for (let index = 0; index < element.children.length; index++) {
          const children = element.children[index];
          let find = false;
          let i: number = 0;
          for (let index = 0; index < list.length; index++) {
            const p = list[index];
            if (p.parent == produit.parent) {
              find = true;
              i = index;
            }
          }
          if (find) {
            list[i].children.push(this.getChildrenOfAParentIdrecursive(children));
          } else {
            if (produit.composition.childProduct.isGroup == true) { // todo: attention tcheck the condition  is correct
              list.push({
                composition: produit.composition,
                title: produit.title,
                group: true,
                parent: produit.parent,
                children: [this.getChildrenOfAParentIdrecursive(children)]
              });
            } else {
              list.push({
                composition: produit.composition,
                title: produit.title,
                group: false,
                parent: produit.parent,
                children: [this.getChildrenOfAParentIdrecursive(children)]
              });
            }
          }
        }
        let listToString = "";
        for (let tt = 0; tt < list.length; tt++) {
          listToString += JSON.stringify(list[tt]);
        }
        return JSON.parse(listToString);
      }
    }
    return produit;
  }

  saveComposition() {
    let listCompositions: any[] = []
    let listAllCompositionInTree = this.getAllCompositionsInTree(this.state().treeData, null, listCompositions)
    let list = this.state().listCompositionsWithModification
    var currentParentId = null;
    var map: Map<number, number> = new Map();
    for (let index = 0; index < listAllCompositionInTree.length; index++) {

      var currentParentId = listAllCompositionInTree[index].parentProductId;
      var order = 0;
      if (map.get(currentParentId) != undefined) {
        order = map.get(currentParentId) as number;
      }

      const element = listAllCompositionInTree[index];
      let resultFilter = list.filter(elemList =>
        elemList.composition.parentProductId == element.parentProductId
        && elemList.composition.childProductId == element.childProductId)
      if (resultFilter.length == 0) {
        if (element.parentProductId < 0 || element.childProductId < 0) {
          element.orderIndex = order;
          map.set(currentParentId, order + 1);
          list.push({ composition: element, change: "clone" });
        } else {
          element.orderIndex = order;
          map.set(currentParentId, order + 1);
          list.push({ composition: element, change: "add" });
        }
      } else if (resultFilter.length == 1) {
        let indexOfelement = this.indexOfListCompositionWithModification(element)

        list[indexOfelement].composition = element
        list[indexOfelement].change = "update"
        listAllCompositionInTree[index].orderIndex = order;
        map.set(currentParentId, order + 1);
      }
    }
    for (let index = 0; index < list.length; index++) {
      let elementResult = listAllCompositionInTree.filter(elemList =>
        list[index].composition.parentProductId == elemList.parentProductId
        && list[index].composition.childProductId == elemList.childProductId)
      if (elementResult.length == 0) {
        list[index].change = "delete"
      }
    }
    list = list.filter(elem => elem.change != "none")
    list.sort((a: any, b: any) => {
      if (a.change == "clone" && a.composition.childProductId > b.composition.childProductId) {
        return -1
      }
      return 0
    });
    list.sort((a: any) => {
      if (a.change == "delete") {
        return -1
      }
      return 0
    });

    let json = JSON.stringify(list)

    this.setField({ saveLoading: true })
    this.operation.save(json, () => {
      this.setField({ saveLoading: false })
      this.showToast(ToastHelper.getToast("menuconfigurator.success.tree.save", 'success'), () => { }, 2500)

      this.refresh()
      this.setField({ treeModified: false })

    })


  }
  indexOfListCompositionWithModification(composition: Composition) {
    let list = this.state().listCompositionsWithModification;
    if (composition == null) {
      return -2
    }
    for (let i = 0; i < list.length; i++) {
      const element = list[i];
      if (element.composition.parentProductId == composition.parentProductId
        && element.composition.childProductId == composition.childProductId)
        return i;
    }

    return -1;
  }

  getAllCompositionsInTree(treeData: any, parentComposition: any, listcompositions: any[]) {
    for (let index = 0; index < treeData.length; index++) {
      if (parentComposition != null && (treeData[index]["group"] == undefined || treeData[index].group == false)) {
        var composition: Composition = {

          orderIndex: parentComposition.orderIndex,
          quantityMin: parentComposition.quantityMin,
          quantityMax: parentComposition.quantityMax,
          childProductId: treeData[index].composition.childProductId,
          parentProductId: treeData[index].composition.parentProductId,
          isMandatory: parentComposition.isMandatory,
          childProduct: treeData[index].composition.childProduct,
          parentProduct: treeData[index].composition.parentProduct,
          creationDate: treeData[index].composition.creationDate,
          lastModificationDate: treeData[index].composition.lastModificationDate
        }
        listcompositions.push(composition);
      } else if (parentComposition != null && treeData[index]["group"] != undefined && treeData[index].group == true) {
        var composition: Composition = {

          orderIndex: parentComposition.orderIndex,
          quantityMin: treeData[index].composition.quantityMin,
          quantityMax: treeData[index].composition.quantityMax,
          childProductId: treeData[index].composition.childProductId,
          parentProductId: treeData[index].composition.parentProductId,
          isMandatory: treeData[index].composition.isMandatory,
          childProduct: treeData[index].composition.childProduct,
          parentProduct: treeData[index].composition.parentProduct,
          creationDate: treeData[index].composition.creationDate,
          lastModificationDate: treeData[index].composition.lastModificationDate
        }
        listcompositions.push(composition);
      }
      if (treeData[index].children) {
        this.getAllCompositionsInTree(treeData[index].children, treeData[index].composition, listcompositions);
      }
    }
    return listcompositions;
  }

  deleteElementInTree(node: any) {
    let treeData: any = this.state().treeData
    let path: any;
    let find = false;
    for (let index = 0; index < treeData.length; index++) {
      let element: any = treeData[index]
      if (node.node.composition.childProductId == element.composition.childProductId
        && node.node.composition.parentProductId == element.composition.parentProductId) {
        treeData.splice(index, 1)
        find = true
        break
      } else if (element.children && !find) {
        path = this.recursiveDeleteNode(node, element.children, treeData[index])
        if (path != undefined) {
          find = true;
          break
        }
      }
    }
    this.setField({ treeModified: true })
    this.setField({
      treeData: [...treeData]
    })
  }
  recursiveDeleteNode(target: any, listChildren: any[], path: any): any {
    for (let index = 0; index < listChildren.length; index++) {
      let element: any = listChildren[index]
      if (target.node.composition.childProductId == element.composition.childProductId
        && target.node.composition.parentProductId == element.composition.parentProductId) {
        path.children.splice([index], 1)
        return { path: path, index: index };
      } else if (element.children) {
        this.recursiveDeleteNode(target, element.children, path.children[index])
      }
    }
  }
  changeParentInTree(node: any) {
    let treeData: any = node.treeData
    let path: any;
    let find = false;
    if (node.nextParentNode != null && ((node.nextParentNode.parent != node.node.composition.parentProductId) || (node.prevPath != node.path))) {
      for (let index = 0; index < treeData.length; index++) {
        let element: any = treeData[index]
        if (node.node.composition.childProductId == element.composition.childProductId && node.node.composition.parentProductId == element.composition.parentProductId) {
          find = true
          node.treeData[index].composition.parentProductId = -1
          node.treeData[index].composition.parentProduct = null
          this.setField({ treeModified: true })
          break
        } else if (element.children && !find) {
          path = this.recursiveChangeParentInTree(node, element.children, node.treeData[index])
          if (path != undefined) {
            find = true;

            break
          }
        }
      }
    }
    this.setField({
      treeData: [...treeData]
    })
  }
  recursiveChangeParentInTree(target: any, listChildren: any[], path: any): any {
    for (let index = 0; index < listChildren.length; index++) {
      let element: any = listChildren[index]
      if (target.node.composition.childProductId == element.composition.childProductId && target.node.composition.parentProductId == element.composition.parentProductId) {
        path.children[index].composition.parentProductId = target.nextParentNode.composition.childProductId;
        path.children[index].composition.parentProduct = target.nextParentNode.composition.childProduct;
        this.setField({ treeModified: true })
        return { path: path, index: index };
      } else if (element.children) {
        this.recursiveChangeParentInTree(target, element.children, path.children[index])
      }
    }
  }


  deletePopupVerification(node: any) {
    this.showPopUp(
      <ConfirmPopUpView callBack={() => {
        this.deleteElementInTree(node)
      }} />
    );
  }
  editField(node: any) {
    this.showPopUp(
      <DetailCompositionView node={node} save={this.save} languages={this._languages} />
    );
  }

  editFieldParent(node: any) {
    if (node.node.composition.childProduct.imageTranslations == null || node.node.composition.childProduct.imageTranslations.length <= 0) node.node.composition.childProduct.imageTranslations = Helpers.generateUrlTable(this._languages);
    if (node.node.composition.childProduct.thumbnailTranslations == null || node.node.composition.childProduct.thumbnailTranslations.length <= 0) node.node.composition.childProduct.thumbnailTranslations = Helpers.generateUrlTable(this._languages);
    if (node.node.composition.childProduct.secondaryImageTranslations == null || node.node.composition.childProduct.secondaryImageTranslations.length <= 0) node.node.composition.childProduct.secondaryImageTranslations = Helpers.generateUrlTable(this._languages);

    if (node.node.composition.childProduct.secondaryTitleTranslations == null || node.node.composition.childProduct.secondaryTitleTranslations.length <= 0) node.node.composition.childProduct.secondaryTitleTranslations = Helpers.generateTranslationTable(this._languages);
    if (node.node.composition.childProduct.secondaryDescriptionTranslations == null || node.node.composition.childProduct.secondaryDescriptionTranslations.length <= 0) node.node.composition.childProduct.secondaryDescriptionTranslations = Helpers.generateTranslationTable(this._languages);
    this.showPopUp(
      <ProductPopupView
        product={Helpers.clone(node.node.composition.childProduct)}
        languages={this._languages}
        refreshParent={this.refresh}
        handleBack={() => { Helpers.closePopup() }}
        isGroup={false}
        nonMandatory={['title', 'posId']}
      />
    );
  }
  save() {
    this.setField({ treeModified: true })
  }

  refreshMenu() {
    this.setField({ isRefreshMenuLoading: true })
    this.adminProduct.updateMenuConfig(() => {
      this.setField({ isRefreshMenuLoading: false });
      this.refresh();
    })
  }

}