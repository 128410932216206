const EnumModules =
{
    USERS: 1,
    REWARDS: 2,
    COUPONS: 3,
    BOOSTERS: 4,
    NEWS: 5,
    LOYALTY_RULES: 6,
    LEGAL_TEXTS: 7,
    STATS: 8,
    STORES: 9,
    TRANSLATION: 10,
    FAQ: 11,
    SAVINGCARDS: 12,
    POS_SIMULATOR: 13,
    PRODUCTS: 14,
    PRODUCT_CATEGORIES: 15,
    AUTOMATED_MESSAGES: 16,
    MESSAGES: 17,
    ORDERS: 18,
    ROLEPERMISSIONS: 19,
    ADMINUSERS: 20,
    KITCHENAPP: 21,
    TICKETS : 22,
    MENUCONFIGURATOR:23,
    PROMOCODES:24,
    EVERYDAYVALUES :25,
    HEALTHSTATUS:26,
    LANGUAGE : 27,
    COUNTRIES:28,
    OVERVIEW : 29,
    STORES_SUMMARY : 30,
    CROSS_SELL_CONFIGURATOR : 31,
    OUT_OF_STOCK :32,
    CONTENT_TYPE_KEY_CONFIGURATOR : 33,
    BANNERS: 34,
    PRODUCTS_TOOLS: 35,
    ZONES_TOOLS: 36,
    EARNINGPOINT: 37,
    MEMBERSHIP : 38,
    VOUCHERS : 39,    
    PRODUCT_ADDITIONAL_INFOS: 40,
}

export default EnumModules;