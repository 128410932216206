import { Animated } from 'react-native';
import assets from 'src/Common/Assets';

import { ContentViewModel, ContentView } from '@levelapp/softfabric';

import MenuItemViewState from './MenuItemViewState';
import MenuItemViewProps from './MenuItemViewProps';
import MenuItemViewStyle from './MenuItemViewStyle';

export default class MenuItemViewModel extends ContentViewModel<MenuItemViewState, MenuItemViewProps, MenuItemViewStyle>
{
  /* CONSTRUCTOR */
  _opacityAnimation: Animated.Value;
  _heightAnimation: Animated.Value;
  _heightTotalAnimation: Animated.Value;
  _maxheight: number;

  /* CONSTRUCTOR */
  constructor(view: ContentView) {
    super(view);

    this.initialState({
      active: false
    });

    // Bindings
    this._opacityAnimation = new Animated.Value(0);
    this._heightAnimation = new Animated.Value(0);
    this._heightTotalAnimation = new Animated.Value(50);
    this._maxheight = 50;
    this.handlePressTick = this.handlePressTick.bind(this);
    this.handleMenuPress = this.handleMenuPress.bind(this);
  }

  handleAnimation(value: number) {
    Animated.spring(this._opacityAnimation, {
      useNativeDriver: true,
      toValue: value,
    }).start();
  }

  handleAnimationHeight(value: number) {
    Animated.spring(this._heightAnimation, {
      useNativeDriver: true,
      toValue: value,
    }).start();
  }

  handlePressTick() {
    if (this.props().isDirect != undefined && !this.props().isDirect) {
      const number = this.state().active ? 0 : 1;
      this.setField({ active: !this.state().active })
      this.handleAnimationHeight(number);
    }
  }

  handleMenuPress() {
    if (this.props().isDirect != undefined && !this.props().isDirect) {
      const number = this.state().active ? 0 : 1;
      
      if (!this.state().active) {
        this.setField({ active: !this.state().active })
        this.handleAnimationHeight(number);
      }
    }

    this.props().onPress();
  }

  getImage() {
    if (!this.state().active) {
      if (this.props().selected && !this.props().underMenu && !this.props().isDirect) {
        return assets.arrowRight;
      } else if (this.props().selected && this.props().underMenu) {
        return ''
      } else if (!this.props().selected && !this.props().underMenu && !this.props().isDirect) {
        return assets.arrowRightGray;
      }
    } else {
      if (this.props().selected && !this.props().underMenu) {
        return assets.arrowDown;
      } else if (this.props().selected && this.props().underMenu) {
        return ''
      } else if (!this.props().selected && !this.props().underMenu && !this.props().isDirect) {
        return assets.arrowDownGray;
      }
    }
  }

  getTextStyle() {
    const { selected, underMenu, isDirect, isSingleProduct } = this.props();

    if (selected && underMenu && isDirect) {
      return [this.style().linkBoldText, !isSingleProduct && { paddingLeft: 30 }]
    } else if (selected && !underMenu && !isDirect) {
      return [this.style().linkTextActive];
    } else if (selected && isDirect && !underMenu) {
      return [this.style().linkTextActive];
    } else if (isSingleProduct) {
      return [{ paddingLeft: 25 }];
    } else if (underMenu && isDirect) {
      return [{ paddingLeft: 30 }];
    } else if (!underMenu) {
      return [{ paddingLeft: 25 }];
    } else {
      return null;
    }
  }
}