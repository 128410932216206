 
import { View, Text } from 'react-native';
import { format, parseISO } from 'date-fns';
import { fr } from 'date-fns/locale'

import { ContentView } from '@levelapp/softfabric';
import { TranslationView } from '@levelapp/softfabric-ui';

import AvailabilityLogsTabViewState from './AvailabilityLogsTabViewState';
import AvailabilityLogsTabViewProps from './AvailabilityLogsTabViewProps';
import AvailabilityLogsTabViewStyle from './AvailabilityLogsTabViewStyle';
import AvailabilityLogsTabViewModel from './AvailabilityLogsTabViewModel';
import NoDataPlaceholderView from '../nodataplaceholder/NoDataPlaceholderView';
import LogTabHeaderView from '../logtabheader/LogTabHeaderView';

export default class AvailabilityLogsTabView extends ContentView<AvailabilityLogsTabViewProps, AvailabilityLogsTabViewState, AvailabilityLogsTabViewModel, AvailabilityLogsTabViewStyle>
{
  /* CONSTRUCTOR */
  constructor(props: AvailabilityLogsTabViewProps) {
    super(props);

    // Binding
    this.bind(new AvailabilityLogsTabViewModel(this), new AvailabilityLogsTabViewStyle(this));
  }

  /* RENDERING */
  render() {
    const { availabilityHistories } = this.props;

    return (
      <>
        {availabilityHistories.length > 0 && (
          <View style={this.style.content}>
            <LogTabHeaderView />
            {availabilityHistories.map((history) => (
              <View style={this.style.row}>
                <Text style={this.style.text}>{format(parseISO(history.date), "P", { locale: fr })}</Text>
                <Text style={this.style.text}>{history.locations}</Text>
                <Text style={this.style.text}>{history.orderSources}</Text>
                <TranslationView style={this.style.text}>
                  {history.oldValue ? 'tools.logs.table.unavailable' : 'tools.logs.table.available'}
                </TranslationView>
                <TranslationView style={this.style.text}>
                  {history.newValue ? 'tools.logs.table.unavailable' : 'tools.logs.table.available'}
                </TranslationView>
              </View>
            ))}
          </View>
        )}
        {availabilityHistories.length <= 0 && (
          <NoDataPlaceholderView />
        )}
      </>
    );
  }
}