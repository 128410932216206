 
import {ContentShellView} from '@levelapp/softfabric';
import ShellStoresSummaryViewState from './ShellStoresSummaryViewState';
import ShellStoresSummaryViewProps from './ShellStoresSummaryViewProps';
import ShellStoresSummaryViewStyle from './ShellStoresSummaryViewStyle';
import ShellStoresSummaryViewModel from './ShellStoresSummaryViewModel';
import {View} from 'react-native';

export default class ShellStoresSummaryView extends ContentShellView<ShellStoresSummaryViewProps, ShellStoresSummaryViewState, ShellStoresSummaryViewModel, ShellStoresSummaryViewStyle>
{
  /* CONSTRUCTOR */
  constructor(props:ShellStoresSummaryViewProps)
  {
    super(props);

    // Binding
    this.bind(new ShellStoresSummaryViewModel(this), new ShellStoresSummaryViewStyle(this));
  }

  /* RENDERING */
  render()
  {
    return (
      <View style={{flex : 1}}>
        {this.getSwitch()}
      </View>
    );
  }
}