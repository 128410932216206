 
import { View } from 'react-native';

import { LottieView } from '@levelapp/softfabric-ui';
import { ContentView } from '@levelapp/softfabric';

import LogTabViewState from './LogTabViewState';
import LogTabViewProps from './LogTabViewProps';
import LogTabViewStyle from './LogTabViewStyle';
import LogTabViewModel from './LogTabViewModel';
import FiltersView from '../filters/FiltersView';

import Constants from 'src/Common/Constants/Constants';
import AvailabilityLogsTabView from '../availabilitylogstab/AvailabilityLogsTabView';
import PriceLogsTabView from '../pricelogstab/PriceLogsTabView';

export default class LogTabView extends ContentView<LogTabViewProps, LogTabViewState, LogTabViewModel, LogTabViewStyle>
{
  /* CONSTRUCTOR */
  constructor(props: LogTabViewProps) {
    super(props);

    // Binding
    this.bind(new LogTabViewModel(this), new LogTabViewStyle(this));
  }

  /* RENDERING */
  render() {
    const {
      availabilityHistories,
      priceHistories,
      selectedFilter,
      isLoading,
    } = this.state;

    return (
      <View>
        <View style={this.style.filters}>
          <FiltersView
            selectedFilter={this.state.selectedFilter}
            setSelectedFilter={this.binding.setSelectedFilter}
          />
        </View>
        {!isLoading ? (
          <>
            {selectedFilter === 'availability' && <AvailabilityLogsTabView availabilityHistories={availabilityHistories} />}
            {selectedFilter === 'price' && <PriceLogsTabView priceHistories={priceHistories} />}
          </>
        ) : (
          <LottieView height={150} width={150} source={require('src/Common' + Constants.LottieAnimationPath)} />
        )}
      </View>
    );
  }
}