 
import {ContentView} from '@levelapp/softfabric';
import LanguagePopUpViewState from './LanguagePopUpViewState';
import LanguagePopUpViewProps from './LanguagePopUpViewProps';
import LanguagePopUpViewStyle from './LanguagePopUpViewStyle';
import LanguagePopUpViewModel from './LanguagePopUpViewModel';
import PopupHeaderView from '../../../../components/popupheader/PopupHeaderView';
import Helpers from '../../../../../../../Common/Helpers/Helpers';
import {  View } from 'react-native';
import ExtendedTextInputView from '../../../../components/extendedtextinput/ExtendedTextInputView';
import ExtendedCheckBoxView from '../../../../components/extendedcheckbox/ExtendedCheckBoxView';
import ExtendedTranslationView from '../../../../components/extendedtranslation/ExtendedTranslationView';
import SectionTitleView from '../../../../components/sectiontitle/SectionTitleView';

export default class LanguagePopUpView extends ContentView<LanguagePopUpViewProps, LanguagePopUpViewState, LanguagePopUpViewModel, LanguagePopUpViewStyle>
{
  /* CONSTRUCTOR */
  constructor(props:LanguagePopUpViewProps)
  {
    super(props);

    // Binding
    this.bind(new LanguagePopUpViewModel(this), new LanguagePopUpViewStyle(this));
  }
  

  /* RENDERING */
  render()
  {
    const { container , popup } = this.style;
    return (
      <View style={popup}>

        <PopupHeaderView
          handleBackPressed={Helpers.closePopup}
          title={`language.popup.title.${this.props.type}`}
          shouldShowButton
          isLoading={this.state.isLoading}
          handleOnPressButton={this.binding.handleSavePressed}
        />    
      
      <View style={container}>
        <SectionTitleView title={'form.informations'}/>

        <View style={{justifyContent : 'space-between' , flexDirection : 'row'}}>
          <ExtendedTextInputView
              infoText={this.binding.translate('info.languagekey')}
              title={'language.form.key'}
              showError={false}
              value={`${this.props.language.key}`}
              onChangeText={(text: string) => this.binding._dispatcherHelper.handleValueChange("languageKey",text)}
            />

          <View>
            <ExtendedTranslationView uppercase>
              language.options
            </ExtendedTranslationView>
            <ExtendedCheckBoxView
              isTranslation
              title={'language.checkbox.default'}
              value={this.props.language.isDefault?? false}
              onValueChange={(isDefault: boolean) => this.binding._dispatcherHelper.handleCheckboxChange("isLanguageDefault",isDefault)}
                />
            <ExtendedCheckBoxView
              isTranslation
              title={'language.checkbox.active'}
              value={this.props.language.isActive?? false}
              onValueChange={(isActive: boolean) => this.binding._dispatcherHelper.handleCheckboxChange("isLanguageActive",isActive)}
            /> 
          </View>
        </View>
        
                
      </View>
      
    
    </View>
      
          
          
    );
  }
}