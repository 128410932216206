import { ContentViewModel, ContentView } from '@levelapp/softfabric';
import StoresViewState from './StoresViewState';
import StoresViewProps from './StoresViewProps';
import StoresOperations from '../../../../../../../Transfer/StoresOperations';
import { TableRow } from '@levelapp/softfabric-ui';
import StorespopupView from '../storespopup/StorespopupView';
import Helpers from '../../../../../../../Common/Helpers/Helpers';

import LanguagesOperations from '../../../../../../../Transfer/LanguagesOperations';
import ConfirmPopUpView from '../../../../../../../Common/Components/confirmpopup/ConfirmPopUpView';
import ToastHelper from '../../../../../../../Common/Helpers/ToastHelper';
import Language from '../../../../../../../Common/DTO/Language';
import ErrorHelper from '../../../../../../../Common/Helpers/ErrorHelper';
import TimeZonesOperations from '../../../../../../../Transfer/TimeZoneOperations';
import TimeZoneInfo from '../../../../../../../Common/DTO/TimeZoneInfo';

export default class StoresViewModel extends ContentViewModel<StoresViewState, StoresViewProps>{

    _operation: StoresOperations;
    _languagesOperation: LanguagesOperations;
    _timeZonesOperation : TimeZonesOperations;
    _languages: Language[] = [];
    _storeTypes: any[] = [];
    _timeZones: TimeZoneInfo[] = [];
    interval: any;
    searchText: string;
    isSorting: boolean;
    indexHeaderSort: number;
    sortOrder: string;
    sortKey: string[];

    constructor(props: ContentView)
    {
        super(props);

        this.interval = null;
        this.searchText = '';
        this.isSorting = false;
        this.indexHeaderSort = -1;
        this.sortOrder = 'asc';
        this.sortKey = ['Id', 'ExId', 'Slug', 'Type'];

        /* Initial State */
        this.initialState({
            tableData: [],
            tableHeaders: ['ID', 'EXTERNAL ID', 'SLUG', 'TYPE'],
            mapData: [],
            isLoading: true,
            tab: 'list',
            nbPage: 0,
            currentPage: 1,
            isResearching: false
        });

        this._operation = new StoresOperations();
        this._languagesOperation = new LanguagesOperations();
        this._timeZonesOperation = new TimeZonesOperations();

        this._languagesOperation.get((languages: any) =>
        {
            this._languages = languages.data;
        });

        this._operation.getStoreTypes((storeTypes: any) =>
        {
            this._storeTypes = storeTypes.data;
        })

        this._timeZonesOperation.getAll((timeZones: any) =>
        {
            this._timeZones = timeZones.data;
        })

        this.addField = this.addField.bind(this);
        this.changeTab = this.changeTab.bind(this);
        this.onPageChange = this.onPageChange.bind(this);
        this.refresh = this.refresh.bind(this);
        this.getData = this.getData.bind(this);
        this.onSortChange = this.onSortChange.bind(this);
        this.handleResearching = this.handleResearching.bind(this);
        this.handleCloseResearching = this.handleCloseResearching.bind(this);
        this.errorManagement = this.errorManagement.bind(this);
    }

    componentDidMount()
    {
        this.refresh();
    }

    refresh()
    {
        this.setField({ isLoading: true });
        let parameters = [];
        parameters.push({ key: "page", value: this.state().currentPage }, { key: "itemPage", value: 20 });
        if (this.state().isResearching)
        {
            parameters.push({ key: "search", value: this.searchText });
        }
        if (this.isSorting)
        {
            parameters.push(
                { key: 'columnSort', value: this.sortKey[this.indexHeaderSort] },
                { key: 'sortOrder', value: this.sortOrder });
        }
        this.getData(parameters);
    }

    getData(queryParameter: any[])
    {
        this._operation.get(queryParameter, (stores: any) =>
        {
            let tableData: TableRow[] = [];
            let mapData: any[] = [];
            stores.data.objects.forEach((store: any) =>
            {
                
                tableData.push({ data: [store.id, store.externalId, store.slug, this._storeTypes.find((storeType: any) => { return storeType.id == store.typeId }).name], isEditable: true, isDeletable: true, editCallback: () => { this.editField(store) }, deleteCallback: () => { this.deleteField(store) } })
                mapData.push({ pos: { lat: store.lattitude, lng: store.longitude }, editCallback: () => { this.editField(store) } });
            });
            this.setField({ tableData: tableData, mapData: mapData, storesList: stores.data.objects, nbPage: stores.data.totalPage, isLoading: false });
        },this.errorManagement);
    }

    editField(store: any)
    {
        // if(store.imageTranslations == null){
        //     store.imageTranslations = Helpers.generateUrlTable(this._languages.length);
        // }
        store.imageTranslations = Helpers.generateUrlTableEdit(store.imageTranslations,this._languages)
        this.showPopUp(
            <StorespopupView store={Helpers.clone(store)} refreshParent={this.refresh} languages={this._languages} storeTypes={this._storeTypes} timeZones={this._timeZones} isEdition={true} />
        );
    }

    deleteField(store: any)
    {
        this.showPopUp(
            <ConfirmPopUpView callBack={() =>
            {
                this._operation.delete(store, () =>
                {
                    this.refresh();
                    this.showToast(ToastHelper.getToast("stores.popup.success.deleted","success"),() => {},2500);
                },this.errorManagement);
            }} />
        );
    }

    addField()
    {
        let storeTypeId;
        if (this._storeTypes.length == 0)
        {
            storeTypeId = 0
        } else
        {
            storeTypeId = this._storeTypes[0].id
        }
        
        this.showPopUp(

            <StorespopupView
                store={{
                    titleTranslations: Helpers.generateTranslationTable(this._languages),
                    descriptionTranslations: Helpers.generateTranslationTable(this._languages),
                    streetTranslations: Helpers.generateTranslationTable(this._languages),
                    urlTranslations: Helpers.generateTranslationTable(this._languages),
                    cityTranslations: Helpers.generateTranslationTable(this._languages),
                    imageTranslations: Helpers.generateUrlTable(this._languages),
                    properties: [],
                    storeOpeningHours: [],
                    driveOpeningHours: [],
                    deliveryOpeningHours: [],
                    deliveries: [],
                    typeId: storeTypeId,
                    orderTypes: [],
                    storePaymentOrderSources:[]
                }
                }
                languages={this._languages}
                refreshParent={this.refresh}
                storeTypes={this._storeTypes}
                timeZones={this._timeZones}
                isEdition={false} />
        );
    }

    

    changeTab(tab: string)
    {
        this.setField((previousState) => previousState.tab = tab);
    }

    onPageChange(page: number)
    {
        this.state().currentPage = page + 1;
        this.refresh()
    }

    onSortChange(indexHeaderSort: number, sortOrder: string)
    {
        if (indexHeaderSort == -1)
        {
            this.isSorting = false;
        } else
        {
            this.indexHeaderSort = indexHeaderSort;
            this.sortOrder = sortOrder;
            this.isSorting = true;
        }
        this.refresh();
    }

    handleResearching(searchText: string)
    {
        this.setField({ isLoading: true, isResearching: true });
        this.searchText = searchText;

        if (this.interval)
        {
            clearInterval(this.interval);
        }
        this.interval = setTimeout(() =>
        {
            this.onPageChange(0)
        }, 1000)
    }

    handleCloseResearching()
    {
        this.state().isResearching = false;
        this.onPageChange(0);
    }

    errorManagement(error : any) 
    {
        this.setField({isLoading : false});
        ErrorHelper.showMessageError(error.response);
    }
}