 
import { View,Text } from 'react-native';
import { ContentView } from '@levelapp/softfabric';
import MessagesViewState from './MessagesViewState';
import MessagesViewProps from './MessagesViewProps';
import MessagesViewStyle from './MessagesViewStyle';
import MessagesViewModel from './MessagesViewModel';
import LineView from '../../../components/line/LineView';
import { LottieView, TranslationView } from '@levelapp/softfabric-ui';
import Constants from '../../../../../../Common/Constants/Constants';
import MessageBlockView from './components/messageblock/MessageBlockView';
import ExtendedCheckBoxView from '../../../components/extendedcheckbox/ExtendedCheckBoxView';
import Language from '../../../../../../Common/DTO/Language';
import Helpers from '../../../../../../Common/Helpers/Helpers';
import SectionTitleView from '../../../components/sectiontitle/SectionTitleView';
import ButtonView from '../../../../../../Common/Components/Button/ButtonView';
import ExtendedTextInputView from '../../../components/extendedtextinput/ExtendedTextInputView';
import PopupHeaderView from '../../../components/popupheader/PopupHeaderView';
import PopupMenuItemView from '../../../components/popupmenuitem/PopupMenuItemView';
import TestPopupView from './testpopup/TestPopupView';

export default class MessagesView extends ContentView<MessagesViewProps, MessagesViewState, MessagesViewModel, MessagesViewStyle>
{
  /* CONSTRUCTOR */
  constructor(props: MessagesViewProps) {
    super(props);
    // Binding
    this.bind(new MessagesViewModel(this), new MessagesViewStyle(this));
  }

  /*
    <HeaderView
    title={"messageCampaignForm.message"}
    description={"messageCampaignForm.popup.title"}
    shouldShowButton={true}
    onButtonPress={this.binding.saveMessageCampaign}
    buttonText={this.state.isEdit ? "messageCampaignForm.save" : "messageCampaignForm.add"}
    button1Loading = {this.state.isButton1Loading}
    shouldShowButton2={this.state.isEdit}
    onButtonPress2={this.binding.testCampaign}
    buttonText2={"messageCampaignForm.test"}
    button2Loading={this.state.isButton2Loading}
  />
  */


  /* RENDERING */
  render() {

    const { container , popup, checkBoxContainer } = this.style;
    return (
        <View style={popup}>
          
          <PopupHeaderView
            handleBackPressed={Helpers.closePopup}
            title={"messageCampaignForm.message"}
            shouldShowButton
            isLoading={this.state.isLoading}
            handleOnPressButton={this.binding.saveMessageCampaign}
          />  
          {this.props.mc != undefined &&
          <View style={{ display: "flex", flexDirection: "row", flexWrap: "wrap" }}>
            <PopupMenuItemView activeItem={this.state.tab} title={"form.maininformation"} item={"main"} handleChangeTab={() => this.binding.changeTab("main")}/>
            <PopupMenuItemView activeItem={this.state.tab} title={"messageCampaignForm.test"} item={"test"} handleChangeTab={() => this.binding.changeTab("test")}/>
          </View>
          }
            

          {this.state.tab == "main" && this.state.metaData != null &&
          <View style = {container}>
            {/* Title Side */}

            <SectionTitleView title={'form.informations'}/>
                <View style={{ flexDirection: "row" , zIndex : 1 }}>
                    <ExtendedTextInputView
                        title={"form.id"}
                        disable
                        showError={false}
                        value={`${this.props.mc == undefined ? ' ' :this.props.mc.id}`}
                        onChangeText={() => {}}
                      />
                    
                    <ExtendedTextInputView
                      style={{width : 300}}
                      infoText={this.binding.translate('messageCampaignForm.emailToNotify.info')}
                      title={'messageCampaignForm.emailToNotify'}
                      showError={false}
                      value={this.state.emailToNotify}
                      onChangeText={(text: string) => this.binding._dispatcherHelper.handleValueChange("email",text)}
                    />
                </View>
            <LineView/>
            {!this.state.isLoadingFirst ? (
            <View>
              <View style={{ flexDirection: "column" }}>
                <SectionTitleView title={'form.messagefields'}/>
                <View style={{ display: "flex", flexDirection: "row", flexWrap: "wrap", justifyContent: 'space-between' }}>
                  {this.state.languages.map((content: Language, index: any) => {
                    var _title = Helpers.getTranslationValueByLanguageKey(this.state.titleTranslations,content.key);
                    var _message = Helpers.getTranslationValueByLanguageKey(this.state.messageTranslations,content.key);
                    return (
                      <MessageBlockView
                        message={_message}
                        messageTitle={_title}
                        handleChangeTextFirstInput={(text: string) => this.binding.handleTitleChanged(text, index)}
                        handleChangeTextSecondInput={(text: string) => this.binding.handleMessageChanged(text, index)}
                        titleExtra={content.key.toUpperCase()}
                        placeholderFirstInput={this.binding.translate('form.titlefield')}
                        placeholderSecondInput={this.binding.translate('form.messagefields')}
                        shouldShowErrorFirstInput={this.state.showErrors && (_title === null || _title === "")}
                        shouldShowErrorSecondInput={this.state.showErrors && (_message === null || _message === "")}
                      />

                    );
                  })}
                </View>
              </View>
              {/* <LineView/>

              <SegmentationView changeSegmentation={this.binding.changeSegmentation} metaData={this.state.metaData} segmentation = {this.state.segmentation}/> */}

              <LineView/>
              {/*Types of messages : push notifications...*/}
              <View style={{ flexDirection: "column",zIndex : -10 }}>


                <SectionTitleView title={'message.messagetypes.title'}/>

                <View style={{ flexDirection: 'column' }}>
                  <View style={checkBoxContainer}>
                    <ExtendedCheckBoxView
                      isTranslation
                      title={"form.email"}
                      value={this.state.isEmail}
                      onValueChange={this.binding.handleCheckBoxEmailChanged}
                      shouldShowError={this.state.showErrorTypeMessage}
                    />
                    <View style={this.style.checkBoxLabel}>
                      <TranslationView style={{fontSize:10}}>{"messageCampaignForm.nbMailToSend"}</TranslationView>
                      <Text style={{fontSize:10}}>{this.state.nbMailToSend}</Text>
                    </View>
                  </View>
                  
                  <View style={checkBoxContainer}>
                    <ExtendedCheckBoxView
                      isTranslation
                      title={"form.inapp"}
                      value={this.state.isInApp}
                      onValueChange={this.binding.handleCheckBoxInAppChanged}
                      shouldShowError={this.state.showErrorTypeMessage}
                    />
                    <View style={this.style.checkBoxLabel}>
                      <TranslationView style={{fontSize:10}}>{"messageCampaignForm.nbInAppToSend"}</TranslationView>
                      <Text style={{fontSize:10}}>{0}</Text>
                    </View>
                  </View>
                  
                  <View style={checkBoxContainer}>
                    <ExtendedCheckBoxView
                      isTranslation
                      title={"form.pushnotification"}
                      value={this.state.isPushNotif}
                      onValueChange={this.binding.handleCheckBoxPushChanged}
                      shouldShowError={this.state.showErrorTypeMessage}
                    />
                    <View style={this.style.checkBoxLabel}>
                      <TranslationView style={{fontSize:10}}>{"messageCampaignForm.nbNotifToSend"}</TranslationView>
                      <Text style={{fontSize:10}}>{this.state.nbNotifToSend}</Text>
                    </View>
                  </View>

                </View>
              </View>


            </View>) : (<LottieView height={150} width={150} source={require('../../../../../../Common' + Constants.LottieAnimationPath)} />)}
          </View>}

          {this.state.tab == "test" &&
            <TestPopupView messageCampaignId={this.props.messageCampaignId}/>
          }
          {this.state.isEdit && this.state.tab == "main" && this.props.mc != undefined && this.props.mc.state != 'Creating' && <View style={this.style.buttonBox}>
            <ButtonView isLoading={this.state.isStartButtonLoading} handleClick={() => this.binding.startCampaign()} backgroundColor={"#FE7E5C"} textColor='white'>
              <TranslationView upperCase={true}>
                {"messageCampaignForm.start"}
              </TranslationView>
            </ButtonView>
          </View>}
          
        </View>
    );
  }
}
