import {Application, ContentViewStyle} from '@levelapp/softfabric';
import { StyleManager, UiManagers } from '@levelapp/softfabric-ui';
import {Dimensions, StyleProp,TextStyle,ViewStyle} from 'react-native';

export default class CrossSellConfiguratorViewStyle extends ContentViewStyle
{
    MyStyleManager : StyleManager = Application.current.resolve<StyleManager>(UiManagers.STYLE_MANAGER);
    
    container: StyleProp<ViewStyle> = {
        display: 'flex',
        height : Dimensions.get('window').height,
        alignItems: 'center',
        paddingTop: 40,
        paddingBottom : 10,
    }

    box: StyleProp<ViewStyle> = {
        width: '95%',
        backgroundColor: 'white',
        borderRadius: 6,
        shadowColor: "#0000006b",
        shadowOffset: {
            width: 1,
            height: 0,
        },
        shadowOpacity: 0.70,
        shadowRadius: 20.00,
        elevation: 60,
    }

    boxSubtitle: StyleProp<ViewStyle> =
    {
        flex: 1,
    }

    title: StyleProp<TextStyle> = {
        color: 'white',
        fontSize: 30,
        paddingTop: 30,
        paddingLeft: 54,
    }

    subTitle: StyleProp<TextStyle> = {
        color: 'white',
        fontSize: 17,
        paddingTop: 2,
        marginLeft: 54,
        marginBottom: 10,
        fontWeight: '100'
    }

    buttonBox : StyleProp<ViewStyle> =
    {
        flexDirection: 'row',
        justifyContent: 'flex-end',
        alignItems:'center',
        flex:1,
        padding: 50,
    }

    boxTitle: StyleProp<ViewStyle> = {
        width: '98%',
        minHeight : 141,
        marginTop: -20,
        margin: 'auto',
        borderRadius: 8,
        flexDirection: 'row',
    }


    boxContainer: StyleProp<ViewStyle> = {
        display: 'flex',
        flex : 1,
        minHeight: 250,
        width: '100%',
        paddingTop: 30,
        paddingBottom: 30,
        paddingLeft: 50,
        paddingRight: 50,
    }
}