import { Service, Application, HttpManager, Managers } from "@levelapp/softfabric";

export default class LTOProductService extends Service{

    httpClient = Application.current.resolve<HttpManager>(Managers.HTTP_MANAGER);

    async get(parameters:any,onSuccess: Function){
        this.httpClient
        .get('LTOProduct/Get/'+parameters)
        .then((response : any) => {
            if (response)
            {
                if (response.data)
                {                  
                    onSuccess(response.data);
                }
            }
        });
    }
}