import {Application, ContentViewStyle} from '@levelapp/softfabric';
import { StyleManager, UiManagers } from '@levelapp/softfabric-ui';
import {StyleProp,TextStyle,ViewStyle} from 'react-native';

export default class VouchersPopUpViewStyle extends ContentViewStyle
{
    MyStyleManager: StyleManager = Application.current.resolve<StyleManager>(UiManagers.STYLE_MANAGER);
  
    title: StyleProp<TextStyle> = {
      textAlign: 'left',
      color: 'white',
      fontSize: 24,
      fontWeight: '600',
      marginTop: 20,
      marginLeft: 60,
      paddingBottom: 20,
      display: "flex",
      flexDirection: "column",
      flexBasis: "85%"
    };
  
    deleteButton: StyleProp<ViewStyle> = {
      marginLeft: 15,
      alignSelf: 'center',
      alignItems: 'center'
    }
  
    scheduleTitle: StyleProp<TextStyle> = {
      fontSize: 25,
      fontWeight: '600',
      color: '#3F3F3F',
      fontFamily: 'Montserrat SemiBold',
      marginBottom: 13,
    };
  
    inputError: StyleProp<ViewStyle> = {
      borderColor: 'red',
      borderWidth: 1,
      width: 140,
      paddingHorizontal: 15,
      borderRadius: 8,
    };
  
    label: StyleProp<TextStyle> = {
      fontSize: 16,
      fontWeight: '600',
      color: '#525f7f',
      marginBottom: 10
    };
  
    section: StyleProp<ViewStyle> = {
      alignSelf: 'flex-end',
      marginVertical: 15,
    };
  
    sectionDatePicker: StyleProp<ViewStyle> = {
      marginLeft: 15,
    };
  
    sectionId: StyleProp<ViewStyle> = {
      alignSelf: 'flex-start'
    };
  
    radioContainer: StyleProp<ViewStyle> = {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      marginTop: 5,
      marginBottom: 5
    };
  
    radioLabel: StyleProp<TextStyle> = {
      fontSize: 16,
      fontWeight: '600',
      color: '#8898aa',
      marginLeft: 10
    };
  
    translationContainer: StyleProp<ViewStyle> = {
      marginTop: 10,
      marginBottom: 10
    };
  
    footer: StyleProp<ViewStyle> = {
      display: 'flex',
      flexDirection: 'row',
      margin: 'auto',
      marginTop: 50,
      marginBottom: 20
    };
  
    input: StyleProp<any> = {
      width: 140,
      paddingHorizontal: 15,
      borderRadius: 8,
      boxShadow: '0 5px 14px 0 rgba(0,0,0,0.1)',
    };
  
    inputTitleUser: StyleProp<any> = {
      width: 475,
      height: 92,
      paddingHorizontal: 5,
      borderRadius: 8,
      boxShadow: '0 5px 14px 0 rgba(0,0,0,0.1)',
    };
  
    textArea: StyleProp<any> = {
      width: '100%',
      minWidth: 475,
      height: 92,
      paddingHorizontal: 5,
      borderRadius: 8,
      boxShadow: '0 5px 14px 0 rgba(0,0,0,0.1)',
    };
  
    errorTextArea: StyleProp<ViewStyle> = {
      borderColor: 'red',
      borderWidth: 1,
      width: '100%',
      minWidth: 475,
      height: 92,
      paddingHorizontal: 5,
      borderRadius: 8,
    };
  
    errorTextField: StyleProp<ViewStyle> = {
      borderColor: 'red',
      borderWidth: 1,
      width: 140,
      paddingHorizontal: 15,
      borderRadius: 8,
    };
  
    step: StyleProp<ViewStyle> = {
      display: "flex",
      flexDirection: "row",
      flexWrap: "wrap"
    };
  
    tabActive: StyleProp<ViewStyle> = {
      backgroundColor: "white",
      width: "100%",
      alignItems: "center",
      height: "50px",
      display: "flex",
      justifyContent: "center",
      borderBottomColor: '#E4E4E4',
      borderBottomWidth: 1
    };
  
    tab: StyleProp<ViewStyle> = {
      backgroundColor: "#EEF0F4",
      width: "100%",
      alignItems: "center",
      height: "50px",
      display: "flex",
      justifyContent: "center",
      borderBottomColor: '#E4E4E4',
      borderBottomWidth: 1
    };
  
    inputTitle: StyleProp<TextStyle> = {
      fontSize: 14,
      fontWeight: '600',
      color: '#3F3F3F',
      fontFamily: 'Montserrat SemiBold',
      marginBottom: 13,
    };
  
    titleActive: StyleProp<TextStyle> = {
      fontSize: 14,
      color: '#3F3F3F',
      fontFamily: 'Montserrat SemiBold',
    }
  
    titleInactive: StyleProp<TextStyle> = {
      color: '#B8BBC1',
      fontSize: 14,
      fontFamily: 'Montserrat SemiBold',
    }
  
    saveButton: StyleProp<TextStyle> = {
      color: this.MyStyleManager.primaryColor,
      fontSize: 10,
      fontWeight: '600',
      height: 27,
      width: 112
    };
  
    datePicker: StyleProp<any> = {
      borderColor: 'red',
      borderWidth: 2,
    }
  
    checkbox: StyleProp<any> = {
      width: 20,
      height: 20,
      marginHorizontal: 5,
    }
  
    importButton: StyleProp<ViewStyle> = {
      marginLeft: 30,
      marginBottom: 10,     
      backgroundColor: 'white',
      width: 105,
      borderRadius: 10,
      height: 34,
  }
  }