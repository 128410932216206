import {ContentViewStyle} from '@levelapp/softfabric';
import {StyleProp,ViewStyle,TextStyle} from 'react-native';

export default class BasketordertypeViewStyle extends ContentViewStyle
{
    page: StyleProp<ViewStyle> = {
        flex: 1
    }

    container : StyleProp<ViewStyle> = {
        flexDirection: "column",
        paddingHorizontal: 170,
        paddingVertical: 50
    }

    

    bodyTitle : StyleProp<TextStyle> =
    {
        fontSize: 25,
        fontWeight: "600",
        color: "#3F3F3F",
        fontFamily: "Montserrat SemiBold",
        marginBottom: 13
    }
}