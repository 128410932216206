import { Service, Application, HttpManager, Managers } from '@levelapp/softfabric';


export default class TagService extends Service
{
    httpClient = Application.current.resolve<HttpManager>(Managers.HTTP_MANAGER);

    post(tagCreation: {label: string, prefixKey: string }, onSuccess : Function = () => {}, onFailure : Function = () => {})
    {
        this.httpClient.handleSomethingWentWrong = onFailure;
        this.httpClient.handleNotFound = onFailure;

        this.httpClient
            .post('Tag', [], tagCreation)
            .then((response : any) => 
            {
                if (response && response.data)
                {
                    onSuccess(response.data.data);
                }
            });
    }

    get(onSuccess : Function = () => {}, onFailure : Function = () => {})
    {
        this.httpClient.handleSomethingWentWrong = onFailure;
        this.httpClient.handleNotFound = onFailure;

        this.httpClient
            .get('Tag', [])
            .then((response : any) => 
            {
                if (response && response.data)
                {
                    onSuccess(response.data.data);
                }
            });
    }
}