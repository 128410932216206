 
import {ContentView} from '@levelapp/softfabric';
import MainInformationViewState from './MainInformationViewState';
import MainInformationViewProps from './MainInformationViewProps';
import MainInformationViewStyle from './MainInformationViewStyle';
import MainInformationViewModel from './MainInformationViewModel';
import { View } from 'react-native';
import ExtendedTextInputView from '../../../../../../components/extendedtextinput/ExtendedTextInputView';
import UserIdentity from '../../../../../../../../../Common/DTO/Identity/UserIdentity';

export default class MainInformationView extends ContentView<MainInformationViewProps, MainInformationViewState, MainInformationViewModel, MainInformationViewStyle>
{
  /* CONSTRUCTOR */
  constructor(props:MainInformationViewProps)
  {
    super(props);

    // Binding
    this.bind(new MainInformationViewModel(this), new MainInformationViewStyle(this));
  }

  /* RENDERING */
  render()
  {
    const { block } = this.style;

    return (
      <View style={{ display: "flex", flexDirection: "column", paddingHorizontal: 170, paddingVertical: 50 }}>  
  
            <View style={block}>
              <ExtendedTextInputView
                style={{width : "30%"}}
                showError={this.props.showError && this.props.user.email == "" }
                disable={this.props.user.id != undefined}
                title={"form.email"}
                value={this.props.user != undefined ? ((this.props.user as UserIdentity).email as string) : ''}
                onChangeText={(text : string) => this.props.handleChangeText('email',text , 0)}
              />
            </View>
            { 
              this.props.user.id == undefined && 
            <View style={block}>
              <ExtendedTextInputView
                style={{width : "30%"}}
                isPassword
                showError={this.props.showError && this.props.user.password == "" }
                title={"form.password"}
                value={this.props.user != undefined ? ((this.props.user as UserIdentity).password as string) : ''}
                onChangeText={(text : string) => this.props.handleChangeText('password',text , 0)}
              />
            </View>
            
            }
            { 
              this.props.user.id == undefined && 
            <View style={block}>
              <ExtendedTextInputView
                style={{width : "30%"}}
                isPassword
                showError={this.props.showError && this.props.user.confirmPassword == "" }
                title={"form.confirmpassword"}
                value={this.props.user != undefined ? ((this.props.user as UserIdentity).confirmPassword as string) : ''}
                onChangeText={(text : string) => this.props.handleChangeText('confirmpassword',text , 0)}
              />
            </View>
            
            }
      </View>
    );
  }
}