import {ContentViewModel,ContentView} from '@levelapp/softfabric';
import PermissionViewState from './PermissionViewState';
import PermissionViewProps from './PermissionViewProps';
import Permission from '../../../../../../../../../Common/DTO/Permission';
import RolePermission from '../../../../../../../../../Common/DTO/M&N/RolePermission';

export default class PermissionViewModel extends ContentViewModel<PermissionViewState, PermissionViewProps>
{
      /* CONSTRUCTOR */
    constructor(view: ContentView)
    {
        super(view);

        this.initialState({}); // Initialize your state here

        // Bindings

    }

    handleCheckboxPermissionChange(value : boolean , permission : any)
    {
        this.props().handlePermissions(value , permission);
    }

    isChecked(item : Permission)
    {
        if(this.props().permissions == null)
        {
            return false;
        }
        return this.props().permissions.find((rolePermission: RolePermission) => rolePermission.permissionId == item.id) == null ? false : true 
    }

    showGlobalPermissionsManagementPopup()
    {
        this.props().navigateOnThePopup();
    }
}