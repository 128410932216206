import * as React from 'react'; 
import {ContentView} from '@levelapp/softfabric';
import MenuItemViewState from './MenuItemViewState';
import MenuItemViewProps from './MenuItemViewProps';
import MenuItemViewStyle from './MenuItemViewStyle';
import MenuItemViewModel from './MenuItemViewModel';
import {TranslationView} from '@levelapp/softfabric-ui';
import { TouchableWithoutFeedback, View, Animated,  Image } from 'react-native';
import LinearGradient from 'react-native-web-linear-gradient';
import Constants from '../../../../../../../Common/Constants/Constants';


export default class MenuItemView extends ContentView<MenuItemViewProps, MenuItemViewState, MenuItemViewModel, MenuItemViewStyle>
{
  _children : number;
  /* CONSTRUCTOR */
  constructor(props:MenuItemViewProps)
  {
    super(props);

    // Binding
    this.bind(new MenuItemViewModel(this), new MenuItemViewStyle(this));
    this._children = 0;
    React.Children.forEach(this.props.children,() =>{
       this._children++;
    });
  }

  /* RENDERING */
  render()
  {
    const {  link } = this.style; 

    const opacity = this.binding._opacityAnimation.interpolate(
      {
        inputRange : [0,1],
        outputRange : [0,0.1],
      })

    const height = this.binding._heightAnimation.interpolate(
      {
        inputRange : [0,1],
        outputRange : [50,((this._children + 1 ) * 50 ) + 20  ],
      })  ;


    return (
      <View style={{paddingVertical : this.props.display ? 2 : 0, backgroundColor : 'white'}}>
      <Animated.View style={this.props.display ? {height : height}: {height : 0}}>
        {this.props.display ? 
        <Animated.View >
          <TouchableWithoutFeedback onPress={() => this.binding.handleOnPress()}>
          <View >
              <View style={[link , {position : 'absolute' , zIndex : 1}]}>
                <div style={{width : '100%' ,  height : '100%'}} onMouseEnter={() => this.binding.handleAnimation(1)} onMouseLeave={() => this.binding.handleAnimation(0)}>
                  <Animated.View style={[{width : '100%' , height : '100%' , backgroundColor : Constants.GradiantMenuStartColor , opacity : opacity}]}/>
                </div>
              </View>
              <LinearGradient start={{ x: 0, y: 0 }} end={{ x: 1, y: 0 }} colors={this.binding.getColor()} style={link}>
                <View style={{flexDirection : 'row' , flex : 1, justifyContent : 'space-between'}}>
                  <TranslationView style={this.binding.getTextStyle()}>
                    {this.props.title}
                  </TranslationView>
                  <Image resizeMode='contain' style={{width : 10 , height : 10, marginRight : 25 , alignSelf : 'center' }} source={this.binding.getImage()}/>
                </View>

              </LinearGradient>
              
            </View>
        
          </TouchableWithoutFeedback>
          <Animated.View style={{height:height }}>
            {this.props.children}
          </Animated.View>
        </Animated.View>
        
        :<View/>}
      </Animated.View>
      </View>
      
      
    );
  }
}