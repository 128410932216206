import { ContentViewModel, ContentView, Application, Managers, LanguageManager } from '@levelapp/softfabric';

import AddZoneViewState from './AddZoneViewState';
import AddZoneViewProps from './AddZoneViewProps';

import StoresOperations from 'src/Transfer/StoresOperations';
import TranslationValue from 'src/Common/DTO/TranslationValue';
import LightStore from 'src/Common/DTO/LightStore';
import StoreGroupOperations from 'src/Transfer/StoreGroupOperations';
import StoreGroup from 'src/Common/DTO/StoreGroup';
import ToastHelper from 'src/Common/Helpers/ToastHelper';

export default class AddZoneViewModel extends ContentViewModel<AddZoneViewState, AddZoneViewProps>
{
  storesOperation: StoresOperations;
  storeGroupOperation: StoreGroupOperations;

  /* CONSTRUCTOR */
  constructor(view: ContentView) {
    super(view);

    this.initialState({
      inputZone: this.props().storeGroup?.name || '',
      showError: false,
      storesAreLoading: false,
      stores: [],
      selectedStores: [],
      search: '',
      isSaving: false,
    });

    this.storesOperation = new StoresOperations();
    this.storeGroupOperation = new StoreGroupOperations();

    // Bindings
    this.onInputZoneChange = this.onInputZoneChange.bind(this);
    this.onPopupClose = this.onPopupClose.bind(this);
    this.onAddZone = this.onAddZone.bind(this);
    this.getStoreTranslation = this.getStoreTranslation.bind(this);
    this.updateStores = this.updateStores.bind(this);
    this.onSearchChange = this.onSearchChange.bind(this);
    this.clearSearch = this.clearSearch.bind(this);
  }

  onInputZoneChange(value: string) {
    this.setField({ inputZone: value })
  }

  onPopupClose() {
    this.closePopUp();
  }

  componentDidMount() {
    this.refresh();

    if (this.props().storeGroup) {
      this.setField({
        selectedStores: this.props().storeGroup?.childrenStoreGroups
          .filter((store) => store.isStore)
          .map((store) => ({
            id: store.id,
            name: store.name,
            itemGroupId: store.id,
          })),
      });
    }
  }

  refresh() {
    this.getData();
  }

  async getData() {
    this.setField({ storesAreLoading: true })

    try {
      const stores = await this.storesOperation.getLight();
      this.setField({ stores: stores });
      this.setField({ storesAreLoading: false });
    } catch {
      this.showToast(ToastHelper.getToast("tools.generic.get.error", "error"), () => { }, 2500);
    }
  }

  async onAddZone(value: string, storeGroup?: StoreGroup) {
    if (!value) {
      this.setField({ showError: true });
      return;
    }
    this.setField({ isSaving: true });
    
    await this.props().onAddZone({
      id: storeGroup?.id,
      name: value,
      stores: this.state().selectedStores,
      parentId: this.props().id || storeGroup?.parentId,
    });

    this.setField({ isSaving: false });
  }

  getStoreTranslation(storeGroup: LightStore) {
    return storeGroup.title.translationValues
      .find((translation: TranslationValue) =>
        translation.language.key == Application.current.resolve<LanguageManager>(
          Managers.LANGUAGE_MANAGER).language).value;
  }

  updateStores(selectedStores: any) {
    this.setField({ selectedStores });
  }

  onSearchChange(value: string) {
    this.setField({ search: value });
  }

  clearSearch() {
    this.setField({ search: '' });
  }
}