 

import { ContentViewModel, ContentView, Application, LanguageManager, Managers } from '@levelapp/softfabric';
import StoreGroupOperations from 'src/Transfer/StoreGroupOperations';
import StoreGroup from 'src/Common/DTO/StoreGroup';
import LightStore from 'src/Common/DTO/LightStore';

import ZonesViewState from './ZonesViewState';
import ZonesViewProps from './ZonesViewProps';
import AddZoneView from '../../addzone/AddZoneView';
import RemoveZoneView from '../../removezone/RemoveZoneView';
import TranslationValue from 'src/Common/DTO/TranslationValue';
import AddStoreGroup from 'src/Common/DTO/AddStoreGroup';
import ToastHelper from 'src/Common/Helpers/ToastHelper';

export default class ZonesViewModel extends ContentViewModel<ZonesViewState, ZonesViewProps>
{
  storeGroupOperations: StoreGroupOperations;

  /* CONSTRUCTOR */
  constructor(view: ContentView) {
    super(view);

    this.initialState({
      storeGroups: [],
      isLoading: false,
    });
    this.storeGroupOperations = new StoreGroupOperations();

    // Bindings
    this.getData = this.getData.bind(this);
    this.refresh = this.refresh.bind(this);
    this.mapper = this.mapper.bind(this);
    this.storeMapper = this.storeMapper.bind(this);
    this.onAddZoneClick = this.onAddZoneClick.bind(this);
    this.onAddZone = this.onAddZone.bind(this);
    this.onRemoveZoneClick = this.onRemoveZoneClick.bind(this);
    this.onRemoveZone = this.onRemoveZone.bind(this);
    this.getZoneTranslation = this.getZoneTranslation.bind(this);
    this.onPopUpClose = this.onPopUpClose.bind(this);
  }

  componentDidMount() {
    this.refresh();
  }

  async getData() {
    try {
      this.setField({ isLoading: true })
      const result = await this.storeGroupOperations.get();
      const mapped = result.map(this.mapper);
      this.setField({ storeGroups: mapped });
    } catch {
      this.showToast(ToastHelper.getToast("tools.generic.get.error", "error"), () => { }, 2500);
    } finally {
      this.setField({ isLoading: false })
    }
  }

  async createZone(storeGroup: AddStoreGroup) {
    try {
      if (storeGroup.parentId && storeGroup.parentId > 0) {
        await this.storeGroupOperations.post(storeGroup);
        this.refresh();
      } else {
        const result = await this.storeGroupOperations.post(storeGroup);
        const newStoreGroups = [...this.state().storeGroups, result];
        this.setField({ storeGroups: newStoreGroups });
      }

      this.onPopUpClose();
      this.showToast(ToastHelper.getToast("tools.zone.create.success", "success"), () => { }, 2500);
    } catch (error: any) {
      this.showToast(ToastHelper.getToast("tools.zone.create.error", "error"), () => { }, 2500);
    }
  }

  mapper(storeGroup: StoreGroup): any {
    return ({
      id: storeGroup.id,
      name: storeGroup.name,
      parentId: storeGroup.parentId,
      isStore: false,
      childCount: storeGroup.childrenStoreGroups?.length + storeGroup.stores?.length,
      totalStoreCount: storeGroup.totalStoreCount,
      fakeId: this.generateRandomString(),
      childrenStoreGroups: [...storeGroup.childrenStoreGroups?.length
        ? storeGroup.childrenStoreGroups.map(this.mapper)
        : [], ...storeGroup.stores.map(this.storeMapper)],
    });
  };

  async onAddZone(value: AddStoreGroup) {
    await this.createZone(value);
  }

  async onRemoveZone(id: number) {
    try {
      await this.storeGroupOperations.delete(id);
      this.closePopUp();
      this.refresh();
      this.showToast(ToastHelper.getToast("tools.zone.delete.success", "success"), () => { }, 2500);
    } catch {
      this.showToast(ToastHelper.getToast("tools.zone.delete.error", "error"), () => { }, 2500);
    }
  }

  onAddZoneClick(id?: number) {
    this.showPopUp(<AddZoneView onAddZone={this.onAddZone} id={id} />);
  }

  onRemoveZoneClick(id: number) {
    this.showPopUp(<RemoveZoneView onRemove={this.onRemoveZone} id={id} />);
  }

  storeMapper(store: LightStore): any {
    return ({
      id: store.id,
      name: this.getZoneTranslation(store),
      key: this.generateRandomString(),
      isStore: true,
      storeGroupId: store.storeGroupId,
    });
  };

  getZoneTranslation(storeGroup: LightStore) {
    return storeGroup.title.translationValues
      .find((translation: TranslationValue) =>
        translation.language.key == Application.current.resolve<LanguageManager>(
          Managers.LANGUAGE_MANAGER).language).value;
  }

  refresh() {
    this.getData();
  };

  generateRandomString() {
    return Math.random().toString(36).replace(/[^a-z]/g, '').substr(0, 10);
  }

  onPopUpClose() {
    this.closePopUp();
    this.refresh();
  }
}