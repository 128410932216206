import ProductOrderSourcesPricesService from "src/Proxy/ProductOrderSourcesPricesService";
import ProductOrderSourcePrice from "src/Common/DTO/ProductOrderSourcePrice";

import { Operation } from "@levelapp/softfabric";

export default class ProductOrderSourcePriceOperations extends Operation {
  service: ProductOrderSourcesPricesService;

  constructor() {
    super();
    this.service = new ProductOrderSourcesPricesService();
  }

  get(productId: number) {
    return this.service.get(productId);
  }

  post(product: ProductOrderSourcePrice[], categoryId?: number) {
    return this.service.post(product, categoryId);
  }

  put(product: ProductOrderSourcePrice[]) {
    return this.service.put(product);
  }

  delete(product: ProductOrderSourcePrice[]) {
    return this.service.delete(product);
  }

  deleteByProductGroupId(id: number) {
    return this.service.deleteByProductGroupId(id);
  }
}