import { HttpManager, Application, Service, Managers } from "@levelapp/softfabric";

export default class UserMetadataValuesService extends Service {
    httpClient: HttpManager;

    constructor() {
        super();
        this.httpClient = Application.current.resolve(Managers.HTTP_MANAGER);
        this.httpClient.handleSomethingWentWrong = (response: any) => {
            console.warn("Erreur : " + response);
        }
    }

    get(callBack: Function) {
        this.httpClient
            .get('UserMetadataValues')
            .then((response: any) => {
                if (response) {
                    if (response.data) {
                        callBack(response.data);
                    }
                }
            });
    }
}