 
import {ContentView} from '@levelapp/softfabric';
import CheckBoxHeaderSelectionViewState from './CheckBoxHeaderSelectionViewState';
import CheckBoxHeaderSelectionViewProps from './CheckBoxHeaderSelectionViewProps';
import CheckBoxHeaderSelectionViewStyle from './CheckBoxHeaderSelectionViewStyle';
import CheckBoxHeaderSelectionViewModel from './CheckBoxHeaderSelectionViewModel';
import {LottieView} from '@levelapp/softfabric-ui';
import { View, CheckBox, Text } from 'react-native';
import Constants from '../../../../../../../Common/Constants/Constants';

export default class CheckBoxHeaderSelectionView extends ContentView<CheckBoxHeaderSelectionViewProps, CheckBoxHeaderSelectionViewState, CheckBoxHeaderSelectionViewModel, CheckBoxHeaderSelectionViewStyle>
{
  /* CONSTRUCTOR */
  constructor(props:CheckBoxHeaderSelectionViewProps)
  {
    super(props);

    // Binding
    this.bind(new CheckBoxHeaderSelectionViewModel(this), new CheckBoxHeaderSelectionViewStyle(this));
  }

  /* RENDERING */
  render()
  {
    const { header , inputTitle} = this.style;

    return (
      <View>
        {this.props.isLoading ? (
            <LottieView height={150} width={150} source={require("../../../../../../../Common" + Constants.LottieAnimationPath)}/>
        ) : 
        (
        <View style={header}>
            { this.props.allItems.map((content: any) => {
                return (
                    <View style={{ flexDirection: "row" }}>
                        <CheckBox style={{ marginRight: 10 }} value={ this.binding.isChecked(content)} onValueChange={(value: boolean) => this.binding.handleCheckboxAllergenChange( value, content )}/>
                        <Text style={inputTitle}>
                            {this.binding.handleTranslations(content)}
                        </Text>
                    </View>
                );
            })}
        </View>
        )}
      </View>
      
    );
  }
}