import { ContentViewStyle } from '@levelapp/softfabric';
import { StyleProp, TextStyle, ViewStyle } from 'react-native';

export default class FiltersViewStyle extends ContentViewStyle {
  container: StyleProp<ViewStyle> = {
    display: 'flex',
    flexDirection: 'row',
  }

  filters: StyleProp<ViewStyle> = {
    display: 'flex',
    flexDirection: 'row',
    marginLeft: 25,
  }

  title: StyleProp<ViewStyle> = {
    display: 'flex',
    alignItems: 'center',
  }

  buttonLeft: StyleProp<ViewStyle> = {
    borderTopLeftRadius: 3,
    borderBottomLeftRadius: 3,
  }

  buttonRight: StyleProp<ViewStyle> = {
    borderTopRightRadius: 3,
    borderBottomRightRadius: 3,
  }

  button: StyleProp<ViewStyle> = {
    display: 'flex',
    justifyContent: 'center',
    paddingHorizontal: 15,
    paddingVertical: 4,
    borderWidth: 1,
    borderColor: '#E8E8E8',
    height: 35,
  }

  selected: StyleProp<ViewStyle> = {
    backgroundColor: 'rgba(216,63,40,0.12)',
  }

  text: StyleProp<TextStyle> = {
    color: '#D83F28',
  }
}