import Constants from "../Constants/Constants";
import Language from "../DTO/Language";
import { Application, PopUpManager, Managers } from "@levelapp/softfabric";
import TranslationValue from "../DTO/TranslationValue";
import ImageTranslation from "../DTO/ImageTranslation";

export default class Helpers {

    static randomIntFromInterval(min : number, max : number) {
        return Math.floor(Math.random() * (max - min + 1) + min);
      }

    static hasDuplicates(array: any) {
        if(array === null){
            return false;
        }
        var firstImage = array[0].url;
        for(var i=1; i<array.length; i++) {
            if(array[i].url != firstImage && array[i].url != Constants.UploadImageUrl)
            return false;
        }

        return true;
    }
    
    static clone(src: any) {
        var result =  JSON.parse(JSON.stringify(src));
        return result;
    }

    static generateUrlTable(languageKeys:Language[]) {
        let table: any[] = [];

        for (var i = 0; i < languageKeys.length; i++) {
            table.push( {
                url: Constants.UploadImageUrl,
                language : languageKeys[i]
             });
        }

        return table;
    }

    static generateUrlTableSize(size : number) {
        let table: any[] = [];

        for (var i = 0; i < size; i++) {
            table.push( {
                url: Constants.UploadImageUrl,
             });
        }

        return table;
    }
    /**
     * 
     * If the table is empty, fill it with empty string and languages.
     * 
     * @param table 
     * @param languages 
     */
     static generateTranslationTableEdit(table : any[], languages : Language[]) {       

        if(table != null)
        {
            if(table.length == 0)
            {
                table = this.generateTranslationTable(languages)
            }else
            {
                for (var i = table.length; i < languages.length; i++) {
                    table.push( {value: "" });
                }
            }
            
        }
        

        return table;
    }

    /**
     * 
     * If the table is empty, fill it with constantUrl and languages.
     * 
     * @param table 
     * @param languages 
     */
    static generateUrlTableEdit(table : any[], languages : Language[]) {       

        if(table != null)
        {
            if(table.length == 0)
            {
                table = this.generateUrlTable(languages)
            }else
            {
                table.sort((a : any , b : any)=> a.languageId - b.languageId);
                for (var i = table.length; i < languages.length; i++) {
                    table.push( {url: Constants.UploadImageUrl });
                }
            }
            
        }
        
        return table;
    }

    static generateTranslationTable(languageKeys:Language[]) {
        let table: any[] = [];

        for (var i = 0; i < languageKeys.length; i++) {
            const language:Language = {
                key : languageKeys[i].key,
                id : languageKeys[i].id,
            };
            table.push( {value: "", language: language });
        }
        return table;
    }


    static closePopup()
    {
       var _popupManager = Application.current.resolve<PopUpManager>(Managers.POPUP_MANAGER);
       _popupManager.closePopUp();
    }

    static getBase64(file: any) {
        return new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.readAsDataURL(file);
          reader.onload = () => resolve(reader.result);
          reader.onerror = error => reject(error);
        });
    }

    /**
     * 
     * Check if the list of images doesn't include a empty field.
     * 
     * FIXME: ChangeTranslationValue to ImageTranslation
     * TODO: Add LOG
     * Including in DTO objects
     * @param list 
     */
    static areImagesComplete(list : TranslationValue[] | ImageTranslation[])
    {
        let isCorrect = true;
        let index = 0;
        while (index < list.length && isCorrect)
        {
            isCorrect = (list[index].url !== Constants.UploadImageUrl);
            index++;
        }
        return isCorrect;
    }

    static fillAllImagesWithDefault(list : TranslationValue[] | ImageTranslation[] , languages : Language[]) : TranslationValue[] | ImageTranslation[]
    {
        let url = list[0].url;
        for (let i = 1; i < languages.length; i++)
            {
                list[i].url = url;
                list[i].languageId = languages[i].id;
                list[i].language = languages[i];
            }
        return list;
    }

    static getLanguageById(listLanguages : Language[] , idLanguage : number) : Language | undefined
    {

        return listLanguages.find(x => x.id ==  idLanguage);     
    }

    static getTranslationValueByLanguageKey(list : TranslationValue[], keyLanguage : string)
    {
        var response = list.find(x => x.language.key == keyLanguage);
        if(response == null)
        {
            return ""
        }else
        {
            return response.value
        }
         
    }
}