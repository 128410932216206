const PaginationConst = {
    PAGE : "page",
    SEARCH : "search",
    ACTION : "action",
    ITEM_PAGE : "itempage",
    SORT_ORDER : "sortOrder",
    COLUMN_SORT : "columnSort",
    SEARCH_COLUMN : "searchColumn",
    USER_LANGUAGE : "userLanguage"
}

export default PaginationConst;
