import { ContentViewStyle } from '@levelapp/softfabric';
import { StyleProp, ViewStyle, Dimensions, TextStyle, } from 'react-native';

export default class KitchenAppViewStyle extends ContentViewStyle
{
    container: StyleProp<ViewStyle> = {
       // display: 'flex',
        //height: Dimensions.get('window').height - 50, // 50 = the menu button
        height: '100%',
        overflow: 'hidden'
    };

    headerContainer: StyleProp<ViewStyle> = {
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        paddingTop: 15,
        paddingBottom: 30,
        zIndex: 1,
        marginRight: 40
    };

    statusesContainer: StyleProp<ViewStyle> = {
        flexDirection: 'row',
        justifyContent: 'space-between',
        flexWrap: 'wrap',
        marginRight: 40
    };

    kitchenModusBtn: StyleProp<ViewStyle> = {
        paddingVertical: 10,
        paddingHorizontal: 25,
        borderWidth: 1,
        borderColor: '#358DFF',
        backgroundColor: 'white'
    };

    kitchenModusTxt: StyleProp<TextStyle> = {
        fontSize: 14,
        color: '#358DFF'
    };

    kitchenModusInfoTxt: StyleProp<TextStyle> = {
        fontSize: 12,
        color: '#B0B0B0'
    };

    ordersContainer: StyleProp<ViewStyle> = {
        display: 'flex',
        flex: 1,
        marginTop: 20,
        flexWrap: 'wrap',
        marginRight: 40,
        
    };

    dayButtonsContainer: StyleProp<ViewStyle> = {
        paddingLeft: 18,
        flexDirection: 'row'
    };

    dayButton: StyleProp<any> = {
        marginHorizontal: 4,
        height: 45,
        width: 45,
        backgroundColor: 'white',
        borderRadius: 5,
        justifyContent: 'center',
        alignItems: 'center',
        boxShadow: '0 2px 4px 2px rgba(164,164,164,0.5)'
    };

    todayButton: StyleProp<any> = {
        height: 45,
        backgroundColor: 'white',
        borderRadius: 5,
        justifyContent: 'center',
        alignItems: 'center',
        boxShadow: '0 2px 4px 2px rgba(164,164,164,0.5)',
        paddingHorizontal: 30
    };

    directionButtonsContainer: StyleProp<any> = {
        flexDirection: 'row',
        boxShadow: '0 2px 4px 2px rgba(164,164,164,0.5)',
        borderRadius: 5
    };

    directionButton: StyleProp<ViewStyle> = {
        width: 75,
        height: 45,
        backgroundColor: 'white',
        justifyContent: 'space-between',
        alignItems: 'center',
        borderWidth: 1,
        paddingVertical: 4
    };

    directionButtonTxt: StyleProp<TextStyle> = {
        fontSize: 10
    };

    multiSelectFooter: StyleProp<any> = {
        position: 'absolute',
        bottom: 0,
        height: 75,
        width: '100%',
        backgroundColor: 'white',
        paddingHorizontal: 30,
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        boxShadow: '0 -2px 20px -6px rgba(163,163,163,0.5)'
    };

    ordersSelectedBubble: StyleProp<ViewStyle> = {
        backgroundColor: '#41B300',
        borderRadius: 17,
        paddingVertical: 5,
        paddingHorizontal: 20,
        marginRight: 15
    };

    ordersSelectedContainer: StyleProp<ViewStyle> = {
        position: 'absolute',
        flexDirection: 'row',
        left: 30,
        alignItems: 'center'
    };

    ordersSelectedCountTxt: StyleProp<TextStyle> = {
        color: 'white',
        fontWeight: 'bold'
    };

    footerButtonsContainer: StyleProp<ViewStyle> = {
        width: 415,
        height: 53,
        flexDirection: 'row',
        justifyContent: 'space-between'
    };

    footerButton: StyleProp<ViewStyle> = {
        alignItems: 'center',
        height: '100%',
        justifyContent: 'space-between'
    };

    overlay: StyleProp<any> = {
        position: 'fixed',
        height: Dimensions.get('window').height + 50,
        width: '100%',
        zIndex: 50,
        top: -50
    };

    kitchenModus: StyleProp<any> = {
        height: 95,
        width: 400,
        backgroundColor: 'white',
        borderBottomLeftRadius: 3,
        borderBottomRightRadius: 3,
        paddingVertical: 10,
        paddingHorizontal: 50,
        boxShadow: '0 2px 4px 0 rgba(0,0,0,0.5)',
        justifyContent: 'space-between'
    };

    filtersContainer: StyleProp<ViewStyle> = {
        paddingVertical: 25,
        flexDirection: 'row',
        paddingLeft: 33
    };

    temporaryText : StyleProp<TextStyle> =
    {
        color : '#C81616',
        paddingVertical : 2,
        fontFamily : 'Montserrat Regular',
        fontSize : 25
    }
}