import {ContentShellViewModel, ContentShellView} from '@levelapp/softfabric';
import ShellOutofstockViewState from './ShellOutofstockViewState';
import ShellOutofstockViewProps from './ShellOutofstockViewProps';
 
import OutofstockView from './pages/outofstock/OutofstockView';
import EnumModules from '../../../../../Common/Constants/EnumModules';

export default class ShellOutofstockViewModel extends ContentShellViewModel<ShellOutofstockViewState, ShellOutofstockViewProps>
{

    constructor(view : ContentShellView)
    {
        super(view);

        this.registerEntryPoint('main'); 
        this.register(<OutofstockView/> ,'main'); 

        this.initialisation();
    }

    getModuleId()
    {
        return EnumModules.OUT_OF_STOCK;
    }

}