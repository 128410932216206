import {ContentViewStyle} from '@levelapp/softfabric';
import {StyleProp,ViewStyle, TextStyle} from 'react-native';

export default class PermissionViewStyle extends ContentViewStyle
{
    page: StyleProp<ViewStyle> = {
        flex: 1
    }

    header : StyleProp<ViewStyle> = { 
        flex: 1, 
        maxHeight: 225, 
        flexWrap: "wrap" 
    }

    inputTitle: StyleProp<TextStyle> = {
        fontSize: 14,
        fontWeight: '600',
        color: '#3F3F3F',
        fontFamily: 'Montserrat SemiBold',
        marginBottom: 13,
    };

    bodyTitle : StyleProp<TextStyle> =
    {
        fontSize: 25,
        fontWeight: "600",
        color: "#3F3F3F",
        fontFamily: "Montserrat SemiBold",
        marginBottom: 13
    }


}