import {ContentViewModel,ContentView} from '@levelapp/softfabric';
import SectionTitleViewState from './SectionTitleViewState';
import SectionTitleViewProps from './SectionTitleViewProps';

export default class SectionTitleViewModel extends ContentViewModel<SectionTitleViewState, SectionTitleViewProps>
{
      /* CONSTRUCTOR */
    constructor(view: ContentView)
    {
        super(view);

        this.initialState({}); // Initialize your state here

        // Bindings

    }
}