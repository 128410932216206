import { Service, Application, HttpManager, Managers } from '@levelapp/softfabric';


export default class PaymentSourceService extends Service
{
    httpClient = Application.current.resolve<HttpManager>(Managers.HTTP_MANAGER);

    async get(onSuccess: Function)
    {
        this.httpClient
            .get(`PaymentSource`, [])
            .then((response: any) =>
            {
                if (response) {
                    if (response.data) {
                        onSuccess(response.data.data);
                    }
                }
            });
    }
}