export default class DashboardHelper
{
    private static settings : Function;
    private static selectedStoreId: number;

    static clickSettings()
    {
        this.settings();
    }

    static setClickSettings(settings : Function)
    {
        this.settings = settings;
    }

    static setStoreId(id: number) {
      this.selectedStoreId = id;
    }

    static getSelectedStoreId() {
      return this.selectedStoreId;
    }
}