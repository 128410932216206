 
import {ContentView} from '@levelapp/softfabric';
import TestPopupViewState from './TestPopupViewState';
import TestPopupViewProps from './TestPopupViewProps';
import TestPopupViewStyle from './TestPopupViewStyle';
import TestPopupViewModel from './TestPopupViewModel';
import { View, Text } from 'react-native';
import ExtendedTextInputView from '../../../../components/extendedtextinput/ExtendedTextInputView';
import ButtonView from '../../../../../../../Common/Components/Button/ButtonView';
import { TranslationView } from '@levelapp/softfabric-ui';
import Constants from '../../../../../../../Common/Constants/Constants';

export default class TestPopupView extends ContentView<TestPopupViewProps, TestPopupViewState, TestPopupViewModel, TestPopupViewStyle>
{
  /* CONSTRUCTOR */
  constructor(props:TestPopupViewProps)
  {
    super(props);

    // Binding
    this.bind(new TestPopupViewModel(this), new TestPopupViewStyle(this));
  }

  /* RENDERING */
  render()
  {
    const { container, addSection } = this.style;

    /*
                <ButtonView isLoading={false} handleClick={() => this.binding.test()} backgroundColor={Constants.MainColor} textColor='black'>
              <TranslationView upperCase={true}>
                {"messageCampaign.popup.test.button"}
              </TranslationView>
            </ButtonView>
    */

    return (

          <View style={container}>
            <View style={addSection}>
              <ExtendedTextInputView
                title={"messageCampaign.popup.loyaltyCard.title"}
                infoText={this.binding.translate('messageCampaign.popup.loyaltyCard.info')}
                showError={false}
                style={{zIndex : 1}}
                value={`${this.state.loyaltycardCode}`}
                onChangeText={(loyaltycardCode: string) => this.binding._dispatcherHelper.handleValueChange('loyaltycardCode', loyaltycardCode)}
              />
              <ButtonView style={{marginTop : 18}} isLoading={false} handleClick={() => this.binding.addId()} backgroundColor={Constants.MainColor} textColor='black'>
                <TranslationView upperCase={true}>
                  {"messageCampaignForm.add"}
                </TranslationView>
              </ButtonView>
            </View>

            <View style={{margin : 5}}>
                <TranslationView style={{fontSize : 14}}>{"messageCampaign.popup.test.loyaltyCardList"}</TranslationView>
                {this.state.idToSend.length > 0 ?  <Text>{this.state.idToSend.map(el => el+",")}</Text>
                    : <TranslationView style={{fontSize : 12}} >{"messageCampaign.popup.test.emptyLoyaltyCardList"}</TranslationView>}
            </View>

            <ButtonView style={{marginTop : 15}} isLoading={false} handleClick={() => this.binding.test()} backgroundColor={Constants.MainColor} textColor='black'>
              <TranslationView upperCase={true}>
                {"messageCampaign.popup.test.button"}
              </TranslationView>
            </ButtonView>



          </View>
    );
  }
}