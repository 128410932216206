 
import {ContentView} from '@levelapp/softfabric';
import ItemLanguageViewState from './ItemLanguageViewState';
import ItemLanguageViewProps from './ItemLanguageViewProps';
import ItemLanguageViewStyle from './ItemLanguageViewStyle';
import ItemLanguageViewModel from './ItemLanguageViewModel';
import { View, Text, TextInput } from 'react-native';

export default class ItemLanguageView extends ContentView<ItemLanguageViewProps, ItemLanguageViewState, ItemLanguageViewModel, ItemLanguageViewStyle>
{
  /* CONSTRUCTOR */
  constructor(props:ItemLanguageViewProps)
  {
    super(props);

    // Binding
    this.bind(new ItemLanguageViewModel(this), new ItemLanguageViewStyle(this));
  }

  /* RENDERING */
  render()
  {
    const { section , inputTitle , errorInput , input } = this.style;

    return (
      <View style={section}>
        <Text style={[inputTitle]}>({this.props.language.toUpperCase()})</Text>
        <TextInput
          placeholder={this.props.placeholder}
          style={this.props.isError && (this.props.value == "") ? errorInput : input}
          onChangeText={(text: string) => { this.props.handleOnChangeText(text , this.props.language) }}
          value={this.props.value}/>
      </View>
    )
  }
}