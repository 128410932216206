import { Operation } from "@levelapp/softfabric";
import OrderSourceService from "../Proxy/OrderSourcesService";

export class OrderSourceOperations extends Operation {

  service: OrderSourceService;

  constructor() {
    super();
    this.service = new OrderSourceService();
  }
  get(callback: Function, query?: string) {
    this.service.get(callback, query);
  }
}