import {ContentViewStyle} from '@levelapp/softfabric';
import {StyleProp,ViewStyle} from 'react-native';

export default class SingleOrGroupViewStyle extends ContentViewStyle
{
    page: StyleProp<ViewStyle> = {
        flex: 1
    }

    boxContainer: StyleProp<ViewStyle> = {
        display: 'flex',
        alignSelf: 'center',
        minHeight: 250,
        width: '100%',
        paddingTop: 30,
        paddingBottom: 30,
        paddingLeft: 50,
        paddingRight: 50,
    }
    boxContainerButton: StyleProp<ViewStyle> = {
        flexDirection: 'row',
        justifyContent:'center',
        width: '100%',
        
    }
    boxContainerText: StyleProp<ViewStyle> = {
        flexDirection: 'row',
        justifyContent:'center',
        width: '100%',
        paddingBottom: 20,

    }

    styleButton: StyleProp<ViewStyle> =
    {       
        height: 42,
        width: 119,
        marginLeft : '10px'
    } 
}