 
import {ContentView} from '@levelapp/softfabric';
import AdminUsersViewState from './AdminUsersViewState';
import AdminUsersViewProps from './AdminUsersViewProps';
import AdminUsersViewStyle from './AdminUsersViewStyle';
import AdminUsersViewModel from './AdminUsersViewModel';
import HeaderView from '../../../../components/header/HeaderView';
import { View } from 'react-native';
import TableView from '../../../../../../../Common/Components/TabelView/TableView';

export default class AdminUsersView extends ContentView<AdminUsersViewProps, AdminUsersViewState, AdminUsersViewModel, AdminUsersViewStyle>
{
  /* CONSTRUCTOR */
  constructor(props:AdminUsersViewProps)
  {
    super(props);

    // Binding
    this.bind(new AdminUsersViewModel(this), new AdminUsersViewStyle(this));
  }

  /* RENDERING */
  render()
  {
    return (
      <View style={this.style.container}>
        <View style={this.style.box}>

          <HeaderView
            title={"adminusers.title"}
            buttonText={"adminusers.button.add"}
            description={"adminusers.description"}
            shouldShowButton
            onButtonPress={this.binding.addField}
          />
          

          <View style={this.style.boxContainer}>
          {/* <ResearchInputView
              placeholder={this.binding.translate("generic.search")}
              handlerResearching={() => {  }}
              handlingClose={() => { }} /> */}
            <TableView
              headerTextStyle={this.style.tableHeader}
              backgroundStepColor={this.style.stepColor}
              rowStyle={this.style.rowStyle}
              nothingToDisplayText={this.binding.translate("component.table.noresults")}
              isLastColumnActions={true}
              data={this.state.tableData}
              isLoading={this.state.isLoading}
              headers={this.state.tableHeaders}
              onPageChange={() => {}}
              handleSortChange={() => {}}
              nbPage={this.state.nbPage}
              currentPage={this.state.currentPage - 1}
              isSortable={false}/>
          </View>
        </View>
      </View>
    );
  }
}