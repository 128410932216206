import {ContentShellViewModel, ContentShellView} from '@levelapp/softfabric';
import ShellHealthStatusViewState from './ShellHealthStatusViewState';
import ShellHealthStatusViewProps from './ShellHealthStatusViewProps';
 
import HealthStatusView from './pages/healthstatus/HealthStatusView';
import EnumModules from '../../../../../Common/Constants/EnumModules';
import HealthStatusV2 from './pages/healthstatus-v2/HealthStatusV2';

export default class ShellHealthStatusViewModel extends ContentShellViewModel<ShellHealthStatusViewState, ShellHealthStatusViewProps>
{

    constructor(view : ContentShellView)
    {
        super(view);

        this.registerEntryPoint('main'); 
        this.register(<HealthStatusView/> ,'main'); 
        this.register(process.env.REACT_APP_ISHEALTHSTATUS_V2 === 'true' ? HealthStatusV2 : <HealthStatusView/>, 'main');


        this.initialisation();
    }

    getModuleId()
    {
        return EnumModules.HEALTHSTATUS;
    }

}