 
import { TouchableWithoutFeedback, View } from 'react-native';
import { ContentView } from '@levelapp/softfabric';
import StoresViewState from './StoresViewState';
import StoresViewProps from './StoresViewProps';
import StoresViewStyle from './StoresViewStyle';
import StoresViewModel from './StoresViewModel';
import SectionTitleView from '../../../../../../components/sectiontitle/SectionTitleView';
import { LottieView, TranslationView } from '@levelapp/softfabric-ui';
import Constants from '../../../../../../../../../Common/Constants/Constants';
import Select from 'react-dropdown-select';
import Store from '../../../../../../../../../Common/DTO/Store';

export default class StoresView extends ContentView<StoresViewProps, StoresViewState, StoresViewModel, StoresViewStyle>
{
  /* CONSTRUCTOR */
  constructor(props: StoresViewProps)
  {
    super(props);

    // Binding
    this.bind(new StoresViewModel(this), new StoresViewStyle(this));
  }

  /* RENDERING */
  render()
  {
    const selectedStores: Store[] = [];
    this.props.selectedIds.forEach((id) =>
    {
      const store = this.props.stores.find(x => x.id == id);
      if (store)
      {
        selectedStores.push(store);
      }
    })

    return (
      <View style={this.style.container}>
        <SectionTitleView title={'form.stores'} />
        {this.props.isLoading ? (
          <LottieView height={150} width={150} source={require("../../../../../../../../../Common" + Constants.LottieAnimationPath)} />
        ) :
          <View>
            <Select
              placeholder="Select stores"
              color={Constants.MainColor}
              values={selectedStores.sort((x1, x2) => x1.slug.localeCompare(x2.slug))}
              options={this.props.stores.sort((x1, x2) => x1.slug.localeCompare(x2.slug))}
              onChange={(value: any) => this.props.handleValueChange('store', value)}
              multi={true}
              create={false}
              keepSelectedInList={false}
              clearable={true}
              closeOnSelect={true}
              labelField={"slug"}
              valueField={"id"}
              searchBy={"slug"}
            />
            <TouchableWithoutFeedback onPress={this.binding.addAllStores}>
              <View>
                <TranslationView style={this.style.addAllStoresLabel}>coupons.stores.addall</TranslationView>
              </View>
            </TouchableWithoutFeedback>

            {this.props.selectedIds.length == 0 && <TranslationView>coupons.stores.warningnothingenabled</TranslationView>}
          </View>
        }
      </View>
    );
  }
}