import { Service, Application, HttpManager, Managers } from '@levelapp/softfabric';
import AddSsoKey from 'src/Common/DTO/AddSsoKey';

export default class SsoService extends Service {
  httpClient = Application.current.resolve<HttpManager>(Managers.HTTP_MANAGER);

  async post(ssoKey: AddSsoKey): Promise<string> {
    const response = await this.httpClient.post('sso', [], { ...ssoKey });

    if (response && response.data) {
      return response.data.data;
    } 

    return Promise.reject();
  }
}