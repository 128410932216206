 
import { ContentView } from '@levelapp/softfabric';
import PromoCodesViewState from './PromoCodesViewState';
import PromoCodesViewProps from './PromoCodesViewProps';
import PromoCodesViewStyle from './PromoCodesViewStyle';
import PromoCodesViewModel from './PromoCodesViewModel';
import { View } from 'react-native';
import HeaderView from '../../../../components/header/HeaderView';
import TableView from '../../../../../../../Common/Components/TabelView/TableView';
import ResearchInputView from '../../../../../../../Common/Components/researchinput/ResearchInputView';

export default class PromoCodesView extends ContentView<PromoCodesViewProps, PromoCodesViewState, PromoCodesViewModel, PromoCodesViewStyle>
{
    /* CONSTRUCTOR */
    constructor(props: PromoCodesViewProps) 
    {
        super(props);

        // Binding
        this.bind(new PromoCodesViewModel(this), new PromoCodesViewStyle(this));
    }

    /* RENDERING */
    render() 
    {
        return (
        <View style={this.style.container}>
            <View style={this.style.box}>
            
                <HeaderView
                    title={"promocodes.title"}
                    description={"promocodes.description"}
                    shouldShowButton={true}
                    onButtonPress={this.binding.addPCB}
                    buttonText={"promocodes.button.add"}
                />
                
                <View style={this.style.boxContainer}>
                    <ResearchInputView
                        placeholder={this.binding.translate("generic.search")}
                        handlerResearching={(searchText: string) => { this.binding.handleResearching(searchText) }}
                        handlingClose={() => { this.binding.handleCloseResearching() }} />
                    <TableView
                        headerTextStyle={this.style.tableHeader}
                        backgroundStepColor={this.style.stepColor}
                        rowStyle={this.style.rowStyle}
                        nothingToDisplayText={this.binding.translate("component.table.noresults")}
                        isLastColumnActions={true}
                        data={this.state.tableData}
                        isLoading={this.state.isLoading}
                        headers={this.state.tableHeaders}
                        onPageChange={(page: number) => this.binding.onPageChange(page)}
                        handleSortChange={(indexHeaderSort : number, sortOrder : string) => {this.binding.onSortChange(indexHeaderSort, sortOrder)}}
                        nbPage={this.state.nbPage}
                        currentPage={this.state.currentPage - 1}
                        isSortable={true}/>
                </View>
            </View>
        </View>
        );
    }
}