import {ContentViewStyle} from '@levelapp/softfabric';
import {StyleProp,ViewStyle, TextStyle} from 'react-native';

export default class ItemLanguageViewStyle extends ContentViewStyle
{
    page: StyleProp<ViewStyle> = {
        flex: 1
    }

    section: StyleProp<ViewStyle> = {
        alignSelf: 'flex-end',
        marginVertical: 15,
    };

    inputTitle: StyleProp<TextStyle> = {
        fontSize: 14,
        fontWeight: '600',
        color: '#3F3F3F',
        marginBottom : 10,
        fontFamily: 'Montserrat SemiBold'
    };

    input: StyleProp<any> = {
        width: 140,
        paddingHorizontal: 15,
        borderRadius: 8,
        marginRight : 10,
        boxShadow: '0 5px 14px 0 rgba(0,0,0,0.1)',
    };

    errorInput: StyleProp<any> = {
        width: 140,
        paddingHorizontal: 15,
        marginRight : 10,
        borderRadius: 8,
        borderColor: 'red',
        borderWidth: 1,
    };
}