 
import {ContentShellView} from '@levelapp/softfabric';
import ShellSavingCardsViewState from './ShellSavingCardsViewState';
import ShellSavingCardsViewProps from './ShellSavingCardsViewProps';
import ShellSavingCardsViewStyle from './ShellSavingCardsViewStyle';
import ShellSavingCardsViewModel from './ShellSavingCardsViewModel';
import {View} from 'react-native';

export default class ShellSavingCardsView extends ContentShellView<ShellSavingCardsViewProps, ShellSavingCardsViewState, ShellSavingCardsViewModel, ShellSavingCardsViewStyle>
{
  /* CONSTRUCTOR */
  constructor(props:ShellSavingCardsViewProps)
  {
    super(props);

    // Binding
    this.bind(new ShellSavingCardsViewModel(this), new ShellSavingCardsViewStyle(this));
  }

  /* RENDERING */
  render()
  {
    return (
      <View style={{flex : 1}}>
        {this.getSwitch()}
      </View>
    );
  }
}