import { StyleProp, TextStyle, ViewStyle } from 'react-native';

import { ContentViewStyle } from '@levelapp/softfabric';

export default class UpsertProductGroupViewStyle extends ContentViewStyle {
  container: StyleProp<ViewStyle> = {
    display: 'flex',
    justifyContent: 'space-between',
    
    marginHorizontal: 50, 
    width: 1000, 
    minHeight: 700,
  }

  content: StyleProp<ViewStyle> = {
    zIndex: 10,
  }

  underTitle: StyleProp<ViewStyle> = {
    marginTop: 24,
  }

  title: StyleProp<TextStyle> = {
    fontSize: 26,
    marginTop: 35,
  }

  input: StyleProp<ViewStyle> = {
    borderRadius: 3,
    height: 44,
    borderColor: '#DCCDCD',
    borderWidth: 1,
    width: '100%',
  }

  inputLabel: StyleProp<TextStyle> = {
    marginBottom: 8,
  }

  buttons: StyleProp<ViewStyle> = {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-end',
    marginTop: 15,
    marginVertical: 35,
  }
  
  addAnother: StyleProp<ViewStyle> = {
    display: 'flex',
    flexDirection: 'row',
  }

  addButton: StyleProp<ViewStyle> = {
    width: 160,
    height: 44,
  }

  cancelButton: StyleProp<ViewStyle> = {
    width: 160,
    height: 44,
    marginLeft: 15,
  }
}