 
import { CheckBox, View } from 'react-native';

import { ContentView } from '@levelapp/softfabric';
import { LottieView } from '@levelapp/softfabric-ui';

import CouponOrderSourceOrderTypeViewState from './CouponOrderSourceOrderTypeViewState';
import CouponOrderSourceOrderTypeViewProps from './CouponOrderSourceOrderTypeViewProps';
import CouponOrderSourceOrderTypeViewStyle from './CouponOrderSourceOrderTypeViewStyle';
import CouponOrderSourceOrderTypeViewModel from './CouponOrderSourceOrderTypeViewModel';

import Constants from 'src/Common/Constants/Constants';

export default class CouponOrderSourceOrderTypeView extends ContentView<CouponOrderSourceOrderTypeViewProps, CouponOrderSourceOrderTypeViewState, CouponOrderSourceOrderTypeViewModel, CouponOrderSourceOrderTypeViewStyle>
{
  /* CONSTRUCTOR */
  constructor(props: CouponOrderSourceOrderTypeViewProps) {
    super(props);

    // Binding
    this.bind(new CouponOrderSourceOrderTypeViewModel(this), new CouponOrderSourceOrderTypeViewStyle(this));
  }

  /* RENDERING */
  render() {
    return (
      <View style={this.style.container}>
        {this.props.isLoading ?
          (
            <LottieView height={150} width={150} source={require('src/Common' + Constants.LottieAnimationPath)} />
          ) : (
            <View>
              <table>
                <tr>
                  <th />
                  {this.props.basketOrderType.map((ps, i) =>
                    <th key={i} style={this.style.th}>{this.binding.getTranslation(ps)}</th>
                  )}
                </tr>

                {this.props.orderSources.map((os, i) =>
                  <tr>
                    <th key={i} style={this.style.th}>{os.label}</th>
                    {this.props.basketOrderType.map(({ id }) =>
                      <td key={id} style={this.style.td} align='center'>
                        <CheckBox
                          style={this.style.checkbox}
                          value={this.binding.isSelected(os.id, id)}
                          onValueChange={(value) => this.props.handleValueChanged(value, os.id, id)}
                        />
                      </td>
                    )}
                  </tr>
                )}
              </table>
            </View>
          )
        }
      </View>
    );
  }
}