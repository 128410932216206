import {ContentViewModel,ContentView} from '@levelapp/softfabric';
import RoleViewState from './RoleViewState';
import RoleViewProps from './RoleViewProps';
import Role from '../../../../../../../../../Common/DTO/Role';
import PermissionUser from '../../../../../../../../../Common/DTO/M&N/PermissionUser';

export default class RoleViewModel extends ContentViewModel<RoleViewState, RoleViewProps>
{
      /* CONSTRUCTOR */
    constructor(view: ContentView)
    {
        super(view);

        this.initialState({}); // Initialize your state here

        // Bindings
        this.handleChangeRole = this.handleChangeRole.bind(this);
    }


    handleChangeRole(role : Role)
    {
        this.props().handleChangeRole('role' , role , 0);
    }

    find(permissionId : number)
    {
        var pu = this.props().user.permissionsUsers as PermissionUser[];
        if(pu != null && pu.length > 0 )
        {
            var founded = pu.find(x => x.permissionId == permissionId);
            if(founded == null)
            {
                return null;
            }
            
            return founded.extraData;
        }

        return null;
    }
    
}