 
import {ContentView} from '@levelapp/softfabric';
import ExtendedBlockTextInputViewState from './ExtendedBlockTextInputViewState';
import ExtendedBlockTextInputViewProps from './ExtendedBlockTextInputViewProps';
import ExtendedBlockTextInputViewStyle from './ExtendedBlockTextInputViewStyle';
import ExtendedBlockTextInputViewModel from './ExtendedBlockTextInputViewModel';
import { View, Text, TextInput } from 'react-native';

export default class ExtendedBlockTextInputView extends ContentView<ExtendedBlockTextInputViewProps, ExtendedBlockTextInputViewState, ExtendedBlockTextInputViewModel, ExtendedBlockTextInputViewStyle>
{
  /* CONSTRUCTOR */
  constructor(props:ExtendedBlockTextInputViewProps)
  {
    super(props);

    // Binding
    this.bind(new ExtendedBlockTextInputViewModel(this), new ExtendedBlockTextInputViewStyle(this));
  }

  /* RENDERING */
  render()
  {
    const { section , inputTitle , errorTextArea , textArea,smallTextArea }= this.style; 

    return (
      <View style={section}>
        <Text style={inputTitle}>
          {this.props.beforeTitle != undefined && this.binding.translate(this.props.beforeTitle,true)}({this.props.title.toUpperCase()})
        </Text>
        <TextInput
          style={this.props.showError && (this.props.value == null || this.props.value == "") ? errorTextArea : this.props.type === 'small'? smallTextArea : textArea}
          value={this.props.value}
          onChangeText={(text: string) => this.props.onChangeText(text)}
          multiline={true} />
      </View>
    );
  }
}