import { StyleProp, ViewStyle } from 'react-native';

import { ContentViewStyle } from '@levelapp/softfabric';

export default class LabelViewViewStyle extends ContentViewStyle {

  storeGroup: StyleProp<ViewStyle> = {
    marginVertical: 8,
    paddingHorizontal: 8,
    paddingVertical: 8,
    borderRadius: 4,
    width: '100%'
  }
}