 
import {ContentView} from '@levelapp/softfabric';
import StoreSummaryPopUpViewState from './StoreSummaryPopUpViewState';
import StoreSummaryPopUpViewProps from './StoreSummaryPopUpViewProps';
import StoreSummaryPopUpViewStyle from './StoreSummaryPopUpViewStyle';
import StoreSummaryPopUpViewModel from './StoreSummaryPopUpViewModel';
import {  Dimensions, View } from 'react-native';
import Helpers from '../../../../../../../Common/Helpers/Helpers';
import PopupHeaderView from '../../../../components/popupheader/PopupHeaderView';

import PopupMenuItemView from '../../../../components/popupmenuitem/PopupMenuItemView';
import OrderSourcesHelper from '../../../../../../../Common/Helpers/OrderSourcesHelper';
import LottieView from '@levelapp/softfabric-ui/components/Both/Lottie/LottieView';
import Constants from '../../../../../../../Common/Constants/Constants';
import GenericLogsView from '../../../../components/genericlogs/GenericLogsView';

export default class StoreSummaryPopUpView extends ContentView<StoreSummaryPopUpViewProps, StoreSummaryPopUpViewState, StoreSummaryPopUpViewModel, StoreSummaryPopUpViewStyle>
{
  /* CONSTRUCTOR */
  constructor(props:StoreSummaryPopUpViewProps)
  {
    super(props);

    // Binding
    this.bind(new StoreSummaryPopUpViewModel(this), new StoreSummaryPopUpViewStyle(this));
  }

  /* RENDERING */
  render()
  {
    return (
      <View style={this.style.popup}>
        <PopupHeaderView
          handleBackPressed={Helpers.closePopup}
          title={`storessummary.popup.title`}
          shouldShowButton ={false}
        />   

        <View style={{ display: "flex", flexDirection: "row", flexWrap: "wrap" ,overflow : 'hidden'}}>
          {this.props.store.orderSources.map((orderSource : any)=>{return(
            <PopupMenuItemView activeItem={`${this.state.storeOrderSourceTab}`} title={OrderSourcesHelper.changeEnumToText(orderSource.orderSourceId)} item={`${orderSource.orderSourceId}`} handleChangeTab={() => this.binding.changeTab(`${orderSource.orderSourceId}`)}/>
          )})}
        </View> 
      
      {this.state.isLoading ? 
            <LottieView height={150} width={150} source={require('../../../../../../../Common' + Constants.LottieAnimationPath)}/> 
           
            :
            <View>
              {this.props.store.orderSources.map((orderSource : any)=>{return(
                <View>
                  { this.state.storeOrderSourceTab == `${orderSource.orderSourceId}` && <GenericLogsView 
                height ={ ((Dimensions.get('window').height /100) * 80) - 120}
                timeLineLogs={this.state.storeLogs.filter(x=>x.currentValue.OrderSourceId == parseInt(orderSource.orderSourceId))} 
                />}
                  </View>
              )})}
            </View>}
    </View>
    );
  }
}