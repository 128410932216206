import { ContentViewStyle } from '@levelapp/softfabric';
import { StyleProp, ViewStyle, TextStyle } from 'react-native';

export default class ExtendedTranslationViewStyle extends ContentViewStyle
{
    page: StyleProp<ViewStyle> = {
        flex: 1
    }

    inputTitle: StyleProp<TextStyle> = {
        fontSize: 14,
        fontWeight: '600',
        color: '#3F3F3F',
        fontFamily: 'Montserrat SemiBold',
        marginBottom: 13,
    };

    popup: StyleProp<any> =
        {
            position: 'absolute',
            top: -10,
            width: 300,
            borderRadius : 8,
            backgroundColor: 'white',
            boxShadow: '0px 5px 20px 0px rgba(0, 0, 0, 0.07)',
            padding: 10
        };
}