import { Operation } from "@levelapp/softfabric";
import LoyaltyRulesUnitsService from "../Proxy/LoyaltyRulesUnitsService";

export default class LoyaltyRulesUnitsOperations extends Operation
{
    service: LoyaltyRulesUnitsService;

    constructor()
    {
        super();
        this.service = new LoyaltyRulesUnitsService();
    }

    get(callback: Function)
    {
        this.service.get(callback);
    }
}