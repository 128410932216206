import {Application, ContentViewStyle} from '@levelapp/softfabric';
import { StyleManager, UiManagers } from '@levelapp/softfabric-ui';
import { StyleProp, TextStyle, ViewStyle } from 'react-native';

export default class TicketDetailsPopUpViewStyle extends ContentViewStyle
{
    MyStyleManager: StyleManager = Application.current.resolve<StyleManager>(UiManagers.STYLE_MANAGER);
    
    title: StyleProp<any> = {
        textAlign: 'left',
        color: 'white',
        fontSize: 24,
        fontWeight: '600',
        marginTop: 20,
        marginLeft: 60,
        paddingBottom: 20,
        display: "flex",
        flexDirection: "column",
        flexBasis: "85%"
    };

    boxContainer: StyleProp<ViewStyle> = {
        display: 'flex',
        alignSelf: 'center',
        minHeight: 50,
        width: '100%'
    }

    stepColor: StyleProp<ViewStyle> =
    {
        backgroundColor: this.MyStyleManager.primaryColor,
    }

    tableHeader: StyleProp<TextStyle> =
    {
        color: '#575757',//575757
        fontSize: 15,
        fontFamily: 'Montserrat',
        fontWeight: 'bold',
    }

    ticketDetailsBox: StyleProp<ViewStyle> =
    {
        marginTop: 20,
        marginLeft: 60,
        paddingBottom: 20,
    }

}