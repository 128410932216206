import {ContentViewStyle, Application} from '@levelapp/softfabric';
import { StyleManager, UiManagers } from '@levelapp/softfabric-ui';
import { StyleProp, ViewStyle, TextStyle } from 'react-native';

export default class PosSimulatorViewStyle extends ContentViewStyle {
    MyStyleManager : StyleManager = Application.current.resolve<StyleManager>(UiManagers.STYLE_MANAGER);

    container: StyleProp<ViewStyle> = {
        display: 'flex',
        flex: 1,
        alignItems: 'center',
        
    }

    box: StyleProp<ViewStyle> = {
        width: '95%',
        backgroundColor: 'white',
        borderRadius: 6,
        marginTop: 40,
        shadowColor: "#0000006b",
        shadowOffset: {
            width: 1,
            height: 0,
        },
        shadowOpacity: 0.70,
        shadowRadius: 20.00,
        elevation: 60,
    }

    

    

    tableHeader : StyleProp<TextStyle> =
    {
        color: '#575757',
        fontSize : 15,
        fontFamily : 'Montserrat',
        fontWeight : 'bold',
        width: '100%',
        
       
    }


    
    buttonSubTitle: StyleProp<TextStyle> = {
        justifyContent:"center",
        width: '100%',
        color: 'white',
        fontSize: 18,
        fontWeight: "bold",
        backgroundColor:'#FC6076',
        padding: 10,
        borderRadius: 30,
        
       
        
    }
    purchasebuttonSubTitle: StyleProp<TextStyle> = {
        justifyContent:"center",
        width: '100%',
        color: 'white',
        fontSize: 15,
        fontWeight: "bold",
        backgroundColor:'#FC6076',
        paddingTop: 10,
        paddingBottom: 10,
        paddingLeft: 55,
        paddingRight: 55,
        borderRadius: 30,
    }
    puchaseAmountContainer: ViewStyle = {
        display: 'flex',
        alignSelf: 'center',
        justifyContent: 'space-around',
        alignItems: 'center',
        marginRight: 15,
        width: '46%',
    }
    cancelButton: StyleProp<TextStyle> = {
        justifyContent:"center",
        width: '100%',
        color: 'white',
        fontSize: 15,
        fontWeight: "bold",
        backgroundColor:'#FF9944',
        paddingTop: 10,
        paddingBottom: 10,
        marginLeft: 8,
        paddingLeft: 10,
        paddingRight: 10,
        borderRadius: 30,     
        
    }
    okButton: StyleProp<TextStyle> = {
        
        color: 'white',
        fontSize: 15,
        fontWeight: "bold",
        backgroundColor: 'green',
        marginTop: 28,
        paddingTop: 10,
        paddingBottom: 10,
        paddingLeft: 10,
        paddingRight: 10,
        marginRight: 40,
        marginLeft: 7,
        borderRadius: 30,      
        
    }
    clearButton: StyleProp<TextStyle> = {
        color: 'white',
        fontSize: 15,
        fontWeight: 'bold',
        backgroundColor: 'red',
        marginTop: 28,
    
       
        paddingTop: 10,
        paddingBottom: 10,
        paddingLeft: 10,
        paddingRight: 10,
        marginRight: 5,
        marginLeft: 16,
        borderRadius: 30,  
    }


    label: StyleProp<TextStyle> = {
        fontSize: 16,
        fontWeight: '600',
        color: '#525f7f',
        marginBottom: 10
    }

    boxContainer: StyleProp<ViewStyle> = {
        display: 'flex',
        alignSelf: 'flex-end',
        justifyContent: 'flex-end',
        alignItems: 'stretch',
        minHeight: 200,
        width: '90%',
        paddingTop: 20,
        paddingBottom: 20,
        paddingLeft: 40,
    }

    qrScannerBoxContainer: StyleProp<ViewStyle> = {
        display: 'flex',
        alignSelf: 'flex-end',
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
        minHeight: 200,
        width: '82%',
        paddingTop: 20,
        paddingBottom: 20,
        marginBottom: 15,
    }

    buttonBox : StyleProp<ViewStyle> =
    {
        flexDirection: 'row',
        display: 'flex',
        alignSelf: 'center',
        justifyContent: 'space-around',
        alignItems: 'center',        
        flex:1,
        paddingTop: 10,
        paddingBottom: 10,
    }

    purchaseButtonBox: StyleProp<ViewStyle> =
    {
        flexDirection: 'row',
        display: 'flex',
        alignSelf: 'center',
        justifyContent: 'space-around',
        alignItems: 'center',        
        flex:1,
        paddingTop: 10,
        paddingBottom: 10,
        paddingLeft: 30,
        paddingRight: 30,
    }

    performButton: StyleProp<TextStyle> = {
        justifyContent:"center",
        width: '100%',
        color: 'white',
        fontSize: 15,
        fontWeight: "bold",
        backgroundColor:'#38B8F2',
        paddingTop: 10,
        paddingBottom: 10,
        marginLeft: 22,
        paddingLeft: 5,
        paddingRight: 10,
        borderRadius: 30,  
              
    }


    iconBox: StyleProp<ViewStyle> = {
        backgroundColor: 'white',
        
    }

    availableIn: StyleProp<ViewStyle> = {
        marginTop: 50
    }

    subCategories: StyleProp<ViewStyle> = {
        marginTop: 50
    }

    radioContainer: StyleProp<ViewStyle> = {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        marginTop: 5,
        marginBottom: 5
    }

    radioLabel: StyleProp<TextStyle> = {
        fontSize: 16,
        fontWeight: '600',
        color: '#8898aa',
        marginLeft: 10
    }

   

    stepColor : StyleProp<ViewStyle> =
    {
        backgroundColor : this.MyStyleManager.primaryColor,
    }

    

    rowStyle: StyleProp<ViewStyle> =
    {
        minHeight: 10,
    }

    identifiantBox: StyleProp<ViewStyle> = {
        width: '100%',
        marginTop: 80,
        marginBottom: 10,
        marginLeft: 600,
        flexDirection: 'row',
    }

    WelcomeBox: StyleProp<ViewStyle> = {
        justifyContent: "center",
        display: 'flex',
        flexDirection: 'row',
        paddingTop: 30,
        flexWrap: 'wrap',
      
    }

    purchaseBox: StyleProp<ViewStyle> = {
        width: '60%',
        paddingTop: 30,
        paddingLeft: 30,     
        // 
        justifyContent: "center",
        flexDirection: 'column',

    }

    qrScannerBox: StyleProp<ViewStyle> = {
        width: '60%',
        paddingTop: 30,
        paddingLeft: 30,     
        // 
        justifyContent: "center",
        flexDirection: 'column',

    }

    welcomeTitle: StyleProp<TextStyle> = {
        justifyContent: "center",
        fontWeight: "bold",
        fontSize: 25,
        marginTop: 40,
        marginLeft: 30,
        paddingLeft: 70,
        color: "white",
    }

    identifiantTitle: StyleProp<TextStyle> = {     
        fontWeight: "bold",
        fontSize: 12,
        marginTop: 10,
        marginLeft: 30,
      
    }
    textInput: ViewStyle = {
        width: '90%',
        borderColor : 'gray',
        borderRadius : 5,
        borderWidth : 1,
        marginRight: 30,
        
    }

    

    qrcodeContainer: ViewStyle = {
        
        display: 'flex',
        alignSelf: 'center',
        justifyContent: 'center',
        alignItems: 'center',
        marginRight: 5,
        width: '58%',
    }

    qrcodeBoxView: ViewStyle = {
        display: 'flex',
        flexDirection: 'row',
        alignSelf: 'center',
        justifyContent: 'center',
        alignItems: 'center',
        marginLeft: 38,
        width: '60%',
    }

    purchaseBoxView: ViewStyle = {
        display: 'flex',
        flexDirection: 'row',
        alignSelf: 'center',
        justifyContent: 'center',
        alignItems: 'center',
        marginBottom: 20,
    }

    infos: ViewStyle = {
        paddingTop: 10,
        paddingBottom: 10,
        paddingLeft: 10,
    }
    infosStyle: StyleProp<TextStyle> = {
        width: '100%',
        fontWeight:"bold",
        padding: 5,
    }

}
