 
import {Application, ContentView} from '@levelapp/softfabric';
import PopupStoresListViewState from './PopupStoresListViewState';
import PopupStoresListViewProps from './PopupStoresListViewProps';
import PopupStoresListViewStyle from './PopupStoresListViewStyle';
import PopupStoresListViewModel from './PopupStoresListViewModel';
import {   TouchableWithoutFeedback,  View,Text } from 'react-native';
import Store from '../../../../../Common/DTO/Store';
import PopupHeaderView from '../popupheader/PopupHeaderView';
import Helpers from '../../../../../Common/Helpers/Helpers';
import ResearchInputView from '../../../../../Common/Components/researchinput/ResearchInputView';
import { LottieView, StyleManager, TranslationView, UiManagers } from '@levelapp/softfabric-ui';
import Constants from '../../../../../Common/Constants/Constants';

export default class PopupStoresListView extends ContentView<PopupStoresListViewProps, PopupStoresListViewState, PopupStoresListViewModel, PopupStoresListViewStyle>
{
  /* CONSTRUCTOR */
  constructor(props:PopupStoresListViewProps)
  {
    super(props);

    // Binding
    this.bind(new PopupStoresListViewModel(this), new PopupStoresListViewStyle(this));
  }

  /* RENDERING */
  render()
  {
    const { container , popup,storesContainer,storeItem,selectedTxt,unselectedTxt } = this.style;
    const myStyleManager : StyleManager = Application.current.resolve<StyleManager>(UiManagers.STYLE_MANAGER);
    return (
      <View style={popup}>

        <PopupHeaderView
          handleBackPressed={Helpers.closePopup}
          title={`storeList.popup.title`}
          shouldShowButton
          isLoading={this.state.isLoading}
          handleOnPressButton={()=> {this.binding.handleSavePressed()}}
        />    
      
      <View style={container}>
      
        
        <View style={{flexDirection : 'row', justifyContent : "space-between"}}>
          <ResearchInputView
                placeholder={this.binding.translate('generic.search')}
                handlerResearching={(searchText: string) => { this.binding.handleResearching(searchText) }}
                handlingClose={() => {  }} />
          <View >
            <TranslationView>{'storeList.chosen.store'}</TranslationView>
            {this.state.selectedStore && 
              <Text>{this.binding.getSelectedStore()}</Text>}
          </View>
            
        </View>
        {this.state.isLoading&& <LottieView height={150} width={150} source={require('../../../../../Common' + Constants.LottieAnimationPath)} />}
        <View style={storesContainer}>
          {this.binding.getOrderedStores().map((store : Store)=>{return(
            <TouchableWithoutFeedback onPress={()=>{this.binding.selectStore(store)}}>
              <View style={store.id == this.state.selectedStore?.id ? [storeItem, {backgroundColor : myStyleManager.primaryColor,}] : storeItem}>
                <View>
                <Text  style={store.id == this.state.selectedStore?.id ? selectedTxt : unselectedTxt }> 
                {this.props.displayNameStore ==='slug' ? store.slug : store.title}
                </Text>
                </View>
              </View>
              </TouchableWithoutFeedback>)
          })}
        </View>     
      </View>
      
    
    </View>

      
    );
  }
}