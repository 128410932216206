import {ContentViewStyle, Application} from '@levelapp/softfabric';
import { StyleProp, ViewStyle, TextStyle } from 'react-native';
import { UiManagers, StyleManager } from '@levelapp/softfabric-ui';

export default class TraductionViewStyle extends ContentViewStyle
{
    MyStyleManager = Application.current.resolve<StyleManager>(UiManagers.STYLE_MANAGER);

    container: StyleProp<ViewStyle> = {
        display: 'flex',
        flex: 1,
        alignItems: 'center'
    }

    box: StyleProp<ViewStyle> = {
        width: '95%',
        backgroundColor: 'white',
        borderRadius: 6,
        marginTop: 40,
        shadowColor: "#0000006b",
        shadowOffset: {
            width: 1,
            height: 0,
        },
        shadowOpacity: 0.70,
        shadowRadius: 20.00,
        elevation: 60,
    }

    boxTitle: StyleProp<ViewStyle> = {
        width: '98%',
        marginTop: -20,
        margin: 'auto',
        borderRadius: 8
    }

    title: StyleProp<TextStyle> = {
        color: 'white',
        fontSize: 30,
        padding: 30
    }

    tableHeader : StyleProp<TextStyle> =
    {
        color: '#575757',
        fontSize : 11,
        fontFamily : 'Montserrat',
        fontWeight : 'bold',
    }


    subTitle: StyleProp<TextStyle> = {
        color: 'white',
        fontSize: 17,
        paddingTop: 2,
        marginLeft: 30,
        marginBottom: 10,
        fontWeight: '100'
    }

    label: StyleProp<TextStyle> = {
        fontSize: 16,
        fontWeight: '600',
        color: '#525f7f',
        marginBottom: 10
    }

    boxContainer: StyleProp<ViewStyle> = {
        display: 'flex',
        alignSelf: 'center',
        minHeight: 250,
        width: '100%',
        paddingTop: 30,
        paddingBottom: 30,
        paddingLeft: 50,
        paddingRight: 50,
    }

    availableIn: StyleProp<ViewStyle> = {
        marginTop: 50
    }

    subCategories: StyleProp<ViewStyle> = {
        marginTop: 50
    }

    radioContainer: StyleProp<ViewStyle> = {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        marginTop: 5,
        marginBottom: 5
    }

    radioLabel: StyleProp<TextStyle> = {
        fontSize: 16,
        fontWeight: '600',
        color: '#8898aa',
        marginLeft: 10
    }

    buttonBox : StyleProp<ViewStyle> =
    {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-end'
    }

    stepColor : StyleProp<ViewStyle> =
    {
        backgroundColor : this.MyStyleManager.primaryColor,
    }

    exportButton: StyleProp<ViewStyle> = {
        marginLeft: 30,
        marginBottom: 10,     
        backgroundColor: 'white',
        width: 105,
        borderRadius: 10,
        height: 34,
    }

    exportButtonText: StyleProp<TextStyle> = {
        color: this.MyStyleManager.primaryColor,
        fontSize: 14,
        textAlign: 'left',  
        marginLeft: '27px',
        marginTop: '5px'
    }

   addTranslationButton: StyleProp<ViewStyle> = {
        marginLeft: 30,
        marginBottom: 10,     
        backgroundColor: 'white',
        width: 205,
        borderRadius: 15,
        height: 34,
    }
}