/**
 * Role Constants
 *  those values are applied when "ShouldWorkWithRole" is a true 
 *  default value if the user has no role.
 */

const RoleConstants = {

     ShowUsers: false,
     ShowRewards: false,
     ShowCoupons: false,
     ShowBoosters: false,
     ShowTranslations: false,
     ShowNews: false,
     ShowProductCategories: false,
     ShowStats: false,
     ShowPosSimulator: false,
     ShowPurchase: false,
     ShowLoyaltyRules: false,
     ShowLegalTexts: false,
     ShowNewsDiplay: false,
     ShowStores: false,
     ShowSavingCards: false,
     ShowFaq: false,
     ShowMessages: false,
     ShowAutomatedMessages: false,
     ShowProducts: false,
     ShowOrders: false,
     ShowRolePermissions: false,
     ShowAdminUsers: false,
     ShowKitchenApp: false,
     ShowMenuConfigurator:false,
     ShowTickets: false,
     ShowPromoCodes : false,
     ShowEverydayValues : false,
     ShowHealthStatus : false,
     ShowLanguage: false,
     ShowCountries: false,
     ShowOverview : false,
     ShowStoresSummary : false,
     ShowCrossSellconfigurator : false, 
     ShowOutOfStock : false, 
     ShowContentTypeKeyConfig : false,
     ShowProductTool: false,
     ShowZoneTool : false,
     ShowBanners : false,
     ShowEarningPoints: false,
     ShowMembership: false,
     ShowVouchers: false,
     ShowProductAdditionalInfo: false,
}

export default RoleConstants;
