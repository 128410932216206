
import { ContentView } from '@levelapp/softfabric';
import TableViewState from './TableViewState';
import TableViewProps from './TableViewProps';
import TableViewStyle from './TableViewStyle';
import TableViewModel from './TableViewModel';
import { View, Text, Image, TouchableWithoutFeedback, CheckBox } from 'react-native';
import StepView from './modules/step/StepView';
import { LottieView } from '@levelapp/softfabric-ui';
import Constants from '../../Constants/Constants';
import ImageButton from '../imagebutton/ImageButtonView';
//import ButtonView from '../Button/ButtonView';


export default class TableView extends ContentView<TableViewProps, TableViewState, TableViewModel, TableViewStyle>
{
  /* CONSTRUCTOR */
  constructor(props: TableViewProps) {
    super(props);

    // Binding
    this.bind(new TableViewModel(this), new TableViewStyle(this));
  }

  /* RENDERING */
  render() {
    return (
      < View >
        {!this.props.isLoading ? (
          <View style={[this.props.maxHeight != undefined && this.props.maxHeight && this.style.maxHeight, { minWidth: this.props.minWidth == undefined ? 300 : this.props.minWidth }]}>

            {/* TH */}
            < View style={[this.style.thRow, this.props.headerStyle]}>
              {this.props.headers.map((head, i) => {
                return (
                  <View key={i} style={{ flexDirection: 'row', flex: 1 }}>

                    <View style={{ display: 'flex', flexDirection: 'row' }}>
                      <TouchableWithoutFeedback onPress={() => this.props.isSortable ? this.binding.OnPress(i) : {}} style={{ display: 'flex', flexDirection: 'row' }}>
                        <View style={{ display: 'flex', flexDirection: 'row' }}>
                          <Text style={[this.style.thText, this.props.headerTextStyle]}>{head}</Text>
                          <Image source={this.state.sortIndice == i ? this.state.imageSort : this.state.image} style={this.props.isSortable ? this.style.showImage : this.style.notShowingImage} resizeMode="contain" />
                        </View>
                      </TouchableWithoutFeedback>
                    </View>

                  </View>);
              })}
              {this.props.isLastColumnCheckBox && (<View style={{ width: 60 }}><Text style={[this.style.thText, this.props.headerTextStyle]}>Select</Text></View>)}
              {this.props.isLastColumnActions && (<View style={{ width: 60 }}><Text style={[this.style.thText, this.props.headerTextStyle]}>Actions</Text></View>)}
            </View >
            {/* TR*/}
            {this.binding.getElementsForPage() != undefined && this.binding.getElementsForPage().length > 0 ? this.binding.getElementsForPage().map((data: any, i: number) => {
              return (

                < View style={[this.style.tdRow, this.props.rowStyle]} key={i}>

                  {data.data.map((content: any, j: any) => {
                    /*return (<View key={j} style={{flex:1 ,  marginLeft: 15}}><Text style={[this.style.tdText]}>{content}</Text></View>);*/
                    return (
                      (("" + content).indexOf("http")) >= 0 ? (
                        ((("" + content).indexOf("on-icon")) >= 0 || (("" + content).indexOf("off-icon")) >= 0) ?
                          (<View key={j} style={{ flex: 1, height: '52px' }}><Image source={{ uri: require("./assets/" + content + ".svg").default }} resizeMode='contain' style={{ height: '93%', width: '30%' }}></Image></View>) :
                          (content.includes('.png') || content.includes('.jpg') || content.includes('.jpeg') ? <View key={j} style={{ flex: 1, }}><Image source={{ uri: content }} resizeMode='contain' style={{ height: '100%', width: '90%' }}></Image></View> : <View key={j} style={{ flex: 1, }}><Text style={this.style.tdText}>{content}</Text></View>)
                      ) : (
                          ((("" + content).indexOf("---")) >= 0) ?
                            ((content != undefined && content.length > 50) ?
                              (<View key={j} style={{ flex: 1 }}><Text style={[this.style.tdText, { fontSize: 30, fontWeight: 'bold' }]}>{content.substr(0, 50) + "..."}</Text></View>) :
                              (<View key={j} style={{ flex: 1 }}><Text style={[this.style.tdText, { fontSize: 30, fontWeight: 'bold' }]}>{content}</Text></View>)) :
                            ((content != undefined && content.length > 50) ?
                              (<View key={j} style={{ flex: 1 }}><Text style={this.style.tdText}>{content.substr(0, 50) + "..."}</Text></View>) :
                              (<View key={j} style={{ flex: 1 }}><Text style={this.style.tdText}>{content}</Text></View>))
                        )


                    );
                  })}
                  {this.props.isLastColumnCheckBox &&
                    <View style={{ width: 40}}>
                      <CheckBox
                        value={data.isCheckBoxChecked}
                        onValueChange={() => this.binding.handleCheckBoxClicked(data)}
                      />
                    </View>

                  }
                  {this.props.isLastColumnActions ?
                    data.isEditable && data.isDeletable ? (
                      <View style={{ display: 'flex', flexDirection: 'row' }}>
                        <ImageButton
                          imageOnMouseEnter={require('./assets/editgradient.svg').default}
                          image={require('./assets/edit.svg').default}
                          onClick={() => data.editCallback()}
                          imageHeight={24}
                          imageWidth={24}
                          style={{ padding: 3 }} />
                        <ImageButton
                          imageOnMouseEnter={require('./assets/deletegradient.svg').default}
                          image={require('./assets/delete.svg').default}
                          onClick={() => data.deleteCallback()}
                          imageHeight={24}
                          imageWidth={24}
                          style={{ padding: 3 }} />
                      </View>
                    )
                      :
                      data.isEditable ? (
                        <View style={{ width: 60 }}>
                          <ImageButton
                            imageOnMouseEnter={require('./assets/editgradient.svg').default}
                            image={require('./assets/edit.svg').default}
                            onClick={() => data.editCallback()}
                            imageHeight={24}
                            imageWidth={24}
                            style={{ padding: 3 }} />
                        </View>
                      )
                        :
                        data.isDeletable ? (
                          <View style={{ width: 60 }}>
                            <ImageButton
                              imageOnMouseEnter={require('./assets/deletegradient.svg').default}
                              image={require('./assets/delete.svg').default}
                              onClick={() => data.deleteCallback()}
                              imageHeight={24}
                              imageWidth={24}
                              style={{ padding: 3 }} />
                          </View>
                        )
                        :
                        data.isViewable ? (
                          <View style={{ width: 60 }}>
                            <ImageButton
                              imageOnMouseEnter={require('./assets/viewgradient.svg').default}
                              image={require('./assets/view.svg').default}
                              onClick={() => data.viewCallback()}
                              imageHeight={24}
                              imageWidth={24}
                              style={{ padding: 3 }} />
                          </View>
                        )
                          :
                          (
                            <View />
                          )
                    : null}
                </View >
              );
            }) : <View style={this.style.nothingToDisplay}>
                <Text>{this.props.nothingToDisplayText}</Text>
              </View>}


            <StepView styleOfChoosenButton={this.props.backgroundStepColor} currentPage={this.binding.getCurrentPage()} numberDisplays={this.binding.getPages()} handleChangingPage={this.binding.changePage} minimumPage={2} />
          </View>
        )
          :

          (
            <LottieView height={150} width={150} source={require('../../../Common' + Constants.LottieAnimationPath)} />
          )}

      </View >
    );
  }
}