import { ContentShellViewModel, ContentShellView } from '@levelapp/softfabric';
import ShellTraductionViewState from './ShellTraductionViewState';
import ShellTraductionViewProps from './ShellTraductionViewProps';
import EnumModules from '../../../../../Common/Constants/EnumModules';
 
import TraductionView from './pages/tranduction/TraductionView';

export default class ShellTraductionViewModel extends ContentShellViewModel<ShellTraductionViewState, ShellTraductionViewProps>
{
    constructor(view: ContentShellView)
    {
        super(view);

        this.registerEntryPoint('main');
        this.register(<TraductionView/> ,'main');

        this.initialisation();
    }

    getModuleId()
    {
        return EnumModules.TRANSLATION;
    }
}