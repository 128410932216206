 
import { ContentView } from '@levelapp/softfabric';
import SavingCardsPopUpViewState from './SavingCardsPopUpViewState';
import SavingCardsPopUpViewProps from './SavingCardsPopUpViewProps';
import SavingCardsPopUpViewStyle from './SavingCardsPopUpViewStyle';
import SavingCardsPopUpViewModel from './SavingCardsPopUpViewModel';
import { TranslationView } from '@levelapp/softfabric-ui';
import Constants from '../../../../../../../Common/Constants/Constants';
import { View } from 'react-native';
import Helpers from '../../../../../../../Common/Helpers/Helpers';
import PopupMenuItemView from '../../../../components/popupmenuitem/PopupMenuItemView';
import PicturesSavingCardsView from './components/picturessavingcards/PicturesSavingCardsView';
import DescriptionView from '../../../../components/description/DescriptionView';
import PopupHeaderView from '../../../../components/popupheader/PopupHeaderView';
import MainInformationView from './components/maininformation/MainInformationView';

export default class SavingCardsPopUpView extends ContentView<SavingCardsPopUpViewProps, SavingCardsPopUpViewState, SavingCardsPopUpViewModel, SavingCardsPopUpViewStyle>
{
  /* CONSTRUCTOR */
  constructor(props: SavingCardsPopUpViewProps) {
    super(props);

    // Binding
    this.bind(new SavingCardsPopUpViewModel(this), new SavingCardsPopUpViewStyle(this));
  }

  /* RENDERING */
  render() {
    return (
      <View style={{ minWidth: 600, minHeight: 850, width: Constants.PopUpWidth, maxWidth: "100%" }}>
          <PopupHeaderView
            handleBackPressed={() =>
              {
                this.props.refreshParent()
                Helpers.closePopup()
              }}
            title={(Constants.IsSavingCardsUsedAsBadge) ? "badges.popup.title" : "savingcards.popup.title"}
            shouldShowButton
            isLoading={this.state.isLoading}
            handleOnPressButton={this.binding.handleSavePressed}
          />    

        <View style={{ display: "flex", flexDirection: "row", flexWrap: "wrap" }}>
          <PopupMenuItemView activeItem={this.state.tab} title={"form.maininformation"} item={"main"} handleChangeTab={() => this.binding.changeTab("main")}/>
          <PopupMenuItemView activeItem={this.state.tab} title={"form.description"} item={"desc"} handleChangeTab={() => this.binding.changeTab("desc")}/>
          <PopupMenuItemView activeItem={this.state.tab} title={"form.images"} item={"pictures"} handleChangeTab={() => this.binding.changeTab("pictures")}/>
        </View>

        {this.state.tab == "main" &&
          <MainInformationView
            handleCheckboxChange={this.binding._dispatcherManager.handleCheckboxChange}
            handleDateChange={this.binding._dispatcherManager.handleDateChange}
            handleValueChange={this.binding._dispatcherManager.handleValueChange}
            handleTranslationsChange={this.binding._dispatcherManager.handleTranslationsChange}
            showErrors={this.state.showErrors}
            savingCard={this.state.savingCard}
            languages={this.state.languages}
            handlePointsChange={this.binding.handlePointsChange}
            handleCouponIdChange={this.binding.handleCouponIdChange}
          />
        }

        {this.state.tab == "desc" &&
          <DescriptionView
            listDesc={this.state.savingCard.descriptionTranslations}
            languages={this.state.languages}
            shouldShowError={this.state.showErrors}
            handleTranslationsChange={this.binding._dispatcherManager.handleTranslationsChange}
          />
        }

        {this.state.tab == "pictures" &&
            <PicturesSavingCardsView
              languages={this.state.languages}
              listImage={this.state.savingCard.imageTranslation}
              listThumbnail={this.state.savingCard.thumbnailTranslation}
              listImageStamped={this.state.savingCard.imageStampedTranslation}
              listImageUnstamped={this.state.savingCard.imageUnstampedTranslation}
              shouldUseDefaultImage={this.state.useDefaultImageChecked}
              shouldUseDefaultThumnail={this.state.useDefaultThumbnailChecked}
              shouldUseDefaultImageStamped={this.state.useDefaultImageStampedChecked}
              shouldUseDefaultImageUnstamped={this.state.useDefaultImageUnstampedChecked}
              handleCheckboxChange={this.binding._dispatcherManager.handleCheckboxChange}
              handleOnComplete={this.binding._dispatcherManager.handleAnyChange}
              handleOnPressImage={this.binding._dispatcherManager.handleNumberChange}
            />
        }
        {this.state.showErrors &&
          < View style={this.style.footer} >
            <TranslationView style={{ color: "red" }}>error.please.fields</TranslationView>
          </View>
        }
      </View>
    );
  }
}