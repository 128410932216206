import {ContentViewStyle} from '@levelapp/softfabric';
import {StyleProp,ViewStyle} from 'react-native';

export default class MainInformationViewStyle extends ContentViewStyle
{
    page: StyleProp<ViewStyle> = {
        flex: 1
    }

    block : StyleProp<ViewStyle> =
    {
        zIndex: -1, 
        display: 'flex', 
        flexDirection: 'row', 
        flexWrap: 'wrap', 
        justifyContent: 'space-between'
    }

    subheading: StyleProp<any> = {
        fontSize: 19,
        marginBottom: 20,
    };
}