import { ContentView, ContentViewModel } from '@levelapp/softfabric';

import LogTabViewProps from './LogTabViewProps';
import LogTabViewState from './LogTabViewState';

import ToastHelper from 'src/Common/Helpers/ToastHelper';
import AvailabilityHistoryOperations from 'src/Transfer/AvailabilityHistoryOperations';
import PriceHistoryOperations from 'src/Transfer/PriceHistoryOperations';



export default class LogTabViewModel extends ContentViewModel<LogTabViewState, LogTabViewProps>
{
  availabilityHistoryOperations: AvailabilityHistoryOperations;
  priceHistoryOperations: PriceHistoryOperations;

  /* CONSTRUCTOR */
  constructor(view: ContentView) {
    super(view);

    this.initialState({
      priceHistories: [],
      availabilityHistories: [],
      currentSelectedProduct: this.props().selectedItem,
      selectedFilter: 'availability',
      isLoading: false,
    });

    this.availabilityHistoryOperations = new AvailabilityHistoryOperations();
    this.priceHistoryOperations = new PriceHistoryOperations();

    // Bindings
    this.setSelectedFilter = this.setSelectedFilter.bind(this);
  }

  componentDidUpdate() {
    if ((this.state().currentSelectedProduct?.id !== this.props().selectedItem?.id)) {
      this.refresh(this.state().selectedFilter);
      this.setField({ currentSelectedProduct: this.props().selectedItem });
    }
  }

  componentDidMount() {
    this.refresh(this.state().selectedFilter);
  }

  refresh(filter: string) {
    this.getData(filter);
  }

  async getData(filter: string) {
    try {
      this.setField({ isLoading: true });
      if (filter === 'availability') {
        const availabilityHistories = await this.availabilityHistoryOperations.get(this.props().selectedItem?.id || 0);
        this.setField({ availabilityHistories })
      } else {
        const priceHistories = await this.priceHistoryOperations.get(this.props().selectedItem?.id || 0);
        this.setField({ priceHistories })
      }
    } catch {
      this.showToast(ToastHelper.getToast("tools.generic.get.error", "error"), () => { }, 2500);
    } finally {
      this.setField({ isLoading: false });
    }
  }

  setSelectedFilter(filter: string) {
    this.setField({ selectedFilter: filter });
    this.refresh(filter);
  }
}