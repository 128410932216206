import { Service, Application, HttpManager, Managers } from "@levelapp/softfabric";

export default class OpeningHoursService extends Service
{
    httpClient = Application.current.resolve<HttpManager>(Managers.HTTP_MANAGER);

    async get(storeId: number)
    {
        return await this.httpClient.get('openinghours', [{ key: 'storeId', value: storeId }]);
    }
}