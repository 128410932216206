import {ContentShellViewModel, ContentShellView} from '@levelapp/softfabric';
import ShellEverydayValuesViewState from './ShellEverydayValuesViewState';
import ShellEverydayValuesViewProps from './ShellEverydayValuesViewProps';
 
import EnumModules from '../../../../../Common/Constants/EnumModules';
import CouponsView from '../coupons/pages/coupons/CouponsView';

export default class ShellEverydayValuesViewModel extends ContentShellViewModel<ShellEverydayValuesViewState, ShellEverydayValuesViewProps>
{

    constructor(view : ContentShellView)
    {
        super(view);

        this.registerEntryPoint('main'); // TODO : change with the route of your entry point
        this.register(<CouponsView type={'everyday'}/> ,'main'); // TODO : register your routes with the JSX (param1) and the route (param2)

        this.initialisation();
    }

    getModuleId()
    {
        return EnumModules.EVERYDAYVALUES; // TODO : change the return value with your ModuleId
    }

}