import { ContentViewStyle } from '@levelapp/softfabric';
import { StyleProp, TextStyle, ViewStyle } from 'react-native';

export default class TimerViewStyle extends ContentViewStyle
{
    timeContainer: StyleProp<ViewStyle> = {
        paddingHorizontal: 7,
        paddingVertical: 3,
        backgroundColor: '#FFFFFF',
        borderRadius: 3
    }

    timeContainerTimeOut: StyleProp<ViewStyle> = {
        borderColor: '#C81616',
        borderWidth: 1,
        backgroundColor: '#F9E7E7',
    }

    timeLabel: StyleProp<TextStyle> = {
        fontSize: 18,
    }

    timeLabelTimeOut: StyleProp<TextStyle> = {
        color: '#C81616'
    }
}