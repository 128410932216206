import { ContentViewStyle, Application } from '@levelapp/softfabric';
import { StyleProp, ViewStyle, TextStyle } from 'react-native';
import { StyleManager, UiManagers } from '@levelapp/softfabric-ui';

export default class LoyaltyRulesViewStyle extends ContentViewStyle
{

    MyStyleManager: StyleManager = Application.current.resolve<StyleManager>(UiManagers.STYLE_MANAGER);

    section: StyleProp<ViewStyle> = {
        marginTop: 50,
        marginLeft: 20,
    };

    input: StyleProp<any> = {
        width: 140,
        paddingHorizontal: 15,
        borderRadius: 8,
        boxShadow: '0 5px 14px 0 rgba(0,0,0,0.1)',
    };

    saveButton: StyleProp<ViewStyle> = {
        width: 200,
        marginLeft: 20,
        marginTop: 20,
        marginBottom: 20
    };

    container: StyleProp<ViewStyle> = {
        display: 'flex',
        flex: 1,
        alignItems: 'center'
    }

    box: StyleProp<ViewStyle> = {
        width: '95%',
        backgroundColor: 'white',
        borderRadius: 6,
        marginTop: 40,
        shadowColor: "#0000006b",
        shadowOffset: {
            width: 1,
            height: 0,
        },
        shadowOpacity: 0.70,
        shadowRadius: 20.00,
        elevation: 60,
    }

    inputTitle: StyleProp<TextStyle> = {
        fontSize: 14,
        fontWeight: '600',
        color: '#3F3F3F',
        fontFamily: 'Montserrat SemiBold',
        marginBottom: 13,
    };

    errorTextField: StyleProp<ViewStyle> = {
        borderColor: 'red',
        borderWidth: 1,
        width: 140,
        paddingHorizontal: 15,
        borderRadius: 8,
    };

    subheading: StyleProp<any> = {
        fontSize: 19,
        marginBottom: 20,
    };

    editTexts: StyleProp<ViewStyle> = {
        marginTop: 30
    };

    loyaltyTranslationButtons: StyleProp<ViewStyle> = {
        display: 'flex',
        flexDirection: 'row'
    };
}