import {ContentViewModel,ContentView} from '@levelapp/softfabric';
import AddCrossSellProductPopUpViewState from './AddCrossSellProductPopUpViewState';
import AddCrossSellProductPopUpViewProps from './AddCrossSellProductPopUpViewProps';
import ImageTranslation from '../../../../../../../Common/DTO/ImageTranslation';
import Product from '../../../../../../../Common/DTO/Product';
import TranslationValue from '../../../../../../../Common/DTO/TranslationValue';
import ErrorHelper from '../../../../../../../Common/Helpers/ErrorHelper';
import Helpers from '../../../../../../../Common/Helpers/Helpers';
import ToastHelper from '../../../../../../../Common/Helpers/ToastHelper';
import TranslationHelper from '../../../../../../../Common/Helpers/TranslationHelper';
import DispatcherManager from '../../../../../../../Common/Managers/DispatcherManager';
import ProductsOperations from '../../../../../../../Transfer/ProductsOperations';

export default class AddCrossSellProductPopUpViewModel extends ContentViewModel<AddCrossSellProductPopUpViewState, AddCrossSellProductPopUpViewProps>
{
    _dispatcherManager : DispatcherManager;
    productsOperations : ProductsOperations;
    
      /* CONSTRUCTOR */
    constructor(view: ContentView)
    {
        super(view);
        this.initialState({
            index: 0,
            isLoading: false,
            showErrors: false,
            indexThumbnail: 0,
            product: this.props().product,
            languages: this.props().languages,       
            useDefaultImageChecked: Helpers.hasDuplicates(this.props().product.imageTranslations),
            useDefaultThumbnailChecked: Helpers.hasDuplicates(this.props().product.thumbnailTranslations)
        }); 

        // Bindings     
        this.handleImage = this.handleImage.bind(this);
        this.saveProducts = this.saveProducts.bind(this);
        this.setIndexImage = this.setIndexImage.bind(this);
        this.handleThumbnail = this.handleThumbnail.bind(this);
        this.setIndexThumbnail = this.setIndexThumbnail.bind(this);
        this.handleSavePressed = this.handleSavePressed.bind(this);
        this.handleBackPressed = this.handleBackPressed.bind(this);
        this.handleTitleChanged = this.handleTitleChanged.bind(this);
        this.handleDescriptionChanged = this.handleDescriptionChanged.bind(this);
        this.errorManagement = this.errorManagement.bind(this);

        this.productsOperations = new ProductsOperations();
        this._dispatcherManager = new DispatcherManager();
    }

    componentDidMount()
    {
        this._dispatcherManager.translationTitle = this.handleTitleChanged;
        this._dispatcherManager.translationDescription = this.handleDescriptionChanged;

        this._dispatcherManager.numberIndexImage = this.setIndexImage;
        this._dispatcherManager.numberIndexThumbnail = this.setIndexThumbnail;

        this._dispatcherManager.uriImage = this.handleImage;
        this._dispatcherManager.uriThumbnail = this.handleThumbnail;
    }

    /* OTHERS */


    fillAllPicturesWithDefault() { 
        if (this.state().useDefaultImageChecked) {
            var list  = Helpers.fillAllImagesWithDefault(this.state().product.imageTranslations,this.state().languages) as ImageTranslation[]
            this.setField((previousState) => previousState.product.imageTranslations = list );
        }
    }

    fillAllThumbnailsWithDefault() {
        if (this.state().useDefaultThumbnailChecked) {
            var list  = Helpers.fillAllImagesWithDefault(this.state().product.thumbnailTranslations,this.state().languages) as TranslationValue[]
            this.setField((previousState) => previousState.product.thumbnailTranslations = list );
        }
    }
    
    handleSavePressed(){
        if (
            this.state().product.posId === null || this.state().product.posId === "" ||
            this.state().product.titleTranslations.length !== this.state().languages.length ||
            !this.isFilledTranslations() 
            ) {
            this.setField((previousState) => previousState.showErrors = true);
        }
        else {
            this.setField((previousState) => previousState.isLoading = true);
            let product = this.state().product 
            product.posId = "0";
            product.isGroup = true;
            product.isShownInCrossSell = true;
            this.saveProducts(product);
        }
    }

    saveProducts(product: Product) {

        for (var i = 0; i < this.props().languages.length; i++) {
            if (product.imageTranslations[i].url.includes("data:image"))
                product.imageTranslations[i].imageData = product.imageTranslations[i].url.replace("data:image/png;base64,", "").replace("data:image/jpeg;base64,", "");
            else
                product.imageTranslations[i].imageData = null;

            if (product.thumbnailTranslations[i].url?.includes("data:image"))
                product.thumbnailTranslations[i].imageData = product.thumbnailTranslations[i].url?.replace("data:image/png;base64,", "").replace("data:image/jpeg;base64,", "");
            else
                product.thumbnailTranslations[i].imageData = null;
        }
        
        this.productsOperations.post(product, () => {
            this.props().refreshParent();
            this.showToast(ToastHelper.getToast("products.popup.success.added",'success'),() => {},2500)
            this.props().handleBack();
        },this.errorManagement);
        
    }

    errorManagement(error : any) 
    {
        this.setField({isLoading : false});
        ErrorHelper.showMessageError(error.response);
    }

    handleBackPressed(){
        this.props().handleBack();
    }
    isFilledTranslations(){
        for(let i=0;i<this.state().product.titleTranslations.length;i++){
            if(this.state().product.titleTranslations[i].value === "" || this.state().product.titleTranslations[i].value === " "){
                return false;
            }
        }
        return true;
    }

    /* URI IMAGE */ 

    private handleImage(base64 : any)
    {
        var state = this.state();
        TranslationHelper.image(state.product.imageTranslations,state.languages,state.index,base64);
        this.setField({state});
        this.fillAllPicturesWithDefault();
    }

    private handleThumbnail(base64 : any)
    {
        var state = this.state();
        TranslationHelper.imageTranslation(state.product.thumbnailTranslations,state.languages,state.indexThumbnail,base64);
        this.setField({state});
        this.fillAllThumbnailsWithDefault();
    }

    /* INDEX IMAGE */
    
    private setIndexImage(index : number){
        this.setField((previousState)=>previousState.index = index);
    }

    private setIndexThumbnail(index:number){
        this.setField((previousState) => previousState.indexThumbnail = index);
    }


    /* TRANSLATIONS */

    private handleTitleChanged(text: string, index : number){
        var state = this.state();
        TranslationHelper.translation(state.product.titleTranslations,state.languages,index,text);
        this.setField({state});
    }
    
    private handleDescriptionChanged(text: string, index: number){
        var state = this.state();
        TranslationHelper.translation(state.product.descriptionTranslations,state.languages,index,text);
        this.setField({state});
    }
}