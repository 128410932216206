import Constants from "../Constants/Constants";
import { PspTypes } from "../Enums/PspTypes";

export default class PspHelper
{
    private static listOfPsp : PspTypes[] = Constants.listPspAvailable;
    private static currentPsp : PspTypes = Constants.currentPsp as any;

    

    static getCurrentPsp()
    {
        return this.currentPsp;
    }

    static getList()
    {
        return this.listOfPsp;
    }
}