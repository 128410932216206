 
import {ContentView} from '@levelapp/softfabric';
import DeleteSectionViewState from './DeleteSectionViewState';
import DeleteSectionViewProps from './DeleteSectionViewProps';
import DeleteSectionViewStyle from './DeleteSectionViewStyle';
import DeleteSectionViewModel from './DeleteSectionViewModel';
import { View } from 'react-native';
import ButtonView from '../../../../../../../../../../../Common/Components/Button/ButtonView';
import { TranslationView } from '@levelapp/softfabric-ui';

export default class DeleteSectionView extends ContentView<DeleteSectionViewProps, DeleteSectionViewState, DeleteSectionViewModel, DeleteSectionViewStyle>
{
  /* CONSTRUCTOR */
  constructor(props:DeleteSectionViewProps)
  {
    super(props);

    // Binding
    this.bind(new DeleteSectionViewModel(this), new DeleteSectionViewStyle(this));
  }

  /* RENDERING */
  render()
  {
    return (
      <View>
        <ButtonView isLoading={false} style={{  height: 40, width: 200 , justifyContent : 'center' }} backgroundColor={'#b44035'} handleClick={this.binding.handleOnClick}>
          <TranslationView>users.maininformation.section.delete</TranslationView>
        </ButtonView>
      </View>
    );
  }
}