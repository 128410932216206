import { Operation } from "@levelapp/softfabric";
import MessageCampaignsService from "../Proxy/MessageCampaignsService";

export default class MessageCampaignsOperations extends Operation
{
    service : MessageCampaignsService;

    constructor()
    {
        super();
        this.service = new MessageCampaignsService();
    }
    
    get(callBack:Function){
        this.service.get(callBack);
    }

    getById(id: number, callBack: Function)
    {
        this.service.getById(id,callBack);
    }

    post(messageCampaign : any, callback : Function, onFailure : Function)
    {
        this.service.post(messageCampaign,callback,onFailure)
    }

    test(messageCampaignId : number, idToSend : string[], callback : Function, onFailure : Function)
    {
        this.service.test(messageCampaignId,idToSend,callback,onFailure)
    }

    getDetails(messageCampaignId : number, callback : Function)
    {
        this.service.getDetails(messageCampaignId,callback)
    }

    delete(messageCampaignId : number, callback : Function, onFailure : Function)
    {
        this.service.delete(messageCampaignId, callback, onFailure)
    }

    getNbUserSegmentation(segmentation : any,metaData : any,type : "pn" | "email",callback : Function)
    {
        var new_seg = [...segmentation]
        new_seg.forEach((el,index) =>{
            var found = metaData.find((md:any) => index == md.id)
            if(found != undefined && el != null && found.userMetadataTypeId == 8){
                new_seg[index] = el.toLocaleString()
            }
        })
        this.service.getNbUserSegmentation(new_seg,type,callback)
    }

    getCardCodeListFromSegmentation(segmentation : any,metaData : any,type : "pn" | "email",callback : Function)
    {
        var new_seg = [...segmentation]
        new_seg.forEach((el,index) =>{
            var found = metaData.find((md:any) => index == md.id)
            if(found != undefined && el != null && found.userMetadataTypeId == 8){
                new_seg[index] = el.toLocaleString()
            }
        })
        this.service.getCardCodeListFromSegmentation(new_seg,type,callback)
    }



    start(messageCampaignId : number, callback : Function, onFailure : Function)
    {
        this.service.start(messageCampaignId,callback, onFailure)
    }

    updateMessageCampaign(messageCampaign : any, callback : Function, onFailure : Function)
    {
        this.service.updateMessageCampaign(messageCampaign,callback,onFailure)
    }
}