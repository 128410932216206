 
import { ContentView } from '@levelapp/softfabric';
import MyWysiwygViewWebViewState from './MyWysiwygViewWebViewState';
import MyWysiwygViewWebViewProps from './MyWysiwygViewWebViewProps';
import MyWysiwygViewWebViewStyle from './MyWysiwygViewWebViewStyle';
import MyWysiwygViewWebViewModel from './MyWysiwygViewWebViewModel';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { View } from 'react-native';

export default class MyWysiwygViewWebView extends ContentView<MyWysiwygViewWebViewProps, MyWysiwygViewWebViewState, MyWysiwygViewWebViewModel, MyWysiwygViewWebViewStyle>
{
  /* CONSTRUCTOR */
  constructor(props: MyWysiwygViewWebViewProps) {
    super(props);

    // Binding
    this.bind(new MyWysiwygViewWebViewModel(this), new MyWysiwygViewWebViewStyle(this));
  }

  /* RENDERING */
  render() {
    return (
      <View>
        <Editor
          editorState={this.state.editorState}
          onEditorStateChange={this.binding.onEditorStateChange}
          toolbar={{
            inline: { inDropdown: true },
            list: { inDropdown: true },
            textAlign: { inDropdown: true },
            link: { inDropdown: true },
            history: { inDropdown: true },
            options: ['inline', 'fontSize', 'list', 'textAlign', 'colorPicker', 'emoji', 'history'],
          }} />
      </View>
    );
  }
}