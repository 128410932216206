import { ContentViewModel, ContentView } from '@levelapp/softfabric';
import MyWysiwygViewWebViewState from './MyWysiwygViewWebViewState';
import MyWysiwygViewWebViewProps from './MyWysiwygViewWebViewProps';
import { EditorState, ContentState, convertFromHTML } from 'draft-js';
import { stateToHTML } from 'draft-js-export-html';

export default class MyWysiwygViewWebViewModel extends ContentViewModel<MyWysiwygViewWebViewState, MyWysiwygViewWebViewProps>
{
    constructor(props: ContentView) {
        super(props);

        let editorState = EditorState.createEmpty();

        if (this.props().text != "") {
            editorState = EditorState.createWithContent(
                ContentState.createFromBlockArray(
                    convertFromHTML(this.props().text)
                ));
        }

        /* BINDINGS */
        this.initialState({
            editorState: editorState
        });
    }

    onEditorStateChange: Function = (editorState: any) => {
        this.setField((previousState) => previousState.editorState = editorState);
        var content = stateToHTML(this.state().editorState.getCurrentContent());
        this.props().onChangeText(content);
    };
}