import { ThirdPartyEnum } from "../Enums/ThirdPartyEnum";

export default class ThirdPartyHelper
{
   static changeEnumToBackgroundColor(enumerator : ThirdPartyEnum)
   {
       switch(enumerator)
       {
            case ThirdPartyEnum.ADYEN :
               return '#3eb551';
            case ThirdPartyEnum.ATO : 
               return '#b8b8b8' //default
            case ThirdPartyEnum.MOLLIE :
                return '#000000'
            case ThirdPartyEnum.NCR:
                return '#58af4a'
            case ThirdPartyEnum.ORACLE:
                return '#d83427'
            case ThirdPartyEnum.RABOBANK:
                return '#11007a'
            case ThirdPartyEnum.STRIPE:
                return '#6261dc'
            default :
                return '#b8b8b8'
       }
   } 

   static changeEnumToTextColor(enumerator : ThirdPartyEnum)
   {
       switch(enumerator)
       {
            case ThirdPartyEnum.ADYEN :
               return 'white';
            case ThirdPartyEnum.ATO : 
               return 'black' //default
            case ThirdPartyEnum.MOLLIE :
                return 'white'
            case ThirdPartyEnum.NCR:
                return 'white'
            case ThirdPartyEnum.ORACLE:
                return 'white'
            case ThirdPartyEnum.RABOBANK:
                return 'white'
            case ThirdPartyEnum.STRIPE:
                return 'white'
            default :
                return 'black'
       }
   } 

   static changeEnumToText(enumerator : ThirdPartyEnum)
   {
       switch(enumerator)
       {
            case ThirdPartyEnum.ADYEN :
               return 'thirdpartyenum.adyen';
            case ThirdPartyEnum.ATO : 
               return 'thirdpartyenum.ato'
            case ThirdPartyEnum.MOLLIE :
                return 'thirdpartyenum.mollie'
            case ThirdPartyEnum.NCR:
                return 'thirdpartyenum.ncr'
            case ThirdPartyEnum.ORACLE:
                return 'thirdpartyenum.oracle'
            case ThirdPartyEnum.RABOBANK:
                return 'thirdpartyenum.rabobank'
            case ThirdPartyEnum.STRIPE:
                return 'thirdpartyenum.stripe'
            default :
                return 'No Text'
       }
   } 


    static onlyUniqueThirdPartyType(array  : any , isText : boolean) {
        var flags = [], output : any[] = [], l = array.length, i;
        for( i=0; i<l; i++) {
            if( flags[array[i].thirdPartyTypeId]) continue;
            flags[array[i].thirdPartyTypeId] = true;
            output.push(array[i].thirdPartyTypeId);
        }
        if(isText)
        {
            return this.changeEnumsToText(output)
        }else
        {
            return output;
        }
    }

    static changeEnumsToText(enumerators : any[])
    {
        var text : string[] = []
        for(var i = 0 ; i<enumerators.length;i++)
        {
            text.push(this.changeEnumToText(enumerators[i]));
        }
        return text;
    } 
}