import {ContentViewModel,ContentView} from '@levelapp/softfabric';
import TitleSectionViewState from './TitleSectionViewState';
import TitleSectionViewProps from './TitleSectionViewProps';

export default class TitleSectionViewModel extends ContentViewModel<TitleSectionViewState, TitleSectionViewProps>
{
      /* CONSTRUCTOR */
    constructor(view: ContentView)
    {
        super(view);

        this.initialState({}); // Initialize your state here

        // Bindings

    }
}