import { ToastView , TranslationView } from '@levelapp/softfabric-ui';
 
import { Text } from 'react-native';

export default class ToastHelper
{
    static getToast(title : string , type : 'error' | 'success' | 'info' | 'warning', isText : boolean = false , time : number = 2200)
    {
        return (
        <ToastView type={type} time={time}>
            {!isText ? <TranslationView style={{color : 'white' , fontSize : 15}}>
                {title}
            </TranslationView> : <Text style={{color : 'white' , fontSize : 15}}>{title}</Text> } 
        </ToastView>    
        )
    }
}