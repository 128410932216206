import { Service, Application, Managers, HttpManager } from "@levelapp/softfabric";


export default class ProductCategoriesService extends Service
{
    httpClient = Application.current.resolve<HttpManager>(Managers.HTTP_MANAGER);

    async get(parameters : any[], onSucess: Function, onFailure : Function = () => {})
    {
        this.httpClient.handleSomethingWentWrong = onFailure;
        this.httpClient.handleNotFound = onFailure;

        this.httpClient
        .get('ProductCategories', parameters)
        .then((response : any) => {
            if(response)
            {
                if(response.data)
                {
                    onSucess(response.data);
                }
            }
            
        });
    }

    post(productcategory:any, callback : Function = () => {}, onFailure : Function = () => {})
    {
        this.httpClient.handleSomethingWentWrong = onFailure;
        this.httpClient.handleNotFound = onFailure;

        this.httpClient
            .post('ProductCategories', [], productcategory)
            .then((response : any) => 
            {
                if (response)
                {
                    callback("");
                }

            })
    }

    put(productcategory: any, callback : Function = () => {}, onFailure : Function = () => {})
    {
        this.httpClient.handleSomethingWentWrong = onFailure;
        this.httpClient.handleNotFound = onFailure;

        this.httpClient
            .put('ProductCategories', [], productcategory)
            .then((response : any) => 
            {
                if(response)
                {
                    callback("");
                }
            });
    }

    delete(productcategory: any, callback : Function = () => {}, onFailure : Function = () => {})
    {
        this.httpClient.handleSomethingWentWrong = onFailure;
        this.httpClient.handleNotFound = onFailure;

        this.httpClient
            .delete('ProductCategories/delete/'+productcategory.id)
            .then((response : any) => {
                if(response)
                {
                    callback("");
                }
            });
    }

}