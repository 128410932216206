 
import {ContentView} from '@levelapp/softfabric';
import ExtendedCheckBoxViewState from './ExtendedCheckBoxViewState';
import ExtendedCheckBoxViewProps from './ExtendedCheckBoxViewProps';
import ExtendedCheckBoxViewStyle from './ExtendedCheckBoxViewStyle';
import ExtendedCheckBoxViewModel from './ExtendedCheckBoxViewModel';
import {TranslationView} from '@levelapp/softfabric-ui';
import { View, CheckBox, Text } from 'react-native';

export default class ExtendedCheckBoxView extends ContentView<ExtendedCheckBoxViewProps, ExtendedCheckBoxViewState, ExtendedCheckBoxViewModel, ExtendedCheckBoxViewStyle>
{
  /* CONSTRUCTOR */
  constructor(props:ExtendedCheckBoxViewProps)
  {
    super(props);

    // Binding
    this.bind(new ExtendedCheckBoxViewModel(this), new ExtendedCheckBoxViewStyle(this));
  }

  /* RENDERING */
  render()
  {
    const { checkbox , titleActive , titleError , container, containerFixWidth } = this.style;

    return (
      <View style={this.props.rewardProductsList ? containerFixWidth :container}>
        <CheckBox disabled={this.props.disable} style={checkbox} value={this.props.value} onValueChange={this.props.onValueChange} />
        {this.props.isTranslation ? <TranslationView style={[this.props.shouldShowError ? titleError : titleActive , this.props.disable && {opacity : 0.5}]}>{this.props.title}</TranslationView> 
        : <Text style={[this.props.shouldShowError ? titleError : titleActive , this.props.disable && {opacity : 0.5}]}>{this.props.title}</Text> }
      </View>
    );
  }
}