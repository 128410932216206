 
import { View, TouchableWithoutFeedback } from 'react-native';
import { ContentView } from '@levelapp/softfabric';
import { TranslationView } from '@levelapp/softfabric-ui';
import UsersPopUpViewState from './UsersPopUpViewState';
import UsersPopUpViewProps from './UsersPopUpViewProps';
import UsersPopUpViewStyle from './UsersPopUpViewStyle';
import UsersPopUpViewModel from './UsersPopUpViewModel';
import Constants from '../../../../../../../Common/Constants/Constants';
import SwitchView from '../../../../components/switch/SwitchView';
import MainInformationView from './components/maininformation/MainInformationView';
import PopupHeaderView from '../../../../components/popupheader/PopupHeaderView';
import Helpers from '../../../../../../../Common/Helpers/Helpers';
import ConfirmGenericPopUpView from '../../../../../../../Common/Components/confirmgenericpopup/ConfirmGenericPopUpView';

export default class UsersPopUpView extends ContentView<UsersPopUpViewProps, UsersPopUpViewState, UsersPopUpViewModel, UsersPopUpViewStyle>
{
  /* CONSTRUCTOR */
  constructor(props: UsersPopUpViewProps) {
    super(props);
    // Binding
    this.bind(new UsersPopUpViewModel(this), new UsersPopUpViewStyle(this));
  }

  /* RENDERING */
  render() {
    return (
      <View style={{ minWidth: 600, minHeight: 850, width: Constants.PopUpWidth, maxWidth: "100%" ,}}>

        {/* Popup information/confirmation */}
        {this.state.showPopup &&
        <ConfirmGenericPopUpView
          titleButtonBack={'generic.no'}
          titleButtonAccepted={'generic.yes'}
          title={this.state.titlePopup}
          content={this.state.contentPopup}
          confirmationPopup={true}
          handleOnPressAccepted={this.binding.handleClick}
          handleOnPressBack={this.binding.handleBackPopupPressed}
        />
        }

        <PopupHeaderView title={'users.popup.title'} isLoading={this.state.isLoading} shouldShowButton handleOnPressButton={this.binding.handleSavePressed} handleBackPressed={Helpers.closePopup}>
          <View style={{ display: "flex", flexDirection: "row", alignItems: 'center' }}>
                <TranslationView style={{ marginRight: 5, color: 'white' }}>users.popup.banned</TranslationView>
                <TouchableWithoutFeedback onPress={() => this.binding.handleBanToggled()}>
                  <View style={{ marginRight: 20 }}>
                    <SwitchView enabledColor='#77e189' enabled={this.state.user.isBanned} />
                  </View>
                </TouchableWithoutFeedback>
              </View>
        </PopupHeaderView>
        

        {/*Container*/}
        <MainInformationView
          user={this.state.user}
          isLoadingAdvancedInformations={this.state.isAdvancedInformationsLoading}
          showInternalPopup={this.binding.showInternalPopup}
          refreshMainInfo={this.binding.refreshMainInfo}
          handleCheckboxChange={this.binding._dispatcherManager.handleCheckboxChange}
          handleValueChange={this.binding._dispatcherManager.handleValueChange}
          saveUser={this.props.saveUser} 
          refresh={this.props.refresh}
        />
      </View>
    );
  }
}
