import {ContentViewModel,ContentView} from '@levelapp/softfabric';
import StoreSummaryViewState from './StoreSummaryViewState';
import StoreSummaryViewProps from './StoreSummaryViewProps';
import StoresOperations from '../../../../../../../Transfer/StoresOperations';
import ErrorHelper from '../../../../../../../Common/Helpers/ErrorHelper';
import { OrderSources } from '../../../../../../../Common/Enums/OrderSources';
import StoreSummaryPopUpView from '../storesummarypopup/StoreSummaryPopUpView';


export default class StoreSummaryViewModel extends ContentViewModel<StoreSummaryViewState, StoreSummaryViewProps>
{
    _storesOperation: StoresOperations;
    searchText: string;
    interval: any;
    
      /* CONSTRUCTOR */
    constructor(view: ContentView)
    {
        super(view);
        this.searchText = '';
        this.interval = null;

        this.initialState({
            isLoading : false,
            stores : [],
            nbPage: 0,
            currentPage: 1,
            isResearching: false
        }); // Initialize your state here

        this._storesOperation = new StoresOperations();

        // Bindings
        this.getOrderSourceName = this.getOrderSourceName.bind(this);
        this.handleResearching = this.handleResearching.bind(this);
        this.handleCloseResearching = this.handleCloseResearching.bind(this);
        this.onPageChange = this.onPageChange.bind(this);
        this.showStoreSumPopUp = this.showStoreSumPopUp.bind(this)

    }

    componentDidMount()
    {
        this.refresh();
    }
    
    showStoreSumPopUp(store : any){
        this.showPopUp(
            <StoreSummaryPopUpView  
            store={store} />
        )
    }
    refresh()
    {
        this.setField({ isLoading: true });
        let parameters = [];
        parameters.push({ key: "page", value: this.state().currentPage }, { key: "itemPage", value: 20 });
        if (this.state().isResearching)
        {
            parameters.push({ key: "search", value: this.searchText });
        }
        parameters.push(
            { key: 'columnSort', value: "Slug"},
            { key: 'sortOrder', value: "asc" });
        this.getData(parameters);
    }

    getData(queryParameter: any[])
    {
        this._storesOperation.get(queryParameter, (stores: any) =>
        {
            this.setField({ stores : stores.data.objects,nbPage: stores.data.totalPage, isLoading: false });
        },this.errorManagement);
    
    }

    errorManagement(error : any) 
    {
        this.setField({isLoading : false});
        ErrorHelper.showMessageError(error.response);
    }

    getOrderSourceName(index : number) : string{
        let orderSourceName : string = "";
        switch(index){
            case OrderSources.APP : 
                orderSourceName = 'APP';
                break;
            case OrderSources.KIOSK : 
                orderSourceName = 'KIOSK';
                break;
            case OrderSources.DELIVERY : 
                orderSourceName = 'DELIVERY'
                break;
            default:
                orderSourceName = 'Unknown';
        }
        return orderSourceName;
    }

    handleResearching(searchText: string)
    {
        this.setField({ isLoading: true, isResearching: true });
        this.searchText = searchText;

        if (this.interval)
        {
            clearInterval(this.interval);
        }
        this.interval = setTimeout(() =>
        {
            this.onPageChange(0)
        }, 1000)
    }

    handleCloseResearching()
    {
        this.state().isResearching = false;
        this.onPageChange(0);
    }
    onPageChange(page: number)
    {
        this.state().currentPage = page + 1;
        this.refresh()
    }
    
}