 
import { View } from 'react-native';
import {ContentView} from '@levelapp/softfabric';
import MainInformationViewState from './MainInformationViewState';
import MainInformationViewProps from './MainInformationViewProps';
import MainInformationViewStyle from './MainInformationViewStyle';
import MainInformationViewModel from './MainInformationViewModel';
import ExtendedTextInputView from '../../../../../../components/extendedtextinput/ExtendedTextInputView';

export default class MainInformationView extends ContentView<MainInformationViewProps, MainInformationViewState, MainInformationViewModel, MainInformationViewStyle>
{
  /* CONSTRUCTOR */
  constructor(props:MainInformationViewProps)
  {
    super(props);

    // Binding
    this.bind(new MainInformationViewModel(this), new MainInformationViewStyle(this));
  }

  /* RENDERING */
  render()
  {

    const { block } = this.style;

    return (
      <View style={{ display: "flex", flexDirection: "column", paddingHorizontal: 170, paddingVertical: 50 }}>    
            <View style={block}>
              <ExtendedTextInputView
                showError={this.props.showError && this.props.role.name == "" }
                title={"form.name"}
                disable={this.props.role != undefined && this.props.role.id != undefined ? true : false}
                value={this.props.role.name}
                onChangeText={(text : string) => this.props.handleChangeText('name',text , 0)}
              />
            </View>
      </View>
    );
  }
}