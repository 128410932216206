import {ContentViewModel, ContentView} from '@levelapp/softfabric';
import ButtonImportFileWebViewState from './ButtonImportFileWebViewState';
import ButtonImportFileWebViewProps from './ButtonImportFileWebViewProps';
import { createRef } from 'react';

export default class ButtonImportFileWebViewModel extends ContentViewModel<ButtonImportFileWebViewState, ButtonImportFileWebViewProps>
{
    constructor(view: ContentView)
    {
        super(view);

        // State init
        this.initialState({
            refInput: createRef(),
        });

        // Bindings
        this.handleClick = this.handleClick.bind(this);
    }


     /* HANDLERS */
     handleClick()
     {
        document.getElementById('FileUploader')?.click();
         
     }

     handleFileChanged(file: any)
     {
        this.props().action(file);
     }
 

}

