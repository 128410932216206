import {ContentViewStyle} from '@levelapp/softfabric';
import {StyleProp,ViewStyle, TextStyle} from 'react-native';

export default class ItemManagementViewStyle extends ContentViewStyle
{
    content: StyleProp<ViewStyle> = 
    { 
        flexDirection: 'row', 
        marginBottom: 10 
    }

    inputTitle: StyleProp<TextStyle> = {
        fontSize: 14,
        fontWeight: '600',
        color: '#3F3F3F',
        fontFamily: 'Montserrat SemiBold'
    };

    deleteButton: StyleProp<ViewStyle> = {
        marginLeft: 15, 
        alignSelf: 'center',
        alignItems: 'center'
    }
}