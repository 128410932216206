import { ContentViewModel, ContentView } from '@levelapp/softfabric';
import ExtendedTranslationViewState from './ExtendedTranslationViewState';
import ExtendedTranslationViewProps from './ExtendedTranslationViewProps';
import { LayoutChangeEvent } from 'react-native';

export default class ExtendedTranslationViewModel extends ContentViewModel<ExtendedTranslationViewState, ExtendedTranslationViewProps>
{
    /* CONSTRUCTOR */
    constructor(view: ContentView)
    {
        super(view);

        this.initialState({
            titleWidth: 0,
            infoPopupVisible: false
        });

        // Bindings
        this.setTitleWidth = this.setTitleWidth.bind(this);
    }

    setTitleWidth(event: LayoutChangeEvent)
    {
        this.setField({ titleWidth: event.nativeEvent.layout.width });
    }
}