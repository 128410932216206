 
import {ContentView} from '@levelapp/softfabric';
import ExtendedDropDownViewState from './ExtendedDropDownViewState';
import ExtendedDropDownViewProps from './ExtendedDropDownViewProps';
import ExtendedDropDownViewStyle from './ExtendedDropDownViewStyle';
import ExtendedDropDownViewModel from './ExtendedDropDownViewModel';
import { Text, View } from 'react-native';
import DropDownView from '../../../../../Common/Components/Dropdown/DropDownView';
import { TranslationView } from '@levelapp/softfabric-ui';

export default class ExtendedDropDownView extends ContentView<ExtendedDropDownViewProps, ExtendedDropDownViewState, ExtendedDropDownViewModel, ExtendedDropDownViewStyle>
{
  /* CONSTRUCTOR */
  constructor(props:ExtendedDropDownViewProps)
  {
    super(props);

    // Binding
    this.bind(new ExtendedDropDownViewModel(this), new ExtendedDropDownViewStyle(this));
  }

  /* RENDERING */
  render()
  {
    const { inputContainer , inputTitle }= this.style; 

    return (
      <View style={inputContainer}>
        {this.props.title != '' && this.props.title != undefined &&<Text style={inputTitle}>
           <TranslationView  upperCase>
            {this.props.title}
          </TranslationView>
          {this.props.extraTitle}
        </Text>    }    
        <DropDownView
          values={this.binding.getValues()}
          selectedValue={this.props.selectedValue}
          onValueChange={(value : any) => {this.props.onValueChange(value)}} />
      </View>
    );
  }
}