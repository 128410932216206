 
import {ContentView} from '@levelapp/softfabric';
import SwitchViewState from './SwitchViewState';
import SwitchViewProps from './SwitchViewProps';
import SwitchViewStyle from './SwitchViewStyle';
import SwitchViewModel from './SwitchViewModel';
import { View } from 'react-native';
import Role from '../../../../../../../../../../../Common/DTO/Role';
import SwitchItemView from '../switchitem/SwitchItemView';

export default class SwitchView extends ContentView<SwitchViewProps, SwitchViewState, SwitchViewModel, SwitchViewStyle>
{
  /* CONSTRUCTOR */
  constructor(props:SwitchViewProps)
  {
    super(props);

    // Binding
    this.bind(new SwitchViewModel(this), new SwitchViewStyle(this));
  }

  /* RENDERING */
  render()
  {
    return (
      <View style={{flexDirection : 'row'}}>
        {this.props.allRoles.map((value : Role , index : number , array : Role[] )=>
          {
            return (<SwitchItemView handleOnPress={() => this.binding.changeRole(value)} active={this.props.selectedRole != undefined ? value.id == this.props.selectedRole.id : false} title={value.name} shouldRadiusLeft={index == 0} shouldRadiusRight={index == (array.length-1)}/>)
          })
        }
      </View>
    );
  }
}