 
import { ContentView } from '@levelapp/softfabric';
import SwitchViewState from './SwitchViewState';
import SwitchViewProps from './SwitchViewProps';
import SwitchViewStyle from './SwitchViewStyle';
import SwitchViewModel from './SwitchViewModel';
import { View, Animated, Text } from 'react-native';

export default class SwitchView extends ContentView<SwitchViewProps, SwitchViewState, SwitchViewModel, SwitchViewStyle>
{
  /* CONSTRUCTOR */
  constructor(props: SwitchViewProps)
  {
    super(props);

    // Binding
    this.bind(new SwitchViewModel(this), new SwitchViewStyle(this));
  }

  /* RENDERING */
  render()
  {
    return (
      <View style={this.style.container}>
        <Animated.View style={[this.style.toggle, { transform: [{ translateY: -1 }, { translateX: this.binding.toggleTransX }] }]} />
        <Animated.View style={[this.style.enabledBg, { backgroundColor: this.props.enabledColor, justifyContent: 'center', alignItems: 'flex-end', paddingRight: 7, transform: [{ translateY: -1 }, { translateX: this.binding.enabledTransX }] }]}>
          <Text style={{ color: 'white', fontSize: 11 }}>ON</Text>
        </Animated.View>
        <Animated.View style={[this.style.disabedBg, { paddingLeft: 7, justifyContent: 'center', transform: [{ translateY: -1 }, { translateX: this.binding.disabledTransX }] }]}>
          <Text style={{ fontSize: 11 }}>OFF</Text>
        </Animated.View>
      </View>
    );
  }
}