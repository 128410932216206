import { ContentViewModel, ContentView } from '@levelapp/softfabric';
import LegalTextsPopUpViewState from './LegalTextsPopUpViewState';
import LegalTextsPopUpViewProps from './LegalTextsPopUpViewProps';
import DispatcherManager from '../../../../../../../Common/Managers/DispatcherManager';
import TranslationHelper from '../../../../../../../Common/Helpers/TranslationHelper';

export default class LegalTextsPopUpViewModel extends ContentViewModel<LegalTextsPopUpViewState, LegalTextsPopUpViewProps>
{
    _dispatcherManager : DispatcherManager;

    constructor(props: ContentView)
    {
        super(props);

        /* Initial State */
        this.initialState({
            text: this.props().text,
            languages: this.props().languages,
            isSaving: false,
        });

        /* BINDINGS */
        this.handleSavePressed = this.handleSavePressed.bind(this);
        this.handleTextChanged = this.handleTextChanged.bind(this);
        this.handleCodeChanged = this.handleCodeChanged.bind(this);

        this._dispatcherManager = new DispatcherManager();
    }

    componentDidMount()
    {
        this._dispatcherManager.valueCode = this.handleCodeChanged;

        this._dispatcherManager.translationTitle = this.handleTextChanged;
    }

    handleSavePressed()
    {
        this.setField((previousState) => previousState.isSaving = true);
        this.props().save(this.props().text);
    }

    /* TRANSLATIONS */

    private handleTextChanged(text: any, index: number)
    {
        const state = this.state();
        // var language = state.languages[index];
        // var translation = state.text.contentTranslations.find((x: any) => x.language.key == language);
        TranslationHelper.translation(state.text.contentTranslations,state.languages,index,text);
        // if(translation == undefined)
        // {
        //     var translationValue : TranslationValue =
        //     {
        //         language : 
        //         {
        //             key : language.key,
        //         },
        //         value : text
        //     }
        //     state.text.contentTranslations.push(translationValue);
        // }else
        // {
        //     translation.value = text;
        // }
        this.setField({state});
    }

    private handleCodeChanged(code: any)
    {
        this.setField((previousState) => previousState.text.key = code);
    }
}