import UserInfosService from '../Proxy/UserInfosService';
import { Operation } from '@levelapp/softfabric';

export default class UserInfosOperations extends Operation
{
    _service : UserInfosService;

    constructor()
    {
        super();
        this._service = new UserInfosService();
    }

    async get(callback: Function = () => { } , onFailure : Function = () => {})
    {
        await (this._service as UserInfosService).get(callback, onFailure);
    }
}