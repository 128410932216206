 
import { ContentView } from '@levelapp/softfabric';
import MapRenderWebViewState from './MapRenderWebViewState';
import MapRenderWebViewProps from './MapRenderWebViewProps';
import MapRenderWebViewStyle from './MapRenderWebViewStyle';
import MapRenderWebViewModel from './MapRenderWebViewModel';
import { GoogleMap, withGoogleMap, Marker, withScriptjs } from "react-google-maps"


export default class MapRenderWebView extends ContentView<MapRenderWebViewProps, MapRenderWebViewState, MapRenderWebViewModel, MapRenderWebViewStyle>
{
  MyMapComponent :any;
  /* CONSTRUCTOR */
  constructor(props: MapRenderWebViewProps) {
    super(props);

    // Binding
    this.bind(new MapRenderWebViewModel(this), new MapRenderWebViewStyle(this));
  }

  /* RENDERING */
  render() {
    var maps: any;
    var i = 0;
    if(this.binding.isTheFirstTimePass)
    {
      this.binding.isTheFirstTimePass = true;
      if(this.binding.lastLatitude == this.props.markers[0].pos.lat && this.binding.lastLongitude == this.props.markers[0].pos.lng)
      {
        return <this.MyMapComponent
          isMarkerShown
          googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyCmvA5Vgm437K9Vzb7G9PJX_gnnhpzvVRU"
          loadingElement={<div style={{ height: `100%` }} />}
          containerElement={<div style={{ flex: 1 }} />}
          mapElement={<div style={{ height: `100%` }} />}
        />;
      
      
      }
    }
    
    this.MyMapComponent = withScriptjs(withGoogleMap((props: any) =>
      <GoogleMap
        ref={ref => {
          if (ref) {
            maps = ref;
          }
        }}
        onIdle={() => {
          if (!this.props.oneMarker) {
            i++;
            if (i == 1) {
              var bounds = maps.getBounds();
              this.props.markers.map((marker) => {
                if (bounds.contains({ lat: marker.pos.lat, lng: marker.pos.lng })) {
                } else {
                  bounds.extend({ lat: marker.pos.lat, lng: marker.pos.lng });
                }
              });
              maps.fitBounds(bounds);
            }
          }
        }
        }
        defaultZoom={8}
        defaultCenter={{ lat: this.props.defaultCenter.latitude, lng: this.props.defaultCenter.longitude }}
        options={{ disableDefaultUI: this.props.disableDefaultUI }}
      >
        {props.isMarkerShown && 
          this.props.markers.map((marker) => {
            return (<Marker position={{ lat: marker.pos.lat == undefined ? 0 : marker.pos.lat , lng: marker.pos.lng == undefined ? 0 : marker.pos.lng}} onClick={() => marker.editCallback()} />)
          })
        }
      </GoogleMap>
    ))

    this.binding.lastLatitude = this.props.markers[0].pos.lat;
    this.binding.lastLongitude = this.props.markers[0].pos.lng;
    this.binding.isTheFirstTimePass = true;
    return (
      <this.MyMapComponent
        isMarkerShown
        googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyCmvA5Vgm437K9Vzb7G9PJX_gnnhpzvVRU"
        loadingElement={<div style={{ height: `100%` }} />}
        containerElement={<div style={{ flex: 1 }} />}
        mapElement={<div style={{ height: `100%` }} />}
      />
    );
  }
}