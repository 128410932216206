import { ContentViewModel, ContentView } from '@levelapp/softfabric';
import ActivationCodeViewState from './ActivationCodeViewState';
import ActivationCodeViewProps from './ActivationCodeViewProps';

//import ErrorHelper from '../../../../../../Common/Helpers/ErrorHelper';
import moment from 'moment';
import PointEarningActivationCodeOperations from 'src/Transfer/PointEarningActivationCodeOperations';
import ToastHelper from 'src/Common/Helpers/ToastHelper';

export default class ActivationCodeViewModel extends ContentViewModel<ActivationCodeViewState, ActivationCodeViewProps>{

 
    _operation: PointEarningActivationCodeOperations;

    constructor(props: ContentView) {
        super(props);

        this.onError = this.onError.bind(this);
        this.handleValuePointsChange = this.handleValuePointsChange.bind(this);
        this.handleValueLimitChange = this.handleValueLimitChange.bind(this); 
        this.handleValueDateChange = this.handleValueDateChange.bind(this); 
        this.handleGenerate = this.handleGenerate.bind(this); 
        this.handleCodeChange = this.handleCodeChange.bind(this);
        this._operation = new PointEarningActivationCodeOperations();

        this.initialState({
            payload: {
                code: "",
                points: 5,
                limit: 1,
                validityEndDate: moment().toDate(),
            },
            isLoading: false,
        });
    }

    componentDidMount() {

    }
    
    handleValuePointsChange(value: string) {
        const parsed = parseInt(value);
        const isValid = !Number.isNaN(parsed) && parsed >0;

        const newState = this.state();
        newState.payload.points =isValid?  parsed: 1;
        this.setField({payload: newState.payload});
    }


    handleValueLimitChange(value: string) {
        const parsed = parseInt(value);
        const isValid = !Number.isNaN(parsed) && parsed >= 0;

        const newState = this.state();
        newState.payload.limit =isValid?  parsed: 0;
        this.setField({payload: newState.payload});
    }

    handleCodeChange(value: string) {
        const newState = this.state();
        newState.payload.code = value;
        this.setField({payload: newState.payload});
    }

    handleValueDateChange(value: Date) {
        const isValid = moment(value).isSameOrAfter(moment());

        const newState = this.state();
        newState.payload.validityEndDate = isValid?  value: moment().toDate();
        this.setField({payload: newState.payload});
    }

    handleGenerate(){
        this.setField({isLoading : true});
        this._operation.post(this.state().payload,(data: string)=> this.onSuccess(data), this.onError); 
    }

    onSuccess(activationCode: string){
        this.handleCodeChange(activationCode);
        this.setField({isLoading : false});
        this.showToast(ToastHelper.getToast("activationCode.popup.success","success"),() => {},2500);
    }

    onError(_ : any) 
    {
        this.setField({isLoading : false});
        //ErrorHelper.showMessageError(error.response);
        this.showToast(ToastHelper.getToast("activationCode.popup.error","error"),() => {},2500);
    }
}