import {ContentViewModel,ContentView} from '@levelapp/softfabric';
import PopupStoresListViewState from './PopupStoresListViewState';
import PopupStoresListViewProps from './PopupStoresListViewProps';
import StoresOperations from '../../../../../Transfer/StoresOperations';
import Store from '../../../../../Common/DTO/Store';
import ErrorHelper from '../../../../../Common/Helpers/ErrorHelper';

export default class PopupStoresListViewModel extends ContentViewModel<PopupStoresListViewState, PopupStoresListViewProps>
{

    _storeOperations: StoresOperations;
      /* CONSTRUCTOR */
    constructor(view: ContentView)
    {
        
        
        super(view);

        this.initialState({
            isLoading : false,
            searchTxt : "",
            orderedStoreList : []
        }); // Initialize your state here

        this._storeOperations = new StoresOperations();

       




        // Bindings
        this.selectStore = this.selectStore.bind(this);
        this.handleSavePressed = this.handleSavePressed.bind(this);
        this.getSelectedStore = this.getSelectedStore.bind(this);
        this.getOrderedStores = this.getOrderedStores.bind(this);

    }



    componentDidMount(){
        this.setField({orderedStoreList : this.props().stores})
    }

    getSelectedStore() : string {
        let storeName = "";
        if(this.props().displayNameStore === "title"){
            storeName = this.state().selectedStore?.title!;
        }else{
            storeName = this.state().selectedStore?.slug!;
        }
        return storeName
    }

    getOrderedStores() : Store []{
        let storeList : Store[] = [];
        if(this.props().displayNameStore === "title"){
            storeList =this.state().orderedStoreList.sort((a,b) => a.title.localeCompare(b.title)).filter((store : Store)=>store.title.toLowerCase().includes(this.state().searchTxt.toLowerCase()))
        }else{
            storeList = this.state().orderedStoreList.sort((a,b) => a.slug.localeCompare(b.slug)).filter((store : Store)=>store.slug.toLowerCase().includes(this.state().searchTxt.toLowerCase()))
        }
        return storeList
    }

    selectStore(store : Store){
        this.setField((previousState)=> previousState.selectedStore = store)
    }

    handleSavePressed(){
        this.closePopUp();
        this.props().callback(this.state().selectedStore)
    }

    handleResearching(searchText : string){
        this.setField((previousState)=> previousState.searchTxt = searchText)
    }
    errorManagement(error: any) 
    {
        this.setField({ isLoading: false });
        ErrorHelper.showMessageError(error.response);
    }
}