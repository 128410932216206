 
import { ContentView } from '@levelapp/softfabric';
import LegalTextsViewState from './LegalTextsViewState';
import LegalTextsViewProps from './LegalTextsViewProps';
import LegalTextsViewStyle from './LegalTextsViewStyle';
import LegalTextsViewModel from './LegalTextsViewModel';
import { View } from 'react-native';
import TableView from '../../../../../../../Common/Components/TabelView/TableView';
import ResearchInputView from '../../../../../../../Common/Components/researchinput/ResearchInputView';
import HeaderView from '../../../../components/header/HeaderView';

export default class LegalTextsView extends ContentView<LegalTextsViewProps, LegalTextsViewState, LegalTextsViewModel, LegalTextsViewStyle>
{
  /* CONSTRUCTOR */
  constructor(props: LegalTextsViewProps) {
    super(props);

    // Binding
    this.bind(new LegalTextsViewModel(this), new LegalTextsViewStyle(this));
  }

  /* RENDERING */
  render() {
    return (
      <View style={this.style.container}>
        <View style={this.style.box}>

          <HeaderView
            title={"legaltexts.title"}
            description={"legaltexts.description"}
            shouldShowButton={true}
            onButtonPress={this.binding.addField}
            buttonText={'legaltexts.button.add'}
          />

          <View style={this.style.boxContainer}>
            <ResearchInputView
              placeholder={this.binding.translate("generic.search")}
              handlerResearching={(searchText: string) => { this.binding.handleResearching(searchText) }}
              handlingClose={() => { this.binding.handleCloseResearching() }} />
            <TableView
              headerTextStyle={this.style.tableHeader}
              backgroundStepColor={this.style.stepColor}
              nothingToDisplayText={this.binding.translate("component.table.noresults")}
              isLastColumnActions={true}
              data={this.state.tableData}
              isLoading={this.state.isLoading}
              headers={this.state.tableHeaders}
              onPageChange={(page: number) => this.binding.onPageChange(page)}
              handleSortChange={(indexHeaderSort : number, sortOrder : string) => {this.binding.onSortChange(indexHeaderSort, sortOrder)}}
              nbPage={this.state.nbPage}
              currentPage={this.state.currentPage - 1}
              isSortable={true}/>
          </View>

        </View>
      </View>
    );
  }
}