import {ContentViewStyle} from '@levelapp/softfabric';
import {StyleProp,ViewStyle, TextStyle} from 'react-native';

export default class RoleViewStyle extends ContentViewStyle
{
    page: StyleProp<ViewStyle> = {
        flex: 1
    }


    block : StyleProp<ViewStyle> =
    {
        zIndex: -1, 
        display: 'flex', 
        flexWrap: 'wrap', 
        justifyContent: 'space-between'
    }

    header : StyleProp<ViewStyle> = { 
        flex: 1, 
        maxHeight: 225, 
        flexWrap: "wrap" 
    }

    inputTitle: StyleProp<TextStyle> = {
        fontSize: 14,
        fontWeight: '600',
        color: '#3F3F3F',
        fontFamily: 'Montserrat SemiBold',
        marginBottom: 13,
        paddingRight : 15,
    };

    subheading: StyleProp<any> = {
        fontSize: 19,
        marginBottom: 20,
    };
}