import ProductOrderSourceAvailability from 'src/Common/DTO/ProductOrderSourceAvailability';

import { Service, Application, HttpManager, Managers } from '@levelapp/softfabric';

export default class ProductOrderSourcesAvailabilitiesService extends Service {
  httpClient = Application.current.resolve<HttpManager>(Managers.HTTP_MANAGER);

  async get(productId: number): Promise<ProductOrderSourceAvailability[]> {
    const response = await this.httpClient.get(`ProductOrderSourceAvailability/products/${productId}`);

    if (response && response.data) {
      return response.data.data;
    }

    return [];
  }

  async post(prices: ProductOrderSourceAvailability[], categoryId?: number): Promise<ProductOrderSourceAvailability[]> {
    const response = await this.httpClient
      .post(`ProductOrderSourceAvailability${categoryId ? `?categoryId=${categoryId}` : ''}`, [], prices);

    if (response && response.data) {
      return response.data.data;
    }

    return Promise.reject();
  }

  async delete(prices: ProductOrderSourceAvailability[]): Promise<boolean> {
    const success = await this.httpClient.post('ProductOrderSourceAvailability/delete', [], prices);

    if (success) {
      return true;
    }

    return false;
  }


  async deleteByProductGroupId(id: number): Promise<boolean> {
    const success = await this.httpClient.delete(`ProductOrderSourceAvailability/${id}`);

    if (success) {
      return true;
    }

    return false;
  }
}