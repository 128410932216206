import { Service, Application, HttpManager, Managers } from '@levelapp/softfabric';


export default class DeliveryProvidersService extends Service
{
    httpClient = Application.current.resolve<HttpManager>(Managers.HTTP_MANAGER);

    async get(onSuccess: Function)
    {       
        this.httpClient
        .get('deliveryproviders', [])
        .then((response : any) => {
            if (response)
            {
                if (response.data)
                {                  
                    onSuccess(response.data);
                }
            }
        });
    }

    post(deliveryProvider:any, callback : Function = () => {})
    {
        this.httpClient
            .post('deliveryproviders', [], deliveryProvider)
            .then((response : any) => 
            {
                if (response)
                {
                    /*if (response.data)
                    {
                        callback(response.data);
                    }*/
                    callback("");
                }
            });
    }

    put(deliveryProvider:any, callback : Function = () => {})
    {
        this.httpClient
            .put('deliveryproviders', [], deliveryProvider)
            .then((response : any) => 
            {
                if (response)
                {
                    /*if (response.data)
                    {
                        callback(response.data);
                    }*/
                    callback("");
                }
            });
    }

    delete(deliveryProvider:any, callback : Function = () => {})
    {
        this.httpClient
            .delete('deliveryproviders/delete/'+deliveryProvider.id)
            .then((response : any) => 
            {
                if (response)
                {
                     /*if (response.data)
                    {
                        callback(response.data);
                    }*/
                    callback("");
                }
            });
    }

    getStoresUsingProvider(provider: any, onSuccess: Function = () => { }) {
        this.httpClient
            .get('deliveryproviders/getStoresUsingProvider/' + provider.id, [])
            .then((response: any) => {
                if (response) {
                    if (response.data) {
                        onSuccess(response.data);
                    }
                }
            });
    }
}