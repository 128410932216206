 
import { ContentView } from '@levelapp/softfabric';
import PicturesSavingCardsViewState from './PicturesSavingCardsViewState';
import PicturesSavingCardsViewProps from './PicturesSavingCardsViewProps';
import PicturesSavingCardsViewStyle from './PicturesSavingCardsViewStyle';
import PicturesSavingCardsViewModel from './PicturesSavingCardsViewModel';
import { View } from 'react-native';
import ExtendedImageView from '../../../../../../components/extendedimage/ExtendedImageView';
import Language from '../../../../../../../../../Common/DTO/Language';
import Constants from '../../../../../../../../../Common/Constants/Constants';

export default class PicturesSavingCardsView extends ContentView<PicturesSavingCardsViewProps, PicturesSavingCardsViewState, PicturesSavingCardsViewModel, PicturesSavingCardsViewStyle>
{
  /* CONSTRUCTOR */
  constructor(props: PicturesSavingCardsViewProps) {
    super(props);

    // Binding
    this.bind(new PicturesSavingCardsViewModel(this), new PicturesSavingCardsViewStyle(this));
  }

  /* RENDERING */
  render() {
    const { container, block } = this.style

    return (
      <View style={container}>
        <View style={{ display: "flex", flexDirection: "row", flexWrap: "wrap" }}>
          <View style={[block, { zIndex: 4 }]}>
            {/* Images */}
            {this.props.languages.map((content: Language, j: any) => {
              return (
                <ExtendedImageView
                  title={'form.picture'}
                  index={j}
                  id={content.id as number}
                  source={this.props.listImage[j].url}
                  shouldUseDefaultImage={this.props.shouldUseDefaultImage}
                  value={content.key}
                  handleCheckboxChange={(value: boolean) => this.props.handleCheckboxChange('isDefaultImage', value)}
                  handleOnComplete={(base64 : any) => this.props.handleOnComplete('image', base64)}
                  handleOnPressImage={(index: number) => this.props.handleOnPressImage('image', index)}
                />
              );
            })}
          </View>
          <View style={[block, { zIndex: 3 }]}>
            {/* Thumbnails */}
            {this.props.languages.map((content: Language, j: any) => {
              return (
                <ExtendedImageView
                  title={'form.thumbnail'}
                  index={j}
                  id={content.id as number}
                  source={this.props.listThumbnail[j].url}
                  shouldUseDefaultImage={this.props.shouldUseDefaultThumnail}
                  value={content.key}
                  handleCheckboxChange={(value: boolean) => this.props.handleCheckboxChange('isDefaultThumbnail', value)}
                  handleOnComplete={(base64 : any) => this.props.handleOnComplete('thumbnail', base64)}
                  handleOnPressImage={(index: number) => this.props.handleOnPressImage('thumbnail', index)}
                />

              );
            })}
          </View>
          {!Constants.IsSavingCardsUsedAsBadge &&
            <View style={[block, { zIndex: 2 }]}>
              {/* ImageStamped */}
              {this.props.languages.map((content: Language, j: any) => {
                return (
                  <ExtendedImageView
                    title={'form.stampedimage'}
                    index={j}
                    id={content.id as number}
                    source={this.props.listImageStamped[j].url}
                    shouldUseDefaultImage={this.props.shouldUseDefaultImageStamped}
                    value={content.key}
                    handleCheckboxChange={(value: boolean) => this.props.handleCheckboxChange('isDefaultStamped', value)}
                    handleOnComplete={(base64 : any) => this.props.handleOnComplete('stamped', base64)}
                    handleOnPressImage={(index: number) => this.props.handleOnPressImage('stamped', index)}
                  />
                );
              })}
            </View>
          }
          {!Constants.IsSavingCardsUsedAsBadge &&
            <View style={[block, { zIndex: 1 }]}>
              {/* ImageUnstamped */}
              {this.props.languages.map((content: Language, j: any) => {
                return (
                  <ExtendedImageView
                    title={'form.unstampedimage'}
                    index={j}
                    id={content.id as number}
                    source={this.props.listImageUnstamped[j].url}
                    shouldUseDefaultImage={this.props.shouldUseDefaultImageUnstamped}
                    value={content.key}
                    handleCheckboxChange={(value: boolean) => this.props.handleCheckboxChange('isDefaultUnstamped', value)}
                    handleOnComplete={(base64 : any) => this.props.handleOnComplete('unstamped', base64)}
                    handleOnPressImage={(index: number) => this.props.handleOnPressImage('unstamped', index)}
                  />
                );
              })}
            </View>
          }
        </View>
      </View>
    );
  }
}