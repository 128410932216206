import {ContentViewModel,ContentView} from '@levelapp/softfabric';
import FilterLogViewState from './FilterLogViewState';
import FilterLogViewProps from './FilterLogViewProps';

export default class FilterLogViewModel extends ContentViewModel<FilterLogViewState, FilterLogViewProps>
{
      /* CONSTRUCTOR */
    constructor(view: ContentView)
    {
        super(view);
        this.initialState(
            {
                showDetails : false,
                dataToSelect : this.props().dataToSelect

            }); // Initialize your state here

        // Bindings

    }

    showDetails(value : boolean)
    {
        this.setField({showDetails : value})
    }

    

    changeValue(value : any , bool : boolean)
    {
        var props = this.props();
        var resp = props.dataToSelect.find(x => x.id == value.id);
        if(resp)
        {
            resp.selected = bool;
        }
        this.props().changeValue(this.props().dataToSelect,this.props().type);
    }
}