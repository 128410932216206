 
import { ContentShellViewModel, ContentShellView } from '@levelapp/softfabric';

import EnumModules from '../../../../../Common/Constants/EnumModules';

import ShellZonesViewState from './ShellZonesViewState';
import ShellZonesViewProps from './ShellZonesViewProps';
import ZonesView from './pages/zones/ZonesView';

export default class ShellZonesViewModel extends ContentShellViewModel<ShellZonesViewState, ShellZonesViewProps>
{

  constructor(view: ContentShellView) {
    super(view);

    this.registerEntryPoint('main');
    this.register(<ZonesView />, 'main');

    this.initialisation();
  }

  getModuleId() {
    return EnumModules.ZONES_TOOLS;
  }
}