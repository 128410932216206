 
import {ContentShellView} from '@levelapp/softfabric';
import ShellVouchersViewState from './ShellVouchersViewState';
import ShellVouchersViewProps from './ShellVouchersViewProps';
import ShellVouchersViewStyle from './ShellVouchersViewStyle';
import ShellVouchersViewModel from './ShellVouchersViewModel';
import {View} from 'react-native';

export default class ShellVouchersView extends ContentShellView<ShellVouchersViewProps, ShellVouchersViewState, ShellVouchersViewModel, ShellVouchersViewStyle>
{
  /* CONSTRUCTOR */
  constructor(props:ShellVouchersViewProps)
  {
    super(props);

    // Binding
    this.bind(new ShellVouchersViewModel(this), new ShellVouchersViewStyle(this));
  }

  /* RENDERING */
  render()
  {
    return (
      <View style={{flex : 1}}>
        {this.getSwitch()}
      </View>
    );
  }
}