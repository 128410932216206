import { Service, Application, HttpManager, Managers } from "@levelapp/softfabric";
import Product from "../Common/DTO/Product";

export default class BoosterTypesService extends Service{

    httpClient = Application.current.resolve<HttpManager>(Managers.HTTP_MANAGER);

    async getLight(): Promise<Product[]> {
      const response = await this.httpClient.get('product/light');
  
      if (response && response.data) {
        return response.data.data;
      }
  
      return [];
    }

    async get(onSuccess: Function, onFailure : Function = () => {}){
        this.httpClient.handleSomethingWentWrong = onFailure;
        this.httpClient.handleNotFound = onFailure;

        this.httpClient
        .get('product')
        .then((response : any) => {
            if (response)
            {
                if (response.data)
                {      
                    onSuccess(response.data.data);
                }
            }
        });
    }

    async getProductsBasicInfo(parameters:any[],onSuccess: Function, onFailure : Function = () => {}){
        this.httpClient.handleSomethingWentWrong = onFailure;
        this.httpClient.handleNotFound = onFailure;

        this.httpClient
        .get('product/GetProductsBasicInfo', parameters)
        .then((response : any) => {
            if (response)
            {
                if (response.data)
                {      
                    onSuccess(response.data.data);
                    
                }
            }
        });
    }

    async getTable(parameters:any[], onSuccess: Function, onFailure : Function = () => {}){
        this.httpClient.handleSomethingWentWrong = onFailure;
        this.httpClient.handleNotFound = onFailure;

        this.httpClient
        .get('product/List',parameters)
        .then((response:any)=>{
            if(response){
                if(response.data){
                    onSuccess(response.data);
                }
            }
        })
    }

    post(product:Product,onSuccess : Function, onFailure : Function = () => {}){
        this.httpClient.handleSomethingWentWrong = onFailure;
        this.httpClient.handleNotFound = onFailure;

        this.httpClient
        .post('product',[],product)
        .then((response:any)=>{
            if(response){
                onSuccess("succes");
            }
        })
    }

    put(product:Product,onSuccess : Function, onFailure : Function = () => {}){
        this.httpClient.handleSomethingWentWrong = onFailure;
        this.httpClient.handleNotFound = onFailure;

        this.httpClient
        .put('product',[],product)
        .then((response:any)=>{
            if(response){
                onSuccess("succes");
            }
        })
    }

    delete(product:Product,onSuccess:Function, onFailure : Function = () => {}){
        this.httpClient.handleSomethingWentWrong = onFailure;
        this.httpClient.handleNotFound = onFailure;

        this.httpClient
        .delete('product/delete/'+product.id)
        .then((response:any)=>{
            if(response)
                onSuccess("succes");
        })
    }
}