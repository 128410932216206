import {Application, ContentViewStyle} from '@levelapp/softfabric';
import { StyleManager, UiManagers } from '@levelapp/softfabric-ui';
import {Dimensions, StyleProp,TextStyle,ViewStyle} from 'react-native';

export default class StoreSummaryViewStyle extends ContentViewStyle
{
    MyStyleManager : StyleManager = Application.current.resolve<StyleManager>(UiManagers.STYLE_MANAGER);
    
    container: StyleProp<ViewStyle> = {
        display: 'flex',
        height : Dimensions.get('window').height,
        alignItems: 'center',
        paddingTop: 40,
        paddingBottom : 10,
    }

    box: StyleProp<ViewStyle> = {
        width: '95%',
        backgroundColor: 'white',
        borderRadius: 6,
        shadowColor: "#0000006b",
        shadowOffset: {
            width: 1,
            height: 0,
        },
        shadowOpacity: 0.70,
        shadowRadius: 20.00,
        elevation: 60,
    }




    boxContainer: StyleProp<ViewStyle> = {
        display: 'flex',
        flex : 1,
        minHeight: 250,
        width: '100%',
        paddingTop: 30,
        paddingBottom: 30,
        paddingLeft: 50,
        paddingRight: 50,
    }
    rowStore: StyleProp<ViewStyle> = {
        display: 'flex',
        flexDirection : 'row',
        justifyContent : 'flex-start',
        flex : 1,
        minHeight: 125,
        width: '100%',
        margin: 10,
        padding : 5,
        borderRadius: 10,
        shadowColor: "#0000006b",
        shadowOffset: {
            width: 1,
            height: 0,
        },
        shadowOpacity: 0.6,
        shadowRadius: 15,
        elevation: 40,

    }
    
    storeName: StyleProp<ViewStyle> = {
        display: 'flex',
        flex : 1,
        justifyContent : 'center',
        alignItems : 'flex-start',
        padding : 10,
        paddingLeft : 20,
        flexDirection : 'column',
    }
    storeNameText: StyleProp<TextStyle> = {
        fontSize: 16,
    }
    orderSourcesListContainer: StyleProp<ViewStyle> = {
        display: 'flex',
        flexDirection : 'column',
        flex : 2,
        padding : 5,
        justifyContent : 'center',
    }
    orderSourceContainer: StyleProp<ViewStyle> = {
        display: 'flex',
        flexDirection : 'row',
        flex : 1,
        alignItems : 'center',
    }
    orderSourceNameContainer: StyleProp<ViewStyle> = {
        display: 'flex',
        flexDirection : 'row',
        flex : 2,
        padding : 5,
        justifyContent : 'center',
        alignItems : 'center',
    }
    orderSourceToggleContainer: StyleProp<ViewStyle> = {
        display: 'flex',
        flexDirection : 'row',
        justifyContent : 'center',
        flex : 3,
        padding : 5,
        alignItems : 'center',
    }

    settings : StyleProp<ViewStyle> = {
        display: 'flex',
        flexDirection : 'row',
        justifyContent : 'flex-end',
        flex : 1,
        padding : 5,
        alignItems : 'center',
    }
    stepColor : StyleProp<ViewStyle> =
    {
        backgroundColor : this.MyStyleManager.primaryColor,
    }
    textTitle : StyleProp<TextStyle> =
    {
        fontWeight : 'bold',
        fontSize : 18
    }
  
 }