import { Operation } from "@levelapp/softfabric";
import CouponTypesService from '../Proxy/CouponTypesService';

export default class CouponTypesOperations extends Operation{
    service : CouponTypesService;

    constructor()
    {
        super();
        this.service = new CouponTypesService();
    }

    async get(callback : Function)
    {
        return await this.service.get(callback);
    }
}