import { Operation } from "@levelapp/softfabric";
import TypesService from "../Proxy/TypesService";

export default class TriggersOperations extends Operation
{
    service : TypesService;

    constructor()
    {
        super();
        this.service = new TypesService();
    }

    get(parameters : any[], callback : Function, onFailure : Function)
    {
        this.service.get(parameters, callback,onFailure);
    }
}