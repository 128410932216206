export enum DeliveryProviders
{
    UBEREATS = 1,
    DELIVEROO = 2,
    JUSTEEAT= 3,
    ClickAndCollect= 4,
    TAKEAWAY = 5
}

export enum DeliveryProviderKeys
{
    UBEREATS = 'UBEREATS',
    DELIVEROO = 'DELIVEROO',
    JUSTEEAT= 'JUSTEAT',
    ClickAndCollect= 'CLICK_AND_COLLECT',
    TAKEAWAY = 'TAKE_AWAY'
}




