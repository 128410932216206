 
import Popup from 'reactjs-popup';
import {
  View,
  Image,
  TouchableOpacity
} from 'react-native';

import { ContentView } from '@levelapp/softfabric';
import { TranslationView } from '@levelapp/softfabric-ui';

import SingleProductPopUpMenuViewState from './SingleProductPopUpMenuViewState';
import SingleProductPopUpMenuViewProps from './SingleProductPopUpMenuViewProps';
import SingleProductPopUpMenuViewStyle from './SingleProductPopUpMenuViewStyle';
import SingleProductPopUpMenuViewModel from './SingleProductPopUpMenuViewModel';

import Assets from 'src/Common/Assets';

export default class SingleProductPopUpMenuView extends ContentView<SingleProductPopUpMenuViewProps, SingleProductPopUpMenuViewState, SingleProductPopUpMenuViewModel, SingleProductPopUpMenuViewStyle>
{
  /* CONSTRUCTOR */
  constructor(props: SingleProductPopUpMenuViewProps) {
    super(props);

    // Binding
    this.bind(new SingleProductPopUpMenuViewModel(this), new SingleProductPopUpMenuViewStyle(this));
  }

  /* RENDERING */
  render() {
    return (
      <Popup
        trigger={
          <button className="popup-trigger">
            <Image
              resizeMode="contain"
              style={this.style.image}
              source={Assets.vDots}
            />
          </button>
        }
        position="right center"
      >
        <View style={this.style.popup}>
          <TouchableOpacity
            style={[this.style.popupItem]}
            onPress={this.binding.openDeleteModal}
          >
            <TranslationView style={this.style.text}>
              tools.product.remove.singleProduct.removeButton
            </TranslationView>
          </TouchableOpacity>
        </View>
      </Popup>
    );
  }
}