import {ContentViewStyle} from '@levelapp/softfabric';
import {StyleProp,ViewStyle} from 'react-native';


export default class PosSimButtonViewStyle extends ContentViewStyle
{
    button: StyleProp<ViewStyle> = {
        borderRadius: 8,
        paddingVertical: 8,
        paddingHorizontal: 25,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',

    }
}