export default class NumberHelper 
{
    /**
     * Check if the text is a valid Integer
     * Return false, if it's not
     * 
     * @param text 
     */

    static isAValidInt(text : string) : boolean
    {
        var number = Number.parseInt(text);
        if(Number.isNaN(number))
        {
            return false;
        }
        return true;
    }

    /**
     * Convert a Text string to int 
     * Return 0 if the value is not a valid text
     * 
     * @param text 
     */

    static convertStringToInt(text : string) : number
    {
        var number = Number.parseInt(text);
        if(Number.isNaN(number))
        {
            return 0;
        }
        return number;
    }
}