import { Operation } from "@levelapp/softfabric";
import BasketOrderTypeService from "../Proxy/BasketOrderTypeService";

export default class BasketOrderTypeOperations extends Operation
{
    service : BasketOrderTypeService;

    constructor()
    {
        super();
        this.service = new BasketOrderTypeService();
    }

    get(callback : Function)
    {
        this.service.get(callback);
    }

    post(orderType:any, callback : Function)
    {
        this.service.post(orderType, callback);
    }

    put(orderType:any, callback : Function)
    {
        this.service.put(orderType, callback);
    }

    delete(orderType:any, callback : Function)
    {
        this.service.delete(orderType, callback);
    }
}