 
import { ContentView } from '@levelapp/softfabric';
import FaqViewState from './FaqViewState';
import FaqViewProps from './FaqViewProps';
import FaqViewStyle from './FaqViewStyle';
import FaqViewModel from './FaqViewModel';
import { View } from 'react-native';
import TableView from '../../../../../../Common/Components/TabelView/TableView';
import ResearchInputView from '../../../../../../Common/Components/researchinput/ResearchInputView';
import HeaderView from '../../../components/header/HeaderView';

export default class FaqView extends ContentView<FaqViewProps, FaqViewState, FaqViewModel, FaqViewStyle>
{
  /* CONSTRUCTOR */
  constructor(props: FaqViewProps) {
    super(props);

    // Binding
    this.bind(new FaqViewModel(this), new FaqViewStyle(this));
  }

  /* RENDERING */
  render() {
    return (
      <View style={this.style.container}>
        <View style={this.style.box}>
          
          <HeaderView
            title={"faq.title"}
            description={"faq.description"}
            shouldShowButton={true}
            onButtonPress={this.binding.addQuestion}
            buttonText={"faq.button.add"}
          />

          <View style={this.style.boxContainer}>
            <ResearchInputView
              placeholder={this.binding.translate("generic.search")}
              handlerResearching={(searchText: string) => { this.binding.handleResearching(searchText) }}
              handlingClose={() => { this.binding.handleCloseResearching() }} />
            <TableView
              headerTextStyle={this.style.tableHeader}
              backgroundStepColor={this.style.stepColor}
              nothingToDisplayText={this.binding.translate("component.table.noresults")}
              isLastColumnActions={true}
              data={this.state.tableData}
              isLoading={this.state.isLoading}
              headers={this.state.tableHeaders}
              onPageChange={(page: number) => this.binding.onPageChange(page)}
              handleSortChange={(indexHeaderSort : number, sortOrder : string) => {this.binding.onSortChange(indexHeaderSort, sortOrder)}}
              nbPage={this.state.nbPage}
              currentPage={this.state.currentPage - 1}
              isSortable={true}/>
          </View>
        </View>
      </View>
    );
  }
}