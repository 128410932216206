import { Service, Application, HttpManager, Managers } from '@levelapp/softfabric';
export default class UserInfosService extends Service
{
    httpClient = Application.current.resolve<HttpManager>(Managers.HTTP_MANAGER);  

    async get(onSuccess: Function , onFailure : Function)
    {       
        this.httpClient.handleSomethingWentWrong = onFailure ;

        this.httpClient
        .get('role', [])
        .then((response : any) => {
            if (response)
            {
                if (response.data) // User profile
                {                  
                    onSuccess(response.data);
                }
            }
        });
    }
}