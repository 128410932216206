import { ContentViewStyle } from '@levelapp/softfabric';
import { StyleProp, ViewStyle, TextStyle } from 'react-native';

export default class StepViewStyle extends ContentViewStyle
{
    container: StyleProp<ViewStyle> =
        {
            display: 'flex',
            flexDirection: 'row',
            alignSelf: 'flex-end',
        }


    circle: StyleProp<ViewStyle> =
        {
            minHeight: 27,
            minWidth: 27,
            borderRadius: 27 / 2,
            marginRight: 7,
            marginTop: 15,
            borderColor: '#D3D3D3',
            backgroundColor: 'transparent',
            borderWidth: 1,
            justifyContent: 'center',
        }
    dontDisplay: StyleProp<ViewStyle> =
        {
            opacity: 0,
            backgroundColor: 'blue',
        }

    choose: StyleProp<ViewStyle> =
        {
            minHeight: 27,
            minWidth: 27,
            borderRadius: 27 / 2,
            marginRight: 7,
            marginTop: 15,
            borderColor: '#D3D3D3',
            backgroundColor: '#7aabf9',
            borderWidth: 1,
            justifyContent: 'center',
        }

    chooseText: StyleProp<TextStyle> =
        {
            color: 'white',
            textAlign: 'center',
            marginRight: 3,
            marginTop: 2,
        }

    text: StyleProp<TextStyle> =
        {
            textAlign: 'center',

            marginRight: 3,
            marginTop: 2,
            color: 'black',
        }
}