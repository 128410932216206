 
import {ContentView} from '@levelapp/softfabric';
import PermissionsManagementViewState from './PermissionsManagementViewState';
import PermissionsManagementViewProps from './PermissionsManagementViewProps';
import PermissionsManagementViewStyle from './PermissionsManagementViewStyle';
import PermissionsManagementViewModel from './PermissionsManagementViewModel';
// import {TranslationView} from '@levelapp/softfabric-ui';
import Constants from '../../../../../../../../../Common/Constants/Constants';
import { View } from 'react-native';
import { ButtonView, TranslationView } from '@levelapp/softfabric-ui';
import PopupConfirmationView from '../../../../../../../../../Common/Components/popupconfirmation/PopupConfirmationView';
import PopupHeaderView from '../../../../../../components/popupheader/PopupHeaderView';
import Permission from '../../../../../../../../../Common/DTO/Permission';
import LineView from '../../../../../../components/line/LineView';
import ExtendedTextInputView from '../../../../../../components/extendedtextinput/ExtendedTextInputView';
import ItemManagementView from '../../../../../../components/itemmanagement/ItemManagementView';

export default class PermissionsManagementView extends ContentView<PermissionsManagementViewProps, PermissionsManagementViewState, PermissionsManagementViewModel, PermissionsManagementViewStyle>
{
  /* CONSTRUCTOR */
  constructor(props:PermissionsManagementViewProps)
  {
    super(props);

    // Binding
    this.bind(new PermissionsManagementViewModel(this), new PermissionsManagementViewStyle(this));
  }

  /* RENDERING */
  render()
  {
    //inputTitle , 
    const { body , text , createButton}  = this.style;

    return (
      <View style={{ minWidth: 600, minHeight: 850, width: Constants.PopUpWidth, maxWidth: "100%" }}>

        {this.state.showPopup &&
          <PopupConfirmationView 
          title={this.state.titlePopup} 
          content={this.state.contentPopup}
          confirmationPopup={this.state.confirmationPopup} 
          handleOnPressBack={ () => this.binding.handleBackPopupPressed()} 
          handleOnPressAccepted={() => this.binding.deletePermission()} />
        }
       
       <PopupHeaderView shouldShowButton={false} title={"permission.management.header.title"} handleBackPressed={this.binding.handleCloseManagement}/>
       <View style={body}>
          <View style={{ flex: 1, maxHeight: 225, flexWrap: 'wrap' }}>
            {this.state.permissions.map((content: Permission) => {
              return (
                <ItemManagementView onEraseClick={() => this.binding.handleDeletePermission(content)}>
                 {content.key} 
                </ItemManagementView>
              );
            })}
          </View>

          <LineView/>

          <TranslationView upperCase style={[text]}>permission.management.title</TranslationView>

          <View style={{ display: "flex", flexDirection: "row", flexWrap: "wrap" }}>
            <ExtendedTextInputView
              title={'form.key'}
              value={this.state.permission != undefined ? this.state.permission.key : ''}
              showError = {false}
              onChangeText={this.binding.handlePermissionNameChanged}
            />
          </View>

           {/* this.binding.handleCreatePermissions */} 
          <View style={createButton}>
            <ButtonView animation={"Bounce"} borders={"Little"} handleClick={this.binding.handleCreatePermission} isLoading={this.state.isCreating} backgroundColor='#2196f3'>
              <TranslationView>permission.management.button.add</TranslationView>
            </ButtonView>
          </View>
         
        </View>

      </View>
    );
  }
}