 
import {ContentView} from '@levelapp/softfabric';
import TitleSectionViewState from './TitleSectionViewState';
import TitleSectionViewProps from './TitleSectionViewProps';
import TitleSectionViewStyle from './TitleSectionViewStyle';
import TitleSectionViewModel from './TitleSectionViewModel';
import Helpers from '../../../../../../../../../../../Common/Helpers/Helpers';
import Language from '../../../../../../../../../../../Common/DTO/Language';
import { View } from 'react-native';
import ExtendedBlockTextInputView from '../../../../../../../../components/extendedblocktextinput/ExtendedBlockTextInputView';
import SectionTitleView from '../../../../../../../../components/sectiontitle/SectionTitleView';

export default class TitleSectionView extends ContentView<TitleSectionViewProps, TitleSectionViewState, TitleSectionViewModel, TitleSectionViewStyle>
{
  /* CONSTRUCTOR */
  constructor(props:TitleSectionViewProps)
  {
    super(props);

    // Binding
    this.bind(new TitleSectionViewModel(this), new TitleSectionViewStyle(this));
  }

  /* RENDERING */
  render()
  {
    return (
      <>
        <SectionTitleView title={'form.titlefield'} />
        <View style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', justifyContent: 'space-between' }}>
          {/* Title */}
          {this.props.languages.map((content: Language) => {

            var _text = Helpers.getTranslationValueByLanguageKey(this.props.reward.titleTranslations,content.key);
            return (
              <ExtendedBlockTextInputView
                title={content.key.toUpperCase()}
                showError={this.props.showErrors && (_text == null || _text == "") ? true : false}
                value={_text}
                onChangeText={(text : string ) => this.props.handleTranslationsChange('title',text,content.id as number)}
              />
            )
          })}
        </View>
       </> 
    );
  }
}