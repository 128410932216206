 
import {ContentView} from '@levelapp/softfabric';
import MainInformationViewState from './MainInformationViewState';
import MainInformationViewProps from './MainInformationViewProps';
import MainInformationViewStyle from './MainInformationViewStyle';
import MainInformationViewModel from './MainInformationViewModel';
import LineView from '../../../../../../components/line/LineView';
import { View } from 'react-native';
import InformationSectionView from './components/informationssection/InformationsSectionView';
import Language from '../../../../../../../../../Common/DTO/Language';
import Helpers from '../../../../../../../../../Common/Helpers/Helpers';
import ExtendedBlockTextInputView from '../../../../../../components/extendedblocktextinput/ExtendedBlockTextInputView';
import SectionTitleView from '../../../../../../components/sectiontitle/SectionTitleView';

export default class MainInformationView extends ContentView<MainInformationViewProps, MainInformationViewState, MainInformationViewModel, MainInformationViewStyle>
{
  /* CONSTRUCTOR */
  constructor(props:MainInformationViewProps)
  {
    super(props);

    // Binding
    this.bind(new MainInformationViewModel(this), new MainInformationViewStyle(this));
  }

  /* RENDERING */
  render()
  {
    return (
      <View style={{ display: "flex", flexDirection: "column", paddingHorizontal: 170, paddingVertical: 50}}>
          <InformationSectionView
            booster={this.props.booster}
            showErrors={this.props.showErrors}
            boosterTypes={this.props.boosterTypes}
            handleValueChange={this.props.handleValueChange}
            handleCheckboxChange={this.props.handleCheckboxChange}
            handleDropDownChange={this.props.handleDropDownChange}
          />
          {/* Middle line */}
          <LineView/>

          <SectionTitleView title={'form.titlefield'}/>
          <View style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', justifyContent: 'space-between' }}>
            {/* Title */}
            {this.props.languages.map((content: Language) => {
              var _text = Helpers.getTranslationValueByLanguageKey(this.props.booster.titleTranslations,content.key)
              return (
                <ExtendedBlockTextInputView
                  title={content.key.toUpperCase()}
                  value={_text}
                  showError={this.props.showErrors && (_text == null || _text == "")}
                  onChangeText={(text: string) => this.props.handleTranslationsChange('title',text, content.id)}
                />
              );
            })}
          </View>
      </View>
    );
  }
}