import {ContentShellView, ContentShellViewModel} from '@levelapp/softfabric';
import EnumModules from '../../../../../Common/Constants/EnumModules';
import TicketsView from './pages/tickets/TicketsView';
import ShellTicketsViewProps from './ShellTicketsViewProps';
import ShellTicketsViewState from './ShellTicketsViewState';
 


export default class ShellTicketsViewModel extends ContentShellViewModel<ShellTicketsViewState, ShellTicketsViewProps>
{
    constructor(view : ContentShellView)
    {
        super(view);

        console.log("tickets");

        this.registerEntryPoint('main');
        this.register(<TicketsView/> ,'main');

        this.initialisation();
    }

    getModuleId()
    {
        return EnumModules.TICKETS;
    }
}