import {ContentViewModel,ContentView} from '@levelapp/softfabric';
import StoreSummaryPopUpViewState from './StoreSummaryPopUpViewState';
import StoreSummaryPopUpViewProps from './StoreSummaryPopUpViewProps';
import StoresOperations from '../../../../../../../Transfer/StoresOperations';
import ErrorHelper from '../../../../../../../Common/Helpers/ErrorHelper';
import StoreOrderSources from '../../../../../../../Common/DTO/StoreOrderSources';
import moment from 'moment';
import EntityLog from '../../../../../../../Common/DTO/EntityLog';


export default class StoreSummaryPopUpViewModel extends ContentViewModel<StoreSummaryPopUpViewState, StoreSummaryPopUpViewProps>
{
    _storesOperation = new StoresOperations();
      /* CONSTRUCTOR */
    constructor(view: ContentView)
    {
        super(view);

        this.initialState({
            storeLogs : [],
            storeOrderSourcesLogs : [],
            isLoading : false,
            storeOrderSourceTab : "",
            
        }); // Initialize your state here

        // Bindings
        this.hasIsEnabledChange = this.hasIsEnabledChange.bind(this)
        this.hasIsTemporaryDisabledChange = this.hasIsTemporaryDisabledChange.bind(this)
        this.getDate = this.getDate.bind(this)

        this.changeTab = this.changeTab.bind(this);

    }
    componentDidUpdate(){
        //console.log("this.state().storeOrderSourcesLogs",this.state().storeOrderSourcesLogs)
    }

    componentDidMount(){
        //GET LOGS
        this.setField({ isLoading : true,storeOrderSourceTab : `${this.props().store.orderSources[0].orderSourceId}` });
        this._storesOperation.getStoreOrderSourcesLogs(this.props().store.id, (response: any) =>
        {
            response.data.forEach((element : EntityLog) => {
                element.currentValue = JSON.parse(element.currentValue);
                element.originalValue = JSON.parse(element.originalValue);
            });
            this.setField({ storeLogs : response.data,storeOrderSourcesLogs : response.data.filter((x : any)=>x.entityName === "Stores.StoreOrderSources"), isLoading : false });
        },this.errorManagement);
    
    }

    errorManagement(error : any) 
    {
        this.setField({isLoading : false});
        ErrorHelper.showMessageError(error.response);
    }

    hasIsEnabledChange(index : number) : string {
        let response = "/";
        let originalValue : StoreOrderSources = this.state().storeOrderSourcesLogs[index].originalValue;
        let currentValue : StoreOrderSources = this.state().storeOrderSourcesLogs[index].currentValue;

        if(originalValue.IsEnabled != currentValue.IsEnabled)
        {
            response = `${originalValue.IsEnabled} => ${currentValue.IsEnabled} ` 
        }
        return response;
    }

    hasIsTemporaryDisabledChange(index : number) : string {
        let response = "/";
        let originalValue : StoreOrderSources = this.state().storeOrderSourcesLogs[index].originalValue;
        let currentValue : StoreOrderSources = this.state().storeOrderSourcesLogs[index].currentValue;
        if(originalValue.IsTemporaryDisabled != currentValue.IsTemporaryDisabled)
        {
            response = `${originalValue.IsTemporaryDisabled} => ${currentValue.IsTemporaryDisabled} ` 
        }
        return response;
    }

    getDate(date : string) : string {
        let newDate = moment.utc(date)
        return newDate.local().format("dddd, MMMM Do YYYY, H:mm")
    }

    changeTab(tab: any)
    {      
        let logsByOrderSource = this.state().storeLogs.filter(x=>x.currentValue.OrderSourceId == parseInt(tab));
        this.setField({storeOrderSourcesLogs : logsByOrderSource,storeOrderSourceTab : tab});
    }

   

    
}