import { Operation } from "@levelapp/softfabric";
import LTOProductService from "../Proxy/LTOProductService";

export default class LTOProductOperations extends Operation
{
    service : LTOProductService;

    constructor()
    {
        super();
        this.service = new LTOProductService();
    }
    get(parameters:any,onSuccess:Function){
        return this.service.get(parameters,onSuccess);
    }
}