 
import {ContentViewModel,ContentView} from '@levelapp/softfabric';
import ManuealEarnedPointsDetailsViewState from './ManuealEarnedPointsDetailsViewState';
import ManuealEarnedPointsDetailsViewProps from './ManuealEarnedPointsDetailsViewProps';
import UsersPopUpView from '../../../../../UsersPopUpView';
import Helpers from 'src/Common/Helpers/Helpers';

export default class ManuealEarnedPointsDetailsViewModel extends ContentViewModel<ManuealEarnedPointsDetailsViewState, ManuealEarnedPointsDetailsViewProps>
{
      /* CONSTRUCTOR */
    constructor(view: ContentView)
    {
        super(view);

        this.initialState({}); // Initialize your state here

        this.close = this.close.bind(this);

        // Bindings

    }

    close() {
        this.showPopUp(
            <UsersPopUpView user={Helpers.clone(this.props().user)} saveUser={this.props().saveUser} refresh={this.props().refresh} />
          );
    }
}