import {ContentViewModel, ContentView} from '@levelapp/softfabric';
import ImageButtonViewState from './ImageButtonViewState';
import ImageButtonViewProps from './ImageButtonViewProps';

export default class ImageButtonViewModel extends ContentViewModel<ImageButtonViewState, ImageButtonViewProps>{
    constructor(props : ContentView) {
        super(props);


        /* BINDINGS */
        this.handleMouseEnter = this.handleMouseEnter.bind(this);
        this.handleMouseLeave = this.handleMouseLeave.bind(this);
    }

    handleMouseEnter() {
        if (this.props().imageOnMouseEnter != undefined)
            this.setField({ image : this.props().imageOnMouseEnter});
    }

    handleMouseLeave() {
        this.setField({ image : this.props().image});
    }
}