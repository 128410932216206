 
import { ContentView } from '@levelapp/softfabric';
import StoresViewState from './StoresViewState';
import StoresViewProps from './StoresViewProps';
import StoresViewStyle from './StoresViewStyle';
import StoresViewModel from './StoresViewModel';
import { View, Text } from 'react-native';
import TableView from '../../../../../../../Common/Components/TabelView/TableView';
import MapRenderView from '../../components/maprender/MapRenderView';
import ResearchInputView from '../../../../../../../Common/Components/researchinput/ResearchInputView';
import HeaderView from '../../../../components/header/HeaderView';


export default class StoresView extends ContentView<StoresViewProps, StoresViewState, StoresViewModel, StoresViewStyle>
{
  /* CONSTRUCTOR */
  constructor(props: StoresViewProps) {
    super(props);

    // Binding
    this.bind(new StoresViewModel(this), new StoresViewStyle(this));
  }

  /* RENDERING */
  render() {
    return (
      <View style={this.style.container}>
        <View style={[this.style.box, this.state.tab == 'map' ? { flex: 1 } : {}]}>

          <HeaderView
            title={"stores.title"}
            buttonText={"stores.button.add"}
            description={"stores.description"}
            shouldShowButton
            onButtonPress={this.binding.addField}
          />

          <View style={this.style.boxContainer}>
            <View style={{ flexDirection: 'row' }}>
              <Text style={this.state.tab == 'list' ? this.style.titleActive : this.style.titleInactive} onPress={() => { this.binding.changeTab('list') }}>Listing</Text>
              <Text style={this.state.tab == 'map' ? this.style.titleActive : this.style.titleInactive} onPress={() => { this.binding.changeTab('map') }}>Map</Text>
            </View>
            {this.state.tab == 'list' &&
              <View style={{ marginTop: 15 }}>
                <ResearchInputView
                  placeholder={this.binding.translate("generic.search")}
                  handlerResearching={(searchText: string) => { this.binding.handleResearching(searchText) }}
                  handlingClose={() => { this.binding.handleCloseResearching() }} />
                <TableView
                  headerTextStyle={this.style.tableHeader}
                  backgroundStepColor={this.style.stepColor}
                  nothingToDisplayText={this.binding.translate("component.table.noresults")}
                  isLastColumnActions={true}
                  data={this.state.tableData}
                  isLoading={this.state.isLoading}
                  headers={this.state.tableHeaders}
                  onPageChange={(page: number) => this.binding.onPageChange(page)}
                  handleSortChange={(indexHeaderSort : number, sortOrder : string) => {this.binding.onSortChange(indexHeaderSort, sortOrder)}}
                  nbPage={this.state.nbPage}
                  currentPage={this.state.currentPage - 1}
                  isSortable={true}/>
              </View>
            }

            {this.state.tab == 'map' &&
              <View style={{ flex: 1, marginTop: 15 }}>
                <MapRenderView
                  defaultCenter={{ latitude: 50.855066, longitude: 4.36247365 }}
                  zoomEnabled={true}
                  scrollEnabled={true}
                  markers={this.state.mapData} />
              </View>
            }
          </View>
        </View>
      </View>
    );
  }
}