import {ContentViewModel,ContentView} from '@levelapp/softfabric';
import MenuItemViewState from './MenuItemViewState';
import MenuItemViewProps from './MenuItemViewProps';
import { Animated } from 'react-native';
import Constants from '../../../../../../../Common/Constants/Constants';
import MenuItemViewStyle from './MenuItemViewStyle';

export default class MenuItemViewModel extends ContentViewModel<MenuItemViewState, MenuItemViewProps, MenuItemViewStyle>
{
    _opacityAnimation : Animated.Value;
    _heightAnimation : Animated.Value;
    _heightTotalAnimation : Animated.Value;
    _maxheight : number;

      /* CONSTRUCTOR */
    constructor(view: ContentView)
    {
        super(view);

        this.initialState(
            {
                active : false
            }); // Initialize your state here

        // Bindings
        this._opacityAnimation = new Animated.Value(0);
        this._heightAnimation = new Animated.Value(0);
        this._heightTotalAnimation = new Animated.Value(50);
        this._maxheight = 50;
    }


    handleAnimation(value : number)
    {
        Animated.spring(this._opacityAnimation,
            {
                useNativeDriver: true,
                toValue : value,
            }).start();
    }

    handleAnimationHeight(value : number)
    {
        Animated.spring(this._heightAnimation,
            {
                useNativeDriver: true,
                toValue : value ,
            }).start();

    }

    handleOnPress()
    {
        if(this.props().isDirect != undefined && !this.props().isDirect)
        {
            var number = this.state().active ? 0 : 1;
            this.setField({active : !this.state().active})
            this.handleAnimationHeight(number);
        }else
        {
            this.props().onPress();
        }
    }

    

    getImage()
    {
        if(!this.state().active)
        {
            if(this.props().selected && !this.props().underMenu && !this.props().isDirect)
            {
                return require('../../assets/arrow-right.svg').default;
            }else if(this.props().selected && this.props().underMenu)
            {
                return ''
            }else if(!this.props().selected && !this.props().underMenu && !this.props().isDirect)
            {
                return require('../../assets/arrow-right-gray.svg').default;
            }
        }else
        {
            if(this.props().selected && !this.props().underMenu)
            {
                return require('../../assets/arrow-down.svg').default;
            }else if(this.props().selected && this.props().underMenu)
            {
                return ''
            }else if(!this.props().selected && !this.props().underMenu && !this.props().isDirect)
            {
                return require('../../assets/arrow-down-gray.svg').default;
            }
        }
        
    }

    getColor()
    {
        if(this.props().selected && !this.props().underMenu && this.props().isDirect)
        {
            return [Constants.GradiantMenuStartColor, Constants.GradiantMenuEndColor];
        }else if(this.props().selected && !this.props().underMenu && !this.props().isDirect)
        {
            return [Constants.GradiantMenuStartColor, Constants.GradiantMenuEndColor];
        }else
        {
            return ['white', 'white'];
        }
    }

    getTextStyle()
    {
        if(this.props().selected && this.props().underMenu && this.props().isDirect)
        {
            //Selected -> Sous Menu bold
            return [this.style().linkBoldText ,{ paddingLeft : 50}]
        }else if(this.props().selected && !this.props().underMenu && !this.props().isDirect)
        {
            //Selected -> Menu blue , text white
            return [this.style().linkTextActive];
        }else if(!this.props().selected && this.props().underMenu && this.props().isDirect)
        {
            return [this.style().linkText , { paddingLeft : 50}]
        }else if(!this.props().selected && !this.props().underMenu && !this.props().isDirect)
        {
            return [this.style().linkText];
        }else if(this.props().selected && this.props().isDirect && !this.props().underMenu)
        {
            return [this.style().linkTextActive];
        }else if(this.props().selected && this.props().underMenu && this.props().isDirect)
        {
            return [this.style().linkText , { paddingLeft : 50}];
        }else
        {
            return [this.style().linkText]
        }
    }
}