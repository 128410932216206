 
import { ContentView } from '@levelapp/softfabric';
import SliderViewState from './SliderViewState';
import SliderViewProps from './SliderViewProps';
import SliderViewStyle from './SliderViewStyle';
import SliderViewModel from './SliderViewModel';
import { TranslationView } from '@levelapp/softfabric-ui';
import { Platform, View, Text } from 'react-native';
import Slider from 'rc-slider';

import 'rc-slider/assets/index.css';

export default class SliderView extends ContentView<SliderViewProps, SliderViewState, SliderViewModel, SliderViewStyle>
{
  /* CONSTRUCTOR */
  constructor(props: SliderViewProps)
  {
    super(props);

    // Binding
    this.bind(new SliderViewModel(this), new SliderViewStyle(this));
  }

  /* RENDERING */
  render()
  {
    if (Platform.OS == "web")
    {
      //let tracker: any;
      return (
        <View>
          <Slider min={this.props.minValue} max={this.props.maxValue}
            value={this.props.currentValue} trackStyle={{ backgroundColor: this.props.backgroundColor, height: 10 }}
            railStyle={{ height: 10 }} onChange={(value) => this.props.handleChange(value)}
            handleStyle={{ borderColor: this.props.backgroundColor, height: 20, width: 20, backgroundColor: 'white' }}
          />
          <View style={this.style.minMax}>
            <Text>{this.props.minValue}</Text>
            <Text>{this.props.maxValue}</Text>
          </View>
          <View style={this.style.currentValue}>
            <TranslationView>form.currentvalue</TranslationView>
            <Text style={{ color: this.props.backgroundColor, fontSize: 20, fontWeight: '900' }}>{this.props.currentValue}</Text>
          </View>
        </View>
      );

    }
    return (
      <TranslationView>Other</TranslationView>
    );
  }
}