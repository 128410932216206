import { ContentViewStyle } from '@levelapp/softfabric';
import { StyleProp, ViewStyle } from 'react-native';

export default class BasketStatusViewStyle extends ContentViewStyle
{
    statusContainer: StyleProp<ViewStyle> = {
        flexDirection: 'row',
        alignItems: 'center'
    };

    statusOrdersAmount: StyleProp<ViewStyle> = {
        marginLeft: 12,
        height: 25,
        width: 25,
        borderRadius: 3,
        justifyContent: 'center',
        alignItems: 'center'
    };

    badge: StyleProp<ViewStyle> = {
        position: 'absolute',
        backgroundColor: '#C81616',
        height: 11,
        width: 11,
        borderRadius: 11,
        top: -4,
        right: -4
    };

    slider: StyleProp<ViewStyle> = {
        height: 2,
        width: '100%',
        backgroundColor: '#358DFF',
        position: 'absolute',
        bottom: -12
    };
}