import { Application, ContentViewStyle } from '@levelapp/softfabric';
import { StyleManager, UiManagers } from '@levelapp/softfabric-ui';
import { StyleProp, TextStyle, ViewStyle } from 'react-native';

export default class StoresViewStyle extends ContentViewStyle
{
    MyStyleManager: StyleManager = Application.current.resolve<StyleManager>(UiManagers.STYLE_MANAGER);

    container: StyleProp<ViewStyle> =
        {
            display: "flex",
            flexDirection: "column",
            paddingHorizontal: 170,
            paddingVertical: 50
        };

    addAllStoresLabel: StyleProp<TextStyle> =
        {
            color: this.MyStyleManager.primaryColor,
            fontSize: 10,
            marginTop: 20,
            fontWeight: '600',
            height: 27,
            width: 112
        };
}