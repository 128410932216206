 
import {ContentView} from '@levelapp/softfabric';
import OutofstockViewState from './OutofstockViewState';
import OutofstockViewProps from './OutofstockViewProps';
import OutofstockViewStyle from './OutofstockViewStyle';
import OutofstockViewModel from './OutofstockViewModel';
import { View,Text } from 'react-native';
import HeaderView from '../../../../components/header/HeaderView';
import TableView from '../../../../../../../Common/Components/TabelView/TableView';
import ResearchInputView from '../../../../../../../Common/Components/researchinput/ResearchInputView';
import TranslationView from '@levelapp/softfabric-ui/components/Both/Translation/TranslationView';

export default class OutofstockView extends ContentView<OutofstockViewProps, OutofstockViewState, OutofstockViewModel, OutofstockViewStyle>
{
  /* CONSTRUCTOR */
  constructor(props:OutofstockViewProps)
  {
    super(props);

    // Binding
    this.bind(new OutofstockViewModel(this), new OutofstockViewStyle(this));
  }

  /* RENDERING */
  render()
  {
    return (
      <View style={this.style.container}>
        <View style={this.style.box}>
          
          <HeaderView
            title={"outofstock.title"}
            description={"outofstock.description"}
            shouldShowButton={!this.state.isLoading}
            onButtonPress={ ()=> this.binding.showViewAddProduct()}
            buttonText={"outofstock.button.add"}

            shouldShowButton2={this.state.hasMultipleStores}
            buttonText2 = {"outofstock.button.choseResto"}
            onButtonPress2={ ()=> this.binding.showStoresList(this.state.stores)}
          />

          <View style={this.style.boxContainer}>
          <View style={{flexDirection : 'row', justifyContent : "space-between"}}>
          <ResearchInputView
              placeholder={this.binding.translate('generic.search')}
              handlerResearching={(searchText: string) => { this.binding.handleResearching(searchText) }}
              handlingClose={() => { this.binding.handleCloseResearching() }} />


     
          <View >
            <TranslationView>{'outofstock.current.store'}</TranslationView>
            <Text style={{fontWeight : 'bold'}}>{this.state.currentStore?.slug}</Text>
          </View>
            
        </View>

            <TableView
              headerTextStyle={this.style.tableHeader}
              rowStyle={this.style.rowStyle}
              backgroundStepColor={this.style.stepColor}
              nothingToDisplayText={this.binding.translate('component.table.noresults')}
              isLastColumnActions={true}
              data={this.state.tableData}
              isLoading={this.state.isLoading}
              headers={this.state.tableHeaders}
              onPageChange={(page: number) => this.binding.onPageChange(page)}
              handleSortChange={() => {}}
              nbPage={this.state.nbPage}
              currentPage={this.state.currentPage - 1}
              isSortable={false}/>
          </View>
        </View>
      </View>
    );
  }
}