import { Service, Application, HttpManager, Managers } from '@levelapp/softfabric';

export default class PosSimulatorService extends Service
{
    httpClient = Application.current.resolve<HttpManager>(Managers.HTTP_MANAGER);

    async getUserFromScan(qrcode : any, onSuccess: Function)
    {       
        this.httpClient
        .get('PosSimulator/GetProfileFromScan/'+qrcode)
        .then((response : any) => {
            if (response)
            {
                if (response.data)
                {                  
                    onSuccess(response.data.data);
                }
                else {
                    onSuccess(new Error());
                }
            }
        });
    }

    async getCouponFromScan(qrcode : any, onSuccess: Function)
    {
        this.httpClient
        .get('PosSimulator/GetCouponInfosByUUID/'+qrcode)
        .then((response : any) => {
            if (response)
            {
                if (response.data)
                {
                    
                    onSuccess(response.data.data);
                    
                }
                else {
                    // console.log("I'm in the service");
                    onSuccess(new Error());
            
                }
            }
            else {
                alert('Something went wrong!\n Is '+qrcode+'a valid coupon/reward or hasn\'t already been scanned ?');
            }
        })
    }

    async cancelCoupons(coupons: any[], onSuccess: Function )
    {
        this.httpClient
        .post('PosSimulator/CancelCoupons/', [], coupons)
        .then((response : any) => {
            if (response)
            {
                if (response.data)
                {                  
                    onSuccess(response.data.data);
                }
                else {
                    onSuccess(new Error());
                }
            }
        });
        
    }

    async confirmPurchase(purchase: any, onSuccess: Function){
        this.httpClient
        .post('PosSimulator/confirmPurchase/', [], purchase)
        .then((response : any) => {
            if (response)
            {
                if (response.data)
                {                  
                    onSuccess(response.data.data);
                }
                else {
                    onSuccess(new Error());
                }
            }
        });
    }
}