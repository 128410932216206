 
import {ContentView} from '@levelapp/softfabric';
import OptinsListViewState from './OptinsListViewState';
import OptinsListViewProps from './OptinsListViewProps';
import OptinsListViewStyle from './OptinsListViewStyle';
import OptinsListViewModel from './OptinsListViewModel';
import { CheckBox, Text, View } from 'react-native';
import ExtendedTranslationView from '../../../../../../../../../../components/extendedtranslation/ExtendedTranslationView';
import { TranslationView } from '@levelapp/softfabric-ui';

export default class OptinsListView extends ContentView<OptinsListViewProps, OptinsListViewState, OptinsListViewModel, OptinsListViewStyle>
{
  /* CONSTRUCTOR */
  constructor(props:OptinsListViewProps)
  {
    super(props);

    // Binding
    this.bind(new OptinsListViewModel(this), new OptinsListViewStyle(this));
  }

  /* RENDERING */
  render()
  {
    const {checkbox} = this.style;

    return (
      <View style={[this.props.style]}>
        {this.props.title != '' && this.props.title != undefined && this.props.value && this.props.value.length > 0 ? (
        <>
        <ExtendedTranslationView styleText={this.props.styleTitle} uppercase={this.props.uppercase != undefined ? this.props.uppercase : true} afterTitle={this.props.extraTitle} infoText={this.props.infoText}>
          {this.props.title}
        </ExtendedTranslationView>
        {this.props.value.map((data : any) => {
          return(
          <View style={{flexDirection : "row"}}>
          <CheckBox disabled={true} style={checkbox} value={data.isChecked} onValueChange={() => {}}/>
          <Text>{data.name}</Text>
          </View>
          )
        })}
        </>
        
        )
        :
        (
          <>
          <ExtendedTranslationView styleText={this.props.styleTitle} uppercase={this.props.uppercase != undefined ? this.props.uppercase : true} afterTitle={this.props.extraTitle} infoText={this.props.infoText}>
              {this.props.title}
            </ExtendedTranslationView>
            <View style={{flexDirection: "row"}}>
            <Text>{this.props.user.firstName} {this.props.user.lastName}</Text>
            <TranslationView style={{paddingBottom: "5%"}}>
              {"form.nooptins"}
            </TranslationView>
            </View>
          </>
        )}
      </View>
    );
  }
}