import { Operation } from "@levelapp/softfabric";
import ProductCategoriesService from "../Proxy/ProductCategoriesService";


export default class ProductCategoriesOperations extends Operation
{
    service : ProductCategoriesService;

    constructor()
    {
        super();
        this.service = new ProductCategoriesService();
    }

    get(parameters : any[], callback : Function, onFailure: Function)
    {
        this.service.get(parameters, callback,onFailure);
    }

    post(ProductCategory:any, callback : Function, onFailure: Function)
    {
        this.service.post(ProductCategory, callback,onFailure);
    }

    put(ProductCategory:any, callback : Function, onFailure: Function)
    {
        this.service.put(ProductCategory, callback,onFailure);
    }

    delete(ProductCategory:any, callback : Function, onFailure: Function)
    {
        this.service.delete(ProductCategory, callback,onFailure);
    }
}