import { ContentViewStyle, Application } from "@levelapp/softfabric";
import { StyleManager, UiManagers } from "@levelapp/softfabric-ui";
import { StyleProp, ViewStyle, TextStyle } from "react-native";


export default class ProductCategoriesViewStyle extends ContentViewStyle {
  MyStyleManager: StyleManager = Application.current.resolve<StyleManager>(UiManagers.STYLE_MANAGER);

  container: StyleProp<ViewStyle> = {
    display: 'flex',
    flex: 1,
    alignItems: 'center'
  }

  buttonBox: StyleProp<ViewStyle> =
    {
      flexDirection: 'row',
      justifyContent: 'flex-end',
      alignItems: 'center',
      flex: 1,
      padding: 40,
    }

  box: StyleProp<ViewStyle> = {
    width: '95%',
    backgroundColor: 'white',
    borderRadius: 6,
    marginTop: 40,
    shadowColor: "#0000006b",
    shadowOffset: {
      width: 1,
      height: 0,
    },
    shadowOpacity: 0.70,
    shadowRadius: 20.00,
    elevation: 60,
  }

  exportButton: StyleProp<ViewStyle> = {
    marginLeft: 30,
    marginBottom: 10,
    backgroundColor: 'white',
    width: 105,
    borderRadius: 10,
    height: 34,
  }



  tableHeader: StyleProp<TextStyle> =
    {
      color: '#575757',
      fontSize: 15,
      fontFamily: 'Montserrat',
      fontWeight: 'bold',
    }




  label: StyleProp<TextStyle> = {
    fontSize: 16,
    fontWeight: '600',
    color: '#525f7f',
    marginBottom: 10
  }

  boxContainer: StyleProp<ViewStyle> = {
    display: 'flex',
    alignSelf: 'center',
    minHeight: 250,
    width: '100%',
    paddingTop: 30,
    paddingBottom: 30,
    paddingLeft: 50,
    paddingRight: 50,
  }

  availableIn: StyleProp<ViewStyle> = {
    marginTop: 50
  }

  subCategories: StyleProp<ViewStyle> = {
    marginTop: 50
  }

  radioContainer: StyleProp<ViewStyle> = {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginTop: 5,
    marginBottom: 5
  }

  radioLabel: StyleProp<TextStyle> = {
    fontSize: 16,
    fontWeight: '600',
    color: '#8898aa',
    marginLeft: 10
  }



  stepColor: StyleProp<ViewStyle> =
    {
      backgroundColor: this.MyStyleManager.primaryColor,
    }



  rowStyle: StyleProp<ViewStyle> =
    {
      minHeight: 170,
    }
}