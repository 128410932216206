 
import { Button, View, } from 'react-native';
import "react-datepicker/dist/react-datepicker.css";
import 'react-datepicker/dist/react-datepicker-cssmodules.css';
import { TextInputMask } from 'react-native-masked-text';

import { TranslationView } from '@levelapp/softfabric-ui';
import { ContentView } from '@levelapp/softfabric';

import Constants from '../../../../../../../Common/Constants/Constants';
import Helpers from '../../../../../../../Common/Helpers/Helpers';
import ImageButton from '../../../../../../../Common/Components/imagebutton/ImageButtonView'
import DropDown from "../../../../../../../Common/Components/Dropdown/DropDownView";
import CouponsPopUpViewState from './CouponsPopUpViewState';
import CouponsPopUpViewProps from './CouponsPopUpViewProps';
import CouponsPopUpViewStyle from './CouponsPopUpViewStyle';
import CouponsPopUpViewModel from './CouponsPopUpViewModel';
import PopupMenuItemView from '../../../../components/popupmenuitem/PopupMenuItemView';
import MainInformationView from './components/maininformation/MainInformationView';
import CountriesView from './components/countries/CountriesView';
import StoresView from './components/stores/StoresView';
import DescriptionView from '../../../../components/description/DescriptionView';
import PicturesView from '../../../../components/pictures/PicturesView';
import PopupHeaderView from '../../../../components/popupheader/PopupHeaderView';
import GenericSettingsSectionView from '../../../../components/genericsettingssection/GenericSettingsSectionView';
import BasketordertypesmanagementpopupView from '../../../stores/pages/storespopup/modules/basketordertypesmanagement/BasketordertypesmanagementView';
import CouponAvailableHour from '../../../../../../../Common/DTO/M&N/CouponAvailableHour';
import CouponOrderSourceOrderTypeView from './components/couponordersourceordertype/CouponOrderSourceOrderTypeView';
import ButtonView from '../../../../../../../Common/Components/Button/ButtonView';
import ButtonImportFileView from '../../../../../../../Common/Components/ButtonImportFileWebView/ButtonImportFileWebView';

export default class CouponsPopUpView extends ContentView<CouponsPopUpViewProps, CouponsPopUpViewState, CouponsPopUpViewModel, CouponsPopUpViewStyle>
{
  /* CONSTRUCTOR */
  constructor(props: CouponsPopUpViewProps) {
    super(props);

    // Binding
    this.bind(new CouponsPopUpViewModel(this), new CouponsPopUpViewStyle(this));
  }

  /* RENDERING */
  render() {
    if (this.state.page) {
      return (
        <BasketordertypesmanagementpopupView
          handleBackPressed={() => this.binding.handleNavigationTo('')}
          languages={this.state.languages}
        />
      )
    }

    return (
      <View style={{ minWidth: 600, minHeight: 850, width: Constants.PopUpWidth, maxWidth: "100%" }}>
        <PopupHeaderView
          handleBackPressed={() => {
            this.props.refreshParent()
            Helpers.closePopup()
          }}
          title={`${this.props.type}.popup.title`}
          shouldShowButton
          isLoading={this.state.isLoading}
          handleOnPressButton={this.binding.handleSavePressed}>
          {Constants.ShowActivateCouponsForUsersList && this.state.coupon.id != undefined && this.state.coupon.id > 0 && this.state.coupon.isMarketingAutomation &&
            <View style={{ display: "flex", flexDirection: "row", alignItems: 'center', marginRight: 150 }}>             
              <TranslationView style={{ marginRight: 5, color: 'white', }}>coupons.importMA</TranslationView>
              <ButtonImportFileView buttonColor="white" textColor={Constants.MainColor} accepted=".json , .xlsx" action={(file: any) => this.binding.handleFile(file)} />
              <View style={{width:20}} />
              <ButtonView
                isLoading={this.state.isLoading}
                handleClick={() => this.binding.downloadUsersForCoupon()}
                backgroundColor='white'
                textColor={Constants.MainColor}>
                <TranslationView upperCase={true}>
                  couponConsumptions.downloadUsers
                </TranslationView>
              </ButtonView>
            </View>
          }

        </PopupHeaderView>

        <View style={{ display: "flex", flexDirection: "row", flexWrap: "wrap" }}>
          <PopupMenuItemView
            activeItem={this.state.tab}
            title="form.maininformation"
            item="main"
            handleChangeTab={() => this.binding.changeTab("main")}
          />
          <PopupMenuItemView
            activeItem={this.state.tab}
            title="form.description"
            item="desc"
            handleChangeTab={() => this.binding.changeTab("desc")}
          />
          <PopupMenuItemView
            activeItem={this.state.tab}
            title="form.couponAvailableHours"
            item="availableHours"
            handleChangeTab={() => this.binding.changeTab("availableHours")}
          />
          <PopupMenuItemView
            activeItem={this.state.tab}
            title="form.images"
            item="pictures"
            handleChangeTab={() => this.binding.changeTab("pictures")}
          />
          {!Constants.IsCountryUnique && this.state.countries.length > 1 &&
            (
              <PopupMenuItemView
                activeItem={this.state.tab}
                title="form.countries"
                item="countries"
                handleChangeTab={() => this.binding.changeTab("countries")}
              />
            )}
          <PopupMenuItemView
            activeItem={this.state.tab}
            title="form.stores"
            item="stores"
            handleChangeTab={() => this.binding.changeTab("stores")}
          />
          {this.props.contentTypeKeys.length > 0 && (
            <PopupMenuItemView
              activeItem={this.state.tab}
              title="form.settings"
              item="settings"
              handleChangeTab={() => this.binding.changeTab("settings")}
            />
          )}
          <PopupMenuItemView
            activeItem={this.state.tab}
            title="form.orderTypeOrderSource"
            item="orderTypeOrderSource"
            handleChangeTab={() => this.binding.changeTab("orderTypeOrderSource")}
          />
        </View>

        {this.state.tab === "main" &&
          <MainInformationView
            languages={this.state.languages}
            products={this.state.products}
            coupon={this.state.coupon}
            type={this.props.type}
            membershipTypes={this.props.membershipTypes}
            couponTypes={this.props.couponType}
            shouldShowError={this.state.showErrors}
            handleCheckboxChange={this.binding._dispatcherHelper.handleCheckboxChange}
            handleDateChange={this.binding._dispatcherHelper.handleDateChange}
            handleValueChange={this.binding._dispatcherHelper.handleValueChange}
            handleTranslationsChange={this.binding._dispatcherHelper.handleTranslationsChange}
            handleDropDownChange={this.binding.handleCouponTypeChanged}
            handleMembershipChange={this.binding.handleMembershipChanged}
            units={this.props.units}
            handleRenewableUnitChange={this.binding.handleRenewableUnitChange}
            handleRenewableValueChange={this.binding.handleRenewableValueChange}
            isRenewableWithCooldown={this.state.isRenewableWithCooldown}/>
        }

        {this.state.tab === "availableHours" &&
          <View style={{ display: "flex", flexDirection: "row", flexWrap: "wrap", justifyContent: 'space-between', paddingHorizontal: 170, paddingVertical: 50 }}>
            <View style={{ flexDirection: "column", minWidth: 505, marginBottom: 20 }}>
              <TranslationView style={this.style.scheduleTitle} upperCase>
                form.couponAvailabilities
              </TranslationView>
              <View>
                {(this.state.coupon.availableHours as any[]).length != 0 && (
                  <View style={{ flexDirection: 'row', marginBottom: 10 }}>
                    <TranslationView style={{ fontSize: 18, marginRight: 85 }} upperCase>form.weekday</TranslationView>
                    <TranslationView style={{ fontSize: 18, marginRight: 105 }} upperCase>form.couponAvailableFrom</TranslationView>
                    <TranslationView style={{ fontSize: 18 }} upperCase>form.couponAvailableTo</TranslationView>
                  </View>)}
                {this.state.coupon.availableHours.map((content: CouponAvailableHour, index: number) => (
                  <View style={{ flexDirection: 'row', flex: 1, marginBottom: 10 }}>
                    <DropDown
                      values={this.binding.weekdays}
                      selectedValue={this.binding.weekdays[content.day - 1]}
                      onValueChange={(value: any) => { this.binding.handleDayChange(value, content) }}
                    />
                    <TextInputMask
                      type="custom"
                      options={{ mask: '99:99' }}
                      style={this.state.showErrors && (
                        !this.binding.isRespectingTimeFormat(content.availableFrom))
                        ? [this.style.inputError, { marginRight: 15 }]
                        : [this.style.input, { marginRight: 15 }]}
                      value={content.availableFrom}
                      onChangeText={(time: string) => { this.binding.handleTimeChange(time, index, true) }}
                      placeholder='Format : 00:00' />

                    <TextInputMask
                      type="custom"
                      options={{ mask: '99:99' }}
                      style={this.state.showErrors && (!this.binding.isRespectingTimeFormat(content.availableTo)) ? this.style.inputError : this.style.input}
                      value={content.availableTo}
                      onChangeText={(time: string) => { this.binding.handleTimeChange(time, index, false) }}
                      placeholder='Format : 00:00' />
                    <ImageButton
                      image={require('src/Common/Assets/erase.svg').default}
                      imageHeight={30}
                      imageWidth={30}
                      onClick={() => this.binding.handleDeleteHour(index)}
                      style={this.style.deleteButton}
                    />
                  </View>
                ))}
              </View>
              <View style={{ width: 200 }}>
                <Button title='Add Hour' onPress={() => { this.binding.addAvailableHours(Constants.ScheduleTypeStoreId) }} />
              </View>
            </View>
          </View>
        }


        {this.state.tab === "desc" &&
          <DescriptionView
            listDesc={this.state.coupon.descriptionTranslations}
            languages={this.state.languages}
            shouldShowError={this.state.showErrors}
            handleTranslationsChange={this.binding._dispatcherHelper.handleTranslationsChange}
          />
        }
        {this.state.tab === "pictures" &&
          <PicturesView
            languages={this.state.languages}
            listImage={this.state.coupon.imageTranslation}
            listThumbnail={this.state.coupon.thumbnailTranslation}
            handleOnComplete={this.binding._dispatcherHelper.handleAnyChange}
            handleOnPressImage={this.binding._dispatcherHelper.handleNumberChange}
            handleCheckboxChange={this.binding._dispatcherHelper.handleCheckboxChange}
            shouldUseDefaultImage={this.state.useDefaultImageChecked}
            shouldUseDefaultThumnail={this.state.useDefaultThumbnailChecked}
          />
        }
        {this.state.tab === "countries" &&
          <CountriesView
            coupon={this.state.coupon}
            countries={this.state.countries}
            handleValueChange={this.binding._dispatcherHelper.handleValueChange}
          />
        }
        {this.state.tab === "stores" &&
          <StoresView
            isLoading={this.state.isStoresLoading}
            stores={this.state.stores}
            selectedIds={this.state.coupon?.storeIds ?? []}
            handleValueChange={this.binding._dispatcherHelper.handleValueChange}
          />
        }
        {this.state.tab === "settings" &&
          <GenericSettingsSectionView
            onKeyValueChange={this.binding._dispatcherHelper.handleAnyChange}
            contentTypeKeys={this.binding.getContentTypeKeys()}
            contentTypeKeyValues={this.props.coupon.contentTypeKeyValue} />
        }
        {this.state.tab === "orderTypeOrderSource" &&
          <CouponOrderSourceOrderTypeView
            isLoading={false}
            orderSources={this.state.orderSources} basketOrderType={this.state.basketOrderTypes}
            couponOrderTypeOrderSources={this.state.coupon.couponOrderTypeOrderSources}
            handleValueChanged={(value, orderSourceId, basketOrderTypeId) => this.binding.handleChange(value, orderSourceId, basketOrderTypeId)}

          />
        }

        {this.state.showErrors && (
          <View style={this.style.footer}>
            <TranslationView style={{ color: "red" }}>
              error.please.fields
            </TranslationView>
          </View>
        )}
      </View >
    );
  }
}