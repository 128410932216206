import { Operation } from "@levelapp/softfabric";
import PropertiesService from "../Proxy/PropertiesService";

export default class PropertiesOperations extends Operation
{
    service : PropertiesService;

    constructor()
    {
        super();
        this.service = new PropertiesService();
    }

    get(callback : Function)
    {
        this.service.get(callback);
    }

    getStoresUsingProperty(property:any, callback : Function)
    {
        this.service.getStoresUsingProperty(property, callback);
    }

    post(property:any, callback : Function)
    {
        this.service.post(property, callback);
    }

    put(property:any, callback : Function)
    {
        this.service.put(property, callback);
    }

    delete(property:any, callback : Function)
    {
        this.service.delete(property, callback);
    }
}