import {ContentViewModel,ContentView} from '@levelapp/softfabric';
import InformationsSectionViewState from './InformationsSectionViewState';
import InformationsSectionViewProps from './InformationsSectionViewProps';

export default class InformationsSectionViewModel extends ContentViewModel<InformationsSectionViewState, InformationsSectionViewProps>
{
      /* CONSTRUCTOR */
    constructor(view: ContentView)
    {
        super(view);

        this.initialState(
            {
                
            }); // Initialize your state here

        // Bindings

    }
}