import { Operation } from "@levelapp/softfabric";
import UserMetadataUnitsService from "../Proxy/UserMetadataUnitsService";

export default class UserMetadataUnitsOperations extends Operation{
    
    service : UserMetadataUnitsService;

    constructor(){
        super();
        this.service = new UserMetadataUnitsService();
    }
    get(callBack:Function){
        this.service.get(callBack);
    }

    getStatus(callBack:Function){
        this.service.getStatus(callBack);
    }
    getCountries(callBack:Function){
        this.service.getCountries(callBack);
    }
    getGenders(callBack:Function){
        this.service.getGenders(callBack);
    }

}