import {ContentViewModel , ContentView} from '@levelapp/softfabric';
import DropDownViewState from './DropDownViewState';
import DropDownViewProps from './DropDownViewProps';

export default class DropDownViewModel extends ContentViewModel<DropDownViewState, DropDownViewProps>{
    constructor(props: ContentView) {
        super(props);

        this.initialState({initialValue : undefined , selectedValue : undefined, values : []});

        this.handleValueChanged = this.handleValueChanged.bind(this);
        this.handleDefaultValue = this.handleDefaultValue.bind(this);
        this.checkInitialValue = this.checkInitialValue.bind(this);
    } 

    handleValueChanged (value : any){
        this.setField({selectedValue : value});
        this.props().onValueChange(JSON.parse(value));
    }

    componentDidMount()
    {
        this.handleDefaultValue();
    }

    checkInitialValue()
    {
        if(JSON.stringify(this.props().selectedValue) != this.state().initialValue)
        {
            this.setField({selectedValue : this.props().selectedValue == undefined ? undefined : JSON.stringify(this.props().selectedValue), initialValue : JSON.stringify(this.props().selectedValue)});
        }
    }

    handleDefaultValue()
    {
        if(this.props().selectedValue != undefined && this.state().selectedValue == undefined)
        {
            this.setField({initialValue : JSON.stringify(this.props().selectedValue), selectedValue : this.props().selectedValue == undefined ? undefined : JSON.stringify(this.props().selectedValue)});
        }
    }
}