import {ContentViewStyle} from '@levelapp/softfabric';
import {StyleProp,TextStyle,ViewStyle} from 'react-native';

export default class StatsBlockViewStyle extends ContentViewStyle
{
    page: StyleProp<ViewStyle> = {
        flex: 1,
        alignItems : "flex-start"
    }

    textContainer: StyleProp<ViewStyle> =
    {
        display: "flex",
        flexDirection:"row",
        borderRadius : 15,
        padding : 7,
        margin : 5
    }

    chartContainer: StyleProp<ViewStyle> =
    {
        width : 550,
        marginBottom : 50,
    }

    headerContent : StyleProp<ViewStyle> =
    {
        display : 'flex',
        flexDirection :'row',
    }
    headerContainer : StyleProp<ViewStyle> =
    {
        display : 'flex',
        flexDirection: 'column',
        alignSelf :'flex-start',
        marginLeft : 40,
        marginBottom : 35
    }

    titleStyle : StyleProp<TextStyle> =
    {
        fontWeight : "bold",
        fontSize : 30,
        marginBottom : 15,
    }

    legend : StyleProp<ViewStyle> = 
    {
        width : 15,
        height : 15,
        borderRadius : 50,
        marginRight : 5,
    }

    legend_status : StyleProp<ViewStyle> = 
    {
        flexDirection : 'row',
        alignContent : 'center',
        justifyContent : 'flex-start'
    }
}