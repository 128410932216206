import { ContentViewModel, ContentView } from '@levelapp/softfabric';
import ProductPopupViewState from './ProductPopupViewState';
import ProductPopupViewProps from './ProductPopupViewProps';
import Helpers from '../../../../../../../Common/Helpers/Helpers';
import AllergenOperations from '../../../../../../../Transfer/AllergenOperations';
import Allergen from '../../../../../../../Common/DTO/Allergen';
import ProductAllergen from '../../../../../../../Common/DTO/M&N/ProductAllergen';
import DispatcherManager from '../../../../../../../Common/Managers/DispatcherManager';
import ImageTranslation from '../../../../../../../Common/DTO/ImageTranslation';
import TranslationValue from '../../../../../../../Common/DTO/TranslationValue';
import TranslationHelper from '../../../../../../../Common/Helpers/TranslationHelper';
import Product from '../../../../../../../Common/DTO/Product';
import ProductsOperations from '../../../../../../../Transfer/ProductsOperations';
import ToastHelper from '../../../../../../../Common/Helpers/ToastHelper';
import ErrorHelper from '../../../../../../../Common/Helpers/ErrorHelper';
import Constants from 'src/Common/Constants/Constants';

export default class ProductPopupViewModel extends ContentViewModel<ProductPopupViewState, ProductPopupViewProps>
{
    _dispatcherManager: DispatcherManager;
    allergenOperations: AllergenOperations;
    productsOperations: ProductsOperations;

    /* CONSTRUCTOR */
    constructor(view: ContentView) {
        super(view);
        this.initialState({
            index: 0,
            secondaryIndex: 0,
            tab: "main",
            allergens: [],
            isLoading: false,
            showErrors: false,
            indexThumbnail: 0,
            windowTab: 'product',
            isLoadingAllergens: true,
            product: this.props().product,
            languages: this.props().languages,
            useDefaultImageChecked: Helpers.hasDuplicates(this.props().product.imageTranslations),
            useDefaultThumbnailChecked: Helpers.hasDuplicates(this.props().product.thumbnailTranslations),
            useDefaultSecondaryImageChecked: this.props().product.secondaryImageTranslations !== undefined ? Helpers.hasDuplicates(this.props().product.secondaryImageTranslations) : false,
        });

        // Bindings     
        this.changeTab = this.changeTab.bind(this);
        this.handleImage = this.handleImage.bind(this);
        this.saveProducts = this.saveProducts.bind(this);
        this.setIndexImage = this.setIndexImage.bind(this);
        this.handleThumbnail = this.handleThumbnail.bind(this);
        this.refreshAllergens = this.refreshAllergens.bind(this);
        this.setIndexThumbnail = this.setIndexThumbnail.bind(this);
        this.handleSavePressed = this.handleSavePressed.bind(this);
        this.handleBackPressed = this.handleBackPressed.bind(this);
        this.handleNavigationTo = this.handleNavigationTo.bind(this);
        this.handlePosIdChanged = this.handlePosIdChanged.bind(this);
        this.handleOrderIndexChanged = this.handleOrderIndexChanged.bind(this);
        this.handleTitleChanged = this.handleTitleChanged.bind(this);
        this.handlePreviewChanged = this.handlePreviewChanged.bind(this);
        this.handleChangeAllergen = this.handleChangeAllergen.bind(this);
        this.handleAnonymousChanged = this.handleAnonymousChanged.bind(this);
        this.handleDescriptionChanged = this.handleDescriptionChanged.bind(this);
        this.handleDefaultCheckBoxChanged = this.handleDefaultCheckBoxChanged.bind(this);
        this.handleDefaultCheckBoxThumbnailChanged = this.handleDefaultCheckBoxThumbnailChanged.bind(this);
        this.handleShownInCrossSell = this.handleShownInCrossSell.bind(this);
        this.errorManagement = this.errorManagement.bind(this);

        this.handleSecondaryOrderIndexChanged = this.handleSecondaryOrderIndexChanged.bind(this);
        this.handleSecondaryTitleChanged = this.handleSecondaryTitleChanged.bind(this);
        this.handleSecondaryDescriptionChanged = this.handleSecondaryDescriptionChanged.bind(this);
        this.handleSecondaryImage = this.handleSecondaryImage.bind(this);
        this.handleSecondaryDefaultCheckBoxChanged = this.handleSecondaryDefaultCheckBoxChanged.bind(this);
        this.setSecondaryIndexImage = this.setSecondaryIndexImage.bind(this);

        this.allergenOperations = new AllergenOperations();
        this.productsOperations = new ProductsOperations();
        this._dispatcherManager = new DispatcherManager();
    }

    componentDidMount() {
        this.allergenOperations.get((data: Allergen[]) => this.setField({ allergens: data, isLoadingAllergens: false }));

        this._dispatcherManager.checkboxPreview = this.handlePreviewChanged;
        this._dispatcherManager.checkboxAnonymous = this.handleAnonymousChanged;
        this._dispatcherManager.checkboxDefaultImage = this.handleDefaultCheckBoxChanged;
        this._dispatcherManager.checkboxDefaultSecondaryImage = this.handleSecondaryDefaultCheckBoxChanged;
        this._dispatcherManager.checkboxDefaultThumbnail = this.handleDefaultCheckBoxThumbnailChanged;
        this._dispatcherManager.checkboxIsShownInCrossSell = this.handleShownInCrossSell;

        this._dispatcherManager.valuePosId = this.handlePosIdChanged;
        this._dispatcherManager.valueOrderIndex = this.handleOrderIndexChanged;
        this._dispatcherManager.valueSecondaryOrderIndex = this.handleSecondaryOrderIndexChanged;

        this._dispatcherManager.translationTitle = this.handleTitleChanged;
        this._dispatcherManager.translationDescription = this.handleDescriptionChanged;
        this._dispatcherManager.translationSecondaryTitle = this.handleSecondaryTitleChanged;
        this._dispatcherManager.translationSecondaryDescription = this.handleSecondaryDescriptionChanged;

        this._dispatcherManager.numberIndexImage = this.setIndexImage;
        this._dispatcherManager.numberIndexSecondaryImage = this.setSecondaryIndexImage;
        this._dispatcherManager.numberIndexThumbnail = this.setIndexThumbnail;

        this._dispatcherManager.uriImage = this.handleImage;
        this._dispatcherManager.uriSecondaryImage = this.handleSecondaryImage;
        this._dispatcherManager.uriThumbnail = this.handleThumbnail;
    }


    /* ALLERGENS */

    refreshAllergens() {
        this.setField({ isLoadingAllergens: true });
        this.allergenOperations.get((data: Allergen[]) => this.setField({ allergens: data, isLoadingAllergens: false }));
    }

    handleChangeAllergen(value: boolean, allergen: Allergen) {
        if (value) {
            // Add allergen to list allergens of the store
            var state = this.state();
            state.product.productAllergens.push(
                {
                    allergenId: allergen.id
                });
            this.setField({ state });
        } else {
            // Remove property to list property of the store
            var obj = this.state().product.productAllergens.find((element: ProductAllergen) => element.allergenId == allergen.id);
            if (obj != undefined) {
                this.setField(previousState => previousState.product.productAllergens.splice(this.state().product.productAllergens.indexOf(obj as ProductAllergen), 1));
            }
        }
    }

    /* Additonal Infos */
    handleChangeAdditionalInfos(infos: any) {
        var state = this.state();
        state.product.additionalInfoValues = infos;
        this.setField({ state });
    }

    /* OTHERS */

    handleNavigationTo(page: string) {
        this.setField({ windowTab: page });
        this.refreshAllergens();
    }

    fillAllPicturesWithDefault() {
        if (this.state().useDefaultImageChecked) {
            var list = Helpers.fillAllImagesWithDefault(this.state().product.imageTranslations, this.state().languages) as ImageTranslation[]
            this.setField((previousState) => previousState.product.imageTranslations = list);
        }
    }

    fillAllSecondaryPicturesWithDefault() {
        if (this.state().useDefaultSecondaryImageChecked) {
            var list  = Helpers.fillAllImagesWithDefault(this.state().product.secondaryImageTranslations,this.state().languages) as ImageTranslation[]
            this.setField((previousState) => previousState.product.secondaryImageTranslations = list );
        }
    }

    fillAllThumbnailsWithDefault() {
        if (this.state().useDefaultThumbnailChecked) {
            var list = Helpers.fillAllImagesWithDefault(this.state().product.thumbnailTranslations, this.state().languages) as TranslationValue[]
            this.setField((previousState) => previousState.product.thumbnailTranslations = list);
        }
    }
    
    handleSavePressed(){
        if (
            (!this.state().product.posId && !this.props().nonMandatory?.includes("posId"))
            || (this.state().product.posId === "" && !this.props().nonMandatory?.includes("posId"))
            || (this.state().product.titleTranslations.length !== this.state().languages.length && !this.props().nonMandatory?.includes("title"))
            || (!this.isFilledTranslations() && !this.props().nonMandatory?.includes("title"))
            // this.state().product.descriptionTranslations.length !== this.state().languages.length ||
            // !Helpers.areImagesComplete(this.state().product.imageTranslations) ||
            // !Helpers.areImagesComplete(this.state().product.thumbnailTranslations) ||

        ) {
            this.setField((previousState) => previousState.showErrors = true);
        }
        else {
            this.setField((previousState) => previousState.isLoading = true);
            let product = this.state().product
            if (this.props().isGroup) {
                product.isGroup = true
            }
            this.saveProducts(product);
        }
    }

    saveProducts(product: Product) {

        for (var i = 0; i < this.props().languages.length; i++) {
            if (product.imageTranslations[i].url.includes("data:image"))
                product.imageTranslations[i].imageData = product.imageTranslations[i].url.replace("data:image/png;base64,", "").replace("data:image/jpeg;base64,", "");
            else
                product.imageTranslations[i].imageData = null;

            if (Constants.ShowDeliveryProductTab) {
                if (product.secondaryImageTranslations && product.secondaryImageTranslations[i].url.includes("data:image"))
                    product.secondaryImageTranslations[i].imageData = product.secondaryImageTranslations[i].url.replace("data:image/png;base64,", "").replace("data:image/jpeg;base64,", "");
                else
                    product.secondaryImageTranslations[i].imageData = null;
            }


            if (product.thumbnailTranslations[i].url?.includes("data:image"))
                product.thumbnailTranslations[i].imageData = product.thumbnailTranslations[i].url?.replace("data:image/png;base64,", "").replace("data:image/jpeg;base64,", "");
            else
                product.thumbnailTranslations[i].imageData = null;
        }

        if (product.id !== undefined) {
            this.productsOperations.put(product, () => {
                this.props().refreshParent();
                this.showToast(ToastHelper.getToast("products.popup.success.updated", 'success'), () => { }, 2500)
                this.props().handleBack();
            }, this.errorManagement);
        }
        else {

            this.productsOperations.post(product, () => {
                this.props().refreshParent();
                this.showToast(ToastHelper.getToast("products.popup.success.added", 'success'), () => { }, 2500)
                this.props().handleBack();
            }, this.errorManagement);
        }
    }

    errorManagement(error: any) {
        this.setField({ isLoading: false });
        ErrorHelper.showMessageError(error.response);
    }

    handleBackPressed() {
        this.props().handleBack();
    }
    isFilledTranslations() {
        for (let i = 0; i < this.state().product.titleTranslations.length; i++) {
            if (this.state().product.titleTranslations[i].value === "" || this.state().product.titleTranslations[i].value === " ") {
                return false;
            }
        }
        // for(let i=0;i<this.state().product.descriptionTranslations.length;i++){
        //     if(this.state().product.descriptionTranslations[i].value === "" || this.state().product.descriptionTranslations[i].value === " " ){
        //         return false;
        //     }
        // }
        return true;
    }

    changeTab(tab: any) {
        this.setField({ tab: tab });
    }

    /* URI IMAGE */

    private handleImage(base64: any) {
        var state = this.state();
        TranslationHelper.image(state.product.imageTranslations, state.languages, state.index, base64);
        this.setField({ state });
        this.fillAllPicturesWithDefault();
    }

    private handleSecondaryImage(base64: any) {
        var state = this.state();
        TranslationHelper.image(state.product.secondaryImageTranslations, state.languages, state.secondaryIndex, base64);
        this.setField({ state });
        this.fillAllSecondaryPicturesWithDefault();
    }

    private handleThumbnail(base64: any) {
        var state = this.state();
        TranslationHelper.imageTranslation(state.product.thumbnailTranslations, state.languages, state.indexThumbnail, base64);
        this.setField({ state });
        this.fillAllThumbnailsWithDefault();
    }

    /* INDEX IMAGE */

    private setIndexImage(index: number) {
        this.setField((previousState) => previousState.index = index);
    }

    private setSecondaryIndexImage(index: number) {
        this.setField((previousState) => previousState.secondaryIndex = index);
    }

    private setIndexThumbnail(index: number) {
        this.setField((previousState) => previousState.indexThumbnail = index);
    }

    /* VALUES */

    private handlePosIdChanged(POSId: string) {
        this.setField((previousState) => previousState.product.posId = POSId);
    }

    private handleOrderIndexChanged(orderIndex: string) {
        this.setField((previousState) => previousState.product.orderIndex = orderIndex);
    }

    private handleSecondaryOrderIndexChanged(secondaryOrderIndex: string) {
        this.setField((previousState) => previousState.product.secondaryOrderIndex = secondaryOrderIndex);
    }

    /* TRANSLATIONS */

    private handleTitleChanged(text: string, index: number) {
        var state = this.state();
        TranslationHelper.translation(state.product.titleTranslations, state.languages, index, text);
        this.setField({ state });
    }

    private handleDescriptionChanged(text: string, index: number) {
        var state = this.state();
        TranslationHelper.translation(state.product.descriptionTranslations, state.languages, index, text);
        this.setField({ state });
    }

    private handleSecondaryTitleChanged(text: string, index: number) {
        var state = this.state();
        TranslationHelper.translation(state.product.secondaryTitleTranslations, state.languages, index, text);
        this.setField({ state });
    }

    private handleSecondaryDescriptionChanged(text: string, index: number) {
        var state = this.state();
        TranslationHelper.translation(state.product.secondaryDescriptionTranslations, state.languages, index, text);
        this.setField({ state });
    }

    /* CHECKBOX */

    private handleDefaultCheckBoxChanged(value: boolean) {
        this.setField((previousState) => previousState.useDefaultImageChecked = value, () => {
            this.fillAllPicturesWithDefault();
        });
    }

    private handleSecondaryDefaultCheckBoxChanged(value: boolean) {
        this.setField((previousState) => previousState.useDefaultSecondaryImageChecked = value, () => {
            this.fillAllSecondaryPicturesWithDefault();
        });
    }

    private handleDefaultCheckBoxThumbnailChanged(value: boolean) {
        this.setField((previousState) => previousState.useDefaultThumbnailChecked = value, () => {
            this.fillAllThumbnailsWithDefault();
        });
    }

    private handlePreviewChanged(value: boolean) {
        this.setField((previousState) => previousState.product.isPreview = value);
    }

    private handleAnonymousChanged(value: boolean) {
        this.setField((previousState) => previousState.product.isAnonymousVisible = value);
    }

    private handleShownInCrossSell(value: boolean) {
        this.setField((previousState) => previousState.product.isShownInCrossSell = value);
    }
}