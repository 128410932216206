import {ContentShellViewModel, ContentShellView} from '@levelapp/softfabric';
import ShellCouponsViewState from './ShellCouponsViewState';
import ShellCouponsViewProps from './ShellCouponsViewProps';
import CouponsView from './pages/coupons/CouponsView';
 
import EnumModules from '../../../../../Common/Constants/EnumModules';

export default class ShellCouponsViewModel extends ContentShellViewModel<ShellCouponsViewState, ShellCouponsViewProps>
{
    constructor(view : ContentShellView)
    {
        super(view);

        this.registerEntryPoint('main');
        this.register(<CouponsView type={'coupons'}/> ,'main');

        this.initialisation();
    }

    getModuleId()
    {
        return EnumModules.COUPONS;
    }
}