import { StyleProp, TextStyle, ViewStyle } from 'react-native';

import { ContentViewStyle } from '@levelapp/softfabric';

export default class TreeMenuViewStyle extends ContentViewStyle {
  treeElement: StyleProp<ViewStyle> = {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    maxWidth: 500,
    marginTop: 5,

    borderColor: '#F1F1F1',
    borderWidth: 1,

    backgroundColor: '#FDFDFD',
    height: 44,
    paddingHorizontal: 15,
    position: 'relative',

    shadowColor: "rgba(210,210,210,0.5)",
    shadowOffset: {
      width: 0,
      height: 7,
    },
    shadowOpacity: 0.41,
    shadowRadius: 9.11,
    
    elevation: 14,
  }

  sideGroup: StyleProp<ViewStyle> = {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  }

  hidden: StyleProp<ViewStyle> = {
    backfaceVisibility: 'hidden',
  }

  leftContent: StyleProp<ViewStyle> = {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  }

  label: StyleProp<TextStyle> = {
    marginLeft: 10,
  }

  rightButton: StyleProp<ViewStyle> = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'white',
    borderWidth: 1,
    borderColor: '#E8E7E7',
    borderRadius: 50,
    width: 28,
    height: 28,
    marginLeft: 10,
  }

  rightButtons: StyleProp<TextStyle> = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'row',
  }

  count: StyleProp<TextStyle> = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#D83F28',
    width: 29,
    height: 20,
    borderRadius: 50,
    marginLeft: 15,
  }

  horizontal: StyleProp<ViewStyle> = {
    position: 'absolute',
    top: 25,
    left: -35,
    backgroundColor: '#979797',
    height: 2,
    width: 35,
  }

  treeWrapper: StyleProp<ViewStyle> = {
    display: 'flex',
    flexDirection: 'row',
  }

  treeIndicator: StyleProp<ViewStyle> = {
    width: 2,
    height: 'calc(100% - 16px)',
    backgroundColor: '#979797',
    marginRight:  34
  }

  treeChildren: StyleProp<ViewStyle> = {
    flex: 1
  }
}