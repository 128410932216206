 
import {ContentShellView} from '@levelapp/softfabric';
import ShellHealthStatusViewState from './ShellHealthStatusViewState';
import ShellHealthStatusViewProps from './ShellHealthStatusViewProps';
import ShellHealthStatusViewStyle from './ShellHealthStatusViewStyle';
import ShellHealthStatusViewModel from './ShellHealthStatusViewModel';
import {View} from 'react-native';

export default class ShellHealthStatusView extends ContentShellView<ShellHealthStatusViewProps, ShellHealthStatusViewState, ShellHealthStatusViewModel, ShellHealthStatusViewStyle>
{
  /* CONSTRUCTOR */
  constructor(props:ShellHealthStatusViewProps)
  {
    super(props);

    // Binding
    this.bind(new ShellHealthStatusViewModel(this), new ShellHealthStatusViewStyle(this));
  }

  /* RENDERING */
  render()
  {
    return (
      <View style={{flex : 1}}>
        {this.getSwitch()}
      </View>
    );
  }
}