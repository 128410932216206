import {ContentViewModel,ContentView} from '@levelapp/softfabric';
import DeliveryInfoViewState from './DeliveryInfoViewState';
import DeliveryInfoViewProps from './DeliveryInfoViewProps';

export default class DeliveryInfoViewModel extends ContentViewModel<DeliveryInfoViewState, DeliveryInfoViewProps>
{
      /* CONSTRUCTOR */
    constructor(view: ContentView)
    {
        super(view);

        this.initialState({}); // Initialize your state here

        // Bindings

    }
}