import {ContentViewModel,ContentView, LanguageManager, Managers, Application} from '@levelapp/softfabric';
import BasketordertypeViewState from './BasketordertypeViewState';
import BasketordertypeViewProps from './BasketordertypeViewProps';
import BasketOrderType from '../../../../../../../../../Common/DTO/BasketOrderType';
import TranslationValue from '../../../../../../../../../Common/DTO/TranslationValue';

export default class BasketordertypeViewModel extends ContentViewModel<BasketordertypeViewState, BasketordertypeViewProps>
{
    languageManager : LanguageManager;
      /* CONSTRUCTOR */
    constructor(view: ContentView)
    {
        super(view);

        this.initialState({}); // Initialize your state here

        // Bindings
        this.handleTranslations = this.handleTranslations.bind(this);
        this.handleCheckboxBasketOrderTypeChange = this.handleCheckboxBasketOrderTypeChange.bind(this);
        this.showGlobalBasketOrderTypesManagementPopup = this.showGlobalBasketOrderTypesManagementPopup.bind(this);

        this.languageManager = Application.current.resolve<LanguageManager>(Managers.LANGUAGE_MANAGER);
    }

    handleCheckboxBasketOrderTypeChange(value : boolean , basketOrderType : BasketOrderType)
    {
        this.props().handleBasketOrderTypes(value , basketOrderType);
    }

    handleTranslations(basketOrderType : BasketOrderType)
    {
        var translation = basketOrderType.labelTranslations.find(
            (element: TranslationValue) => 
            {
                  return ( element.language.key == this.languageManager.language);
            }
          )
        if(translation == undefined)
        {
            return "No Translation"
        }

        return translation.value;
    }

    showGlobalBasketOrderTypesManagementPopup()
    {
        this.props().navigateOnThePopup();
    }
}