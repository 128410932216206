import { Operation } from "@levelapp/softfabric";
import PermissionService from "../../Proxy/PermissionService";
import Permission from "../../Common/DTO/Permission";

export default class PermissionOperation extends Operation
{
    service : PermissionService;

    constructor()
    {
        super();
        this.service = new PermissionService();
    }

    async get(callback : Function, onFailure : Function)
    {
        await this.service.get(callback, onFailure);
    }

    async getAll(callback : Function)
    {
        await this.service.getAll(callback);
    }

    async delete(permissionId : number , callback : Function , callbackError : Function)
    {
        await this.service.delete(permissionId , callback , callbackError);
    }

    async post(permission : Permission , callback : Function , callbackError : Function)
    {
        await this.service.post(permission, callback, callbackError);
    }
}