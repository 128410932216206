import { Operation } from "@levelapp/softfabric";
import ContentTypeKey from "../Common/DTO/ContentTypeKey";
import ContentTypeKeyService from "../Proxy/ContentTypeKeyService";

export default class ContentTypeKeyOperations extends Operation
{
    service : ContentTypeKeyService;

    constructor()
    {
        super();
        this.service = new ContentTypeKeyService();
    }

    get( callback : Function, onFailure : Function)
    {
        this.service.get( callback,onFailure);
    }

    getContentTypeKeys(contentTypeId : number, callback : Function, onFailure : Function)
    {
        this.service.getContentTypeKeys(contentTypeId, callback,onFailure);
    }

    createContentTypeKey(contentTypeKey : ContentTypeKey, callback : Function, onFailure : Function)
    {
        this.service.createContentTypeKey(contentTypeKey, callback,onFailure);
    }
    delete(contentTypeKeyId : number, callback : Function, onFailure : Function)
    {
        this.service.delete(contentTypeKeyId, callback,onFailure);
    }
}