import {ContentViewStyle} from '@levelapp/softfabric';
import {StyleProp,ViewStyle, TextStyle} from 'react-native';

export default class HeaderViewStyle extends ContentViewStyle
{
    page: StyleProp<ViewStyle> = {
        flex: 1
    }

    boxSubtitle: StyleProp<ViewStyle> =
    {
        flex: 1,
    }

    title: StyleProp<TextStyle> = {
        color: 'white',
        fontSize: 30,
        paddingTop: 30,
        paddingLeft: 54,
    }

    subTitle: StyleProp<TextStyle> = {
        color: 'white',
        fontSize: 17,
        paddingTop: 2,
        marginLeft: 54,
        marginBottom: 10,
        fontWeight: '100'
    }

    buttonBox : StyleProp<ViewStyle> =
    {
        flexDirection: 'row',
        justifyContent: 'flex-end',
        alignItems:'center',
        flex:1,
        padding: 40,
    }

    boxTitle: StyleProp<ViewStyle> = {
        width: '98%',
        minHeight : 141,
        marginTop: -20,
        margin: 'auto',
        borderRadius: 8,
        flexDirection: 'row',
    }

    buttonsContainer: StyleProp<ViewStyle> = {

        flexDirection: 'row',
    }
}