 
import { Image, TouchableOpacity } from 'react-native';
import images from './assets/index';

import { ContentView } from '@levelapp/softfabric';

import CheckboxViewState from './CheckboxViewState';
import CheckboxViewProps from './CheckboxViewProps';
import CheckboxViewStyle from './CheckboxViewStyle';
import CheckboxViewModel from './CheckboxViewModel';

export default class CheckboxView extends ContentView<CheckboxViewProps, CheckboxViewState, CheckboxViewModel, CheckboxViewStyle>
{
  /* CONSTRUCTOR */
  constructor(props: CheckboxViewProps) {
    super(props);

    // Binding
    this.bind(new CheckboxViewModel(this), new CheckboxViewStyle(this));
  }

  /* RENDERING */
  render() {
    const { selected, onPress } = this.props;

    return (
      <TouchableOpacity
        onPress={onPress}
        style={[this.style.checkbox, selected && this.style.selected]}
      >
        <Image resizeMode='contain' style={{ width: 15, height: 15 }} source={images.checked} />
      </TouchableOpacity>
    );
  }
}