import {ContentViewModel,ContentView} from '@levelapp/softfabric';
import SwitchViewState from './SwitchViewState';
import SwitchViewProps from './SwitchViewProps';
import Role from '../../../../../../../../../../../Common/DTO/Role';

export default class SwitchViewModel extends ContentViewModel<SwitchViewState, SwitchViewProps>
{
      /* CONSTRUCTOR */
    constructor(view: ContentView)
    {
        super(view);

        this.initialState({}); // Initialize your state here

        // Bindings

    }

    changeRole(role : Role)
    {
        if(this.props().selectedRole == null || this.props().selectedRole.id != role.id)
        {
            this.props().changeRole(role);
        }
    }

}