 
import {ContentView} from '@levelapp/softfabric';
import NewsViewState from './NewsViewState';
import NewsViewProps from './NewsViewProps';
import NewsViewStyle from './NewsViewStyle';
import NewsViewModel from './NewsViewModel';
import { View } from 'react-native';
import TableView from '../../../../../../../Common/Components/TabelView/TableView';
import ResearchInputView from '../../../../../../../Common/Components/researchinput/ResearchInputView';
import HeaderView from '../../../../components/header/HeaderView';

export default class NewsView extends ContentView<NewsViewProps, NewsViewState, NewsViewModel, NewsViewStyle>
{
  /* CONSTRUCTOR */
  constructor(props:NewsViewProps)
  {
    super(props);

    // Binding
    this.bind(new NewsViewModel(this), new NewsViewStyle(this));
  }

 /* RENDERING */
 render()
 {
   return (
     <View style={this.style.container}>
       <View style={this.style.box}>

          <HeaderView
            title={"news.title"}
            description={"news.description"}
            shouldShowButton={true}
            onButtonPress={this.binding.addField}
            buttonText={"news.button.add"}
          />

          <View style={this.style.boxContainer}>
          <ResearchInputView
              placeholder={this.binding.translate('generic.search')}
              handlerResearching={(searchText: string) => { this.binding.handleResearching(searchText) }}
              handlingClose={() => { this.binding.handleCloseResearching() }} />
            <TableView
              headerTextStyle={this.style.tableHeader}
              backgroundStepColor={this.style.stepColor}
              rowStyle={this.style.rowStyle}
              nothingToDisplayText={this.binding.translate('component.table.noresults')}
              isLastColumnActions={true}
              data={this.state.tableData}
              isLoading={this.state.isLoading}
              headers={this.state.tableHeaders}
              onPageChange={(page: number) => this.binding.onPageChange(page)}
              handleSortChange={(indexHeaderSort : number, sortOrder : string) => {this.binding.onSortChange(indexHeaderSort, sortOrder)}}
              nbPage={this.state.nbPage}
              currentPage={this.state.currentPage - 1}
              isSortable={true}/>
          </View>
         </View>
       </View>
   );
 }
}