import React from 'react';
import { ContentView } from '@levelapp/softfabric';
import HoverableViewState from './HoverableViewState';
import HoverableViewProps from './HoverableViewProps';
import HoverableViewStyle from './HoverableViewStyle';
import HoverableViewModel from './HoverableViewModel';

export default class HoverableView extends ContentView<HoverableViewProps, HoverableViewState, HoverableViewModel, HoverableViewStyle>
{
  /* CONSTRUCTOR */
  constructor(props: HoverableViewProps)
  {
    super(props);

    // Binding
    this.bind(new HoverableViewModel(this), new HoverableViewStyle(this));
  }

  /* RENDERING */
  render()
  {
    const {
      children
    } = this.props;

    const child = typeof children === 'function' ? children(this.state.isHovered) : children;

    return React.cloneElement(React.Children.only(child), {
      onMouseEnter: this.binding.handleMouseEnter,
      onMouseLeave: this.binding.handleMouseLeave
    });
  }
}