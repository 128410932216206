import {ContentViewModel,ContentView} from '@levelapp/softfabric';
import MessageBlockViewState from './MessageBlockViewState';
import MessageBlockViewProps from './MessageBlockViewProps';

export default class MessageBlockViewModel extends ContentViewModel<MessageBlockViewState, MessageBlockViewProps>
{
      /* CONSTRUCTOR */
    constructor(view: ContentView)
    {
        super(view);

        this.initialState({}); // Initialize your state here

        // Bindings

    }
}