import { Service, Application, HttpManager, Managers } from '@levelapp/softfabric';


export default class BoostersService extends Service
{
    httpClient = Application.current.resolve<HttpManager>(Managers.HTTP_MANAGER);

    async get(parameters : any[], onSuccess: Function, onFailure : Function = () => {})
    {       
        this.httpClient.handleSomethingWentWrong = onFailure;
        this.httpClient.handleNotFound = onFailure;

        this.httpClient
        .get('boosters', parameters)
        .then((response : any) => {
            if (response)
            {
                if (response.data)
                {                  
                    onSuccess(response.data);
                }
            }
        });
    }

    post(booster:any, callback : Function = () => {}, onFailure : Function = () => {})
    {
        this.httpClient.handleSomethingWentWrong = onFailure;
        this.httpClient.handleNotFound = onFailure;

        this.httpClient
            .post('boosters', [], booster)
            .then((response : any) => 
            {
                if (response)
                {
                    callback("");
                }
            });
    }

    put(booster:any, callback : Function = () => {}, onFailure : Function = () => {})
    {
        this.httpClient.handleSomethingWentWrong = onFailure;
        this.httpClient.handleNotFound = onFailure;

        this.httpClient
            .put('boosters', [], booster)
            .then((response : any) => 
            {
                if (response)
                {
                    callback("");
                }
            });
    }

    delete(booster:any, callback : Function = () => {}, onFailure : Function = () => {})
    {
        this.httpClient.handleSomethingWentWrong = onFailure;
        this.httpClient.handleNotFound = onFailure;

        this.httpClient
            .delete('boosters/delete/'+booster.id)
            .then((response : any) => 
            {
                if (response)
                {
                    callback("");
                }
            });
    }
}