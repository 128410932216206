import { Operation } from "@levelapp/softfabric";
import UserMetaDataService  from "../Proxy/UserMetaDataService";

export default class UserMetadataUnitsOperations extends Operation{
    
    service : UserMetaDataService;

    constructor(){
        super();
        this.service = new UserMetaDataService();
    }
    getAllMetaData(callBack:Function){
        this.service.getAllMetaData(callBack);
    }

}