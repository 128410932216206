 
import { ContentViewModel, ContentView, Managers, LanguageManager, Application } from '@levelapp/softfabric';
import BannersViewState from './BannersViewState';
import BannersViewProps from './BannersViewProps';
import BannersOperation from '../../../../../../../Transfer/BannersOperation';
import LanguagesOperations from '../../../../../../../Transfer/LanguagesOperations';
import CountryOperations from '../../../../../../../Transfer/CountryOperations';
import Language from '../../../../../../../Common/DTO/Language';
import ErrorHelper from '../../../../../../../Common/Helpers/ErrorHelper';
import Country from '../../../../../../../Common/DTO/Country';
import BannersPopUpView from '../bannerspopup/BannersPopUpView';
import Helpers from '../../../../../../../Common/Helpers/Helpers';
import { TableRow } from '@levelapp/softfabric-ui';
import Banner from '../../../../../../../Common/DTO/Banner';
import ConfirmPopUpView from '../../../../../../../Common/Components/confirmpopup/ConfirmPopUpView';
import ToastHelper from '../../../../../../../Common/Helpers/ToastHelper';

export default class BannersViewModel extends ContentViewModel<BannersViewState, BannersViewProps>
{
    operation: BannersOperation;
    languagesOperation: LanguagesOperations;
    countryOperations: CountryOperations;
    _languages: Language[] = [];
    interval: any;
    searchText: string;
    isSorting: boolean;
    indexHeaderSort: number;
    sortOrder: string;
    sortKey: string[];


    /* CONSTRUCTOR */
    constructor(view: ContentView) {
        super(view);
        this.interval = null;
        this.searchText = '';
        this.isSorting = false;
        this.indexHeaderSort = -1;
        this.sortOrder = 'asc';
        this.sortKey = ['Id', 'Screen', 'Order source', 'Img'];
        this.operation = new BannersOperation();
        this.languagesOperation = new LanguagesOperations();
        this.countryOperations = new CountryOperations();

        /* Initial State */
        this.initialState({ tableData: [], tableHeaders: ['Id', 'Screen', 'Order source', 'Img'], isLoading: true, nbPage: 0, currentPage: 1, isResearching: false, countries: [] });

        this.languagesOperation.get((languages: any) => {
            this._languages = languages.data;
        });

        /* BINDINGS */
        //this.editField = this.editField.bind(this);
        this.addField = this.addField.bind(this);
        //this.deleteField = this.deleteField.bind(this);
        this.refresh = this.refresh.bind(this);
        this.getData = this.getData.bind(this);
        this.onPageChange = this.onPageChange.bind(this);
        this.onSortChange = this.onSortChange.bind(this);
        this.errorManagement = this.errorManagement.bind(this);
        this.handleResearching = this.handleResearching.bind(this);
        this.handleCloseResearching = this.handleCloseResearching.bind(this);
    }

    componentDidMount() {
        this.countryOperations.get((list: Country[]) => {
            this.setField({ countries: list });
            this.refresh();
        })
    }

    addField() {
        this.showPopUp(
            <BannersPopUpView
                banner={{
                    imageTranslation: Helpers.generateUrlTable(this._languages),
                }}
                languages={this._languages}
                refreshParent={this.refresh}
                countries={this.state().countries} />
        );
    }

    handleResearching(searchText: string) {
        this.setField({ isLoading: true, isResearching: true });
        this.searchText = searchText;

        if (this.interval) {
            clearInterval(this.interval);
        }
        this.interval = setTimeout(() => {
            this.onPageChange(0)
        }, 1000)
    }

    onPageChange(page: number) {
        this.state().currentPage = page + 1;
        this.refresh()
    }

    refresh() {
        this.setField({ isLoading: true });
        let parameters = [];
        parameters.push({ key: "page", value: this.state().currentPage }, { key: "itemPage", value: 20 });
        if (this.isSorting) {
            parameters.push(
                { key: 'columnSort', value: this.sortKey[this.indexHeaderSort] },
                { key: 'sortOrder', value: this.sortOrder },
                { key: 'userLanguage', value: Application.current.resolve<LanguageManager>(Managers.LANGUAGE_MANAGER).language });
        }
        if (this.state().isResearching) {
            parameters.push({ key: "search", value: this.searchText });
        }
        this.getData(parameters);
    }

    getData(queryParameters: any[]) {
        this.operation.get(queryParameters, (ret: any) => {
            let tableData: TableRow[] = [];
            ret.data.objects.forEach((banner: any) => {
                let screen = '';
                banner.bannerTags.forEach((tag: any) => {
                    screen += tag.tag.label.key.replace("bannertag.", "") + ' ';
                });
                let src = '';
                if(banner.orderSource)
                    src = banner.orderSource.label;
                let img = banner.imageTranslation != null && banner.imageTranslation.length > 0 ? banner.imageTranslation[0].url : '';
                tableData.push({ data: [banner.id, screen,  src, img], isDeletable: true, isEditable: true, editCallback: () => { this.editField(banner) }, deleteCallback: () => { this.deleteField(banner) } });
            });
            this.setField({ tableData: tableData, bannerts: ret.data.objects, nbPage: ret.data.totalPage, isLoading: false });
        }, this.errorManagement);
    }

    errorManagement(error: any) {
        this.setField({ isLoading: false });
        ErrorHelper.showMessageError(error.response);
    }

    handleCloseResearching() {
        this.state().isResearching = false;
        this.onPageChange(0);
    }

    editField(banner: Banner) {
        //Doesn't edit, just display the popup
        banner.imageTranslation = Helpers.generateUrlTableEdit(banner.imageTranslation, this._languages);

        this.showPopUp(
            <BannersPopUpView refreshParent={this.refresh} banner={Helpers.clone(banner)} languages={this._languages} countries={this.state().countries} />
        );
    }

    deleteField(banner: Banner) {
        this.showPopUp(
            <ConfirmPopUpView callBack={() => {
                this.operation.delete(banner, () => {
                    this.showToast(ToastHelper.getToast("banner.popup.success.deleted","success"),() => {},2500);
                    this.refresh();
                },this.errorManagement);
            }} />
        );
    }

    onSortChange(indexHeaderSort: number, sortOrder: string) {
        if (indexHeaderSort == -1) {
            this.isSorting = false;
        } else {
            this.indexHeaderSort = indexHeaderSort;
            this.sortOrder = sortOrder;
            this.isSorting = true;
        }
        this.refresh();
    }
}