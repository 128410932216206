import {ContentShellViewModel, ContentShellView} from '@levelapp/softfabric';
import ShellLegalTextsViewState from './ShellLegalTextsViewState';
import ShellLegalTextsViewProps from './ShellLegalTextsViewProps';
import LegalTextsView from './pages/legaltexts/LegalTextsView';
import EnumModules from '../../../../../Common/Constants/EnumModules';
 

export default class ShellLegalTextsViewModel extends ContentShellViewModel<ShellLegalTextsViewState, ShellLegalTextsViewProps>
{
    constructor(view : ContentShellView)
    {
        super(view);

        this.registerEntryPoint('main');
        this.register(<LegalTextsView/> ,'main');

        this.initialisation();
    }

    getModuleId()
    {
        return EnumModules.LEGAL_TEXTS;
    }
}