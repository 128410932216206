 
import { ContentView } from '@levelapp/softfabric';
import MainInformationViewState from './MainInformationViewState';
import MainInformationViewProps from './MainInformationViewProps';
import MainInformationViewStyle from './MainInformationViewStyle';
import MainInformationViewModel from './MainInformationViewModel';
import { View } from 'react-native';
import ExtendedTextInputView from '../../../../../../components/extendedtextinput/ExtendedTextInputView';
import ExtendedCheckBoxView from '../../../../../../components/extendedcheckbox/ExtendedCheckBoxView';
import LineView from '../../../../../../components/line/LineView';
import Language from '../../../../../../../../../Common/DTO/Language';
import ExtendedBlockTextInputView from '../../../../../../components/extendedblocktextinput/ExtendedBlockTextInputView';
import SectionTitleView from '../../../../../../components/sectiontitle/SectionTitleView';
import Constants from 'src/Common/Constants/Constants';

export default class MainInformationView extends ContentView<MainInformationViewProps, MainInformationViewState, MainInformationViewModel, MainInformationViewStyle>
{
  /* CONSTRUCTOR */
  constructor(props: MainInformationViewProps) {
    super(props);

    // Binding
    this.bind(new MainInformationViewModel(this), new MainInformationViewStyle(this));
  }

  /* RENDERING */
  render() {
    return (
      <View style={{ display: "flex", flexDirection: "column", paddingHorizontal: 170, paddingVertical: 50 }}>
        <View>
          <SectionTitleView title={'form.informations'} />
          <ExtendedTextInputView
            title={'form.id'}
            showError={false}
            disable
            onChangeText={() => { }}
            value={`${this.props.product.id == undefined ? ' ' : this.props.product.id}`} />
          <ExtendedTextInputView
            title={'form.posid'}
            showError={false}
            infoText={this.binding.translate('generic.posid.info').replace('{1}', this.binding.translate('products.title.singular').toLowerCase())}
            onChangeText={(text: string) => this.props.handleValueChange('posid', text)}
            value={this.props.product.posId} />
          {Constants.ShowProductOrderIndex &&
            <ExtendedTextInputView
              title={'form.index'}
              showError={false}
              onChangeText={(text: string) => this.props.handleValueChange('orderindex', text)}
              value={`${this.props.product.orderIndex == undefined ? ' ' : this.props.product.orderIndex}`} />
          }
          <View style={{ flexDirection: 'row' }}>
            <ExtendedCheckBoxView
              isTranslation
              title={'form.preview'}
              value={this.props.product.isPreview}
              onValueChange={(value: boolean) => this.props.handleCheckboxChange('isPreviewVisible', value)} />
            <ExtendedCheckBoxView
              isTranslation
              title={'form.anonymous'}
              value={this.props.product.isAnonymousVisible}
              onValueChange={(value: boolean) => this.props.handleCheckboxChange('isAnonymousVisible', value)} />
            <ExtendedCheckBoxView
              isTranslation
              title={'form.crosssell'}
              value={this.props.product.isShownInCrossSell}
              onValueChange={(value: boolean) => this.props.handleCheckboxChange('isShownInCrossSell', value)} />
          </View>
        </View>

        <LineView />
        <SectionTitleView title={'form.titlefield'} />

        <View style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', justifyContent: 'space-between' }}>
          {this.props.languages.map((content: Language) => {
            var result = this.props.product.titleTranslations.find((x: any) => x.language.id == content.id);
            var _text = result ? result.value : "";
            return (
              <ExtendedBlockTextInputView
                value={_text}
                title={content.key.toUpperCase()}
                showError={this.props.showErrors && (_text == null || _text == "")}
                onChangeText={(text: string) => this.props.handleTranslationsChange('title', text, content.id)}
              />
            );
          })}
        </View>
      </View>
    );
  }
}