import { ContentViewModel, ContentView } from '@levelapp/softfabric';
import PromoCodeBatchPopupViewState from './PromoCodeBatchPopupViewState';
import PromoCodeBatchPopupViewProps from './PromoCodeBatchPopupViewProps';
import DispatcherManager from '../../../../../../../Common/Managers/DispatcherManager';
import TranslationHelper from '../../../../../../../Common/Helpers/TranslationHelper';
import DateHelper from '../../../../../../../Common/Helpers/DateHelper';
import ToastHelper from '../../../../../../../Common/Helpers/ToastHelper';
import PromoCodesOperations from '../../../../../../../Transfer/PromoCodesOperations';
import ErrorHelper from '../../../../../../../Common/Helpers/ErrorHelper';
import Country from '../../../../../../../Common/DTO/Country';

export default class PromoCodeBatchPopupViewModel extends ContentViewModel<PromoCodeBatchPopupViewState, PromoCodeBatchPopupViewProps>
{
    _dispatcherHelper : DispatcherManager;
    _promoCodesOperation: PromoCodesOperations;

    constructor(props: ContentView)
    {
        super(props);

        /* Initial State */
        this.initialState({
            promocodebatch: this.props().promocodebatch,
            languages: this.props().languages,
            showErrors: false,
            tab: "main",
            isLoading: false,
            countries: this.props().countries,
        });

        this._dispatcherHelper = new DispatcherManager();
        this._promoCodesOperation = new PromoCodesOperations();

        /* BINDINGS */
        this.changeTab = this.changeTab.bind(this);
        this.handleSavePressed = this.handleSavePressed.bind(this);
        this.handleValueChange = this.handleValueChange.bind(this);
        this.handleSingleCodeChange = this.handleSingleCodeChange.bind(this);
        this.handleNumberChange = this.handleNumberChange.bind(this);
        this.handleCodeChange = this.handleCodeChange.bind(this);
        this.handleEndDateChanged = this.handleEndDateChanged.bind(this);
        this.handleStartDateChanged = this.handleStartDateChanged.bind(this);
        this.handlePOSIdChange = this.handlePOSIdChange.bind(this);
        this.handleTitleChanged = this.handleTitleChanged.bind(this);
        this.handleDescriptionChanged = this.handleDescriptionChanged.bind(this);
        this.handleTypeChange = this.handleTypeChange.bind(this);
        this.handleProductPOSIdChange = this.handleProductPOSIdChange.bind(this);
        this.handleRequiredPOSIdChange = this.handleRequiredPOSIdChange.bind(this);
        this.handleMinimumValueChange = this.handleMinimumValueChange.bind(this);
        this.savePCB = this.savePCB.bind(this);
        this.errorManagement = this.errorManagement.bind(this);
        this.handleCountryChanged = this.handleCountryChanged.bind(this);
    }

    componentDidMount()
    {
        this._dispatcherHelper.valueValue = this.handleValueChange;
        this._dispatcherHelper.valueNumber = this.handleNumberChange;
        this._dispatcherHelper.valuePromoCode = this.handleCodeChange;
        this._dispatcherHelper.checkboxSingleCode = this.handleSingleCodeChange;
        this._dispatcherHelper.dateEndDate = this.handleEndDateChanged;
        this._dispatcherHelper.dateStartDate = this.handleStartDateChanged;
        this._dispatcherHelper.valuePosId = this.handlePOSIdChange;
        this._dispatcherHelper.translationTitle = this.handleTitleChanged;
        this._dispatcherHelper.translationDescription = this.handleDescriptionChanged;
        this._dispatcherHelper.checkboxIsFreeProduct = this.handleTypeChange;
        this._dispatcherHelper.valueProductId = this.handleProductPOSIdChange;
        this._dispatcherHelper.valueRequiredProductId = this.handleRequiredPOSIdChange;
        this._dispatcherHelper.valueMinimum = this.handleMinimumValueChange;
        this._dispatcherHelper.valueCountry = this.handleCountryChanged;
    }

    /* COMPONENT EVENTS */
    componentWillMount() { }

    /* OTHERS */

    changeTab(tab: any) {
        this.setField({tab :tab});
    }

    handleSavePressed()
    {
        if (
            (!this.state().promocodebatch.isSingleCode && this.state().promocodebatch.numberToGenerate == null && this.state().promocodebatch.id === undefined) ||
            (!this.state().promocodebatch.isFreeProduct && (this.state().promocodebatch.value == null || this.state().promocodebatch.value == "")) ||
            this.state().promocodebatch.posId == null || this.state().promocodebatch.posId == "" ||
            this.state().promocodebatch.StartDateInput == null || this.state().promocodebatch.EndDateInput == null)
        {
            this.setField((previousState) => previousState.showErrors = true);
        }
        else if(this.state().promocodebatch.id === undefined && this.state().promocodebatch.isSingleCode && (this.state().promocodebatch.code == "" || this.state().promocodebatch.code == null))
        {
            this.setField((previousState) => previousState.showErrors = true);
        }
        else if(this.state().promocodebatch.isFreeProduct && (this.state().promocodebatch.product.posId == "" || this.state().promocodebatch.product.posId == null))
        {
            this.setField((previousState) => previousState.showErrors = true);
        }
        else
        {
            this.setField((previousState) => previousState.isLoading = true,() => this.savePCB(this.state().promocodebatch));
        }
    }

    savePCB(promocodebatch: any) {
        var startResult = DateHelper.changeDateToBeValidUTC(promocodebatch.StartDateInput);      
        var endResult = DateHelper.changeDateToBeValidUTC(promocodebatch.EndDateInput);
        promocodebatch.startDate = startResult;
        promocodebatch.endDate = endResult;

        if (promocodebatch.id !== undefined) {
            this._promoCodesOperation.put(promocodebatch, () => {
                this.props().refreshParent();
                this.showToast(ToastHelper.getToast("promocodes.popup.success.updated","success"),() => {},2500);
                this.closePopUp();
            },this.errorManagement);
        }
        else {
            this._promoCodesOperation.post(promocodebatch, () => {
                this.props().refreshParent();
                this.showToast(ToastHelper.getToast("promocodes.popup.success.added","success"),() => {},2500);
                this.closePopUp();
            },this.errorManagement);
        }
    }

    errorManagement(error : any) 
    {
        this.setField({isLoading : false});
        ErrorHelper.showMessageError(error.response);
    }

    /* VALUES */

    private handleValueChange(value: string)
    {
        this.setField((previousState) => previousState.promocodebatch.value = value);
    }

    private handleNumberChange(number: string)
    {
        this.setField((previousState) => previousState.promocodebatch.numberToGenerate = number);
        this.setField((previousState) => previousState.promocodebatch.NumberOfCodes = number);
    }

    private handleCodeChange(code: string)
    {
        this.setField((previousState) => previousState.promocodebatch.code = code);
    }

    private handlePOSIdChange(posId: string)
    {
        this.setField((previousState) => previousState.promocodebatch.posId = posId);
    }

    private handleProductPOSIdChange(posId: string)
    {
        this.setField((previousState) => previousState.promocodebatch.product.posId = posId);
    }

    private handleRequiredPOSIdChange(posId: string)
    {
        this.setField((previousState) => previousState.promocodebatch.requiredProduct.posId = posId);
    }

    private handleMinimumValueChange(value: string)
    {
        this.setField((previousState) => previousState.promocodebatch.minimumValue = value);
    }

    private handleCountryChanged(value: Country[])
    {
        var state = this.state();
        state.promocodebatch.countryPromoCodeBatches = [];

        value.forEach((country: Country) =>
        {
            state.promocodebatch.countryPromoCodeBatches.push({ countryId: country.id, promoCodeBatchId: Number(state.promocodebatch.id) });
        })

        this.setField({ state });
    }

    /* DATE */

    private handleStartDateChanged(text: Date)
    {
        this.setField((previousState) => previousState.promocodebatch.StartDateInput = text);
    }

    private handleEndDateChanged(text: Date)
    {
        this.setField((previousState) => previousState.promocodebatch.EndDateInput = text);
    }

    /* TRANSLATIONS */

    private handleTitleChanged(text: any, index: any)
    {
        var state = this.state();
        TranslationHelper.translation(state.promocodebatch.titleTranslations,state.languages,index,text);
        this.setField({state});
    }

    private handleDescriptionChanged(text: any, index: any)
    {
        var state = this.state();
        TranslationHelper.translation(state.promocodebatch.descriptionTranslations,state.languages,index,text);
        this.setField({state});
    }

    /* CHECKBOX */

    private handleSingleCodeChange(value: boolean)
    {
        this.setField((previousState) => previousState.promocodebatch.isSingleCode = value);
    }

    private handleTypeChange(value: boolean)
    {
        this.setField((previousState) => previousState.promocodebatch.isFreeProduct = value);

        if(value)
        {
            this.setField((previousState) => previousState.promocodebatch.type = 2);
        }
        else
        {
            this.setField((previousState) => previousState.promocodebatch.type = 1);
        }
    }

    
}