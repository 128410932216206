 
import { ContentView } from '@levelapp/softfabric';
import ChangeStatusPopupViewState from './ChangeStatusPopupViewState';
import ChangeStatusPopupViewProps from './ChangeStatusPopupViewProps';
import ChangeStatusPopupViewStyle from './ChangeStatusPopupViewStyle';
import ChangeStatusPopupViewModel from './ChangeStatusPopupViewModel';
import { TranslationView, ButtonView } from '@levelapp/softfabric-ui';
import { View, Text } from 'react-native';

export default class ChangeStatusPopupView extends ContentView<ChangeStatusPopupViewProps, ChangeStatusPopupViewState, ChangeStatusPopupViewModel, ChangeStatusPopupViewStyle>
{
  /* CONSTRUCTOR */
  constructor(props: ChangeStatusPopupViewProps)
  {
    super(props);

    // Binding
    this.bind(new ChangeStatusPopupViewModel(this), new ChangeStatusPopupViewStyle(this));
  }

  /* RENDERING */
  render()
  {
    return (
      <View style={this.style.container}>
        <TranslationView style={{ marginBottom: 30 }}>kitchenapp.selectnextstatus</TranslationView>
        <View style={this.style.buttonsContainer}>
          {this.props.statuses.map((st, i) =>
          {
            const label = st.label.translationValues.find(x => x.language.key == this.props.language)?.value ?? '';

            return (
              <ButtonView key={i} isLoading={this.state.statusesLoading[i]} handleClick={() => { if (this.binding.isButtonEnabled()) this.binding.handleButtonPressed(st, i) }} backgroundColor={st.labelColor} animation="Bounce" borders="None" style={this.style.buttonStyle}>
                <Text style={this.style.buttonTxt}>{label}</Text>
              </ButtonView>
            );
          })}
        </View>
      </View>
    );
  }
}