import { ContentView, ContentViewModel } from "@levelapp/softfabric";
import { ContentTypeEnum } from "../../../../../../../Common/Enums/ContentTypeEnum";
import Constants from "../../../../../../../Common/Constants/Constants";
import Country from "../../../../../../../Common/DTO/Country";
import RewardTag from "../../../../../../../Common/DTO/RewardTag";
import TranslationValue from "../../../../../../../Common/DTO/TranslationValue";
import ErrorHelper from "../../../../../../../Common/Helpers/ErrorHelper";
import Helpers from "../../../../../../../Common/Helpers/Helpers";
import ToastHelper from "../../../../../../../Common/Helpers/ToastHelper";
import TranslationHelper from "../../../../../../../Common/Helpers/TranslationHelper";
import DispatcherManager from "../../../../../../../Common/Managers/DispatcherManager";
import BannersOperation from "../../../../../../../Transfer/BannersOperation";
import { OrderSourceOperations } from "../../../../../../../Transfer/OrderSourceOperations";
import TagOperations from "../../../../../../../Transfer/TagOperations";
import BannersPopUpViewProps from "./BannersPopUpViewProps";
import BannersPopUpViewState from "./BannersPopUpViewState";

interface TagListItem {
    id: number;
    key: string;
    title: string;
}

export default class BannersPopUpViewModel extends ContentViewModel<
    BannersPopUpViewState,
    BannersPopUpViewProps
> {
    _dispatcherManager: DispatcherManager;
    tagOperations: TagOperations;
    tagKey: string = "bannertag";
    orderSourceOperations: OrderSourceOperations;
    operation: BannersOperation;

    /* CONSTRUCTOR */
    constructor(view: ContentView) {
        super(view);
        /* Initial State */
        this.initialState({
            banner: this.props().banner,
            languages: this.props().languages,
            showErrors: false,
            index: 0,
            indexThumbnail: 0,
            useDefaultImageChecked: Helpers.hasDuplicates(
                this.props().banner.imageTranslation
            ),
            tab: "main",
            isLoading: false,
            color: Constants.MainColor,
            selectedCountries: [],
            selectedScreens: [],
            tags: [],
            filters: [],
        });

        /* BINDINGS */
        this.handleSavePressed = this.handleSavePressed.bind(this);
        this.changeTab = this.changeTab.bind(this);
        this.handleDefaultCheckBoxChanged =
            this.handleDefaultCheckBoxChanged.bind(this);
        this.handleImage = this.handleImage.bind(this);
        this.setIndexImage = this.setIndexImage.bind(this);
        this.addTag = this.addTag.bind(this);
        this.handleOrderSourceRecieved =
            this.handleOrderSourceRecieved.bind(this);
        this.handleDropDownChange = this.handleDropDownChange.bind(this);
        this.handleCountryChanged = this.handleCountryChanged.bind(this);
        this.errorManagement = this.errorManagement.bind(this);

        this._dispatcherManager = new DispatcherManager();
        this.tagOperations = new TagOperations();
        this.orderSourceOperations = new OrderSourceOperations();
        this.operation = new BannersOperation();
    }

    componentDidMount() {
        this._dispatcherManager.checkboxDefaultImage =
            this.handleDefaultCheckBoxChanged;
        this._dispatcherManager.uriImage = this.handleImage;
        this._dispatcherManager.numberIndexImage = this.setIndexImage;
        this._dispatcherManager.valueCountry = this.handleCountryChanged;

        this.orderSourceOperations.get(this.handleOrderSourceRecieved);
    }

    private handleOrderSourceRecieved(orderSources: any[]) {
        orderSources.forEach((element) => {
            element.title = element.label;
        });
        let banner = this.state().banner;
        if (!banner.orderSource) banner.orderSource = orderSources[0];
        this.setField({ filters: orderSources, banner });
    }

    handleSavePressed() {
        let banner = this.state().banner;
        if (!banner.tags) {
            alert(this.translate("banner.popup.selectScreen"));
            return;
        }

        if (
            banner.imageTranslation.find((x) =>
                x.url?.includes(
                    "https://appstoragflyx.blob.core.windows.net/app/Console/unavailable.svg"
                )
            )
        ) {
            alert(this.translate("error.please.images"));
            return;
        }
        this.setField({ isLoading: true });
        if (banner.id !== undefined) {
            this.operation.put(
                banner,
                () => {
                    this.props().refreshParent();
                    this.setField({ isLoading: false });
                    this.showToast(
                        ToastHelper.getToast(
                            "banner.popup.success.updated",
                            "success"
                        ),
                        () => {},
                        2500
                    );
                    this.closePopUp();
                },
                this.errorManagement
            );
        } else {
            this.operation.post(
                banner,
                () => {
                    this.props().refreshParent();
                    this.setField({ isLoading: false });
                    this.showToast(
                        ToastHelper.getToast(
                            "banner.popup.success.added",
                            "success"
                        ),
                        () => {},
                        2500
                    );
                    this.closePopUp();
                },
                this.errorManagement
            );
        }
    }

    errorManagement(error: any) {
        //set loading : false
        this.setField({ isLoading: false });
        ErrorHelper.showMessageError(error.response);
    }

    changeTab(tab: any) {
        this.setField((previousState) => (previousState.tab = tab));
    }

    private handleDefaultCheckBoxChanged(value: boolean) {
        this.setField(
            (previousState) => (previousState.useDefaultImageChecked = value),
            () => {
                this.fillAllPicturesWithDefault();
            }
        );
    }

    private setIndexImage(index: number) {
        this.setField((previousState) => (previousState.index = index));
    }

    fillAllPicturesWithDefault() {
        if (this.state().useDefaultImageChecked) {
            var state = this.state();
            var list = Helpers.fillAllImagesWithDefault(
                state.banner.imageTranslation,
                state.languages
            ) as TranslationValue[];
            this.setField(
                (prevouisState) =>
                    (prevouisState.banner.imageTranslation = list)
            );
        }
    }

    getFormattedRewardTags(): TagListItem[] {
        const list: RewardTag[] = [];

        this.state().tags.forEach((rewardTag) => {
            if (list.find((item) => item.tagId === rewardTag.tagId)) {
                return;
            }
            list.push(rewardTag);
        });
        return this.formatTags(list);
    }

    private formatTags(rewardTags: RewardTag[]): TagListItem[] {
        return rewardTags.map((rewardTag) => {
            return {
                id: rewardTag.tag.id as number,
                key: rewardTag.tag.label.key,
                title: rewardTag.tag.label.translationValues[0].value,
            };
        });
    }

    private handleImage(base64 : any) {
        var state = this.state();
        TranslationHelper.imageTranslation(
            state.banner.imageTranslation,
            state.languages,
            state.index,
            base64
        );
        this.setField({ state });
        this.fillAllPicturesWithDefault();
    }

    getLinkedTags(): TagListItem[] {
        const { banner } = this.state();
        if (banner.bannerTags === undefined) return [];
        const list = banner.bannerTags?.filter((tag) => {
            return tag != null;
        });

        return this.formatTags(list);
    }

    removeTag(tag: TagListItem): void {
        const { banner } = this.state();
        let tagsFromState = this.state().tags;

        banner.bannerTags = banner.bannerTags?.filter(
            (x) => x.tagId !== tag.id
        );
        banner.tags = banner.tags?.filter((tagKey) => tagKey !== tag.key);
        tagsFromState = tagsFromState.filter((x) => x.tagId !== tag.id);
        this.setField({ banner, tags: tagsFromState });
    }

    addTag(tag: any): void {
        const payload = {
            prefixKey: this.tagKey,
            label: tag.title,
            contentTypeId: ContentTypeEnum.BANNER
        };
        this.tagOperations.post(
            payload,
            (response: any) => {
                const key = `${this.tagKey}.${tag.title
                    .trim()
                    .replace(" ", ".")}`;
                const { banner, tags } = this.state();
                const newRewardTag = {
                    rewardId: banner.id as number,
                    tagId: response.id,
                    tag: response,
                };

                tags.push(newRewardTag);

                if (!banner.tags) banner.tags = [];
                if (!banner.bannerTags) banner.bannerTags = [];

                banner.tags.push(key);
                banner.bannerTags?.push(newRewardTag);

                this.setField({ banner, tags });
            },
            this.handleError
        );
    }

    handleDropDownChange(value: { id: number; label: string; title: string }) {
        let banner = this.state().banner;
        banner.orderSource = value;
        banner.orderSourceId = value.id;
        this.setField({ banner });
    }

    private handleCountryChanged(value: Country[]) {
        var state = this.state();
        state.banner.countries = [];

        value.forEach((country: Country) => {
            state.banner.countries.push({
                id: country.id,
                countryCode: country.countryCode,
                labelTranslations: [],
            });
        });

        this.setField({ state });
    }

    private handleError(): void {}
}
