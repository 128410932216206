 
import {ContentShellView} from '@levelapp/softfabric';
import ShellMessagesViewState from './ShellMessagesViewState';
import ShellMessagesViewProps from './ShellMessagesViewProps';
import ShellMessagesViewStyle from './ShellMessagesViewStyle';
import ShellMessagesViewModel from './ShellMessagesViewModel';
import {View} from 'react-native';

export default class ShellMessagesView extends ContentShellView<ShellMessagesViewProps, ShellMessagesViewState, ShellMessagesViewModel, ShellMessagesViewStyle>
{
  /* CONSTRUCTOR */
  constructor(props:ShellMessagesViewProps)
  {
    super(props);

    // Binding
    this.bind(new ShellMessagesViewModel(this), new ShellMessagesViewStyle(this));
  }

  /* RENDERING */
  render()
  {
    return (
      <View style={{flex : 1}}>
        {this.getSwitch()}
      </View>
    );
  }
}