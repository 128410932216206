import {ContentViewModel,ContentView, Application, LanguageManager, Managers} from '@levelapp/softfabric';
import AutomatedMessagesViewState from './AutomatedMessagesViewState';
import AutomatedMessagesViewProps from './AutomatedMessagesViewProps';
import LanguagesOperations from '../../../../../../../Transfer/LanguagesOperations';
import AutomatedMessagePopUpView from '../automatedmessagepopup/AutomatedMessagePopUpView';
import AutomatedMessageHistory from '../../../../../../../Common/DTO/AutomatedMessageHistory';
 
import AutomatedMessagesOperations from '../../../../../../../Transfer/AutomatedMessagesOperations';
import TableRow from '../../../../../../../Common/Components/TabelView/TableRow';
import Helpers from '../../../../../../../Common/Helpers/Helpers';
import Language from '../../../../../../../Common/DTO/Language';
import PaginationHelper from '../../../../../../../Common/Helpers/PaginationHelper';
//import Constants from '../../../../../../../Common/Constants/Constants';

export default class AutomatedMessagesViewModel extends ContentViewModel<AutomatedMessagesViewState, AutomatedMessagesViewProps>
{
    languageOperations : LanguagesOperations;
    automatedMessagesOperations : AutomatedMessagesOperations;

    _languages: Language[] = [];
    interval: any;
    searchText: string;
    isSorting: boolean;
    indexHeaderSort: number;
    sortOrder: string;
    sortKey: string[];

      /* CONSTRUCTOR */
    constructor(view: ContentView)
    {
        super(view);

        this.languageOperations = new LanguagesOperations();
        this.automatedMessagesOperations = new AutomatedMessagesOperations();

        this.interval = null;
        this.searchText = '';
        this.isSorting = false;
        this.indexHeaderSort = -1;
        this.sortOrder = 'asc';
        this.sortKey = ['Function', 'Title'];

        this.languageOperations.get((languages:any)=>{
            this._languages = languages.data;
        })

        this.initialState({tableData: [], tableHeaders: ['Function', 'Title', 'Type', 'Activated'], isLoading: true, nbPage: 0, currentPage: 1, isResearching: false });
        // Bindings
        this.editField = this.editField.bind(this);
        this.save = this.save.bind(this);
        this.onPageChange = this.onPageChange.bind(this);
        this.onSortChange = this.onSortChange.bind(this);
        this.handleResearching = this.handleResearching.bind(this);
        this.handleCloseResearching = this.handleCloseResearching.bind(this);
    }

    componentDidMount(){
        this.refresh();
    }

    refresh(){
        this.setField({isLoading:true});
        let parameters = PaginationHelper.basicResearchPush(
            this.state().currentPage,
            20,
            this.sortKey[this.indexHeaderSort],
            this.sortOrder,
            Application.current.resolve<LanguageManager>(Managers.LANGUAGE_MANAGER).language ,
            this.searchText,
            this.isSorting,
            this.state().isResearching);
        this.getData(parameters);
    }

    getData(parameters: any[]){
        this.automatedMessagesOperations.get(parameters, (automatedMessages: any) => {
            let tabledata: TableRow[] = [];
            automatedMessages.data.objects.forEach((automatedMessageHistory: AutomatedMessageHistory) => {
                if(automatedMessageHistory.titleTranslations == null)
                    automatedMessageHistory.titleTranslations = Helpers.generateTranslationTable(this._languages);

                if(automatedMessageHistory.messageTranslations == null)
                    automatedMessageHistory.messageTranslations = Helpers.generateTranslationTable(this._languages);
                
                let title = automatedMessageHistory.titleTranslations[0].value;
                let type = (automatedMessageHistory.isEmail ? " - Email\n" : "" );
                type+= automatedMessageHistory.isMessageCenter ? " - In app\n" : "";
                type+= automatedMessageHistory.isPushNotification ? " - Notification" : "";

                let image = automatedMessageHistory.isActivated ? "http-on-icon": "http-off-icon";
                tabledata.push({data: [automatedMessageHistory.automatedMessage.automatedMessageType.functionName,title, type, image  ], isEditable:true, editCallback: () => {this.editField(automatedMessageHistory)}});
            });
            this.setField({tableData: tabledata, automatedMessages: automatedMessages.data.objects,nbPage: automatedMessages.data.totalPage, isLoading:false });
        });
    }

    

    save(automatedMessage: AutomatedMessageHistory){
        this.automatedMessagesOperations.post(automatedMessage, () => {
            this.refresh();
            this.closePopUp();
        });
    }

    editField(automatedMessageHistory:AutomatedMessageHistory){
        this.showPopUp(
            <AutomatedMessagePopUpView languages={this._languages} automatedMessageHistory={automatedMessageHistory} save={this.save}/>
        );
    }

    onSortChange(indexHeaderSort: number, sortOrder: string) {
        if (indexHeaderSort == -1) {
            this.isSorting = false;
        } else {
            this.indexHeaderSort = indexHeaderSort;
            this.sortOrder = sortOrder;
            this.isSorting = true;
        }
        this.refresh();
    }
    
    handleResearching(searchText: string) {
        this.setField({ isLoading: true, isResearching: true });
        this.searchText = searchText;

        if (this.interval) {
            clearInterval(this.interval);
        }
        this.interval = setTimeout(() => {
            this.onPageChange(0)
        }, 1000)
    }

    handleCloseResearching() {
        this.state().isResearching = false;
        this.onPageChange(0);
    }

    onPageChange(page: number){
        this.state().currentPage = page + 1;
        this.refresh()
    }
}