 
import { ContentShellView} from '@levelapp/softfabric';
import ShellBoostersViewState from './ShellBoostersViewState';
import ShellBoostersViewProps from './ShellBoostersViewProps';
import ShellBoostersViewStyle from './ShellBoostersViewStyle';
import ShellBoostersViewModel from './ShellBoostersViewModel';
import { View } from 'react-native';

export default class ShellBoostersView extends ContentShellView<ShellBoostersViewProps, ShellBoostersViewState, ShellBoostersViewModel, ShellBoostersViewStyle>
{
  /* CONSTRUCTOR */
  constructor(props:ShellBoostersViewProps)
  {
    super(props);

    // Binding
    this.bind(new ShellBoostersViewModel(this), new ShellBoostersViewStyle(this));
  }

  /* RENDERING */
  render()
  {
    return (
      <View style={{flex : 1}}>
        {this.getSwitch()}
      </View>
    );
  }
}