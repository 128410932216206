import ProductsOperations from 'src/Transfer/ProductsOperations';
import Product from 'src/Common/DTO/Product';
import TranslationValue from 'src/Common/DTO/TranslationValue';
import ProductGroupOperations from 'src/Transfer/ProductGroupOperations';

import { ContentViewModel, ContentView } from '@levelapp/softfabric';

import UpsertProductGroupViewState from './UpsertProductGroupViewState';
import UpsertProductGroupViewProps from './UpsertProductGroupViewProps';
import ToastHelper from 'src/Common/Helpers/ToastHelper';
import Constants from 'src/Common/Constants/Constants';

export default class UpsertProductGroupViewModel extends ContentViewModel<UpsertProductGroupViewState, UpsertProductGroupViewProps>
{
  productOperations: ProductsOperations;
  productGroupOperations: ProductGroupOperations;

  /* CONSTRUCTOR */
  constructor(view: ContentView) {
    super(view);

    this.initialState({
      products: [],
      selectedProducts: [],
      groupName: "",
      productsAreLoading: false,
      showError: false,
      selectedTypeItem: 'group',
      search: '',
      isSaving: false,
      shouldAddAnotherProduct: false,
    });

    this.productOperations = new ProductsOperations();
    this.productGroupOperations = new ProductGroupOperations();

    // Bindings
    this.onPopupClose = this.onPopupClose.bind(this);
    this.getProductTranslation = this.getProductTranslation.bind(this);
    this.updateProducts = this.updateProducts.bind(this);
    this.handleGroupNameChanged = this.handleGroupNameChanged.bind(this);
    this.onProductGroupSaved = this.onProductGroupSaved.bind(this);
    this.onItemPress = this.onItemPress.bind(this);
    this.onSearchChange = this.onSearchChange.bind(this);
    this.clearSearch = this.clearSearch.bind(this);
    this.onAddAnotherPress = this.onAddAnotherPress.bind(this);
  }

  onPopupClose() {
    this.props().closePopUp();
  }

  componentDidMount() {
    this.refresh();

    if (this.props().productGroup) {
      this.setField({ groupName: this.props().productGroup?.name });
      this.setField({
        selectedProducts: this.props().productGroup?.products.map((p) => ({
          id: p.id,
          name: this.getProductTranslation(p),
          productGroupId: p.productGroupId,
        }))
      });
    }
  }

  refresh() {
    this.getData();
  }

  async getData() {
    this.setField({ productsAreLoading: true })

    try {
      const products = await this.productOperations.getLight();
      this.setField({ products });
      this.setField({ productsAreLoading: false })
    } catch {
      this.showToast(ToastHelper.getToast("tools.product.upsert.error", "error"), () => { }, 2500);
    }
  }

  getProductTranslation(product: Product): string {
    if (product.title) {
      const translation = product.title.translationValues
        .find((translation: TranslationValue) =>
          translation.language.key === Constants.DefaultSearchLanguage);

      if (translation) {
        return translation.value;
      }

      return 'No name';
    }

    return 'No name';
  }

  updateProducts(selectedProducts: any) {
    this.setField({ selectedProducts });
  }

  handleGroupNameChanged(groupName: string) {
    this.setField({ groupName });
  }

  async onProductGroupSaved(productGroupId?: number) {
    if (this.state().isSaving) {
      return;
    }

    if (!this.state().groupName && this.state().selectedTypeItem === 'group') {
      this.setField({ showError: true });

      return;
    }

    this.setField({ showError: false });

    try {
      this.setField({ isSaving: true });

      if (this.state().selectedTypeItem === 'single') {
        if (!this.state().selectedProducts.length) {
          this.setField({ isSaving: false });
          return;
        }

        await this.productGroupOperations.addSingleProduct(this.state().selectedProducts);
      } else if (!this.props().productGroup) {
        await this.productGroupOperations.post({
          name: this.state().groupName,
          products: this.state().selectedProducts,
        });
      } else {
        await this.productGroupOperations.update({
          id: productGroupId,
          name: this.state().groupName,
          products: this.state().selectedProducts,
        });
      }

      this.setField({ isSaving: false });
      this.showToast(ToastHelper.getToast("tools.product.upsert.success", "success"), () => { }, 2500);

      if ((!this.state().shouldAddAnotherProduct && this.state().selectedTypeItem === 'single') || this.state().selectedTypeItem === 'group') {
        this.props().closePopUp();
      }

      if (this.state().shouldAddAnotherProduct) {
        this.refresh();
        this.setField({ selectedProducts: [] });
      }
    } catch {
      this.setField({ isSaving: false });
      this.showToast(ToastHelper.getToast("tools.product.upsert.error", "error"), () => { }, 2500);
    }
  }

  onItemPress(id: string) {
    this.setField({ selectedTypeItem: id });

    if (id === 'single') {
      this.setField({ showError: false });
      this.setField({ selectedProducts: [] });
    } else {
      this.setField({ shouldAddAnotherProduct: false });
    }
  }

  onSearchChange(value: string) {
    this.setField({ search: value });
  }

  clearSearch() {
    this.setField({ search: '' });
  }

  onAddAnotherPress() {
    this.setField({ shouldAddAnotherProduct: !this.state().shouldAddAnotherProduct });
  }
}