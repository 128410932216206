import { Operation } from "@levelapp/softfabric";
import RewardTagService from "../Proxy/RewardTagService";

export default class RewardTagOperations extends Operation
{
    service : RewardTagService;
    constructor()
    {
        super();
        this.service = new RewardTagService();
    }

    get( callback : Function, onFailure : Function)
    {
        this.service.get(callback,onFailure);
    }

    put(rewardTag: {rewardId: number, tagId?: number, label?: string }, callback : Function, onFailure : Function) {
        this.service.put(rewardTag, callback, onFailure);
    }

    delete(rewardTag: {rewardId: number, tagId: number }, callback : Function, onFailure : Function) {
        this.service.delete(rewardTag, callback, onFailure);
    }

}