import { Operation } from "@levelapp/softfabric";
import PointEarningActivationCode from "src/Common/DTO/PointEarningActivationCode";
import PointEarningActivationCodeService from "src/Proxy/PointEarningActivationCodeService";

export default class PointEarningActivationCodeOperations extends Operation
{
    service : PointEarningActivationCodeService;

    constructor()
    {
        super();
        this.service = new PointEarningActivationCodeService();
    }

    post(parameters : PointEarningActivationCode, callback : Function , onFailure : Function)
    {
        this.service.post(parameters, callback , onFailure);
    }
}