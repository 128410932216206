 
import {ContentView} from '@levelapp/softfabric';
import OverviewViewState from './OverviewViewState';
import OverviewViewProps from './OverviewViewProps';
import OverviewViewStyle from './OverviewViewStyle';
import OverviewViewModel from './OverviewViewModel';
import { View } from 'react-native';
import Store from '../../../../../../../Common/DTO/Store';
import { HorizontalBar } from '@reactchartjs/react-chart.js';
import ExtendedDatePickerView from '../../../../components/extendeddatepicker/ExtendedDatePickerView';
import { LottieView } from '@levelapp/softfabric-ui';
import SectionTitleView from '../../../../components/sectiontitle/SectionTitleView';

export default class OverviewView extends ContentView<OverviewViewProps, OverviewViewState, OverviewViewModel, OverviewViewStyle>
{
  /* CONSTRUCTOR */
  constructor(props:OverviewViewProps)
  {
    super(props);

    // Binding
    this.bind(new OverviewViewModel(this), new OverviewViewStyle(this));
  }

  /* RENDERING */
  render()
  {
    var sortedstore = this.state.stores;
    if(this.state.logs.size > 0)
    {
      sortedstore = this.state.stores.sort((a : Store , b : Store) => 
      {
        var logStoreA = this.state.logs.get(a.id);
        var calculateA = ((logStoreA?.success as number)/((logStoreA?.failure as number) + (logStoreA?.success as number)));
        
        if(logStoreA?.failure == 0 && logStoreA.success == 0)
        {
          calculateA = 2
        }else if(Number.isNaN(calculateA))
        {
          calculateA = 1;
        }
        var logStoreB = this.state.logs.get(b.id);
        var calculateB = ((logStoreB?.success as number)/((logStoreB?.failure as number) + (logStoreB?.success as number)));
        if(logStoreB?.failure == 0 && logStoreB.success == 0)
        {
          calculateB = 2
        }else if(Number.isNaN(calculateB))
        {
          calculateB = 1;
        }
        var c = calculateA - calculateB;
        return c;
      })
    }

    const bar = () => {

      return {
        datasets: [{
        
          backgroundColor: '#73C678',
          borderColor: '#000000',
          label : 'Success',
          data: sortedstore.map((x : Store) => this.state.logs.get(x.id)?.success)
        },
        {
        
          backgroundColor: '#FF6961',
          borderColor: '#000000',
          label : 'Failure',

          data: sortedstore.map((x : Store) => this.state.logs.get(x.id)?.failure),
        }],
        labels : sortedstore.map((value : Store) => value.slug)
      }
    }
    
    return (
      <View style={{flex : 1}}>
        <View style={{flexDirection : 'row' , zIndex : 1}}>


          <ExtendedDatePickerView
            zIndex={1}
            title={'form.date'}
            selected={this.state.selectedDateTo}
            ref={(r : any) => this.binding._refEndDate = r}
            placeholder={this.binding.translate('form.date.placeholder')}
            handleOnChange={(date : Date) => this.binding.handleOnChangeDate('date',date)}
          />
          <View style={{justifyContent : 'flex-end' , paddingRight : 15 , paddingBottom : 15}}>
          {this.state.isLoading && <LottieView height={40} width={40} source={require('./assets/50-material-loader.json')} /> }

          </View>

        </View>
        <View style={{flexDirection : 'row'}}>
          <View style={{width : '80%' , height : '100%'}}>
            <HorizontalBar 
                width={100}
                height={300}
                type={'horizontalBar'} data={bar} options={
                  {
                    onClick : (_e : any, item : any) =>
                    {
                      if(item[0] != undefined)
                      {
                        var store = sortedstore.filter((x : Store) => this.state.logs.get(x.id) != undefined)[item[0]._index];
                        if(store != undefined)
                        {
                          this.binding.handleNavigate(store);
                        }
                      }

                    },
                    scales: {
                        xAxes: [{
                            stacked: true
                        }],
                        yAxes: [{
                            stacked: true
                        }]
                    }
                  }}/>
        
          </View>
            
            <View style={this.style.sticky}>
              <SectionTitleView title={'overview.overall'}/>
              <div style={{width : '200px' , height : '100px'}} ref={(r) => this.binding.ref = r} id="container"></div>
            </View>
        </View>
        
      </View>
    );
  }
}