import {ContentViewModel,ContentView} from '@levelapp/softfabric';
import CountrypopupViewState from './CountrypopupViewState';
import CountrypopupViewProps from './CountrypopupViewProps';
import DispatcherManager from '../../../../../../../Common/Managers/DispatcherManager';
import Country from '../../../../../../../Common/DTO/Country';
import ToastHelper from '../../../../../../../Common/Helpers/ToastHelper';
import CountryOperations from '../../../../../../../Transfer/CountryOperations';
import ErrorHelper from '../../../../../../../Common/Helpers/ErrorHelper';

export default class CountrypopupViewModel extends ContentViewModel<CountrypopupViewState, CountrypopupViewProps>
{

    _dispatcherHelper : DispatcherManager;
    _operationCountry : CountryOperations;

      /* CONSTRUCTOR */
    constructor(props: ContentView)
    {
        super(props);

        this.initialState({
            isLoading : false,
            country : this.props().country}); 
        
        this._dispatcherHelper = new DispatcherManager();  
        this._operationCountry = new CountryOperations();  
        
        // Bindings
        this.handleCountryChanged = this.handleCountryChanged.bind(this);
        this.handleSavePressed = this.handleSavePressed.bind(this);
        this.errorManagement = this.errorManagement.bind(this);
    }

    componentDidMount()
    {
        this._dispatcherHelper.valueCountry = this.handleCountryChanged
    }


    handleSavePressed(){
        this.setField({isLoading : true});
        this.saveCountry(this.state().country)
    }

    private handleCountryChanged(value : string)
    {
        this.setField((previousState) => previousState.country.countryCode = value)
    }

    saveCountry(country : Country){

        if(country.id == 0){
            this._operationCountry.post(country, () => {
                this.props().refreshParent();
                this.showToast(ToastHelper.getToast("countries.popup.success.added","success"),() => {},2500);           
                this.closePopUp();
            },this.errorManagement)
        }
        else{
            this._operationCountry.put(country, () => {
                this.props().refreshParent();
                this.showToast(ToastHelper.getToast("countries.popup.success.updated","success"),() => {},2500);
                this.closePopUp();
            },this.errorManagement)
        }
    }

    errorManagement(error : any) 
    {
        this.setField({isLoading : false});
        ErrorHelper.showMessageError(error.response);
    }

}