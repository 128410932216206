import {ContentShellViewModel, ContentShellView} from '@levelapp/softfabric';
import ShellRolePermissionViewState from './ShellRolePermissionViewState';
import ShellRolePermissionViewProps from './ShellRolePermissionViewProps';
 
import RolePermissionView from './pages/rolepermission/RolePermissionView';
import EnumModules from '../../../../../Common/Constants/EnumModules';

export default class ShellRolePermissionViewModel extends ContentShellViewModel<ShellRolePermissionViewState, ShellRolePermissionViewProps>
{

    constructor(view : ContentShellView)
    {
        super(view);

        this.registerEntryPoint('main'); // TODO : change with the route of your entry point
        this.register(<RolePermissionView/> ,'main'); // TODO : register your routes with the JSX (param1) and the route (param2)

        this.initialisation();
    }

    getModuleId()
    {
        return EnumModules.ROLEPERMISSIONS; // TODO : change the return value with your ModuleId
    }

}