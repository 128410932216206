import {ContentViewStyle, Application} from '@levelapp/softfabric';
import {StyleProp,ViewStyle, TextStyle, ImageStyle} from 'react-native';
import { StyleManager, UiManagers } from '@levelapp/softfabric-ui';
import Constants from '../../../../../../../../../Common/Constants/Constants';
export default class DetailCompositionViewStyle extends ContentViewStyle
{
    page: StyleProp<ViewStyle> = {
        flex: 1
    }
    MyStyleManager : StyleManager = Application.current.resolve<StyleManager>(UiManagers.STYLE_MANAGER);

    title: StyleProp<TextStyle> = {
        textAlign: 'left',
        color: 'white',
        fontSize: 24,
        fontWeight: '600',
        marginTop: 20,
        marginLeft: 60,
        paddingBottom: 20,
        display: "flex", 
        flexDirection: "column", 
        flexBasis: "85%"
    };
    popup: StyleProp<ViewStyle> = {
        minWidth: 600, 
        minHeight: 850, 
        width: Constants.PopUpWidth, 
        maxWidth: "100%"
    }
    saveButton: StyleProp<TextStyle> = {
        color: this.MyStyleManager.primaryColor,
        fontSize: 10,
        fontWeight: '600',
        height: 27,
        width: 112
    };
    image: StyleProp<ImageStyle> = {
        height: 23, 
        width: 23, 
        resizeMode: 'contain', 
        marginTop: 25, 
        marginLeft: 20
    }
    inputTitle: StyleProp<TextStyle> = {
        fontSize: 14,
        fontWeight: '600',
        color: '#3F3F3F',
        fontFamily : 'Montserrat SemiBold',
        marginBottom : 13,
    };
    input: StyleProp<any> = {
        width: 240,//140
        paddingHorizontal: 15,
        borderRadius : 8,
        boxShadow: '0 5px 14px 0 rgba(0,0,0,0.1)',
    };
    errorTextField: StyleProp<ViewStyle> = {
        borderColor: 'red',
        borderWidth: 1,
        width: 240,
        paddingHorizontal: 15,
        borderRadius : 8,
    };


}