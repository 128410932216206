 
import {ContentView} from '@levelapp/softfabric';
import HealthStatusViewState from './HealthStatusViewState';
import HealthStatusViewProps from './HealthStatusViewProps';
import HealthStatusViewStyle from './HealthStatusViewStyle';
import HealthStatusViewModel from './HealthStatusViewModel';
import { Text, View } from 'react-native';
import { Bar } from '@reactchartjs/react-chart.js';
import FilterLogView from './components/filterlog/FilterLogView';
import { LottieView, TranslationView } from '@levelapp/softfabric-ui';
import TableView from '../../../../../../../Common/Components/TabelView/TableView';
import ExtendedDropDownView from '../../../../components/extendeddropdown/ExtendedDropDownView';
import Store from '../../../../../../../Common/DTO/Store';
import ExtendedDatePickerView from '../../../../components/extendeddatepicker/ExtendedDatePickerView';
import ButtonView from '../../../../../../../Common/Components/Button/ButtonView';


export default class HealthStatusView extends ContentView<HealthStatusViewProps, HealthStatusViewState, HealthStatusViewModel, HealthStatusViewStyle>
{
  basicReferenceType : string[] = ['Baskets', 'Coupon' , 'Rewards' , 'Booster', 'POS' , 'Payment'];
  basicThirdPartyType : string[] = ['Mollie', 'Rabobank' , 'Stripe' , 'Adyen', 'Oracle' , 'NCR' , 'ATO'];


  /* CONSTRUCTOR */
  constructor(props:HealthStatusViewProps)
  {
    super(props);

    // Binding
    this.bind(new HealthStatusViewModel(this), new HealthStatusViewStyle(this));
  }

  /* RENDERING */
  render()
  {
    const bar = () => {
      var selected = this.state.referenceType.filter(x => x.selected);

      var referencesTypesUnique = selected.map((value : any) =>
      {
        return this.binding.translate(value.title);
      })
      return {
        datasets: [{
        
          backgroundColor: '#34eb59',
          borderColor: '#000000',
          label : 'Success',

          data: this.state.success.filter
          ((_value : number , index : number) =>
          {
            var found = selected.find(x => x.id == index + 1);
            if(found && found.selected )
            {
              return true;
            }
            return false;
          }),
        },
        {
        
          backgroundColor: '#FF6961',
          borderColor: '#000000',
          label : 'Failure',

          data: this.state.failure.filter
          ((_value : number , index : number) =>
          {
            var found = selected.find(x => x.id == index + 1);
            if(found && found.selected )
            {
              return true;
            }
            return false;
          }),
        }],
        labels : this.state.logs.length > 0 ? referencesTypesUnique.length > 0 ? referencesTypesUnique : this.basicReferenceType : this.basicReferenceType
      }
    }

    const barTP = () => {
      var selected = this.state.thirdpartyType.filter(x => x.selected);
      var thirdpartyTypesUnique = selected.map((value : any) =>
      {
        return this.binding.translate(value.title);
      })
      return {
        datasets: [{
        
          backgroundColor: '#73C678',
          borderColor: '#000000',
          label : 'Success',

          data: this.state.successTP.filter
          ((_value : number , index : number) =>
          {
            var found = selected.find(x => x.id == index + 1);
            if(found && found.selected )
            {
              return true;
            }
            return false;
          }),
        },
        {
        
          backgroundColor: '#FF6961',
          borderColor: '#000000',
          label : 'Failure',

          data: this.state.failureTP.filter
          ((_value : number , index : number) =>
          {
            var found = selected.find(x => x.id == index + 1);
            if(found && found.selected )
            {
              return true;
            }
            return false;
          }),
        }],
        labels : this.state.logs.length > 0 ? thirdpartyTypesUnique.length > 0 ? thirdpartyTypesUnique : this.basicThirdPartyType : this.basicThirdPartyType
      }
    }

    

    return (
      <View style={this.style.container}>
        <View style={this.style.box}>
          
          <View style={{flexDirection : 'row' , justifyContent : 'flex-start' , zIndex : 2 , alignItems : 'center'}}>
            <ExtendedDropDownView
                  title={"stores.title"}
                  values={this.state.stores.map((value : Store)=>
                    {
                      return { title : value.title , value : value }
                    })}
                  selectedValue={{title : this.state.currentStore?.title , value : this.state.currentStore}}
                  onValueChange={(value : any) => this.binding.handleValueChange(value)}
                />
            <View style={{flexDirection : 'row' , zIndex : 1 }}>
                  <ExtendedDatePickerView
                    zIndex={2}
                    title={'form.startdate'}
                    selected={this.state.selectedDateFrom}
                    placeholder={this.binding.translate('form.startdate.placeholder')}
                    handleOnChange={(date : Date) =>
                      {
                        // this.binding._refEndDate.getInnerRef().input.focus();
                        this.binding.handleOnChangeDate('startdate',date);
                      } }
                  />

                  <ExtendedDatePickerView
                    zIndex={1}
                    title={'form.enddate'}
                    selected={this.state.selectedDateTo}
                    ref={(r : any) => this.binding._refEndDate = r}
                    placeholder={this.binding.translate('form.enddate.placeholder')}
                    handleOnChange={(date : Date) => this.binding.handleOnChangeDate('enddate',date)}
                  />

                  <View style={{justifyContent : 'flex-end' , paddingRight : 15 , paddingBottom : 15}}>
                    <ButtonView backgroundColor={'white'} handleClick={() => this.binding.handleExport()} isLoading={this.state.isLoadingExport}>
                      <TranslationView style={{ color: '#358DFF' }}>healthstatus.export</TranslationView>
                    </ButtonView>
                  
                  </View>
                  <View style={{justifyContent : 'flex-end' , paddingRight : 15 , paddingBottom : 15}}>
                    {this.state.isLoading && <LottieView height={40} width={40} source={require('./assets/50-material-loader.json')} /> }
                  </View>

                </View>
            
          </View>
         
          <View style={{flexDirection : 'row' , zIndex : 1 , maxWidth : '100%' , flexWrap : 'wrap'}}>
            <Text style={{fontSize : 20 , paddingBottom : 30 ,  textAlign : 'center'}}>General Board</Text>
            <View style={{paddingLeft : 20  , paddingBottom : 15, flexDirection : 'row'}}>
              <View style={{paddingHorizontal : 5}}>
                <FilterLogView type={'reference'} changeValue={(value : any , type : string) => this.binding.changeValue(value,type)} dataToSelect={this.state.referenceType} >
                  <TranslationView>healthstatus.reference</TranslationView> = {this.state.referenceType.find(x => x.selected == false) == undefined ? 'All' : this.state.referenceType.map(x => 
                    {
                      if(x.selected)
                      {
                        return ` ${this.binding.translate(x.title)} `;
                      }
                      return;
                    })}
                </FilterLogView>
              </View>
              <View style={{paddingHorizontal : 5}}>
                <FilterLogView type={'tp'} changeValue={(value : any , type : string) => this.binding.changeValue(value,type)} dataToSelect={this.state.thirdpartyType}>
                  <TranslationView>healthstatus.thirdparty</TranslationView> = {this.state.thirdpartyType.find(x => x.selected == false) == undefined ? 'All' : this.state.thirdpartyType.map(x => 
                    {
                      if(x.selected)
                      {
                        return ` ${this.binding.translate(x.title)} `;
                      }
                      return;
                    })}
                </FilterLogView>
              </View>
              <View style={{paddingHorizontal : 5}}>
                <FilterLogView type={'type'} changeValue={(value : any , type : string) => this.binding.changeValue(value,type)} dataToSelect={this.state.type}>
                  Types
                </FilterLogView>
              </View>
            </View>
            

           
          </View>
         
          <View style={{width : '100%' , flexDirection : 'row' , justifyContent : 'space-between' }}>
            <View style={{width : '35%' , backgroundColor : 'white' , padding : 10 , borderRadius : 5}}>
              <Text style={{fontSize : 20 , paddingVertical : 10 , textAlign : 'center'}}><TranslationView>healthstatus.reference</TranslationView></Text>
              <Bar 

              type={'bar'} data={bar} options={
                {
                  scales: {
                      xAxes: [{
                          stacked: true
                      }],
                      yAxes: [{
                          stacked: true
                      }]
                  }
                }}/>
            </View>

            <View style={{width : '35%' , backgroundColor : 'white' , padding : 10 , borderRadius : 5}}>
            <Text style={{fontSize : 20 , paddingVertical : 10 , textAlign : 'center'}}><TranslationView>healthstatus.thirdparty</TranslationView></Text>
            <Bar 
              type={'bar'} data={barTP} options={
                {
                  scales: {
                      xAxes: [{
                          stacked: true
                      }],
                      yAxes: [{
                          stacked: true
                      }]
                  }
              }}/>
          </View>        
            <View style={{ width : '15%', backgroundColor : 'white' , padding : 10 , borderRadius : 5, height : '100%',  alignItems : 'center'}}>
            <Text style={{fontSize : 20 , paddingVertical : 10 , textAlign : 'center'}}><TranslationView>healthstatus.overall</TranslationView></Text>
            <View style={{height : '80%' , alignItems : 'center' , justifyContent : 'center'}}>
              <div style={{width : '200px' , height : '100px'}} ref={(r) => this.binding.ref = r} id="container"></div>
            </View>
            </View>
          </View>

          <View style={{backgroundColor : 'white' , marginTop : 10, padding : 5 , width : '100%'}}>
            <View style={{flexDirection : 'row' , flex : 1 , zIndex : 1 , justifyContent : 'space-between'}}>
              <Text style={{fontSize : 20 , paddingVertical : 10 , alignSelf : 'center'}}>Logs</Text>
              <View style={{alignSelf : 'flex-end'}}>
                <FilterLogView type={'type'} changeValue={(value : any , type : string) => this.binding.changeValue(value,type)} dataToSelect={this.state.type}>
                  Types
                </FilterLogView>
              </View>
            </View>
            
            <TableView
              headerTextStyle={this.style.tableHeader}
              backgroundStepColor={this.style.stepColor}
              rowStyle={this.style.rowStyle}
              nothingToDisplayText={this.binding.translate("component.table.noresults")}
              isLastColumnActions={true}
              data={this.state.tableData}
              isLoading={this.state.isLoading}
              headers={this.state.tableHeaders}
              onPageChange={(page: number) => this.binding.changePage(page)}
              handleSortChange={() => {}}
              nbPage={this.state.nbPage}
              currentPage={this.state.currentPage - 1}
              isSortable={false}/>
          </View>

        </View>
      </View>
    );
  }
}