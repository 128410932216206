import { Operation } from "@levelapp/softfabric";
import FaqService from "../Proxy/FaqService";

export default class FaqOperations extends Operation
{
    service : FaqService;

    constructor()
    {
        super();
        this.service = new FaqService();
    }

    get(parameters : any[], callback : Function , onFailure : Function)
    {
        this.service.get(parameters, callback , onFailure);
    }

    post(question:any, callback : Function , onFailure : Function)
    {
        this.service.post(question, callback, onFailure);
    }

    put(question:any, callback : Function , onFailure : Function)
    {
        this.service.put(question, callback, onFailure);
    }

    delete(question:any, callback : Function , onFailure : Function)
    {
        this.service.delete(question, callback, onFailure);
    }
}