import moment from 'moment';
import User from '../../../../../../../../../../../Common/DTO/User';
import HistorySectionViewState from './HistorySectionViewState';
import HistorySectionViewProps from './HistorySectionViewProps';
import { ContentViewModel, ContentView } from '@levelapp/softfabric';
import LoyaltyCard from '../../../../../../../../../../../Common/DTO/LoyaltyCard';
// import TicketDetailsPopUpView from '../ticketdetailspopup/TicketDetailsPopUpView';
import TicketOperations from '../../../../../../../../../../../Transfer/TicketOperation';
import HistoryOperations from '../../../../../../../../../../../Transfer/HistoryOperations';
import PaginationHelper from '../../../../../../../../../../../Common/Helpers/PaginationHelper';
import PurchaseOperations from '../../../../../../../../../../../Transfer/PurchaseOperations';
import ToastHelper from '../../../../../../../../../../../Common/Helpers/ToastHelper';
import TicketDetailsPopUpView from '../../../ticketdetailspopup/TicketDetailsPopUpView';

import HistorySectionViewStyle from './HistorySectionViewStyle';
import ManuealEarnedPointsDetailsView from './manuealearnedpointsdetails/ManuealEarnedPointsDetailsView';
import TableRow from '../../../../../../../../../../../Common/Components/TabelView/TableRow';

export default class HistorySectionViewModel extends ContentViewModel<HistorySectionViewState, HistorySectionViewProps, HistorySectionViewStyle>
{
    historyOperation: HistoryOperations;
    ticketOperation: TicketOperations;
    purchaseOperation: PurchaseOperations;

    isSorting: boolean;
    indexHeaderSort: number;
    sortOrder: string;
    sortKey: string[];

    /* CONSTRUCTOR */
    constructor(view: ContentView) {
        super(view);

        this.initialState({
            isLoading: false,
            tableHeaders: [], tableData: [], nbPage: 0, currentPage: 1, historyTab: "All"
        }); // Initialize your state here

        this.isSorting = false;
        this.indexHeaderSort = -1;
        this.sortOrder = 'asc';
        this.sortKey = ["Type", "Points", "Date", "TicketId"];

        this.ticketOperation = new TicketOperations();
        this.historyOperation = new HistoryOperations();
        this.purchaseOperation = new PurchaseOperations();

        // Bindings
        this.refresh = this.refresh.bind(this);
        this.changeTab = this.changeTab.bind(this);
        this.onSortChange = this.onSortChange.bind(this);
        this.onPageChange = this.onPageChange.bind(this);
        this.handleTicketsDetails = this.handleTicketsDetails.bind(this);
        this.handleDeletePurchase = this.handleDeletePurchase.bind(this);
        this.handleAddedPointsDetails = this.handleAddedPointsDetails.bind(this);
    }

    componentDidMount() {
        moment.locale('fr');
        this.refresh(this.state().historyTab);
        document.addEventListener("pointsAdded", () => this.refresh(this.state().historyTab))
    }

    refresh(currentTab: any) {

        this.setField({ isLoading: true });
        let parameters = [];

        //afficher page courante + nbr d'element par page
        parameters.push(PaginationHelper.pushPage(this.state().currentPage));
        parameters.push(PaginationHelper.pushItemPage(10));
        parameters.push(PaginationHelper.pushCustomKey("loyaltyCardId", ((this.props().user as User).loyaltyCard as LoyaltyCard).id));
        parameters.push(PaginationHelper.pushAction(currentTab));

        //faire un tri selon different headers
        //columnSort = sort table by the column
        //sortOrder = asc,desc,default
        if (this.isSorting) {
            parameters.push(PaginationHelper.pushColumnSort(this.sortKey[this.indexHeaderSort]));
            parameters.push(PaginationHelper.pushSortOrder(this.sortOrder));
        }

        this.historyOperation.get(parameters, (history: any) => {
            let tableData: TableRow[] = [];
            history.data.objects.forEach((historyLine: any) => {

                let titre = historyLine.title;
                if (historyLine.subtitle !== null) {
                    titre += "\n  " + historyLine.subtitle;
                }
                //Page All

                tableData.push({
                    data: [titre
                        , historyLine.points
                        , moment.utc(historyLine.date).local().format('MM-DD-YYYY, HH:mm')
                        , historyLine.ticketId]
                    , isEditable: (titre.includes("Purchase"))
                    , isDeletable: (titre.includes("Purchase"))
                    , isViewable : (titre.includes("Manual"), historyLine.message !== null && historyLine.message !== "")
                    , editCallback: () => { this.handleTicketsDetails(historyLine) }
                    , deleteCallback: () => this.handleDeletePurchase(historyLine)
                    , viewCallback: () => { this.handleAddedPointsDetails(historyLine.message) }
                });
            })
            //refresh the user after points updated -> renvoyer utilisateur du back en faisant points added
            this.setField({ tableHeaders: ["Type", "Points", "Date", "TicketId"], tableData: tableData, nbPage: history.data.totalPage, isLoading: false, isLoadingPoints: false });// + nbPages et isLoading = false
        });

    }

    handleTicketsDetails(ticket: any) {
        this.ticketOperation.get(ticket.ticketId, (_ret: any) => {           
                this.showPopUp(
                    <TicketDetailsPopUpView ticket={_ret.data} user={this.props().user} saveUser={this.props().saveUser} refresh={this.props().refresh} />
                );
        });
    }

    handleDeletePurchase(purchase: any) {
        this.props().showInternalPopup('purchase.popup.confirmation.title', 'purchase.popup.confirmation.content', () => (
            this.purchaseOperation.cancel(purchase.ticketId, () => {
                this.showToast(ToastHelper.getToast("history.popup.success.deleted", "success"), () => { }, 2500);
                this.props().refreshMainInfo();
            })
        ));
    }

    handleAddedPointsDetails(message: any){
        this.showPopUp(<ManuealEarnedPointsDetailsView message={message} user={this.props().user} saveUser={this.props().saveUser} refresh={this.props().refresh}></ManuealEarnedPointsDetailsView>)
    }

    onSortChange(indexHeaderSort: number, sortOrder: string) {
        if (indexHeaderSort == -1) {
            this.isSorting = false;
        } else {
            this.isSorting = true;
            this.indexHeaderSort = indexHeaderSort;
            this.sortOrder = sortOrder;
        }
        this.refresh(this.state().historyTab);
    }

    onPageChange(page: number) {
        this.state().currentPage = page + 1;
        this.refresh(this.state().historyTab);
    }

    changeTab(tab: any) {
        var state = this.state();
        state.historyTab = tab;
        state.isLoading = true;
        this.setField({ state });
        this.refresh(tab);
    }
}