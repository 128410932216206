import {ContentViewModel,ContentView} from '@levelapp/softfabric';
import TestPopupViewState from './TestPopupViewState';
import TestPopupViewProps from './TestPopupViewProps';
import DispatcherManager from '../../../../../../../Common/Managers/DispatcherManager';
import MessageCampaignsOperations from '../../../../../../../Transfer/MessageCampaignOperations';
import UsersOperations from '../../../../../../../Transfer/UsersOperations';
import ToastHelper from '../../../../../../../Common/Helpers/ToastHelper';

export default class TestPopupViewModel extends ContentViewModel<TestPopupViewState, TestPopupViewProps>
{
    _dispatcherHelper : DispatcherManager;
    messageCampaignOperation: MessageCampaignsOperations;
    userOperation : UsersOperations;
      /* CONSTRUCTOR */
    constructor(view: ContentView)
    {
        super(view);

        this.initialState({ isLoading: false, loyaltycardCode : "", idToSend : []}); // Initialize your state here
        this._dispatcherHelper = new DispatcherManager(); 
        this.messageCampaignOperation = new MessageCampaignsOperations();
        this.userOperation = new UsersOperations();
        // Bindings
        this.handleLoyaltyCardCodeChanged = this.handleLoyaltyCardCodeChanged.bind(this)
        this.test = this.test.bind(this)
        this.addId = this.addId

    }

    componentDidMount()
    {
      this._dispatcherHelper.valueLoyaltyCardCode = this.handleLoyaltyCardCodeChanged
    }

    private handleLoyaltyCardCodeChanged(value : string)
    {
      this.setField((previousState) => previousState.loyaltycardCode = value)
    }

    addId(){
      let idToSend = this.state().idToSend
      if (idToSend.includes(this.state().loyaltycardCode)){
        this.showToast(ToastHelper.getToast("messageCampaign.popup.loyaltyCard.failed.alreadyAdded","error"),() => {},2500);
        this.setField({ loyaltycardCode:"",idToSend : idToSend })
        return
      }
      this.userOperation.loyalatyCardExist(this.state().loyaltycardCode, (response :any) => {
        if(response.data.data){
          idToSend.push(this.state().loyaltycardCode)
          this.showToast(ToastHelper.getToast("messageCampaign.popup.loyaltyCard.success","success"),() => {},2500);
          this.setField({ loyaltycardCode:"",idToSend : idToSend })
        }else{
          this.showToast(ToastHelper.getToast("messageCampaign.popup.loyaltyCard.failed","error"),() => {},2500);
          this.setField({ loyaltycardCode:"",idToSend : idToSend })
        }
      })

    }

    test(){
      let mcId = this.props().messageCampaignId
      this.messageCampaignOperation.test(mcId,this.state().idToSend
            ,() => {
              this.showToast(ToastHelper.getToast("messageCampaignForm.test.success","success"),() => {},2500);
              this.closePopUp()}
            ,() => {
              this.showToast(ToastHelper.getToast("messageCampaignForm.test.failure","success"),() => {},2500);
              this.closePopUp()})
    }
}