import {ContentViewModel,ContentView} from '@levelapp/softfabric';
import TitleDescriptionViewState from './TitleDescriptionViewState';
import TitleDescritpionViewProps from './TitleDescritpionViewProps';

export default class TitleDescriptionViewModel extends ContentViewModel<TitleDescriptionViewState, TitleDescritpionViewProps>
{
    _refEndDate : any;
    /* CONSTRUCTOR */
    constructor(view: ContentView)
    {
        super(view);
        this.initialState({ isLoading: true }); // Initialize your state here
    }

    componentDidMount() {
        this.refresh();
    }

    refresh() {
        this.setField({ isLoading: true });
        this.loadData();
    }

    loadData()
    {

    }
}