import { ContentShellViewModel, ContentShellView} from '@levelapp/softfabric';
import ShellBoostersViewState from './ShellBoostersViewState';
import ShellBoostersViewProps from './ShellBoostersViewProps';
import BoostersView from './pages/booster/BoostersView';
import EnumModules from '../../../../../Common/Constants/EnumModules';
import BoostersV2 from './pages/booster-v2/BoostersV2';
 

export default class ShellBoostersViewModel extends ContentShellViewModel<ShellBoostersViewState, ShellBoostersViewProps>
{

    constructor(view : ContentShellView)
    {
        super(view);

        this.registerEntryPoint('main');
        this.register(process.env.REACT_APP_ISBOOSTERS_V2 === 'true' ? BoostersV2 : <BoostersView />, 'main');

        this.initialisation();
    }

    getModuleId()
    {
        return EnumModules.BOOSTERS;
    }

}