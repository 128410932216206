import { ContentViewModel, ContentView } from '@levelapp/softfabric';
import CheckboxViewState from './CheckboxViewState';
import CheckboxViewProps from './CheckboxViewProps';

export default class CheckboxViewModel extends ContentViewModel<CheckboxViewState, CheckboxViewProps>
{
  /* CONSTRUCTOR */
  constructor(view: ContentView) {
    super(view);

    this.initialState({}); // Initialize your state here

    // Bindings

  }
}