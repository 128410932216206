import {ContentView, ContentViewModel} from '@levelapp/softfabric';
import TicketDetailsPopUpViewState from './TicketDetailsPopUpViewState';
import TicketDetailsPopUpViewProps from './TicketDetailsPopUpViewProps';
import { TableRow } from '@levelapp/softfabric-ui';
import UsersPopUpView from '../../UsersPopUpView';
import Helpers from '../../../../../../../../../Common/Helpers/Helpers';
 
import StoresOperations from 'src/Transfer/StoresOperations';


export default class TicketDetailsPopUpViewModel extends ContentViewModel<TicketDetailsPopUpViewState, TicketDetailsPopUpViewProps>
{
    constructor(props: ContentView) {
        super(props);

        this.initialState({
            ticket: this.props().ticket,   
            tableHeaders: ["Product", "Quantity", "Price", "ParentId"], 
            tableData: [],
            storeName: ''       
        });


         /* BINDINGS */
         this.close = this.close.bind(this);
    }

    /* COMPONENT EVENTS */
    componentDidMount() {
        let tableData: TableRow[] = [];
        this.props().ticket.ticketLines.forEach((line: any) => {

            tableData.push({
                data: [line.itemName, line.quantity, line.totalVatInclude, line.parentEntryId
                    ]                   
            });
            
        })
        this.setField({ tableData: tableData,});

        let ope = new StoresOperations();
        ope.getAll((ret:any) => {          
            ret.data.objects.forEach((x:any) => {
               if(x.posId == this.props().ticket.entityCode) {
                   this.setField({storeName : x.slug})
               }
           })
        }, () => {})
      
    }

    close() {
        this.showPopUp(
            <UsersPopUpView user={Helpers.clone(this.props().user)} saveUser={this.props().saveUser} refresh={this.props().refresh} />
          );
    }
}