import { ContentViewStyle } from '@levelapp/softfabric';
import { StyleProp, TextStyle, ViewStyle } from 'react-native';

export default class SettingsLineViewStyle extends ContentViewStyle {
  container: StyleProp<ViewStyle> = {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingVertical: 10
  };

  titleTxt: StyleProp<TextStyle> = {
    fontSize: 14,
    color: '#358DFF'
  };

  button: StyleProp<any> = {
    height: 45,
    backgroundColor: 'white',
    borderRadius: 5,
    justifyContent: 'center',
    alignItems: 'center',
    boxShadow: '0 2px 4px 2px rgba(164,164,164,0.5)',
    paddingHorizontal: 30
  };
}