 
import { ContentView } from '@levelapp/softfabric';
import KitchenAppViewState from './KitchenAppViewState';
import KitchenAppViewProps from './KitchenAppViewProps';
import KitchenAppViewStyle from './KitchenAppViewStyle';
import KitchenAppViewModel from './KitchenAppViewModel';
import { View, LayoutChangeEvent, Image, TouchableWithoutFeedback, Animated, Text, ScrollView } from 'react-native';
import BasketStatusView from '../../components/basketstatus/BasketStatusView';
import CustomDatePickerView from '../../components/customdatepicker/CustomDatePickerView';
import OpeningHoursHelper from '../../../../../../../Common/Helpers/OpeningHoursHelper';
import { LottieView, TranslationView } from '@levelapp/softfabric-ui';
import { FlowDirections } from '../../../../../../../Common/Enums/FlowDirections';
import FilterStatusView from '../../components/filterstatus/FilterStatusView';
import OrderFiltersPopupView from '../../components/orderfilterspopup/OrderFiltersPopupView';
import Constants from '../../../../../../../Common/Constants/Constants';
import RefreshProductView from '../../components/refreshProduct/RefreshProductView';

export default class KitchenAppView extends ContentView<KitchenAppViewProps, KitchenAppViewState, KitchenAppViewModel, KitchenAppViewStyle>
{
  /* CONSTRUCTOR */
  constructor(props: KitchenAppViewProps)
  {
    super(props);

    // Binding
    this.bind(new KitchenAppViewModel(this), new KitchenAppViewStyle(this));
  }

  /* RENDERING */
  render()
  {
    const { isClosingToday, getCurrentShiftEnd } = OpeningHoursHelper;
    const { openingHours, selectedDate, statuses, selectedStatusId, selectedFilters, isKitchenModusEnabled } = this.state;
    const isSelectedDateToday = selectedDate.toISOString().split('T')[0] == new Date().toISOString().split('T')[0];

    return (
      <TouchableWithoutFeedback onPress={() => { this.binding.onTouchScreen();if (this.state.orderSettingsOpenId) { this.setState({ orderSettingsOpenId: undefined }) } }}>
        <View style={this.style.container}>
          {/** HEADER */}
          <View style={this.style.headerContainer}> 
            {/** LEFT BUTTONS */}
            <View style={{ flexDirection: "row", alignItems: 'center' }}>
              {!isKitchenModusEnabled && (
                <CustomDatePickerView
                  handleOnChange={this.binding.handleDateChanged} selected={selectedDate}
                  zIndex={1} isClosingToday={!isSelectedDateToday ? true : isClosingToday(openingHours, selectedDate)} currentShiftEnd={getCurrentShiftEnd(openingHours, selectedDate)} />
              )}

              {!isKitchenModusEnabled && (
                <View style={this.style.dayButtonsContainer}>
                  <TouchableWithoutFeedback onPress={this.binding.handlePreviousDayPressed}>
                    <View style={this.style.dayButton}>
                      <Image source={require('../../assets/left.svg').default} style={{ width: 10, height: 17 }} />
                    </View>
                  </TouchableWithoutFeedback>


                  <TouchableWithoutFeedback onPress={this.binding.handleNextDayPressed}>
                    <View style={this.style.dayButton}>
                      <Image source={require('../../assets/right.svg').default} style={{ width: 10, height: 17 }} />
                    </View>
                  </TouchableWithoutFeedback>
                </View>
              )}

                

              {!isSelectedDateToday && (
                <View style={this.style.dayButtonsContainer}>
                  <TouchableWithoutFeedback onPress={() => this.binding.handleDateChanged(new Date())}>
                    <View style={this.style.todayButton}>
                      <TranslationView style={{ color: '#358DFF' }}>kitchenapp.today</TranslationView>
                    </View>
                  </TouchableWithoutFeedback>
                </View>
              )}
              {Constants.allowedToRefreshCacheKitchenApp && <RefreshProductView selectedStore={this.state._selectedStore}/>}    
              
              <OrderFiltersPopupView  
                setFilterSelection={this.binding.setFilterSelection} 
                filters={this.state.filters} 
                status={this.state.statuses} 
                brands={this.state.brands}
                language={this.binding.language} 
                count={{
                  filtered: this.binding.getFilteredOrderCount(), // All => Tabs all orders after source, type filters 
                  total: this.binding.getOrderCount(), // Total => Only filter on visible status 
                }}/>


            </View>

            {/* TEMPORARY  */}
            <View>
              {this.state._selectedStore != undefined && this.state._selectedStore?.isClickAndCollectTemporaryDisabled && <TranslationView style={this.style.temporaryText}>kitchenapp.temporary.cc.disable</TranslationView>}
              {this.state._selectedStore != undefined && this.state._selectedStore?.isKioskTemporaryDisabled && <TranslationView style={this.style.temporaryText}>kitchenapp.temporary.kiosk.disable</TranslationView>}
            </View>
            
            {/** RIGHT BUTTONS */}
            <View style={{ flexDirection: "row", alignItems: 'center' }}>
              <View style={this.style.directionButtonsContainer}>
                <TouchableWithoutFeedback onPress={() => {this.binding.onTouchScreen();this.setState({ flowDirection: FlowDirections.HORIZONTAL })}}>
                  <View style={[this.style.directionButton, { borderColor: this.state.flowDirection == FlowDirections.HORIZONTAL ? '#0C8EF4' : '#BBBBBB', borderTopLeftRadius: 5, borderBottomLeftRadius: 5, borderRightWidth: this.state.flowDirection == FlowDirections.HORIZONTAL ? 1 : 0 }]}>
                    <Image source={this.state.flowDirection == FlowDirections.HORIZONTAL ? require('../../assets/horActive.svg').default : require('../../assets/horInactive.svg').default} style={{ height: 18, width: 22 }} />
                    <TranslationView style={[this.style.directionButtonTxt, { color: this.state.flowDirection == FlowDirections.HORIZONTAL ? '#0C8EF4' : '#BBBBBB' }]}>kitchenapp.horizontal</TranslationView>
                  </View>
                </TouchableWithoutFeedback>

                <TouchableWithoutFeedback onPress={() => {this.binding.onTouchScreen();this.setState({ flowDirection: FlowDirections.VERTICAL })}}>
                  <View style={[this.style.directionButton, { borderColor: this.state.flowDirection == FlowDirections.VERTICAL ? '#0C8EF4' : '#BBBBBB', borderBottomRightRadius: 5, borderTopRightRadius: 5, borderLeftWidth: this.state.flowDirection == FlowDirections.VERTICAL ? 1 : 0 }]}>
                    <Image source={this.state.flowDirection == FlowDirections.VERTICAL ? require('../../assets/vertActive.svg').default : require('../../assets/vertInactive.svg').default} style={{ height: 22, width: 18 }} />
                    <TranslationView style={[this.style.directionButtonTxt, { color: this.state.flowDirection == FlowDirections.VERTICAL ? '#0C8EF4' : '#BBBBBB' }]}>kitchenapp.vertical</TranslationView>
                  </View>
                </TouchableWithoutFeedback>
              </View>

              

              {Constants.allowedToZoomKitchenApp &&<View style={[this.style.directionButtonsContainer,{marginLeft : 25}]}>
                <TouchableWithoutFeedback onPress={() => this.binding.zoom(-1)}>
                  <View style={[this.style.directionButton, {borderTopLeftRadius: 5, borderBottomLeftRadius: 5, borderRightWidth: this.state.flowDirection == FlowDirections.HORIZONTAL ? 1 : 0 }]}>
                    <TranslationView style={[this.style.directionButtonTxt]}>kitchenapp.zoomIn</TranslationView>
                  </View>
                </TouchableWithoutFeedback>
                <TouchableWithoutFeedback onPress={() => this.binding.reset()}>
                  <View style={[this.style.directionButton, { borderLeftWidth: this.state.flowDirection == FlowDirections.VERTICAL ? 1 : 0 }]}>
                    <TranslationView style={[this.style.directionButtonTxt]}>kitchenapp.reset</TranslationView>
                    <Text style={[this.style.directionButtonTxt]}>{Math.round(this.state.currentZoomPercentage*100) + "%"}</Text>
                  </View>
                </TouchableWithoutFeedback>
                <TouchableWithoutFeedback onPress={() => this.binding.zoom(1)}>
                  <View style={[this.style.directionButton, {borderBottomRightRadius: 5, borderTopRightRadius: 5, borderLeftWidth: this.state.flowDirection == FlowDirections.VERTICAL ? 1 : 0 }]}>
                    <TranslationView style={[this.style.directionButtonTxt]}>kitchenapp.zoomOut</TranslationView>
                  </View>
                </TouchableWithoutFeedback>
              </View> }
            </View>
          </View>


          {/** STATUSES */}
          {!this.state.isKitchenModusEnabled && (<>
            <View style={this.style.statusesContainer}>
              {this.binding.getStatus().map((st, key) =>
              {
                const label = st.label.translationValues.find(x => x.language.key == this.binding.language)?.value ?? '';

                return (
                  <BasketStatusView key={key} color={st.labelColor}
                    label={label} count={this.binding.getBasketsCountByStatus(st)} handleSelected={() => this.binding.handleStatusSelected(st)}
                    showBadge={st.showBadge} isSelected={this.state.selectedStatusId == st.id} />
                )
              })}
              {statuses.length != 0 && (
                <BasketStatusView color={'#d6d6d6'}
                  label={this.binding.translate("kitchenapp.all")} 
                  count={this.binding.getFilteredOrderCount()} 
                  handleSelected={() => this.binding.handleAllPressed()}
                  showBadge={false} isSelected={this.state.selectedStatusId == -1} />
              )}
            </View>
          </>)}

          {/** ALL FILTERS */}
          {selectedStatusId == -1 && statuses.length > 0 && (
            <View style={this.style.filtersContainer}>
              {statuses.filter(x => x.isDisplayed).sort((x, y) => x.displayIndex - y.displayIndex).map((st, key) =>
              {
                const label = st.label.translationValues.find(x => x.language.key == this.binding.language)?.value ?? '';

                return (
                  <TouchableWithoutFeedback onPress={() => this.binding.handleFilterSelected(st.id)}>
                    <View style={{ paddingHorizontal: 7.5 }}>
                      <FilterStatusView key={key} label={label} backgroundColor={st.labelColor} secondaryColor={st.labelSecondaryColor} isSelected={selectedFilters.includes(st.id)} />
                    </View>
                  </TouchableWithoutFeedback>
                )
              })}
            </View>
          )}

          {/** ORDERS */}
          <View style={{ flex: 1 }} onLayout={(e: LayoutChangeEvent) => this.setState({ orderMaxHeight: e.nativeEvent.layout.height - 100 })}>
            <ScrollView style={{ height: '100%', width: '100%' }} horizontal={this.state.flowDirection == FlowDirections.VERTICAL}>
              <View style={[this.style.ordersContainer, { flexDirection: this.state.flowDirection == FlowDirections.HORIZONTAL ? 'row' : 'column' }]} >
                {this.state.isLoading ? (
                  <View style={{ flex: 1, justifyContent: 'center', alignItems: 'center' }}>
                    <LottieView height={150} width={150} source={require('../../assets/loading.json')} />
                  </View>
                ) :
                  this.binding.formatOrders()
                }
              </View>
            </ScrollView> 
          </View>

          {/** MULTI-SELECT FOOTER */}
          <Animated.View style={[this.style.multiSelectFooter, { transform: [{ translateY: this.binding.footerTransY }] }]}>
            <View style={this.style.ordersSelectedContainer}>
              <View style={this.style.ordersSelectedBubble}>
                <Text style={this.style.ordersSelectedCountTxt}>{this.state.selectedBasketIds.length}</Text>
              </View>
              <TranslationView>kitchenapp.selectedorders</TranslationView>
            </View>

            <View style={this.style.footerButtonsContainer}>
              {/*<TouchableWithoutFeedback onPress={() => alert('TODO PRINT')}>
                <View style={this.style.footerButton}>
                  <Image source={require('../../assets/print.svg')} style={{ width: 36, height: 31 }} />
                  <TranslationView style={{ fontSize: 12 }}>kitchenapp.printreceipts</TranslationView>
                </View>
              </TouchableWithoutFeedback>*/}

              <TouchableWithoutFeedback onPress={() => this.binding.handleNextStatusPressed(this.state.baskets.filter(x => this.state.selectedBasketIds.includes(x.id)))}>
                <View style={this.style.footerButton}>
                  <Image source={require('../../assets/changestatus.svg').default} style={{ width: 41, height: 30 }} />
                  <TranslationView style={{ fontSize: 12 }}>kitchenapp.changestatus</TranslationView>
                </View>
              </TouchableWithoutFeedback>

              <TouchableWithoutFeedback onPress={() => alert('TODO CANCEL ORDERS')}>
                <View style={this.style.footerButton}>
                  <Image source={require('../../assets/cancel.svg').default} style={{ width: 32, height: 30 }} />
                  <TranslationView style={{ fontSize: 12 }}>kitchenapp.cancelorders</TranslationView>
                </View>
              </TouchableWithoutFeedback>

            </View>

          </Animated.View>
        </View>
      </TouchableWithoutFeedback >
    );
  }
}