 
import { View, TextInput, Text, CheckBox, Button, TouchableWithoutFeedback } from 'react-native';
import { TextInputMask } from 'react-native-masked-text';
import PspHelper from '../../../../../../../Common/Helpers/PspHelper';
import { PspTypes } from '../../../../../../../Common/Enums/PspTypes';

import { ContentView, Application, LanguageManager, Managers } from '@levelapp/softfabric';
import { TranslationView, LottieView } from '@levelapp/softfabric-ui';

import Constants from '../../../../../../../Common/Constants/Constants';
import Language from '../../../../../../../Common/DTO/Language';
import Helpers from '../../../../../../../Common/Helpers/Helpers';
import BasketOrderType from '../../../../../../../Common/DTO/BasketOrderType';
import DropDown from "../../../../../../../Common/Components/Dropdown/DropDownView";
import ImageButton from '../../../../../../../Common/Components/imagebutton/ImageButtonView'
import ButtonView from '../../../../../../../Common/Components/Button/ButtonView';

import PaymentSourcesView from './modules/paymentsources/PaymentSourcesView';
import StorespopupViewState from './StorespopupViewState';
import StorespopupViewProps from './StorespopupViewProps';
import StorespopupViewStyle from './StorespopupViewStyle';
import StorespopupViewModel from './StorespopupViewModel';
import PropertiesManagementPopupView from './modules/propertiesmanagementpopup/PropertiesManagementPopupView';
import DeliveryProviderPopupView from './modules/deliveryproviderpopup/DeliveryProviderPopupView';
import BasketOrderTypeView from './modules/basketordertype/BasketordertypeView';
import BasketordertypesmanagementpopupView from './modules/basketordertypesmanagement/BasketordertypesmanagementView';
import PspSectionView from './components/pspsection/PspSectionView';
import MapRenderView from '../../components/maprender/MapRenderView';
import LineView from '../../../../components/line/LineView';
import PopupMenuItemView from '../../../../components/popupmenuitem/PopupMenuItemView';
import ExtendedTextInputView from '../../../../components/extendedtextinput/ExtendedTextInputView';
import ExtendedDropDownView from '../../../../components/extendeddropdown/ExtendedDropDownView';
import PopupHeaderView from '../../../../components/popupheader/PopupHeaderView';
import PicturesView from '../../../../../../../Presentation/modules/dashboard/components/pictures/PicturesView';
import BrandsView from './modules/brands/BrandsView';


export default class StorespopupView extends ContentView<StorespopupViewProps, StorespopupViewState, StorespopupViewModel, StorespopupViewStyle>
{
  /* CONSTRUCTOR */
  constructor(props: StorespopupViewProps) {
    super(props);

    // Binding
    this.bind(new StorespopupViewModel(this), new StorespopupViewStyle(this));
  }

  /* RENDERING */
  render() {
    switch (this.state.windowTab) {
      case 'store':
        return (
          <View style={{ minWidth: 600, minHeight: 850, width: Constants.PopUpWidth, maxWidth: "100%" }}>

            {/* Popup information/confirmation */}
            {this.state.showPopup &&
              <View style={{ position: 'absolute', width: '100%', height: '100%', zIndex: 500, alignItems: 'center' }}>
                <TouchableWithoutFeedback onPress={() => this.binding.handleBackPopupPressed()}>
                  <View style={{ width: '100%', height: '100%', opacity: 0.5, backgroundColor: 'black' }}></View>
                </TouchableWithoutFeedback>
                <View style={{ position: 'absolute', marginTop: '10%', width: 600, height: 350, backgroundColor: 'white', zIndex: 600, borderRadius: 8 }}>
                  <TranslationView style={this.style.titlePopup}>{this.state.titlePopup}</TranslationView>
                  <View style={this.style.footer}>
                    <ButtonView isLoading={false} handleClick={() => this.binding.handleBackPopupPressed()} backgroundColor={Constants.MainColor} style={this.style.YesButton}>
                      <TranslationView upperCase>generic.ok</TranslationView>
                    </ButtonView>
                  </View>
                </View>
              </View>
            }

            {/* Probleme BG black */}
            {/* {this.state.showPopup && 
            <View style={{position : 'fixed', width: '100%', height: '100%', zIndex: 500, alignItems: 'center' } as any}>
              <PopupConfirmationView 
              title={this.state.titlePopup} 
              handleOnPressAccepted={() => {}}
              content={''} confirmationPopup={false} handleOnPressBack={() => this.binding.handleBackPopupPressed()}/>
            </View>} */}


            <PopupHeaderView
              handleBackPressed={() => {

                Helpers.closePopup()
              }}
              title={"store.popup.title"}
              shouldShowButton
              isLoading={this.state.isLoading}
              handleOnPressButton={this.binding.handleSavePressed}
            />

            {/* Menu */}
            <View style={{ display: "flex", flexDirection: "row", flexWrap: "wrap" }}>
              <PopupMenuItemView activeItem={this.state.storeTab} title={"form.maininformation"} item={"main"} handleChangeTab={() => this.binding.changeTab("main")} />
              <PopupMenuItemView activeItem={this.state.storeTab} title={"form.description"} item={"desc"} handleChangeTab={() => this.binding.changeTab("desc")} />
              {/* <PopupMenuItemView activeItem={this.state.storeTab} title={"form.images"} item={"pictures"} handleChangeTab={() => this.binding.changeTab("pictures")}/> */}
              <PopupMenuItemView activeItem={this.state.storeTab} title={"form.openinghours"} item={"openingHour"} handleChangeTab={() => this.binding.changeTab("openingHour")} />
              <PopupMenuItemView activeItem={this.state.storeTab} title={"form.properties"} item={"property"} handleChangeTab={() => this.binding.changeTab("property")} />
              <PopupMenuItemView activeItem={this.state.storeTab} title={"form.delivery"} item={"delivery"} handleChangeTab={() => this.binding.changeTab("delivery")} />
              <PopupMenuItemView activeItem={this.state.storeTab} title={"form.orderType"} item={"orderType"} handleChangeTab={() => this.binding.changeTab("orderType")} />
              <PopupMenuItemView activeItem={this.state.storeTab} title={"form.paymentSources"} item={"paymentSources"} handleChangeTab={() => this.binding.changeTab("paymentSources")} />
              {this.state.brands.length > 1 && (
                <PopupMenuItemView activeItem={this.state.storeTab} title={"form.brands"} item={"brands"} handleChangeTab={() => this.binding.changeTab("brands")} />
              )}
            </View>

            {/* Main Information */}
            {this.state.storeTab == "main" &&
              <View style={{ display: "flex", flexDirection: "column", paddingHorizontal: 170, paddingVertical: 50 }}>

                {/* Id & Type side */}
                <View style={{ flex: 1, justifyContent: "flex-start", flexDirection: 'row' }}>
                  {/* Container External ID & Slug */}
                  <View style={{ flexDirection: 'row', flex: 1 }}>
                    <ExtendedTextInputView
                      title={"form.externaldata"}
                      style={{ zIndex: 2 }}
                      infoText={this.binding.translate('generic.externaldata.info').replace('{1}', this.binding.translate('stores.title.singular').toLowerCase())}
                      showError={this.state.showErrors && (this.state.store.externalId == null || this.state.store.externalId == "") ? true : false}
                      value={this.state.store.externalId}
                      //disable={this.props.isEdition}
                      onChangeText={(text: string) => this.binding.handleExternalIdChange(text)}
                    />

                    <ExtendedTextInputView
                      title={"form.slug"}
                      style={{ zIndex: 1 }}
                      infoText={this.binding.translate('generic.slug.info').replace('{1}', this.binding.translate('stores.title.singular').toLowerCase())}
                      showError={this.state.showErrors && (this.state.store.slug == null || this.state.store.slug == "") ? true : false}
                      value={this.state.store.slug}
                      onChangeText={(text: string) => this.binding.handleSlugChange(text)}
                    />

                    <ExtendedTextInputView
                      title={"form.posid"}
                      style={{ zIndex: 0 }}
                      infoText={this.binding.translate('store.popup.posid.info').replace('{1}', this.binding.translate('stores.title.singular').toLowerCase())}
                      showError={this.state.showErrors && (this.state.store.posId == null || this.state.store.posId == "") ? true : false}
                      value={this.state.store.posId}
                      onChangeText={(text: string) => this.binding.handlePosId(text)}
                    />
                  </View>

                  {/* Container Type */}
                  {/* <View style={{ flexDirection: 'row-reverse' }}>
                    <View>
                      <TranslationView style={this.style.inputTitle} upperCase>form.type</TranslationView>
                      <DropDown
                        values={this.binding.getStoreTypes()}
                        selectedValue={this.binding.getStoreTypes().find((element: any) => { return element.id == this.state.store.typeId })}
                        onValueChange={(value: any) => { this.binding.handleStoreTypeChange(value) }} />
                    </View>
                  </View> */}
                </View>

                {/* Click and collect */}
                <View style={{ marginTop: 25 }}>
                  <View style={{ flexDirection: 'row' }}>
                    <CheckBox style={this.style.checkbox} value={this.state.isClickAndCollectEnabled} onValueChange={(value) => this.binding.handleClickAndCollectChanged(value)} />
                    <Text style={[this.style.inputTitle, { marginLeft: '5px' }]}><TranslationView>store.popup.clickandcollectenabled</TranslationView></Text>
                  </View>
                  <View style={{ flexDirection: 'row' }}>
                    <CheckBox style={this.style.checkbox} value={this.state.store.isPreview} onValueChange={(value: boolean) => this.binding.handlePreviewChanged(value)} />
                    <TranslationView style={[this.style.inputTitle, { marginLeft: '5px' }]}>form.preview</TranslationView>
                  </View>



                </View>
                {PspHelper.getCurrentPsp() != PspTypes.NONE &&
                  <>
                    <LineView />

                    <PspSectionView
                      store={this.props.store}
                      showErrors={false}
                      handleValueChange={this.binding._dispatcherManager.handleValueChange}
                      handleCheckboxChange={this.binding._dispatcherManager.handleCheckboxChange}
                    />
                  </>
                }
                {/* Line */}
                <LineView />
                {/* Favorite language */}
                <View style={{ flexDirection: 'row', flex: 1 }}>
                  <ExtendedDropDownView
                    title={'form.favoritelanguage'}
                    values={this.binding.getFavoriteLanguages()}
                    selectedValue={this.binding.getFavoriteLanguages().find(l => l.id === this.state.store.language?.id)}
                    onValueChange={(value: any) => this.binding.handleDropDownLanguageChange(value)}
                  />
                </View>
                {/* Line */}
                <LineView />
                {/* Title Side */}
                <View style={{ display: "flex", flexDirection: "row", flexWrap: "wrap", justifyContent: 'space-between' }}>
                  {this.state.languages.map((content: Language) => {
                    var _text = Helpers.getTranslationValueByLanguageKey(this.state.store.titleTranslations, content.key);
                    return (
                      <View style={{ marginBottom: 20 }}>
                        <Text style={this.style.inputTitle}><TranslationView upperCase>form.titlefield</TranslationView> ({content.key.toUpperCase()})</Text>
                        <TextInput
                          style={this.state.showErrors && (_text == null || _text == "") ? this.style.errorTextArea : this.style.textArea}
                          multiline={true}
                          onChangeText={(text: string) => this.binding.handleTitleChanged(text, content.id as number)}
                          value={_text} />
                      </View>
                    );
                  })}
                </View>

                {/* Line */}
                <View style={{ zIndex: -1, backgroundColor: '#D8D8D8', width: '100%', height: 1, marginTop: 30, marginBottom: 50 }}></View>

                {/* Address Side */}
                <View style={{ flexDirection: 'row' }}>
                  <ExtendedTextInputView
                    title={'form.streetnumber'}
                    showError={false}
                    value={this.state.store.streetNumber}
                    onChangeText={this.binding.handleStreetNumberChange}
                  />

                  <View style={{ flex: 1, paddingRight: 40 }}>
                    {this.state.languages.map((content: Language) => {
                      var _text = Helpers.getTranslationValueByLanguageKey(this.state.store.streetTranslations, content.key);
                      return (
                        <View style={{ minWidth: 100, paddingBottom: 20 }}>
                          <Text style={this.style.inputTitle}><TranslationView upperCase>form.street</TranslationView> ({content.key.toUpperCase()})</Text>
                          <TextInput
                            style={this.state.showErrors && (_text == null || _text == "") ? this.style.inputStreetVariableSizeError : this.style.inputStreetVariableSize}
                            onChangeText={(text: string) => this.binding.handleStreetChanged(text, content.id)}
                            value={_text} />
                        </View>
                      );
                    })}
                  </View>

                  <View style={{ marginRight: 40 }}>
                    <TranslationView style={this.style.inputTitle} upperCase>form.zipcode</TranslationView>
                    <TextInput
                      style={this.state.showErrors && (this.state.store.zipCode == null || this.state.store.zipCode == "") ? this.style.inputStreetFixedSizeError : this.style.inputStreetFixedSize}
                      value={this.state.store.zipCode}
                      onChangeText={this.binding.handleZipCodeChange} />
                  </View>

                  <View style={{ flex: 1, marginRight: 40 }}>
                    {this.state.languages.map((content: Language) => {
                      var _text = Helpers.getTranslationValueByLanguageKey(this.state.store.cityTranslations, content.key);
                      return (
                        <View style={{ minWidth: 100, marginBottom: 20 }}>
                          <Text style={this.style.inputTitle}><TranslationView upperCase>form.city</TranslationView> ({content.key.toUpperCase()})</Text>
                          <TextInput
                            style={this.state.showErrors && (_text == null || _text == "") ? this.style.inputStreetVariableSizeError : this.style.inputStreetVariableSize}
                            onChangeText={(text: string) => this.binding.handleCityChanged(text, content.id)}
                            value={_text} />
                        </View>
                      );
                    })}
                  </View>

                  <View>
                    <TranslationView style={this.style.inputTitle} upperCase>form.country</TranslationView>
                    <TextInputMask
                      type={'custom'}
                      options={{ mask: 'AA' }}
                      style={this.state.showErrors && (this.state.store.country == null || this.state.store.country == "") ? this.style.inputStreetFixedSizeError : this.style.inputStreetFixedSize}
                      value={this.state.store.country}
                      onChangeText={this.binding.handleCountryChange}
                      placeholder='Ex: BE'
                    />
                  </View>
                </View>

                {/* Line */}
                <View style={{ zIndex: -1, backgroundColor: '#D8D8D8', width: '100%', height: 1, marginTop: 10, marginBottom: 50 }}></View>

                {/* Position Side */}
                <View style={{ justifyContent: "flex-start", flexDirection: 'row' }}>
                  <View style={{ marginRight: 40 }}>
                    <TranslationView style={this.style.inputTitle} upperCase>form.lattitude</TranslationView>
                    <TextInput
                      style={this.state.showErrors && !this.binding.isValidLatitude() ? this.style.inputError : this.style.input}
                      value={this.state.store.lattitude}
                      onChangeText={this.binding.handleLatitudeChange} />
                  </View>

                  <View style={{ marginRight: 40 }}>
                    <TranslationView style={this.style.inputTitle} upperCase>form.longitude</TranslationView>
                    <TextInput
                      style={this.state.showErrors && !this.binding.isValidLongitude() ? this.style.inputError : this.style.input}
                      value={this.state.store.longitude}
                      onChangeText={this.binding.handleLongitudeChange} />
                  </View>

                  <View style={{ height: 250, width: 250 }}>
                    <MapRenderView
                      defaultCenter={{ latitude: this.state.store.lattitude, longitude: this.state.store.longitude }}
                      disableDefaultUI={true}
                      markers={[{ pos: { lat: this.state.store.lattitude, lng: this.state.store.longitude }, editCallback: () => { } }]}
                      oneMarker={true} />
                  </View>
                </View>
                
                {/* Store's timezone */}
                <View style={{ flexDirection: 'row', flex: 1}}>
                    <ExtendedDropDownView
                      title={'store.timezone'}
                      values={this.binding.getTimeZones()}
                      selectedValue={this.binding.getTimeZones().find(tz => tz.id === this.state.store.timeZoneId)}
                      onValueChange={(value: any) => this.binding.handleTimeZoneChanged(value)}
                    />
                </View>

                {/* Line */}
                <View style={{ zIndex: -1, backgroundColor: '#D8D8D8', width: '100%', height: 1, marginTop: 30, marginBottom: 50 }}></View>

                {/* Contact Side */}
                <View style={{ flex: 1, justifyContent: "flex-start", flexDirection: 'row' }}>
                  <View style={{ marginRight: 40 }}>
                    <TranslationView style={this.style.inputTitle} upperCase>form.phonenumber</TranslationView>
                    <TextInputMask
                      type={'custom'}
                      options={{ mask: '999999999999999' }}
                      style={false && this.state.showErrors && (this.state.store.phone == null || this.state.store.phone == "") ? this.style.inputError : this.style.input}
                      value={this.state.store.phone}
                      onChangeText={this.binding.handlePhoneNumberChange}
                      placeholder={'Ex : 0032474782546'} />
                  </View>

                  <View>
                    <TranslationView style={this.style.inputTitle} upperCase>form.email</TranslationView>
                    <TextInput
                      style={false && this.state.showErrors && (this.state.store.email == null || this.state.store.email == "") ? [this.style.inputError, { width: 300 }] : [this.style.input, { width: 300 }]}
                      value={this.state.store.email}
                      onChangeText={this.binding.handleEmailChange} />
                  </View>
                </View>

                {/* Line */}
                <View style={{ zIndex: -1, backgroundColor: '#D8D8D8', width: '100%', height: 1, marginTop: 30, marginBottom: 50 }}></View>

                {/* Manager Side */}
                <View style={{ flex: 1, justifyContent: "flex-start", flexDirection: 'row' }}>
                  <View>
                    <TranslationView style={this.style.inputTitle} upperCase>form.manager</TranslationView>
                    <TextInput
                      style={this.style.input}
                      value={this.state.store.manager}
                      onChangeText={this.binding.handleManagerChange} />
                  </View>
                </View>
              </View>}
            {
              this.state.storeTab == "desc" &&
              <View style={{ flexDirection: "column", paddingHorizontal: 170, paddingVertical: 50 }}>
                <View style={{ display: "flex", flexDirection: "row", flexWrap: "wrap", justifyContent: 'space-between' }}>
                  {this.state.languages.map((content: Language) => {
                    var _text = "";
                    if(this.state.store.descriptionTranslations !== null){
                      _text=Helpers.getTranslationValueByLanguageKey(this.state.store.descriptionTranslations, content.key);
                    }
                    return (
                      <View style={this.style.section}>
                        <Text style={this.style.inputTitle}><TranslationView upperCase>form.description</TranslationView> ({content.key.toUpperCase()})</Text>
                        <TextInput
                          multiline={true}
                          numberOfLines={10}
                          style={this.state.showErrors && (_text == null || _text == "") ? this.style.errorTextArea : this.style.textArea}
                          onChangeText={(text: string) => this.binding.handleDescriptionChanged(text, content.id as number)}
                          value={_text} />
                      </View>
                    );
                  })}
                </View>

                {/* Line */}
                <View style={{ zIndex: -1, backgroundColor: '#D8D8D8', width: '100%', height: 1, marginTop: 30, marginBottom: 50 }}></View>

                <View style={{ display: "flex", flexDirection: "row", flexWrap: "wrap", justifyContent: 'space-between' }}>
                  {this.state.languages.map((content: Language) => {
                    var _text = Helpers.getTranslationValueByLanguageKey(this.state.store.urlTranslations, content.key);
                    return (
                      <View style={this.style.section}>
                        <Text style={this.style.inputTitle}><TranslationView upperCase>form.url</TranslationView> ({content.key.toUpperCase()})</Text>
                        <TextInput
                          style={this.state.showErrors && (_text == null || _text == "") ? this.style.errorTextArea : this.style.textArea}
                          multiline={true}
                          value={_text}
                          onChangeText={(text: string) => this.binding.handleURLChanged(text, content.id)} />
                      </View>
                    );
                  })}
                </View>
              </View>
            }

            {
              this.state.storeTab == "openingHour" &&
              <View style={{ display: "flex", flexDirection: "row", flexWrap: "wrap", justifyContent: 'space-between', paddingHorizontal: 170, paddingVertical: 50 }}>
                {Constants.ShowStoreOpeningHours &&
                  <View style={{ flexDirection: "column", minWidth: 505, marginBottom: 20 }}>
                    <TranslationView style={this.style.scheduleTitle} upperCase>form.storeopening</TranslationView>
                    <View>
                      {(this.state.store.storeOpeningHours as any[]).length != 0 ? <View style={{ flexDirection: 'row', marginBottom: 10 }}>
                        <TranslationView style={{ fontSize: 18, marginRight: 73 }} upperCase>form.weekday</TranslationView>
                        <TranslationView style={{ fontSize: 18, marginRight: 65 }} upperCase>form.opening</TranslationView>
                        <TranslationView style={{ fontSize: 18 }} upperCase>form.closing</TranslationView>
                      </View> : <View></View>}
                      {this.state.store.storeOpeningHours.map((content: any, j: number) => {
                        return (
                          <View style={{ flexDirection: 'row', flex: 1, marginBottom: 10 }}>
                            <DropDown values={this.binding.weekdays}
                              selectedValue={this.binding.weekdays[content.day - 1]} onValueChange={(value: any) => { this.binding.handleDayChange(value, content) }} />
                            <TextInputMask
                              type={'custom'}
                              options={{ mask: '99:99' }}
                              style={this.state.showErrors && (!this.binding.isRespectingTimeFormat(content.opening)) ? [this.style.inputError, { marginRight: 15 }] : [this.style.input, { marginRight: 15 }]}
                              value={content.opening}
                              onChangeText={(time: string) => { this.binding.handleTimeChange(time, j, true, Constants.ScheduleTypeStoreId) }}
                              placeholder='Format : 00:00' />

                            <TextInputMask
                              type={'custom'}
                              options={{ mask: '99:99' }}
                              style={this.state.showErrors && (!this.binding.isRespectingTimeFormat(content.closing)) ? this.style.inputError : this.style.input}
                              value={content.closing}
                              onChangeText={(time: string) => { this.binding.handleTimeChange(time, j, false, Constants.ScheduleTypeStoreId) }}
                              placeholder='Format : 00:00' />
                            <ImageButton image={require('./assets/erase.svg').default} imageHeight={30} imageWidth={30} onClick={() => this.binding.handleDeleteHour(j, Constants.ScheduleTypeStoreId)} style={this.style.deleteButton} />
                          </View>
                        );
                      })}
                    </View>
                    <View style={{ width: 200 }}><Button title='Add Hour' onPress={() => { this.binding.addOpeningHour(Constants.ScheduleTypeStoreId) }}></Button></View>
                  </View>
                }

                {Constants.ShowDriveOpeningHours &&
                  <View style={{ flexDirection: "column", minWidth: 505, marginBottom: 20 }}>
                    <TranslationView style={this.style.scheduleTitle} upperCase>form.driveopening</TranslationView>
                    <View>
                      {(this.state.store.driveOpeningHours as any[]).length != 0 ? <View style={{ flexDirection: 'row', marginBottom: 10 }}>
                        <TranslationView style={{ fontSize: 18, marginRight: 73 }} upperCase>form.weekday</TranslationView>
                        <TranslationView style={{ fontSize: 18, marginRight: 65 }} upperCase>form.opening</TranslationView>
                        <TranslationView style={{ fontSize: 18 }} upperCase>form.closing</TranslationView>
                      </View> : <View></View>}
                      {this.state.store.driveOpeningHours.map((content: any, j: number) => {
                        return (
                          <View style={{ flexDirection: 'row', flex: 1, marginBottom: 10 }}>
                            <DropDown values={this.binding.weekdays}
                              selectedValue={this.binding.weekdays[content.day - 1]} onValueChange={(value: any) => { this.binding.handleDayChange(value, content) }} />
                            <TextInputMask
                              type={'custom'}
                              options={{ mask: '99:99' }}
                              style={this.state.showErrors && (!this.binding.isRespectingTimeFormat(content.opening)) ? [this.style.inputError, { marginRight: 15 }] : [this.style.input, { marginRight: 15 }]}
                              value={content.opening}
                              onChangeText={(time: string) => { this.binding.handleTimeChange(time, j, true, Constants.ScheduleTypeDriveId) }}
                              placeholder='Format : 00:00' />
                            <TextInputMask
                              type={'custom'}
                              options={{ mask: '99:99' }}
                              style={this.state.showErrors && (!this.binding.isRespectingTimeFormat(content.closing)) ? this.style.inputError : this.style.input}
                              value={content.closing}
                              onChangeText={(time: string) => { this.binding.handleTimeChange(time, j, false, Constants.ScheduleTypeDriveId) }}
                              placeholder='Format : 00:00' />
                            <ImageButton image={require('./assets/erase.svg').default} imageHeight={30} imageWidth={30} onClick={() => this.binding.handleDeleteHour(j, Constants.ScheduleTypeDriveId)} style={this.style.deleteButton} />
                          </View>
                        );
                      })}
                    </View>
                    <View style={{ width: 200 }}><Button title='Add Hour' onPress={() => { this.binding.addOpeningHour(Constants.ScheduleTypeDriveId) }}></Button></View>
                  </View>
                }

                {Constants.ShowDeliveryOpeningHours &&
                  <View style={{ flexDirection: "column", minWidth: 505, marginBottom: 20 }}>
                    <TranslationView style={this.style.scheduleTitle} upperCase>form.deliveryopening</TranslationView>
                    <View>
                      {(this.state.store.deliveryOpeningHours as any[]).length != 0 ? <View style={{ flexDirection: 'row', marginBottom: 10 }}>
                        <TranslationView style={{ fontSize: 18, marginRight: 73 }} upperCase>form.weekday</TranslationView>
                        <TranslationView style={{ fontSize: 18, marginRight: 65 }} upperCase>form.opening</TranslationView>
                        <TranslationView style={{ fontSize: 18 }} upperCase>form.closing</TranslationView>
                      </View> : <View></View>}
                      {this.state.store.deliveryOpeningHours.map((content: any, j: number) => {
                        return (
                          <View style={{ flexDirection: 'row', flex: 1, marginBottom: 10 }}>
                            <DropDown values={this.binding.weekdays}
                              selectedValue={this.binding.weekdays[content.day - 1]} onValueChange={(value: any) => { this.binding.handleDayChange(value, content) }} />
                            <TextInputMask
                              type={'custom'}
                              options={{ mask: '99:99' }}
                              style={this.state.showErrors && (!this.binding.isRespectingTimeFormat(content.opening)) ? [this.style.inputError, { marginRight: 15 }] : [this.style.input, { marginRight: 15 }]}
                              value={content.opening}
                              onChangeText={(time: string) => { this.binding.handleTimeChange(time, j, true, Constants.ScheduleTypeDeliveryId) }}
                              placeholder='Format : 00:00' />
                            <TextInputMask
                              type={'custom'}
                              options={{ mask: '99:99' }}
                              style={this.state.showErrors && (!this.binding.isRespectingTimeFormat(content.closing)) ? this.style.inputError : this.style.input}
                              value={content.closing}
                              onChangeText={(time: string) => { this.binding.handleTimeChange(time, j, false, Constants.ScheduleTypeDeliveryId) }}
                              placeholder='Format : 00:00' />
                            <ImageButton image={require('./assets/erase.svg').default} imageHeight={30} imageWidth={30} onClick={() => this.binding.handleDeleteHour(j, Constants.ScheduleTypeDeliveryId)} style={this.style.deleteButton} />
                          </View>
                        );
                      })}
                    </View>
                    <View style={{ width: 200 }}><Button title='Add Hour' onPress={() => { this.binding.addOpeningHour(Constants.ScheduleTypeDeliveryId) }}></Button></View>
                  </View>
                }
              </View>
            }

            {
              this.state.storeTab == "property" &&
              <View style={{ flexDirection: "column", paddingHorizontal: 170, paddingVertical: 50 }}>

                {this.state.isLoadingProperties ?

                  <LottieView height={150} width={150} source={require('../../../../../../../Common' + Constants.LottieAnimationPath)} />

                  :

                  <View style={{ flex: 1, maxHeight: 225, flexWrap: 'wrap' }}>
                    {this.binding.getProperties().map((content: any) => {
                      var contentS = content.titleTranslations.find((element: any) => { return element.language.key == Application.current.resolve<LanguageManager>(Managers.LANGUAGE_MANAGER).language });
                      if (contentS != undefined) {
                        return (
                          <View style={{ flexDirection: 'row' }}>
                            <CheckBox
                              style={{ marginRight: 10 }}
                              value={this.state.store.properties.find((element: any) => element.propertyId == content.id) == null ? false : true}
                              onValueChange={(value: any) => this.binding.handleCheckboxPropertyChange(value, content)} />
                            <Text style={this.style.inputTitle}>{contentS.value}</Text>
                          </View>

                        );
                      } else {
                        return <View />
                      }

                    })}
                  </View>
                }


                {/* Line */}
                <View style={{ zIndex: -1, backgroundColor: '#D8D8D8', width: '100%', height: 1, marginTop: 30, marginBottom: 50 }}></View>

                <Text style={{
                  fontSize: 25,
                  fontWeight: '600',
                  color: '#3F3F3F',
                  fontFamily: 'Montserrat SemiBold',
                  marginBottom: 13,
                }}><TranslationView upperCase>form.propertycreation</TranslationView></Text>

                <View style={{ flexDirection: 'row' }}>
                  <Button disabled={this.state.isLoadingProperties} title='Property Management' onPress={() => { this.binding.showGlobalPropertiesManagementPopup() }}></Button>
                </View>
              </View>
            }

            {
              this.state.storeTab == "delivery" &&
              <View style={{ flexDirection: "column", paddingHorizontal: 170, paddingVertical: 50 }}>

                {this.state.isLoadingProviders ?

                  <LottieView height={150} width={150} source={require('../../../../../../../Common' + Constants.LottieAnimationPath)} />

                  :

                  <View>

                    {this.state.store.deliveries != undefined && this.state.store.deliveries.map((content: any, j: number) => {
                      var providers = this.binding.getDeliveryProviders();
                      return (
                        <View>
                          <View style={{ flexDirection: 'row', flex: 1, marginBottom: 15 }}>
                            <ExtendedDropDownView
                              title={j == 0 ? 'form.provider' : undefined}
                              values={providers}
                              selectedValue={providers.find((element: any) => { return element.id == content.providerId })}
                              onValueChange={(value: any) => { this.binding.handleDeliveriesProviderChange(value, content) }}
                            />

                            <ExtendedTextInputView
                              showError={this.state.showErrors && (content.key == null || content.key == "")}
                              title={j == 0 ? 'form.key' : undefined}
                              value={content.key}
                              style={{ width: 200 }}
                              onChangeText={(key: string) => { this.binding.handleDeliveriesKeyChange(key, j) }}
                            />

                            <ExtendedTextInputView
                              showError={this.state.showErrors && (content.url == null || content.url == "")}
                              title={j == 0 ? 'form.url' : undefined}
                              value={content.url}
                              style={{ width: 200 }}
                              onChangeText={(url: string) => { this.binding.handleDeliveriesURLChange(url, j) }}
                            />

                            {Constants.ShowStoreDeliveriesCheckBoxes &&
                              <View style={{ flexDirection: 'column', width: 200 }}>
                                <View style={{ flexDirection: 'row' }}>
                                  <CheckBox style={this.style.checkbox} value={content.hasMenuInjection} onValueChange={(value) => this.binding.handleMenuInjectionEnabledChange(value, j)} />
                                  <Text style={[this.style.inputTitle, { marginLeft: '5px' }]}><TranslationView>store.popup.menuInjectionEnabled</TranslationView></Text>
                                </View>
                                <View style={{ flexDirection: 'row' }}>
                                  <CheckBox style={this.style.checkbox} value={content.hasOrderInjection} onValueChange={(value) => this.binding.handleOrderInjectionEnabledChange(value, j)} />
                                  <Text style={[this.style.inputTitle, { marginLeft: '5px' }]}><TranslationView>store.popup.orderInjectionEnabled</TranslationView></Text>
                                </View>
                                <View style={{ flexDirection: 'row' }}>
                                  <CheckBox style={this.style.checkbox} value={content.hasInAppDelivery} onValueChange={(value) => this.binding.handleInAppDeliveryEnabledChange(value, j)} />
                                  <Text style={[this.style.inputTitle, { marginLeft: '5px' }]}><TranslationView>store.popup.inAppDeliveryEnabled</TranslationView></Text>
                                </View>
                              </View>
                            }

                            <ImageButton image={require('./assets/erase.svg').default} imageHeight={30} imageWidth={30} onClick={() => this.binding.handleDeleteDelivery(j)} style={this.style.deleteButton} />
                          </View>
                          {Constants.ShowStoreDeliveriesCheckBoxes &&
                            <View>
                              <PicturesView
                                languages={this.props.languages}
                                listImage={content.imageTranslation}
                                listThumbnail={content.imageTranslation}
                                handleOnComplete={(_, value: any) => this.binding.handleImage(value, j)}
                                handleOnPressImage={(_, value: any) => { this.binding.setIndexImage(value) }}
                                handleCheckboxChange={(value: any) => { this.binding.handleDefaultCheckBoxDeliveryChanged(value, j) }}
                                shouldUseDefaultImage={content.useDefaultImageChecked}
                                shouldUseDefaultThumnail={content.useDefaultThumbnailChecked}
                                shouldSkipThumnail={true}
                              />
                            </View>
                          }
                          <View style={{ zIndex: -1, backgroundColor: '#D8D8D8', width: '100%', height: 1, marginTop: 0, marginBottom: 20 }}></View>
                        </View>
                      );
                    })}
                    <View style={{ width: 200 }}>
                      <Button title='Add Delivery' onPress={() => { this.binding.addDelivery() }}></Button>
                    </View>
                  </View>
                }

                {/* Line */}
                <View style={{ zIndex: -1, backgroundColor: '#D8D8D8', width: '100%', height: 1, marginTop: 30, marginBottom: 50 }}></View>

                <Text style={{
                  fontSize: 25,
                  fontWeight: '600',
                  color: '#3F3F3F',
                  fontFamily: 'Montserrat SemiBold',
                  marginBottom: 13,
                }}><TranslationView upperCase>form.providercreation</TranslationView></Text>

                <View style={{ flexDirection: 'row' }}>
                  <Button disabled={this.state.isLoadingProviders} title='Delivery Provider Management' onPress={() => { this.binding.showDeliveryProviderManagementPopup() }}></Button>
                </View>
              </View>
            }

            {this.state.storeTab == "orderType" &&
              <BasketOrderTypeView
                handleBasketOrderTypes={(bool: boolean, orderType: BasketOrderType) => this.binding.handleChangeBasketOrderType(bool, orderType)}
                navigateOnThePopup={() => this.binding.handleNavigationTo('orderType')}
                isLoading={this.state.isLoadingBasketOrderTypes}
                basketOrderTypes={this.state.store.orderTypes}
                allBasketOrderTypes={this.state.basketOrderTypes} />
            }

            {this.state.storeTab == "paymentSources" &&
              <PaymentSourcesView
                isLoading={this.state.isLoadingOrderSources && this.state.isLoadingPaymentSources}
                orderSources={this.state.orderSources} paymentSources={this.state.paymentSources}
                storePaymentSources={this.state.store.storePaymentOrderSources}
                handleValueChanged={(value, orderSourceId, paymentSourceId) => this.binding.handleChangePaymentOrderSource(value, orderSourceId, paymentSourceId)} />
            }

            {this.state.storeTab === "brands" && (
              <BrandsView
                brands={this.state.brands}
                selectedIds={this.state.store?.brandIds ?? []}
                isLoading={this.state.isLoadingBrands}
                handleValueChanged={this.binding.handleChangeBrands}
               />
            )}

            {this.state.showErrors &&
              <View style={this.style.footer} >
                <TranslationView style={{ color: "red" }}>error.please.fields</TranslationView>
              </View>
            }

          </View >
        );
      case 'properties':
        return (<PropertiesManagementPopupView handleBackPressed={() => { this.binding.handlePropertiesBackPressed() }} languages={this.state.languages} list={this.binding.getProperties()} />);
      case 'providers':
        return (<DeliveryProviderPopupView handleBackPressed={() => { this.binding.handleProvidersBackPressed() }} languages={this.state.languages} list={this.binding.getDeliveryProviders()} />);
      case 'orderType':
        return <BasketordertypesmanagementpopupView
          handleBackPressed={() => this.binding.handleNavigationTo('store')}
          languages={this.state.languages}
        />
      default:
        return (<View></View>);
    }

  }
}