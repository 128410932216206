import { ContentViewModel, ContentView, Application, LanguageManager, Managers } from '@levelapp/softfabric';
import RewardsPopUpView from '../rewardspopup/RewardsPopUpView';
 
import RewardsOperations from '../../../../../../../Transfer/RewardsOperations';
import { TableRow } from '@levelapp/softfabric-ui';
import RewardsViewState from './RewardsViewState';
import RewardsViewProps from './RewardsViewProps';
import LanguagesOperations from '../../../../../../../Transfer/LanguagesOperations';
import Helpers from '../../../../../../../Common/Helpers/Helpers';
import ConfirmPopUpView from '../../../../../../../Common/Components/confirmpopup/ConfirmPopUpView';
import ToastHelper from '../../../../../../../Common/Helpers/ToastHelper';
import Reward from '../../../../../../../Common/DTO/Reward';
import Language from '../../../../../../../Common/DTO/Language';
import PaginationHelper from '../../../../../../../Common/Helpers/PaginationHelper';
import ErrorHelper from '../../../../../../../Common/Helpers/ErrorHelper';
import ContentTypeKeyOperations from '../../../../../../../Transfer/ContentTypeKeyOperations';
import ContentTypeKey from '../../../../../../../Common/DTO/ContentTypeKey';
import { ContentTypeEnum } from '../../../../../../../Common/Enums/ContentTypeEnum';
import Constants from '../../../../../../../Common/Constants/Constants';
import TagOperations from '../../../../../../../Transfer/TagOperations';
import Tag from '../../../../../../..//Common/DTO/Tag';
import TagHelper from 'src/Common/Helpers/TagHelper';

export default class RewardsViewModel extends ContentViewModel<RewardsViewState, RewardsViewProps>{

    _contentTypeKeys : ContentTypeKey [] = [];
    interval: any;
    searchText: string;
    isSorting: boolean;
    indexHeaderSort: number;
    sortOrder: string;
    sortKey: string[];
    _languages: Language[] = [];
    _filtredTag : Tag[] = [];

    tagOperation : TagOperations;
    rewardOperation: RewardsOperations;
    languagesOperation: LanguagesOperations;
    contentTypeKeyOperation: ContentTypeKeyOperations;


    constructor(props: ContentView) {
        super(props);

        this.interval = null;
        this.searchText = '';
        this.isSorting = false;
        this.indexHeaderSort = -1;
        this.sortOrder = 'asc';
        this.sortKey = ['Id', 'PosId', 'Points', 'Title',  'Image'];

        /* Initial State */
        this.initialState({ tableData: [], tableHeaders: ['Id', 'POS Id', 'Points', 'Title', 'Image'], isLoading: true, isLoadingPopup : false, nbPage: 0, currentPage: 1, isResearching: false });
        this.tagOperation = new TagOperations();
        this.rewardOperation = new RewardsOperations();
        this.languagesOperation = new LanguagesOperations();
        this.contentTypeKeyOperation = new ContentTypeKeyOperations();

        this.languagesOperation.get((languages: any) => {
            this._languages = languages.data;
        });

        this.tagOperation.get(( tags : Tag[])=> 
        {
            this._filtredTag = TagHelper.filterTag(tags,"reward");
        } , () =>{})


        /* BINDINGS */
        this.editField = this.editField.bind(this);
        this.addField = this.addField.bind(this);
        this.deleteField = this.deleteField.bind(this);
        this.refresh = this.refresh.bind(this);
        this.getData = this.getData.bind(this);
        this.onPageChange = this.onPageChange.bind(this);
        this.onSortChange = this.onSortChange.bind(this);
        this.handleResearching = this.handleResearching.bind(this);
        this.handleCloseResearching = this.handleCloseResearching.bind(this);
        this.errorManagement = this.errorManagement.bind(this);
    }

    componentDidMount() {
        this.refresh();
    }

    refresh() {
        this.setField({ isLoading: true });
        Constants.UseSettingsContent && this.contentTypeKeyOperation.getContentTypeKeys(ContentTypeEnum.REWARD,(contentTypeKeys:ContentTypeKey[]) => {
            this._contentTypeKeys = contentTypeKeys;
        },this.errorManagement)
        let parameters = PaginationHelper.basicResearchPush(
            this.state().currentPage,
            20,
            this.sortKey[this.indexHeaderSort],
            this.sortOrder,
            Application.current.resolve<LanguageManager>(Managers.LANGUAGE_MANAGER).language ,
            this.searchText,
            this.isSorting,
            this.state().isResearching);
        this.getData(parameters);
    }

    getData(queryParameters : any[]) {
        this.rewardOperation.get(queryParameters, (rewards: any) => {
            let tableData: TableRow[] = [];
            rewards.data.objects.forEach((reward: any) => {
                let title = reward.titleTranslations[0].value;
                let img = reward.imageTranslation != null && reward.imageTranslation.length > 0 ? reward.imageTranslation[0].url : '';
                tableData.push({ data: [reward.id, reward.posId, reward.points, title,  img], isDeletable: true, isEditable: true, editCallback: () => { this.editField(reward) }, deleteCallback: () => { this.deleteField(reward) } });
            });
            this.setField({ tableData: tableData, rewards: rewards.data.objects, nbPage: rewards.data.totalPage, isLoading: false });
        },this.errorManagement)
    }

    addField() {
        //Doesn't add, just display the popup

        this.showPopUp(
            <RewardsPopUpView
                reward={{
                    
                    isAnonymousVisible: false,
                    isPreview : false,
                    isUnlocked : false,
                    points : 0,
                    posId : '',
                    rewardProducts : [],
                    titleTranslations: Helpers.generateTranslationTable(this._languages),
                    descriptionTranslations: Helpers.generateTranslationTable(this._languages),
                    imageTranslation: Helpers.generateUrlTable(this._languages),
                    thumbnailTranslation: Helpers.generateUrlTable(this._languages),
                    contentTypeKeyValue : [],
                }
                }
                tags={this._filtredTag}
                refreshParent={this.refresh} 
                languages={this._languages}
                contentTypeKeys={this._contentTypeKeys}
                 />
        );
    }
 
    editField(reward: Reward) {
        //Doesn't edit, just display the popup
        reward.imageTranslation = Helpers.generateUrlTableEdit(reward.imageTranslation, this._languages);
        reward.thumbnailTranslation = Helpers.generateUrlTableEdit(reward.thumbnailTranslation, this._languages);
        this.showPopUp(
            <RewardsPopUpView 
            tags={this._filtredTag}
            refreshParent={this.refresh} 
            reward={Helpers.clone(reward)}  
            languages={this._languages} 
            contentTypeKeys={this._contentTypeKeys}/>
        );
    }

    deleteField(reward: Reward) {
        this.showPopUp(
            <ConfirmPopUpView callBack={() => {
                this.rewardOperation.delete(reward, () => {
                    this.showToast(ToastHelper.getToast("rewards.popup.success.deleted","success"),() => {},2500);
                    this.refresh();
                },this.errorManagement);
            }} />
        );
    }


    onPageChange(page: number) {
        this.state().currentPage = page + 1;
        this.refresh()
    }

    onSortChange(indexHeaderSort: number, sortOrder: string) {
        if (indexHeaderSort == -1) {
            this.isSorting = false;
        } else {
            this.indexHeaderSort = indexHeaderSort;
            this.sortOrder = sortOrder;
            this.isSorting = true;
        }
        this.refresh();
    }

    handleResearching(searchText: string) {
        this.setField({ isLoading: true, isResearching: true });
        this.searchText = searchText;

        if (this.interval) {
            clearInterval(this.interval);
        }
        this.interval = setTimeout(() => {
            this.onPageChange(0)
        }, 1000)
    }

    handleCloseResearching() {
        this.state().isResearching = false;
        this.onPageChange(0);
    }


    errorManagement(error : any) 
    {
        //set loading : false
        this.setField({isLoading : false});
        ErrorHelper.showMessageError(error.response);
    }
}