import {ContentViewStyle} from '@levelapp/softfabric';
import {StyleProp,TextStyle,ViewStyle} from 'react-native';

export default class SectionTitleViewStyle extends ContentViewStyle
{
    page: StyleProp<ViewStyle> = {
        flex: 1
    }

    subHeading: StyleProp<TextStyle> = {
        fontSize: 19,
        fontWeight : 'bold'
    };
}