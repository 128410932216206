import {ContentViewModel,ContentView} from '@levelapp/softfabric';
import BasketStatusViewState from './BasketStatusViewState';
import BasketStatusViewProps from './BasketStatusViewProps';

export default class BasketStatusViewModel extends ContentViewModel<BasketStatusViewState, BasketStatusViewProps>
{
      /* CONSTRUCTOR */
    constructor(view: ContentView)
    {
        super(view);

        this.initialState({}); // Initialize your state here

        // Bindings

    }
}