 
import { ContentView } from '@levelapp/softfabric';
import SettingsLineViewState from './SettingsLineViewState';
import SettingsLineViewProps from './SettingsLineViewProps';
import SettingsLineViewStyle from './SettingsLineViewStyle';
import SettingsLineViewModel from './SettingsLineViewModel';
import { TouchableWithoutFeedback, View } from 'react-native';
import ExtendedTranslationView from '../../../../../../components/extendedtranslation/ExtendedTranslationView';
import SwitchView from '../../../../../../components/switch/SwitchView';
import { LottieView, TranslationView } from '@levelapp/softfabric-ui';

export default class SettingsLineView extends ContentView<SettingsLineViewProps, SettingsLineViewState, SettingsLineViewModel, SettingsLineViewStyle>
{
  /* CONSTRUCTOR */
  constructor(props: SettingsLineViewProps) {
    super(props);

    // Binding
    this.bind(new SettingsLineViewModel(this), new SettingsLineViewStyle(this));
  }

  /* RENDERING */
  render() {
    const { infoKey, titleKey, type, value, buttonKey, isLoading, onClick } = this.props;

    return (
      <View style={this.style.container}>
        <View style={{ zIndex: 1 }}>
          <ExtendedTranslationView styleText={this.style.titleTxt} infoText={this.binding.translate(infoKey)}>{titleKey}</ExtendedTranslationView>
        </View>
        <TouchableWithoutFeedback onPress={onClick}>
          {type === 'toggle' ? (
            <View>
              <SwitchView enabled={value} enabledColor={'#99D597'} />
            </View>
          ) : (
            <View style={this.style.button}>
              {isLoading ? (
                <LottieView height={150} width={150} source={require('../../../../assets/loading.json')} />
              ) : (
                <TranslationView style={{ color: '#358DFF' }}>
                  {buttonKey}
                </TranslationView>
              )}
            </View>
          )}
        </TouchableWithoutFeedback>
      </View>
    );
  }
}