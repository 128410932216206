import { ContentViewModel, ContentView, Application, LanguageManager, Managers } from '@levelapp/softfabric';

import CouponOrderSourceOrderTypeViewState from './CouponOrderSourceOrderTypeViewState';
import CouponOrderSourceOrderTypeViewProps from './CouponOrderSourceOrderTypeViewProps';

import { OrderSources } from 'src/Common/Enums/OrderSources';
import BasketOrderType from 'src/Common/DTO/BasketOrderType';
import TranslationValue from 'src/Common/DTO/TranslationValue';

export default class CouponOrderSourceOrderTypeViewModel extends ContentViewModel<CouponOrderSourceOrderTypeViewState, CouponOrderSourceOrderTypeViewProps>
{
  /* CONSTRUCTOR */
  constructor(view: ContentView) {
    super(view);

    this.initialState({}); // Initialize your state here

    // Bindings
    this.isSelected = this.isSelected.bind(this);
    this.getTranslation = this.getTranslation.bind(this);
  }

  isSelected(orderSource: OrderSources, basketOrderType: number) {
    const { couponOrderTypeOrderSources } = this.props();

    return couponOrderTypeOrderSources
      .find(x => x.orderSourceId == orderSource && x.basketOrderTypeId == basketOrderType) != undefined;
  }

  getTranslation(basketOrderType: BasketOrderType) {
    const translation = basketOrderType.labelTranslations
      .find((translation: TranslationValue) => translation.language.key == Application.current.resolve<LanguageManager>(
        Managers.LANGUAGE_MANAGER).language)?.value;

    if (translation) {
      return translation;
    }

    return 'No name';
  }
}